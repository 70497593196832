import { Box, Button, Center, Flex, SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useColorModeValue } from "@chakra-ui/react";
import { WiCloudy, WiDaySleet, WiDaySunny, WiNa, WiNightAltSleet, WiNightClear, WiRain, WiRainWind } from 'react-icons/wi';
import { BiWind, BiSun } from "react-icons/bi";
import { FaRegSnowflake, FaRegStar, FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";

function Widget({ station, isFavorite, toggleFavorite, openStationModal }) {
    const textColor = useColorModeValue("white", "gray.700");
    const footerTextColor = useColorModeValue("gray.700", "white");

    const degreesToCardinal = (dir) => {
        let directions = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW', 'N'];
        return directions[Math.round(dir / 22.5)];
    }

    return (
        <Box borderRadius="20px" p="10px" bgGradient={gradientColor()} h={370}>
            <Link to={`/estacao/${station.id}`}>

                {station.last_data && (
                    <Flex color={textColor} fontSize="sm" justifyContent="center" fontWeight="light">
                        {station.last_data.created_at}
                    </Flex>
                )}

                <Flex color={textColor} direction="column" pt="5px" style={{ position: 'relative' }}>
                    <Center>
                        <Text fontWeight="semibold" whiteSpace="nowrap" overflowX="hidden" textOverflow="ellipsis">
                            {station.city} / {station.state}
                        </Text>
                    </Center>

                    {station.last_data && (
                        <Center>
                            <Text fontSize="6xl" fontWeight="extrabold">
                                {station.last_data.temp}
                            </Text>
                        </Center>
                    )}
                    <Center>
                        {icon({ fontSize: "7em", margin: "-15px 0 -10px 0" })}
                    </Center>
                </Flex>
            </Link>

            {/* Data Tabs */}
            {station.last_data && (
                <Tabs variant={"unstyled"} isFitted>
                    <TabPanels>
                        <TabPanel>
                            <SimpleGrid columns={3} color={textColor}>
                                <Box>
                                    <Center fontSize={"x-small"}>Mínima</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.temp_min}º</Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Sensação</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.heat_index || '-'}º</Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Máxima</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.temp_max}º</Center>
                                </Box>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={3} color={textColor}>
                                <Box>
                                    <Center fontSize={"x-small"}>Velocidade</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.wind_speed || 0}<Text fontSize={"x-small"}>km/h</Text></Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Direção</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{degreesToCardinal(station.last_data.wind_dir)}</Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Rajada</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.wind_gust || '-'}{station.last_data.wind_gust && (<Text fontSize={"x-small"}>km/h</Text>)}</Center>
                                </Box>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={3} color={textColor}>
                                <Box>
                                    <Center fontSize={"x-small"}>Umidade</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.humidity}<Text fontSize={"x-small"}>%</Text></Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Chuva</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.precip_rate || 0}<Text fontSize={"x-small"}>mm</Text></Center>
                                </Box>
                                <Box>
                                    <Center fontSize={"x-small"}>Acumulado</Center>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>{station.last_data.precip_total || 0}<Text fontSize={"x-small"}>mm</Text></Center>
                                </Box>
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <SimpleGrid columns={1} color={textColor} mt={1}>
                                <Box>
                                    <Center whiteSpace={"nowrap"} fontSize={"sm"}>
                                        <Button colorScheme={"yellow"} size="sm" borderRadius={6} leftIcon={isFavorite ? <FaStar /> : <FaRegStar />} onClick={() => toggleFavorite(station)}>
                                            {isFavorite ? "Desfavoritar" : "Favoritar"}
                                        </Button>
                                    </Center>
                                </Box>
                            </SimpleGrid>
                        </TabPanel>
                    </TabPanels>
                    <TabList bgColor={textColor} style={{ borderRadius: 15 }} color={footerTextColor}>
                        <Tab style={{ opacity: .3 }} _selected={{ color: "yellow.400", borderRadius: 10, opacity: "100% !important" }} px={2}><BiSun fontSize={20} strokeWidth={.5} /></Tab>
                        <Tab style={{ opacity: .3 }} _selected={{ color: "blue.300", borderRadius: 10, opacity: "100% !important" }} px={2}><BiWind fontSize={20} strokeWidth={.5} /> </Tab>
                        <Tab style={{ opacity: .3 }} _selected={{ color: "blue.500", borderRadius: 10, opacity: "100% !important" }} px={2}><WiRainWind fontSize={24} strokeWidth={.5} /></Tab>
                        <Tab style={{ opacity: .3 }} _selected={{ color: "gold", borderRadius: 10, opacity: "100% !important" }} px={2}><FaStar fontSize={20} /></Tab>
                    </TabList>
                </Tabs>
            )}
        </Box>
    );

    function gradientColor() {
        let condition = getCategory('bg');
        switch (condition) {
            case "none":
                return "linear(to-br, gray.300 0%, gray.400 50%, gray.300 100%)";
            case "freezing":
                return "linear(to-br, purple.600 0%, purple.400 50%, purple.100 100%)";
            case "cold":
                return "linear(to-br, blue.100 0%, blue.300 50%, blue.100 100%)";
            case "agradable":
                return "linear(to-br, blue.200 0%, blue.400 50%, blue.300 100%)";
            case "cloudy":
            case "rain":
                return "linear(to-br, gray.600 0%, gray.500 25%, blue.600 50%, blue.400 100%)";
            case "hot":
                return "linear(to-br, yellow.200 0%, orange.300 25%, blue.300 80%)";
            case "hottest":
                return "linear(to-br, yellow.200 0%, orange.500 25%, blue.300 80%)";
        }
    }

    function icon(style) {
        let condition = getCategory('icon');
        let hours = new Date().getHours();
        let dayMoment = hours < 6 || hours > 18 ? "night" : "day";

        switch (condition) {
            case "none":
                style.marginTop = 70;
                return <WiNa style={style} />
            case "freezing":
                return <FaRegSnowflake style={{ ...style, fontSize: '5em', margin: ".2rem 0 .2rem 0" }} my={2} />;
            case "sunny":
            case "cold":
            case "agradable":
            case "hot":
            case "hottest":
                if (dayMoment === "day")
                    return <WiDaySunny style={style} />;
                else
                    return <WiNightClear style={style} />;
            case "cloudy":
                return <WiCloudy style={style} />;
            case "rain":
                return <WiRain style={style} />;
            case "opening_clouds":
                if (dayMoment === "day")
                    return <WiDaySleet style={style} />;
                else
                    return <WiNightAltSleet style={style} />;
        }
    }

    function getCategory(type) {
        if (!station.last_data || !station.last_data.temp)
            return 'none';

        switch (type) {
            case 'bg':
                if (station.last_data.temp < 0) {
                    return 'freezing';
                } else if (station.last_data.temp < 15) {
                    return 'cold';
                } else if (station.last_data.temp > 35) {
                    return 'hottest';
                } else if (station.last_data.temp > 30) {
                    return 'hot';
                } else {
                    if (station.last_data.precip_total > 0) {
                        if (station.last_data.precip_rate > 0)
                            return 'rain';
                        else
                            return 'cloudy';
                    } else {
                        return 'agradable';
                    }
                }
                break;
            case 'icon':
                if (station.last_data.temp < 0) {
                    return 'freezing';
                } else {
                    if (station.last_data.precip_total > 0) {
                        if (station.last_data.precip_rate > 0)
                            return 'rain';
                        else
                            return 'opening_clouds';
                    } else {
                        return "sunny";
                    }
                }
                break;
        }
    }
}

export default Widget;
