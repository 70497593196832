import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button } from "@chakra-ui/react";
import { useAuth } from "Providers/AuthProvider";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";

function ModuleLocked() {
    const loading = useLoading();
    const user = useAuth().getUser();

    useEffect(() => {
        loading.hide();
    });

    return (
        <Box>
            <Alert
                status='warning'
                variant='subtle'
                flexDirection='column'
                alignItems='center'
                justifyContent='center'
                textAlign='center'
                py={10}
                rounded="md"
            >
                <AlertIcon boxSize='40px' mr={0} />
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                    Módulo Bloqueado
                </AlertTitle>
                <AlertDescription>
                    Você não possui acesso a este módulo no momento!<br />
                    Para projetos personalizados de Balanço Hídrico, entre em contato conosco pelo WhatsApp.<br/>

                    <Button colorScheme="whatsapp" leftIcon={<FaWhatsapp fontSize={24}/>} mt={4} as={"a"} target="_blank" href={"https://wa.me/553591851089?text=Ol%C3%A1%21%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Balan%C3%A7o%20H%C3%ADdrico%20no%20WS%20Clima"}>
                        William - (35) 9185-1089
                    </Button>
                </AlertDescription>
            </Alert>
        </Box>
    );
}

export default ModuleLocked;
