import {
    Flex,
    Text,
    SimpleGrid,
    GridItem,
    Skeleton,
    Box,
    useColorModeValue,
    Spinner,
    TableContainer,
    Thead,
    Table,
    Tr,
    Th,
    Tbody,
    Td,
    useToast,
    Divider,
    Icon,
} from "@chakra-ui/react";
import StationsApi from "Api/StationsApi";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import StationMap from "./Components/StationMap";
import jsonData from "../../apex_chart_pt_br.json";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import {
    TbArrowDown,
    TbArrowUp,
    TbCloudRain,
    TbDropCircle,
    TbDroplets,
    TbWind,
    TbWindsock,
    TbWindmill,
    TbSunWind,
    TbSunHigh,
    TbArrowBigDownLines,
    TbBattery,
    TbBattery3,
    TbSpray,
    TbCheck,
    TbCheckbox,
    TbCircleCheckFilled,
    TbCircleXFilled,
    TbCircleMinus,
    TbCircleDotFilled,
    TbAlertTriangleFilled,
} from "react-icons/tb";
import Forecast16Graphs from "views/Components/Forecast16Graphs";

export default function StationDetails() {
    let { id } = useParams();
    const history = useHistory();
    const toast = useToast({
        position: "top-right",
    });

    const bgColor = useColorModeValue("white", "gray.700");

    const degreesToCardinal = (dir) => {
        let directions = [
            "N",
            "NNE",
            "NE",
            "ENE",
            "E",
            "ESE",
            "SE",
            "SSE",
            "S",
            "SSW",
            "SW",
            "WSW",
            "W",
            "WNW",
            "NW",
            "NNW",
            "N",
        ];
        return directions[Math.round(dir / 22.5)];
    };

    const [data, setData] = useState([]);
    const [data24, setData24] = useState([]);
    const [station, setStation] = useState(null);
    const [currData, setCurrData] = useState(null);
    const [extremos, setExtremos] = useState(null);
    const [last10Data, setLast10Data] = useState([]);

    // const [forecastData, setForecastData] = useState([]);
    const loading = useLoading();

    async function loadData() {
        loading.show();
        // setForecastData([]);
        let response = null;
        try {
            response = await StationsApi.detail(id);
        } catch (err) { }

        if (!response || response.status != 200 || !response.json.station) {
            toast({
                status: "error",
                title: `Estação com ID #${id} não encontrada!`,
                duration: 6000,
                variant: "solid",
                isClosable: true,
            });
            history.push("/");
            return;
        }

        let _station = response.json.station;

        setData(response.json.data_7_days);
        setData24(response.json.data_24_hours);
        setCurrData(response.json.curr_data);
        setLast10Data(response.json.last_10_entries);
        setExtremos({
            minima: response.json.minima,
            maxima: response.json.maxima,
        });
        setStation(_station);

        window.gtag("event", "visualizacao_estacao", {
            event_label: "Visualização Estação",
            value: _station.identifier + " - " + _station.provider,
        });

        loading.hide();
        // response = await StationsApi.forecast(_station.id);
        // setForecastData(response.json);
    }

    useEffect(() => {
        refreshData();
    }, []);

    async function refreshData() {
        loadData();

        setTimeout(() => {
            refreshData();
        }, 1000 * 60 * 2);
    }

    const getChartSeriesTemp = () => [
        {
            name: "Temperatura Mínima",
            data: data.map((d) => parseFloat(d.temp_min)),
        },
        {
            name: "Temperatura Média",
            data: data.map((d) => parseFloat(d.temp_avg)),
        },
        {
            name: "Temperatura Máxima",
            data: data.map((d) => parseFloat(d.temp_max)),
        },
    ];

    const getChartSeriesPrecip = () => [
        {
            name: "Precipitação",
            data: data.map((d) => parseFloat(d.precip_total || 0)),
        },
    ];

    const getChartSeries24Temp = () => [
        {
            name: "Temperatura",
            data: data24.map((d) => parseFloat(d.temp_avg)),
            color: "#ECC94B",
        },
    ];

    const getChartSeries24Precip = () => [
        {
            name: "Precipitação",
            data: data24.map((d) => parseFloat(d.precip_total) || 0),
        },
    ];

    const getChartOptions24 = (suffix = "", dataLabels = true) => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: "pt-br",
            },
            tooltip: {
                theme: "dark",
                x: {
                    show: true,
                    format: "dd MMM HH:mm",
                },
            },
            dataLabels: {
                enabled: dataLabels,
                formatter: function (val, opts) {
                    if (opts.dataPointIndex == 0 || opts.dataPointIndex % 5 != 0)
                        return "";

                    return val + suffix;
                },
            },
            xaxis: {
                type: "datetime",
                categories: data24.map((d) => d.date),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            yaxis: {
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
        };
    };

    const getChartOptions = (suffix = "") => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: "pt-br",
            },
            tooltip: {
                theme: "dark",
                x: {
                    show: true,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    return val + suffix;
                },
            },
            xaxis: {
                type: "datetime",
                categories: data.map((d) => d.date),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            yaxis: {
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
        };
    };

    const pulvData = currData ? calculatePulv(currData) : {};

    return (
        <Box p={4} maxW={"8xl"} mx="auto">
            <Box p={4} bg={bgColor} rounded="lg" shadow="md">
                <Flex
                    align="center"
                    justify={"center"}
                    direction={"column"}
                    mb={4}
                    fontSize={"2xl"}
                >
                    {!station ? (
                        <>
                            <Text>Carregando dados da estação...</Text>
                            <Spinner m={2} />
                        </>
                    ) : (
                        <>
                            <Text textAlign={"center"} mb={2} fontSize={"2xl"}>
                                {station.full_name}
                            </Text>
                        </>
                    )}
                </Flex>

                {!!currData && (
                    <Box mb={4}>
                        <Flex
                            justifyContent={"center"}
                            flexWrap={"wrap"}
                            direction={"row"}
                            align="center"
                            gridGap={{ base: 3, md: 8 }}
                        >
                            <Flex
                                fontSize={{ base: "xl", md: "3xl" }}
                                color={"blue.500"}
                                align="center"
                                gridGap={1}
                            >
                                <TbArrowDown />
                                {extremos?.minima || "-"}º
                            </Flex>
                            <Flex
                                fontSize={{ base: "3xl", md: "6xl" }}
                                direction={"column"}
                                justify={"center"}
                                align="center"
                            >
                                {currData?.temp || "-"}º
                                {currData?.heat_index && (
                                    <Text
                                        mt={{ base: -2, md: -4 }}
                                        fontSize={{ base: "xs", md: "sm" }}
                                        opacity={0.75}
                                    >
                                        Sensação de {currData?.heat_index || "-"}º
                                    </Text>
                                )}
                            </Flex>
                            <Flex
                                fontSize={{ base: "xl", md: "3xl" }}
                                color={"red.500"}
                                align="center"
                                gridGap={1}
                            >
                                {extremos?.maxima || "-"}º
                                <TbArrowUp />
                            </Flex>
                        </Flex>

                        <Flex wrap={"wrap"} justify={"center"} mt={2} mx={-2}>
                            <WeatherDataCard
                                icon={<TbDroplets />}
                                color={"blue.400"}
                                text={"Umidade"}
                                data={`${currData?.humidity || "-"}%`}
                            />
                            <WeatherDataCard
                                icon={<TbWind />}
                                color={"green.400"}
                                text={"Vel. do Vento"}
                                data={`${currData?.wind_speed || "-"}km/h`}
                            />
                            <WeatherDataCard
                                icon={<TbWindsock />}
                                color={"red.400"}
                                text={"Dir. do Vento"}
                                data={`${currData.wind_dir || "-"}º (${degreesToCardinal(
                                    currData.wind_dir
                                )})`}
                            />
                            <WeatherDataCard
                                icon={<TbWindmill />}
                                color={"purple.400"}
                                text={"Raj. de Vento"}
                                data={`${currData.wind_gust || "-"}km/h`}
                            />
                            <WeatherDataCard
                                icon={<TbCloudRain />}
                                color={"blue.600"}
                                text={"Índice de Chuva"}
                                data={`${currData.precip_rate || "-"}mm`}
                            />
                            <WeatherDataCard
                                icon={<TbDropCircle />}
                                color={"cyan.400"}
                                text={"Chuva Acumulada"}
                                data={`${currData.precip_total || "-"}mm`}
                            />
                            <WeatherDataCard
                                icon={<TbSunHigh />}
                                color={"yellow.400"}
                                text={"Índice UV"}
                                data={`${currData.uv || "-"}`}
                            />
                            <WeatherDataCard
                                icon={<TbSunWind />}
                                color={"orange.400"}
                                text={"Rad. Solar"}
                                data={`${currData.solar_radiation || "-"}`}
                            />
                            <WeatherDataCard
                                icon={<TbArrowBigDownLines />}
                                color={"green.400"}
                                text={"Pressão Atmosf."}
                                data={`${currData.pressure || "-"}`}
                            />

                            {!!station?.battery_level && (
                                <WeatherDataCard
                                    icon={<TbBattery3 />}
                                    color={"green.400"}
                                    text={"Nível da Bateria"}
                                    data={`${station?.battery_level || "-"}`}
                                />
                            )}

                            <WeatherDataCard
                                icon={<TbSpray />}
                                color={"red.400"}
                                text={"Pulverização"}
                                data={
                                    <Flex direction={"column"} align={"end"} w="full" fontSize={"sm"}>
                                        <Text color={pulvData.status == "good" ? "whatsapp.500" : pulvData.status == "ok" ? "yellow.500" : "red.500"} fontWeight={"semibold"}>
                                        {pulvData.status == "good" ? "Ideal" : pulvData.status == "ok" ? "Arriscado" : "Inadequado"}
                                        </Text>
                                        <Flex direction={"row"} gridGap={4} fontSize={"xs"}>
                                            <Flex whiteSpace={"nowrap"} gridGap={1} align="center" direction={"row"}>
                                                <Icon fontSize={"lg"} as={pulvData.deltaTStatus == "good" ? TbCircleCheckFilled : pulvData.deltaTStatus == "ok" ? TbAlertTriangleFilled : TbCircleXFilled} color={pulvData.deltaTStatus == "good" ? "whatsapp.500" : pulvData.deltaTStatus == "ok" ? "yellow.500" : "red.500"} />{" "}
                                                <Flex direction={"column"} textAlign={"left"}>
                                                    Delta
                                                    <Text opacity={0.5} fontSize={"x-small"}>{pulvData.deltaT?.toFixed(1)}º</Text>
                                                </Flex>
                                            </Flex>
                                            <Flex whiteSpace={"nowrap"} gridGap={1} align="center" direction={"row"}>
                                                <Icon fontSize={"lg"} as={pulvData.windStatus == "good" ? TbCircleCheckFilled : pulvData.windStatus == "ok" ? TbCircleDotFilled : TbCircleXFilled} color={pulvData.windStatus == "good" ? "whatsapp.500" : pulvData.windStatus == "ok" ? "yellow.500" : "red.500"} />{" "}
                                                <Flex direction={"column"} textAlign={"left"}>
                                                    Vento
                                                    <Text opacity={0.5} fontSize={"x-small"}>{pulvData.wind}km/h</Text>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                }
                            />
                        </Flex>

                        <Text textAlign={"center"} opacity={0.5} fontSize={"xs"} mt={2}>
                            Atualizado {moment(currData.created_at).format("llll")}
                        </Text>
                    </Box>
                )}

                <Divider my={4} />

                <Flex direction="column" mb={4}>
                    <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>
                        Últimas 24 Horas - Temperatura
                    </Text>
                    <ReactApexChart
                        type="line"
                        options={getChartOptions24("°")}
                        series={getChartSeries24Temp()}
                        width="100%"
                        height={350}
                    />
                    {station?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                </Flex>

                <Divider my={4} />

                <Flex direction="column" mb={4}>
                    <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>
                        Últimas 24 Horas - Chuva
                    </Text>
                    <ReactApexChart
                        options={getChartOptions24("mm", false)}
                        series={getChartSeries24Precip()}
                        width="100%"
                        height={350}
                        type="bar"
                    />
                    {station?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                </Flex>

                <Divider my={4} />

                <Flex direction="column" mb={4}>
                    <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>
                        Últimos 7 Dias - Temperatura
                    </Text>
                    <ReactApexChart
                        type="line"
                        options={getChartOptions("°")}
                        series={getChartSeriesTemp()}
                        width="100%"
                        height={350}
                    />
                    {station?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                </Flex>

                <Divider my={4} />

                <Flex direction="column" mb={4}>
                    <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>
                        Últimos 7 Dias - Chuva
                    </Text>
                    <ReactApexChart
                        options={getChartOptions("mm")}
                        series={getChartSeriesPrecip()}
                        width="100%"
                        height={350}
                        type="bar"
                    />
                    {station?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                </Flex>

                <Divider my={8} />

                <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} mb={4} gap={3}>
                    <GridItem colSpan={{ sm: 1, md: 2, lg: 3 }} mb={2}>
                        <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>
                            Previsão do Tempo
                        </Text>
                    </GridItem>
                    <GridItem colSpan={{ sm: 1, md: 2, lg: 3 }}>
                        <Forecast16Graphs station_id={id} />
                    </GridItem>
                </SimpleGrid>

                <Divider my={8} />

                {!!station && <StationMap station={station} />}

                <Divider my={8} />

                <Box my={4}>
                    <Text
                        fontSize={"lg"}
                        fontWeight={"semibold"}
                        mb={2}
                        textAlign={"center"}
                    >
                        Últimos 10 dados recebidos
                    </Text>
                    <TableContainer>
                        <Table variant="striped" size="sm">
                            <Thead>
                                <Tr>
                                    <Th>Momento</Th>
                                    <Th>Temperatura</Th>
                                    <Th>Umidade</Th>
                                    <Th>Chuva</Th>
                                    <Th>Vento</Th>
                                    <Th>Índices Solares</Th>
                                    <Th>Pressão</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {last10Data?.length > 0 ? (
                                    last10Data?.map((_data) => (
                                        <Tr>
                                            <Td>{moment(_data.created_at).format("lll")}</Td>
                                            <Td>{_data.temp || "-"}ºC</Td>
                                            <Td>{_data.humidity || "-"}%</Td>
                                            <Td>
                                                <b>Índice:</b> {_data.precip_rate || "-"}mm
                                                <br />
                                                <b>Acumulado:</b> {_data.precip_total || "-"}mm
                                            </Td>
                                            <Td>
                                                <b>Velocidade:</b> {_data.wind_speed || "-"}km/h
                                                <br />
                                                <b>Direção:</b> {_data.wind_dir || "-"}º (
                                                {degreesToCardinal(currData.wind_dir)})
                                                <br />
                                                <b>Rajada:</b> {_data.wind_gust || "-"}km/h
                                            </Td>
                                            <Td>
                                                <b>UV:</b> {currData.uv || "-"} <br /> <b>Radiação:</b>{" "}
                                                {currData.solar_radiation || "-"}
                                            </Td>
                                            <Td>{_data.pressure || "-"}</Td>
                                        </Tr>
                                    ))
                                ) : (
                                    <>
                                        <Tr>
                                            <Td colSpan={99}>
                                                <Skeleton w={"full"} h="20px" />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colSpan={99}>
                                                <Skeleton w={"full"} h="20px" />
                                            </Td>
                                        </Tr>
                                        <Tr>
                                            <Td colSpan={99}>
                                                <Skeleton w={"full"} h="20px" />
                                            </Td>
                                        </Tr>
                                    </>
                                )}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    );
}

function calculateDewPoint(temperature, humidity) {
    var a = 17.27;
    var b = 237.3;
    var gamma = (a * temperature) / (b + temperature) + Math.log(humidity / 100);

    var dewPoint = (b * gamma) / (a - gamma);

    return dewPoint;
}

function calculatePulv(currData) {
    let temp = parseFloat(currData.temp || 0);
    let humidity = parseFloat(currData.humidity || 0);
    let wind = parseFloat(currData.wind_speed || 0);

    let dewPoint = currData.dew_point ? parseFloat(currData.dew_point) : calculateDewPoint(temp, humidity);


    let deltaT = temp - dewPoint;
    let deltaTStatus = "good";

    if (deltaT >= 3 && deltaT <= 8) {
        deltaTStatus = "good";
    } else if (
        (deltaT >= 0 && deltaT < 3)
        ||
        (deltaT > 8 && deltaT <= 10)
    ) {
        deltaTStatus = "ok";
    } else {
        deltaTStatus = "bad";
    }

    let windStatus = "good";
    if (wind >= 2 && wind <= 12) {
        windStatus = "good";
    } else if (wind > 12 && wind <= 15) {
        windStatus = "ok";
    } else {
        windStatus = "bad";
    }

    let status = "good";
    switch (deltaTStatus) {
        case "good":
            switch (windStatus) {
                case "good":
                    status = "good";
                    break;
                case "ok":
                    status = "ok";
                    break;
                case "bad":
                    status = "bad";
                    break;
            }
            break;
        case "ok":
            switch (windStatus) {
                case "good":
                case "ok":
                    status = "ok";
                    break;
                case "bad":
                    status = "bad";
                    break;
            }
            break;
        case "bad":
            status = "bad";
            break;
    }

    return {
        deltaT,
        deltaTStatus,
        wind,
        windStatus,
        status
    }
}

function WeatherDataCard({ icon, color, text, data }) {
    let borderColor = useColorModeValue("gray.100", "gray.800");

    return (
        <Box
            w={{ base: "full", md: "50%", lg: "25%", xl: "20%", "2xl": "10%" }}
            p={2}
        >
            <Flex
                gap={1}
                justify={"space-between"}
                align={"center"}
                border={"1px solid"}
                borderColor={borderColor}
                rounded="md"
                shadow="sm"
            >
                <Box py={4} pl={4}>
                    <Box
                        bg={"gray.100"}
                        p={3}
                        rounded="full"
                        color={color}
                        fontSize={"2xl"}
                    >
                        {icon}
                    </Box>
                </Box>
                <Box px={4} w="full" textAlign={"right"}>
                    <Text fontSize={"xs"} opacity={0.7}>
                        {text}
                    </Text>
                    <Box>{data}</Box>
                </Box>
            </Flex>
        </Box>
    );
}
