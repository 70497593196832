import { Box, Button, Center, Divider, Flex, GridItem, SimpleGrid, Skeleton, Text, useDisclosure } from "@chakra-ui/react";
import StationsApi from "Api/StationsApi";
import { Separator } from "components/Separator/Separator";
import Favorites from "Helpers/Favorites";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useState } from "react";
import { FaSortAlphaDown, FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";
import Ranking from "./Components/Ranking";
import RankingRain from "./Components/RankingRain";
// import StationModal from "./Components/StationModal";
import StationsMap from "./Components/StationsMap";
import Widget from "./Components/Widget";
import Footer from "components/Footer/Footer";

export default function Estacoes() {
    const loading = useLoading();
    const [rankings, setRankings] = useState({});
    const [isLoadingStations, setIsLoadingStations] = useState(true);
    const [stations, setStations] = useState([]);
    // const [currentStation, setCurrentStation] = useState(null);
    const [order, setOrder] = useState('temp_asc');
    const [favorites, setFavorites] = useState(Favorites.get());
    // const { isOpen: isStationModalOpen, onOpen: onStationModalOpen, onClose: onStationModalClose } = useDisclosure();

    useEffect( () => {
        loading.hide();
        loadStations();
        loadRankings();
    }, []);

    useEffect(() => {
        var channel = window.pusher.subscribe('stations');
        channel.bind('stations-update', function() {
            loadStations(false);
        });

        return () => {
            window.pusher.unsubscribe('stations');
        }
    }, []);

    async function loadStations(showLoading = true) {
        showLoading && setIsLoadingStations(true);
        let data = await StationsApi.homepage();
        setStations(data.json || []);
        showLoading && setIsLoadingStations(false);
    }

    async function loadRankings() {
        let data = await StationsApi.ranking();
        setRankings(data.json.data || []);
    }

    const toggleFavorite = (station) => {
        const newFavorites = favorites.includes(station.identifier)
            ? favorites.filter((f) => f !== station.identifier)
            : [...favorites, station.identifier];
        setFavorites(newFavorites);
    }

    useEffect( () => {
        Favorites.set(favorites);
    }, [favorites]);

    function getFavorites() {
        return orderStations((stations || []).filter( station => {
            return favorites.includes(station.identifier) && station.last_data && station.last_data.temp;
        }));
    }

    function getNonFavorites() {
        return orderStations((stations || []).filter( station => {
            return !favorites.includes(station.identifier) && station.last_data && station.last_data.temp;
        }));
    }

    // function openStationModal(station) {
    //     setCurrentStation(station);
    //     onStationModalOpen();
    // }

    function orderStations(stations) {
        if (order === 'temp_asc' || order === 'temp_desc') {
            stations.sort((stationA, stationB) => {
                if (!stationA.last_data || !stationB.last_data)
                    return 9999;

                return stationA.last_data.temp - stationB.last_data.temp;
            });
            if (order === 'temp_desc') {
                stations.reverse();
            }
            return stations;
        } else {
            return stations.sort((a, b) => a.city.localeCompare(b.city));
        }
    }

    return (
        <>
            <Flex justifyContent={"center"} direction={{ sm: "column", lg: "row"}}>
                <Button leftIcon={<FaSortAmountDownAlt/>} onClick={() => setOrder('temp_asc')} colorScheme={order == 'temp_asc' ? 'blue' : 'gray'}>
                    Temperatura | Menor &gt; Maior
                </Button>
                <Button leftIcon={<FaSortAmountDown/>} onClick={() => setOrder('temp_desc')} colorScheme={order == 'temp_desc' ? 'blue' : 'gray'} my={{sm: 2, lg: 0}} mx={{sm: 0, lg: 2}}>
                    Temperatura | Maior &gt; Menor
                </Button>
                <Button leftIcon={<FaSortAlphaDown/>} onClick={() => setOrder('city_name')} colorScheme={order == 'city_name' ? 'blue' : 'gray'}>
                    Nome da Cidade
                </Button>
            </Flex>

            {favorites?.length ? (
                <SimpleGrid columns={{sm: 1, md: 2, lg: 4, xl: 6, '2xl': 8}} spacing='10px' p="10px" w="100%">
                    <GridItem colSpan={{sm: 1, md: 2, lg: 4, xl: 6, '2xl': 8}} justifyContent={"center"}>
                        <Text fontSize={"2xl"} fontWeight={"semibold"}>Favoritos</Text>
                    </GridItem>
                    {getFavorites().map( station => {
                        return (
                            <GridItem key={station.identifier}>
                                <Widget station={station} isFavorite={true} toggleFavorite={toggleFavorite}/>
                            </GridItem>
                        )
                    })}
                </SimpleGrid>
            ) : ""}
            { getNonFavorites().length ? (
                <SimpleGrid columns={{sm: 1, md: 2, lg: 4, xl: 6, '2xl': 8}} spacing='10px' p="10px" w="100%">
                    {favorites.length ? (
                        <GridItem colSpan={{sm: 1, md: 2, lg: 4, xl: 6, '2xl': 8}} justifyContent={"center"}>
                            <Separator/>
                            <Text fontSize={"2xl"} fontWeight={"semibold"}>Outras Estações</Text>
                        </GridItem>
                    ) : ""}
                    {
                        getNonFavorites().map(station => (
                            <Box key={station.identifier}>
                                <Widget station={station} isFavorite={favorites.indexOf(station.identifier) != -1} toggleFavorite={toggleFavorite}/>
                            </Box>
                        ))
                    }
                </SimpleGrid>
            ) : ""}


            { isLoadingStations ? (
                <SimpleGrid columns={{sm: 1, md: 2, lg: 4, xl: 6, '2xl': 8}} spacing='10px' p="10px" w="100%">
                    {[...Array(8).keys()].map(i => (
                        <Skeleton key={i} height={370} borderRadius={20}/>
                    ))}
                </SimpleGrid>
            ): null}

            <Separator my={5}/>

            <Center>
                <Text fontSize={"2xl"} fontWeight={"semibold"}>Ranking Diário - {(new Date).toLocaleDateString('pt-BR')}</Text>
            </Center>
            <SimpleGrid columns={{ sm: 1, md: 2}} gap={4} mb={2}>
                <GridItem>
                    <Center>
                        <Text fontSize={"lg"} fontWeight={"semibold"}>Mínimas</Text>
                    </Center>
                    <Ranking stations={rankings?.day_min}/>
                </GridItem>
                <GridItem>
                    <Center>
                        <Text fontSize={"lg"} fontWeight={"semibold"}>Máximas</Text>
                    </Center>
                    <Ranking stations={rankings?.day_max}/>
                </GridItem>
            </SimpleGrid>

            <Center mt={10}>
                <Text fontSize={"2xl"} fontWeight={"semibold"}>Ranking Anual - {(new Date).getFullYear()}</Text>
            </Center>
            <SimpleGrid columns={{ sm: 1, md: 2}} gap={4} mb={2}>
                <GridItem>
                    <Center>
                        <Text fontSize={"lg"} fontWeight={"semibold"}>Mínimas</Text>
                    </Center>
                    <Ranking stations={rankings?.year_min}/>
                </GridItem>
                <GridItem>
                    <Center>
                        <Text fontSize={"lg"} fontWeight={"semibold"}>Máximas</Text>
                    </Center>
                    <Ranking stations={rankings?.year_max}/>
                </GridItem>
            </SimpleGrid>

            <Separator my={5}/>

            <SimpleGrid columns={1} gap={4}>
                {/* <GridItem colSpan={{ sm: 12, lg: 6}}>
                    <Center>
                        <Text fontSize={"2xl"} fontWeight={"semibold"}>Localização das Estações</Text>
                    </Center>
                    <StationsMap stations={stations}/>
                </GridItem> */}
                <GridItem colSpan={{ sm: 12, lg: 6}}>
                    <Center>
                        <Text fontSize={"2xl"} fontWeight={"semibold"}>Ranking Chuva - Diário</Text>
                    </Center>
                    <RankingRain stations={rankings?.rain}/>
                </GridItem>
            </SimpleGrid>


            <Divider my={4} />

            <Footer />

            {/* <StationModal isOpen={isStationModalOpen} station={currentStation} onClose={onStationModalClose}/> */}
        </>
    );
}
