import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";
import Storage from "Helpers/Storage";
import { useAuth } from "Providers/AuthProvider";
import { useEffect, useState } from "react";

function SubscriptionAlert() {
    const user = useAuth().getUser();
    const [isOpen, setIsOpen] = useState(false);
    const onClose = () => setIsOpen(false);

    useEffect(() => {
        if (user.membership_until_in_days <= 7) {
            let current_alert_time = Storage.get('subscription_alert');
            if (!current_alert_time || new Date(current_alert_time) < new Date ) {
                let new_alert = new Date;
                new_alert.setHours( new_alert.getHours() + 12 );
                Storage.set('subscription_alert', new_alert);
                setIsOpen(true);
            }
        } else {
            Storage.remove('subscription_alert');
        }
    }, []);

    function alertRenew() {
        onClose();
        window.location.href = "/members/account";
    }

    return (
        <>
            {
                <AlertDialog isOpen={isOpen} onClose={onClose}>
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Sua assinatura { user.membership_until_in_days <= 0 ? "expirou!" : "está prestes a expirar!"}
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                { user.membership_until_in_days <= 0 ?
                                    <>
                                        Olá Membro, sua assinatura assinatura expirou!<br/><br/>
                                        Para continuar utilizando o sistema, clique no botão abaixo e renove sua assinatura.
                                    </>
                                    :
                                    <>
                                        Olá Membro, sua assinatura está prestes a expirar! Falta(m) apenas {user.membership_until_in_days} dia(s).<br/><br/>
                                        Para renovar sua assinatura, clique no botão abaixo!
                                    </>
                                }
                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button colorScheme={"whatsapp"} onClick={alertRenew} mx={2}>
                                    Quero Renovar
                                </Button>
                                <Button colorScheme={"red"} onClick={onClose}>
                                    Outra hora...
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>
            }
        </>
    );
}

export default SubscriptionAlert;
