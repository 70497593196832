import { Alert, AlertIcon, Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { useAuth } from "Providers/AuthProvider";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect } from "react";
import { FaClock, FaCloudRain, FaLock, FaReceipt, FaTable } from "react-icons/fa";
import { RiBaseStationLine } from "react-icons/ri";
import { Link } from "react-router-dom";

function MemberHome() {
    const loading = useLoading();
    const user = useAuth().getUser();

    useEffect( () => {
        loading.hide();
    });

    return (
        <Box>
            {
                (user.membership_until_in_days <= 7) && (
                    <Alert status='warning' mb={4} borderRadius={10}>
                        <AlertIcon />
                            { user.membership_until_in_days <= 0 ?
                                    <>
                                        Sua assinatura expirou!
                                    </>
                                    :
                                    <>
                                        Sua assinatura irá expirar em {user.membership_until_in_days} dia(s)!
                                    </>
                            }
                            {" "}
                            Para continuar utilizando, renove sua assinatura.
                            <Link to="/members/account">
                                <Text color="blue.400" as="span" ms="5px" fontWeight="bold">
                                    Clique aqui
                                </Text>
                            </Link>
                    </Alert>
                )
            }
            <SimpleGrid w="100%" columns={{ sm: 1, lg: 4 }} gap={4}>
                <Link to={!user.membership ? "/members/account" : "/members/reports/cold"}>
                    <Box p={10} bgColor={"teal.300"} borderRadius={10} color="white" opacity={!user.membership ? .25 : 1}>
                        <Flex justifyContent={"center"} w="100%">
                            {!user.membership ? <FaLock fontSize={"3em"}/> : <FaClock fontSize={"3em"}/>}
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Horas Frio</Text>
                    </Box>
                </Link>
                <Link to={!user.membership ? "/members/account" : "/members/reports/data/month"}>
                    <Box p={10} bgColor={"teal.300"} borderRadius={10} color="white" opacity={!user.membership ? .25 : 1}>
                        <Flex justifyContent={"center"} w="100%">
                            {!user.membership ? <FaLock fontSize={"3em"}/> : <FaTable fontSize={"3em"}/>}
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Relatório Mensal</Text>
                    </Box>
                </Link>
                <Link to={!user.membership ? "/members/account" : "/members/reports/data/year"}>
                    <Box p={10} bgColor={"teal.300"} borderRadius={10} color="white" opacity={!user.membership ? .25 : 1}>
                        <Flex justifyContent={"center"} w="100%">
                            {!user.membership ? <FaLock fontSize={"3em"}/> : <FaTable fontSize={"3em"}/>}
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Relatório Anual</Text>
                    </Box>
                </Link>
                <Link to={!user.membership ? "/members/account" : "/members/reports/rain/year"}>
                    <Box p={10} bgColor={"teal.300"} borderRadius={10} color="white" opacity={!user.membership ? .25 : 1}>
                        <Flex justifyContent={"center"} w="100%">
                            {!user.membership ? <FaLock fontSize={"3em"}/> : <FaCloudRain fontSize={"3em"}/>}
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Chuva Mensal</Text>
                    </Box>
                </Link>
                <Link to="/members/account">
                    <Box p={10} bgColor={"blue.400"} borderRadius={10} color="white">
                        <Flex justifyContent={"center"} w="100%">
                            <FaReceipt fontSize={"3em"}/>
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Minha Conta</Text>
                    </Box>
                </Link>
                <Link to="/">
                    <Box p={10} bgColor={"green.400"} borderRadius={10} color="white">
                        <Flex justifyContent={"center"} w="100%">
                            <RiBaseStationLine fontSize={"3em"}/>
                        </Flex>
                        <Text whiteSpace={"nowrap"} w="100%" textAlign={"center"} mt={2} fontSize={"xl"} fontWeight={"semibold"}>Estações</Text>
                    </Box>
                </Link>
            </SimpleGrid>
        </Box>
    );
}

export default MemberHome;
