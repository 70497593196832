import { Flex, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import colorGradient from "javascript-color-gradient";

function Ranking({ stations = [] }) {

    function getBgColor(temp)
    {
        if (isNaN(temp))
            return;
        temp = Math.round(temp);

        if (temp <= 0)
            temp = 1;
        if (temp >= 40)
            temp = 40;

        return colorGradient
            .setGradient("#00b0f0", "#FDEB84", "#F24044")
            .setMidpoint(40)
            .getColor(parseInt(temp));
    }

    return (
        <Table size="sm">
            <Thead>
                <Tr>
                    <Th>Estação</Th>
                    <Th>Momento</Th>
                    <Th>Temperatura</Th>
                </Tr>
            </Thead>
            <Tbody>
                {(stations?.length &&
                    stations?.map(station => (
                        <Tr key={station.identifier}>
                            <Td>
                                <Flex direction="column">
                                    <Text>{station.city}/{station.state}</Text>
                                    <Text fontSize={"sm"} color="gray.500">{station.provider}</Text>
                                </Flex>
                            </Td>
                            <Td>{station.moment}</Td>
                            <Td bgColor={getBgColor(station.temp)} color={"gray.700"}>{station.temp}º</Td>
                        </Tr>
                    ))
                ) || (
                    [...Array(10).keys()].map(i => (
                    <Tr key={i}>
                        <Td>
                            <Skeleton h={5}/>
                        </Td>
                        <Td>
                            <Skeleton h={5}/>
                        </Td>
                        <Td>
                            <Skeleton h={5}/>
                        </Td>
                    </Tr>
                )))}
            </Tbody>
        </Table>
    );

}

export default Ranking;
