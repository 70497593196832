
import StationsMap from "views/Public/Components/StationsMap";

function SingleMarkerMap({point, text}) {
    return (
        <StationsMap stations={[{lat: point[0], long: point[1], identifier: 'single-marker'}]} text={text} center={point} zoom={10}/>
    )
}

export default SingleMarkerMap;
