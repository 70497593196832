import { Box, Button, Flex, FormControl, FormLabel, GridItem, Input, Select, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import ReportsApi from "Api/ReportsApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
// import colorGradient from "javascript-color-gradient";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import StationsApi from "Api/StationsApi";
import { FaSearch } from "react-icons/fa";
import Helper from "Helpers/Helper";
import PrintMode from "components/Buttons/PrintMode";
import Favorites from "Helpers/Favorites";
import moment from "moment";

function ColdReport() {
    const [data, setData] = useState(null);
    const [_type, setType] = useState(null);
    const loading = useLoading();

    async function loadData(station_id, type, from, to, min_temp, max_temp) {
        loading.show();
        setData([]);
        setType(type);
        let response = await ReportsApi.cold(station_id, type, from, to, min_temp, max_temp);
        setData(response.json);
        loading.hide();
    }

    return (
        <Card overflowX="auto">
            <CardBody>
                <Flex direction="column" w="100%">
                    <Box mb={4}>
                        <FormFilter loadData={loadData} />
                    </Box>

                    {data?.data != null && _type == "custom" && <DayDataTable data={data?.data} />}
                    {data?.data != null && _type !== "custom" && <DataTable data={data?.data} type={_type} />}

                    {data?.data == null && (
                        <Flex w="100%" justifyContent={"center"} mt={4}>
                            <Text opacity={.5}>Nenhum dado para exibir</Text>
                        </Flex>
                    )}

                    {
                        data?.provider == 'INMET' &&
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    }
                    <PrintMode />
                </Flex>
            </CardBody>
        </Card>
    );
}

function FormFilter({ loadData }) {
    const [stations, setStations] = useState([]);
    const loading = useLoading();
    const favorites = Favorites.get();


    useEffect(() => {
        loadStationOptions();
    }, []);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json);
        loading.hide();
    }

    async function submitHandle(values) {
        loadData(values.station_id, values.type, values.from || null, values.to || null, values.min_temp, values.max_temp);
    }

    return (
        <Formik onSubmit={submitHandle} initialValues={{ station_id: null, type: 'month', from: null, to: null, min_temp: null, max_temp: null }} enableReinitialize={true}>
            {props => <>
                <Form id="reportFilterForm">
                    <Flex flexDirection="row" flexWrap w="100%">
                        <SimpleGrid w="100%" columns={{ sm: 1, lg: 12 }} gap={4}>
                            <GridItem colSpan={{ base: 12, lg: 8 }}>
                                <Field name="station_id">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="station_id" fontWeight="normal">Estação:</FormLabel>
                                            <Select id="station_id" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione a Estação...">
                                                {
                                                    !favorites || !favorites.length ?
                                                        (
                                                            stations.map((station, k) => (
                                                                <option key={k} value={station.id}>{station.text}</option>
                                                            ))
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <optgroup key="favorites" label="Estações Favoritas">
                                                                    {stations.map((station, k) => {
                                                                        if (favorites.includes(station.identifier))
                                                                            return (<option key={k} value={station.id}>{station.text}</option>);
                                                                        else
                                                                            return "";
                                                                    })}
                                                                </optgroup>
                                                                <optgroup key="others" label="Outras Estações">
                                                                    {stations.map((station, k) => {
                                                                        if (!favorites.includes(station.identifier))
                                                                            return (<option key={k} value={station.id}>{station.text}</option>);
                                                                        else
                                                                            return "";
                                                                    })}
                                                                </optgroup>
                                                            </>
                                                        )
                                                }
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ base: 12, lg: 4 }}>
                                <Field name="type">
                                    {({ field }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="type" fontWeight="normal">Tipo de Período:</FormLabel>
                                            <Select id="type" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                <option value="month">Mês</option>
                                                <option value="year">Ano</option>
                                                <option value="custom">Customizado</option>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            {
                                props.values.type == "custom" && <>
                                    <GridItem colSpan={{ sm: 1, lg: 3 }}>
                                        <Field name="from">
                                            {({ field, form }) => (
                                                <FormControl isRequired>
                                                    <FormLabel mb="0" htmlFor="from" fontWeight="normal">A partir de:</FormLabel>
                                                    <Input id="from" {...field} borderRadius="10px" fontSize="md" type="date" />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 1, lg: 3 }}>
                                        <Field name="to">
                                            {({ field, form }) => (
                                                <FormControl isRequired>
                                                    <FormLabel mb="0" htmlFor="to" fontWeight="normal">até:</FormLabel>
                                                    <Input id="to" {...field} borderRadius="10px" fontSize="md" type="date" />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                </>
                            }

                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Field name="min_temp">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="min_temp" fontWeight="normal">Acima de º:</FormLabel>
                                            <Input id="min_temp" {...field} borderRadius="10px" fontSize="md" type="number" step=".1" placeholder="1.5" />
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Field name="max_temp">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="max_temp" fontWeight="normal">Abaixo de º:</FormLabel>
                                            <Input id="max_temp" {...field} borderRadius="10px" fontSize="md" type="number" step=".1" placeholder="10" />
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Button w="100%" type='submit' form='reportFilterForm' colorScheme="blue" mt={{ lg: 6 }} leftIcon={<FaSearch />}>
                                    Buscar
                                </Button>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Form>
            </>}
        </Formik>
    );
}

function DayDataTable({ data }) {
    let total = data.reduce((a, b) => a + b.hours, 0);

    return (
        <>
            <Table size="xs" mt={4} variant="striped" colorScheme={"blue"}>
                <Thead>
                    <Tr>
                        <Th textAlign={"center"} fontSize={"sm"}>Data</Th>
                        <Th textAlign={"left"} fontSize={"sm"}>Horas</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((d, k) => (
                        <Tr key={k}>
                            <Td textAlign={"center"} fontWeight={"semibold"} fontSize={"sm"}>{moment(d.date).format('ll')}</Td>
                            <Td textAlign={"left"}>
                                {d.hours ? (
                                    <>
                                        {d.hours}h
                                        <br /><Text fontSize={"xs"} opacity={.5}>{(d.hours / 24).toFixed(1)} dia(s)</Text>
                                    </>
                                ) : <Text fontSize={"xs"} opacity={.25}>*</Text>}
                            </Td>
                        </Tr>
                    ))}
                    <Tr>
                        <Td colSpan={2}></Td>
                    </Tr>
                    <Tr>
                        <Td textAlign={"center"} fontWeight={"semibold"} fontSize={"sm"}>Total</Td>
                        <Td fontWeight={"semibold"}>{total ? (
                            <>
                                {total}h
                                <br /><Text fontSize={"xs"} opacity={.5}>{(total / 24).toFixed(1)} dia(s)</Text>
                            </>
                        ) : <Text fontSize={"xs"} opacity={.25}>*</Text>}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </>
    );
}

function DataTable({ data, type }) {
    let years = Object.keys(data);

    return (
        <>
            <Table size="xs" mt={4} variant="striped" colorScheme={"blue"}>
                <Thead>
                    <Tr>
                        <Th textAlign={"center"}>{type == 'year' ? 'Ano' : ''}</Th>
                        {type == 'month' && (
                            <>
                                <Th textAlign={"center"} fontSize={"sm"}>Jan.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Fev.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Mar.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Abr.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Mai.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Jun.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Jul.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Ago.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Set.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Out.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Nov.</Th>
                                <Th textAlign={"center"} fontSize={"sm"}>Dez.</Th>
                            </>
                        )
                            ||
                            type == 'year' && (
                                <Th>Horas Frio</Th>
                            )}

                    </Tr>
                </Thead>
                <Tbody>
                    {years.map(year => (
                        <Tr key={year}>
                            <Td textAlign={"center"} fontWeight={"semibold"} fontSize={"sm"}>{year}</Td>
                            {
                                type == 'month' && (
                                    [...Array(12).keys()].map(i => {
                                        let month = Helper.lpad((i + 1).toString(), 2, '0');
                                        let hours = data[year][month];
                                        return (
                                            <Td textAlign={"center"} key={i + 1}>
                                                {hours}{hours ? (
                                                    <>
                                                        h
                                                        <br /><Text fontSize={"xs"} opacity={.5}>{(hours / 24).toFixed(1)} dia(s)</Text>
                                                    </>
                                                ) : <Text fontSize={"xs"} opacity={.25}>*</Text>}
                                            </Td>
                                        );
                                    })
                                )
                                ||
                                type == 'year' && (
                                    <Td textAlign={"left"}>
                                        {data[year]}{data[year] ? (
                                            <>
                                                h
                                                <br /><Text fontSize={"xs"} opacity={.5}>{(data[year] / 24).toFixed(1)} dia(s)</Text>
                                            </>
                                        ) : <Text fontSize={"xs"} opacity={.25}>*</Text>}
                                    </Td>
                                )
                            }
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}

export default ColdReport;
