import React, { useState } from "react";
// Chakra imports
import {
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import AuthApi from "Api/AuthApi";

function Recover() {
    const loading = useLoading();
    const [mailSent, setMailSent] = useState(false);
    const toast = useToast({
        position: "top-right",
    });

    let submitHandle = async (values, { setFieldError }) => {
        loading.show();
        let response = await AuthApi.recover(values);
        if (response.status !== 200) {
            if (response.status === 422) {
                setFieldError('email', 'E-mail não encontrado!');
            } else {
                toast({ status: 'error', title: 'Erro ao redefinir senha!', duration: 3000, variant: 'solid', isClosable: true });
            }
            loading.hide();
        } else {
            setMailSent(true);
            loading.hide();
        }
    }

    // Chakra color mode
    const titleColor = useColorModeValue("blue.300", "blue.200");
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <>
            {mailSent ?
                (
                    <Flex w="100%" justifyContent={"center"} my={4}>
                        <Alert
                            status='success'
                            variant='subtle'
                            flexDirection='column'
                            alignItems='center'
                            justifyContent='center'
                            textAlign='center'
                            height='200px'
                            w="auto"
                            borderRadius={10}
                        >
                            <AlertIcon boxSize='40px' mr={0} />
                            <AlertTitle mt={4} mb={1} fontSize='lg'>
                                Recuperação de senha enviada!
                            </AlertTitle>
                            <AlertDescription maxWidth='sm'>
                                Você receberá um e-mail em poucos intantes!<br/>
                                Clique no botão dentro dele para alterar sua senha!
                            </AlertDescription>
                        </Alert>
                    </Flex>
                )
                :
                (
                    <Flex position="relative">
                        <Flex w="100%" mx="auto" justifyContent="center">
                            <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
                                <Flex direction="column" w="100%" background="transparent" p="24px">
                                    <Heading color={titleColor} fontSize="32px" textAlign={"center"}>
                                        Recuperação de Senha
                                    </Heading>
                                    <Text mb="15px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px" textAlign={"center"}>
                                        Digite seu e-mail e receba um link para redefinir sua senha.
                                    </Text>
                                    <Formik onSubmit={submitHandle} initialValues={{ email: "", password: "", remember: false }}>
                                        <Form>
                                            <Field name="email">
                                                {({ field, form }) => (
                                                    <FormControl isInvalid={form.errors.email && form.touched.email}>
                                                        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                            E-mail
                                                        </FormLabel>
                                                        <Input {...field} borderRadius="10px" fontSize="sm" type="text" placeholder="Seu e-mail" size="lg"/>
                                                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                    </FormControl>
                                                )}
                                            </Field>
                                            <Button type="submit" colorScheme={"blue"} w="100%" h="45" mb={2} mt="20px">
                                                Enviar redefinição de senha
                                            </Button>
                                        </Form>
                                    </Formik>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                )
            }
        </>
    );
}

export default Recover;
