import Client from './Client';

export const ReportsApi = {
    year: async (id, year) => {
        return await Client.get(`/v1/stations/${id}/report/year/${year}`);
    },
    cold: async (id, type, from, to, min_temp, max_temp) => {
        return await Client.post(`/v1/stations/${id}/report/cold`, {
            type,
            from,
            to,
            min_temp,
            max_temp
        });
    },
    month: async (id, month) => {
        return await Client.get(`/v1/stations/${id}/report/month/${month}`);
    },
    rain: async (id, month) => {
        return await Client.get(`/v1/stations/${id}/report/rain/${month}`);
    },
};
export default ReportsApi;
