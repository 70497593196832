
const Cookie = {
    get: (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ')
                c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0)
                    return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    set: (name, value) => {
        var date = new Date();
        date.setDate(date.getDate() + 1000);
        let expires = "; expires=" + date.toUTCString();
        let cookie = name + "=" + (value || "")  + expires + "; path=/";
        document.cookie = cookie;
    },

    remove: (name) => {
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
};

export default Cookie;
