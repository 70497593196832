import { Box, Spinner } from '@chakra-ui/react';
import React from 'react';

const Loading = () => {
    return (
        <Box d="flex" top="0" bottom="0" left="0" right="0" position="fixed" alignItems="center" justifyContent="center" zIndex={999999999}>
            <Box h="100%" w="100%" zIndex="40" opacity="75%" bgColor="#333"></Box>
            <Box d="flex" position="fixed" alignItems="center" justifyContent="center" zIndex={9999999999}>
                <Spinner h="50px" w="50px" thickness='4px' color='white'/>
            </Box>
        </Box>
    )
}

export default Loading;