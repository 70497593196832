import { Flex, Text } from "@chakra-ui/react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

function StationsMap({stations, center = [ -10.183056, -48.333611 ], zoom = 3, text = null, height = '350px'}) {
    return (
        <>
            <MapContainer center={center} zoom={zoom} style={{ height: height, width: "100%", borderRadius: "15px !important", zIndex: 0 }}>
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"/>
                <TileLayer url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}" opacity={.75}/>
                {(stations || []).map(station => (
                    <Marker key={"marker_" + station.identifier} position={[ station.lat, station.long ]}>
                        <Popup>
                            { text ? text : (
                                <Flex justifyContent={"center"} w="100%" direction={"column"}>
                                    <Text textAlign={"center"} fontWeight={"semibold"} m={"0 !important"}>{station.full_name ? station.full_name : `${station.city} / ${station.state} / ${station.provider}` }</Text>
                                </Flex>
                            )}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
            </>
    )
}

export default StationsMap;
