import { Box, Flex, Img, Text } from '@chakra-ui/react';
import { createContext, useContext, useState } from 'react';
import Loading from '../components/UI/Loading';
import WsClimaLogo from '../assets/img/wsclima.png' ;
import InmetLogo from '../assets/img/inmet.webp' ;

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);

    const show = () => {
        setLoading(true);
        document.body.style.overflowY = "hidden";
    }

    const hide = () => {
        setLoading(false);
        document.body.style.overflowY = "auto";
    }

    return (
        <LoadingContext.Provider value={{ hide, show, loading }}>
            {children}
            { loading ? <Loading /> : '' }
            <Box className="partnership">
                <Flex align="center" direction={"column"}>
                    <Text fontSize={"sm"}>Em parceria</Text>
                    <Box>
                        <Flex direction={"row"} align="center">
                            <Img w={{base: "40px", md: "50px"}} h="auto" src={WsClimaLogo} />
                            <Img w={{base: "40px", md: "50px"}} h="auto" src={InmetLogo} ml={2} />
                        </Flex>
                    </Box>
                </Flex>

            </Box>
        </LoadingContext.Provider>
    )
}

export const useLoading = () => useContext(LoadingContext)
