import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Badge, Box, Button, Divider, Flex, Grid, GridItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Tag, Text, useDisclosure, useToast } from "@chakra-ui/react";
import AuthApi from "Api/AuthApi";
import BillingApi from "Api/BillingApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Storage from "Helpers/Storage";
import { useAuth } from "Providers/AuthProvider";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useState } from "react";
import { FaCalendarPlus, FaCartPlus } from "react-icons/fa";
import { Pagination } from "react-laravel-paginex";
import Transaction from "./Components/Transaction";

function Account() {
    const user = useAuth().getUser();
    const [page, setPage] = useState(1);
    const loading = useLoading();
    const [data, setData] = useState({});
    const membershipModal = useDisclosure();

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : ( page || 1 );
        setPage(currentPage);
        let response = await BillingApi.transactions(currentPage);
        if (response.status === 200) {
            setData(response.json);
        }
        loading.hide();
    }

    async function loadUserData() {
        let user = await AuthApi.me();
        Storage.set('user', user.json.data);
    }

    useEffect( () => {
        getData({page: 1})
    }, []);

    useEffect(() => {
        var channel = window.pusher.subscribe('user_' + user.id);
        channel.bind('transaction-update', async function() {
            loading.show();
            await loadUserData();
            await getData();
            loading.hide();
        });

        return () => {
            window.pusher.unsubscribe('user_' + user.id);
        }
    }, []);

    return (
        <>
            <Card>
                <CardBody>
                    <SimpleGrid columns={{ sm: 1, lg: 5}} w="100%">
                        <GridItem colSpan={{ sm: 1, lg: 2 }}>
                            <Text fontSize="lg" fontWeight="bold" mb={2}>
                                Minha Conta
                            </Text>
                            <Flex>
                                <Text fontWeight={"semibold"} mr={2}>Nome:</Text> {user.name}
                            </Flex>
                            <Flex>
                                <Text fontWeight={"semibold"} mr={2}>E-mail:</Text> {user.email}
                            </Flex>
                            <Flex>
                                <Text fontWeight={"semibold"} mr={2}>Telefone:</Text> {user.phone}
                            </Flex>
                            <Flex>
                                <Text fontWeight={"semibold"} mr={2}>Cidade:</Text> {user.city}/{user.state}
                            </Flex>
                            <Flex>
                                <Text fontWeight={"semibold"} mr={2}>Desde:</Text> {(new Date(user.created_at)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}
                            </Flex>
                        </GridItem>

                        <GridItem colSpan={{ sm: 1, lg: 3 }} mt={{ sm: 5, lg: 0 }}>
                            <Flex w="100%" justifyContent={"space-between"} alignItems={"center"}>
                                <Text fontSize="lg" fontWeight="bold" mb={2}>
                                    Assinatura
                                </Text>
                                <Tag colorScheme={user.membership ? 'green' : 'red'} fontWeight={"semibold"}>Assinatura {user.membership ? 'Ativa' : 'Suspensa'}</Tag>
                            </Flex>

                            <Flex w="100%" justifyContent={{sm: "center", lg: "space-between"}} flexDirection={{ sm: "column", lg: "row"}} alignItems={"center"}>
                                <Box>
                                    <Text fontWeight={"semibold"}>Válida até: {(new Date(user.membership_until)).toLocaleDateString('pt-BR', {timeZone: 'UTC'})}</Text>
                                    <Text textAlign={{sm: "center", lg: "left"}} fontSize={"xs"} opacity=".5">({user.membership_until_in_days > 0 ? 'Faltam ' + user.membership_until_in_days + ' dias' : 'Expirado'})</Text>
                                </Box>
                                { user.membership_until_in_days <= 7 &&
                                    <Button onClick={membershipModal.onOpen} colorScheme={"whatsapp"} leftIcon={<FaCalendarPlus/>} borderRadius={10} mt={{ sm: 3, lg: 0 }}>
                                        Renovar Assinatura
                                    </Button>
                                }
                            </Flex>

                            <Box w="100%">
                                {
                                    data.data && data.data.map((transaction, k) => (
                                        <Transaction key={k} transaction={transaction} />
                                    ))
                                }
                            </Box>

                            <Pagination changePage={getData} data={data} nextButtonText="Próxima" prevButtonText="Anterior"/>
                        </GridItem>
                    </SimpleGrid>
                </CardBody>
            </Card>
            <MembershipPricesModal isOpen={membershipModal.isOpen} onClose={membershipModal.onClose} />
        </>
    )
}

function MembershipPricesModal({ isOpen, onClose }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right",
    });
    const paymentInfoModal = useDisclosure();

    const plans = {
        month: {
            name: 'Mensal',
            price: 'R$ 19,99',
            description: '30 Dias',
        },
        quarter: {
            name: 'Trimestral',
            price: 'R$ 49,99',
            description: '90 Dias',
        },
        semiannual: {
            name: 'Semestral',
            price: 'R$ 99,99',
            description: '180 Dias',
        },
        year: {
            name: 'Anual',
            price: 'R$ 199,99',
            description: '1 Ano',
        }
    };

    async function renew(plan)
    {
        loading.show();
        let response = await BillingApi.getCheckoutUrl(plan);
        if (response.status !== 200) {
            loading.hide();
            toast({ status: 'error', title: 'Houve um problema ao obter o link de checkout!', duration: 3000, variant: 'solid', isClosable: true });
            return;
        } else {
            let checkoutUrl = response.json.data;
            window.open(checkoutUrl, '_blank');
            loading.hide();
            setTimeout(() => {
                window.gtag('event', 'conversion', {
                    label_name: "Assinatura " + plans[plan]['name'],
                    value: plans[plan]['price'].replace('R$ ', '').replace('.', '').replace(',', '.'),
                });
                paymentInfoModal.onOpen();
                onClose();
            }, 500);
        }
    }

    return <>
        <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Período da Assinatura</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={8}>
                    <SimpleGrid gap={2} w="100%" columns={{ sm: 2, md: 4 }}>
                        {
                            Object.keys(plans).map(plan => (
                                <PriceCard title={plans[plan]['name']} price={plans[plan]['price']} description={plans[plan]['description']} onClick={() => { renew(plan) }} />
                            ))
                        }
                    </SimpleGrid>
                </ModalBody>
            </ModalContent>
        </Modal>

        <AlertDialog isOpen={paymentInfoModal.isOpen} onClose={paymentInfoModal.onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Informação
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        Após efetuar o pagamento, pode levar algum tempo para que o mesmo seja compensado em nosso sistema e sua assinatura renovada.<br/><br/>
                        <b>Cartão/Pix:</b> O pagamento é processado dentro de alguns minutos/horas.<br/>
                        <b>Boleto Bancário:</b> O pagamento é processado em até 3 dias úteis.<br/>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button onClick={paymentInfoModal.onClose}>
                            Ok, entendi!
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
}

function PriceCard({ title, price, description, onClick }) {
    return <Box p={4} w="100%" rounded="lg" boxShadow={"md"} borderColor="gray.300" borderWidth={1}>
        <Flex flexDirection="column" alignItems="center">
            <Text fontSize="lg" fontWeight="semibold" mb={2}>{title}</Text>
            <Text fontSize="xl" fontWeight="bold" mb={2}>{price}</Text>
            <Text fontSize="xs" mb={2}>{description}</Text>

            <Button colorScheme={"whatsapp"} size="sm" onClick={onClick} leftIcon={<FaCartPlus/>}>Selecionar</Button>
        </Flex>
    </Box>
}

export default Account;
