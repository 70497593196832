import Client from './Client';

export const UserBHsApi = {
    list: async (user_id) => {
        return await Client.get(`/v1/users/${user_id}/bhs`);
    },
    store: async (user_id, formData) => {
        return await Client.post(`/v1/users/${user_id}/bhs`, formData);
    },
    update: async (user_id, bh_id, formData) => {
        return await Client.post(`/v1/users/${user_id}/bhs/${bh_id}`, formData);
    },
    delete: async (user_id, bh_id) => {
        return await Client.destroy(`/v1/users/${user_id}/bhs/${bh_id}`);
    },
    listAvailable: async (user_id) => {
        return await Client.get(`/v1/users/${user_id}/bhs/available`);
    },
    generate: async (user_id, bh_id) => {
        return await Client.get(`/v1/users/${user_id}/bhs/${bh_id}/generate`);
    },
    irrigation: async (user_id, bh_id, values) => {
        return await Client.post(`/v1/users/${user_id}/bhs/${bh_id}/irrigation`, values);
    },
};

export default UserBHsApi;
