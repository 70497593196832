import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {default as routes} from "member_routes.js";
import theme from "theme/theme.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import Storage from "Helpers/Storage";
import AuthApi from "Api/AuthApi";
import { useLoading } from "Providers/LoadingProvider";
import SubscriptionAlert from "views/Member/Components/SubscriptionAlert";

export default function Member(props) {
    const { ...rest } = props;
    const loading = useLoading();
    const location = useLocation();

    useEffect( () => {
        async function getUser() {
            loading.show();
            let user = await AuthApi.me();

            if (user.status === 401)
                return;

            Storage.set('user', user.json.data);
            loading.hide();
        }
        getUser();
    }, [location]);


    const getActiveRoute = (routes) => {
        let activeRoute = "";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].path) !== -1) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "account") {
                return getRoutes(prop.views);
            }
            return (
                <Route exact={true} path={prop.path} component={prop.component} key={key}/>
            );
        });
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <SubscriptionAlert/>
            <Sidebar
                routes={routes}
                logoText={"WS Consultoria Climática"}
                display="none"
                sidebarVariant='opaque'
                {...rest}
            />
            <MainPanel w={{
                base: "100%",
                xl: "calc(100% - 275px)",
            }}>
                <Portal>
                    <AdminNavbar
                        onOpen={onOpen}
                        logoText={"WS Consultoria Climática"}
                        brandText={getActiveRoute(routes)}
                        fixed={true}
                        {...rest}
                    />
                </Portal>
                <PanelContent className="main-panel-container" pt="50px">
                    <PanelContainer className="main-panel-container-content">
                        <Switch>
                            {getRoutes(routes)}
                        </Switch>
                    </PanelContainer>
                </PanelContent>
                <Footer />
            </MainPanel>
        </ChakraProvider>
    );
}
