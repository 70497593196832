import Storage from "Helpers/Storage";
import env from "react-dotenv";
import AuthHelper from "Helpers/AuthHelper";
import axios from "axios";

const Client = {
    _request: async (endPoint, method, body = {}, hasFile = false) => {
        let formData = {};
        if (hasFile) {
            formData = new FormData();
            Object.keys(body).forEach(key => formData.append(key, body[key]));
        }

        let data = {
            method: method,
            headers: hasFile ? {
                "Content-Type": 'multipart/form-data',
                "Access-Control-Allow-Origin": '*'
            } : {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": '*'
            },
            data: method != 'GET' ? (hasFile ? formData : JSON.stringify(body)) : null
        };
        if (Storage.exists('token')) {
            let valid = await AuthHelper.checkForTokenRefresh();

            if (!valid) {
                Storage.clear();
            }

            if (!valid && window.location.pathname.indexOf('members') !== -1) {
                window.location.href = "/auth";
                return {};
            }

            if (valid) {
                data.headers.Authorization = AuthHelper.getBearerToken();
            }
        }

        let url = ['localhost', '127.0.0.1'].indexOf(window.location.hostname) != -1 ? env.API_URL_DEV : env.API_URL_PROD;

        let req = await axios({
            url: `${url}${endPoint}`,
            ...data
        });

        return {
            status: req.status,
            json: req.status !== 204 ? await req.data : null
        };
    },
    get: async (endPoint) => {
        return await Client._request(endPoint, 'GET');
    },

    post: async (endPoint, body = {}) => {
        return await Client._request(endPoint, 'POST', body);
    },

    postFile: async (endPoint, body = {}) => {
        return await Client._request(endPoint, 'POST', body, true);
    },

    put: async (endPoint, body) => {
        return await Client._request(endPoint, 'PUT', body);
    },

    destroy: async (endPoint) => { // Unable to name as delete
        return await Client._request(endPoint, 'DELETE');
    }
};

export default Client;
