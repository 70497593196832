import Client from './Client';

export const NoticeApi = {
    list: async (page, filter = '') => {
        return await Client.get(`/v1/notices?page=${page}&filter=${filter}`);
    },
    store: async (formData) => {
        return await Client.post(`/v1/notices`, formData);
    },
    update: async (notice_id, formData) => {
        return await Client.post(`/v1/notices/${notice_id}`, formData);
    },
    delete: async (notice_id) => {
        return await Client.destroy(`/v1/notices/${notice_id}`);
    },
};

export default NoticeApi;
