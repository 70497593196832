import Client from './Client';

export const UsersApi = {
    list: async (page, filter = '') => {
        return await Client.get(`/v1/users?page=${page}&filter=${filter}`);
    },
    validity: async (id, values) => {
        return await Client.post(`/v1/users/${id}/validity`, values);
    },
    toggleModule: async (id, module_id) => {
        return await Client.post(`/v1/users/${id}/module/${module_id}`, {});
    },
    delete: async (id) => {
        return await Client.destroy(`/v1/users/${id}`);
    }
};

export default UsersApi;
