import { useAuth } from "Providers/AuthProvider";
import { Redirect } from "react-router-dom";

function Logout() {
    const auth = useAuth();

    auth.logout();

    return <Redirect to="/"/>
}

export default Logout;
