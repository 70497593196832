import { Badge, Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import MoneyHelper from "Helpers/MoneyHelper";
import React from "react";
import { FaMoneyBill } from "react-icons/fa";

function Transaction({ transaction }) {
    const textColor = useColorModeValue("gray.700", "white");

    function getColorScheme(status) {
        switch (status.toString()) {
            case "0":
            case "3":
            case "4":
                return "green";
            case "1":
                return "blue";
            case "5":
            case "9":
            case "2":
                return "yellow";
            default:
                return "red";
        }
    }

    return (
        <Flex my="1rem" justifyContent="space-between">
            <Flex alignItems="center">
                <Box me="12px" borderRadius="50%" color={"green.400"} border="1px solid" display="flex" alignItems="center" justifyContent="center" w="35px" h="35px">
                    <Icon as={FaMoneyBill} />
                </Box>
                <Flex direction="column">
                    <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} color={textColor} fontWeight="semibold">
                        Assinatura
                    </Text>
                    <Text fontSize={{ sm: "xs", md: "sm", lg: "xs" }} color="gray.400" fontWeight="semibold">
                        {transaction.days} Dias - {transaction.created_at}
                    </Text>
                </Flex>
            </Flex>
            <Box color={textColor} textAlign={"right"}>
                <Text fontSize={{ sm: "md", md: "lg", lg: "md" }} fontWeight="bold">
                    {MoneyHelper.format(transaction.amount)}
                </Text>
                <Text fontSize={{ sm: "xs", md: "sm", lg: "xs" }} fontWeight="semibold">
                    <Badge colorScheme={getColorScheme(transaction.status)} fontWeight="semibold">
                        {transaction.status_name}
                    </Badge>
                </Text>
            </Box>
        </Flex>
    );
}

export default Transaction;
