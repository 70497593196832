import React from "react";
// Chakra imports
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Flex,
} from "@chakra-ui/react";

function NotFound() {
    return (
        <Flex position="relative" mt={4}>
            <Flex w="100%" mx="auto" justifyContent="center">
                <Alert
                    status='error'
                    variant='subtle'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    textAlign='center'
                    height='200px'
                    w="auto"
                    borderRadius={10}
                >
                    <AlertIcon boxSize='40px' mr={0} />
                    <AlertTitle mt={4} mb={1} fontSize='lg'>
                        404 - Not Found
                    </AlertTitle>
                    <AlertDescription maxWidth='sm'>
                        A página que você estava procurando não existe!
                    </AlertDescription>
                </Alert>
            </Flex>
        </Flex>
    );
}

export default NotFound;
