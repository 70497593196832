import { Box, Button, Flex, FormControl, FormLabel, GridItem, Input, Select, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import ReportsApi from "Api/ReportsApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import StationsApi from "Api/StationsApi";
import { FaSearch } from "react-icons/fa";
import colorGradient from "javascript-color-gradient";
import Helper from "Helpers/Helper";
import PrintMode from "components/Buttons/PrintMode";
import Favorites from "Helpers/Favorites";

function MonthDataReport() {
    const [data, setData] = useState(null);
    const loading = useLoading();

    async function loadData(station_id, month) {
        loading.show();
        setData([]);
        let response = await ReportsApi.month(station_id, month);
        setData(response.json);
        loading.hide();
    }

    return (
        <Card overflowX="auto">
            <CardBody>
                <Flex direction="column" w="100%">
                    <Box mb={4}>
                        <FormFilter loadData={loadData} />
                    </Box>
                    {data?.data != null && <DataTable data={Object.values(data?.data)} /> || (
                        <Flex w="100%" justifyContent={"center"} mt={4}>
                            <Text opacity={.5}>Nenhum dado para exibir</Text>
                        </Flex>
                    )}
                    {
                        data?.provider == 'INMET' &&
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    }
                    <PrintMode />
                </Flex>
            </CardBody>
        </Card>
    );
}

function FormFilter({ loadData }) {
    const [stations, setStations] = useState([]);
    const loading = useLoading();
    const favorites = Favorites.get();


    useEffect(() => {
        loadStationOptions();
    }, []);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json);
        loading.hide();
    }

    async function submitHandle(values) {
        loadData(values.station_id, values.month);
    }

    return (
        <Formik onSubmit={submitHandle} initialValues={{ station_id: null, month: (new Date).toISOString().slice(0, 7) }} enableReinitialize={true}>
            {props => (
                <Form id="reportFilterForm">
                    <Flex flexDirection="row" flexWrap w="100%">
                        <SimpleGrid w="100%" columns={{ sm: 1, lg: 12 }} gap={4}>
                            <GridItem colSpan={{ sm: 1, lg: 6 }}>
                                <Field name="station_id">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="station_id" fontWeight="normal">Estação:</FormLabel>
                                            <Select id="station_id" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione a Estação...">
                                                {
                                                    !favorites || !favorites.length ?
                                                        (
                                                            stations?.map(station => (
                                                                <option value={station.id}>{station.text}</option>
                                                            ))
                                                        )
                                                        :
                                                        (
                                                            <>
                                                                <optgroup key="favorites" label="Estações Favoritas">
                                                                    {stations?.map(station => {
                                                                        if (favorites.includes(station.identifier))
                                                                            return (<option value={station.id}>{station.text}</option>);
                                                                        else
                                                                            return "";
                                                                    })}
                                                                </optgroup>
                                                                <optgroup key="others" label="Outras Estações">
                                                                    {stations?.map(station => {
                                                                        if (!favorites.includes(station.identifier))
                                                                            return (<option value={station.id}>{station.text}</option>);
                                                                        else
                                                                            return "";
                                                                    })}
                                                                </optgroup>
                                                            </>
                                                        )
                                                }
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem colSpan={{ sm: 1, lg: 4 }}>
                                <Field name="month">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="month" fontWeight="normal">Mês/Ano:</FormLabel>
                                            <Input id="month" {...field} borderRadius="10px" fontSize="md" type="month" />
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Button w="100%" type='submit' form='reportFilterForm' colorScheme="blue" mt={{ lg: 6 }} leftIcon={<FaSearch />}>
                                    Buscar
                                </Button>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
}

function DataTable({ data }) {
    function getBgColor(temp) {
        if (isNaN(temp))
            return;
        temp = Math.round(temp);

        if (temp <= 0)
            temp = 1;
        if (temp >= 40)
            temp = 40;

        return colorGradient
            .setGradient("#00b0f0", "#FDEB84", "#F24044")
            .setMidpoint(40)
            .getColor(parseInt(temp));
    }

    function getGDColor(temp) {
        if (isNaN(temp))
            return;
        temp = parseInt(Math.round(temp));

        let maxGd = getGdAcumulado();

        if (maxGd <= 0) {
            maxGd = 1;
        }

        if (temp <= 0)
            temp = 1;
        if (temp >= maxGd)
            temp = maxGd;

        return colorGradient
            .setGradient("#00b0f0", "#FDEB84", "#F24044")
            .setMidpoint(maxGd)
            .getColor(temp);
    }

    function getBgColorWater(value, max = 100, min = 1) {
        if (isNaN(value))
            return;
        value = Math.round(value);

        if (value <= min)
            value = min;
        if (value >= max)
            value = max;

        return colorGradient
            .setGradient("#FDEB84", "#68D391", "#3182CE")
            .setMidpoint(max)
            .getColor(parseInt(value));
    }

    function getBgColorWind(value, max = 100, min = 1) {
        if (isNaN(value))
            return;
        value = Math.round(value);

        if (value <= min)
            value = min;
        if (value >= max)
            value = max;

        return colorGradient
            .setGradient("#68D391", "#FDEB84", "#F24044")
            .setMidpoint(max)
            .getColor(parseInt(value));
    }


    function getEtoBgColor(eto) {
        if (isNaN(eto) || eto == null)
            return;
        eto = Math.round(eto);

        if (eto <= 1)
            eto = 1;
        if (eto >= 10)
            eto = 10;

        return colorGradient
            .setGradient("#FDEB84", "#68D391", "#3182CE")
            .setMidpoint(10)
            .getColor(eto);
    }

    function getGD(day, index = 0, singleDay = false) {
        if (!day.temp_min || !day.temp_max) {
            return 0;
        }

        let gd = ((parseFloat(day.temp_min) + parseFloat(day.temp_max)) / 2) - 10;

        if (singleDay)
            return gd;

        window.gdAcumulado = (window.gdAcumulado || 0) + gd;

        return window.gdAcumulado;
    }

    function getGdAcumulado() {
        let total = 0;
        data.map((d) => total += getGD(d, 0, true));

        return total;
    }

    useEffect(() => {
        window.gdAcumulado = 0;
    }, [data]);

    return (
        <>
            <Table size="xs" mt={4}>
                <Thead>
                    <Tr>
                        <Th textAlign={"center"} fontSize={"xs"} borderRight="2px solid">Dia</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Mín</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Méd</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Máx</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>ST. Mín</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>ST. Máx</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Umid. Méd.</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Precip.</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Pto. Orv.</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Vento Máx.</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>Raj. Máx.</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>ETo</Th>
                        <Th textAlign={"center"} fontSize={"xs"}>GD</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.map((day, index) => {
                        let gdDay = getGD(day, index);
                        return (
                            <Tr key={index}>
                                <Td textAlign={"center"} fontSize={"sm"} borderRight="2px solid">{index + 1}</Td>
                                {(day && day.temp_min && day.temp_max && day.temp_avg ? (
                                    <>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.temp_min)} textAlign={"center"} fontSize={"sm"}>{day.temp_min}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.temp_avg)} textAlign={"center"} fontSize={"sm"}>{day.temp_avg}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.temp_max)} textAlign={"center"} fontSize={"sm"}>{day.temp_max}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.heat_index_min)} textAlign={"center"} fontSize={"sm"}>{day.heat_index_min}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.heat_index_max)} textAlign={"center"} fontSize={"sm"}>{day.heat_index_max}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColorWater(day.humidity_avg)} textAlign={"center"} fontSize={"sm"}>{day.humidity_avg}%</Td>
                                        <Td color={"gray.700"} bgColor={getBgColorWater(day.precip_total_max, 40)} textAlign={"center"} fontSize={"sm"}>{day.precip_total_max}mm</Td>
                                        <Td color={"gray.700"} bgColor={getBgColor(day.dew_point_avg)} textAlign={"center"} fontSize={"sm"}>{day.dew_point_avg}º</Td>
                                        <Td color={"gray.700"} bgColor={getBgColorWind(day.wind_speed_max, 50)} textAlign={"center"} fontSize={"sm"}>{day.wind_speed_max}km/h</Td>
                                        <Td color={"gray.700"} bgColor={getBgColorWind(day.wind_gust_max, 100)} textAlign={"center"} fontSize={"sm"}>{day.wind_gust_max}km/h</Td>
                                        <Td color={"gray.700"} bgColor={getEtoBgColor(day.eto)} textAlign={"center"} fontSize={"sm"}>{day.eto?.toFixed(4)}</Td>
                                        <Td color={"gray.700"} bgColor={getGDColor(gdDay)} textAlign={"center"} fontSize={"sm"}>{gdDay.toFixed(1)}º <Text display={"inline"} fontSize={"xs"} opacity={0.7}>({getGD(day, index, true).toFixed(1)}º)</Text></Td>
                                    </>
                                ) : (
                                    <Td colSpan={12} textAlign={"center"} fontSize={"xs"}>Nenhum dado encontrado</Td>
                                ))}
                            </Tr>
                        )
                    })}
                    <Tr>
                        <Td borderTop="4px solid" fontWeight={"semibold"} fontSize={"xs"} textAlign={"center"}></Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'temp_min'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'temp_min')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'temp_avg'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'temp_avg')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'temp_max'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'temp_max')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'heat_index_min'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'heat_index_min')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'heat_index_max'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'heat_index_max')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColorWater(Helper.getAverage(data, 'humidity_avg'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'humidity_avg')}%</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColorWater(Helper.getSum(data, 'precip_total_max', 40))} textAlign={"center"} fontSize={"sm"}>{Helper.getSum(data, 'precip_total_max')}mm</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColor(Helper.getAverage(data, 'dew_point_avg'))} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'dew_point_avg')}º</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColorWind(Helper.getAverage(data, 'wind_speed_max'), 50)} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'wind_speed_max')}km/h</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getBgColorWind(Helper.getAverage(data, 'wind_gust_max'), 100)} textAlign={"center"} fontSize={"sm"}>{Helper.getAverage(data, 'wind_gust_max')}km/h</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getEtoBgColor(Helper.getAverage(data, 'eto'))} textAlign={"center"} fontSize={"sm"}>{Helper.getSum(data, 'eto', 4)}</Td>
                        <Td borderTop="4px solid" fontWeight={"semibold"} color={"gray.700"} bgColor={getGDColor(getGdAcumulado())} textAlign={"center"} fontSize={"sm"}>{getGdAcumulado()?.toFixed(1)}º</Td>
                    </Tr>
                </Tbody>
            </Table>
        </>
    );
}

export default MonthDataReport;
