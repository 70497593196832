// Chakra Imports
import {
    Box,
    Flex,
    useColorModeValue,
    Text
} from "@chakra-ui/react";
import { useAuth } from "Providers/AuthProvider";
import React, { useState } from "react";
import PublicNavbarLinks from "./PublicNavbarLinks";

export default function PublicNavbar(props) {
    const auth = useAuth();
    const {
        variant,
        children,
        fixed,
        secondary,
        brandText,
        onOpen,
        ...rest
    } = props;

    // Here are all the props that may change depending on navbar's type or state.(secondary, variant)
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarPosition = "absolute";
    let navbarFilter = "none";
    let navbarBackdrop = "blur(21px)";
    let navbarShadow = "none";
    let navbarBg = "none";
    let navbarBorder = "transparent";
    let secondaryMargin = "0px";
    let paddingX = "15px";
    if (props.fixed === true) {
        navbarPosition = "fixed";
        navbarShadow = useColorModeValue(
            "0px 7px 23px rgba(0, 0, 0, 0.05)",
            "none"
        );
        navbarBg = useColorModeValue(
            "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
            "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
        );
        navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
        navbarFilter = useColorModeValue(
            "none",
            "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
        );
    }
    if (props.secondary) {
        navbarBackdrop = "none";
        navbarPosition = "absolute";
        mainText = "white";
        secondaryText = "white";
        secondaryMargin = "22px";
        paddingX = "30px";
    }

    const greeting = () => {
        let greeting = '';
        var data = [
            [0, 4, "Boa noite"],
            [5, 11, "Bom dia"],
            [12, 17, "Boa tarde"],
            [18, 24, "Boa noite"]
        ],
        hr = new Date().getHours();

        for(var i = 0; i < data.length; i++){
            if(hr >= data[i][0] && hr <= data[i][1]){
                greeting = data[i][2];
            }
        }
        return greeting;
    }

    return (
        <Flex
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        borderBottomWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display="flex"
        minH="50px"
        justifyContent={{ xl: "center" }}
        mx="auto"
        px={{
            sm: paddingX,
            md: "30px",
        }}
        ps={{
            xl: "12px",
        }}
        w={"100%"}
        >
        <Flex
            w="100%"
            flexDirection={{
                sm: "column",
                md: "row",
            }}
            alignItems={{ xl: "center" }}
        >
            <Box mb={{ sm: "8px", md: "0px" }}>
                <Box fontSize={"sm"} color={mainText} bg="inherit" borderRadius="inherit" textAlign={{ sm: "center", md: "left" }}>
                    <b>WS Consultoria Climatológica</b>
                    <Text fontSize={"xs"}>{greeting()}, { auth.check() ? auth.getUser().name : 'Visitante'}</Text>
                </Box>
            </Box>
            <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
                <PublicNavbarLinks
                    onOpen={props.onOpen}
                    logoText={props.logoText}
                    secondary={props.secondary}
                    fixed={props.fixed}
                />
            </Box>
        </Flex>
        </Flex>
    );
}
