
import { Flex, Switch, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import React, { useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { Link } from "react-router-dom";
import SobreNos from "views/Public/Components/SobreNos";

export default function HeaderLinks(props) {
    const { variant, children, secondary, onOpen, ...rest } = props;
    const [isSobreNosOpen, setIsSobreNosOpen] = useState(false)

    // Chakra Color Mode
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    const { colorMode, toggleColorMode } = useColorMode();

    if (secondary) {
        navbarIcon = "white";
        mainText = "white";
    }

    return (
        <Flex w={{ sm: "100%", md: "auto" }} alignItems="center" justifyContent={{ sm: "center", md: "right"}} flexDirection="row">
            <Link to="/">
                <Text fontSize={"sm"} mr={4} cursor={"pointer"}>Mapa</Text>
            </Link>
            <Link to="/estacoes">
                <Text fontSize={"sm"} mr={4} cursor={"pointer"}>Estações</Text>
            </Link>
            <Link to="/members/home">
                <Text fontSize={"sm"} mr={4} cursor={"pointer"}>Membros</Text>
            </Link>
            <Text fontSize={"sm"} mr={4} cursor={"pointer"} onClick={() => setIsSobreNosOpen(true)}>Sobre</Text>
            <Flex alignItems={"center"}>
                <FaSun fontSize={14}/>
                <Switch size="sm" mx={2} onChange={toggleColorMode} defaultChecked={colorMode === 'dark'} />
                <FaMoon fontSize={14}/>
            </Flex>
            {/* <Switch onClick={toggleColorMode} variant={"unstyled"}>{colorMode === "light" ? <FaMoon/> : <FaSun/>}</Switch> */}
            {/* <SettingsIcon cursor="pointer" ms={{ base: "16px", xl: "0px" }} me="16px" onClick={props.onOpen} color={navbarIcon} w="18px" h="18px"/> */}
            {/* <SidebarResponsive logoText={props.logoText} secondary={props.secondary} routes={routes} {...rest}/> */}
            <SobreNos isOpen={isSobreNosOpen} setIsOpen={setIsSobreNosOpen}/>
        </Flex>
    );
}
