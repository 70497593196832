import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormControl, FormLabel, GridItem, Icon, Input, Select, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Field, Form, Formik } from "formik";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useRef, useState } from "react";
import { FaPencilAlt, FaPlusCircle, FaSearch, FaTrashAlt } from "react-icons/fa";
import { Pagination } from 'react-laravel-paginex';
import 'views/Components/pagination.css';
import CulturesApi from "Api/CulturesApi";
import Helper from "Helpers/Helper";

function Cultures() {
    const [page, setPage] = useState(1);
    const [data, setData] = useState({});
    const [currentCulture, setCurrentCulture] = useState({});
    const loading = useLoading();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filter, setFilter] = useState('');
    const textColor = useColorModeValue("gray.700", "white");

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : (page || 1);
        setPage(currentPage);
        let response = await CulturesApi.list(currentPage, filter);
        setData(response.json);
        loading.hide();
    }

    //Delete
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const onAlertClose = () => setAlertIsOpen(false);

    useEffect(() => {
        getData();
    }, [filter]);

    const onFilterSubmit = (formData) => {
        setPage(1);
        setFilter(formData.filter);
    }

    return (
        <>
            <Card py={2} px={4}>
                <CardHeader>
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" fontWeight="bold">
                            Culturas
                        </Text>
                        <Button onClick={() => { setCurrentCulture({}); onOpen() }} leftIcon={<FaPlusCircle />} colorScheme="whatsapp" fontSize="md">
                            Nova
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" w="100%">
                        <Flex direction="column" w="100%" overflowX={"auto"} mb={4}>
                            <Formik onSubmit={onFilterSubmit} initialValues={{ search: "" }}>
                                <Form id="filterForm">
                                    <Field name="filter">
                                        {({ field, form }) => (
                                            <FormControl>
                                                <SimpleGrid columns={12}>
                                                    <GridItem colSpan={{ base: 12, md: 10 }}>
                                                        <Input {...field} mb={{ base: 2, md: 0 }} borderRightRadius={{ base: 10, md: 0 }} placeholder="Pesquisar por..." borderRadius="10px" fontSize="sm" size="md" pr='4.5rem' />
                                                    </GridItem>
                                                    <GridItem colSpan={{ base: 12, md: 2 }}>
                                                        <Button size='md' borderLeftRadius={{ base: 15, md: 0 }} type="submit" w="100%" leftIcon={<FaSearch />}>
                                                            Buscar
                                                        </Button>
                                                    </GridItem>
                                                </SimpleGrid>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            </Formik>
                        </Flex>
                        <Flex direction="column" w="100%">
                            <Flex direction="column" w="100%" overflowX={"auto"}>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Cultura</Th>
                                            <Th>Solo</Th>
                                            <Th>Ciclo</Th>
                                            <Th>Tipo de Cálculo</Th>
                                            <Th textAlign={"center"}>Ações</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.data && data.data.length ?
                                            data.data.map((culture) => (
                                                <Tr key={culture.id}>
                                                    <Td>{culture.name}</Td>
                                                    <Td>{Helper.initCap((culture?.soil || '').replace('_', " "))}</Td>
                                                    <Td>{culture.cycle}</Td>
                                                    <Td>{culture.formula == "gdu" ? "GDU" : "KC/CAD Manual"}</Td>
                                                    <Td textAlign={"center"}>
                                                        <Button p="0px" bg="transparent" mb={{ sm: "10px", md: "0px" }} me={{ md: "12px" }} onClick={() => { setCurrentCulture(culture); setAlertIsOpen(true); }}>
                                                            <Flex color="red.500" cursor="pointer" align="center" p="12px">
                                                                <Icon as={FaTrashAlt} me="4px" />
                                                                <Text fontSize="sm" fontWeight="semibold">
                                                                    EXCLUIR
                                                                </Text>
                                                            </Flex>
                                                        </Button>

                                                        <Button p="0px" bg="transparent" onClick={() => { setCurrentCulture(culture); onOpen(); }}>
                                                            <Flex color={textColor} cursor="pointer" align="center" p="12px">
                                                                <Icon as={FaPencilAlt} me="4px" />
                                                                <Text fontSize="sm" fontWeight="semibold">
                                                                    EDITAR
                                                                </Text>
                                                            </Flex>
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ))
                                            :
                                            (
                                                <Tr>
                                                    <Td colSpan={4} textAlign={"center"} color="gray.500">Nenhuma cultura encontrada</Td>
                                                </Tr>
                                            )
                                        }
                                    </Tbody>
                                </Table>
                            </Flex>
                            <Pagination changePage={getData} data={data} nextButtonText="Próxima" prevButtonText="Anterior" />
                            <Flex w={"100%"} justify="center" fontSize="sm" color="gray.500">
                                {
                                    data?.meta?.total ?
                                        (
                                            data.meta.filter ?
                                                <Text>{data.meta.total} registros encontradas para "{data.meta.filter}", mostrando de {data.meta.from} a {data.meta.to}</Text>
                                                :
                                                <Text>
                                                    Mostrando de {data.meta.from} a {data.meta.to} de {data.meta.total} registros
                                                </Text>
                                        )
                                        :
                                        ""
                                }
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>

            <CultureFormDrawer isOpen={isOpen} onClose={onClose} culture={currentCulture} getData={getData} />
            <DeleteCulture culture={currentCulture} isOpen={isAlertOpen} onClose={onAlertClose} getData={getData} />
        </>
    );
}

function DeleteCulture({ culture, isOpen, onClose, getData }) {
    const cancelRef = useRef();
    const toast = useToast({
        position: "top-right"
    });
    const onDelete = async () => {
        let response = await CulturesApi.delete(culture.id);
        if (response.status == 204) {
            toast({ status: 'success', title: 'Cultura excluído com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            onClose();
            getData();
        } else {
            toast({ status: 'error', title: 'Houve um problema ao excluir a cultura! Cód. ' + response.status, duration: 3000, variant: 'solid', isClosable: true });
        }
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Excluir Cultura
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Você tem certeza que deseja excluir a cultura: <b>{culture?.name}</b>?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Não, Cancelar
                        </Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>
                            Sim, Excluir
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

function CultureFormDrawer({ isOpen, onClose, culture, getData }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });
    const [ csv, setCsv ] = useState(null);

    const isEdit = !!culture.id;

    const submitHandle = async (values) => {
        if (!isEdit && values.data_type == "csv" && !csv) {
            toast({ status: 'error', title: "por favor, selecione um arquivo CSV para continuar.", duration: 5000, variant: 'solid', isClosable: true });
            return;
        }

        loading.show();
        let data = null;

        try {
            if (culture.id) {
                data = await CulturesApi.update(culture.id, values);
            } else {
                if (!isEdit && values.data_type == "csv") {
                    values.csv = csv;
                }

                data = await CulturesApi.store(values, !!values.csv);
            }
        } catch (err) {
            toast({ status: 'error', title: err?.response?.data?.message || "Erro ao salvar cultura, verifique seus dados e tente novamente!", duration: 5000, variant: 'solid', isClosable: true });
            loading.hide();
        }

        if (data.status >= 200 && data.status < 300) {
            toast({ status: 'success', title: 'Cultura salvo com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            getData();
            onClose();
            loading.hide();
        } else {
            toast({ status: 'error', title: 'Erro ao salvar a cultura! Cód. ' + data.status, duration: 3000, variant: 'solid', isClosable: true });
            loading.hide();
        }
    }

    let initialValues = {
        name: culture.name ?? null,
        soil: culture.soil ?? null,
        cycle: culture.cycle ?? null,
        data_type: "manual",
        kcs: culture.kcs ?? [],
        formula: culture.formula ?? null,
        initial_gdu: culture.initial_gdu ?? null,
        gdu_temp_min: culture.gdu_temp_min ?? null,
        gdu_temp_max: culture.gdu_temp_max ?? null,
    };

    function generateKcCADFields(props) {
        props.setFieldValue(`kcs`, (props.values.kcs || []).slice(0, props.getFieldProps('cycle').value || 0));

        Array(props.getFieldProps('cycle').value || 0).fill(null).map((_, i) => {
            props.setFieldValue(`kcs.${i}.day`, i + 1);
        });
    }

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{culture.id ? 'Editar' : 'Nova'} Cultura</DrawerHeader>
                <DrawerBody>

                    <Formik onSubmit={submitHandle} initialValues={initialValues} enableReinitialize={true}>
                        {props => (
                            <Form id="stationForm">
                                <Flex flexDirection="row" flexWrap w="100%">
                                    <SimpleGrid w="100%" columns={12} gap={4}>
                                        <GridItem colSpan={12}>
                                            <Field name="name">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="name" whiteSpace={"nowrap"} fontWeight="normal">Cultura:</FormLabel>
                                                        <Input id="name" {...field} borderRadius="10px" fontSize="md" type="text" placeholder="Milho" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>


                                        <GridItem colSpan={12}>
                                            <Field name="formula">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="formula" fontWeight="normal">Tipo de Cálculo:</FormLabel>
                                                        <Select id="formula" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione..." onChange={(e) => {
                                                            field.onChange(e);

                                                            if (!e.target.value || e.target.value == "gdu") {
                                                                props.setFieldValue(`kcs`, []);
                                                            }
                                                        }} onBlur={(e) => {
                                                            props.handleBlur(e);

                                                            if (props?.values?.formula == "kc_cad" && props?.values?.cycle > 0) {
                                                                generateKcCADFields(props);
                                                            }
                                                        }}>
                                                            <option value="gdu">Por GDU</option>
                                                            <option value="kc_cad">Por KC e CAD fixo</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        {
                                            !isEdit && props?.values?.formula == "kc_cad" &&
                                            <GridItem colSpan={12}>
                                                <Field name="data_type">
                                                    {({ field, form }) => (
                                                        <FormControl isRequired>
                                                            <FormLabel mb="0" htmlFor="data_type" fontWeight="normal">Forma de Cadastro:</FormLabel>
                                                            <Select id="data_type" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                                <option value="manual">Manual</option>
                                                                <option value="csv">CSV</option>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        }

                                        <GridItem colSpan={props?.values?.data_type == "manual" ? 6 : 12}>
                                            <Field name="soil">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="soil" fontWeight="normal">Tipo de Solo:</FormLabel>
                                                        <Select id="soil" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione...">
                                                            <option value="argiloso">Argiloso</option>
                                                            <option value="medio">Médio</option>
                                                            <option value="arenoso">Arenoso</option>
                                                            <option value="muito_argiloso">Muito Argiloso</option>
                                                            <option value="muito_arenoso">Muito Arenoso</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        {
                                            props?.values?.data_type == "manual" &&
                                            <GridItem colSpan={6}>
                                                <Field name="cycle">
                                                    {({ field, form }) => (
                                                        <FormControl isRequired>
                                                            <FormLabel mb="0" htmlFor="cycle" whiteSpace={"nowrap"} fontWeight="normal">Ciclo (dias):</FormLabel>
                                                            <Input id="cycle" {...field} onBlur={(e) => {
                                                                props.handleBlur(e);

                                                                if (props?.values?.formula == "kc_cad") {
                                                                    generateKcCADFields(props);
                                                                }
                                                            }} borderRadius="10px" fontSize="md" type="number" step={1} min="1" placeholder="120" disabled={!props?.values?.formula} />
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        }

                                        {
                                            props?.values?.data_type == "csv" &&
                                            <GridItem colSpan={12}>
                                                <FormControl isRequired>
                                                    <FormLabel mb="0" htmlFor="csv" whiteSpace={"nowrap"} fontWeight="normal">CSV:</FormLabel>
                                                    <Input id="csv" borderRadius="10px" fontSize="md" type="file" accept=".csv" onChange={(event) => {
                                                        setCsv(event?.currentTarget?.files[0] || null);
                                                    }} />
                                                </FormControl>
                                            </GridItem>
                                        }

                                        {
                                            props?.values?.formula == "kc_cad" && Array(props?.values?.cycle || 0).fill(null).map((_, i) => <>
                                                <GridItem colSpan={2}>
                                                    <Field name={`kcs.${i}.day`}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired isReadOnly isDisabled>
                                                                <FormLabel mb="0" htmlFor={`kcs.${i}.day`} whiteSpace={"nowrap"} fontWeight="normal">Dia:</FormLabel>
                                                                <Input id={`kcs.${i}.day`} {...field} borderRadius="10px" fontSize="md" type="number" step={1} min="0" />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                                <GridItem colSpan={5}>
                                                    <Field name={`kcs.${i}.kc`}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired>
                                                                <FormLabel mb="0" htmlFor={`kcs.${i}.kc`} whiteSpace={"nowrap"} fontWeight="normal">KC:</FormLabel>
                                                                <Input id={`kcs.${i}.kc`} {...field} borderRadius="10px" fontSize="md" type="number" step={.0000000001} placeholder="0.5" />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                                <GridItem colSpan={5}>
                                                    <Field name={`kcs.${i}.cad`}>
                                                        {({ field, form }) => (
                                                            <FormControl isRequired>
                                                                <FormLabel mb="0" htmlFor={`kcs.${i}.cad`} whiteSpace={"nowrap"} fontWeight="normal">CAD:</FormLabel>
                                                                <Input id={`kcs.${i}.cad`} {...field} borderRadius="10px" fontSize="md" type="number" step={.0000000001} placeholder="7.100025770" />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                            </>)
                                        }

                                        {
                                            props?.values?.formula == "gdu" && <>
                                                <GridItem colSpan={{ base: 12, lg: 6 }}>
                                                    <Field name="gdu_temp_min">
                                                        {({ field, form }) => (
                                                            <FormControl isRequired>
                                                                <FormLabel mb="0" htmlFor="gdu_temp_min" whiteSpace={"nowrap"} fontWeight="normal">Temp. Basal Mínima:</FormLabel>
                                                                <Input id="gdu_temp_min" {...field} borderRadius="10px" fontSize="md" type="number" step={0.1} placeholder="10" />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                                <GridItem colSpan={{ base: 12, lg: 6 }}>
                                                    <Field name="gdu_temp_max">
                                                        {({ field, form }) => (
                                                            <FormControl isRequired>
                                                                <FormLabel mb="0" htmlFor="gdu_temp_max" whiteSpace={"nowrap"} fontWeight="normal">Temp. Basal Máxima:</FormLabel>
                                                                <Input id="gdu_temp_max" {...field} borderRadius="10px" fontSize="md" type="number" step={0.1} placeholder="30" />
                                                            </FormControl>
                                                        )}
                                                    </Field>
                                                </GridItem>
                                            </>}

                                    </SimpleGrid>
                                </Flex>
                            </Form>
                        )}
                    </Formik>


                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' form='stationForm' colorScheme="whatsapp">
                        Salvar
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}

export default Cultures;
