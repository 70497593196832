import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    GridItem,
    Input,
    Select,
    SimpleGrid,
    Text,
} from "@chakra-ui/react";
import ReportsApi from "Api/ReportsApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import StationsApi from "Api/StationsApi";
import { FaSearch } from "react-icons/fa";
import ReactApexChart from "react-apexcharts";
import jsonData from "../../../apex_chart_pt_br.json";
import PrintMode from "components/Buttons/PrintMode";
import Favorites from "Helpers/Favorites";

function RainDataReport() {
    const [data, setData] = useState(null);
    const loading = useLoading();

    async function loadData(station_id, year) {
        loading.show();
        setData([]);
        let response = await ReportsApi.rain(station_id, year);
        setData(response.json);
        loading.hide();
    }

    return (
        <Card overflowX="auto">
            <CardBody>
                <Flex direction="column" w="100%">
                    <Box mb={4}>
                        <FormFilter loadData={loadData} />
                    </Box>
                    {(data?.data != null && (
                        <DataTable data={data?.data} normal={data?.normal} />
                    )) || (
                            <Flex w="100%" justifyContent={"center"} mt={4}>
                                <Text opacity={0.5}>Nenhum dado para exibir</Text>
                            </Flex>
                        )}
                    {data?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                    <PrintMode />
                </Flex>
            </CardBody>
        </Card>
    );
}

function FormFilter({ loadData }) {
    const [stations, setStations] = useState([]);
    const loading = useLoading();
    const favorites = Favorites.get();

    useEffect(() => {
        loadStationOptions();
    }, []);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json);
        loading.hide();
    }

    async function submitHandle(values) {
        loadData(values.station_id, values.year);
    }

    return (
        <Formik
            onSubmit={submitHandle}
            initialValues={{ station_id: null, year: new Date().getFullYear() }}
            enableReinitialize={true}
        >
            {(props) => (
                <Form id="reportFilterForm">
                    <Flex flexDirection="row" flexWrap w="100%">
                        <SimpleGrid w="100%" columns={{ sm: 1, lg: 12 }} gap={4}>
                            <GridItem colSpan={{ sm: 1, lg: 6 }}>
                                <Field name="station_id">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel
                                                mb="0"
                                                htmlFor="station_id"
                                                fontWeight="normal"
                                            >
                                                Estação:
                                            </FormLabel>
                                            <Select
                                                id="station_id"
                                                {...field}
                                                borderRadius="10px"
                                                fontSize="md"
                                                placeholder="Selecione a Estação..."
                                            >
                                                {!favorites || !favorites.length ? (
                                                    stations.map((station) => (
                                                        <option value={station.id}>{station.text}</option>
                                                    ))
                                                ) : (
                                                    <>
                                                        <optgroup
                                                            key="favorites"
                                                            label="Estações Favoritas"
                                                        >
                                                            {stations.map((station) => {
                                                                if (favorites.includes(station.identifier))
                                                                    return (
                                                                        <option value={station.id}>
                                                                            {station.text}
                                                                        </option>
                                                                    );
                                                                else return "";
                                                            })}
                                                        </optgroup>
                                                        <optgroup key="others" label="Outras Estações">
                                                            {stations.map((station) => {
                                                                if (!favorites.includes(station.identifier))
                                                                    return (
                                                                        <option value={station.id}>
                                                                            {station.text}
                                                                        </option>
                                                                    );
                                                                else return "";
                                                            })}
                                                        </optgroup>
                                                    </>
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem colSpan={{ sm: 1, lg: 4 }}>
                                <Field name="year">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="year" fontWeight="normal">
                                                Ano:
                                            </FormLabel>
                                            <Input
                                                id="year"
                                                {...field}
                                                borderRadius="10px"
                                                fontSize="md"
                                                type="year"
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Button
                                    w="100%"
                                    type="submit"
                                    form="reportFilterForm"
                                    colorScheme="blue"
                                    mt={{ lg: 6 }}
                                    leftIcon={<FaSearch />}
                                >
                                    Buscar
                                </Button>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
}

function DataTable({ data, normal }) {
    const getChartSeries = () => [
        {
            name: "Precipitação (mm)",
            type: "bar",
            data: Object.values(data).map((d, k) => {
                return {
                    x: Object.keys(data)[k],
                    y: Math.round(d.total),
                };
            }),
        },
        {
            name: "Média Climatológica (mm)",
            type: "line",
            data: Object.values(sortArrayByMonthName(normal || [])).map((d, k) => {
                return {
                    x: Object.keys(normal)[k],
                    y: Math.round(d),
                };
            }),
        },
    ];

    function sortArrayByMonthName(array) {
        let months = [
            "jan",
            "feb",
            "mar",
            "apr",
            "may",
            "jun",
            "jul",
            "aug",
            "sep",
            "oct",
            "nov",
            "dec",
        ];
        return Object.keys(array)
            .sort((a, b) => {
                return months.indexOf(a) - months.indexOf(b);
            })
            .reduce((obj, key) => {
                obj[key] = array[key];
                return obj;
            }, {});
    }

    const getChartOptions = () => {
        return {
            chart: {
                type: "bar",
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: "pt-br",
            },
            xaxis: {
                type: "date",
                categories: Object.keys(data),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: function (value, timestamp) {
                        return (value || "").toString().split("-").reverse().join("/");
                    },
                },
            },
            yaxis: {
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            tooltip: {
                shared: true,
                theme: "dark",
            },
        };
    };

    return (
        <>
            <ReactApexChart
                options={getChartOptions()}
                series={getChartSeries()}
                width="100%"
                height={350}
            />
        </>
    );
}

export default RainDataReport;
