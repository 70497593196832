import { Flex, GridItem, SimpleGrid, Skeleton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import colorGradient from "javascript-color-gradient";

function RankingRain({ stations }) {
    let firstTable = stations && stations.length ? stations.slice(0, 5) : [];
    let secondTable = stations && stations.length ? stations.slice(5, 10) : [];

    return (
        <SimpleGrid columns={{sm: 1, lg: 2}}>
            <GridItem>
                <StationTable rows={firstTable} />
            </GridItem>
            <GridItem>
                <StationTable rows={secondTable} isLast />
            </GridItem>
        </SimpleGrid>
    );
}

function StationTable({ rows, isLast = false }) {
    let isMobile = window.innerWidth < 768;

    function getBgColor(temp)
    {
        if (isNaN(temp))
            return;
        temp = Math.round(temp);

        if (temp <= 0)
            temp = 1;
        if (temp >= 40)
            temp = 40;

        return colorGradient
            .setGradient("#90CDF4", "#4299E1", "#2B6CB0")
            .setMidpoint(40)
            .getColor(parseInt(temp));
    }

    return (
    <Table size="sm">
        {
            (!isMobile || !isLast ) && (
                <Thead>
                    <Tr>
                        <Th>Estação</Th>
                        <Th w={100}>Precip.</Th>
                    </Tr>
                </Thead>
            )
        }
        <Tbody>
            {(rows.length && rows.map((station, k) => (
                    <Tr key={k}>
                        <Td>
                            <Flex direction="column">
                                <Text>{station.city}/{station.state}</Text>
                                <Text fontSize={"sm"} color="gray.500">{station.provider}</Text>
                            </Flex>
                        </Td>
                        <Td w={100} bgColor={getBgColor(station.rain)} color={"gray.700"}>{station.rain}mm</Td>
                    </Tr>
                ))
            ) || (
                [...Array(5).keys()].map(i => (
                <Tr key={i}>
                    <Td>
                        <Skeleton h={5}/>
                    </Td>
                    <Td>
                        <Skeleton h={5}/>
                    </Td>
                </Tr>
            )))}
        </Tbody>
    </Table>

    );
}

export default RankingRain;
