const Helper = {
    ufList: function() {
        return [
            {uf: 'AC', full_name: 'Acre'},
            {uf: 'AL', full_name: 'Alagoas'},
            {uf: 'AP', full_name: 'Amapá'},
            {uf: 'AM', full_name: 'Amazonas'},
            {uf: 'BA', full_name: 'Bahia'},
            {uf: 'CE', full_name: 'Ceará'},
            {uf: 'DF', full_name: 'Distrito Federal'},
            {uf: 'ES', full_name: 'Espírito Santo'},
            {uf: 'GO', full_name: 'Goiás'},
            {uf: 'MA', full_name: 'Maranhão'},
            {uf: 'MT', full_name: 'Mato Grosso'},
            {uf: 'MS', full_name: 'Mato Grosso do Sul'},
            {uf: 'MG', full_name: 'Minas Gerais'},
            {uf: 'PA', full_name: 'Pará'},
            {uf: 'PB', full_name: 'Paraíba'},
            {uf: 'PR', full_name: 'Paraná'},
            {uf: 'PE', full_name: 'Pernambuco'},
            {uf: 'PI', full_name: 'Piauí'},
            {uf: 'RJ', full_name: 'Rio de Janeiro'},
            {uf: 'RN', full_name: 'Rio Grande do Norte'},
            {uf: 'RS', full_name: 'Rio Grande do Sul'},
            {uf: 'RO', full_name: 'Rondônia'},
            {uf: 'RR', full_name: 'Roraima'},
            {uf: 'SC', full_name: 'Santa Catarina'},
            {uf: 'SP', full_name: 'São Paulo'},
            {uf: 'SE', full_name: 'Sergipe'},
            {uf: 'TO', full_name: 'Tocantins'}
        ];
    },

    initCap: (str) => {
        return str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
            return m.toUpperCase();
        });
    },

    lpad: (str, length, pad) => {
        while (str.length < length) {
            str = pad + str;
        }
        return str;
    },

    getMin: (array, field) => {
        let min = null;
        array.forEach(item => {
            if (!item || !item[field])
                return;
            if (!min || item[field] < min)
                min = item[field];
        });
        return min;
    },

    getMax: (array, field) => {
        let max = null;
        array.forEach(item => {
            if (!item || !item[field])
                return;
            if (!max || item[field] > max)
                max = item[field];
        });
        return max;
    },

    getAverage: (array, field, fixed = 1) => {
        let sum = 0;
        let count = 0;
        array.forEach(item => {
            if (!item || !item[field])
                return;
            sum +=  parseFloat(item[field]);
            count++;
        });
        if (count === 0)
            return 0.0;

        return (sum / count).toFixed(fixed);
    },

    getSum: (array, field, fixed = 1) => {
        let sum = 0;
        array.forEach(item => {
            if (!item || !item[field])
                return;
            sum +=  parseFloat(item[field]);
        });

        return sum.toFixed(fixed);
    },

    getMonthAverage: (array) => {
        let min_avg = parseFloat(Helper.getAverage(Object.values(array), 'temp_min'));
        let max_avg = parseFloat(Helper.getAverage(Object.values(array), 'temp_max'));

        if (min_avg === 0.0 || max_avg === 0.0)
            return 0.0;

        return ((min_avg + max_avg) / 2).toFixed(1);
    },

    getYearAverage: (array) => {
        let sum = 0;
        let count = 0;

        array.map(month => {
            let avg = parseFloat(Helper.getMonthAverage(Object.values(month)));
            if (avg === 0.0) return;
            count++;
            sum += avg;
        });

        if (count === 0)
            return 0.0;
        return (sum / count).toFixed(1);
    },

    getYearMin: (array, field) => {
        let lowest = null;
        array.forEach(month => {
            Object.values(month).forEach(day => {
                if (!day[field] && !day[field] == 0.0)
                    return;
                let fieldValue = parseFloat(day[field])
                if (!lowest || fieldValue < lowest)
                    lowest = fieldValue;
            });
        });
        return lowest;
    },

    getYearMax: (array, field) => {
        let greatest = null;
        array.forEach(month => {
            Object.values(month).forEach(day => {
                if (!day[field] && !day[field] == 0.0)
                    return;
                let fieldValue = parseFloat(day[field])
                if (!greatest || fieldValue > greatest)
                    greatest = fieldValue;
            });
        });
        return greatest;
    },

    countDaysInTemp: (array) => {
        let values = {
            min: {
                below_minus5: 0,
                below_0: 0,
                below_5: 0,
                below_10: 0,
                below_15: 0,
                below_20: 0,
            },
            max: {
                below_10: 0,
                below_15: 0,
                below_20: 0,
                above_25: 0,
                above_30: 0,
                above_35: 0,
            }
        };

        array.forEach(month => {
            Object.values(month).forEach(day => {
                let temp = null;
                if (day.temp_min) {
                    temp = parseFloat(day.temp_min);
                    if (temp <= -5)
                        values.min.below_minus5++;
                    if (temp <= 0)
                        values.min.below_0++;
                    if (temp <= 5)
                        values.min.below_5++;
                    if (temp <= 10)
                        values.min.below_10++;
                    if (temp <= 15)
                        values.min.below_15++;
                    if (temp <= 20)
                        values.min.below_20++;
                }
                if (day.temp_max) {
                    temp = parseFloat(day.temp_max);
                    if (temp <= 10)
                        values.max.below_10++;
                    if (temp <= 15)
                        values.max.below_15++;
                    if (temp <= 20)
                        values.max.below_20++;
                    if (temp >= 25)
                        values.max.above_25++;
                    if (temp >= 30)
                        values.max.above_30++;
                    if (temp >= 35)
                        values.max.above_35++;
                }
            });
        });

        return values;
    },

    usToBrDate: (date) => {
        let [year, month, day] = date.split('-');
        return `${day}/${month}/${year}`;
    },

    formatDate: (date) => {
        return (new Date(date)).toLocaleDateString('pt-BR');
    },


    normalize: (val, min, max) => Math.max(0, Math.min(1, parseFloat(((val - min) / (max - min)).toFixed(2))))
}

export default Helper;
