import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Input,
    Radio,
    RadioGroup,
    Select,
    Spinner,
    Stack,
    Text,
    useDisclosure,
    SimpleGrid,
    GridItem
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup } from "react-leaflet";
import brazilGeoJson from "../../../assets/brazil";
import StationsApi from "Api/StationsApi";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { getCor } from "../../Public/Home";
import { divIcon } from "leaflet";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import PrintMode from "components/Buttons/PrintMode";

export default function Heatmap() {
    const [stations, setStations] = useState([]);
    const [isLoadingStations, setIsLoadingStations] = useState(false);
    const [heatOptions, setHeatOptions] = useState({
        currentDataType: 'temp_avg', resolution: "5", opacity: "0.6", exp: "3", show_stations: "1"
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoadingHeat, setLoadingHeat] = useState(false);

    const { isOpen: isOpenData, onOpen: onOpenData, onClose: onCloseData } = useDisclosure();

    window.mapMarkerType = heatOptions.currentDataType;

    let center = [-14.4095261, -51.31668];

    async function loadStationsData(date) {
        setIsLoadingStations(true);
        let data = await StationsApi.summary(date);
        setStations(data.json || []);
        setIsLoadingStations(false);
    }

    function renderStationMarkers() {
        return (stations || []).map((station) => (
            <Marker
                icon={divIcon({
                    iconSize: [0, 0],
                    html: `<div style="background-color: ${getCor(
                        getActiveData(station)
                    )}; color:white;" class="icone icone-${getCor(
                        getActiveData(station)
                    ).replace('#', '')}">${getActiveData(station) || "-"
                        }</div>`,
                    popupAnchor: [5, 0],
                    className: `${getActiveData(station) || "x"}`,
                })}
                key={"marker_" + station.identifier}
                position={[station.lat, station.long]}
            >
                <Popup>
                    <Flex justifyContent={"center"} w="100%" direction={"column"}>
                        <Text
                            textAlign={"center"}
                            fontWeight={"semibold"}
                            m={"0 !important"}
                        >
                            {station.full_name
                                ? station.full_name
                                : `${station.city} / ${station.state} / ${station.provider}`}
                        </Text>
                        <SimpleGrid columns={3} mt={2} rowGap={3}>
                            <GridItem>
                                <Flex
                                    justifyContent={"center"}
                                    direction={"column"}
                                    textAlign={"center"}
                                    color="blue.500"
                                >
                                    <b>{station.temp_min || "-"}º</b>
                                    Mínima
                                </Flex>
                            </GridItem>

                            <GridItem>
                                <Flex
                                    justifyContent={"center"}
                                    direction={"column"}
                                    textAlign={"center"}
                                >
                                    <b>{station.temp_avg || "-"}º</b>
                                    Média
                                </Flex>
                            </GridItem>

                            <GridItem>
                                <Flex
                                    justifyContent={"center"}
                                    direction={"column"}
                                    textAlign={"center"}
                                    color="red.500"
                                >
                                    <b>{station.temp_max || "-"}º</b>
                                    Máxima
                                </Flex>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Popup>
            </Marker>
        ))
    }

    function getActiveData(station) {
        switch (heatOptions.currentDataType) {
            case "temp_avg":
                return station.temp_avg;
            case "temp_max":
                return station.temp_max;
            case "temp_min":
                return station.temp_min;
            default:
                return 0;
        }
    }

    return (
        <>
            <Card py={2} px={4}>
                <CardHeader>
                    <Flex justify="space-between" align="center" minHeight="60px" w="100%">
                        <Text fontSize="lg" fontWeight="bold">
                            Mapa de Calor
                        </Text>

                        <Flex flexWrap="wrap" gridGap={2}>
                            <Button
                                onClick={() => {
                                    var evt = new CustomEvent('generateheatmap', {
                                        detail: {
                                            currentDataType: heatOptions.currentDataType,
                                            resolution: heatOptions.resolution,
                                            opacity: heatOptions.opacity,
                                            exp: heatOptions.exp
                                        }
                                    });

                                    window.dispatchEvent(evt);
                                }}
                                size="sm"
                                colorScheme="blue"
                                isLoading={isLoadingHeat}
                                isDisabled={!stations.length}
                                loadingText="Gerando..."
                            >Gerar Mapa de Calor</Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={onOpenData}
                                isDisabled={isLoadingHeat}
                            >
                                Dados
                            </Button>
                            <Button
                                size="sm"
                                variant="outline"
                                onClick={onOpen}
                                isDisabled={isLoadingHeat}
                            >
                                Configurações
                            </Button>
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <link
                        rel="stylesheet"
                        href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
                    />


                    <Drawer isOpen={isOpenData} placement="right" onClose={onCloseData}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Dados do Mapa</DrawerHeader>

                            <DrawerBody>
                                <form onSubmit={(e) => {
                                    e.preventDefault();

                                    var formData = new FormData(e.target);

                                    onCloseData();

                                    loadStationsData(formData.get('date'));
                                }}>
                                    <Text mt={4} fontWeight={"semibold"}>Data</Text>
                                    <Input type="date" name="date" isRequired />


                                    <Flex mt={4} justify={"center"}>
                                        <Box>
                                            <Button colorSchema="blue" type="submit">Carregar dados</Button>
                                        </Box>
                                    </Flex>
                                </form>
                            </DrawerBody>

                            <DrawerFooter>
                                <Button variant="outline" mr={3} onClick={onCloseData}>
                                    Cancelar
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>

                    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Mapa de Calor</DrawerHeader>

                            <DrawerBody>
                                <form onSubmit={(e) => {
                                    e.preventDefault();

                                    var formData = new FormData(e.target);

                                    onClose();

                                    setHeatOptions({
                                        ...Object.fromEntries(formData)
                                    });
                                }}>
                                    <Text fontWeight={"semibold"}>Dado a ser exibido</Text>
                                    <RadioGroup
                                        defaultValue={heatOptions.currentDataType}
                                        mt={2}
                                        name="currentDataType"
                                    >
                                        <Stack direction="column">
                                            <Radio value="temp_avg">Temperatura Média (°C)</Radio>
                                            <Radio value="temp_min">Temperatura Mínima (°C)</Radio>
                                            <Radio value="temp_max">Temperatura Máxima (°C)</Radio>
                                        </Stack>
                                    </RadioGroup>

                                    <Text mt={4} fontWeight={"semibold"}>Resolução</Text>
                                    <Select name="resolution" defaultValue={heatOptions.resolution}>
                                        <option value="1">1px</option>
                                        <option value="2">2px</option>
                                        <option value="3">3px</option>
                                        <option value="5">5px</option>
                                        <option value="7">7px</option>
                                        <option value="10">10px</option>
                                    </Select>

                                    <Text mt={4} fontWeight={"semibold"}>Opacidade</Text>
                                    <Select name="opacity" defaultValue={heatOptions.opacity}>
                                        <option value="0.2">20%</option>
                                        <option value="0.3">30%</option>
                                        <option value="0.4">40%</option>
                                        <option value="0.5">50%</option>
                                        <option value="0.6">60%</option>
                                        <option value="0.7">70%</option>
                                        <option value="0.8">80%</option>
                                        <option value="0.9">90%</option>
                                        <option value="1.0">100%</option>
                                    </Select>


                                    <Text mt={4} fontWeight={"semibold"}>Exponente</Text>
                                    <Select name="exp" defaultValue={heatOptions.exp}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </Select>

                                    <Text mt={4} fontWeight={"semibold"}>Mostrar Estações</Text>
                                    <Select name="show_stations" defaultValue={heatOptions.show_stations}>
                                        <option value="0">Não</option>
                                        <option value="1">Sim</option>
                                    </Select>


                                    <Flex mt={4} justify={"center"}>
                                        <Box>
                                            <Button colorSchema="blue" type="submit">Aplicar</Button>
                                        </Box>
                                    </Flex>
                                </form>
                            </DrawerBody>

                            <DrawerFooter>
                                <Button variant="outline" mr={3} onClick={onClose}>
                                    Cancelar
                                </Button>
                            </DrawerFooter>
                        </DrawerContent>
                    </Drawer>

                    <Flex w="full" direction="column" gap={2}>
                        <Flex
                            as={MapContainer}
                            center={center}
                            zoom={4}
                            style={{ minHeight: "500px", height: "calc(100vh - 150px)", width: "100%", borderRadius: "15px !important", zIndex: 0, flexGrow: 1 }}
                            borderRadius={"lg"}
                            attributionControl={false}
                        >
                            <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="OpenStreetMap" />

                            {!isLoadingStations && <HeatmapComponent data={stations} {...heatOptions} isLoading={isLoadingHeat} setLoading={setLoadingHeat} />}

                            {heatOptions.show_stations == "1" &&
                                <MarkerClusterGroup
                                    maxClusterRadius={50}
                                    disableClusteringAtZoom={7}
                                    iconCreateFunction={(cluster) => {
                                        let data = [];
                                        const childMarkers = cluster.getAllChildMarkers();

                                        childMarkers.forEach((childMarker) => {
                                            let value = childMarker.options.icon.options.className;

                                            if (value != "x") {
                                                data.push(Number(value));
                                            }
                                        });

                                        let avg = (
                                            data.reduce((a, b) => a + b, 0) / childMarkers.length
                                        ).toFixed(1);

                                        return divIcon({
                                            iconSize: [50, 30],
                                            html: `<div style="background-color: ${getCor(
                                                avg
                                            )}; color:white;" class="icone icone-cluster icone-${getCor(
                                                avg
                                            ).replace('#', '')}">~${avg}<br>${cluster._childCount
                                                } estações</div>`,
                                            popupAnchor: [60, 0],
                                        });
                                    }}
                                >
                                    {renderStationMarkers(stations)}
                                </MarkerClusterGroup>}

                        </Flex>
                        <style>
                            {`
                .icone, .icone-cluster {
                    opacity: 0.6 !important
                }

                .leaflet-div-icon {
                    background: transparent !important;
                }
                `}
                        </style>

                        {
                            isLoadingStations && <Flex zIndex={999} position={"fixed"} bottom={0} left={0} right={0} p={4} justify={"center"}>
                                <Flex fontSize={"sm"} className="theme-bg" align="center" justify={"center"} p={2} rounded="md" border={"1px solid"} borderColor="blue.500" shadow={"lg"} bg="white" fontWeight={"semibold"}>
                                    <Spinner size="sm" />
                                    <Text ml={2}>Carregando estações...</Text>
                                </Flex>
                            </Flex>
                        }
                        <PrintMode />
                    </Flex>

                </CardBody>
            </Card>
        </>
    );
}

function HeatmapComponent({ data, currentDataType = 'temp', resolution = 5, opacity = 0.6, exp = 3, isLoading, setLoading }) {
    const map = useMap();

    useEffect(() => {
        // Masking everything outside Brazil
        var worldPolygon = turf.polygon([[
            [-180, -90], [180, -90], [180, 90], [-180, 90], [-180, -90]
        ]]);
        var maskPolygon = turf.difference(worldPolygon, brazilGeoJson);

        L.geoJSON(maskPolygon, {
            style: {
                fillColor: '#ddd',
                fillOpacity: 1,
                color: 'white',
                opacity: 1
            }
        }).addTo(map);

        // Adding Brazil's GeoJSON
        L.geoJSON(brazilGeoJson, {
            style: {
                fillColor: 'transparent',
                fillOpacity: 0,
                color: 'transparent',
                weight: 0
            }
        }).addTo(map);
    }, [])

    useEffect(() => {
        window.map = map;
    }, [map])

    function getData(station) {
        switch (currentDataType) {
            case "temp_avg":
                return station.temp_avg;
            case "temp_max":
                return station.temp_max;
            case "temp_min":
                return station.temp_min;
        }
    }

    function generateHeatMap() {
        if (window.heatmapLayer) {
            map.removeLayer(window.heatmapLayer);
        }

        setTimeout(() => {
            window.heatmapLayer = L.idwLayer(
                data.map(s => {
                    return [
                        s.lat, s.long, getData(s)
                    ]
                }),
                {
                    opacity: parseFloat(opacity),
                    cellSize: parseInt(resolution),
                    exp: parseInt(exp),
                    max: 40,
                    min: -5,
                    onProcessStart: () => setLoading(true),
                    onProcessEnd: () => setLoading(false)
                }
            ).addTo(window.map);
        }, 1000);
    }

    useEffect(() => {
        generateHeatMap();
        return () => {
            if (window.heatmapLayer) {
                map.removeLayer(window.heatmapLayer);
            }
        }
    }, [currentDataType, resolution, opacity, exp]);

    // L.idwLayer(parsedStations, {opacity: 0.3, cellSize: 10, exp: 2, max: 1200}).addTo(map);

    return isLoading ? <Flex zIndex={999} position={"absolute"} bottom={0} left={0} right={0} p={4} justify={"center"}>

        <Flex fontSize={"sm"} className="theme-bg" align="center" justify={"center"} p={2} rounded="md" border={"1px solid"} borderColor="blue.500" shadow={"lg"} bg="white" fontWeight={"semibold"}>
            <Spinner size="sm" />
            <Text ml={2}>Gerando mapa de calor</Text>
        </Flex>

    </Flex> : <></>;
}
