import { createContext, useContext, useEffect, useState } from 'react';
import Storage from 'Helpers/Storage';
import { useLoading } from './LoadingProvider';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import AuthHelper from 'Helpers/AuthHelper';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const history = useHistory();
    const loading = useLoading();

    const toast = useToast({
        position: 'top-right'
    });

    async function checkToken() {
        if (Storage.exists('user')) {
            setUser(Storage.get('user'));
            loading.show();
            let valid = await AuthHelper.checkForTokenRefresh();
            if ((!valid || Storage.get('user') == 'undefined') && window.location.pathname.indexOf('members') !== -1) {
                logout('Sessão expirada, faça login novamente');
                history.push('/auth');
            }
            loading.hide();
        }
    }

    useEffect( () => {
        checkToken();
    }, []);

    const check = () => {
        return (Storage.exists('user') && Storage.get('user') != 'undefined') || Storage.exists('token');
    };

    const getUser = () => {
        return Storage.get('user');
    }

    const login = (user) => {
        Storage.set('user', user);
        AuthHelper.setRefreshDate();
    }

    const logout = (message = null) => {
        console.log("Logging out...");
        Storage.clear();
        toast({ status: 'info', title: message || 'Sessão encerrada com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
        history.push('/');
        return;
    }
    return (
        <AuthContext.Provider value={{ user, check, login, logout, getUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);
