import Cookie from "./Cookie";

const Favorites = {
    get: () => {
        return JSON.parse(Cookie.get('favorites') ?? '[]');
    },

    add: (identifier) => {
        let favorites = JSON.parse(getCookie('favorites') ?? '[]');
        favorites.push(identifier);
        Cookie.set('favorites', JSON.stringify(favorites));
    },

    remove: (identifier) => {
        let favorites = JSON.parse(getCookie('favorites') ?? '[]');
        favorites = favorites.filter(e => e != identifier);
        Cookie.set('favorites', JSON.stringify(favorites));
    },

    set: (favorites) => {
        Cookie.set('favorites', JSON.stringify(favorites));
    },

    reset: () => {
        Cookie.set('favorites', []);
    }
};

export default Favorites;
