import Client from './Client';

export const CulturesApi = {
    list: async (page, filter = '') => {
        return await Client.get(`/v1/cultures?page=${page}&filter=${filter}`);
    },
    // search: async (formData) => {
    //     return await Client.post(`/v1/stations/search`, formData);
    // },
    options: async () => {
        return await Client.get(`/v1/cultures/options`);
    },
    generate: async (values) => {
        return await Client.post(`/v1/cultures/generate`, values);
    },
    store: async (formData, hasFile = false) => {
        if (hasFile) {
            return await Client.postFile(`/v1/cultures`, formData);
        } else {
            return await Client.post(`/v1/cultures`, formData);
        }
    },
    update: async (id, formData) => {
        return await Client.put(`/v1/cultures/${id}`, formData);
    },
    delete: async (id) => {
        return await Client.destroy(`/v1/cultures/${id}`);
    },
};

export default CulturesApi;
