import React from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
} from "@chakra-ui/react";
import { useLoading } from "Providers/LoadingProvider";
import { Link, useHistory, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import AuthApi from "Api/AuthApi";

function RecoverToken() {
    const loading = useLoading();
    const history = useHistory();
    const toast = useToast({
        position: "top-right",
    });
    let { token } = useParams();

    let submitHandle = async (values, { setFieldError }) => {
        loading.show();
        let response = await AuthApi.redefine(token, values);
        if (response.status !== 200) {
            if (response.status === 422) {
                let errors = response.json.errors;
                for (let field in errors) {
                    setFieldError(field, errors[field].join(" "));
                }
            } else {
                toast({ status: 'error', title: response.json.error || 'Erro ao recuperar senha', duration: 3000, variant: 'solid', isClosable: true });
            }
        } else {
            toast({ status: 'success', title: 'Senha alterada com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            history.push("/auth/signin");
        }
        loading.hide();
    }

    // Chakra color mode
    const titleColor = useColorModeValue("blue.300", "blue.200");
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position="relative">
            <Flex w="100%" mx="auto" justifyContent="center">
                <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
                    <Flex direction="column" w="100%" background="transparent" p="24px">
                        <Heading color={titleColor} fontSize="32px" textAlign={"center"}>
                            Redefinição de Senha
                        </Heading>
                        <Text mb="15px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px" textAlign={"center"}>
                            Crie uma nova senha para acessar o sistema.
                        </Text>
                        <Formik onSubmit={submitHandle} initialValues={{ password: "", password_confirmation: ""}}>
                            <Form>
                                <Field name="password">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.password && form.touched.password} mb={2}>
                                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                Nova Senha:
                                            </FormLabel>
                                            <Input {...field} borderRadius="10px" fontSize="sm" type="password" size="lg"/>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="password_confirmation">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.password_confirmation && form.touched.password_confirmation}>
                                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                Confirme a senha:
                                            </FormLabel>
                                            <Input {...field} borderRadius="10px" fontSize="sm" type="password" size="lg"/>
                                            <FormErrorMessage>{form.errors.password_confirmation}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button type="submit" colorScheme={"green"} w="100%" h="45" mb={2} mt="20px">
                                    Redefinir minha senha
                                </Button>
                            </Form>
                        </Formik>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default RecoverToken;
