import Client from './Client';

export const BillingApi = {
    getCheckoutUrl: async (plan) => {
        return await Client.post('/v1/billing/checkout', { plan });
    },
    transactions: async (page = 1) => {
        return await Client.get(`/v1/billing/transactions?page=${page}`);
    }
};

export default BillingApi;
