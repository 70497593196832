import Recover from "views/Pages/Recover";
import RecoverToken from "views/Pages/RecoverToken";
import SignIn from "views/Pages/SignIn.js";
import SignUp from "views/Pages/SignUp.js";

var authRoutes = [
    {
        path: "/auth/signin",
        name: "SignIn",
        component: SignIn,
    },
    {
        path: "/auth/signup",
        name: "SignUp",
        component: SignUp,
    },
    {
        path: "/auth/recover",
        name: "Recover",
        component: Recover,
    },
    {
        path: "/auth/redefine/:token",
        name: "RecoverToken",
        component: RecoverToken,
    },
];
export default authRoutes;
