const PREFIX = '@WS-Consultoria-Climatologica';

const Storage = {
    get: (key) => {
        return JSON.parse(localStorage.getItem(`${PREFIX}/${key}`));
    },

    set: (key, value) => {
        value = value === undefined ? null : value;
        localStorage.setItem(`${PREFIX}/${key}`, JSON.stringify(value));
    },

    remove: (key) => {
        localStorage.removeItem(`${PREFIX}/${key}`);
    },

    exists: (key) => {
        return localStorage.getItem(`${PREFIX}/${key}`) != null;
    },

    clear: (except = null) => {
        console.log("Clearing")
        let old_value = null;
        if (except)
            old_value = Storage.get(except);

        localStorage.clear();

        if (except)
            Storage.set(except, old_value);
    }
};

export default Storage;
