import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    GridItem,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import CulturesApi from "Api/CulturesApi";
import StationsApi from "Api/StationsApi";
import { UserBHsApi } from "Api/UserBHsApi";
import UsersApi from "Api/UsersApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Field, Form, Formik } from "formik";
import Favorites from "Helpers/Favorites";
import Helper from "Helpers/Helper";
import { startCase } from "lodash";
import moment from "moment";
import 'moment/locale/pt-br'
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useRef, useState } from "react";
import { FaCalendar, FaEdit, FaPlus, FaSearch, FaSeedling, FaTrash } from "react-icons/fa";
import { Pagination } from "react-laravel-paginex";

function Users() {
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState("");
    const [data, setData] = useState({});
    const [currentUser, setCurrentUser] = useState({});
    const loading = useLoading();

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : page || 1;
        setPage(currentPage);
        let response = await UsersApi.list(currentPage, filter);
        setData(response.json);
        loading.hide();
    };

    useEffect(() => {
        getData();
    }, [filter]);

    const onFilterSubmit = (formData) => {
        setPage(1);
        setFilter(formData.filter);
    };

    //Delete
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const onAlertClose = () => setAlertIsOpen(false);

    return (
        <>
            <Card py={2} px={4}>
                <CardHeader>
                    <Flex
                        justify="space-between"
                        align="center"
                        minHeight="60px"
                        w="100%"
                    >
                        <Text fontSize="lg" fontWeight="bold">
                            Usuários
                        </Text>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" w="100%">
                        <Flex direction="column" w="100%" overflowX={"auto"} mb={4}>
                            <Formik onSubmit={onFilterSubmit} initialValues={{ search: "" }}>
                                <Form id="filterForm">
                                    <Field name="filter">
                                        {({ field, form }) => (
                                            <FormControl>
                                                <SimpleGrid columns={12}>
                                                    <GridItem colSpan={{ base: 12, md: 10 }}>
                                                        <Input
                                                            {...field}
                                                            mb={{ base: 2, md: 0 }}
                                                            borderRightRadius={{ base: 10, md: 0 }}
                                                            placeholder="Pesquisar por..."
                                                            borderRadius="10px"
                                                            fontSize="sm"
                                                            size="md"
                                                            pr="4.5rem"
                                                        />
                                                    </GridItem>
                                                    <GridItem colSpan={{ base: 12, md: 2 }}>
                                                        <Button
                                                            size="md"
                                                            borderLeftRadius={{ base: 15, md: 0 }}
                                                            type="submit"
                                                            w="100%"
                                                            leftIcon={<FaSearch />}
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </GridItem>
                                                </SimpleGrid>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            </Formik>
                        </Flex>
                        <Flex direction="column" w="100%" overflowX={"auto"}>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th whiteSpace={"nowrap"}>E-mail</Th>
                                        <Th>Telefone</Th>
                                        <Th>Cidade</Th>
                                        {/* <Th>Módulos</Th> */}
                                        <Th>Válido até</Th>
                                        <Th textAlign={"center"}>Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.data && data.data.length ? (
                                        data.data.map((user) => (
                                            <UserRow
                                                getData={getData}
                                                key={user.id}
                                                user={user}
                                                onDeleteOpen={() => {
                                                    setCurrentUser(user);
                                                    setAlertIsOpen(true);
                                                }}
                                            />
                                        ))
                                    ) : (
                                        <Tr>
                                            <Td colSpan={7} textAlign={"center"} color="gray.500">
                                                Nenhum usuário encontrado
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </Flex>
                        <Flex direction="column" w="100%">
                            <Pagination
                                changePage={getData}
                                data={data}
                                nextButtonText="Próxima"
                                prevButtonText="Anterior"
                            />
                            <Flex w={"100%"} justify="center" fontSize="sm" color="gray.500">
                                {data?.meta?.total ? (
                                    data.meta.filter ? (
                                        <Text>
                                            {data.meta.total} registros encontradas para "
                                            {data.meta.filter}", mostrando de {data.meta.from} a{" "}
                                            {data.meta.to}
                                        </Text>
                                    ) : (
                                        <Text>
                                            Mostrando de {data.meta.from} a {data.meta.to} de{" "}
                                            {data.meta.total} registros
                                        </Text>
                                    )
                                ) : (
                                    ""
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>
            <DeleteUser
                user={currentUser}
                isOpen={isAlertOpen}
                onClose={onAlertClose}
                getData={getData}
            />
        </>
    );
}

function UserRow({ user, getData }) {
    const userValidityModal = useDisclosure();
    const userBHsModal = useDisclosure();
    // const loading = useLoading();
    // const toast = useToast({
    //     position: "top-right",
    // });

    // function moduleEnabled(name) {
    //     return !!user.modules.find((module) => module.name === name);
    // }

    // async function toggleModule(id) {
    //     loading.show();
    //     let response = await UsersApi.toggleModule(user.id, id);
    //     loading.hide();
    //     if (response.status == 200) {
    //         toast({
    //             status: "success",
    //             title: "Usuário atualizado com sucesso!",
    //             duration: 3000,
    //             variant: "solid",
    //             isClosable: true,
    //         });
    //         getData();
    //     } else {
    //         toast({
    //             status: "error",
    //             title:
    //                 "Houve um problema ao atualizar o usuário! Cód. " + response.status,
    //             duration: 3000,
    //             variant: "solid",
    //             isClosable: true,
    //         });
    //     }
    //     getData;
    // }

    return (
        <Tr>
            <Td>{user.name}</Td>
            <Td>{user.email}</Td>
            <Td>{user.phone}</Td>
            <Td>
                {user.city}/{user.state}
            </Td>
            {/* <Td>{user.modules.map((module) => module.name).join(", ") || "-"}</Td> */}
            <Td>
                <ValidityText user={user} />
            </Td>
            <Td textAlign={"center"}>
                <Button
                    colorScheme="blue"
                    variant="solid"
                    size={"sm"}
                    onClick={userValidityModal.onOpen}
                    leftIcon={<FaCalendar />}
                    m={2}
                >
                    Ajustar Validade
                </Button>

                <Button
                    m={2}
                    colorScheme={"green"}
                    variant="solid"
                    size={"sm"}
                    onClick={userBHsModal.onOpen}
                    leftIcon={<FaSeedling />}
                >
                    Balanços Hídricos
                </Button>
                <Text fontSize={"xs"} opacity={0.7}>{user.bhs?.length > 0 ? `${user.bhs?.length} BH(s) cadastrado(s)` : ""}</Text>
                <UserValidityModal
                    user={user}
                    isOpen={userValidityModal.isOpen}
                    onClose={userValidityModal.onClose}
                    getData={getData}
                />
                <UserBHsModal
                    user={user}
                    isOpen={userBHsModal.isOpen}
                    onClose={userBHsModal.onClose}
                    getData={getData}
                />
            </Td>
            {/* <Td>
                <Button p="0px" bg="transparent" mb={{ sm: "10px", md: "0px" }} me={{ md: "12px" }} onClick={() => { onDeleteOpen()}}>
                    <Flex color="red.500" cursor="pointer" align="center" p="12px">
                        <Icon as={FaTrashAlt} me="4px" />
                        <Text fontSize="sm" fontWeight="semibold">
                            EXCLUIR
                        </Text>
                    </Flex>
                </Button>
            </Td> */}
        </Tr>
    );
}

function DeleteUser({ user, isOpen, onClose, getData }) {
    const toast = useToast({
        position: "top-right",
    });
    const onDelete = async () => {
        let response = await UsersApi.delete(user.id);
        if (response.status == 204) {
            toast({
                status: "success",
                title: "Usuário excluído com sucesso!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
            onClose();
            getData();
        } else {
            toast({
                status: "error",
                title:
                    "Houve um problema ao excluir o usuário! Cód. " + response.status,
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
        }
    };

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Excluir Usuário
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Você tem certeza que deseja excluir o usuário: <b>{user.name}</b>?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>Não, Cancelar</Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3}>
                            Sim, Excluir
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

function UserValidityModal({ user, isOpen, onClose, getData }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right",
    });

    async function onSubmit(formData) {
        loading.show();
        const response = await UsersApi.validity(user.id, formData);
        loading.hide();
        if (response.status == 200) {
            user.expirity = formData.date;
            toast({
                status: "success",
                title: "Validade atualizada com sucesso!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
            getData();
            onClose();
        } else {
            toast({
                status: "error",
                title: "Erro ao atualizar validade!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
        }
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Validade da Assinatura</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={8}>
                        <Flex direction={"column"} mb={4}>
                            <Flex>
                                <Text mr={2} fontWeight="semibold">
                                    Usuário:{" "}
                                </Text>{" "}
                                <Text>{user.name}</Text>
                            </Flex>
                            <Flex>
                                <Text mr={2} fontWeight="semibold">
                                    Validade:{" "}
                                </Text>{" "}
                                <Text>
                                    <ValidityText user={user} />
                                </Text>
                            </Flex>
                        </Flex>

                        <Formik onSubmit={onSubmit} initialValues={{ date: "" }}>
                            <Form id="validityForm">
                                <Field name="date">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel>Nova Validade:</FormLabel>
                                            <Input
                                                {...field}
                                                type="date"
                                                borderRadius="10px"
                                                fontSize="sm"
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                            </Form>
                        </Formik>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" form="validityForm" type="submit" mr={3}>
                            Aplicar Validade
                        </Button>
                        <Button colorScheme="red" onClick={onClose}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function ValidityText({ user }) {
    return (
        <>
            {user.canceled_at ? (
                <Text color="red.500" fontWeight={"semibold"}>
                    Suspenso em {Helper.formatDate(user.canceled_at)}
                </Text>
            ) : user.is_active ? (
                Helper.usToBrDate(user.expirity)
            ) : (
                <Text color="red.500" fontWeight={"semibold"}>
                    Inativo
                </Text>
            )}
        </>
    );
}

function UserBHsModal({ user, isOpen, onClose, getData }) {
    const loading = useLoading();
    const createBHFormDisclosure = useDisclosure();

    const [data, setData] = useState({});

    const loadBHsData = async () => {
        loading.show();
        let response = await UserBHsApi.list(user.id);
        setData(response.json);
        loading.hide();
    };

    useEffect(() => {
        if (isOpen) {
            loadBHsData();
        }
    }, [isOpen]);

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="6xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Balanços Hídricos de {user.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={8}>
                        <Flex justifyContent={"end"} mb={2}>
                            <Button colorScheme="green" leftIcon={<FaPlus/>} size="sm" onClick={createBHFormDisclosure.onOpen}>Criar</Button>

                            <UserBHFormDrawer user={user} getData={loadBHsData} isOpen={createBHFormDisclosure.isOpen} onClose={createBHFormDisclosure.onClose} />
                        </Flex>
                        <Flex direction="column" w="100%" overflowX={"auto"}>
                            <Table size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Nome</Th>
                                        <Th>Estação</Th>
                                        <Th>Irrigação</Th>
                                        <Th>Cultura</Th>
                                        <Th>Data de Início</Th>
                                        <Th textAlign={"center"}>Ações</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {data.bhs && data.bhs.length ? (
                                        data.bhs.map((bh) => (
                                            <UserBHRow getData={loadBHsData} user={user} key={bh.id} bh={bh} />
                                        ))
                                    ) : (
                                        <Tr>
                                            <Td colSpan={6} textAlign={"center"} color="gray.500">
                                                Usuário não possui BHs
                                            </Td>
                                        </Tr>
                                    )}
                                </Tbody>
                            </Table>
                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" onClick={() => {
                            getData();
                            onClose();
                        }}>
                            Fechar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

function UserBHRow({ bh, user, getData }) {
    const userBHEditModal = useDisclosure();
    const userBHDeleteModal = useDisclosure();

    return (
        <Tr>
            <Td>{bh.name}</Td>
            <Td>{bh.station.full_name}</Td>
            <Td>
                {bh.irrigation_type} ({bh.irrigation_efficiency * 100}%)
            </Td>
            <Td>{bh.culture.name} - {bh.culture.cycle}dias<br/>{ startCase(bh.culture.soil?.replaceAll('_', ' '))}</Td>
            <Td>{moment(bh.start_date).locale('pt-br').format("ll")}</Td>
            <Td textAlign={"center"}>
                <Button
                    colorScheme="red"
                    variant="solid"
                    size={"sm"}
                    leftIcon={<FaTrash />}
                    m={2}
                    onClick={userBHDeleteModal.onOpen}
                >
                    Excluir
                </Button>

                <Button colorScheme="blue" leftIcon={<FaEdit/>} size="sm" onClick={userBHEditModal.onOpen}>Editar</Button>
                <UserBHFormDrawer user={user} bh={bh} getData={getData} isOpen={userBHEditModal.isOpen} onClose={userBHEditModal.onClose} />
                <DeleteBHModal bh={bh} user={user} isOpen={userBHDeleteModal.isOpen} onClose={userBHDeleteModal.onClose} getData={getData} />
            </Td>
            {/* <Td>
                <Button p="0px" bg="transparent" mb={{ sm: "10px", md: "0px" }} me={{ md: "12px" }} onClick={() => { onDeleteOpen()}}>
                    <Flex color="red.500" cursor="pointer" align="center" p="12px">
                        <Icon as={FaTrashAlt} me="4px" />
                        <Text fontSize="sm" fontWeight="semibold">
                            EXCLUIR
                        </Text>
                    </Flex>
                </Button>
            </Td> */}
        </Tr>
    );
}


function DeleteBHModal({ bh, user, isOpen, onClose, getData }) {
    const cancelRef = useRef();
    const toast = useToast({
        position: "top-right"
    });
    const onDelete = async () => {
        let response = await UserBHsApi.delete(user.id, bh.id);
        if (response.status == 204) {
            toast({ status: 'success', title: 'BH excluído com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            onClose();
            getData();
        } else {
            toast({ status: 'error', title: 'Houve um problema ao excluir oBH! Cód. ' + response.status, duration: 3000, variant: 'solid', isClosable: true });
        }
    };

    return (
        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Excluir Balanço Hídrico
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Você tem certeza que deseja excluir o Balanço Hídrico: <b>{bh?.name}</b>?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Não, Cancelar
                        </Button>
                        <Button colorScheme='red' onClick={onDelete} ml={3}>
                            Sim, Excluir
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}



function UserBHFormDrawer({ isOpen, onClose, bh, getData, user }) {
    const loading = useLoading();
    const toast = useToast({
        position: "top-right"
    });


    const [cultures, setCultures] = useState([]);
    const [stations, setStations] = useState([]);
    const favorites = Favorites.get();


    useEffect(() => {
        if (!isOpen) return;

        loadStationOptions();
        loadCultureOptions();
    }, [isOpen]);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json || []);
        loading.hide();
    }


    async function loadCultureOptions() {
        loading.show();
        let response = await CulturesApi.options();
        setCultures(response.json.data || []);
        loading.hide();
    }


    const submitHandle = async (values) => {
        loading.show();
        let data = null;

        try {
            if (bh?.id) {
                data = await UserBHsApi.update(user.id, bh.id, values);
            } else {
                data = await UserBHsApi.store(user.id, values);
            }
        } catch (err) {
            toast({ status: 'error', title: err?.response?.data?.message || "Erro ao salvar BH, verifique seus dados e tente novamente!", duration: 5000, variant: 'solid', isClosable: true });
            loading.hide();
        }

        if (data.status >= 200 && data.status < 300) {
            toast({ status: 'success', title: 'BH salvo com sucesso!', duration: 3000, variant: 'solid', isClosable: true });
            getData();
            onClose();
            loading.hide();
        } else {
            toast({ status: 'error', title: 'Erro ao salvar o BH! Cód. ' + data.status, duration: 3000, variant: 'solid', isClosable: true });
            loading.hide();
        }
    }

    let initialValues = {
        name: bh?.name || '',
        station_id: bh?.station_id || '',
        irrigation_type: bh?.irrigation_type || '',
        start_date: bh?.start_date || '',
        soil: bh?.culture?.soil || '',
        culture_id: bh?.culture_id || '',
    };

    let irrigationTypes = {
        'Gotejamento': 0.95,
        'Micro Aspersores': 0.8,
        'Xique-Xique': 0.85,
        'Rolo': 0.6,
        'Aspersao Convencional': 0.65,
        'Pivo Central': 0.85,
        'Sulco': 0.3,
        'Sem Irrigação': 0,
    };

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{bh?.id ? 'Editar' : 'Novo'} Balanço Hídrico</DrawerHeader>
                <DrawerBody>

                    <Formik onSubmit={submitHandle} initialValues={initialValues} enableReinitialize={true}>
                        {props => (
                            <Form id="bhForm">

                                <Flex flexDirection="row" flexWrap w="100%">
                                    <SimpleGrid w="100%" columns={12} gap={4}>

                                        <GridItem colSpan={12}>
                                            <Field name="name">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="name" whiteSpace={"nowrap"} fontWeight="normal">Nome:</FormLabel>
                                                        <Input id="name" {...field} borderRadius="10px" fontSize="md" type="text" placeholder="Safra Soja 2023 - XYZ" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="station_id">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="station_id" fontWeight="normal">Estação:</FormLabel>
                                                        <Select id="station_id" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione a Estação...">
                                                            {
                                                                !favorites || !favorites.length ?
                                                                    (
                                                                        stations.map(station => (
                                                                            <option value={station.id}>{station.text}</option>
                                                                        ))
                                                                    )
                                                                    :
                                                                    (
                                                                        <>
                                                                            <optgroup key="favorites" label="Estações Favoritas">
                                                                                {stations.map(station => {
                                                                                    if (favorites.includes(station.identifier))
                                                                                        return (<option value={station.id}>{station.text}</option>);
                                                                                    else
                                                                                        return "";
                                                                                })}
                                                                            </optgroup>
                                                                            <optgroup key="others" label="Outras Estações">
                                                                                {stations.map(station => {
                                                                                    if (!favorites.includes(station.identifier))
                                                                                        return (<option value={station.id}>{station.text}</option>);
                                                                                    else
                                                                                        return "";
                                                                                })}
                                                                            </optgroup>
                                                                        </>
                                                                    )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>



                                        <GridItem colSpan={12}>
                                            <Field name="irrigation_type">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="irrigation_type" fontWeight="normal">Tipo de Irrigação:</FormLabel>
                                                        <Select id="irrigation_type" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione o tipo de irrigação...">
                                                            {
                                                                Object.keys(irrigationTypes).map(type => <option key={type} value={type}>{type} ({irrigationTypes[type]*100}%)</option>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="start_date">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="start_date" whiteSpace={"nowrap"} fontWeight="normal">Data de Início:</FormLabel>
                                                        <Input id="start_date" {...field} borderRadius="10px" fontSize="md" type="date" />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>




                                        <GridItem colSpan={12}>
                                            <Field name="soil">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="soil" fontWeight="normal">Tipo de Solo:</FormLabel>
                                                        <Select id="soil" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione o Solo..." onChange={(e) => {
                                                            props.setValues({
                                                                ...props.values,
                                                                soil: e.target.value,
                                                                culture_id: null,
                                                            });
                                                        }}>
                                                            <option value="argiloso">Argiloso</option>
                                                            <option value="medio">Médio</option>
                                                            <option value="arenoso">Arenoso</option>
                                                            <option value="muito_argiloso">Muito Argiloso</option>
                                                            <option value="muito_arenoso">Muito Arenoso</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="culture_id">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel mb="0" htmlFor="culture_id" fontWeight="normal">Cultura:</FormLabel>
                                                        <Select id="culture_id" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione a cultura...">
                                                            {
                                                                props.values.soil && (cultures || [])
                                                                    ?.filter(culture => culture.soil == props.values.soil)
                                                                    ?.map(culture => <option key={culture.name} value={culture.id}>{culture.name} - {culture.cycle} dias</option>)
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>



                                    </SimpleGrid>
                                </Flex>
                            </Form>
                        )}
                    </Formik>


                </DrawerBody>
                <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type='submit' form='bhForm' colorScheme="whatsapp">
                        Salvar
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}

export default Users;
