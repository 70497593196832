
import { Button, Flex, Switch, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { SettingsIcon } from "components/Icons/Icons";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React, { useState } from "react";
import { FaMoon, FaSun } from "react-icons/fa";
import { Link } from "react-router-dom";
import routes from "member_routes.js";
import SobreNos from "views/Public/Components/SobreNos";

export default function HeaderLinks(props) {
    const { variant, children, secondary, onOpen, ...rest } = props;
    const [isSobreNosOpen, setIsSobreNosOpen] = useState(false)

    // Chakra Color Mode
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarIcon = useColorModeValue("gray.500", "gray.200");
    const { colorMode, toggleColorMode } = useColorMode();

    if (secondary) {
        navbarIcon = "white";
        mainText = "white";
    }

    return (
        <Flex w={{ sm: "100%", md: "auto" }} alignItems="center" justifyContent={{ sm: "space-between", md: "right"}} flexDirection="row">
            <SidebarResponsive
                logoText={props.logoText}
                secondary={props.secondary}
                routes={routes}
                // logo={logo}
                {...rest}
            />
            <Flex alignItems={"center"}>
                <FaSun fontSize={14}/>
                <Switch size="sm" mx={2} onChange={toggleColorMode} defaultChecked={colorMode === 'dark'} />
                <FaMoon fontSize={14}/>
            </Flex>

        </Flex>
    );
}
