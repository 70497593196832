import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from "@chakra-ui/react";

function SobreNos({ isOpen, setIsOpen }) {
    const onClose = () => setIsOpen(false)

    return (
        <AlertDialog isOpen={isOpen} onClose={onClose} size="5xl" motionPreset='slideInBottom'>
            <AlertDialogOverlay>
                <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Quem somos nós?
                </AlertDialogHeader>

                <AlertDialogBody>
                    Ainda jovem, o William Siqueira já começou a medir as temperaturas de Maria da Fé com um termômetro simples, com o intuito de divulgar principalmente as épocas frias da cidade, pela qual é conhecida e também a primeira cidade a extrair o azeite extra virgem no Brasil. Com o passar dos anos foi evoluindo para termômetros mais robustos até chegar à primeira estação meteorológica particular da cidade. Atualmente, o número de estações em Maria da Fé já cresceu para nove e o projeto já expandiu para mais de 200 estações espalhadas por todos os estados do Brasil.
                    <br/><br/>
                    Hoje a WS Consultoria Climatológica atua na área de vendas, instalação e manutenção de equipamentos meteorológicos. Trabalha também na geração de relatórios climatológicos para produtores rurais nos ramos da Olivicultura e da Vinicultura, auxiliamos mais de 150 produtores do Brasil inteiro com relatórios climatológicos mensais e anuais. Somos a única empresa no Brasil a ter um banco de dados de mais de 200 estações meteorológicas com informações de horas frio acumuladas ( Muito importante na produção de azeite) umidade diária e relatórios de temperatura.
                    <br/><br/>
                    <i>"Fico muito feliz pela constante ampliação do meu projeto e por trazer tantos benefícios para toda população onde atuamos, principalmente para os produtores que podem mapear melhor o clima das cidades e se adiantar a problemas relacionados ao tempo que afetam suas plantações. Agora o produtor que tem uma parceria firmada com a WS Consultoria Climatológica, vai ter um diferencial para agregar mais valor e informações preciosas de tempo na sua produção, dessa forma beneficiando ainda mais todo o público que precisa desse tipo de conteúdo"</i>, enaltece o CEO da WS Consultoria Climatológica, William Siqueira.
                </AlertDialogBody>

                <AlertDialogFooter>
                    <Button onClick={onClose} colorScheme="blue">
                        Fechar
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

export default SobreNos;
