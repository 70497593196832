import React, { useEffect } from "react";
// Chakra imports
import {
    Box,
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Switch,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
} from "@chakra-ui/react";
import { useLoading } from "Providers/LoadingProvider";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "Providers/AuthProvider";
import { Field, Form, Formik } from "formik";
import AuthApi from "Api/AuthApi";
import Storage from "Helpers/Storage";

function SignIn() {
    const loading = useLoading();
    const history = useHistory();
    const auth = useAuth();
    const toast = useToast({
        position: "top-right",
    });

    let submitHandle = async (values) => {
        loading.show();
        let response = null;
        try {
            response = await AuthApi.signIn(values);
        } catch (err) {
            response = err.response;
        }

        if (response.status !== 200) {
            if (response.status === 401)
                toast({ status: 'error', title: 'Usuário ou senha incorretos!', duration: 3000, variant: 'solid', isClosable: true });
            else
                toast({ status: 'error', title: 'Erro ao autenticar!', duration: 3000, variant: 'solid', isClosable: true });

            loading.hide();
        } else {
            Storage.set('token', response.json.token);
            let responseMe = await AuthApi.me();
            if (responseMe.status !== 200) {
                auth.logout('Erro ao obter informações do servidor, entre em contato com o suporte.');
                loading.hide();
            } else {
                auth.login(responseMe.json.data);
                window.gtag('event', 'login');
                setTimeout( () => {
                    toast({ status: 'success', title: 'Login realizado com sucesso! Bem-vindo.', duration: 3000, variant: 'solid', isClosable: true });
                    history.push("/members/home");
                }, 300);
            }
        }
    }

    // Chakra color mode
    const titleColor = useColorModeValue("blue.300", "blue.200");
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position="relative">
            <Flex w="100%" mx="auto" justifyContent="center">
                <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "50%", lg: "42%" }}>
                    <Flex direction="column" w="100%" background="transparent" p="24px">
                        <Heading color={titleColor} fontSize="32px" textAlign={"center"}>
                            Bem-vindo
                        </Heading>
                        <Text mb="15px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px" textAlign={"center"}>
                            Digite seu e-mail e senha para acessar sua conta.
                        </Text>
                        <Formik onSubmit={submitHandle} initialValues={{ email: "", password: "", remember: false }}>
                            <Form>
                                <Field name="email">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.email && form.touched.email}>
                                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                E-mail
                                            </FormLabel>
                                            <Input {...field} borderRadius="10px" mb="24px" fontSize="sm" type="text" placeholder="Seu e-mail" size="lg"/>
                                            <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="password">
                                    {({ field, form }) => (
                                        <FormControl isInvalid={form.errors.password && form.touched.password}>
                                            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                                                Senha
                                            </FormLabel>
                                            <Input {...field} borderRadius="10px" mb="36px" fontSize="sm" type="password" placeholder="Sua senha" size="lg"/>
                                            <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="remember">
                                    {({ field, form }) => (
                                        <FormControl display="flex" alignItems="center" isInvalid={form.errors.remember && form.touched.remember}>
                                            <Switch id="remember" colorScheme="teal" me="10px" {...field} />
                                            <FormLabel htmlFor="remember" mb="0" ms="1" fontWeight="normal">
                                                Manter logado
                                            </FormLabel>
                                            <FormErrorMessage>{form.errors.remember}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Button type="submit" colorScheme={"blue"} w="100%" h="45" mb={2} mt="20px">
                                    Login
                                </Button>
                            </Form>
                        </Formik>
                        <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px" mb={2}>
                            <Text color={textColor} fontWeight="medium" textAlign={"center"}>
                                <Link to="/auth/recover">
                                    <Text color={titleColor} as="span" ms="5px" fontWeight="bold">
                                        Esqueci minha senha
                                    </Text>
                                </Link>
                            </Text>
                        </Flex>
                        <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
                            <Text color={textColor} fontWeight="medium" textAlign={"center"}>
                                Ainda não tem uma conta? Teste Grátis por 30 Dias<br/>
                                <Link to="/auth/signup">
                                    <Text color={titleColor} as="span" ms="5px" fontWeight="bold">
                                        Crie sua conta já
                                    </Text>
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignIn;
