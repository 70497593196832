import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    FormControl,
    FormLabel,
    GridItem,
    Icon,
    IconButton,
    Input,
    Select,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useDisclosure,
    useToast,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Field, Form, Formik } from "formik";
import { useLoading } from "Providers/LoadingProvider";
import { useEffect, useRef, useState } from "react";
import {
    FaPencilAlt,
    FaPlusCircle,
    FaSearch,
    FaTrashAlt,
} from "react-icons/fa";
import { Pagination } from "react-laravel-paginex";
import "views/Components/pagination.css";
import CulturesApi from "Api/CulturesApi";
import Helper from "Helpers/Helper";
import NoticeApi from "Api/NoticeApi";
import Favorites from "Helpers/Favorites";
import StationsApi from "Api/StationsApi";
import { TbX } from "react-icons/tb";

function Notices() {
    const [page, setPage] = useState(1);
    const [data, setData] = useState({});
    const [current, setCurrent] = useState({});
    const loading = useLoading();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [filter, setFilter] = useState("");
    const textColor = useColorModeValue("gray.700", "white");

    const getData = async (param) => {
        loading.show();
        let currentPage = param ? param.page : page || 1;
        setPage(currentPage);
        let response = await NoticeApi.list(currentPage, filter);
        setData(response.json);
        loading.hide();
    };

    //Delete
    const [isAlertOpen, setAlertIsOpen] = useState(false);
    const onAlertClose = () => setAlertIsOpen(false);

    useEffect(() => {
        getData();
    }, [filter]);

    const onFilterSubmit = (formData) => {
        setPage(1);
        setFilter(formData.filter);
    };

    function getLabel(type) {
        switch (type) {
            case "temp":
                return "Temperatura";
            case "offline":
                return "Offline";
            case "humidity":
                return "Umidade";
            case "wind_speed":
                return "Velocidade do Vento";
            case "wind_gust":
                return "Rajada de Vento";
            case "rain_total":
                return "Chuva acumulada";
            case "rain_rate":
                return "Taxa de chuva";
        }
    }

    return (
        <>
            <Card py={2} px={4}>
                <CardHeader>
                    <Flex
                        justify="space-between"
                        align="center"
                        minHeight="60px"
                        w="100%"
                    >
                        <Text fontSize="lg" fontWeight="bold">
                            Alertas
                        </Text>
                        <Button
                            onClick={() => {
                                setCurrent({});
                                onOpen();
                            }}
                            leftIcon={<FaPlusCircle />}
                            colorScheme="whatsapp"
                            fontSize="md"
                        >
                            Novo
                        </Button>
                    </Flex>
                </CardHeader>
                <CardBody>
                    <Flex direction="column" w="100%">
                        <Flex direction="column" w="100%" overflowX={"auto"} mb={4}>
                            <Formik onSubmit={onFilterSubmit} initialValues={{ search: "" }}>
                                <Form id="filterForm">
                                    <Field name="filter">
                                        {({ field, form }) => (
                                            <FormControl>
                                                <SimpleGrid columns={12}>
                                                    <GridItem colSpan={{ base: 12, md: 10 }}>
                                                        <Input
                                                            {...field}
                                                            mb={{ base: 2, md: 0 }}
                                                            borderRightRadius={{ base: 10, md: 0 }}
                                                            placeholder="Pesquisar por..."
                                                            borderRadius="10px"
                                                            fontSize="sm"
                                                            size="md"
                                                            pr="4.5rem"
                                                        />
                                                    </GridItem>
                                                    <GridItem colSpan={{ base: 12, md: 2 }}>
                                                        <Button
                                                            size="md"
                                                            borderLeftRadius={{ base: 15, md: 0 }}
                                                            type="submit"
                                                            w="100%"
                                                            leftIcon={<FaSearch />}
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </GridItem>
                                                </SimpleGrid>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            </Formik>
                        </Flex>
                        <Flex direction="column" w="100%">
                            <Flex direction="column" w="100%" overflowX={"auto"}>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>Nome</Th>
                                            <Th>Estações</Th>
                                            <Th>Condição</Th>
                                            <Th textAlign={"center"}>Ações</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {data.data && data.data.length ? (
                                            data.data.map((notice) => (
                                                <Tr key={notice.id}>
                                                    <Td>{notice.name}</Td>
                                                    <Td>{notice.stations?.map(s => s.name).join(", ")}</Td>
                                                    <Td>
                                                        {getLabel(notice.type)}{" "}
                                                        {notice.type != "offline"
                                                            ? notice.rule == "above"
                                                                ? "acima de "
                                                                : "abaixo de "
                                                            : ""}{" "}
                                                        {notice.type != "offline" ? notice.limit : ""}
                                                    </Td>
                                                    <Td textAlign={"center"}>
                                                        <Button
                                                            p="0px"
                                                            bg="transparent"
                                                            mb={{ sm: "10px", md: "0px" }}
                                                            me={{ md: "12px" }}
                                                            onClick={() => {
                                                                setCurrent(notice);
                                                                setAlertIsOpen(true);
                                                            }}
                                                        >
                                                            <Flex
                                                                color="red.500"
                                                                cursor="pointer"
                                                                align="center"
                                                                p="12px"
                                                            >
                                                                <Icon as={FaTrashAlt} me="4px" />
                                                                <Text fontSize="sm" fontWeight="semibold">
                                                                    EXCLUIR
                                                                </Text>
                                                            </Flex>
                                                        </Button>

                                                        <Button
                                                            p="0px"
                                                            bg="transparent"
                                                            onClick={() => {
                                                                setCurrent(notice);
                                                                onOpen();
                                                            }}
                                                        >
                                                            <Flex
                                                                color={textColor}
                                                                cursor="pointer"
                                                                align="center"
                                                                p="12px"
                                                            >
                                                                <Icon as={FaPencilAlt} me="4px" />
                                                                <Text fontSize="sm" fontWeight="semibold">
                                                                    EDITAR
                                                                </Text>
                                                            </Flex>
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            ))
                                        ) : (
                                            <Tr>
                                                <Td colSpan={4} textAlign={"center"} color="gray.500">
                                                    Nenhuma alerta encontrada
                                                </Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </Table>
                            </Flex>
                            <Pagination
                                changePage={getData}
                                data={data}
                                nextButtonText="Próxima"
                                prevButtonText="Anterior"
                            />
                            <Flex w={"100%"} justify="center" fontSize="sm" color="gray.500">
                                {data?.meta?.total ? (
                                    data.meta.filter ? (
                                        <Text>
                                            {data.meta.total} registros encontradas para "
                                            {data.meta.filter}", mostrando de {data.meta.from} a{" "}
                                            {data.meta.to}
                                        </Text>
                                    ) : (
                                        <Text>
                                            Mostrando de {data.meta.from} a {data.meta.to} de{" "}
                                            {data.meta.total} registros
                                        </Text>
                                    )
                                ) : (
                                    ""
                                )}
                            </Flex>
                        </Flex>
                    </Flex>
                </CardBody>
            </Card>

            <FormDrawer
                isOpen={isOpen}
                onClose={onClose}
                notice={current}
                getData={getData}
            />
            <Delete
                notice={current}
                isOpen={isAlertOpen}
                onClose={onAlertClose}
                getData={getData}
            />
        </>
    );
}

function Delete({ notice, isOpen, onClose, getData }) {
    const cancelRef = useRef();
    const toast = useToast({
        position: "top-right",
    });
    const onDelete = async () => {
        let response = await NoticeApi.delete(notice.id);
        if (response.status == 204) {
            toast({
                status: "success",
                title: "Alerta excluído com sucesso!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
            onClose();
            getData();
        } else {
            toast({
                status: "error",
                title: "Houve um problema ao excluir o alerta! Cód. " + response.status,
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
        }
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        Excluir Alerta
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Você tem certeza que deseja excluir este alerta:{" "}
                        <b>{notice?.name}</b>?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Não, Cancelar
                        </Button>
                        <Button colorScheme="red" onClick={onDelete} ml={3}>
                            Sim, Excluir
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
}

function FormDrawer({ isOpen, onClose, notice, getData }) {
    const [stations, setStations] = useState([]);
    const favorites = Favorites.get();
    const loading = useLoading();
    const toast = useToast({
        position: "top-right",
    });

    useEffect(() => {
        loadStationOptions();
    }, []);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json);
        loading.hide();
    }

    const submitHandle = async (values) => {
        loading.show();
        let data = null;

        try {
            if (notice?.id) {
                data = await NoticeApi.update(notice.id, values);
            } else {
                data = await NoticeApi.store(values);
            }
        } catch (err) {
            toast({
                status: "error",
                title:
                    err?.response?.data?.message ||
                    "Erro ao salvar alerta, verifique seus dados e tente novamente!",
                duration: 5000,
                variant: "solid",
                isClosable: true,
            });
            loading.hide();
        }

        if (data.status >= 200 && data.status < 300) {
            toast({
                status: "success",
                title: "Alerta salvo com sucesso!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
            getData();
            onClose();
            loading.hide();
        } else {
            toast({
                status: "error",
                title: "Erro ao salvar a alerta! Cód. " + data.status,
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
            loading.hide();
        }
    };

    let initialValues = {
        name: notice?.name ?? null,
        type: notice?.type ?? null,
        email: notice?.email ?? null,
        rule: notice?.rule ?? null,
        limit: notice?.limit ?? null,
        interval: notice?.interval ?? null,
        stations: notice?.stations?.map(s => s.id) || [],
    };

    return (
        <Drawer isOpen={isOpen} onClose={onClose} size="md">
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>{notice?.id ? "Editar" : "Novo"} Alerta</DrawerHeader>
                <DrawerBody>
                    <Formik
                        onSubmit={submitHandle}
                        initialValues={initialValues}
                        enableReinitialize={true}
                    >
                        {(props) => (
                            <Form id="noticeForm">
                                <Flex flexDirection="row" flexWrap w="100%">
                                    <SimpleGrid w="100%" columns={12} gap={4}>
                                        <GridItem colSpan={12}>
                                            <Field name="name">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel
                                                            mb="0"
                                                            htmlFor="name"
                                                            whiteSpace={"nowrap"}
                                                            fontWeight="normal"
                                                        >
                                                            Nome:
                                                        </FormLabel>
                                                        <Input
                                                            id="name"
                                                            {...field}
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            type="text"
                                                            placeholder="Temperatura acima de X"
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="stations">
                                                {({ field, form }) => (
                                                    <FormControl>
                                                        <FormLabel mb="0" fontWeight="normal">
                                                            Estações:
                                                        </FormLabel>
                                                        <Select
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            placeholder="Adicionar estação..."
                                                            onChange={(e) => {
                                                                if (!e.target.value || (form.values.stations || []).indexOf(Number(e.target.value)) !== -1) {
                                                                    e.target.value = "";
                                                                    return;
                                                                }

                                                                field.onChange({
                                                                    target: {
                                                                        name: "stations",
                                                                        value: [
                                                                            e.target.value,
                                                                            ...(form.values.stations || []),
                                                                        ],
                                                                    },
                                                                });

                                                                e.target.value = "";
                                                            }}
                                                        >
                                                            {!favorites || !favorites.length ? (
                                                                stations.map((station, k) => (
                                                                    <option key={k} value={station.id}>
                                                                        {station.text}
                                                                    </option>
                                                                ))
                                                            ) : (
                                                                <>
                                                                    <optgroup
                                                                        key="favorites"
                                                                        label="Estações Favoritas"
                                                                    >
                                                                        {stations.map((station, k) => {
                                                                            if (
                                                                                favorites.includes(station.identifier)
                                                                            )
                                                                                return (
                                                                                    <option key={k} value={station.id}>
                                                                                        {station.text}
                                                                                    </option>
                                                                                );
                                                                            else return "";
                                                                        })}
                                                                    </optgroup>
                                                                    <optgroup
                                                                        key="others"
                                                                        label="Outras Estações"
                                                                    >
                                                                        {stations.map((station, k) => {
                                                                            if (
                                                                                !favorites.includes(station.identifier)
                                                                            )
                                                                                return (
                                                                                    <option key={k} value={station.id}>
                                                                                        {station.text}
                                                                                    </option>
                                                                                );
                                                                            else return "";
                                                                        })}
                                                                    </optgroup>
                                                                </>
                                                            )}
                                                        </Select>
                                                        <Flex direction="column" gridGap={2} mt={2}>
                                                            {form.values?.stations?.map((station_id) => (
                                                                <Flex
                                                                    align="center"
                                                                    gridGap={1}
                                                                    whiteSpace={"nowrap"}
                                                                    overflowX={"hidden"}
                                                                >
                                                                    <IconButton
                                                                        size="xs"
                                                                        variant="ghost"
                                                                        colorScheme="red"
                                                                        icon={<TbX />}
                                                                        onClick={() =>
                                                                            field.onChange({
                                                                                target: {
                                                                                    name: "stations",
                                                                                    value: (
                                                                                        form.values.stations || []
                                                                                    ).filter((s) => s != station_id),
                                                                                },
                                                                            })
                                                                        }
                                                                    />
                                                                    <Text fontSize={"xs"}>
                                                                        {
                                                                            stations.find((st) => st.id == station_id)
                                                                                ?.text
                                                                        }
                                                                    </Text>
                                                                </Flex>
                                                            ))}
                                                        </Flex>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="type">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel
                                                            mb="0"
                                                            htmlFor="type"
                                                            fontWeight="normal"
                                                        >
                                                            Tipo de Dado:
                                                        </FormLabel>
                                                        <Select
                                                            id="type"
                                                            {...field}
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            placeholder="Selecione..."
                                                        >
                                                            <option value="temp">Temperatura</option>
                                                            <option value="humidity">Umidade</option>
                                                            <option value="wind_speed">
                                                                Velocidade do Vento
                                                            </option>
                                                            <option value="wind_gust">Rajada de Vento</option>
                                                            <option value="rain_total">
                                                                Chuva Acumulada no dia
                                                            </option>
                                                            <option value="rain_rate">
                                                                Taxa de Chuva por hora
                                                            </option>
                                                            <option value="offline">Offline</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="email">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel
                                                            mb="0"
                                                            htmlFor="email"
                                                            whiteSpace={"nowrap"}
                                                            fontWeight="normal"
                                                        >
                                                            E-mail:
                                                        </FormLabel>
                                                        <Input
                                                            id="email"
                                                            {...field}
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            type="email"
                                                            placeholder="meuemail@email.com"
                                                        />
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        <GridItem colSpan={12}>
                                            <Field name="interval">
                                                {({ field, form }) => (
                                                    <FormControl isRequired>
                                                        <FormLabel
                                                            mb="0"
                                                            htmlFor="interval"
                                                            fontWeight="normal"
                                                        >
                                                            Intervalo mínimo entre alertas:
                                                        </FormLabel>
                                                        <Select
                                                            id="interval"
                                                            {...field}
                                                            borderRadius="10px"
                                                            fontSize="md"
                                                            placeholder="Selecione..."
                                                        >
                                                            <option value="1">1 Hora</option>
                                                            <option value="2">2 Horas</option>
                                                            <option value="3">3 Horas</option>
                                                            <option value="4">4 Horas</option>
                                                            <option value="5">5 Horas</option>
                                                            <option value="6">6 Horas</option>
                                                            <option value="12">12 Horas</option>
                                                            <option value="24">24 Horas</option>
                                                            <option value="168">7 Dias</option>
                                                            <option value="720">30 Dias</option>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </GridItem>

                                        {props.values.type != "offline" && (
                                            <GridItem colSpan={6}>
                                                <Field name="rule">
                                                    {({ field, form }) => (
                                                        <FormControl isRequired>
                                                            <FormLabel
                                                                mb="0"
                                                                htmlFor="rule"
                                                                fontWeight="normal"
                                                            >
                                                                Regra:
                                                            </FormLabel>
                                                            <Select
                                                                id="rule"
                                                                {...field}
                                                                borderRadius="10px"
                                                                fontSize="md"
                                                                placeholder="Selecione..."
                                                            >
                                                                <option value="above">Acima de</option>
                                                                <option value="under">Abaixo de</option>
                                                            </Select>
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        )}

                                        {props.values.type != "offline" && (
                                            <GridItem colSpan={6}>
                                                <Field name="limit">
                                                    {({ field, form }) => (
                                                        <FormControl isRequired>
                                                            <FormLabel
                                                                mb="0"
                                                                htmlFor="limit"
                                                                whiteSpace={"nowrap"}
                                                                fontWeight="normal"
                                                            >
                                                                Valor:
                                                            </FormLabel>
                                                            <Input
                                                                id="limit"
                                                                {...field}
                                                                borderRadius="10px"
                                                                fontSize="md"
                                                                type="tel"
                                                                step={0.1}
                                                                placeholder="10.1"
                                                            />
                                                        </FormControl>
                                                    )}
                                                </Field>
                                            </GridItem>
                                        )}
                                    </SimpleGrid>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </DrawerBody>
                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button type="submit" form="noticeForm" colorScheme="whatsapp">
                        Salvar
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
}

export default Notices;
