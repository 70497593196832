import { Box, Button, Divider, Flex, FormControl, FormLabel, GridItem, Input, Select, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import ReportsApi from "Api/ReportsApi";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Helper from "Helpers/Helper";
import { useEffect, useState } from "react";
import colorGradient from "javascript-color-gradient";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import StationsApi from "Api/StationsApi";
import { FaSearch } from "react-icons/fa";
import PrintMode from "components/Buttons/PrintMode";
import Favorites from "Helpers/Favorites";

function YearTempReport() {
    const [data, setData] = useState([]);
    const [daysCount, setDaysCount] = useState(null);
    const loading = useLoading();

    useEffect(() => {
        if (!Object.keys(data?.data || {}).length)
            return;

        setDaysCount(Helper.countDaysInTemp(Object.values(data?.data)));
    }, [data]);

    async function loadData(station_id, year) {
        loading.show();
        setData([]);
        let response = await ReportsApi.year(station_id, year);
        setData(response.json);
        loading.hide();
    }

    return (
        <Card overflowX="auto">
            <CardBody>
                <Flex direction="column" w="100%">
                    <Box className="form-filter-container" mb={4}>
                        <FormFilter loadData={loadData}/>
                    </Box>
                    {data?.data && <DataTable data={Object.values(data?.data)} rainData={data?.rain} daysCount={daysCount}/> || (
                        <Flex w="100%" justifyContent={"center"} mt={4}>
                            <Text opacity={.5}>Nenhum dado para exibir</Text>
                        </Flex>
                    )}
                    {
                        data?.provider == 'INMET' &&
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    }
                    <PrintMode/>
                </Flex>
            </CardBody>
        </Card>
    );
}

function FormFilter({ loadData }) {
    const [stations, setStations] = useState([]);
    const loading = useLoading();
    const favorites = Favorites.get();


    useEffect(() => {
        loadStationOptions();
    } , []);

    async function loadStationOptions() {
        loading.show();
        let response = await StationsApi.options();
        setStations(response.json);
        loading.hide();
    }

    async function submitHandle(values) {
        loadData(values.station_id, values.year);
    }

    return (
        <Formik onSubmit={submitHandle} initialValues={{ station_id: null, year: (new Date()).getFullYear() }} enableReinitialize={true}>
            { props => (
                <Form id="reportFilterForm">
                    <Flex flexDirection="row" flexWrap w="100%">
                        <SimpleGrid w="100%" columns={{ sm: 1, lg: 12}} gap={4}>
                            <GridItem colSpan={{ sm: 1, lg: 7}}>
                                <Field name="station_id">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="station_id" fontWeight="normal">Estação:</FormLabel>
                                            <Select id="station_id" {...field} borderRadius="10px" fontSize="md" placeholder="Selecione a Estação...">
                                                {
                                                    !favorites || !favorites.length ?
                                                    (
                                                        stations.map( station => (
                                                            <option value={station.id}>{station.text}</option>
                                                        ))
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <optgroup key="favorites" label="Estações Favoritas">
                                                                {stations.map( station => {
                                                                    if (favorites.includes(station.identifier))
                                                                        return (<option value={station.id}>{station.text}</option>);
                                                                    else
                                                                        return "";
                                                                })}
                                                            </optgroup>
                                                            <optgroup key="others" label="Outras Estações">
                                                                {stations.map( station => {
                                                                    if (!favorites.includes(station.identifier))
                                                                        return (<option value={station.id}>{station.text}</option>);
                                                                    else
                                                                        return "";
                                                                })}
                                                            </optgroup>
                                                        </>
                                                    )
                                                }
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>
                            <GridItem colSpan={{ sm: 1, lg: 3}}>
                                <Field name="year">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="year" fontWeight="normal">Ano:</FormLabel>
                                            <Input id="year" {...field} borderRadius="10px" fontSize="md" type="text"/>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ sm: 1, lg: 2}}>
                                <Button w="100%" type='submit' form='reportFilterForm' colorScheme="blue" mt={{ lg: 6 }} leftIcon={<FaSearch/>}>
                                    Buscar
                                </Button>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
}

function DataTable({ data, rainData, daysCount }) {
    function getBgColor(temp)
    {
        if (isNaN(temp))
            return;
        temp = Math.round(temp);

        if (temp <= 0)
            temp = 1;
        if (temp >= 40)
            temp = 40;

        return colorGradient
            .setGradient("#00b0f0", "#FDEB84", "#F24044")
            .setMidpoint(40)
            .getColor(parseInt(temp));
    }

    function getBgColorWater(value, max = 250) {
        if (isNaN(value))
            return;
        value = Math.round(value);

        if (value <= 0)
            value = 1;
        if (value >= max)
            value = max;

        return colorGradient
            .setGradient("#FDEB84", "#68D391", "#3182CE")
            .setMidpoint(max)
            .getColor(parseInt(value));
    }

    let yearAvg = Helper.getYearAverage(data);
    yearAvg = yearAvg == 0.0 ? '*' : yearAvg;

    let rainYearSum = Object.values(rainData).reduce((a, b) => a + b);
    rainYearSum = rainYearSum == 0 ? '*' :rainYearSum;

    let temp_min_yearMin = Helper.getYearMin(data, 'temp_min');
    let temp_min_yearMax = Helper.getYearMax(data, 'temp_min');
    let temp_max_yearMin = Helper.getYearMin(data, 'temp_max');
    let temp_max_yearMax = Helper.getYearMax(data, 'temp_max');

    return (
        <>
            <Table size="xs">
                <Thead>
                    <Tr>
                        <Th fontSize={"xs"} rowSpan={2}></Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Jan.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Fev.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Mar.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Abr.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Mai.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Jun.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Jul.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Ago.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Set.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Out.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Nov.</Th>
                        <Th border={'2px solid #333;'} borderBottom={0} textAlign={"center"} fontSize={"xs"} colSpan={2}>Dez.</Th>
                    </Tr>
                    <Tr>
                        {data.length && data.map( (month, k) => (
                            <>
                                <Th border={'2px solid #333;'} borderTop={0} textAlign={"center"} fontSize={"xs"}>Min.</Th>
                                <Th border={'2px solid #333;'} borderTop={0} textAlign={"center"} fontSize={"xs"}>Máx.</Th>
                            </>
                        ))}

                    </Tr>
                </Thead>
                <Tbody>
                    {[...Array(31).keys()].map(i => (
                        <Tr key={i}>
                            <Td textAlign={"center"} fontSize={"xs"} fontWeight={"semibold"}>{i + 1}</Td>
                            {data.length && data.map( (month, k) => {

                                let day_number = i + 1;
                                let day_data = month[day_number] ? month[day_number] : null;
                                let min = day_data && day_data.temp_min ? day_data.temp_min : '*';
                                let max = day_data && day_data.temp_max ? day_data.temp_max : '*';
                                return (
                                    <>
                                        <Td border={'2px solid #333;'} textColor={min == '*' ? '' :  'gray.700'} textAlign={"center"} bgColor={getBgColor(min)} fontSize={"xs"} key={`${day_number}_${i+1}_min`} opacity={min == '*' ? .25 : 1}>{min != '*' ? `${min}º` : '*'}</Td>
                                        <Td border={'2px solid #333;'} textColor={max == '*' ? '' :  'gray.700'} textAlign={"center"} bgColor={getBgColor(max)} fontSize={"xs"} key={`${day_number}_${i+1}_max`} opacity={max == '*' ? .25 : 1}>{max != '*' ? `${max}º` : '*'}</Td>
                                    </>
                                );
                                }
                            )}
                        </Tr>
                    ))}
                    <Tr>
                        <Td textAlign={"center"} fontSize={"xs"} fontWeight={"semibold"} rowSpan={3} bgColor={"gray.100"}>Média</Td>
                        {data.length && data.map( (month, k) => {
                            let min_avg = Helper.getAverage(Object.values(month), 'temp_min');
                            min_avg = min_avg == 0.0 ? '*' : min_avg;
                            let max_avg = Helper.getAverage(Object.values(month), 'temp_max');
                            max_avg = max_avg == 0.0 ? '*' : max_avg;
                            return (
                                <>
                                    <Td border={'2px solid #333;'} bgColor={getBgColor(min_avg)} textColor={min_avg == '*' ? '' :  'gray.700'} opacity={min_avg == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"} key={`${k}_avg_min`}>{min_avg != '*' ? `${min_avg}º` : '*'}</Td>
                                    <Td border={'2px solid #333;'} bgColor={getBgColor(max_avg)} textColor={max_avg == '*' ? '' :  'gray.700'} opacity={max_avg == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"} key={`${k}_avg_max`}>{max_avg != '*' ? `${max_avg}º` : '*'}</Td>
                                </>
                            );
                        })}
                    </Tr>

                    <Tr>
                        {data.length && data.map( (month, k) => {
                            let avg = Helper.getMonthAverage(Object.values(month));
                            avg = avg == 0.0 ? '*' : avg;
                            return (
                                <>
                                    <Td border={'2px solid #333;'} bgColor={getBgColor(avg)} textColor={avg == '*' ? '' :  'gray.700'} colSpan={2} opacity={avg == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"} key={`${k}_avg_month`}>{avg != '*' ? `${avg}º` : '*'}</Td>
                                </>
                            );
                        })}
                    </Tr>

                    <Tr>
                        <Td border={'2px solid #333;'} colSpan={24} bgColor={getBgColor(yearAvg)} textColor={yearAvg == '*' ? '' :  'gray.700'} opacity={yearAvg == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"}>{yearAvg != '*' ? `${yearAvg}º` : '*'}</Td>
                    </Tr>

                    <Tr>
                        <Td textAlign={"center"} fontSize={"xs"} fontWeight={"semibold"} rowSpan={2} bgColor={"blue.100"}>Chuva</Td>
                        {data.length && data.map( (month, k) => {
                            let rain = rainData[k + 1];
                            let tempAvg = Helper.getMonthAverage(Object.values(month));
                            rain = tempAvg == 0.0 ? '*' : rain;

                            return (
                                <>
                                    <Td border={'2px solid #333;'} bgColor={getBgColorWater(rain)} textColor={rain == '*' ? '' :  'gray.700'} colSpan={2} opacity={rain == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"} key={`${k}_rain_month`}>{rain != "*" ? `${rain}mm` : rain}</Td>
                                </>
                            );
                        })}
                    </Tr>
                    <Tr>
                        <Td colSpan={24} border={'2px solid #333;'} bgColor={getBgColorWater(rainYearSum)} textColor={rainYearSum == '*' ? '' :  'gray.700'} opacity={rainYearSum == '*' ? .25 : 1} fontSize={"xs"} fontWeight={"semibold"} textAlign={"center"}>{rainYearSum != '*' ? `${rainYearSum.toFixed(1)}mm` : '*'}</Td>
                    </Tr>
                </Tbody>
            </Table>

            <Flex w="100%">
                <Divider w="100%"  my={4}/>
            </Flex>

            <SimpleGrid columns={{ sm: 1, lg: 3}}>
                <Table size="xs">
                    <Thead>
                        <Tr>
                            <Th textAlign={"center"}>Valor</Th>
                            <Th textAlign={"center"}>Dias</Th>
                        </Tr>
                    </Thead>
                    {daysCount && (
                        <Tbody>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} -5º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_minus5}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} 0º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_0}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} 5º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_5}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} 10º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_10}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} 15º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_15}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Min. {'<='} 20º</Td>
                                <Td textAlign={"center"}>{daysCount.min.below_20}</Td>
                            </Tr>
                        </Tbody>
                    )}
                </Table>

                <Table size="xs">
                    <Thead>
                        <Tr>
                            <Th textAlign={"center"}>Valor</Th>
                            <Th textAlign={"center"}>Dias</Th>
                        </Tr>
                    </Thead>
                    {daysCount && (
                        <Tbody>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'<='} 10º</Td>
                                <Td textAlign={"center"}>{daysCount.max.below_10}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'<='} 15º</Td>
                                <Td textAlign={"center"}>{daysCount.max.below_15}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'<='} 20º</Td>
                                <Td textAlign={"center"}>{daysCount.max.below_20}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'>='} 25º</Td>
                                <Td textAlign={"center"}>{daysCount.max.above_25}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'>='} 30º</Td>
                                <Td textAlign={"center"}>{daysCount.max.above_30}</Td>
                            </Tr>
                            <Tr>
                                <Td textAlign={"center"}>Temp. Máx. {'>='} 35º</Td>
                                <Td textAlign={"center"}>{daysCount.max.above_35}</Td>
                            </Tr>
                        </Tbody>
                    )}
                </Table>


                <Table size="xs">
                    <Thead>
                        <Tr>
                            <Th></Th>
                            <Th textAlign={"center"}>Menor</Th>
                            <Th textAlign={"center"}>Maior</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td textAlign={"center"}>Temp. Mínima</Td>
                            <Td color="gray.700" textAlign={"center"} bgColor={getBgColor(temp_min_yearMin || '*')}>{temp_min_yearMin ? temp_min_yearMin + 'º' : '*'}</Td>
                            <Td color="gray.700" textAlign={"center"} bgColor={getBgColor(temp_min_yearMax || '*')}>{temp_min_yearMax ? temp_min_yearMax + 'º' : '*'}</Td>
                        </Tr>
                        <Tr>
                            <Td textAlign={"center"}>Temp. Máxima</Td>
                            <Td color="gray.700" textAlign={"center"} bgColor={getBgColor(temp_max_yearMin || '*')}>{temp_max_yearMin ? temp_max_yearMin + 'º' : '*'}</Td>
                            <Td color="gray.700" textAlign={"center"} bgColor={getBgColor(temp_max_yearMax || '*')}>{temp_max_yearMax ? temp_max_yearMax + 'º' : '*'}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </SimpleGrid>
        </>
    );
}

export default YearTempReport;
