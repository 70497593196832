import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    GridItem,
    Input,
    Select,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Table,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    useToast,
    useToken,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { useEffect, useState } from "react";
import { useLoading } from "Providers/LoadingProvider";
import { Field, Form, Formik } from "formik";
import {
    FaArrowDown,
    FaArrowUp,
    FaCheck,
    FaCloudShowersHeavy,
    FaCogs,
    FaSun,
    FaTemperatureLow,
} from "react-icons/fa";
import PrintMode from "components/Buttons/PrintMode";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import UserBHsApi from "Api/UserBHsApi";
import { useAuth } from "Providers/AuthProvider";
import { TbGraph, TbDroplets, TbTable } from "react-icons/tb";

function BalancoHidrico() {
    const [data, setData] = useState(null);
    const loading = useLoading();
    const user = useAuth().getUser();

    async function loadData(values) {
        loading.show();
        setData(null);
        let response = await UserBHsApi.generate(user.id, values.bh_id);
        setData(response.json);
        loading.hide();
    }

    return (
        <Card overflowX="auto">
            <CardBody>
                <Flex direction="column" w="100%">
                    <Box className="form-filter-container" mb={4}>
                        <FormFilter loadData={loadData} />
                    </Box>
                    {data != null && !loading.loading && (
                        <>
                            <Tabs isFitted variant="enclosed">
                                <TabList>
                                    <Tab fontWeight={"semibold"}><TbGraph /> <Text ml={1}>Relatório</Text></Tab>
                                    <Tab fontWeight={"semibold"}><TbDroplets /> <Text ml={1}>Irrigação</Text></Tab>
                                    <Tab fontWeight={"semibold"}><TbTable /> <Text ml={1}>Logs</Text></Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <Report data={data} />
                                    </TabPanel>
                                    <TabPanel>
                                        <Irrigation data={data} />
                                    </TabPanel>
                                    {
                                        user.admin &&
                                        <TabPanel>
                                            <Logs logs={data.log || {}} />
                                        </TabPanel>
                                    }
                                </TabPanels>
                            </Tabs>
                        </>
                    )}
                    {data?.provider == "INMET" && (
                        <Box mb={2} w="100%" textAlign={"center"} fontSize="sm">
                            Fonte de dados: INMET
                        </Box>
                    )}
                    <PrintMode />
                </Flex>
            </CardBody>
        </Card>
    );
}

function FormFilter({ loadData }) {
    const loading = useLoading();
    const [data, setData] = useState({});
    const user = useAuth().getUser();

    const loadBHsData = async () => {
        loading.show();
        let response = await UserBHsApi.listAvailable(user.id);
        setData(response.json);
        loading.hide();
    };

    useEffect(() => {
        loadBHsData();
    }, []);

    async function submitHandle(values) {
        loadData(values);
    }

    return (
        <Formik
            onSubmit={submitHandle}
            initialValues={{ bh_id: "" }}
            enableReinitialize={true}
        >
            {(props) => (
                <Form id="reportFilterForm">
                    <Flex flexDirection="row" flexWrap w="100%">
                        <SimpleGrid w="100%" columns={{ sm: 1, lg: 12 }} gap={4}>
                            <GridItem colSpan={{ sm: 1, lg: 10 }}>
                                <Field name="bh_id">
                                    {({ field, form }) => (
                                        <FormControl isRequired>
                                            <FormLabel mb="0" htmlFor="bh_id" fontWeight="normal">
                                                Balanço Hídrico:
                                            </FormLabel>
                                            <Select
                                                id="bh_id"
                                                {...field}
                                                borderRadius="10px"
                                                fontSize="md"
                                                placeholder="Selecione o balanço hídrico..."
                                            >
                                                {data?.bhs?.map((bh) => (
                                                    <option value={bh.id}>{bh.full_name}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                            </GridItem>

                            <GridItem colSpan={{ sm: 1, lg: 2 }}>
                                <Button
                                    w="100%"
                                    type="submit"
                                    form="reportFilterForm"
                                    colorScheme="blue"
                                    mt={{ lg: 6 }}
                                    leftIcon={<FaCogs />}
                                >
                                    Gerar
                                </Button>
                            </GridItem>
                        </SimpleGrid>
                    </Flex>
                </Form>
            )}
        </Formik>
    );
}

function Report({ data }) {
    const mode = "culture";
    let parsedData = {
        labels: data?.labels || [],
        deficit: Object.values(data?.hidrico?.deficit || {}),
        excedente: Object.values(data?.hidrico?.excedente || {}),
        temperatura_minima: data?.temperatura_minima || [],
        temperatura_media: data?.temperatura_media || [],
        temperatura_maxima: data?.temperatura_maxima || [],
        irrigation_data: data?.irrigation_data || [],
        arm: Object.values(data?.hidrico?.ARM || {}),
        cad: Object.values(data?.hidrico?.CAD || {}),
        precipitacao: data?.precipitacao || [],
        precipitacao_acumulada: data?.precipitacao_acumulada || [],
        kc: Object.values(data?.hidrico?.KC || {}),
    };

    let sum = parsedData.temperatura_media?.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
    );
    let avg = ((sum / parsedData.temperatura_media.length) || 0);

    let min = Math.min(...parsedData.temperatura_minima);
    let max = Math.max(...parsedData.temperatura_maxima);
    let precipAcum = Math.max(...parsedData.precipitacao_acumulada);

    let yellowColor = useColorModeValue("yellow.500", "yellow.200");
    let [yellowColorHex] = useToken("colors", [yellowColor]);

    let quickData = [
        {
            icon: <FaArrowDown />,
            name: "Minima",
            value:
                ((!min && min != "0") || !Number.isFinite(min) ? "-" : min) + " ºC",
            color: useColorModeValue("blue.500", "blue.200"),
        },
        {
            icon: <FaTemperatureLow />,
            name: "Média",
            value:
                ((!avg && avg != "0") || !Number.isFinite(avg) ? "-" : avg?.toFixed(1)) + " ºC",
            color: yellowColor,
        },
        {
            icon: <FaArrowUp />,
            name: "Máxima",
            value:
                ((!max && max != "0") || !Number.isFinite(max) ? "-" : max) + " ºC",
            color: useColorModeValue("red.500", "red.200"),
        },
        {
            icon: <FaSun />,
            name: "Sem Chuva",
            value:
                parsedData.precipitacao?.filter(
                    (precipitacao) => (precipitacao || 0) == 0
                ).length + " dias",
            color: yellowColor,
        },
        {
            icon: <FaCloudShowersHeavy />,
            name: "Total Chuva",
            value:
                ((!precipAcum && precipAcum != "0") || !Number.isFinite(precipAcum)
                    ? "-"
                    : precipAcum) + " mm",
            color: useColorModeValue("blue.500", "blue.200"),
        },
    ];

    function getChartOptions(yAxes) {
        return {
            responsive: true,
            scales: {
                xAxes: [
                    {
                        type: "time",
                        time: {
                            parser: "DD/MM/YY",
                            tooltipFormat: "DD/MM/YY",
                            minUnit: "day",
                            displayFormats: {
                                day: "DD/MM",
                            },
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Dia",
                        },
                        ticks: {
                            maxTicksLimit: 20,
                            autoSkip: true,
                        },
                    },
                ],
                yAxes: yAxes || [],
            },
            legend: {
                position: "bottom",
                labels: {
                    filter: function (legendItem) {
                        return mode != "culture" &&
                            legendItem.text == "Necessidade hídrica do cultivo (KC)"
                            ? false
                            : true;
                    },
                },
            },
            tooltips: {
                enabled: true,
                mode: "index",
                callbacks: {
                    label: function (tooltipItem, data) {
                        return (
                            data.datasets[tooltipItem.datasetIndex].label +
                            ": " +
                            tooltipItem.yLabel +
                            (data.datasets[tooltipItem.datasetIndex].suffix || "")
                        );
                    },
                },
            },
            plugins: {
                zoom: {
                    drag: false,
                    zoom: {
                        enabled: true,
                        threshold: 20,
                        drag: {
                            animationDuration: 1000,
                        },
                        mode: "x",
                        speed: 0.05,
                    },
                },
            },
        };
    }

    return (
        <>
            <Text fontSize={"2xl"} textAlign={"center"}>
                Dados Rápidos
            </Text>
            <Text textAlign={"center"} mx="auto">
                <b>Estação:</b> {data.bh.station.full_name}
            </Text>
            <Text textAlign={"center"} mx="auto">
                <b>Balanço Hídrico:</b> {data.bh.full_name} | <b>Início:</b>{" "}
                {moment(data.bh.start_date).format("LL")}
            </Text>
            <Text textAlign={"center"} mb={2} mx="auto">
                <b>Irrigação:</b> {data.bh.irrigation_type} (Eficiência{" "}
                {data.bh.irrigation_efficiency * 100}%)
            </Text>
            <Flex wrap={"wrap"} justify={"center"}>
                {quickData.map((_data) => (
                    <Box p={2} w={{ base: "100%", lg: "30%" }}>
                        <Stat
                            px={{ base: 2, md: 4 }}
                            py={"5"}
                            shadow={"md"}
                            border={"2px solid"}
                            borderColor={_data.color}
                            rounded={"lg"}
                            w="full"
                        >
                            <Flex>
                                <Box
                                    my={"auto"}
                                    color={_data.color}
                                    alignContent={"center"}
                                    fontSize={"3xl"}
                                >
                                    {_data.icon}
                                </Box>
                                <Box pl={{ base: 2, md: 4 }} color={_data.color}>
                                    <StatLabel fontWeight={"medium"} isTruncated>
                                        {_data.name}
                                    </StatLabel>
                                    <StatNumber fontSize={"xl"} fontWeight={"semibold"}>
                                        {_data.value}
                                    </StatNumber>
                                </Box>
                            </Flex>
                        </Stat>
                    </Box>
                ))}
            </Flex>

            <Divider my={6} />

            <Box>
                <Text fontSize={"2xl"} textAlign={"center"}>
                    Balanço Hídrico
                </Text>
                <Bar
                    options={getChartOptions([
                        {
                            id: "hidrico",
                            position: "left",
                            type: "linear",
                            ticks: {
                                maxTicksLimit: 5,
                                autoSkip: true,
                                minRotation: 0,
                                maxRotation: 0,
                                callback: function (label) {
                                    return label + "mm";
                                },
                            },
                        },
                        {
                            position: "right",
                            id: "kc",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            type: "linear",
                            display: false,
                        },
                    ])}
                    data={{
                        labels: parsedData.labels || [],
                        datasets: [
                            {
                                label: "Déficit Hídrico",
                                type: "line",
                                backgroundColor: "#ff336688", // 6B46C188
                                borderColor: "#ff3366",
                                fill: true,
                                yAxisID: "hidrico",
                                lineTension: 0.3,
                                pointHitRadius: 7,
                                data: parsedData.deficit || [],
                                lineTension: 0.15,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                label: "Excedente Hídrico",
                                type: "line",
                                backgroundColor: "#25AAEC88", // 38A16988
                                borderColor: "#25AAEC88", // 38A169
                                fill: true,
                                yAxisID: "hidrico",
                                data: parsedData.excedente || [],
                                lineTension: 0.15,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                type: "line",
                                label: "Armazenamento",
                                borderColor: "#38A169",
                                data: parsedData.arm || [],
                                fill: false,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                lineTension: 0.15,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                label: "Precipitação",
                                backgroundColor: "#25AAEC44",
                                borderColor: "#25AAEC77",
                                data: parsedData.precipitacao || [],
                                fill: true,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                label: "Irrigação",
                                backgroundColor: yellowColorHex + "77",
                                borderColor: yellowColorHex,
                                data: parsedData.irrigation_data,
                                fill: true,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                type: "line",
                                label: "Necessidade hídrica do cultivo (KC)",
                                borderColor: "#FFCA4A",
                                data: parsedData.kc || [],
                                fill: false,
                                yAxisID: "kc",
                                pointHitRadius: 7,
                                lineTension: 0,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                hidden: true,
                            },
                        ],
                    }}
                />
            </Box>

            <Divider my={6} />

            <Box>
                <Text fontSize={"2xl"} textAlign={"center"}>
                    Oscilação Térmica
                </Text>
                <Bar
                    options={getChartOptions([
                        {
                            position: "right",
                            id: "temperatura",
                            type: "linear",
                            ticks: {
                                maxTicksLimit: 5,
                                autoSkip: true,
                                minRotation: 0,
                                maxRotation: 0,
                                callback: function (label) {
                                    return label + "º";
                                },
                            },
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        },
                        {
                            position: "right",
                            id: "kc",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            type: "linear",
                            display: false,
                        },
                    ])}
                    data={{
                        labels: parsedData.labels || [],
                        datasets: [
                            {
                                type: "line",
                                label: "Temperatura Máxima",
                                backgroundColor: "#ff3366",
                                borderColor: "#ff336688",
                                data: parsedData.temperatura_maxima,
                                fill: "false",
                                yAxisID: "temperatura",
                                pointHitRadius: 7,
                                pointRadius: 0,
                                lineTension: 0.3,
                                suffix: " ºC",
                            },
                            {
                                type: "line",
                                label: "Temperatura Média",
                                backgroundColor: "#FFCA4A",
                                borderColor: "#FFCA4A99",
                                data: parsedData.temperatura_media,
                                fill: "false",
                                yAxisID: "temperatura",
                                pointHitRadius: 7,
                                lineTension: 0.3,
                                pointRadius: 0,
                                suffix: " ºC",
                            },
                            {
                                type: "line",
                                label: "Temperatura Mínima",
                                backgroundColor: "#25AAEC88",
                                borderColor: "#25AAEC88",
                                data: parsedData.temperatura_minima,
                                fill: "false",
                                yAxisID: "temperatura",
                                pointHitRadius: 7,
                                lineTension: 0.3,
                                pointRadius: 0,
                                suffix: " ºC",
                            },
                            // {
                            //     type: "line",
                            //     label: "Necessidade hídrica do cultivo (KC)",
                            //     borderColor: "#FFCA4A",
                            //     data: parsedData.kc,
                            //     fill: false,
                            //     yAxisID: "kc",
                            //     pointHitRadius: 7,
                            //     lineTension: 0,
                            //     pointRadius: 0,
                            //     pointHitRadius: 7,
                            //     hidden: true,
                            // },
                        ],
                    }}
                />
            </Box>

            <Divider my={6} />

            <Box>
                <Text fontSize={"2xl"} textAlign={"center"}>
                    Visão Hídrica
                </Text>
                <Bar
                    options={getChartOptions([
                        {
                            id: "hidrico",
                            position: "right",
                            type: "linear",
                            ticks: {
                                maxTicksLimit: 5,
                                autoSkip: true,
                                minRotation: 0,
                                maxRotation: 0,
                                callback: function (label) {
                                    return label + "mm";
                                },
                            },
                        },
                    ])}
                    data={{
                        labels: parsedData.labels || [],
                        datasets: [
                            {
                                label: "Déficit Hídrico",
                                type: "line",
                                backgroundColor: "#ff336688", // 6B46C188
                                borderColor: "#ff3366",
                                fill: true,
                                yAxisID: "hidrico",
                                lineTension: 0.3,
                                pointHitRadius: 7,
                                data: parsedData.deficit,
                                lineTension: 0.15,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                type: "line",
                                label: "Armazenamento",
                                borderColor: "#38A169",
                                data: parsedData.arm,
                                fill: false,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                lineTension: 0.15,
                                pointRadius: 0,
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                type: "line",
                                label: "Precipitação Acumulada",
                                backgroundColor: "#25AAEC44",
                                borderColor: "#25AAEC77",
                                data: parsedData.precipitacao_acumulada,
                                fill: true,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                            {
                                label: "Irrigação",
                                backgroundColor: yellowColorHex + "77",
                                borderColor: yellowColorHex,
                                data: parsedData.irrigation_data,
                                fill: true,
                                yAxisID: "hidrico",
                                pointHitRadius: 7,
                                suffix: "mm",
                            },
                        ],
                    }}
                />
            </Box>
        </>
    );
}

function Irrigation({ data }) {
    const [irrigationData, setIrrigationData] = useState({});
    const user = useAuth().getUser();
    const toast = useToast({
        position: "top-right",
    });
    const [loadingDates, setLoadingDates] = useState([]);

    useEffect(() => {
        setIrrigationData(data.irrigations);
    }, [data]);

    async function saveIrrigationData(date) {
        setLoadingDates((curr) => [...curr, date]);

        let response = null;
        try {
            response = await UserBHsApi.irrigation(user.id, data.bh.id, {
                date: date,
                ...(irrigationData[date] || {}),
            });
        } catch (err) {
            toast({
                status: "error",
                title:
                    err?.response?.data?.message ||
                    "Erro ao salvar Irrigação, verifique seus dados e tente novamente!",
                duration: 5000,
                variant: "solid",
                isClosable: true,
            });
        }

        if (response.status >= 200 && response.status < 300) {
            toast({
                status: "success",
                title: "Irrigação salva com sucesso!",
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
        } else {
            toast({
                status: "error",
                title: "Erro ao salvar a irrigação! Cód. " + response.status,
                duration: 3000,
                variant: "solid",
                isClosable: true,
            });
        }

        setLoadingDates((curr) => curr.filter((a) => a != date));
    }

    return (
        <>
            <Text my={2} mx="auto" textAlign={"center"}>
                <Text fontWeight={"semibold"}>Tipo:</Text> {data.bh.irrigation_type}{" "}
                (Eficiência {data.bh.irrigation_efficiency * 100}%)
            </Text>
            <Table colorScheme="gray" size="sm">
                <Thead>
                    <Tr>
                        <Th>Dia</Th>
                        <Th textAlign={"center"} whiteSpace={"nowrap"}>
                            Precipitação Manual (mm)
                        </Th>
                        <Th textAlign={"center"} whiteSpace={"nowrap"}>
                            Irrigado Bruto (mm)
                        </Th>
                        <Th textAlign={"center"} whiteSpace={"nowrap"}>
                            Irrigado Real (mm)
                        </Th>
                        <Th textAlign={"center"} whiteSpace={"nowrap"}>
                            Salvar
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.keys(irrigationData || {})?.map((irrigationDate, key) => (
                        <Tr key={irrigationDate}>
                            <Td p={1} py={2} fontSize={"sm"}>
                                {moment(irrigationDate).format("ddd, L")}
                                <br />
                                <Text fontSize={"sm"} opacity={0.7}>
                                    Dia {key + 1}
                                </Text>
                            </Td>
                            <Td p={1} py={2}>
                                <Input
                                    size="sm"
                                    type="number"
                                    name="manual_precipitation"
                                    step={0.01}
                                    defaultValue={
                                        irrigationData[irrigationDate]?.manual_precipitation || ""
                                    }
                                    onChange={(e) => {
                                        setIrrigationData((curr) => {
                                            return {
                                                ...curr,
                                                [irrigationDate]: {
                                                    ...(curr[irrigationDate] || {}),
                                                    [e.target.name]: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    borderRadius="10px"
                                    borderWidth={1}
                                    borderColor={"gray.300"}
                                    placeholder="Padrão: Dado da estação"
                                />
                            </Td>
                            <Td p={1} py={2}>
                                <Input
                                    size="sm"
                                    type="number"
                                    name="irrigated"
                                    step={0.01}
                                    defaultValue={irrigationData[irrigationDate]?.irrigated || ""}
                                    borderRadius="10px"
                                    borderWidth={1}
                                    borderColor={"gray.300"}
                                    placeholder="Bruto em mm - Ex.: 10.5"
                                    onChange={(e) => {
                                        setIrrigationData((curr) => {
                                            return {
                                                ...curr,
                                                [irrigationDate]: {
                                                    ...(curr[irrigationDate] || {}),
                                                    [e.target.name]: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                />
                            </Td>
                            <Td p={1} py={2} textAlign={"center"}>
                                {irrigationData[irrigationDate]?.irrigated
                                    ? (
                                        irrigationData[irrigationDate]?.irrigated *
                                        data.bh.irrigation_efficiency
                                    ).toFixed(2) + "mm"
                                    : "-"}
                            </Td>
                            <Td p={1} py={2}>
                                <Button
                                    isLoading={loadingDates?.indexOf(irrigationDate) !== -1}
                                    loadingText="Salvando..."
                                    variant="outline"
                                    size="sm"
                                    colorScheme="blue"
                                    onClick={() => saveIrrigationData(irrigationDate)}
                                    leftIcon={<FaCheck />}
                                >
                                    Salvar Dia
                                </Button>
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    );
}

function Logs({ logs }) {
    let firstRow = Object.keys(logs || {})[0] ?? null;

    if (!firstRow) return "Nenhum log encontrado";

    return (
        <Box overflowX={"auto"}>
            <Table colorScheme="gray" size="sm" fontSize={"x-small"} variant="striped">
                <Thead>
                    <Tr>
                        <Th p={1}>Dia</Th>
                        {Object.keys(logs[firstRow]).map((col, k) => (
                            <Th key={k} p={1}>
                                {col}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.entries(logs).map(([date, values], key) => {
                        return (
                            <Tr key={key}>
                                <Td p={1}>
                                    {moment(date).format("ddd, L")}
                                    <br />
                                    <Text fontSize={"sm"} opacity={0.7}>
                                        Dia {key + 1}
                                    </Text>
                                </Td>
                                {Object.keys(logs[firstRow]).map((col, k2) => (
                                    <Td key={k2} p={1}>
                                        {values[col]}
                                    </Td>
                                ))}
                            </Tr>
                        );
                    })}
                    <Tr></Tr>
                </Tbody>
            </Table>
        </Box>
    );
}

export default BalancoHidrico;
