import Client from './Client';

export const AuthApi = {
    signIn: async (formData) => {
        return await Client.post('/v1/auth/signin', formData);
    },
    me: async () => {
        return await Client.post('/v1/auth/me', {});
    },
    refreshToken: async () => {
        return await Client.post('/v1/auth/refresh');
    },
    signUp: async (formData) => {
        return await Client.post('/v1/auth/signup', formData);
    },
    recover: async (formData) => {
        return await Client.post('/v1/auth/recover', formData);
    },
    redefine: async (token, formData) => {
        return await Client.post(`/v1/auth/redefine/${token}`, formData);
    }
};

export default AuthApi;
