import React, { useEffect, useState} from "react";
import {
    Flex,
    Button,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
    useColorModeValue,
    FormErrorMessage,
    useToast,
    SimpleGrid,
    GridItem,
    Select,
} from "@chakra-ui/react";
import { useLoading } from "Providers/LoadingProvider";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "Providers/AuthProvider";
import { Field, Form, Formik } from "formik";
import AuthApi from "Api/AuthApi";
import InputMask from 'react-input-mask';

function SignUp() {
    const loading = useLoading();
    const history = useHistory();
    const auth = useAuth();
    const toast = useToast({
        position: "top-right",
    });

    var initialValues = {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        phone: "",
        city: "",
        state: "",
    };

    async function submitHandle(values, { setFieldError }) {
        loading.show();
        let response = await AuthApi.signUp(values);

        if (response.status == 200) {
            await auth.login(values.email, values.password);
            window.gtag('event', 'conversion', {
                event_label: 'Criação de Conta'
            });
            toast({ status: 'success', title: 'Cadastro realizado com sucesso! Faça login para continuar.', duration: 3000, variant: "solid", isClosable: true });
            history.push("/auth/signin");
            loading.hide();
            return;
        } else if (response.status == 422) {
            toast({ status: 'error', title: 'Erro, verifique seus dados!', duration: 3000, variant: "solid", isClosable: true });
            let errors = response.json.errors;
            for (let field in errors) {
                setFieldError(field, errors[field][0]);
            }
            loading.hide();
            return;
        } else {
            toast({ status: 'error', title: 'Erro interno durante o processamento, tente novamente mais tarde, se o problema persistir, entre em contato com o adminstrador!', duration: 3000, variant: "solid", isClosable: true });
            console.error(response);
            loading.hide();
            return;
        }
    }

    const [phoneMaskState, setPhoneMaskState] = useState('(99) 9999-99999');
    function checkPhoneNumberSize(event) {
        let size = event.target.value.split(' ').join('').split('-').join('');
        if (size.length <= 12) {
            setPhoneMaskState('(99) 9999-99999');
        } else {
            setPhoneMaskState('(99) 9 9999-9999');
        }
    }

    // Chakra color mode
    const titleColor = useColorModeValue("blue.300", "blue.200");
    const textColor = useColorModeValue("gray.400", "white");
    return (
        <Flex position="relative">
            <Flex w="100%" mx="auto" justifyContent="center">
                <Flex alignItems="center" justifyContent="start" style={{ userSelect: "none" }} w={{ base: "100%", md: "90%", lg: "70%" }}>
                    <Flex direction="column" w="100%" background="transparent" p="24px">
                        <Heading color={titleColor} fontSize="32px" textAlign={"Center"}>
                            Assinatura de Membro<br/>R$ 9,99/mês - 30 dias Grátis
                        </Heading>
                        <Text mb={10} color={textColor} fontWeight="bold" fontSize="14px" textAlign={"center"}>
                            Quase lá! Só preencher seus dados e iniciar seu teste grátis.
                        </Text>

                        <Formik onSubmit={submitHandle} initialValues={initialValues}>
                            <Form>
                                <SimpleGrid columns={12} gap={4}>
                                    <GridItem colSpan={{ sm: 12, lg: 8}}>
                                        <Field name="name">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.name && form.touched.name}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">Nome Completo:</FormLabel>
                                                    <Input {...field} borderRadius="10px" type="text" placeholder="João da Silva" size="md"/>
                                                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 12, lg: 4}}>
                                        <Field name="phone">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.phone && form.touched.phone}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">Telefone:</FormLabel>
                                                    <InputMask {...field} mask={phoneMaskState} maskChar="" onChange={(e) => {checkPhoneNumberSize(e); form.handleChange(e);}}>
                                                        {(inputProps) => <Input {...inputProps} type="tel" minlength="14" borderRadius="10px" size="md" placeholder="(XX) 9 9999-9999"/>}
                                                    </InputMask>
                                                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 8, lg: 4}}>
                                        <Field name="city">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.city && form.touched.city}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">Cidade:</FormLabel>
                                                    <Input {...field} borderRadius="10px" type="text" size="md" placeholder="São Paulo"/>
                                                    <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 4, lg: 2}}>
                                        <Field name="state">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.state && form.touched.state}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">UF:</FormLabel>
                                                    <Select {...field} borderRadius="10px" size="md" placeholder="Selecione...">
                                                        <option value="AC">AC</option>
                                                        <option value="AL">AL</option>
                                                        <option value="AP">AP</option>
                                                        <option value="AM">AM</option>
                                                        <option value="BA">BA</option>
                                                        <option value="CE">CE</option>
                                                        <option value="DF">DF</option>
                                                        <option value="ES">ES</option>
                                                        <option value="GO">GO</option>
                                                        <option value="MA">MA</option>
                                                        <option value="MT">MT</option>
                                                        <option value="MS">MS</option>
                                                        <option value="MG">MG</option>
                                                        <option value="PA">PA</option>
                                                        <option value="PB">PB</option>
                                                        <option value="PR">PR</option>
                                                        <option value="PE">PE</option>
                                                        <option value="PI">PI</option>
                                                        <option value="RJ">RJ</option>
                                                        <option value="RN">RN</option>
                                                        <option value="RS">RS</option>
                                                        <option value="RO">RO</option>
                                                        <option value="RR">RR</option>
                                                        <option value="SC">SC</option>
                                                        <option value="SP">SP</option>
                                                        <option value="SE">SE</option>
                                                        <option value="TO">TO</option>
                                                    </Select>
                                                    <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 12, lg: 6}}>
                                        <Field name="email">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.email && form.touched.email}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">E-mail:</FormLabel>
                                                    <Input {...field} borderRadius="10px" type="email" placeholder="email@email.com" size="md"/>
                                                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 12, lg: 6}}>
                                        <Field name="password">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.password && form.touched.password}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">Senha:</FormLabel>
                                                    <Input {...field} borderRadius="10px" type="password" size="md"/>
                                                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>

                                    <GridItem colSpan={{ sm: 12, lg: 6}}>
                                        <Field name="password_confirmation">
                                            {({ field, form }) => (
                                                <FormControl isRequired isInvalid={form.errors.password_confirmation && form.touched.password_confirmation}>
                                                    <FormLabel fontSize="sm" fontWeight="normal">Confirme a Senha:</FormLabel>
                                                    <Input {...field} borderRadius="10px" type="password" size="md"/>
                                                    <FormErrorMessage>{form.errors.password_confirmation}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </GridItem>
                                </SimpleGrid>
                                <Button type="submit" colorScheme={"whatsapp"} w="100%" h="45" mb={2} mt={10}>
                                    Criar conta e Iniciar teste grátis
                                </Button>
                            </Form>
                        </Formik>
                        <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mb={2}>
                            <Text color={textColor} fontWeight="medium" fontSize={"xs"} mb={2}>
                                Já tem uma conta?
                                <Link to="/auth/signin">
                                    <Text color={titleColor} as="span" ms="5px" fontWeight="bold">
                                        Faça login
                                    </Text>
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignUp;
