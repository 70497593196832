// Chakra Imports
import {
    Box,
    Flex,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import AdminNavbarLinks from "./AdminNavbarLinks";

export default function AdminNavbar(props) {
    const {
        variant,
        children,
        fixed,
        secondary,
        brandText,
        onOpen,
        ...rest
    } = props;

    // Here are all the props that may change depending on navbar's type or state.(secondary, variant)
    let mainText = useColorModeValue("gray.700", "gray.200");
    let navbarPosition = "absolute";
    let navbarFilter = "none";
    let navbarBackdrop = "blur(21px)";
    let navbarShadow = "none";
    let navbarBg = "none";
    let navbarBorder = "transparent";
    let secondaryMargin = "0px";
    let paddingX = "15px";
    if (props.fixed === true) {
        navbarPosition = "fixed";
        navbarShadow = useColorModeValue(
            "0px 7px 23px rgba(0, 0, 0, 0.05)",
            "none"
        );
        navbarBg = useColorModeValue(
            "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
            "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
        );
        navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
        navbarFilter = useColorModeValue(
            "none",
            "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
        );
    }
    if (props.secondary) {
        navbarBackdrop = "none";
        navbarPosition = "absolute";
        mainText = "white";
        secondaryText = "white";
        secondaryMargin = "22px";
        paddingX = "30px";
    }

    return (
        <Flex
        className="navbar"
        position={navbarPosition}
        boxShadow={navbarShadow}
        bg={navbarBg}
        borderColor={navbarBorder}
        filter={navbarFilter}
        backdropFilter={navbarBackdrop}
        borderBottomWidth="1.5px"
        borderStyle="solid"
        transitionDelay="0s, 0s, 0s, 0s"
        transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
        transition-property="box-shadow, background-color, filter, border"
        transitionTimingFunction="linear, linear, linear, linear"
        alignItems={{ xl: "center" }}
        display="flex"
        minH="50px"
        justifyContent={{ xl: "center" }}
        mx="auto"
        mt={secondaryMargin}
        pb="8px"
        left={{sm: 0, xl: 260}}
        px={{
            sm: paddingX,
            md: "30px",
        }}
        ps={{
            xl: "12px",
        }}
        pt="8px"
        w={{ sm: "calc(100vw)", xl: "calc(100vw - 260px)" }}
        >
        <Flex
            w="100%"
            flexDirection={{
                sm: "column",
                md: "row",
            }}
            alignItems={{ xl: "center" }}
        >
            <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
                <AdminNavbarLinks
                    onOpen={props.onOpen}
                    logoText={props.logoText}
                    secondary={props.secondary}
                    fixed={props.fixed}
                />
            </Box>
        </Flex>
        </Flex>
    );
}
