/*eslint-disable*/
import React from "react";
import { Box, Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Separator } from "components/Separator/Separator";

export default function Footer(props) {
    return (
        <>
        <Box className="footer">
            <Flex w="100%" justifyContent={"center"} px="30px" pb="0px" textColor={"gray.400"} fontSize={"sm"}>
                <Text>WS CONSULTORIA CLIMATOLOGICA LTDA - CNPJ 45.138.916/0001-40</Text>
            </Flex>
            <Separator my={4}/>
            <Flex flexDirection={{ base: "column", xl: "row", }} alignItems={{ base: "center", xl: "start", }} justifyContent="space-between" px="30px" pb="20px">
                <Text color="gray.400" textAlign={{ base: "center", xl: "start", }} mb={{ base: "20px", xl: "0px" }}>
                    &copy; 2019 - {1900 + new Date().getYear()} |{" "}
                    <Text as="span">
                        {"Desenvolvido por "}
                    </Text>
                    <Link color="teal.400" href="https://www.paulokramer.com" target="_blank">
                        {"PK Systems"}
                    </Link>
                </Text>
                <List display="flex">
                    <ListItem me={{ base: "20px", md: "44px" }}>
                        <Link color="gray.400" href="https://www.facebook.com/william.siqueira.31/" target="_blank">
                            <Flex alignItems={"center"}>
                                <FaFacebook/> <Text ml={1}>Facebook</Text>
                            </Flex>
                        </Link>
                    </ListItem>

                    <ListItem me={{ base: "20px", md: "44px" }}>
                        <Link color="gray.400" href="https://www.instagram.com/owilliamsiqueira/" target="_blank">
                            <Flex alignItems={"center"}>
                                <FaInstagram/> <Text ml={1}>Instagram</Text>
                            </Flex>
                        </Link>
                    </ListItem>

                    <ListItem me={{ base: "20px", md: "44px" }}>
                        <Link color="gray.400" href="https://wa.me/5535991851089" target="_blank">
                            <Flex alignItems={"center"}>
                                <FaWhatsapp/> <Text ml={1}>WhatsApp</Text>
                            </Flex>
                        </Link>
                    </ListItem>
                </List>
            </Flex>
        </Box>
        </>
    );
}
