/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    Link,
    Stack,
    Text,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import IconBox from "components/Icons/IconBox";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import { useAuth } from "Providers/AuthProvider";
import React from "react";
import { FaLock } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";

// FUNCTIONS
function greeting() {
    let greeting = "";
    var data = [
        [0, 4, "Boa noite"],
        [5, 11, "Bom dia"],
        [12, 17, "Boa tarde"],
        [18, 24, "Boa noite"],
    ],
        hr = new Date().getHours();

    for (var i = 0; i < data.length; i++) {
        if (hr >= data[i][0] && hr <= data[i][1]) {
            greeting = data[i][2];
        }
    }
    return greeting;
}

function Sidebar(props) {
    const auth = useAuth();
    // to check for active links and opened collapses
    let location = useLocation();
    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    const mainPanel = React.useRef();
    let variantChange = "0.2s linear";
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes, type) => {
        // Chakra Color Mode
        const activeBg = "transparent";
        const inactiveBg = useColorModeValue("gray.100", "gray.600");

        return routes.map((prop, key) => {
            if (prop.adminOnly && !auth.getUser().admin) return;

            let isLocked = prop.lock ? !auth.getUser().membership : false;

            let isModuleLocked = prop.isModule ? !auth.getUser().admin : false;

            if (isModuleLocked) {
                isModuleLocked = prop.module ? !(auth.getUser().modules || []).find(module => module.name == prop.module) : true

                if (isModuleLocked && prop.userHas) {
                    isModuleLocked = !auth.getUser()[prop.userHas] || auth.getUser()[prop.userHas]?.length == 0;
                }
            }

            if (prop.type != type) return;
            if (prop.redirect || prop.menu === false) {
                return null;
            }
            if (prop.category) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <>
                        <Text
                            opacity={0.75}
                            fontWeight="bold"
                            mb={{
                                xl: "12px",
                            }}
                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                        >
                            {prop.name}
                        </Text>
                        {createLinks(prop.views)}
                    </>
                );
            }
            return (
                <NavLink
                    to={isLocked ? "/members/account" : (isModuleLocked ? "/members/module-locked" : prop.path)}
                    key={"create_links" + key}
                >
                    {activeRoute(prop.path) === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg={activeBg}
                            mx={{
                                xl: "auto",
                            }}
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            shadow={"md"}
                            border="2px"
                            borderColor={"teal.300"}
                            opacity={isLocked || isModuleLocked ? 0.5 : 1}
                        >
                            <Flex>
                                <IconBox
                                    bg="teal.300"
                                    color="white"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                    transition={variantChange}
                                >
                                    {isLocked || isModuleLocked ? <FaLock /> : prop.icon}
                                </IconBox>
                                <Text my="auto" fontSize="sm">
                                    {prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg="transparent"
                            py="12px"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            opacity={isLocked || isModuleLocked ? 0.5 : 1}
                        >
                            <Flex>
                                <IconBox
                                    bg={inactiveBg}
                                    color="teal.300"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                    transition={variantChange}
                                >
                                    {isLocked || isModuleLocked ? <FaLock /> : prop.icon}
                                </IconBox>
                                <Text my="auto" fontSize="sm">
                                    {prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };
    const { logoText, routes, sidebarVariant } = props;

    var linksMember = <>{createLinks(routes, "member")}</>;
    var linksAdmin = <>{createLinks(routes, "admin")}</>;
    //  BRAND
    //  Chakra Color Mode
    const mainText = useColorModeValue("gray.700", "gray.200");
    let sidebarBg = "none";
    let sidebarRadius = "0px";
    let sidebarMargins = "0px";
    if (sidebarVariant === "opaque") {
        sidebarBg = useColorModeValue("white", "gray.700");
        sidebarRadius = "16px";
        sidebarMargins = "16px 0px 16px 16px";
    }
    var brand = (
        <Box pt={"25px"} mb="12px">
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                mb={5}
                flexDirection={"column"}
            >
                <Link href={`/`} lineHeight="120%" fontWeight="bold" fontSize="11px">
                    <Text fontSize="sm" mt="3px" textAlign={"center"}>
                        WS Consultoria Climatológica
                    </Text>
                </Link>
                <Text mt={2} fontSize={"xs"}>
                    {greeting()}, {auth.check() ? auth.getUser().name : "Visitante"}
                </Text>
            </Box>
            <Separator />
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                my={2}
                flexDirection={"column"}
            >
                <Text fontSize={"sm"} fontWeight={"semibold"}>
                    Área de Membros
                </Text>
            </Box>
            <Separator />
        </Box>
    );

    // SIDEBAR
    return (
        <Box className="sidebar" ref={mainPanel}>
            <Box display={{ sm: "none", xl: "block" }} position="fixed">
                <Box
                    bg={sidebarBg}
                    transition={variantChange}
                    w="260px"
                    maxW="260px"
                    h="calc(100vh)"
                    ps="20px"
                    pe="20px"
                    overflowY={"auto"}
                >
                    <Box>{brand}</Box>
                    <Stack direction="column" mb="40px">
                        <Box>{linksMember}</Box>
                        {auth.getUser().admin ? (
                            <>
                                <Separator />
                                <Box
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex"
                                    my={2}
                                    flexDirection={"column"}
                                >
                                    <Text fontSize={"sm"} fontWeight={"semibold"}>
                                        Administrativo
                                    </Text>
                                </Box>
                                <Separator />
                                <Box>{linksAdmin}</Box>
                            </>
                        ) : null}
                    </Stack>
                </Box>
            </Box>
        </Box>
    );
}

// FUNCTIONS

export function SidebarResponsive(props) {
    const auth = useAuth();
    // to check for active links and opened collapses
    let location = useLocation();
    // this is for the rest of the collapses
    const [state, setState] = React.useState({});
    const mainPanel = React.useRef();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return location.pathname === routeName ? "active" : "";
    };
    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routes, type) => {
        // Chakra Color Mode
        const activeBg = useColorModeValue("white", "gray.700");
        const inactiveBg = useColorModeValue("white", "gray.700");

        return routes.map((prop, key) => {
            if (prop.adminOnly && !auth.getUser().admin) return;

            let isLocked = prop.lock ? !auth.getUser().membership : false;


            let isModuleLocked = prop.isModule ? !auth.getUser().admin : false;

            if (isModuleLocked) {
                isModuleLocked = prop.module ? !(auth.getUser().modules || []).find(module => module.name == prop.module) : true

                if (isModuleLocked && prop.userHas) {
                    isModuleLocked = !auth.getUser()[prop.userHas] || auth.getUser()[prop.userHas]?.length == 0;
                }
            }

            if (prop.type != type) return;
            if (prop.redirect) {
                return null;
            }
            if (prop.category) {
                var st = {};
                st[prop["state"]] = !state[prop.state];
                return (
                    <div key={key}>
                        <Text
                            opacity={0.75}
                            fontWeight="bold"
                            mb={{
                                xl: "12px",
                            }}
                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                        >
                            {prop.name}
                        </Text>
                        {createLinks(prop.views)}
                    </div>
                );
            }
            return (
                <NavLink to={isLocked ? "/members/account" : (isModuleLocked ? "/members/module-locked" : prop.path)} key={key}>
                    {activeRoute(prop.path) === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg={activeBg}
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                            opacity={isLocked || isModuleLocked ? 0.5 : 1}
                        >
                            <Flex>
                                <IconBox
                                    bg="teal.300"
                                    color="white"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                >
                                    {isLocked || isModuleLocked ? <FaLock /> : prop.icon}
                                </IconBox>
                                <Text my="auto" fontSize="sm">
                                    {prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            bg="transparent"
                            mb={{
                                xl: "12px",
                            }}
                            mx={{
                                xl: "auto",
                            }}
                            py="12px"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            borderRadius="15px"
                            _hover="none"
                            w="100%"
                            _active={{
                                bg: "inherit",
                                transform: "none",
                                borderColor: "transparent",
                            }}
                            _focus={{
                                boxShadow: "none",
                            }}
                            opacity={isLocked || isModuleLocked ? 0.5 : 1}
                        >
                            <Flex>
                                <IconBox
                                    bg={inactiveBg}
                                    color="teal.300"
                                    h="30px"
                                    w="30px"
                                    me="12px"
                                >
                                    {isLocked || isModuleLocked ? <FaLock /> : prop.icon}
                                </IconBox>
                                <Text my="auto" fontSize="sm">
                                    {prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };
    const { logoText, routes, ...rest } = props;

    var linksMember = <>{createLinks(routes, "member")}</>;
    var linksAdmin = <>{createLinks(routes, "admin")}</>;
    //  BRAND
    //  Chakra Color Mode
    const mainText = useColorModeValue("gray.700", "gray.200");
    let hamburgerColor = useColorModeValue("gray.500", "gray.200");
    if (props.secondary === true) {
        hamburgerColor = "white";
    }
    var brand = (
        <Box
            pt={"35px"}
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection={"column"}
        >
            <Link href={`/`} lineHeight="100%" fontWeight="bold" fontSize="11px">
                <Text fontSize="sm" mt="3px">
                    WS Consultoria Climatológica
                </Text>
            </Link>
            <Text mt={2} fontSize={"xs"}>
                {greeting()}, {auth.check() ? auth.getUser().name : "Visitante"}
            </Text>
            <Separator mt={5} />
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                my={2}
                flexDirection={"column"}
            >
                <Text fontSize={"sm"} fontWeight={"semibold"}>
                    Área de Membros
                </Text>
            </Box>
            <Separator mb={2} />
        </Box>
    );

    // SIDEBAR
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();
    // Color variables
    return (
        <Flex
            display={{ sm: "flex", xl: "none" }}
            ref={mainPanel}
            alignItems="center"
        >
            <HamburgerIcon
                color={hamburgerColor}
                w="18px"
                h="18px"
                ref={btnRef}
                // colorScheme="teal"
                onClick={onOpen}
            />
            <Drawer
                isOpen={isOpen}
                onClose={onClose}
                placement="left"
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent w="250px" maxW="250px">
                    <DrawerCloseButton
                        _focus={{ boxShadow: "none" }}
                        _hover={{ boxShadow: "none" }}
                    />
                    <DrawerBody maxW="250px" px="1rem">
                        <Box maxW="100%" h="100%">
                            <Box>{brand}</Box>
                            <Stack direction="column" mb="40px">
                                <Box>{linksMember}</Box>
                            </Stack>
                            {auth.getUser().admin ? (
                                <>
                                    <Separator />
                                    <Box
                                        justifyContent="center"
                                        alignItems="center"
                                        display="flex"
                                        my={2}
                                        flexDirection={"column"}
                                    >
                                        <Text fontSize={"sm"} fontWeight={"semibold"}>
                                            Adminsitrativo
                                        </Text>
                                    </Box>
                                    <Separator />
                                    <Box>{linksAdmin}</Box>
                                </>
                            ) : null}
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    );
}
// PROPS

Sidebar.propTypes = {
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    variant: PropTypes.string,
};
SidebarResponsive.propTypes = {
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
