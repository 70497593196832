const brazilGeoJson = {
    "type": "Feature",
    "properties": {
    "shapeName": "Brazil",
    "shapeISO": "BRA",
    "shapeID": "88855331B58508533874502",
    "shapeGroup": "BRA",
    "shapeType": "ADM0"
    },
    "geometry": {
    "type": "MultiPolygon",
    "coordinates": [
        [
        [
            [-48.540067916992186, -27.937168526367195],
            [-48.541767916992185, -27.934068526367199],
            [-48.545067916992181, -27.933368526367197],
            [-48.54416791699218, -27.936968526367195],
            [-48.54276791699219, -27.940168526367195],
            [-48.540067916992186, -27.937168526367195]
        ]
        ],
        [
        [
            [-48.501267916992184, -27.837868526367195],
            [-48.50166791699219, -27.834768526367196],
            [-48.505767916992184, -27.835168526367198],
            [-48.50766791699219, -27.838968526367196],
            [-48.503067916992187, -27.840468526367196],
            [-48.501267916992184, -27.837868526367195]
        ]
        ],
        [
        [
            [-48.524867916992186, -27.842668526367198],
            [-48.523767916992185, -27.839068526367196],
            [-48.520467916992189, -27.837568526367196],
            [-48.520767916992185, -27.834368526367196],
            [-48.525367916992181, -27.835768526367197],
            [-48.527667916992186, -27.838968526367196],
            [-48.529767916992185, -27.842868526367198],
            [-48.527567916992183, -27.844868526367197],
            [-48.524867916992186, -27.842668526367198]
        ]
        ],
        [
        [
            [-48.464867916992183, -27.701768526367196],
            [-48.463667916992186, -27.698468526367197],
            [-48.462667916992189, -27.695168526367198],
            [-48.46186791699219, -27.691868526367195],
            [-48.465467916992182, -27.691568526367199],
            [-48.466467916992187, -27.694268526367196],
            [-48.467567916992181, -27.698868526367196],
            [-48.467267916992185, -27.703268526367197],
            [-48.464867916992183, -27.701768526367196]
        ]
        ],
        [
        [
            [-48.355567916992186, -27.484668526367194],
            [-48.358667916992189, -27.485668526367196],
            [-48.362167916992185, -27.487468526367195],
            [-48.357567916992181, -27.488368526367196],
            [-48.355567916992186, -27.484668526367194]
        ]
        ],
        [
        [
            [-48.356767916992183, -27.293868526367199],
            [-48.358567916992186, -27.287468526367196],
            [-48.358967916992185, -27.283468526367194],
            [-48.359267916992181, -27.279068526367197],
            [-48.35996791699219, -27.274568526367197],
            [-48.363267916992186, -27.274268526367194],
            [-48.367467916992183, -27.274068526367195],
            [-48.369767916992188, -27.270768526367196],
            [-48.372667916992185, -27.273768526367196],
            [-48.375867916992185, -27.278968526367194],
            [-48.375967916992181, -27.283068526367195],
            [-48.371567916992184, -27.284268526367196],
            [-48.369467916992186, -27.286468526367194],
            [-48.367967916992185, -27.289768526367197],
            [-48.366567916992182, -27.294168526367194],
            [-48.363667916992185, -27.296068526367197],
            [-48.35966791699218, -27.296468526367196],
            [-48.356367916992184, -27.297868526367196],
            [-48.356767916992183, -27.293868526367199]
        ]
        ],
        [
        [
            [-48.464467916992184, -27.204868526367196],
            [-48.46926791699218, -27.204268526367194],
            [-48.473467916992185, -27.205168526367196],
            [-48.474167916992187, -27.208268526367196],
            [-48.470867916992184, -27.207868526367196],
            [-48.467267916992185, -27.209268526367197],
            [-48.464467916992184, -27.204868526367196]
        ]
        ],
        [
        [
            [-48.40706791699219, -27.183868526367196],
            [-48.403667916992184, -27.181068526367199],
            [-48.405467916992187, -27.176868526367194],
            [-48.409167916992189, -27.181068526367199],
            [-48.409567916992188, -27.186968526367195],
            [-48.40706791699219, -27.183868526367196]
        ]
        ],
        [
        [
            [-48.476667916992184, -27.140768526367197],
            [-48.478567916992183, -27.136368526367196],
            [-48.480567916992186, -27.138468526367198],
            [-48.476667916992184, -27.140768526367197]
        ]
        ],
        [
        [
            [-48.543167916992189, -27.142168526367197],
            [-48.540667916992184, -27.139868526367195],
            [-48.540667916992184, -27.136268526367196],
            [-48.544267916992183, -27.138568526367195],
            [-48.546467916992185, -27.140668526367197],
            [-48.546867916992184, -27.143768526367197],
            [-48.543167916992189, -27.142168526367197]
        ]
        ],
        [
        [
            [-48.57756791699218, -26.458468526367199],
            [-48.579667916992186, -26.455668526367198],
            [-48.582667916992186, -26.459668526367196],
            [-48.578967916992184, -26.461468526367199],
            [-48.57756791699218, -26.458468526367199]
        ]
        ],
        [
        [
            [-48.482867916992184, -26.175268526367198],
            [-48.485867916992184, -26.175868526367196],
            [-48.486367916992187, -26.178668526367197],
            [-48.482867916992184, -26.179668526367195],
            [-48.482867916992184, -26.175268526367198]
        ]
        ],
        [
        [
            [-47.859267916992181, -25.125468526367197],
            [-47.855667916992182, -25.124068526367196],
            [-47.852867916992182, -25.121168526367196],
            [-47.850267916992188, -25.119468526367196],
            [-47.853067916992188, -25.116468526367196],
            [-47.857767916992188, -25.117468526367198],
            [-47.860667916992185, -25.120068526367199],
            [-47.864967916992185, -25.122668526367196],
            [-47.86296791699219, -25.127368526367196],
            [-47.859267916992181, -25.125468526367197]
        ]
        ],
        [
        [
            [-46.671767916992188, -24.488268526367197],
            [-46.672267916992183, -24.485068526367197],
            [-46.674367916992182, -24.482468526367196],
            [-46.675567916992186, -24.477768526367196],
            [-46.677267916992186, -24.480568526367197],
            [-46.677567916992189, -24.484668526367194],
            [-46.676567916992184, -24.487768526367198],
            [-46.676067916992189, -24.490568526367198],
            [-46.671767916992188, -24.488268526367197]
        ]
        ],
        [
        [
            [-45.697367916992185, -24.106668526367194],
            [-45.692967916992188, -24.105868526367196],
            [-45.688467916992188, -24.103968526367197],
            [-45.684467916992183, -24.101668526367199],
            [-45.682167916992185, -24.097368526367198],
            [-45.687667916992183, -24.096668526367196],
            [-45.689867916992185, -24.092268526367196],
            [-45.691667916992188, -24.095568526367195],
            [-45.693867916992183, -24.098468526367196],
            [-45.696267916992184, -24.100268526367195],
            [-45.698567916992182, -24.102068526367198],
            [-45.700667916992188, -24.104968526367195],
            [-45.702867916992183, -24.106768526367198],
            [-45.700667916992188, -24.108868526367196],
            [-45.697367916992185, -24.106668526367194]
        ]
        ],
        [
        [
            [-46.263467916992184, -24.047768526367197],
            [-46.265667916992186, -24.050568526367197],
            [-46.261467916992189, -24.051668526367195],
            [-46.263467916992184, -24.047768526367197]
        ]
        ],
        [
        [
            [-46.184967916992186, -23.972368526367198],
            [-46.182167916992185, -23.974968526367196],
            [-46.180867916992185, -23.971668526367196],
            [-46.184967916992186, -23.972368526367198]
        ]
        ],
        [
        [
            [-45.777067916992188, -23.868068526367196],
            [-45.775067916992185, -23.864468526367197],
            [-45.774067916992188, -23.860668526367196],
            [-45.776867916992188, -23.858168526367194],
            [-45.780967916992182, -23.858868526367196],
            [-45.784167916992182, -23.859668526367194],
            [-45.786667916992187, -23.863268526367197],
            [-45.785967916992185, -23.868268526367196],
            [-45.781267916992185, -23.870168526367195],
            [-45.777067916992188, -23.868068526367196]
        ]
        ],
        [
        [
            [-45.522067916992185, -23.853068526367196],
            [-45.520767916992185, -23.848668526367199],
            [-45.523767916992185, -23.846968526367196],
            [-45.525967916992187, -23.850368526367198],
            [-45.525067916992185, -23.854468526367196],
            [-45.522067916992185, -23.853068526367196]
        ]
        ],
        [
        [
            [-45.242967916992185, -23.837868526367195],
            [-45.240367916992184, -23.841168526367198],
            [-45.236567916992186, -23.842568526367195],
            [-45.231167916992185, -23.840068526367197],
            [-45.228067916992188, -23.837268526367197],
            [-45.231467916992187, -23.836168526367196],
            [-45.235067916992186, -23.836568526367195],
            [-45.238067916992186, -23.838168526367198],
            [-45.242967916992185, -23.837868526367195]
        ]
        ],
        [
        [
            [-45.717367916992188, -23.799668526367196],
            [-45.721667916992182, -23.799368526367196],
            [-45.724967916992185, -23.802268526367197],
            [-45.720967916992187, -23.803868526367197],
            [-45.716267916992187, -23.804168526367196],
            [-45.717367916992188, -23.799668526367196]
        ]
        ],
        [
        [
            [-45.139667916992188, -23.796368526367196],
            [-45.138867916992183, -23.791668526367197],
            [-45.142167916992186, -23.790568526367196],
            [-45.145667916992188, -23.791368526367194],
            [-45.149267916992187, -23.792468526367195],
            [-45.152667916992186, -23.794668526367197],
            [-45.155067916992188, -23.796968526367195],
            [-45.157367916992186, -23.799368526367196],
            [-45.160067916992183, -23.802468526367196],
            [-45.157367916992186, -23.804368526367199],
            [-45.156467916992185, -23.808868526367196],
            [-45.154167916992186, -23.810768526367198],
            [-45.150667916992184, -23.811968526367195],
            [-45.147567916992188, -23.814168526367197],
            [-45.145767916992185, -23.816568526367199],
            [-45.142367916992185, -23.814368526367197],
            [-45.139667916992188, -23.812968526367197],
            [-45.136267916992182, -23.811568526367196],
            [-45.133267916992182, -23.809968526367197],
            [-45.129567916992187, -23.809768526367197],
            [-45.125567916992182, -23.810568526367199],
            [-45.122767916992188, -23.812468526367198],
            [-45.119767916992188, -23.813368526367196],
            [-45.116867916992184, -23.810768526367198],
            [-45.116667916992185, -23.806968526367196],
            [-45.116867916992184, -23.803268526367198],
            [-45.121867916992187, -23.802768526367196],
            [-45.124367916992185, -23.801068526367196],
            [-45.128067916992187, -23.799268526367197],
            [-45.132367916992187, -23.797768526367197],
            [-45.136367916992185, -23.797868526367196],
            [-45.139667916992188, -23.796368526367196]
        ]
        ],
        [
        [
            [-45.022167916992188, -23.740068526367196],
            [-45.022867916992183, -23.745468526367198],
            [-45.021767916992182, -23.748868526367197],
            [-45.018267916992187, -23.750168526367197],
            [-45.017067916992183, -23.753468526367197],
            [-45.018167916992184, -23.757968526367197],
            [-45.015167916992183, -23.759868526367196],
            [-45.011067916992182, -23.758868526367195],
            [-45.008467916992188, -23.755768526367198],
            [-45.007167916992188, -23.752768526367198],
            [-45.007967916992186, -23.749068526367196],
            [-45.011067916992182, -23.748068526367199],
            [-45.014967916992184, -23.748068526367199],
            [-45.014967916992184, -23.744968526367195],
            [-45.014267916992182, -23.741968526367195],
            [-45.016467916992184, -23.739668526367197],
            [-45.022167916992188, -23.740068526367196]
        ]
        ],
        [
        [
            [-45.022167916992188, -23.740068526367196],
            [-45.020667916992188, -23.735868526367199],
            [-45.024367916992183, -23.737268526367195],
            [-45.022167916992188, -23.740068526367196]
        ]
        ],
        [
        [
            [-45.242967916992185, -23.837868526367195],
            [-45.241967916992188, -23.835068526367195],
            [-45.238967916992188, -23.833668526367198],
            [-45.239767916992186, -23.830468526367198],
            [-45.244067916992186, -23.830768526367194],
            [-45.244067916992186, -23.827668526367198],
            [-45.247467916992186, -23.824868526367197],
            [-45.245167916992187, -23.821868526367197],
            [-45.242167916992187, -23.821568526367194],
            [-45.239167916992187, -23.819068526367197],
            [-45.237567916992184, -23.815468526367198],
            [-45.237967916992183, -23.811968526367195],
            [-45.241667916992185, -23.811168526367197],
            [-45.241667916992185, -23.807168526367196],
            [-45.238667916992185, -23.802968526367195],
            [-45.236667916992182, -23.800768526367197],
            [-45.236067916992184, -23.796668526367196],
            [-45.236067916992184, -23.792068526367196],
            [-45.234667916992187, -23.789168526367199],
            [-45.233267916992183, -23.785368526367197],
            [-45.234267916992188, -23.781668526367199],
            [-45.231067916992188, -23.777568526367197],
            [-45.233967916992185, -23.775468526367199],
            [-45.235867916992184, -23.771568526367197],
            [-45.238867916992184, -23.767968526367198],
            [-45.241367916992182, -23.765368526367197],
            [-45.243367916992185, -23.762968526367196],
            [-45.247667916992185, -23.762468526367197],
            [-45.252067916992182, -23.762868526367196],
            [-45.256267916992186, -23.761768526367199],
            [-45.259567916992182, -23.758568526367196],
            [-45.258767916992184, -23.754468526367198],
            [-45.256567916992182, -23.751668526367197],
            [-45.257367916992187, -23.748768526367197],
            [-45.260367916992188, -23.746068526367196],
            [-45.262967916992189, -23.742268526367198],
            [-45.266267916992184, -23.742968526367196],
            [-45.267967916992184, -23.740568526367198],
            [-45.271267916992187, -23.739668526367197],
            [-45.274367916992183, -23.737568526367198],
            [-45.276767916992185, -23.735268526367197],
            [-45.280067916992188, -23.735068526367197],
            [-45.283467916992187, -23.734668526367194],
            [-45.288067916992183, -23.736368526367194],
            [-45.291967916992185, -23.736668526367197],
            [-45.294567916992186, -23.733668526367197],
            [-45.298967916992183, -23.732868526367199],
            [-45.302767916992188, -23.731368526367199],
            [-45.306567916992186, -23.731468526367195],
            [-45.310167916992185, -23.730668526367197],
            [-45.313067916992182, -23.728868526367194],
            [-45.315167916992188, -23.726668526367199],
            [-45.317667916992185, -23.724568526367197],
            [-45.320467916992186, -23.723668526367199],
            [-45.323567916992182, -23.723368526367196],
            [-45.327567916992187, -23.722568526367198],
            [-45.332567916992183, -23.722668526367194],
            [-45.335467916992187, -23.723968526367194],
            [-45.338367916992183, -23.726368526367196],
            [-45.341467916992187, -23.728568526367198],
            [-45.342267916992185, -23.732968526367195],
            [-45.344167916992184, -23.737468526367195],
            [-45.347267916992188, -23.739768526367197],
            [-45.348667916992184, -23.743068526367196],
            [-45.348367916992188, -23.746568526367199],
            [-45.348467916992185, -23.749668526367195],
            [-45.349567916992186, -23.752968526367198],
            [-45.350667916992187, -23.756768526367196],
            [-45.350867916992186, -23.759968526367196],
            [-45.349267916992183, -23.763868526367197],
            [-45.351667916992184, -23.767968526367198],
            [-45.351967916992187, -23.771568526367197],
            [-45.353667916992187, -23.773968526367199],
            [-45.357267916992186, -23.775468526367199],
            [-45.358967916992185, -23.778168526367196],
            [-45.358267916992183, -23.781768526367195],
            [-45.358967916992185, -23.784868526367198],
            [-45.362567916992184, -23.787068526367197],
            [-45.364067916992184, -23.791368526367194],
            [-45.365067916992189, -23.794568526367197],
            [-45.365467916992188, -23.797768526367197],
            [-45.365067916992189, -23.801368526367199],
            [-45.365267916992188, -23.804768526367198],
            [-45.365767916992183, -23.808368526367197],
            [-45.367667916992183, -23.811968526367195],
            [-45.370567916992186, -23.815468526367198],
            [-45.373367916992187, -23.819268526367196],
            [-45.376567916992187, -23.819068526367197],
            [-45.379967916992186, -23.821668526367198],
            [-45.383067916992182, -23.824368526367195],
            [-45.385667916992183, -23.827168526367196],
            [-45.388567916992187, -23.829368526367197],
            [-45.391867916992183, -23.831268526367197],
            [-45.394267916992185, -23.833468526367199],
            [-45.396767916992182, -23.836468526367199],
            [-45.400167916992189, -23.839868526367198],
            [-45.403667916992184, -23.842968526367194],
            [-45.407267916992183, -23.844068526367195],
            [-45.410367916992186, -23.846168526367194],
            [-45.413467916992182, -23.849468526367197],
            [-45.414767916992183, -23.854768526367195],
            [-45.417767916992183, -23.858868526367196],
            [-45.422567916992186, -23.859668526367194],
            [-45.426067916992189, -23.859168526367196],
            [-45.428967916992185, -23.859768526367198],
            [-45.431167916992187, -23.861768526367197],
            [-45.431367916992187, -23.864968526367196],
            [-45.432967916992183, -23.867968526367196],
            [-45.436067916992187, -23.870268526367198],
            [-45.441167916992185, -23.872568526367196],
            [-45.444167916992185, -23.876668526367197],
            [-45.446667916992183, -23.879168526367195],
            [-45.448767916992182, -23.881768526367196],
            [-45.451067916992187, -23.884368526367197],
            [-45.454267916992187, -23.884868526367196],
            [-45.458267916992185, -23.886068526367197],
            [-45.461267916992185, -23.888968526367197],
            [-45.460967916992182, -23.892168526367197],
            [-45.460667916992186, -23.895368526367196],
            [-45.459767916992185, -23.899468526367194],
            [-45.459367916992186, -23.902668526367197],
            [-45.458967916992187, -23.905968526367197],
            [-45.459867916992188, -23.909768526367195],
            [-45.459667916992188, -23.914568526367198],
            [-45.456467916992182, -23.917868526367194],
            [-45.454067916992187, -23.920668526367194],
            [-45.451767916992182, -23.922468526367197],
            [-45.450667916992188, -23.925568526367197],
            [-45.448467916992186, -23.927468526367196],
            [-45.445767916992182, -23.931168526367195],
            [-45.443467916992184, -23.934468526367198],
            [-45.440567916992187, -23.935468526367195],
            [-45.437767916992186, -23.936568526367196],
            [-45.434767916992186, -23.936868526367196],
            [-45.431867916992182, -23.938768526367198],
            [-45.427467916992185, -23.939868526367196],
            [-45.422767916992186, -23.938868526367195],
            [-45.417767916992183, -23.938868526367195],
            [-45.414467916992187, -23.938368526367196],
            [-45.411167916992184, -23.938568526367199],
            [-45.407667916992182, -23.937968526367197],
            [-45.405167916992184, -23.935468526367195],
            [-45.401267916992182, -23.933668526367196],
            [-45.396867916992186, -23.932668526367195],
            [-45.393667916992186, -23.932668526367195],
            [-45.390667916992186, -23.931968526367196],
            [-45.386367916992185, -23.931568526367194],
            [-45.381567916992182, -23.930168526367197],
            [-45.377167916992185, -23.930768526367196],
            [-45.373267916992184, -23.931168526367195],
            [-45.370167916992187, -23.930868526367199],
            [-45.366867916992184, -23.930168526367197],
            [-45.363967916992188, -23.929768526367198],
            [-45.360667916992185, -23.928368526367194],
            [-45.356667916992187, -23.926868526367194],
            [-45.353067916992188, -23.923068526367196],
            [-45.350667916992187, -23.919268526367198],
            [-45.348067916992186, -23.916968526367196],
            [-45.344767916992183, -23.918168526367197],
            [-45.343767916992185, -23.921868526367199],
            [-45.340467916992182, -23.924468526367196],
            [-45.339067916992185, -23.921368526367196],
            [-45.336867916992183, -23.919268526367198],
            [-45.333467916992184, -23.917168526367199],
            [-45.329267916992187, -23.915368526367196],
            [-45.326367916992183, -23.912768526367195],
            [-45.322467916992188, -23.914168526367199],
            [-45.319367916992185, -23.915568526367196],
            [-45.314167916992183, -23.913868526367196],
            [-45.309367916992187, -23.911668526367194],
            [-45.304367916992184, -23.909168526367196],
            [-45.301867916992187, -23.914468526367195],
            [-45.298567916992184, -23.913368526367197],
            [-45.295067916992188, -23.911668526367194],
            [-45.291367916992186, -23.911268526367195],
            [-45.289567916992183, -23.915668526367199],
            [-45.288067916992183, -23.918168526367197],
            [-45.285167916992187, -23.920368526367199],
            [-45.283067916992188, -23.923368526367199],
            [-45.281267916992185, -23.927168526367197],
            [-45.280567916992183, -23.930768526367196],
            [-45.281267916992185, -23.934068526367199],
            [-45.282767916992185, -23.936868526367196],
            [-45.285067916992183, -23.938568526367199],
            [-45.286767916992183, -23.941368526367196],
            [-45.284267916992185, -23.944668526367195],
            [-45.281267916992185, -23.947168526367197],
            [-45.279767916992185, -23.950668526367195],
            [-45.278767916992187, -23.953468526367196],
            [-45.275667916992184, -23.955668526367198],
            [-45.273267916992182, -23.958768526367194],
            [-45.270967916992184, -23.961468526367199],
            [-45.268567916992183, -23.963968526367196],
            [-45.265967916992182, -23.965868526367196],
            [-45.261867916992188, -23.965968526367199],
            [-45.258467916992188, -23.965868526367196],
            [-45.255167916992185, -23.966168526367198],
            [-45.249867916992187, -23.966968526367197],
            [-45.244767916992188, -23.965168526367197],
            [-45.241067916992186, -23.962868526367195],
            [-45.238667916992185, -23.960768526367197],
            [-45.236967916992185, -23.956168526367197],
            [-45.235267916992186, -23.951468526367197],
            [-45.231967916992183, -23.947168526367197],
            [-45.228067916992188, -23.943568526367194],
            [-45.233067916992184, -23.944368526367196],
            [-45.235867916992184, -23.942968526367196],
            [-45.235367916992182, -23.939868526367196],
            [-45.235667916992185, -23.936868526367196],
            [-45.235567916992188, -23.933368526367197],
            [-45.233267916992183, -23.929968526367198],
            [-45.236467916992183, -23.926968526367197],
            [-45.237467916992188, -23.923268526367195],
            [-45.236067916992184, -23.919468526367197],
            [-45.237467916992188, -23.916668526367197],
            [-45.237467916992188, -23.913368526367197],
            [-45.237467916992188, -23.909268526367196],
            [-45.234967916992183, -23.906768526367195],
            [-45.231667916992187, -23.907068526367198],
            [-45.226967916992187, -23.907368526367197],
            [-45.223767916992188, -23.904168526367194],
            [-45.226367916992189, -23.902068526367195],
            [-45.228367916992184, -23.899068526367195],
            [-45.232167916992182, -23.899768526367197],
            [-45.235267916992186, -23.899068526367195],
            [-45.238567916992189, -23.895468526367196],
            [-45.242367916992187, -23.893568526367197],
            [-45.245067916992184, -23.897068526367196],
            [-45.245067916992184, -23.901168526367197],
            [-45.249467916992188, -23.901568526367196],
            [-45.252667916992188, -23.899068526367195],
            [-45.254467916992184, -23.896768526367197],
            [-45.257367916992187, -23.895368526367196],
            [-45.260467916992184, -23.893268526367194],
            [-45.264067916992182, -23.892568526367196],
            [-45.268167916992184, -23.892868526367195],
            [-45.272567916992188, -23.892068526367197],
            [-45.274567916992183, -23.887468526367197],
            [-45.277367916992183, -23.884368526367197],
            [-45.275067916992185, -23.882468526367198],
            [-45.272567916992188, -23.880468526367196],
            [-45.269367916992188, -23.879868526367197],
            [-45.269667916992184, -23.876068526367199],
            [-45.272567916992188, -23.873668526367197],
            [-45.275467916992184, -23.873268526367198],
            [-45.278367916992188, -23.872968526367195],
            [-45.281967916992187, -23.873768526367197],
            [-45.285567916992186, -23.873668526367197],
            [-45.289467916992187, -23.871368526367196],
            [-45.289467916992187, -23.866468526367196],
            [-45.286367916992184, -23.863668526367196],
            [-45.288167916992187, -23.860568526367196],
            [-45.288467916992182, -23.856368526367199],
            [-45.287367916992189, -23.852868526367196],
            [-45.285667916992182, -23.849968526367196],
            [-45.281967916992187, -23.848468526367196],
            [-45.280067916992188, -23.843768526367196],
            [-45.275867916992183, -23.841168526367198],
            [-45.272967916992187, -23.838468526367194],
            [-45.269567916992187, -23.837368526367197],
            [-45.266067916992185, -23.837868526367195],
            [-45.261467916992189, -23.836968526367194],
            [-45.255667916992188, -23.836568526367195],
            [-45.251667916992183, -23.837268526367197],
            [-45.247667916992185, -23.837868526367195],
            [-45.242967916992185, -23.837868526367195]
        ]
        ],
        [
        [
            [-45.289567916992183, -23.593168526367194],
            [-45.292867916992186, -23.595168526367196],
            [-45.294667916992182, -23.597568526367198],
            [-45.294167916992187, -23.601168526367196],
            [-45.290267916992185, -23.601668526367199],
            [-45.287067916992186, -23.600068526367195],
            [-45.283167916992184, -23.599868526367196],
            [-45.280467916992187, -23.596168526367194],
            [-45.281967916992187, -23.593368526367197],
            [-45.285067916992183, -23.594168526367199],
            [-45.289567916992183, -23.593168526367194]
        ]
        ],
        [
        [
            [-45.154767916992185, -23.570968526367196],
            [-45.151467916992182, -23.568868526367197],
            [-45.147867916992183, -23.568768526367194],
            [-45.150767916992187, -23.565468526367198],
            [-45.152867916992186, -23.562968526367197],
            [-45.155967916992182, -23.560468526367195],
            [-45.159167916992182, -23.562668526367197],
            [-45.162067916992186, -23.566068526367197],
            [-45.162367916992189, -23.569568526367195],
            [-45.158067916992188, -23.571568526367194],
            [-45.154767916992185, -23.570968526367196]
        ]
        ],
        [
        [
            [-45.079367916992183, -23.531668526367199],
            [-45.082067916992187, -23.535968526367196],
            [-45.081867916992188, -23.539468526367195],
            [-45.079367916992183, -23.542268526367195],
            [-45.078567916992185, -23.545568526367198],
            [-45.079567916992183, -23.548868526367194],
            [-45.080767916992187, -23.552868526367199],
            [-45.080367916992188, -23.556568526367194],
            [-45.078967916992184, -23.559668526367197],
            [-45.077567916992187, -23.563368526367196],
            [-45.075267916992182, -23.559868526367197],
            [-45.072067916992182, -23.557268526367196],
            [-45.067667916992185, -23.555768526367196],
            [-45.064167916992183, -23.554368526367199],
            [-45.061967916992188, -23.552168526367197],
            [-45.059467916992183, -23.549768526367195],
            [-45.056867916992182, -23.546368526367196],
            [-45.053267916992183, -23.545668526367194],
            [-45.049367916992182, -23.545368526367199],
            [-45.046467916992185, -23.543468526367196],
            [-45.043367916992182, -23.542468526367195],
            [-45.040667916992184, -23.540668526367199],
            [-45.040267916992185, -23.537368526367196],
            [-45.039767916992183, -23.534168526367196],
            [-45.043967916992187, -23.533868526367197],
            [-45.045567916992184, -23.531268526367196],
            [-45.049367916992182, -23.529568526367196],
            [-45.054667916992187, -23.527668526367197],
            [-45.059067916992184, -23.527668526367197],
            [-45.060767916992184, -23.531468526367199],
            [-45.061667916992185, -23.535368526367197],
            [-45.064667916992185, -23.538468526367197],
            [-45.070267916992186, -23.539168526367199],
            [-45.074367916992188, -23.538068526367198],
            [-45.077167916992188, -23.535868526367196],
            [-45.079367916992183, -23.531668526367199]
        ]
        ],
        [
        [
            [-45.079367916992183, -23.531668526367199],
            [-45.077667916992183, -23.528468526367199],
            [-45.080367916992188, -23.526768526367196],
            [-45.079367916992183, -23.531668526367199]
        ]
        ],
        [
        [
            [-44.852567916992186, -23.423368526367199],
            [-44.850667916992187, -23.420368526367199],
            [-44.853867916992186, -23.415568526367196],
            [-44.855367916992186, -23.421068526367197],
            [-44.858367916992187, -23.424168526367197],
            [-44.854567916992188, -23.425468526367197],
            [-44.852567916992186, -23.423368526367199]
        ]
        ],
        [
        [
            [-44.850367916992184, -23.397668526367198],
            [-44.853867916992186, -23.399268526367194],
            [-44.851367916992189, -23.401568526367196],
            [-44.850367916992184, -23.397668526367198]
        ]
        ],
        [
        [
            [-44.946267916992184, -23.387368526367197],
            [-44.943067916992185, -23.387068526367194],
            [-44.941067916992182, -23.384068526367194],
            [-44.945267916992186, -23.383468526367196],
            [-44.949267916992184, -23.387368526367197],
            [-44.946267916992184, -23.387368526367197]
        ]
        ],
        [
        [
            [-44.627667916992188, -23.342268526367196],
            [-44.630167916992185, -23.344468526367194],
            [-44.632367916992187, -23.346768526367196],
            [-44.633067916992182, -23.350968526367197],
            [-44.629467916992184, -23.350568526367198],
            [-44.627667916992188, -23.346668526367196],
            [-44.627667916992188, -23.342268526367196]
        ]
        ],
        [
        [
            [-44.513467916992184, -23.292068526367196],
            [-44.511567916992185, -23.295368526367199],
            [-44.507767916992186, -23.299168526367197],
            [-44.503467916992186, -23.295768526367198],
            [-44.503467916992186, -23.292868526367194],
            [-44.505767916992184, -23.290968526367195],
            [-44.509567916992182, -23.291768526367196],
            [-44.513467916992184, -23.292068526367196]
        ]
        ],
        [
        [
            [-44.155067916992188, -23.224568526367197],
            [-44.151567916992185, -23.224168526367194],
            [-44.150067916992185, -23.221168526367194],
            [-44.149567916992183, -23.216568526367197],
            [-44.153667916992184, -23.215568526367196],
            [-44.153667916992184, -23.219768526367197],
            [-44.157567916992186, -23.221968526367196],
            [-44.162067916992186, -23.224168526367194],
            [-44.159767916992188, -23.226468526367196],
            [-44.155067916992188, -23.224568526367197]
        ]
        ],
        [
        [
            [-44.592267916992185, -23.202968526367197],
            [-44.595667916992184, -23.203168526367197],
            [-44.598467916992185, -23.204868526367196],
            [-44.600967916992182, -23.208468526367199],
            [-44.603467916992187, -23.212668526367196],
            [-44.607867916992184, -23.215568526367196],
            [-44.611967916992185, -23.216668526367194],
            [-44.615567916992184, -23.218768526367196],
            [-44.619767916992188, -23.222268526367195],
            [-44.615367916992184, -23.224468526367197],
            [-44.612267916992188, -23.224168526367194],
            [-44.608567916992186, -23.223768526367195],
            [-44.604767916992188, -23.222668526367194],
            [-44.601467916992185, -23.221468526367197],
            [-44.600567916992183, -23.218168526367194],
            [-44.596667916992182, -23.215868526367196],
            [-44.591467916992187, -23.213468526367194],
            [-44.588967916992182, -23.211168526367196],
            [-44.586567916992188, -23.208968526367194],
            [-44.585867916992186, -23.204868526367196],
            [-44.590667916992182, -23.205368526367195],
            [-44.592267916992185, -23.202968526367197]
        ]
        ],
        [
        [
            [-44.579967916992182, -23.199268526367199],
            [-44.583667916992184, -23.200668526367195],
            [-44.580467916992184, -23.202368526367195],
            [-44.579967916992182, -23.199268526367199]
        ]
        ],
        [
        [
            [-44.573167916992183, -23.187968526367197],
            [-44.573267916992187, -23.184068526367199],
            [-44.576767916992182, -23.185168526367196],
            [-44.576567916992182, -23.188868526367195],
            [-44.573167916992183, -23.187968526367197]
        ]
        ],
        [
        [
            [-44.688867916992187, -23.167168526367199],
            [-44.687367916992187, -23.162768526367195],
            [-44.685167916992185, -23.159768526367195],
            [-44.682967916992183, -23.156168526367196],
            [-44.683567916992182, -23.151868526367195],
            [-44.686867916992185, -23.151768526367196],
            [-44.689567916992182, -23.154868526367196],
            [-44.694567916992185, -23.157568526367196],
            [-44.694267916992182, -23.161968526367197],
            [-44.692067916992187, -23.165368526367196],
            [-44.691867916992187, -23.168468526367196],
            [-44.688867916992187, -23.167168526367199]
        ]
        ],
        [
        [
            [-44.236467916992183, -23.083968526367194],
            [-44.237967916992183, -23.087968526367199],
            [-44.237467916992188, -23.091268526367195],
            [-44.240067916992189, -23.093668526367196],
            [-44.243267916992188, -23.095068526367196],
            [-44.246567916992184, -23.095668526367199],
            [-44.250467916992186, -23.096768526367196],
            [-44.254367916992187, -23.099268526367197],
            [-44.250867916992185, -23.100568526367198],
            [-44.248567916992187, -23.102868526367196],
            [-44.249067916992182, -23.106368526367199],
            [-44.250567916992182, -23.110268526367197],
            [-44.253767916992182, -23.111168526367194],
            [-44.257367916992187, -23.112768526367198],
            [-44.259967916992188, -23.115068526367196],
            [-44.263767916992187, -23.114968526367196],
            [-44.267167916992186, -23.115268526367196],
            [-44.270667916992188, -23.116168526367197],
            [-44.274067916992188, -23.117568526367194],
            [-44.272967916992187, -23.121668526367195],
            [-44.274067916992188, -23.124468526367195],
            [-44.278167916992182, -23.125568526367196],
            [-44.280467916992187, -23.128768526367196],
            [-44.283067916992188, -23.131668526367196],
            [-44.283067916992188, -23.135968526367197],
            [-44.287067916992186, -23.136768526367199],
            [-44.290667916992184, -23.136468526367196],
            [-44.291367916992186, -23.133168526367196],
            [-44.294967916992185, -23.132768526367194],
            [-44.296967916992187, -23.130268526367196],
            [-44.295867916992187, -23.126568526367194],
            [-44.294467916992183, -23.122968526367195],
            [-44.294167916992187, -23.119168526367197],
            [-44.295767916992183, -23.116168526367197],
            [-44.300867916992182, -23.119368526367197],
            [-44.304767916992184, -23.120768526367197],
            [-44.307467916992188, -23.123268526367198],
            [-44.309667916992183, -23.125568526367196],
            [-44.311567916992182, -23.128468526367197],
            [-44.312967916992186, -23.132468526367198],
            [-44.312267916992184, -23.136068526367197],
            [-44.316667916992188, -23.135668526367198],
            [-44.320167916992183, -23.137368526367197],
            [-44.324267916992184, -23.139368526367196],
            [-44.320667916992186, -23.142868526367195],
            [-44.320967916992188, -23.146768526367197],
            [-44.322867916992188, -23.150468526367199],
            [-44.324967916992186, -23.153768526367195],
            [-44.328467916992182, -23.155168526367198],
            [-44.333267916992185, -23.155568526367198],
            [-44.336567916992188, -23.154868526367196],
            [-44.340367916992186, -23.154268526367197],
            [-44.343367916992186, -23.155868526367197],
            [-44.345867916992184, -23.158368526367198],
            [-44.349567916992186, -23.160268526367197],
            [-44.354767916992188, -23.159868526367198],
            [-44.356767916992183, -23.162068526367197],
            [-44.358967916992185, -23.164468526367195],
            [-44.362467916992188, -23.165668526367199],
            [-44.367167916992187, -23.165568526367196],
            [-44.370567916992186, -23.167168526367199],
            [-44.373767916992186, -23.168968526367195],
            [-44.376667916992183, -23.171468526367196],
            [-44.377667916992188, -23.174768526367195],
            [-44.377067916992182, -23.178268526367198],
            [-44.374367916992185, -23.180568526367196],
            [-44.370867916992182, -23.182268526367196],
            [-44.366167916992183, -23.180468526367196],
            [-44.362567916992184, -23.179368526367199],
            [-44.362167916992185, -23.182268526367196],
            [-44.359167916992185, -23.184768526367197],
            [-44.357767916992188, -23.189468526367197],
            [-44.354167916992182, -23.189168526367197],
            [-44.351467916992185, -23.185868526367194],
            [-44.347667916992187, -23.184668526367197],
            [-44.346167916992187, -23.181568526367194],
            [-44.343067916992183, -23.180868526367199],
            [-44.341267916992187, -23.184668526367197],
            [-44.340067916992183, -23.187768526367197],
            [-44.340067916992183, -23.191268526367196],
            [-44.342067916992185, -23.194868526367195],
            [-44.343767916992185, -23.197768526367199],
            [-44.346267916992183, -23.200968526367198],
            [-44.348667916992184, -23.204568526367197],
            [-44.350267916992188, -23.208268526367196],
            [-44.350867916992186, -23.211868526367198],
            [-44.350267916992188, -23.215868526367196],
            [-44.345867916992184, -23.217668526367198],
            [-44.341267916992187, -23.218168526367194],
            [-44.342967916992187, -23.220968526367194],
            [-44.343767916992185, -23.223968526367194],
            [-44.339867916992183, -23.223868526367198],
            [-44.336967916992187, -23.223468526367196],
            [-44.333967916992187, -23.223368526367196],
            [-44.333167916992188, -23.219468526367194],
            [-44.333667916992184, -23.215668526367196],
            [-44.331867916992188, -23.212368526367197],
            [-44.329667916992186, -23.208168526367196],
            [-44.326267916992187, -23.205668526367198],
            [-44.322867916992188, -23.202868526367197],
            [-44.320667916992186, -23.199368526367195],
            [-44.318467916992184, -23.195668526367196],
            [-44.316267916992182, -23.191568526367199],
            [-44.316867916992187, -23.185468526367195],
            [-44.313567916992184, -23.182668526367195],
            [-44.310867916992187, -23.180468526367196],
            [-44.306867916992182, -23.179368526367199],
            [-44.302967916992188, -23.178268526367198],
            [-44.298867916992187, -23.177268526367197],
            [-44.295067916992188, -23.176868526367194],
            [-44.291667916992182, -23.176568526367198],
            [-44.286667916992187, -23.177868526367199],
            [-44.282367916992186, -23.177468526367196],
            [-44.278367916992188, -23.177868526367199],
            [-44.273167916992186, -23.179168526367196],
            [-44.270067916992183, -23.182268526367196],
            [-44.266767916992187, -23.186268526367197],
            [-44.266467916992184, -23.191068526367197],
            [-44.262967916992189, -23.192068526367194],
            [-44.259067916992187, -23.190468526367198],
            [-44.255967916992184, -23.189068526367194],
            [-44.252667916992188, -23.190268526367198],
            [-44.250867916992185, -23.193468526367198],
            [-44.250767916992189, -23.196368526367195],
            [-44.253567916992182, -23.198868526367196],
            [-44.257167916992188, -23.199668526367198],
            [-44.257667916992183, -23.202468526367198],
            [-44.255167916992185, -23.204668526367197],
            [-44.251267916992184, -23.204868526367196],
            [-44.247667916992185, -23.202168526367196],
            [-44.243867916992187, -23.200668526367195],
            [-44.239967916992185, -23.200768526367199],
            [-44.237167916992185, -23.199868526367197],
            [-44.233967916992185, -23.199868526367197],
            [-44.230067916992184, -23.198168526367198],
            [-44.225867916992186, -23.196268526367199],
            [-44.222067916992188, -23.196268526367199],
            [-44.218667916992182, -23.194868526367195],
            [-44.215467916992182, -23.194568526367195],
            [-44.212567916992185, -23.194168526367196],
            [-44.208967916992187, -23.192968526367196],
            [-44.205767916992187, -23.194068526367197],
            [-44.202167916992188, -23.195968526367196],
            [-44.198567916992182, -23.196368526367195],
            [-44.197167916992186, -23.199268526367199],
            [-44.196367916992187, -23.202368526367195],
            [-44.193067916992185, -23.200668526367195],
            [-44.190267916992184, -23.197768526367199],
            [-44.186967916992188, -23.197368526367196],
            [-44.184167916992187, -23.196068526367196],
            [-44.182267916992188, -23.192768526367196],
            [-44.185767916992184, -23.190768526367197],
            [-44.188767916992184, -23.189868526367196],
            [-44.189867916992185, -23.186268526367197],
            [-44.187967916992186, -23.182568526367199],
            [-44.183667916992185, -23.180068526367194],
            [-44.179367916992184, -23.179968526367198],
            [-44.176167916992185, -23.180768526367196],
            [-44.172467916992183, -23.182468526367195],
            [-44.168367916992182, -23.182668526367195],
            [-44.167867916992186, -23.179768526367198],
            [-44.165067916992186, -23.177568526367196],
            [-44.163667916992182, -23.174968526367195],
            [-44.159567916992188, -23.173068526367196],
            [-44.155367916992184, -23.171068526367197],
            [-44.151967916992184, -23.170568526367198],
            [-44.147967916992187, -23.168968526367195],
            [-44.143267916992187, -23.168168526367197],
            [-44.139667916992188, -23.167468526367195],
            [-44.134967916992188, -23.167868526367194],
            [-44.131267916992186, -23.169268526367198],
            [-44.127667916992188, -23.171368526367196],
            [-44.124067916992182, -23.175568526367197],
            [-44.124367916992185, -23.180168526367197],
            [-44.127667916992188, -23.182568526367199],
            [-44.130167916992185, -23.186168526367197],
            [-44.125467916992186, -23.185468526367195],
            [-44.121267916992188, -23.185268526367196],
            [-44.116167916992183, -23.185168526367196],
            [-44.112967916992183, -23.182568526367199],
            [-44.109767916992183, -23.179668526367195],
            [-44.106367916992184, -23.177968526367195],
            [-44.101567916992188, -23.176668526367195],
            [-44.095567916992188, -23.174468526367196],
            [-44.093067916992183, -23.169968526367196],
            [-44.092267916992185, -23.166368526367194],
            [-44.096267916992183, -23.164868526367197],
            [-44.099167916992187, -23.164168526367199],
            [-44.103467916992187, -23.164568526367198],
            [-44.107567916992188, -23.163168526367194],
            [-44.111067916992184, -23.160268526367197],
            [-44.113667916992185, -23.158068526367195],
            [-44.116067916992186, -23.154768526367196],
            [-44.115067916992189, -23.151568526367196],
            [-44.115067916992189, -23.148668526367196],
            [-44.118267916992188, -23.150668526367198],
            [-44.120767916992186, -23.152868526367197],
            [-44.124167916992185, -23.154068526367197],
            [-44.127167916992185, -23.155068526367195],
            [-44.130267916992182, -23.155368526367198],
            [-44.134667916992186, -23.157868526367196],
            [-44.139367916992185, -23.158368526367198],
            [-44.139267916992182, -23.153968526367194],
            [-44.137467916992186, -23.150668526367198],
            [-44.136667916992188, -23.147368526367195],
            [-44.139867916992188, -23.143268526367194],
            [-44.140067916992187, -23.139668526367196],
            [-44.138167916992188, -23.137468526367197],
            [-44.134867916992185, -23.134268526367194],
            [-44.132067916992185, -23.130268526367196],
            [-44.129367916992187, -23.127168526367196],
            [-44.125967916992188, -23.124168526367196],
            [-44.126967916992186, -23.119768526367196],
            [-44.130267916992182, -23.120268526367198],
            [-44.134167916992183, -23.121568526367199],
            [-44.138767916992187, -23.121668526367195],
            [-44.142467916992182, -23.122668526367196],
            [-44.146767916992182, -23.124468526367195],
            [-44.147267916992185, -23.127968526367198],
            [-44.149467916992187, -23.130268526367196],
            [-44.151167916992186, -23.132768526367194],
            [-44.153467916992184, -23.134868526367196],
            [-44.156967916992187, -23.134168526367198],
            [-44.155967916992182, -23.137468526367197],
            [-44.158367916992184, -23.140168526367198],
            [-44.161767916992183, -23.141468526367198],
            [-44.164967916992182, -23.141568526367195],
            [-44.167867916992186, -23.139568526367196],
            [-44.169967916992185, -23.137068526367194],
            [-44.169767916992186, -23.132668526367198],
            [-44.172167916992187, -23.128468526367197],
            [-44.171467916992185, -23.124768526367198],
            [-44.169167916992187, -23.120868526367197],
            [-44.171067916992186, -23.117968526367196],
            [-44.175067916992184, -23.117168526367195],
            [-44.179367916992184, -23.118068526367196],
            [-44.183867916992185, -23.119168526367197],
            [-44.186867916992185, -23.121068526367196],
            [-44.190467916992183, -23.121368526367196],
            [-44.194067916992182, -23.119968526367195],
            [-44.197867916992188, -23.121068526367196],
            [-44.202167916992188, -23.118368526367199],
            [-44.203967916992184, -23.114968526367196],
            [-44.205467916992184, -23.111368526367194],
            [-44.207567916992183, -23.108168526367194],
            [-44.209567916992185, -23.110868526367199],
            [-44.214367916992188, -23.110368526367196],
            [-44.217567916992188, -23.108268526367198],
            [-44.216967916992182, -23.105268526367198],
            [-44.212867916992188, -23.103868526367194],
            [-44.208967916992187, -23.103668526367194],
            [-44.204567916992183, -23.104168526367197],
            [-44.200367916992185, -23.106968526367197],
            [-44.195967916992188, -23.106368526367199],
            [-44.193467916992184, -23.103168526367199],
            [-44.195267916992186, -23.099968526367196],
            [-44.198867916992185, -23.098068526367197],
            [-44.202367916992188, -23.097368526367198],
            [-44.206267916992182, -23.098068526367197],
            [-44.210467916992187, -23.098368526367196],
            [-44.210067916992188, -23.095268526367196],
            [-44.210667916992186, -23.092268526367196],
            [-44.214867916992183, -23.090868526367196],
            [-44.217667916992184, -23.089468526367199],
            [-44.220867916992184, -23.089768526367195],
            [-44.224467916992182, -23.089268526367196],
            [-44.227467916992182, -23.089768526367195],
            [-44.230667916992182, -23.090168526367197],
            [-44.233867916992182, -23.090168526367197],
            [-44.234667916992187, -23.086968526367194],
            [-44.236467916992183, -23.083968526367194]
        ]
        ],
        [
        [
            [-44.236467916992183, -23.083968526367194],
            [-44.232267916992186, -23.080368526367195],
            [-44.229967916992187, -23.076768526367196],
            [-44.232767916992188, -23.076068526367195],
            [-44.236067916992184, -23.076868526367196],
            [-44.238267916992186, -23.080368526367195],
            [-44.236467916992183, -23.083968526367194]
        ]
        ],
        [
        [
            [-44.639867916992188, -23.073468526367197],
            [-44.637767916992182, -23.070668526367196],
            [-44.641067916992185, -23.068868526367197],
            [-44.644367916992188, -23.071368526367195],
            [-44.643167916992184, -23.074368526367195],
            [-44.639867916992188, -23.073468526367197]
        ]
        ],
        [
        [
            [-43.192067916992187, -23.072468526367196],
            [-43.193267916992184, -23.067968526367196],
            [-43.197167916992186, -23.068468526367198],
            [-43.198467916992186, -23.071368526367195],
            [-43.195567916992182, -23.073168526367198],
            [-43.192067916992187, -23.072468526367196]
        ]
        ],
        [
        [
            [-43.508867916992187, -23.064868526367196],
            [-43.512067916992187, -23.062268526367198],
            [-43.512767916992182, -23.065468526367198],
            [-43.508867916992187, -23.064868526367196]
        ]
        ],
        [
        [
            [-43.144867916992183, -23.062668526367197],
            [-43.148667916992189, -23.060468526367195],
            [-43.147367916992188, -23.064568526367196],
            [-43.143267916992187, -23.065268526367198],
            [-43.144867916992183, -23.062668526367197]
        ]
        ],
        [
        [
            [-44.594067916992188, -23.059068526367199],
            [-44.597867916992186, -23.059368526367194],
            [-44.599567916992186, -23.062668526367197],
            [-44.595667916992184, -23.063068526367196],
            [-44.594067916992188, -23.059068526367199]
        ]
        ],
        [
        [
            [-44.313767916992184, -23.060568526367199],
            [-44.310467916992188, -23.059468526367198],
            [-44.314167916992183, -23.057668526367195],
            [-44.317367916992183, -23.061068526367194],
            [-44.313767916992184, -23.060568526367199]
        ]
        ],
        [
        [
            [-44.557167916992185, -23.061868526367196],
            [-44.556867916992182, -23.057268526367196],
            [-44.560267916992188, -23.059968526367197],
            [-44.562367916992187, -23.064368526367197],
            [-44.557167916992185, -23.061868526367196]
        ]
        ],
        [
        [
            [-44.487867916992187, -23.044268526367198],
            [-44.492967916992185, -23.046168526367197],
            [-44.497667916992185, -23.047568526367197],
            [-44.500467916992186, -23.049268526367197],
            [-44.502667916992188, -23.053068526367198],
            [-44.499467916992188, -23.054768526367198],
            [-44.495767916992186, -23.053268526367198],
            [-44.492567916992186, -23.051668526367195],
            [-44.490467916992188, -23.048668526367194],
            [-44.487867916992187, -23.044268526367198]
        ]
        ],
        [
        [
            [-44.487867916992187, -23.044268526367198],
            [-44.484367916992184, -23.043868526367199],
            [-44.483367916992187, -23.040668526367199],
            [-44.487867916992187, -23.044268526367198]
        ]
        ],
        [
        [
            [-44.124367916992185, -23.038868526367196],
            [-44.129067916992184, -23.036468526367194],
            [-44.130467916992188, -23.039868526367197],
            [-44.127667916992188, -23.041968526367196],
            [-44.124367916992185, -23.038868526367196]
        ]
        ],
        [
        [
            [-43.469867916992186, -23.034168526367196],
            [-43.470867916992184, -23.037068526367197],
            [-43.467867916992184, -23.036768526367197],
            [-43.469867916992186, -23.034168526367196]
        ]
        ],
        [
        [
            [-43.831467916992182, -23.069368526367196],
            [-43.827067916992185, -23.068468526367198],
            [-43.820267916992186, -23.067668526367196],
            [-43.815967916992186, -23.067168526367198],
            [-43.809067916992184, -23.066368526367196],
            [-43.804767916992184, -23.065568526367194],
            [-43.800867916992182, -23.064968526367196],
            [-43.797767916992186, -23.064568526367196],
            [-43.792067916992188, -23.062468526367198],
            [-43.795767916992183, -23.061568526367196],
            [-43.800767916992186, -23.061368526367197],
            [-43.806167916992187, -23.061268526367197],
            [-43.811967916992188, -23.060568526367199],
            [-43.817467916992186, -23.059968526367197],
            [-43.823467916992186, -23.058868526367196],
            [-43.828267916992182, -23.058068526367194],
            [-43.835067916992188, -23.056168526367195],
            [-43.842067916992185, -23.054368526367199],
            [-43.848667916992184, -23.051968526367197],
            [-43.854667916992184, -23.049968526367195],
            [-43.860267916992186, -23.047468526367197],
            [-43.864967916992185, -23.044968526367196],
            [-43.870767916992186, -23.042468526367195],
            [-43.875967916992188, -23.040268526367196],
            [-43.880267916992182, -23.037568526367195],
            [-43.884367916992183, -23.036168526367199],
            [-43.888967916992186, -23.033768526367197],
            [-43.893967916992182, -23.032268526367197],
            [-43.891267916992184, -23.035868526367196],
            [-43.888267916992184, -23.038168526367194],
            [-43.884567916992182, -23.040568526367196],
            [-43.880967916992184, -23.042068526367196],
            [-43.879667916992183, -23.044968526367196],
            [-43.877667916992188, -23.048868526367194],
            [-43.877067916992182, -23.052468526367196],
            [-43.876667916992183, -23.057568526367199],
            [-43.877967916992183, -23.061668526367196],
            [-43.881267916992186, -23.066068526367197],
            [-43.884867916992185, -23.068268526367198],
            [-43.888967916992186, -23.068768526367194],
            [-43.892367916992185, -23.069368526367196],
            [-43.895967916992184, -23.069268526367196],
            [-43.899467916992187, -23.068768526367194],
            [-43.902667916992186, -23.067368526367197],
            [-43.905667916992186, -23.065468526367198],
            [-43.908767916992183, -23.062968526367197],
            [-43.912867916992184, -23.060468526367195],
            [-43.916067916992183, -23.059168526367195],
            [-43.918967916992187, -23.058268526367197],
            [-43.922467916992183, -23.058068526367194],
            [-43.928967916992185, -23.057968526367198],
            [-43.933367916992182, -23.056568526367194],
            [-43.937367916992187, -23.053668526367197],
            [-43.940167916992188, -23.052168526367197],
            [-43.944067916992182, -23.051468526367195],
            [-43.947367916992185, -23.050368526367194],
            [-43.949867916992183, -23.047868526367196],
            [-43.951367916992183, -23.044968526367196],
            [-43.953167916992186, -23.041768526367196],
            [-43.957367916992183, -23.042068526367196],
            [-43.962567916992185, -23.042768526367198],
            [-43.966467916992187, -23.043868526367199],
            [-43.972567916992183, -23.045068526367196],
            [-43.975667916992187, -23.047468526367197],
            [-43.977767916992185, -23.049468526367196],
            [-43.979267916992185, -23.052568526367196],
            [-43.981467916992187, -23.057168526367196],
            [-43.984367916992184, -23.059468526367198],
            [-43.987767916992183, -23.058868526367196],
            [-43.990467916992188, -23.057568526367199],
            [-43.991367916992182, -23.060468526367195],
            [-43.991167916992183, -23.064068526367194],
            [-43.994367916992182, -23.067368526367197],
            [-43.997767916992188, -23.070168526367198],
            [-44.001567916992187, -23.073268526367194],
            [-44.006267916992186, -23.072868526367195],
            [-44.009267916992187, -23.075968526367198],
            [-44.011067916992182, -23.079068526367195],
            [-44.008467916992188, -23.082168526367198],
            [-44.008767916992184, -23.086168526367196],
            [-44.007767916992186, -23.089068526367196],
            [-44.007767916992186, -23.092568526367195],
            [-44.004867916992183, -23.095668526367199],
            [-43.999667916992188, -23.096668526367196],
            [-43.995867916992182, -23.100268526367195],
            [-43.993067916992182, -23.101468526367196],
            [-43.989967916992185, -23.102068526367198],
            [-43.984767916992183, -23.100668526367194],
            [-43.979767916992188, -23.098368526367196],
            [-43.976467916992185, -23.095668526367199],
            [-43.973767916992188, -23.094468526367194],
            [-43.970167916992182, -23.092668526367198],
            [-43.966967916992182, -23.090068526367197],
            [-43.963167916992184, -23.088968526367196],
            [-43.959867916992188, -23.088268526367195],
            [-43.955467916992184, -23.087268526367197],
            [-43.950667916992188, -23.086468526367199],
            [-43.947367916992185, -23.085868526367197],
            [-43.943167916992188, -23.084868526367195],
            [-43.938567916992184, -23.084068526367197],
            [-43.933567916992182, -23.082968526367196],
            [-43.929067916992182, -23.082368526367198],
            [-43.924967916992188, -23.081768526367195],
            [-43.921967916992187, -23.081068526367197],
            [-43.918067916992186, -23.080468526367198],
            [-43.914167916992184, -23.080168526367196],
            [-43.911167916992184, -23.079668526367197],
            [-43.907367916992186, -23.079068526367195],
            [-43.902567916992183, -23.078168526367197],
            [-43.898267916992182, -23.077368526367195],
            [-43.894867916992183, -23.076768526367196],
            [-43.890367916992183, -23.075968526367198],
            [-43.886767916992184, -23.075368526367196],
            [-43.883067916992182, -23.074868526367197],
            [-43.879067916992184, -23.074568526367194],
            [-43.875567916992182, -23.074068526367196],
            [-43.870467916992183, -23.073468526367197],
            [-43.865467916992188, -23.073268526367194],
            [-43.862167916992185, -23.073168526367198],
            [-43.858867916992182, -23.072668526367195],
            [-43.854667916992184, -23.072368526367196],
            [-43.850567916992183, -23.071868526367197],
            [-43.846467916992182, -23.071668526367198],
            [-43.839067916992185, -23.070268526367194],
            [-43.835667916992186, -23.069968526367198],
            [-43.831467916992182, -23.069368526367196]
        ]
        ],
        [
        [
            [-44.370167916992187, -23.039568526367198],
            [-44.372567916992182, -23.035768526367196],
            [-44.374467916992188, -23.031668526367199],
            [-44.378267916992186, -23.031968526367194],
            [-44.381367916992183, -23.035868526367196],
            [-44.384867916992185, -23.038068526367198],
            [-44.387967916992189, -23.040068526367197],
            [-44.392067916992183, -23.040568526367196],
            [-44.390367916992183, -23.045268526367195],
            [-44.386467916992189, -23.045768526367198],
            [-44.382667916992183, -23.045368526367199],
            [-44.378767916992182, -23.045368526367199],
            [-44.375767916992189, -23.044468526367197],
            [-44.371367916992185, -23.044468526367197],
            [-44.368367916992185, -23.045868526367194],
            [-44.364967916992185, -23.045568526367198],
            [-44.361767916992186, -23.046468526367196],
            [-44.359367916992184, -23.050068526367198],
            [-44.357767916992188, -23.053668526367197],
            [-44.355667916992182, -23.056168526367195],
            [-44.357167916992182, -23.059368526367194],
            [-44.353967916992183, -23.063168526367196],
            [-44.350567916992183, -23.064068526367194],
            [-44.347267916992188, -23.065468526367198],
            [-44.346167916992187, -23.068168526367195],
            [-44.345167916992182, -23.063568526367199],
            [-44.350367916992184, -23.060168526367196],
            [-44.350967916992182, -23.055768526367196],
            [-44.350667916992187, -23.052268526367197],
            [-44.348067916992186, -23.050368526367194],
            [-44.349467916992182, -23.046768526367195],
            [-44.348667916992184, -23.042768526367198],
            [-44.348367916992188, -23.038968526367196],
            [-44.353067916992188, -23.037468526367196],
            [-44.357467916992185, -23.035968526367196],
            [-44.359667916992187, -23.039168526367199],
            [-44.364967916992185, -23.040568526367196],
            [-44.370167916992187, -23.039568526367198]
        ]
        ],
        [
        [
            [-44.401167916992186, -23.031268526367196],
            [-44.399767916992182, -23.027668526367197],
            [-44.402667916992186, -23.027268526367195],
            [-44.404067916992183, -23.031968526367194],
            [-44.401167916992186, -23.031268526367196]
        ]
        ],
        [
        [
            [-42.919667916992182, -23.016568526367195],
            [-42.918867916992184, -23.013168526367195],
            [-42.921767916992188, -23.014268526367196],
            [-42.922767916992186, -23.017368526367196],
            [-42.919667916992182, -23.016568526367195]
        ]
        ],
        [
        [
            [-43.949567916992187, -23.006568526367197],
            [-43.952967916992186, -23.005768526367198],
            [-43.951767916992182, -23.009068526367194],
            [-43.949567916992187, -23.006568526367197]
        ]
        ],
        [
        [
            [-44.029467916992182, -23.004068526367199],
            [-44.031167916992182, -22.999968526367198],
            [-44.034267916992185, -22.998068526367199],
            [-44.038867916992189, -22.999068526367196],
            [-44.043367916992182, -23.000468526367197],
            [-44.047467916992183, -23.001568526367194],
            [-44.051567916992184, -23.002968526367198],
            [-44.052867916992184, -23.006368526367197],
            [-44.050567916992186, -23.008568526367196],
            [-44.047867916992182, -23.010268526367199],
            [-44.045267916992188, -23.008468526367196],
            [-44.042567916992184, -23.006668526367196],
            [-44.041467916992183, -23.009668526367197],
            [-44.037567916992188, -23.010668526367198],
            [-44.033767916992183, -23.009168526367198],
            [-44.030867916992186, -23.007368526367195],
            [-44.029467916992182, -23.004068526367199]
        ]
        ],
        [
        [
            [-43.920767916992183, -22.989068526367198],
            [-43.922867916992182, -22.991168526367197],
            [-43.926367916992184, -22.989668526367197],
            [-43.929067916992182, -22.990768526367198],
            [-43.931067916992184, -22.993868526367194],
            [-43.933267916992186, -22.997368526367197],
            [-43.936867916992185, -22.999368526367196],
            [-43.937667916992183, -23.003768526367196],
            [-43.937467916992183, -23.007068526367195],
            [-43.934367916992187, -23.009668526367197],
            [-43.934967916992186, -23.013968526367197],
            [-43.930867916992185, -23.010968526367197],
            [-43.929767916992184, -23.007068526367195],
            [-43.927267916992186, -23.004468526367198],
            [-43.925767916992186, -23.001568526367194],
            [-43.923667916992187, -22.998868526367197],
            [-43.920567916992184, -22.996868526367194],
            [-43.917767916992183, -22.994068526367194],
            [-43.917567916992184, -22.989368526367194],
            [-43.920767916992183, -22.989068526367198]
        ]
        ],
        [
        [
            [-44.413167916992187, -22.988968526367195],
            [-44.411967916992182, -22.993268526367196],
            [-44.410067916992183, -22.990068526367196],
            [-44.413167916992187, -22.988968526367195]
        ]
        ],
        [
        [
            [-43.081867916992188, -22.986168526367194],
            [-43.085467916992187, -22.986368526367194],
            [-43.087967916992184, -22.988268526367197],
            [-43.084267916992182, -22.989068526367198],
            [-43.081867916992188, -22.986168526367194]
        ]
        ],
        [
        [
            [-43.920767916992183, -22.989068526367198],
            [-43.919267916992183, -22.985768526367195],
            [-43.922767916992186, -22.986468526367197],
            [-43.920767916992183, -22.989068526367198]
        ]
        ],
        [
        [
            [-44.413167916992187, -22.988968526367195],
            [-44.412867916992184, -22.984368526367199],
            [-44.416367916992186, -22.987568526367198],
            [-44.413167916992187, -22.988968526367195]
        ]
        ],
        [
        [
            [-43.908467916992187, -22.980568526367197],
            [-43.911967916992182, -22.979668526367199],
            [-43.914867916992186, -22.981068526367196],
            [-43.912267916992185, -22.983568526367197],
            [-43.908467916992187, -22.980568526367197]
        ]
        ],
        [
        [
            [-41.982867916992184, -23.000768526367196],
            [-41.980367916992186, -22.999068526367196],
            [-41.978167916992184, -22.996668526367195],
            [-41.976967916992187, -22.993368526367199],
            [-41.974467916992182, -22.990768526367198],
            [-41.975567916992183, -22.987168526367196],
            [-41.977267916992183, -22.983968526367196],
            [-41.977467916992182, -22.979668526367199],
            [-41.981767916992183, -22.978868526367194],
            [-41.984667916992187, -22.980668526367197],
            [-41.987867916992187, -22.982568526367196],
            [-41.990567916992184, -22.984668526367194],
            [-41.993367916992185, -22.987168526367196],
            [-41.997467916992186, -22.991168526367197],
            [-42.001567916992187, -22.991468526367196],
            [-42.003067916992187, -22.994468526367196],
            [-42.003067916992187, -22.997768526367196],
            [-42.003867916992185, -23.000568526367196],
            [-42.006667916992185, -23.002668526367195],
            [-42.009267916992187, -23.001268526367198],
            [-42.012367916992183, -23.000168526367197],
            [-42.011867916992188, -23.002968526367198],
            [-42.009667916992186, -23.005268526367196],
            [-42.006867916992185, -23.007968526367197],
            [-42.004867916992183, -23.012468526367197],
            [-41.999667916992188, -23.014268526367196],
            [-41.997967916992188, -23.010168526367195],
            [-41.997167916992183, -23.007068526367195],
            [-41.993367916992185, -23.004868526367197],
            [-41.989067916992184, -23.003768526367196],
            [-41.985267916992186, -23.003568526367197],
            [-41.982867916992184, -23.000768526367196]
        ]
        ],
        [
        [
            [-44.400367916992188, -22.982868526367199],
            [-44.397067916992185, -22.980068526367198],
            [-44.401467916992182, -22.977068526367198],
            [-44.404767916992185, -22.977768526367196],
            [-44.406567916992188, -22.981368526367199],
            [-44.404567916992185, -22.984468526367195],
            [-44.400367916992188, -22.982868526367199]
        ]
        ],
        [
        [
            [-44.418967916992187, -22.971968526367196],
            [-44.414767916992183, -22.968768526367196],
            [-44.418067916992186, -22.968068526367198],
            [-44.421667916992185, -22.968768526367196],
            [-44.424367916992182, -22.969768526367197],
            [-44.423867916992187, -22.973168526367196],
            [-44.418967916992187, -22.971968526367196]
        ]
        ],
        [
        [
            [-41.987867916992187, -22.967368526367196],
            [-41.986467916992183, -22.964068526367196],
            [-41.990867916992187, -22.963168526367198],
            [-41.993367916992185, -22.964768526367195],
            [-41.993367916992185, -22.968068526367198],
            [-41.990567916992184, -22.968768526367196],
            [-41.987867916992187, -22.967368526367196]
        ]
        ],
        [
        [
            [-43.857867916992184, -22.954268526367194],
            [-43.857467916992185, -22.950668526367195],
            [-43.861067916992184, -22.947468526367196],
            [-43.863567916992189, -22.949268526367199],
            [-43.861667916992182, -22.951368526367197],
            [-43.862167916992185, -22.955368526367195],
            [-43.857867916992184, -22.954268526367194]
        ]
        ],
        [
        [
            [-44.338167916992184, -22.940868526367197],
            [-44.336567916992188, -22.936968526367195],
            [-44.340167916992186, -22.935268526367196],
            [-44.343767916992185, -22.939368526367197],
            [-44.341167916992184, -22.942668526367196],
            [-44.338167916992184, -22.940868526367197]
        ]
        ],
        [
        [
            [-43.870767916992186, -22.924368526367196],
            [-43.874867916992187, -22.924768526367195],
            [-43.878867916992185, -22.925368526367194],
            [-43.881867916992185, -22.924668526367196],
            [-43.883867916992187, -22.926968526367197],
            [-43.887767916992182, -22.929668526367195],
            [-43.892167916992186, -22.928568526367194],
            [-43.893567916992183, -22.932168526367196],
            [-43.896867916992186, -22.932968526367198],
            [-43.900467916992184, -22.930768526367196],
            [-43.904467916992182, -22.931568526367194],
            [-43.907367916992186, -22.934468526367198],
            [-43.911967916992182, -22.936168526367197],
            [-43.911467916992187, -22.940768526367197],
            [-43.910967916992185, -22.943868526367197],
            [-43.907267916992183, -22.945968526367196],
            [-43.905367916992184, -22.948968526367196],
            [-43.907267916992183, -22.952068526367196],
            [-43.908967916992182, -22.955368526367195],
            [-43.906667916992184, -22.958168526367196],
            [-43.903367916992188, -22.958968526367194],
            [-43.899767916992182, -22.958568526367195],
            [-43.895767916992185, -22.957868526367196],
            [-43.892867916992188, -22.956168526367197],
            [-43.891567916992187, -22.953568526367196],
            [-43.888867916992183, -22.951468526367197],
            [-43.886367916992185, -22.948768526367196],
            [-43.882367916992187, -22.947668526367195],
            [-43.879067916992184, -22.949868526367197],
            [-43.875567916992182, -22.947668526367195],
            [-43.876567916992187, -22.943468526367198],
            [-43.877667916992188, -22.939868526367196],
            [-43.876267916992184, -22.937368526367194],
            [-43.874367916992185, -22.935168526367196],
            [-43.872367916992182, -22.932768526367198],
            [-43.869667916992185, -22.930168526367197],
            [-43.868267916992188, -22.926868526367194],
            [-43.870767916992186, -22.924368526367196]
        ]
        ],
        [
        [
            [-43.176367916992184, -22.896068526367195],
            [-43.172267916992183, -22.897968526367194],
            [-43.167767916992183, -22.896468526367194],
            [-43.171467916992185, -22.894068526367196],
            [-43.174667916992185, -22.893168526367198],
            [-43.176367916992184, -22.896068526367195]
        ]
        ],
        [
        [
            [-41.976967916992187, -22.899868526367197],
            [-41.979567916992188, -22.897168526367196],
            [-41.980267916992183, -22.891868526367197],
            [-41.984267916992188, -22.891468526367198],
            [-41.987467916992188, -22.892168526367197],
            [-41.985867916992184, -22.894868526367198],
            [-41.982767916992188, -22.898168526367197],
            [-41.980367916992186, -22.901168526367197],
            [-41.976967916992187, -22.899868526367197]
        ]
        ],
        [
        [
            [-43.131767916992182, -22.872768526367196],
            [-43.133467916992188, -22.869368526367197],
            [-43.137367916992183, -22.870468526367198],
            [-43.137067916992187, -22.874968526367198],
            [-43.131767916992182, -22.872768526367196]
        ]
        ],
        [
        [
            [-41.940267916992184, -22.872968526367195],
            [-41.938767916992184, -22.869068526367194],
            [-41.936867916992185, -22.863868526367199],
            [-41.941667916992188, -22.863568526367196],
            [-41.945467916992186, -22.864368526367194],
            [-41.947467916992188, -22.867268526367198],
            [-41.948167916992183, -22.870468526367198],
            [-41.946567916992187, -22.874368526367196],
            [-41.942667916992185, -22.874768526367198],
            [-41.940267916992184, -22.872968526367195]
        ]
        ],
        [
        [
            [-43.117967916992185, -22.858868526367196],
            [-43.123267916992184, -22.859368526367199],
            [-43.127367916992185, -22.861968526367196],
            [-43.122967916992188, -22.863068526367197],
            [-43.119767916992188, -22.863568526367196],
            [-43.117967916992185, -22.858868526367196]
        ]
        ],
        [
        [
            [-41.903367916992188, -22.856368526367199],
            [-41.904867916992188, -22.852268526367197],
            [-41.906767916992187, -22.855568526367197],
            [-41.908767916992183, -22.858168526367194],
            [-41.905067916992188, -22.859968526367197],
            [-41.903367916992188, -22.856368526367199]
        ]
        ],
        [
        [
            [-43.224567916992186, -22.841168526367198],
            [-43.223467916992185, -22.845268526367196],
            [-43.221767916992185, -22.847668526367194],
            [-43.220567916992188, -22.844468526367194],
            [-43.224567916992186, -22.841168526367198]
        ]
        ],
        [
        [
            [-43.105067916992184, -22.835468526367194],
            [-43.106667916992187, -22.832968526367196],
            [-43.109967916992183, -22.830768526367194],
            [-43.113367916992182, -22.832668526367197],
            [-43.111067916992184, -22.837568526367196],
            [-43.107267916992186, -22.839768526367195],
            [-43.105067916992184, -22.835468526367194]
        ]
        ],
        [
        [
            [-43.096967916992185, -22.826768526367196],
            [-43.096967916992185, -22.823168526367198],
            [-43.100567916992183, -22.823968526367196],
            [-43.102267916992183, -22.828468526367196],
            [-43.096967916992185, -22.826768526367196]
        ]
        ],
        [
        [
            [-43.093467916992182, -22.823168526367198],
            [-43.095267916992185, -22.819868526367195],
            [-43.096967916992185, -22.823168526367198],
            [-43.093467916992182, -22.823168526367198]
        ]
        ],
        [
        [
            [-43.162067916992186, -22.811568526367196],
            [-43.164767916992183, -22.812468526367198],
            [-43.162067916992186, -22.814468526367197],
            [-43.162067916992186, -22.811568526367196]
        ]
        ],
        [
        [
            [-43.173867916992187, -22.777268526367195],
            [-43.176667916992187, -22.779568526367196],
            [-43.178567916992186, -22.782668526367196],
            [-43.183367916992182, -22.783068526367195],
            [-43.188767916992184, -22.784468526367196],
            [-43.190767916992186, -22.787368526367196],
            [-43.188367916992185, -22.788868526367196],
            [-43.189167916992183, -22.791968526367196],
            [-43.189167916992183, -22.794968526367196],
            [-43.192767916992182, -22.795268526367195],
            [-43.196367916992187, -22.793968526367195],
            [-43.199867916992183, -22.793868526367195],
            [-43.202867916992183, -22.794768526367196],
            [-43.206467916992182, -22.794968526367196],
            [-43.209367916992186, -22.792768526367198],
            [-43.212367916992186, -22.789868526367197],
            [-43.215467916992182, -22.787568526367195],
            [-43.218967916992185, -22.785268526367197],
            [-43.222267916992188, -22.784268526367196],
            [-43.225867916992186, -22.783968526367197],
            [-43.229167916992182, -22.784168526367196],
            [-43.228967916992183, -22.787368526367196],
            [-43.232267916992186, -22.789768526367197],
            [-43.235267916992186, -22.791368526367197],
            [-43.239667916992182, -22.791368526367197],
            [-43.243867916992187, -22.791968526367196],
            [-43.246967916992183, -22.793068526367197],
            [-43.250167916992183, -22.795868526367197],
            [-43.254067916992184, -22.797468526367197],
            [-43.256867916992185, -22.798068526367196],
            [-43.259067916992187, -22.800468526367197],
            [-43.260967916992186, -22.803568526367197],
            [-43.262967916992189, -22.806968526367196],
            [-43.265367916992183, -22.811368526367197],
            [-43.263467916992184, -22.814868526367196],
            [-43.261267916992182, -22.816968526367198],
            [-43.259167916992183, -22.819068526367197],
            [-43.256567916992182, -22.820568526367197],
            [-43.253067916992187, -22.822368526367196],
            [-43.250867916992185, -22.825168526367197],
            [-43.250567916992182, -22.828268526367197],
            [-43.247967916992188, -22.830368526367195],
            [-43.244667916992185, -22.832268526367194],
            [-43.240067916992189, -22.833668526367198],
            [-43.237167916992185, -22.831868526367195],
            [-43.235367916992182, -22.829568526367197],
            [-43.233567916992186, -22.827168526367196],
            [-43.230567916992186, -22.823868526367196],
            [-43.226467916992185, -22.820568526367197],
            [-43.222267916992188, -22.819568526367195],
            [-43.218367916992186, -22.818168526367195],
            [-43.215167916992186, -22.817368526367197],
            [-43.212267916992182, -22.816268526367196],
            [-43.209267916992182, -22.817368526367197],
            [-43.204667916992186, -22.819568526367195],
            [-43.200667916992188, -22.819568526367195],
            [-43.196767916992187, -22.818768526367197],
            [-43.193067916992185, -22.819568526367195],
            [-43.190167916992188, -22.821368526367195],
            [-43.187967916992186, -22.827868526367197],
            [-43.183867916992185, -22.831468526367196],
            [-43.179467916992188, -22.830168526367196],
            [-43.176167916992185, -22.826268526367194],
            [-43.172567916992186, -22.826068526367195],
            [-43.173667916992187, -22.829368526367197],
            [-43.170667916992187, -22.831068526367197],
            [-43.168667916992185, -22.828268526367197],
            [-43.167867916992186, -22.825468526367196],
            [-43.165567916992188, -22.821568526367198],
            [-43.170567916992184, -22.821268526367195],
            [-43.174667916992185, -22.818868526367197],
            [-43.176167916992185, -22.815468526367198],
            [-43.176067916992189, -22.811868526367196],
            [-43.177467916992185, -22.808068526367197],
            [-43.177767916992188, -22.804768526367198],
            [-43.176467916992188, -22.801368526367195],
            [-43.173267916992188, -22.798668526367198],
            [-43.171367916992182, -22.795368526367195],
            [-43.168367916992182, -22.792768526367198],
            [-43.165667916992184, -22.791068526367198],
            [-43.161967916992182, -22.789468526367195],
            [-43.157067916992183, -22.786668526367198],
            [-43.156167916992182, -22.782868526367196],
            [-43.156667916992184, -22.779568526367196],
            [-43.160567916992186, -22.777968526367196],
            [-43.163467916992182, -22.777568526367197],
            [-43.166367916992186, -22.777868526367197],
            [-43.169767916992186, -22.781468526367195],
            [-43.173867916992187, -22.777268526367195]
        ]
        ],
        [
        [
            [-41.821767916992187, -22.773168526367197],
            [-41.825367916992185, -22.771868526367196],
            [-41.825367916992185, -22.775368526367195],
            [-41.821767916992187, -22.773168526367197]
        ]
        ],
        [
        [
            [-43.154767916992185, -22.774268526367198],
            [-43.157367916992186, -22.772568526367195],
            [-43.160367916992186, -22.770768526367196],
            [-43.158867916992186, -22.775068526367196],
            [-43.154767916992185, -22.774268526367198]
        ]
        ],
        [
        [
            [-43.115767916992183, -22.756668526367196],
            [-43.118067916992182, -22.754468526367198],
            [-43.119067916992186, -22.757768526367197],
            [-43.115767916992183, -22.756668526367196]
        ]
        ],
        [
        [
            [-43.107467916992185, -22.760468526367195],
            [-43.107767916992188, -22.756868526367196],
            [-43.105667916992182, -22.754668526367198],
            [-43.102867916992182, -22.751868526367197],
            [-43.106067916992188, -22.749368526367196],
            [-43.108967916992185, -22.749368526367196],
            [-43.110767916992188, -22.752768526367195],
            [-43.110767916992188, -22.757068526367195],
            [-43.110767916992188, -22.760168526367195],
            [-43.110567916992188, -22.764968526367195],
            [-43.113267916992186, -22.767668526367196],
            [-43.110667916992185, -22.769268526367195],
            [-43.107267916992186, -22.768668526367197],
            [-43.103667916992187, -22.765768526367197],
            [-43.101967916992187, -22.762968526367196],
            [-43.105067916992184, -22.763168526367195],
            [-43.107467916992185, -22.760468526367195]
        ]
        ],
        [
        [
            [-41.700667916992188, -22.417868526367197],
            [-41.702367916992188, -22.413768526367196],
            [-41.699367916992188, -22.411368526367195],
            [-41.700167916992186, -22.407568526367196],
            [-41.702867916992183, -22.405168526367195],
            [-41.705767916992187, -22.406968526367198],
            [-41.708967916992187, -22.409868526367195],
            [-41.710367916992183, -22.414168526367195],
            [-41.708267916992185, -22.417868526367197],
            [-41.705167916992188, -22.418968526367195],
            [-41.702567916992187, -22.420568526367198],
            [-41.700667916992188, -22.417868526367197]
        ]
        ],
        [
        [
            [-41.691567916992184, -22.403368526367196],
            [-41.690767916992186, -22.400368526367195],
            [-41.693067916992185, -22.397968526367197],
            [-41.695767916992182, -22.399768526367197],
            [-41.695267916992186, -22.404168526367197],
            [-41.691567916992184, -22.403368526367196]
        ]
        ],
        [
        [
            [-57.456767916992185, -22.189968526367196],
            [-57.457967916992189, -22.193768526367197],
            [-57.453267916992189, -22.194868526367195],
            [-57.454667916992186, -22.192068526367198],
            [-57.456767916992185, -22.189968526367196]
        ]
        ],
        [
        [
            [-41.047567916992186, -21.588168526367195],
            [-41.048167916992185, -21.583268526367196],
            [-41.051967916992183, -21.580968526367197],
            [-41.050267916992183, -21.585468526367197],
            [-41.047567916992186, -21.588168526367195]
        ]
        ],
        [
        [
            [-29.299367916992182, -20.519868526367198],
            [-29.301067916992189, -20.517568526367196],
            [-29.304367916992184, -20.515468526367197],
            [-29.306867916992182, -20.511768526367195],
            [-29.309067916992184, -20.507968526367197],
            [-29.311967916992188, -20.507168526367195],
            [-29.315467916992183, -20.506268526367197],
            [-29.318867916992183, -20.503768526367196],
            [-29.321367916992187, -20.499768526367195],
            [-29.324567916992187, -20.497468526367197],
            [-29.327867916992183, -20.495768526367197],
            [-29.331167916992186, -20.493268526367196],
            [-29.334067916992183, -20.489368526367198],
            [-29.337567916992185, -20.487768526367198],
            [-29.340367916992186, -20.489968526367196],
            [-29.342567916992188, -20.491968526367195],
            [-29.343667916992182, -20.495868526367197],
            [-29.343767916992185, -20.499468526367195],
            [-29.343667916992182, -20.504068526367195],
            [-29.343767916992185, -20.509068526367198],
            [-29.341567916992183, -20.512368526367197],
            [-29.338467916992187, -20.515068526367195],
            [-29.333967916992187, -20.517568526367196],
            [-29.331067916992183, -20.521168526367195],
            [-29.330467916992184, -20.524468526367198],
            [-29.327167916992188, -20.522968526367197],
            [-29.323967916992189, -20.524768526367197],
            [-29.320267916992186, -20.522968526367197],
            [-29.316667916992188, -20.521868526367196],
            [-29.312767916992186, -20.520668526367196],
            [-29.308867916992185, -20.522068526367196],
            [-29.305467916992185, -20.525868526367198],
            [-29.301067916992189, -20.526868526367195],
            [-29.301467916992188, -20.522668526367195],
            [-29.299367916992182, -20.519868526367198]
        ]
        ],
        [
        [
            [-28.849167916992187, -20.474468526367197],
            [-28.852467916992182, -20.472268526367195],
            [-28.854167916992182, -20.474768526367196],
            [-28.851667916992184, -20.478168526367195],
            [-28.849167916992187, -20.474468526367197]
        ]
        ],
        [
        [
            [-40.277667916992186, -20.300068526367195],
            [-40.282367916992186, -20.299768526367195],
            [-40.285367916992186, -20.303068526367195],
            [-40.280967916992182, -20.303668526367197],
            [-40.277667916992186, -20.300068526367195]
        ]
        ],
        [
        [
            [-38.706867916992188, -17.964468526367195],
            [-38.711767916992187, -17.966168526367195],
            [-38.708667916992184, -17.968768526367196],
            [-38.706867916992188, -17.964468526367195]
        ]
        ],
        [
        [
            [-38.706867916992188, -17.964468526367195],
            [-38.703167916992186, -17.964768526367195],
            [-38.699667916992183, -17.962668526367196],
            [-38.702067916992185, -17.961168526367196],
            [-38.705967916992186, -17.961768526367198],
            [-38.706867916992188, -17.964468526367195]
        ]
        ],
        [
        [
            [-39.194967916992184, -17.755968526367198],
            [-39.191967916992184, -17.754068526367195],
            [-39.190467916992183, -17.750868526367196],
            [-39.194667916992188, -17.752368526367196],
            [-39.198267916992187, -17.754068526367195],
            [-39.200767916992184, -17.755968526367198],
            [-39.198267916992187, -17.757968526367197],
            [-39.194967916992184, -17.755968526367198]
        ]
        ],
        [
        [
            [-68.877467916992188, -11.019868526367198],
            [-68.875767916992189, -11.015668526367197],
            [-68.878767916992189, -11.012368526367197],
            [-68.880767916992184, -11.016068526367196],
            [-68.886067916992189, -11.016268526367195],
            [-68.882967916992186, -11.019368526367195],
            [-68.880267916992182, -11.020768526367196],
            [-68.877467916992188, -11.019868526367198]
        ]
        ],
        [
        [
            [-68.75736791699218, -11.009868526367196],
            [-68.761067916992189, -11.010368526367195],
            [-68.763267916992191, -11.012368526367197],
            [-68.763567916992187, -11.016068526367196],
            [-68.76066791699219, -11.017468526367196],
            [-68.758867916992187, -11.013568526367198],
            [-68.75736791699218, -11.009868526367196]
        ]
        ],
        [
        [
            [-68.78846791699219, -11.006868526367196],
            [-68.789967916992182, -11.009868526367196],
            [-68.787167916992189, -11.010968526367197],
            [-68.78846791699219, -11.006868526367196]
        ]
        ],
        [
        [
            [-34.967267916992185, -8.375968526367195],
            [-34.966167916992184, -8.379968526367197],
            [-34.967267916992185, -8.384268526367197],
            [-34.965367916992186, -8.387368526367197],
            [-34.961167916992189, -8.387868526367196],
            [-34.957267916992187, -8.384068526367198],
            [-34.956767916992185, -8.379968526367197],
            [-34.956867916992188, -8.376668526367197],
            [-34.959867916992188, -8.373868526367197],
            [-34.965667916992182, -8.371968526367198],
            [-34.967267916992185, -8.375968526367195]
        ]
        ],
        [
        [
            [-34.861467916992183, -6.981368526367195],
            [-34.866567916992182, -6.981968526367197],
            [-34.867267916992184, -6.985268526367197],
            [-34.867767916992186, -6.988368526367196],
            [-34.867267916992184, -6.992968526367196],
            [-34.866367916992182, -6.996368526367196],
            [-34.864767916992186, -7.002768526367195],
            [-34.862267916992188, -7.007768526367197],
            [-34.861067916992184, -7.010768526367197],
            [-34.859367916992184, -7.013868526367197],
            [-34.855267916992183, -7.011268526367196],
            [-34.852767916992185, -7.007668526367198],
            [-34.850667916992187, -7.004568526367198],
            [-34.849467916992182, -7.001268526367195],
            [-34.849167916992187, -6.997468526367197],
            [-34.847667916992187, -6.992468526367198],
            [-34.847367916992184, -6.988368526367196],
            [-34.848367916992188, -6.983568526367197],
            [-34.852567916992186, -6.982468526367196],
            [-34.856767916992183, -6.982868526367195],
            [-34.861467916992183, -6.981368526367195]
        ]
        ],
        [
        [
            [-32.400467916992184, -3.856668526367194],
            [-32.397567916992188, -3.855968526367199],
            [-32.396167916992184, -3.853068526367196],
            [-32.393167916992184, -3.851668526367199],
            [-32.395767916992185, -3.847368526367198],
            [-32.396467916992187, -3.842968526367194],
            [-32.398667916992189, -3.838468526367194],
            [-32.397267916992185, -3.834268526367197],
            [-32.398967916992184, -3.829268526367194],
            [-32.400467916992184, -3.833268526367199],
            [-32.403467916992184, -3.835968526367196],
            [-32.407367916992186, -3.8375685263672],
            [-32.411367916992184, -3.838468526367194],
            [-32.415267916992185, -3.838668526367194],
            [-32.418167916992182, -3.841268526367195],
            [-32.421767916992188, -3.8420685263672],
            [-32.425467916992183, -3.843168526367194],
            [-32.428667916992183, -3.844768526367197],
            [-32.431667916992183, -3.845868526367198],
            [-32.434467916992183, -3.847568526367198],
            [-32.438367916992185, -3.849268526367197],
            [-32.442167916992183, -3.850068526367195],
            [-32.445167916992183, -3.8542685263672],
            [-32.447867916992188, -3.857168526367197],
            [-32.447967916992184, -3.861468526367197],
            [-32.452167916992188, -3.864068526367198],
            [-32.457067916992187, -3.866168526367197],
            [-32.459767916992185, -3.869068526367194],
            [-32.461167916992189, -3.872168526367197],
            [-32.464067916992185, -3.872768526367196],
            [-32.466967916992182, -3.876268526367198],
            [-32.464067916992185, -3.879068526367199],
            [-32.460667916992186, -3.878368526367197],
            [-32.457967916992182, -3.880268526367196],
            [-32.456067916992183, -3.877668526367195],
            [-32.452467916992184, -3.875868526367199],
            [-32.448467916992186, -3.874868526367194],
            [-32.445467916992186, -3.873868526367197],
            [-32.442767916992182, -3.871568526367199],
            [-32.439067916992187, -3.871868526367194],
            [-32.435767916992184, -3.870268526367198],
            [-32.432167916992185, -3.871268526367196],
            [-32.429167916992185, -3.871868526367194],
            [-32.426867916992187, -3.873568526367194],
            [-32.424267916992186, -3.875568526367196],
            [-32.422867916992182, -3.8726685263672],
            [-32.425067916992184, -3.870468526367198],
            [-32.425767916992186, -3.867268526367198],
            [-32.421867916992184, -3.867268526367198],
            [-32.418067916992186, -3.868068526367196],
            [-32.416467916992183, -3.864768526367193],
            [-32.412267916992185, -3.862568526367198],
            [-32.409767916992188, -3.859368526367199],
            [-32.408067916992188, -3.857068526367193],
            [-32.404467916992182, -3.8574685263672],
            [-32.400467916992184, -3.856668526367194]
        ]
        ],
        [
        [
            [-32.384567916992182, -3.814868526367199],
            [-32.381367916992183, -3.813268526367196],
            [-32.380267916992182, -3.8101685263672],
            [-32.383567916992185, -3.809668526367197],
            [-32.387467916992186, -3.807568526367199],
            [-32.390367916992183, -3.809768526367193],
            [-32.393167916992184, -3.812168526367195],
            [-32.389267916992182, -3.815168526367195],
            [-32.384567916992182, -3.814868526367199]
        ]
        ],
        [
        [
            [-44.659867916992184, -2.975968526367197],
            [-44.659167916992182, -2.970068526367193],
            [-44.659567916992188, -2.964868526367198],
            [-44.660067916992183, -2.961768526367194],
            [-44.661467916992187, -2.958168526367196],
            [-44.663967916992185, -2.961768526367194],
            [-44.666467916992183, -2.969468526367194],
            [-44.666367916992186, -2.973168526367196],
            [-44.664767916992183, -2.975568526367198],
            [-44.662567916992188, -2.979168526367197],
            [-44.659867916992184, -2.975968526367197]
        ]
        ],
        [
        [
            [-44.625167916992183, -2.940168526367195],
            [-44.622667916992185, -2.9366685263672],
            [-44.621267916992188, -2.932968526367198],
            [-44.621967916992183, -2.927568526367196],
            [-44.625467916992186, -2.930268526367193],
            [-44.628467916992186, -2.933068526367194],
            [-44.629367916992187, -2.9366685263672],
            [-44.628767916992182, -2.942668526367193],
            [-44.625167916992183, -2.940168526367195]
        ]
        ],
        [
        [
            [-44.659767916992188, -2.944568526367199],
            [-44.657567916992186, -2.941668526367195],
            [-44.655067916992188, -2.938368526367199],
            [-44.650067916992185, -2.932568526367199],
            [-44.647067916992185, -2.929168526367199],
            [-44.644367916992188, -2.926968526367197],
            [-44.640967916992182, -2.923868526367194],
            [-44.640067916992187, -2.918168526367197],
            [-44.640767916992182, -2.914868526367194],
            [-44.643667916992186, -2.915568526367196],
            [-44.649767916992182, -2.917868526367194],
            [-44.655667916992186, -2.921668526367199],
            [-44.659167916992182, -2.924168526367197],
            [-44.664567916992183, -2.929968526367198],
            [-44.664567916992183, -2.937368526367194],
            [-44.663067916992183, -2.944568526367199],
            [-44.659767916992188, -2.944568526367199]
        ]
        ],
        [
        [
            [-39.908467916992187, -2.874068526367196],
            [-39.914467916992187, -2.870168526367195],
            [-39.920367916992184, -2.868368526367199],
            [-39.924467916992185, -2.870268526367198],
            [-39.919967916992185, -2.872468526367193],
            [-39.915867916992184, -2.873768526367193],
            [-39.912567916992188, -2.8741685263672],
            [-39.908467916992187, -2.874068526367196]
        ]
        ],
        [
        [
            [-44.588267916992187, -2.875168526367197],
            [-44.586967916992187, -2.870568526367194],
            [-44.587867916992188, -2.867468526367198],
            [-44.590867916992188, -2.872968526367195],
            [-44.591267916992187, -2.876568526367194],
            [-44.588267916992187, -2.875168526367197]
        ]
        ],
        [
        [
            [-44.481467916992187, -2.726668526367199],
            [-44.484767916992183, -2.724568526367193],
            [-44.488367916992182, -2.723768526367195],
            [-44.491467916992185, -2.724468526367197],
            [-44.494667916992185, -2.727068526367198],
            [-44.499467916992188, -2.726968526367195],
            [-44.502667916992188, -2.727068526367198],
            [-44.505167916992185, -2.728868526367194],
            [-44.507667916992183, -2.731668526367194],
            [-44.510667916992183, -2.735668526367199],
            [-44.514567916992185, -2.737268526367195],
            [-44.515667916992186, -2.741068526367194],
            [-44.521167916992184, -2.744068526367194],
            [-44.526267916992182, -2.746368526367199],
            [-44.532267916992183, -2.749868526367194],
            [-44.536367916992184, -2.753768526367196],
            [-44.538067916992183, -2.758568526367199],
            [-44.540267916992185, -2.761768526367199],
            [-44.540667916992184, -2.765768526367197],
            [-44.544267916992183, -2.769368526367195],
            [-44.548667916992187, -2.771868526367193],
            [-44.551667916992187, -2.775468526367199],
            [-44.555867916992185, -2.7780685263672],
            [-44.559367916992187, -2.782068526367198],
            [-44.562967916992186, -2.786668526367194],
            [-44.565967916992186, -2.789968526367197],
            [-44.568567916992187, -2.792568526367198],
            [-44.586567916992188, -2.815468526367198],
            [-44.588167916992184, -2.819668526367195],
            [-44.587867916992188, -2.824968526367194],
            [-44.587267916992182, -2.828768526367199],
            [-44.586867916992183, -2.832968526367196],
            [-44.586167916992189, -2.840168526367194],
            [-44.583267916992185, -2.848468526367199],
            [-44.580367916992188, -2.856468526367195],
            [-44.580367916992188, -2.860268526367193],
            [-44.581267916992182, -2.864468526367197],
            [-44.583267916992185, -2.873368526367194],
            [-44.586167916992189, -2.880568526367199],
            [-44.587267916992182, -2.884968526367196],
            [-44.588467916992187, -2.889668526367196],
            [-44.589567916992188, -2.894768526367194],
            [-44.593967916992185, -2.902268526367195],
            [-44.598467916992185, -2.904068526367197],
            [-44.603967916992183, -2.918868526367199],
            [-44.605667916992182, -2.927568526367196],
            [-44.606767916992183, -2.934068526367199],
            [-44.608567916992186, -2.937368526367194],
            [-44.610867916992184, -2.944568526367199],
            [-44.613867916992184, -2.950468526367196],
            [-44.618667916992187, -2.956268526367197],
            [-44.622667916992185, -2.9595685263672],
            [-44.628267916992186, -2.963368526367198],
            [-44.634867916992185, -2.966168526367198],
            [-44.637867916992185, -2.967368526367196],
            [-44.640667916992186, -2.969568526367198],
            [-44.641467916992184, -2.972368526367198],
            [-44.638567916992187, -2.979768526367195],
            [-44.635667916992183, -2.983868526367196],
            [-44.632067916992185, -2.987168526367199],
            [-44.628067916992187, -2.989368526367194],
            [-44.625167916992183, -2.990268526367196],
            [-44.621867916992187, -2.991068526367194],
            [-44.618267916992188, -2.993068526367196],
            [-44.614967916992185, -2.995268526367198],
            [-44.611667916992182, -2.998368526367194],
            [-44.608867916992182, -3.002068526367196],
            [-44.606967916992183, -3.005668526367195],
            [-44.602067916992183, -3.009268526367194],
            [-44.599467916992182, -3.010668526367198],
            [-44.596467916992182, -3.012068526367194],
            [-44.592667916992184, -3.0143685263672],
            [-44.588667916992186, -3.018968526367196],
            [-44.585967916992182, -3.024068526367195],
            [-44.583267916992185, -3.030168526367198],
            [-44.579567916992183, -3.031268526367199],
            [-44.574967916992186, -3.029268526367197],
            [-44.572067916992182, -3.027968526367196],
            [-44.569367916992185, -3.0265685263672],
            [-44.565767916992186, -3.024768526367197],
            [-44.561867916992185, -3.022868526367198],
            [-44.556567916992186, -3.018968526367196],
            [-44.553667916992183, -3.016268526367199],
            [-44.547467916992183, -3.011768526367199],
            [-44.539167916992184, -3.005268526367196],
            [-44.529867916992188, -2.998068526367199],
            [-44.523167916992186, -2.993068526367196],
            [-44.507667916992183, -2.983368526367194],
            [-44.495567916992186, -2.975868526367194],
            [-44.489667916992182, -2.971368526367193],
            [-44.484667916992187, -2.965168526367194],
            [-44.482167916992182, -2.960868526367193],
            [-44.479467916992185, -2.958268526367199],
            [-44.479967916992187, -2.953268526367197],
            [-44.481667916992187, -2.9473685263672],
            [-44.481767916992183, -2.938268526367196],
            [-44.481467916992187, -2.930268526367193],
            [-44.479667916992184, -2.920868526367194],
            [-44.478567916992183, -2.914168526367199],
            [-44.477867916992182, -2.906468526367199],
            [-44.476967916992187, -2.896768526367197],
            [-44.476367916992189, -2.887968526367196],
            [-44.475967916992182, -2.876568526367194],
            [-44.476467916992185, -2.863668526367199],
            [-44.477067916992183, -2.857168526367197],
            [-44.478567916992183, -2.852068526367198],
            [-44.479767916992188, -2.843768526367199],
            [-44.481767916992183, -2.834268526367197],
            [-44.481967916992183, -2.830468526367198],
            [-44.481167916992185, -2.823968526367196],
            [-44.481667916992187, -2.814868526367199],
            [-44.482067916992186, -2.807968526367198],
            [-44.481167916992185, -2.800368526367194],
            [-44.479267916992185, -2.793568526367196],
            [-44.477767916992185, -2.786968526367197],
            [-44.476667916992184, -2.782368526367193],
            [-44.476367916992189, -2.7750685263672],
            [-44.477067916992183, -2.768168526367198],
            [-44.477867916992182, -2.7643685263672],
            [-44.478967916992183, -2.760668526367198],
            [-44.478867916992186, -2.757468526367198],
            [-44.477467916992182, -2.754868526367197],
            [-44.477767916992185, -2.749368526367199],
            [-44.478067916992188, -2.744668526367199],
            [-44.477067916992183, -2.739668526367197],
            [-44.476167916992182, -2.736068526367198],
            [-44.475967916992182, -2.7324685263672],
            [-44.477867916992182, -2.729168526367197],
            [-44.481467916992187, -2.726668526367199]
        ]
        ],
        [
        [
            [-44.361667916992182, -2.523268526367197],
            [-44.362967916992183, -2.520668526367196],
            [-44.366167916992183, -2.518768526367197],
            [-44.371567916992184, -2.521068526367195],
            [-44.371867916992187, -2.525168526367196],
            [-44.369767916992188, -2.527668526367194],
            [-44.367267916992184, -2.526168526367194],
            [-44.364067916992184, -2.527668526367194],
            [-44.361667916992182, -2.523268526367197]
        ]
        ],
        [
        [
            [-43.465167916992186, -2.480368526367194],
            [-43.467067916992185, -2.475768526367197],
            [-43.469567916992183, -2.470968526367194],
            [-43.472667916992187, -2.473368526367196],
            [-43.471267916992183, -2.477368526367194],
            [-43.469467916992187, -2.480268526367198],
            [-43.466267916992187, -2.483568526367193],
            [-43.465167916992186, -2.480368526367194]
        ]
        ],
        [
        [
            [-43.855567916992186, -2.440768526367194],
            [-43.850667916992187, -2.436868526367199],
            [-43.848367916992188, -2.432468526367195],
            [-43.846167916992187, -2.428668526367197],
            [-43.847367916992184, -2.425768526367193],
            [-43.850567916992183, -2.424668526367199],
            [-43.852067916992183, -2.428868526367197],
            [-43.856767916992183, -2.430868526367199],
            [-43.860067916992186, -2.431568526367194],
            [-43.862467916992188, -2.433368526367197],
            [-43.864967916992185, -2.435868526367194],
            [-43.867267916992184, -2.438768526367198],
            [-43.869167916992183, -2.441668526367195],
            [-43.868867916992187, -2.445168526367198],
            [-43.864967916992185, -2.443468526367198],
            [-43.861867916992182, -2.443168526367195],
            [-43.858067916992184, -2.443868526367197],
            [-43.855567916992186, -2.440768526367194]
        ]
        ],
        [
        [
            [-44.421167916992182, -2.426568526367198],
            [-44.420267916992188, -2.421168526367197],
            [-44.421167916992182, -2.415968526367195],
            [-44.425367916992187, -2.415568526367196],
            [-44.426567916992184, -2.419268526367198],
            [-44.426167916992185, -2.423868526367194],
            [-44.424667916992185, -2.427768526367196],
            [-44.421167916992182, -2.426568526367198]
        ]
        ],
        [
        [
            [-43.506867916992185, -2.404468526367197],
            [-43.505967916992184, -2.397868526367198],
            [-43.505767916992184, -2.392868526367195],
            [-43.506067916992187, -2.388268526367199],
            [-43.507367916992187, -2.382968526367193],
            [-43.511767916992184, -2.380468526367196],
            [-43.512967916992189, -2.377668526367195],
            [-43.516467916992184, -2.374368526367199],
            [-43.518967916992182, -2.372168526367197],
            [-43.521467916992187, -2.370768526367193],
            [-43.525767916992187, -2.370468526367198],
            [-43.527567916992183, -2.372968526367195],
            [-43.529767916992185, -2.378468526367193],
            [-43.530367916992184, -2.383768526367199],
            [-43.531467916992185, -2.388768526367194],
            [-43.533667916992187, -2.394368526367195],
            [-43.534267916992185, -2.398968526367199],
            [-43.535867916992188, -2.402568526367197],
            [-43.536267916992188, -2.406168526367196],
            [-43.531467916992185, -2.405868526367193],
            [-43.527867916992186, -2.405068526367195],
            [-43.522867916992183, -2.405168526367198],
            [-43.519267916992185, -2.405668526367194],
            [-43.515167916992183, -2.406568526367195],
            [-43.511267916992182, -2.405468526367194],
            [-43.506867916992185, -2.404468526367197]
        ]
        ],
        [
        [
            [-43.757667916992183, -2.373268526367198],
            [-43.761567916992185, -2.370468526367198],
            [-43.765367916992183, -2.369468526367193],
            [-43.770667916992188, -2.369168526367197],
            [-43.774767916992182, -2.370768526367193],
            [-43.779167916992186, -2.372968526367195],
            [-43.783967916992182, -2.375468526367193],
            [-43.786467916992187, -2.3771685263672],
            [-43.788167916992187, -2.380468526367196],
            [-43.788067916992183, -2.383468526367196],
            [-43.786767916992183, -2.386068526367197],
            [-43.783867916992186, -2.387068526367194],
            [-43.781267916992185, -2.390768526367197],
            [-43.781167916992182, -2.394368526367195],
            [-43.782867916992188, -2.398468526367196],
            [-43.785067916992183, -2.400468526367199],
            [-43.787067916992186, -2.402668526367194],
            [-43.787567916992188, -2.405868526367193],
            [-43.787767916992188, -2.409468526367199],
            [-43.788067916992183, -2.413468526367197],
            [-43.785267916992183, -2.412768526367195],
            [-43.781267916992185, -2.410968526367199],
            [-43.777867916992186, -2.409768526367195],
            [-43.774567916992183, -2.406168526367196],
            [-43.772567916992188, -2.402268526367195],
            [-43.770067916992183, -2.398668526367196],
            [-43.768767916992182, -2.395368526367193],
            [-43.767367916992185, -2.391068526367199],
            [-43.767167916992186, -2.387368526367197],
            [-43.766067916992185, -2.384368526367197],
            [-43.764067916992182, -2.381768526367196],
            [-43.762467916992186, -2.379168526367195],
            [-43.759067916992187, -2.377468526367196],
            [-43.754667916992183, -2.377968526367198],
            [-43.757667916992183, -2.373268526367198]
        ]
        ],
        [
        [
            [-43.566667916992188, -2.425068526367198],
            [-43.561867916992185, -2.423868526367194],
            [-43.559367916992187, -2.419268526367198],
            [-43.558867916992185, -2.415668526367199],
            [-43.556967916992186, -2.413068526367198],
            [-43.557467916992188, -2.408968526367197],
            [-43.556067916992184, -2.406268526367199],
            [-43.552567916992189, -2.401168526367194],
            [-43.548567916992184, -2.397268526367199],
            [-43.546367916992182, -2.392968526367198],
            [-43.543567916992188, -2.386768526367199],
            [-43.541667916992182, -2.378868526367199],
            [-43.541667916992182, -2.375768526367196],
            [-43.539767916992183, -2.368868526367194],
            [-43.541167916992187, -2.364768526367193],
            [-43.542067916992188, -2.360568526367196],
            [-43.547467916992183, -2.359768526367198],
            [-43.554967916992183, -2.356668526367194],
            [-43.557967916992183, -2.355368526367194],
            [-43.562167916992188, -2.356768526367198],
            [-43.564867916992185, -2.358868526367196],
            [-43.569567916992185, -2.360668526367199],
            [-43.572467916992188, -2.361368526367194],
            [-43.581867916992188, -2.362468526367195],
            [-43.598367916992188, -2.366868526367199],
            [-43.600967916992182, -2.368368526367199],
            [-43.604167916992182, -2.370168526367195],
            [-43.607167916992182, -2.3726685263672],
            [-43.610867916992184, -2.375968526367195],
            [-43.612767916992183, -2.378468526367193],
            [-43.613067916992186, -2.381768526367196],
            [-43.609467916992187, -2.384368526367197],
            [-43.608667916992182, -2.388768526367194],
            [-43.607767916992188, -2.392868526367195],
            [-43.603667916992187, -2.400368526367195],
            [-43.600367916992184, -2.407268526367197],
            [-43.600267916992188, -2.412868526367198],
            [-43.595367916992188, -2.413668526367196],
            [-43.591567916992183, -2.415068526367193],
            [-43.587967916992184, -2.418168526367197],
            [-43.583967916992187, -2.421168526367197],
            [-43.581467916992182, -2.423268526367195],
            [-43.577867916992183, -2.423668526367194],
            [-43.573467916992186, -2.425368526367194],
            [-43.566667916992188, -2.425068526367198]
        ]
        ],
        [
        [
            [-44.470567916992188, -2.077868526367197],
            [-44.467067916992185, -2.075968526367198],
            [-44.465867916992188, -2.070268526367194],
            [-44.468767916992185, -2.067968526367196],
            [-44.471667916992182, -2.068868526367197],
            [-44.476767916992188, -2.073168526367198],
            [-44.480367916992186, -2.075468526367196],
            [-44.481967916992183, -2.078168526367193],
            [-44.477367916992186, -2.078468526367196],
            [-44.470567916992188, -2.077868526367197]
        ]
        ],
        [
        [
            [-44.738667916992185, -1.695268526367194],
            [-44.734367916992184, -1.691268526367196],
            [-44.731767916992183, -1.688768526367198],
            [-44.732767916992188, -1.685268526367196],
            [-44.735067916992186, -1.681068526367199],
            [-44.739367916992187, -1.679368526367199],
            [-44.745167916992187, -1.682168526367199],
            [-44.748367916992187, -1.684068526367199],
            [-44.753867916992185, -1.686568526367196],
            [-44.757367916992187, -1.688368526367199],
            [-44.755567916992185, -1.691568526367199],
            [-44.752367916992185, -1.693468526367198],
            [-44.750167916992183, -1.695968526367196],
            [-44.746567916992184, -1.697968526367198],
            [-44.741667916992185, -1.698568526367197],
            [-44.738667916992185, -1.695268526367194]
        ]
        ],
        [
        [
            [-44.725567916992183, -1.6667685263672],
            [-44.725967916992182, -1.662368526367196],
            [-44.728067916992188, -1.659168526367196],
            [-44.731967916992183, -1.659768526367195],
            [-44.735567916992188, -1.661368526367198],
            [-44.738967916992188, -1.663168526367194],
            [-44.741867916992184, -1.667468526367195],
            [-44.739167916992187, -1.668868526367199],
            [-44.736067916992184, -1.6699685263672],
            [-44.732167916992182, -1.671168526367197],
            [-44.727467916992182, -1.672168526367194],
            [-44.725567916992183, -1.6667685263672]
        ]
        ],
        [
        [
            [-44.827067916992185, -1.557268526367196],
            [-44.831167916992186, -1.556868526367197],
            [-44.835467916992187, -1.556868526367197],
            [-44.838967916992182, -1.558368526367197],
            [-44.836267916992185, -1.562768526367194],
            [-44.832967916992182, -1.565768526367194],
            [-44.830167916992188, -1.567468526367193],
            [-44.826767916992182, -1.566668526367195],
            [-44.824867916992183, -1.563868526367195],
            [-44.824967916992186, -1.560768526367198],
            [-44.827067916992185, -1.557268526367196]
        ]
        ],
        [
        [
            [-44.758167916992186, -1.577468526367198],
            [-44.755167916992185, -1.574868526367197],
            [-44.752667916992188, -1.571368526367195],
            [-44.748267916992184, -1.569068526367197],
            [-44.745867916992182, -1.567168526367198],
            [-44.741167916992183, -1.562768526367194],
            [-44.737567916992184, -1.558268526367193],
            [-44.734367916992184, -1.555268526367193],
            [-44.732467916992185, -1.550068526367198],
            [-44.732167916992182, -1.544168526367194],
            [-44.735267916992186, -1.539568526367198],
            [-44.738267916992186, -1.536468526367194],
            [-44.744467916992186, -1.535668526367196],
            [-44.748767916992186, -1.5342685263672],
            [-44.752367916992185, -1.535868526367196],
            [-44.753867916992185, -1.539568526367198],
            [-44.754367916992187, -1.543168526367197],
            [-44.755267916992182, -1.547768526367193],
            [-44.759367916992183, -1.549768526367195],
            [-44.761567916992185, -1.552968526367195],
            [-44.762367916992183, -1.556568526367194],
            [-44.762167916992183, -1.564968526367196],
            [-44.762967916992189, -1.570168526367198],
            [-44.763167916992188, -1.573168526367198],
            [-44.762867916992185, -1.576268526367194],
            [-44.761767916992184, -1.579368526367197],
            [-44.758167916992186, -1.577468526367198]
        ]
        ],
        [
        [
            [-44.865067916992189, -1.491068526367194],
            [-44.869867916992185, -1.490768526367198],
            [-44.867467916992183, -1.492968526367193],
            [-44.865067916992189, -1.491068526367194]
        ]
        ],
        [
        [
            [-44.827067916992185, -1.557268526367196],
            [-44.822067916992182, -1.557668526367195],
            [-44.817767916992182, -1.560468526367195],
            [-44.814067916992187, -1.560768526367198],
            [-44.809967916992186, -1.560768526367198],
            [-44.806567916992186, -1.559368526367194],
            [-44.803667916992183, -1.556568526367194],
            [-44.801067916992189, -1.554068526367196],
            [-44.798867916992187, -1.551968526367197],
            [-44.797167916992187, -1.548568526367198],
            [-44.794967916992185, -1.545568526367198],
            [-44.793967916992187, -1.542268526367195],
            [-44.795867916992187, -1.539868526367194],
            [-44.795267916992188, -1.534768526367195],
            [-44.797467916992183, -1.530968526367197],
            [-44.798567916992184, -1.527868526367193],
            [-44.799267916992186, -1.524068526367195],
            [-44.795367916992184, -1.522568526367195],
            [-44.791667916992182, -1.526268526367197],
            [-44.787267916992185, -1.525868526367198],
            [-44.784567916992188, -1.522868526367198],
            [-44.781267916992185, -1.521268526367194],
            [-44.778167916992182, -1.519268526367199],
            [-44.772567916992188, -1.518168526367198],
            [-44.768767916992182, -1.518268526367194],
            [-44.765667916992186, -1.517668526367196],
            [-44.762767916992182, -1.518268526367194],
            [-44.759167916992183, -1.517668526367196],
            [-44.754667916992183, -1.517568526367199],
            [-44.750567916992182, -1.517168526367193],
            [-44.746567916992184, -1.513168526367195],
            [-44.745167916992187, -1.505268526367196],
            [-44.745767916992186, -1.499068526367196],
            [-44.744067916992186, -1.4961685263672],
            [-44.742267916992184, -1.492668526367197],
            [-44.742967916992185, -1.486868526367196],
            [-44.745467916992183, -1.480068526367198],
            [-44.748767916992186, -1.473168526367196],
            [-44.751567916992187, -1.468368526367193],
            [-44.755467916992188, -1.463168526367198],
            [-44.758867916992187, -1.460368526367198],
            [-44.762667916992186, -1.458168526367196],
            [-44.766467916992184, -1.457968526367196],
            [-44.769267916992185, -1.459368526367193],
            [-44.774367916992183, -1.460368526367198],
            [-44.779467916992182, -1.461768526367194],
            [-44.783867916992186, -1.463468526367194],
            [-44.789867916992186, -1.466268526367195],
            [-44.792067916992188, -1.469768526367197],
            [-44.786967916992182, -1.472068526367195],
            [-44.783467916992187, -1.473668526367199],
            [-44.779767916992185, -1.476668526367199],
            [-44.776867916992188, -1.479168526367197],
            [-44.781967916992187, -1.479468526367199],
            [-44.780967916992182, -1.483868526367196],
            [-44.785567916992186, -1.488268526367193],
            [-44.789467916992187, -1.490568526367198],
            [-44.793967916992187, -1.493268526367196],
            [-44.809167916992187, -1.5021685263672],
            [-44.811967916992188, -1.507068526367199],
            [-44.807967916992183, -1.511568526367199],
            [-44.808767916992188, -1.515668526367193],
            [-44.814367916992182, -1.522368526367195],
            [-44.815767916992186, -1.525468526367199],
            [-44.820267916992186, -1.528368526367196],
            [-44.824867916992183, -1.530668526367194],
            [-44.828267916992182, -1.532068526367198],
            [-44.832267916992187, -1.533668526367194],
            [-44.835167916992184, -1.538468526367197],
            [-44.836567916992188, -1.542768526367198],
            [-44.834267916992182, -1.5464685263672],
            [-44.830367916992188, -1.552768526367196],
            [-44.827067916992185, -1.557268526367196]
        ],
        [
            [-44.773167916992186, -1.490568526367198],
            [-44.774767916992182, -1.495168526367195],
            [-44.778367916992188, -1.500168526367197],
            [-44.785267916992183, -1.506068526367194],
            [-44.787567916992188, -1.508868526367195],
            [-44.791467916992183, -1.513968526367194],
            [-44.794167916992187, -1.516768526367194],
            [-44.794967916992185, -1.5128685263672],
            [-44.792067916992188, -1.510768526367194],
            [-44.787567916992188, -1.504168526367195],
            [-44.781767916992187, -1.498668526367197],
            [-44.778967916992187, -1.496068526367196],
            [-44.776467916992182, -1.492468526367198],
            [-44.773367916992186, -1.486368526367194],
            [-44.773167916992186, -1.490568526367198]
        ]
        ],
        [
        [
            [-45.072467916992188, -1.453568526367199],
            [-45.071267916992184, -1.449068526367199],
            [-45.069867916992187, -1.445168526367198],
            [-45.072067916992182, -1.443168526367195],
            [-45.070267916992186, -1.439168526367197],
            [-45.073167916992183, -1.4351685263672],
            [-45.075467916992189, -1.430468526367193],
            [-45.077567916992187, -1.428268526367198],
            [-45.080767916992187, -1.428868526367197],
            [-45.082567916992183, -1.431868526367197],
            [-45.084567916992185, -1.436368526367197],
            [-45.084767916992185, -1.439368526367197],
            [-45.084867916992188, -1.442468526367193],
            [-45.083767916992187, -1.445968526367196],
            [-45.081267916992182, -1.449668526367198],
            [-45.078167916992186, -1.454668526367193],
            [-45.074567916992187, -1.457968526367196],
            [-45.072467916992188, -1.453568526367199]
        ]
        ],
        [
        [
            [-45.088467916992187, -1.438268526367196],
            [-45.087967916992184, -1.431868526367197],
            [-45.086167916992189, -1.427168526367197],
            [-45.086167916992189, -1.423968526367197],
            [-45.088967916992182, -1.422468526367197],
            [-45.093467916992182, -1.423868526367194],
            [-45.095567916992188, -1.428568526367194],
            [-45.096267916992183, -1.431868526367197],
            [-45.096767916992185, -1.434968526367193],
            [-45.096167916992187, -1.437968526367193],
            [-45.095567916992188, -1.440768526367194],
            [-45.093767916992185, -1.443068526367199],
            [-45.089767916992187, -1.443868526367197],
            [-45.088467916992187, -1.438268526367196]
        ]
        ],
        [
        [
            [-45.108967916992185, -1.468968526367199],
            [-45.105567916992186, -1.467568526367195],
            [-45.103067916992188, -1.4640685263672],
            [-45.102367916992186, -1.460368526367198],
            [-45.102767916992185, -1.455368526367195],
            [-45.104167916992182, -1.450668526367195],
            [-45.107567916992188, -1.445168526367198],
            [-45.109767916992183, -1.437268526367198],
            [-45.110867916992184, -1.429668526367195],
            [-45.111067916992184, -1.421968526367195],
            [-45.116967916992188, -1.429368526367199],
            [-45.118567916992184, -1.432468526367195],
            [-45.123367916992187, -1.437968526367193],
            [-45.124067916992182, -1.445268526367194],
            [-45.127967916992183, -1.452168526367196],
            [-45.128267916992186, -1.456268526367197],
            [-45.132767916992186, -1.459268526367197],
            [-45.133767916992184, -1.463768526367197],
            [-45.131767916992182, -1.467968526367194],
            [-45.129167916992188, -1.471668526367196],
            [-45.125967916992188, -1.4717685263672],
            [-45.117167916992187, -1.470868526367198],
            [-45.112767916992183, -1.470968526367194],
            [-45.108967916992185, -1.468968526367199]
        ]
        ],
        [
        [
            [-45.410967916992185, -1.418168526367197],
            [-45.407267916992183, -1.413468526367197],
            [-45.404567916992185, -1.4092685263672],
            [-45.404467916992182, -1.400968526367194],
            [-45.409867916992184, -1.399368526367198],
            [-45.413867916992189, -1.401768526367199],
            [-45.417767916992183, -1.403668526367198],
            [-45.420267916992188, -1.407368526367193],
            [-45.419967916992185, -1.410268526367197],
            [-45.419267916992183, -1.413368526367194],
            [-45.416767916992185, -1.417268526367195],
            [-45.413867916992189, -1.420768526367198],
            [-45.410967916992185, -1.418168526367197]
        ]
        ],
        [
        [
            [-45.099167916992187, -1.429768526367198],
            [-45.098067916992186, -1.423268526367195],
            [-45.096367916992186, -1.419168526367194],
            [-45.094067916992188, -1.415568526367196],
            [-45.092667916992184, -1.411668526367194],
            [-45.090567916992185, -1.408368526367198],
            [-45.088367916992183, -1.404768526367199],
            [-45.087067916992183, -1.402068526367195],
            [-45.085467916992187, -1.398168526367193],
            [-45.084767916992185, -1.393268526367194],
            [-45.082267916992187, -1.391268526367199],
            [-45.077367916992188, -1.391268526367199],
            [-45.074567916992187, -1.389568526367199],
            [-45.074067916992185, -1.386568526367199],
            [-45.074867916992183, -1.382768526367194],
            [-45.072667916992188, -1.380668526367195],
            [-45.070767916992182, -1.378468526367193],
            [-45.071367916992187, -1.374468526367195],
            [-45.074567916992187, -1.371968526367198],
            [-45.077867916992183, -1.373068526367199],
            [-45.081167916992186, -1.376268526367198],
            [-45.082567916992183, -1.379368526367195],
            [-45.082867916992186, -1.382368526367195],
            [-45.085067916992188, -1.386468526367196],
            [-45.087967916992184, -1.391768526367194],
            [-45.090167916992186, -1.396768526367197],
            [-45.093067916992183, -1.404168526367194],
            [-45.097267916992188, -1.411968526367197],
            [-45.100267916992188, -1.416668526367197],
            [-45.102367916992186, -1.420368526367199],
            [-45.103467916992187, -1.424968526367195],
            [-45.102867916992182, -1.428568526367194],
            [-45.100667916992187, -1.432468526367195],
            [-45.099167916992187, -1.429768526367198]
        ]
        ],
        [
        [
            [-45.042467916992187, -1.395168526367193],
            [-45.040367916992182, -1.391268526367199],
            [-45.039467916992187, -1.388168526367195],
            [-45.039867916992186, -1.384968526367196],
            [-45.041167916992187, -1.379868526367197],
            [-45.041167916992187, -1.375468526367193],
            [-45.041667916992182, -1.372468526367193],
            [-45.043567916992188, -1.369368526367197],
            [-45.046367916992182, -1.366368526367197],
            [-45.049967916992188, -1.368668526367195],
            [-45.049467916992185, -1.3726685263672],
            [-45.048267916992188, -1.375768526367196],
            [-45.046667916992185, -1.380568526367199],
            [-45.047167916992187, -1.384968526367196],
            [-45.046967916992187, -1.389068526367197],
            [-45.047467916992183, -1.394268526367199],
            [-45.047167916992187, -1.397168526367196],
            [-45.044767916992186, -1.398768526367199],
            [-45.042467916992187, -1.395168526367193]
        ]
        ],
        [
        [
            [-45.105567916992186, -1.408968526367197],
            [-45.105267916992183, -1.403468526367199],
            [-45.103667916992187, -1.399368526367198],
            [-45.101967916992187, -1.396868526367193],
            [-45.099567916992186, -1.393168526367198],
            [-45.097267916992188, -1.389068526367197],
            [-45.096967916992185, -1.3848685263672],
            [-45.093667916992182, -1.382768526367194],
            [-45.093767916992185, -1.379668526367198],
            [-45.096967916992185, -1.375168526367197],
            [-45.099467916992182, -1.371568526367199],
            [-45.104967916992187, -1.367168526367195],
            [-45.108867916992182, -1.364368526367194],
            [-45.112167916992185, -1.362768526367198],
            [-45.115567916992184, -1.363868526367199],
            [-45.119767916992188, -1.370268526367198],
            [-45.123067916992184, -1.375168526367197],
            [-45.125767916992189, -1.381368526367197],
            [-45.126067916992184, -1.386068526367197],
            [-45.120867916992182, -1.388468526367198],
            [-45.117967916992185, -1.390768526367197],
            [-45.115767916992183, -1.394668526367198],
            [-45.113667916992185, -1.400868526367198],
            [-45.112267916992188, -1.405968526367197],
            [-45.111867916992182, -1.408868526367193],
            [-45.111467916992183, -1.411968526367197],
            [-45.108067916992184, -1.412468526367199],
            [-45.105567916992186, -1.408968526367197]
        ]
        ],
        [
        [
            [-44.966167916992184, -1.271068526367195],
            [-44.970967916992187, -1.267968526367198],
            [-44.977067916992183, -1.265168526367198],
            [-44.981067916992188, -1.264068526367197],
            [-44.986467916992183, -1.266068526367199],
            [-44.992767916992186, -1.274768526367197],
            [-44.998767916992186, -1.285668526367196],
            [-45.001667916992183, -1.291968526367199],
            [-45.000267916992186, -1.295568526367198],
            [-44.996067916992182, -1.296968526367195],
            [-44.993267916992188, -1.301968526367197],
            [-44.992467916992183, -1.306068526367199],
            [-44.988367916992182, -1.307168526367199],
            [-44.981967916992183, -1.308268526367193],
            [-44.978967916992183, -1.311268526367193],
            [-44.978467916992187, -1.316668526367195],
            [-44.981667916992187, -1.320468526367193],
            [-44.987267916992188, -1.322468526367196],
            [-44.992667916992183, -1.323168526367198],
            [-44.999467916992188, -1.323968526367196],
            [-45.004967916992186, -1.324968526367194],
            [-45.010967916992186, -1.3238685263672],
            [-45.018667916992186, -1.321268526367199],
            [-45.023967916992184, -1.319068526367197],
            [-45.028667916992184, -1.317968526367196],
            [-45.030567916992183, -1.3223685263672],
            [-45.031167916992182, -1.328568526367199],
            [-45.032867916992188, -1.333268526367199],
            [-45.033867916992186, -1.337968526367199],
            [-45.030467916992187, -1.341468526367194],
            [-45.025767916992187, -1.345968526367194],
            [-45.021267916992187, -1.347368526367198],
            [-45.016067916992185, -1.3467685263672],
            [-45.014067916992182, -1.349468526367197],
            [-45.014567916992185, -1.353568526367198],
            [-45.018267916992187, -1.361368526367194],
            [-45.019067916992185, -1.369068526367194],
            [-45.021767916992182, -1.372468526367193],
            [-45.022067916992185, -1.378768526367196],
            [-45.021467916992187, -1.383868526367195],
            [-45.018567916992183, -1.388768526367194],
            [-45.015367916992183, -1.391368526367195],
            [-45.012067916992187, -1.394368526367195],
            [-45.008867916992187, -1.397268526367199],
            [-45.005267916992182, -1.4000685263672],
            [-44.998567916992187, -1.401968526367199],
            [-44.993867916992187, -1.4015685263672],
            [-44.989667916992182, -1.4015685263672],
            [-44.987967916992183, -1.399068526367195],
            [-44.986067916992184, -1.395968526367199],
            [-44.981767916992183, -1.394768526367194],
            [-44.978367916992184, -1.394268526367199],
            [-44.975367916992184, -1.393168526367198],
            [-44.971767916992185, -1.392168526367193],
            [-44.968167916992186, -1.390468526367194],
            [-44.966967916992182, -1.387668526367193],
            [-44.965067916992183, -1.385368526367195],
            [-44.962067916992183, -1.383768526367199],
            [-44.958267916992185, -1.378568526367197],
            [-44.954567916992183, -1.377768526367198],
            [-44.951367916992183, -1.377368526367199],
            [-44.948167916992183, -1.379068526367199],
            [-44.946067916992185, -1.375768526367196],
            [-44.945967916992188, -1.372468526367193],
            [-44.943867916992183, -1.368668526367195],
            [-44.942167916992183, -1.364368526367194],
            [-44.936967916992188, -1.362568526367198],
            [-44.932667916992187, -1.358368526367194],
            [-44.928267916992183, -1.355868526367196],
            [-44.923867916992187, -1.352868526367196],
            [-44.918867916992184, -1.347368526367198],
            [-44.916367916992186, -1.344268526367195],
            [-44.913067916992183, -1.342568526367195],
            [-44.909167916992182, -1.341468526367194],
            [-44.905167916992184, -1.340068526367197],
            [-44.902367916992183, -1.337868526367195],
            [-44.897367916992188, -1.337268526367197],
            [-44.893667916992186, -1.338368526367198],
            [-44.891767916992187, -1.341168526367198],
            [-44.892067916992183, -1.344468526367194],
            [-44.893267916992187, -1.347868526367193],
            [-44.889567916992185, -1.348168526367196],
            [-44.886567916992185, -1.345568526367195],
            [-44.884367916992183, -1.340868526367196],
            [-44.881367916992183, -1.335468526367194],
            [-44.877667916992188, -1.330368526367195],
            [-44.873567916992187, -1.328168526367193],
            [-44.868567916992184, -1.329268526367194],
            [-44.867267916992184, -1.3253685263672],
            [-44.867967916992185, -1.320168526367198],
            [-44.869067916992186, -1.314368526367197],
            [-44.872667916992185, -1.311868526367199],
            [-44.874367916992185, -1.307768526367198],
            [-44.873867916992182, -1.304668526367195],
            [-44.871667916992187, -1.3024685263672],
            [-44.870467916992183, -1.299268526367193],
            [-44.872667916992185, -1.292768526367198],
            [-44.876367916992187, -1.288068526367198],
            [-44.878067916992187, -1.285368526367193],
            [-44.885167916992188, -1.280968526367197],
            [-44.899467916992187, -1.274268526367194],
            [-44.913067916992183, -1.269868526367198],
            [-44.928967916992185, -1.266768526367194],
            [-44.946267916992184, -1.266068526367199],
            [-44.952367916992188, -1.267968526367198],
            [-44.954567916992183, -1.270468526367196],
            [-44.960767916992182, -1.271768526367197],
            [-44.966167916992184, -1.271068526367195]
        ]
        ],
        [
        [
            [-45.569667916992188, -1.269268526367199],
            [-45.569867916992187, -1.265068526367195],
            [-45.570167916992183, -1.261568526367199],
            [-45.574367916992188, -1.261768526367199],
            [-45.575267916992182, -1.265068526367195],
            [-45.575267916992182, -1.268268526367194],
            [-45.576067916992187, -1.272168526367196],
            [-45.572667916992188, -1.273168526367193],
            [-45.569667916992188, -1.269268526367199]
        ]
        ],
        [
        [
            [-45.535867916992188, -1.227868526367196],
            [-45.533167916992184, -1.225668526367194],
            [-45.532067916992183, -1.2217685263672],
            [-45.534167916992182, -1.2170685263672],
            [-45.537467916992185, -1.2125685263672],
            [-45.538067916992183, -1.208468526367199],
            [-45.539267916992188, -1.205168526367196],
            [-45.542767916992183, -1.203168526367193],
            [-45.547767916992186, -1.200768526367199],
            [-45.550867916992182, -1.198768526367196],
            [-45.554967916992183, -1.196868526367197],
            [-45.559467916992183, -1.194668526367195],
            [-45.564467916992186, -1.192468526367193],
            [-45.568167916992188, -1.191568526367199],
            [-45.572667916992188, -1.191868526367195],
            [-45.576067916992187, -1.192768526367196],
            [-45.579267916992187, -1.194968526367198],
            [-45.580467916992184, -1.199268526367199],
            [-45.578267916992182, -1.2048685263672],
            [-45.579067916992187, -1.215368526367193],
            [-45.580367916992188, -1.220968526367194],
            [-45.583167916992188, -1.2247685263672],
            [-45.587967916992184, -1.230868526367196],
            [-45.593367916992186, -1.235368526367196],
            [-45.595267916992185, -1.237768526367198],
            [-45.594867916992186, -1.241168526367197],
            [-45.590867916992188, -1.2399685263672],
            [-45.586567916992188, -1.236668526367197],
            [-45.577967916992186, -1.230668526367197],
            [-45.569567916992185, -1.230068526367198],
            [-45.560767916992184, -1.229668526367199],
            [-45.553667916992183, -1.229568526367196],
            [-45.548067916992188, -1.229668526367199],
            [-45.542167916992184, -1.230868526367196],
            [-45.539167916992184, -1.230268526367198],
            [-45.535867916992188, -1.227868526367196]
        ]
        ],
        [
        [
            [-45.768667916992186, -1.190868526367197],
            [-45.771167916992184, -1.189168526367197],
            [-45.773967916992184, -1.191368526367199],
            [-45.772567916992188, -1.194868526367195],
            [-45.768567916992183, -1.195268526367194],
            [-45.768667916992186, -1.190868526367197]
        ]
        ],
        [
        [
            [-45.688867916992187, -1.264568526367199],
            [-45.685167916992185, -1.2628685263672],
            [-45.681967916992186, -1.260468526367198],
            [-45.678567916992186, -1.258068526367197],
            [-45.674767916992188, -1.255768526367198],
            [-45.672167916992187, -1.253568526367197],
            [-45.669767916992186, -1.249968526367198],
            [-45.668067916992186, -1.243568526367199],
            [-45.668667916992185, -1.239668526367197],
            [-45.668967916992187, -1.235368526367196],
            [-45.667267916992188, -1.230068526367198],
            [-45.667467916992187, -1.225868526367194],
            [-45.668567916992188, -1.221968526367199],
            [-45.671367916992182, -1.219268526367195],
            [-45.674967916992188, -1.217568526367195],
            [-45.676467916992188, -1.213768526367197],
            [-45.677467916992185, -1.206268526367197],
            [-45.677467916992185, -1.203268526367197],
            [-45.675767916992186, -1.200468526367196],
            [-45.673667916992187, -1.197768526367199],
            [-45.674267916992186, -1.193868526367197],
            [-45.676167916992185, -1.190768526367194],
            [-45.679167916992185, -1.188268526367196],
            [-45.684367916992187, -1.185268526367196],
            [-45.688367916992185, -1.183568526367196],
            [-45.690467916992183, -1.185868526367194],
            [-45.692467916992186, -1.189868526367199],
            [-45.695267916992186, -1.192768526367196],
            [-45.697367916992185, -1.195168526367198],
            [-45.699367916992188, -1.198568526367197],
            [-45.705167916992188, -1.212268526367197],
            [-45.709367916992186, -1.222268526367195],
            [-45.710667916992186, -1.2277685263672],
            [-45.709567916992185, -1.231368526367199],
            [-45.709367916992186, -1.234468526367195],
            [-45.709867916992188, -1.238268526367193],
            [-45.709667916992188, -1.242968526367193],
            [-45.708167916992188, -1.2461685263672],
            [-45.704667916992186, -1.249368526367199],
            [-45.701467916992186, -1.252468526367196],
            [-45.698967916992189, -1.255268526367196],
            [-45.696367916992187, -1.258568526367199],
            [-45.694867916992187, -1.261468526367196],
            [-45.692767916992182, -1.264668526367196],
            [-45.688867916992187, -1.264568526367199]
        ]
        ],
        [
        [
            [-45.604267916992185, -1.158468526367194],
            [-45.604567916992188, -1.152268526367195],
            [-45.608367916992187, -1.150968526367194],
            [-45.609667916992187, -1.155868526367193],
            [-45.608567916992186, -1.159868526367198],
            [-45.604267916992185, -1.158468526367194]
        ]
        ],
        [
        [
            [-45.812967916992186, -1.152568526367197],
            [-45.810767916992184, -1.150668526367198],
            [-45.808267916992186, -1.146868526367193],
            [-45.804667916992187, -1.143668526367193],
            [-45.804767916992184, -1.136468526367196],
            [-45.805467916992185, -1.132168526367195],
            [-45.805767916992188, -1.128868526367199],
            [-45.807167916992185, -1.124968526367198],
            [-45.809667916992183, -1.123368526367194],
            [-45.812667916992183, -1.123068526367199],
            [-45.815567916992187, -1.123068526367199],
            [-45.818567916992187, -1.123368526367194],
            [-45.821567916992187, -1.124468526367195],
            [-45.824567916992187, -1.125968526367195],
            [-45.827067916992185, -1.127968526367198],
            [-45.827167916992188, -1.131268526367194],
            [-45.827067916992185, -1.1348685263672],
            [-45.825667916992188, -1.137368526367197],
            [-45.826467916992186, -1.141068526367199],
            [-45.826067916992187, -1.145668526367196],
            [-45.826067916992187, -1.149368526367198],
            [-45.824867916992183, -1.152568526367197],
            [-45.822367916992185, -1.154168526367194],
            [-45.817767916992182, -1.154268526367197],
            [-45.812967916992186, -1.152568526367197]
        ]
        ],
        [
        [
            [-46.016267916992184, -1.079268526367194],
            [-46.011767916992184, -1.0768685263672],
            [-46.009667916992186, -1.074968526367194],
            [-46.010167916992188, -1.072168526367193],
            [-46.013567916992187, -1.071868526367197],
            [-46.016067916992185, -1.074268526367199],
            [-46.016267916992184, -1.079268526367194]
        ]
        ],
        [
        [
            [-46.434467916992183, -1.015368526367197],
            [-46.432267916992188, -1.011068526367197],
            [-46.433267916992186, -1.006268526367194],
            [-46.437167916992188, -1.005968526367198],
            [-46.440567916992187, -1.007668526367198],
            [-46.444267916992182, -1.010368526367195],
            [-46.446767916992187, -1.012468526367194],
            [-46.448267916992187, -1.016368526367195],
            [-46.443767916992186, -1.017168526367193],
            [-46.438867916992187, -1.017468526367196],
            [-46.434467916992183, -1.015368526367197]
        ]
        ],
        [
        [
            [-46.375467916992186, -1.020068526367197],
            [-46.375967916992188, -1.017068526367197],
            [-46.379167916992188, -1.012168526367198],
            [-46.378067916992187, -1.003568526367197],
            [-46.380667916992188, -1.001968526367193],
            [-46.383267916992182, -1.003268526367194],
            [-46.385467916992184, -1.006368526367197],
            [-46.385467916992184, -1.011468526367196],
            [-46.384167916992183, -1.015768526367197],
            [-46.382667916992183, -1.019368526367195],
            [-46.379867916992183, -1.021968526367196],
            [-46.375467916992186, -1.020068526367197]
        ]
        ],
        [
        [
            [-46.429967916992183, -0.9869685263672],
            [-46.426967916992183, -0.984768526367198],
            [-46.424167916992182, -0.982768526367195],
            [-46.420767916992183, -0.980568526367193],
            [-46.417467916992187, -0.978168526367199],
            [-46.414467916992187, -0.975868526367194],
            [-46.411467916992187, -0.972368526367198],
            [-46.409467916992185, -0.970168526367196],
            [-46.406767916992187, -0.968168526367194],
            [-46.404867916992188, -0.965368526367193],
            [-46.403667916992184, -0.961168526367196],
            [-46.402067916992188, -0.955668526367198],
            [-46.401567916992185, -0.951068526367195],
            [-46.402067916992188, -0.946568526367194],
            [-46.404267916992183, -0.943068526367199],
            [-46.408367916992184, -0.941268526367196],
            [-46.413867916992189, -0.941568526367199],
            [-46.416667916992182, -0.943768526367194],
            [-46.418067916992186, -0.947868526367195],
            [-46.420067916992188, -0.951368526367197],
            [-46.422467916992183, -0.955668526367198],
            [-46.424267916992186, -0.9595685263672],
            [-46.426167916992185, -0.963268526367195],
            [-46.428067916992184, -0.965968526367199],
            [-46.429967916992183, -0.968368526367193],
            [-46.431667916992183, -0.971168526367194],
            [-46.432967916992183, -0.976968526367195],
            [-46.433667916992185, -0.983168526367194],
            [-46.433767916992188, -0.987868526367194],
            [-46.429967916992183, -0.9869685263672]
        ]
        ],
        [
        [
            [-46.308767916992188, -1.002968526367198],
            [-46.305567916992182, -0.997768526367196],
            [-46.306567916992186, -0.989068526367198],
            [-46.308867916992185, -0.9824685263672],
            [-46.311567916992182, -0.976468526367199],
            [-46.313467916992188, -0.969868526367193],
            [-46.314067916992187, -0.963368526367198],
            [-46.313767916992184, -0.960068526367195],
            [-46.312467916992183, -0.956168526367193],
            [-46.310867916992187, -0.952168526367196],
            [-46.306867916992182, -0.944068526367197],
            [-46.307167916992185, -0.940468526367198],
            [-46.309467916992183, -0.937968526367193],
            [-46.312967916992186, -0.936568526367196],
            [-46.315967916992186, -0.934768526367193],
            [-46.321067916992185, -0.936068526367194],
            [-46.324667916992183, -0.939168526367197],
            [-46.328167916992186, -0.942968526367196],
            [-46.332067916992187, -0.947168526367193],
            [-46.335367916992183, -0.951068526367195],
            [-46.338467916992187, -0.955668526367198],
            [-46.339867916992183, -0.959768526367199],
            [-46.336567916992188, -0.962068526367197],
            [-46.333467916992184, -0.965368526367193],
            [-46.331167916992186, -0.968768526367199],
            [-46.328167916992186, -0.972068526367195],
            [-46.330367916992188, -0.979968526367195],
            [-46.331767916992185, -0.983168526367194],
            [-46.331067916992183, -0.986668526367197],
            [-46.324867916992183, -0.993068526367196],
            [-46.317767916992182, -1.002368526367199],
            [-46.315967916992186, -1.005268526367196],
            [-46.311967916992188, -1.006568526367197],
            [-46.308767916992188, -1.002968526367198]
        ]
        ],
        [
        [
            [-46.149567916992183, -0.953968526367198],
            [-46.146567916992183, -0.951768526367196],
            [-46.144367916992188, -0.947868526367195],
            [-46.143267916992187, -0.944268526367196],
            [-46.142567916992185, -0.939168526367197],
            [-46.141467916992184, -0.934468526367198],
            [-46.139667916992188, -0.930568526367196],
            [-46.137767916992182, -0.926168526367199],
            [-46.142067916992183, -0.924368526367196],
            [-46.145767916992185, -0.923568526367198],
            [-46.148967916992184, -0.924168526367197],
            [-46.151967916992184, -0.925468526367197],
            [-46.153667916992184, -0.929768526367198],
            [-46.154167916992186, -0.934668526367197],
            [-46.154467916992182, -0.938868526367195],
            [-46.154267916992183, -0.942768526367196],
            [-46.155367916992184, -0.948468526367193],
            [-46.155567916992183, -0.952568526367195],
            [-46.152867916992186, -0.954268526367194],
            [-46.149567916992183, -0.953968526367198]
        ]
        ],
        [
        [
            [-46.718767916992185, -0.787768526367195],
            [-46.720867916992184, -0.783468526367194],
            [-46.723867916992184, -0.782068526367198],
            [-46.728967916992183, -0.783468526367194],
            [-46.726467916992185, -0.786768526367197],
            [-46.723167916992182, -0.788668526367196],
            [-46.720867916992184, -0.790368526367196],
            [-46.718767916992185, -0.787768526367195]
        ]
        ],
        [
        [
            [-47.992167916992187, -0.684468526367198],
            [-47.992967916992185, -0.681568526367194],
            [-47.995467916992183, -0.679668526367195],
            [-47.995267916992184, -0.684168526367195],
            [-47.992967916992185, -0.688468526367195],
            [-47.992167916992187, -0.684468526367198]
        ]
        ],
        [
        [
            [-48.010267916992184, -0.686168526367197],
            [-48.008267916992182, -0.683068526367194],
            [-48.007067916992185, -0.678568526367194],
            [-48.00636791699219, -0.674968526367195],
            [-48.00606791699218, -0.668068526367193],
            [-48.008067916992189, -0.663068526367198],
            [-48.012167916992183, -0.662068526367193],
            [-48.01536791699219, -0.665668526367199],
            [-48.016067916992185, -0.671068526367193],
            [-48.015667916992186, -0.675568526367194],
            [-48.015167916992183, -0.679768526367198],
            [-48.014367916992185, -0.6836685263672],
            [-48.010267916992184, -0.686168526367197]
        ]
        ],
        [
        [
            [-47.838767916992182, -0.660668526367196],
            [-47.840967916992184, -0.657268526367197],
            [-47.844567916992183, -0.659868526367198],
            [-47.845967916992187, -0.662568526367195],
            [-47.846167916992187, -0.666668526367197],
            [-47.844567916992183, -0.669568526367193],
            [-47.842267916992185, -0.6714685263672],
            [-47.840567916992185, -0.668068526367193],
            [-47.840067916992183, -0.663368526367194],
            [-47.838767916992182, -0.660668526367196]
        ]
        ],
        [
        [
            [-47.838767916992182, -0.660668526367196],
            [-47.836467916992184, -0.663468526367197],
            [-47.834667916992188, -0.658468526367194],
            [-47.836767916992187, -0.656268526367199],
            [-47.838767916992182, -0.660668526367196]
        ]
        ],
        [
        [
            [-47.978067916992188, -0.668968526367195],
            [-47.974867916992181, -0.6652685263672],
            [-47.972367916992184, -0.660568526367193],
            [-47.971167916992187, -0.656268526367199],
            [-47.971667916992189, -0.653368526367196],
            [-47.973167916992182, -0.650968526367194],
            [-47.976167916992182, -0.649568526367197],
            [-47.979667916992184, -0.652568526367197],
            [-47.982167916992182, -0.655168526367198],
            [-47.98466791699218, -0.657268526367197],
            [-47.98496791699219, -0.660968526367199],
            [-47.98496791699219, -0.666068526367198],
            [-47.984167916992185, -0.669468526367197],
            [-47.981367916992184, -0.672168526367194],
            [-47.978067916992188, -0.668968526367195]
        ]
        ],
        [
        [
            [-47.822167916992186, -0.632668526367198],
            [-47.827467916992184, -0.631368526367197],
            [-47.829267916992187, -0.634568526367197],
            [-47.829567916992183, -0.638768526367194],
            [-47.827167916992188, -0.644768526367194],
            [-47.825767916992184, -0.640768526367197],
            [-47.824367916992188, -0.637968526367196],
            [-47.822167916992186, -0.632668526367198]
        ]
        ],
        [
        [
            [-47.908067916992181, -0.600368526367198],
            [-47.911667916992187, -0.596968526367199],
            [-47.913667916992182, -0.594268526367195],
            [-47.915867916992184, -0.592268526367199],
            [-47.918967916992187, -0.590168526367194],
            [-47.920867916992187, -0.593368526367193],
            [-47.91916791699218, -0.595968526367194],
            [-47.916367916992186, -0.598068526367193],
            [-47.91446791699218, -0.600568526367198],
            [-47.908067916992181, -0.600368526367198]
        ]
        ],
        [
        [
            [-47.915567916992188, -0.580768526367194],
            [-47.91776791699219, -0.578768526367199],
            [-47.921067916992186, -0.577168526367196],
            [-47.921467916992185, -0.580668526367198],
            [-47.918067916992186, -0.583768526367194],
            [-47.914567916992183, -0.583968526367194],
            [-47.915567916992188, -0.580768526367194]
        ]
        ],
        [
        [
            [-48.970567916992181, -0.181868526367197],
            [-48.972767916992183, -0.178268526367198],
            [-48.97596791699219, -0.175068526367198],
            [-48.982167916992182, -0.172168526367194],
            [-48.986067916992184, -0.171368526367196],
            [-48.986367916992187, -0.175368526367194],
            [-48.986367916992187, -0.179768526367198],
            [-48.982267916992186, -0.184168526367195],
            [-48.979167916992182, -0.185868526367194],
            [-48.97596791699219, -0.187668526367197],
            [-48.972867916992186, -0.188768526367198],
            [-48.969467916992187, -0.189068526367194],
            [-48.970567916992181, -0.181868526367197]
        ]
        ],
        [
        [
            [-48.94216791699219, -0.179768526367198],
            [-48.939067916992187, -0.175268526367198],
            [-48.939067916992187, -0.171068526367193],
            [-48.944267916992189, -0.171068526367193],
            [-48.946067916992185, -0.174368526367196],
            [-48.947667916992188, -0.178068526367198],
            [-48.946667916992183, -0.181868526367197],
            [-48.94216791699219, -0.179768526367198]
        ]
        ],
        [
        [
            [-48.73166791699218, -0.172168526367194],
            [-48.733567916992186, -0.168868526367199],
            [-48.735567916992181, -0.172268526367198],
            [-48.73166791699218, -0.172168526367194]
        ]
        ],
        [
        [
            [-48.975067916992188, -0.166968526367199],
            [-48.978167916992184, -0.165568526367196],
            [-48.980867916992182, -0.1667685263672],
            [-48.978667916992187, -0.170068526367196],
            [-48.975267916992181, -0.171368526367196],
            [-48.975067916992188, -0.166968526367199]
        ]
        ],
        [
        [
            [-48.761067916992189, -0.184668526367197],
            [-48.758067916992189, -0.181068526367199],
            [-48.755267916992182, -0.179668526367195],
            [-48.752467916992188, -0.177868526367199],
            [-48.750567916992182, -0.175068526367198],
            [-48.74836791699218, -0.172768526367193],
            [-48.747467916992186, -0.169168526367194],
            [-48.751867916992182, -0.165068526367193],
            [-48.756667916992185, -0.163668526367196],
            [-48.761767916992184, -0.165568526367196],
            [-48.765467916992186, -0.168668526367199],
            [-48.768167916992184, -0.172468526367197],
            [-48.77006791699219, -0.1774685263672],
            [-48.767667916992181, -0.181168526367195],
            [-48.765467916992186, -0.184868526367197],
            [-48.761067916992189, -0.184668526367197]
        ]
        ],
        [
        [
            [-48.910567916992186, -0.150168526367196],
            [-48.915267916992185, -0.151468526367196],
            [-48.919267916992183, -0.153768526367195],
            [-48.92246791699219, -0.155868526367193],
            [-48.926067916992181, -0.158168526367199],
            [-48.928967916992185, -0.159868526367198],
            [-48.932167916992185, -0.1622685263672],
            [-48.932767916992184, -0.166668526367197],
            [-48.933667916992185, -0.170368526367199],
            [-48.935467916992181, -0.174268526367193],
            [-48.936367916992182, -0.177968526367195],
            [-48.939567916992189, -0.184468526367198],
            [-48.941667916992188, -0.189068526367194],
            [-48.944367916992185, -0.194568526367199],
            [-48.945767916992182, -0.197968526367198],
            [-48.946767916992187, -0.202168526367196],
            [-48.947367916992185, -0.206768526367199],
            [-48.94516791699219, -0.209368526367193],
            [-48.942367916992183, -0.210868526367193],
            [-48.938367916992185, -0.211568526367195],
            [-48.935167916992185, -0.2125685263672],
            [-48.930567916992189, -0.213268526367195],
            [-48.926967916992183, -0.213668526367194],
            [-48.923867916992187, -0.213668526367194],
            [-48.920267916992188, -0.211468526367199],
            [-48.914967916992182, -0.208268526367199],
            [-48.908867916992186, -0.206168526367193],
            [-48.905067916992181, -0.205068526367199],
            [-48.900867916992183, -0.203168526367193],
            [-48.895767916992185, -0.202168526367196],
            [-48.890167916992183, -0.202068526367199],
            [-48.885167916992188, -0.202868526367197],
            [-48.881267916992186, -0.203268526367197],
            [-48.878467916992186, -0.202168526367196],
            [-48.875467916992186, -0.200668526367195],
            [-48.872667916992185, -0.199968526367194],
            [-48.870567916992186, -0.197868526367195],
            [-48.868567916992184, -0.195768526367196],
            [-48.865767916992183, -0.192068526367194],
            [-48.864367916992187, -0.187668526367197],
            [-48.865467916992188, -0.1836685263672],
            [-48.869167916992183, -0.183068526367194],
            [-48.874067916992189, -0.182168526367199],
            [-48.878767916992189, -0.180568526367196],
            [-48.879167916992188, -0.176968526367197],
            [-48.875967916992181, -0.176568526367198],
            [-48.872967916992181, -0.173868526367194],
            [-48.874367916992185, -0.167468526367195],
            [-48.875967916992181, -0.163168526367194],
            [-48.877767916992184, -0.159768526367195],
            [-48.880267916992182, -0.156668526367199],
            [-48.885767916992187, -0.153668526367198],
            [-48.891267916992184, -0.152368526367198],
            [-48.896467916992187, -0.151468526367196],
            [-48.901267916992182, -0.150368526367195],
            [-48.906167916992189, -0.150368526367195],
            [-48.910567916992186, -0.150168526367196]
        ]
        ],
        [
        [
            [-49.130967916992184, -0.160868526367196],
            [-49.126967916992186, -0.160368526367193],
            [-49.124067916992189, -0.1592685263672],
            [-49.121567916992184, -0.156968526367194],
            [-49.120767916992186, -0.153768526367195],
            [-49.122167916992183, -0.151168526367194],
            [-49.122267916992186, -0.145968526367199],
            [-49.12466791699218, -0.143968526367196],
            [-49.127367916992185, -0.141468526367198],
            [-49.131267916992186, -0.140368526367197],
            [-49.140767916992189, -0.138168526367195],
            [-49.144867916992183, -0.135668526367198],
            [-49.150667916992184, -0.136568526367199],
            [-49.151867916992188, -0.141368526367195],
            [-49.153667916992184, -0.145668526367196],
            [-49.15406791699219, -0.148768526367199],
            [-49.151567916992185, -0.151468526367196],
            [-49.146767916992189, -0.154468526367197],
            [-49.143267916992187, -0.157868526367196],
            [-49.139867916992188, -0.160168526367194],
            [-49.135267916992184, -0.161368526367198],
            [-49.130967916992184, -0.160868526367196]
        ]
        ],
        [
        [
            [-49.44986791699219, 0.116731473632804],
            [-49.452067916992185, 0.119831473632807],
            [-49.45456791699219, 0.122631473632801],
            [-49.45756791699219, 0.125531473632805],
            [-49.460767916992182, 0.128131473632806],
            [-49.464467916992184, 0.131331473632805],
            [-49.467267916992185, 0.134231473632802],
            [-49.46926791699218, 0.136731473632807],
            [-49.471167916992187, 0.138931473632802],
            [-49.47256791699219, 0.141931473632802],
            [-49.473667916992184, 0.145031473632805],
            [-49.47696791699218, 0.148331473632801],
            [-49.484167916992185, 0.150131473632804],
            [-49.487567916992184, 0.149331473632806],
            [-49.490867916992187, 0.145031473632805],
            [-49.493867916992187, 0.141431473632807],
            [-49.496567916992184, 0.137831473632801],
            [-49.500167916992183, 0.1350314736328],
            [-49.502367916992185, 0.132331473632803],
            [-49.505167916992185, 0.129531473632802],
            [-49.506867916992185, 0.126731473632802],
            [-49.507067916992185, 0.122931473632804],
            [-49.504967916992186, 0.119531473632804],
            [-49.502367916992185, 0.117531473632802],
            [-49.49696791699219, 0.117131473632803],
            [-49.491867916992184, 0.116231473632801],
            [-49.486167916992187, 0.115931473632806],
            [-49.482467916992185, 0.116131473632805],
            [-49.479467916992185, 0.116131473632805],
            [-49.475267916992181, 0.113931473632803],
            [-49.470867916992184, 0.112931473632806],
            [-49.466967916992189, 0.112531473632806],
            [-49.46326791699218, 0.112331473632807],
            [-49.458767916992187, 0.1121314736328],
            [-49.453567916992185, 0.112331473632807],
            [-49.449367916992188, 0.113531473632804],
            [-49.44986791699219, 0.116731473632804]
        ]
        ],
        [
        [
            [-49.626267916992184, 0.190131473632803],
            [-49.632067916992185, 0.194231473632804],
            [-49.63566791699219, 0.193931473632801],
            [-49.640767916992189, 0.192531473632805],
            [-49.646867916992186, 0.189531473632805],
            [-49.651767916992185, 0.190131473632803],
            [-49.656167916992189, 0.189131473632806],
            [-49.660667916992182, 0.187331473632803],
            [-49.671967916992187, 0.173931473632805],
            [-49.675567916992186, 0.170331473632807],
            [-49.678367916992187, 0.163131473632802],
            [-49.677467916992185, 0.155731473632805],
            [-49.671967916992187, 0.151531473632801],
            [-49.66476791699219, 0.147731473632803],
            [-49.653367916992181, 0.145031473632805],
            [-49.639267916992182, 0.148931473632807],
            [-49.631567916992182, 0.149331473632806],
            [-49.621867916992187, 0.151631473632804],
            [-49.615367916992184, 0.152931473632805],
            [-49.610267916992186, 0.159731473632803],
            [-49.605867916992182, 0.163131473632802],
            [-49.60226791699219, 0.169531473632802],
            [-49.602767916992185, 0.1761314736328],
            [-49.607467916992185, 0.182131473632801],
            [-49.615267916992181, 0.184731473632802],
            [-49.620067916992184, 0.186531473632805],
            [-49.626267916992184, 0.190131473632803]
        ]
        ],
        [
        [
            [-49.563367916992185, 0.186731473632804],
            [-49.559867916992189, 0.194531473632807],
            [-49.555567916992189, 0.201431473632802],
            [-49.554767916992184, 0.206531473632801],
            [-49.564867916992185, 0.206131473632801],
            [-49.578567916992185, 0.201931473632804],
            [-49.59126791699218, 0.196931473632802],
            [-49.60196791699218, 0.194531473632807],
            [-49.607867916992184, 0.192031473632802],
            [-49.602567916992186, 0.184831473632805],
            [-49.591967916992189, 0.182531473632807],
            [-49.583467916992184, 0.182931473632806],
            [-49.573967916992189, 0.180331473632805],
            [-49.568267916992184, 0.181131473632803],
            [-49.563367916992185, 0.186731473632804]
        ]
        ],
        [
        [
            [-49.618067916992189, 0.199231473632807],
            [-49.612467916992188, 0.202731473632802],
            [-49.607467916992185, 0.206731473632807],
            [-49.599167916992187, 0.211131473632804],
            [-49.591967916992189, 0.213931473632805],
            [-49.584267916992189, 0.215831473632804],
            [-49.576267916992187, 0.219731473632805],
            [-49.570767916992182, 0.224631473632805],
            [-49.561867916992185, 0.235731473632804],
            [-49.557767916992184, 0.243931473632806],
            [-49.56216791699218, 0.245931473632801],
            [-49.574067916992185, 0.2493314736328],
            [-49.584867916992181, 0.248431473632806],
            [-49.602067916992183, 0.242931473632801],
            [-49.613667916992185, 0.232131473632805],
            [-49.625567916992182, 0.217731473632803],
            [-49.631867916992185, 0.210331473632806],
            [-49.633767916992184, 0.2050314736328],
            [-49.632067916992185, 0.201131473632806],
            [-49.626067916992184, 0.198931473632804],
            [-49.618067916992189, 0.199231473632807]
        ]
        ],
        [
        [
            [-50.461467916992184, 2.111331473632802],
            [-50.464267916992185, 2.104931473632803],
            [-50.466167916992184, 2.102131473632802],
            [-50.468367916992186, 2.100131473632807],
            [-50.472367916992184, 2.099131473632802],
            [-50.477567916992186, 2.099631473632805],
            [-50.48166791699218, 2.100931473632805],
            [-50.485567916992181, 2.100531473632806],
            [-50.488567916992181, 2.097131473632807],
            [-50.491867916992184, 2.094131473632807],
            [-50.497667916992185, 2.092731473632803],
            [-50.504167916992188, 2.094131473632807],
            [-50.50766791699219, 2.089131473632804],
            [-50.509567916992182, 2.082731473632805],
            [-50.510167916992188, 2.077731473632802],
            [-50.510167916992188, 2.072831473632803],
            [-50.509867916992185, 2.067731473632804],
            [-50.510167916992188, 2.062531473632802],
            [-50.51066791699219, 2.058431473632801],
            [-50.51236791699219, 2.054531473632807],
            [-50.512867916992185, 2.050131473632803],
            [-50.512967916992181, 2.046131473632805],
            [-50.513567916992187, 2.042931473632805],
            [-50.515167916992183, 2.038531473632801],
            [-50.51706791699219, 2.033831473632802],
            [-50.51706791699219, 2.029331473632801],
            [-50.518467916992186, 2.025131473632804],
            [-50.519067916992185, 2.020831473632803],
            [-50.51976791699218, 2.0175314736328],
            [-50.520367916992186, 2.013131473632804],
            [-50.520367916992186, 2.009131473632806],
            [-50.521167916992184, 2.005531473632807],
            [-50.520367916992186, 2.001331473632803],
            [-50.516867916992183, 1.999531473632807],
            [-50.510167916992188, 1.999731473632806],
            [-50.505667916992181, 1.9993314736328],
            [-50.501867916992182, 1.996931473632806],
            [-50.498567916992187, 1.994731473632804],
            [-50.496067916992189, 1.990731473632806],
            [-50.494067916992186, 1.9871314736328],
            [-50.492467916992183, 1.983231473632806],
            [-50.491367916992189, 1.979631473632807],
            [-50.490767916992183, 1.975131473632807],
            [-50.491067916992186, 1.968331473632801],
            [-50.490567916992184, 1.964731473632803],
            [-50.488067916992186, 1.960831473632801],
            [-50.485267916992186, 1.957931473632804],
            [-50.482867916992184, 1.954331473632806],
            [-50.479167916992182, 1.9505314736328],
            [-50.475567916992183, 1.944131473632801],
            [-50.471767916992185, 1.937531473632802],
            [-50.469067916992188, 1.931531473632802],
            [-50.465867916992181, 1.923231473632804],
            [-50.463667916992186, 1.915931473632803],
            [-50.461467916992184, 1.907731473632801],
            [-50.458467916992184, 1.898031473632805],
            [-50.456567916992185, 1.8925314736328],
            [-50.454867916992185, 1.888531473632803],
            [-50.452367916992188, 1.884131473632806],
            [-50.448967916992189, 1.879731473632802],
            [-50.444167916992185, 1.875931473632804],
            [-50.440467916992183, 1.873431473632806],
            [-50.437267916992184, 1.871131473632801],
            [-50.433067916992186, 1.868931473632806],
            [-50.428867916992189, 1.867531473632802],
            [-50.42546791699219, 1.867131473632803],
            [-50.421467916992185, 1.867531473632802],
            [-50.41776791699219, 1.868331473632807],
            [-50.410867916992188, 1.869831473632807],
            [-50.403667916992184, 1.871931473632806],
            [-50.395767916992185, 1.874531473632807],
            [-50.386267916992182, 1.877731473632807],
            [-50.37966791699219, 1.881131473632806],
            [-50.374367916992185, 1.884131473632806],
            [-50.369767916992188, 1.887131473632806],
            [-50.366967916992181, 1.8895314736328],
            [-50.363567916992181, 1.893131473632806],
            [-50.358367916992187, 1.8972314736328],
            [-50.353367916992184, 1.902131473632807],
            [-50.348067916992186, 1.908031473632803],
            [-50.341767916992183, 1.915131473632805],
            [-50.331767916992185, 1.9261314736328],
            [-50.329367916992183, 1.929031473632804],
            [-50.325667916992188, 1.934731473632802],
            [-50.323867916992185, 1.937931473632801],
            [-50.321367916992187, 1.940931473632801],
            [-50.317767916992182, 1.946731473632802],
            [-50.317367916992183, 1.949731473632802],
            [-50.316967916992184, 1.954531473632805],
            [-50.31716791699219, 1.959131473632802],
            [-50.318467916992184, 1.964931473632802],
            [-50.32016791699219, 1.970931473632803],
            [-50.32186791699219, 1.975231473632803],
            [-50.324267916992184, 1.978731473632806],
            [-50.326267916992187, 1.981831473632802],
            [-50.328567916992185, 1.984931473632805],
            [-50.331767916992185, 1.9871314736328],
            [-50.333967916992187, 1.989731473632801],
            [-50.338467916992187, 1.993131473632801],
            [-50.340367916992186, 1.996731473632806],
            [-50.345067916992186, 1.996231473632804],
            [-50.347667916992187, 2.001131473632803],
            [-50.348367916992188, 2.004931473632801],
            [-50.350367916992184, 2.009731473632804],
            [-50.35096791699219, 2.013131473632804],
            [-50.354167916992182, 2.016131473632804],
            [-50.356767916992183, 2.017731473632807],
            [-50.360367916992189, 2.018931473632804],
            [-50.362467916992188, 2.022131473632804],
            [-50.362167916992185, 2.025331473632804],
            [-50.360667916992185, 2.029431473632805],
            [-50.362267916992181, 2.0359314736328],
            [-50.36296791699219, 2.041731473632801],
            [-50.361867916992182, 2.0481314736328],
            [-50.361967916992185, 2.051331473632807],
            [-50.360867916992184, 2.054331473632807],
            [-50.360767916992188, 2.057531473632807],
            [-50.362467916992188, 2.060931473632806],
            [-50.367967916992185, 2.065631473632806],
            [-50.373267916992184, 2.070331473632805],
            [-50.379067916992184, 2.069231473632804],
            [-50.383467916992188, 2.071331473632803],
            [-50.383567916992185, 2.075031473632805],
            [-50.383867916992187, 2.0800314736328],
            [-50.386067916992189, 2.083331473632803],
            [-50.388167916992188, 2.086131473632804],
            [-50.389567916992185, 2.088931473632805],
            [-50.392367916992185, 2.089731473632803],
            [-50.39476791699218, 2.092131473632804],
            [-50.39506791699219, 2.095131473632804],
            [-50.396167916992184, 2.100131473632807],
            [-50.398667916992181, 2.105131473632802],
            [-50.401967916992184, 2.107731473632803],
            [-50.405367916992184, 2.111331473632802],
            [-50.409867916992184, 2.111831473632805],
            [-50.416367916992186, 2.111731473632801],
            [-50.421167916992189, 2.110431473632801],
            [-50.429667916992187, 2.111831473632805],
            [-50.437767916992186, 2.112531473632806],
            [-50.442467916992186, 2.109631473632803],
            [-50.447067916992182, 2.109331473632807],
            [-50.451267916992187, 2.109631473632803],
            [-50.456267916992189, 2.112631473632803],
            [-50.461467916992184, 2.111331473632802]
        ]
        ],
        [
        [
            [-50.461467916992184, 2.111331473632802],
            [-50.459267916992189, 2.114731473632801],
            [-50.454967916992189, 2.115331473632807],
            [-50.448467916992186, 2.112931473632806],
            [-50.442767916992182, 2.116131473632805],
            [-50.436267916992186, 2.117531473632802],
            [-50.431067916992184, 2.117531473632802],
            [-50.426467916992181, 2.117531473632802],
            [-50.421867916992184, 2.119531473632804],
            [-50.419967916992185, 2.122631473632801],
            [-50.41916791699218, 2.127031473632805],
            [-50.419267916992183, 2.131531473632805],
            [-50.420367916992184, 2.134231473632802],
            [-50.421867916992184, 2.137831473632801],
            [-50.42246791699219, 2.142931473632807],
            [-50.424167916992189, 2.145831473632803],
            [-50.427467916992185, 2.147731473632803],
            [-50.429967916992183, 2.150131473632804],
            [-50.430867916992185, 2.153931473632802],
            [-50.432167916992185, 2.157331473632802],
            [-50.435167916992185, 2.159731473632803],
            [-50.438467916992181, 2.161031473632804],
            [-50.440567916992187, 2.164631473632802],
            [-50.443067916992185, 2.167731473632806],
            [-50.445767916992182, 2.166331473632802],
            [-50.450667916992188, 2.166731473632801],
            [-50.45286791699219, 2.168931473632803],
            [-50.456267916992189, 2.171531473632804],
            [-50.458267916992185, 2.174031473632802],
            [-50.458967916992187, 2.176831473632802],
            [-50.463967916992189, 2.182931473632806],
            [-50.467667916992184, 2.183731473632804],
            [-50.473367916992188, 2.182931473632806],
            [-50.477467916992182, 2.181231473632806],
            [-50.480667916992189, 2.181231473632806],
            [-50.483667916992189, 2.183731473632804],
            [-50.488267916992186, 2.184331473632803],
            [-50.491167916992183, 2.182931473632806],
            [-50.494367916992189, 2.182631473632803],
            [-50.498267916992184, 2.183331473632805],
            [-50.501567916992187, 2.183131473632805],
            [-50.504567916992187, 2.183731473632804],
            [-50.507767916992186, 2.184831473632805],
            [-50.51236791699219, 2.1853314736328],
            [-50.515767916992189, 2.184331473632803],
            [-50.519867916992183, 2.183731473632804],
            [-50.523667916992181, 2.180931473632803],
            [-50.528067916992185, 2.176731473632806],
            [-50.529467916992189, 2.173231473632804],
            [-50.532367916992186, 2.170431473632803],
            [-50.537067916992186, 2.167131473632807],
            [-50.540267916992185, 2.165131473632805],
            [-50.543867916992184, 2.162131473632805],
            [-50.546467916992185, 2.158831473632802],
            [-50.548367916992184, 2.155931473632805],
            [-50.549267916992186, 2.153031473632801],
            [-50.551467916992181, 2.150831473632806],
            [-50.548667916992187, 2.149331473632806],
            [-50.545367916992184, 2.149931473632805],
            [-50.541767916992185, 2.1487314736328],
            [-50.538067916992183, 2.1457314736328],
            [-50.534167916992189, 2.142931473632807],
            [-50.53176791699218, 2.140731473632805],
            [-50.529467916992189, 2.138331473632803],
            [-50.526967916992184, 2.134131473632806],
            [-50.525067916992185, 2.129231473632807],
            [-50.522967916992187, 2.124531473632807],
            [-50.520367916992186, 2.121731473632806],
            [-50.517667916992181, 2.116131473632805],
            [-50.51406791699219, 2.108131473632803],
            [-50.511267916992182, 2.103531473632806],
            [-50.508467916992188, 2.100731473632806],
            [-50.503567916992182, 2.098131473632805],
            [-50.49966791699218, 2.0969314736328],
            [-50.495467916992183, 2.096331473632802],
            [-50.492467916992183, 2.097731473632805],
            [-50.488667916992185, 2.102431473632805],
            [-50.486167916992187, 2.104531473632804],
            [-50.482267916992186, 2.104331473632804],
            [-50.479467916992185, 2.103531473632806],
            [-50.475567916992183, 2.101931473632803],
            [-50.472067916992188, 2.102131473632802],
            [-50.468967916992185, 2.103931473632805],
            [-50.466667916992186, 2.106531473632806],
            [-50.461467916992184, 2.111331473632802]
        ]
        ],
        [
        [
            [-48.010267916992184, -0.686168526367197],
            [-48.015967916992182, -0.686868526367199],
            [-48.020667916992181, -0.685768526367198],
            [-48.022567916992188, -0.682968526367198],
            [-48.022567916992188, -0.679368526367199],
            [-48.022567916992188, -0.674968526367195],
            [-48.023667916992181, -0.671068526367193],
            [-48.026467916992189, -0.668068526367193],
            [-48.029767916992185, -0.6667685263672],
            [-48.033167916992184, -0.664568526367198],
            [-48.036967916992182, -0.663168526367194],
            [-48.040267916992185, -0.663168526367194],
            [-48.043867916992184, -0.661968526367197],
            [-48.048267916992188, -0.659568526367195],
            [-48.052967916992188, -0.660368526367193],
            [-48.057167916992185, -0.663968526367199],
            [-48.060167916992185, -0.667468526367195],
            [-48.062667916992183, -0.669168526367194],
            [-48.067367916992183, -0.673368526367199],
            [-48.075967916992184, -0.684368526367194],
            [-48.081767916992185, -0.691068526367197],
            [-48.087967916992184, -0.697968526367198],
            [-48.10526791699219, -0.716468526367194],
            [-48.133867916992187, -0.745268526367198],
            [-48.141567916992187, -0.754168526367195],
            [-48.147367916992181, -0.760968526367193],
            [-48.155167916992184, -0.770168526367193],
            [-48.158367916992184, -0.774068526367195],
            [-48.161267916992188, -0.779768526367199],
            [-48.166367916992186, -0.792868526367194],
            [-48.16646791699219, -0.799168526367197],
            [-48.16646791699219, -0.802568526367196],
            [-48.16646791699219, -0.807168526367199],
            [-48.166967916992185, -0.810468526367195],
            [-48.169267916992183, -0.814368526367197],
            [-48.171867916992184, -0.816668526367195],
            [-48.175267916992183, -0.819368526367199],
            [-48.177767916992181, -0.820968526367196],
            [-48.18116791699218, -0.821368526367195],
            [-48.185267916992188, -0.820768526367196],
            [-48.188767916992184, -0.819968526367198],
            [-48.191667916992188, -0.819568526367199],
            [-48.196067916992185, -0.819068526367197],
            [-48.203167916992186, -0.819268526367196],
            [-48.206167916992186, -0.819868526367195],
            [-48.209867916992181, -0.820268526367194],
            [-48.212867916992181, -0.820968526367196],
            [-48.218367916992186, -0.822068526367197],
            [-48.221167916992187, -0.822868526367195],
            [-48.223767916992188, -0.824368526367195],
            [-48.227067916992183, -0.826268526367194],
            [-48.229467916992185, -0.827868526367197],
            [-48.231767916992183, -0.830468526367198],
            [-48.234467916992187, -0.833568526367195],
            [-48.236867916992182, -0.8375685263672],
            [-48.238567916992181, -0.841168526367198],
            [-48.241867916992184, -0.849968526367199],
            [-48.242967916992185, -0.853568526367198],
            [-48.245467916992183, -0.861168526367194],
            [-48.249367916992185, -0.871668526367195],
            [-48.250267916992186, -0.874468526367195],
            [-48.25136791699218, -0.877768526367198],
            [-48.253767916992189, -0.881868526367199],
            [-48.257767916992186, -0.888268526367199],
            [-48.268467916992186, -0.902868526367193],
            [-48.280967916992182, -0.913068526367198],
            [-48.293667916992185, -0.919668526367197],
            [-48.31416791699219, -0.920768526367198],
            [-48.33656791699218, -0.921368526367196],
            [-48.365767916992183, -0.918868526367199],
            [-48.39476791699218, -0.910968526367199],
            [-48.409467916992185, -0.905668526367194],
            [-48.423867916992187, -0.900468526367199],
            [-48.454667916992186, -0.886568526367199],
            [-48.474567916992186, -0.873868526367197],
            [-48.491567916992182, -0.848168526367196],
            [-48.500967916992181, -0.828968526367198],
            [-48.505967916992184, -0.810468526367195],
            [-48.506667916992185, -0.805868526367199],
            [-48.50936791699219, -0.788168526367194],
            [-48.508467916992188, -0.773968526367199],
            [-48.509967916992181, -0.769868526367198],
            [-48.511267916992182, -0.765968526367196],
            [-48.511067916992189, -0.762968526367196],
            [-48.51206791699218, -0.759968526367196],
            [-48.511067916992189, -0.756868526367199],
            [-48.508867916992187, -0.754068526367199],
            [-48.507167916992188, -0.750268526367194],
            [-48.50166791699219, -0.744768526367196],
            [-48.497667916992185, -0.744168526367197],
            [-48.493567916992184, -0.743268526367196],
            [-48.491367916992189, -0.741068526367194],
            [-48.489967916992185, -0.737768526367198],
            [-48.48796791699219, -0.733868526367196],
            [-48.487167916992185, -0.7292685263672],
            [-48.486467916992183, -0.725968526367197],
            [-48.486367916992187, -0.722568526367198],
            [-48.486367916992187, -0.719268526367195],
            [-48.486167916992187, -0.714568526367195],
            [-48.48496791699219, -0.708168526367196],
            [-48.483567916992186, -0.704368526367197],
            [-48.483167916992187, -0.700168526367193],
            [-48.482167916992182, -0.694968526367198],
            [-48.482167916992182, -0.690468526367198],
            [-48.48166791699218, -0.687268526367198],
            [-48.479667916992184, -0.682268526367196],
            [-48.478667916992187, -0.678268526367198],
            [-48.47896791699219, -0.675068526367198],
            [-48.479767916992188, -0.671068526367193],
            [-48.479767916992188, -0.6667685263672],
            [-48.479267916992185, -0.663668526367196],
            [-48.479167916992182, -0.660568526367193],
            [-48.478167916992184, -0.657868526367196],
            [-48.476667916992184, -0.6530685263672],
            [-48.474967916992185, -0.648768526367199],
            [-48.473067916992186, -0.644268526367199],
            [-48.47256791699219, -0.640068526367195],
            [-48.473067916992186, -0.636768526367199],
            [-48.474467916992182, -0.633068526367197],
            [-48.47396791699218, -0.628368526367197],
            [-48.472367916992184, -0.622168526367197],
            [-48.471367916992186, -0.617268526367198],
            [-48.470567916992181, -0.613368526367196],
            [-48.471467916992182, -0.610368526367196],
            [-48.474167916992187, -0.607768526367195],
            [-48.47726791699219, -0.605268526367198],
            [-48.477367916992186, -0.601668526367199],
            [-48.47596791699219, -0.597668526367194],
            [-48.475267916992181, -0.594568526367198],
            [-48.474767916992185, -0.590868526367196],
            [-48.474967916992185, -0.587368526367193],
            [-48.476367916992189, -0.583968526367194],
            [-48.47726791699219, -0.580168526367196],
            [-48.47596791699219, -0.574568526367194],
            [-48.475067916992188, -0.570168526367198],
            [-48.474767916992185, -0.565268526367198],
            [-48.474567916992186, -0.560468526367195],
            [-48.474767916992185, -0.555868526367199],
            [-48.475267916992181, -0.551868526367194],
            [-48.475067916992188, -0.548268526367195],
            [-48.474567916992186, -0.544668526367197],
            [-48.47426791699219, -0.539568526367198],
            [-48.473767916992188, -0.533368526367198],
            [-48.473367916992188, -0.525468526367199],
            [-48.47226791699218, -0.521468526367194],
            [-48.47096791699218, -0.517068526367197],
            [-48.470667916992184, -0.512968526367196],
            [-48.470567916992181, -0.507968526367193],
            [-48.469467916992187, -0.504868526367197],
            [-48.467667916992184, -0.500768526367196],
            [-48.466167916992184, -0.497168526367197],
            [-48.464467916992184, -0.493068526367196],
            [-48.46326791699218, -0.489668526367197],
            [-48.462067916992183, -0.486368526367194],
            [-48.460667916992186, -0.482568526367196],
            [-48.459667916992188, -0.479668526367199],
            [-48.458667916992184, -0.476368526367196],
            [-48.456467916992182, -0.469768526367197],
            [-48.455067916992185, -0.465868526367196],
            [-48.454067916992187, -0.460868526367193],
            [-48.453967916992184, -0.456768526367199],
            [-48.452167916992181, -0.450668526367195],
            [-48.450967916992184, -0.446268526367199],
            [-48.450967916992184, -0.442768526367196],
            [-48.45156791699219, -0.438068526367196],
            [-48.451267916992187, -0.433868526367199],
            [-48.449367916992188, -0.427868526367199],
            [-48.446367916992187, -0.421368526367196],
            [-48.444067916992182, -0.416668526367197],
            [-48.441067916992182, -0.412068526367193],
            [-48.435767916992184, -0.406568526367195],
            [-48.428667916992183, -0.400168526367196],
            [-48.423967916992183, -0.395368526367193],
            [-48.41746791699218, -0.388168526367195],
            [-48.413667916992182, -0.384068526367194],
            [-48.407367916992186, -0.374468526367195],
            [-48.404167916992186, -0.370768526367193],
            [-48.400067916992185, -0.366868526367199],
            [-48.397367916992181, -0.363668526367199],
            [-48.395367916992186, -0.361068526367198],
            [-48.393267916992187, -0.357768526367195],
            [-48.39036791699219, -0.354968526367195],
            [-48.388167916992188, -0.352568526367193],
            [-48.38706791699218, -0.348368526367196],
            [-48.384867916992185, -0.344768526367197],
            [-48.383767916992184, -0.340868526367196],
            [-48.38236791699218, -0.336568526367195],
            [-48.380667916992181, -0.332668526367193],
            [-48.379167916992188, -0.328968526367198],
            [-48.378067916992187, -0.325468526367196],
            [-48.377067916992189, -0.320968526367196],
            [-48.376567916992187, -0.317168526367198],
            [-48.375767916992189, -0.312768526367194],
            [-48.375267916992186, -0.309368526367194],
            [-48.374867916992187, -0.305868526367199],
            [-48.374467916992188, -0.302968526367195],
            [-48.374467916992188, -0.299668526367199],
            [-48.375267916992186, -0.295568526367198],
            [-48.375767916992189, -0.291368526367194],
            [-48.377067916992189, -0.286168526367199],
            [-48.378467916992186, -0.282268526367197],
            [-48.37966791699219, -0.279468526367197],
            [-48.381767916992189, -0.275468526367199],
            [-48.38406791699218, -0.2720685263672],
            [-48.386267916992182, -0.268568526367197],
            [-48.388567916992187, -0.264868526367195],
            [-48.390767916992189, -0.261768526367199],
            [-48.392667916992181, -0.259368526367197],
            [-48.394567916992187, -0.256568526367197],
            [-48.396167916992184, -0.252668526367195],
            [-48.400467916992184, -0.249368526367199],
            [-48.404267916992183, -0.247168526367197],
            [-48.40846791699218, -0.244468526367193],
            [-48.412867916992184, -0.243068526367196],
            [-48.415667916992184, -0.241868526367199],
            [-48.418867916992184, -0.240568526367198],
            [-48.42246791699219, -0.237968526367197],
            [-48.423067916992181, -0.234668526367194],
            [-48.424167916992189, -0.230868526367196],
            [-48.427567916992189, -0.227568526367193],
            [-48.432167916992185, -0.227068526367198],
            [-48.436567916992189, -0.225668526367194],
            [-48.440167916992181, -0.225368526367198],
            [-48.443267916992184, -0.2262685263672],
            [-48.446667916992183, -0.228468526367195],
            [-48.449067916992185, -0.230968526367199],
            [-48.453767916992184, -0.231768526367198],
            [-48.457867916992186, -0.231768526367198],
            [-48.460967916992189, -0.231068526367196],
            [-48.463767916992182, -0.231668526367194],
            [-48.468167916992186, -0.231668526367194],
            [-48.471367916992186, -0.230668526367197],
            [-48.475667916992187, -0.231168526367199],
            [-48.47996791699218, -0.232568526367196],
            [-48.482867916992184, -0.2324685263672],
            [-48.485867916992184, -0.232068526367193],
            [-48.48966791699219, -0.233668526367197],
            [-48.491967916992181, -0.235368526367196],
            [-48.494967916992181, -0.236868526367196],
            [-48.499367916992185, -0.239068526367198],
            [-48.504167916992188, -0.242268526367198],
            [-48.508067916992189, -0.241168526367197],
            [-48.510767916992187, -0.2399685263672],
            [-48.515467916992186, -0.238268526367193],
            [-48.521867916992186, -0.237468526367195],
            [-48.526467916992189, -0.238668526367199],
            [-48.529567916992185, -0.238868526367199],
            [-48.533067916992181, -0.238668526367199],
            [-48.537767916992181, -0.239068526367198],
            [-48.543067916992186, -0.240068526367196],
            [-48.54746791699219, -0.238568526367196],
            [-48.553567916992186, -0.238268526367193],
            [-48.557767916992184, -0.239668526367197],
            [-48.560767916992184, -0.238668526367199],
            [-48.564667916992185, -0.237868526367194],
            [-48.567767916992182, -0.236868526367196],
            [-48.572067916992182, -0.235568526367196],
            [-48.576767916992182, -0.234968526367197],
            [-48.583667916992184, -0.2339685263672],
            [-48.589567916992181, -0.233368526367194],
            [-48.594867916992186, -0.233668526367197],
            [-48.60096791699219, -0.235368526367196],
            [-48.605867916992182, -0.235368526367196],
            [-48.609767916992183, -0.233568526367193],
            [-48.615267916992181, -0.232268526367193],
            [-48.620567916992186, -0.231668526367194],
            [-48.624467916992188, -0.231368526367199],
            [-48.629067916992184, -0.231368526367199],
            [-48.634567916992182, -0.231668526367194],
            [-48.637767916992189, -0.232168526367197],
            [-48.641067916992185, -0.233068526367198],
            [-48.643767916992189, -0.234268526367195],
            [-48.647167916992188, -0.237168526367199],
            [-48.651567916992185, -0.240068526367196],
            [-48.654567916992185, -0.239768526367193],
            [-48.657867916992188, -0.239468526367197],
            [-48.66176791699219, -0.240068526367196],
            [-48.665367916992182, -0.240568526367198],
            [-48.668867916992184, -0.240468526367195],
            [-48.67216791699218, -0.241068526367194],
            [-48.676567916992184, -0.242268526367198],
            [-48.682167916992185, -0.243068526367196],
            [-48.685167916992185, -0.243668526367195],
            [-48.688567916992184, -0.245268526367198],
            [-48.693767916992186, -0.244968526367195],
            [-48.697467916992181, -0.245568526367194],
            [-48.702367916992188, -0.247268526367193],
            [-48.70726791699218, -0.249668526367195],
            [-48.710867916992186, -0.251368526367195],
            [-48.714867916992183, -0.251668526367197],
            [-48.719467916992187, -0.251068526367199],
            [-48.725367916992184, -0.251068526367199],
            [-48.734767916992183, -0.251068526367199],
            [-48.73966791699219, -0.250468526367193],
            [-48.74366791699218, -0.249868526367194],
            [-48.752767916992184, -0.245268526367198],
            [-48.758867916992187, -0.241868526367199],
            [-48.763567916992187, -0.239668526367197],
            [-48.76976791699218, -0.238568526367196],
            [-48.775067916992185, -0.237568526367198],
            [-48.778067916992185, -0.234968526367197],
            [-48.782367916992186, -0.229168526367197],
            [-48.78506791699219, -0.226468526367199],
            [-48.788167916992187, -0.224468526367197],
            [-48.794967916992185, -0.222068526367195],
            [-48.798567916992184, -0.221968526367199],
            [-48.802267916992186, -0.219768526367197],
            [-48.805567916992189, -0.217268526367199],
            [-48.80946791699219, -0.215068526367197],
            [-48.815467916992183, -0.215668526367196],
            [-48.82016791699219, -0.216268526367195],
            [-48.82486791699219, -0.217268526367199],
            [-48.82786791699219, -0.219468526367194],
            [-48.833267916992185, -0.222268526367195],
            [-48.842567916992181, -0.222068526367195],
            [-48.845567916992181, -0.2217685263672],
            [-48.857267916992186, -0.221668526367196],
            [-48.86466791699219, -0.222668526367194],
            [-48.890467916992186, -0.2217685263672],
            [-48.899467916992187, -0.222068526367195],
            [-48.902667916992186, -0.222368526367198],
            [-48.905667916992186, -0.223468526367199],
            [-48.909267916992185, -0.225568526367198],
            [-48.912567916992188, -0.228068526367196],
            [-48.916767916992185, -0.229968526367195],
            [-48.921167916992189, -0.228868526367194],
            [-48.930267916992186, -0.226968526367195],
            [-48.935767916992184, -0.2262685263672],
            [-48.940567916992187, -0.225268526367195],
            [-48.944067916992182, -0.223668526367199],
            [-48.94816791699219, -0.221368526367193],
            [-48.951767916992182, -0.218168526367194],
            [-48.955067916992185, -0.212968526367199],
            [-48.958467916992184, -0.208968526367194],
            [-48.964867916992183, -0.203268526367197],
            [-48.971467916992182, -0.199668526367198],
            [-48.975067916992188, -0.198468526367193],
            [-48.978167916992184, -0.196268526367199],
            [-48.984167916992185, -0.192668526367193],
            [-48.988267916992186, -0.189868526367199],
            [-48.991867916992184, -0.187168526367195],
            [-48.994767916992188, -0.1866685263672],
            [-48.997967916992181, -0.185268526367196],
            [-49.002967916992183, -0.179668526367195],
            [-49.008567916992185, -0.171868526367199],
            [-49.010767916992187, -0.168568526367196],
            [-49.010767916992187, -0.161768526367197],
            [-49.013467916992184, -0.159868526367198],
            [-49.017967916992184, -0.160368526367193],
            [-49.021467916992187, -0.161468526367194],
            [-49.024467916992187, -0.163068526367198],
            [-49.028967916992187, -0.1637685263672],
            [-49.031967916992187, -0.164268526367195],
            [-49.035867916992188, -0.165668526367199],
            [-49.03846791699219, -0.168868526367199],
            [-49.043367916992182, -0.168568526367196],
            [-49.046767916992181, -0.166668526367197],
            [-49.051467916992181, -0.164568526367198],
            [-49.055767916992181, -0.163168526367194],
            [-49.060167916992185, -0.162068526367193],
            [-49.063267916992189, -0.161368526367198],
            [-49.067067916992187, -0.161168526367199],
            [-49.07156791699218, -0.161668526367194],
            [-49.075667916992188, -0.161668526367194],
            [-49.079267916992187, -0.162768526367195],
            [-49.082067916992187, -0.163668526367196],
            [-49.084767916992185, -0.1652685263672],
            [-49.087867916992181, -0.166368526367194],
            [-49.090567916992185, -0.164268526367195],
            [-49.093667916992189, -0.163168526367194],
            [-49.097367916992184, -0.163468526367197],
            [-49.100067916992188, -0.165568526367196],
            [-49.103367916992184, -0.163668526367196],
            [-49.108067916992184, -0.161668526367194],
            [-49.113267916992186, -0.160568526367193],
            [-49.116467916992185, -0.161368526367198],
            [-49.12196791699219, -0.163968526367199],
            [-49.125267916992186, -0.166668526367197],
            [-49.125967916992181, -0.171068526367193],
            [-49.12966791699219, -0.171068526367193],
            [-49.133467916992188, -0.169568526367193],
            [-49.136767916992184, -0.168168526367197],
            [-49.139267916992182, -0.166468526367197],
            [-49.142867916992188, -0.163168526367194],
            [-49.147367916992181, -0.159768526367195],
            [-49.152267916992187, -0.158468526367194],
            [-49.156567916992188, -0.156468526367199],
            [-49.157367916992186, -0.151968526367199],
            [-49.158867916992186, -0.149268526367194],
            [-49.161967916992182, -0.147868526367198],
            [-49.164167916992184, -0.144768526367194],
            [-49.163867916992189, -0.141568526367195],
            [-49.163067916992183, -0.137468526367194],
            [-49.16346791699219, -0.132968526367193],
            [-49.165567916992188, -0.129568526367194],
            [-49.168967916992187, -0.129068526367199],
            [-49.171967916992187, -0.130268526367196],
            [-49.174967916992188, -0.131768526367196],
            [-49.176167916992185, -0.134668526367193],
            [-49.176867916992187, -0.138768526367194],
            [-49.180767916992181, -0.138968526367194],
            [-49.185567916992184, -0.136268526367196],
            [-49.190567916992187, -0.133468526367196],
            [-49.19516791699219, -0.131268526367194],
            [-49.199267916992184, -0.133068526367197],
            [-49.203267916992189, -0.135368526367195],
            [-49.206867916992181, -0.137968526367196],
            [-49.207967916992189, -0.142068526367197],
            [-49.210167916992184, -0.145368526367193],
            [-49.213967916992189, -0.148968526367199],
            [-49.217667916992184, -0.147268526367199],
            [-49.220867916992184, -0.148768526367199],
            [-49.223767916992188, -0.151168526367194],
            [-49.228067916992188, -0.153168526367196],
            [-49.231067916992188, -0.154468526367197],
            [-49.233567916992186, -0.155868526367193],
            [-49.236867916992182, -0.156968526367194],
            [-49.242167916992187, -0.159468526367199],
            [-49.245867916992182, -0.161768526367197],
            [-49.246567916992184, -0.164968526367197],
            [-49.250467916992186, -0.164568526367198],
            [-49.252367916992185, -0.161968526367197],
            [-49.254967916992186, -0.160168526367194],
            [-49.259567916992182, -0.1592685263672],
            [-49.263267916992184, -0.158768526367197],
            [-49.266767916992187, -0.159168526367196],
            [-49.270767916992185, -0.158368526367198],
            [-49.27406791699218, -0.158068526367195],
            [-49.277367916992183, -0.157868526367196],
            [-49.281667916992184, -0.157868526367196],
            [-49.285867916992188, -0.157568526367193],
            [-49.290367916992182, -0.157268526367197],
            [-49.293967916992187, -0.157068526367198],
            [-49.297867916992189, -0.157068526367198],
            [-49.302567916992189, -0.157668526367196],
            [-49.307967916992183, -0.158168526367199],
            [-49.313067916992182, -0.158068526367195],
            [-49.31686791699218, -0.157268526367197],
            [-49.32016791699219, -0.158768526367197],
            [-49.323467916992186, -0.159768526367195],
            [-49.327367916992188, -0.158068526367195],
            [-49.331567916992185, -0.1592685263672],
            [-49.334367916992186, -0.160568526367193],
            [-49.33686791699219, -0.162368526367196],
            [-49.339767916992187, -0.164768526367197],
            [-49.342567916992181, -0.166668526367197],
            [-49.345367916992188, -0.169168526367194],
            [-49.348067916992186, -0.171868526367199],
            [-49.35096791699219, -0.173668526367194],
            [-49.356367916992184, -0.176868526367194],
            [-49.360067916992186, -0.178868526367197],
            [-49.362467916992188, -0.180768526367196],
            [-49.366067916992186, -0.181868526367197],
            [-49.370267916992184, -0.184668526367197],
            [-49.372967916992181, -0.1866685263672],
            [-49.37666791699219, -0.188568526367199],
            [-49.379567916992187, -0.191068526367197],
            [-49.383467916992188, -0.190968526367193],
            [-49.387767916992189, -0.190168526367195],
            [-49.390767916992189, -0.190568526367194],
            [-49.394067916992185, -0.190868526367197],
            [-49.400067916992185, -0.187268526367198],
            [-49.408367916992184, -0.181968526367193],
            [-49.413867916992189, -0.176368526367199],
            [-49.421467916992185, -0.168968526367195],
            [-49.427767916992181, -0.158168526367199],
            [-49.432667916992187, -0.146868526367193],
            [-49.43586791699218, -0.135668526367198],
            [-49.438567916992184, -0.126368526367195],
            [-49.437667916992183, -0.116168526367197],
            [-49.435167916992185, -0.108268526367198],
            [-49.433367916992182, -0.102068526367198],
            [-49.431967916992186, -0.098168526367196],
            [-49.431567916992186, -0.094468526367194],
            [-49.431567916992186, -0.091168526367198],
            [-49.431567916992186, -0.088168526367198],
            [-49.429667916992187, -0.085468526367194],
            [-49.426867916992187, -0.081868526367195],
            [-49.424667916992185, -0.079268526367194],
            [-49.421767916992181, -0.075968526367198],
            [-49.419667916992182, -0.0723685263672],
            [-49.41746791699218, -0.068468526367198],
            [-49.413167916992187, -0.065568526367194],
            [-49.409167916992189, -0.063368526367199],
            [-49.405467916992187, -0.062768526367194],
            [-49.402067916992181, -0.060868526367194],
            [-49.398967916992184, -0.0586685263672],
            [-49.394367916992181, -0.057568526367199],
            [-49.389967916992184, -0.058768526367196],
            [-49.386767916992184, -0.055268526367193],
            [-49.38536791699218, -0.051568526367198],
            [-49.383867916992187, -0.047568526367193],
            [-49.380967916992184, -0.043968526367195],
            [-49.378567916992182, -0.041168526367194],
            [-49.376967916992186, -0.038168526367194],
            [-49.375567916992182, -0.033368526367198],
            [-49.372467916992186, -0.031968526367194],
            [-49.368367916992185, -0.032268526367197],
            [-49.36466791699219, -0.030568526367198],
            [-49.362467916992188, -0.028768526367195],
            [-49.359467916992187, -0.026468526367196],
            [-49.35696791699219, -0.022568526367195],
            [-49.355567916992186, -0.018268526367194],
            [-49.355067916992184, -0.015468526367194],
            [-49.354467916992185, -0.012468526367194],
            [-49.354267916992185, -0.009568526367197],
            [-49.35496791699218, -0.006368526367197],
            [-49.355867916992182, -0.002668526367195],
            [-49.35696791699219, 0.002031473632805],
            [-49.357767916992188, 0.004931473632801],
            [-49.358667916992189, 0.009131473632806],
            [-49.361767916992186, 0.013931473632802],
            [-49.365767916992183, 0.016331473632803],
            [-49.369367916992189, 0.018131473632806],
            [-49.371567916992184, 0.023031473632805],
            [-49.367267916992184, 0.026331473632801],
            [-49.366067916992186, 0.029431473632805],
            [-49.36766791699219, 0.033531473632806],
            [-49.369467916992186, 0.037931473632803],
            [-49.372367916992189, 0.041531473632801],
            [-49.374867916992187, 0.044531473632802],
            [-49.377367916992185, 0.046531473632804],
            [-49.380767916992184, 0.0481314736328],
            [-49.384267916992187, 0.050331473632802],
            [-49.387667916992186, 0.053731473632801],
            [-49.390667916992186, 0.057131473632801],
            [-49.392667916992181, 0.059731473632802],
            [-49.395667916992181, 0.061731473632804],
            [-49.399767916992189, 0.062331473632803],
            [-49.402667916992186, 0.062331473632803],
            [-49.406167916992189, 0.062731473632802],
            [-49.409567916992188, 0.064531473632805],
            [-49.412467916992185, 0.0663314736328],
            [-49.415367916992182, 0.067331473632805],
            [-49.419267916992183, 0.068731473632802],
            [-49.423567916992184, 0.070131473632806],
            [-49.426167916992185, 0.071331473632803],
            [-49.428967916992185, 0.072131473632801],
            [-49.431967916992186, 0.072831473632803],
            [-49.435567916992184, 0.073931473632804],
            [-49.439867916992185, 0.074531473632803],
            [-49.44516791699219, 0.074131473632804],
            [-49.450667916992188, 0.073331473632805],
            [-49.456767916992185, 0.074231473632807],
            [-49.461167916992181, 0.075731473632807],
            [-49.464867916992183, 0.076931473632804],
            [-49.468667916992189, 0.0785314736328],
            [-49.472367916992184, 0.079331473632806],
            [-49.47596791699219, 0.080531473632803],
            [-49.480067916992184, 0.081331473632801],
            [-49.484467916992187, 0.081131473632801],
            [-49.487467916992188, 0.081131473632801],
            [-49.490567916992184, 0.080831473632806],
            [-49.493867916992187, 0.081731473632807],
            [-49.497367916992189, 0.081931473632807],
            [-49.500467916992186, 0.082231473632802],
            [-49.504967916992186, 0.082231473632802],
            [-49.510967916992186, 0.081331473632801],
            [-49.51506791699218, 0.081331473632801],
            [-49.518167916992184, 0.081731473632807],
            [-49.521467916992187, 0.081331473632801],
            [-49.525467916992184, 0.081731473632807],
            [-49.531667916992184, 0.081331473632801],
            [-49.535367916992186, 0.081731473632807],
            [-49.540067916992186, 0.082231473632802],
            [-49.544267916992183, 0.083531473632803],
            [-49.54746791699219, 0.085131473632806],
            [-49.552467916992185, 0.085131473632806],
            [-49.558667916992185, 0.085331473632806],
            [-49.56416791699219, 0.085531473632805],
            [-49.570267916992186, 0.086531473632803],
            [-49.575667916992188, 0.090531473632801],
            [-49.581267916992189, 0.089931473632802],
            [-49.586767916992187, 0.088031473632803],
            [-49.590567916992185, 0.085831473632801],
            [-49.594467916992187, 0.084431473632804],
            [-49.598167916992182, 0.083331473632803],
            [-49.601367916992189, 0.081731473632807],
            [-49.603867916992186, 0.0800314736328],
            [-49.60826791699219, 0.078131473632801],
            [-49.614167916992187, 0.075331473632801],
            [-49.617467916992183, 0.074231473632807],
            [-49.62336791699218, 0.074131473632804],
            [-49.632167916992188, 0.074931473632802],
            [-49.641067916992185, 0.078931473632807],
            [-49.647367916992181, 0.082731473632805],
            [-49.650667916992184, 0.084431473632804],
            [-49.656167916992189, 0.088731473632805],
            [-49.665567916992188, 0.096631473632804],
            [-49.674767916992181, 0.106831473632802],
            [-49.682467916992181, 0.117131473632803],
            [-49.689467916992186, 0.126931473632801],
            [-49.695667916992186, 0.139131473632801],
            [-49.69956791699218, 0.150531473632803],
            [-49.69986791699219, 0.158031473632803],
            [-49.698567916992189, 0.162331473632804],
            [-49.696367916992187, 0.166831473632804],
            [-49.69516791699219, 0.171131473632805],
            [-49.69386791699219, 0.175431473632806],
            [-49.692667916992185, 0.178131473632803],
            [-49.690467916992183, 0.180731473632804],
            [-49.688367916992185, 0.183131473632805],
            [-49.685467916992181, 0.185531473632807],
            [-49.682267916992188, 0.188431473632804],
            [-49.679667916992187, 0.191531473632807],
            [-49.676367916992184, 0.194231473632804],
            [-49.67246791699219, 0.198731473632805],
            [-49.668167916992189, 0.202131473632804],
            [-49.663867916992189, 0.204531473632805],
            [-49.660667916992182, 0.206131473632801],
            [-49.65706791699219, 0.207931473632804],
            [-49.653667916992184, 0.210731473632805],
            [-49.650467916992184, 0.212531473632801],
            [-49.647867916992183, 0.213931473632805],
            [-49.644867916992183, 0.216131473632807],
            [-49.643167916992184, 0.220231473632801],
            [-49.641767916992187, 0.224331473632802],
            [-49.64006791699218, 0.228731473632806],
            [-49.637467916992186, 0.233231473632806],
            [-49.635167916992188, 0.236831473632805],
            [-49.633267916992182, 0.241231473632801],
            [-49.629067916992184, 0.243731473632806],
            [-49.623567916992187, 0.245731473632802],
            [-49.619167916992183, 0.247931473632804],
            [-49.615267916992181, 0.250531473632805],
            [-49.612267916992181, 0.253331473632805],
            [-49.610367916992189, 0.256331473632805],
            [-49.606767916992183, 0.259931473632804],
            [-49.603467916992187, 0.262131473632806],
            [-49.598367916992188, 0.264731473632807],
            [-49.592067916992185, 0.266431473632807],
            [-49.58656791699218, 0.269431473632807],
            [-49.583667916992184, 0.272531473632803],
            [-49.580367916992188, 0.272931473632802],
            [-49.577167916992181, 0.2737314736328],
            [-49.574367916992188, 0.274431473632802],
            [-49.57156791699218, 0.275831473632806],
            [-49.56886791699219, 0.277531473632806],
            [-49.564367916992182, 0.278531473632803],
            [-49.559767916992186, 0.279431473632805],
            [-49.55516791699219, 0.280931473632805],
            [-49.551167916992185, 0.282331473632802],
            [-49.54716791699218, 0.283831473632802],
            [-49.54276791699219, 0.2859314736328],
            [-49.537867916992184, 0.288331473632802],
            [-49.533967916992182, 0.289331473632807],
            [-49.529167916992186, 0.291731473632801],
            [-49.526167916992186, 0.294131473632802],
            [-49.523467916992189, 0.298531473632806],
            [-49.520667916992181, 0.302331473632805],
            [-49.516867916992183, 0.304331473632807],
            [-49.512467916992186, 0.305931473632803],
            [-49.507467916992184, 0.308431473632801],
            [-49.503467916992186, 0.311231473632802],
            [-49.500167916992183, 0.313731473632807],
            [-49.497667916992185, 0.3163314736328],
            [-49.494967916992181, 0.318731473632802],
            [-49.492767916992186, 0.322131473632801],
            [-49.491067916992186, 0.325731473632807],
            [-49.48966791699219, 0.329931473632804],
            [-49.488567916992181, 0.334331473632801],
            [-49.488667916992185, 0.339331473632804],
            [-49.489367916992187, 0.343031473632806],
            [-49.491467916992185, 0.346631473632804],
            [-49.494367916992189, 0.350531473632806],
            [-49.496867916992187, 0.354331473632804],
            [-49.497467916992186, 0.361131473632803],
            [-49.499867916992187, 0.366931473632803],
            [-49.503067916992187, 0.371731473632806],
            [-49.50736791699218, 0.374831473632803],
            [-49.511567916992185, 0.377731473632807],
            [-49.515667916992186, 0.379731473632802],
            [-49.519067916992185, 0.382331473632803],
            [-49.522867916992183, 0.384231473632802],
            [-49.525667916992184, 0.386431473632804],
            [-49.528667916992184, 0.388131473632804],
            [-49.533067916992181, 0.389331473632801],
            [-49.536167916992184, 0.3895314736328],
            [-49.53946791699218, 0.389731473632807],
            [-49.542567916992184, 0.3895314736328],
            [-49.547767916992186, 0.390031473632803],
            [-49.549267916992186, 0.393131473632806],
            [-49.553067916992184, 0.3972314736328],
            [-49.557767916992184, 0.402331473632806],
            [-49.561067916992187, 0.405931473632805],
            [-49.56416791699219, 0.409731473632803],
            [-49.566667916992188, 0.411731473632805],
            [-49.569967916992184, 0.414531473632806],
            [-49.57316791699219, 0.416731473632801],
            [-49.576467916992186, 0.417431473632803],
            [-49.581867916992181, 0.418731473632803],
            [-49.585067916992188, 0.418231473632801],
            [-49.587867916992181, 0.414931473632805],
            [-49.589067916992185, 0.409931473632803],
            [-49.590567916992185, 0.406631473632807],
            [-49.593967916992185, 0.405931473632805],
            [-49.598167916992182, 0.405531473632806],
            [-49.602567916992186, 0.403331473632804],
            [-49.606067916992188, 0.401331473632801],
            [-49.608867916992182, 0.399131473632806],
            [-49.612167916992185, 0.396331473632806],
            [-49.615467916992188, 0.394931473632802],
            [-49.618067916992189, 0.392131473632801],
            [-49.62166791699218, 0.392131473632801],
            [-49.62466791699218, 0.393631473632801],
            [-49.628867916992185, 0.395031473632805],
            [-49.633567916992185, 0.394931473632802],
            [-49.63906791699219, 0.394431473632807],
            [-49.642367916992185, 0.393631473632801],
            [-49.646167916992184, 0.393931473632804],
            [-49.650667916992184, 0.393631473632801],
            [-49.656967916992187, 0.393931473632804],
            [-49.66146791699218, 0.393131473632806],
            [-49.665267916992185, 0.391431473632807],
            [-49.66946791699219, 0.390031473632803],
            [-49.673667916992187, 0.388531473632803],
            [-49.678067916992184, 0.386331473632801],
            [-49.682267916992188, 0.385631473632806],
            [-49.686967916992188, 0.384731473632804],
            [-49.692767916992182, 0.383731473632807],
            [-49.699267916992184, 0.3835314736328],
            [-49.705467916992184, 0.381931473632804],
            [-49.711267916992185, 0.380531473632807],
            [-49.719167916992184, 0.378431473632801],
            [-49.724567916992186, 0.377731473632807],
            [-49.731067916992188, 0.375331473632805],
            [-49.736967916992185, 0.371931473632806],
            [-49.741667916992185, 0.369031473632802],
            [-49.747467916992186, 0.365331473632807],
            [-49.752067916992189, 0.361831473632805],
            [-49.755467916992188, 0.358731473632801],
            [-49.759667916992186, 0.355331473632802],
            [-49.763267916992184, 0.351331473632804],
            [-49.766267916992184, 0.348731473632803],
            [-49.769267916992185, 0.347131473632807],
            [-49.772267916992185, 0.345131473632804],
            [-49.778167916992189, 0.341331473632806],
            [-49.78346791699218, 0.339931473632802],
            [-49.789267916992188, 0.338331473632806],
            [-49.795267916992188, 0.335531473632805],
            [-49.80216791699219, 0.333331473632803],
            [-49.807767916992184, 0.332131473632806],
            [-49.811867916992185, 0.333331473632803],
            [-49.814067916992187, 0.336131473632804],
            [-49.813467916992181, 0.343031473632806],
            [-49.81386791699218, 0.349331473632802],
            [-49.816567916992184, 0.353931473632805],
            [-49.822167916992186, 0.356331473632807],
            [-49.828267916992189, 0.355531473632801],
            [-49.833167916992181, 0.352131473632802],
            [-49.839867916992183, 0.347731473632805],
            [-49.846467916992182, 0.343731473632801],
            [-49.854467916992185, 0.339431473632807],
            [-49.861467916992183, 0.335831473632801],
            [-49.864767916992186, 0.334331473632801],
            [-49.868267916992181, 0.332931473632804],
            [-49.875167916992183, 0.329331473632806],
            [-49.881867916992185, 0.326131473632806],
            [-49.889267916992182, 0.3225314736328],
            [-49.897967916992187, 0.324231473632807],
            [-49.904767916992185, 0.323731473632805],
            [-49.914167916992184, 0.319731473632807],
            [-49.918867916992184, 0.321331473632803],
            [-49.920867916992187, 0.324931473632802],
            [-49.917267916992188, 0.329331473632806],
            [-49.914967916992182, 0.332531473632805],
            [-49.910267916992183, 0.336331473632804],
            [-49.907667916992182, 0.340131473632802],
            [-49.90976791699218, 0.344931473632805],
            [-49.913867916992189, 0.349531473632801],
            [-49.916067916992183, 0.351731473632803],
            [-49.918167916992189, 0.353731473632806],
            [-49.921867916992184, 0.357531473632804],
            [-49.925767916992186, 0.360331473632804],
            [-49.931367916992187, 0.363131473632805],
            [-49.938467916992181, 0.365331473632807],
            [-49.945967916992188, 0.365931473632806],
            [-49.95286791699219, 0.364731473632801],
            [-49.957867916992186, 0.364531473632802],
            [-49.963967916992189, 0.364031473632807],
            [-49.970567916992181, 0.363531473632804],
            [-49.975867916992186, 0.362531473632806],
            [-49.980567916992186, 0.360431473632801],
            [-49.982767916992188, 0.357331473632804],
            [-49.983967916992185, 0.3529314736328],
            [-49.985567916992181, 0.347931473632805],
            [-49.986167916992187, 0.342731473632803],
            [-49.992967916992185, 0.338531473632806],
            [-50.002467916992188, 0.337531473632801],
            [-50.014367916992185, 0.337931473632807],
            [-50.024467916992187, 0.340131473632802],
            [-50.032667916992182, 0.3407314736328],
            [-50.042167916992184, 0.340131473632802],
            [-50.051067916992181, 0.336931473632802],
            [-50.059967916992186, 0.336731473632803],
            [-50.069967916992184, 0.337931473632807],
            [-50.080667916992184, 0.339431473632807],
            [-50.088767916992182, 0.341131473632807],
            [-50.098367916992188, 0.344531473632806],
            [-50.101667916992184, 0.343531473632801],
            [-50.10826791699219, 0.341631473632802],
            [-50.119767916992188, 0.336331473632804],
            [-50.131667916992185, 0.330731473632802],
            [-50.141767916992187, 0.333131473632804],
            [-50.154567916992185, 0.336531473632803],
            [-50.168567916992188, 0.341331473632806],
            [-50.174667916992185, 0.347931473632805],
            [-50.175867916992189, 0.354931473632803],
            [-50.176967916992183, 0.363531473632804],
            [-50.177567916992189, 0.375631473632801],
            [-50.176367916992184, 0.387331473632806],
            [-50.172767916992186, 0.397731473632803],
            [-50.155867916992186, 0.409331473632804],
            [-50.141067916992185, 0.419531473632802],
            [-50.119967916992181, 0.430131473632805],
            [-50.105567916992186, 0.440131473632803],
            [-50.088167916992184, 0.453131473632801],
            [-50.077367916992188, 0.463531473632806],
            [-50.070767916992182, 0.477431473632805],
            [-50.065467916992183, 0.487531473632806],
            [-50.06086791699218, 0.496231473632804],
            [-50.055467916992185, 0.507031473632807],
            [-50.044967916992185, 0.521931473632804],
            [-50.036167916992184, 0.535131473632802],
            [-50.033367916992184, 0.546731473632803],
            [-50.03376791699219, 0.550731473632801],
            [-50.02906791699219, 0.558731473632804],
            [-50.027667916992186, 0.567831473632801],
            [-50.029167916992186, 0.578131473632801],
            [-50.032267916992183, 0.590831473632804],
            [-50.037267916992185, 0.601931473632803],
            [-50.041667916992182, 0.607931473632803],
            [-50.045867916992187, 0.613931473632803],
            [-50.052567916992189, 0.621731473632806],
            [-50.06416791699219, 0.630631473632803],
            [-50.072467916992181, 0.635931473632802],
            [-50.083667916992184, 0.640331473632806],
            [-50.095367916992188, 0.641731473632802],
            [-50.104267916992185, 0.6395314736328],
            [-50.112467916992188, 0.633731473632807],
            [-50.117767916992186, 0.627931473632806],
            [-50.12336791699218, 0.621931473632806],
            [-50.133467916992188, 0.619731473632804],
            [-50.138967916992186, 0.620931473632801],
            [-50.143167916992184, 0.623131473632803],
            [-50.149767916992189, 0.624831473632803],
            [-50.16006791699219, 0.627931473632806],
            [-50.170067916992181, 0.631931473632804],
            [-50.179067916992182, 0.634731473632804],
            [-50.190267916992184, 0.640331473632806],
            [-50.203967916992184, 0.648031473632805],
            [-50.213467916992187, 0.655931473632805],
            [-50.217567916992181, 0.665731473632803],
            [-50.222667916992187, 0.678331473632802],
            [-50.225067916992188, 0.691531473632807],
            [-50.226367916992189, 0.696331473632803],
            [-50.22896791699219, 0.718831473632804],
            [-50.231167916992185, 0.730331473632802],
            [-50.227467916992182, 0.7371314736328],
            [-50.220367916992188, 0.743131473632801],
            [-50.215067916992183, 0.745131473632803],
            [-50.21156791699218, 0.746231473632804],
            [-50.196367916992187, 0.748331473632803],
            [-50.174967916992188, 0.753131473632806],
            [-50.166067916992183, 0.754531473632802],
            [-50.153367916992181, 0.749831473632803],
            [-50.133767916992184, 0.754731473632802],
            [-50.116867916992184, 0.759731473632804],
            [-50.111867916992182, 0.760531473632803],
            [-50.10096791699219, 0.765031473632803],
            [-50.09726791699218, 0.767131473632801],
            [-50.093367916992186, 0.771931473632804],
            [-50.090867916992181, 0.775131473632804],
            [-50.085467916992187, 0.780931473632805],
            [-50.078167916992186, 0.789331473632807],
            [-50.070667916992186, 0.797331473632802],
            [-50.067667916992185, 0.800331473632802],
            [-50.064567916992189, 0.803131473632803],
            [-50.06216791699218, 0.805431473632801],
            [-50.05516791699219, 0.812731473632802],
            [-50.048567916992184, 0.819531473632807],
            [-50.046367916992182, 0.821931473632802],
            [-50.036667916992187, 0.832531473632805],
            [-50.034467916992185, 0.835731473632805],
            [-50.030967916992182, 0.840231473632805],
            [-50.027867916992186, 0.844531473632806],
            [-50.025367916992181, 0.848131473632805],
            [-50.02406791699218, 0.851031473632801],
            [-50.022567916992188, 0.854931473632803],
            [-50.020167916992186, 0.858931473632801],
            [-50.016867916992183, 0.861331473632802],
            [-50.011067916992189, 0.864331473632802],
            [-50.003067916992187, 0.867331473632802],
            [-49.995467916992183, 0.870331473632802],
            [-49.989967916992185, 0.873431473632806],
            [-49.987467916992188, 0.876731473632802],
            [-49.986067916992184, 0.880531473632807],
            [-49.986067916992184, 0.8835314736328],
            [-49.987467916992188, 0.887131473632806],
            [-49.989767916992186, 0.891931473632802],
            [-49.993367916992185, 0.897731473632803],
            [-49.996567916992184, 0.901331473632801],
            [-50.002467916992188, 0.906531473632803],
            [-49.996367916992185, 0.921331473632804],
            [-49.988967916992181, 0.939231473632802],
            [-49.974467916992182, 0.941731473632807],
            [-49.966767916992183, 0.943731473632802],
            [-49.956767916992185, 0.949531473632803],
            [-49.949367916992188, 0.955331473632803],
            [-49.94686791699219, 0.957731473632805],
            [-49.944067916992182, 0.959431473632804],
            [-49.940967916992186, 0.962931473632807],
            [-49.93716791699218, 0.967131473632804],
            [-49.93446791699219, 0.971131473632802],
            [-49.933267916992186, 0.974631473632805],
            [-49.930767916992181, 0.980731473632801],
            [-49.927467916992185, 0.984631473632803],
            [-49.917867916992186, 0.991731473632804],
            [-49.909267916992185, 0.999531473632807],
            [-49.903667916992184, 1.003431473632801],
            [-49.893767916992189, 1.009731473632804],
            [-49.889667916992181, 1.013631473632806],
            [-49.887467916992186, 1.022931473632802],
            [-49.885767916992187, 1.034931473632803],
            [-49.885267916992184, 1.039931473632805],
            [-49.884567916992182, 1.047131473632803],
            [-49.88566791699219, 1.066131473632801],
            [-49.883567916992185, 1.077731473632802],
            [-49.883867916992187, 1.081931473632807],
            [-49.886067916992189, 1.088031473632803],
            [-49.885967916992186, 1.111131473632803],
            [-49.884867916992185, 1.134131473632806],
            [-49.884667916992186, 1.137731473632805],
            [-49.882967916992186, 1.169131473632802],
            [-49.885467916992184, 1.173731473632806],
            [-49.888167916992188, 1.176731473632806],
            [-49.891567916992187, 1.178931473632801],
            [-49.894367916992181, 1.182131473632801],
            [-49.896167916992184, 1.187331473632803],
            [-49.897567916992188, 1.191731473632807],
            [-49.897367916992181, 1.195531473632805],
            [-49.897267916992185, 1.201431473632802],
            [-49.897067916992185, 1.206531473632801],
            [-49.897867916992183, 1.210531473632805],
            [-49.900367916992181, 1.212931473632807],
            [-49.901567916992185, 1.240331473632807],
            [-49.902267916992187, 1.252931473632806],
            [-49.904267916992183, 1.295731473632806],
            [-49.899767916992189, 1.3026314736328],
            [-49.896267916992187, 1.311231473632802],
            [-49.893567916992183, 1.318431473632806],
            [-49.889567916992185, 1.324531473632803],
            [-49.886267916992182, 1.332131473632806],
            [-49.883567916992185, 1.340131473632802],
            [-49.881867916992185, 1.3529314736328],
            [-49.880767916992184, 1.363931473632803],
            [-49.880467916992188, 1.369731473632804],
            [-49.880667916992181, 1.375531473632805],
            [-49.882467916992184, 1.380931473632806],
            [-49.882767916992186, 1.3850314736328],
            [-49.879967916992186, 1.386931473632806],
            [-49.878467916992186, 1.389731473632807],
            [-49.878767916992189, 1.396731473632805],
            [-49.878267916992186, 1.406931473632802],
            [-49.876567916992187, 1.416731473632801],
            [-49.875767916992189, 1.423231473632804],
            [-49.875567916992182, 1.430431473632801],
            [-49.876267916992184, 1.437531473632802],
            [-49.876567916992187, 1.4505314736328],
            [-49.877167916992185, 1.458931473632802],
            [-49.877367916992185, 1.462531473632801],
            [-49.877667916992181, 1.465531473632801],
            [-49.879067916992184, 1.473131473632805],
            [-49.881267916992186, 1.479931473632803],
            [-49.881567916992182, 1.488931473632803],
            [-49.881667916992185, 1.500131473632806],
            [-49.88266791699219, 1.504931473632801],
            [-49.883567916992185, 1.509131473632806],
            [-49.886367916992185, 1.516431473632807],
            [-49.888267916992184, 1.522731473632803],
            [-49.891267916992184, 1.530531473632806],
            [-49.894367916992181, 1.533331473632806],
            [-49.894367916992181, 1.538131473632802],
            [-49.893467916992186, 1.541331473632802],
            [-49.898467916992189, 1.557131473632801],
            [-49.900467916992184, 1.568331473632803],
            [-49.901967916992184, 1.577131473632804],
            [-49.902867916992186, 1.5800314736328],
            [-49.901867916992188, 1.584131473632802],
            [-49.900067916992185, 1.588531473632806],
            [-49.899767916992189, 1.595931473632803],
            [-49.900167916992189, 1.602431473632805],
            [-49.901967916992184, 1.607331473632804],
            [-49.902267916992187, 1.610431473632801],
            [-49.905567916992183, 1.624731473632806],
            [-49.907367916992186, 1.631731473632804],
            [-49.908867916992186, 1.636431473632804],
            [-49.91006791699219, 1.649931473632805],
            [-49.912767916992181, 1.660531473632801],
            [-49.915267916992185, 1.665331473632804],
            [-49.918867916992184, 1.667431473632803],
            [-49.919967916992185, 1.672531473632802],
            [-49.920667916992187, 1.676531473632807],
            [-49.919267916992183, 1.682131473632801],
            [-49.920567916992184, 1.687931473632801],
            [-49.923867916992187, 1.693431473632806],
            [-49.929167916992185, 1.699231473632807],
            [-49.942667916992185, 1.709931473632807],
            [-49.958167916992181, 1.719731473632805],
            [-49.995867916992182, 1.738931473632803],
            [-50.003067916992187, 1.743131473632801],
            [-50.009267916992187, 1.746531473632807],
            [-50.018967916992182, 1.753131473632806],
            [-50.032267916992183, 1.759731473632804],
            [-50.050867916992189, 1.767231473632805],
            [-50.080467916992184, 1.778931473632802],
            [-50.092267916992185, 1.782131473632802],
            [-50.132167916992188, 1.796531473632804],
            [-50.145467916992189, 1.803931473632801],
            [-50.15376791699218, 1.808731473632804],
            [-50.15976791699218, 1.811731473632804],
            [-50.165667916992184, 1.813131473632801],
            [-50.176867916992187, 1.813431473632804],
            [-50.182267916992188, 1.813131473632801],
            [-50.186567916992189, 1.812731473632802],
            [-50.197167916992186, 1.8103314736328],
            [-50.227067916992183, 1.809131473632803],
            [-50.233867916992182, 1.808931473632803],
            [-50.240767916992183, 1.809731473632802],
            [-50.245167916992187, 1.810131473632801],
            [-50.264267916992182, 1.812531473632802],
            [-50.280067916992181, 1.808731473632804],
            [-50.289567916992183, 1.807931473632806],
            [-50.32456791699218, 1.815531473632802],
            [-50.331267916992189, 1.8148314736328],
            [-50.35826791699219, 1.804731473632806],
            [-50.37336791699218, 1.8011314736328],
            [-50.400367916992181, 1.800331473632802],
            [-50.41446791699218, 1.801831473632802],
            [-50.421167916992189, 1.803731473632801],
            [-50.424367916992182, 1.805431473632801],
            [-50.42716791699219, 1.808731473632804],
            [-50.430867916992185, 1.810731473632806],
            [-50.440567916992187, 1.8133314736328],
            [-50.44486791699218, 1.815131473632803],
            [-50.448567916992189, 1.817831473632801],
            [-50.466667916992186, 1.831131473632801],
            [-50.474967916992185, 1.838731473632805],
            [-50.504467916992184, 1.881131473632806],
            [-50.50736791699218, 1.884531473632805],
            [-50.508267916992182, 1.887731473632805],
            [-50.525467916992184, 1.929831473632802],
            [-50.529167916992186, 1.934331473632803],
            [-50.533067916992181, 1.940131473632803],
            [-50.535567916992186, 1.941731473632807],
            [-50.53946791699218, 1.943131473632803],
            [-50.551367916992184, 1.949531473632803],
            [-50.557967916992183, 1.955831473632806],
            [-50.58256791699219, 1.989731473632801],
            [-50.588967916992189, 2.004531473632802],
            [-50.595267916992185, 2.024931473632805],
            [-50.597367916992184, 2.0297314736328],
            [-50.599767916992185, 2.040731473632803],
            [-50.599567916992186, 2.052531473632804],
            [-50.598467916992185, 2.057631473632803],
            [-50.597867916992186, 2.062531473632802],
            [-50.600567916992183, 2.068431473632806],
            [-50.603667916992187, 2.073131473632806],
            [-50.609367916992184, 2.078931473632807],
            [-50.612467916992188, 2.083531473632803],
            [-50.617767916992186, 2.091331473632806],
            [-50.623267916992184, 2.094531473632806],
            [-50.624867916992187, 2.0969314736328],
            [-50.634167916992183, 2.102331473632802],
            [-50.636367916992185, 2.105531473632801],
            [-50.642867916992188, 2.1121314736328],
            [-50.646467916992187, 2.114731473632801],
            [-50.656567916992188, 2.119831473632807],
            [-50.663067916992183, 2.124531473632807],
            [-50.670867916992187, 2.129131473632803],
            [-50.674767916992181, 2.130931473632806],
            [-50.681067916992184, 2.1335314736328],
            [-50.683867916992185, 2.143631473632801],
            [-50.684667916992183, 2.151531473632801],
            [-50.682567916992184, 2.156331473632804],
            [-50.68116791699218, 2.161731473632805],
            [-50.680767916992181, 2.167131473632807],
            [-50.681867916992189, 2.175331473632802],
            [-50.683067916992186, 2.179531473632807],
            [-50.684367916992187, 2.183931473632803],
            [-50.686567916992189, 2.190931473632801],
            [-50.69186791699218, 2.201431473632802],
            [-50.704667916992186, 2.224131473632802],
            [-50.712567916992185, 2.232931473632803],
            [-50.714867916992183, 2.234631473632803],
            [-50.717567916992181, 2.235931473632803],
            [-50.720567916992181, 2.237331473632807],
            [-50.72226791699218, 2.240331473632807],
            [-50.72426791699219, 2.245131473632803],
            [-50.724467916992182, 2.248731473632802],
            [-50.725267916992181, 2.252731473632807],
            [-50.725267916992181, 2.255631473632803],
            [-50.724767916992185, 2.261131473632801],
            [-50.723367916992188, 2.266331473632803],
            [-50.721967916992185, 2.278031473632801],
            [-50.721967916992185, 2.281531473632803],
            [-50.722367916992184, 2.286531473632806],
            [-50.723467916992185, 2.290131473632805],
            [-50.726367916992189, 2.294531473632802],
            [-50.731167916992185, 2.300931473632801],
            [-50.735067916992186, 2.303931473632801],
            [-50.738267916992186, 2.307131473632801],
            [-50.740767916992183, 2.309131473632803],
            [-50.742167916992187, 2.312331473632803],
            [-50.743267916992181, 2.316131473632801],
            [-50.744067916992186, 2.319131473632801],
            [-50.744667916992185, 2.324231473632807],
            [-50.744067916992186, 2.328931473632807],
            [-50.744667916992185, 2.333131473632804],
            [-50.745467916992183, 2.336531473632803],
            [-50.745467916992183, 2.339931473632802],
            [-50.746267916992181, 2.343531473632801],
            [-50.746567916992184, 2.347431473632803],
            [-50.746567916992184, 2.351931473632803],
            [-50.747667916992185, 2.356731473632806],
            [-50.749467916992188, 2.361831473632805],
            [-50.752667916992181, 2.3651314736328],
            [-50.757467916992184, 2.370331473632802],
            [-50.757767916992186, 2.374831473632803],
            [-50.757167916992188, 2.380931473632806],
            [-50.756567916992182, 2.385931473632802],
            [-50.757767916992186, 2.3895314736328],
            [-50.759867916992185, 2.3957314736328],
            [-50.76206791699218, 2.399931473632805],
            [-50.763767916992187, 2.402531473632806],
            [-50.766267916992184, 2.405531473632806],
            [-50.768467916992186, 2.408731473632805],
            [-50.768767916992189, 2.413331473632802],
            [-50.766567916992187, 2.429531473632807],
            [-50.766767916992187, 2.436531473632805],
            [-50.767567916992185, 2.443331473632803],
            [-50.768967916992182, 2.449231473632807],
            [-50.770367916992186, 2.4535314736328],
            [-50.772367916992181, 2.457731473632805],
            [-50.776567916992185, 2.463731473632805],
            [-50.783867916992186, 2.471131473632802],
            [-50.78476791699218, 2.475131473632807],
            [-50.787867916992184, 2.486131473632803],
            [-50.789967916992182, 2.491131473632805],
            [-50.793567916992188, 2.497631473632801],
            [-50.797567916992186, 2.500931473632804],
            [-50.801867916992187, 2.502731473632807],
            [-50.807267916992188, 2.503131473632806],
            [-50.811867916992185, 2.503131473632806],
            [-50.816567916992184, 2.503131473632806],
            [-50.819267916992189, 2.505631473632803],
            [-50.827067916992185, 2.513931473632802],
            [-50.826367916992183, 2.519331473632803],
            [-50.823467916992186, 2.527731473632805],
            [-50.82456791699218, 2.530531473632806],
            [-50.827667916992183, 2.534131473632804],
            [-50.833167916992181, 2.540931473632803],
            [-50.832867916992186, 2.545331473632807],
            [-50.831567916992185, 2.549031473632802],
            [-50.831567916992185, 2.554031473632804],
            [-50.833167916992181, 2.559731473632802],
            [-50.834767916992185, 2.567731473632804],
            [-50.837367916992186, 2.576331473632806],
            [-50.840867916992181, 2.580831473632806],
            [-50.84726791699218, 2.592731473632803],
            [-50.845867916992184, 2.597931473632805],
            [-50.84426791699218, 2.601031473632801],
            [-50.844467916992187, 2.612531473632806],
            [-50.84456791699219, 2.619731473632804],
            [-50.846167916992187, 2.632831473632805],
            [-50.846767916992185, 2.636931473632806],
            [-50.847667916992187, 2.640331473632806],
            [-50.850667916992187, 2.6425314736328],
            [-50.85396791699219, 2.643931473632804],
            [-50.858167916992187, 2.644931473632802],
            [-50.859367916992184, 2.647731473632803],
            [-50.857767916992188, 2.650331473632804],
            [-50.857467916992185, 2.653731473632803],
            [-50.857167916992182, 2.656631473632807],
            [-50.857867916992184, 2.659731473632803],
            [-50.859367916992184, 2.663731473632801],
            [-50.868067916992189, 2.677331473632805],
            [-50.870767916992186, 2.680731473632804],
            [-50.872967916992181, 2.682631473632803],
            [-50.876267916992184, 2.684531473632802],
            [-50.879567916992187, 2.684531473632802],
            [-50.882167916992188, 2.689231473632802],
            [-50.886067916992189, 2.710731473632805],
            [-50.88436791699219, 2.713731473632805],
            [-50.883267916992182, 2.723131473632805],
            [-50.883767916992184, 2.732331473632804],
            [-50.88406791699218, 2.737931473632806],
            [-50.884567916992182, 2.741531473632804],
            [-50.886567916992185, 2.745331473632802],
            [-50.890467916992186, 2.749131473632801],
            [-50.895467916992189, 2.753131473632806],
            [-50.899467916992187, 2.755631473632803],
            [-50.90376791699218, 2.757331473632803],
            [-50.905867916992186, 2.775331473632804],
            [-50.906167916992189, 2.781931473632802],
            [-50.907267916992183, 2.786331473632806],
            [-50.908967916992182, 2.789531473632806],
            [-50.91346791699219, 2.792431473632803],
            [-50.915667916992184, 2.795931473632805],
            [-50.918867916992184, 2.799931473632803],
            [-50.922767916992186, 2.803331473632802],
            [-50.931867916992189, 2.808131473632805],
            [-50.937667916992183, 2.811931473632804],
            [-50.940567916992187, 2.814531473632805],
            [-50.94516791699219, 2.818731473632802],
            [-50.947467916992181, 2.827131473632804],
            [-50.949067916992185, 2.830531473632803],
            [-50.951067916992187, 2.834331473632801],
            [-50.953567916992185, 2.837131473632802],
            [-50.95286791699219, 2.840831473632804],
            [-50.950167916992186, 2.844131473632807],
            [-50.949267916992184, 2.849631473632805],
            [-50.949967916992186, 2.854131473632805],
            [-50.951267916992187, 2.861531473632802],
            [-50.950367916992185, 2.866931473632803],
            [-50.948967916992189, 2.872631473632801],
            [-50.94956791699218, 2.8773314736328],
            [-50.950767916992184, 2.882731473632802],
            [-50.959367916992186, 2.894931473632802],
            [-50.962367916992186, 2.900731473632803],
            [-50.97426791699219, 2.924331473632805],
            [-50.975567916992183, 2.928731473632801],
            [-50.978067916992188, 2.931231473632806],
            [-50.979467916992185, 2.933731473632804],
            [-50.982867916992184, 2.936931473632804],
            [-50.983967916992185, 2.941531473632807],
            [-50.987267916992181, 2.951731473632805],
            [-50.989367916992187, 2.959431473632804],
            [-50.991867916992184, 2.962231473632805],
            [-50.991967916992181, 2.965531473632801],
            [-50.992267916992184, 2.974531473632801],
            [-50.993067916992189, 2.982731473632803],
            [-50.993867916992187, 2.988731473632804],
            [-50.995067916992184, 2.994731473632804],
            [-50.997267916992186, 3.001131473632803],
            [-50.997167916992183, 3.004531473632802],
            [-50.998767916992186, 3.011131473632801],
            [-51.000567916992182, 3.015031473632803],
            [-51.003767916992189, 3.020331473632801],
            [-51.00736791699218, 3.026931473632807],
            [-51.01506791699218, 3.037131473632805],
            [-51.017867916992188, 3.039531473632806],
            [-51.017867916992188, 3.043131473632805],
            [-51.021267916992187, 3.052331473632805],
            [-51.021167916992184, 3.056731473632802],
            [-51.021767916992189, 3.060131473632801],
            [-51.019267916992185, 3.0648314736328],
            [-51.015667916992186, 3.068331473632803],
            [-51.016467916992184, 3.071931473632802],
            [-51.01706791699219, 3.074931473632802],
            [-51.017467916992189, 3.079331473632806],
            [-51.014267916992182, 3.082131473632806],
            [-51.014567916992185, 3.085531473632805],
            [-51.021167916992184, 3.096631473632804],
            [-51.025167916992189, 3.102431473632805],
            [-51.027967916992182, 3.104931473632803],
            [-51.03176791699218, 3.106831473632802],
            [-51.032667916992182, 3.109931473632805],
            [-51.035867916992188, 3.115931473632806],
            [-51.040367916992182, 3.124831473632803],
            [-51.044267916992183, 3.134131473632806],
            [-51.044267916992183, 3.1395314736328],
            [-51.043567916992188, 3.143131473632806],
            [-51.04416791699218, 3.148331473632801],
            [-51.043167916992189, 3.152531473632806],
            [-51.042067916992181, 3.156631473632807],
            [-51.042267916992188, 3.1609314736328],
            [-51.043167916992189, 3.164631473632802],
            [-51.041367916992186, 3.167931473632805],
            [-51.035967916992185, 3.175931473632801],
            [-51.03476791699218, 3.180931473632803],
            [-51.034267916992185, 3.189131473632806],
            [-51.034267916992185, 3.194131473632801],
            [-51.03346791699218, 3.198931473632804],
            [-51.033367916992184, 3.203131473632801],
            [-51.032567916992186, 3.206931473632807],
            [-51.032767916992185, 3.213731473632805],
            [-51.03476791699218, 3.219731473632805],
            [-51.035967916992185, 3.222431473632803],
            [-51.043567916992188, 3.240731473632806],
            [-51.046167916992189, 3.244731473632804],
            [-51.048967916992183, 3.247631473632801],
            [-51.049367916992182, 3.250631473632801],
            [-51.051567916992184, 3.252931473632806],
            [-51.052767916992181, 3.2585314736328],
            [-51.057667916992187, 3.271531473632805],
            [-51.065167916992181, 3.288131473632802],
            [-51.069067916992182, 3.303331473632802],
            [-51.08226791699218, 3.339931473632802],
            [-51.086467916992184, 3.350231473632803],
            [-51.091167916992184, 3.369031473632802],
            [-51.098167916992182, 3.394731473632802],
            [-51.099567916992186, 3.405931473632805],
            [-51.105367916992186, 3.425931473632801],
            [-51.105867916992182, 3.434131473632803],
            [-51.109267916992181, 3.450731473632807],
            [-51.108967916992185, 3.454331473632806],
            [-51.101467916992185, 3.461131473632804],
            [-51.096367916992186, 3.468731473632801],
            [-51.094167916992184, 3.474631473632805],
            [-51.090967916992184, 3.480531473632801],
            [-51.090567916992185, 3.483531473632802],
            [-51.087067916992183, 3.499831473632803],
            [-51.086967916992187, 3.519731473632802],
            [-51.083267916992185, 3.544131473632802],
            [-51.081767916992185, 3.559531473632802],
            [-51.082067916992187, 3.565631473632806],
            [-51.086167916992181, 3.591531473632806],
            [-51.086167916992181, 3.601531473632804],
            [-51.086767916992187, 3.610131473632805],
            [-51.088467916992187, 3.614331473632802],
            [-51.088667916992186, 3.622531473632804],
            [-51.090167916992186, 3.629931473632801],
            [-51.090367916992186, 3.632831473632805],
            [-51.089267916992185, 3.636131473632801],
            [-51.087667916992189, 3.642931473632807],
            [-51.087667916992189, 3.651131473632802],
            [-51.089067916992185, 3.671531473632804],
            [-51.089267916992185, 3.675731473632801],
            [-51.089567916992181, 3.679731473632806],
            [-51.089867916992183, 3.684531473632802],
            [-51.090067916992183, 3.688731473632807],
            [-51.090467916992182, 3.702131473632804],
            [-51.090567916992185, 3.707131473632806],
            [-51.091167916992184, 3.738531473632804],
            [-51.090867916992181, 3.743331473632807],
            [-51.088167916992184, 3.7645314736328],
            [-51.087267916992189, 3.783831473632802],
            [-51.088967916992189, 3.794131473632802],
            [-51.086767916992187, 3.796831473632807],
            [-51.087667916992189, 3.804031473632804],
            [-51.086967916992187, 3.8088314736328],
            [-51.089367916992188, 3.819131473632801],
            [-51.087267916992189, 3.824231473632807],
            [-51.087067916992183, 3.827731473632802],
            [-51.086167916992181, 3.836531473632803],
            [-51.084367916992186, 3.839331473632804],
            [-51.082867916992186, 3.857131473632805],
            [-51.080667916992184, 3.884931473632804],
            [-51.081467916992182, 3.890031473632803],
            [-51.082967916992189, 3.895031473632805],
            [-51.085067916992188, 3.899931473632805],
            [-51.089367916992188, 3.905531473632806],
            [-51.092067916992185, 3.908331473632806],
            [-51.095367916992188, 3.910231473632805],
            [-51.098367916992188, 3.910731473632801],
            [-51.102067916992183, 3.9124314736328],
            [-51.110367916992189, 3.911731473632805],
            [-51.113867916992184, 3.9109314736328],
            [-51.118267916992181, 3.909331473632804],
            [-51.122967916992181, 3.9079314736328],
            [-51.15076791699218, 3.913831473632804],
            [-51.179367916992184, 3.919531473632802],
            [-51.179667916992187, 3.924331473632805],
            [-51.179967916992183, 3.929331473632807],
            [-51.179167916992185, 3.936231473632802],
            [-51.180067916992186, 3.940931473632801],
            [-51.179467916992181, 3.944931473632806],
            [-51.179467916992181, 3.947831473632803],
            [-51.179367916992184, 3.952731473632802],
            [-51.177567916992189, 3.958131473632804],
            [-51.180267916992186, 3.961931473632802],
            [-51.179367916992184, 3.964931473632802],
            [-51.179367916992184, 3.972431473632803],
            [-51.178367916992187, 3.976731473632803],
            [-51.177567916992189, 3.980931473632801],
            [-51.177767916992181, 3.9841314736328],
            [-51.17716791699219, 3.988131473632805],
            [-51.177767916992181, 3.992631473632805],
            [-51.177867916992184, 3.997331473632805],
            [-51.176567916992184, 4.000131473632806],
            [-51.177767916992181, 4.004731473632802],
            [-51.175767916992186, 4.0100314736328],
            [-51.175867916992189, 4.013331473632803],
            [-51.176867916992187, 4.018931473632804],
            [-51.177967916992188, 4.022931473632802],
            [-51.179767916992184, 4.026931473632807],
            [-51.181067916992184, 4.0329314736328],
            [-51.181667916992183, 4.036931473632805],
            [-51.181067916992184, 4.040931473632803],
            [-51.183367916992182, 4.047531473632802],
            [-51.18416791699218, 4.051731473632806],
            [-51.183067916992186, 4.054731473632806],
            [-51.18446791699219, 4.060131473632801],
            [-51.187267916992184, 4.069931473632806],
            [-51.189367916992182, 4.073731473632805],
            [-51.189067916992187, 4.077231473632807],
            [-51.189567916992189, 4.080331473632803],
            [-51.192767916992182, 4.088731473632805],
            [-51.193467916992184, 4.092331473632804],
            [-51.197167916992186, 4.099331473632802],
            [-51.19786791699218, 4.104331473632804],
            [-51.199267916992184, 4.108731473632801],
            [-51.200467916992181, 4.113931473632803],
            [-51.20256791699218, 4.117931473632801],
            [-51.20286791699219, 4.121131473632801],
            [-51.203967916992184, 4.125131473632806],
            [-51.206567916992185, 4.127731473632807],
            [-51.215167916992186, 4.142731473632807],
            [-51.214067916992185, 4.145531473632801],
            [-51.21626791699218, 4.150131473632804],
            [-51.221767916992185, 4.157731473632801],
            [-51.222667916992187, 4.160731473632801],
            [-51.22596791699219, 4.163731473632801],
            [-51.231467916992187, 4.171331473632804],
            [-51.235067916992186, 4.1776314736328],
            [-51.23966791699219, 4.184531473632802],
            [-51.244967916992181, 4.191731473632807],
            [-51.249367916992185, 4.197131473632801],
            [-51.251267916992184, 4.199531473632803],
            [-51.25166791699219, 4.202831473632806],
            [-51.254467916992184, 4.2050314736328],
            [-51.25636791699219, 4.207731473632805],
            [-51.259267916992187, 4.211731473632803],
            [-51.262667916992186, 4.2157314736328],
            [-51.268967916992182, 4.229131473632805],
            [-51.271767916992189, 4.231031473632804],
            [-51.271267916992187, 4.2341314736328],
            [-51.275667916992184, 4.239031473632807],
            [-51.282567916992186, 4.246731473632806],
            [-51.283367916992184, 4.250331473632805],
            [-51.29716791699218, 4.261331473632801],
            [-51.319567916992185, 4.284931473632803],
            [-51.331867916992181, 4.304831473632802],
            [-51.335767916992182, 4.307131473632801],
            [-51.337867916992181, 4.3103314736328],
            [-51.351667916992184, 4.319731473632807],
            [-51.366067916992186, 4.336131473632804],
            [-51.374467916992188, 4.342531473632803],
            [-51.38536791699218, 4.353131473632807],
            [-51.394267916992185, 4.3651314736328],
            [-51.399867916992186, 4.368731473632806],
            [-51.406467916992185, 4.372631473632801],
            [-51.411167916992184, 4.377031473632805],
            [-51.415867916992184, 4.381331473632805],
            [-51.418967916992187, 4.384231473632802],
            [-51.421867916992184, 4.387131473632806],
            [-51.430467916992185, 4.393531473632805],
            [-51.433667916992185, 4.3957314736328],
            [-51.436867916992185, 4.397931473632802],
            [-51.439567916992189, 4.399731473632805],
            [-51.444567916992185, 4.403731473632803],
            [-51.44786791699218, 4.405931473632805],
            [-51.460867916992186, 4.416831473632804],
            [-51.47426791699219, 4.428331473632802],
            [-51.485867916992184, 4.437331473632803],
            [-51.489167916992187, 4.437531473632802],
            [-51.492467916992183, 4.439131473632806],
            [-51.508567916992185, 4.447131473632801],
            [-51.511767916992184, 4.448131473632806],
            [-51.516467916992184, 4.450031473632805],
            [-51.637467916992186, 4.508931473632806],
            [-51.652267916992187, 4.486131473632803],
            [-51.661967916992182, 4.470731473632803],
            [-51.669267916992183, 4.453931473632807],
            [-51.663667916992182, 4.423531473632806],
            [-51.673967916992183, 4.394331473632803],
            [-51.679167916992185, 4.366131473632805],
            [-51.676867916992187, 4.332731473632805],
            [-51.66916791699218, 4.309831473632805],
            [-51.649267916992187, 4.267231473632805],
            [-51.64036791699219, 4.252031473632805],
            [-51.63266791699219, 4.240431473632803],
            [-51.623067916992184, 4.225731473632806],
            [-51.618867916992187, 4.209931473632807],
            [-51.621867916992187, 4.195131473632806],
            [-51.631267916992186, 4.186131473632805],
            [-51.634867916992185, 4.176331473632807],
            [-51.63566791699219, 4.172131473632803],
            [-51.636067916992189, 4.167431473632803],
            [-51.636767916992184, 4.157331473632802],
            [-51.637467916992186, 4.133331473632801],
            [-51.642167916992186, 4.116231473632801],
            [-51.642567916992185, 4.111831473632805],
            [-51.643167916992184, 4.106331473632807],
            [-51.643667916992186, 4.103131473632807],
            [-51.644367916992181, 4.098331473632804],
            [-51.64506791699219, 4.094531473632806],
            [-51.645667916992181, 4.091631473632802],
            [-51.646267916992187, 4.087131473632802],
            [-51.646867916992186, 4.083531473632803],
            [-51.647667916992184, 4.079531473632805],
            [-51.648467916992189, 4.076431473632802],
            [-51.649267916992187, 4.073531473632805],
            [-51.651467916992189, 4.069531473632807],
            [-51.65376791699218, 4.063431473632804],
            [-51.654767916992185, 4.058331473632805],
            [-51.655967916992182, 4.053931473632801],
            [-51.65846791699218, 4.050931473632801],
            [-51.663867916992189, 4.046731473632803],
            [-51.668367916992182, 4.043231473632801],
            [-51.671767916992181, 4.039531473632806],
            [-51.674767916992181, 4.036731473632805],
            [-51.678967916992185, 4.035131473632802],
            [-51.681967916992186, 4.034331473632804],
            [-51.686267916992186, 4.032331473632802],
            [-51.691667916992188, 4.029131473632802],
            [-51.695967916992188, 4.027531473632806],
            [-51.698767916992182, 4.026331473632801],
            [-51.702367916992188, 4.024131473632806],
            [-51.705367916992188, 4.0207314736328],
            [-51.708167916992181, 4.018531473632805],
            [-51.711967916992187, 4.0175314736328],
            [-51.71656791699219, 4.015931473632804],
            [-51.719467916992187, 4.014331473632801],
            [-51.722067916992188, 4.013131473632804],
            [-51.724767916992185, 4.011731473632807],
            [-51.727367916992186, 4.009731473632804],
            [-51.730667916992189, 4.007531473632802],
            [-51.73496791699219, 4.005931473632806],
            [-51.738667916992185, 4.004931473632801],
            [-51.741667916992185, 4.003731473632804],
            [-51.744767916992188, 4.002031473632805],
            [-51.747667916992185, 3.999831473632803],
            [-51.750567916992182, 3.997631473632801],
            [-51.753767916992189, 3.995131473632803],
            [-51.75766791699219, 3.992531473632802],
            [-51.760467916992184, 3.988231473632801],
            [-51.762767916992189, 3.985731473632804],
            [-51.765667916992186, 3.983231473632806],
            [-51.769367916992181, 3.980731473632801],
            [-51.774767916992189, 3.977331473632802],
            [-51.777567916992183, 3.974531473632801],
            [-51.779767916992185, 3.971331473632802],
            [-51.781267916992185, 3.967931473632802],
            [-51.782267916992183, 3.964131473632804],
            [-51.782367916992186, 3.960831473632801],
            [-51.782267916992183, 3.956331473632801],
            [-51.782267916992183, 3.952831473632806],
            [-51.783067916992181, 3.948131473632806],
            [-51.781167916992189, 3.943331473632803],
            [-51.780467916992187, 3.939531473632805],
            [-51.781167916992189, 3.935731473632806],
            [-51.781267916992185, 3.931131473632803],
            [-51.781267916992185, 3.926331473632807],
            [-51.781967916992187, 3.922731473632801],
            [-51.78376791699219, 3.917931473632805],
            [-51.785867916992188, 3.913331473632802],
            [-51.787267916992185, 3.909931473632803],
            [-51.789267916992188, 3.905731473632805],
            [-51.791167916992187, 3.901531473632801],
            [-51.793667916992185, 3.897531473632803],
            [-51.795367916992184, 3.893631473632801],
            [-51.796867916992184, 3.890031473632803],
            [-51.798867916992187, 3.886931473632806],
            [-51.801167916992185, 3.884931473632804],
            [-51.803567916992186, 3.882531473632802],
            [-51.806067916992184, 3.879531473632802],
            [-51.808367916992182, 3.8773314736328],
            [-51.810767916992184, 3.875531473632805],
            [-51.81416791699219, 3.872631473632801],
            [-51.820467916992186, 3.870131473632803],
            [-51.822367916992185, 3.866731473632804],
            [-51.822467916992181, 3.861731473632801],
            [-51.823567916992189, 3.858731473632801],
            [-51.825967916992184, 3.856731473632806],
            [-51.828167916992186, 3.854931473632803],
            [-51.830967916992186, 3.8529314736328],
            [-51.833667916992184, 3.851531473632804],
            [-51.83686791699219, 3.850531473632806],
            [-51.839467916992184, 3.848531473632804],
            [-51.841167916992184, 3.846131473632802],
            [-51.84456791699219, 3.842131473632804],
            [-51.849167916992187, 3.838331473632806],
            [-51.854767916992188, 3.833931473632802],
            [-51.857067916992186, 3.829531473632805],
            [-51.859167916992185, 3.825931473632807],
            [-51.861767916992186, 3.823931473632804],
            [-51.863967916992181, 3.822131473632801],
            [-51.865767916992183, 3.819131473632801],
            [-51.867167916992187, 3.816531473632807],
            [-51.868267916992181, 3.812931473632801],
            [-51.869167916992183, 3.809131473632803],
            [-51.870767916992186, 3.806531473632802],
            [-51.873767916992186, 3.803531473632802],
            [-51.876967916992186, 3.8026314736328],
            [-51.880167916992185, 3.803131473632803],
            [-51.883467916992188, 3.801831473632802],
            [-51.884567916992182, 3.798931473632805],
            [-51.886067916992189, 3.795931473632805],
            [-51.88906791699219, 3.7951314736328],
            [-51.89206791699219, 3.796531473632804],
            [-51.89476791699218, 3.797531473632802],
            [-51.898267916992182, 3.798731473632806],
            [-51.901467916992189, 3.800331473632802],
            [-51.904467916992189, 3.797931473632801],
            [-51.903967916992187, 3.793531473632804],
            [-51.901467916992189, 3.791831473632804],
            [-51.898267916992182, 3.791531473632801],
            [-51.896267916992187, 3.789331473632807],
            [-51.896167916992184, 3.7859314736328],
            [-51.899867916992186, 3.784131473632804],
            [-51.90406791699219, 3.782331473632802],
            [-51.90876791699219, 3.780531473632806],
            [-51.912867916992184, 3.780131473632807],
            [-51.918167916992189, 3.781531473632803],
            [-51.921067916992186, 3.777731473632805],
            [-51.921167916992189, 3.7737314736328],
            [-51.924467916992185, 3.770831473632803],
            [-51.926167916992185, 3.767931473632807],
            [-51.923167916992185, 3.763531473632803],
            [-51.921367916992182, 3.760331473632803],
            [-51.920367916992184, 3.755531473632807],
            [-51.919567916992186, 3.7508314736328],
            [-51.916667916992182, 3.746131473632801],
            [-51.917267916992188, 3.743131473632801],
            [-51.919267916992183, 3.7401314736328],
            [-51.919767916992186, 3.735931473632803],
            [-51.91916791699218, 3.732131473632805],
            [-51.920267916992188, 3.727331473632802],
            [-51.922567916992186, 3.723331473632804],
            [-51.925567916992186, 3.722331473632806],
            [-51.929167916992185, 3.722431473632803],
            [-51.932967916992183, 3.722731473632805],
            [-51.93446791699219, 3.720131473632804],
            [-51.937467916992183, 3.720131473632804],
            [-51.940767916992186, 3.721131473632802],
            [-51.945967916992188, 3.721631473632804],
            [-51.94956791699218, 3.722431473632803],
            [-51.952667916992183, 3.722131473632807],
            [-51.955667916992184, 3.720531473632803],
            [-51.956867916992181, 3.717731473632803],
            [-51.957367916992183, 3.714131473632804],
            [-51.961167916992181, 3.713531473632806],
            [-51.964467916992184, 3.711731473632803],
            [-51.968467916992182, 3.708331473632803],
            [-51.971767916992185, 3.705831473632806],
            [-51.973467916992185, 3.701431473632802],
            [-51.974167916992187, 3.697331473632801],
            [-51.976367916992189, 3.692831473632801],
            [-51.977467916992182, 3.689131473632806],
            [-51.977867916992182, 3.684731473632802],
            [-51.979167916992182, 3.681731473632802],
            [-51.981067916992188, 3.679031473632804],
            [-51.983567916992186, 3.677331473632805],
            [-51.986067916992184, 3.673231473632804],
            [-51.986767916992186, 3.669131473632802],
            [-51.987767916992183, 3.665531473632804],
            [-51.988267916992186, 3.661031473632804],
            [-51.987167916992185, 3.657131473632802],
            [-51.986967916992185, 3.653731473632803],
            [-51.988267916992186, 3.650831473632806],
            [-51.990767916992183, 3.647731473632803],
            [-51.992967916992185, 3.642731473632807],
            [-51.991167916992183, 3.640331473632806],
            [-51.989967916992185, 3.636331473632801],
            [-51.988667916992185, 3.632331473632803],
            [-51.988567916992181, 3.627931473632806],
            [-51.990767916992183, 3.624831473632803],
            [-51.993267916992181, 3.621531473632807],
            [-51.995767916992186, 3.617931473632801],
            [-51.997667916992185, 3.615731473632806],
            [-52.000167916992183, 3.613131473632805],
            [-52.002967916992183, 3.6091314736328],
            [-52.005567916992185, 3.607131473632805],
            [-52.007467916992184, 3.604931473632803],
            [-52.009567916992182, 3.602731473632801],
            [-52.013167916992188, 3.599131473632802],
            [-52.016467916992184, 3.596331473632802],
            [-52.020367916992186, 3.591931473632805],
            [-52.022567916992188, 3.5877314736328],
            [-52.024867916992186, 3.5847314736328],
            [-52.027667916992186, 3.577831473632806],
            [-52.029767916992185, 3.575031473632805],
            [-52.031967916992187, 3.572331473632801],
            [-52.033967916992182, 3.568131473632803],
            [-52.035967916992185, 3.564231473632802],
            [-52.037767916992181, 3.561131473632805],
            [-52.039167916992184, 3.557931473632806],
            [-52.041767916992185, 3.553731473632801],
            [-52.04416791699218, 3.549731473632804],
            [-52.048167916992185, 3.542131473632807],
            [-52.051167916992185, 3.5374314736328],
            [-52.053267916992183, 3.534131473632804],
            [-52.055267916992186, 3.529431473632805],
            [-52.058267916992186, 3.523531473632801],
            [-52.061267916992186, 3.519331473632803],
            [-52.064867916992185, 3.518131473632806],
            [-52.06986791699218, 3.518131473632806],
            [-52.073467916992186, 3.514131473632801],
            [-52.072367916992185, 3.509931473632804],
            [-52.070267916992186, 3.507731473632802],
            [-52.071267916992184, 3.504231473632807],
            [-52.073767916992182, 3.499731473632806],
            [-52.07786791699219, 3.496131473632801],
            [-52.080067916992185, 3.492531473632802],
            [-52.082967916992189, 3.491131473632805],
            [-52.087267916992189, 3.488531473632804],
            [-52.089367916992188, 3.484931473632805],
            [-52.090867916992181, 3.481831473632802],
            [-52.092667916992184, 3.479131473632805],
            [-52.093967916992185, 3.475131473632807],
            [-52.093167916992186, 3.470531473632803],
            [-52.09426791699218, 3.466931473632805],
            [-52.094767916992183, 3.462931473632807],
            [-52.099867916992181, 3.459331473632801],
            [-52.102467916992182, 3.456531473632801],
            [-52.105867916992182, 3.454131473632806],
            [-52.108067916992184, 3.451731473632805],
            [-52.109767916992183, 3.448331473632805],
            [-52.111367916992187, 3.444531473632807],
            [-52.112867916992187, 3.4413314736328],
            [-52.113267916992186, 3.4383314736328],
            [-52.114467916992183, 3.434131473632803],
            [-52.117267916992184, 3.432631473632803],
            [-52.119167916992183, 3.429831473632802],
            [-52.120767916992186, 3.427331473632805],
            [-52.12166791699218, 3.424331473632805],
            [-52.124467916992188, 3.419931473632801],
            [-52.127367916992185, 3.415331473632804],
            [-52.12936791699218, 3.4124314736328],
            [-52.131567916992182, 3.4079314736328],
            [-52.133167916992186, 3.404931473632807],
            [-52.136767916992184, 3.397931473632802],
            [-52.138867916992183, 3.392731473632807],
            [-52.142667916992181, 3.386731473632807],
            [-52.145667916992181, 3.381931473632804],
            [-52.148467916992189, 3.378931473632804],
            [-52.150067916992185, 3.375131473632806],
            [-52.150867916992183, 3.371231473632804],
            [-52.153067916992185, 3.367331473632802],
            [-52.155167916992184, 3.363231473632801],
            [-52.157367916992186, 3.359631473632803],
            [-52.159467916992185, 3.355931473632801],
            [-52.161967916992182, 3.351931473632803],
            [-52.165067916992186, 3.349331473632802],
            [-52.167867916992186, 3.346631473632804],
            [-52.169767916992186, 3.344531473632806],
            [-52.172267916992183, 3.339931473632802],
            [-52.173867916992187, 3.337531473632801],
            [-52.173867916992187, 3.333131473632804],
            [-52.175567916992186, 3.328931473632807],
            [-52.177967916992188, 3.326331473632806],
            [-52.181667916992183, 3.324231473632807],
            [-52.184067916992184, 3.322731473632807],
            [-52.186567916992189, 3.320131473632806],
            [-52.187967916992186, 3.317531473632805],
            [-52.188767916992184, 3.314731473632804],
            [-52.189567916992189, 3.311231473632802],
            [-52.19186791699218, 3.306731473632802],
            [-52.18916791699219, 3.302531473632804],
            [-52.190567916992187, 3.297731473632801],
            [-52.19516791699219, 3.294931473632801],
            [-52.198867916992185, 3.291731473632801],
            [-52.203167916992186, 3.286331473632806],
            [-52.20456791699219, 3.283331473632806],
            [-52.206067916992183, 3.280731473632805],
            [-52.209267916992189, 3.278331473632804],
            [-52.211467916992184, 3.275731473632803],
            [-52.213667916992186, 3.271531473632805],
            [-52.215867916992181, 3.267931473632807],
            [-52.218667916992189, 3.262831473632801],
            [-52.221367916992186, 3.259731473632804],
            [-52.223067916992186, 3.256731473632804],
            [-52.224467916992182, 3.252731473632807],
            [-52.227767916992185, 3.248431473632806],
            [-52.230367916992186, 3.245331473632802],
            [-52.232167916992182, 3.242931473632801],
            [-52.235567916992181, 3.239931473632801],
            [-52.23966791699219, 3.238931473632803],
            [-52.242467916992183, 3.237631473632803],
            [-52.246367916992185, 3.240931473632806],
            [-52.246867916992187, 3.245931473632801],
            [-52.24836791699218, 3.249731473632806],
            [-52.253067916992187, 3.251931473632801],
            [-52.25766791699219, 3.252731473632807],
            [-52.261267916992182, 3.252731473632807],
            [-52.263567916992187, 3.250131473632806],
            [-52.263567916992187, 3.246131473632801],
            [-52.266067916992185, 3.243331473632807],
            [-52.269267916992185, 3.241531473632804],
            [-52.272267916992185, 3.239031473632807],
            [-52.273967916992184, 3.236531473632802],
            [-52.276567916992185, 3.234931473632805],
            [-52.279867916992188, 3.2341314736328],
            [-52.283967916992182, 3.231031473632804],
            [-52.287367916992181, 3.228531473632806],
            [-52.291767916992185, 3.226531473632804],
            [-52.29276791699219, 3.221131473632802],
            [-52.293167916992189, 3.218031473632806],
            [-52.293967916992187, 3.215231473632805],
            [-52.295367916992184, 3.212131473632802],
            [-52.296967916992187, 3.209431473632804],
            [-52.299467916992185, 3.206131473632801],
            [-52.301867916992187, 3.201731473632805],
            [-52.302867916992184, 3.197131473632801],
            [-52.302467916992185, 3.193431473632806],
            [-52.301167916992185, 3.188731473632807],
            [-52.300867916992189, 3.184331473632803],
            [-52.299667916992185, 3.179831473632802],
            [-52.299667916992185, 3.1761314736328],
            [-52.301467916992181, 3.173231473632804],
            [-52.306067916992184, 3.174031473632802],
            [-52.311567916992189, 3.175331473632802],
            [-52.315967916992186, 3.173231473632804],
            [-52.319067916992182, 3.171131473632805],
            [-52.323467916992186, 3.170931473632805],
            [-52.327167916992181, 3.171531473632804],
            [-52.330767916992187, 3.171131473632805],
            [-52.330167916992181, 3.167431473632803],
            [-52.330467916992184, 3.163331473632802],
            [-52.332367916992183, 3.160231473632805],
            [-52.333767916992187, 3.155131473632807],
            [-52.335667916992186, 3.150831473632806],
            [-52.337867916992181, 3.148931473632807],
            [-52.340067916992183, 3.145531473632801],
            [-52.336467916992184, 3.141431473632807],
            [-52.331767916992185, 3.137331473632806],
            [-52.334367916992186, 3.134731473632804],
            [-52.339767916992187, 3.134931473632804],
            [-52.343767916992185, 3.134731473632804],
            [-52.346967916992185, 3.136431473632804],
            [-52.349567916992186, 3.133931473632806],
            [-52.352367916992186, 3.128931473632804],
            [-52.352467916992182, 3.125331473632805],
            [-52.350867916992186, 3.122631473632801],
            [-52.348067916992186, 3.120931473632801],
            [-52.343767916992185, 3.120931473632801],
            [-52.339067916992185, 3.120531473632802],
            [-52.338667916992186, 3.116931473632803],
            [-52.340667916992189, 3.114031473632807],
            [-52.342567916992181, 3.111331473632802],
            [-52.345167916992182, 3.108131473632803],
            [-52.345867916992184, 3.104331473632804],
            [-52.345667916992184, 3.100731473632806],
            [-52.344867916992186, 3.096331473632802],
            [-52.341667916992186, 3.092731473632803],
            [-52.338967916992189, 3.089731473632803],
            [-52.335367916992183, 3.0877314736328],
            [-52.330967916992186, 3.084331473632801],
            [-52.327667916992183, 3.081531473632801],
            [-52.326067916992187, 3.0785314736328],
            [-52.326767916992182, 3.074131473632804],
            [-52.328267916992189, 3.070531473632805],
            [-52.330667916992184, 3.067831473632801],
            [-52.333167916992181, 3.063731473632807],
            [-52.334667916992188, 3.060531473632807],
            [-52.336167916992181, 3.057331473632807],
            [-52.337967916992184, 3.054031473632804],
            [-52.341167916992184, 3.050731473632801],
            [-52.343067916992183, 3.046831473632807],
            [-52.345067916992186, 3.043131473632805],
            [-52.346167916992187, 3.038531473632801],
            [-52.348067916992186, 3.035231473632805],
            [-52.350267916992181, 3.031131473632804],
            [-52.352367916992186, 3.026931473632807],
            [-52.35226791699219, 3.0237314736328],
            [-52.35496791699218, 3.0207314736328],
            [-52.358967916992185, 3.018631473632801],
            [-52.362267916992181, 3.015931473632804],
            [-52.363367916992189, 3.0100314736328],
            [-52.363667916992185, 3.006131473632806],
            [-52.365367916992184, 3.0023314736328],
            [-52.366167916992183, 2.997531473632804],
            [-52.36866791699218, 2.993131473632801],
            [-52.370867916992182, 2.991231473632801],
            [-52.370767916992186, 2.988131473632805],
            [-52.369067916992186, 2.985131473632805],
            [-52.368567916992184, 2.981531473632806],
            [-52.369967916992181, 2.9779314736328],
            [-52.371367916992185, 2.974631473632805],
            [-52.372967916992181, 2.9719314736328],
            [-52.374367916992185, 2.968531473632801],
            [-52.375967916992181, 2.965231473632805],
            [-52.377967916992183, 2.962131473632802],
            [-52.379867916992183, 2.958131473632804],
            [-52.381667916992185, 2.953931473632807],
            [-52.383867916992187, 2.950331473632801],
            [-52.387767916992189, 2.948931473632804],
            [-52.391567916992187, 2.948131473632806],
            [-52.393167916992184, 2.945331473632805],
            [-52.39036791699219, 2.942031473632802],
            [-52.39036791699219, 2.937931473632801],
            [-52.392167916992186, 2.933931473632803],
            [-52.394867916992183, 2.931231473632806],
            [-52.39476791699218, 2.9276314736328],
            [-52.39206791699219, 2.925331473632802],
            [-52.389667916992181, 2.922931473632801],
            [-52.389367916992185, 2.919131473632802],
            [-52.386567916992185, 2.917731473632806],
            [-52.38236791699218, 2.918531473632804],
            [-52.378767916992189, 2.920731473632806],
            [-52.377667916992181, 2.915731473632803],
            [-52.380167916992185, 2.912131473632805],
            [-52.38236791699218, 2.909731473632803],
            [-52.384167916992183, 2.907331473632802],
            [-52.386767916992184, 2.904431473632805],
            [-52.389267916992182, 2.900331473632804],
            [-52.393267916992187, 2.8972314736328],
            [-52.397967916992187, 2.897131473632804],
            [-52.401267916992182, 2.897131473632804],
            [-52.404267916992183, 2.900331473632804],
            [-52.407767916992185, 2.899431473632802],
            [-52.409467916992185, 2.897131473632804],
            [-52.41006791699219, 2.894131473632804],
            [-52.413967916992185, 2.893531473632805],
            [-52.418367916992182, 2.893931473632804],
            [-52.42216791699218, 2.891131473632804],
            [-52.423267916992188, 2.887531473632805],
            [-52.424967916992188, 2.8835314736328],
            [-52.42716791699219, 2.879531473632802],
            [-52.429367916992184, 2.876331473632803],
            [-52.430767916992181, 2.873331473632803],
            [-52.433067916992186, 2.870331473632802],
            [-52.434867916992189, 2.867131473632803],
            [-52.435467916992181, 2.864031473632807],
            [-52.437467916992183, 2.861531473632802],
            [-52.439367916992182, 2.858531473632802],
            [-52.441267916992189, 2.854531473632804],
            [-52.444067916992182, 2.851031473632801],
            [-52.445567916992189, 2.847731473632805],
            [-52.447167916992186, 2.843031473632806],
            [-52.449067916992185, 2.840131473632802],
            [-52.450967916992184, 2.835831473632801],
            [-52.451867916992185, 2.831731473632807],
            [-52.453167916992186, 2.828331473632801],
            [-52.454967916992189, 2.8255314736328],
            [-52.456067916992183, 2.821931473632802],
            [-52.458467916992184, 2.818431473632806],
            [-52.461167916992181, 2.814231473632802],
            [-52.462867916992181, 2.811531473632805],
            [-52.464467916992184, 2.808131473632805],
            [-52.466667916992186, 2.804731473632806],
            [-52.468667916992189, 2.801831473632802],
            [-52.470067916992186, 2.798931473632805],
            [-52.472667916992187, 2.795331473632807],
            [-52.473467916992185, 2.792131473632807],
            [-52.473767916992188, 2.789131473632807],
            [-52.475267916992181, 2.784931473632803],
            [-52.477767916992185, 2.781531473632803],
            [-52.478367916992184, 2.778331473632804],
            [-52.477067916992183, 2.774431473632802],
            [-52.476367916992189, 2.769931473632802],
            [-52.47596791699219, 2.765531473632805],
            [-52.478167916992184, 2.762731473632805],
            [-52.481467916992187, 2.761131473632801],
            [-52.482567916992181, 2.757031473632807],
            [-52.479767916992188, 2.753931473632804],
            [-52.480667916992189, 2.750131473632806],
            [-52.482167916992182, 2.747031473632802],
            [-52.482867916992184, 2.744031473632802],
            [-52.485267916992186, 2.740731473632806],
            [-52.486867916992182, 2.736531473632802],
            [-52.488667916992185, 2.733231473632806],
            [-52.490867916992187, 2.730131473632802],
            [-52.494367916992189, 2.727431473632805],
            [-52.496867916992187, 2.725731473632806],
            [-52.498767916992186, 2.723331473632804],
            [-52.499967916992183, 2.720231473632801],
            [-52.500167916992183, 2.716631473632802],
            [-52.502367916992185, 2.713731473632805],
            [-52.504067916992184, 2.711131473632804],
            [-52.504867916992183, 2.708131473632804],
            [-52.506267916992186, 2.704931473632804],
            [-52.50736791699218, 2.701731473632805],
            [-52.50736791699218, 2.698331473632805],
            [-52.50936791699219, 2.695631473632801],
            [-52.512467916992186, 2.692831473632801],
            [-52.51506791699218, 2.690131473632803],
            [-52.517667916992181, 2.687531473632802],
            [-52.518967916992182, 2.684731473632802],
            [-52.52006791699219, 2.681531473632802],
            [-52.519367916992181, 2.676731473632806],
            [-52.521467916992187, 2.674331473632805],
            [-52.522567916992188, 2.671131473632805],
            [-52.525167916992189, 2.666831473632804],
            [-52.527567916992183, 2.662331473632804],
            [-52.526167916992186, 2.659531473632804],
            [-52.528467916992184, 2.656131473632804],
            [-52.529467916992189, 2.652131473632807],
            [-52.531967916992187, 2.650331473632804],
            [-52.53476791699218, 2.650831473632806],
            [-52.53676791699219, 2.652931473632805],
            [-52.541667916992182, 2.653931473632802],
            [-52.545367916992184, 2.651531473632801],
            [-52.549667916992185, 2.649431473632802],
            [-52.55046791699219, 2.645531473632801],
            [-52.552967916992188, 2.6425314736328],
            [-52.55516791699219, 2.640031473632803],
            [-52.555067916992186, 2.636931473632806],
            [-52.553067916992184, 2.634531473632805],
            [-52.551067916992181, 2.631731473632804],
            [-52.546067916992186, 2.630531473632807],
            [-52.543567916992188, 2.626131473632803],
            [-52.54246791699218, 2.622631473632801],
            [-52.543367916992182, 2.618931473632806],
            [-52.54576791699219, 2.614731473632801],
            [-52.549367916992182, 2.612331473632807],
            [-52.546867916992184, 2.609331473632807],
            [-52.543167916992189, 2.607931473632803],
            [-52.540367916992182, 2.606031473632804],
            [-52.53646791699218, 2.603731473632806],
            [-52.533967916992182, 2.600931473632805],
            [-52.531967916992187, 2.598831473632806],
            [-52.530867916992186, 2.595131473632804],
            [-52.529467916992189, 2.589731473632803],
            [-52.527567916992183, 2.584331473632801],
            [-52.527867916992186, 2.580831473632806],
            [-52.530467916992187, 2.578931473632807],
            [-52.533167916992184, 2.575931473632807],
            [-52.53506791699219, 2.5725314736328],
            [-52.537467916992185, 2.567531473632805],
            [-52.538167916992187, 2.564531473632805],
            [-52.53646791699218, 2.561231473632802],
            [-52.537767916992181, 2.558431473632801],
            [-52.540967916992187, 2.559531473632802],
            [-52.54416791699218, 2.561131473632805],
            [-52.548267916992188, 2.562031473632807],
            [-52.551967916992183, 2.5603314736328],
            [-52.554767916992184, 2.557131473632801],
            [-52.557667916992187, 2.5526314736328],
            [-52.56086791699218, 2.550431473632806],
            [-52.560467916992181, 2.546831473632807],
            [-52.558367916992182, 2.543231473632801],
            [-52.556567916992186, 2.540931473632803],
            [-52.55616791699218, 2.536931473632805],
            [-52.554667916992187, 2.532331473632802],
            [-52.550267916992183, 2.530531473632806],
            [-52.551967916992183, 2.525731473632803],
            [-52.552567916992189, 2.520531473632801],
            [-52.557167916992185, 2.519731473632802],
            [-52.560567916992184, 2.518331473632806],
            [-52.564867916992185, 2.516331473632803],
            [-52.568767916992186, 2.512131473632806],
            [-52.572167916992186, 2.5085314736328],
            [-52.574967916992186, 2.505631473632803],
            [-52.577167916992181, 2.503131473632806],
            [-52.579667916992186, 2.501331473632803],
            [-52.582167916992184, 2.498931473632801],
            [-52.584367916992186, 2.495531473632802],
            [-52.587967916992184, 2.493731473632806],
            [-52.588667916992186, 2.488931473632803],
            [-52.591467916992187, 2.485431473632801],
            [-52.593467916992182, 2.481031473632804],
            [-52.594467916992187, 2.476931473632803],
            [-52.598067916992186, 2.475731473632806],
            [-52.601167916992182, 2.476931473632803],
            [-52.604167916992182, 2.476031473632801],
            [-52.607467916992185, 2.476331473632804],
            [-52.611967916992185, 2.475531473632806],
            [-52.616367916992189, 2.475731473632806],
            [-52.619467916992186, 2.473331473632804],
            [-52.621067916992189, 2.470531473632803],
            [-52.62196791699219, 2.466331473632806],
            [-52.620867916992182, 2.462131473632802],
            [-52.616867916992184, 2.459431473632804],
            [-52.614167916992187, 2.457231473632802],
            [-52.611667916992189, 2.455731473632802],
            [-52.610367916992189, 2.452831473632806],
            [-52.612467916992188, 2.450331473632801],
            [-52.614967916992185, 2.448731473632805],
            [-52.616167916992183, 2.446131473632803],
            [-52.620167916992187, 2.445531473632805],
            [-52.625167916992183, 2.445531473632805],
            [-52.629067916992184, 2.444531473632807],
            [-52.631667916992185, 2.442731473632804],
            [-52.633167916992186, 2.445931473632804],
            [-52.636567916992185, 2.444131473632801],
            [-52.637167916992183, 2.440131473632803],
            [-52.640167916992183, 2.437531473632802],
            [-52.643167916992184, 2.435131473632801],
            [-52.645667916992181, 2.432931473632806],
            [-52.645167916992186, 2.428331473632802],
            [-52.643567916992183, 2.424931473632803],
            [-52.644067916992185, 2.420931473632805],
            [-52.646167916992184, 2.417331473632807],
            [-52.649267916992187, 2.414331473632807],
            [-52.652267916992187, 2.410531473632801],
            [-52.654867916992188, 2.406931473632802],
            [-52.65676791699218, 2.404331473632801],
            [-52.658067916992181, 2.401131473632802],
            [-52.655867916992186, 2.398031473632805],
            [-52.654467916992189, 2.393631473632801],
            [-52.656167916992189, 2.390331473632806],
            [-52.657667916992182, 2.386131473632801],
            [-52.659467916992185, 2.381731473632804],
            [-52.660267916992183, 2.378431473632801],
            [-52.661667916992187, 2.3743314736328],
            [-52.666667916992182, 2.373331473632803],
            [-52.669567916992186, 2.371231473632804],
            [-52.671667916992185, 2.369031473632802],
            [-52.675267916992183, 2.369731473632804],
            [-52.679367916992184, 2.370731473632802],
            [-52.682967916992183, 2.369331473632805],
            [-52.686567916992189, 2.367631473632805],
            [-52.689867916992185, 2.367531473632802],
            [-52.692367916992183, 2.365431473632803],
            [-52.696367916992187, 2.363931473632803],
            [-52.701067916992187, 2.362531473632806],
            [-52.705167916992181, 2.360731473632804],
            [-52.709067916992183, 2.358931473632801],
            [-52.712667916992189, 2.356531473632806],
            [-52.714767916992187, 2.354331473632804],
            [-52.717567916992181, 2.351331473632804],
            [-52.718667916992189, 2.348331473632804],
            [-52.717667916992184, 2.344331473632806],
            [-52.72256791699219, 2.341531473632806],
            [-52.72596791699219, 2.339931473632802],
            [-52.728867916992186, 2.339331473632804],
            [-52.732467916992185, 2.338731473632805],
            [-52.735067916992186, 2.336531473632803],
            [-52.738967916992181, 2.336531473632803],
            [-52.74266791699219, 2.333631473632806],
            [-52.746067916992189, 2.331331473632801],
            [-52.748567916992187, 2.329531473632805],
            [-52.751867916992182, 2.328331473632801],
            [-52.755667916992181, 2.326431473632802],
            [-52.759867916992185, 2.325031473632805],
            [-52.763967916992186, 2.323731473632805],
            [-52.766267916992184, 2.320931473632804],
            [-52.76976791699218, 2.321131473632803],
            [-52.771567916992183, 2.318331473632803],
            [-52.775167916992189, 2.315131473632803],
            [-52.778367916992181, 2.317531473632805],
            [-52.780967916992182, 2.314731473632804],
            [-52.783367916992184, 2.311131473632805],
            [-52.787767916992181, 2.310531473632807],
            [-52.790667916992184, 2.307631473632803],
            [-52.793567916992188, 2.304731473632806],
            [-52.796767916992181, 2.304531473632807],
            [-52.801067916992181, 2.301531473632807],
            [-52.805067916992186, 2.300131473632803],
            [-52.809067916992184, 2.298931473632805],
            [-52.812967916992186, 2.296831473632807],
            [-52.817367916992183, 2.295331473632807],
            [-52.821067916992185, 2.294931473632801],
            [-52.825467916992181, 2.293931473632803],
            [-52.829867916992185, 2.293231473632801],
            [-52.833267916992185, 2.292731473632806],
            [-52.835967916992189, 2.290331473632804],
            [-52.840567916992185, 2.290931473632803],
            [-52.842567916992181, 2.287931473632803],
            [-52.839867916992183, 2.2859314736328],
            [-52.838367916992183, 2.282331473632802],
            [-52.840967916992184, 2.280931473632805],
            [-52.84456791699219, 2.280731473632805],
            [-52.848367916992188, 2.280131473632807],
            [-52.853067916992188, 2.2797314736328],
            [-52.857267916992186, 2.281131473632804],
            [-52.856067916992188, 2.276931473632807],
            [-52.856767916992183, 2.274131473632806],
            [-52.85526791699219, 2.271731473632805],
            [-52.852067916992183, 2.269731473632802],
            [-52.853867916992186, 2.265331473632806],
            [-52.857767916992188, 2.262331473632806],
            [-52.861867916992182, 2.260631473632806],
            [-52.865267916992181, 2.261331473632801],
            [-52.868367916992185, 2.259731473632804],
            [-52.871867916992187, 2.259531473632805],
            [-52.871867916992187, 2.256131473632806],
            [-52.872367916992189, 2.252031473632805],
            [-52.873267916992184, 2.247531473632804],
            [-52.875867916992185, 2.243931473632806],
            [-52.875767916992189, 2.240431473632803],
            [-52.879867916992183, 2.238231473632801],
            [-52.885767916992187, 2.238531473632804],
            [-52.886267916992182, 2.234531473632806],
            [-52.886267916992182, 2.229131473632805],
            [-52.887167916992183, 2.224131473632802],
            [-52.886067916992189, 2.220131473632804],
            [-52.888567916992187, 2.218331473632801],
            [-52.891467916992184, 2.216931473632805],
            [-52.892167916992186, 2.213931473632805],
            [-52.892867916992188, 2.211131473632804],
            [-52.894567916992187, 2.208331473632803],
            [-52.897267916992185, 2.2050314736328],
            [-52.899567916992183, 2.200331473632801],
            [-52.901567916992185, 2.197131473632801],
            [-52.902667916992186, 2.193431473632806],
            [-52.90376791699218, 2.187531473632802],
            [-52.908067916992181, 2.189231473632802],
            [-52.910867916992188, 2.188431473632804],
            [-52.913067916992183, 2.191731473632807],
            [-52.916767916992185, 2.192331473632805],
            [-52.919967916992185, 2.192731473632804],
            [-52.923567916992184, 2.190631473632806],
            [-52.929767916992184, 2.1913314736328],
            [-52.933267916992186, 2.194231473632804],
            [-52.936267916992186, 2.192331473632805],
            [-52.938767916992184, 2.189231473632802],
            [-52.938267916992189, 2.186131473632805],
            [-52.938567916992184, 2.182131473632801],
            [-52.941967916992184, 2.178731473632801],
            [-52.946267916992184, 2.177331473632805],
            [-52.950667916992188, 2.175931473632801],
            [-52.955467916992184, 2.173931473632805],
            [-52.959367916992186, 2.174931473632803],
            [-52.962367916992186, 2.173731473632806],
            [-52.965467916992182, 2.1731314736328],
            [-52.968667916992189, 2.172731473632801],
            [-52.971667916992189, 2.171831473632807],
            [-52.975267916992181, 2.171731473632803],
            [-52.978367916992184, 2.168531473632804],
            [-52.982467916992185, 2.168231473632801],
            [-52.986967916992185, 2.168731473632803],
            [-52.989967916992185, 2.171831473632807],
            [-52.993267916992181, 2.173531473632806],
            [-52.995767916992186, 2.175431473632806],
            [-52.998767916992186, 2.178131473632803],
            [-53.001967916992186, 2.1776314736328],
            [-53.004567916992187, 2.179331473632807],
            [-53.007767916992186, 2.178331473632802],
            [-53.01066791699219, 2.179331473632807],
            [-53.013967916992186, 2.179731473632806],
            [-53.018767916992189, 2.181931473632801],
            [-53.02406791699218, 2.182931473632806],
            [-53.028067916992185, 2.184331473632803],
            [-53.030667916992186, 2.186231473632802],
            [-53.035167916992187, 2.186731473632804],
            [-53.039967916992182, 2.186231473632802],
            [-53.04246791699218, 2.1883314736328],
            [-53.045867916992187, 2.189131473632806],
            [-53.049167916992189, 2.190131473632803],
            [-53.052467916992185, 2.190331473632803],
            [-53.054467916992181, 2.193131473632803],
            [-53.057567916992184, 2.195331473632805],
            [-53.061067916992187, 2.198731473632805],
            [-53.065267916992184, 2.2005314736328],
            [-53.069367916992185, 2.2035314736328],
            [-53.07486791699219, 2.207131473632806],
            [-53.07956791699219, 2.210531473632805],
            [-53.084367916992186, 2.212931473632807],
            [-53.089567916992181, 2.212931473632807],
            [-53.096367916992186, 2.213531473632806],
            [-53.099467916992182, 2.214331473632804],
            [-53.112567916992184, 2.2127314736328],
            [-53.117967916992185, 2.211931473632802],
            [-53.126967916992186, 2.211931473632802],
            [-53.133467916992188, 2.211731473632803],
            [-53.138567916992187, 2.211931473632802],
            [-53.144067916992185, 2.210131473632806],
            [-53.148267916992182, 2.208131473632804],
            [-53.155467916992187, 2.206931473632807],
            [-53.163067916992183, 2.204931473632804],
            [-53.166367916992186, 2.205331473632803],
            [-53.171167916992189, 2.204531473632805],
            [-53.175267916992183, 2.204331473632806],
            [-53.177967916992188, 2.203131473632801],
            [-53.180767916992181, 2.200731473632807],
            [-53.185167916992185, 2.199231473632807],
            [-53.189567916992189, 2.199731473632802],
            [-53.192767916992182, 2.199531473632803],
            [-53.19656791699218, 2.198731473632805],
            [-53.19986791699219, 2.199531473632803],
            [-53.208267916992185, 2.199231473632807],
            [-53.209267916992189, 2.196431473632806],
            [-53.212567916992185, 2.195631473632801],
            [-53.215467916992182, 2.193931473632801],
            [-53.217567916992181, 2.195931473632804],
            [-53.220667916992184, 2.193731473632802],
            [-53.224567916992186, 2.196331473632803],
            [-53.228467916992187, 2.197831473632803],
            [-53.229667916992184, 2.200731473632807],
            [-53.234367916992184, 2.205731473632802],
            [-53.23966791699219, 2.207231473632802],
            [-53.242767916992186, 2.207731473632805],
            [-53.245867916992182, 2.205331473632803],
            [-53.246367916992185, 2.201931473632804],
            [-53.247967916992181, 2.199131473632804],
            [-53.252367916992185, 2.198531473632805],
            [-53.255567916992185, 2.197731473632807],
            [-53.256267916992186, 2.194931473632806],
            [-53.257767916992186, 2.191531473632807],
            [-53.261267916992182, 2.189231473632802],
            [-53.265467916992186, 2.188931473632806],
            [-53.269567916992187, 2.190131473632803],
            [-53.272867916992183, 2.191531473632807],
            [-53.275867916992183, 2.194131473632801],
            [-53.278167916992189, 2.192031473632802],
            [-53.276467916992189, 2.1883314736328],
            [-53.279167916992186, 2.186131473632805],
            [-53.283967916992182, 2.189131473632806],
            [-53.286967916992182, 2.196731473632802],
            [-53.284867916992184, 2.201331473632806],
            [-53.279767916992185, 2.206331473632801],
            [-53.279767916992185, 2.213031473632803],
            [-53.278967916992187, 2.217731473632803],
            [-53.277267916992187, 2.221631473632804],
            [-53.279767916992185, 2.223331473632804],
            [-53.276267916992182, 2.225231473632803],
            [-53.271467916992187, 2.224631473632805],
            [-53.267367916992185, 2.223731473632803],
            [-53.263167916992188, 2.228531473632806],
            [-53.26066791699219, 2.235931473632803],
            [-53.260767916992187, 2.242931473632801],
            [-53.260167916992188, 2.246931473632806],
            [-53.260467916992184, 2.251931473632801],
            [-53.253267916992186, 2.246531473632807],
            [-53.249867916992187, 2.248331473632803],
            [-53.246067916992189, 2.2523314736328],
            [-53.241567916992182, 2.254731473632802],
            [-53.237467916992188, 2.258931473632806],
            [-53.232567916992181, 2.260531473632803],
            [-53.229467916992185, 2.262531473632805],
            [-53.229467916992185, 2.265531473632805],
            [-53.230667916992189, 2.268931473632804],
            [-53.233167916992187, 2.2722314736328],
            [-53.23466791699218, 2.275531473632803],
            [-53.245767916992186, 2.273031473632805],
            [-53.26206791699218, 2.276531473632801],
            [-53.263467916992184, 2.279931473632807],
            [-53.261367916992185, 2.289331473632807],
            [-53.265767916992189, 2.291031473632806],
            [-53.273167916992186, 2.296831473632807],
            [-53.277567916992183, 2.298931473632805],
            [-53.282767916992185, 2.309831473632805],
            [-53.288167916992187, 2.309531473632802],
            [-53.292267916992188, 2.310531473632807],
            [-53.296067916992186, 2.309531473632802],
            [-53.305867916992185, 2.315531473632802],
            [-53.308067916992186, 2.317731473632804],
            [-53.311567916992189, 2.325331473632801],
            [-53.314967916992188, 2.326731473632805],
            [-53.316367916992185, 2.3300314736328],
            [-53.316967916992184, 2.339931473632802],
            [-53.32016791699219, 2.344131473632807],
            [-53.323267916992187, 2.347131473632807],
            [-53.326867916992185, 2.344531473632806],
            [-53.330667916992184, 2.343531473632801],
            [-53.333467916992184, 2.344531473632806],
            [-53.335767916992182, 2.347931473632805],
            [-53.338167916992184, 2.353731473632806],
            [-53.343767916992185, 2.355131473632802],
            [-53.348367916992188, 2.354631473632807],
            [-53.352067916992183, 2.351731473632803],
            [-53.35526791699219, 2.3529314736328],
            [-53.35996791699219, 2.349331473632802],
            [-53.35826791699219, 2.345131473632804],
            [-53.35826791699219, 2.341531473632806],
            [-53.35996791699219, 2.333631473632806],
            [-53.362267916992181, 2.328631473632804],
            [-53.365267916992181, 2.324931473632802],
            [-53.367567916992186, 2.320631473632801],
            [-53.377167916992185, 2.317831473632801],
            [-53.380767916992184, 2.315331473632803],
            [-53.38566791699219, 2.308431473632801],
            [-53.389267916992182, 2.308731473632804],
            [-53.394067916992185, 2.307931473632806],
            [-53.398967916992184, 2.308331473632805],
            [-53.400167916992189, 2.304331473632807],
            [-53.402567916992183, 2.300331473632802],
            [-53.405067916992181, 2.297531473632802],
            [-53.408967916992182, 2.297531473632802],
            [-53.413967916992185, 2.297731473632801],
            [-53.41916791699218, 2.292331473632807],
            [-53.421767916992181, 2.289931473632805],
            [-53.433567916992189, 2.287931473632803],
            [-53.440267916992184, 2.280231473632803],
            [-53.44356791699218, 2.280731473632805],
            [-53.447967916992184, 2.284331473632804],
            [-53.452367916992188, 2.285231473632805],
            [-53.455767916992187, 2.286931473632805],
            [-53.461267916992185, 2.291031473632806],
            [-53.468467916992182, 2.289531473632806],
            [-53.469767916992183, 2.2859314736328],
            [-53.468967916992185, 2.2829314736328],
            [-53.465567916992185, 2.276931473632807],
            [-53.461167916992181, 2.276631473632804],
            [-53.456867916992181, 2.273531473632801],
            [-53.454367916992183, 2.270031473632805],
            [-53.454967916992189, 2.264331473632801],
            [-53.458667916992184, 2.261131473632801],
            [-53.473067916992186, 2.256731473632804],
            [-53.475667916992187, 2.258331473632801],
            [-53.478367916992184, 2.261131473632801],
            [-53.485267916992186, 2.264931473632807],
            [-53.489967916992185, 2.264131473632801],
            [-53.495267916992184, 2.261131473632801],
            [-53.502367916992185, 2.262731473632805],
            [-53.505267916992182, 2.261431473632804],
            [-53.509867916992185, 2.262131473632806],
            [-53.516567916992187, 2.265531473632805],
            [-53.520167916992186, 2.266431473632807],
            [-53.525467916992184, 2.264131473632801],
            [-53.527667916992186, 2.261331473632801],
            [-53.532767916992185, 2.257831473632805],
            [-53.537267916992185, 2.259231473632802],
            [-53.540867916992184, 2.257331473632803],
            [-53.544267916992183, 2.259531473632805],
            [-53.546967916992187, 2.258331473632801],
            [-53.549967916992188, 2.258331473632801],
            [-53.551167916992185, 2.260931473632802],
            [-53.554767916992184, 2.260931473632802],
            [-53.557267916992188, 2.263331473632803],
            [-53.560167916992185, 2.267231473632805],
            [-53.565967916992186, 2.268931473632804],
            [-53.567667916992185, 2.271531473632805],
            [-53.575167916992186, 2.272931473632802],
            [-53.578167916992186, 2.272931473632802],
            [-53.580767916992187, 2.270531473632801],
            [-53.584267916992189, 2.268931473632804],
            [-53.587267916992189, 2.2722314736328],
            [-53.592967916992187, 2.276631473632804],
            [-53.595867916992184, 2.279431473632805],
            [-53.598067916992186, 2.284531473632804],
            [-53.601167916992182, 2.286031473632804],
            [-53.604267916992185, 2.286731473632805],
            [-53.606767916992183, 2.285131473632802],
            [-53.611067916992184, 2.286931473632805],
            [-53.61466791699219, 2.282731473632801],
            [-53.614967916992185, 2.278531473632803],
            [-53.620167916992187, 2.279431473632805],
            [-53.622667916992185, 2.283731473632805],
            [-53.626267916992184, 2.284531473632804],
            [-53.63436791699219, 2.281631473632807],
            [-53.638467916992184, 2.283731473632805],
            [-53.643167916992184, 2.283831473632802],
            [-53.648767916992185, 2.283031473632803],
            [-53.652267916992187, 2.283331473632806],
            [-53.656267916992185, 2.284531473632804],
            [-53.656967916992187, 2.288131473632802],
            [-53.654167916992186, 2.293231473632801],
            [-53.653467916992184, 2.297531473632802],
            [-53.653367916992181, 2.302531473632804],
            [-53.661167916992184, 2.299731473632804],
            [-53.662867916992184, 2.3026314736328],
            [-53.663867916992189, 2.305431473632801],
            [-53.666767916992185, 2.305931473632803],
            [-53.671167916992189, 2.305431473632801],
            [-53.676667916992187, 2.302331473632805],
            [-53.682167916992185, 2.301331473632807],
            [-53.680467916992185, 2.297131473632803],
            [-53.684767916992186, 2.294631473632805],
            [-53.68916791699219, 2.299731473632804],
            [-53.691667916992188, 2.303931473632801],
            [-53.692767916992182, 2.307531473632807],
            [-53.692667916992185, 2.310731473632806],
            [-53.695467916992186, 2.312331473632803],
            [-53.699267916992184, 2.311931473632804],
            [-53.70756791699219, 2.309131473632803],
            [-53.714767916992187, 2.312331473632803],
            [-53.720667916992184, 2.313431473632804],
            [-53.728067916992188, 2.313931473632806],
            [-53.731367916992184, 2.309831473632805],
            [-53.739767916992186, 2.309331473632803],
            [-53.748767916992186, 2.312931473632801],
            [-53.749167916992185, 2.316131473632801],
            [-53.749767916992184, 2.319131473632801],
            [-53.74836791699218, 2.3225314736328],
            [-53.745567916992186, 2.323131473632806],
            [-53.741667916992185, 2.324931473632802],
            [-53.737567916992184, 2.323531473632805],
            [-53.736967916992185, 2.327231473632807],
            [-53.739067916992184, 2.329731473632805],
            [-53.73966791699219, 2.335331473632806],
            [-53.737167916992185, 2.338731473632805],
            [-53.730667916992189, 2.340531473632801],
            [-53.728367916992184, 2.342931473632802],
            [-53.725567916992183, 2.349131473632802],
            [-53.723367916992188, 2.3529314736328],
            [-53.727867916992182, 2.351031473632801],
            [-53.730367916992186, 2.352431473632805],
            [-53.733567916992186, 2.356331473632807],
            [-53.738567916992181, 2.362331473632807],
            [-53.741567916992182, 2.361831473632805],
            [-53.744767916992188, 2.358931473632801],
            [-53.748567916992187, 2.359531473632806],
            [-53.752467916992188, 2.364731473632801],
            [-53.744767916992188, 2.366731473632804],
            [-53.740867916992187, 2.369331473632805],
            [-53.739067916992184, 2.372631473632801],
            [-53.741567916992182, 2.375131473632806],
            [-53.748267916992184, 2.374131473632801],
            [-53.75436791699218, 2.375131473632806],
            [-53.757967916992186, 2.375131473632806],
            [-53.760467916992184, 2.3773314736328],
            [-53.767167916992186, 2.378931473632804],
            [-53.771767916992189, 2.377731473632807],
            [-53.773267916992182, 2.373731473632802],
            [-53.776167916992186, 2.372931473632804],
            [-53.784167916992189, 2.372631473632801],
            [-53.791767916992185, 2.364531473632802],
            [-53.798367916992184, 2.365331473632807],
            [-53.803367916992187, 2.359531473632806],
            [-53.805467916992185, 2.357531473632804],
            [-53.810567916992184, 2.357531473632804],
            [-53.813067916992182, 2.353231473632803],
            [-53.812967916992186, 2.348531473632804],
            [-53.814567916992189, 2.342531473632803],
            [-53.819667916992188, 2.340831473632804],
            [-53.82456791699218, 2.340231473632805],
            [-53.833767916992187, 2.340531473632801],
            [-53.836467916992184, 2.334931473632807],
            [-53.83256791699219, 2.328931473632807],
            [-53.827967916992186, 2.328631473632804],
            [-53.824967916992186, 2.3300314736328],
            [-53.820567916992189, 2.329731473632805],
            [-53.817967916992188, 2.327131473632804],
            [-53.815967916992186, 2.322131473632801],
            [-53.814867916992185, 2.317031473632802],
            [-53.818167916992181, 2.316531473632807],
            [-53.823967916992189, 2.317731473632804],
            [-53.830367916992188, 2.313931473632806],
            [-53.83656791699218, 2.310931473632806],
            [-53.840067916992183, 2.309831473632805],
            [-53.842867916992184, 2.308431473632801],
            [-53.849467916992182, 2.309331473632803],
            [-53.853167916992184, 2.311231473632802],
            [-53.854267916992185, 2.315131473632803],
            [-53.85666791699218, 2.318731473632802],
            [-53.859467916992187, 2.320131473632806],
            [-53.864967916992185, 2.312531473632802],
            [-53.872267916992186, 2.3163314736328],
            [-53.875967916992181, 2.315631473632806],
            [-53.878867916992185, 2.316531473632807],
            [-53.883867916992187, 2.3163314736328],
            [-53.886567916992185, 2.314131473632806],
            [-53.88906791699219, 2.307531473632807],
            [-53.889667916992181, 2.304031473632804],
            [-53.887967916992181, 2.300331473632802],
            [-53.889567916992185, 2.295431473632803],
            [-53.892867916992188, 2.291031473632806],
            [-53.889267916992182, 2.286331473632806],
            [-53.892567916992185, 2.283831473632802],
            [-53.897567916992188, 2.287731473632803],
            [-53.903467916992184, 2.280531473632806],
            [-53.905467916992187, 2.275831473632806],
            [-53.907767916992185, 2.274131473632806],
            [-53.916367916992186, 2.277331473632806],
            [-53.917567916992184, 2.280531473632806],
            [-53.917167916992184, 2.284531473632804],
            [-53.915367916992182, 2.286731473632805],
            [-53.915967916992187, 2.290731473632803],
            [-53.918667916992185, 2.292331473632807],
            [-53.922567916992186, 2.292431473632803],
            [-53.928267916992183, 2.290531473632804],
            [-53.935167916992185, 2.297131473632803],
            [-53.937667916992183, 2.2951314736328],
            [-53.939967916992188, 2.292131473632807],
            [-53.93716791699218, 2.289531473632806],
            [-53.936567916992189, 2.286331473632806],
            [-53.93916791699219, 2.284331473632804],
            [-53.942067916992187, 2.278031473632801],
            [-53.937967916992186, 2.270531473632801],
            [-53.938067916992182, 2.266931473632802],
            [-53.938767916992184, 2.263331473632803],
            [-53.935567916992184, 2.259531473632805],
            [-53.939067916992187, 2.254531473632802],
            [-53.942667916992185, 2.252531473632807],
            [-53.942367916992183, 2.242531473632802],
            [-53.945667916992186, 2.241131473632805],
            [-53.950667916992188, 2.242931473632801],
            [-53.954267916992187, 2.245331473632802],
            [-53.960867916992186, 2.243131473632801],
            [-53.964067916992185, 2.243731473632806],
            [-53.967067916992185, 2.243131473632801],
            [-53.970567916992181, 2.238731473632804],
            [-53.973467916992185, 2.233131473632803],
            [-53.979167916992182, 2.233931473632801],
            [-53.985267916992186, 2.235131473632805],
            [-53.989967916992185, 2.237531473632806],
            [-53.994367916992189, 2.236131473632803],
            [-53.996567916992184, 2.233731473632801],
            [-54.001267916992184, 2.230131473632802],
            [-54.189067916992187, 2.178931473632801],
            [-54.436567916992189, 2.209931473632807],
            [-54.601667916992184, 2.337231473632805],
            [-54.662067916992186, 2.327131473632804],
            [-54.692067916992187, 2.361531473632802],
            [-54.686267916992186, 2.411331473632806],
            [-54.684867916992189, 2.446931473632802],
            [-54.744067916992186, 2.471531473632801],
            [-54.783067916992181, 2.447731473632807],
            [-54.79746791699219, 2.439131473632806],
            [-54.872167916992183, 2.433931473632803],
            [-54.911167916992184, 2.489731473632801],
            [-54.91946791699219, 2.499731473632806],
            [-54.935467916992181, 2.518631473632801],
            [-54.954267916992187, 2.583631473632806],
            [-55.003767916992189, 2.591131473632807],
            [-55.008767916992184, 2.587531473632801],
            [-55.05616791699218, 2.553331473632802],
            [-55.103067916992188, 2.525831473632806],
            [-55.12336791699218, 2.567731473632804],
            [-55.172767916992186, 2.559531473632802],
            [-55.20756791699219, 2.522931473632802],
            [-55.234767916992183, 2.503431473632801],
            [-55.277267916992187, 2.512531473632805],
            [-55.32016791699219, 2.515531473632805],
            [-55.356167916992185, 2.475731473632806],
            [-55.345567916992181, 2.448131473632806],
            [-55.38536791699218, 2.418531473632804],
            [-55.429367916992184, 2.439131473632806],
            [-55.482267916992186, 2.438731473632807],
            [-55.49966791699218, 2.443431473632806],
            [-55.504167916992188, 2.439531473632805],
            [-55.508567916992185, 2.438431473632804],
            [-55.513167916992188, 2.438431473632804],
            [-55.517667916992181, 2.435731473632806],
            [-55.520667916992181, 2.437031473632807],
            [-55.524767916992189, 2.435931473632806],
            [-55.528167916992189, 2.431931473632801],
            [-55.527967916992182, 2.428731473632801],
            [-55.551467916992181, 2.429731473632806],
            [-55.557667916992187, 2.425931473632801],
            [-55.560567916992184, 2.425431473632806],
            [-55.564067916992187, 2.426531473632807],
            [-55.56716791699219, 2.424731473632804],
            [-55.570667916992186, 2.423731473632806],
            [-55.571067916992185, 2.428131473632803],
            [-55.572367916992185, 2.430731473632804],
            [-55.574667916992183, 2.437931473632801],
            [-55.57786791699219, 2.435531473632807],
            [-55.587267916992189, 2.434331473632803],
            [-55.589767916992187, 2.431531473632802],
            [-55.593467916992182, 2.433331473632805],
            [-55.599967916992185, 2.430931473632803],
            [-55.604267916992185, 2.430331473632805],
            [-55.609467916992187, 2.428131473632803],
            [-55.612567916992184, 2.426331473632807],
            [-55.617167916992187, 2.419931473632801],
            [-55.624467916992188, 2.4201314736328],
            [-55.631567916992182, 2.417131473632807],
            [-55.63566791699219, 2.418231473632801],
            [-55.642167916992186, 2.416031473632806],
            [-55.646867916992186, 2.416831473632804],
            [-55.651267916992182, 2.418131473632805],
            [-55.654167916992186, 2.419931473632801],
            [-55.657267916992183, 2.420731473632806],
            [-55.662067916992186, 2.419331473632802],
            [-55.66746791699218, 2.414531473632806],
            [-55.673967916992183, 2.413731473632801],
            [-55.681067916992184, 2.416831473632804],
            [-55.686267916992186, 2.417331473632807],
            [-55.69516791699219, 2.421731473632803],
            [-55.698467916992186, 2.421731473632803],
            [-55.699667916992183, 2.417731473632806],
            [-55.700167916992186, 2.413731473632801],
            [-55.703767916992184, 2.408731473632805],
            [-55.704667916992186, 2.405731473632805],
            [-55.707667916992186, 2.403331473632804],
            [-55.717867916992184, 2.402131473632807],
            [-55.727867916992182, 2.406931473632802],
            [-55.73466791699218, 2.406531473632803],
            [-55.737167916992185, 2.409131473632804],
            [-55.74266791699219, 2.408831473632802],
            [-55.74696791699219, 2.410531473632801],
            [-55.752767916992184, 2.417931473632805],
            [-55.754067916992184, 2.421831473632807],
            [-55.753767916992189, 2.425931473632801],
            [-55.756767916992189, 2.430131473632805],
            [-55.76066791699219, 2.430431473632801],
            [-55.763567916992187, 2.433131473632805],
            [-55.767467916992189, 2.438131473632801],
            [-55.761567916992185, 2.440631473632806],
            [-55.76236791699219, 2.4475314736328],
            [-55.764267916992182, 2.452131473632804],
            [-55.766767916992187, 2.455331473632803],
            [-55.770667916992181, 2.456931473632807],
            [-55.776967916992184, 2.460531473632805],
            [-55.781267916992185, 2.461931473632802],
            [-55.783867916992186, 2.456731473632807],
            [-55.790267916992185, 2.458931473632802],
            [-55.794267916992183, 2.457531473632805],
            [-55.798667916992187, 2.459331473632801],
            [-55.802767916992181, 2.459431473632804],
            [-55.810767916992184, 2.457731473632805],
            [-55.815267916992184, 2.459131473632802],
            [-55.827367916992188, 2.460531473632805],
            [-55.830167916992181, 2.459331473632801],
            [-55.835767916992182, 2.460831473632801],
            [-55.838767916992182, 2.462231473632805],
            [-55.843467916992182, 2.465231473632805],
            [-55.847067916992188, 2.466531473632806],
            [-55.850667916992187, 2.469331473632806],
            [-55.853867916992186, 2.477331473632802],
            [-55.854567916992181, 2.480731473632801],
            [-55.858067916992184, 2.482531473632804],
            [-55.861767916992186, 2.483231473632806],
            [-55.866167916992183, 2.481531473632806],
            [-55.868867916992187, 2.483531473632802],
            [-55.870867916992182, 2.486131473632803],
            [-55.873867916992182, 2.488131473632805],
            [-55.880767916992184, 2.489031473632807],
            [-55.882067916992185, 2.494031473632802],
            [-55.884967916992181, 2.498431473632806],
            [-55.886267916992182, 2.503431473632801],
            [-55.891467916992184, 2.505931473632806],
            [-55.897567916992188, 2.505631473632803],
            [-55.900067916992185, 2.509231473632802],
            [-55.899767916992189, 2.514731473632807],
            [-55.901567916992185, 2.517231473632805],
            [-55.906667916992184, 2.519431473632807],
            [-55.910267916992183, 2.520031473632805],
            [-55.914967916992182, 2.519731473632802],
            [-55.919967916992185, 2.517931473632807],
            [-55.924467916992185, 2.518631473632801],
            [-55.921867916992184, 2.522931473632802],
            [-55.922767916992186, 2.527331473632806],
            [-55.924167916992189, 2.530731473632805],
            [-55.92716791699219, 2.531631473632807],
            [-55.930267916992186, 2.531331473632804],
            [-55.934667916992183, 2.533731473632805],
            [-55.942767916992182, 2.531931473632802],
            [-55.945767916992182, 2.532131473632802],
            [-55.953567916992185, 2.530931473632805],
            [-55.959067916992183, 2.531331473632804],
            [-55.970567916992181, 2.529431473632805],
            [-55.973667916992184, 2.525831473632806],
            [-55.978167916992184, 2.527531473632806],
            [-55.978567916992183, 2.522531473632803],
            [-55.979667916992184, 2.518931473632804],
            [-55.983367916992187, 2.519331473632803],
            [-55.989367916992187, 2.517931473632807],
            [-55.991367916992189, 2.512531473632805],
            [-55.992967916992185, 2.504731473632802],
            [-55.991367916992189, 2.502031473632805],
            [-55.98496791699219, 2.499531473632807],
            [-55.982267916992186, 2.497631473632801],
            [-55.977867916992182, 2.496531473632807],
            [-55.977067916992183, 2.492931473632801],
            [-55.977467916992182, 2.489731473632801],
            [-55.979767916992188, 2.485331473632804],
            [-55.988267916992186, 2.478931473632805],
            [-55.988667916992185, 2.470931473632803],
            [-55.991167916992183, 2.467131473632804],
            [-55.996067916992189, 2.465531473632801],
            [-56.000267916992186, 2.463031473632803],
            [-56.001567916992187, 2.460331473632806],
            [-56.005467916992188, 2.459131473632802],
            [-56.008467916992188, 2.454531473632805],
            [-56.011567916992185, 2.452831473632806],
            [-56.01066791699219, 2.449131473632804],
            [-56.008867916992187, 2.444531473632807],
            [-56.005967916992184, 2.446931473632802],
            [-56.003067916992187, 2.445931473632804],
            [-56.000167916992183, 2.442031473632802],
            [-55.99696791699219, 2.4413314736328],
            [-55.995067916992184, 2.435731473632806],
            [-55.992167916992187, 2.438731473632807],
            [-55.989067916992184, 2.436131473632805],
            [-55.985767916992188, 2.434131473632803],
            [-55.985367916992189, 2.429531473632807],
            [-55.98966791699219, 2.427131473632805],
            [-55.991067916992186, 2.422531473632802],
            [-55.987167916992185, 2.420431473632803],
            [-55.989967916992185, 2.417431473632803],
            [-55.995267916992184, 2.416531473632801],
            [-56.001567916992187, 2.413131473632802],
            [-56.003067916992187, 2.408831473632802],
            [-56.005167916992185, 2.406531473632803],
            [-56.007067916992185, 2.401631473632804],
            [-56.011067916992189, 2.400331473632804],
            [-56.014667916992181, 2.399931473632805],
            [-56.015467916992186, 2.397131473632804],
            [-56.017667916992181, 2.394931473632802],
            [-56.020667916992181, 2.393331473632806],
            [-56.023267916992182, 2.390931473632804],
            [-56.024767916992189, 2.382531473632802],
            [-56.032267916992183, 2.378431473632801],
            [-56.033667916992187, 2.374831473632803],
            [-56.036667916992187, 2.3743314736328],
            [-56.037067916992186, 2.370331473632802],
            [-56.039967916992182, 2.366231473632801],
            [-56.033067916992181, 2.364331473632802],
            [-56.026967916992184, 2.355531473632801],
            [-56.025867916992183, 2.350731473632806],
            [-56.026967916992184, 2.347331473632806],
            [-56.022567916992188, 2.347431473632803],
            [-56.021867916992186, 2.342531473632803],
            [-56.029767916992185, 2.339731473632803],
            [-56.028067916992185, 2.336331473632804],
            [-56.029567916992185, 2.332531473632805],
            [-56.03476791699218, 2.332931473632804],
            [-56.03846791699219, 2.3347314736328],
            [-56.04246791699218, 2.333931473632802],
            [-56.046167916992189, 2.333631473632806],
            [-56.050567916992186, 2.335131473632806],
            [-56.05916791699218, 2.343031473632806],
            [-56.060167916992185, 2.346131473632802],
            [-56.059667916992183, 2.351731473632803],
            [-56.061267916992186, 2.355131473632802],
            [-56.064967916992188, 2.357331473632804],
            [-56.06856791699218, 2.361531473632802],
            [-56.069367916992185, 2.364731473632801],
            [-56.071067916992185, 2.368331473632807],
            [-56.073967916992189, 2.370931473632801],
            [-56.076467916992186, 2.369331473632805],
            [-56.080967916992186, 2.369031473632802],
            [-56.080767916992187, 2.373331473632803],
            [-56.084367916992186, 2.375131473632806],
            [-56.086167916992181, 2.372631473632801],
            [-56.090167916992186, 2.372531473632804],
            [-56.089867916992183, 2.368331473632807],
            [-56.089067916992185, 2.364031473632807],
            [-56.092267916992185, 2.360331473632804],
            [-56.090667916992189, 2.357131473632805],
            [-56.088967916992189, 2.351731473632803],
            [-56.090667916992189, 2.348831473632806],
            [-56.090467916992182, 2.345131473632804],
            [-56.093967916992185, 2.341131473632807],
            [-56.095667916992184, 2.338531473632806],
            [-56.092867916992184, 2.334931473632807],
            [-56.091967916992189, 2.3300314736328],
            [-56.093367916992186, 2.3255314736328],
            [-56.093767916992185, 2.321331473632803],
            [-56.101467916992185, 2.320931473632804],
            [-56.107167916992182, 2.318431473632806],
            [-56.109467916992187, 2.315131473632803],
            [-56.113367916992189, 2.311231473632802],
            [-56.112567916992184, 2.307531473632807],
            [-56.114367916992187, 2.303531473632802],
            [-56.114467916992183, 2.298731473632806],
            [-56.117967916992185, 2.295431473632803],
            [-56.124167916992185, 2.291031473632806],
            [-56.128067916992187, 2.286731473632805],
            [-56.12666791699219, 2.283731473632805],
            [-56.125467916992186, 2.278331473632804],
            [-56.127967916992183, 2.275731473632803],
            [-56.13706791699218, 2.270831473632803],
            [-56.138967916992186, 2.265931473632804],
            [-56.13566791699219, 2.263631473632806],
            [-56.132467916992184, 2.263631473632806],
            [-56.12636791699218, 2.259131473632806],
            [-56.123067916992184, 2.258931473632806],
            [-56.120167916992187, 2.255131473632801],
            [-56.120467916992183, 2.250531473632805],
            [-56.112267916992181, 2.247531473632804],
            [-56.108967916992185, 2.244831473632807],
            [-56.10396791699219, 2.245531473632802],
            [-56.09596791699218, 2.241531473632804],
            [-56.095667916992184, 2.246231473632804],
            [-56.091767916992183, 2.248731473632802],
            [-56.087967916992184, 2.250131473632806],
            [-56.085367916992183, 2.246931473632806],
            [-56.083167916992181, 2.243131473632801],
            [-56.079267916992187, 2.241131473632805],
            [-56.077367916992188, 2.237531473632806],
            [-56.073967916992189, 2.237331473632807],
            [-56.070967916992188, 2.239731473632801],
            [-56.068767916992186, 2.242931473632801],
            [-56.065167916992181, 2.241731473632804],
            [-56.058067916992186, 2.233531473632802],
            [-56.055067916992186, 2.232531473632804],
            [-56.048567916992184, 2.232331473632804],
            [-56.046167916992189, 2.229331473632804],
            [-56.042867916992186, 2.2279314736328],
            [-56.04276791699219, 2.2249314736328],
            [-56.043367916992182, 2.221531473632801],
            [-56.037867916992184, 2.219731473632805],
            [-56.036167916992184, 2.216931473632805],
            [-56.036167916992184, 2.213731473632805],
            [-56.040667916992184, 2.209131473632802],
            [-56.042567916992184, 2.206331473632801],
            [-56.043167916992189, 2.201431473632802],
            [-56.046867916992184, 2.196131473632803],
            [-56.051367916992184, 2.192331473632805],
            [-56.05616791699218, 2.189531473632805],
            [-56.055067916992186, 2.184831473632805],
            [-56.049767916992181, 2.184531473632802],
            [-56.048567916992184, 2.180331473632805],
            [-56.045867916992187, 2.177331473632805],
            [-56.042067916992181, 2.1761314736328],
            [-56.040267916992185, 2.173231473632804],
            [-56.037367916992181, 2.171531473632804],
            [-56.03506791699219, 2.168931473632803],
            [-56.027267916992187, 2.169631473632805],
            [-56.020367916992186, 2.167931473632805],
            [-56.01406791699219, 2.165531473632804],
            [-56.011067916992189, 2.166531473632801],
            [-56.003067916992187, 2.167731473632806],
            [-55.999367916992185, 2.164131473632807],
            [-55.995767916992186, 2.161331473632806],
            [-55.99366791699218, 2.158831473632802],
            [-55.993567916992184, 2.154131473632802],
            [-55.991967916992181, 2.151131473632802],
            [-55.987467916992188, 2.148931473632807],
            [-55.986667916992189, 2.145831473632803],
            [-55.989967916992185, 2.1425314736328],
            [-55.99366791699218, 2.139731473632807],
            [-55.992467916992183, 2.136731473632807],
            [-55.991367916992189, 2.131531473632805],
            [-55.987867916992187, 2.132531473632802],
            [-55.982167916992182, 2.132031473632807],
            [-55.975567916992183, 2.126131473632803],
            [-55.975667916992187, 2.119731473632804],
            [-55.978167916992184, 2.115331473632807],
            [-55.973467916992185, 2.116231473632801],
            [-55.969767916992183, 2.113131473632805],
            [-55.973067916992186, 2.103231473632803],
            [-55.971167916992187, 2.0999314736328],
            [-55.966967916992189, 2.088531473632806],
            [-55.96156791699218, 2.088331473632806],
            [-55.953567916992185, 2.091131473632807],
            [-55.948767916992182, 2.091631473632802],
            [-55.94516791699219, 2.089131473632804],
            [-55.943167916992181, 2.085731473632805],
            [-55.941267916992189, 2.081331473632801],
            [-55.938067916992182, 2.078631473632804],
            [-55.935267916992188, 2.077131473632804],
            [-55.929167916992185, 2.078631473632804],
            [-55.926667916992187, 2.075331473632801],
            [-55.928867916992189, 2.068431473632806],
            [-55.932967916992183, 2.061231473632802],
            [-55.932967916992183, 2.057931473632806],
            [-55.929067916992182, 2.057531473632807],
            [-55.926867916992187, 2.054531473632807],
            [-55.923967916992183, 2.051831473632802],
            [-55.920567916992184, 2.052531473632804],
            [-55.908867916992186, 2.047531473632802],
            [-55.905967916992182, 2.044631473632805],
            [-55.903167916992189, 2.041031473632806],
            [-55.902067916992181, 2.029431473632805],
            [-55.90376791699218, 2.026331473632801],
            [-55.907567916992186, 2.0222314736328],
            [-55.910567916992186, 2.022131473632804],
            [-55.910367916992186, 2.0175314736328],
            [-55.910867916992188, 2.0145314736328],
            [-55.90676791699218, 2.013531473632803],
            [-55.905867916992186, 2.009131473632806],
            [-55.908667916992187, 2.008131473632801],
            [-55.908067916992181, 2.001331473632803],
            [-55.912467916992185, 1.994031473632802],
            [-55.913867916992189, 1.990731473632806],
            [-55.918567916992188, 1.991931473632803],
            [-55.922767916992186, 1.991231473632801],
            [-55.925267916992183, 1.994831473632807],
            [-55.929167916992185, 1.997531473632804],
            [-55.931967916992186, 1.995731473632802],
            [-55.935167916992185, 1.996731473632806],
            [-55.936367916992182, 1.986731473632801],
            [-55.936067916992187, 1.983531473632802],
            [-55.931967916992186, 1.971331473632802],
            [-55.928267916992183, 1.969531473632806],
            [-55.927267916992186, 1.9657314736328],
            [-55.926367916992184, 1.957231473632802],
            [-55.92246791699219, 1.9505314736328],
            [-55.922867916992189, 1.947131473632801],
            [-55.929667916992187, 1.939231473632802],
            [-55.927567916992189, 1.936931473632804],
            [-55.923867916992187, 1.937331473632803],
            [-55.921967916992187, 1.933331473632805],
            [-55.920367916992184, 1.928331473632802],
            [-55.920567916992184, 1.919131473632802],
            [-55.911967916992182, 1.913131473632802],
            [-55.91006791699219, 1.910531473632801],
            [-55.901967916992184, 1.9079314736328],
            [-55.900867916992183, 1.903331473632804],
            [-55.901567916992185, 1.899931473632805],
            [-55.901467916992189, 1.896931473632804],
            [-55.90376791699218, 1.888131473632804],
            [-55.909867916992184, 1.887131473632806],
            [-55.914967916992182, 1.884931473632804],
            [-55.923067916992181, 1.879931473632801],
            [-55.924967916992188, 1.876331473632803],
            [-55.929467916992181, 1.873131473632803],
            [-55.934367916992187, 1.871731473632806],
            [-55.935267916992188, 1.867631473632805],
            [-55.938267916992189, 1.866131473632805],
            [-55.939867916992185, 1.861831473632805],
            [-55.944067916992182, 1.858931473632801],
            [-55.954267916992187, 1.848731473632803],
            [-55.956467916992182, 1.845231473632801],
            [-55.962867916992181, 1.848131473632805],
            [-55.966967916992189, 1.847431473632803],
            [-55.970067916992186, 1.848331473632804],
            [-55.97096791699218, 1.844731473632805],
            [-55.97596791699219, 1.840531473632801],
            [-55.979267916992185, 1.842131473632804],
            [-55.982467916992185, 1.839431473632807],
            [-55.98326791699219, 1.835731473632805],
            [-55.995767916992186, 1.835131473632806],
            [-55.999067916992189, 1.831531473632801],
            [-56.004067916992184, 1.833931473632802],
            [-56.007467916992184, 1.836131473632804],
            [-56.011267916992182, 1.835831473632801],
            [-56.02406791699218, 1.842731473632803],
            [-56.02906791699219, 1.841631473632802],
            [-56.032567916992186, 1.845131473632804],
            [-56.036667916992187, 1.850231473632803],
            [-56.041167916992187, 1.8499314736328],
            [-56.043567916992188, 1.847331473632806],
            [-56.046367916992182, 1.849531473632801],
            [-56.049967916992188, 1.851031473632801],
            [-56.059067916992184, 1.850931473632805],
            [-56.066567916992184, 1.851931473632803],
            [-56.075467916992181, 1.8499314736328],
            [-56.077367916992188, 1.845231473632801],
            [-56.081467916992182, 1.845931473632803],
            [-56.083267916992185, 1.850231473632803],
            [-56.085367916992183, 1.853131473632807],
            [-56.090167916992186, 1.853931473632805],
            [-56.092567916992181, 1.851531473632804],
            [-56.095867916992184, 1.849331473632802],
            [-56.098667916992184, 1.846631473632804],
            [-56.104267916992185, 1.849131473632802],
            [-56.112867916992187, 1.855131473632802],
            [-56.11766791699219, 1.851031473632801],
            [-56.11866791699218, 1.854531473632804],
            [-56.120167916992187, 1.857331473632804],
            [-56.126267916992184, 1.857731473632803],
            [-56.129967916992186, 1.859331473632807],
            [-56.131267916992186, 1.862931473632806],
            [-56.13266791699219, 1.870131473632803],
            [-56.136767916992184, 1.873431473632806],
            [-56.141067916992185, 1.875131473632806],
            [-56.146467916992187, 1.881931473632804],
            [-56.150967916992187, 1.885631473632806],
            [-56.152667916992186, 1.890931473632804],
            [-56.156667916992184, 1.891931473632802],
            [-56.160267916992183, 1.891431473632807],
            [-56.165267916992185, 1.887331473632806],
            [-56.168567916992188, 1.887731473632805],
            [-56.170667916992187, 1.891331473632803],
            [-56.168867916992184, 1.897931473632802],
            [-56.170867916992187, 1.900731473632803],
            [-56.177967916992188, 1.896331473632806],
            [-56.182467916992181, 1.892731473632807],
            [-56.191667916992188, 1.893931473632804],
            [-56.195267916992186, 1.892231473632805],
            [-56.198967916992189, 1.892731473632807],
            [-56.200967916992184, 1.896331473632806],
            [-56.20456791699219, 1.896131473632806],
            [-56.208967916992187, 1.894431473632807],
            [-56.212367916992186, 1.899731473632805],
            [-56.215067916992183, 1.902131473632807],
            [-56.234467916992187, 1.8987314736328],
            [-56.235867916992184, 1.893931473632804],
            [-56.237767916992183, 1.889931473632807],
            [-56.240567916992184, 1.886731473632807],
            [-56.240767916992183, 1.883331473632801],
            [-56.241467916992185, 1.879731473632802],
            [-56.244067916992186, 1.878331473632805],
            [-56.248767916992186, 1.879731473632802],
            [-56.255967916992184, 1.884231473632802],
            [-56.258767916992184, 1.885631473632806],
            [-56.263167916992188, 1.885631473632806],
            [-56.265467916992186, 1.889731473632807],
            [-56.272267916992185, 1.896731473632805],
            [-56.275967916992187, 1.896931473632804],
            [-56.280167916992184, 1.899431473632802],
            [-56.282667916992182, 1.902531473632806],
            [-56.289967916992182, 1.906631473632807],
            [-56.290667916992184, 1.910731473632801],
            [-56.295267916992188, 1.908831473632802],
            [-56.298867916992187, 1.909731473632803],
            [-56.302767916992181, 1.911731473632805],
            [-56.304767916992184, 1.915731473632803],
            [-56.308867916992185, 1.917731473632806],
            [-56.309067916992184, 1.921131473632805],
            [-56.311967916992188, 1.9231314736328],
            [-56.317067916992187, 1.922531473632802],
            [-56.321367916992187, 1.923731473632806],
            [-56.324967916992186, 1.9276314736328],
            [-56.327667916992183, 1.929531473632807],
            [-56.32956791699219, 1.932631473632803],
            [-56.332867916992186, 1.934131473632803],
            [-56.335067916992188, 1.937331473632803],
            [-56.339367916992188, 1.935531473632807],
            [-56.341967916992189, 1.937031473632807],
            [-56.348367916992188, 1.939231473632802],
            [-56.351167916992182, 1.937731473632802],
            [-56.354467916992185, 1.938731473632807],
            [-56.35826791699219, 1.941531473632807],
            [-56.364367916992187, 1.938131473632801],
            [-56.369067916992186, 1.937531473632802],
            [-56.37196791699219, 1.932931473632806],
            [-56.378767916992189, 1.931531473632802],
            [-56.380967916992184, 1.933331473632805],
            [-56.385467916992184, 1.932631473632803],
            [-56.388567916992187, 1.932931473632806],
            [-56.39006791699218, 1.928931473632801],
            [-56.388467916992184, 1.9261314736328],
            [-56.39006791699218, 1.923531473632806],
            [-56.397067916992185, 1.923231473632804],
            [-56.399767916992189, 1.9276314736328],
            [-56.403667916992184, 1.930431473632801],
            [-56.408167916992184, 1.929831473632802],
            [-56.411967916992182, 1.928331473632802],
            [-56.41476791699219, 1.931231473632806],
            [-56.419767916992186, 1.937931473632801],
            [-56.422567916992186, 1.939131473632806],
            [-56.423867916992187, 1.942031473632802],
            [-56.422867916992189, 1.945931473632804],
            [-56.428067916992184, 1.945931473632804],
            [-56.432167916992185, 1.946431473632806],
            [-56.43586791699218, 1.949131473632804],
            [-56.436967916992188, 1.951931473632804],
            [-56.441667916992188, 1.951431473632802],
            [-56.444667916992188, 1.948731473632805],
            [-56.451267916992187, 1.956331473632801],
            [-56.454267916992187, 1.952531473632803],
            [-56.46156791699218, 1.950931473632807],
            [-56.465167916992186, 1.951331473632806],
            [-56.468967916992185, 1.949231473632807],
            [-56.473367916992188, 1.950031473632805],
            [-56.482867916992184, 1.954331473632806],
            [-56.487167916992185, 1.951931473632804],
            [-56.487567916992184, 1.946331473632803],
            [-56.49266791699219, 1.941731473632807],
            [-56.495567916992186, 1.938131473632801],
            [-56.498267916992184, 1.936931473632804],
            [-56.50136791699218, 1.936931473632804],
            [-56.503867916992185, 1.934531473632802],
            [-56.509667916992186, 1.932631473632803],
            [-56.513767916992187, 1.932131473632801],
            [-56.517967916992184, 1.932531473632807],
            [-56.522567916992188, 1.929331473632807],
            [-56.524867916992186, 1.924731473632804],
            [-56.531267916992185, 1.922331473632802],
            [-56.535267916992183, 1.921831473632807],
            [-56.538167916992187, 1.918731473632803],
            [-56.541367916992186, 1.917131473632807],
            [-56.546667916992185, 1.915531473632804],
            [-56.552567916992189, 1.918131473632805],
            [-56.555567916992189, 1.917431473632803],
            [-56.56086791699218, 1.911031473632804],
            [-56.570967916992188, 1.909731473632803],
            [-56.577067916992185, 1.907731473632801],
            [-56.579667916992186, 1.905931473632805],
            [-56.58256791699219, 1.907331473632802],
            [-56.58656791699218, 1.911031473632804],
            [-56.587267916992189, 1.915331473632804],
            [-56.590067916992183, 1.921531473632804],
            [-56.59456791699219, 1.9231314736328],
            [-56.596467916992182, 1.9261314736328],
            [-56.595567916992181, 1.929731473632806],
            [-56.596167916992187, 1.932931473632806],
            [-56.598467916992185, 1.936531473632805],
            [-56.60396791699219, 1.936131473632805],
            [-56.607867916992184, 1.934531473632802],
            [-56.612467916992188, 1.937031473632807],
            [-56.617967916992185, 1.943931473632801],
            [-56.621567916992184, 1.946131473632803],
            [-56.624467916992188, 1.945331473632805],
            [-56.629967916992186, 1.942531473632805],
            [-56.632167916992188, 1.9383314736328],
            [-56.636567916992185, 1.936731473632804],
            [-56.639667916992181, 1.934331473632803],
            [-56.641067916992185, 1.931531473632802],
            [-56.646167916992184, 1.930131473632805],
            [-56.64906791699218, 1.931231473632806],
            [-56.650067916992185, 1.928131473632803],
            [-56.650067916992185, 1.922331473632802],
            [-56.651167916992186, 1.917331473632807],
            [-56.654767916992185, 1.918231473632801],
            [-56.662267916992185, 1.915331473632804],
            [-56.666967916992185, 1.915531473632804],
            [-56.669967916992185, 1.913331473632802],
            [-56.673567916992184, 1.912931473632803],
            [-56.676067916992181, 1.918931473632803],
            [-56.679167916992185, 1.921131473632805],
            [-56.681567916992186, 1.919331473632802],
            [-56.681867916992189, 1.913831473632804],
            [-56.686567916992189, 1.913331473632802],
            [-56.692367916992183, 1.915331473632804],
            [-56.695667916992186, 1.917331473632807],
            [-56.702367916992188, 1.920431473632803],
            [-56.704667916992186, 1.924031473632802],
            [-56.709567916992185, 1.922731473632801],
            [-56.713467916992187, 1.924331473632805],
            [-56.71626791699218, 1.9261314736328],
            [-56.72096791699218, 1.925931473632801],
            [-56.722867916992186, 1.922131473632803],
            [-56.72696791699218, 1.918731473632803],
            [-56.730367916992186, 1.918231473632801],
            [-56.734767916992183, 1.917331473632807],
            [-56.736167916992187, 1.914631473632802],
            [-56.737567916992184, 1.908731473632805],
            [-56.743267916992181, 1.906531473632803],
            [-56.746267916992181, 1.903031473632801],
            [-56.749867916992187, 1.902931473632805],
            [-56.750567916992182, 1.896331473632806],
            [-56.753567916992182, 1.892731473632807],
            [-56.75636791699219, 1.890731473632805],
            [-56.762167916992183, 1.889931473632807],
            [-56.764967916992184, 1.888531473632803],
            [-56.766267916992184, 1.8835314736328],
            [-56.766067916992185, 1.880331473632801],
            [-56.768167916992184, 1.876131473632803],
            [-56.773967916992184, 1.868931473632806],
            [-56.781667916992184, 1.864531473632802],
            [-56.785867916992188, 1.865331473632807],
            [-56.788667916992182, 1.864331473632802],
            [-56.787867916992184, 1.858731473632801],
            [-56.788067916992183, 1.854531473632804],
            [-56.791367916992186, 1.852431473632805],
            [-56.797867916992189, 1.853531473632806],
            [-56.798567916992184, 1.857331473632804],
            [-56.79576791699219, 1.858231473632806],
            [-56.79576791699219, 1.861531473632802],
            [-56.798867916992187, 1.863731473632804],
            [-56.801067916992181, 1.866131473632805],
            [-56.798567916992184, 1.868731473632806],
            [-56.798867916992187, 1.872631473632801],
            [-56.801467916992181, 1.874131473632801],
            [-56.809367916992187, 1.873931473632801],
            [-56.812967916992186, 1.876731473632802],
            [-56.81716791699219, 1.877731473632807],
            [-56.820967916992188, 1.879231473632807],
            [-56.82156791699218, 1.883331473632801],
            [-56.825667916992188, 1.884131473632806],
            [-56.828567916992185, 1.881731473632804],
            [-56.830967916992186, 1.888931473632802],
            [-56.834367916992186, 1.889331473632801],
            [-56.837867916992181, 1.888131473632804],
            [-56.845867916992184, 1.893331473632806],
            [-56.849167916992187, 1.892131473632801],
            [-56.852067916992183, 1.891931473632802],
            [-56.856367916992184, 1.889331473632801],
            [-56.85966791699218, 1.890731473632805],
            [-56.858667916992189, 1.895331473632801],
            [-56.861867916992182, 1.8957314736328],
            [-56.86466791699219, 1.896731473632805],
            [-56.866667916992185, 1.893331473632806],
            [-56.88106791699218, 1.8972314736328],
            [-56.886867916992188, 1.897131473632804],
            [-56.888167916992188, 1.900131473632804],
            [-56.891467916992184, 1.901931473632807],
            [-56.893467916992186, 1.899431473632802],
            [-56.899867916992186, 1.904131473632802],
            [-56.907267916992183, 1.910531473632801],
            [-56.906267916992185, 1.915331473632804],
            [-56.908867916992186, 1.917131473632807],
            [-56.910967916992185, 1.922531473632802],
            [-56.915267916992185, 1.923931473632805],
            [-56.919767916992186, 1.930431473632801],
            [-56.923067916992181, 1.929331473632807],
            [-56.925067916992184, 1.926831473632802],
            [-56.932167916992185, 1.926531473632807],
            [-56.940467916992183, 1.921531473632804],
            [-56.94986791699219, 1.919531473632802],
            [-56.950667916992188, 1.922731473632801],
            [-56.955067916992185, 1.922931473632801],
            [-56.959267916992189, 1.924531473632804],
            [-56.961267916992185, 1.920431473632803],
            [-56.968167916992186, 1.916731473632801],
            [-56.968467916992182, 1.912331473632804],
            [-56.97096791699218, 1.908831473632802],
            [-56.971467916992182, 1.905731473632805],
            [-56.976167916992182, 1.903031473632801],
            [-56.981467916992187, 1.904431473632805],
            [-56.982867916992184, 1.907331473632802],
            [-56.987467916992188, 1.906331473632804],
            [-56.991667916992185, 1.907431473632805],
            [-56.99666791699218, 1.9079314736328],
            [-57.000767916992189, 1.907331473632802],
            [-57.004467916992184, 1.9109314736328],
            [-57.009167916992183, 1.917131473632807],
            [-57.014267916992182, 1.915131473632805],
            [-57.017567916992185, 1.918931473632803],
            [-57.024467916992187, 1.927931473632803],
            [-57.023767916992185, 1.933931473632803],
            [-57.025067916992185, 1.9398314736328],
            [-57.028467916992184, 1.942031473632802],
            [-57.030867916992186, 1.944231473632804],
            [-57.031967916992187, 1.947131473632801],
            [-57.03476791699218, 1.948731473632805],
            [-57.044267916992183, 1.951331473632806],
            [-57.04746791699219, 1.953331473632801],
            [-57.050367916992187, 1.953631473632804],
            [-57.053867916992189, 1.952531473632803],
            [-57.056567916992186, 1.956931473632807],
            [-57.060767916992184, 1.957931473632804],
            [-57.064067916992187, 1.956131473632801],
            [-57.066567916992184, 1.961131473632804],
            [-57.067367916992183, 1.964431473632807],
            [-57.071267916992184, 1.968831473632804],
            [-57.07156791699218, 1.973131473632805],
            [-57.070467916992186, 1.976931473632803],
            [-57.070467916992186, 1.980531473632801],
            [-57.072367916992185, 1.984331473632807],
            [-57.07016791699219, 1.989531473632802],
            [-57.071267916992184, 1.996731473632806],
            [-57.073467916992186, 2.002531473632807],
            [-57.077067916992185, 2.005931473632806],
            [-57.08256791699219, 2.007731473632802],
            [-57.082667916992186, 2.013931473632802],
            [-57.081567916992185, 2.018931473632804],
            [-57.083967916992187, 2.021731473632805],
            [-57.086767916992187, 2.026531473632801],
            [-57.090467916992182, 2.025531473632803],
            [-57.094167916992184, 2.022531473632803],
            [-57.096367916992186, 2.017931473632807],
            [-57.100067916992188, 2.018931473632804],
            [-57.102567916992186, 2.022731473632803],
            [-57.106467916992187, 2.026931473632807],
            [-57.111667916992189, 2.0297314736328],
            [-57.116667916992185, 2.028031473632801],
            [-57.117467916992183, 2.024431473632802],
            [-57.116567916992182, 2.019731473632802],
            [-57.116367916992189, 2.015031473632803],
            [-57.119967916992181, 2.010331473632803],
            [-57.129867916992183, 2.006531473632805],
            [-57.132167916992188, 2.002731473632807],
            [-57.140767916992189, 2.001131473632803],
            [-57.146467916992187, 1.995331473632802],
            [-57.14506791699219, 1.991531473632804],
            [-57.147567916992188, 1.989031473632807],
            [-57.153067916992185, 1.992631473632805],
            [-57.158367916992184, 1.991931473632803],
            [-57.163967916992185, 1.994831473632807],
            [-57.168867916992184, 1.994331473632805],
            [-57.179167916992185, 1.990331473632807],
            [-57.183367916992182, 1.979631473632807],
            [-57.186567916992189, 1.978931473632805],
            [-57.189467916992186, 1.979631473632807],
            [-57.194267916992189, 1.978531473632806],
            [-57.199967916992186, 1.978931473632805],
            [-57.203267916992189, 1.976731473632803],
            [-57.20726791699218, 1.970931473632803],
            [-57.211167916992181, 1.968831473632804],
            [-57.210067916992188, 1.966131473632807],
            [-57.214767916992187, 1.961531473632803],
            [-57.220067916992186, 1.959431473632804],
            [-57.222367916992184, 1.956531473632801],
            [-57.225567916992183, 1.956731473632807],
            [-57.229167916992182, 1.953631473632804],
            [-57.229167916992182, 1.950331473632801],
            [-57.230067916992184, 1.945631473632801],
            [-57.229267916992185, 1.937731473632802],
            [-57.232267916992186, 1.936931473632804],
            [-57.237567916992184, 1.939531473632805],
            [-57.237767916992183, 1.943431473632806],
            [-57.241167916992183, 1.943431473632806],
            [-57.244167916992183, 1.947331473632801],
            [-57.247167916992183, 1.948531473632805],
            [-57.252967916992183, 1.948531473632805],
            [-57.255467916992188, 1.9505314736328],
            [-57.25766791699219, 1.954931473632804],
            [-57.25636791699219, 1.961331473632804],
            [-57.26236791699219, 1.961931473632802],
            [-57.264567916992185, 1.966331473632806],
            [-57.266867916992183, 1.969331473632806],
            [-57.271167916992184, 1.969931473632805],
            [-57.273467916992189, 1.974331473632802],
            [-57.270767916992185, 1.976531473632804],
            [-57.267867916992188, 1.979931473632803],
            [-57.268967916992182, 1.983731473632801],
            [-57.272367916992181, 1.983731473632801],
            [-57.277267916992187, 1.983131473632803],
            [-57.284267916992185, 1.986131473632803],
            [-57.287767916992181, 1.988231473632801],
            [-57.301167916992185, 1.995531473632802],
            [-57.304367916992184, 1.997631473632801],
            [-57.307167916992185, 1.996731473632806],
            [-57.30916791699218, 1.992131473632803],
            [-57.312667916992183, 1.981331473632807],
            [-57.313267916992189, 1.977331473632802],
            [-57.31886791699219, 1.977331473632802],
            [-57.323967916992189, 1.9779314736328],
            [-57.329267916992187, 1.979631473632807],
            [-57.332967916992189, 1.977131473632802],
            [-57.338167916992184, 1.975231473632803],
            [-57.345667916992184, 1.980331473632802],
            [-57.351767916992188, 1.976531473632804],
            [-57.356367916992184, 1.970531473632803],
            [-57.35666791699218, 1.966131473632807],
            [-57.354267916992185, 1.961331473632804],
            [-57.358967916992185, 1.958931473632802],
            [-57.365367916992184, 1.958131473632804],
            [-57.369167916992183, 1.956531473632801],
            [-57.368267916992181, 1.952231473632807],
            [-57.361867916992182, 1.948331473632805],
            [-57.357067916992186, 1.947331473632801],
            [-57.35666791699218, 1.944231473632804],
            [-57.35996791699219, 1.940631473632806],
            [-57.361167916992187, 1.936531473632805],
            [-57.361467916992183, 1.932131473632801],
            [-57.364967916992185, 1.9261314736328],
            [-57.367767916992186, 1.923931473632805],
            [-57.370567916992186, 1.922531473632802],
            [-57.375767916992189, 1.922531473632802],
            [-57.383667916992181, 1.927031473632802],
            [-57.392167916992186, 1.924731473632804],
            [-57.395767916992185, 1.924031473632802],
            [-57.398467916992189, 1.921831473632807],
            [-57.402867916992186, 1.912931473632803],
            [-57.40676791699218, 1.911731473632805],
            [-57.410667916992182, 1.909931473632803],
            [-57.413667916992182, 1.909731473632803],
            [-57.41746791699218, 1.910731473632801],
            [-57.419967916992185, 1.909131473632804],
            [-57.423567916992184, 1.908331473632806],
            [-57.427767916992181, 1.909531473632804],
            [-57.431067916992184, 1.908031473632803],
            [-57.433367916992182, 1.906131473632804],
            [-57.433667916992185, 1.901131473632802],
            [-57.432567916992184, 1.8972314736328],
            [-57.432467916992181, 1.890931473632804],
            [-57.43446791699219, 1.886931473632806],
            [-57.436267916992186, 1.880631473632803],
            [-57.434067916992184, 1.877731473632807],
            [-57.429367916992184, 1.874131473632801],
            [-57.432267916992188, 1.869531473632804],
            [-57.431967916992186, 1.858531473632802],
            [-57.436367916992182, 1.853131473632807],
            [-57.440167916992181, 1.850931473632805],
            [-57.439567916992189, 1.845131473632804],
            [-57.444967916992184, 1.841331473632806],
            [-57.444067916992182, 1.836731473632803],
            [-57.441067916992182, 1.835331473632806],
            [-57.43916791699219, 1.832931473632804],
            [-57.437767916992186, 1.8300314736328],
            [-57.437767916992186, 1.826931473632804],
            [-57.440167916992181, 1.823531473632805],
            [-57.44486791699218, 1.8225314736328],
            [-57.446267916992184, 1.817731473632804],
            [-57.448867916992185, 1.812031473632807],
            [-57.451867916992185, 1.811531473632805],
            [-57.450967916992184, 1.806531473632802],
            [-57.453267916992189, 1.803931473632801],
            [-57.457067916992187, 1.802131473632805],
            [-57.460667916992186, 1.803331473632802],
            [-57.463767916992182, 1.800431473632806],
            [-57.468367916992186, 1.799731473632804],
            [-57.474167916992187, 1.796131473632805],
            [-57.476367916992189, 1.799031473632802],
            [-57.477767916992185, 1.794631473632805],
            [-57.48166791699218, 1.795431473632803],
            [-57.482167916992182, 1.798531473632806],
            [-57.485267916992186, 1.800131473632803],
            [-57.486667916992189, 1.796131473632805],
            [-57.487167916992185, 1.792731473632806],
            [-57.490867916992187, 1.790731473632803],
            [-57.49366791699218, 1.792731473632806],
            [-57.499867916992187, 1.788831473632804],
            [-57.501867916992182, 1.786031473632804],
            [-57.500167916992183, 1.781531473632803],
            [-57.499167916992185, 1.776531473632801],
            [-57.49696791699219, 1.773531473632801],
            [-57.497667916992185, 1.770331473632801],
            [-57.497367916992189, 1.766931473632802],
            [-57.499867916992187, 1.761331473632801],
            [-57.501867916992182, 1.758131473632801],
            [-57.508567916992185, 1.757531473632802],
            [-57.509867916992185, 1.754531473632802],
            [-57.514567916992185, 1.747331473632805],
            [-57.516067916992185, 1.742131473632803],
            [-57.51536791699219, 1.736831473632805],
            [-57.514367916992185, 1.733931473632801],
            [-57.516767916992187, 1.731831473632802],
            [-57.515967916992182, 1.7279314736328],
            [-57.514267916992182, 1.723131473632805],
            [-57.518967916992182, 1.721331473632802],
            [-57.521767916992189, 1.723331473632804],
            [-57.522067916992185, 1.726331473632804],
            [-57.526167916992186, 1.728131473632807],
            [-57.529167916992186, 1.723831473632806],
            [-57.531967916992187, 1.721631473632804],
            [-57.53676791699219, 1.722331473632806],
            [-57.541367916992186, 1.720531473632803],
            [-57.540267916992185, 1.716931473632805],
            [-57.536667916992187, 1.714731473632803],
            [-57.535267916992183, 1.711531473632803],
            [-57.534167916992189, 1.708331473632803],
            [-57.535167916992187, 1.704331473632806],
            [-57.537767916992181, 1.700731473632807],
            [-57.541667916992182, 1.698731473632805],
            [-57.550367916992187, 1.700731473632807],
            [-57.55216791699219, 1.698131473632806],
            [-57.553267916992183, 1.692531473632805],
            [-57.557767916992184, 1.692331473632805],
            [-57.561067916992187, 1.694531473632807],
            [-57.564567916992189, 1.695931473632804],
            [-57.572667916992188, 1.688931473632806],
            [-57.577167916992181, 1.690531473632802],
            [-57.577167916992181, 1.699931473632802],
            [-57.576067916992187, 1.702731473632802],
            [-57.581767916992185, 1.706331473632801],
            [-57.583967916992187, 1.702131473632804],
            [-57.590167916992186, 1.700031473632805],
            [-57.592967916992187, 1.701931473632804],
            [-57.597867916992186, 1.700731473632807],
            [-57.600067916992188, 1.6975314736328],
            [-57.604767916992188, 1.693131473632803],
            [-57.60996791699219, 1.692831473632801],
            [-57.614767916992186, 1.695631473632801],
            [-57.619467916992186, 1.695931473632804],
            [-57.62196791699219, 1.699931473632802],
            [-57.62936791699218, 1.698531473632805],
            [-57.632967916992186, 1.691531473632807],
            [-57.636467916992189, 1.690631473632806],
            [-57.641567916992187, 1.686731473632804],
            [-57.645367916992186, 1.685931473632806],
            [-57.650467916992184, 1.682531473632807],
            [-57.654167916992186, 1.686531473632805],
            [-57.655567916992183, 1.690531473632802],
            [-57.660567916992186, 1.689231473632802],
            [-57.662867916992184, 1.692731473632804],
            [-57.667867916992186, 1.695631473632801],
            [-57.669567916992186, 1.700031473632805],
            [-57.668167916992189, 1.702731473632802],
            [-57.670667916992187, 1.708131473632804],
            [-57.67246791699219, 1.710831473632801],
            [-57.675067916992184, 1.706131473632801],
            [-57.684067916992184, 1.705731473632802],
            [-57.686967916992188, 1.706531473632801],
            [-57.689567916992189, 1.708631473632806],
            [-57.692367916992183, 1.712231473632805],
            [-57.69816791699219, 1.713931473632805],
            [-57.697067916992182, 1.717131473632804],
            [-57.697167916992186, 1.720931473632803],
            [-57.705167916992181, 1.731031473632804],
            [-57.708167916992181, 1.731331473632807],
            [-57.711967916992187, 1.728731473632806],
            [-57.711467916992184, 1.724331473632802],
            [-57.708167916992181, 1.720931473632803],
            [-57.710167916992184, 1.717731473632803],
            [-57.718467916992182, 1.715531473632801],
            [-57.716467916992187, 1.720931473632803],
            [-57.719767916992183, 1.723131473632805],
            [-57.724867916992181, 1.720231473632801],
            [-57.731167916992185, 1.718831473632804],
            [-57.736167916992187, 1.719531473632806],
            [-57.738967916992181, 1.722431473632803],
            [-57.744967916992181, 1.722931473632805],
            [-57.752167916992185, 1.719131473632807],
            [-57.758267916992182, 1.718731473632801],
            [-57.760267916992184, 1.722731473632805],
            [-57.763267916992184, 1.723331473632804],
            [-57.766767916992187, 1.722731473632805],
            [-57.768967916992182, 1.727431473632805],
            [-57.77436791699219, 1.729931473632803],
            [-57.777067916992181, 1.728231473632803],
            [-57.777667916992186, 1.723131473632805],
            [-57.780367916992184, 1.719331473632806],
            [-57.783067916992181, 1.721131473632802],
            [-57.785367916992186, 1.724331473632802],
            [-57.792267916992188, 1.726731473632803],
            [-57.793167916992189, 1.719731473632805],
            [-57.795567916992184, 1.713931473632805],
            [-57.794667916992182, 1.710331473632806],
            [-57.795367916992184, 1.706531473632801],
            [-57.796967916992187, 1.703331473632801],
            [-57.799967916992188, 1.701931473632804],
            [-57.803667916992183, 1.701931473632804],
            [-57.803367916992187, 1.698531473632805],
            [-57.798367916992184, 1.697131473632801],
            [-57.796367916992182, 1.691131473632801],
            [-57.797567916992186, 1.687331473632803],
            [-57.799767916992181, 1.6853314736328],
            [-57.803067916992184, 1.684731473632802],
            [-57.80916791699218, 1.688431473632804],
            [-57.81386791699218, 1.687031473632807],
            [-57.819567916992185, 1.683931473632803],
            [-57.82286791699218, 1.685131473632801],
            [-57.82786791699219, 1.685131473632801],
            [-57.836467916992184, 1.686731473632804],
            [-57.840067916992183, 1.683731473632804],
            [-57.841167916992184, 1.686531473632805],
            [-57.844167916992184, 1.689131473632806],
            [-57.846767916992185, 1.687031473632807],
            [-57.846967916992185, 1.681131473632803],
            [-57.849967916992185, 1.679331473632807],
            [-57.853067916992188, 1.677931473632803],
            [-57.853667916992187, 1.674731473632804],
            [-57.850667916992187, 1.673531473632806],
            [-57.852067916992183, 1.667931473632805],
            [-57.857167916992182, 1.668731473632803],
            [-57.863267916992186, 1.673931473632805],
            [-57.866567916992182, 1.674731473632804],
            [-57.870467916992183, 1.676731473632806],
            [-57.87336791699218, 1.680431473632801],
            [-57.877167916992185, 1.680431473632801],
            [-57.878467916992186, 1.676331473632807],
            [-57.877167916992185, 1.671831473632807],
            [-57.880767916992184, 1.670331473632807],
            [-57.884667916992186, 1.669631473632805],
            [-57.889267916992182, 1.674331473632805],
            [-57.89506791699219, 1.674531473632804],
            [-57.899767916992189, 1.671131473632805],
            [-57.902067916992181, 1.667931473632805],
            [-57.902067916992181, 1.660231473632805],
            [-57.904767916992185, 1.655931473632805],
            [-57.908967916992182, 1.651331473632801],
            [-57.91346791699219, 1.647731473632803],
            [-57.92076791699219, 1.646731473632805],
            [-57.926867916992187, 1.644931473632802],
            [-57.937467916992183, 1.646531473632805],
            [-57.940567916992187, 1.648531473632801],
            [-57.94516791699219, 1.6487314736328],
            [-57.950267916992189, 1.647731473632803],
            [-57.953967916992184, 1.650531473632803],
            [-57.959367916992186, 1.651331473632801],
            [-57.957867916992186, 1.654331473632801],
            [-57.961967916992187, 1.657331473632802],
            [-57.965067916992183, 1.655231473632803],
            [-57.970567916992181, 1.654431473632805],
            [-57.978867916992186, 1.655931473632805],
            [-57.981067916992188, 1.659531473632804],
            [-57.985767916992188, 1.660231473632805],
            [-57.990067916992189, 1.658531473632806],
            [-57.990267916992181, 1.651531473632801],
            [-58.002367916992185, 1.649431473632802],
            [-58.002667916992181, 1.645831473632803],
            [-57.996267916992181, 1.644431473632807],
            [-57.992167916992187, 1.641131473632804],
            [-57.989067916992184, 1.640531473632805],
            [-57.986467916992183, 1.636931473632806],
            [-57.982567916992181, 1.635531473632803],
            [-57.980367916992186, 1.633331473632801],
            [-57.979567916992181, 1.628731473632804],
            [-57.982767916992188, 1.626731473632802],
            [-57.982767916992188, 1.621931473632806],
            [-57.982067916992186, 1.617931473632801],
            [-57.985367916992189, 1.616931473632803],
            [-57.98496791699219, 1.613131473632805],
            [-57.982767916992188, 1.608531473632802],
            [-57.98326791699219, 1.603931473632805],
            [-57.981767916992183, 1.600231473632803],
            [-57.981367916992184, 1.596531473632801],
            [-57.983367916992187, 1.590531473632801],
            [-57.983867916992182, 1.586331473632804],
            [-57.982567916992181, 1.579531473632805],
            [-57.978567916992183, 1.572831473632803],
            [-57.980367916992186, 1.569131473632801],
            [-57.982567916992181, 1.565931473632801],
            [-57.986067916992184, 1.567031473632802],
            [-57.989967916992185, 1.567531473632805],
            [-57.988867916992184, 1.562531473632802],
            [-57.991867916992184, 1.561531473632805],
            [-57.99366791699218, 1.556531473632802],
            [-57.994367916992189, 1.553331473632802],
            [-57.992167916992187, 1.550131473632803],
            [-57.989367916992187, 1.547731473632801],
            [-57.988667916992185, 1.543931473632803],
            [-57.990267916992181, 1.540731473632803],
            [-57.994967916992181, 1.538831473632804],
            [-57.996867916992187, 1.534531473632804],
            [-57.998867916992182, 1.530731473632805],
            [-58.001267916992184, 1.528331473632804],
            [-58.000467916992186, 1.520831473632803],
            [-58.005567916992185, 1.516931473632802],
            [-58.005267916992182, 1.513131473632804],
            [-58.004167916992188, 1.509931473632804],
            [-58.004167916992188, 1.503131473632806],
            [-58.008867916992187, 1.505131473632801],
            [-58.01536791699219, 1.505931473632806],
            [-58.021167916992184, 1.507831473632805],
            [-58.023967916992184, 1.510931473632802],
            [-58.027267916992187, 1.508731473632807],
            [-58.029467916992189, 1.513331473632803],
            [-58.036167916992184, 1.518631473632801],
            [-58.040567916992188, 1.521131473632806],
            [-58.043867916992184, 1.519731473632802],
            [-58.052467916992185, 1.524131473632806],
            [-58.06086791699218, 1.525531473632803],
            [-58.065267916992184, 1.5222314736328],
            [-58.065567916992187, 1.518331473632806],
            [-58.06856791699218, 1.512731473632805],
            [-58.069567916992185, 1.507531473632802],
            [-58.073467916992186, 1.506931473632804],
            [-58.076467916992186, 1.507831473632805],
            [-58.085067916992188, 1.508131473632801],
            [-58.083767916992187, 1.511431473632804],
            [-58.085467916992187, 1.514931473632807],
            [-58.097667916992187, 1.514131473632801],
            [-58.100267916992181, 1.509931473632804],
            [-58.104167916992182, 1.506931473632804],
            [-58.107167916992182, 1.505931473632806],
            [-58.111367916992187, 1.501331473632803],
            [-58.117167916992187, 1.500531473632805],
            [-58.121567916992184, 1.501331473632803],
            [-58.129467916992184, 1.499131473632801],
            [-58.13566791699219, 1.503431473632801],
            [-58.139267916992182, 1.505131473632801],
            [-58.145967916992184, 1.507331473632803],
            [-58.149567916992183, 1.5100314736328],
            [-58.147567916992188, 1.512531473632805],
            [-58.146167916992184, 1.515531473632805],
            [-58.148367916992186, 1.521331473632806],
            [-58.150367916992181, 1.523531473632801],
            [-58.152867916992186, 1.528031473632801],
            [-58.155167916992184, 1.530131473632807],
            [-58.155367916992184, 1.536731473632805],
            [-58.153667916992184, 1.543131473632805],
            [-58.156667916992184, 1.543731473632803],
            [-58.15976791699218, 1.552931473632803],
            [-58.157867916992188, 1.555931473632803],
            [-58.160667916992182, 1.5603314736328],
            [-58.16476791699219, 1.560931473632806],
            [-58.171367916992182, 1.563431473632804],
            [-58.179667916992187, 1.565531473632802],
            [-58.183867916992185, 1.565331473632803],
            [-58.186867916992185, 1.568131473632803],
            [-58.189567916992189, 1.571731473632802],
            [-58.193067916992185, 1.572331473632801],
            [-58.19486791699218, 1.569731473632807],
            [-58.197367916992185, 1.567331473632805],
            [-58.200967916992184, 1.567031473632802],
            [-58.212867916992181, 1.5648314736328],
            [-58.216767916992183, 1.560531473632807],
            [-58.22096791699218, 1.556731473632802],
            [-58.225567916992183, 1.554031473632804],
            [-58.229267916992185, 1.553931473632801],
            [-58.23326791699219, 1.547531473632802],
            [-58.236067916992184, 1.546831473632807],
            [-58.239367916992187, 1.548731473632806],
            [-58.241367916992189, 1.554031473632804],
            [-58.245267916992184, 1.557331473632807],
            [-58.250967916992181, 1.559131473632803],
            [-58.254567916992187, 1.562031473632807],
            [-58.25736791699218, 1.569231473632804],
            [-58.26236791699219, 1.570931473632804],
            [-58.266867916992183, 1.569731473632807],
            [-58.271767916992189, 1.567531473632805],
            [-58.276267916992182, 1.569131473632801],
            [-58.284167916992189, 1.573331473632805],
            [-58.288167916992187, 1.5725314736328],
            [-58.291067916992183, 1.568931473632801],
            [-58.292867916992186, 1.564231473632802],
            [-58.296667916992185, 1.5648314736328],
            [-58.301967916992183, 1.5648314736328],
            [-58.31716791699219, 1.568431473632806],
            [-58.317667916992185, 1.575931473632807],
            [-58.315967916992186, 1.578631473632804],
            [-58.314067916992187, 1.5847314736328],
            [-58.314867916992185, 1.588031473632803],
            [-58.314367916992182, 1.594131473632807],
            [-58.319067916992182, 1.594731473632805],
            [-58.322367916992185, 1.597131473632807],
            [-58.327167916992181, 1.595231473632801],
            [-58.32956791699219, 1.593531473632801],
            [-58.332367916992183, 1.590831473632804],
            [-58.337567916992185, 1.581331473632801],
            [-58.342267916992185, 1.578131473632801],
            [-58.34456791699219, 1.575931473632807],
            [-58.345567916992181, 1.572831473632803],
            [-58.344867916992186, 1.569931473632806],
            [-58.343467916992182, 1.566731473632807],
            [-58.345867916992184, 1.564531473632805],
            [-58.35096791699219, 1.563931473632806],
            [-58.360367916992189, 1.5603314736328],
            [-58.360367916992189, 1.554731473632806],
            [-58.361867916992182, 1.551331473632807],
            [-58.359467916992187, 1.546531473632804],
            [-58.360667916992185, 1.542431473632803],
            [-58.363067916992186, 1.538131473632802],
            [-58.367467916992183, 1.536931473632805],
            [-58.374067916992189, 1.537931473632803],
            [-58.378867916992185, 1.535531473632801],
            [-58.382167916992188, 1.537331473632804],
            [-58.389567916992185, 1.535531473632801],
            [-58.391067916992185, 1.531531473632803],
            [-58.39476791699218, 1.526531473632801],
            [-58.39506791699219, 1.522931473632802],
            [-58.393767916992189, 1.520031473632805],
            [-58.389967916992184, 1.515331473632806],
            [-58.384567916992182, 1.514131473632801],
            [-58.383567916992185, 1.510931473632802],
            [-58.38736791699219, 1.507731473632802],
            [-58.391567916992187, 1.506531473632805],
            [-58.392667916992181, 1.501731473632802],
            [-58.39036791699219, 1.496231473632804],
            [-58.38566791699219, 1.492931473632801],
            [-58.385167916992188, 1.488731473632804],
            [-58.381667916992185, 1.489731473632801],
            [-58.37666791699219, 1.488131473632805],
            [-58.372367916992189, 1.483031473632806],
            [-58.38406791699218, 1.472731473632805],
            [-58.38566791699219, 1.470131473632804],
            [-58.390967916992182, 1.469931473632805],
            [-58.396767916992189, 1.470931473632803],
            [-58.403967916992187, 1.469931473632805],
            [-58.40676791699218, 1.471531473632801],
            [-58.412867916992184, 1.472731473632805],
            [-58.41646791699219, 1.470931473632803],
            [-58.421967916992187, 1.471131473632802],
            [-58.425267916992183, 1.467131473632804],
            [-58.430467916992185, 1.466931473632805],
            [-58.439467916992186, 1.464431473632807],
            [-58.44216791699219, 1.461731473632803],
            [-58.444267916992189, 1.456531473632801],
            [-58.448467916992186, 1.456131473632801],
            [-58.454067916992187, 1.460331473632806],
            [-58.457867916992186, 1.460831473632801],
            [-58.459267916992189, 1.463531473632806],
            [-58.461967916992187, 1.466131473632807],
            [-58.465067916992183, 1.466331473632806],
            [-58.472767916992183, 1.461131473632804],
            [-58.477767916992185, 1.460831473632801],
            [-58.480667916992189, 1.461531473632803],
            [-58.483867916992182, 1.460731473632805],
            [-58.488367916992189, 1.462131473632802],
            [-58.490467916992188, 1.466631473632802],
            [-58.493367916992185, 1.468331473632801],
            [-58.496567916992184, 1.466331473632806],
            [-58.505167916992185, 1.464931473632802],
            [-58.508767916992184, 1.463031473632803],
            [-58.509567916992182, 1.459431473632804],
            [-58.507967916992186, 1.449231473632807],
            [-58.507967916992186, 1.444931473632806],
            [-58.502367916992185, 1.442831473632801],
            [-58.498067916992184, 1.430331473632805],
            [-58.503267916992186, 1.424931473632803],
            [-58.503267916992186, 1.418531473632804],
            [-58.501567916992187, 1.415131473632805],
            [-58.505967916992184, 1.409731473632803],
            [-58.505167916992185, 1.403331473632804],
            [-58.500567916992182, 1.401331473632801],
            [-58.495767916992186, 1.3972314736328],
            [-58.490567916992184, 1.394731473632802],
            [-58.487167916992185, 1.394331473632803],
            [-58.485067916992186, 1.392131473632801],
            [-58.479667916992184, 1.390331473632806],
            [-58.479167916992182, 1.387331473632806],
            [-58.485067916992186, 1.384931473632804],
            [-58.48166791699218, 1.381931473632804],
            [-58.479267916992185, 1.378331473632805],
            [-58.475667916992187, 1.376131473632803],
            [-58.471667916992189, 1.3743314736328],
            [-58.470067916992186, 1.370931473632801],
            [-58.465967916992184, 1.372631473632801],
            [-58.464867916992183, 1.375531473632805],
            [-58.46156791699218, 1.3743314736328],
            [-58.457867916992186, 1.371531473632807],
            [-58.461167916992181, 1.367131473632803],
            [-58.464067916992185, 1.3651314736328],
            [-58.465167916992186, 1.3621314736328],
            [-58.468967916992185, 1.356531473632806],
            [-58.471667916992189, 1.354931473632803],
            [-58.470867916992184, 1.350931473632805],
            [-58.47426791699219, 1.348531473632804],
            [-58.473367916992188, 1.340831473632804],
            [-58.473867916992184, 1.337931473632807],
            [-58.473067916992186, 1.329131473632806],
            [-58.47096791699218, 1.319531473632807],
            [-58.476167916992182, 1.308431473632801],
            [-58.478067916992188, 1.305731473632804],
            [-58.483567916992186, 1.300431473632806],
            [-58.486367916992187, 1.299931473632803],
            [-58.495167916992187, 1.2951314736328],
            [-58.493367916992185, 1.291031473632806],
            [-58.493367916992185, 1.287931473632803],
            [-58.496367916992185, 1.282131473632802],
            [-58.497267916992186, 1.279131473632802],
            [-58.494467916992186, 1.275331473632804],
            [-58.494467916992186, 1.270831473632803],
            [-58.496267916992181, 1.268131473632806],
            [-58.504067916992184, 1.269131473632804],
            [-58.50436791699218, 1.273331473632801],
            [-58.50736791699218, 1.274431473632802],
            [-58.508467916992188, 1.277331473632806],
            [-58.512967916992181, 1.277731473632805],
            [-58.51406791699219, 1.273931473632807],
            [-58.517367916992185, 1.272731473632803],
            [-58.519367916992181, 1.268631473632801],
            [-58.520167916992186, 1.265731473632805],
            [-58.517967916992184, 1.261931473632806],
            [-58.519067916992185, 1.258331473632801],
            [-58.522967916992187, 1.257831473632805],
            [-58.52406791699218, 1.262731473632805],
            [-58.52406791699218, 1.267131473632801],
            [-58.527067916992181, 1.269731473632802],
            [-58.529867916992188, 1.2707314736328],
            [-58.53346791699218, 1.269131473632804],
            [-58.535967916992185, 1.265731473632805],
            [-58.538167916992187, 1.267731473632807],
            [-58.537767916992181, 1.271731473632805],
            [-58.538067916992183, 1.275831473632806],
            [-58.539567916992183, 1.281631473632807],
            [-58.538867916992189, 1.284931473632803],
            [-58.540267916992185, 1.288131473632802],
            [-58.54276791699219, 1.289931473632805],
            [-58.546867916992184, 1.291831473632804],
            [-58.549967916992188, 1.291831473632804],
            [-58.554367916992184, 1.292731473632806],
            [-58.556567916992186, 1.290531473632804],
            [-58.559667916992183, 1.290331473632804],
            [-58.561567916992189, 1.292731473632806],
            [-58.564567916992189, 1.294631473632805],
            [-58.567967916992188, 1.292731473632806],
            [-58.569567916992185, 1.290331473632804],
            [-58.56986791699218, 1.286031473632804],
            [-58.577667916992183, 1.283331473632806],
            [-58.579067916992187, 1.279131473632802],
            [-58.577667916992183, 1.274731473632805],
            [-58.579867916992185, 1.270831473632803],
            [-58.581867916992181, 1.268531473632805],
            [-58.582667916992186, 1.263531473632803],
            [-58.585467916992187, 1.262131473632806],
            [-58.588167916992184, 1.263531473632803],
            [-58.587067916992183, 1.267231473632805],
            [-58.588467916992187, 1.270031473632805],
            [-58.592367916992188, 1.270331473632801],
            [-58.595667916992184, 1.271131473632806],
            [-58.601767916992188, 1.274131473632806],
            [-58.60526791699219, 1.274731473632805],
            [-58.60826791699219, 1.273931473632807],
            [-58.612567916992184, 1.281131473632804],
            [-58.614967916992185, 1.283031473632803],
            [-58.618367916992185, 1.285131473632802],
            [-58.622367916992189, 1.284731473632803],
            [-58.628767916992189, 1.288731473632801],
            [-58.633267916992182, 1.289131473632807],
            [-58.63706791699218, 1.288331473632802],
            [-58.639367916992185, 1.285531473632801],
            [-58.642667916992181, 1.2859314736328],
            [-58.645967916992184, 1.285531473632801],
            [-58.650167916992189, 1.286731473632805],
            [-58.652667916992186, 1.288331473632802],
            [-58.656267916992185, 1.286931473632805],
            [-58.659267916992185, 1.283731473632805],
            [-58.661267916992188, 1.280531473632806],
            [-58.664167916992184, 1.279431473632805],
            [-58.666067916992183, 1.281631473632807],
            [-58.669267916992183, 1.281331473632804],
            [-58.676067916992181, 1.285231473632805],
            [-58.677567916992189, 1.288331473632802],
            [-58.677467916992185, 1.292131473632807],
            [-58.68016791699219, 1.296331473632804],
            [-58.684067916992184, 1.296731473632803],
            [-58.686567916992189, 1.294931473632801],
            [-58.694567916992185, 1.297531473632802],
            [-58.697067916992182, 1.294631473632805],
            [-58.705967916992186, 1.293731473632803],
            [-58.710467916992187, 1.290131473632805],
            [-58.710967916992189, 1.286931473632805],
            [-58.709867916992181, 1.283531473632806],
            [-58.71186791699219, 1.281131473632804],
            [-58.713467916992187, 1.278031473632801],
            [-58.714067916992185, 1.274431473632802],
            [-58.718167916992186, 1.266131473632804],
            [-58.71926791699218, 1.2585314736328],
            [-58.720667916992184, 1.255531473632807],
            [-58.72096791699218, 1.245331473632802],
            [-58.71626791699218, 1.241131473632805],
            [-58.715167916992186, 1.237631473632803],
            [-58.717967916992187, 1.236331473632802],
            [-58.719867916992186, 1.233931473632801],
            [-58.72126791699219, 1.230731473632801],
            [-58.721667916992189, 1.227731473632801],
            [-58.725267916992181, 1.218731473632801],
            [-58.728867916992186, 1.216931473632805],
            [-58.730367916992186, 1.214131473632804],
            [-58.73026791699219, 1.210731473632805],
            [-58.73496791699219, 1.208331473632803],
            [-58.737167916992185, 1.206331473632801],
            [-58.73966791699219, 1.200031473632805],
            [-58.744367916992189, 1.205731473632802],
            [-58.747967916992181, 1.208131473632804],
            [-58.751267916992184, 1.208631473632806],
            [-58.754567916992187, 1.205331473632803],
            [-58.761267916992182, 1.200031473632805],
            [-58.76406791699219, 1.197131473632801],
            [-58.769867916992183, 1.186531473632805],
            [-58.773467916992189, 1.186231473632802],
            [-58.776267916992182, 1.185531473632807],
            [-58.778467916992184, 1.187731473632802],
            [-58.779467916992189, 1.190631473632806],
            [-58.78676791699219, 1.188931473632806],
            [-58.791667916992182, 1.190531473632802],
            [-58.794967916992185, 1.187731473632802],
            [-58.793567916992188, 1.184731473632802],
            [-58.79716791699218, 1.181931473632801],
            [-58.800067916992184, 1.176731473632806],
            [-58.803567916992186, 1.1761314736328],
            [-58.805067916992186, 1.180131473632805],
            [-58.809867916992189, 1.179031473632804],
            [-58.811567916992189, 1.176331473632807],
            [-58.821267916992184, 1.171131473632805],
            [-58.825167916992186, 1.171331473632804],
            [-58.824067916992185, 1.183331473632805],
            [-58.826867916992185, 1.187031473632807],
            [-58.831867916992181, 1.187931473632801],
            [-58.83656791699218, 1.185531473632807],
            [-58.84596791699218, 1.181931473632801],
            [-58.850067916992188, 1.183131473632805],
            [-58.854267916992185, 1.183431473632801],
            [-58.856367916992184, 1.186231473632802],
            [-58.854667916992184, 1.1898314736328],
            [-58.856367916992184, 1.193431473632806],
            [-58.859367916992184, 1.196331473632803],
            [-58.859367916992184, 1.204131473632806],
            [-58.866867916992184, 1.203331473632801],
            [-58.870767916992186, 1.208931473632802],
            [-58.870867916992182, 1.217731473632803],
            [-58.874467916992188, 1.219731473632805],
            [-58.874467916992188, 1.223331473632804],
            [-58.876867916992182, 1.225531473632806],
            [-58.879867916992183, 1.225531473632806],
            [-58.882067916992185, 1.228931473632805],
            [-58.891867916992183, 1.230931473632801],
            [-58.895467916992189, 1.2279314736328],
            [-58.898767916992185, 1.228931473632805],
            [-58.904167916992186, 1.2341314736328],
            [-58.90846791699218, 1.235331473632804],
            [-58.912067916992186, 1.238231473632801],
            [-58.905167916992184, 1.240931473632806],
            [-58.904267916992183, 1.245331473632802],
            [-58.905167916992184, 1.2493314736328],
            [-58.902967916992182, 1.252031473632805],
            [-58.899867916992186, 1.252531473632807],
            [-58.895167916992186, 1.254931473632801],
            [-58.892867916992188, 1.257831473632805],
            [-58.889367916992185, 1.258931473632806],
            [-58.886867916992188, 1.260331473632803],
            [-58.888767916992187, 1.263131473632804],
            [-58.892367916992185, 1.2675314736328],
            [-58.898767916992185, 1.279431473632805],
            [-58.902067916992181, 1.279931473632807],
            [-58.918567916992188, 1.284931473632803],
            [-58.92246791699219, 1.289931473632805],
            [-58.923867916992187, 1.293531473632804],
            [-58.921067916992186, 1.294631473632805],
            [-58.919667916992182, 1.301731473632806],
            [-58.915967916992187, 1.305131473632805],
            [-58.917167916992184, 1.309131473632803],
            [-58.915567916992188, 1.313131473632801],
            [-58.917567916992184, 1.316131473632801],
            [-58.924767916992181, 1.317531473632805],
            [-58.929667916992187, 1.316731473632807],
            [-58.932667916992187, 1.317531473632805],
            [-58.938767916992184, 1.3163314736328],
            [-58.942367916992183, 1.316531473632807],
            [-58.944067916992182, 1.314131473632806],
            [-58.949067916992185, 1.312731473632802],
            [-58.953167916992186, 1.3133314736328],
            [-58.956267916992189, 1.312031473632807],
            [-58.96186791699219, 1.313131473632801],
            [-58.965967916992184, 1.312531473632802],
            [-58.970167916992182, 1.309731473632802],
            [-58.973367916992188, 1.307631473632803],
            [-58.97596791699219, 1.3026314736328],
            [-58.978867916992186, 1.301731473632806],
            [-58.98166791699218, 1.304731473632806],
            [-58.982467916992185, 1.307931473632806],
            [-58.985367916992189, 1.312531473632802],
            [-58.988667916992185, 1.318331473632803],
            [-58.98966791699219, 1.324931473632802],
            [-58.992767916992186, 1.326431473632802],
            [-58.997967916992181, 1.3255314736328],
            [-59.00166791699219, 1.324131473632804],
            [-59.005767916992184, 1.324231473632807],
            [-59.013267916992184, 1.323931473632804],
            [-59.021867916992186, 1.331131473632801],
            [-59.027667916992186, 1.330331473632803],
            [-59.030967916992182, 1.327831473632806],
            [-59.038867916992189, 1.324231473632807],
            [-59.04276791699219, 1.323331473632805],
            [-59.048567916992184, 1.322731473632807],
            [-59.052467916992185, 1.323931473632804],
            [-59.054067916992182, 1.326731473632805],
            [-59.054667916992187, 1.331131473632801],
            [-59.056867916992189, 1.334331473632801],
            [-59.06086791699218, 1.335531473632805],
            [-59.066967916992184, 1.335131473632806],
            [-59.067367916992183, 1.331131473632801],
            [-59.07016791699219, 1.329731473632805],
            [-59.074967916992186, 1.330531473632803],
            [-59.087067916992183, 1.338531473632806],
            [-59.089067916992185, 1.342531473632803],
            [-59.090167916992186, 1.345931473632803],
            [-59.09596791699218, 1.344731473632805],
            [-59.099567916992186, 1.347931473632805],
            [-59.102767916992185, 1.348331473632804],
            [-59.10496791699218, 1.346331473632802],
            [-59.108867916992182, 1.345531473632803],
            [-59.11466791699219, 1.342731473632803],
            [-59.118367916992185, 1.342731473632803],
            [-59.122267916992186, 1.345931473632803],
            [-59.127367916992185, 1.350731473632806],
            [-59.131567916992182, 1.352431473632805],
            [-59.13906791699219, 1.349131473632802],
            [-59.14506791699219, 1.347331473632806],
            [-59.152667916992186, 1.348831473632806],
            [-59.154867916992188, 1.351031473632801],
            [-59.162067916992186, 1.3591314736328],
            [-59.168867916992184, 1.356731473632806],
            [-59.171867916992184, 1.357131473632805],
            [-59.176867916992187, 1.360431473632801],
            [-59.179067916992182, 1.363931473632803],
            [-59.182967916992183, 1.368131473632801],
            [-59.187967916992186, 1.375131473632806],
            [-59.19656791699218, 1.378331473632805],
            [-59.204867916992185, 1.376531473632802],
            [-59.206067916992183, 1.372031473632802],
            [-59.212667916992189, 1.374731473632806],
            [-59.22596791699219, 1.3773314736328],
            [-59.23026791699219, 1.382731473632802],
            [-59.23496791699219, 1.386731473632807],
            [-59.241967916992181, 1.385531473632803],
            [-59.246267916992181, 1.385631473632806],
            [-59.253267916992186, 1.388331473632803],
            [-59.258467916992188, 1.396131473632806],
            [-59.27006791699219, 1.401631473632804],
            [-59.272067916992185, 1.406631473632807],
            [-59.277367916992183, 1.408031473632803],
            [-59.281967916992187, 1.409931473632803],
            [-59.280067916992181, 1.413331473632802],
            [-59.275167916992189, 1.418531473632804],
            [-59.279167916992186, 1.4261314736328],
            [-59.282267916992183, 1.429731473632806],
            [-59.284267916992185, 1.437031473632807],
            [-59.287367916992181, 1.438931473632806],
            [-59.28846791699219, 1.444731473632807],
            [-59.284167916992189, 1.448731473632805],
            [-59.287067916992186, 1.452231473632807],
            [-59.292067916992181, 1.457931473632804],
            [-59.297867916992189, 1.460331473632806],
            [-59.30216791699219, 1.465231473632805],
            [-59.307267916992188, 1.466331473632806],
            [-59.310467916992181, 1.466131473632807],
            [-59.313067916992182, 1.461131473632804],
            [-59.319567916992185, 1.460331473632806],
            [-59.32486791699219, 1.461331473632804],
            [-59.327067916992185, 1.464131473632804],
            [-59.326267916992187, 1.467731473632803],
            [-59.327667916992183, 1.474531473632801],
            [-59.322967916992184, 1.479531473632804],
            [-59.32156791699218, 1.482131473632805],
            [-59.322367916992185, 1.485331473632804],
            [-59.330167916992181, 1.499731473632806],
            [-59.332167916992184, 1.506331473632805],
            [-59.329867916992185, 1.509131473632806],
            [-59.329867916992185, 1.513931473632802],
            [-59.333167916992181, 1.514131473632801],
            [-59.339567916992181, 1.518331473632806],
            [-59.347367916992184, 1.517131473632801],
            [-59.353067916992188, 1.517131473632801],
            [-59.35966791699218, 1.516431473632807],
            [-59.358067916992184, 1.511131473632801],
            [-59.361367916992187, 1.505131473632801],
            [-59.362267916992181, 1.508331473632801],
            [-59.365867916992187, 1.509731473632804],
            [-59.371867916992187, 1.506731473632804],
            [-59.379067916992184, 1.507731473632802],
            [-59.38266791699219, 1.508731473632807],
            [-59.380767916992184, 1.512131473632806],
            [-59.379067916992184, 1.515531473632805],
            [-59.379567916992187, 1.521731473632805],
            [-59.384167916992183, 1.523031473632805],
            [-59.38406791699218, 1.528931473632802],
            [-59.394367916992181, 1.537131473632805],
            [-59.395667916992181, 1.540931473632803],
            [-59.395367916992186, 1.554531473632807],
            [-59.398967916992184, 1.557131473632801],
            [-59.403467916992184, 1.554731473632806],
            [-59.412767916992181, 1.551731473632806],
            [-59.415267916992185, 1.554531473632807],
            [-59.422867916992189, 1.555731473632804],
            [-59.43116791699218, 1.567331473632805],
            [-59.430067916992186, 1.5755314736328],
            [-59.427467916992185, 1.579131473632806],
            [-59.426567916992184, 1.582931473632804],
            [-59.42716791699219, 1.586331473632804],
            [-59.432267916992188, 1.590531473632801],
            [-59.437667916992183, 1.593831473632804],
            [-59.438067916992182, 1.598831473632806],
            [-59.443267916992184, 1.605731473632801],
            [-59.44486791699218, 1.614731473632801],
            [-59.448867916992185, 1.619531473632804],
            [-59.457067916992187, 1.618331473632807],
            [-59.465167916992186, 1.617631473632805],
            [-59.472667916992187, 1.620531473632802],
            [-59.478867916992186, 1.625531473632805],
            [-59.482867916992184, 1.626731473632802],
            [-59.48496791699219, 1.630331473632801],
            [-59.488867916992184, 1.635531473632803],
            [-59.489167916992187, 1.640731473632805],
            [-59.494667916992185, 1.6472314736328],
            [-59.496267916992181, 1.654131473632802],
            [-59.495467916992183, 1.658531473632806],
            [-59.49366791699218, 1.661331473632806],
            [-59.494367916992189, 1.665931473632803],
            [-59.493267916992181, 1.669331473632802],
            [-59.493267916992181, 1.673531473632806],
            [-59.499367916992185, 1.677131473632805],
            [-59.502667916992181, 1.674331473632805],
            [-59.50636791699219, 1.674731473632804],
            [-59.507067916992185, 1.668931473632803],
            [-59.510267916992184, 1.667931473632805],
            [-59.51206791699218, 1.671331473632804],
            [-59.512667916992186, 1.678331473632802],
            [-59.514267916992182, 1.681231473632806],
            [-59.520167916992186, 1.684731473632802],
            [-59.523167916992186, 1.687531473632802],
            [-59.526267916992182, 1.6913314736328],
            [-59.527067916992181, 1.694731473632807],
            [-59.526967916992184, 1.7035314736328],
            [-59.533867916992186, 1.708331473632803],
            [-59.53476791699218, 1.716631473632802],
            [-59.538067916992183, 1.721131473632802],
            [-59.544267916992183, 1.721331473632802],
            [-59.547767916992186, 1.727131473632802],
            [-59.55046791699219, 1.728231473632803],
            [-59.554067916992182, 1.727131473632802],
            [-59.557167916992185, 1.724631473632805],
            [-59.560467916992181, 1.729631473632807],
            [-59.564967916992188, 1.734331473632807],
            [-59.570467916992186, 1.734531473632806],
            [-59.576267916992187, 1.734631473632803],
            [-59.580667916992184, 1.731831473632802],
            [-59.584767916992185, 1.731731473632806],
            [-59.58826791699218, 1.726531473632804],
            [-59.593167916992186, 1.726331473632804],
            [-59.597667916992187, 1.725131473632807],
            [-59.600667916992187, 1.722431473632803],
            [-59.608867916992182, 1.7219314736328],
            [-59.611867916992182, 1.717731473632803],
            [-59.616667916992185, 1.716531473632806],
            [-59.622167916992183, 1.7219314736328],
            [-59.629867916992183, 1.728131473632807],
            [-59.631867916992185, 1.733531473632802],
            [-59.632467916992184, 1.7401314736328],
            [-59.638767916992187, 1.740431473632803],
            [-59.643767916992189, 1.741731473632804],
            [-59.648367916992186, 1.740731473632806],
            [-59.655867916992186, 1.742331473632802],
            [-59.659467916992185, 1.738231473632801],
            [-59.663067916992183, 1.736831473632805],
            [-59.664167916992184, 1.741131473632805],
            [-59.663067916992183, 1.744731473632804],
            [-59.664567916992183, 1.748431473632806],
            [-59.664867916992186, 1.752531473632807],
            [-59.66746791699218, 1.755331473632801],
            [-59.671067916992186, 1.756331473632805],
            [-59.676567916992184, 1.755631473632803],
            [-59.680867916992185, 1.754131473632803],
            [-59.690167916992181, 1.756331473632805],
            [-59.691667916992188, 1.760931473632802],
            [-59.690267916992184, 1.764331473632801],
            [-59.68886791699218, 1.766931473632802],
            [-59.686567916992189, 1.771331473632806],
            [-59.685567916992184, 1.774731473632805],
            [-59.688367916992185, 1.779431473632805],
            [-59.686267916992186, 1.782331473632802],
            [-59.684867916992189, 1.786731473632805],
            [-59.684367916992187, 1.790331473632804],
            [-59.679767916992184, 1.789931473632805],
            [-59.679767916992184, 1.794531473632802],
            [-59.680867916992185, 1.797331473632802],
            [-59.677967916992188, 1.807631473632803],
            [-59.672867916992189, 1.807531473632807],
            [-59.668067916992186, 1.811931473632804],
            [-59.664267916992188, 1.813431473632804],
            [-59.65976791699218, 1.811231473632802],
            [-59.654267916992183, 1.816931473632806],
            [-59.655667916992186, 1.819731473632807],
            [-59.658167916992184, 1.823931473632804],
            [-59.655467916992187, 1.828931473632807],
            [-59.653467916992184, 1.834131473632802],
            [-59.650167916992189, 1.840231473632805],
            [-59.649867916992186, 1.843531473632801],
            [-59.652667916992186, 1.8499314736328],
            [-59.656567916992188, 1.853231473632803],
            [-59.65976791699218, 1.860131473632805],
            [-59.662767916992181, 1.863931473632803],
            [-59.666367916992186, 1.864331473632802],
            [-59.66916791699218, 1.859631473632803],
            [-59.665267916992185, 1.850531473632806],
            [-59.666067916992183, 1.847731473632805],
            [-59.66916791699218, 1.846631473632804],
            [-59.671867916992184, 1.843731473632801],
            [-59.674967916992188, 1.839731473632803],
            [-59.679067916992182, 1.839331473632804],
            [-59.681567916992186, 1.845931473632803],
            [-59.690967916992186, 1.853131473632807],
            [-59.703467916992182, 1.854631473632807],
            [-59.707667916992186, 1.854131473632805],
            [-59.71186791699219, 1.854331473632804],
            [-59.720567916992181, 1.849531473632801],
            [-59.726767916992188, 1.849331473632802],
            [-59.73466791699218, 1.851531473632804],
            [-59.737867916992187, 1.851731473632803],
            [-59.745867916992182, 1.851931473632803],
            [-59.747967916992181, 1.855531473632801],
            [-59.751567916992187, 1.858531473632802],
            [-59.752167916992185, 1.861731473632801],
            [-59.753467916992186, 1.865731473632806],
            [-59.752167916992185, 1.871231473632804],
            [-59.749867916992187, 1.873931473632801],
            [-59.747367916992189, 1.878731473632804],
            [-59.749867916992187, 1.882331473632803],
            [-59.752667916992181, 1.890731473632805],
            [-59.755467916992188, 1.894931473632802],
            [-59.75936791699219, 1.900531473632803],
            [-59.755767916992184, 1.905531473632806],
            [-59.757167916992188, 1.909131473632804],
            [-59.75436791699218, 1.911531473632806],
            [-59.753867916992185, 1.917431473632803],
            [-59.756267916992186, 1.919531473632802],
            [-59.755267916992182, 1.922731473632801],
            [-59.74966791699218, 1.927531473632804],
            [-59.745467916992183, 1.929531473632807],
            [-59.746267916992181, 1.933331473632805],
            [-59.745867916992182, 1.941131473632801],
            [-59.743367916992185, 1.944531473632807],
            [-59.739467916992183, 1.956931473632807],
            [-59.738267916992186, 1.961131473632804],
            [-59.737767916992183, 1.966331473632806],
            [-59.739967916992185, 1.970131473632804],
            [-59.742167916992187, 1.972431473632803],
            [-59.740267916992181, 1.975531473632806],
            [-59.738367916992189, 1.979331473632804],
            [-59.738267916992186, 1.983531473632802],
            [-59.73496791699219, 1.984631473632803],
            [-59.735267916992186, 1.989031473632807],
            [-59.733967916992185, 1.993731473632806],
            [-59.734467916992187, 1.998331473632803],
            [-59.734767916992183, 2.002731473632807],
            [-59.733167916992187, 2.005131473632801],
            [-59.731767916992183, 2.008931473632806],
            [-59.734267916992181, 2.011931473632806],
            [-59.734267916992181, 2.014931473632807],
            [-59.732467916992185, 2.018631473632801],
            [-59.727567916992186, 2.020031473632805],
            [-59.723467916992185, 2.022731473632803],
            [-59.721467916992182, 2.027331473632806],
            [-59.724867916992181, 2.029331473632801],
            [-59.728667916992187, 2.030931473632805],
            [-59.728867916992186, 2.033831473632802],
            [-59.731467916992187, 2.036531473632806],
            [-59.731067916992188, 2.040331473632804],
            [-59.732467916992185, 2.043731473632803],
            [-59.735367916992189, 2.048531473632806],
            [-59.737467916992188, 2.051831473632802],
            [-59.736667916992189, 2.055131473632805],
            [-59.737567916992184, 2.058331473632805],
            [-59.739967916992185, 2.061531473632805],
            [-59.740767916992183, 2.065131473632803],
            [-59.741467916992185, 2.068431473632806],
            [-59.741667916992185, 2.073731473632805],
            [-59.73966791699219, 2.076431473632802],
            [-59.739467916992183, 2.079931473632804],
            [-59.737167916992185, 2.082731473632805],
            [-59.736467916992183, 2.086931473632802],
            [-59.735667916992185, 2.089731473632803],
            [-59.732467916992185, 2.091931473632805],
            [-59.72996791699218, 2.093831473632804],
            [-59.727867916992182, 2.096131473632802],
            [-59.724567916992186, 2.098331473632804],
            [-59.72226791699218, 2.102131473632802],
            [-59.72226791699218, 2.106031473632804],
            [-59.723067916992186, 2.110431473632801],
            [-59.721767916992185, 2.113731473632804],
            [-59.722067916992188, 2.116931473632803],
            [-59.723367916992188, 2.119531473632804],
            [-59.724467916992182, 2.122331473632805],
            [-59.728067916992188, 2.123731473632802],
            [-59.727067916992183, 2.127931473632806],
            [-59.726467916992185, 2.132731473632802],
            [-59.728667916992187, 2.137731473632805],
            [-59.732567916992181, 2.140931473632804],
            [-59.734167916992185, 2.143631473632801],
            [-59.737867916992187, 2.146131473632806],
            [-59.739467916992183, 2.149431473632802],
            [-59.737767916992183, 2.152531473632806],
            [-59.738067916992186, 2.155731473632805],
            [-59.739467916992183, 2.159331473632804],
            [-59.741467916992185, 2.162931473632803],
            [-59.740367916992184, 2.166731473632801],
            [-59.739767916992186, 2.171131473632805],
            [-59.737567916992184, 2.175131473632803],
            [-59.739367916992187, 2.177531473632804],
            [-59.740067916992189, 2.180331473632805],
            [-59.736767916992186, 2.182931473632806],
            [-59.736667916992189, 2.186731473632804],
            [-59.736067916992184, 2.189731473632804],
            [-59.73326791699219, 2.193131473632803],
            [-59.731367916992184, 2.196431473632806],
            [-59.729267916992185, 2.199931473632802],
            [-59.727867916992182, 2.202731473632802],
            [-59.726667916992184, 2.205531473632803],
            [-59.726667916992184, 2.209331473632801],
            [-59.728867916992186, 2.213331473632806],
            [-59.728867916992186, 2.217331473632804],
            [-59.729567916992181, 2.220531473632803],
            [-59.730567916992186, 2.224631473632805],
            [-59.73026791699219, 2.229631473632807],
            [-59.729567916992181, 2.232731473632803],
            [-59.72696791699218, 2.234931473632805],
            [-59.723067916992186, 2.236531473632802],
            [-59.720067916992186, 2.237631473632803],
            [-59.72096791699218, 2.241231473632801],
            [-59.722667916992187, 2.245331473632802],
            [-59.721667916992189, 2.249131473632801],
            [-59.723667916992184, 2.252531473632807],
            [-59.725267916992181, 2.254931473632801],
            [-59.726367916992189, 2.259231473632802],
            [-59.729167916992182, 2.2645314736328],
            [-59.727767916992185, 2.267931473632807],
            [-59.725867916992186, 2.270031473632805],
            [-59.72426791699219, 2.273031473632805],
            [-59.723367916992188, 2.276331473632801],
            [-59.725567916992183, 2.279431473632805],
            [-59.72726791699219, 2.282131473632802],
            [-59.730067916992184, 2.283831473632802],
            [-59.732467916992185, 2.285731473632801],
            [-59.736467916992183, 2.286531473632806],
            [-59.739067916992184, 2.289631473632802],
            [-59.740467916992188, 2.292731473632806],
            [-59.745767916992186, 2.292131473632807],
            [-59.749967916992183, 2.288131473632802],
            [-59.752467916992188, 2.289931473632805],
            [-59.756267916992186, 2.289931473632805],
            [-59.761267916992182, 2.290531473632804],
            [-59.764667916992181, 2.292431473632803],
            [-59.767567916992185, 2.293931473632803],
            [-59.770967916992184, 2.294631473632805],
            [-59.774567916992183, 2.292131473632807],
            [-59.777567916992183, 2.289531473632806],
            [-59.780067916992181, 2.2859314736328],
            [-59.783067916992181, 2.285131473632802],
            [-59.785967916992185, 2.284531473632804],
            [-59.78846791699219, 2.288331473632802],
            [-59.793867916992184, 2.290931473632803],
            [-59.796867916992184, 2.293731473632803],
            [-59.799167916992189, 2.297531473632802],
            [-59.803567916992186, 2.298731473632806],
            [-59.804667916992187, 2.302331473632805],
            [-59.805267916992186, 2.306231473632806],
            [-59.809067916992184, 2.309531473632802],
            [-59.811867916992185, 2.311131473632805],
            [-59.815467916992183, 2.312931473632801],
            [-59.818167916992181, 2.317331473632805],
            [-59.821267916992184, 2.318331473632803],
            [-59.824667916992183, 2.319231473632804],
            [-59.827367916992188, 2.323131473632806],
            [-59.828267916992189, 2.328331473632801],
            [-59.831767916992185, 2.329131473632806],
            [-59.834267916992189, 2.325331473632801],
            [-59.836267916992185, 2.322331473632801],
            [-59.838667916992186, 2.320631473632801],
            [-59.841667916992186, 2.320531473632805],
            [-59.843767916992185, 2.323331473632805],
            [-59.844067916992188, 2.328931473632807],
            [-59.848467916992185, 2.3300314736328],
            [-59.852467916992182, 2.330731473632802],
            [-59.85496791699218, 2.333931473632802],
            [-59.85496791699218, 2.338331473632806],
            [-59.857167916992182, 2.340531473632801],
            [-59.860267916992186, 2.342531473632803],
            [-59.862567916992184, 2.344931473632805],
            [-59.866667916992185, 2.348131473632805],
            [-59.869467916992186, 2.350231473632803],
            [-59.869367916992189, 2.353231473632803],
            [-59.869667916992185, 2.356731473632806],
            [-59.872967916992181, 2.360431473632801],
            [-59.877167916992185, 2.357331473632804],
            [-59.880767916992184, 2.353531473632806],
            [-59.883867916992187, 2.353531473632806],
            [-59.886767916992184, 2.354931473632803],
            [-59.890167916992183, 2.357131473632805],
            [-59.892967916992184, 2.361131473632803],
            [-59.896767916992189, 2.361531473632802],
            [-59.898267916992182, 2.364731473632801],
            [-59.898967916992184, 2.369531473632804],
            [-59.902267916992187, 2.373131473632803],
            [-59.905067916992181, 2.376931473632801],
            [-59.905867916992186, 2.381131473632806],
            [-59.907267916992183, 2.384931473632804],
            [-59.90846791699218, 2.388331473632803],
            [-59.908167916992184, 2.393631473632801],
            [-59.905867916992186, 2.397731473632803],
            [-59.901267916992182, 2.399731473632805],
            [-59.898667916992181, 2.402731473632805],
            [-59.897167916992188, 2.405531473632806],
            [-59.895967916992184, 2.409331473632804],
            [-59.895767916992185, 2.412731473632803],
            [-59.897267916992185, 2.416731473632801],
            [-59.898767916992185, 2.421331473632804],
            [-59.897167916992188, 2.424531473632804],
            [-59.893167916992184, 2.424731473632804],
            [-59.89206791699219, 2.4276314736328],
            [-59.894567916992187, 2.430931473632803],
            [-59.898167916992186, 2.435131473632801],
            [-59.900467916992184, 2.438431473632804],
            [-59.899767916992189, 2.442531473632805],
            [-59.897967916992187, 2.445331473632805],
            [-59.896867916992186, 2.450031473632805],
            [-59.894867916992183, 2.453131473632801],
            [-59.894367916992181, 2.456931473632807],
            [-59.89506791699219, 2.460831473632801],
            [-59.89476791699218, 2.465231473632805],
            [-59.898167916992186, 2.468831473632804],
            [-59.900167916992189, 2.471631473632804],
            [-59.899267916992187, 2.474631473632805],
            [-59.897067916992185, 2.4779314736328],
            [-59.897867916992183, 2.482131473632805],
            [-59.90076791699218, 2.483131473632803],
            [-59.902567916992183, 2.485931473632803],
            [-59.904767916992185, 2.489931473632801],
            [-59.907567916992186, 2.492331473632802],
            [-59.910367916992186, 2.493731473632806],
            [-59.912367916992181, 2.496131473632801],
            [-59.912567916992188, 2.499831473632803],
            [-59.913067916992183, 2.503331473632805],
            [-59.916167916992187, 2.505631473632803],
            [-59.920067916992181, 2.508331473632801],
            [-59.91916791699218, 2.511931473632806],
            [-59.919567916992186, 2.516931473632802],
            [-59.921067916992186, 2.5207314736328],
            [-59.918667916992185, 2.5237314736328],
            [-59.918667916992185, 2.526631473632804],
            [-59.921967916992187, 2.530131473632807],
            [-59.923567916992184, 2.533731473632805],
            [-59.924967916992188, 2.536331473632806],
            [-59.926867916992187, 2.538831473632804],
            [-59.929367916992184, 2.540531473632804],
            [-59.932667916992187, 2.544531473632802],
            [-59.93416791699218, 2.549531473632804],
            [-59.938067916992182, 2.552931473632803],
            [-59.93716791699218, 2.556231473632806],
            [-59.935167916992185, 2.558431473632801],
            [-59.933567916992189, 2.561531473632805],
            [-59.931367916992187, 2.564231473632802],
            [-59.929367916992184, 2.567731473632804],
            [-59.930867916992185, 2.571131473632803],
            [-59.934767916992186, 2.573131473632806],
            [-59.93586791699218, 2.576331473632806],
            [-59.937667916992183, 2.579731473632805],
            [-59.940167916992181, 2.582231473632802],
            [-59.942667916992185, 2.584431473632804],
            [-59.947367916992185, 2.586331473632804],
            [-59.951867916992185, 2.586731473632803],
            [-59.955367916992188, 2.589131473632804],
            [-59.958967916992187, 2.591531473632806],
            [-59.962867916992181, 2.592331473632804],
            [-59.965967916992184, 2.592131473632804],
            [-59.970667916992184, 2.592531473632803],
            [-59.97256791699219, 2.596531473632801],
            [-59.972067916992188, 2.599631473632805],
            [-59.970667916992184, 2.602731473632801],
            [-59.969067916992188, 2.605531473632801],
            [-59.966667916992186, 2.608731473632801],
            [-59.962967916992184, 2.609631473632803],
            [-59.959267916992189, 2.610331473632804],
            [-59.962667916992189, 2.614031473632807],
            [-59.964467916992184, 2.617631473632805],
            [-59.964767916992187, 2.621531473632807],
            [-59.963967916992189, 2.624831473632803],
            [-59.965867916992181, 2.6273314736328],
            [-59.968767916992185, 2.628731473632804],
            [-59.97126791699219, 2.632731473632802],
            [-59.972767916992183, 2.635331473632803],
            [-59.97596791699219, 2.638131473632804],
            [-59.977367916992186, 2.642731473632807],
            [-59.975867916992186, 2.646131473632806],
            [-59.974167916992187, 2.649131473632806],
            [-59.972067916992188, 2.655131473632807],
            [-59.972367916992184, 2.659131473632804],
            [-59.974467916992182, 2.6624314736328],
            [-59.97426791699219, 2.666531473632801],
            [-59.976667916992184, 2.670731473632806],
            [-59.980067916992184, 2.674731473632804],
            [-59.982867916992184, 2.675431473632806],
            [-59.985567916992181, 2.677331473632805],
            [-59.988267916992186, 2.679831473632802],
            [-59.990767916992183, 2.683431473632801],
            [-59.991467916992185, 2.6883314736328],
            [-59.989367916992187, 2.693431473632806],
            [-59.990067916992189, 2.699231473632807],
            [-59.990767916992183, 2.702131473632804],
            [-59.991567916992182, 2.706531473632801],
            [-59.991167916992183, 2.710531473632805],
            [-59.989767916992186, 2.717331473632804],
            [-59.990067916992189, 2.720531473632803],
            [-59.992267916992184, 2.725231473632803],
            [-59.992267916992184, 2.728731473632806],
            [-59.99266791699219, 2.731731473632806],
            [-59.992967916992185, 2.734631473632803],
            [-59.991567916992182, 2.737531473632806],
            [-59.98966791699219, 2.740431473632803],
            [-59.988267916992186, 2.743331473632807],
            [-59.987867916992187, 2.747031473632802],
            [-59.987767916992183, 2.750631473632801],
            [-59.988367916992189, 2.753931473632804],
            [-59.990067916992189, 2.757831473632805],
            [-59.991467916992185, 2.762331473632806],
            [-59.992267916992184, 2.768331473632806],
            [-59.991967916992181, 2.7722314736328],
            [-59.991967916992181, 2.777331473632806],
            [-59.992267916992184, 2.780531473632806],
            [-59.993367916992185, 2.783731473632805],
            [-59.993267916992181, 2.786931473632805],
            [-59.991967916992181, 2.790331473632804],
            [-59.991867916992184, 2.793731473632803],
            [-59.990267916992181, 2.797531473632802],
            [-59.987467916992188, 2.802131473632805],
            [-59.988067916992186, 2.806731473632802],
            [-59.988867916992184, 2.811531473632805],
            [-59.989067916992184, 2.815131473632803],
            [-59.989967916992185, 2.820631473632801],
            [-59.990767916992183, 2.825931473632807],
            [-59.991167916992183, 2.831531473632801],
            [-59.991467916992185, 2.835531473632805],
            [-59.991167916992183, 2.839931473632802],
            [-59.990367916992184, 2.843731473632801],
            [-59.988067916992186, 2.846531473632801],
            [-59.985267916992186, 2.851331473632804],
            [-59.98466791699218, 2.855531473632801],
            [-59.985367916992189, 2.858731473632801],
            [-59.986867916992182, 2.862331473632807],
            [-59.985767916992188, 2.8651314736328],
            [-59.982867916992184, 2.869031473632802],
            [-59.983367916992187, 2.873731473632802],
            [-59.98496791699219, 2.877031473632805],
            [-59.988967916992181, 2.880531473632807],
            [-59.988967916992181, 2.8835314736328],
            [-59.986067916992184, 2.885631473632806],
            [-59.982867916992184, 2.889131473632801],
            [-59.979267916992185, 2.893931473632804],
            [-59.978067916992188, 2.897531473632803],
            [-59.978867916992186, 2.903331473632804],
            [-59.982467916992185, 2.904131473632802],
            [-59.984367916992184, 2.906531473632803],
            [-59.986367916992187, 2.909731473632803],
            [-59.984767916992183, 2.913131473632802],
            [-59.983967916992185, 2.915931473632803],
            [-59.982867916992184, 2.919131473632802],
            [-59.982767916992188, 2.922931473632801],
            [-59.983667916992189, 2.926531473632807],
            [-59.983367916992187, 2.930931473632803],
            [-59.980067916992184, 2.935131473632801],
            [-59.97726791699219, 2.937731473632802],
            [-59.974167916992187, 2.940131473632803],
            [-59.970067916992186, 2.945131473632806],
            [-59.967867916992184, 2.947331473632801],
            [-59.963767916992182, 2.949231473632807],
            [-59.961167916992181, 2.951131473632806],
            [-59.959567916992185, 2.954331473632806],
            [-59.960067916992188, 2.957531473632805],
            [-59.962967916992184, 2.959431473632804],
            [-59.964767916992187, 2.962931473632807],
            [-59.962367916992186, 2.968531473632801],
            [-59.960667916992186, 2.972931473632805],
            [-59.958667916992184, 2.977131473632802],
            [-59.957367916992183, 2.983131473632803],
            [-59.955767916992187, 2.986131473632803],
            [-59.954267916992187, 2.9901314736328],
            [-59.952467916992184, 2.993731473632806],
            [-59.949667916992183, 2.996231473632804],
            [-59.947367916992185, 2.998931473632801],
            [-59.947467916992181, 3.002731473632807],
            [-59.949967916992186, 3.005631473632803],
            [-59.955167916992181, 3.009231473632802],
            [-59.95756791699219, 3.011731473632807],
            [-59.960067916992188, 3.013631473632806],
            [-59.96186791699219, 3.0175314736328],
            [-59.960467916992187, 3.0207314736328],
            [-59.959567916992185, 3.024331473632806],
            [-59.958967916992187, 3.028331473632804],
            [-59.957067916992187, 3.031531473632803],
            [-59.954667916992186, 3.035731473632801],
            [-59.951867916992185, 3.040731473632803],
            [-59.951267916992187, 3.044131473632802],
            [-59.951067916992187, 3.047531473632802],
            [-59.950767916992184, 3.054731473632806],
            [-59.951067916992187, 3.058431473632801],
            [-59.952067916992185, 3.061231473632802],
            [-59.953267916992189, 3.0663314736328],
            [-59.954267916992187, 3.069131473632801],
            [-59.956167916992186, 3.073731473632805],
            [-59.956467916992182, 3.077131473632804],
            [-59.955467916992184, 3.081731473632807],
            [-59.95156791699219, 3.083131473632804],
            [-59.946367916992187, 3.084431473632804],
            [-59.940967916992186, 3.086331473632804],
            [-59.937767916992186, 3.089931473632802],
            [-59.938367916992185, 3.093831473632804],
            [-59.939367916992182, 3.098131473632805],
            [-59.936967916992188, 3.102331473632802],
            [-59.933567916992189, 3.106331473632807],
            [-59.929367916992184, 3.109931473632805],
            [-59.924667916992185, 3.108731473632801],
            [-59.923067916992181, 3.112331473632807],
            [-59.92246791699219, 3.116731473632804],
            [-59.92216791699218, 3.120131473632803],
            [-59.918667916992185, 3.120931473632801],
            [-59.916767916992185, 3.116931473632803],
            [-59.915967916992187, 3.112331473632807],
            [-59.912367916992181, 3.110331473632804],
            [-59.908067916992181, 3.113931473632803],
            [-59.907367916992186, 3.118331473632807],
            [-59.908367916992184, 3.121231473632804],
            [-59.909167916992189, 3.124831473632803],
            [-59.912867916992184, 3.127031473632805],
            [-59.917067916992181, 3.126331473632803],
            [-59.918867916992184, 3.128931473632804],
            [-59.918967916992187, 3.132731473632802],
            [-59.916667916992182, 3.137531473632805],
            [-59.912567916992188, 3.139131473632801],
            [-59.908967916992182, 3.139131473632801],
            [-59.907567916992186, 3.141931473632802],
            [-59.908867916992186, 3.145331473632801],
            [-59.912767916992181, 3.146131473632806],
            [-59.916067916992183, 3.144931473632802],
            [-59.919967916992185, 3.145031473632805],
            [-59.918567916992188, 3.149431473632802],
            [-59.91446791699218, 3.149131473632806],
            [-59.910267916992183, 3.149131473632806],
            [-59.904267916992183, 3.149131473632806],
            [-59.901467916992189, 3.150731473632803],
            [-59.900067916992185, 3.154931473632807],
            [-59.901267916992182, 3.160231473632805],
            [-59.90376791699218, 3.165131473632805],
            [-59.905367916992184, 3.168931473632803],
            [-59.907667916992182, 3.172531473632802],
            [-59.909167916992189, 3.177331473632805],
            [-59.91006791699219, 3.181231473632806],
            [-59.911667916992187, 3.185731473632806],
            [-59.912467916992185, 3.190631473632806],
            [-59.912367916992181, 3.194731473632807],
            [-59.910567916992186, 3.199231473632807],
            [-59.909567916992188, 3.202831473632806],
            [-59.908667916992187, 3.207231473632802],
            [-59.908367916992184, 3.211331473632804],
            [-59.90676791699218, 3.213931473632805],
            [-59.902867916992186, 3.216131473632807],
            [-59.898767916992185, 3.216631473632802],
            [-59.895167916992186, 3.2157314736328],
            [-59.892167916992186, 3.215231473632805],
            [-59.888467916992184, 3.216331473632806],
            [-59.885167916992188, 3.217931473632802],
            [-59.88236791699218, 3.220231473632801],
            [-59.878767916992189, 3.221531473632801],
            [-59.875867916992185, 3.223131473632805],
            [-59.874467916992188, 3.225731473632806],
            [-59.875867916992185, 3.230131473632802],
            [-59.879167916992188, 3.232931473632803],
            [-59.882067916992185, 3.234631473632803],
            [-59.885967916992186, 3.237331473632807],
            [-59.887767916992189, 3.239931473632801],
            [-59.88736791699219, 3.242931473632801],
            [-59.88436791699219, 3.243931473632806],
            [-59.88106791699218, 3.244731473632804],
            [-59.879067916992184, 3.247331473632805],
            [-59.88136791699219, 3.253931473632804],
            [-59.88136791699219, 3.257331473632803],
            [-59.880467916992188, 3.260331473632803],
            [-59.877767916992184, 3.263331473632803],
            [-59.875767916992189, 3.266331473632803],
            [-59.873567916992187, 3.270331473632801],
            [-59.872967916992181, 3.274731473632805],
            [-59.871567916992184, 3.277331473632806],
            [-59.86866791699218, 3.278731473632803],
            [-59.863667916992185, 3.278531473632803],
            [-59.858867916992182, 3.279131473632802],
            [-59.857067916992186, 3.283731473632805],
            [-59.855667916992189, 3.287331473632804],
            [-59.85396791699219, 3.290531473632804],
            [-59.852067916992183, 3.2951314736328],
            [-59.852867916992182, 3.300931473632801],
            [-59.850267916992181, 3.305731473632804],
            [-59.846967916992185, 3.307531473632807],
            [-59.843167916992186, 3.308131473632805],
            [-59.84126791699218, 3.3103314736328],
            [-59.840867916992181, 3.3133314736328],
            [-59.841967916992189, 3.316731473632807],
            [-59.841467916992187, 3.319931473632806],
            [-59.838167916992184, 3.321331473632803],
            [-59.834767916992185, 3.3225314736328],
            [-59.833167916992181, 3.326131473632806],
            [-59.829667916992186, 3.329531473632805],
            [-59.82756791699218, 3.332231473632802],
            [-59.830467916992184, 3.3407314736328],
            [-59.832867916992186, 3.345531473632803],
            [-59.833967916992187, 3.348731473632803],
            [-59.834267916992189, 3.351931473632803],
            [-59.831267916992189, 3.355131473632802],
            [-59.827167916992181, 3.356531473632806],
            [-59.824267916992184, 3.356531473632806],
            [-59.820967916992188, 3.355731473632801],
            [-59.817367916992183, 3.354531473632804],
            [-59.810567916992184, 3.352331473632802],
            [-59.806367916992187, 3.353931473632805],
            [-59.804967916992183, 3.357331473632804],
            [-59.804467916992181, 3.360731473632804],
            [-59.804167916992185, 3.364331473632802],
            [-59.804367916992184, 3.370331473632802],
            [-59.80946791699219, 3.376131473632803],
            [-59.811267916992186, 3.380331473632801],
            [-59.811667916992185, 3.383731473632807],
            [-59.812467916992183, 3.387331473632806],
            [-59.813567916992184, 3.392131473632801],
            [-59.815167916992181, 3.395831473632803],
            [-59.817067916992187, 3.3987314736328],
            [-59.819167916992185, 3.405231473632803],
            [-59.821367916992187, 3.411731473632805],
            [-59.821367916992187, 3.416331473632802],
            [-59.814967916992188, 3.419531473632802],
            [-59.812967916992186, 3.421831473632807],
            [-59.81086791699218, 3.424731473632804],
            [-59.811867916992185, 3.428531473632802],
            [-59.814467916992186, 3.430331473632805],
            [-59.817767916992182, 3.430131473632805],
            [-59.822367916992185, 3.428331473632802],
            [-59.825467916992181, 3.425931473632801],
            [-59.829267916992187, 3.4231314736328],
            [-59.83226791699218, 3.422531473632802],
            [-59.836167916992181, 3.422531473632802],
            [-59.839067916992185, 3.423931473632805],
            [-59.84126791699218, 3.426531473632807],
            [-59.840667916992189, 3.433731473632804],
            [-59.840367916992186, 3.437031473632807],
            [-59.840567916992185, 3.440931473632801],
            [-59.839767916992187, 3.443931473632801],
            [-59.836967916992187, 3.446431473632806],
            [-59.833667916992184, 3.449231473632807],
            [-59.830367916992188, 3.453331473632801],
            [-59.828467916992182, 3.456931473632807],
            [-59.826867916992185, 3.459931473632807],
            [-59.825767916992184, 3.463531473632806],
            [-59.82456791699218, 3.467931473632802],
            [-59.822467916992181, 3.4719314736328],
            [-59.81886791699219, 3.472331473632806],
            [-59.815167916992181, 3.470531473632803],
            [-59.811267916992186, 3.469731473632805],
            [-59.810767916992184, 3.473731473632803],
            [-59.812267916992184, 3.476931473632803],
            [-59.814567916992189, 3.479331473632804],
            [-59.818767916992186, 3.479331473632804],
            [-59.824067916992185, 3.476731473632803],
            [-59.826867916992185, 3.479931473632803],
            [-59.82486791699219, 3.483131473632803],
            [-59.82156791699218, 3.484531473632806],
            [-59.815267916992184, 3.485331473632804],
            [-59.811867916992185, 3.485731473632804],
            [-59.808067916992186, 3.488231473632801],
            [-59.803267916992183, 3.491131473632805],
            [-59.801567916992184, 3.494731473632804],
            [-59.80216791699219, 3.497531473632804],
            [-59.802567916992189, 3.501331473632803],
            [-59.803367916992187, 3.504731473632802],
            [-59.805067916992186, 3.509531473632805],
            [-59.808667916992185, 3.512831473632801],
            [-59.812967916992186, 3.516431473632807],
            [-59.815567916992187, 3.519331473632803],
            [-59.818767916992186, 3.521531473632805],
            [-59.822467916992181, 3.523031473632805],
            [-59.826067916992187, 3.523931473632807],
            [-59.831467916992182, 3.522131473632804],
            [-59.834567916992185, 3.523931473632807],
            [-59.837867916992181, 3.527931473632805],
            [-59.839267916992185, 3.531131473632804],
            [-59.839867916992183, 3.534531473632804],
            [-59.84126791699218, 3.538131473632802],
            [-59.843667916992189, 3.542331473632807],
            [-59.844867916992186, 3.546831473632807],
            [-59.842967916992187, 3.552131473632805],
            [-59.845567916992181, 3.554031473632804],
            [-59.848467916992185, 3.554831473632802],
            [-59.852067916992183, 3.555331473632805],
            [-59.85666791699218, 3.555931473632803],
            [-59.860667916992185, 3.556931473632801],
            [-59.864967916992185, 3.558131473632805],
            [-59.869967916992181, 3.559731473632802],
            [-59.872267916992186, 3.563431473632804],
            [-59.868367916992185, 3.565131473632803],
            [-59.865867916992187, 3.567531473632805],
            [-59.862867916992187, 3.569531473632807],
            [-59.859167916992185, 3.5725314736328],
            [-59.862167916992185, 3.575331473632801],
            [-59.866067916992186, 3.577131473632804],
            [-59.862567916992184, 3.579331473632806],
            [-59.859267916992181, 3.580531473632803],
            [-59.857767916992188, 3.584931473632807],
            [-59.856367916992184, 3.589131473632804],
            [-59.854167916992182, 3.591931473632805],
            [-59.851667916992184, 3.594531473632806],
            [-59.850667916992187, 3.598731473632803],
            [-59.85096791699219, 3.603131473632807],
            [-59.848067916992186, 3.605331473632802],
            [-59.844167916992184, 3.605331473632802],
            [-59.840167916992186, 3.605931473632801],
            [-59.834667916992188, 3.607731473632803],
            [-59.830667916992184, 3.610131473632805],
            [-59.827467916992184, 3.611731473632801],
            [-59.82316791699219, 3.613931473632803],
            [-59.81986791699218, 3.613131473632805],
            [-59.816967916992184, 3.610731473632804],
            [-59.816367916992185, 3.614731473632801],
            [-59.816567916992184, 3.619331473632805],
            [-59.813467916992181, 3.619031473632802],
            [-59.810467916992181, 3.617131473632803],
            [-59.806067916992184, 3.617131473632803],
            [-59.803267916992183, 3.617631473632805],
            [-59.800067916992184, 3.618931473632806],
            [-59.795667916992187, 3.619531473632804],
            [-59.791667916992182, 3.618131473632801],
            [-59.787467916992185, 3.617331473632802],
            [-59.78476791699218, 3.619331473632805],
            [-59.781167916992189, 3.621931473632806],
            [-59.777967916992182, 3.624131473632801],
            [-59.775067916992185, 3.625631473632801],
            [-59.770667916992181, 3.626531473632802],
            [-59.765467916992186, 3.627531473632807],
            [-59.768167916992184, 3.631931473632804],
            [-59.763567916992187, 3.632731473632802],
            [-59.759267916992187, 3.634231473632802],
            [-59.758467916992188, 3.639331473632801],
            [-59.757467916992184, 3.643131473632806],
            [-59.75636791699219, 3.645831473632803],
            [-59.753867916992185, 3.649931473632805],
            [-59.751567916992187, 3.653031473632801],
            [-59.748767916992186, 3.654131473632802],
            [-59.745867916992182, 3.655731473632805],
            [-59.744167916992183, 3.658331473632806],
            [-59.741667916992185, 3.660731473632801],
            [-59.738667916992185, 3.661731473632805],
            [-59.735367916992189, 3.661931473632805],
            [-59.732467916992185, 3.662731473632803],
            [-59.729167916992182, 3.663331473632802],
            [-59.72426791699219, 3.663831473632804],
            [-59.72126791699219, 3.665731473632803],
            [-59.719467916992187, 3.668531473632804],
            [-59.719167916992184, 3.673931473632805],
            [-59.717267916992185, 3.676531473632807],
            [-59.714267916992185, 3.677931473632803],
            [-59.710467916992187, 3.679831473632802],
            [-59.708467916992184, 3.682931473632806],
            [-59.706867916992181, 3.686231473632802],
            [-59.704267916992187, 3.689131473632806],
            [-59.701067916992187, 3.689131473632806],
            [-59.697067916992182, 3.688731473632807],
            [-59.692467916992186, 3.689131473632806],
            [-59.689467916992186, 3.693331473632803],
            [-59.688067916992182, 3.696131473632803],
            [-59.686667916992185, 3.699531473632803],
            [-59.682467916992181, 3.696931473632802],
            [-59.679467916992181, 3.696931473632802],
            [-59.674667916992185, 3.700031473632805],
            [-59.668967916992187, 3.702831473632806],
            [-59.66776791699219, 3.706931473632807],
            [-59.665867916992184, 3.710531473632805],
            [-59.663167916992187, 3.713931473632805],
            [-59.663167916992187, 3.717931473632802],
            [-59.665567916992188, 3.719931473632805],
            [-59.668867916992184, 3.722131473632807],
            [-59.671067916992186, 3.726331473632804],
            [-59.669767916992186, 3.730531473632801],
            [-59.667567916992184, 3.732531473632804],
            [-59.664567916992183, 3.733131473632803],
            [-59.665967916992187, 3.736831473632805],
            [-59.668167916992189, 3.740431473632803],
            [-59.670367916992184, 3.743331473632807],
            [-59.673167916992185, 3.744031473632802],
            [-59.674667916992185, 3.747331473632805],
            [-59.673167916992185, 3.751731473632802],
            [-59.674167916992189, 3.755131473632801],
            [-59.677867916992184, 3.755331473632801],
            [-59.679667916992187, 3.757531473632802],
            [-59.676367916992184, 3.7600314736328],
            [-59.672867916992189, 3.761931473632806],
            [-59.669967916992185, 3.766431473632807],
            [-59.668667916992185, 3.771931473632804],
            [-59.666167916992187, 3.775531473632803],
            [-59.665367916992182, 3.778731473632803],
            [-59.663967916992185, 3.782131473632802],
            [-59.659467916992185, 3.7829314736328],
            [-59.655367916992184, 3.785731473632801],
            [-59.650967916992187, 3.785531473632801],
            [-59.647567916992188, 3.787131473632805],
            [-59.644067916992185, 3.786331473632806],
            [-59.640667916992186, 3.784731473632803],
            [-59.637967916992181, 3.786031473632804],
            [-59.636267916992182, 3.790131473632805],
            [-59.63566791699219, 3.793131473632805],
            [-59.633467916992188, 3.795331473632807],
            [-59.629867916992183, 3.798531473632806],
            [-59.62636791699218, 3.796131473632805],
            [-59.628067916992187, 3.793231473632801],
            [-59.631267916992186, 3.792331473632807],
            [-59.632467916992184, 3.789331473632807],
            [-59.630067916992189, 3.785631473632805],
            [-59.624767916992184, 3.790131473632805],
            [-59.619367916992189, 3.791031473632806],
            [-59.616867916992184, 3.793131473632805],
            [-59.615067916992189, 3.797131473632803],
            [-59.612867916992187, 3.801331473632807],
            [-59.60966791699218, 3.804331473632807],
            [-59.606067916992188, 3.801731473632806],
            [-59.603467916992187, 3.796831473632807],
            [-59.596767916992185, 3.794931473632801],
            [-59.595667916992184, 3.799331473632805],
            [-59.594867916992186, 3.802531473632804],
            [-59.594067916992188, 3.805931473632803],
            [-59.594867916992186, 3.808731473632804],
            [-59.59756791699219, 3.811131473632805],
            [-59.597867916992186, 3.814531473632805],
            [-59.593367916992186, 3.814731473632804],
            [-59.589767916992187, 3.8163314736328],
            [-59.587967916992184, 3.819131473632801],
            [-59.585167916992184, 3.823131473632806],
            [-59.582667916992186, 3.819731473632807],
            [-59.578967916992184, 3.816931473632806],
            [-59.57786791699219, 3.821331473632803],
            [-59.578167916992186, 3.825331473632801],
            [-59.578167916992186, 3.830831473632806],
            [-59.580767916992187, 3.833531473632803],
            [-59.584367916992186, 3.833931473632802],
            [-59.586467916992184, 3.836931473632802],
            [-59.585867916992186, 3.841131473632807],
            [-59.587567916992185, 3.844731473632805],
            [-59.588367916992183, 3.847931473632805],
            [-59.588967916992189, 3.852431473632805],
            [-59.586967916992187, 3.857331473632804],
            [-59.590467916992182, 3.858231473632806],
            [-59.592967916992187, 3.860131473632805],
            [-59.59126791699218, 3.864031473632807],
            [-59.590167916992186, 3.867131473632803],
            [-59.591167916992184, 3.869831473632807],
            [-59.592567916992181, 3.872931473632804],
            [-59.593667916992189, 3.877531473632807],
            [-59.594167916992184, 3.881931473632804],
            [-59.593367916992186, 3.884931473632804],
            [-59.59126791699218, 3.887831473632801],
            [-59.587967916992184, 3.889131473632801],
            [-59.583767916992187, 3.888931473632802],
            [-59.579667916992186, 3.890931473632804],
            [-59.576067916992187, 3.890731473632805],
            [-59.576067916992187, 3.896331473632806],
            [-59.571367916992187, 3.892131473632801],
            [-59.567767916992182, 3.891131473632804],
            [-59.567767916992182, 3.895331473632801],
            [-59.568267916992184, 3.898331473632801],
            [-59.567367916992183, 3.901531473632801],
            [-59.56686791699218, 3.905131473632807],
            [-59.566267916992189, 3.909931473632803],
            [-59.565467916992183, 3.915731473632803],
            [-59.561567916992189, 3.920731473632806],
            [-59.558367916992182, 3.920431473632803],
            [-59.555267916992186, 3.919931473632801],
            [-59.55216791699219, 3.919131473632802],
            [-59.548567916992184, 3.919131473632802],
            [-59.544967916992185, 3.920731473632806],
            [-59.541967916992185, 3.922731473632801],
            [-59.539867916992186, 3.925431473632806],
            [-59.542267916992188, 3.928931473632801],
            [-59.53976791699219, 3.931731473632802],
            [-59.536667916992187, 3.929331473632807],
            [-59.532767916992185, 3.926731473632806],
            [-59.528167916992189, 3.924731473632804],
            [-59.525367916992181, 3.9276314736328],
            [-59.524267916992187, 3.932931473632806],
            [-59.526267916992182, 3.937731473632802],
            [-59.522667916992184, 3.9413314736328],
            [-59.518767916992189, 3.943431473632806],
            [-59.516767916992187, 3.946331473632803],
            [-59.519267916992185, 3.947831473632803],
            [-59.522167916992188, 3.951331473632806],
            [-59.520167916992186, 3.954531473632805],
            [-59.522167916992188, 3.958331473632803],
            [-59.52436791699219, 3.961331473632804],
            [-59.525867916992183, 3.964331473632804],
            [-59.526567916992185, 3.968031473632806],
            [-59.530367916992184, 3.969131473632807],
            [-59.53376791699219, 3.971331473632802],
            [-59.537767916992181, 3.971531473632801],
            [-59.542067916992181, 3.971331473632802],
            [-59.544567916992186, 3.973531473632804],
            [-59.544767916992186, 3.977731473632801],
            [-59.548067916992181, 3.978231473632803],
            [-59.551967916992183, 3.979131473632805],
            [-59.556567916992186, 3.979131473632805],
            [-59.558567916992189, 3.976331473632804],
            [-59.560467916992181, 3.973131473632805],
            [-59.56416791699219, 3.971531473632801],
            [-59.566267916992189, 3.968531473632801],
            [-59.567467916992186, 3.965231473632805],
            [-59.569667916992188, 3.968031473632806],
            [-59.572367916992185, 3.969931473632805],
            [-59.576767916992182, 3.968831473632804],
            [-59.580667916992184, 3.968031473632806],
            [-59.584267916992189, 3.969131473632807],
            [-59.585967916992189, 3.972731473632805],
            [-59.586167916992181, 3.975931473632805],
            [-59.583467916992184, 3.979531473632804],
            [-59.580767916992187, 3.982131473632805],
            [-59.57756791699218, 3.9841314736328],
            [-59.574967916992186, 3.985431473632801],
            [-59.572467916992181, 3.987631473632803],
            [-59.574267916992184, 3.991731473632804],
            [-59.575967916992184, 3.994331473632805],
            [-59.578267916992189, 3.996231473632804],
            [-59.578967916992184, 3.9993314736328],
            [-59.581167916992186, 4.0023314736328],
            [-59.584267916992189, 4.002531473632807],
            [-59.583967916992187, 3.998331473632803],
            [-59.584867916992181, 3.994831473632807],
            [-59.589467916992184, 3.995331473632802],
            [-59.592267916992185, 3.998431473632806],
            [-59.59596791699218, 4.001531473632802],
            [-59.593667916992189, 4.005631473632803],
            [-59.592067916992185, 4.009231473632802],
            [-59.593367916992186, 4.013131473632804],
            [-59.59456791699219, 4.0175314736328],
            [-59.597867916992186, 4.015531473632805],
            [-59.600367916992184, 4.011731473632807],
            [-59.603167916992184, 4.0085314736328],
            [-59.606067916992188, 4.007031473632807],
            [-59.608867916992182, 4.009531473632805],
            [-59.610267916992186, 4.012731473632805],
            [-59.613067916992186, 4.015931473632804],
            [-59.611367916992187, 4.018631473632801],
            [-59.608567916992186, 4.016331473632803],
            [-59.60526791699219, 4.014931473632807],
            [-59.601167916992182, 4.017131473632801],
            [-59.602367916992186, 4.019731473632802],
            [-59.603067916992188, 4.023331473632801],
            [-59.60196791699218, 4.027731473632805],
            [-59.604767916992188, 4.031131473632804],
            [-59.609267916992181, 4.032431473632805],
            [-59.612567916992184, 4.033831473632802],
            [-59.615367916992184, 4.035731473632801],
            [-59.618367916992185, 4.037331473632804],
            [-59.620167916992187, 4.040531473632804],
            [-59.619367916992189, 4.044331473632802],
            [-59.618067916992189, 4.0481314736328],
            [-59.617967916992185, 4.051331473632807],
            [-59.621167916992185, 4.053531473632802],
            [-59.625767916992189, 4.053931473632801],
            [-59.629067916992184, 4.052331473632805],
            [-59.632067916992185, 4.054031473632804],
            [-59.634867916992185, 4.055731473632804],
            [-59.637467916992186, 4.058731473632804],
            [-59.640167916992183, 4.061731473632804],
            [-59.641467916992184, 4.064731473632804],
            [-59.643267916992187, 4.067531473632805],
            [-59.646467916992187, 4.067731473632804],
            [-59.649767916992189, 4.068131473632803],
            [-59.65376791699218, 4.071131473632803],
            [-59.652367916992183, 4.074131473632804],
            [-59.650367916992181, 4.076431473632802],
            [-59.648667916992181, 4.078931473632807],
            [-59.646867916992186, 4.082231473632802],
            [-59.645667916992181, 4.084931473632807],
            [-59.643967916992182, 4.088731473632805],
            [-59.640667916992186, 4.092331473632804],
            [-59.638567916992187, 4.095931473632803],
            [-59.63736791699219, 4.098731473632803],
            [-59.636067916992189, 4.101931473632803],
            [-59.634567916992182, 4.104931473632803],
            [-59.632767916992186, 4.108131473632803],
            [-59.630967916992184, 4.112931473632806],
            [-59.627367916992185, 4.116231473632801],
            [-59.625567916992182, 4.119331473632805],
            [-59.625567916992182, 4.122331473632805],
            [-59.624467916992188, 4.126331473632803],
            [-59.622167916992183, 4.130131473632801],
            [-59.619767916992188, 4.131931473632804],
            [-59.62336791699218, 4.134731473632804],
            [-59.626967916992186, 4.137331473632806],
            [-59.63136791699219, 4.138531473632803],
            [-59.635167916992188, 4.141731473632802],
            [-59.64006791699218, 4.143331473632806],
            [-59.644867916992183, 4.144431473632807],
            [-59.648767916992185, 4.145531473632801],
            [-59.651767916992185, 4.145831473632803],
            [-59.655867916992186, 4.146131473632806],
            [-59.660867916992188, 4.146131473632806],
            [-59.663867916992189, 4.145831473632803],
            [-59.666667916992182, 4.144131473632804],
            [-59.669967916992185, 4.145031473632805],
            [-59.673367916992184, 4.146531473632805],
            [-59.677767916992181, 4.146731473632805],
            [-59.683067916992186, 4.146931473632804],
            [-59.687967916992186, 4.149431473632802],
            [-59.692667916992185, 4.151531473632801],
            [-59.696367916992187, 4.153931473632802],
            [-59.699067916992185, 4.156931473632802],
            [-59.702167916992181, 4.160131473632802],
            [-59.705767916992187, 4.160531473632801],
            [-59.709367916992186, 4.161931473632805],
            [-59.712867916992181, 4.163331473632802],
            [-59.715067916992183, 4.166331473632802],
            [-59.718367916992186, 4.166731473632801],
            [-59.721767916992185, 4.165731473632803],
            [-59.723367916992188, 4.169531473632802],
            [-59.72226791699218, 4.172731473632801],
            [-59.723667916992184, 4.175731473632801],
            [-59.727367916992186, 4.178931473632801],
            [-59.730667916992189, 4.181531473632802],
            [-59.729767916992188, 4.184831473632805],
            [-59.727567916992186, 4.187531473632802],
            [-59.72896791699219, 4.192031473632802],
            [-59.730867916992182, 4.196131473632803],
            [-59.732767916992188, 4.199231473632807],
            [-59.734367916992184, 4.203331473632801],
            [-59.737167916992185, 4.208331473632803],
            [-59.738667916992185, 4.212131473632802],
            [-59.738367916992189, 4.216531473632806],
            [-59.736367916992187, 4.220531473632803],
            [-59.736067916992184, 4.224331473632802],
            [-59.736367916992187, 4.228731473632806],
            [-59.739367916992187, 4.231831473632802],
            [-59.739067916992184, 4.235931473632803],
            [-59.736767916992186, 4.239031473632807],
            [-59.735067916992186, 4.241931473632803],
            [-59.732867916992184, 4.243931473632806],
            [-59.730667916992189, 4.246931473632806],
            [-59.728367916992184, 4.249831473632803],
            [-59.726467916992185, 4.2523314736328],
            [-59.724567916992186, 4.254531473632802],
            [-59.722867916992186, 4.257031473632807],
            [-59.719867916992186, 4.259931473632804],
            [-59.716167916992184, 4.264131473632801],
            [-59.711467916992184, 4.266431473632807],
            [-59.711767916992187, 4.2707314736328],
            [-59.715067916992183, 4.272731473632803],
            [-59.71926791699218, 4.274331473632806],
            [-59.722667916992187, 4.271731473632805],
            [-59.725067916992188, 4.269131473632804],
            [-59.728867916992186, 4.268131473632806],
            [-59.732067916992186, 4.270331473632801],
            [-59.733567916992186, 4.274431473632802],
            [-59.731367916992184, 4.277131473632807],
            [-59.729467916992185, 4.279931473632807],
            [-59.731767916992183, 4.2829314736328],
            [-59.73196791699219, 4.286031473632804],
            [-59.729167916992182, 4.288531473632801],
            [-59.724167916992187, 4.293531473632804],
            [-59.72096791699218, 4.296731473632803],
            [-59.719067916992188, 4.3011314736328],
            [-59.715967916992184, 4.304331473632807],
            [-59.711467916992184, 4.306531473632802],
            [-59.70756791699219, 4.305331473632805],
            [-59.70456791699219, 4.307131473632801],
            [-59.703267916992189, 4.310931473632806],
            [-59.705467916992184, 4.315531473632802],
            [-59.703567916992185, 4.319931473632806],
            [-59.700667916992188, 4.323131473632806],
            [-59.698267916992187, 4.325031473632805],
            [-59.695967916992188, 4.328631473632804],
            [-59.692967916992188, 4.332231473632802],
            [-59.689367916992182, 4.335731473632805],
            [-59.685467916992181, 4.338031473632803],
            [-59.682167916992185, 4.340231473632805],
            [-59.679067916992182, 4.342731473632803],
            [-59.676867916992187, 4.348331473632804],
            [-59.678567916992186, 4.352331473632802],
            [-59.679167916992185, 4.355531473632801],
            [-59.681967916992186, 4.357731473632803],
            [-59.683267916992186, 4.360731473632804],
            [-59.682967916992183, 4.364331473632802],
            [-59.680467916992185, 4.367931473632801],
            [-59.677267916992186, 4.369331473632805],
            [-59.673367916992184, 4.370531473632802],
            [-59.670367916992184, 4.372331473632805],
            [-59.670067916992181, 4.375631473632801],
            [-59.672567916992186, 4.3773314736328],
            [-59.676867916992187, 4.380531473632807],
            [-59.679767916992184, 4.382831473632805],
            [-59.683667916992185, 4.384931473632804],
            [-59.689067916992187, 4.382731473632802],
            [-59.69216791699219, 4.380131473632801],
            [-59.694967916992184, 4.381131473632806],
            [-59.697967916992184, 4.383731473632807],
            [-59.702067916992185, 4.386331473632801],
            [-59.705167916992181, 4.388631473632806],
            [-59.709367916992186, 4.390931473632804],
            [-59.712267916992189, 4.393931473632804],
            [-59.716167916992184, 4.396531473632805],
            [-59.720267916992185, 4.397931473632802],
            [-59.721467916992182, 4.400531473632803],
            [-59.719767916992183, 4.404131473632802],
            [-59.720867916992184, 4.408831473632802],
            [-59.723667916992184, 4.411031473632804],
            [-59.727367916992186, 4.410731473632801],
            [-59.730567916992186, 4.415931473632803],
            [-59.734467916992187, 4.421531473632804],
            [-59.737567916992184, 4.423931473632805],
            [-59.741467916992185, 4.422331473632802],
            [-59.744467916992186, 4.422731473632801],
            [-59.747667916992185, 4.421531473632804],
            [-59.752167916992185, 4.420731473632806],
            [-59.755767916992184, 4.421331473632804],
            [-59.755967916992184, 4.424731473632804],
            [-59.759167916992183, 4.428931473632801],
            [-59.760967916992186, 4.432531473632807],
            [-59.763867916992183, 4.434131473632803],
            [-59.765767916992189, 4.436731473632804],
            [-59.768467916992186, 4.438431473632804],
            [-59.771767916992189, 4.438731473632807],
            [-59.776467916992189, 4.440631473632806],
            [-59.781167916992189, 4.441531473632807],
            [-59.779467916992189, 4.444931473632806],
            [-59.780867916992186, 4.448131473632806],
            [-59.78346791699218, 4.450031473632805],
            [-59.786667916992187, 4.447731473632807],
            [-59.789867916992186, 4.446731473632802],
            [-59.793667916992185, 4.4475314736328],
            [-59.796067916992186, 4.450031473632805],
            [-59.796167916992189, 4.453331473632801],
            [-59.79576791699219, 4.456931473632807],
            [-59.79416791699218, 4.463031473632803],
            [-59.795667916992187, 4.465531473632801],
            [-59.800567916992186, 4.466631473632802],
            [-59.804167916992185, 4.466331473632806],
            [-59.805467916992185, 4.463031473632803],
            [-59.805867916992185, 4.459931473632807],
            [-59.806867916992189, 4.453631473632804],
            [-59.80916791699218, 4.450331473632801],
            [-59.811867916992185, 4.447731473632807],
            [-59.817667916992185, 4.447731473632807],
            [-59.822667916992188, 4.448331473632805],
            [-59.82786791699219, 4.449931473632802],
            [-59.831767916992185, 4.452731473632802],
            [-59.833267916992185, 4.457531473632805],
            [-59.838967916992189, 4.455531473632803],
            [-59.844467916992187, 4.454931473632804],
            [-59.848467916992185, 4.4535314736328],
            [-59.852367916992186, 4.455331473632803],
            [-59.856067916992188, 4.455331473632803],
            [-59.860567916992181, 4.452731473632802],
            [-59.864467916992183, 4.454531473632805],
            [-59.867767916992186, 4.457931473632804],
            [-59.871367916992185, 4.461331473632804],
            [-59.870167916992187, 4.466031473632803],
            [-59.866667916992185, 4.469731473632805],
            [-59.863567916992181, 4.471631473632804],
            [-59.866667916992185, 4.474631473632805],
            [-59.870267916992184, 4.476531473632804],
            [-59.874467916992188, 4.478231473632803],
            [-59.874867916992187, 4.481531473632806],
            [-59.873067916992184, 4.484631473632803],
            [-59.875767916992189, 4.486531473632802],
            [-59.878067916992187, 4.483531473632802],
            [-59.878067916992187, 4.480531473632801],
            [-59.878767916992189, 4.477731473632801],
            [-59.880467916992188, 4.475131473632807],
            [-59.884267916992187, 4.475931473632805],
            [-59.887667916992186, 4.477431473632805],
            [-59.890967916992182, 4.478131473632807],
            [-59.893967916992182, 4.477731473632801],
            [-59.897967916992187, 4.478531473632806],
            [-59.900067916992185, 4.476031473632801],
            [-59.902367916992183, 4.469731473632805],
            [-59.90376791699218, 4.466131473632807],
            [-59.905467916992187, 4.461331473632804],
            [-59.910267916992183, 4.460831473632801],
            [-59.913067916992183, 4.4627314736328],
            [-59.915667916992184, 4.466331473632806],
            [-59.914567916992183, 4.470531473632803],
            [-59.913067916992183, 4.474531473632801],
            [-59.911967916992182, 4.4779314736328],
            [-59.915667916992184, 4.478231473632803],
            [-59.920267916992188, 4.479931473632803],
            [-59.926567916992184, 4.479931473632803],
            [-59.930567916992189, 4.481531473632806],
            [-59.928267916992183, 4.485931473632803],
            [-59.926167916992185, 4.489931473632801],
            [-59.929767916992184, 4.492331473632802],
            [-59.934867916992189, 4.494531473632804],
            [-59.937767916992186, 4.499131473632801],
            [-59.941967916992184, 4.497631473632801],
            [-59.946267916992184, 4.498931473632801],
            [-59.950667916992188, 4.499831473632803],
            [-59.954267916992187, 4.502931473632806],
            [-59.959267916992189, 4.504931473632801],
            [-59.962867916992181, 4.507531473632802],
            [-59.96656791699219, 4.508731473632807],
            [-59.971667916992189, 4.507831473632805],
            [-59.971467916992182, 4.503931473632804],
            [-59.97226791699218, 4.500931473632804],
            [-59.972367916992184, 4.496731473632806],
            [-59.974167916992187, 4.493331473632807],
            [-59.974867916992181, 4.489531473632802],
            [-59.97256791699219, 4.484331473632807],
            [-59.973067916992186, 4.481331473632807],
            [-59.976367916992189, 4.482931473632803],
            [-59.97896791699219, 4.485331473632804],
            [-59.983567916992186, 4.4871314736328],
            [-59.988267916992186, 4.485731473632804],
            [-59.991467916992185, 4.486731473632801],
            [-59.994367916992189, 4.488131473632805],
            [-59.997767916992188, 4.491131473632805],
            [-60.000167916992183, 4.493131473632801],
            [-60.002367916992185, 4.495131473632803],
            [-60.005567916992185, 4.495731473632802],
            [-60.009167916992183, 4.495531473632802],
            [-60.011367916992185, 4.493531473632807],
            [-60.013967916992186, 4.490931473632806],
            [-60.017867916992188, 4.489931473632801],
            [-60.021567916992183, 4.492331473632802],
            [-60.024767916992189, 4.494031473632802],
            [-60.028467916992184, 4.492531473632802],
            [-60.032567916992186, 4.490931473632806],
            [-60.036667916992187, 4.490431473632803],
            [-60.03976791699219, 4.491131473632805],
            [-60.043167916992189, 4.492531473632802],
            [-60.046167916992189, 4.494531473632804],
            [-60.049767916992181, 4.494331473632805],
            [-60.053067916992184, 4.494731473632804],
            [-60.056567916992186, 4.495331473632802],
            [-60.05946791699219, 4.495131473632803],
            [-60.064067916992187, 4.495331473632802],
            [-60.067767916992182, 4.493931473632806],
            [-60.070967916992188, 4.493731473632806],
            [-60.07156791699218, 4.497031473632802],
            [-60.071067916992185, 4.500631473632801],
            [-60.06986791699218, 4.503731473632804],
            [-60.068467916992184, 4.507031473632807],
            [-60.067667916992185, 4.512331473632806],
            [-60.066567916992184, 4.516131473632804],
            [-60.064367916992182, 4.518531473632805],
            [-60.066667916992188, 4.523331473632801],
            [-60.06986791699218, 4.524331473632806],
            [-60.073267916992187, 4.524731473632805],
            [-60.078467916992182, 4.523931473632807],
            [-60.080067916992185, 4.5207314736328],
            [-60.081767916992185, 4.517931473632807],
            [-60.085667916992186, 4.5175314736328],
            [-60.085867916992186, 4.5207314736328],
            [-60.084867916992181, 4.5237314736328],
            [-60.083267916992185, 4.527531473632806],
            [-60.084367916992186, 4.531631473632807],
            [-60.087367916992186, 4.533331473632806],
            [-60.090667916992189, 4.5329314736328],
            [-60.093667916992189, 4.528731473632803],
            [-60.098467916992185, 4.528331473632804],
            [-60.103367916992184, 4.527531473632806],
            [-60.104767916992188, 4.5237314736328],
            [-60.102767916992185, 4.521131473632806],
            [-60.09896791699218, 4.518531473632805],
            [-60.097667916992187, 4.514731473632807],
            [-60.099167916992187, 4.511731473632807],
            [-60.101767916992188, 4.5085314736328],
            [-60.10526791699219, 4.506531473632805],
            [-60.107567916992181, 4.504531473632802],
            [-60.110867916992184, 4.505131473632801],
            [-60.114167916992187, 4.504231473632807],
            [-60.11766791699219, 4.502031473632805],
            [-60.120867916992182, 4.501931473632801],
            [-60.122667916992185, 4.504231473632807],
            [-60.124067916992189, 4.506931473632804],
            [-60.128767916992189, 4.508131473632801],
            [-60.131667916992185, 4.510631473632806],
            [-60.134967916992181, 4.512331473632806],
            [-60.137967916992181, 4.5145314736328],
            [-60.142967916992184, 4.513631473632806],
            [-60.146867916992186, 4.512331473632806],
            [-60.150067916992185, 4.511431473632804],
            [-60.153167916992189, 4.512131473632806],
            [-60.156967916992187, 4.513931473632802],
            [-60.159567916992188, 4.516731473632802],
            [-60.161367916992184, 4.519431473632807],
            [-60.160567916992186, 4.522931473632802],
            [-60.15846791699218, 4.525331473632804],
            [-60.158367916992184, 4.528331473632804],
            [-60.15876791699219, 4.532331473632802],
            [-60.158067916992181, 4.536031473632804],
            [-60.157367916992186, 4.539131473632807],
            [-60.156167916992189, 4.541831473632804],
            [-60.154467916992189, 4.544931473632801],
            [-60.154467916992189, 4.549531473632804],
            [-60.156567916992188, 4.5526314736328],
            [-60.15976791699218, 4.554531473632807],
            [-60.160967916992185, 4.557931473632806],
            [-60.160167916992187, 4.561931473632804],
            [-60.157767916992185, 4.565131473632803],
            [-60.156967916992187, 4.571331473632803],
            [-60.155467916992187, 4.574731473632802],
            [-60.152867916992186, 4.575931473632807],
            [-60.149467916992187, 4.574231473632807],
            [-60.147967916992187, 4.569731473632807],
            [-60.144267916992185, 4.566931473632806],
            [-60.139967916992184, 4.566531473632807],
            [-60.136267916992182, 4.565931473632801],
            [-60.138467916992184, 4.570931473632804],
            [-60.138567916992187, 4.575931473632807],
            [-60.139667916992181, 4.579331473632806],
            [-60.135967916992186, 4.580731473632802],
            [-60.133067916992189, 4.578931473632807],
            [-60.130267916992182, 4.577131473632804],
            [-60.128067916992187, 4.575331473632801],
            [-60.124467916992188, 4.576431473632802],
            [-60.122967916992181, 4.579131473632806],
            [-60.125467916992186, 4.582131473632806],
            [-60.128067916992187, 4.585531473632805],
            [-60.129167916992188, 4.589931473632802],
            [-60.130267916992182, 4.593331473632801],
            [-60.130667916992181, 4.596531473632801],
            [-60.128767916992189, 4.600131473632807],
            [-60.124367916992185, 4.604131473632805],
            [-60.119767916992188, 4.604931473632803],
            [-60.116467916992185, 4.604531473632804],
            [-60.113667916992185, 4.6029314736328],
            [-60.111067916992184, 4.604331473632804],
            [-60.108067916992184, 4.607731473632803],
            [-60.104467916992185, 4.607331473632804],
            [-60.100367916992184, 4.604331473632804],
            [-60.096167916992187, 4.604531473632804],
            [-60.094067916992188, 4.606731473632806],
            [-60.090067916992183, 4.605731473632801],
            [-60.086467916992184, 4.608231473632806],
            [-60.082967916992189, 4.6121314736328],
            [-60.079667916992186, 4.613531473632804],
            [-60.076867916992185, 4.615731473632806],
            [-60.073967916992189, 4.6151314736328],
            [-60.070967916992188, 4.616531473632804],
            [-60.070667916992186, 4.620731473632802],
            [-60.072967916992184, 4.6243314736328],
            [-60.074067916992185, 4.628331473632805],
            [-60.075167916992186, 4.631731473632804],
            [-60.07456791699218, 4.636331473632801],
            [-60.070267916992186, 4.638531473632803],
            [-60.069567916992185, 4.643131473632806],
            [-60.070667916992186, 4.646531473632805],
            [-60.072467916992181, 4.649731473632805],
            [-60.075767916992184, 4.649131473632806],
            [-60.075967916992184, 4.653331473632804],
            [-60.074267916992184, 4.656931473632802],
            [-60.072467916992181, 4.659331473632804],
            [-60.070567916992189, 4.662131473632805],
            [-60.068267916992184, 4.664931473632805],
            [-60.065167916992181, 4.668131473632805],
            [-60.062267916992184, 4.671331473632804],
            [-60.06086791699218, 4.673931473632805],
            [-60.058567916992189, 4.6776314736328],
            [-60.054467916992181, 4.679731473632806],
            [-60.053067916992184, 4.682931473632806],
            [-60.050067916992184, 4.686231473632802],
            [-60.046467916992185, 4.689131473632806],
            [-60.04416791699218, 4.6913314736328],
            [-60.041667916992182, 4.695131473632806],
            [-60.037067916992186, 4.696731473632802],
            [-60.032667916992182, 4.698931473632804],
            [-60.030167916992184, 4.702531473632803],
            [-60.027967916992182, 4.704531473632805],
            [-60.025667916992184, 4.706331473632801],
            [-60.026167916992186, 4.709431473632804],
            [-60.026967916992184, 4.713031473632803],
            [-60.029867916992188, 4.714931473632802],
            [-60.032567916992186, 4.717331473632804],
            [-60.035567916992186, 4.719131473632807],
            [-60.037567916992188, 4.722131473632807],
            [-60.038367916992186, 4.725931473632805],
            [-60.036667916992187, 4.729631473632807],
            [-60.035567916992186, 4.732531473632804],
            [-60.03346791699218, 4.734631473632803],
            [-60.030167916992184, 4.735931473632803],
            [-60.027667916992186, 4.738531473632804],
            [-60.025967916992187, 4.741931473632803],
            [-60.025367916992181, 4.744831473632807],
            [-60.024567916992183, 4.748931473632801],
            [-60.024867916992186, 4.7523314736328],
            [-60.025067916992185, 4.755631473632803],
            [-60.024767916992189, 4.759731473632804],
            [-60.026467916992189, 4.764131473632801],
            [-60.026467916992189, 4.7675314736328],
            [-60.025167916992189, 4.771131473632806],
            [-60.023767916992185, 4.773931473632807],
            [-60.023467916992189, 4.776931473632807],
            [-60.022567916992188, 4.7797314736328],
            [-60.022267916992185, 4.783031473632803],
            [-60.026567916992185, 4.785531473632801],
            [-60.029267916992183, 4.7874314736328],
            [-60.033667916992187, 4.788331473632802],
            [-60.035867916992188, 4.790531473632804],
            [-60.035567916992186, 4.794331473632802],
            [-60.034167916992189, 4.798231473632804],
            [-60.034167916992189, 4.802931473632803],
            [-60.030167916992184, 4.807631473632803],
            [-60.025167916992189, 4.809831473632805],
            [-60.022267916992185, 4.812531473632802],
            [-60.02436791699219, 4.815131473632803],
            [-60.027867916992186, 4.819231473632804],
            [-60.026567916992185, 4.823531473632805],
            [-60.025467916992184, 4.827731473632802],
            [-60.025367916992181, 4.831731473632807],
            [-60.022867916992183, 4.833931473632802],
            [-60.018667916992186, 4.836331473632804],
            [-60.016867916992183, 4.838531473632806],
            [-60.014567916992185, 4.841531473632806],
            [-60.013267916992184, 4.844131473632807],
            [-60.013167916992188, 4.848831473632806],
            [-60.011467916992189, 4.8529314736328],
            [-60.010767916992187, 4.857331473632804],
            [-60.009167916992183, 4.861831473632805],
            [-60.009167916992183, 4.864931473632801],
            [-60.010267916992184, 4.868931473632806],
            [-60.009967916992181, 4.873331473632803],
            [-60.006567916992182, 4.877031473632805],
            [-60.005167916992185, 4.881731473632804],
            [-60.005267916992182, 4.885531473632803],
            [-60.006567916992182, 4.888531473632803],
            [-60.005567916992185, 4.891731473632802],
            [-60.005167916992185, 4.895031473632805],
            [-60.004067916992184, 4.900131473632804],
            [-60.00166791699219, 4.905531473632806],
            [-60.002367916992185, 4.909731473632803],
            [-60.002167916992185, 4.913531473632801],
            [-60.00166791699219, 4.916731473632801],
            [-59.998867916992182, 4.918731473632803],
            [-59.998067916992184, 4.921831473632807],
            [-59.998067916992184, 4.924931473632803],
            [-59.997967916992181, 4.928331473632802],
            [-59.996567916992184, 4.931731473632802],
            [-59.99366791699218, 4.934331473632803],
            [-59.992467916992183, 4.937531473632802],
            [-59.994067916992186, 4.940131473632803],
            [-59.994167916992183, 4.944731473632807],
            [-59.993267916992181, 4.949931473632802],
            [-59.990067916992189, 4.949931473632802],
            [-59.98966791699219, 4.9535314736328],
            [-59.990467916992188, 4.956331473632801],
            [-59.991667916992185, 4.959431473632804],
            [-59.992267916992184, 4.9627314736328],
            [-59.993867916992187, 4.965131473632802],
            [-59.991567916992182, 4.968831473632804],
            [-59.989367916992187, 4.9719314736328],
            [-59.987567916992184, 4.974331473632802],
            [-59.986967916992185, 4.977731473632801],
            [-59.990067916992189, 4.983731473632801],
            [-59.989467916992183, 4.987631473632803],
            [-59.987467916992188, 4.991331473632805],
            [-59.987867916992187, 4.997331473632805],
            [-59.988867916992184, 5.001931473632801],
            [-59.986867916992182, 5.006531473632805],
            [-59.985267916992186, 5.009131473632806],
            [-59.985267916992186, 5.012331473632806],
            [-59.98496791699219, 5.017231473632805],
            [-59.980667916992189, 5.016431473632807],
            [-59.978067916992188, 5.019131473632804],
            [-59.977567916992186, 5.022931473632802],
            [-59.976667916992184, 5.026631473632804],
            [-59.975267916992181, 5.030531473632806],
            [-59.977467916992182, 5.033731473632805],
            [-59.977567916992186, 5.038531473632801],
            [-59.978067916992188, 5.042331473632807],
            [-59.97696791699218, 5.045931473632805],
            [-59.975567916992183, 5.049531473632804],
            [-59.977467916992182, 5.053531473632802],
            [-59.975067916992188, 5.056231473632806],
            [-59.972367916992184, 5.058931473632803],
            [-59.970667916992184, 5.062731473632802],
            [-59.971667916992189, 5.066731473632807],
            [-59.971367916992186, 5.070531473632805],
            [-59.971667916992189, 5.073531473632805],
            [-59.973367916992188, 5.076431473632802],
            [-59.976767916992188, 5.078931473632807],
            [-59.981167916992185, 5.080531473632803],
            [-59.98496791699219, 5.082931473632804],
            [-59.989367916992187, 5.084131473632802],
            [-59.99366791699218, 5.085531473632805],
            [-59.997667916992185, 5.083531473632803],
            [-60.000767916992189, 5.083131473632804],
            [-60.00436791699218, 5.084131473632802],
            [-60.008067916992189, 5.086131473632804],
            [-60.011067916992189, 5.0877314736328],
            [-60.013567916992187, 5.089331473632804],
            [-60.017867916992188, 5.090531473632801],
            [-60.021467916992187, 5.092931473632802],
            [-60.025367916992181, 5.096531473632801],
            [-60.028367916992181, 5.100231473632803],
            [-60.030867916992186, 5.102731473632801],
            [-60.033367916992184, 5.105131473632802],
            [-60.03676791699219, 5.108231473632806],
            [-60.041767916992185, 5.106731473632806],
            [-60.045667916992187, 5.107931473632803],
            [-60.044267916992183, 5.110931473632803],
            [-60.048267916992188, 5.113431473632801],
            [-60.051667916992187, 5.118131473632801],
            [-60.052267916992186, 5.122631473632801],
            [-60.056067916992184, 5.123931473632801],
            [-60.057967916992183, 5.126131473632803],
            [-60.05946791699219, 5.130331473632801],
            [-60.061567916992189, 5.133931473632806],
            [-60.065267916992184, 5.137731473632805],
            [-60.07156791699218, 5.137731473632805],
            [-60.077067916992185, 5.138131473632804],
            [-60.080967916992186, 5.139331473632801],
            [-60.083767916992187, 5.141331473632803],
            [-60.090967916992184, 5.141331473632803],
            [-60.094867916992186, 5.140531473632805],
            [-60.096367916992186, 5.143931473632804],
            [-60.098467916992185, 5.146131473632806],
            [-60.098367916992188, 5.149331473632806],
            [-60.099467916992182, 5.153531473632803],
            [-60.101367916992189, 5.1609314736328],
            [-60.102467916992182, 5.171131473632805],
            [-60.103867916992186, 5.175331473632802],
            [-60.104267916992185, 5.180431473632801],
            [-60.106767916992183, 5.194131473632801],
            [-60.10996791699219, 5.198931473632804],
            [-60.116067916992186, 5.203931473632807],
            [-60.120167916992187, 5.207531473632805],
            [-60.124367916992185, 5.2127314736328],
            [-60.125767916992189, 5.217131473632804],
            [-60.127367916992185, 5.219531473632806],
            [-60.130667916992181, 5.222131473632807],
            [-60.133067916992189, 5.225931473632805],
            [-60.13406791699218, 5.229931473632803],
            [-60.134167916992183, 5.234931473632805],
            [-60.135467916992184, 5.243531473632807],
            [-60.13566791699219, 5.248731473632802],
            [-60.138867916992183, 5.248331473632803],
            [-60.147667916992184, 5.241131473632805],
            [-60.150667916992184, 5.238931473632803],
            [-60.15406791699219, 5.235731473632804],
            [-60.156267916992185, 5.233131473632803],
            [-60.159467916992185, 5.231331473632807],
            [-60.162767916992181, 5.230331473632802],
            [-60.165667916992184, 5.230331473632802],
            [-60.171667916992185, 5.226731473632803],
            [-60.177967916992188, 5.229631473632807],
            [-60.182167916992185, 5.230731473632801],
            [-60.187667916992183, 5.232731473632803],
            [-60.194167916992185, 5.237331473632807],
            [-60.195567916992189, 5.241131473632805],
            [-60.192767916992182, 5.251331473632803],
            [-60.19686791699219, 5.257031473632807],
            [-60.198267916992187, 5.260531473632803],
            [-60.201267916992187, 5.265531473632805],
            [-60.205167916992181, 5.267931473632807],
            [-60.211467916992184, 5.271131473632806],
            [-60.218167916992186, 5.270031473632805],
            [-60.22096791699218, 5.268931473632804],
            [-60.228067916992188, 5.263731473632802],
            [-60.233867916992182, 5.263131473632804],
            [-60.246867916992187, 5.251731473632802],
            [-60.24966791699218, 5.251131473632803],
            [-60.253467916992186, 5.251731473632802],
            [-60.257167916992188, 5.251931473632801],
            [-60.26406791699219, 5.251131473632803],
            [-60.26706791699219, 5.247931473632804],
            [-60.269867916992183, 5.243531473632807],
            [-60.270467916992189, 5.239531473632802],
            [-60.270967916992184, 5.234331473632807],
            [-60.277967916992182, 5.233931473632801],
            [-60.282367916992186, 5.229531473632804],
            [-60.285667916992182, 5.229131473632805],
            [-60.289867916992186, 5.228231473632803],
            [-60.295267916992188, 5.229531473632804],
            [-60.298967916992183, 5.228231473632803],
            [-60.301867916992187, 5.227731473632801],
            [-60.305267916992186, 5.225931473632805],
            [-60.309367916992187, 5.219131473632807],
            [-60.311067916992187, 5.213531473632806],
            [-60.313467916992181, 5.210531473632805],
            [-60.319167916992185, 5.208331473632803],
            [-60.323467916992186, 5.207131473632806],
            [-60.327667916992183, 5.205731473632802],
            [-60.333167916992181, 5.207531473632805],
            [-60.33656791699218, 5.207931473632804],
            [-60.344867916992186, 5.204931473632804],
            [-60.349467916992182, 5.205331473632803],
            [-60.352767916992185, 5.208531473632803],
            [-60.356067916992188, 5.2097314736328],
            [-60.35966791699218, 5.209131473632802],
            [-60.361467916992183, 5.206331473632801],
            [-60.363867916992184, 5.204731473632805],
            [-60.367967916992185, 5.204731473632805],
            [-60.371067916992189, 5.207531473632805],
            [-60.370867916992182, 5.212931473632807],
            [-60.37336791699218, 5.216131473632807],
            [-60.378267916992186, 5.217531473632803],
            [-60.380967916992184, 5.220731473632803],
            [-60.384567916992182, 5.221531473632801],
            [-60.386767916992184, 5.219731473632805],
            [-60.390167916992183, 5.218731473632801],
            [-60.394067916992185, 5.216931473632805],
            [-60.398467916992189, 5.215531473632801],
            [-60.401267916992182, 5.213931473632805],
            [-60.405867916992186, 5.211331473632804],
            [-60.410967916992185, 5.213931473632805],
            [-60.413867916992189, 5.213031473632803],
            [-60.418067916992186, 5.205731473632802],
            [-60.418567916992188, 5.194531473632807],
            [-60.427567916992189, 5.188931473632806],
            [-60.430467916992185, 5.184331473632803],
            [-60.433667916992185, 5.181531473632802],
            [-60.445267916992186, 5.183431473632801],
            [-60.44986791699219, 5.188931473632806],
            [-60.44956791699218, 5.197131473632801],
            [-60.449967916992186, 5.200931473632807],
            [-60.452667916992183, 5.203131473632801],
            [-60.455967916992186, 5.203131473632801],
            [-60.462567916992185, 5.199531473632803],
            [-60.465667916992189, 5.200031473632805],
            [-60.467967916992187, 5.202531473632803],
            [-60.47126791699219, 5.202531473632803],
            [-60.474567916992186, 5.200731473632807],
            [-60.478067916992188, 5.202131473632804],
            [-60.481067916992188, 5.202531473632803],
            [-60.485767916992188, 5.202731473632802],
            [-60.489067916992184, 5.202131473632804],
            [-60.494767916992188, 5.202831473632806],
            [-60.500467916992186, 5.202731473632802],
            [-60.50436791699218, 5.204531473632805],
            [-60.509667916992186, 5.206331473632801],
            [-60.515167916992183, 5.207131473632806],
            [-60.519067916992185, 5.207931473632804],
            [-60.523467916992189, 5.207131473632806],
            [-60.528367916992181, 5.207931473632804],
            [-60.533367916992184, 5.205531473632803],
            [-60.536667916992187, 5.2035314736328],
            [-60.540567916992188, 5.201731473632805],
            [-60.54276791699219, 5.197831473632803],
            [-60.546467916992185, 5.194531473632807],
            [-60.551167916992185, 5.194931473632806],
            [-60.55516791699219, 5.195331473632805],
            [-60.558767916992181, 5.195331473632805],
            [-60.564567916992189, 5.196331473632803],
            [-60.570267916992186, 5.195931473632804],
            [-60.577067916992185, 5.197831473632803],
            [-60.587367916992186, 5.205531473632803],
            [-60.590967916992184, 5.208331473632803],
            [-60.59126791699218, 5.2127314736328],
            [-60.592567916992181, 5.218031473632806],
            [-60.594867916992186, 5.219731473632805],
            [-60.598867916992184, 5.220131473632804],
            [-60.602567916992186, 5.215831473632804],
            [-60.608367916992187, 5.215231473632805],
            [-60.613667916992185, 5.218031473632806],
            [-60.617167916992187, 5.218031473632806],
            [-60.621267916992181, 5.216131473632807],
            [-60.625567916992182, 5.215231473632805],
            [-60.629167916992188, 5.216931473632805],
            [-60.634167916992183, 5.219531473632806],
            [-60.639267916992182, 5.220231473632801],
            [-60.644067916992185, 5.2249314736328],
            [-60.647367916992181, 5.228531473632806],
            [-60.651467916992189, 5.230531473632801],
            [-60.656267916992185, 5.227731473632801],
            [-60.659867916992184, 5.226931473632803],
            [-60.665267916992185, 5.227731473632801],
            [-60.67546791699219, 5.231831473632802],
            [-60.679967916992183, 5.232131473632805],
            [-60.687367916992187, 5.230731473632801],
            [-60.691267916992189, 5.229331473632804],
            [-60.697167916992186, 5.228931473632805],
            [-60.69986791699219, 5.227131473632802],
            [-60.702967916992186, 5.2249314736328],
            [-60.704867916992185, 5.222431473632803],
            [-60.708167916992181, 5.219331473632806],
            [-60.712967916992184, 5.217731473632803],
            [-60.716667916992186, 5.218331473632801],
            [-60.719867916992186, 5.219731473632805],
            [-60.723167916992182, 5.219931473632805],
            [-60.724967916992185, 5.216631473632802],
            [-60.727067916992183, 5.213931473632805],
            [-60.728867916992186, 5.210331473632806],
            [-60.732167916992182, 5.205731473632802],
            [-60.735167916992182, 5.202531473632803],
            [-60.732767916992188, 5.199731473632802],
            [-60.728067916992188, 5.201131473632806],
            [-60.725267916992181, 5.198531473632805],
            [-60.722067916992188, 5.196431473632806],
            [-60.718167916992186, 5.195631473632801],
            [-60.710667916992186, 5.197131473632801],
            [-60.705167916992181, 5.195331473632805],
            [-60.70156791699219, 5.194231473632804],
            [-60.698767916992182, 5.194931473632806],
            [-60.694667916992188, 5.197131473632801],
            [-60.691567916992184, 5.195531473632805],
            [-60.686667916992185, 5.194231473632804],
            [-60.683767916992181, 5.1913314736328],
            [-60.680767916992181, 5.188731473632807],
            [-60.68016791699219, 5.184131473632803],
            [-60.680467916992185, 5.179531473632807],
            [-60.678667916992183, 5.1761314736328],
            [-60.674367916992182, 5.175431473632806],
            [-60.668367916992182, 5.169331473632802],
            [-60.661167916992184, 5.163831473632804],
            [-60.659167916992189, 5.159531473632804],
            [-60.656967916992187, 5.156131473632804],
            [-60.656567916992188, 5.152131473632807],
            [-60.655867916992186, 5.149131473632806],
            [-60.655967916992182, 5.144931473632802],
            [-60.656567916992188, 5.140931473632804],
            [-60.657667916992182, 5.137531473632805],
            [-60.658367916992184, 5.133931473632806],
            [-60.657367916992186, 5.129131473632803],
            [-60.655867916992186, 5.126331473632803],
            [-60.655167916992184, 5.117531473632802],
            [-60.65406791699219, 5.114331473632802],
            [-60.651267916992182, 5.117131473632803],
            [-60.648767916992185, 5.119031473632802],
            [-60.645767916992185, 5.118331473632807],
            [-60.642567916992185, 5.117331473632802],
            [-60.640767916992189, 5.109631473632803],
            [-60.643267916992187, 5.102731473632801],
            [-60.644667916992184, 5.098731473632803],
            [-60.644367916992181, 5.095731473632803],
            [-60.642467916992189, 5.091131473632807],
            [-60.640767916992189, 5.081531473632801],
            [-60.642667916992181, 5.0785314736328],
            [-60.642567916992185, 5.074731473632802],
            [-60.641767916992187, 5.069531473632807],
            [-60.642867916992188, 5.0663314736328],
            [-60.644367916992181, 5.062931473632801],
            [-60.644567916992187, 5.057631473632803],
            [-60.642667916992181, 5.054731473632806],
            [-60.638267916992184, 5.051331473632807],
            [-60.632167916992188, 5.049331473632805],
            [-60.630167916992185, 5.045731473632806],
            [-60.628067916992187, 5.042931473632805],
            [-60.627667916992181, 5.036531473632806],
            [-60.625967916992181, 5.031131473632804],
            [-60.623067916992184, 5.026131473632802],
            [-60.619667916992185, 5.022531473632803],
            [-60.616867916992184, 5.019331473632803],
            [-60.612167916992185, 5.015931473632804],
            [-60.613567916992181, 5.009531473632805],
            [-60.612167916992185, 5.006531473632805],
            [-60.608967916992185, 5.004531473632802],
            [-60.603867916992186, 5.003431473632801],
            [-60.599567916992186, 4.999131473632801],
            [-60.598067916992186, 4.996231473632804],
            [-60.59626791699219, 4.992531473632802],
            [-60.59456791699219, 4.9871314736328],
            [-60.593467916992182, 4.982131473632805],
            [-60.593167916992186, 4.973831473632806],
            [-60.592667916992184, 4.970731473632803],
            [-60.590167916992186, 4.967131473632804],
            [-60.586267916992185, 4.959431473632804],
            [-60.584367916992186, 4.956731473632807],
            [-60.584367916992186, 4.952131473632804],
            [-60.588467916992187, 4.946331473632803],
            [-60.589567916992181, 4.942731473632804],
            [-60.589867916992183, 4.939731473632804],
            [-60.589867916992183, 4.931131473632803],
            [-60.591967916992189, 4.926831473632802],
            [-60.594867916992186, 4.926331473632807],
            [-60.598167916992182, 4.926331473632807],
            [-60.603867916992186, 4.925331473632802],
            [-60.608867916992182, 4.9231314736328],
            [-60.612467916992188, 4.919631473632805],
            [-60.616067916992186, 4.918131473632805],
            [-60.618867916992187, 4.913831473632804],
            [-60.621867916992187, 4.909531473632804],
            [-60.624067916992189, 4.907131473632802],
            [-60.625767916992189, 4.904131473632802],
            [-60.625567916992182, 4.896531473632805],
            [-60.628067916992187, 4.891931473632802],
            [-60.629467916992184, 4.888631473632806],
            [-60.632967916992186, 4.888131473632804],
            [-60.639567916992185, 4.886331473632801],
            [-60.644267916992185, 4.8850314736328],
            [-60.647667916992184, 4.884931473632804],
            [-60.652367916992183, 4.882831473632805],
            [-60.652967916992182, 4.878331473632805],
            [-60.651567916992185, 4.874731473632806],
            [-60.64906791699218, 4.872631473632801],
            [-60.64906791699218, 4.868131473632801],
            [-60.653167916992189, 4.866931473632803],
            [-60.656267916992185, 4.865331473632807],
            [-60.659867916992184, 4.856031473632804],
            [-60.659267916992185, 4.852331473632802],
            [-60.659867916992184, 4.848831473632806],
            [-60.663167916992187, 4.847731473632805],
            [-60.666067916992183, 4.844531473632806],
            [-60.668567916992188, 4.842731473632803],
            [-60.673067916992181, 4.839931473632802],
            [-60.674967916992188, 4.835531473632805],
            [-60.675767916992186, 4.832131473632806],
            [-60.680567916992189, 4.8300314736328],
            [-60.68586791699218, 4.826331473632806],
            [-60.687367916992187, 4.823331473632805],
            [-60.687367916992187, 4.819731473632807],
            [-60.69216791699219, 4.814231473632802],
            [-60.695967916992188, 4.810531473632807],
            [-60.69956791699218, 4.810531473632807],
            [-60.705167916992181, 4.809731473632802],
            [-60.712367916992186, 4.804731473632806],
            [-60.71656791699219, 4.801331473632807],
            [-60.71956791699219, 4.799731473632804],
            [-60.721967916992185, 4.794931473632801],
            [-60.724467916992182, 4.791731473632801],
            [-60.726667916992184, 4.788731473632801],
            [-60.724567916992186, 4.784531473632804],
            [-60.721967916992185, 4.781531473632803],
            [-60.721167916992187, 4.777931473632805],
            [-60.723467916992185, 4.775831473632806],
            [-60.725867916992186, 4.774131473632806],
            [-60.729167916992182, 4.772731473632803],
            [-60.733067916992184, 4.772531473632803],
            [-60.735867916992184, 4.771531473632805],
            [-60.738367916992189, 4.770031473632805],
            [-60.740567916992184, 4.766331473632803],
            [-60.742167916992187, 4.762531473632805],
            [-60.744467916992186, 4.760331473632803],
            [-60.747167916992183, 4.759131473632806],
            [-60.74966791699218, 4.756331473632805],
            [-60.751867916992182, 4.753931473632804],
            [-60.755267916992182, 4.753731473632804],
            [-60.758767916992184, 4.755531473632807],
            [-60.761867916992188, 4.757831473632805],
            [-60.765667916992186, 4.757731473632802],
            [-60.769067916992185, 4.755631473632803],
            [-60.772367916992181, 4.753431473632801],
            [-60.776267916992182, 4.752531473632807],
            [-60.780867916992186, 4.752931473632806],
            [-60.784867916992184, 4.753731473632804],
            [-60.789167916992184, 4.751931473632801],
            [-60.791167916992187, 4.749131473632801],
            [-60.794267916992183, 4.748431473632806],
            [-60.798567916992184, 4.750331473632805],
            [-60.803267916992183, 4.747931473632804],
            [-60.806567916992186, 4.747631473632801],
            [-60.808567916992189, 4.745531473632802],
            [-60.810167916992185, 4.742531473632802],
            [-60.811867916992185, 4.737631473632803],
            [-60.813267916992189, 4.733531473632802],
            [-60.814467916992186, 4.730131473632802],
            [-60.81686791699218, 4.727331473632802],
            [-60.820667916992186, 4.725531473632806],
            [-60.826067916992187, 4.725531473632806],
            [-60.830067916992185, 4.7279314736328],
            [-60.834567916992185, 4.726331473632804],
            [-60.834367916992186, 4.722331473632806],
            [-60.837267916992189, 4.720131473632804],
            [-60.837967916992184, 4.717131473632804],
            [-60.840167916992186, 4.713731473632805],
            [-60.843667916992189, 4.712231473632805],
            [-60.846467916992182, 4.711131473632804],
            [-60.848167916992182, 4.708131473632804],
            [-60.850067916992188, 4.704731473632805],
            [-60.852567916992186, 4.703131473632801],
            [-60.856067916992188, 4.703931473632807],
            [-60.858667916992189, 4.705731473632802],
            [-60.862467916992188, 4.706531473632801],
            [-60.866867916992184, 4.708131473632804],
            [-60.870767916992186, 4.709431473632804],
            [-60.873067916992184, 4.711931473632802],
            [-60.875767916992189, 4.715131473632802],
            [-60.878767916992189, 4.715531473632801],
            [-60.880967916992184, 4.713531473632806],
            [-60.883767916992184, 4.713031473632803],
            [-60.88706791699218, 4.7157314736328],
            [-60.891767916992187, 4.716931473632805],
            [-60.896167916992184, 4.717131473632804],
            [-60.899867916992186, 4.717531473632803],
            [-60.902867916992186, 4.714431473632807],
            [-60.904567916992185, 4.711131473632804],
            [-60.907367916992186, 4.708931473632802],
            [-60.909567916992188, 4.704331473632806],
            [-60.912267916992185, 4.699731473632802],
            [-60.912367916992181, 4.696131473632803],
            [-60.911267916992188, 4.691931473632806],
            [-60.910167916992187, 4.688431473632804],
            [-60.911967916992182, 4.685931473632806],
            [-60.912367916992181, 4.682331473632807],
            [-60.913067916992183, 4.678731473632801],
            [-60.91476791699219, 4.674531473632804],
            [-60.915967916992187, 4.670931473632805],
            [-60.91776791699219, 4.666831473632804],
            [-60.919767916992186, 4.663531473632801],
            [-60.922867916992189, 4.662331473632804],
            [-60.92716791699219, 4.662131473632805],
            [-60.93116791699218, 4.661031473632804],
            [-60.933267916992186, 4.658331473632806],
            [-60.935767916992184, 4.656131473632804],
            [-60.940567916992187, 4.655931473632805],
            [-60.944567916992185, 4.655731473632805],
            [-60.947967916992184, 4.654131473632802],
            [-60.948467916992186, 4.650331473632804],
            [-60.948967916992189, 4.6472314736328],
            [-60.950667916992188, 4.643931473632804],
            [-60.950367916992185, 4.640331473632806],
            [-60.94986791699219, 4.637331473632806],
            [-60.946767916992187, 4.636931473632806],
            [-60.943067916992185, 4.635331473632803],
            [-60.939367916992182, 4.633731473632807],
            [-60.938767916992184, 4.628731473632804],
            [-60.937267916992184, 4.624531473632807],
            [-60.935467916992181, 4.621931473632806],
            [-60.933867916992185, 4.619531473632804],
            [-60.933367916992182, 4.616231473632801],
            [-60.934867916992189, 4.612931473632806],
            [-60.936267916992186, 4.608231473632806],
            [-60.93716791699218, 4.604131473632805],
            [-60.937467916992183, 4.598731473632803],
            [-60.936067916992187, 4.594131473632807],
            [-60.934767916992186, 4.589131473632804],
            [-60.932967916992183, 4.585331473632806],
            [-60.936667916992185, 4.582731473632805],
            [-60.940167916992181, 4.5800314736328],
            [-60.943067916992185, 4.580731473632802],
            [-60.94686791699219, 4.5800314736328],
            [-60.950367916992185, 4.580731473632802],
            [-60.953167916992186, 4.582531473632805],
            [-60.956467916992182, 4.581131473632801],
            [-60.956867916992181, 4.577531473632803],
            [-60.955167916992181, 4.573531473632805],
            [-60.954867916992185, 4.569131473632801],
            [-60.95456791699219, 4.565331473632803],
            [-60.953267916992189, 4.561931473632804],
            [-60.953567916992185, 4.558731473632804],
            [-60.956267916992189, 4.557131473632801],
            [-60.957667916992186, 4.553531473632802],
            [-60.958267916992185, 4.549331473632805],
            [-60.957967916992189, 4.545731473632806],
            [-60.960867916992186, 4.544631473632805],
            [-60.963967916992189, 4.543731473632803],
            [-60.967567916992181, 4.543231473632801],
            [-60.97096791699218, 4.543131473632805],
            [-60.974467916992182, 4.542431473632803],
            [-60.978067916992188, 4.541731473632801],
            [-60.98166791699218, 4.542131473632807],
            [-60.98496791699219, 4.541831473632804],
            [-60.986167916992187, 4.537931473632803],
            [-60.987167916992185, 4.533031473632803],
            [-60.990367916992184, 4.5329314736328],
            [-60.993367916992185, 4.532431473632805],
            [-60.996367916992185, 4.528931473632802],
            [-60.99666791699218, 4.525831473632806],
            [-60.99366791699218, 4.522931473632802],
            [-60.994667916992185, 4.519331473632803],
            [-60.998067916992184, 4.516731473632802],
            [-61.00166791699219, 4.517731473632807],
            [-61.005767916992184, 4.518331473632806],
            [-61.01036791699218, 4.517931473632807],
            [-61.01406791699219, 4.515331473632806],
            [-61.017467916992189, 4.515931473632804],
            [-61.018967916992182, 4.518531473632805],
            [-61.022867916992183, 4.520031473632805],
            [-61.027267916992187, 4.520031473632805],
            [-61.031467916992185, 4.520031473632805],
            [-61.034567916992188, 4.521531473632805],
            [-61.037467916992185, 4.521531473632805],
            [-61.040667916992184, 4.522731473632803],
            [-61.044267916992183, 4.522931473632802],
            [-61.047867916992189, 4.523531473632801],
            [-61.051367916992184, 4.5237314736328],
            [-61.055867916992185, 4.523031473632805],
            [-61.06086791699218, 4.523531473632801],
            [-61.065167916992181, 4.522931473632802],
            [-61.068767916992186, 4.5207314736328],
            [-61.072367916992185, 4.518631473632801],
            [-61.075667916992188, 4.5175314736328],
            [-61.078767916992184, 4.518131473632806],
            [-61.082867916992186, 4.517931473632807],
            [-61.085667916992186, 4.518531473632805],
            [-61.08826791699218, 4.5207314736328],
            [-61.090967916992184, 4.523031473632805],
            [-61.09456791699219, 4.521331473632806],
            [-61.09626791699219, 4.5175314736328],
            [-61.100267916992181, 4.517231473632805],
            [-61.102767916992185, 4.515031473632803],
            [-61.105067916992184, 4.512531473632805],
            [-61.108567916992186, 4.510631473632806],
            [-61.112467916992188, 4.510331473632803],
            [-61.116367916992189, 4.508731473632807],
            [-61.119367916992189, 4.506331473632805],
            [-61.122367916992189, 4.505931473632806],
            [-61.125767916992189, 4.504531473632802],
            [-61.127167916992185, 4.500131473632806],
            [-61.130667916992181, 4.498331473632803],
            [-61.133467916992188, 4.496131473632801],
            [-61.134167916992183, 4.493331473632807],
            [-61.135267916992184, 4.4901314736328],
            [-61.13906791699219, 4.489031473632807],
            [-61.142367916992185, 4.486131473632803],
            [-61.144667916992184, 4.483231473632806],
            [-61.146467916992187, 4.481031473632804],
            [-61.149567916992183, 4.484931473632805],
            [-61.15076791699218, 4.490731473632806],
            [-61.151567916992185, 4.494831473632807],
            [-61.155167916992184, 4.495531473632802],
            [-61.159567916992188, 4.495931473632801],
            [-61.163367916992186, 4.497631473632801],
            [-61.165667916992184, 4.501931473632801],
            [-61.170067916992181, 4.502031473632805],
            [-61.172867916992189, 4.506131473632806],
            [-61.175067916992184, 4.508931473632806],
            [-61.17716791699219, 4.511931473632806],
            [-61.18116791699218, 4.513631473632806],
            [-61.183867916992185, 4.515331473632806],
            [-61.186567916992189, 4.516931473632802],
            [-61.188467916992181, 4.520031473632805],
            [-61.192667916992185, 4.520531473632801],
            [-61.196067916992185, 4.518631473632801],
            [-61.19956791699218, 4.516131473632804],
            [-61.203567916992185, 4.517931473632807],
            [-61.205767916992187, 4.520831473632803],
            [-61.208667916992184, 4.522731473632803],
            [-61.211167916992181, 4.525831473632806],
            [-61.214467916992184, 4.528031473632801],
            [-61.216767916992183, 4.531631473632807],
            [-61.21956791699219, 4.530531473632806],
            [-61.220867916992184, 4.526631473632804],
            [-61.221767916992185, 4.522931473632802],
            [-61.225367916992184, 4.524331473632806],
            [-61.228667916992187, 4.525731473632803],
            [-61.233067916992184, 4.527131473632807],
            [-61.238367916992189, 4.528331473632804],
            [-61.242567916992186, 4.531931473632802],
            [-61.246567916992184, 4.533031473632803],
            [-61.248767916992186, 4.530731473632805],
            [-61.25166791699219, 4.526331473632801],
            [-61.254467916992184, 4.529331473632801],
            [-61.255167916992185, 4.533331473632806],
            [-61.257767916992186, 4.536731473632805],
            [-61.26236791699219, 4.536531473632806],
            [-61.265767916992189, 4.5374314736328],
            [-61.268967916992182, 4.539331473632807],
            [-61.27006791699219, 4.535131473632802],
            [-61.271167916992184, 4.532331473632802],
            [-61.273467916992189, 4.5297314736328],
            [-61.276567916992185, 4.528531473632803],
            [-61.278667916992184, 4.531131473632804],
            [-61.281167916992189, 4.534531473632804],
            [-61.285567916992186, 4.537131473632805],
            [-61.291367916992186, 4.535531473632801],
            [-61.295867916992187, 4.537331473632804],
            [-61.298967916992183, 4.536031473632804],
            [-61.302567916992189, 4.5374314736328],
            [-61.305267916992186, 4.540331473632804],
            [-61.306667916992183, 4.5374314736328],
            [-61.309667916992183, 4.536031473632804],
            [-61.313067916992182, 4.535531473632801],
            [-61.316267916992189, 4.534331473632804],
            [-61.322167916992186, 4.534931473632803],
            [-61.324967916992186, 4.533531473632806],
            [-61.325267916992189, 4.529331473632801],
            [-61.327367916992188, 4.525731473632803],
            [-61.32956791699219, 4.520531473632801],
            [-61.328967916992184, 4.5175314736328],
            [-61.327167916992181, 4.5145314736328],
            [-61.32486791699219, 4.512531473632805],
            [-61.322367916992185, 4.509231473632802],
            [-61.322467916992181, 4.504131473632803],
            [-61.319267916992189, 4.502731473632807],
            [-61.313467916992181, 4.504231473632807],
            [-61.310467916992181, 4.5023314736328],
            [-61.308367916992182, 4.499531473632807],
            [-61.30616791699218, 4.496131473632801],
            [-61.302467916992185, 4.493731473632806],
            [-61.296467916992185, 4.492631473632805],
            [-61.295667916992187, 4.487731473632806],
            [-61.296067916992186, 4.481731473632806],
            [-61.293867916992184, 4.479931473632803],
            [-61.290567916992188, 4.478131473632807],
            [-61.285967916992185, 4.478731473632806],
            [-61.280167916992184, 4.479131473632805],
            [-61.279467916992189, 4.4749314736328],
            [-61.279467916992189, 4.470231473632801],
            [-61.281267916992185, 4.467131473632804],
            [-61.287067916992186, 4.463731473632805],
            [-61.289167916992184, 4.457731473632805],
            [-61.294767916992186, 4.457931473632804],
            [-61.298567916992184, 4.458531473632803],
            [-61.302467916992185, 4.456531473632801],
            [-61.305467916992185, 4.459131473632802],
            [-61.309767916992186, 4.458131473632804],
            [-61.313467916992181, 4.456531473632801],
            [-61.314367916992182, 4.451131473632806],
            [-61.31686791699218, 4.449731473632802],
            [-61.32016791699219, 4.446331473632803],
            [-61.323467916992186, 4.445631473632801],
            [-61.32486791699219, 4.441531473632807],
            [-61.322067916992182, 4.437731473632802],
            [-61.321267916992184, 4.434331473632803],
            [-61.32156791699218, 4.430331473632805],
            [-61.326067916992187, 4.429831473632802],
            [-61.33226791699218, 4.428331473632802],
            [-61.337567916992185, 4.4276314736328],
            [-61.338467916992187, 4.424731473632804],
            [-61.338467916992187, 4.420731473632806],
            [-61.34156791699219, 4.419631473632805],
            [-61.344867916992186, 4.419131473632802],
            [-61.349467916992182, 4.419131473632802],
            [-61.352567916992186, 4.419331473632802],
            [-61.356367916992184, 4.420331473632807],
            [-61.362467916992188, 4.422531473632802],
            [-61.366867916992184, 4.424731473632804],
            [-61.37196791699219, 4.426731473632806],
            [-61.376867916992182, 4.425731473632801],
            [-61.380167916992185, 4.426731473632806],
            [-61.383167916992186, 4.426331473632807],
            [-61.387467916992186, 4.425731473632801],
            [-61.391867916992183, 4.424331473632805],
            [-61.40076791699218, 4.425731473632801],
            [-61.405167916992184, 4.425431473632806],
            [-61.407767916992185, 4.429531473632807],
            [-61.414167916992184, 4.432131473632801],
            [-61.418067916992186, 4.431731473632802],
            [-61.423367916992184, 4.433131473632805],
            [-61.426967916992183, 4.430931473632803],
            [-61.427867916992184, 4.426331473632807],
            [-61.43116791699218, 4.426331473632807],
            [-61.436067916992187, 4.424931473632803],
            [-61.440167916992181, 4.424331473632805],
            [-61.441567916992184, 4.427131473632805],
            [-61.437967916992186, 4.429831473632802],
            [-61.436567916992189, 4.433331473632805],
            [-61.439867916992185, 4.436131473632805],
            [-61.442967916992188, 4.435931473632806],
            [-61.445967916992188, 4.4353314736328],
            [-61.449267916992184, 4.438731473632807],
            [-61.453567916992185, 4.437731473632802],
            [-61.456867916992181, 4.435731473632806],
            [-61.461967916992187, 4.435531473632807],
            [-61.462667916992189, 4.432131473632801],
            [-61.462567916992185, 4.428331473632802],
            [-61.466667916992186, 4.424331473632805],
            [-61.471667916992189, 4.424531473632804],
            [-61.474967916992185, 4.421331473632804],
            [-61.477767916992185, 4.417931473632805],
            [-61.48166791699218, 4.415331473632804],
            [-61.487567916992184, 4.410531473632801],
            [-61.495167916992187, 4.410231473632805],
            [-61.499367916992185, 4.407731473632801],
            [-61.503067916992187, 4.408331473632806],
            [-61.506867916992185, 4.408331473632806],
            [-61.510467916992184, 4.407131473632802],
            [-61.513467916992184, 4.405531473632806],
            [-61.513767916992187, 4.402531473632806],
            [-61.517167916992186, 4.3987314736328],
            [-61.516667916992184, 4.393731473632805],
            [-61.515767916992189, 4.389731473632807],
            [-61.520467916992189, 4.385331473632803],
            [-61.520167916992186, 4.381131473632806],
            [-61.519567916992187, 4.377531473632807],
            [-61.521867916992186, 4.372631473632801],
            [-61.520667916992181, 4.367531473632802],
            [-61.520167916992186, 4.363931473632803],
            [-61.520767916992185, 4.360731473632804],
            [-61.519267916992185, 4.355331473632802],
            [-61.517167916992186, 4.349531473632801],
            [-61.521167916992184, 4.3469314736328],
            [-61.525067916992185, 4.3469314736328],
            [-61.526767916992185, 4.342931473632802],
            [-61.525367916992181, 4.337931473632807],
            [-61.522367916992181, 4.335831473632801],
            [-61.520967916992184, 4.332731473632805],
            [-61.521167916992184, 4.326431473632802],
            [-61.516567916992187, 4.324131473632804],
            [-61.513467916992184, 4.323531473632805],
            [-61.508167916992186, 4.320631473632801],
            [-61.510167916992188, 4.316131473632801],
            [-61.512167916992183, 4.312931473632801],
            [-61.512967916992181, 4.309331473632803],
            [-61.515967916992182, 4.307631473632803],
            [-61.51706791699219, 4.304731473632806],
            [-61.520167916992186, 4.299931473632803],
            [-61.52436791699219, 4.297731473632801],
            [-61.526167916992186, 4.294531473632802],
            [-61.524567916992183, 4.291731473632801],
            [-61.52406791699218, 4.288331473632802],
            [-61.531067916992185, 4.285431473632805],
            [-61.53846791699219, 4.276331473632801],
            [-61.541767916992185, 4.272731473632803],
            [-61.544967916992185, 4.270331473632801],
            [-61.548567916992184, 4.267931473632807],
            [-61.552567916992189, 4.266131473632804],
            [-61.554667916992187, 4.262331473632806],
            [-61.555467916992185, 4.257031473632807],
            [-61.558267916992186, 4.253431473632801],
            [-61.562667916992183, 4.251131473632803],
            [-61.567667916992185, 4.251731473632802],
            [-61.572167916992186, 4.250631473632801],
            [-61.575167916992186, 4.249131473632801],
            [-61.578567916992185, 4.246731473632806],
            [-61.583967916992187, 4.246531473632807],
            [-61.587667916992189, 4.244031473632802],
            [-61.59156791699219, 4.244531473632804],
            [-61.595167916992182, 4.246931473632806],
            [-61.599167916992187, 4.248331473632803],
            [-61.601367916992189, 4.250531473632805],
            [-61.604667916992184, 4.258331473632801],
            [-61.60826791699219, 4.261131473632801],
            [-61.614967916992185, 4.257831473632805],
            [-61.616567916992182, 4.255331473632801],
            [-61.623267916992184, 4.250931473632804],
            [-61.627067916992189, 4.253331473632805],
            [-61.630167916992185, 4.244831473632807],
            [-61.631867916992185, 4.241131473632805],
            [-61.635967916992186, 4.238231473632801],
            [-61.638767916992187, 4.237531473632806],
            [-61.642367916992185, 4.238131473632805],
            [-61.644567916992187, 4.241231473632801],
            [-61.645467916992189, 4.244731473632804],
            [-61.644667916992184, 4.248331473632803],
            [-61.646267916992187, 4.251931473632801],
            [-61.648767916992185, 4.253331473632805],
            [-61.652967916992182, 4.253931473632804],
            [-61.656267916992185, 4.256331473632805],
            [-61.65706791699219, 4.260631473632806],
            [-61.665267916992185, 4.262531473632805],
            [-61.67076791699219, 4.262531473632805],
            [-61.673567916992184, 4.261331473632801],
            [-61.676167916992185, 4.257831473632805],
            [-61.680867916992185, 4.254231473632807],
            [-61.684867916992189, 4.256331473632805],
            [-61.688367916992185, 4.255631473632803],
            [-61.693267916992184, 4.253931473632804],
            [-61.697367916992185, 4.254931473632801],
            [-61.703467916992182, 4.254731473632802],
            [-61.706567916992185, 4.252731473632807],
            [-61.710967916992189, 4.250331473632805],
            [-61.715467916992182, 4.247931473632804],
            [-61.720567916992181, 4.244831473632807],
            [-61.724767916992185, 4.244831473632807],
            [-61.730567916992186, 4.250131473632806],
            [-61.73026791699219, 4.254531473632802],
            [-61.725567916992183, 4.257531473632802],
            [-61.723167916992182, 4.260331473632803],
            [-61.723367916992188, 4.268531473632805],
            [-61.72696791699218, 4.267931473632807],
            [-61.732467916992185, 4.264731473632807],
            [-61.736167916992187, 4.262531473632805],
            [-61.736367916992187, 4.259231473632802],
            [-61.738267916992186, 4.256531473632805],
            [-61.742467916992183, 4.255531473632807],
            [-61.749167916992185, 4.255531473632807],
            [-61.75466791699219, 4.253431473632801],
            [-61.758067916992189, 4.2523314736328],
            [-61.761567916992185, 4.250631473632801],
            [-61.765167916992183, 4.250531473632805],
            [-61.769067916992185, 4.248331473632803],
            [-61.772267916992185, 4.248931473632801],
            [-61.775367916992181, 4.247931473632804],
            [-61.772867916992183, 4.244331473632805],
            [-61.771567916992183, 4.241731473632804],
            [-61.769867916992183, 4.239331473632802],
            [-61.766767916992187, 4.236531473632802],
            [-61.763767916992187, 4.234931473632805],
            [-61.765167916992183, 4.231531473632806],
            [-61.769267916992185, 4.228731473632806],
            [-61.77406791699218, 4.227331473632802],
            [-61.777967916992182, 4.225731473632806],
            [-61.782667916992182, 4.224131473632802],
            [-61.785967916992185, 4.224631473632805],
            [-61.786367916992184, 4.227731473632801],
            [-61.787367916992181, 4.230731473632801],
            [-61.790567916992188, 4.231031473632804],
            [-61.796467916992185, 4.229131473632805],
            [-61.802467916992185, 4.227331473632802],
            [-61.801067916992181, 4.222731473632805],
            [-61.801867916992187, 4.218331473632801],
            [-61.806567916992186, 4.216331473632806],
            [-61.807567916992184, 4.212931473632807],
            [-61.807167916992185, 4.2097314736328],
            [-61.808367916992182, 4.2035314736328],
            [-61.814867916992185, 4.199931473632802],
            [-61.819667916992188, 4.199731473632802],
            [-61.819067916992182, 4.194931473632806],
            [-61.817367916992183, 4.192531473632805],
            [-61.815767916992186, 4.188431473632804],
            [-61.81686791699218, 4.185531473632807],
            [-61.816667916992188, 4.181531473632802],
            [-61.818267916992184, 4.175731473632801],
            [-61.818467916992184, 4.169131473632802],
            [-61.823467916992186, 4.164131473632807],
            [-61.832167916992184, 4.161331473632806],
            [-61.83686791699219, 4.160231473632805],
            [-61.840867916992181, 4.162731473632803],
            [-61.845567916992181, 4.164131473632807],
            [-61.84896791699218, 4.167931473632805],
            [-61.849967916992185, 4.171731473632803],
            [-61.852067916992183, 4.174031473632802],
            [-61.85826791699219, 4.171731473632803],
            [-61.861467916992183, 4.171131473632805],
            [-61.870467916992183, 4.172531473632802],
            [-61.874867916992187, 4.170331473632807],
            [-61.874067916992189, 4.165731473632803],
            [-61.872667916992185, 4.161931473632805],
            [-61.870467916992183, 4.157431473632805],
            [-61.872967916992181, 4.152531473632806],
            [-61.877667916992181, 4.154131473632802],
            [-61.881667916992185, 4.154931473632807],
            [-61.886567916992185, 4.153531473632803],
            [-61.890667916992186, 4.155531473632806],
            [-61.895467916992189, 4.158531473632806],
            [-61.898967916992184, 4.160531473632801],
            [-61.903367916992181, 4.159531473632804],
            [-61.907267916992183, 4.157331473632802],
            [-61.912067916992186, 4.157731473632801],
            [-61.915667916992184, 4.156331473632804],
            [-61.919267916992183, 4.156331473632804],
            [-61.923367916992184, 4.154931473632807],
            [-61.921467916992185, 4.151331473632801],
            [-61.921467916992185, 4.147931473632802],
            [-61.923867916992187, 4.145831473632803],
            [-61.924367916992182, 4.142131473632801],
            [-61.924367916992182, 4.136431473632804],
            [-61.925067916992184, 4.131731473632804],
            [-61.925767916992186, 4.127031473632805],
            [-61.926867916992187, 4.1243314736328],
            [-61.926867916992187, 4.120531473632802],
            [-61.930467916992185, 4.116231473632801],
            [-61.931067916992184, 4.111831473632805],
            [-61.928567916992186, 4.108231473632806],
            [-61.930767916992181, 4.103231473632803],
            [-61.934667916992183, 4.104531473632804],
            [-61.939067916992187, 4.107131473632805],
            [-61.942667916992185, 4.1091314736328],
            [-61.945967916992188, 4.111831473632805],
            [-61.947967916992184, 4.116231473632801],
            [-61.94816791699219, 4.120531473632802],
            [-61.950767916992184, 4.125531473632805],
            [-61.954867916992185, 4.128431473632801],
            [-61.959067916992183, 4.134731473632804],
            [-61.960167916992184, 4.137731473632805],
            [-61.963467916992187, 4.141731473632802],
            [-61.971167916992187, 4.147731473632803],
            [-61.975267916992181, 4.151131473632802],
            [-61.97696791699218, 4.154431473632805],
            [-61.978067916992188, 4.158731473632805],
            [-61.981067916992188, 4.161931473632805],
            [-61.980367916992186, 4.166331473632802],
            [-61.982867916992184, 4.171731473632803],
            [-61.981767916992183, 4.176331473632807],
            [-61.981367916992184, 4.179831473632802],
            [-61.987167916992185, 4.178531473632802],
            [-61.990767916992183, 4.176331473632807],
            [-61.993267916992181, 4.174931473632803],
            [-61.998567916992187, 4.168131473632805],
            [-61.999867916992187, 4.164631473632802],
            [-62.004167916992188, 4.164931473632805],
            [-62.007167916992188, 4.1624314736328],
            [-62.008467916992188, 4.158531473632806],
            [-62.011567916992185, 4.156131473632804],
            [-62.014667916992181, 4.155531473632806],
            [-62.018767916992189, 4.155231473632803],
            [-62.021867916992186, 4.157131473632802],
            [-62.023767916992185, 4.154931473632807],
            [-62.026267916992182, 4.151131473632802],
            [-62.02906791699219, 4.150131473632804],
            [-62.031967916992187, 4.151931473632807],
            [-62.033367916992184, 4.1547314736328],
            [-62.03376791699219, 4.1579314736328],
            [-62.038167916992187, 4.156331473632804],
            [-62.041767916992185, 4.156131473632804],
            [-62.04576791699219, 4.153331473632804],
            [-62.048267916992188, 4.150331473632804],
            [-62.051067916992181, 4.150831473632806],
            [-62.055067916992186, 4.152531473632806],
            [-62.062667916992183, 4.157431473632805],
            [-62.066267916992189, 4.157431473632805],
            [-62.06986791699218, 4.156331473632804],
            [-62.076867916992185, 4.153331473632804],
            [-62.078167916992186, 4.1487314736328],
            [-62.080667916992184, 4.143631473632801],
            [-62.078167916992186, 4.137531473632805],
            [-62.075967916992184, 4.134131473632806],
            [-62.073267916992187, 4.132531473632802],
            [-62.070967916992188, 4.129531473632802],
            [-62.072367916992185, 4.124831473632803],
            [-62.075967916992184, 4.121931473632806],
            [-62.078467916992182, 4.119531473632804],
            [-62.08256791699219, 4.121231473632804],
            [-62.088467916992187, 4.124131473632801],
            [-62.090167916992186, 4.128331473632805],
            [-62.097067916992188, 4.127531473632807],
            [-62.099467916992182, 4.123731473632802],
            [-62.099467916992182, 4.119531473632804],
            [-62.103167916992184, 4.117631473632805],
            [-62.106167916992185, 4.1151314736328],
            [-62.110667916992185, 4.117131473632803],
            [-62.114067916992184, 4.116931473632803],
            [-62.118267916992181, 4.116731473632804],
            [-62.117167916992187, 4.111831473632805],
            [-62.116867916992184, 4.107731473632803],
            [-62.117267916992184, 4.104531473632804],
            [-62.113267916992186, 4.100931473632805],
            [-62.115367916992184, 4.097331473632806],
            [-62.113667916992185, 4.094731473632805],
            [-62.112267916992181, 4.091331473632806],
            [-62.116167916992183, 4.088331473632806],
            [-62.121267916992181, 4.088531473632806],
            [-62.126067916992184, 4.088331473632806],
            [-62.127967916992183, 4.085731473632805],
            [-62.133267916992182, 4.087131473632802],
            [-62.136367916992185, 4.085131473632806],
            [-62.140167916992183, 4.083331473632803],
            [-62.142667916992181, 4.081331473632801],
            [-62.143267916992187, 4.077731473632802],
            [-62.144667916992184, 4.074731473632802],
            [-62.147867916992183, 4.0785314736328],
            [-62.156567916992188, 4.083931473632802],
            [-62.159867916992184, 4.084931473632807],
            [-62.161667916992187, 4.087131473632802],
            [-62.161967916992182, 4.090531473632801],
            [-62.166067916992183, 4.091331473632806],
            [-62.171467916992185, 4.092131473632804],
            [-62.176667916992187, 4.090231473632805],
            [-62.179667916992187, 4.091131473632807],
            [-62.190967916992186, 4.096631473632804],
            [-62.19386791699219, 4.099331473632802],
            [-62.193767916992186, 4.106531473632806],
            [-62.194267916992189, 4.111331473632802],
            [-62.197967916992184, 4.112931473632806],
            [-62.205767916992187, 4.110931473632803],
            [-62.209867916992181, 4.111331473632802],
            [-62.214267916992185, 4.109531473632806],
            [-62.217667916992184, 4.111131473632803],
            [-62.220567916992181, 4.1121314736328],
            [-62.224467916992182, 4.111731473632801],
            [-62.228567916992183, 4.112631473632803],
            [-62.230567916992186, 4.116531473632804],
            [-62.23496791699219, 4.118331473632807],
            [-62.240067916992189, 4.117931473632801],
            [-62.244067916992186, 4.116931473632803],
            [-62.246867916992187, 4.118131473632801],
            [-62.24836791699218, 4.121931473632806],
            [-62.248767916992186, 4.126131473632803],
            [-62.252067916992189, 4.128731473632804],
            [-62.25736791699218, 4.127731473632807],
            [-62.260967916992186, 4.127931473632806],
            [-62.26506791699218, 4.129931473632801],
            [-62.270167916992186, 4.131131473632806],
            [-62.275467916992184, 4.129231473632807],
            [-62.27876791699218, 4.129531473632802],
            [-62.281967916992187, 4.131731473632804],
            [-62.284867916992184, 4.1335314736328],
            [-62.286967916992182, 4.136131473632801],
            [-62.288867916992189, 4.139731473632807],
            [-62.293167916992189, 4.141331473632803],
            [-62.296367916992182, 4.140531473632805],
            [-62.299367916992182, 4.138331473632803],
            [-62.303567916992186, 4.136431473632804],
            [-62.307167916992185, 4.137731473632805],
            [-62.315167916992181, 4.137131473632806],
            [-62.318767916992186, 4.140731473632805],
            [-62.321667916992183, 4.140531473632805],
            [-62.324367916992188, 4.139331473632801],
            [-62.326267916992187, 4.136431473632804],
            [-62.328467916992182, 4.134531473632805],
            [-62.332667916992186, 4.136931473632806],
            [-62.336267916992185, 4.138931473632802],
            [-62.338467916992187, 4.143931473632804],
            [-62.341967916992189, 4.146931473632804],
            [-62.34726791699218, 4.149331473632806],
            [-62.350667916992187, 4.151931473632807],
            [-62.352867916992182, 4.157331473632802],
            [-62.353667916992187, 4.164331473632807],
            [-62.357467916992185, 4.166331473632802],
            [-62.362567916992184, 4.166331473632802],
            [-62.365067916992189, 4.163131473632802],
            [-62.369067916992186, 4.162731473632803],
            [-62.374367916992185, 4.164931473632805],
            [-62.377167916992185, 4.166731473632801],
            [-62.383467916992188, 4.1731314736328],
            [-62.387167916992183, 4.174931473632803],
            [-62.38906791699219, 4.177531473632804],
            [-62.393967916992182, 4.173531473632806],
            [-62.398667916992181, 4.175431473632806],
            [-62.400967916992187, 4.178131473632803],
            [-62.406267916992185, 4.179831473632802],
            [-62.410267916992183, 4.180331473632805],
            [-62.414967916992182, 4.181531473632802],
            [-62.41776791699219, 4.184331473632803],
            [-62.42216791699218, 4.181731473632802],
            [-62.425067916992184, 4.182531473632807],
            [-62.429067916992182, 4.181931473632801],
            [-62.432667916992187, 4.182531473632807],
            [-62.435467916992181, 4.183131473632805],
            [-62.43886791699218, 4.182331473632807],
            [-62.445967916992188, 4.179331473632807],
            [-62.448767916992182, 4.174331473632805],
            [-62.453967916992184, 4.173931473632805],
            [-62.458167916992181, 4.176831473632802],
            [-62.462867916992181, 4.1776314736328],
            [-62.464867916992183, 4.173731473632806],
            [-62.46656791699219, 4.169931473632801],
            [-62.468367916992186, 4.166731473632801],
            [-62.470567916992181, 4.163531473632801],
            [-62.466967916992189, 4.1624314736328],
            [-62.462267916992189, 4.162731473632803],
            [-62.455667916992184, 4.156931473632802],
            [-62.459367916992186, 4.153331473632804],
            [-62.462867916992181, 4.150731473632803],
            [-62.463467916992187, 4.145831473632803],
            [-62.461167916992181, 4.142131473632801],
            [-62.464567916992181, 4.138931473632802],
            [-62.470167916992182, 4.139131473632801],
            [-62.47426791699219, 4.135931473632802],
            [-62.476367916992189, 4.132531473632802],
            [-62.480367916992186, 4.131131473632806],
            [-62.488567916992181, 4.134231473632802],
            [-62.490067916992189, 4.137831473632801],
            [-62.491667916992185, 4.140931473632804],
            [-62.495467916992183, 4.1425314736328],
            [-62.498767916992186, 4.1425314736328],
            [-62.502967916992183, 4.140531473632805],
            [-62.507167916992188, 4.137131473632806],
            [-62.507067916992185, 4.134131473632806],
            [-62.506267916992186, 4.131131473632806],
            [-62.507467916992184, 4.127531473632807],
            [-62.510967916992186, 4.1273314736328],
            [-62.51406791699219, 4.129731473632802],
            [-62.518267916992187, 4.130531473632807],
            [-62.522967916992187, 4.133131473632801],
            [-62.527667916992186, 4.134731473632804],
            [-62.530967916992182, 4.136331473632801],
            [-62.531967916992187, 4.1335314736328],
            [-62.528667916992184, 4.130631473632803],
            [-62.525467916992184, 4.128131473632806],
            [-62.522367916992181, 4.122331473632805],
            [-62.524767916992189, 4.116231473632801],
            [-62.531267916992185, 4.114031473632807],
            [-62.535167916992187, 4.113531473632804],
            [-62.541067916992183, 4.110931473632803],
            [-62.544567916992186, 4.109331473632807],
            [-62.549267916992186, 4.109331473632807],
            [-62.55216791699219, 4.108231473632806],
            [-62.550567916992186, 4.104531473632804],
            [-62.54716791699218, 4.101031473632801],
            [-62.544967916992185, 4.098831473632806],
            [-62.547767916992186, 4.096531473632801],
            [-62.551567916992184, 4.093331473632801],
            [-62.551667916992187, 4.090131473632802],
            [-62.55216791699219, 4.086131473632804],
            [-62.554067916992182, 4.082131473632806],
            [-62.557967916992183, 4.0800314736328],
            [-62.555767916992181, 4.078131473632801],
            [-62.550767916992186, 4.077531473632803],
            [-62.546867916992184, 4.077531473632803],
            [-62.54276791699219, 4.079331473632806],
            [-62.540367916992182, 4.075331473632801],
            [-62.542867916992186, 4.069931473632806],
            [-62.54416791699218, 4.066731473632807],
            [-62.543067916992186, 4.060931473632806],
            [-62.540667916992184, 4.057931473632806],
            [-62.536967916992182, 4.053531473632802],
            [-62.533967916992182, 4.050331473632802],
            [-62.533067916992181, 4.046831473632807],
            [-62.535667916992182, 4.044931473632801],
            [-62.538167916992187, 4.043231473632801],
            [-62.541367916992186, 4.043931473632803],
            [-62.548167916992185, 4.0451314736328],
            [-62.55216791699219, 4.042331473632807],
            [-62.555867916992185, 4.041331473632802],
            [-62.557767916992184, 4.038531473632801],
            [-62.561967916992188, 4.037331473632804],
            [-62.561967916992188, 4.033331473632806],
            [-62.559867916992189, 4.027931473632805],
            [-62.560267916992188, 4.024431473632802],
            [-62.557767916992184, 4.022131473632804],
            [-62.55616791699218, 4.017931473632807],
            [-62.559067916992184, 4.016131473632804],
            [-62.563467916992181, 4.016931473632802],
            [-62.568767916992186, 4.015331473632806],
            [-62.572067916992182, 4.014931473632807],
            [-62.575667916992188, 4.015731473632805],
            [-62.580167916992181, 4.017731473632807],
            [-62.581067916992183, 4.021531473632805],
            [-62.582167916992184, 4.025731473632803],
            [-62.586967916992187, 4.028031473632801],
            [-62.587967916992184, 4.031331473632804],
            [-62.59126791699218, 4.033531473632806],
            [-62.594867916992186, 4.034931473632803],
            [-62.599567916992186, 4.0359314736328],
            [-62.604667916992184, 4.038331473632802],
            [-62.607467916992185, 4.042431473632803],
            [-62.610367916992189, 4.044131473632802],
            [-62.61466791699219, 4.042131473632807],
            [-62.617467916992183, 4.041331473632802],
            [-62.620567916992186, 4.040331473632804],
            [-62.624467916992188, 4.040531473632804],
            [-62.62936791699218, 4.039631473632802],
            [-62.633567916992185, 4.041331473632802],
            [-62.638467916992184, 4.043931473632803],
            [-62.642467916992189, 4.044131473632802],
            [-62.647067916992185, 4.046131473632805],
            [-62.652567916992183, 4.043731473632803],
            [-62.655867916992186, 4.042331473632807],
            [-62.657667916992182, 4.039931473632805],
            [-62.657667916992182, 4.036331473632806],
            [-62.660867916992188, 4.034731473632803],
            [-62.665267916992185, 4.036331473632806],
            [-62.669267916992183, 4.036331473632806],
            [-62.674367916992182, 4.036731473632805],
            [-62.679367916992184, 4.038331473632802],
            [-62.682667916992187, 4.036331473632806],
            [-62.68586791699218, 4.034731473632803],
            [-62.689367916992182, 4.032431473632805],
            [-62.697367916992185, 4.032731473632801],
            [-62.702467916992184, 4.031531473632803],
            [-62.705467916992184, 4.033331473632806],
            [-62.710467916992187, 4.034531473632804],
            [-62.713767916992182, 4.034731473632803],
            [-62.717067916992185, 4.035731473632801],
            [-62.721667916992189, 4.035131473632802],
            [-62.729667916992184, 4.037731473632803],
            [-62.732767916992188, 4.039331473632807],
            [-62.736767916992186, 4.039331473632807],
            [-62.739967916992185, 4.037131473632805],
            [-62.743867916992187, 4.036731473632805],
            [-62.747667916992185, 4.035231473632805],
            [-62.752967916992183, 4.031631473632807],
            [-62.752767916992184, 4.026531473632801],
            [-62.749767916992184, 4.022131473632804],
            [-62.754167916992188, 4.019731473632802],
            [-62.756867916992185, 4.017931473632807],
            [-62.761267916992182, 4.015731473632805],
            [-62.766567916992187, 4.012531473632805],
            [-62.768167916992184, 4.009131473632806],
            [-62.768767916992189, 4.006331473632805],
            [-62.766767916992187, 4.003731473632804],
            [-62.766767916992187, 4.000531473632805],
            [-62.767467916992189, 3.997531473632804],
            [-62.765667916992186, 3.993731473632806],
            [-62.762667916992186, 3.989531473632802],
            [-62.762767916992189, 3.985331473632804],
            [-62.758467916992188, 3.981731473632806],
            [-62.749867916992187, 3.977431473632805],
            [-62.744067916992186, 3.975531473632806],
            [-62.745067916992184, 3.971331473632802],
            [-62.750167916992183, 3.966131473632807],
            [-62.752367916992185, 3.959931473632807],
            [-62.755167916992185, 3.954131473632806],
            [-62.757467916992184, 3.947831473632803],
            [-62.761267916992182, 3.942331473632805],
            [-62.76406791699219, 3.940331473632803],
            [-62.769867916992183, 3.937931473632801],
            [-62.772867916992183, 3.936731473632804],
            [-62.778367916992181, 3.934331473632803],
            [-62.782367916992186, 3.934331473632803],
            [-62.783967916992182, 3.931531473632802],
            [-62.785867916992188, 3.927531473632804],
            [-62.783067916992181, 3.924031473632802],
            [-62.783067916992181, 3.921131473632805],
            [-62.783867916992186, 3.916531473632801],
            [-62.786967916992182, 3.915131473632805],
            [-62.788167916992187, 3.907731473632801],
            [-62.788167916992187, 3.904431473632805],
            [-62.788167916992187, 3.898931473632807],
            [-62.78846791699219, 3.892931473632807],
            [-62.783667916992187, 3.888531473632803],
            [-62.780367916992184, 3.8835314736328],
            [-62.77576791699218, 3.880331473632801],
            [-62.776267916992182, 3.876131473632803],
            [-62.773967916992184, 3.873731473632802],
            [-62.772067916992185, 3.870931473632801],
            [-62.767467916992189, 3.866131473632805],
            [-62.757767916992186, 3.859631473632803],
            [-62.758167916992186, 3.853931473632805],
            [-62.75466791699219, 3.850531473632806],
            [-62.759267916992187, 3.846331473632802],
            [-62.76206791699218, 3.843731473632801],
            [-62.765767916992189, 3.842931473632802],
            [-62.771867916992186, 3.839131473632804],
            [-62.771767916992189, 3.835131473632806],
            [-62.769567916992187, 3.832531473632805],
            [-62.764567916992185, 3.829131473632806],
            [-62.761267916992182, 3.825931473632807],
            [-62.75906791699218, 3.823331473632805],
            [-62.75636791699219, 3.822131473632801],
            [-62.752967916992183, 3.819931473632806],
            [-62.750567916992182, 3.818131473632803],
            [-62.748267916992184, 3.812931473632801],
            [-62.744167916992183, 3.809131473632803],
            [-62.737567916992184, 3.806231473632806],
            [-62.732267916992186, 3.805331473632805],
            [-62.729167916992182, 3.804731473632806],
            [-62.729567916992181, 3.801831473632802],
            [-62.731167916992185, 3.797331473632802],
            [-62.733967916992185, 3.794131473632802],
            [-62.738667916992185, 3.791331473632802],
            [-62.742267916992184, 3.789531473632806],
            [-62.743367916992185, 3.786031473632804],
            [-62.741167916992183, 3.784131473632804],
            [-62.736067916992184, 3.780131473632807],
            [-62.735367916992189, 3.776131473632802],
            [-62.738967916992181, 3.769931473632802],
            [-62.739967916992185, 3.766431473632807],
            [-62.739967916992185, 3.762831473632801],
            [-62.73966791699219, 3.759131473632806],
            [-62.739767916992186, 3.755531473632807],
            [-62.738367916992189, 3.751131473632803],
            [-62.738267916992186, 3.747531473632804],
            [-62.737467916992188, 3.743931473632806],
            [-62.735367916992189, 3.740431473632803],
            [-62.734167916992185, 3.736331473632802],
            [-62.73496791699219, 3.729131473632805],
            [-62.734767916992183, 3.718531473632801],
            [-62.73326791699219, 3.714331473632804],
            [-62.731167916992185, 3.708331473632803],
            [-62.732867916992184, 3.702731473632802],
            [-62.736367916992187, 3.699731473632802],
            [-62.736767916992186, 3.695931473632804],
            [-62.736067916992184, 3.691931473632806],
            [-62.736867916992182, 3.689131473632806],
            [-62.739067916992184, 3.686231473632802],
            [-62.743267916992181, 3.684531473632802],
            [-62.744367916992189, 3.681731473632802],
            [-62.74366791699218, 3.673931473632805],
            [-62.747767916992188, 3.672931473632801],
            [-62.752667916992181, 3.675331473632802],
            [-62.756267916992186, 3.677531473632804],
            [-62.758467916992188, 3.680331473632805],
            [-62.762967916992181, 3.683931473632803],
            [-62.768167916992184, 3.686231473632802],
            [-62.771167916992184, 3.689531473632805],
            [-62.773667916992181, 3.694231473632804],
            [-62.775967916992187, 3.697131473632801],
            [-62.779567916992185, 3.698531473632805],
            [-62.783867916992186, 3.699531473632803],
            [-62.785867916992188, 3.702131473632804],
            [-62.788167916992187, 3.714131473632804],
            [-62.791967916992185, 3.718831473632804],
            [-62.796767916992181, 3.720131473632804],
            [-62.800767916992186, 3.723331473632804],
            [-62.80216791699219, 3.726031473632801],
            [-62.803967916992185, 3.729331473632804],
            [-62.808067916992186, 3.731831473632802],
            [-62.813367916992185, 3.732731473632803],
            [-62.818167916992181, 3.731731473632806],
            [-62.822967916992184, 3.732731473632803],
            [-62.826067916992187, 3.734931473632805],
            [-62.831767916992185, 3.738231473632801],
            [-62.836267916992185, 3.738231473632801],
            [-62.83826791699218, 3.736131473632803],
            [-62.840167916992186, 3.730531473632801],
            [-62.842367916992188, 3.726931473632803],
            [-62.844867916992186, 3.723831473632806],
            [-62.848467916992185, 3.721331473632802],
            [-62.850667916992187, 3.715131473632802],
            [-62.853867916992186, 3.711131473632804],
            [-62.859767916992183, 3.707131473632806],
            [-62.86466791699219, 3.704131473632806],
            [-62.867167916992187, 3.702731473632802],
            [-62.869767916992188, 3.7005314736328],
            [-62.873267916992184, 3.698131473632806],
            [-62.879067916992184, 3.692031473632802],
            [-62.883767916992184, 3.686731473632804],
            [-62.886867916992188, 3.683431473632801],
            [-62.889867916992188, 3.681731473632802],
            [-62.892967916992184, 3.679331473632807],
            [-62.896267916992187, 3.677531473632804],
            [-62.900467916992184, 3.675931473632801],
            [-62.903667916992184, 3.673931473632805],
            [-62.90706791699219, 3.671831473632807],
            [-62.912367916992181, 3.671831473632807],
            [-62.91746791699218, 3.673931473632805],
            [-62.91946791699219, 3.671731473632803],
            [-62.921467916992185, 3.665531473632804],
            [-62.921467916992185, 3.655231473632803],
            [-62.92246791699219, 3.652131473632807],
            [-62.924667916992185, 3.648931473632807],
            [-62.92716791699219, 3.645531473632801],
            [-62.928867916992189, 3.640031473632803],
            [-62.930467916992185, 3.637331473632806],
            [-62.932967916992183, 3.635331473632803],
            [-62.93446791699219, 3.631731473632804],
            [-62.93586791699218, 3.628731473632804],
            [-62.939067916992187, 3.626131473632803],
            [-62.942367916992183, 3.623931473632801],
            [-62.945767916992182, 3.619731473632804],
            [-62.947167916992186, 3.616231473632801],
            [-62.94956791699218, 3.614031473632807],
            [-62.953267916992189, 3.611531473632802],
            [-62.95726791699218, 3.609631473632803],
            [-62.960667916992186, 3.607731473632803],
            [-62.963767916992182, 3.609631473632803],
            [-62.966467916992187, 3.610931473632803],
            [-62.970067916992186, 3.610131473632805],
            [-62.974567916992186, 3.610331473632804],
            [-62.978567916992183, 3.610731473632804],
            [-62.983967916992185, 3.610131473632805],
            [-62.990067916992189, 3.611731473632801],
            [-62.994667916992185, 3.615331473632807],
            [-62.99696791699219, 3.618131473632801],
            [-62.999867916992187, 3.622031473632802],
            [-63.002467916992188, 3.624831473632803],
            [-63.005267916992182, 3.628731473632804],
            [-63.007967916992186, 3.6335314736328],
            [-63.010767916992187, 3.639331473632801],
            [-63.014267916992182, 3.641331473632803],
            [-63.016067916992185, 3.645831473632803],
            [-63.020767916992185, 3.647731473632803],
            [-63.023767916992185, 3.648931473632807],
            [-63.027567916992183, 3.651531473632801],
            [-63.02876791699218, 3.657731473632801],
            [-63.029867916992188, 3.661731473632805],
            [-63.03176791699218, 3.664931473632805],
            [-63.036367916992184, 3.667431473632803],
            [-63.039167916992184, 3.669131473632802],
            [-63.043867916992184, 3.670431473632803],
            [-63.046967916992187, 3.672331473632802],
            [-63.054067916992182, 3.6761314736328],
            [-63.056867916992189, 3.677131473632805],
            [-63.059667916992183, 3.681131473632803],
            [-63.065967916992186, 3.683931473632803],
            [-63.070467916992186, 3.684731473632802],
            [-63.074067916992185, 3.686231473632802],
            [-63.077067916992185, 3.689131473632806],
            [-63.078967916992184, 3.691531473632807],
            [-63.080167916992181, 3.694131473632801],
            [-63.075767916992184, 3.695531473632805],
            [-63.071367916992187, 3.696131473632803],
            [-63.068767916992186, 3.697731473632807],
            [-63.06686791699218, 3.7005314736328],
            [-63.064367916992182, 3.704131473632806],
            [-63.062967916992186, 3.707531473632805],
            [-63.063467916992181, 3.710731473632805],
            [-63.063067916992182, 3.714131473632804],
            [-63.062967916992186, 3.718031473632806],
            [-63.062967916992186, 3.729931473632803],
            [-63.063567916992184, 3.733931473632801],
            [-63.061867916992185, 3.7371314736328],
            [-63.059367916992187, 3.739731473632801],
            [-63.059367916992187, 3.748731473632802],
            [-63.061667916992185, 3.752031473632805],
            [-63.065767916992186, 3.751931473632801],
            [-63.069567916992185, 3.753131473632806],
            [-63.07656791699219, 3.760631473632806],
            [-63.079067916992187, 3.764331473632801],
            [-63.082367916992183, 3.767231473632805],
            [-63.084067916992183, 3.769931473632802],
            [-63.085367916992183, 3.774931473632805],
            [-63.08686791699219, 3.778531473632803],
            [-63.092067916992185, 3.781631473632807],
            [-63.095167916992182, 3.784731473632803],
            [-63.100267916992181, 3.789131473632807],
            [-63.103167916992184, 3.793231473632801],
            [-63.111067916992184, 3.796131473632805],
            [-63.113567916992181, 3.798531473632806],
            [-63.119367916992189, 3.803531473632802],
            [-63.124167916992185, 3.804331473632807],
            [-63.128767916992189, 3.8026314736328],
            [-63.13706791699218, 3.802931473632803],
            [-63.145467916992189, 3.805431473632801],
            [-63.150167916992189, 3.805931473632803],
            [-63.155167916992184, 3.805931473632803],
            [-63.16346791699219, 3.804831473632802],
            [-63.168167916992189, 3.805431473632801],
            [-63.170567916992184, 3.807531473632807],
            [-63.173167916992185, 3.8103314736328],
            [-63.179667916992187, 3.812931473632801],
            [-63.185467916992181, 3.812531473632802],
            [-63.19386791699219, 3.814531473632805],
            [-63.20156791699219, 3.811931473632804],
            [-63.204667916992186, 3.811931473632804],
            [-63.20756791699219, 3.814531473632805],
            [-63.211167916992181, 3.817531473632805],
            [-63.213467916992187, 3.821431473632806],
            [-63.214867916992183, 3.824931473632802],
            [-63.218667916992189, 3.828931473632807],
            [-63.221667916992189, 3.830331473632803],
            [-63.223367916992188, 3.832931473632804],
            [-63.22596791699219, 3.835131473632806],
            [-63.225567916992183, 3.838331473632806],
            [-63.227767916992185, 3.845231473632801],
            [-63.231367916992184, 3.849631473632805],
            [-63.23166791699218, 3.856531473632806],
            [-63.232767916992188, 3.861731473632801],
            [-63.23166791699218, 3.867531473632802],
            [-63.232567916992181, 3.870531473632802],
            [-63.233367916992187, 3.873331473632803],
            [-63.232867916992184, 3.879131473632803],
            [-63.232867916992184, 3.882831473632805],
            [-63.230667916992189, 3.886331473632801],
            [-63.227467916992182, 3.888331473632803],
            [-63.222067916992188, 3.898531473632801],
            [-63.221367916992186, 3.901531473632801],
            [-63.221967916992185, 3.904431473632805],
            [-63.220567916992181, 3.907131473632802],
            [-63.218467916992182, 3.910231473632805],
            [-63.217667916992184, 3.914531473632806],
            [-63.215467916992182, 3.918131473632805],
            [-63.21326791699218, 3.922531473632802],
            [-63.212967916992184, 3.927531473632804],
            [-63.212367916992186, 3.931731473632802],
            [-63.210967916992189, 3.934331473632803],
            [-63.209067916992183, 3.937031473632807],
            [-63.207367916992183, 3.940531473632802],
            [-63.208267916992185, 3.943431473632806],
            [-63.204967916992189, 3.950031473632805],
            [-63.207667916992186, 3.952531473632803],
            [-63.21186791699219, 3.951931473632804],
            [-63.217267916992185, 3.950931473632807],
            [-63.223367916992188, 3.952731473632802],
            [-63.226367916992189, 3.952731473632802],
            [-63.230667916992189, 3.953631473632804],
            [-63.23466791699218, 3.954131473632806],
            [-63.240067916992189, 3.954331473632806],
            [-63.246567916992184, 3.954531473632805],
            [-63.250267916992186, 3.957131473632806],
            [-63.253467916992186, 3.961131473632804],
            [-63.257067916992185, 3.961131473632804],
            [-63.260467916992184, 3.960831473632801],
            [-63.26406791699219, 3.961331473632804],
            [-63.269867916992183, 3.959431473632804],
            [-63.272967916992187, 3.957731473632805],
            [-63.277667916992186, 3.955531473632803],
            [-63.281967916992187, 3.955831473632806],
            [-63.282667916992182, 3.959131473632802],
            [-63.28206791699219, 3.961931473632802],
            [-63.283667916992187, 3.964931473632802],
            [-63.285967916992185, 3.9719314736328],
            [-63.289967916992182, 3.971331473632802],
            [-63.293067916992186, 3.970931473632803],
            [-63.296067916992186, 3.974631473632805],
            [-63.298967916992183, 3.976931473632803],
            [-63.307967916992183, 3.981331473632807],
            [-63.311967916992188, 3.980931473632801],
            [-63.314067916992187, 3.976731473632803],
            [-63.313767916992184, 3.9719314736328],
            [-63.314867916992185, 3.968531473632801],
            [-63.311867916992185, 3.962131473632802],
            [-63.315467916992183, 3.961931473632802],
            [-63.320967916992188, 3.9627314736328],
            [-63.325967916992184, 3.963331473632806],
            [-63.331567916992185, 3.961931473632802],
            [-63.334867916992181, 3.9597314736328],
            [-63.338467916992187, 3.956931473632807],
            [-63.34156791699219, 3.957731473632805],
            [-63.348467916992185, 3.960131473632806],
            [-63.353067916992188, 3.960731473632805],
            [-63.361367916992187, 3.9627314736328],
            [-63.36466791699219, 3.964131473632804],
            [-63.368267916992181, 3.965831473632804],
            [-63.375167916992183, 3.969531473632806],
            [-63.379067916992184, 3.972731473632805],
            [-63.381867916992185, 3.974531473632801],
            [-63.387167916992183, 3.976731473632803],
            [-63.389867916992188, 3.978931473632805],
            [-63.393967916992182, 3.980531473632801],
            [-63.398767916992185, 3.978931473632805],
            [-63.401867916992188, 3.978531473632806],
            [-63.405167916992184, 3.978131473632807],
            [-63.408367916992184, 3.977131473632802],
            [-63.413067916992183, 3.975531473632806],
            [-63.418067916992186, 3.975731473632806],
            [-63.423267916992188, 3.976031473632801],
            [-63.427767916992181, 3.977131473632802],
            [-63.432467916992181, 3.974531473632801],
            [-63.436267916992186, 3.968531473632801],
            [-63.440467916992183, 3.965531473632801],
            [-63.44356791699218, 3.963331473632806],
            [-63.447167916992186, 3.961331473632804],
            [-63.45156791699219, 3.954731473632805],
            [-63.447967916992184, 3.952131473632804],
            [-63.444067916992182, 3.951131473632806],
            [-63.439967916992188, 3.948531473632805],
            [-63.439367916992182, 3.943931473632801],
            [-63.441667916992188, 3.940531473632802],
            [-63.438367916992185, 3.936431473632801],
            [-63.432967916992183, 3.934331473632803],
            [-63.428267916992183, 3.936131473632805],
            [-63.425067916992184, 3.934731473632802],
            [-63.418867916992184, 3.9353314736328],
            [-63.416367916992186, 3.932631473632803],
            [-63.416767916992185, 3.928731473632801],
            [-63.420267916992188, 3.925131473632803],
            [-63.417267916992188, 3.921531473632804],
            [-63.414167916992184, 3.918231473632801],
            [-63.412767916992181, 3.915731473632803],
            [-63.411967916992182, 3.912731473632803],
            [-63.413167916992187, 3.909131473632804],
            [-63.418967916992187, 3.900731473632803],
            [-63.422767916992186, 3.898331473632801],
            [-63.425767916992186, 3.895531473632801],
            [-63.429367916992184, 3.893331473632806],
            [-63.433767916992181, 3.887531473632805],
            [-63.436567916992189, 3.8850314736328],
            [-63.438267916992189, 3.882731473632802],
            [-63.435167916992185, 3.879231473632807],
            [-63.434067916992184, 3.875531473632805],
            [-63.434867916992189, 3.872531473632804],
            [-63.434867916992189, 3.869331473632805],
            [-63.434367916992187, 3.866131473632805],
            [-63.437967916992186, 3.863231473632801],
            [-63.441267916992189, 3.862931473632806],
            [-63.447067916992182, 3.861131473632803],
            [-63.451767916992182, 3.858531473632802],
            [-63.458467916992184, 3.860431473632801],
            [-63.46156791699218, 3.862531473632806],
            [-63.468167916992186, 3.862331473632807],
            [-63.470367916992188, 3.866231473632801],
            [-63.473867916992184, 3.869031473632802],
            [-63.481767916992183, 3.873731473632802],
            [-63.488667916992185, 3.873731473632802],
            [-63.491667916992185, 3.872931473632804],
            [-63.493267916992181, 3.868131473632801],
            [-63.498067916992184, 3.869031473632802],
            [-63.502367916992185, 3.868331473632807],
            [-63.502467916992188, 3.864931473632801],
            [-63.498767916992186, 3.861831473632805],
            [-63.499167916992185, 3.856831473632802],
            [-63.497667916992185, 3.853931473632805],
            [-63.495467916992183, 3.851331473632804],
            [-63.495867916992182, 3.847331473632806],
            [-63.496267916992181, 3.842531473632803],
            [-63.49836791699218, 3.840231473632805],
            [-63.500467916992186, 3.843331473632801],
            [-63.502367916992185, 3.848131473632805],
            [-63.50636791699219, 3.849631473632805],
            [-63.510967916992186, 3.847431473632803],
            [-63.517167916992186, 3.8499314736328],
            [-63.519667916992184, 3.853231473632803],
            [-63.522567916992188, 3.855531473632801],
            [-63.526567916992185, 3.856331473632807],
            [-63.531967916992187, 3.856731473632806],
            [-63.534867916992184, 3.858531473632802],
            [-63.536667916992187, 3.861831473632805],
            [-63.539867916992186, 3.8651314736328],
            [-63.544967916992185, 3.863531473632804],
            [-63.548967916992183, 3.863131473632805],
            [-63.551867916992187, 3.864931473632801],
            [-63.553267916992183, 3.868131473632801],
            [-63.554767916992184, 3.873331473632803],
            [-63.554667916992187, 3.876731473632802],
            [-63.553667916992183, 3.881731473632804],
            [-63.554067916992182, 3.887531473632805],
            [-63.55516791699219, 3.891131473632804],
            [-63.560467916992181, 3.888331473632803],
            [-63.563267916992189, 3.886331473632801],
            [-63.572367916992185, 3.8835314736328],
            [-63.579867916992185, 3.886331473632801],
            [-63.589767916992187, 3.884931473632804],
            [-63.592067916992185, 3.889931473632807],
            [-63.584867916992181, 3.896531473632805],
            [-63.585667916992186, 3.900131473632804],
            [-63.589067916992185, 3.901131473632802],
            [-63.591967916992189, 3.902131473632807],
            [-63.593067916992183, 3.904931473632807],
            [-63.59126791699218, 3.911931473632805],
            [-63.587967916992184, 3.918231473632801],
            [-63.592267916992185, 3.928331473632802],
            [-63.595867916992184, 3.932631473632803],
            [-63.599567916992186, 3.935531473632807],
            [-63.602767916992185, 3.939731473632804],
            [-63.60666791699218, 3.941931473632806],
            [-63.608867916992182, 3.944231473632804],
            [-63.615467916992188, 3.955531473632803],
            [-63.617467916992183, 3.9597314736328],
            [-63.620467916992183, 3.9627314736328],
            [-63.624067916992189, 3.9657314736328],
            [-63.627667916992181, 3.969331473632806],
            [-63.63106791699218, 3.9719314736328],
            [-63.63436791699219, 3.974631473632805],
            [-63.638167916992188, 3.977131473632802],
            [-63.642867916992188, 3.978531473632806],
            [-63.64476791699218, 3.9841314736328],
            [-63.647667916992184, 3.990431473632803],
            [-63.648467916992189, 3.993331473632807],
            [-63.64936791699219, 3.997031473632802],
            [-63.652667916992186, 4.001931473632801],
            [-63.655167916992184, 4.003931473632804],
            [-63.658067916992181, 4.007031473632807],
            [-63.661667916992187, 4.010331473632803],
            [-63.667167916992184, 4.011131473632801],
            [-63.671067916992186, 4.013331473632803],
            [-63.675067916992184, 4.014931473632807],
            [-63.676867916992187, 4.019131473632804],
            [-63.680467916992185, 4.018131473632806],
            [-63.683767916992181, 4.018531473632805],
            [-63.68916791699219, 4.014731473632807],
            [-63.688067916992182, 4.011431473632804],
            [-63.685767916992184, 4.009531473632805],
            [-63.686067916992187, 4.004131473632803],
            [-63.68916791699219, 3.999831473632803],
            [-63.687467916992183, 3.995731473632802],
            [-63.686967916992188, 3.992631473632805],
            [-63.691067916992182, 3.986131473632803],
            [-63.691667916992188, 3.982331473632804],
            [-63.690767916992186, 3.979131473632805],
            [-63.694367916992185, 3.973831473632806],
            [-63.69486791699218, 3.970731473632803],
            [-63.696267916992184, 3.966331473632806],
            [-63.695467916992186, 3.961931473632802],
            [-63.695567916992189, 3.957731473632805],
            [-63.697367916992185, 3.954731473632805],
            [-63.700267916992189, 3.951331473632806],
            [-63.700767916992184, 3.947831473632803],
            [-63.700467916992181, 3.944231473632804],
            [-63.697367916992185, 3.942731473632804],
            [-63.693767916992186, 3.937031473632807],
            [-63.690167916992181, 3.934731473632802],
            [-63.685767916992184, 3.934831473632805],
            [-63.681967916992186, 3.9353314736328],
            [-63.679967916992183, 3.932631473632803],
            [-63.678667916992183, 3.928931473632801],
            [-63.674267916992186, 3.927331473632805],
            [-63.673967916992183, 3.923731473632806],
            [-63.67076791699219, 3.9231314736328],
            [-63.663867916992189, 3.922531473632802],
            [-63.668867916992184, 3.917431473632803],
            [-63.67216791699218, 3.914631473632802],
            [-63.677267916992186, 3.912731473632803],
            [-63.683367916992182, 3.907731473632801],
            [-63.690167916992181, 3.909131473632804],
            [-63.695967916992188, 3.912931473632803],
            [-63.701067916992187, 3.912131473632805],
            [-63.704267916992187, 3.913731473632801],
            [-63.707367916992183, 3.913831473632804],
            [-63.710667916992186, 3.911731473632805],
            [-63.709667916992188, 3.907331473632802],
            [-63.712267916992189, 3.904131473632802],
            [-63.72126791699219, 3.910731473632801],
            [-63.723367916992188, 3.914131473632807],
            [-63.72696791699218, 3.914931473632805],
            [-63.733567916992186, 3.913331473632802],
            [-63.738067916992186, 3.914931473632805],
            [-63.740067916992189, 3.918731473632803],
            [-63.743267916992181, 3.920931473632805],
            [-63.739967916992185, 3.925331473632802],
            [-63.738667916992185, 3.929031473632804],
            [-63.741167916992183, 3.932631473632803],
            [-63.752967916992183, 3.938431473632804],
            [-63.755967916992184, 3.940531473632802],
            [-63.761067916992189, 3.938931473632806],
            [-63.767667916992181, 3.935931473632806],
            [-63.772367916992181, 3.938731473632807],
            [-63.777367916992183, 3.935531473632807],
            [-63.785867916992188, 3.935531473632807],
            [-63.789967916992182, 3.933931473632803],
            [-63.79746791699219, 3.933431473632801],
            [-63.802567916992189, 3.935531473632807],
            [-63.808767916992181, 3.936931473632804],
            [-63.812667916992183, 3.939131473632806],
            [-63.816567916992184, 3.939531473632805],
            [-63.820467916992186, 3.939731473632804],
            [-63.824667916992183, 3.940931473632801],
            [-63.828167916992186, 3.939731473632804],
            [-63.834367916992186, 3.9398314736328],
            [-63.837367916992186, 3.943131473632803],
            [-63.840067916992183, 3.946331473632803],
            [-63.844167916992184, 3.946931473632802],
            [-63.84726791699218, 3.949531473632803],
            [-63.851167916992182, 3.948931473632804],
            [-63.853167916992184, 3.945131473632806],
            [-63.857067916992186, 3.946431473632806],
            [-63.862467916992188, 3.946431473632806],
            [-63.867167916992187, 3.940331473632803],
            [-63.870867916992182, 3.937531473632802],
            [-63.877367916992185, 3.936231473632802],
            [-63.879867916992183, 3.934531473632802],
            [-63.884967916992181, 3.933331473632805],
            [-63.890367916992183, 3.930931473632803],
            [-63.893667916992186, 3.931131473632803],
            [-63.900467916992184, 3.933431473632801],
            [-63.904867916992188, 3.931931473632801],
            [-63.909367916992181, 3.930031473632802],
            [-63.915267916992185, 3.925931473632801],
            [-63.926067916992181, 3.9261314736328],
            [-63.928967916992185, 3.924331473632805],
            [-63.930467916992185, 3.921331473632804],
            [-63.935167916992185, 3.917731473632806],
            [-63.936567916992189, 3.914531473632806],
            [-63.935867916992187, 3.909531473632804],
            [-63.937167916992188, 3.903331473632804],
            [-63.938767916992184, 3.900731473632803],
            [-63.941967916992184, 3.899731473632805],
            [-63.945467916992186, 3.900331473632804],
            [-63.947067916992182, 3.892931473632807],
            [-63.949567916992187, 3.889931473632807],
            [-63.950967916992184, 3.886431473632804],
            [-63.956767916992185, 3.889331473632801],
            [-63.959567916992185, 3.885531473632803],
            [-63.959267916992189, 3.880531473632807],
            [-63.960967916992189, 3.877031473632805],
            [-63.961567916992188, 3.873931473632801],
            [-63.961567916992188, 3.869531473632804],
            [-63.964867916992183, 3.868331473632807],
            [-63.966567916992183, 3.873431473632806],
            [-63.968467916992182, 3.878431473632801],
            [-63.972667916992187, 3.885931473632802],
            [-63.975267916992188, 3.893331473632806],
            [-63.977767916992185, 3.898531473632801],
            [-63.979967916992187, 3.900531473632803],
            [-63.982867916992184, 3.897531473632803],
            [-63.987467916992188, 3.8972314736328],
            [-63.990467916992188, 3.899131473632806],
            [-63.992767916992186, 3.902531473632806],
            [-63.996667916992187, 3.903031473632801],
            [-63.997667916992185, 3.908331473632806],
            [-63.998367916992187, 3.911731473632805],
            [-63.999767916992184, 3.923531473632806],
            [-64.000767916992189, 3.930331473632805],
            [-64.001867916992182, 3.933131473632805],
            [-64.014367916992185, 3.932931473632806],
            [-64.018667916992186, 3.933431473632801],
            [-64.030967916992182, 3.933931473632803],
            [-64.035667916992182, 3.934331473632803],
            [-64.035967916992178, 3.944131473632801],
            [-64.047767916992186, 3.949731473632802],
            [-64.050867916992189, 3.950931473632807],
            [-64.051167916992185, 3.954331473632806],
            [-64.052567916992189, 3.957231473632802],
            [-64.057967916992183, 3.966331473632806],
            [-64.063767916992191, 3.977731473632801],
            [-64.065467916992191, 3.981331473632807],
            [-64.068267916992184, 3.985431473632801],
            [-64.070767916992182, 3.988531473632804],
            [-64.074367916992188, 3.994731473632804],
            [-64.075767916992191, 3.998731473632802],
            [-64.083267916992185, 4.009931473632804],
            [-64.087967916992184, 4.016931473632802],
            [-64.09126791699218, 4.021131473632806],
            [-64.093767916992192, 4.023331473632801],
            [-64.097067916992188, 4.025131473632804],
            [-64.098467916992178, 4.028531473632803],
            [-64.099567916992186, 4.031631473632807],
            [-64.099967916992185, 4.034731473632803],
            [-64.095867916992191, 4.039531473632806],
            [-64.09456791699219, 4.042731473632806],
            [-64.095267916992185, 4.048231473632804],
            [-64.096367916992179, 4.053531473632802],
            [-64.098467916992178, 4.058931473632803],
            [-64.10066791699218, 4.062731473632802],
            [-64.10196791699218, 4.065531473632802],
            [-64.10496791699218, 4.076131473632806],
            [-64.108867916992182, 4.085531473632805],
            [-64.111467916992183, 4.088331473632806],
            [-64.112567916992191, 4.091331473632806],
            [-64.114967916992185, 4.093831473632804],
            [-64.11766791699219, 4.096131473632802],
            [-64.121267916992181, 4.098531473632804],
            [-64.12466791699218, 4.100231473632803],
            [-64.133267916992182, 4.106031473632804],
            [-64.136267916992182, 4.107531473632804],
            [-64.141267916992177, 4.107731473632803],
            [-64.144067916992185, 4.108531473632802],
            [-64.147367916992181, 4.110331473632804],
            [-64.150467916992184, 4.112531473632806],
            [-64.152967916992182, 4.114731473632801],
            [-64.156567916992188, 4.116731473632804],
            [-64.159467916992185, 4.119031473632802],
            [-64.160967916992178, 4.121931473632806],
            [-64.163667916992182, 4.125531473632805],
            [-64.168067916992186, 4.127931473632806],
            [-64.171867916992184, 4.128931473632804],
            [-64.174367916992182, 4.127531473632807],
            [-64.177567916992189, 4.125631473632801],
            [-64.184367916992187, 4.119731473632804],
            [-64.190767916992186, 4.116731473632804],
            [-64.201867916992185, 4.115331473632807],
            [-64.212967916992184, 4.114931473632801],
            [-64.218767916992192, 4.115431473632803],
            [-64.224167916992187, 4.117531473632802],
            [-64.232167916992182, 4.123931473632801],
            [-64.23966791699219, 4.124731473632806],
            [-64.244367916992189, 4.126731473632802],
            [-64.248567916992187, 4.129131473632803],
            [-64.251567916992187, 4.129531473632802],
            [-64.25436791699218, 4.128331473632805],
            [-64.261567916992192, 4.125631473632801],
            [-64.265167916992183, 4.125931473632804],
            [-64.269067916992185, 4.127931473632806],
            [-64.272167916992188, 4.133931473632806],
            [-64.273967916992177, 4.141731473632802],
            [-64.276267916992182, 4.143931473632804],
            [-64.289567916992183, 4.143931473632804],
            [-64.293867916992184, 4.142231473632805],
            [-64.296367916992182, 4.140331473632806],
            [-64.299367916992182, 4.134231473632802],
            [-64.30216791699219, 4.132531473632802],
            [-64.30616791699218, 4.131331473632805],
            [-64.314867916992185, 4.131331473632805],
            [-64.324667916992183, 4.130331473632801],
            [-64.335467916992187, 4.129131473632803],
            [-64.345667916992184, 4.131131473632806],
            [-64.386267916992182, 4.137131473632806],
            [-64.397367916992181, 4.1335314736328],
            [-64.40376791699218, 4.132031473632807],
            [-64.431567916992179, 4.134731473632804],
            [-64.44216791699219, 4.130631473632803],
            [-64.448767916992182, 4.126131473632803],
            [-64.452367916992188, 4.123331473632803],
            [-64.457667916992179, 4.121231473632804],
            [-64.46626791699218, 4.118931473632806],
            [-64.476367916992189, 4.116931473632803],
            [-64.493267916992181, 4.114031473632807],
            [-64.500267916992186, 4.111731473632801],
            [-64.503267916992186, 4.110431473632801],
            [-64.506267916992186, 4.111131473632803],
            [-64.509667916992186, 4.111831473632805],
            [-64.517167916992179, 4.114031473632807],
            [-64.520967916992191, 4.116231473632801],
            [-64.52406791699218, 4.116131473632805],
            [-64.529867916992188, 4.114331473632802],
            [-64.535867916992188, 4.113931473632803],
            [-64.544267916992183, 4.113931473632803],
            [-64.551067916992181, 4.111331473632802],
            [-64.556867916992189, 4.105531473632801],
            [-64.560467916992181, 4.101731473632803],
            [-64.579667916992179, 4.1091314736328],
            [-64.58656791699218, 4.111331473632802],
            [-64.59296791699218, 4.114031473632807],
            [-64.600267916992181, 4.119031473632802],
            [-64.608967916992185, 4.126731473632802],
            [-64.616867916992192, 4.131331473632805],
            [-64.623567916992187, 4.134931473632804],
            [-64.627367916992185, 4.138531473632803],
            [-64.63106791699218, 4.143531473632805],
            [-64.640967916992182, 4.165331473632804],
            [-64.654767916992185, 4.204331473632806],
            [-64.65846791699218, 4.211531473632803],
            [-64.664567916992183, 4.218031473632806],
            [-64.684667916992183, 4.239331473632802],
            [-64.691267916992189, 4.244731473632804],
            [-64.699667916992183, 4.250331473632805],
            [-64.706067916992183, 4.252931473632806],
            [-64.724567916992186, 4.259931473632804],
            [-64.730567916992186, 4.262531473632805],
            [-64.738867916992177, 4.266931473632802],
            [-64.744067916992179, 4.269931473632802],
            [-64.750767916992189, 4.274131473632806],
            [-64.757167916992188, 4.276531473632801],
            [-64.76406791699219, 4.278931473632802],
            [-64.78046791699218, 4.286731473632805],
            [-64.78846791699219, 4.287131473632805],
            [-64.793567916992188, 4.286931473632805],
            [-64.796867916992184, 4.285731473632801],
            [-64.799667916992178, 4.284131473632804],
            [-64.804667916992187, 4.277731473632805],
            [-64.808767916992181, 4.271731473632805],
            [-64.817667916992178, 4.265331473632806],
            [-64.819967916992184, 4.261431473632804],
            [-64.82156791699218, 4.2585314736328],
            [-64.822367916992192, 4.253431473632801],
            [-64.824967916992179, 4.242931473632801],
            [-64.825167916992186, 4.238931473632803],
            [-64.82186791699219, 4.223331473632804],
            [-64.821267916992184, 4.218031473632806],
            [-64.82186791699219, 4.211931473632802],
            [-64.820467916992186, 4.205331473632803],
            [-64.814367916992182, 4.184731473632802],
            [-64.810467916992181, 4.174731473632804],
            [-64.807967916992183, 4.171731473632803],
            [-64.803867916992189, 4.167131473632807],
            [-64.796467916992185, 4.161931473632805],
            [-64.789567916992183, 4.157131473632802],
            [-64.78346791699218, 4.152731473632805],
            [-64.753267916992186, 4.136931473632806],
            [-64.745167916992187, 4.131731473632804],
            [-64.740067916992189, 4.127731473632807],
            [-64.736067916992184, 4.124131473632801],
            [-64.732167916992182, 4.120331473632802],
            [-64.723067916992193, 4.104931473632803],
            [-64.714867916992191, 4.084131473632802],
            [-64.712267916992189, 4.074231473632807],
            [-64.708467916992191, 4.066531473632807],
            [-64.705167916992181, 4.062531473632802],
            [-64.69516791699219, 4.051731473632806],
            [-64.686067916992187, 4.043531473632804],
            [-64.683767916992181, 4.040331473632804],
            [-64.682967916992183, 4.036331473632806],
            [-64.68116791699218, 4.024731473632805],
            [-64.680767916992181, 4.016331473632803],
            [-64.675067916992191, 4.012531473632805],
            [-64.67386791699218, 4.004731473632802],
            [-64.66446791699218, 3.999731473632806],
            [-64.658067916992181, 3.996131473632801],
            [-64.654867916992188, 3.994531473632804],
            [-64.650667916992191, 3.991931473632803],
            [-64.646867916992193, 3.988531473632804],
            [-64.643167916992184, 3.984631473632803],
            [-64.633867916992187, 3.975131473632807],
            [-64.628567916992182, 3.963031473632803],
            [-64.624367916992185, 3.956531473632801],
            [-64.619767916992188, 3.951331473632806],
            [-64.61466791699219, 3.946731473632802],
            [-64.60966791699218, 3.944931473632806],
            [-64.602767916992178, 3.942831473632801],
            [-64.597067916992188, 3.938431473632804],
            [-64.585667916992179, 3.932131473632801],
            [-64.580167916992181, 3.928331473632802],
            [-64.578267916992189, 3.922531473632802],
            [-64.57756791699218, 3.917931473632805],
            [-64.571267916992184, 3.894931473632802],
            [-64.568267916992184, 3.881931473632804],
            [-64.563467916992181, 3.875931473632804],
            [-64.557967916992183, 3.868531473632807],
            [-64.552967916992188, 3.863731473632804],
            [-64.54746791699219, 3.8591314736328],
            [-64.540367916992182, 3.854631473632807],
            [-64.533967916992182, 3.849331473632802],
            [-64.530367916992191, 3.847131473632807],
            [-64.52446791699218, 3.841931473632805],
            [-64.522567916992188, 3.838731473632805],
            [-64.512867916992178, 3.821331473632803],
            [-64.50906791699218, 3.815131473632803],
            [-64.505467916992188, 3.809831473632805],
            [-64.500567916992182, 3.804031473632804],
            [-64.496167916992192, 3.800131473632803],
            [-64.492967916992185, 3.796731473632803],
            [-64.490467916992188, 3.793231473632801],
            [-64.488867916992177, 3.790531473632804],
            [-64.485367916992189, 3.7874314736328],
            [-64.482467916992192, 3.785731473632801],
            [-64.477767916992178, 3.783031473632803],
            [-64.472367916992184, 3.781631473632807],
            [-64.468767916992192, 3.781331473632804],
            [-64.459067916992183, 3.781331473632804],
            [-64.455467916992191, 3.782431473632805],
            [-64.451067916992187, 3.783531473632806],
            [-64.447367916992192, 3.784131473632804],
            [-64.443767916992186, 3.783831473632802],
            [-64.433767916992181, 3.781531473632803],
            [-64.428567916992179, 3.779931473632807],
            [-64.42546791699219, 3.778031473632801],
            [-64.422767916992186, 3.775831473632806],
            [-64.420267916992188, 3.773331473632801],
            [-64.416767916992185, 3.770831473632803],
            [-64.409167916992189, 3.765731473632805],
            [-64.403367916992181, 3.763931473632802],
            [-64.396567916992183, 3.762531473632805],
            [-64.389867916992188, 3.759731473632804],
            [-64.376867916992182, 3.753331473632805],
            [-64.368567916992191, 3.747631473632801],
            [-64.36466791699219, 3.747031473632802],
            [-64.357767916992188, 3.741231473632801],
            [-64.352467916992182, 3.738531473632804],
            [-64.346967916992185, 3.736731473632801],
            [-64.342667916992184, 3.734931473632805],
            [-64.339367916992188, 3.733131473632803],
            [-64.335167916992191, 3.729631473632807],
            [-64.332667916992179, 3.725531473632806],
            [-64.330367916992188, 3.722431473632803],
            [-64.327967916992179, 3.720531473632803],
            [-64.325167916992186, 3.719531473632806],
            [-64.321667916992183, 3.718331473632801],
            [-64.297867916992189, 3.704131473632806],
            [-64.28846791699219, 3.698731473632805],
            [-64.286667916992187, 3.693931473632801],
            [-64.286967916992182, 3.690331473632803],
            [-64.286367916992191, 3.687031473632807],
            [-64.283867916992179, 3.679331473632807],
            [-64.282367916992186, 3.676731473632806],
            [-64.280967916992182, 3.669531473632802],
            [-64.278467916992184, 3.665331473632804],
            [-64.27576791699218, 3.661331473632806],
            [-64.272367916992181, 3.658531473632806],
            [-64.267367916992185, 3.6547314736328],
            [-64.261867916992188, 3.651331473632801],
            [-64.25766791699219, 3.647731473632803],
            [-64.253567916992182, 3.641331473632803],
            [-64.251267916992191, 3.637531473632805],
            [-64.24966791699218, 3.633331473632801],
            [-64.246867916992187, 3.627731473632807],
            [-64.244367916992189, 3.624831473632803],
            [-64.241367916992189, 3.621931473632806],
            [-64.23166791699218, 3.613231473632801],
            [-64.209867916992181, 3.594731473632805],
            [-64.205967916992179, 3.590531473632801],
            [-64.203767916992177, 3.587531473632801],
            [-64.200467916992181, 3.583331473632803],
            [-64.196267916992184, 3.578931473632807],
            [-64.187367916992187, 3.555431473632801],
            [-64.184367916992187, 3.489331473632802],
            [-64.161267916992188, 3.465131473632802],
            [-64.148667916992181, 3.4398314736328],
            [-64.147867916992183, 3.403931473632802],
            [-64.139867916992188, 3.384731473632804],
            [-64.145167916992179, 3.378331473632805],
            [-64.146867916992193, 3.371231473632804],
            [-64.14036791699219, 3.364931473632801],
            [-64.132767916992179, 3.363931473632803],
            [-64.132767916992179, 3.357131473632805],
            [-64.136267916992182, 3.351031473632801],
            [-64.134267916992187, 3.348731473632803],
            [-64.132767916992179, 3.345231473632801],
            [-64.127367916992185, 3.345931473632803],
            [-64.125567916992182, 3.338731473632805],
            [-64.117967916992185, 3.337531473632801],
            [-64.114967916992185, 3.334931473632807],
            [-64.115767916992183, 3.329131473632806],
            [-64.117467916992183, 3.3255314736328],
            [-64.118567916992191, 3.321431473632806],
            [-64.115867916992187, 3.3133314736328],
            [-64.116667916992185, 3.307631473632803],
            [-64.119667916992185, 3.304831473632802],
            [-64.11866791699218, 3.301531473632807],
            [-64.119667916992185, 3.298231473632804],
            [-64.121567916992191, 3.294331473632802],
            [-64.123267916992191, 3.291531473632801],
            [-64.119367916992189, 3.289331473632807],
            [-64.118067916992189, 3.286731473632805],
            [-64.118067916992189, 3.282331473632802],
            [-64.117467916992183, 3.278531473632803],
            [-64.120767916992179, 3.278731473632803],
            [-64.125967916992181, 3.275831473632806],
            [-64.129067916992184, 3.274331473632806],
            [-64.132967916992186, 3.271931473632804],
            [-64.136867916992188, 3.272531473632803],
            [-64.138567916992187, 3.268631473632801],
            [-64.144867916992183, 3.264931473632807],
            [-64.144567916992187, 3.261331473632801],
            [-64.147967916992187, 3.258331473632801],
            [-64.150067916992185, 3.255331473632801],
            [-64.153367916992181, 3.253931473632804],
            [-64.150467916992184, 3.2493314736328],
            [-64.156267916992192, 3.248431473632806],
            [-64.15846791699218, 3.243731473632806],
            [-64.157267916992183, 3.2401314736328],
            [-64.15676791699218, 3.234331473632807],
            [-64.154567916992193, 3.230131473632802],
            [-64.154867916992188, 3.221531473632801],
            [-64.15676791699218, 3.212531473632801],
            [-64.158667916992187, 3.206731473632807],
            [-64.16646791699219, 3.204731473632805],
            [-64.169967916992192, 3.201331473632806],
            [-64.170267916992188, 3.197131473632801],
            [-64.17216791699218, 3.191131473632801],
            [-64.174967916992188, 3.188731473632807],
            [-64.17686791699218, 3.184131473632803],
            [-64.176467916992181, 3.179531473632807],
            [-64.181367916992187, 3.170431473632803],
            [-64.181367916992187, 3.165331473632804],
            [-64.187367916992187, 3.164131473632807],
            [-64.189667916992192, 3.160731473632801],
            [-64.192767916992182, 3.159131473632804],
            [-64.195767916992182, 3.160231473632805],
            [-64.200367916992178, 3.164631473632802],
            [-64.205367916992188, 3.159931473632803],
            [-64.206867916992181, 3.157131473632802],
            [-64.210967916992189, 3.154331473632801],
            [-64.22096791699218, 3.150131473632804],
            [-64.223367916992188, 3.145031473632805],
            [-64.223067916992193, 3.139731473632807],
            [-64.225867916992186, 3.132031473632807],
            [-64.225867916992186, 3.127931473632806],
            [-64.223367916992188, 3.124131473632801],
            [-64.218767916992192, 3.1121314736328],
            [-64.21926791699218, 3.106031473632804],
            [-64.216167916992191, 3.100131473632807],
            [-64.218667916992189, 3.096131473632802],
            [-64.218667916992189, 3.0907314736328],
            [-64.213767916992182, 3.090531473632801],
            [-64.208167916992181, 3.082231473632802],
            [-64.199067916992192, 3.081731473632807],
            [-64.192667916992178, 3.073931473632804],
            [-64.185567916992184, 3.067831473632801],
            [-64.174467916992185, 3.062031473632807],
            [-64.179067916992182, 3.054831473632802],
            [-64.174667916992178, 3.050731473632801],
            [-64.168067916992186, 3.050431473632806],
            [-64.158367916992191, 3.045331473632807],
            [-64.156167916992189, 3.040131473632805],
            [-64.147967916992187, 3.036531473632806],
            [-64.146867916992193, 3.032331473632802],
            [-64.144567916992187, 3.025831473632806],
            [-64.146767916992189, 3.018931473632804],
            [-64.152267916992187, 3.016331473632803],
            [-64.147067916992185, 3.010631473632806],
            [-64.152267916992187, 3.002931473632806],
            [-64.157667916992182, 2.994031473632802],
            [-64.160867916992188, 2.987331473632807],
            [-64.152267916992187, 2.985131473632805],
            [-64.14476791699218, 2.985931473632803],
            [-64.139567916992178, 2.990731473632806],
            [-64.132067916992185, 2.986831473632805],
            [-64.123767916992179, 2.988231473632801],
            [-64.118867916992187, 2.985731473632804],
            [-64.117467916992183, 2.981031473632804],
            [-64.112167916992178, 2.970131473632804],
            [-64.10696791699219, 2.966531473632806],
            [-64.109167916992192, 2.9597314736328],
            [-64.107767916992188, 2.954131473632806],
            [-64.10096791699219, 2.952131473632804],
            [-64.09756791699219, 2.946931473632802],
            [-64.096467916992182, 2.944131473632801],
            [-64.093667916992189, 2.9398314736328],
            [-64.094467916992187, 2.935131473632801],
            [-64.091467916992187, 2.932931473632806],
            [-64.084367916992193, 2.931231473632806],
            [-64.07956791699219, 2.925431473632806],
            [-64.07316791699219, 2.922931473632801],
            [-64.070467916992186, 2.917431473632803],
            [-64.07456791699218, 2.913131473632802],
            [-64.07756791699218, 2.905931473632805],
            [-64.077167916992181, 2.901331473632801],
            [-64.071267916992184, 2.902931473632805],
            [-64.069267916992189, 2.896931473632804],
            [-64.06986791699218, 2.890731473632805],
            [-64.075467916992181, 2.883331473632801],
            [-64.078167916992186, 2.879931473632801],
            [-64.078767916992177, 2.874131473632801],
            [-64.076067916992187, 2.870331473632802],
            [-64.067767916992182, 2.869731473632804],
            [-64.060767916992191, 2.864731473632801],
            [-64.052567916992189, 2.855531473632801],
            [-64.051167916992185, 2.850731473632806],
            [-64.046967916992187, 2.840231473632805],
            [-64.042167916992184, 2.836731473632803],
            [-64.041367916992186, 2.831731473632807],
            [-64.034467916992185, 2.830731473632802],
            [-64.032867916992188, 2.827731473632802],
            [-64.027067916992181, 2.825031473632805],
            [-64.020767916992185, 2.818131473632803],
            [-64.014667916992181, 2.812331473632803],
            [-64.01676791699218, 2.807331473632807],
            [-64.018167916992184, 2.795331473632807],
            [-64.016867916992183, 2.790331473632804],
            [-64.01406791699219, 2.7859314736328],
            [-64.016067916992185, 2.777731473632805],
            [-64.01206791699218, 2.773931473632807],
            [-64.004067916992184, 2.773331473632801],
            [-63.998767916992186, 2.770031473632805],
            [-63.996967916992183, 2.766331473632803],
            [-63.999467916992188, 2.759931473632804],
            [-64.005167916992178, 2.758931473632806],
            [-64.005767916992184, 2.753331473632805],
            [-64.003867916992192, 2.750331473632805],
            [-64.003267916992186, 2.747331473632805],
            [-64.002367916992185, 2.741731473632804],
            [-63.999067916992189, 2.736831473632805],
            [-63.994367916992189, 2.736831473632805],
            [-63.991367916992189, 2.735731473632804],
            [-63.988567916992181, 2.732531473632804],
            [-63.983867916992182, 2.728531473632806],
            [-63.986367916992187, 2.725231473632803],
            [-63.987567916992184, 2.720731473632803],
            [-63.987167916992185, 2.7157314736328],
            [-63.990767916992183, 2.716531473632806],
            [-63.995867916992182, 2.715231473632805],
            [-63.996667916992187, 2.710831473632801],
            [-64.000267916992186, 2.709431473632804],
            [-64.006867916992178, 2.709431473632804],
            [-64.008767916992184, 2.7050314736328],
            [-64.001267916992191, 2.700031473632805],
            [-64.000267916992186, 2.695931473632804],
            [-63.997967916992181, 2.6913314736328],
            [-63.994467916992186, 2.690131473632803],
            [-63.988567916992181, 2.6898314736328],
            [-63.986667916992189, 2.683931473632803],
            [-63.989067916992184, 2.682131473632801],
            [-63.988667916992185, 2.677931473632803],
            [-63.990067916992189, 2.673731473632806],
            [-63.990367916992184, 2.669331473632802],
            [-63.989767916992186, 2.663531473632801],
            [-63.987567916992184, 2.658031473632803],
            [-63.987167916992185, 2.654931473632807],
            [-63.988367916992189, 2.651631473632804],
            [-63.986067916992184, 2.6487314736328],
            [-63.991067916992186, 2.645831473632803],
            [-63.991067916992186, 2.641931473632802],
            [-63.994067916992186, 2.636131473632801],
            [-63.996967916992183, 2.632531473632802],
            [-64.003467916992179, 2.630331473632801],
            [-64.007767916992179, 2.626731473632802],
            [-64.005267916992182, 2.621231473632804],
            [-64.011267916992182, 2.617331473632802],
            [-64.011767916992184, 2.610431473632801],
            [-64.009967916992181, 2.604631473632807],
            [-64.01406791699219, 2.600131473632807],
            [-64.018267916992187, 2.598831473632806],
            [-64.018767916992189, 2.594731473632805],
            [-64.021467916992179, 2.592731473632803],
            [-64.025967916992187, 2.589731473632803],
            [-64.028667916992191, 2.585731473632805],
            [-64.026767916992185, 2.582531473632805],
            [-64.027267916992187, 2.577231473632807],
            [-64.031667916992191, 2.577831473632806],
            [-64.03346791699218, 2.5755314736328],
            [-64.03806791699219, 2.5800314736328],
            [-64.042567916992184, 2.5800314736328],
            [-64.044767916992186, 2.574931473632802],
            [-64.046367916992182, 2.570331473632805],
            [-64.04886791699218, 2.567531473632805],
            [-64.048067916992181, 2.564531473632805],
            [-64.046767916992181, 2.561231473632802],
            [-64.04446791699219, 2.553731473632801],
            [-64.048267916992188, 2.552131473632805],
            [-64.047567916992179, 2.549031473632802],
            [-64.046767916992181, 2.543931473632803],
            [-64.045867916992179, 2.539531473632806],
            [-64.04446791699219, 2.536331473632806],
            [-64.04576791699219, 2.533331473632806],
            [-64.045667916992187, 2.528531473632803],
            [-64.046067916992186, 2.522131473632804],
            [-64.047767916992186, 2.518531473632805],
            [-64.054367916992192, 2.516931473632802],
            [-64.05616791699218, 2.513131473632804],
            [-64.058267916992179, 2.509131473632806],
            [-64.06086791699218, 2.505631473632803],
            [-64.059767916992186, 2.500331473632805],
            [-64.05616791699218, 2.496531473632807],
            [-64.052767916992181, 2.497031473632802],
            [-64.047767916992186, 2.496231473632804],
            [-64.043867916992184, 2.496731473632806],
            [-64.041967916992178, 2.492531473632802],
            [-64.039867916992179, 2.488931473632803],
            [-64.03806791699219, 2.485331473632804],
            [-64.034267916992178, 2.485431473632801],
            [-64.032567916992178, 2.481731473632806],
            [-64.032567916992178, 2.477331473632802],
            [-64.031467916992185, 2.472731473632805],
            [-64.027867916992193, 2.475131473632807],
            [-64.026167916992179, 2.471331473632802],
            [-64.023367916992186, 2.467531473632803],
            [-64.022867916992183, 2.464731473632803],
            [-64.018467916992179, 2.467331473632804],
            [-64.015967916992182, 2.471131473632802],
            [-64.014267916992182, 2.477331473632802],
            [-64.008867916992187, 2.480131473632802],
            [-63.999967916992183, 2.479131473632805],
            [-63.997467916992186, 2.476031473632801],
            [-63.991567916992182, 2.472731473632805],
            [-63.986767916992186, 2.472131473632807],
            [-63.982267916992186, 2.466931473632805],
            [-63.976467916992185, 2.465131473632802],
            [-63.972567916992183, 2.466631473632802],
            [-63.968667916992189, 2.468031473632806],
            [-63.966467916992187, 2.472431473632803],
            [-63.960067916992188, 2.473531473632804],
            [-63.958767916992187, 2.470731473632803],
            [-63.959267916992189, 2.467731473632803],
            [-63.954667916992186, 2.459431473632804],
            [-63.950667916992188, 2.461331473632804],
            [-63.946867916992183, 2.463531473632806],
            [-63.943867916992183, 2.460831473632801],
            [-63.939367916992182, 2.4627314736328],
            [-63.937967916992186, 2.458931473632802],
            [-63.935467916992181, 2.457131473632806],
            [-63.926967916992183, 2.465131473632802],
            [-63.925867916992189, 2.471531473632801],
            [-63.920367916992184, 2.473731473632803],
            [-63.915567916992188, 2.4719314736328],
            [-63.912267916992185, 2.473531473632804],
            [-63.904767916992185, 2.471131473632802],
            [-63.899067916992188, 2.470231473632801],
            [-63.895767916992185, 2.466531473632806],
            [-63.892067916992183, 2.465831473632804],
            [-63.886067916992189, 2.468831473632804],
            [-63.882067916992185, 2.469131473632807],
            [-63.880167916992185, 2.471531473632801],
            [-63.878767916992189, 2.474531473632801],
            [-63.875467916992186, 2.477131473632802],
            [-63.874067916992189, 2.480531473632801],
            [-63.871267916992181, 2.482931473632803],
            [-63.868067916992189, 2.483231473632806],
            [-63.863967916992188, 2.485431473632801],
            [-63.860267916992186, 2.486831473632805],
            [-63.856467916992187, 2.486731473632801],
            [-63.848467916992185, 2.492631473632805],
            [-63.845167916992182, 2.495331473632802],
            [-63.834067916992183, 2.491131473632805],
            [-63.830667916992184, 2.4871314736328],
            [-63.823567916992189, 2.487331473632807],
            [-63.818767916992186, 2.487531473632806],
            [-63.810567916992184, 2.481831473632802],
            [-63.806567916992186, 2.481731473632806],
            [-63.799467916992185, 2.480331473632802],
            [-63.795867916992187, 2.476531473632804],
            [-63.794267916992183, 2.472731473632805],
            [-63.791067916992183, 2.472731473632805],
            [-63.787767916992181, 2.4719314736328],
            [-63.78476791699218, 2.471131473632802],
            [-63.783067916992181, 2.467331473632804],
            [-63.78346791699218, 2.462531473632801],
            [-63.781967916992187, 2.456931473632807],
            [-63.776767916992185, 2.4550314736328],
            [-63.773967916992184, 2.452731473632802],
            [-63.773467916992189, 2.448731473632805],
            [-63.770367916992186, 2.448731473632805],
            [-63.767467916992189, 2.4475314736328],
            [-63.761867916992188, 2.443931473632801],
            [-63.759567916992182, 2.446431473632806],
            [-63.756267916992186, 2.445531473632805],
            [-63.753867916992185, 2.443431473632806],
            [-63.750467916992186, 2.441931473632806],
            [-63.74696791699219, 2.441931473632806],
            [-63.736467916992183, 2.446131473632803],
            [-63.733967916992185, 2.453331473632801],
            [-63.731067916992188, 2.4550314736328],
            [-63.72896791699219, 2.452231473632807],
            [-63.72696791699218, 2.448931473632804],
            [-63.718367916992186, 2.444931473632806],
            [-63.712067916992183, 2.448531473632805],
            [-63.71156791699218, 2.454331473632806],
            [-63.708667916992184, 2.453631473632804],
            [-63.705367916992188, 2.455331473632803],
            [-63.703967916992184, 2.457931473632804],
            [-63.697967916992184, 2.457731473632805],
            [-63.694567916992185, 2.457231473632802],
            [-63.691667916992188, 2.457931473632804],
            [-63.690767916992186, 2.4550314736328],
            [-63.68916791699219, 2.449931473632802],
            [-63.688767916992184, 2.446731473632802],
            [-63.68586791699218, 2.445331473632805],
            [-63.681667916992183, 2.442531473632805],
            [-63.674967916992188, 2.445331473632805],
            [-63.672267916992183, 2.442831473632801],
            [-63.669267916992183, 2.444531473632807],
            [-63.665967916992187, 2.443731473632802],
            [-63.664567916992183, 2.440331473632803],
            [-63.662067916992186, 2.443131473632803],
            [-63.658867916992186, 2.444131473632801],
            [-63.655467916992187, 2.442731473632804],
            [-63.652567916992183, 2.440631473632806],
            [-63.64936791699219, 2.444531473632807],
            [-63.645967916992184, 2.446731473632802],
            [-63.643667916992186, 2.444931473632806],
            [-63.639667916992181, 2.445531473632805],
            [-63.636867916992188, 2.440631473632806],
            [-63.632967916992186, 2.440931473632801],
            [-63.630267916992182, 2.445131473632806],
            [-63.625867916992185, 2.4475314736328],
            [-63.622967916992181, 2.447831473632803],
            [-63.617467916992183, 2.451131473632806],
            [-63.619367916992189, 2.443931473632801],
            [-63.615367916992184, 2.444131473632801],
            [-63.612467916992188, 2.448531473632805],
            [-63.60696791699219, 2.447331473632801],
            [-63.604267916992185, 2.449231473632807],
            [-63.602767916992185, 2.452731473632802],
            [-63.603567916992183, 2.457231473632802],
            [-63.593667916992189, 2.455731473632802],
            [-63.590567916992185, 2.453131473632801],
            [-63.580767916992187, 2.451731473632805],
            [-63.576767916992182, 2.448531473632805],
            [-63.572067916992182, 2.446731473632802],
            [-63.569367916992185, 2.447831473632803],
            [-63.564467916992186, 2.447131473632801],
            [-63.561967916992188, 2.451431473632802],
            [-63.559067916992184, 2.450931473632807],
            [-63.558567916992189, 2.4475314736328],
            [-63.555067916992186, 2.447131473632801],
            [-63.555567916992189, 2.444131473632801],
            [-63.555767916992181, 2.439131473632806],
            [-63.553867916992189, 2.433131473632805],
            [-63.553367916992187, 2.430331473632805],
            [-63.555567916992189, 2.427531473632804],
            [-63.555767916992181, 2.423531473632806],
            [-63.552467916992185, 2.424731473632804],
            [-63.549167916992189, 2.426531473632807],
            [-63.546067916992186, 2.428731473632801],
            [-63.542867916992186, 2.427531473632804],
            [-63.539567916992183, 2.425731473632801],
            [-63.536267916992188, 2.427131473632805],
            [-63.53376791699219, 2.425331473632802],
            [-63.529567916992185, 2.424031473632802],
            [-63.526567916992185, 2.425431473632806],
            [-63.525067916992185, 2.422731473632801],
            [-63.519367916992181, 2.422731473632801],
            [-63.513267916992184, 2.425331473632802],
            [-63.509967916992181, 2.425931473632801],
            [-63.50766791699219, 2.428331473632802],
            [-63.503567916992182, 2.430431473632801],
            [-63.502667916992181, 2.426531473632807],
            [-63.498767916992186, 2.426831473632802],
            [-63.495867916992182, 2.430331473632805],
            [-63.494767916992188, 2.435731473632806],
            [-63.490867916992187, 2.436531473632805],
            [-63.48796791699219, 2.436931473632804],
            [-63.487467916992188, 2.433731473632804],
            [-63.488667916992185, 2.430931473632803],
            [-63.488367916992189, 2.427331473632805],
            [-63.483667916992189, 2.428931473632801],
            [-63.480367916992186, 2.431931473632801],
            [-63.478167916992184, 2.4276314736328],
            [-63.475067916992188, 2.423731473632806],
            [-63.47096791699218, 2.4231314736328],
            [-63.47096791699218, 2.4201314736328],
            [-63.471767916992185, 2.416831473632804],
            [-63.472067916992188, 2.413331473632802],
            [-63.469467916992187, 2.410731473632801],
            [-63.466967916992189, 2.408031473632803],
            [-63.464467916992184, 2.402331473632806],
            [-63.460367916992183, 2.403931473632802],
            [-63.455367916992188, 2.403931473632802],
            [-63.455067916992185, 2.408531473632806],
            [-63.454967916992189, 2.412731473632803],
            [-63.451267916992187, 2.413831473632804],
            [-63.448467916992186, 2.410231473632805],
            [-63.445767916992182, 2.415531473632804],
            [-63.441667916992188, 2.417731473632806],
            [-63.441067916992182, 2.420931473632805],
            [-63.441967916992184, 2.424531473632804],
            [-63.440167916992181, 2.431731473632802],
            [-63.436367916992182, 2.436131473632805],
            [-63.43446791699219, 2.439231473632802],
            [-63.434667916992183, 2.442531473632805],
            [-63.430767916992181, 2.444531473632807],
            [-63.426867916992187, 2.446931473632802],
            [-63.42246791699219, 2.445131473632806],
            [-63.419667916992182, 2.448731473632805],
            [-63.416667916992182, 2.4535314736328],
            [-63.412867916992184, 2.450731473632807],
            [-63.410667916992182, 2.445931473632804],
            [-63.412467916992185, 2.443131473632803],
            [-63.40976791699218, 2.4398314736328],
            [-63.405467916992187, 2.440531473632802],
            [-63.406267916992185, 2.435731473632806],
            [-63.404267916992183, 2.431231473632806],
            [-63.400367916992181, 2.429731473632806],
            [-63.397067916992185, 2.428931473632801],
            [-63.392867916992188, 2.428531473632802],
            [-63.389267916992182, 2.426831473632802],
            [-63.388167916992188, 2.430931473632803],
            [-63.383467916992188, 2.429831473632802],
            [-63.382167916992188, 2.426731473632806],
            [-63.378767916992189, 2.426731473632806],
            [-63.372967916992181, 2.426331473632807],
            [-63.371567916992184, 2.422731473632801],
            [-63.37196791699219, 2.418731473632803],
            [-63.370067916992184, 2.414531473632806],
            [-63.36766791699219, 2.416031473632806],
            [-63.36466791699219, 2.416731473632801],
            [-63.366067916992186, 2.412731473632803],
            [-63.366867916992184, 2.407731473632801],
            [-63.366667916992185, 2.403331473632804],
            [-63.366667916992185, 2.399931473632805],
            [-63.364967916992185, 2.3972314736328],
            [-63.365367916992184, 2.393631473632801],
            [-63.363867916992184, 2.390031473632803],
            [-63.358867916992182, 2.388631473632806],
            [-63.355667916992189, 2.387131473632806],
            [-63.35526791699219, 2.382331473632803],
            [-63.35696791699219, 2.378931473632804],
            [-63.35996791699219, 2.375631473632801],
            [-63.362167916992185, 2.372631473632801],
            [-63.366167916992183, 2.368131473632801],
            [-63.369367916992189, 2.362331473632807],
            [-63.37336791699218, 2.361131473632803],
            [-63.375767916992189, 2.357531473632804],
            [-63.37636791699218, 2.352131473632802],
            [-63.376067916992184, 2.348131473632805],
            [-63.374867916992187, 2.344331473632806],
            [-63.376267916992184, 2.340831473632804],
            [-63.372967916992181, 2.338031473632803],
            [-63.368367916992185, 2.335831473632801],
            [-63.366867916992184, 2.331331473632801],
            [-63.361367916992187, 2.3285314736328],
            [-63.361467916992183, 2.324731473632802],
            [-63.36296791699219, 2.318131473632803],
            [-63.366367916992189, 2.316131473632801],
            [-63.366467916992185, 2.312931473632801],
            [-63.361967916992185, 2.304831473632802],
            [-63.361367916992187, 2.300931473632801],
            [-63.363867916992184, 2.297531473632802],
            [-63.365767916992183, 2.294131473632802],
            [-63.367967916992185, 2.289631473632802],
            [-63.365367916992184, 2.286331473632806],
            [-63.36466791699219, 2.281531473632803],
            [-63.361767916992186, 2.278031473632801],
            [-63.361067916992184, 2.272931473632802],
            [-63.360367916992189, 2.269331473632803],
            [-63.364167916992187, 2.266331473632803],
            [-63.366167916992183, 2.260931473632802],
            [-63.367967916992185, 2.255331473632801],
            [-63.367967916992185, 2.251331473632803],
            [-63.369067916992186, 2.248131473632803],
            [-63.368267916992181, 2.243131473632801],
            [-63.370767916992186, 2.241231473632801],
            [-63.374067916992189, 2.239931473632801],
            [-63.37666791699219, 2.236131473632803],
            [-63.378467916992186, 2.231831473632802],
            [-63.380567916992185, 2.228231473632803],
            [-63.378067916992187, 2.226331473632804],
            [-63.376567916992187, 2.223831473632806],
            [-63.374967916992183, 2.218531473632801],
            [-63.374367916992185, 2.215131473632802],
            [-63.371867916992187, 2.212131473632802],
            [-63.386867916992188, 2.202131473632804],
            [-63.395167916992186, 2.199731473632802],
            [-63.395167916992186, 2.188431473632804],
            [-63.388767916992187, 2.183131473632805],
            [-63.390767916992189, 2.172531473632802],
            [-63.394367916992181, 2.165731473632803],
            [-63.393767916992189, 2.156931473632802],
            [-63.397667916992184, 2.146931473632804],
            [-63.405867916992186, 2.144331473632803],
            [-63.414167916992184, 2.137831473632801],
            [-63.421067916992186, 2.131331473632805],
            [-63.427567916992189, 2.135331473632803],
            [-63.434067916992184, 2.126331473632803],
            [-63.444067916992182, 2.125331473632805],
            [-63.451267916992187, 2.128431473632801],
            [-63.460167916992184, 2.136431473632804],
            [-63.46656791699219, 2.141131473632804],
            [-63.47426791699219, 2.137831473632801],
            [-63.475567916992183, 2.126731473632802],
            [-63.485367916992189, 2.121231473632804],
            [-63.492467916992183, 2.115431473632803],
            [-63.500467916992186, 2.115431473632803],
            [-63.50936791699219, 2.113731473632804],
            [-63.514367916992185, 2.121131473632801],
            [-63.528467916992184, 2.128331473632805],
            [-63.536367916992184, 2.131131473632806],
            [-63.545067916992181, 2.130531473632807],
            [-63.553667916992183, 2.131131473632806],
            [-63.561867916992185, 2.132531473632802],
            [-63.568767916992186, 2.124731473632806],
            [-63.585467916992187, 2.119731473632804],
            [-63.594467916992187, 2.120331473632802],
            [-63.60196791699218, 2.113931473632803],
            [-63.60666791699218, 2.107531473632804],
            [-63.617267916992184, 2.106731473632806],
            [-63.618567916992184, 2.096331473632802],
            [-63.622667916992185, 2.089131473632804],
            [-63.630467916992188, 2.0847314736328],
            [-63.633567916992185, 2.076131473632806],
            [-63.637967916992181, 2.069231473632804],
            [-63.644867916992183, 2.0648314736328],
            [-63.65376791699218, 2.068131473632803],
            [-63.662067916992186, 2.065531473632802],
            [-63.664967916992182, 2.057531473632807],
            [-63.663067916992183, 2.048731473632806],
            [-63.660867916992188, 2.033331473632806],
            [-63.662067916992186, 2.023531473632801],
            [-63.668067916992186, 2.016931473632802],
            [-63.674967916992188, 2.021331473632806],
            [-63.69216791699219, 2.023331473632801],
            [-63.688567916992184, 2.031631473632807],
            [-63.693767916992186, 2.041031473632806],
            [-63.703267916992189, 2.046331473632804],
            [-63.711267916992185, 2.040331473632804],
            [-63.718767916992185, 2.035231473632805],
            [-63.728167916992184, 2.026331473632801],
            [-63.731067916992188, 2.017931473632807],
            [-63.739067916992184, 2.016331473632803],
            [-63.74266791699219, 2.0085314736328],
            [-63.740467916992188, 1.999731473632806],
            [-63.743267916992181, 1.998931473632801],
            [-63.751867916992182, 1.996131473632801],
            [-63.760167916992188, 2.004131473632803],
            [-63.768767916992189, 1.999731473632806],
            [-63.774867916992186, 1.993331473632807],
            [-63.777367916992183, 1.985331473632804],
            [-63.784867916992184, 1.981731473632806],
            [-63.793167916992189, 1.983231473632806],
            [-63.799967916992188, 1.987531473632806],
            [-63.808267916992186, 1.988231473632801],
            [-63.815767916992186, 1.991131473632805],
            [-63.823767916992182, 1.991131473632805],
            [-63.824667916992183, 1.983231473632806],
            [-63.829667916992186, 1.968331473632801],
            [-63.837067916992183, 1.965131473632802],
            [-63.84596791699218, 1.964131473632804],
            [-63.849467916992182, 1.971631473632804],
            [-63.854167916992182, 1.978531473632806],
            [-63.861467916992183, 1.979931473632803],
            [-63.869367916992189, 1.978931473632805],
            [-63.878767916992189, 1.981331473632807],
            [-63.887667916992186, 1.982531473632804],
            [-63.890767916992189, 1.989531473632802],
            [-63.898467916992189, 1.992531473632802],
            [-63.906567916992188, 1.991531473632804],
            [-63.914167916992184, 1.986331473632802],
            [-63.922767916992186, 1.982931473632803],
            [-63.931167916992187, 1.984631473632803],
            [-63.938567916992184, 1.989331473632802],
            [-63.946067916992185, 1.988131473632805],
            [-63.954067916992187, 1.988231473632801],
            [-63.961267916992185, 1.992531473632802],
            [-63.967667916992184, 1.988231473632801],
            [-63.975567916992183, 1.991231473632801],
            [-63.981367916992184, 1.985931473632803],
            [-63.987467916992188, 1.979131473632805],
            [-63.995467916992183, 1.979931473632803],
            [-64.001567916992187, 1.9749314736328],
            [-64.011267916992182, 1.962531473632801],
            [-64.017967916992191, 1.958931473632802],
            [-64.019067916992185, 1.951331473632806],
            [-64.03346791699218, 1.943931473632801],
            [-64.041667916992182, 1.942731473632804],
            [-64.045267916992188, 1.934131473632803],
            [-64.053567916992179, 1.931131473632803],
            [-64.061867916992185, 1.930731473632804],
            [-64.061867916992185, 1.922531473632802],
            [-64.060467916992181, 1.913831473632804],
            [-64.061567916992189, 1.905931473632805],
            [-64.064067916992187, 1.8987314736328],
            [-64.063567916992184, 1.890731473632805],
            [-64.061867916992185, 1.882731473632802],
            [-64.065167916992181, 1.875631473632801],
            [-64.067667916992178, 1.868531473632807],
            [-64.071067916992178, 1.852431473632805],
            [-64.065167916992181, 1.844731473632805],
            [-64.056067916992191, 1.841131473632807],
            [-64.054667916992187, 1.833531473632803],
            [-64.05516791699219, 1.8255314736328],
            [-64.062667916992183, 1.820631473632801],
            [-64.057967916992183, 1.813131473632801],
            [-64.061867916992185, 1.805431473632801],
            [-64.062967916992193, 1.797531473632802],
            [-64.062367916992187, 1.789631473632802],
            [-64.066067916992182, 1.781331473632804],
            [-64.07016791699219, 1.773531473632801],
            [-64.072967916992184, 1.766331473632803],
            [-64.077967916992179, 1.759131473632806],
            [-64.07656791699219, 1.751331473632803],
            [-64.068767916992186, 1.747631473632801],
            [-64.065167916992181, 1.739931473632801],
            [-64.066267916992189, 1.730731473632801],
            [-64.072167916992186, 1.725931473632805],
            [-64.07016791699219, 1.717331473632804],
            [-64.06686791699218, 1.707931473632804],
            [-64.061267916992179, 1.701731473632805],
            [-64.063267916992189, 1.692831473632801],
            [-64.066567916992184, 1.683931473632803],
            [-64.065167916992181, 1.6761314736328],
            [-64.075467916992181, 1.652731473632805],
            [-64.087367916992179, 1.640331473632806],
            [-64.089867916992191, 1.632831473632805],
            [-64.092067916992193, 1.624731473632806],
            [-64.095167916992182, 1.617131473632803],
            [-64.107467916992192, 1.607331473632804],
            [-64.111067916992184, 1.598831473632806],
            [-64.112167916992178, 1.589431473632807],
            [-64.120467916992183, 1.584931473632807],
            [-64.123867916992182, 1.580531473632803],
            [-64.126067916992184, 1.577731473632802],
            [-64.135467916992184, 1.576931473632804],
            [-64.144067916992185, 1.582531473632805],
            [-64.152967916992182, 1.578631473632804],
            [-64.159867916992184, 1.574531473632803],
            [-64.16776791699219, 1.576131473632806],
            [-64.17386791699218, 1.571131473632803],
            [-64.174967916992188, 1.563131473632801],
            [-64.180767916992181, 1.556931473632801],
            [-64.18716791699218, 1.551831473632802],
            [-64.193767916992186, 1.542731473632806],
            [-64.19486791699218, 1.534131473632804],
            [-64.19486791699218, 1.524931473632805],
            [-64.202367916992188, 1.518331473632806],
            [-64.206067916992183, 1.515931473632804],
            [-64.209067916992183, 1.513931473632802],
            [-64.215467916992182, 1.5085314736328],
            [-64.223367916992188, 1.507031473632807],
            [-64.231067916992188, 1.509531473632805],
            [-64.240067916992189, 1.511331473632801],
            [-64.247467916992179, 1.508331473632801],
            [-64.254067916992184, 1.503931473632804],
            [-64.261567916992192, 1.499831473632803],
            [-64.265167916992183, 1.492131473632803],
            [-64.267367916992185, 1.484631473632803],
            [-64.27576791699218, 1.485431473632801],
            [-64.27876791699218, 1.478531473632806],
            [-64.28816791699218, 1.476331473632804],
            [-64.30216791699219, 1.468331473632801],
            [-64.304367916992192, 1.460531473632805],
            [-64.312967916992193, 1.456531473632801],
            [-64.312967916992193, 1.447831473632803],
            [-64.317967916992188, 1.440131473632803],
            [-64.326067916992187, 1.436131473632805],
            [-64.327167916992181, 1.427931473632803],
            [-64.329067916992187, 1.419331473632802],
            [-64.32486791699219, 1.4109314736328],
            [-64.326067916992187, 1.401931473632807],
            [-64.329667916992179, 1.394931473632802],
            [-64.337367916992179, 1.391431473632807],
            [-64.342667916992184, 1.384231473632802],
            [-64.341967916992189, 1.375531473632805],
            [-64.335167916992191, 1.370931473632801],
            [-64.337967916992184, 1.363531473632804],
            [-64.345867916992191, 1.366131473632805],
            [-64.352767916992178, 1.374831473632803],
            [-64.356767916992183, 1.381331473632805],
            [-64.359367916992184, 1.389931473632807],
            [-64.377367916992185, 1.386431473632804],
            [-64.385167916992188, 1.389331473632801],
            [-64.393467916992179, 1.388531473632803],
            [-64.399867916992179, 1.394931473632802],
            [-64.398267916992182, 1.402731473632805],
            [-64.395167916992179, 1.409731473632803],
            [-64.393467916992179, 1.417731473632806],
            [-64.397367916992181, 1.424531473632804],
            [-64.391267916992177, 1.429731473632806],
            [-64.388467916992184, 1.436931473632804],
            [-64.383767916992184, 1.443331473632803],
            [-64.377367916992185, 1.448331473632805],
            [-64.37466791699218, 1.456531473632801],
            [-64.378767916992189, 1.463731473632805],
            [-64.373567916992187, 1.471131473632802],
            [-64.364967916992185, 1.478231473632803],
            [-64.357167916992182, 1.483931473632801],
            [-64.351667916992184, 1.491731473632804],
            [-64.348867916992191, 1.502031473632805],
            [-64.351367916992189, 1.518331473632806],
            [-64.358867916992182, 1.520531473632801],
            [-64.367967916992185, 1.520031473632805],
            [-64.374867916992187, 1.523931473632807],
            [-64.383567916992178, 1.520531473632801],
            [-64.397367916992181, 1.526931473632807],
            [-64.402267916992187, 1.519731473632802],
            [-64.409167916992189, 1.513931473632802],
            [-64.410867916992188, 1.506131473632806],
            [-64.414967916992182, 1.498431473632806],
            [-64.421867916992184, 1.493531473632807],
            [-64.424667916992178, 1.485331473632804],
            [-64.430767916992181, 1.477431473632805],
            [-64.438567916992184, 1.4719314736328],
            [-64.449367916992188, 1.473331473632804],
            [-64.453467916992182, 1.480331473632802],
            [-64.468767916992192, 1.471631473632804],
            [-64.476667916992184, 1.468031473632806],
            [-64.482467916992192, 1.462531473632801],
            [-64.487167916992178, 1.4535314736328],
            [-64.492967916992185, 1.448331473632805],
            [-64.500467916992193, 1.442731473632804],
            [-64.512667916992186, 1.438931473632806],
            [-64.521867916992193, 1.437731473632802],
            [-64.527967916992182, 1.444131473632801],
            [-64.531267916992192, 1.4353314736328],
            [-64.53046791699218, 1.4261314736328],
            [-64.536967916992182, 1.421731473632803],
            [-64.545267916992188, 1.418931473632803],
            [-64.553267916992183, 1.416831473632804],
            [-64.559067916992191, 1.409931473632803],
            [-64.562967916992193, 1.402131473632807],
            [-64.568767916992186, 1.394131473632804],
            [-64.573767916992182, 1.3835314736328],
            [-64.582667916992179, 1.375531473632805],
            [-64.579867916992185, 1.367531473632802],
            [-64.582167916992191, 1.3591314736328],
            [-64.576067916992187, 1.354131473632805],
            [-64.580467916992191, 1.347431473632803],
            [-64.590867916992181, 1.337531473632801],
            [-64.602067916992183, 1.338331473632806],
            [-64.61136791699218, 1.335831473632801],
            [-64.616567916992182, 1.3300314736328],
            [-64.624067916992189, 1.324131473632804],
            [-64.634867916992192, 1.320331473632805],
            [-64.639867916992188, 1.3133314736328],
            [-64.644667916992191, 1.304831473632802],
            [-64.65406791699219, 1.298931473632805],
            [-64.660267916992183, 1.291331473632802],
            [-64.668567916992188, 1.291831473632804],
            [-64.676567916992184, 1.285531473632801],
            [-64.684667916992183, 1.283831473632802],
            [-64.701867916992185, 1.291331473632802],
            [-64.705167916992181, 1.283831473632802],
            [-64.704367916992183, 1.276131473632802],
            [-64.709667916992188, 1.269331473632803],
            [-64.712267916992189, 1.260631473632806],
            [-64.714367916992188, 1.251131473632803],
            [-64.721367916992179, 1.247331473632805],
            [-64.722367916992184, 1.238531473632804],
            [-64.729167916992182, 1.234631473632803],
            [-64.738567916992181, 1.231731473632806],
            [-64.746567916992191, 1.225531473632806],
            [-64.75736791699218, 1.226331473632804],
            [-64.76376791699218, 1.230931473632801],
            [-64.772667916992191, 1.247531473632804],
            [-64.78046791699218, 1.258331473632801],
            [-64.78806791699219, 1.264131473632801],
            [-64.793567916992188, 1.269331473632803],
            [-64.791367916992186, 1.277531473632806],
            [-64.789167916992184, 1.285731473632801],
            [-64.803267916992183, 1.310531473632807],
            [-64.822967916992184, 1.278531473632803],
            [-64.824967916992179, 1.2951314736328],
            [-64.83656791699218, 1.286331473632806],
            [-64.845867916992191, 1.282731473632801],
            [-64.852767916992178, 1.275831473632806],
            [-64.861467916992183, 1.269331473632803],
            [-64.852767916992178, 1.263531473632803],
            [-64.857467916992192, 1.257031473632807],
            [-64.863867916992177, 1.247531473632804],
            [-64.86866791699218, 1.241131473632805],
            [-64.868567916992191, 1.231831473632802],
            [-64.878567916992182, 1.228531473632806],
            [-64.886567916992192, 1.231831473632802],
            [-64.887967916992181, 1.239531473632802],
            [-64.891067916992185, 1.248331473632803],
            [-64.898267916992182, 1.252731473632807],
            [-64.907267916992183, 1.251331473632803],
            [-64.906567916992188, 1.241731473632804],
            [-64.912067916992186, 1.236131473632803],
            [-64.91446791699218, 1.228131473632807],
            [-64.918867916992184, 1.221631473632804],
            [-64.926367916992177, 1.214731473632803],
            [-64.935167916992185, 1.211331473632804],
            [-64.94356791699218, 1.2157314736328],
            [-64.945767916992182, 1.225231473632803],
            [-64.95156791699219, 1.231731473632806],
            [-64.960767916992182, 1.230331473632802],
            [-64.96626791699218, 1.224631473632805],
            [-64.96956791699219, 1.217331473632804],
            [-64.96626791699218, 1.206131473632801],
            [-64.961267916992185, 1.198331473632805],
            [-64.960767916992182, 1.189731473632804],
            [-64.970267916992185, 1.172931473632801],
            [-64.978867916992186, 1.163831473632804],
            [-64.991567916992182, 1.150531473632803],
            [-65.006667916992185, 1.128931473632804],
            [-65.016467916992184, 1.124531473632807],
            [-65.022267916992178, 1.114931473632801],
            [-65.035967916992178, 1.116531473632804],
            [-65.04576791699219, 1.116931473632803],
            [-65.054467916992181, 1.115431473632803],
            [-65.061267916992179, 1.111831473632805],
            [-65.065967916992179, 1.119031473632802],
            [-65.064867916992185, 1.128731473632804],
            [-65.06086791699218, 1.137731473632805],
            [-65.066067916992182, 1.144731473632802],
            [-65.071267916992184, 1.153531473632803],
            [-65.078567916992185, 1.147531473632803],
            [-65.088167916992191, 1.146131473632806],
            [-65.095267916992185, 1.151331473632801],
            [-65.103067916992188, 1.156631473632807],
            [-65.110767916992188, 1.150731473632803],
            [-65.119067916992179, 1.146531473632805],
            [-65.122467916992179, 1.1350314736328],
            [-65.129867916992183, 1.130631473632803],
            [-65.13876791699218, 1.127531473632807],
            [-65.146267916992187, 1.125931473632804],
            [-65.155067916992181, 1.125131473632806],
            [-65.151467916992189, 1.116531473632804],
            [-65.150367916992181, 1.107531473632804],
            [-65.151167916992179, 1.098131473632805],
            [-65.154167916992179, 1.088731473632805],
            [-65.152867916992193, 1.079531473632805],
            [-65.153167916992189, 1.068431473632806],
            [-65.153967916992187, 1.056231473632806],
            [-65.158067916992181, 1.046331473632804],
            [-65.158967916992182, 1.033531473632806],
            [-65.166967916992178, 1.027131473632807],
            [-65.16946791699219, 1.018531473632805],
            [-65.166767916992185, 1.011431473632804],
            [-65.157767916992185, 1.008731473632807],
            [-65.147267916992178, 1.009231473632802],
            [-65.150467916992184, 1.001531473632802],
            [-65.154867916992188, 0.986831473632805],
            [-65.161667916992187, 0.978531473632806],
            [-65.164167916992184, 0.969931473632805],
            [-65.16446791699218, 0.961131473632804],
            [-65.164967916992182, 0.9505314736328],
            [-65.171367916992182, 0.940331473632803],
            [-65.177967916992188, 0.932931473632806],
            [-65.182467916992181, 0.922531473632802],
            [-65.188767916992191, 0.917731473632806],
            [-65.197167916992186, 0.9109314736328],
            [-65.207367916992183, 0.906931473632802],
            [-65.205167916992181, 0.917131473632807],
            [-65.208767916992187, 0.928931473632801],
            [-65.217367916992188, 0.927931473632803],
            [-65.22126791699219, 0.9276314736328],
            [-65.22596791699219, 0.927531473632804],
            [-65.233867916992182, 0.925431473632806],
            [-65.240767916992183, 0.921131473632805],
            [-65.24996791699219, 0.921331473632804],
            [-65.256867916992178, 0.925331473632802],
            [-65.263567916992187, 0.919631473632805],
            [-65.269367916992181, 0.926831473632802],
            [-65.26406791699219, 0.934131473632803],
            [-65.269867916992183, 0.943331473632803],
            [-65.277367916992191, 0.9383314736328],
            [-65.286967916992182, 0.940331473632803],
            [-65.294667916992182, 0.9413314736328],
            [-65.302567916992189, 0.942031473632802],
            [-65.314067916992187, 0.933931473632803],
            [-65.32186791699219, 0.928131473632803],
            [-65.329067916992187, 0.931731473632802],
            [-65.331267916992189, 0.923531473632806],
            [-65.333767916992187, 0.916031473632806],
            [-65.338767916992182, 0.908831473632802],
            [-65.339867916992191, 0.901131473632802],
            [-65.343367916992179, 0.893531473632805],
            [-65.349167916992187, 0.887531473632805],
            [-65.356367916992184, 0.884731473632804],
            [-65.354667916992184, 0.880331473632801],
            [-65.35196791699218, 0.873131473632803],
            [-65.358567916992186, 0.868531473632807],
            [-65.366067916992179, 0.865731473632806],
            [-65.373567916992187, 0.864731473632801],
            [-65.376567916992187, 0.857731473632803],
            [-65.376867916992182, 0.849531473632801],
            [-65.384667916992186, 0.849131473632802],
            [-65.391567916992187, 0.844131473632807],
            [-65.394367916992181, 0.836131473632804],
            [-65.397167916992188, 0.829131473632806],
            [-65.397967916992187, 0.821331473632803],
            [-65.405967916992182, 0.819731473632807],
            [-65.41306791699219, 0.815631473632806],
            [-65.406567916992188, 0.805331473632805],
            [-65.399767916992189, 0.797531473632802],
            [-65.396567916992183, 0.790531473632804],
            [-65.400167916992189, 0.781631473632807],
            [-65.394867916992183, 0.774431473632802],
            [-65.399767916992189, 0.767131473632801],
            [-65.395467916992189, 0.758331473632801],
            [-65.397367916992181, 0.749831473632803],
            [-65.413867916992189, 0.755131473632801],
            [-65.415667916992192, 0.747631473632801],
            [-65.410567916992193, 0.739731473632801],
            [-65.421367916992182, 0.732131473632805],
            [-65.421867916992184, 0.723331473632804],
            [-65.423267916992188, 0.715831473632804],
            [-65.423567916992184, 0.707931473632804],
            [-65.429967916992183, 0.703331473632801],
            [-65.438567916992184, 0.700331473632801],
            [-65.442967916992188, 0.6898314736328],
            [-65.452667916992183, 0.685531473632807],
            [-65.46186791699219, 0.684731473632802],
            [-65.47226791699218, 0.682631473632803],
            [-65.48026791699219, 0.686931473632804],
            [-65.48466791699218, 0.678931473632801],
            [-65.494967916992181, 0.678331473632802],
            [-65.500467916992193, 0.672131473632803],
            [-65.512967916992181, 0.670331473632807],
            [-65.522167916992188, 0.670931473632805],
            [-65.53046791699218, 0.668131473632805],
            [-65.53346791699218, 0.659131473632804],
            [-65.540567916992188, 0.648931473632807],
            [-65.54576791699219, 0.655531473632806],
            [-65.553567916992179, 0.658331473632806],
            [-65.557967916992183, 0.669931473632801],
            [-65.564067916992187, 0.675731473632801],
            [-65.56716791699219, 0.685731473632806],
            [-65.571067916992178, 0.692731473632804],
            [-65.572967916992184, 0.701131473632806],
            [-65.57626791699218, 0.711731473632803],
            [-65.584867916992181, 0.714731473632803],
            [-65.59126791699218, 0.721631473632804],
            [-65.583767916992187, 0.736331473632802],
            [-65.575767916992191, 0.744731473632804],
            [-65.575767916992191, 0.756131473632806],
            [-65.573567916992189, 0.764331473632801],
            [-65.564067916992187, 0.770031473632805],
            [-65.55616791699218, 0.777731473632805],
            [-65.548567916992184, 0.774731473632805],
            [-65.54416791699218, 0.783331473632806],
            [-65.539567916992183, 0.796831473632807],
            [-65.540667916992192, 0.805331473632805],
            [-65.530967916992182, 0.805331473632805],
            [-65.52406791699218, 0.824731473632802],
            [-65.527667916992186, 0.833331473632803],
            [-65.521267916992187, 0.840131473632802],
            [-65.514367916992185, 0.837231473632805],
            [-65.508767916992184, 0.843531473632801],
            [-65.500167916992183, 0.842531473632803],
            [-65.503567916992182, 0.852431473632805],
            [-65.504067916992184, 0.861131473632803],
            [-65.505167916992178, 0.868531473632807],
            [-65.493567916992191, 0.882031473632807],
            [-65.496067916992189, 0.889331473632801],
            [-65.504167916992188, 0.887731473632805],
            [-65.512167916992183, 0.885331473632803],
            [-65.513867916992183, 0.895531473632801],
            [-65.509167916992183, 0.905131473632807],
            [-65.508267916992182, 0.915531473632804],
            [-65.521867916992193, 0.929531473632807],
            [-65.52446791699218, 0.937531473632802],
            [-65.530867916992179, 0.942731473632804],
            [-65.536367916992191, 0.948131473632806],
            [-65.530867916992179, 0.956131473632801],
            [-65.537067916992186, 0.961131473632804],
            [-65.541767916992185, 0.975531473632806],
            [-65.549667916992178, 0.975531473632806],
            [-65.557967916992183, 0.975931473632805],
            [-65.561367916992182, 0.985931473632803],
            [-65.557767916992191, 0.993331473632807],
            [-65.56716791699219, 0.994331473632805],
            [-65.572167916992186, 0.985131473632805],
            [-65.581267916992189, 1.000531473632805],
            [-65.585467916992187, 1.008931473632806],
            [-65.591167916992191, 1.0023314736328],
            [-65.598467916992178, 1.005131473632801],
            [-65.60666791699218, 1.004531473632802],
            [-65.610267916992186, 1.014331473632801],
            [-65.617467916992183, 1.009931473632804],
            [-65.627167916992192, 1.009231473632802],
            [-65.626867916992182, 1.001331473632803],
            [-65.62496791699219, 0.989031473632807],
            [-65.637667916992186, 1.000531473632805],
            [-65.644867916992183, 1.005331473632801],
            [-65.652667916992186, 1.011931473632806],
            [-65.66306791699219, 1.006931473632804],
            [-65.669267916992183, 1.002531473632807],
            [-65.674667916992178, 0.996931473632806],
            [-65.678567916992179, 0.988931473632803],
            [-65.686567916992189, 0.990931473632806],
            [-65.688567916992184, 0.999731473632806],
            [-65.695467916992186, 1.004131473632803],
            [-65.705767916992187, 1.003131473632806],
            [-65.712267916992189, 0.997531473632804],
            [-65.721667916992189, 1.001731473632802],
            [-65.730667916992189, 1.001331473632803],
            [-65.73936791699218, 0.999731473632806],
            [-65.745767916992179, 0.990731473632806],
            [-65.750767916992189, 0.982531473632804],
            [-65.761067916992189, 0.981031473632804],
            [-65.764867916992188, 0.973131473632805],
            [-65.767167916992179, 0.964131473632804],
            [-65.77406791699218, 0.959131473632802],
            [-65.779867916992188, 0.964131473632804],
            [-65.787767916992181, 0.961931473632802],
            [-65.793867916992184, 0.954131473632806],
            [-65.801567916992184, 0.953631473632804],
            [-65.809067916992191, 0.949131473632804],
            [-65.816267916992189, 0.952131473632804],
            [-65.822167916992186, 0.945531473632805],
            [-65.831567916992185, 0.940131473632803],
            [-65.842267916992185, 0.938431473632804],
            [-65.862267916992181, 0.930931473632803],
            [-65.87966791699219, 0.932631473632803],
            [-65.873767916992179, 0.922131473632803],
            [-65.879167916992188, 0.913131473632802],
            [-65.88136791699219, 0.901631473632804],
            [-65.890167916992183, 0.896131473632806],
            [-65.897167916992188, 0.892231473632805],
            [-65.901167916992179, 0.883731473632807],
            [-65.908367916992191, 0.888931473632802],
            [-65.916967916992178, 0.889331473632801],
            [-65.925367916992187, 0.891331473632803],
            [-65.936567916992189, 0.882031473632807],
            [-65.935767916992191, 0.870731473632802],
            [-65.933267916992179, 0.862531473632806],
            [-65.938567916992184, 0.855931473632801],
            [-65.945967916992188, 0.852731473632801],
            [-65.948467916992186, 0.844331473632806],
            [-65.951767916992182, 0.836331473632804],
            [-65.957667916992179, 0.828931473632807],
            [-65.964767916992187, 0.819931473632806],
            [-65.964367916992188, 0.809531473632802],
            [-65.971367916992179, 0.806131473632803],
            [-65.977767916992178, 0.811931473632804],
            [-65.98326791699219, 0.806231473632806],
            [-65.992967916992185, 0.807131473632801],
            [-66.000567916992182, 0.8026314736328],
            [-66.017167916992179, 0.800131473632803],
            [-66.037867916992184, 0.812031473632807],
            [-66.055867916992185, 0.802331473632805],
            [-66.069967916992184, 0.805131473632805],
            [-66.07456791699218, 0.798731473632806],
            [-66.075767916992191, 0.787331473632804],
            [-66.078267916992189, 0.779131473632802],
            [-66.07626791699218, 0.768331473632806],
            [-66.088467916992187, 0.759131473632806],
            [-66.102067916992183, 0.763631473632806],
            [-66.114467916992183, 0.763531473632803],
            [-66.121067916992189, 0.752031473632805],
            [-66.132167916992188, 0.747931473632804],
            [-66.141267916992177, 0.748131473632803],
            [-66.150967916992187, 0.744531473632804],
            [-66.160967916992178, 0.746231473632804],
            [-66.16316791699218, 0.756131473632806],
            [-66.17246791699219, 0.761331473632801],
            [-66.182667916992187, 0.767231473632805],
            [-66.188067916992182, 0.778731473632803],
            [-66.202167916992181, 0.781331473632804],
            [-66.212867916992181, 0.780731473632805],
            [-66.318467916992191, 0.755131473632801],
            [-66.595367916992188, 0.996231473632804],
            [-66.59926791699219, 0.999731473632806],
            [-66.856767916992183, 1.230331473632802],
            [-67.009867916992192, 1.188431473632804],
            [-67.087967916992184, 1.166831473632804],
            [-67.084767916992178, 1.183331473632805],
            [-67.082867916992186, 1.2035314736328],
            [-67.087267916992189, 1.231731473632806],
            [-67.085967916992189, 1.256131473632806],
            [-67.089767916992187, 1.288131473632802],
            [-67.088767916992182, 1.325031473632805],
            [-67.085367916992183, 1.351031473632801],
            [-67.083267916992185, 1.361831473632805],
            [-67.080367916992188, 1.401331473632801],
            [-67.072367916992192, 1.442731473632804],
            [-67.07316791699219, 1.472931473632805],
            [-67.078567916992185, 1.499831473632803],
            [-67.077967916992179, 1.544531473632802],
            [-67.079067916992187, 1.587231473632805],
            [-67.081467916992182, 1.606531473632806],
            [-67.081467916992182, 1.638331473632803],
            [-67.086267916992185, 1.658031473632803],
            [-67.099467916992182, 1.681931473632801],
            [-67.101367916992189, 1.706331473632801],
            [-67.099567916992186, 1.719331473632806],
            [-67.09726791699218, 1.732731473632803],
            [-67.106367916992184, 1.750631473632801],
            [-67.115567916992191, 1.767731473632807],
            [-67.124067916992189, 1.798931473632805],
            [-67.139667916992181, 1.826731473632805],
            [-67.156967916992187, 1.848831473632806],
            [-67.174267916992193, 1.857131473632805],
            [-67.206267916992189, 1.858231473632806],
            [-67.218067916992183, 1.859931473632805],
            [-67.228867916992186, 1.860931473632803],
            [-67.243867916992187, 1.858231473632806],
            [-67.26066791699219, 1.865431473632803],
            [-67.278067916992185, 1.875631473632801],
            [-67.286667916992187, 1.888631473632806],
            [-67.28946791699218, 1.899431473632802],
            [-67.300067916992191, 1.926731473632806],
            [-67.310467916992181, 1.966631473632802],
            [-67.316667916992188, 2.001331473632803],
            [-67.326067916992187, 2.0297314736328],
            [-67.330467916992191, 2.061231473632802],
            [-67.329867916992185, 2.078331473632801],
            [-67.336767916992187, 2.098131473632805],
            [-67.336467916992177, 2.116731473632804],
            [-67.339067916992178, 2.152131473632807],
            [-67.340467916992182, 2.186731473632804],
            [-67.343467916992182, 2.205531473632803],
            [-67.364467916992183, 2.230331473632802],
            [-67.389267916992182, 2.244031473632802],
            [-67.406467916992185, 2.246231473632804],
            [-67.410267916992183, 2.246731473632806],
            [-67.430867916992185, 2.241731473632804],
            [-67.438567916992184, 2.233231473632806],
            [-67.442467916992186, 2.220131473632804],
            [-67.44816791699219, 2.208131473632804],
            [-67.462367916992179, 2.199131473632804],
            [-67.479567916992181, 2.191131473632801],
            [-67.500567916992182, 2.179031473632804],
            [-67.521867916992193, 2.170331473632807],
            [-67.540067916992186, 2.155931473632805],
            [-67.550867916992189, 2.141731473632802],
            [-67.555767916992181, 2.120931473632801],
            [-67.557967916992183, 2.106031473632804],
            [-67.572767916992191, 2.097931473632805],
            [-67.586267916992185, 2.085531473632805],
            [-67.590167916992186, 2.074531473632803],
            [-67.597667916992179, 2.055931473632803],
            [-67.607067916992179, 2.035531473632801],
            [-67.619767916992188, 2.0237314736328],
            [-67.642967916992191, 2.020331473632801],
            [-67.671667916992192, 2.020331473632801],
            [-67.697067916992182, 2.023531473632801],
            [-67.725067916992188, 2.034131473632804],
            [-67.749867916992187, 2.040131473632805],
            [-67.768967916992182, 2.039331473632807],
            [-67.779467916992189, 2.031131473632804],
            [-67.798167916992185, 1.998131473632803],
            [-67.808567916992189, 1.985331473632804],
            [-67.818167916992181, 1.966931473632805],
            [-67.832067916992187, 1.957231473632802],
            [-67.835467916992187, 1.945531473632805],
            [-67.851467916992192, 1.930331473632805],
            [-67.85966791699218, 1.920331473632807],
            [-67.88266791699219, 1.902931473632805],
            [-67.902367916992191, 1.898531473632801],
            [-67.912867916992184, 1.897931473632802],
            [-67.910367916992186, 1.880631473632803],
            [-67.90676791699218, 1.866131473632805],
            [-67.91176791699219, 1.855931473632801],
            [-67.922567916992179, 1.852331473632802],
            [-67.924667916992178, 1.843531473632801],
            [-67.930767916992181, 1.834931473632807],
            [-67.941267916992189, 1.830731473632802],
            [-67.961267916992185, 1.830531473632803],
            [-67.973467916992178, 1.832931473632804],
            [-67.983667916992189, 1.842331473632804],
            [-68.004067916992184, 1.860331473632804],
            [-68.024867916992179, 1.885531473632803],
            [-68.043367916992182, 1.899731473632805],
            [-68.053667916992183, 1.899431473632802],
            [-68.065267916992184, 1.897931473632802],
            [-68.079367916992183, 1.902531473632806],
            [-68.087567916992185, 1.901331473632801],
            [-68.094067916992188, 1.910231473632805],
            [-68.103067916992188, 1.936231473632802],
            [-68.114467916992183, 1.944231473632804],
            [-68.119767916992188, 1.957731473632805],
            [-68.122467916992179, 1.965831473632804],
            [-68.129467916992184, 1.971631473632804],
            [-68.133867916992187, 1.979531473632804],
            [-68.140667916992186, 1.984931473632805],
            [-68.150967916992187, 1.980931473632801],
            [-68.15846791699218, 1.977731473632801],
            [-68.16616791699218, 1.984631473632803],
            [-68.182967916992183, 1.978931473632805],
            [-68.188267916992189, 1.970931473632803],
            [-68.198867916992185, 1.960831473632801],
            [-68.208667916992184, 1.961731473632803],
            [-68.218667916992189, 1.9535314736328],
            [-68.223167916992182, 1.945631473632801],
            [-68.235367916992189, 1.937931473632801],
            [-68.243267916992181, 1.926831473632802],
            [-68.245767916992179, 1.915531473632804],
            [-68.247367916992189, 1.906331473632804],
            [-68.249167916992178, 1.898531473632801],
            [-68.250467916992193, 1.890031473632803],
            [-68.253267916992186, 1.878731473632804],
            [-68.259567916992182, 1.867631473632805],
            [-68.263567916992187, 1.860731473632804],
            [-68.263567916992187, 1.853131473632807],
            [-68.266567916992187, 1.846131473632802],
            [-68.266867916992183, 1.827231473632807],
            [-68.257067916992185, 1.829331473632806],
            [-68.244167916992183, 1.831331473632801],
            [-68.233667916992189, 1.829931473632804],
            [-68.235367916992189, 1.819131473632801],
            [-68.239067916992184, 1.807131473632801],
            [-68.244767916992188, 1.794931473632801],
            [-68.240567916992191, 1.779331473632801],
            [-68.236467916992183, 1.772531473632803],
            [-68.22866791699218, 1.772731473632803],
            [-68.220367916992188, 1.773331473632801],
            [-68.208967916992179, 1.778331473632804],
            [-68.20256791699218, 1.786331473632806],
            [-68.187667916992183, 1.784731473632803],
            [-68.17686791699218, 1.774131473632806],
            [-68.16946791699219, 1.7707314736328],
            [-68.175867916992189, 1.760931473632802],
            [-68.179167916992185, 1.753731473632804],
            [-68.159467916992185, 1.750131473632806],
            [-68.157367916992186, 1.741931473632803],
            [-68.15676791699218, 1.731731473632806],
            [-68.164967916992182, 1.731731473632806],
            [-68.697167916992186, 1.732131473632805],
            [-69.000767916992189, 1.732131473632805],
            [-69.178567916992179, 1.733531473632802],
            [-69.357467916992192, 1.730331473632802],
            [-69.384867916992192, 1.729631473632807],
            [-69.393167916992184, 1.724131473632802],
            [-69.393467916992179, 1.734631473632803],
            [-69.401267916992182, 1.737931473632806],
            [-69.41316791699218, 1.746531473632807],
            [-69.418167916992189, 1.7523314736328],
            [-69.426067916992181, 1.756931473632804],
            [-69.434067916992191, 1.758131473632801],
            [-69.441967916992184, 1.759731473632804],
            [-69.451067916992187, 1.764331473632801],
            [-69.45756791699219, 1.759231473632802],
            [-69.468667916992189, 1.761931473632806],
            [-69.474467916992182, 1.768631473632801],
            [-69.47866791699218, 1.7600314736328],
            [-69.486867916992182, 1.762131473632806],
            [-69.487467916992188, 1.7722314736328],
            [-69.495167916992187, 1.766931473632802],
            [-69.503267916992186, 1.772131473632804],
            [-69.512867916992178, 1.774431473632802],
            [-69.520167916992179, 1.778931473632802],
            [-69.536967916992182, 1.784931473632803],
            [-69.552267916992179, 1.791731473632801],
            [-69.561367916992182, 1.784331473632804],
            [-69.567367916992183, 1.777331473632806],
            [-69.575167916992186, 1.775831473632806],
            [-69.58256791699219, 1.770331473632801],
            [-69.592267916992185, 1.767731473632807],
            [-69.603067916992188, 1.764331473632801],
            [-69.620867916992182, 1.752531473632807],
            [-69.628867916992192, 1.750631473632801],
            [-69.630667916992181, 1.743331473632807],
            [-69.636467916992189, 1.736831473632805],
            [-69.645667916992181, 1.743531473632807],
            [-69.657567916992178, 1.736831473632805],
            [-69.669567916992179, 1.738531473632804],
            [-69.681867916992189, 1.735731473632804],
            [-69.683067916992186, 1.747031473632802],
            [-69.691567916992184, 1.745531473632802],
            [-69.700767916992191, 1.743931473632806],
            [-69.711167916992181, 1.749531473632807],
            [-69.717067916992193, 1.743731473632806],
            [-69.728067916992188, 1.746931473632806],
            [-69.741667916992185, 1.744531473632804],
            [-69.752167916992192, 1.738931473632803],
            [-69.75766791699219, 1.733531473632802],
            [-69.768967916992182, 1.724331473632802],
            [-69.777867916992193, 1.723531473632804],
            [-69.78506791699219, 1.714931473632802],
            [-69.785667916992182, 1.704931473632804],
            [-69.795067916992181, 1.710131473632806],
            [-69.805767916992181, 1.7097314736328],
            [-69.821767916992187, 1.7050314736328],
            [-69.830367916992188, 1.709431473632804],
            [-69.826767916992182, 1.716331473632806],
            [-69.842567916992181, 1.720931473632803],
            [-69.844467916992187, 1.576931473632804],
            [-69.845567916992181, 1.499731473632806],
            [-69.844867916992186, 1.294131473632802],
            [-69.844467916992187, 1.136331473632801],
            [-69.84426791699218, 1.085531473632805],
            [-69.835367916992183, 1.078331473632801],
            [-69.830167916992181, 1.072131473632801],
            [-69.81216791699218, 1.069231473632804],
            [-69.801967916992183, 1.073731473632805],
            [-69.795267916992188, 1.090231473632805],
            [-69.78476791699218, 1.101531473632804],
            [-69.766067916992185, 1.113231473632801],
            [-69.75466791699219, 1.118731473632806],
            [-69.740067916992189, 1.120731473632802],
            [-69.730667916992189, 1.115931473632806],
            [-69.721767916992178, 1.119831473632807],
            [-69.710467916992187, 1.126531473632802],
            [-69.706467916992182, 1.112531473632806],
            [-69.709267916992189, 1.090231473632805],
            [-69.706167916992186, 1.082231473632802],
            [-69.698267916992179, 1.083631473632806],
            [-69.684667916992183, 1.076431473632802],
            [-69.671467916992185, 1.0877314736328],
            [-69.660567916992193, 1.090831473632804],
            [-69.64936791699219, 1.095531473632803],
            [-69.640767916992189, 1.094931473632805],
            [-69.63106791699218, 1.094931473632805],
            [-69.62166791699218, 1.102431473632805],
            [-69.606767916992183, 1.105331473632802],
            [-69.59456791699219, 1.096531473632801],
            [-69.587567916992185, 1.088531473632806],
            [-69.566367916992192, 1.080731473632802],
            [-69.556367916992187, 1.081731473632807],
            [-69.542867916992179, 1.081331473632801],
            [-69.532267916992183, 1.079731473632805],
            [-69.521267916992187, 1.079731473632805],
            [-69.509967916992181, 1.082231473632802],
            [-69.493367916992185, 1.087931473632807],
            [-69.483667916992189, 1.086731473632803],
            [-69.472767916992183, 1.086531473632803],
            [-69.457067916992187, 1.081931473632807],
            [-69.447967916992184, 1.083631473632806],
            [-69.437367916992187, 1.080731473632802],
            [-69.439367916992182, 1.071731473632802],
            [-69.435467916992181, 1.057631473632803],
            [-69.41646791699219, 1.052531473632804],
            [-69.404267916992183, 1.064131473632806],
            [-69.39506791699219, 1.074931473632802],
            [-69.385967916992186, 1.082731473632805],
            [-69.379567916992187, 1.095231473632801],
            [-69.367567916992186, 1.092731473632803],
            [-69.361167916992187, 1.088031473632803],
            [-69.34896791699218, 1.093731473632801],
            [-69.331167916992186, 1.096331473632802],
            [-69.32156791699218, 1.095731473632803],
            [-69.308267916992179, 1.079131473632806],
            [-69.306567916992179, 1.071331473632803],
            [-69.295567916992184, 1.070331473632805],
            [-69.28976791699219, 1.062531473632802],
            [-69.277567916992183, 1.066931473632806],
            [-69.26536791699219, 1.064231473632802],
            [-69.253467916992179, 1.054831473632802],
            [-69.246067916992189, 1.047731473632801],
            [-69.236867916992182, 1.042131473632807],
            [-69.225367916992184, 1.030231473632803],
            [-69.213667916992179, 1.020831473632803],
            [-69.205767916992187, 1.0100314736328],
            [-69.203567916992185, 0.999731473632806],
            [-69.220567916992181, 0.985131473632805],
            [-69.211767916992187, 0.979931473632803],
            [-69.206067916992183, 0.972331473632806],
            [-69.19486791699218, 0.968531473632801],
            [-69.183867916992185, 0.964431473632807],
            [-69.173567916992184, 0.959931473632807],
            [-69.163367916992186, 0.946131473632803],
            [-69.171467916992185, 0.942331473632805],
            [-69.18586791699218, 0.939231473632802],
            [-69.19056791699218, 0.932631473632803],
            [-69.189567916992189, 0.922531473632802],
            [-69.184667916992183, 0.915531473632804],
            [-69.185167916992185, 0.902931473632805],
            [-69.171767916992181, 0.902731473632805],
            [-69.162767916992181, 0.895831473632803],
            [-69.154467916992189, 0.888131473632804],
            [-69.143967916992182, 0.886331473632801],
            [-69.135767916992179, 0.8773314736328],
            [-69.136467916992189, 0.863731473632804],
            [-69.142167916992179, 0.852331473632802],
            [-69.15406791699219, 0.854331473632804],
            [-69.166067916992191, 0.851931473632803],
            [-69.165267916992178, 0.843031473632806],
            [-69.165867916992184, 0.8285314736328],
            [-69.159867916992184, 0.817531473632805],
            [-69.151467916992189, 0.809831473632805],
            [-69.150967916992187, 0.799931473632803],
            [-69.146867916992193, 0.791531473632801],
            [-69.14506791699219, 0.783031473632803],
            [-69.152367916992191, 0.765331473632806],
            [-69.160967916992178, 0.760331473632803],
            [-69.172867916992189, 0.763131473632804],
            [-69.177767916992181, 0.755631473632803],
            [-69.187267916992184, 0.7493314736328],
            [-69.186267916992179, 0.733231473632806],
            [-69.180267916992193, 0.724531473632801],
            [-69.166367916992186, 0.718831473632804],
            [-69.153667916992191, 0.710131473632806],
            [-69.144367916992181, 0.702531473632803],
            [-69.136567916992192, 0.685731473632806],
            [-69.132067916992185, 0.678731473632801],
            [-69.127467916992188, 0.668231473632801],
            [-69.120267916992191, 0.659531473632804],
            [-69.115067916992189, 0.649931473632805],
            [-69.116867916992192, 0.635531473632803],
            [-69.127967916992191, 0.640331473632806],
            [-69.133567916992178, 0.646331473632806],
            [-69.14506791699219, 0.644931473632802],
            [-69.156667916992191, 0.651331473632801],
            [-69.17076791699219, 0.651631473632804],
            [-69.180767916992181, 0.655931473632805],
            [-69.193167916992181, 0.651531473632801],
            [-69.195767916992182, 0.637331473632806],
            [-69.20156791699219, 0.631531473632805],
            [-69.200467916992181, 0.617331473632802],
            [-69.203567916992185, 0.605331473632802],
            [-69.208167916992181, 0.607531473632804],
            [-69.212867916992181, 0.609631473632803],
            [-69.226167916992182, 0.605731473632801],
            [-69.238867916992177, 0.602431473632805],
            [-69.244067916992179, 0.608731473632801],
            [-69.254967916992186, 0.604531473632804],
            [-69.265767916992189, 0.608231473632806],
            [-69.276167916992179, 0.603131473632807],
            [-69.285367916992186, 0.602131473632802],
            [-69.298267916992188, 0.603531473632806],
            [-69.293967916992187, 0.611131473632803],
            [-69.285967916992178, 0.619731473632804],
            [-69.29246791699218, 0.635331473632803],
            [-69.287567916992188, 0.646531473632805],
            [-69.301667916992187, 0.649331473632806],
            [-69.311567916992189, 0.637531473632805],
            [-69.325167916992186, 0.632831473632805],
            [-69.340567916992185, 0.639331473632801],
            [-69.341667916992179, 0.627531473632807],
            [-69.352067916992183, 0.615331473632807],
            [-69.358967916992185, 0.611831473632805],
            [-69.36866791699218, 0.627531473632807],
            [-69.38236791699218, 0.636431473632804],
            [-69.391067916992185, 0.645831473632803],
            [-69.400367916992181, 0.654931473632807],
            [-69.408967916992182, 0.661031473632804],
            [-69.409867916992184, 0.674731473632804],
            [-69.420267916992188, 0.6853314736328],
            [-69.428067916992191, 0.690931473632801],
            [-69.436967916992188, 0.694231473632804],
            [-69.43746791699219, 0.7097314736328],
            [-69.446067916992178, 0.714331473632804],
            [-69.459367916992193, 0.7157314736328],
            [-69.462567916992185, 0.729131473632805],
            [-69.46926791699218, 0.735331473632804],
            [-69.481367916992184, 0.735731473632804],
            [-69.497967916992181, 0.7279314736328],
            [-69.505967916992191, 0.721331473632802],
            [-69.510267916992191, 0.712531473632801],
            [-69.519367916992181, 0.713531473632806],
            [-69.527867916992193, 0.707731473632805],
            [-69.534867916992184, 0.700031473632805],
            [-69.532567916992178, 0.692331473632805],
            [-69.533967916992182, 0.683731473632804],
            [-69.53946791699218, 0.682131473632801],
            [-69.543367916992182, 0.680931473632803],
            [-69.555867916992185, 0.683131473632805],
            [-69.566367916992192, 0.684131473632803],
            [-69.566667916992188, 0.672531473632802],
            [-69.579867916992185, 0.666531473632801],
            [-69.590967916992184, 0.659131473632804],
            [-69.591167916992191, 0.647931473632802],
            [-69.594767916992183, 0.638931473632802],
            [-69.605667916992189, 0.638931473632802],
            [-69.606767916992183, 0.629231473632807],
            [-69.61866791699218, 0.629531473632802],
            [-69.626867916992182, 0.636431473632804],
            [-69.634567916992182, 0.636431473632804],
            [-69.640167916992183, 0.646731473632805],
            [-69.643967916992182, 0.653531473632803],
            [-69.647967916992187, 0.6609314736328],
            [-69.656267916992192, 0.666031473632806],
            [-69.666667916992182, 0.666831473632804],
            [-69.679767916992191, 0.670731473632806],
            [-69.690767916992186, 0.665531473632804],
            [-69.691667916992188, 0.653531473632803],
            [-69.69956791699218, 0.652931473632805],
            [-69.711167916992181, 0.648031473632805],
            [-69.71796791699218, 0.643131473632806],
            [-69.720067916992178, 0.632831473632805],
            [-69.721667916992189, 0.626131473632803],
            [-69.725267916992181, 0.616931473632803],
            [-69.740067916992189, 0.614931473632801],
            [-69.750167916992183, 0.622031473632802],
            [-69.76406791699219, 0.605531473632801],
            [-69.775067916992185, 0.601731473632803],
            [-69.785967916992178, 0.595731473632803],
            [-69.792067916992181, 0.585831473632801],
            [-69.800067916992191, 0.580731473632802],
            [-69.809367916992187, 0.572331473632801],
            [-69.813067916992182, 0.582931473632804],
            [-69.819667916992188, 0.586131473632804],
            [-69.831567916992185, 0.590131473632802],
            [-69.837667916992189, 0.583531473632803],
            [-69.847867916992186, 0.586931473632802],
            [-69.860367916992189, 0.579931473632804],
            [-69.870167916992187, 0.579931473632804],
            [-69.88736791699219, 0.575031473632805],
            [-69.895367916992186, 0.583131473632804],
            [-69.906667916992191, 0.581331473632801],
            [-69.91476791699219, 0.585331473632806],
            [-69.925567916992179, 0.5648314736328],
            [-69.932467916992181, 0.560131473632801],
            [-69.942367916992183, 0.554331473632807],
            [-69.955667916992184, 0.556731473632802],
            [-69.966167916992191, 0.561231473632802],
            [-69.971367916992179, 0.570931473632804],
            [-69.987267916992181, 0.571331473632803],
            [-69.999067916992189, 0.570331473632805],
            [-69.991367916992189, 0.561131473632805],
            [-69.995167916992187, 0.547731473632801],
            [-70.004167916992188, 0.540931473632803],
            [-70.015967916992182, 0.539331473632807],
            [-70.022967916992187, 0.543531473632804],
            [-70.026467916992189, 0.557931473632806],
            [-70.035567916992193, 0.561531473632805],
            [-70.043567916992188, 0.558931473632803],
            [-70.043967916992187, 0.550431473632806],
            [-70.04446791699219, 0.457231473632802],
            [-70.045667916992187, 0.247531473632804],
            [-70.046367916992182, 0.1091314736328],
            [-70.046367916992182, 0.068331473632803],
            [-70.046467916992185, -0.000468526367193],
            [-70.045867916992179, -0.0098685263672],
            [-70.046367916992182, -0.020968526367199],
            [-70.045067916992181, -0.030868526367193],
            [-70.043667916992177, -0.105268526367198],
            [-70.041367916992186, -0.113868526367199],
            [-70.041667916992182, -0.124068526367196],
            [-70.045667916992187, -0.137068526367194],
            [-70.048667916992187, -0.145668526367196],
            [-70.058067916992186, -0.158368526367198],
            [-70.057967916992183, -0.169268526367198],
            [-70.057267916992188, -0.1866685263672],
            [-70.051967916992183, -0.192968526367196],
            [-70.043567916992188, -0.1973685263672],
            [-70.037567916992188, -0.203968526367198],
            [-70.030167916992184, -0.209068526367197],
            [-70.020167916992179, -0.218168526367194],
            [-70.00606791699218, -0.225368526367198],
            [-69.99696791699219, -0.234668526367194],
            [-69.990267916992181, -0.251668526367197],
            [-69.981367916992184, -0.258568526367199],
            [-69.975367916992184, -0.269768526367194],
            [-69.963767916992182, -0.284868526367198],
            [-69.953567916992185, -0.292168526367199],
            [-69.948267916992179, -0.301468526367195],
            [-69.93716791699218, -0.306168526367195],
            [-69.934067916992191, -0.316568526367199],
            [-69.929367916992192, -0.323468526367193],
            [-69.923067916992181, -0.331468526367196],
            [-69.915567916992188, -0.335968526367196],
            [-69.907567916992178, -0.342368526367196],
            [-69.900167916992189, -0.346368526367193],
            [-69.892467916992189, -0.349168526367194],
            [-69.883467916992188, -0.344268526367195],
            [-69.874367916992185, -0.3467685263672],
            [-69.861967916992185, -0.348168526367196],
            [-69.854267916992185, -0.346368526367193],
            [-69.844867916992186, -0.345868526367198],
            [-69.837867916992181, -0.3542685263672],
            [-69.835967916992189, -0.361668526367197],
            [-69.83226791699218, -0.368368526367199],
            [-69.823467916992186, -0.380968526367198],
            [-69.81386791699218, -0.381268526367194],
            [-69.805867916992185, -0.387368526367197],
            [-69.793967916992187, -0.391568526367195],
            [-69.791967916992178, -0.400168526367196],
            [-69.78346791699218, -0.4015685263672],
            [-69.775367916992181, -0.400168526367196],
            [-69.769267916992192, -0.407368526367193],
            [-69.76206791699218, -0.410668526367196],
            [-69.755467916992188, -0.421068526367193],
            [-69.74966791699218, -0.427168526367197],
            [-69.742467916992183, -0.429768526367198],
            [-69.739167916992187, -0.440468526367198],
            [-69.73326791699219, -0.446268526367199],
            [-69.72596791699219, -0.448568526367197],
            [-69.717667916992184, -0.455068526367199],
            [-69.708967916992179, -0.457568526367197],
            [-69.699067916992192, -0.458768526367194],
            [-69.691067916992182, -0.462368526367193],
            [-69.686267916992179, -0.468968526367199],
            [-69.678267916992183, -0.469768526367197],
            [-69.670367916992191, -0.471668526367196],
            [-69.66316791699218, -0.474268526367197],
            [-69.652967916992182, -0.480568526367193],
            [-69.64506791699219, -0.482868526367199],
            [-69.646167916992184, -0.491568526367196],
            [-69.643767916992189, -0.499468526367195],
            [-69.633267916992182, -0.498368526367194],
            [-69.622367916992189, -0.501668526367197],
            [-69.615467916992188, -0.506268526367194],
            [-69.611067916992184, -0.513468526367198],
            [-69.611967916992185, -0.521768526367197],
            [-69.61466791699219, -0.529068526367197],
            [-69.61466791699219, -0.5402685263672],
            [-69.606367916992184, -0.5449685263672],
            [-69.603467916992187, -0.553668526367197],
            [-69.596167916992187, -0.559468526367198],
            [-69.593467916992182, -0.568568526367194],
            [-69.596467916992182, -0.577368526367195],
            [-69.598167916992182, -0.5890685263672],
            [-69.60496791699218, -0.599968526367199],
            [-69.594767916992183, -0.606368526367199],
            [-69.590467916992182, -0.613268526367193],
            [-69.588167916992191, -0.6226685263672],
            [-69.580067916992192, -0.620168526367195],
            [-69.573267916992179, -0.626068526367199],
            [-69.575167916992186, -0.636768526367199],
            [-69.56416791699219, -0.639668526367196],
            [-69.573767916992182, -0.649768526367197],
            [-69.575767916992191, -0.658368526367198],
            [-69.582067916992187, -0.6667685263672],
            [-69.584767916992178, -0.674768526367195],
            [-69.585167916992191, -0.684368526367194],
            [-69.593667916992189, -0.692768526367196],
            [-69.598467916992178, -0.699668526367198],
            [-69.602367916992179, -0.706868526367195],
            [-69.60526791699219, -0.713968526367196],
            [-69.603867916992186, -0.721668526367196],
            [-69.611967916992185, -0.723668526367199],
            [-69.622667916992185, -0.732068526367193],
            [-69.618067916992189, -0.740868526367194],
            [-69.62636791699218, -0.749668526367195],
            [-69.616567916992182, -0.750468526367193],
            [-69.619767916992188, -0.759168526367198],
            [-69.60996791699219, -0.761568526367199],
            [-69.611967916992185, -0.770168526367193],
            [-69.603067916992188, -0.778768526367195],
            [-69.595067916992178, -0.787068526367193],
            [-69.588367916992183, -0.790868526367198],
            [-69.583767916992187, -0.801568526367198],
            [-69.574267916992184, -0.801068526367196],
            [-69.568767916992186, -0.806868526367197],
            [-69.563067916992182, -0.812268526367198],
            [-69.55946791699219, -0.822868526367195],
            [-69.566267916992189, -0.833268526367199],
            [-69.573567916992189, -0.842268526367199],
            [-69.561567916992189, -0.848668526367199],
            [-69.555067916992186, -0.855268526367198],
            [-69.548067916992181, -0.860868526367199],
            [-69.53846791699219, -0.8619685263672],
            [-69.534567916992188, -0.870468526367198],
            [-69.527867916992193, -0.878268526367194],
            [-69.523167916992179, -0.886268526367196],
            [-69.525867916992183, -0.911968526367197],
            [-69.527367916992191, -0.921868526367199],
            [-69.523467916992189, -0.929668526367195],
            [-69.516467916992184, -0.933568526367196],
            [-69.507967916992186, -0.932968526367198],
            [-69.502167916992192, -0.942468526367193],
            [-69.492967916992185, -0.9488685263672],
            [-69.48496791699219, -0.950668526367195],
            [-69.47866791699218, -0.957268526367194],
            [-69.470867916992191, -0.960468526367194],
            [-69.463467916992187, -0.967268526367199],
            [-69.462667916992189, -0.976468526367199],
            [-69.464067916992178, -0.984168526367199],
            [-69.456267916992189, -0.993268526367196],
            [-69.449967916992179, -0.998268526367198],
            [-69.440967916992179, -0.994668526367199],
            [-69.430267916992193, -0.9976685263672],
            [-69.421467916992185, -1.000468526367193],
            [-69.421067916992186, -1.012168526367198],
            [-69.428367916992187, -1.015668526367193],
            [-69.436567916992189, -1.026868526367195],
            [-69.444067916992182, -1.027868526367193],
            [-69.443467916992191, -1.036668526367194],
            [-69.433367916992182, -1.039768526367197],
            [-69.424267916992193, -1.0494685263672],
            [-69.423967916992183, -1.058868526367199],
            [-69.428367916992187, -1.072968526367198],
            [-69.427467916992185, -1.075768526367199],
            [-69.424967916992188, -1.083568526367195],
            [-69.410867916992188, -1.101968526367195],
            [-69.400867916992183, -1.113868526367199],
            [-69.396767916992189, -1.123368526367194],
            [-69.395667916992181, -1.132668526367198],
            [-69.398367916992186, -1.144368526367195],
            [-69.405867916992179, -1.149468526367194],
            [-69.41316791699218, -1.157668526367196],
            [-69.41446791699218, -1.174168526367197],
            [-69.414167916992184, -1.1866685263672],
            [-69.41916791699218, -1.198768526367196],
            [-69.425767916992186, -1.210668526367193],
            [-69.430267916992193, -1.224568526367193],
            [-69.43116791699218, -1.232868526367199],
            [-69.430767916992181, -1.241868526367199],
            [-69.42546791699219, -1.257168526367195],
            [-69.418567916992188, -1.266768526367194],
            [-69.416967916992178, -1.269768526367194],
            [-69.413867916992189, -1.276168526367194],
            [-69.411367916992191, -1.281268526367199],
            [-69.40846791699218, -1.293168526367197],
            [-69.408367916992191, -1.306868526367197],
            [-69.409567916992188, -1.324668526367198],
            [-69.409467916992185, -1.333768526367194],
            [-69.404567916992193, -1.344468526367194],
            [-69.399867916992179, -1.353868526367194],
            [-69.399867916992179, -1.367568526367194],
            [-69.399867916992179, -1.370768526367193],
            [-69.409867916992184, -1.373368526367194],
            [-69.418167916992189, -1.378068526367194],
            [-69.42246791699219, -1.380668526367195],
            [-69.429167916992185, -1.386868526367195],
            [-69.43446791699219, -1.398268526367197],
            [-69.436967916992188, -1.417468526367195],
            [-69.436567916992189, -1.429768526367198],
            [-69.435167916992185, -1.446268526367199],
            [-69.435267916992188, -1.457868526367193],
            [-69.440967916992179, -1.470968526367194],
            [-69.450267916992189, -1.478968526367197],
            [-69.45756791699219, -1.493368526367199],
            [-69.456467916992182, -1.508568526367199],
            [-69.453567916992185, -1.521768526367197],
            [-69.453267916992189, -1.531268526367199],
            [-69.485567916992181, -1.720568526367195],
            [-69.51676791699218, -1.899768526367197],
            [-69.534467916992185, -2.000468526367193],
            [-69.613367916992189, -2.440168526367195],
            [-69.620867916992182, -2.482868526367199],
            [-69.689467916992186, -2.871568526367199],
            [-69.690267916992184, -2.876268526367198],
            [-69.699267916992184, -2.926968526367197],
            [-69.700367916992178, -2.933068526367194],
            [-69.712267916992189, -3.000568526367196],
            [-69.742467916992183, -3.165868526367198],
            [-69.798667916992187, -3.474268526367197],
            [-69.799367916992182, -3.477868526367196],
            [-69.799967916992188, -3.480668526367197],
            [-69.80046791699219, -3.483668526367197],
            [-69.825367916992178, -3.619768526367196],
            [-69.837567916992185, -3.686568526367196],
            [-69.858567916992186, -3.803568526367194],
            [-69.883467916992188, -3.941968526367198],
            [-69.893967916992182, -4.000568526367196],
            [-69.912267916992192, -4.096668526367196],
            [-69.921367916992182, -4.143968526367196],
            [-69.931567916992179, -4.197868526367195],
            [-69.933267916992179, -4.206868526367195],
            [-69.935167916992185, -4.216468526367194],
            [-69.943467916992191, -4.216568526367197],
            [-69.946267916992184, -4.2202685263672],
            [-69.951067916992187, -4.227368526367194],
            [-69.952067916992178, -4.233868526367196],
            [-69.952667916992183, -4.238568526367196],
            [-69.952967916992179, -4.243068526367196],
            [-69.953267916992189, -4.246568526367199],
            [-69.953767916992177, -4.253368526367197],
            [-69.954667916992179, -4.266268526367199],
            [-69.95756791699219, -4.284168526367196],
            [-69.963767916992182, -4.300568526367194],
            [-69.968667916992189, -4.3101685263672],
            [-69.975267916992181, -4.320468526367193],
            [-69.980867916992182, -4.327368526367195],
            [-69.985267916992186, -4.328268526367197],
            [-69.98966791699219, -4.329368526367197],
            [-70.00166791699219, -4.332168526367198],
            [-70.011367916992185, -4.336568526367195],
            [-70.019067916992185, -4.345568526367195],
            [-70.025867916992183, -4.349868526367196],
            [-70.032767916992185, -4.355068526367198],
            [-70.039567916992183, -4.353368526367198],
            [-70.043667916992177, -4.352268526367197],
            [-70.04446791699219, -4.342868526367198],
            [-70.050067916992191, -4.3345685263672],
            [-70.063467916992181, -4.3298685263672],
            [-70.072767916992191, -4.329368526367197],
            [-70.07926791699218, -4.324868526367197],
            [-70.081267916992189, -4.308368526367197],
            [-70.071367916992187, -4.296868526367199],
            [-70.081567916992185, -4.291968526367199],
            [-70.09756791699219, -4.2964685263672],
            [-70.103167916992192, -4.288468526367197],
            [-70.10366791699218, -4.276168526367194],
            [-70.107767916992188, -4.2643685263672],
            [-70.113567916992181, -4.271168526367198],
            [-70.112767916992183, -4.283168526367199],
            [-70.119467916992193, -4.2902685263672],
            [-70.128767916992189, -4.293668526367199],
            [-70.136867916992188, -4.287068526367193],
            [-70.144267916992192, -4.2827685263672],
            [-70.151767916992185, -4.280168526367198],
            [-70.155067916992181, -4.282268526367197],
            [-70.158167916992184, -4.284468526367199],
            [-70.161367916992191, -4.2964685263672],
            [-70.16746791699218, -4.305868526367199],
            [-70.168067916992186, -4.3146685263672],
            [-70.15976791699218, -4.319868526367195],
            [-70.152267916992187, -4.325668526367195],
            [-70.152367916992191, -4.335468526367194],
            [-70.160567916992193, -4.339768526367195],
            [-70.170067916992181, -4.340868526367196],
            [-70.178267916992183, -4.347068526367195],
            [-70.180467916992185, -4.357868526367199],
            [-70.186867916992185, -4.364768526367193],
            [-70.190167916992181, -4.363268526367193],
            [-70.19516791699219, -4.363668526367199],
            [-70.19816791699219, -4.366068526367194],
            [-70.205667916992184, -4.361068526367198],
            [-70.206467916992182, -4.353068526367196],
            [-70.206767916992192, -4.348968526367194],
            [-70.208967916992179, -4.339468526367199],
            [-70.208967916992179, -4.328168526367193],
            [-70.212667916992189, -4.323568526367197],
            [-70.216467916992187, -4.319068526367197],
            [-70.222367916992184, -4.324268526367199],
            [-70.234167916992192, -4.323168526367198],
            [-70.244067916992179, -4.319868526367195],
            [-70.255467916992188, -4.318468526367198],
            [-70.26676791699218, -4.311968526367195],
            [-70.272667916992191, -4.301068526367196],
            [-70.26536791699219, -4.297468526367197],
            [-70.257067916992185, -4.296068526367193],
            [-70.250267916992186, -4.287068526367193],
            [-70.255567916992192, -4.277668526367194],
            [-70.262867916992178, -4.281768526367195],
            [-70.272667916992191, -4.288468526367197],
            [-70.279467916992189, -4.292468526367195],
            [-70.286667916992187, -4.295268526367195],
            [-70.298267916992188, -4.290668526367199],
            [-70.289567916992183, -4.282268526367197],
            [-70.29576791699219, -4.275868526367198],
            [-70.307167916992185, -4.269368526367195],
            [-70.315967916992179, -4.259568526367197],
            [-70.312767916992186, -4.250268526367194],
            [-70.301367916992177, -4.244768526367196],
            [-70.290367916992182, -4.241168526367197],
            [-70.284867916992184, -4.233868526367196],
            [-70.290667916992192, -4.213468526367194],
            [-70.295067916992181, -4.200468526367196],
            [-70.292567916992184, -4.184768526367193],
            [-70.292267916992188, -4.176468526367195],
            [-70.29886791699218, -4.166068526367198],
            [-70.31246791699219, -4.162768526367195],
            [-70.31886791699219, -4.156768526367195],
            [-70.325967916992184, -4.146268526367194],
            [-70.331767916992192, -4.156268526367199],
            [-70.329667916992179, -4.168068526367193],
            [-70.33256791699219, -4.178368526367194],
            [-70.340867916992181, -4.186868526367199],
            [-70.351367916992189, -4.180768526367196],
            [-70.361167916992187, -4.169468526367197],
            [-70.366567916992182, -4.160968526367199],
            [-70.369167916992183, -4.152568526367197],
            [-70.375767916992189, -4.141768526367194],
            [-70.38706791699218, -4.137668526367193],
            [-70.396267916992187, -4.142168526367193],
            [-70.41916791699218, -4.140368526367197],
            [-70.430567916992189, -4.134968526367196],
            [-70.439467916992186, -4.134068526367194],
            [-70.446667916992183, -4.141868526367197],
            [-70.44056791699218, -4.150368526367195],
            [-70.437367916992187, -4.158468526367194],
            [-70.44356791699218, -4.1652685263672],
            [-70.452167916992181, -4.162768526367195],
            [-70.460667916992179, -4.162768526367195],
            [-70.463467916992187, -4.173068526367196],
            [-70.473367916992188, -4.177268526367193],
            [-70.48026791699219, -4.171968526367195],
            [-70.487567916992191, -4.161768526367197],
            [-70.495167916992187, -4.166368526367194],
            [-70.500467916992193, -4.187668526367197],
            [-70.503467916992179, -4.197068526367197],
            [-70.515167916992183, -4.196268526367199],
            [-70.521467916992179, -4.190768526367194],
            [-70.51536791699219, -4.178868526367197],
            [-70.50936791699219, -4.163468526367197],
            [-70.515767916992189, -4.149768526367197],
            [-70.520767916992185, -4.142668526367196],
            [-70.53506791699219, -4.139268526367196],
            [-70.549267916992193, -4.137168526367198],
            [-70.546167916992189, -4.1500685263672],
            [-70.553867916992189, -4.158068526367195],
            [-70.561567916992189, -4.161468526367194],
            [-70.565267916992184, -4.168968526367195],
            [-70.579667916992179, -4.173068526367196],
            [-70.58656791699218, -4.178868526367197],
            [-70.593367916992179, -4.182668526367195],
            [-70.604667916992184, -4.187768526367194],
            [-70.615067916992189, -4.191368526367199],
            [-70.624867916992187, -4.192768526367196],
            [-70.632767916992179, -4.187668526367197],
            [-70.63876791699218, -4.179668526367195],
            [-70.638867916992183, -4.166168526367194],
            [-70.635467916992184, -4.159468526367199],
            [-70.63136791699219, -4.152868526367193],
            [-70.626067916992184, -4.143168526367198],
            [-70.623867916992182, -4.131768526367196],
            [-70.631567916992182, -4.126668526367197],
            [-70.640667916992186, -4.127668526367195],
            [-70.653467916992184, -4.1271685263672],
            [-70.65546791699218, -4.137368526367197],
            [-70.654767916992185, -4.148668526367196],
            [-70.655667916992186, -4.166968526367199],
            [-70.663367916992186, -4.158668526367194],
            [-70.665667916992192, -4.146568526367197],
            [-70.675267916992183, -4.145068526367197],
            [-70.672767916992186, -4.166968526367199],
            [-70.674967916992188, -4.178368526367194],
            [-70.676167916992185, -4.190168526367195],
            [-70.680867916992185, -4.199668526367198],
            [-70.686667916992178, -4.199568526367194],
            [-70.692667916992178, -4.199068526367199],
            [-70.700967916992184, -4.188368526367199],
            [-70.710067916992188, -4.188768526367198],
            [-70.719867916992186, -4.187968526367193],
            [-70.72996791699218, -4.184368526367194],
            [-70.737167916992178, -4.178668526367197],
            [-70.74836791699218, -4.1652685263672],
            [-70.758867916992187, -4.159868526367198],
            [-70.767867916992188, -4.161168526367199],
            [-70.775867916992183, -4.166668526367197],
            [-70.79146791699219, -4.186068526367194],
            [-70.808067916992186, -4.183368526367197],
            [-70.812767916992186, -4.196368526367195],
            [-70.825667916992188, -4.202968526367194],
            [-70.830167916992181, -4.218768526367199],
            [-70.820267916992179, -4.232168526367197],
            [-70.830467916992191, -4.240068526367196],
            [-70.832867916992186, -4.250168526367197],
            [-70.844067916992188, -4.253868526367199],
            [-70.856367916992184, -4.2476685263672],
            [-70.868567916992191, -4.250568526367196],
            [-70.865867916992187, -4.259968526367196],
            [-70.85966791699218, -4.269368526367195],
            [-70.849867916992181, -4.278668526367198],
            [-70.858667916992189, -4.283668526367194],
            [-70.872667916992185, -4.289168526367199],
            [-70.87936791699218, -4.292568526367198],
            [-70.883467916992188, -4.300768526367193],
            [-70.882967916992186, -4.309068526367199],
            [-70.879967916992186, -4.322668526367195],
            [-70.889567916992178, -4.325468526367196],
            [-70.899867916992179, -4.324568526367194],
            [-70.90876791699219, -4.333168526367196],
            [-70.91306791699219, -4.3452685263672],
            [-70.915567916992188, -4.353968526367197],
            [-70.925067916992191, -4.361868526367196],
            [-70.931867916992189, -4.356468526367195],
            [-70.93716791699218, -4.363668526367199],
            [-70.936867916992185, -4.375168526367197],
            [-70.943067916992192, -4.385968526367193],
            [-70.949267916992184, -4.379468526367198],
            [-70.962567916992185, -4.379068526367199],
            [-70.973067916992193, -4.375568526367196],
            [-70.976467916992192, -4.362868526367194],
            [-70.984767916992183, -4.352368526367194],
            [-70.996867916992187, -4.345168526367196],
            [-70.99996791699219, -4.354468526367199],
            [-70.989467916992183, -4.3619685263672],
            [-70.988367916992189, -4.369668526367199],
            [-70.995767916992179, -4.384568526367197],
            [-71.007467916992184, -4.387668526367193],
            [-71.016267916992177, -4.383768526367199],
            [-71.023967916992177, -4.3848685263672],
            [-71.030867916992179, -4.3970685263672],
            [-71.043167916992189, -4.400968526367194],
            [-71.05046791699219, -4.395668526367196],
            [-71.047767916992186, -4.383768526367199],
            [-71.048067916992181, -4.374968526367198],
            [-71.057167916992185, -4.373268526367198],
            [-71.058567916992189, -4.386568526367199],
            [-71.07956791699219, -4.396268526367194],
            [-71.086167916992181, -4.389968526367198],
            [-71.079667916992179, -4.379868526367197],
            [-71.088667916992179, -4.375568526367196],
            [-71.10056791699219, -4.377368526367199],
            [-71.110867916992191, -4.384168526367198],
            [-71.109367916992184, -4.401268526367197],
            [-71.11766791699219, -4.410568526367193],
            [-71.126867916992182, -4.402068526367195],
            [-71.130967916992191, -4.392868526367195],
            [-71.137867916992178, -4.386568526367199],
            [-71.15376791699218, -4.383068526367197],
            [-71.153367916992181, -4.392068526367197],
            [-71.150367916992181, -4.399868526367193],
            [-71.160267916992183, -4.406168526367196],
            [-71.160567916992193, -4.395068526367197],
            [-71.167167916992184, -4.390468526367194],
            [-71.172867916992189, -4.395668526367196],
            [-71.184767916992186, -4.392668526367196],
            [-71.18916791699219, -4.399068526367195],
            [-71.188767916992191, -4.414568526367198],
            [-71.193467916992191, -4.421368526367196],
            [-71.201367916992183, -4.426068526367196],
            [-71.205367916992188, -4.4137685263672],
            [-71.208967916992179, -4.400368526367195],
            [-71.202167916992181, -4.388968526367194],
            [-71.207067916992187, -4.379868526367197],
            [-71.217067916992193, -4.379968526367193],
            [-71.219767916992183, -4.387368526367197],
            [-71.220667916992184, -4.405568526367198],
            [-71.230567916992186, -4.4077685263672],
            [-71.229167916992182, -4.397368526367195],
            [-71.228867916992186, -4.387668526367193],
            [-71.239067916992184, -4.387068526367194],
            [-71.251267916992191, -4.391268526367199],
            [-71.258767916992184, -4.387968526367196],
            [-71.268967916992182, -4.386268526367196],
            [-71.278067916992185, -4.387968526367196],
            [-71.271567916992183, -4.396868526367193],
            [-71.269867916992183, -4.416068526367198],
            [-71.266567916992187, -4.428668526367197],
            [-71.272367916992181, -4.434468526367198],
            [-71.285967916992178, -4.442968526367196],
            [-71.298267916992188, -4.437468526367198],
            [-71.30186791699218, -4.430568526367196],
            [-71.310267916992188, -4.417768526367198],
            [-71.323967916992189, -4.4214685263672],
            [-71.318167916992181, -4.432968526367198],
            [-71.307467916992181, -4.440268526367198],
            [-71.303267916992183, -4.451768526367196],
            [-71.314567916992189, -4.458468526367199],
            [-71.31386791699218, -4.449068526367199],
            [-71.319967916992184, -4.444568526367199],
            [-71.32756791699218, -4.442768526367196],
            [-71.336167916992181, -4.442168526367198],
            [-71.345367916992188, -4.444668526367195],
            [-71.347067916992188, -4.434368526367194],
            [-71.353867916992186, -4.427968526367195],
            [-71.359467916992187, -4.4336685263672],
            [-71.369967916992181, -4.435768526367198],
            [-71.376267916992191, -4.431168526367195],
            [-71.388567916992187, -4.428068526367198],
            [-71.396267916992187, -4.432668526367195],
            [-71.402267916992187, -4.438068526367196],
            [-71.406267916992192, -4.448168526367198],
            [-71.40976791699218, -4.4518685263672],
            [-71.415667916992192, -4.458468526367199],
            [-71.41776791699219, -4.466168526367198],
            [-71.430767916992181, -4.466668526367194],
            [-71.436867916992185, -4.460468526367194],
            [-71.437367916992187, -4.451268526367194],
            [-71.44986791699219, -4.451768526367196],
            [-71.459867916992181, -4.439368526367197],
            [-71.473167916992182, -4.439568526367196],
            [-71.481367916992184, -4.443068526367199],
            [-71.480367916992179, -4.455068526367199],
            [-71.48936791699218, -4.459868526367195],
            [-71.497967916992181, -4.449568526367194],
            [-71.50936791699219, -4.448268526367194],
            [-71.509567916992182, -4.457268526367194],
            [-71.507167916992188, -4.465468526367196],
            [-71.498767916992179, -4.471668526367196],
            [-71.494667916992185, -4.478568526367198],
            [-71.494767916992188, -4.487468526367195],
            [-71.502667916992181, -4.486868526367196],
            [-71.51036791699218, -4.477868526367196],
            [-71.521567916992183, -4.468668526367196],
            [-71.537467916992185, -4.467268526367199],
            [-71.542167916992184, -4.473868526367198],
            [-71.541767916992185, -4.484268526367195],
            [-71.554467916992181, -4.481068526367196],
            [-71.564067916992187, -4.496068526367196],
            [-71.569567916992185, -4.507168526367195],
            [-71.582667916992179, -4.520168526367193],
            [-71.598367916992188, -4.531568526367195],
            [-71.60526791699219, -4.533968526367197],
            [-71.613067916992179, -4.533368526367198],
            [-71.614167916992187, -4.530368526367198],
            [-71.616967916992181, -4.528768526367195],
            [-71.619767916992188, -4.527568526367197],
            [-71.623267916992191, -4.527868526367193],
            [-71.625967916992181, -4.525968526367194],
            [-71.634167916992183, -4.519068526367199],
            [-71.634267916992187, -4.510968526367193],
            [-71.624167916992178, -4.507068526367199],
            [-71.61866791699218, -4.494668526367199],
            [-71.615767916992183, -4.486768526367193],
            [-71.614167916992187, -4.4792685263672],
            [-71.619367916992189, -4.4702685263672],
            [-71.626567916992187, -4.474968526367199],
            [-71.630267916992182, -4.482868526367199],
            [-71.626967916992186, -4.491168526367197],
            [-71.637667916992186, -4.497168526367197],
            [-71.644367916992181, -4.503768526367196],
            [-71.65376791699218, -4.508168526367193],
            [-71.654867916992188, -4.495468526367198],
            [-71.663667916992182, -4.504968526367193],
            [-71.671167916992189, -4.509668526367193],
            [-71.679667916992187, -4.504368526367195],
            [-71.689967916992188, -4.502068526367196],
            [-71.698567916992189, -4.506768526367196],
            [-71.70426791699218, -4.512968526367196],
            [-71.714867916992191, -4.512168526367198],
            [-71.721967916992185, -4.506568526367197],
            [-71.728367916992184, -4.498868526367197],
            [-71.739467916992183, -4.497368526367197],
            [-71.741067916992179, -4.485368526367196],
            [-71.74836791699218, -4.472568526367198],
            [-71.755167916992178, -4.477268526367197],
            [-71.749867916992187, -4.486768526367193],
            [-71.751867916992182, -4.495268526367198],
            [-71.761067916992189, -4.503568526367197],
            [-71.772367916992181, -4.504968526367193],
            [-71.771567916992183, -4.495268526367198],
            [-71.776967916992191, -4.487468526367195],
            [-71.788867916992189, -4.491168526367197],
            [-71.80046791699219, -4.490868526367194],
            [-71.811067916992187, -4.4976685263672],
            [-71.825367916992178, -4.499468526367195],
            [-71.838967916992189, -4.505268526367196],
            [-71.847667916992179, -4.509668526367193],
            [-71.856367916992184, -4.513168526367195],
            [-71.860367916992189, -4.520668526367196],
            [-71.868267916992181, -4.526768526367199],
            [-71.875267916992186, -4.519268526367199],
            [-71.882767916992179, -4.516068526367199],
            [-71.88906791699219, -4.521568526367197],
            [-71.88536791699218, -4.533768526367197],
            [-71.893567916992183, -4.538168526367194],
            [-71.903967916992187, -4.528168526367196],
            [-71.902567916992183, -4.543868526367199],
            [-71.910267916992183, -4.548868526367194],
            [-71.910967916992178, -4.539268526367195],
            [-71.919967916992192, -4.540968526367195],
            [-71.926967916992183, -4.545068526367196],
            [-71.921667916992192, -4.554768526367198],
            [-71.923167916992185, -4.562468526367198],
            [-71.932167916992185, -4.561568526367196],
            [-71.930067916992186, -4.571268526367199],
            [-71.936367916992182, -4.5768685263672],
            [-71.946267916992184, -4.575768526367199],
            [-71.947067916992182, -4.584668526367196],
            [-71.941667916992188, -4.593368526367193],
            [-71.94986791699219, -4.597668526367194],
            [-71.945267916992179, -4.604968526367195],
            [-71.962267916992189, -4.608068526367198],
            [-71.96956791699219, -4.605268526367198],
            [-71.976767916992188, -4.608068526367198],
            [-71.981767916992183, -4.617468526367198],
            [-71.984467916992187, -4.625468526367193],
            [-71.993067916992189, -4.623868526367197],
            [-72.000567916992182, -4.625868526367199],
            [-72.005167916992178, -4.632168526367195],
            [-72.005967916992191, -4.641568526367195],
            [-72.013567916992187, -4.638168526367195],
            [-72.018267916992187, -4.631368526367197],
            [-72.026767916992185, -4.628468526367193],
            [-72.039267916992188, -4.623268526367198],
            [-72.037567916992188, -4.637368526367197],
            [-72.042867916992179, -4.644068526367199],
            [-72.045267916992188, -4.6515685263672],
            [-72.053367916992187, -4.6515685263672],
            [-72.062667916992183, -4.653468526367199],
            [-72.082067916992187, -4.672768526367193],
            [-72.076867916992185, -4.679468526367195],
            [-72.090967916992184, -4.688768526367198],
            [-72.096667916992189, -4.694968526367198],
            [-72.100267916992181, -4.703168526367193],
            [-72.107467916992192, -4.706868526367195],
            [-72.115767916992183, -4.713768526367197],
            [-72.127067916992189, -4.723368526367196],
            [-72.133867916992187, -4.717668526367198],
            [-72.141067916992185, -4.713668526367194],
            [-72.145767916992185, -4.721468526367197],
            [-72.152267916992187, -4.726168526367196],
            [-72.160367916992186, -4.727868526367196],
            [-72.161367916992191, -4.736468526367197],
            [-72.16946791699219, -4.734968526367197],
            [-72.184667916992183, -4.744068526367194],
            [-72.192767916992182, -4.746568526367199],
            [-72.187967916992179, -4.754068526367199],
            [-72.192367916992183, -4.762068526367194],
            [-72.200967916992184, -4.757368526367195],
            [-72.206167916992186, -4.751268526367198],
            [-72.214567916992181, -4.756868526367199],
            [-72.216467916992187, -4.767868526367195],
            [-72.224867916992181, -4.772268526367199],
            [-72.231367916992184, -4.776768526367199],
            [-72.239967916992185, -4.778668526367198],
            [-72.247167916992183, -4.780868526367193],
            [-72.249467916992188, -4.772968526367194],
            [-72.254067916992184, -4.765368526367197],
            [-72.260167916992188, -4.772668526367198],
            [-72.263167916992188, -4.781268526367199],
            [-72.271567916992183, -4.795868526367194],
            [-72.280167916992184, -4.798668526367194],
            [-72.283067916992181, -4.790568526367196],
            [-72.277567916992183, -4.783668526367194],
            [-72.291767916992185, -4.779268526367197],
            [-72.299667916992178, -4.776168526367194],
            [-72.308367916992182, -4.783468526367194],
            [-72.320667916992193, -4.777668526367194],
            [-72.327667916992183, -4.781168526367196],
            [-72.323567916992189, -4.787768526367195],
            [-72.324067916992192, -4.796868526367199],
            [-72.33686791699219, -4.797268526367198],
            [-72.347767916992183, -4.806168526367195],
            [-72.35666791699218, -4.805268526367193],
            [-72.371567916992191, -4.807268526367196],
            [-72.376267916992191, -4.827568526367195],
            [-72.383467916992188, -4.831268526367197],
            [-72.38136791699219, -4.844168526367199],
            [-72.384867916992192, -4.850968526367197],
            [-72.383567916992192, -4.862168526367199],
            [-72.38566791699219, -4.872168526367197],
            [-72.39906791699218, -4.875168526367197],
            [-72.404167916992179, -4.881068526367194],
            [-72.412367916992181, -4.8848685263672],
            [-72.418667916992192, -4.878068526367194],
            [-72.42716791699219, -4.881068526367194],
            [-72.420367916992191, -4.887668526367193],
            [-72.419767916992186, -4.897868526367198],
            [-72.430267916992179, -4.902668526367194],
            [-72.433267916992179, -4.911268526367195],
            [-72.440767916992186, -4.909768526367195],
            [-72.444267916992189, -4.901468526367196],
            [-72.452067916992178, -4.903668526367198],
            [-72.455067916992192, -4.895668526367196],
            [-72.464067916992178, -4.896468526367194],
            [-72.468767916992192, -4.903468526367199],
            [-72.463967916992189, -4.909868526367198],
            [-72.457867916992186, -4.9152685263672],
            [-72.458167916992181, -4.925468526367197],
            [-72.468067916992183, -4.925568526367194],
            [-72.476667916992184, -4.929368526367199],
            [-72.476667916992184, -4.938068526367196],
            [-72.47596791699219, -4.945768526367196],
            [-72.48166791699218, -4.953468526367196],
            [-72.488667916992185, -4.950468526367196],
            [-72.485067916992179, -4.942668526367193],
            [-72.490567916992191, -4.936868526367199],
            [-72.495467916992183, -4.944068526367197],
            [-72.498067916992184, -4.953868526367195],
            [-72.508567916992192, -4.950668526367195],
            [-72.505667916992181, -4.942068526367194],
            [-72.512467916992179, -4.938768526367198],
            [-72.521767916992189, -4.936868526367199],
            [-72.523667916992181, -4.945768526367196],
            [-72.528067916992185, -4.958168526367196],
            [-72.539167916992184, -4.961568526367195],
            [-72.547567916992179, -4.958468526367199],
            [-72.553867916992189, -4.963268526367195],
            [-72.557967916992183, -4.970168526367196],
            [-72.564867916992185, -4.974568526367193],
            [-72.569567916992185, -4.981468526367195],
            [-72.577367916992188, -4.985268526367193],
            [-72.586467916992191, -4.985268526367193],
            [-72.592567916992181, -4.991568526367196],
            [-72.60666791699218, -4.995568526367194],
            [-72.60226791699219, -5.002768526367198],
            [-72.603067916992188, -5.010968526367193],
            [-72.609467916992187, -5.016568526367195],
            [-72.611067916992184, -5.037068526367193],
            [-72.622267916992186, -5.045368526367199],
            [-72.629467916992184, -5.051968526367197],
            [-72.639667916992181, -5.058068526367194],
            [-72.641467916992184, -5.050068526367198],
            [-72.648667916992181, -5.054168526367199],
            [-72.652567916992183, -5.063768526367198],
            [-72.667867916992179, -5.055168526367197],
            [-72.673167916992185, -5.054968526367198],
            [-72.678367916992187, -5.054768526367198],
            [-72.692067916992187, -5.064568526367196],
            [-72.699967916992179, -5.059368526367194],
            [-72.70756791699219, -5.052868526367199],
            [-72.712367916992179, -5.058868526367199],
            [-72.720867916992191, -5.059068526367199],
            [-72.72566791699218, -5.065468526367198],
            [-72.734267916992181, -5.064068526367194],
            [-72.735767916992188, -5.072168526367193],
            [-72.73026791699219, -5.077968526367194],
            [-72.736867916992182, -5.082668526367193],
            [-72.739167916992187, -5.091468526367194],
            [-72.753567916992182, -5.082668526367193],
            [-72.757967916992186, -5.090668526367196],
            [-72.760267916992191, -5.098068526367193],
            [-72.766067916992185, -5.103668526367194],
            [-72.77406791699218, -5.098868526367198],
            [-72.780367916992191, -5.103368526367198],
            [-72.782767916992185, -5.112468526367195],
            [-72.790967916992187, -5.112568526367198],
            [-72.798567916992184, -5.115068526367196],
            [-72.814567916992189, -5.109668526367194],
            [-72.815767916992186, -5.134568526367197],
            [-72.815967916992179, -5.138468526367198],
            [-72.822167916992186, -5.142068526367197],
            [-72.828267916992189, -5.145468526367196],
            [-72.835467916992187, -5.148368526367193],
            [-72.843467916992182, -5.149868526367193],
            [-72.84756791699219, -5.142568526367199],
            [-72.856167916992192, -5.143468526367194],
            [-72.862167916992178, -5.148368526367193],
            [-72.865067916992189, -5.155668526367194],
            [-72.872967916992181, -5.155968526367197],
            [-72.877167916992192, -5.162468526367199],
            [-72.884967916992181, -5.1667685263672],
            [-72.88436791699219, -5.174668526367199],
            [-72.877367916992185, -5.1774685263672],
            [-72.877067916992189, -5.185568526367199],
            [-72.868867916992187, -5.199868526367197],
            [-72.873767916992179, -5.206568526367199],
            [-72.865767916992183, -5.209768526367199],
            [-72.863667916992185, -5.2170685263672],
            [-72.865067916992189, -5.225368526367198],
            [-72.866967916992181, -5.232868526367199],
            [-72.863567916992181, -5.239768526367193],
            [-72.87166791699218, -5.253068526367194],
            [-72.869067916992179, -5.266368526367195],
            [-72.864967916992185, -5.272168526367196],
            [-72.865567916992191, -5.276968526367199],
            [-72.866667916992185, -5.280168526367198],
            [-72.868867916992187, -5.289168526367199],
            [-72.875567916992182, -5.293368526367196],
            [-72.870067916992184, -5.299368526367196],
            [-72.879867916992183, -5.310768526367198],
            [-72.888167916992188, -5.313868526367195],
            [-72.886067916992189, -5.322668526367195],
            [-72.89036791699219, -5.330768526367194],
            [-72.898667916992181, -5.336968526367194],
            [-72.898667916992181, -5.346168526367194],
            [-72.903667916992191, -5.353168526367199],
            [-72.909267916992178, -5.366068526367194],
            [-72.906967916992187, -5.375168526367197],
            [-72.914567916992183, -5.377968526367198],
            [-72.920667916992187, -5.382368526367195],
            [-72.920367916992191, -5.390168526367198],
            [-72.923667916992187, -5.397868526367198],
            [-72.929067916992182, -5.405368526367198],
            [-72.929367916992192, -5.413068526367198],
            [-72.93586791699218, -5.418668526367199],
            [-72.93586791699218, -5.426168526367199],
            [-72.940167916992181, -5.434168526367195],
            [-72.942467916992186, -5.441368526367199],
            [-72.950967916992184, -5.444668526367195],
            [-72.948467916992186, -5.451768526367196],
            [-72.951067916992187, -5.459368526367193],
            [-72.956867916992181, -5.465368526367193],
            [-72.959067916992183, -5.482768526367195],
            [-72.957967916992189, -5.491968526367195],
            [-72.963667916992179, -5.498068526367199],
            [-72.962867916992181, -5.506368526367197],
            [-72.959667916992188, -5.514668526367196],
            [-72.960467916992187, -5.523468526367196],
            [-72.956167916992186, -5.532268526367197],
            [-72.953767916992192, -5.540068526367193],
            [-72.957167916992191, -5.556668526367197],
            [-72.95456791699219, -5.561368526367197],
            [-72.951867916992185, -5.566268526367196],
            [-72.958467916992191, -5.570768526367196],
            [-72.955667916992184, -5.577968526367194],
            [-72.950967916992184, -5.584268526367197],
            [-72.958467916992191, -5.588168526367198],
            [-72.96626791699218, -5.593168526367194],
            [-72.96956791699219, -5.601168526367196],
            [-72.973167916992182, -5.608068526367198],
            [-72.974167916992187, -5.615868526367194],
            [-72.974467916992182, -5.624068526367196],
            [-72.967267916992185, -5.629568526367194],
            [-72.967367916992188, -5.637068526367194],
            [-72.965967916992184, -5.645368526367193],
            [-72.961767916992187, -5.6545685263672],
            [-72.965167916992186, -5.661668526367194],
            [-72.971667916992189, -5.657268526367197],
            [-72.978567916992191, -5.660568526367193],
            [-72.986167916992187, -5.6622685263672],
            [-72.990267916992181, -5.669268526367198],
            [-72.988267916992186, -5.673268526367195],
            [-72.98636791699218, -5.678368526367194],
            [-72.987267916992181, -5.681868526367197],
            [-72.988267916992186, -5.685768526367198],
            [-72.98496791699219, -5.694268526367196],
            [-72.99266791699219, -5.695768526367196],
            [-72.992167916992187, -5.703768526367199],
            [-73.003867916992192, -5.708168526367196],
            [-72.998767916992179, -5.714768526367195],
            [-73.002767916992184, -5.721368526367193],
            [-73.010467916992184, -5.724468526367197],
            [-73.021567916992183, -5.731768526367198],
            [-73.020667916992181, -5.739468526367197],
            [-73.026967916992191, -5.745268526367198],
            [-73.03476791699218, -5.750568526367196],
            [-73.04246791699218, -5.749068526367196],
            [-73.04746791699219, -5.756068526367194],
            [-73.049667916992178, -5.765968526367196],
            [-73.055867916992185, -5.771768526367197],
            [-73.053667916992183, -5.780568526367198],
            [-73.055467916992185, -5.789168526367199],
            [-73.064067916992187, -5.789268526367195],
            [-73.07926791699218, -5.805868526367199],
            [-73.095567916992181, -5.813268526367196],
            [-73.093467916992182, -5.821668526367198],
            [-73.101367916992189, -5.821768526367194],
            [-73.110267916992186, -5.824268526367199],
            [-73.116967916992181, -5.828568526367199],
            [-73.117467916992183, -5.837668526367196],
            [-73.116367916992189, -5.845868526367198],
            [-73.11466791699219, -5.853668526367194],
            [-73.126567916992187, -5.855068526367198],
            [-73.137467916992179, -5.858568526367193],
            [-73.143167916992184, -5.863568526367196],
            [-73.150967916992187, -5.863368526367196],
            [-73.156967916992187, -5.869368526367197],
            [-73.160967916992178, -5.876568526367194],
            [-73.155867916992179, -5.882468526367198],
            [-73.169967916992192, -5.892668526367196],
            [-73.169967916992192, -5.900668526367198],
            [-73.168167916992189, -5.9092685263672],
            [-73.174167916992189, -5.915568526367196],
            [-73.173667916992187, -5.923868526367194],
            [-73.181567916992179, -5.922868526367196],
            [-73.182967916992183, -5.932568526367199],
            [-73.187767916992186, -5.938768526367198],
            [-73.185767916992191, -5.946868526367197],
            [-73.186567916992189, -5.955668526367198],
            [-73.181667916992183, -5.962668526367196],
            [-73.184067916992191, -5.9702685263672],
            [-73.194067916992182, -5.984268526367195],
            [-73.19216791699219, -5.991668526367199],
            [-73.189067916992187, -5.9991685263672],
            [-73.20256791699218, -6.005268526367196],
            [-73.210167916992191, -6.004968526367193],
            [-73.216167916992191, -6.011768526367199],
            [-73.217867916992191, -6.019768526367194],
            [-73.224767916992192, -6.024268526367194],
            [-73.22996791699218, -6.030668526367194],
            [-73.237467916992188, -6.032068526367198],
            [-73.237767916992183, -6.044768526367193],
            [-73.23466791699218, -6.055168526367197],
            [-73.233867916992182, -6.063868526367195],
            [-73.23166791699218, -6.073468526367193],
            [-73.23966791699219, -6.079568526367197],
            [-73.235067916992179, -6.085768526367197],
            [-73.241067916992179, -6.091768526367197],
            [-73.245467916992183, -6.099468526367197],
            [-73.250767916992189, -6.104968526367195],
            [-73.250167916992183, -6.112468526367195],
            [-73.252667916992181, -6.129868526367197],
            [-73.249367916992185, -6.137068526367194],
            [-73.250167916992183, -6.144868526367198],
            [-73.245467916992183, -6.152068526367195],
            [-73.240567916992191, -6.158168526367199],
            [-73.232567916992181, -6.163368526367194],
            [-73.226667916992184, -6.171368526367196],
            [-73.220567916992181, -6.178568526367194],
            [-73.218167916992186, -6.1866685263672],
            [-73.216967916992189, -6.203268526367197],
            [-73.220367916992188, -6.212268526367197],
            [-73.219167916992191, -6.221668526367196],
            [-73.209067916992183, -6.226468526367199],
            [-73.204867916992185, -6.233268526367198],
            [-73.197667916992188, -6.239668526367197],
            [-73.196267916992184, -6.247168526367197],
            [-73.192767916992182, -6.256268526367194],
            [-73.185167916992185, -6.257068526367199],
            [-73.178367916992187, -6.262368526367197],
            [-73.172867916992189, -6.268668526367193],
            [-73.165367916992182, -6.274068526367195],
            [-73.161967916992182, -6.283068526367195],
            [-73.166067916992191, -6.290568526367196],
            [-73.16146791699218, -6.298968526367197],
            [-73.160367916992186, -6.307968526367198],
            [-73.160967916992178, -6.317968526367196],
            [-73.158167916992184, -6.327168526367196],
            [-73.150967916992187, -6.321668526367198],
            [-73.148167916992179, -6.3298685263672],
            [-73.146767916992189, -6.339468526367199],
            [-73.147167916992188, -6.348368526367196],
            [-73.145467916992189, -6.356168526367199],
            [-73.139267916992182, -6.363068526367194],
            [-73.137767916992189, -6.371868526367194],
            [-73.133867916992187, -6.378868526367199],
            [-73.133467916992188, -6.387368526367197],
            [-73.125267916992186, -6.389668526367196],
            [-73.116967916992181, -6.394868526367198],
            [-73.112467916992188, -6.400868526367198],
            [-73.108867916992182, -6.410168526367194],
            [-73.110867916992191, -6.4199685263672],
            [-73.119367916992189, -6.423868526367194],
            [-73.122467916992179, -6.431868526367197],
            [-73.118067916992189, -6.439868526367199],
            [-73.117767916992179, -6.449868526367197],
            [-73.123567916992187, -6.454668526367193],
            [-73.123767916992179, -6.463268526367195],
            [-73.132167916992188, -6.468168526367194],
            [-73.13406791699218, -6.477268526367197],
            [-73.139267916992182, -6.483868526367196],
            [-73.14176791699218, -6.491168526367197],
            [-73.138467916992184, -6.497968526367195],
            [-73.142567916992192, -6.506268526367194],
            [-73.14906791699218, -6.511068526367197],
            [-73.153367916992181, -6.520068526367197],
            [-73.159267916992178, -6.524768526367197],
            [-73.166767916992185, -6.525968526367194],
            [-73.172567916992179, -6.518168526367198],
            [-73.18416791699218, -6.527568526367197],
            [-73.182467916992181, -6.539968526367197],
            [-73.190167916992181, -6.545268526367195],
            [-73.19816791699219, -6.544468526367197],
            [-73.19816791699219, -6.5539685263672],
            [-73.205167916992181, -6.556868526367197],
            [-73.200367916992178, -6.5646685263672],
            [-73.205667916992184, -6.572768526367199],
            [-73.212867916992181, -6.577868526367197],
            [-73.21956791699219, -6.573168526367198],
            [-73.221967916992185, -6.5646685263672],
            [-73.230067916992184, -6.570468526367193],
            [-73.233167916992187, -6.577868526367197],
            [-73.24696791699219, -6.584268526367197],
            [-73.255667916992181, -6.582668526367193],
            [-73.262967916992181, -6.586568526367195],
            [-73.272267916992178, -6.586768526367194],
            [-73.280367916992191, -6.585368526367198],
            [-73.28806791699219, -6.587368526367193],
            [-73.293367916992182, -6.592668526367198],
            [-73.301567916992184, -6.594768526367197],
            [-73.310767916992191, -6.592568526367195],
            [-73.318167916992181, -6.594168526367199],
            [-73.325767916992191, -6.594268526367195],
            [-73.331767916992192, -6.599868526367196],
            [-73.337567916992185, -6.593068526367198],
            [-73.34596791699218, -6.595168526367196],
            [-73.354467916992178, -6.594868526367193],
            [-73.359767916992183, -6.602468526367197],
            [-73.367267916992191, -6.608068526367198],
            [-73.37466791699218, -6.611068526367198],
            [-73.379167916992188, -6.617268526367198],
            [-73.380767916992184, -6.625168526367197],
            [-73.380667916992181, -6.634968526367196],
            [-73.387967916992181, -6.6378685263672],
            [-73.405067916992181, -6.642868526367195],
            [-73.412767916992181, -6.642068526367197],
            [-73.419667916992182, -6.647368526367195],
            [-73.428267916992183, -6.652268526367195],
            [-73.436967916992188, -6.650968526367194],
            [-73.444067916992182, -6.657268526367197],
            [-73.451467916992186, -6.660968526367199],
            [-73.458667916992184, -6.665568526367196],
            [-73.467067916992193, -6.6667685263672],
            [-73.47596791699219, -6.6652685263672],
            [-73.482467916992192, -6.671068526367193],
            [-73.490567916992191, -6.672768526367193],
            [-73.498267916992191, -6.671868526367199],
            [-73.506767916992189, -6.674668526367199],
            [-73.516467916992184, -6.677268526367193],
            [-73.522567916992188, -6.682468526367195],
            [-73.52906791699219, -6.686368526367197],
            [-73.536167916992184, -6.693168526367195],
            [-73.54246791699218, -6.701068526367195],
            [-73.550367916992187, -6.706568526367199],
            [-73.555567916992189, -6.713968526367196],
            [-73.559667916992183, -6.720868526367198],
            [-73.576467916992186, -6.726468526367199],
            [-73.584367916992178, -6.724568526367193],
            [-73.592567916992181, -6.729968526367195],
            [-73.601467916992192, -6.733568526367193],
            [-73.60696791699219, -6.739068526367198],
            [-73.615767916992183, -6.741168526367197],
            [-73.621267916992181, -6.746968526367198],
            [-73.62806791699218, -6.7521685263672],
            [-73.634867916992192, -6.756368526367197],
            [-73.642867916992188, -6.762068526367194],
            [-73.645167916992179, -6.770468526367196],
            [-73.648467916992189, -6.778168526367196],
            [-73.65376791699218, -6.784168526367196],
            [-73.65846791699218, -6.792768526367198],
            [-73.66316791699218, -6.798968526367197],
            [-73.666767916992185, -6.806368526367194],
            [-73.675067916992191, -6.810868526367194],
            [-73.681567916992179, -6.817968526367196],
            [-73.685167916992185, -6.826768526367196],
            [-73.690467916992191, -6.834268526367197],
            [-73.697367916992192, -6.839368526367195],
            [-73.707167916992191, -6.839568526367195],
            [-73.710767916992182, -6.854468526367196],
            [-73.711467916992191, -6.862568526367195],
            [-73.716967916992189, -6.870468526367198],
            [-73.721967916992185, -6.889568526367196],
            [-73.735367916992189, -6.902268526367195],
            [-73.741467916992178, -6.909468526367196],
            [-73.743367916992185, -6.916768526367196],
            [-73.747267916992186, -6.926068526367196],
            [-73.752767916992184, -6.932968526367198],
            [-73.754567916992187, -6.941868526367195],
            [-73.750567916992182, -6.950668526367195],
            [-73.746067916992189, -6.958168526367196],
            [-73.742767916992179, -6.965168526367197],
            [-73.740867916992187, -6.974868526367196],
            [-73.738367916992189, -6.982068526367197],
            [-73.737567916992191, -6.991368526367197],
            [-73.738567916992181, -6.999368526367196],
            [-73.72996791699218, -7.006768526367196],
            [-73.727467916992182, -7.014568526367196],
            [-73.726767916992188, -7.022568526367195],
            [-73.733567916992186, -7.036668526367198],
            [-73.738667916992185, -7.045268526367195],
            [-73.746367916992185, -7.051068526367196],
            [-73.763167916992188, -7.071368526367195],
            [-73.77006791699219, -7.078568526367196],
            [-73.774267916992187, -7.085168526367195],
            [-73.782367916992186, -7.089868526367198],
            [-73.78846791699219, -7.098068526367197],
            [-73.796667916992192, -7.103168526367195],
            [-73.801567916992184, -7.112168526367196],
            [-73.796667916992192, -7.119368526367197],
            [-73.791767916992185, -7.125168526367197],
            [-73.793567916992188, -7.131368526367197],
            [-73.785367916992186, -7.141268526367195],
            [-73.781967916992187, -7.142168526367197],
            [-73.777567916992183, -7.145468526367196],
            [-73.777267916992187, -7.150768526367198],
            [-73.775167916992189, -7.156168526367196],
            [-73.765967916992182, -7.163068526367198],
            [-73.75936791699219, -7.173968526367197],
            [-73.756567916992182, -7.190968526367197],
            [-73.755167916992178, -7.197868526367195],
            [-73.753267916992186, -7.200968526367195],
            [-73.74836791699218, -7.206768526367195],
            [-73.744667916992185, -7.210068526367195],
            [-73.732267916992186, -7.215368526367197],
            [-73.723867916992191, -7.220868526367195],
            [-73.71326791699218, -7.234968526367197],
            [-73.714767916992187, -7.241668526367196],
            [-73.715167916992186, -7.247968526367195],
            [-73.715867916992181, -7.257468526367198],
            [-73.715667916992189, -7.267168526367197],
            [-73.710867916992186, -7.277368526367198],
            [-73.703567916992185, -7.285668526367196],
            [-73.700667916992188, -7.290568526367196],
            [-73.698867916992185, -7.295668526367198],
            [-73.69986791699219, -7.303868526367197],
            [-73.703767916992192, -7.309868526367197],
            [-73.708967916992179, -7.315468526367198],
            [-73.71186791699219, -7.321668526367198],
            [-73.718367916992179, -7.328868526367195],
            [-73.72866791699218, -7.335168526367195],
            [-73.746867916992187, -7.343668526367196],
            [-73.755267916992182, -7.346468526367197],
            [-73.763867916992183, -7.347368526367195],
            [-73.759867916992192, -7.340968526367195],
            [-73.759967916992181, -7.333768526367198],
            [-73.765767916992189, -7.331468526367196],
            [-73.769567916992187, -7.331468526367196],
            [-73.779467916992189, -7.335468526367197],
            [-73.78676791699219, -7.340068526367197],
            [-73.79416791699218, -7.343768526367196],
            [-73.798667916992187, -7.341168526367195],
            [-73.807967916992183, -7.336968526367198],
            [-73.817067916992187, -7.335068526367195],
            [-73.82316791699219, -7.335068526367195],
            [-73.82956791699219, -7.336468526367195],
            [-73.842667916992184, -7.343968526367195],
            [-73.852067916992183, -7.346968526367196],
            [-73.855067916992184, -7.351668526367195],
            [-73.85996791699219, -7.368668526367195],
            [-73.862867916992187, -7.373568526367198],
            [-73.866867916992192, -7.377368526367196],
            [-73.869667916992185, -7.378368526367197],
            [-73.876567916992187, -7.382168526367195],
            [-73.890167916992183, -7.377668526367195],
            [-73.896567916992183, -7.379368526367195],
            [-73.909467916992185, -7.377668526367195],
            [-73.91346791699219, -7.374368526367196],
            [-73.918667916992192, -7.365568526367195],
            [-73.930867916992185, -7.351968526367195],
            [-73.935167916992185, -7.351968526367195],
            [-73.942767916992182, -7.361368526367198],
            [-73.951867916992185, -7.346268526367197],
            [-73.958667916992184, -7.345068526367196],
            [-73.963367916992183, -7.347268526367195],
            [-73.966167916992191, -7.351668526367195],
            [-73.967067916992193, -7.359768526367198],
            [-73.962967916992184, -7.364068526367195],
            [-73.96156791699218, -7.370468526367198],
            [-73.958667916992184, -7.377768526367195],
            [-73.948867916992185, -7.394568526367195],
            [-73.941567916992184, -7.409468526367196],
            [-73.930767916992181, -7.423968526367197],
            [-73.927467916992185, -7.451568526367197],
            [-73.924667916992178, -7.460168526367195],
            [-73.921867916992184, -7.463968526367196],
            [-73.919267916992183, -7.465168526367197],
            [-73.929167916992185, -7.477568526367197],
            [-73.932167916992185, -7.486768526367197],
            [-73.932767916992191, -7.494068526367197],
            [-73.941567916992184, -7.513468526367195],
            [-73.943167916992181, -7.519368526367195],
            [-73.948567916992189, -7.526568526367196],
            [-73.959267916992189, -7.528168526367196],
            [-73.968767916992192, -7.531568526367195],
            [-73.97426791699219, -7.532268526367197],
            [-73.98636791699218, -7.530868526367197],
            [-73.990467916992188, -7.535868526367196],
            [-73.989067916992184, -7.543368526367196],
            [-73.987767916992183, -7.554768526367198],
            [-73.98196791699219, -7.566268526367196],
            [-73.972767916992183, -7.565568526367198],
            [-73.968367916992179, -7.567368526367197],
            [-73.958167916992181, -7.576568526367197],
            [-73.950467916992181, -7.585868526367197],
            [-73.942367916992183, -7.598468526367196],
            [-73.937367916992187, -7.601768526367195],
            [-73.932267916992188, -7.607868526367195],
            [-73.922767916992186, -7.612968526367197],
            [-73.911667916992187, -7.621868526367198],
            [-73.90846791699218, -7.624368526367196],
            [-73.895667916992181, -7.626968526367197],
            [-73.888267916992191, -7.631268526367197],
            [-73.886267916992182, -7.634568526367197],
            [-73.886067916992189, -7.650368526367195],
            [-73.884967916992181, -7.655168526367195],
            [-73.879067916992184, -7.661968526367197],
            [-73.872967916992181, -7.671068526367197],
            [-73.857167916992182, -7.677568526367196],
            [-73.854167916992182, -7.677268526367197],
            [-73.849467916992182, -7.674768526367195],
            [-73.845267916992185, -7.675068526367195],
            [-73.842067916992193, -7.677568526367196],
            [-73.838967916992189, -7.688268526367196],
            [-73.832367916992183, -7.696268526367195],
            [-73.825667916992188, -7.712568526367196],
            [-73.82156791699218, -7.717368526367196],
            [-73.814567916992189, -7.721168526367197],
            [-73.809067916992191, -7.722568526367198],
            [-73.785967916992178, -7.722368526367195],
            [-73.759667916992186, -7.730068526367198],
            [-73.755267916992182, -7.731968526367197],
            [-73.740767916992183, -7.733568526367197],
            [-73.732067916992179, -7.742168526367195],
            [-73.722067916992188, -7.748068526367195],
            [-73.713167916992191, -7.755468526367196],
            [-73.705367916992188, -7.758268526367196],
            [-73.696367916992187, -7.763168526367195],
            [-73.693167916992181, -7.771468526367197],
            [-73.683767916992181, -7.775968526367198],
            [-73.681867916992189, -7.789568526367198],
            [-73.681867916992189, -7.796068526367197],
            [-73.683267916992179, -7.805568526367196],
            [-73.686867916992185, -7.820568526367197],
            [-73.686367916992182, -7.828768526367195],
            [-73.683867916992185, -7.836768526367198],
            [-73.683367916992182, -7.842868526367198],
            [-73.686967916992188, -7.853968526367197],
            [-73.69386791699219, -7.859668526367198],
            [-73.697967916992184, -7.866868526367195],
            [-73.703967916992184, -7.872668526367196],
            [-73.712967916992184, -7.875468526367197],
            [-73.741967916992181, -7.872668526367196],
            [-73.744667916992185, -7.871368526367196],
            [-73.747267916992186, -7.867268526367198],
            [-73.75736791699218, -7.857568526367196],
            [-73.761067916992189, -7.857568526367196],
            [-73.767167916992179, -7.860868526367195],
            [-73.766067916992185, -7.870168526367195],
            [-73.761267916992182, -7.879068526367195],
            [-73.752767916992184, -7.890668526367197],
            [-73.757967916992186, -7.894668526367198],
            [-73.770767916992185, -7.897568526367195],
            [-73.771867916992178, -7.901968526367195],
            [-73.770667916992181, -7.904868526367196],
            [-73.758867916992187, -7.915268526367196],
            [-73.750867916992178, -7.923368526367195],
            [-73.746367916992185, -7.935168526367196],
            [-73.747167916992183, -7.947068526367197],
            [-73.745867916992182, -7.953568526367196],
            [-73.743367916992185, -7.956168526367197],
            [-73.736867916992182, -7.958568526367195],
            [-73.734267916992181, -7.961268526367196],
            [-73.73196791699219, -7.966968526367197],
            [-73.72866791699218, -7.968968526367195],
            [-73.724167916992187, -7.965068526367197],
            [-73.722767916992183, -7.960068526367195],
            [-73.717367916992188, -7.959668526367196],
            [-73.710867916992186, -7.960668526367197],
            [-73.703567916992185, -7.959868526367195],
            [-73.697467916992181, -7.960168526367195],
            [-73.692467916992186, -7.963768526367197],
            [-73.688567916992184, -7.971368526367197],
            [-73.685567916992184, -7.983268526367198],
            [-73.681967916992193, -7.992268526367198],
            [-73.678667916992183, -8.003368526367197],
            [-73.675867916992189, -8.007768526367197],
            [-73.663367916992186, -8.008168526367196],
            [-73.657867916992188, -8.006868526367196],
            [-73.64946791699218, -8.002468526367196],
            [-73.645767916992185, -8.002968526367198],
            [-73.639867916992188, -8.007668526367198],
            [-73.634667916992186, -8.014068526367197],
            [-73.626867916992182, -8.021468526367197],
            [-73.623767916992179, -8.028368526367196],
            [-73.62336791699218, -8.039268526367195],
            [-73.626567916992187, -8.046868526367195],
            [-73.630567916992192, -8.053268526367198],
            [-73.633467916992188, -8.056668526367197],
            [-73.631567916992182, -8.059968526367197],
            [-73.624167916992178, -8.063268526367196],
            [-73.615367916992184, -8.069868526367195],
            [-73.612167916992178, -8.074568526367198],
            [-73.60696791699219, -8.086468526367195],
            [-73.593767916992192, -8.097368526367195],
            [-73.585367916992183, -8.122768526367196],
            [-73.589067916992178, -8.130168526367196],
            [-73.591167916992191, -8.142168526367197],
            [-73.589867916992191, -8.153468526367195],
            [-73.585767916992182, -8.164168526367195],
            [-73.585367916992183, -8.172768526367197],
            [-73.586467916992191, -8.178068526367195],
            [-73.586467916992191, -8.186368526367197],
            [-73.592567916992181, -8.198168526367198],
            [-73.595267916992185, -8.206568526367196],
            [-73.591767916992183, -8.206868526367195],
            [-73.581567916992185, -8.207868526367196],
            [-73.577667916992183, -8.211268526367196],
            [-73.577067916992178, -8.214468526367195],
            [-73.574367916992188, -8.217868526367198],
            [-73.573567916992189, -8.225568526367198],
            [-73.57156791699218, -8.229768526367195],
            [-73.563767916992191, -8.231468526367195],
            [-73.560167916992185, -8.235268526367197],
            [-73.557167916992185, -8.245868526367197],
            [-73.553267916992183, -8.253768526367196],
            [-73.550567916992179, -8.255668526367195],
            [-73.540267916992178, -8.262668526367197],
            [-73.536367916992191, -8.269868526367198],
            [-73.535967916992178, -8.277268526367195],
            [-73.539567916992183, -8.287568526367195],
            [-73.540067916992186, -8.294968526367196],
            [-73.537267916992192, -8.301468526367195],
            [-73.53176791699218, -8.307768526367198],
            [-73.529567916992193, -8.313268526367196],
            [-73.53476791699218, -8.328468526367196],
            [-73.537767916992181, -8.334068526367197],
            [-73.536367916992191, -8.345868526367195],
            [-73.529267916992183, -8.351368526367196],
            [-73.527267916992187, -8.356168526367195],
            [-73.523267916992182, -8.359368526367195],
            [-73.516067916992185, -8.369668526367196],
            [-73.505267916992182, -8.377968526367198],
            [-73.493867916992187, -8.378468526367197],
            [-73.489767916992179, -8.379168526367195],
            [-73.485367916992189, -8.379168526367195],
            [-73.486067916992184, -8.385768526367197],
            [-73.482767916992188, -8.391068526367196],
            [-73.47226791699218, -8.393168526367198],
            [-73.457967916992189, -8.397968526367197],
            [-73.452967916992179, -8.400968526367198],
            [-73.44686791699219, -8.402868526367197],
            [-73.44356791699218, -8.406768526367195],
            [-73.433367916992182, -8.412068526367197],
            [-73.421467916992185, -8.406968526367198],
            [-73.412267916992192, -8.410568526367197],
            [-73.406667916992191, -8.419168526367198],
            [-73.405667916992186, -8.434068526367195],
            [-73.401567916992178, -8.447368526367196],
            [-73.394367916992181, -8.467268526367196],
            [-73.386567916992192, -8.469568526367198],
            [-73.381767916992189, -8.468168526367197],
            [-73.37666791699219, -8.462268526367197],
            [-73.37336791699218, -8.466668526367197],
            [-73.370167916992187, -8.463968526367196],
            [-73.364967916992185, -8.463368526367198],
            [-73.360267916992186, -8.465168526367197],
            [-73.355367916992179, -8.465668526367196],
            [-73.347067916992188, -8.470568526367195],
            [-73.335367916992183, -8.467068526367196],
            [-73.331267916992189, -8.471768526367196],
            [-73.331767916992192, -8.476668526367195],
            [-73.327667916992183, -8.481468526367195],
            [-73.328567916992185, -8.502468526367196],
            [-73.327967916992179, -8.517068526367197],
            [-73.331767916992192, -8.527568526367197],
            [-73.334067916992183, -8.540068526367197],
            [-73.334367916992178, -8.552468526367196],
            [-73.337367916992179, -8.570968526367196],
            [-73.34126791699218, -8.578568526367196],
            [-73.342867916992191, -8.591768526367197],
            [-73.343467916992182, -8.596468526367197],
            [-73.343767916992192, -8.601668526367195],
            [-73.34126791699218, -8.615768526367198],
            [-73.338167916992191, -8.617768526367197],
            [-73.319567916992185, -8.614068526367195],
            [-73.313767916992191, -8.616068526367197],
            [-73.311567916992189, -8.621668526367195],
            [-73.312267916992184, -8.632068526367195],
            [-73.308867916992185, -8.633168526367196],
            [-73.301967916992183, -8.629968526367197],
            [-73.299667916992178, -8.632168526367195],
            [-73.30186791699218, -8.636468526367196],
            [-73.29746791699219, -8.650868526367198],
            [-73.290267916992178, -8.661668526367198],
            [-73.28476791699218, -8.666368526367197],
            [-73.275667916992191, -8.669268526367198],
            [-73.264267916992182, -8.677568526367196],
            [-73.257167916992188, -8.678868526367197],
            [-73.252967916992191, -8.682768526367198],
            [-73.247767916992188, -8.687268526367195],
            [-73.237767916992183, -8.686568526367196],
            [-73.23166791699218, -8.684668526367197],
            [-73.221767916992178, -8.679768526367198],
            [-73.21626791699218, -8.678868526367197],
            [-73.208267916992185, -8.680868526367195],
            [-73.19956791699218, -8.685868526367198],
            [-73.194667916992188, -8.686968526367195],
            [-73.176067916992181, -8.694268526367196],
            [-73.165567916992188, -8.699868526367197],
            [-73.155367916992191, -8.707668526367197],
            [-73.149567916992183, -8.714268526367196],
            [-73.142367916992185, -8.722668526367197],
            [-73.140467916992179, -8.727068526367198],
            [-73.13906791699219, -8.734968526367197],
            [-73.137467916992179, -8.742968526367196],
            [-73.137667916992186, -8.751268526367195],
            [-73.138167916992188, -8.759568526367197],
            [-73.129067916992184, -8.770468526367196],
            [-73.123067916992184, -8.774568526367197],
            [-73.119767916992188, -8.778468526367195],
            [-73.113667916992185, -8.786768526367197],
            [-73.111467916992183, -8.791768526367196],
            [-73.111767916992179, -8.815468526367198],
            [-73.109467916992187, -8.821568526367198],
            [-73.099967916992185, -8.832568526367197],
            [-73.094167916992191, -8.837268526367197],
            [-73.080667916992184, -8.840868526367196],
            [-73.077967916992179, -8.842668526367195],
            [-73.077367916992188, -8.860868526367195],
            [-73.071267916992184, -8.869768526367196],
            [-73.068267916992184, -8.877668526367195],
            [-73.06416791699219, -8.893468526367197],
            [-73.05616791699218, -8.906968526367198],
            [-73.048167916992185, -8.913668526367196],
            [-73.042267916992188, -8.915268526367196],
            [-73.035167916992179, -8.914468526367195],
            [-73.02406791699218, -8.913468526367197],
            [-73.008867916992187, -8.912768526367195],
            [-72.999367916992185, -8.916768526367196],
            [-72.994667916992185, -8.920268526367195],
            [-72.992967916992185, -8.924468526367196],
            [-72.987767916992183, -8.932468526367195],
            [-72.98466791699218, -8.942468526367197],
            [-72.978867916992186, -8.953868526367195],
            [-72.97426791699219, -8.967868526367198],
            [-72.970567916992181, -8.974768526367196],
            [-72.966167916992191, -8.979168526367197],
            [-72.959567916992185, -8.981668526367198],
            [-72.944367916992178, -8.983968526367196],
            [-72.936867916992185, -8.988268526367197],
            [-72.93916791699219, -8.996868526367198],
            [-72.948467916992186, -9.005668526367195],
            [-72.952967916992179, -9.007468526367198],
            [-72.956867916992181, -9.010668526367198],
            [-72.955167916992181, -9.016068526367196],
            [-72.948267916992179, -9.025468526367195],
            [-72.945967916992188, -9.034768526367195],
            [-72.945267916992179, -9.046768526367195],
            [-72.941667916992188, -9.062768526367197],
            [-72.940967916992179, -9.065968526367197],
            [-72.940167916992181, -9.068868526367197],
            [-72.945767916992182, -9.075668526367195],
            [-72.945267916992179, -9.087068526367197],
            [-72.94386791699219, -9.091968526367197],
            [-72.943767916992186, -9.102068526367198],
            [-72.945567916992189, -9.107268526367196],
            [-72.946367916992187, -9.113268526367197],
            [-72.95286791699219, -9.128768526367196],
            [-72.964267916992185, -9.146868526367196],
            [-72.971667916992189, -9.148168526367197],
            [-72.98026791699219, -9.155068526367195],
            [-72.986067916992184, -9.159868526367195],
            [-73.001567916992187, -9.164468526367195],
            [-73.008567916992192, -9.167768526367198],
            [-73.013567916992187, -9.171968526367195],
            [-73.016267916992192, -9.176568526367195],
            [-73.016067916992185, -9.182768526367198],
            [-73.011067916992189, -9.187468526367198],
            [-72.994367916992189, -9.191068526367197],
            [-72.994967916992181, -9.194268526367196],
            [-73.001267916992191, -9.199568526367198],
            [-73.003467916992179, -9.203468526367196],
            [-73.00736791699218, -9.206468526367196],
            [-73.019067916992185, -9.218968526367195],
            [-73.021867916992178, -9.225868526367197],
            [-73.025967916992187, -9.229168526367197],
            [-73.039867916992179, -9.236168526367198],
            [-73.044967916992192, -9.236868526367196],
            [-73.053367916992187, -9.226168526367196],
            [-73.05616791699218, -9.225268526367195],
            [-73.061067916992187, -9.227068526367198],
            [-73.065467916992191, -9.230568526367197],
            [-73.079367916992183, -9.238268526367197],
            [-73.08656791699218, -9.244468526367196],
            [-73.091967916992189, -9.261568526367196],
            [-73.095067916992178, -9.266068526367196],
            [-73.108067916992184, -9.275368526367195],
            [-73.11136791699218, -9.279468526367197],
            [-73.110867916992191, -9.283168526367195],
            [-73.104267916992185, -9.295268526367195],
            [-73.10096791699219, -9.298968526367197],
            [-73.101667916992184, -9.304968526367198],
            [-73.107167916992182, -9.307468526367195],
            [-73.119467916992193, -9.309468526367198],
            [-73.127967916992191, -9.312468526367198],
            [-73.139267916992182, -9.321768526367197],
            [-73.147367916992181, -9.333268526367196],
            [-73.156667916992191, -9.350868526367197],
            [-73.163667916992182, -9.354168526367197],
            [-73.186867916992185, -9.360568526367196],
            [-73.190467916992191, -9.366868526367195],
            [-73.191667916992188, -9.386568526367196],
            [-73.193467916992191, -9.392868526367195],
            [-73.198567916992189, -9.397868526367198],
            [-73.210367916992183, -9.406468526367195],
            [-73.21186791699219, -9.411668526367198],
            [-73.207667916992179, -9.411668526367198],
            [-72.983667916992189, -9.411668526367198],
            [-72.980067916992184, -9.411668526367198],
            [-72.97596791699219, -9.411668526367198],
            [-72.819567916992185, -9.411668526367198],
            [-72.804367916992192, -9.411668526367198],
            [-72.79886791699218, -9.411668526367198],
            [-72.735767916992188, -9.411668526367198],
            [-72.720567916992181, -9.411668526367198],
            [-72.715867916992181, -9.412068526367197],
            [-72.715867916992181, -9.415368526367196],
            [-72.717667916992184, -9.418668526367195],
            [-72.716167916992191, -9.421668526367196],
            [-72.712567916992185, -9.424668526367196],
            [-72.710167916992191, -9.429168526367196],
            [-72.706567916992185, -9.429968526367198],
            [-72.70256791699218, -9.431568526367197],
            [-72.69956791699218, -9.428068526367195],
            [-72.697767916992191, -9.431168526367195],
            [-72.698467916992186, -9.434468526367198],
            [-72.69486791699218, -9.433568526367196],
            [-72.695267916992179, -9.437668526367197],
            [-72.692467916992186, -9.435868526367198],
            [-72.687967916992179, -9.433868526367196],
            [-72.683567916992189, -9.432168526367196],
            [-72.680067916992186, -9.435868526367198],
            [-72.676167916992185, -9.438068526367196],
            [-72.67246791699219, -9.436168526367197],
            [-72.668567916992188, -9.437968526367197],
            [-72.668867916992184, -9.441668526367195],
            [-72.665867916992184, -9.441268526367196],
            [-72.661367916992191, -9.446068526367196],
            [-72.65676791699218, -9.446368526367195],
            [-72.657267916992183, -9.449568526367198],
            [-72.654167916992179, -9.449968526367197],
            [-72.651467916992189, -9.445168526367198],
            [-72.647667916992191, -9.445968526367196],
            [-72.644867916992183, -9.445168526367198],
            [-72.639667916992181, -9.443068526367195],
            [-72.635967916992186, -9.443468526367198],
            [-72.632767916992179, -9.444068526367197],
            [-72.629867916992183, -9.443768526367197],
            [-72.626967916992186, -9.442768526367196],
            [-72.624467916992188, -9.444968526367198],
            [-72.621567916992191, -9.447068526367197],
            [-72.619067916992179, -9.450668526367195],
            [-72.616167916992183, -9.451568526367197],
            [-72.612467916992188, -9.448568526367197],
            [-72.608867916992182, -9.449968526367197],
            [-72.604267916992185, -9.451068526367198],
            [-72.600867916992186, -9.453568526367196],
            [-72.596667916992189, -9.455468526367195],
            [-72.592667916992184, -9.454268526367198],
            [-72.589267916992185, -9.455068526367196],
            [-72.58656791699218, -9.457168526367198],
            [-72.582867916992186, -9.458668526367195],
            [-72.577367916992188, -9.457968526367196],
            [-72.576767916992182, -9.462268526367197],
            [-72.571267916992184, -9.462568526367196],
            [-72.566667916992188, -9.460368526367198],
            [-72.564867916992185, -9.464568526367195],
            [-72.56086791699218, -9.462968526367195],
            [-72.558767916992181, -9.465968526367195],
            [-72.553967916992178, -9.466768526367197],
            [-72.55046791699219, -9.466168526367195],
            [-72.54576791699219, -9.466468526367198],
            [-72.54146791699219, -9.467668526367195],
            [-72.53976791699219, -9.471468526367197],
            [-72.537567916992188, -9.468768526367196],
            [-72.535967916992178, -9.472368526367195],
            [-72.531667916992191, -9.473068526367197],
            [-72.531167916992189, -9.476368526367196],
            [-72.527967916992182, -9.479268526367196],
            [-72.526967916992191, -9.483568526367197],
            [-72.523167916992179, -9.485568526367196],
            [-72.522167916992188, -9.489368526367198],
            [-72.517367916992185, -9.490568526367195],
            [-72.516067916992185, -9.487968526367197],
            [-72.514367916992185, -9.483968526367196],
            [-72.510267916992191, -9.484468526367195],
            [-72.504967916992186, -9.485368526367196],
            [-72.502367916992185, -9.481968526367197],
            [-72.499067916992189, -9.480668526367197],
            [-72.495567916992186, -9.483568526367197],
            [-72.492567916992186, -9.480268526367198],
            [-72.488267916992186, -9.479668526367195],
            [-72.484767916992183, -9.482768526367195],
            [-72.48166791699218, -9.484668526367198],
            [-72.47896791699219, -9.485768526367195],
            [-72.474767916992192, -9.483868526367196],
            [-72.470267916992185, -9.484968526367197],
            [-72.467267916992185, -9.485668526367196],
            [-72.463967916992189, -9.485868526367195],
            [-72.460767916992182, -9.485668526367196],
            [-72.456467916992182, -9.487868526367198],
            [-72.45286791699219, -9.484968526367197],
            [-72.449967916992179, -9.481768526367198],
            [-72.446767916992187, -9.481068526367196],
            [-72.44386791699219, -9.481768526367198],
            [-72.441367916992192, -9.479168526367197],
            [-72.43716791699218, -9.482168526367197],
            [-72.432767916992191, -9.481068526367196],
            [-72.428267916992183, -9.481368526367195],
            [-72.42686791699218, -9.483968526367196],
            [-72.423967916992183, -9.480668526367197],
            [-72.420267916992188, -9.480668526367197],
            [-72.415567916992188, -9.482268526367196],
            [-72.412767916992181, -9.481668526367198],
            [-72.411167916992184, -9.479168526367197],
            [-72.407567916992178, -9.476968526367195],
            [-72.403667916992191, -9.480368526367197],
            [-72.398667916992181, -9.483568526367197],
            [-72.396867916992178, -9.487268526367195],
            [-72.392867916992188, -9.486468526367197],
            [-72.389267916992182, -9.488268526367197],
            [-72.38706791699218, -9.491068526367197],
            [-72.382467916992184, -9.491668526367196],
            [-72.376567916992187, -9.494468526367196],
            [-72.371567916992191, -9.492268526367198],
            [-72.368267916992181, -9.489168526367198],
            [-72.36466791699219, -9.491868526367195],
            [-72.363267916992186, -9.495768526367197],
            [-72.360267916992186, -9.495168526367195],
            [-72.35666791699218, -9.494368526367197],
            [-72.353867916992186, -9.497168526367197],
            [-72.35196791699218, -9.500768526367196],
            [-72.346767916992178, -9.502668526367195],
            [-72.343967916992185, -9.504468526367198],
            [-72.340967916992184, -9.505968526367198],
            [-72.339867916992191, -9.510668526367198],
            [-72.338967916992189, -9.514668526367196],
            [-72.340867916992181, -9.518668526367197],
            [-72.33826791699218, -9.521468526367197],
            [-72.335367916992183, -9.520968526367195],
            [-72.335067916992188, -9.523968526367195],
            [-72.332967916992189, -9.528168526367196],
            [-72.32926791699218, -9.531968526367198],
            [-72.32756791699218, -9.534268526367196],
            [-72.32456791699218, -9.538068526367198],
            [-72.320967916992188, -9.541168526367198],
            [-72.317667916992178, -9.543368526367196],
            [-72.31216791699218, -9.544168526367198],
            [-72.311267916992179, -9.540968526367195],
            [-72.306867916992189, -9.539468526367195],
            [-72.302567916992189, -9.537068526367197],
            [-72.299967916992188, -9.533768526367197],
            [-72.294667916992182, -9.534768526367195],
            [-72.290567916992188, -9.535868526367196],
            [-72.288867916992189, -9.538168526367198],
            [-72.287067916992186, -9.540968526367195],
            [-72.28376791699219, -9.540268526367196],
            [-72.282567916992178, -9.543668526367195],
            [-72.282267916992183, -9.548568526367195],
            [-72.282767916992185, -9.553268526367198],
            [-72.283967916992182, -9.557468526367195],
            [-72.283367916992191, -9.561368526367197],
            [-72.284867916992184, -9.564868526367196],
            [-72.28506791699219, -9.568268526367195],
            [-72.284267916992178, -9.572168526367197],
            [-72.284467916992185, -9.577468526367195],
            [-72.285267916992183, -9.581868526367195],
            [-72.285867916992188, -9.585168526367195],
            [-72.285567916992179, -9.588468526367198],
            [-72.284267916992178, -9.591468526367198],
            [-72.285167916992179, -9.595268526367196],
            [-72.288367916992186, -9.600868526367197],
            [-72.285867916992188, -9.605268526367198],
            [-72.28206791699219, -9.608568526367197],
            [-72.27876791699218, -9.611468526367197],
            [-72.275467916992184, -9.614368526367198],
            [-72.270467916992189, -9.618268526367196],
            [-72.26536791699219, -9.619068526367197],
            [-72.263867916992183, -9.615568526367195],
            [-72.259567916992182, -9.614668526367197],
            [-72.254967916992186, -9.615068526367196],
            [-72.252967916992191, -9.619668526367196],
            [-72.253567916992182, -9.623568526367198],
            [-72.255467916992188, -9.626568526367198],
            [-72.255967916992191, -9.629368526367195],
            [-72.25736791699218, -9.632768526367197],
            [-72.257967916992186, -9.637368526367197],
            [-72.259967916992181, -9.640668526367197],
            [-72.259867916992192, -9.643968526367196],
            [-72.258467916992188, -9.647568526367195],
            [-72.254567916992187, -9.652268526367195],
            [-72.251067916992184, -9.655468526367198],
            [-72.24666791699218, -9.657368526367197],
            [-72.247367916992189, -9.662468526367196],
            [-72.248767916992179, -9.666068526367198],
            [-72.248767916992179, -9.670068526367196],
            [-72.249767916992184, -9.674468526367196],
            [-72.25166791699219, -9.677568526367196],
            [-72.24996791699219, -9.684068526367195],
            [-72.251267916992191, -9.692968526367196],
            [-72.25936791699219, -9.706468526367196],
            [-72.263867916992183, -9.712968526367195],
            [-72.26506791699218, -9.718768526367196],
            [-72.264567916992178, -9.721668526367196],
            [-72.261867916992188, -9.725968526367197],
            [-72.26406791699219, -9.730668526367197],
            [-72.268967916992182, -9.737168526367196],
            [-72.270667916992181, -9.743368526367195],
            [-72.268667916992186, -9.749368526367196],
            [-72.264267916992182, -9.752168526367196],
            [-72.261467916992189, -9.752968526367198],
            [-72.256267916992186, -9.752168526367196],
            [-72.251567916992187, -9.753768526367196],
            [-72.248567916992187, -9.760468526367195],
            [-72.244367916992189, -9.765068526367195],
            [-72.233967916992185, -9.763168526367195],
            [-72.23026791699219, -9.763268526367195],
            [-72.226367916992189, -9.765068526367195],
            [-72.220867916992191, -9.768668526367197],
            [-72.214467916992191, -9.773968526367195],
            [-72.209067916992183, -9.775368526367195],
            [-72.20126791699218, -9.773968526367195],
            [-72.190467916992191, -9.778068526367196],
            [-72.186967916992188, -9.779468526367197],
            [-72.175267916992183, -9.781768526367195],
            [-72.158067916992181, -9.791068526367198],
            [-72.152867916992179, -9.796668526367196],
            [-72.150967916992187, -9.798868526367198],
            [-72.151567916992178, -9.803968526367196],
            [-72.155167916992184, -9.811568526367196],
            [-72.163867916992189, -9.820468526367197],
            [-72.165367916992182, -9.827968526367197],
            [-72.160667916992182, -9.831868526367195],
            [-72.157567916992178, -9.834768526367196],
            [-72.155067916992181, -9.842068526367196],
            [-72.155967916992182, -9.854768526367195],
            [-72.155067916992181, -9.860368526367196],
            [-72.152267916992187, -9.865068526367196],
            [-72.140667916992186, -9.877468526367196],
            [-72.142667916992181, -9.882668526367198],
            [-72.152367916992191, -9.892968526367195],
            [-72.153967916992187, -9.897668526367195],
            [-72.156667916992191, -9.902268526367195],
            [-72.166367916992186, -9.909468526367196],
            [-72.167267916992188, -9.914268526367195],
            [-72.173967916992183, -9.921468526367196],
            [-72.174367916992182, -9.928668526367197],
            [-72.172867916992189, -9.934868526367197],
            [-72.168167916992189, -9.939168526367197],
            [-72.160667916992182, -9.939468526367197],
            [-72.159867916992184, -9.953268526367197],
            [-72.155867916992179, -9.960368526367198],
            [-72.155667916992186, -9.963668526367197],
            [-72.157367916992186, -9.968668526367196],
            [-72.162267916992192, -9.974168526367198],
            [-72.169567916992179, -9.978668526367198],
            [-72.179667916992187, -9.983868526367196],
            [-72.181567916992179, -9.991968526367195],
            [-72.180467916992185, -9.999968526367198],
            [-72.023967916992191, -9.999968526367198],
            [-71.970667916992184, -9.999968526367198],
            [-71.861967916992185, -9.999968526367198],
            [-71.837067916992183, -9.999968526367198],
            [-71.826767916992182, -9.999968526367198],
            [-71.792167916992184, -9.999968526367198],
            [-71.719167916992191, -9.999968526367198],
            [-71.680267916992193, -9.999968526367198],
            [-71.67716791699219, -9.999968526367198],
            [-71.658167916992184, -9.999968526367198],
            [-71.585467916992187, -9.999968526367198],
            [-71.515467916992179, -9.999968526367198],
            [-71.431867916992189, -9.999968526367198],
            [-71.377367916992185, -9.999968526367198],
            [-71.374467916992188, -9.996568526367195],
            [-71.368867916992187, -9.989668526367197],
            [-71.363967916992181, -9.984968526367197],
            [-71.357467916992192, -9.989468526367197],
            [-71.354667916992184, -9.987968526367197],
            [-71.352867916992182, -9.976468526367196],
            [-71.348367916992188, -9.973068526367197],
            [-71.338767916992182, -9.971968526367196],
            [-71.322467916992181, -9.985668526367196],
            [-71.317667916992178, -9.991168526367197],
            [-71.307967916992183, -9.991968526367195],
            [-71.299667916992178, -9.989168526367198],
            [-71.29146791699219, -9.984968526367197],
            [-71.285367916992186, -9.985068526367197],
            [-71.282267916992183, -9.983868526367196],
            [-71.271167916992184, -9.978168526367195],
            [-71.25906791699218, -9.978068526367196],
            [-71.25466791699219, -9.971668526367196],
            [-71.252967916992191, -9.963968526367196],
            [-71.246567916992191, -9.961968526367198],
            [-71.240367916992184, -9.966468526367198],
            [-71.227467916992182, -9.969268526367195],
            [-71.210767916992182, -9.966268526367195],
            [-71.205667916992184, -9.947168526367197],
            [-71.19386791699219, -9.938068526367196],
            [-71.187367916992187, -9.919668526367197],
            [-71.181367916992187, -9.917468526367195],
            [-71.180067916992186, -9.910668526367196],
            [-71.171667916992192, -9.887968526367196],
            [-71.16616791699218, -9.883868526367195],
            [-71.16306791699219, -9.875268526367197],
            [-71.13236791699218, -9.854768526367195],
            [-71.12806791699218, -9.852068526367198],
            [-71.124467916992188, -9.851668526367195],
            [-71.119967916992181, -9.851768526367195],
            [-71.115367916992184, -9.852468526367197],
            [-71.112267916992181, -9.852868526367196],
            [-71.107467916992192, -9.852768526367196],
            [-71.102767916992178, -9.851768526367195],
            [-71.101367916992189, -9.848368526367196],
            [-71.099567916992186, -9.845568526367195],
            [-71.096667916992189, -9.844568526367198],
            [-71.093167916992186, -9.841968526367197],
            [-71.089367916992188, -9.840168526367197],
            [-71.085467916992187, -9.839268526367196],
            [-71.082867916992186, -9.835468526367197],
            [-71.080767916992187, -9.832268526367198],
            [-71.078567916992185, -9.830468526367195],
            [-71.076367916992183, -9.827168526367196],
            [-71.07186791699219, -9.827168526367196],
            [-71.067967916992188, -9.826868526367196],
            [-71.063467916992181, -9.825468526367196],
            [-71.059867916992189, -9.824568526367198],
            [-71.056867916992189, -9.820968526367196],
            [-71.054067916992182, -9.818068526367195],
            [-71.050867916992189, -9.816268526367196],
            [-71.04746791699219, -9.817068526367198],
            [-71.043067916992186, -9.818868526367197],
            [-71.040067916992186, -9.818868526367197],
            [-71.037267916992192, -9.817468526367197],
            [-71.033167916992184, -9.815168526367195],
            [-71.029767916992185, -9.815568526367198],
            [-71.026567916992178, -9.817168526367198],
            [-71.022967916992187, -9.818268526367195],
            [-71.019367916992181, -9.816268526367196],
            [-71.015167916992183, -9.817068526367198],
            [-71.009867916992192, -9.816268526367196],
            [-71.00606791699218, -9.817168526367198],
            [-71.002367916992185, -9.816968526367198],
            [-70.999067916992189, -9.818568526367198],
            [-70.994367916992189, -9.816868526367195],
            [-70.992567916992186, -9.814468526367197],
            [-70.991667916992185, -9.811368526367197],
            [-70.992167916992187, -9.808368526367197],
            [-70.992767916992193, -9.803668526367197],
            [-70.991367916992189, -9.799268526367197],
            [-70.991067916992179, -9.793868526367195],
            [-70.990367916992184, -9.789268526367195],
            [-70.98636791699218, -9.788468526367197],
            [-70.98496791699219, -9.784768526367195],
            [-70.983867916992182, -9.781268526367196],
            [-70.981467916992187, -9.778668526367195],
            [-70.981767916992183, -9.775768526367198],
            [-70.980367916992179, -9.772068526367196],
            [-70.978867916992186, -9.768568526367197],
            [-70.976167916992182, -9.764668526367196],
            [-70.974467916992182, -9.760968526367197],
            [-70.973167916992182, -9.758168526367196],
            [-70.970567916992181, -9.756568526367197],
            [-70.966767916992183, -9.755168526367196],
            [-70.962967916992184, -9.750568526367196],
            [-70.959267916992189, -9.749168526367196],
            [-70.95756791699219, -9.746668526367195],
            [-70.954967916992189, -9.744968526367195],
            [-70.953167916992186, -9.741968526367195],
            [-70.948967916992189, -9.742668526367197],
            [-70.945967916992188, -9.742268526367198],
            [-70.941667916992188, -9.742968526367196],
            [-70.938267916992189, -9.743368526367195],
            [-70.935467916992181, -9.742268526367198],
            [-70.932167916992185, -9.740768526367198],
            [-70.928567916992179, -9.741468526367196],
            [-70.925067916992191, -9.741368526367197],
            [-70.922767916992186, -9.738568526367196],
            [-70.920267916992188, -9.736068526367195],
            [-70.918067916992186, -9.732468526367196],
            [-70.914867916992179, -9.728568526367198],
            [-70.912067916992186, -9.725968526367197],
            [-70.90846791699218, -9.722368526367195],
            [-70.905967916992182, -9.718968526367195],
            [-70.90376791699218, -9.715968526367195],
            [-70.902667916992186, -9.713268526367198],
            [-70.898167916992179, -9.712068526367197],
            [-70.896567916992183, -9.707368526367198],
            [-70.896567916992183, -9.702668526367198],
            [-70.893167916992184, -9.700668526367195],
            [-70.89176791699218, -9.697168526367197],
            [-70.89006791699218, -9.693568526367198],
            [-70.888567916992187, -9.691068526367197],
            [-70.886767916992184, -9.688768526367195],
            [-70.88266791699219, -9.688868526367198],
            [-70.880567916992192, -9.685468526367195],
            [-70.880167916992178, -9.680868526367195],
            [-70.87666791699219, -9.678668526367197],
            [-70.875467916992193, -9.675068526367195],
            [-70.871867916992187, -9.673668526367198],
            [-70.869167916992183, -9.669468526367197],
            [-70.86866791699218, -9.664968526367197],
            [-70.864467916992183, -9.663468526367197],
            [-70.861167916992187, -9.660868526367196],
            [-70.858067916992184, -9.659768526367195],
            [-70.855667916992189, -9.656768526367195],
            [-70.853467916992187, -9.654868526367196],
            [-70.849867916992181, -9.653768526367195],
            [-70.846667916992189, -9.656168526367196],
            [-70.842567916992181, -9.655168526367195],
            [-70.839367916992188, -9.655968526367197],
            [-70.83656791699218, -9.652568526367197],
            [-70.836467916992177, -9.649468526367198],
            [-70.835767916992182, -9.646468526367197],
            [-70.834367916992193, -9.643468526367197],
            [-70.831067916992183, -9.645768526367196],
            [-70.828167916992186, -9.643468526367197],
            [-70.824267916992184, -9.643768526367197],
            [-70.821067916992178, -9.642068526367197],
            [-70.815967916992179, -9.642068526367197],
            [-70.811967916992188, -9.639268526367196],
            [-70.808367916992182, -9.640668526367197],
            [-70.804767916992191, -9.642668526367196],
            [-70.799967916992188, -9.640468526367197],
            [-70.794267916992183, -9.636268526367196],
            [-70.793167916992189, -9.632768526367197],
            [-70.790667916992192, -9.630168526367196],
            [-70.792067916992181, -9.626668526367197],
            [-70.78976791699219, -9.624168526367196],
            [-70.785867916992188, -9.621568526367195],
            [-70.78676791699219, -9.618068526367196],
            [-70.786167916992184, -9.613568526367196],
            [-70.782367916992186, -9.611968526367196],
            [-70.779267916992183, -9.608968526367196],
            [-70.775367916992181, -9.609968526367197],
            [-70.773367916992186, -9.606668526367198],
            [-70.76706791699219, -9.605868526367196],
            [-70.76706791699219, -9.601268526367196],
            [-70.762867916992178, -9.602468526367197],
            [-70.758267916992182, -9.603168526367195],
            [-70.75606791699218, -9.599168526367198],
            [-70.755167916992178, -9.595868526367195],
            [-70.756267916992186, -9.591968526367197],
            [-70.757167916992188, -9.587868526367195],
            [-70.757067916992185, -9.582968526367196],
            [-70.757167916992188, -9.578468526367196],
            [-70.753767916992189, -9.578168526367197],
            [-70.754867916992183, -9.574968526367197],
            [-70.752367916992185, -9.571368526367195],
            [-70.749467916992188, -9.568168526367195],
            [-70.753767916992189, -9.567668526367196],
            [-70.751567916992187, -9.564168526367197],
            [-70.748867916992182, -9.560468526367195],
            [-70.745167916992187, -9.561068526367198],
            [-70.742467916992183, -9.558868526367196],
            [-70.738267916992186, -9.558068526367197],
            [-70.73636791699218, -9.561568526367196],
            [-70.733067916992184, -9.564068526367198],
            [-70.730567916992186, -9.565468526367198],
            [-70.72996791699218, -9.568768526367197],
            [-70.727867916992182, -9.566368526367196],
            [-70.725067916992188, -9.565168526367195],
            [-70.72226791699218, -9.562268526367195],
            [-70.724167916992187, -9.560168526367196],
            [-70.722667916992179, -9.557468526367195],
            [-70.724767916992192, -9.555568526367196],
            [-70.72096791699218, -9.554668526367195],
            [-70.71796791699218, -9.553568526367197],
            [-70.71626791699218, -9.550068526367195],
            [-70.712867916992181, -9.546068526367197],
            [-70.708967916992179, -9.545768526367198],
            [-70.703467916992182, -9.543668526367195],
            [-70.700967916992184, -9.541968526367196],
            [-70.69816791699219, -9.539768526367197],
            [-70.693467916992191, -9.538868526367196],
            [-70.692367916992183, -9.534868526367195],
            [-70.688467916992181, -9.534468526367196],
            [-70.68416791699218, -9.531168526367196],
            [-70.681567916992179, -9.533068526367195],
            [-70.679367916992192, -9.531168526367196],
            [-70.675267916992183, -9.528768526367195],
            [-70.67216791699218, -9.526168526367197],
            [-70.669267916992183, -9.523668526367196],
            [-70.666767916992185, -9.521468526367197],
            [-70.66446791699218, -9.519368526367195],
            [-70.662467916992185, -9.523368526367197],
            [-70.66006791699219, -9.520068526367197],
            [-70.65846791699218, -9.516468526367195],
            [-70.656267916992192, -9.513968526367197],
            [-70.653967916992187, -9.510368526367195],
            [-70.652067916992181, -9.512768526367196],
            [-70.650067916992185, -9.509868526367196],
            [-70.650367916992181, -9.506268526367197],
            [-70.650667916992191, -9.502968526367198],
            [-70.650967916992187, -9.499668526367195],
            [-70.648467916992189, -9.498268526367195],
            [-70.645667916992181, -9.497768526367196],
            [-70.643167916992184, -9.495268526367198],
            [-70.639567916992178, -9.496968526367198],
            [-70.636467916992189, -9.499068526367196],
            [-70.635167916992188, -9.494668526367196],
            [-70.63136791699219, -9.492768526367197],
            [-70.634567916992182, -9.489068526367195],
            [-70.636067916992189, -9.486368526367198],
            [-70.632467916992184, -9.484768526367198],
            [-70.63136791699219, -9.480668526367197],
            [-70.628867916992192, -9.478368526367195],
            [-70.62466791699218, -9.477068526367198],
            [-70.623567916992187, -9.473468526367196],
            [-70.622267916992186, -9.470068526367196],
            [-70.619067916992179, -9.471468526367197],
            [-70.615567916992191, -9.474468526367197],
            [-70.615767916992183, -9.469768526367197],
            [-70.611767916992193, -9.465968526367195],
            [-70.611467916992183, -9.460768526367197],
            [-70.610867916992191, -9.456268526367197],
            [-70.607867916992191, -9.457068526367195],
            [-70.604667916992184, -9.457868526367196],
            [-70.598467916992178, -9.458968526367197],
            [-70.596167916992187, -9.456468526367196],
            [-70.59626791699219, -9.452168526367196],
            [-70.595667916992184, -9.448168526367198],
            [-70.59626791699219, -9.444668526367195],
            [-70.59456791699219, -9.440968526367197],
            [-70.590367916992193, -9.440468526367198],
            [-70.586467916992177, -9.443868526367197],
            [-70.583667916992184, -9.442068526367198],
            [-70.579967916992189, -9.438868526367198],
            [-70.575467916992181, -9.436968526367195],
            [-70.572467916992181, -9.435768526367195],
            [-70.569567916992185, -9.437668526367197],
            [-70.569567916992185, -9.432668526367195],
            [-70.565467916992191, -9.431868526367197],
            [-70.560467916992181, -9.429968526367198],
            [-70.557667916992187, -9.433268526367197],
            [-70.553267916992183, -9.431868526367197],
            [-70.54886791699218, -9.433368526367197],
            [-70.546667916992192, -9.429668526367195],
            [-70.54446791699219, -9.426868526367198],
            [-70.540667916992192, -9.429968526367198],
            [-70.537367916992181, -9.432268526367196],
            [-70.537567916992188, -9.436168526367197],
            [-70.53476791699218, -9.434668526367197],
            [-70.532267916992183, -9.432268526367196],
            [-70.528667916992191, -9.429968526367198],
            [-70.525867916992183, -9.426868526367198],
            [-70.524867916992179, -9.429768526367198],
            [-70.526567916992178, -9.433868526367196],
            [-70.523467916992189, -9.433868526367196],
            [-70.519267916992192, -9.431568526367197],
            [-70.519367916992181, -9.428268526367198],
            [-70.51536791699219, -9.426468526367195],
            [-70.511067916992189, -9.424168526367197],
            [-70.50636791699219, -9.422768526367197],
            [-70.502967916992191, -9.423268526367195],
            [-70.502167916992192, -9.426068526367196],
            [-70.49696791699219, -9.424668526367196],
            [-70.495067916992184, -9.428068526367195],
            [-70.494367916992189, -9.433568526367196],
            [-70.494467916992193, -9.437468526367198],
            [-70.496567916992191, -9.441268526367196],
            [-70.498867916992182, -9.442968526367196],
            [-70.502067916992189, -9.444168526367196],
            [-70.505967916992191, -9.444968526367198],
            [-70.50936791699219, -9.445568526367197],
            [-70.511067916992189, -9.448168526367198],
            [-70.509867916992192, -9.452068526367196],
            [-70.507767916992179, -9.455068526367196],
            [-70.505767916992184, -9.457868526367196],
            [-70.504067916992184, -9.460868526367197],
            [-70.50606791699218, -9.464268526367196],
            [-70.509967916992181, -9.465068526367197],
            [-70.51376791699218, -9.464068526367196],
            [-70.514967916992191, -9.458768526367198],
            [-70.519267916992192, -9.457168526367198],
            [-70.522567916992188, -9.458968526367197],
            [-70.521467916992179, -9.461968526367198],
            [-70.520367916992186, -9.466968526367197],
            [-70.51976791699218, -9.473468526367196],
            [-70.516067916992185, -9.478068526367196],
            [-70.513167916992188, -9.480568526367197],
            [-70.51066791699219, -9.483068526367198],
            [-70.508067916992189, -9.485868526367195],
            [-70.50636791699219, -9.488268526367197],
            [-70.504567916992187, -9.490468526367195],
            [-70.502667916992181, -9.493068526367196],
            [-70.502167916992192, -9.496668526367195],
            [-70.503467916992179, -9.502368526367196],
            [-70.505967916992191, -9.504968526367197],
            [-70.508467916992188, -9.502968526367198],
            [-70.510267916992191, -9.499368526367196],
            [-70.510467916992184, -9.495768526367197],
            [-70.512867916992178, -9.492468526367198],
            [-70.51536791699219, -9.495768526367197],
            [-70.515667916992186, -9.499168526367196],
            [-70.517167916992179, -9.502068526367196],
            [-70.51976791699218, -9.504368526367195],
            [-70.52406791699218, -9.504868526367197],
            [-70.527267916992187, -9.501668526367197],
            [-70.529867916992188, -9.504468526367198],
            [-70.529167916992179, -9.509268526367197],
            [-70.530567916992183, -9.514268526367196],
            [-70.534467916992185, -9.515368526367197],
            [-70.537767916992181, -9.513768526367198],
            [-70.54146791699219, -9.512468526367197],
            [-70.544567916992179, -9.513868526367197],
            [-70.546067916992186, -9.516468526367195],
            [-70.546467916992185, -9.519368526367195],
            [-70.546067916992186, -9.523668526367196],
            [-70.542167916992184, -9.525468526367195],
            [-70.537567916992188, -9.529568526367196],
            [-70.53806791699219, -9.534168526367196],
            [-70.540667916992192, -9.536668526367198],
            [-70.543867916992184, -9.537868526367195],
            [-70.548667916992187, -9.538668526367196],
            [-70.552967916992188, -9.538168526367198],
            [-70.557267916992188, -9.537068526367197],
            [-70.561567916992189, -9.532368526367197],
            [-70.563767916992191, -9.529868526367196],
            [-70.567767916992182, -9.530168526367195],
            [-70.56986791699218, -9.532768526367196],
            [-70.56716791699219, -9.534768526367195],
            [-70.564667916992192, -9.536268526367195],
            [-70.563067916992182, -9.539468526367195],
            [-70.564967916992188, -9.543568526367196],
            [-70.56686791699218, -9.546968526367195],
            [-70.568067916992192, -9.549668526367196],
            [-70.568167916992181, -9.553368526367198],
            [-70.565967916992179, -9.555768526367196],
            [-70.563067916992182, -9.556368526367198],
            [-70.55946791699219, -9.556868526367197],
            [-70.555867916992185, -9.558768526367196],
            [-70.553267916992183, -9.565468526367198],
            [-70.553367916992187, -9.569568526367195],
            [-70.556367916992187, -9.573168526367198],
            [-70.560167916992185, -9.574568526367198],
            [-70.56716791699219, -9.571568526367198],
            [-70.57016791699219, -9.569068526367197],
            [-70.57286791699218, -9.564568526367196],
            [-70.577067916992178, -9.562968526367197],
            [-70.581067916992183, -9.565468526367198],
            [-70.583967916992179, -9.566968526367198],
            [-70.587967916992184, -9.565568526367198],
            [-70.588367916992183, -9.561668526367196],
            [-70.586467916992177, -9.558668526367196],
            [-70.584667916992188, -9.555168526367197],
            [-70.586767916992187, -9.550068526367195],
            [-70.59126791699218, -9.548068526367196],
            [-70.595367916992188, -9.550668526367197],
            [-70.598467916992178, -9.555468526367196],
            [-70.599467916992182, -9.559068526367195],
            [-70.599467916992182, -9.562668526367197],
            [-70.595167916992182, -9.564068526367198],
            [-70.592067916992193, -9.566968526367198],
            [-70.58826791699218, -9.570468526367197],
            [-70.585867916992186, -9.573468526367197],
            [-70.586167916992181, -9.576768526367196],
            [-70.586767916992187, -9.579668526367197],
            [-70.58696791699218, -9.583168526367196],
            [-70.589467916992191, -9.585868526367197],
            [-70.585867916992186, -9.587568526367196],
            [-70.58656791699218, -9.591968526367197],
            [-70.587567916992185, -9.596468526367197],
            [-70.590667916992189, -9.601268526367196],
            [-70.593767916992192, -9.603168526367195],
            [-70.59756791699219, -9.605668526367197],
            [-70.597067916992188, -9.608568526367197],
            [-70.595567916992181, -9.611068526367195],
            [-70.592367916992188, -9.616568526367196],
            [-70.59126791699218, -9.622168526367197],
            [-70.589867916992191, -9.625268526367197],
            [-70.587667916992189, -9.628768526367196],
            [-70.587267916992189, -9.632068526367195],
            [-70.584267916992189, -9.636368526367196],
            [-70.583267916992185, -9.640368526367197],
            [-70.57956791699219, -9.643668526367197],
            [-70.57786791699219, -9.647568526367195],
            [-70.57486791699219, -9.650068526367196],
            [-70.573967916992189, -9.654468526367197],
            [-70.571767916992187, -9.658368526367195],
            [-70.569667916992188, -9.661268526367195],
            [-70.566267916992189, -9.665568526367196],
            [-70.56216791699218, -9.667768526367198],
            [-70.558067916992186, -9.666768526367196],
            [-70.553367916992187, -9.667768526367198],
            [-70.550767916992186, -9.671068526367197],
            [-70.548967916992183, -9.675768526367197],
            [-70.546367916992182, -9.678568526367197],
            [-70.542267916992188, -9.680468526367196],
            [-70.540567916992188, -9.684468526367198],
            [-70.53816791699218, -9.689468526367197],
            [-70.536667916992187, -9.692768526367196],
            [-70.536167916992184, -9.696068526367196],
            [-70.534467916992185, -9.699368526367195],
            [-70.533167916992184, -9.702168526367196],
            [-70.532367916992186, -9.706168526367197],
            [-70.530967916992182, -9.709368526367196],
            [-70.528667916992191, -9.711968526367198],
            [-70.525967916992187, -9.714468526367195],
            [-70.528367916992181, -9.717568526367195],
            [-70.526867916992188, -9.720068526367196],
            [-70.526767916992185, -9.723068526367197],
            [-70.527867916992193, -9.725868526367197],
            [-70.529467916992189, -9.728468526367195],
            [-70.532267916992183, -9.732268526367196],
            [-70.532867916992188, -9.736068526367195],
            [-70.532267916992183, -9.739468526367197],
            [-70.532767916992185, -9.744368526367197],
            [-70.534867916992184, -9.747368526367197],
            [-70.53846791699219, -9.749968526367198],
            [-70.538867916992189, -9.753868526367196],
            [-70.537467916992185, -9.757068526367195],
            [-70.535967916992178, -9.760268526367195],
            [-70.535867916992188, -9.764268526367196],
            [-70.53846791699219, -9.767468526367196],
            [-70.541767916992185, -9.771468526367197],
            [-70.544967916992192, -9.774868526367197],
            [-70.545367916992191, -9.777868526367197],
            [-70.548267916992188, -9.779468526367197],
            [-70.55186791699218, -9.783468526367198],
            [-70.554167916992185, -9.785968526367196],
            [-70.556967916992193, -9.787768526367195],
            [-70.56086791699218, -9.788368526367197],
            [-70.565167916992181, -9.785368526367197],
            [-70.569267916992189, -9.783068526367195],
            [-70.572067916992182, -9.780468526367198],
            [-70.574967916992179, -9.777868526367197],
            [-70.578267916992189, -9.777968526367196],
            [-70.581167916992186, -9.780168526367195],
            [-70.583667916992184, -9.781968526367198],
            [-70.585867916992186, -9.786768526367197],
            [-70.585467916992187, -9.790668526367195],
            [-70.587867916992181, -9.795268526367195],
            [-70.589267916992185, -9.798668526367198],
            [-70.591167916992191, -9.802568526367196],
            [-70.59626791699219, -9.801168526367196],
            [-70.60066791699218, -9.800068526367195],
            [-70.604267916992185, -9.800068526367195],
            [-70.607467916992192, -9.800868526367196],
            [-70.610067916992179, -9.802468526367196],
            [-70.612267916992181, -9.805268526367197],
            [-70.613867916992177, -9.810568526367195],
            [-70.615067916992189, -9.815968526367197],
            [-70.620167916992187, -9.818068526367195],
            [-70.623267916992191, -9.820768526367196],
            [-70.622267916992186, -9.823768526367196],
            [-70.621867916992187, -9.827868526367197],
            [-70.620767916992179, -9.831468526367196],
            [-70.619967916992181, -10.078168526367197],
            [-70.620467916992183, -10.081468526367196],
            [-70.620867916992182, -10.095368526367196],
            [-70.621067916992189, -10.228168526367195],
            [-70.620267916992191, -10.359268526367195],
            [-70.620267916992191, -10.363568526367196],
            [-70.620467916992183, -10.424968526367195],
            [-70.620567916992186, -10.499968526367198],
            [-70.620567916992186, -10.508568526367196],
            [-70.620567916992186, -10.707568526367197],
            [-70.620767916992179, -10.714768526367195],
            [-70.620867916992182, -10.811068526367198],
            [-70.620867916992182, -10.821368526367195],
            [-70.620867916992182, -10.910668526367196],
            [-70.620867916992182, -10.999468526367195],
            [-70.529867916992188, -10.934468526367198],
            [-70.528967916992187, -10.931168526367195],
            [-70.526167916992179, -10.932168526367196],
            [-70.522867916992183, -10.934168526367195],
            [-70.520667916992181, -10.936268526367197],
            [-70.519667916992191, -10.939068526367198],
            [-70.518167916992184, -10.943468526367198],
            [-70.516567916992187, -10.945968526367196],
            [-70.51236791699219, -10.949868526367197],
            [-70.508467916992188, -10.951768526367196],
            [-70.50436791699218, -10.952168526367196],
            [-70.501567916992187, -10.955668526367198],
            [-70.498767916992179, -10.958468526367195],
            [-70.495067916992184, -10.962568526367196],
            [-70.490367916992184, -10.963668526367197],
            [-70.486467916992183, -10.964068526367196],
            [-70.481167916992177, -10.967868526367198],
            [-70.47866791699218, -10.971268526367197],
            [-70.47596791699219, -10.974768526367196],
            [-70.473467916992178, -10.979268526367196],
            [-70.471167916992187, -10.984268526367195],
            [-70.468367916992179, -10.987468526367195],
            [-70.465367916992193, -10.987468526367195],
            [-70.462967916992184, -10.989668526367197],
            [-70.460467916992187, -10.991168526367197],
            [-70.457167916992191, -10.993368526367195],
            [-70.452467916992191, -10.995868526367197],
            [-70.450167916992186, -10.999068526367196],
            [-70.44786791699218, -11.004068526367195],
            [-70.445967916992188, -11.007368526367195],
            [-70.443767916992186, -11.011068526367197],
            [-70.441267916992189, -11.014268526367196],
            [-70.440767916992186, -11.017368526367196],
            [-70.439867916992185, -11.020068526367197],
            [-70.437667916992183, -11.025068526367196],
            [-70.438467916992181, -11.028968526367198],
            [-70.439967916992188, -11.032768526367196],
            [-70.437967916992193, -11.036668526367198],
            [-70.435567916992184, -11.038468526367197],
            [-70.432167916992185, -11.039168526367195],
            [-70.427567916992189, -11.038468526367197],
            [-70.422267916992183, -11.038168526367198],
            [-70.418367916992182, -11.039268526367195],
            [-70.414567916992183, -11.040568526367196],
            [-70.410567916992193, -11.041668526367197],
            [-70.408367916992191, -11.043968526367195],
            [-70.405367916992191, -11.046068526367197],
            [-70.401967916992191, -11.045668526367198],
            [-70.398167916992179, -11.044968526367196],
            [-70.39506791699219, -11.044968526367196],
            [-70.391467916992184, -11.045368526367195],
            [-70.386467916992189, -11.046768526367195],
            [-70.38266791699219, -11.048568526367195],
            [-70.380267916992182, -11.051568526367195],
            [-70.37936791699218, -11.054668526367195],
            [-70.376967916992186, -11.056668526367197],
            [-70.373867916992182, -11.059068526367195],
            [-70.369467916992193, -11.057968526367198],
            [-70.365767916992183, -11.058668526367196],
            [-70.361767916992193, -11.059468526367198],
            [-70.357767916992188, -11.058868526367196],
            [-70.354267916992185, -11.058568526367196],
            [-70.35056791699219, -11.060868526367198],
            [-70.34726791699218, -11.064668526367196],
            [-70.342667916992184, -11.066268526367196],
            [-70.339367916992188, -11.065968526367197],
            [-70.335467916992187, -11.066668526367195],
            [-70.332667916992179, -11.065468526367198],
            [-70.327067916992178, -11.064668526367196],
            [-70.322367916992192, -11.065868526367197],
            [-70.316567916992184, -11.067668526367196],
            [-70.313367916992178, -11.068868526367197],
            [-70.310167916992185, -11.069568526367195],
            [-70.306867916992189, -11.069168526367196],
            [-70.30216791699219, -11.067668526367196],
            [-70.299667916992178, -11.065268526367195],
            [-70.296767916992181, -11.064568526367196],
            [-70.29276791699219, -11.064068526367198],
            [-70.288667916992182, -11.061168526367197],
            [-70.285967916992178, -11.057568526367195],
            [-70.281267916992192, -11.055768526367196],
            [-70.277667916992186, -11.056168526367195],
            [-70.27436791699219, -11.057768526367198],
            [-70.270667916992181, -11.057968526367198],
            [-70.26706791699219, -11.056168526367195],
            [-70.264967916992191, -11.052768526367196],
            [-70.263167916992188, -11.050468526367197],
            [-70.25906791699218, -11.051468526367195],
            [-70.253867916992192, -11.049368526367196],
            [-70.248567916992187, -11.048668526367198],
            [-70.245067916992184, -11.048368526367195],
            [-70.242167916992187, -11.046768526367195],
            [-70.239467916992183, -11.048168526367196],
            [-70.236167916992187, -11.048968526367197],
            [-70.231167916992177, -11.050768526367197],
            [-70.228067916992188, -11.048868526367198],
            [-70.222767916992183, -11.050368526367198],
            [-70.218667916992189, -11.049768526367195],
            [-70.214367916992188, -11.051568526367195],
            [-70.212067916992183, -11.049668526367196],
            [-70.208267916992185, -11.050468526367197],
            [-70.204967916992189, -11.051168526367196],
            [-70.199967916992179, -11.053068526367195],
            [-70.197067916992182, -11.051868526367198],
            [-70.192667916992178, -11.050368526367198],
            [-70.189967916992188, -11.048268526367195],
            [-70.188767916992191, -11.044468526367197],
            [-70.179767916992191, -11.042868526367197],
            [-70.17686791699218, -11.039168526367195],
            [-70.174267916992193, -11.040968526367195],
            [-70.171367916992182, -11.040868526367195],
            [-70.168067916992186, -11.040668526367195],
            [-70.165067916992186, -11.038868526367196],
            [-70.161267916992188, -11.040268526367196],
            [-70.158067916992181, -11.039568526367198],
            [-70.156267916992192, -11.032768526367196],
            [-70.152267916992187, -11.032268526367197],
            [-70.149567916992183, -11.030368526367198],
            [-70.145967916992191, -11.029868526367196],
            [-70.141467916992184, -11.028968526367198],
            [-70.139267916992182, -11.023168526367197],
            [-70.133767916992184, -11.021768526367197],
            [-70.129867916992183, -11.020468526367196],
            [-70.12666791699219, -11.015468526367197],
            [-70.123767916992179, -11.012168526367198],
            [-70.12166791699218, -11.009968526367196],
            [-70.117767916992193, -11.009668526367197],
            [-70.11436791699218, -11.008068526367197],
            [-70.116867916992192, -11.006368526367197],
            [-70.114467916992183, -11.004568526367198],
            [-70.110367916992189, -11.004068526367195],
            [-70.107767916992188, -11.007168526367195],
            [-70.106767916992183, -11.002768526367195],
            [-70.107767916992188, -10.998868526367197],
            [-70.107767916992188, -10.994768526367196],
            [-70.10366791699218, -10.994768526367196],
            [-70.104167916992182, -10.999068526367196],
            [-70.101367916992189, -10.998368526367198],
            [-70.09756791699219, -10.997668526367196],
            [-70.095567916992181, -10.993668526367195],
            [-70.095167916992182, -10.988968526367195],
            [-70.090667916992189, -10.986468526367197],
            [-70.086467916992177, -10.988868526367195],
            [-70.083267916992185, -10.985268526367197],
            [-70.080367916992188, -10.984968526367197],
            [-70.079867916992185, -10.989468526367197],
            [-70.076867916992185, -10.989168526367198],
            [-70.072967916992184, -10.990768526367198],
            [-70.069667916992188, -10.992568526367197],
            [-70.06556791699218, -10.991668526367196],
            [-70.06416791699219, -10.986768526367197],
            [-70.060267916992188, -10.986868526367196],
            [-70.056967916992193, -10.986868526367196],
            [-70.052867916992184, -10.985868526367195],
            [-70.05046791699219, -10.984368526367195],
            [-70.047567916992179, -10.979568526367196],
            [-70.044767916992186, -10.983168526367198],
            [-70.044667916992182, -10.978868526367197],
            [-70.040367916992182, -10.979268526367196],
            [-70.037467916992185, -10.978068526367196],
            [-70.03806791699219, -10.974768526367196],
            [-70.035867916992188, -10.971168526367197],
            [-70.03206791699219, -10.972768526367197],
            [-70.029767916992185, -10.975668526367198],
            [-70.026567916992178, -10.975968526367197],
            [-70.025867916992183, -10.971968526367196],
            [-70.022367916992181, -10.969268526367195],
            [-70.017467916992189, -10.968768526367196],
            [-70.013467916992184, -10.967668526367195],
            [-70.011067916992189, -10.965168526367197],
            [-70.00906791699218, -10.962868526367195],
            [-70.005967916992191, -10.963668526367197],
            [-70.003567916992182, -10.960468526367197],
            [-69.999767916992184, -10.961868526367198],
            [-69.996867916992187, -10.960368526367198],
            [-69.995867916992182, -10.957568526367197],
            [-69.995867916992182, -10.953468526367196],
            [-69.994667916992185, -10.950268526367196],
            [-69.990767916992183, -10.950768526367195],
            [-69.988267916992186, -10.952668526367198],
            [-69.984767916992183, -10.953168526367197],
            [-69.983967916992185, -10.949568526367198],
            [-69.984467916992187, -10.946368526367195],
            [-69.982067916992179, -10.942168526367198],
            [-69.978467916992187, -10.945268526367197],
            [-69.975267916992181, -10.947368526367196],
            [-69.97096791699218, -10.945168526367198],
            [-69.96796791699218, -10.941268526367196],
            [-69.964067916992178, -10.938868526367198],
            [-69.960067916992188, -10.938268526367196],
            [-69.96156791699218, -10.934468526367198],
            [-69.957367916992183, -10.934168526367195],
            [-69.953767916992177, -10.932268526367196],
            [-69.953767916992177, -10.926868526367198],
            [-69.94986791699219, -10.924668526367196],
            [-69.94686791699219, -10.923868526367198],
            [-69.944067916992182, -10.924668526367196],
            [-69.940967916992179, -10.924968526367195],
            [-69.936367916992182, -10.922468526367197],
            [-69.932967916992183, -10.920568526367198],
            [-69.929667916992187, -10.921968526367195],
            [-69.924167916992189, -10.923368526367195],
            [-69.923667916992187, -10.927468526367196],
            [-69.923367916992191, -10.930468526367196],
            [-69.920667916992187, -10.931568526367197],
            [-69.917067916992181, -10.929368526367195],
            [-69.91746791699218, -10.925068526367195],
            [-69.91476791699219, -10.922868526367196],
            [-69.912067916992186, -10.921468526367196],
            [-69.907667916992182, -10.921668526367196],
            [-69.902867916992193, -10.919768526367196],
            [-69.898667916992181, -10.919168526367198],
            [-69.894367916992181, -10.920768526367198],
            [-69.89036791699219, -10.921468526367196],
            [-69.885967916992186, -10.922568526367197],
            [-69.88236791699218, -10.922868526367196],
            [-69.87806791699218, -10.922868526367196],
            [-69.875767916992189, -10.926168526367196],
            [-69.872667916992185, -10.928368526367198],
            [-69.868267916992181, -10.926068526367196],
            [-69.864967916992185, -10.924768526367195],
            [-69.861467916992183, -10.925768526367197],
            [-69.85996791699219, -10.928568526367197],
            [-69.857267916992186, -10.931668526367197],
            [-69.855367916992179, -10.927468526367196],
            [-69.854467916992178, -10.924468526367196],
            [-69.85096791699219, -10.922868526367196],
            [-69.846667916992189, -10.922868526367196],
            [-69.843067916992183, -10.923868526367198],
            [-69.839567916992181, -10.927968526367195],
            [-69.835467916992187, -10.927268526367197],
            [-69.831767916992192, -10.928268526367198],
            [-69.832167916992191, -10.931668526367197],
            [-69.82926791699218, -10.932168526367196],
            [-69.824667916992183, -10.932068526367196],
            [-69.820267916992179, -10.930468526367196],
            [-69.816267916992189, -10.929368526367195],
            [-69.812967916992193, -10.928268526367198],
            [-69.811967916992188, -10.924468526367196],
            [-69.809067916992191, -10.924668526367196],
            [-69.805467916992185, -10.925268526367198],
            [-69.80186791699218, -10.925268526367198],
            [-69.798967916992183, -10.926068526367196],
            [-69.79716791699218, -10.930468526367196],
            [-69.793967916992187, -10.932268526367196],
            [-69.79146791699219, -10.930568526367196],
            [-69.787067916992186, -10.929368526367195],
            [-69.783167916992184, -10.931168526367195],
            [-69.77906791699219, -10.933568526367196],
            [-69.77406791699218, -10.931668526367197],
            [-69.770667916992181, -10.928668526367197],
            [-69.768167916992184, -10.930268526367197],
            [-69.768767916992189, -10.933068526367197],
            [-69.771867916992193, -10.934868526367197],
            [-69.772867916992183, -10.938468526367195],
            [-69.768667916992186, -10.939868526367196],
            [-69.764267916992182, -10.939368526367197],
            [-69.759967916992181, -10.938568526367195],
            [-69.757767916992179, -10.941068526367197],
            [-69.755467916992188, -10.944368526367196],
            [-69.751967916992186, -10.947668526367195],
            [-69.750167916992183, -10.950668526367195],
            [-69.747167916992183, -10.953868526367195],
            [-69.746567916992191, -10.958568526367195],
            [-69.74366791699218, -10.961468526367195],
            [-69.73966791699219, -10.963368526367198],
            [-69.73936791699218, -10.966568526367197],
            [-69.740767916992183, -10.969768526367197],
            [-69.739167916992187, -10.973368526367196],
            [-69.73496791699219, -10.973068526367197],
            [-69.730667916992189, -10.970368526367196],
            [-69.726767916992188, -10.966968526367197],
            [-69.723367916992188, -10.964468526367195],
            [-69.719767916992183, -10.967368526367196],
            [-69.717667916992184, -10.972768526367197],
            [-69.711767916992187, -10.973368526367196],
            [-69.70756791699219, -10.969768526367197],
            [-69.704667916992179, -10.967368526367196],
            [-69.701067916992187, -10.965568526367196],
            [-69.698867916992185, -10.970168526367196],
            [-69.69516791699219, -10.967068526367196],
            [-69.692367916992183, -10.963768526367197],
            [-69.68916791699219, -10.963668526367197],
            [-69.684367916992187, -10.962268526367197],
            [-69.679767916992191, -10.960068526367195],
            [-69.673067916992181, -10.960068526367195],
            [-69.669567916992179, -10.961468526367195],
            [-69.666367916992186, -10.958268526367196],
            [-69.663667916992182, -10.956468526367196],
            [-69.661967916992182, -10.953768526367195],
            [-69.658067916992181, -10.951768526367196],
            [-69.652567916992183, -10.952068526367196],
            [-69.648367916992186, -10.953168526367197],
            [-69.644267916992192, -10.954268526367198],
            [-69.639867916992188, -10.955368526367195],
            [-69.63566791699219, -10.956768526367195],
            [-69.631267916992186, -10.955368526367195],
            [-69.62806791699218, -10.954068526367195],
            [-69.625167916992183, -10.951468526367197],
            [-69.62166791699218, -10.949668526367198],
            [-69.616067916992179, -10.951268526367198],
            [-69.611667916992189, -10.955068526367196],
            [-69.611967916992185, -10.950968526367195],
            [-69.612167916992178, -10.947168526367197],
            [-69.609467916992187, -10.944968526367198],
            [-69.606367916992184, -10.943868526367197],
            [-69.603567916992191, -10.942768526367196],
            [-69.59896791699218, -10.942068526367198],
            [-69.594467916992187, -10.943868526367197],
            [-69.590467916992182, -10.944968526367198],
            [-69.586767916992187, -10.947868526367195],
            [-69.585467916992187, -10.944868526367195],
            [-69.588967916992189, -10.941668526367195],
            [-69.585767916992182, -10.939868526367196],
            [-69.57956791699219, -10.940468526367198],
            [-69.576067916992187, -10.942668526367196],
            [-69.572367916992192, -10.944168526367196],
            [-69.567667916992178, -10.944068526367197],
            [-69.564867916992185, -10.945168526367198],
            [-69.561567916992189, -10.946368526367195],
            [-69.558267916992179, -10.946068526367196],
            [-69.55516791699219, -10.944868526367195],
            [-69.552567916992189, -10.948168526367198],
            [-69.551167916992185, -10.951268526367198],
            [-69.548167916992185, -10.952568526367195],
            [-69.544267916992183, -10.952468526367195],
            [-69.541767916992185, -10.949568526367198],
            [-69.543567916992188, -10.945768526367196],
            [-69.54276791699219, -10.942368526367197],
            [-69.541767916992185, -10.938368526367196],
            [-69.538867916992189, -10.936868526367196],
            [-69.534867916992184, -10.936168526367197],
            [-69.529867916992188, -10.936068526367198],
            [-69.525167916992189, -10.935868526367198],
            [-69.521767916992189, -10.937968526367197],
            [-69.519067916992185, -10.936868526367196],
            [-69.516067916992185, -10.937268526367195],
            [-69.513167916992188, -10.940568526367198],
            [-69.510267916992191, -10.942168526367198],
            [-69.506667916992185, -10.942368526367197],
            [-69.504067916992184, -10.939468526367197],
            [-69.500967916992181, -10.936868526367196],
            [-69.498867916992182, -10.939168526367197],
            [-69.49836791699218, -10.943868526367197],
            [-69.496267916992181, -10.947468526367196],
            [-69.492267916992191, -10.949668526367198],
            [-69.487567916992191, -10.949368526367195],
            [-69.484367916992184, -10.949668526367198],
            [-69.481067916992188, -10.952168526367196],
            [-69.477067916992183, -10.952368526367195],
            [-69.478167916992192, -10.947768526367195],
            [-69.47696791699218, -10.944368526367196],
            [-69.473367916992188, -10.943868526367197],
            [-69.470167916992182, -10.946868526367197],
            [-69.464567916992181, -10.949868526367197],
            [-69.460967916992189, -10.948868526367196],
            [-69.46186791699219, -10.944868526367195],
            [-69.459067916992183, -10.944368526367196],
            [-69.45426791699218, -10.947868526367195],
            [-69.453167916992186, -10.944568526367195],
            [-69.452067916992178, -10.936868526367196],
            [-69.447667916992188, -10.934368526367198],
            [-69.443067916992192, -10.934068526367195],
            [-69.439367916992182, -10.933368526367197],
            [-69.435467916992181, -10.933368526367197],
            [-69.429067916992182, -10.936868526367196],
            [-69.425567916992179, -10.932968526367198],
            [-69.424967916992188, -10.927468526367196],
            [-69.422567916992179, -10.924368526367196],
            [-69.419267916992183, -10.925468526367197],
            [-69.41616791699218, -10.925868526367196],
            [-69.41346791699219, -10.929368526367195],
            [-69.41306791699219, -10.933368526367197],
            [-69.412767916992181, -10.936868526367196],
            [-69.40876791699219, -10.933368526367197],
            [-69.407367916992186, -10.928568526367197],
            [-69.403367916992181, -10.927968526367195],
            [-69.400167916992189, -10.930768526367196],
            [-69.39936791699219, -10.933568526367196],
            [-69.398667916992181, -10.936568526367196],
            [-69.397067916992185, -10.940468526367198],
            [-69.394867916992183, -10.943168526367195],
            [-69.392567916992192, -10.941268526367196],
            [-69.39476791699218, -10.938468526367195],
            [-69.39506791699219, -10.934468526367198],
            [-69.392167916992179, -10.933068526367197],
            [-69.388167916992188, -10.932968526367198],
            [-69.38706791699218, -10.936968526367195],
            [-69.384267916992187, -10.940968526367197],
            [-69.380267916992182, -10.939168526367197],
            [-69.377767916992184, -10.941268526367196],
            [-69.377667916992181, -10.944568526367195],
            [-69.377167916992192, -10.948168526367198],
            [-69.377367916992185, -10.951468526367197],
            [-69.374367916992185, -10.952868526367197],
            [-69.372267916992186, -10.949868526367197],
            [-69.372267916992186, -10.946368526367195],
            [-69.374167916992178, -10.941068526367197],
            [-69.369767916992188, -10.937768526367197],
            [-69.365467916992188, -10.938768526367195],
            [-69.362467916992188, -10.940568526367198],
            [-69.359767916992183, -10.942968526367196],
            [-69.356767916992183, -10.943068526367195],
            [-69.357767916992188, -10.939068526367198],
            [-69.35666791699218, -10.935868526367198],
            [-69.353167916992192, -10.935768526367195],
            [-69.350267916992181, -10.937268526367195],
            [-69.346767916992178, -10.936368526367197],
            [-69.344867916992186, -10.938468526367195],
            [-69.345567916992181, -10.942768526367196],
            [-69.346367916992179, -10.946668526367198],
            [-69.343467916992182, -10.949968526367197],
            [-69.34156791699219, -10.946368526367195],
            [-69.340067916992183, -10.942768526367196],
            [-69.337867916992181, -10.940568526367198],
            [-69.334567916992185, -10.941268526367196],
            [-69.333467916992191, -10.946068526367196],
            [-69.328967916992184, -10.948768526367196],
            [-69.327167916992181, -10.945268526367197],
            [-69.327167916992181, -10.938468526367195],
            [-69.325667916992188, -10.935768526367195],
            [-69.322367916992192, -10.937168526367195],
            [-69.320967916992188, -10.941668526367195],
            [-69.317767916992182, -10.943568526367198],
            [-69.313767916992191, -10.943268526367195],
            [-69.310567916992184, -10.943868526367197],
            [-69.307667916992187, -10.944868526367195],
            [-69.304667916992187, -10.946668526367198],
            [-69.301167916992185, -10.946768526367197],
            [-69.296367916992182, -10.946368526367195],
            [-69.291767916992185, -10.948568526367197],
            [-69.287767916992181, -10.948468526367197],
            [-69.286167916992184, -10.944168526367196],
            [-69.282867916992188, -10.942668526367196],
            [-69.279767916992185, -10.944368526367196],
            [-69.277567916992183, -10.947668526367195],
            [-69.276167916992179, -10.950968526367195],
            [-69.273167916992179, -10.952668526367198],
            [-69.274567916992183, -10.948268526367197],
            [-69.275067916992185, -10.945268526367197],
            [-69.273267916992182, -10.942068526367198],
            [-69.270167916992179, -10.940468526367198],
            [-69.266067916992185, -10.940268526367195],
            [-69.262467916992179, -10.939968526367196],
            [-69.258567916992178, -10.940568526367198],
            [-69.255167916992178, -10.939168526367197],
            [-69.25136791699218, -10.938768526367195],
            [-69.249867916992187, -10.941968526367198],
            [-69.252967916992191, -10.943868526367197],
            [-69.257067916992185, -10.944668526367195],
            [-69.254067916992184, -10.947468526367196],
            [-69.248867916992182, -10.948568526367197],
            [-69.244767916992188, -10.949868526367197],
            [-69.240067916992189, -10.950168526367197],
            [-69.236767916992193, -10.947868526367195],
            [-69.23466791699218, -10.943568526367198],
            [-69.232467916992192, -10.939368526367197],
            [-69.228867916992186, -10.941968526367198],
            [-69.224967916992185, -10.946368526367195],
            [-69.220667916992184, -10.947468526367196],
            [-69.218367916992179, -10.950268526367196],
            [-69.217067916992193, -10.953868526367195],
            [-69.215367916992193, -10.956168526367197],
            [-69.211767916992187, -10.954868526367196],
            [-69.207867916992186, -10.951468526367197],
            [-69.201767916992182, -10.948168526367198],
            [-69.197967916992184, -10.947068526367197],
            [-69.193167916992181, -10.948268526367197],
            [-69.188467916992181, -10.948468526367197],
            [-69.184867916992189, -10.948468526367197],
            [-69.183267916992179, -10.952868526367197],
            [-69.178267916992183, -10.956068526367197],
            [-69.174167916992189, -10.957068526367195],
            [-69.170267916992188, -10.958668526367195],
            [-69.16916791699218, -10.962668526367196],
            [-69.165967916992187, -10.964068526367196],
            [-69.162367916992181, -10.964568526367195],
            [-69.15976791699218, -10.962568526367196],
            [-69.157667916992182, -10.958168526367196],
            [-69.15406791699219, -10.955068526367196],
            [-69.152667916992186, -10.958468526367195],
            [-69.152867916992193, -10.962968526367195],
            [-69.146467916992179, -10.961768526367198],
            [-69.143967916992182, -10.963468526367198],
            [-69.146867916992193, -10.966168526367195],
            [-69.148967916992177, -10.969868526367197],
            [-69.14476791699218, -10.969868526367197],
            [-69.14206791699219, -10.968368526367197],
            [-69.138967916992186, -10.965668526367196],
            [-69.138467916992184, -10.969768526367197],
            [-69.136067916992189, -10.972268526367195],
            [-69.132067916992185, -10.969568526367198],
            [-69.128567916992182, -10.967268526367196],
            [-69.125267916992186, -10.965668526367196],
            [-69.12166791699218, -10.966168526367195],
            [-69.119667916992185, -10.969468526367198],
            [-69.119967916992181, -10.972368526367195],
            [-69.120867916992182, -10.976268526367196],
            [-69.119767916992188, -10.979268526367196],
            [-69.116967916992181, -10.977768526367196],
            [-69.115767916992183, -10.974968526367196],
            [-69.112767916992183, -10.970868526367195],
            [-69.10826791699219, -10.971168526367197],
            [-69.105067916992184, -10.975568526367198],
            [-69.101367916992189, -10.979468526367196],
            [-69.096467916992182, -10.977568526367197],
            [-69.092667916992184, -10.974768526367196],
            [-69.090067916992183, -10.971768526367196],
            [-69.08686791699219, -10.969068526367195],
            [-69.083967916992179, -10.969568526367198],
            [-69.081167916992186, -10.972268526367195],
            [-69.07786791699219, -10.973368526367196],
            [-69.075467916992181, -10.969868526367197],
            [-69.073467916992186, -10.965868526367196],
            [-69.069667916992188, -10.968768526367196],
            [-69.068467916992191, -10.973668526367195],
            [-69.06556791699218, -10.976368526367196],
            [-69.064067916992187, -10.979268526367196],
            [-69.062967916992193, -10.982868526367195],
            [-69.058867916992185, -10.983368526367197],
            [-69.055567916992189, -10.979968526367195],
            [-69.052467916992185, -10.974568526367197],
            [-69.04886791699218, -10.973868526367195],
            [-69.04886791699218, -10.978368526367195],
            [-69.044667916992182, -10.979968526367195],
            [-69.042267916992188, -10.977768526367196],
            [-69.039867916992179, -10.976268526367196],
            [-69.037067916992186, -10.978668526367198],
            [-69.035567916992193, -10.982468526367196],
            [-69.031567916992188, -10.984768526367198],
            [-69.027667916992186, -10.981968526367197],
            [-69.02576791699218, -10.985268526367197],
            [-69.025667916992191, -10.989368526367198],
            [-69.023367916992186, -10.991668526367196],
            [-69.019667916992191, -10.990068526367196],
            [-69.015767916992189, -10.989368526367198],
            [-69.01206791699218, -10.990468526367195],
            [-69.009567916992182, -10.993368526367195],
            [-69.011267916992182, -10.996568526367195],
            [-69.014267916992182, -10.999468526367195],
            [-69.011867916992188, -11.001668526367197],
            [-69.00736791699218, -11.001668526367197],
            [-69.004067916992184, -11.004968526367197],
            [-69.001567916992187, -11.007168526367195],
            [-68.997667916992185, -11.002668526367195],
            [-68.994367916992189, -11.002968526367198],
            [-68.99366791699218, -11.007768526367197],
            [-68.991867916992192, -11.009968526367196],
            [-68.987867916992187, -11.009568526367197],
            [-68.984467916992187, -11.006768526367196],
            [-68.98636791699218, -11.002768526367195],
            [-68.986867916992182, -10.997768526367196],
            [-68.983167916992187, -10.996568526367195],
            [-68.980567916992186, -10.995268526367198],
            [-68.978167916992192, -10.992968526367196],
            [-68.975267916992181, -10.989968526367196],
            [-68.972667916992179, -10.988268526367197],
            [-68.969467916992187, -10.986768526367197],
            [-68.966167916992191, -10.987168526367196],
            [-68.963467916992187, -10.989368526367198],
            [-68.960867916992186, -10.991068526367197],
            [-68.956867916992181, -10.990068526367196],
            [-68.952167916992181, -10.988968526367195],
            [-68.952467916992191, -10.993368526367195],
            [-68.954867916992185, -10.996868526367198],
            [-68.955767916992187, -11.000968526367195],
            [-68.952967916992179, -11.002768526367195],
            [-68.948567916992189, -11.002968526367198],
            [-68.945767916992182, -11.004568526367198],
            [-68.946767916992187, -11.009568526367197],
            [-68.944067916992182, -11.010668526367198],
            [-68.940467916992191, -11.007768526367197],
            [-68.93716791699218, -11.004368526367195],
            [-68.932767916992191, -11.004068526367195],
            [-68.930867916992185, -11.007068526367195],
            [-68.930067916992186, -11.010368526367195],
            [-68.926967916992183, -11.011068526367197],
            [-68.925067916992191, -11.013468526367195],
            [-68.92386791699218, -11.016368526367195],
            [-68.921067916992186, -11.012868526367196],
            [-68.91646791699219, -11.015768526367197],
            [-68.91316791699218, -11.018968526367196],
            [-68.909167916992189, -11.020468526367196],
            [-68.905967916992182, -11.017068526367197],
            [-68.903667916992191, -11.012368526367197],
            [-68.899767916992189, -11.008868526367195],
            [-68.896167916992184, -11.005168526367196],
            [-68.89206791699219, -11.007068526367195],
            [-68.888967916992186, -11.009868526367196],
            [-68.886767916992184, -11.013468526367195],
            [-68.883867916992187, -11.009868526367196],
            [-68.879867916992183, -11.008468526367196],
            [-68.876267916992191, -11.008468526367196],
            [-68.872667916992185, -11.008768526367195],
            [-68.869767916992188, -11.012668526367197],
            [-68.86466791699219, -11.014068526367197],
            [-68.860667916992185, -11.015768526367197],
            [-68.858867916992182, -11.012368526367197],
            [-68.859167916992192, -11.008768526367195],
            [-68.859367916992184, -11.004168526367195],
            [-68.855567916992186, -11.001268526367195],
            [-68.852767916992178, -10.997968526367195],
            [-68.848367916992188, -10.991868526367195],
            [-68.842667916992184, -10.991168526367197],
            [-68.840867916992181, -10.993368526367195],
            [-68.840667916992189, -10.996268526367196],
            [-68.843967916992185, -10.998268526367195],
            [-68.840367916992193, -11.000868526367196],
            [-68.83656791699218, -10.998668526367197],
            [-68.835367916992183, -10.994768526367196],
            [-68.833767916992187, -10.992168526367195],
            [-68.830067916992192, -10.993668526367195],
            [-68.826767916992182, -10.995168526367195],
            [-68.825767916992191, -10.998268526367195],
            [-68.82956791699219, -11.000168526367197],
            [-68.82956791699219, -11.003568526367197],
            [-68.827067916992178, -11.005268526367196],
            [-68.822167916992186, -11.002068526367196],
            [-68.81686791699218, -11.003668526367196],
            [-68.812367916992187, -11.000568526367196],
            [-68.808067916992186, -10.998768526367197],
            [-68.810767916992191, -10.994368526367197],
            [-68.805467916992185, -10.993268526367196],
            [-68.801567916992184, -10.992968526367196],
            [-68.798967916992183, -10.989768526367197],
            [-68.796067916992186, -10.988668526367196],
            [-68.792067916992181, -10.989368526367198],
            [-68.793867916992184, -10.993068526367196],
            [-68.796867916992184, -10.994668526367196],
            [-68.796967916992187, -10.998368526367198],
            [-68.79416791699218, -10.998868526367197],
            [-68.789567916992183, -10.999668526367195],
            [-68.78646791699218, -11.003068526367198],
            [-68.782567916992178, -10.999868526367198],
            [-68.779267916992183, -10.997768526367196],
            [-68.777667916992186, -11.003568526367197],
            [-68.772567916992188, -11.004068526367195],
            [-68.769267916992192, -11.005268526367196],
            [-68.765467916992179, -11.004068526367195],
            [-68.76236791699219, -11.002968526367198],
            [-68.758567916992178, -11.000868526367196],
            [-68.758567916992178, -11.005168526367196],
            [-68.75736791699218, -11.009868526367196],
            [-68.754167916992188, -11.008768526367195],
            [-68.751267916992191, -11.007768526367197],
            [-68.748267916992191, -11.009868526367196],
            [-68.750267916992186, -11.014368526367196],
            [-68.749467916992188, -11.018768526367197],
            [-68.752367916992185, -11.022368526367195],
            [-68.75166791699219, -11.025968526367198],
            [-68.751867916992182, -11.029468526367197],
            [-68.75166791699219, -11.033868526367197],
            [-68.752067916992189, -11.037068526367197],
            [-68.756867916992178, -11.038068526367198],
            [-68.760267916992191, -11.040368526367196],
            [-68.760967916992186, -11.044168526367198],
            [-68.764667916992181, -11.047568526367197],
            [-68.764667916992181, -11.051668526367198],
            [-68.76066791699219, -11.054768526367198],
            [-68.76236791699219, -11.058068526367197],
            [-68.763867916992183, -11.061868526367196],
            [-68.764567916992178, -11.066368526367196],
            [-68.76236791699219, -11.070268526367197],
            [-68.758767916992184, -11.073568526367197],
            [-68.756267916992186, -11.077968526367197],
            [-68.75766791699219, -11.082568526367197],
            [-68.75466791699219, -11.084268526367197],
            [-68.750767916992189, -11.086268526367196],
            [-68.748067916992184, -11.090668526367196],
            [-68.745767916992179, -11.094568526367198],
            [-68.741867916992192, -11.096368526367197],
            [-68.738867916992177, -11.094068526367195],
            [-68.735067916992179, -11.093368526367197],
            [-68.731767916992183, -11.095668526367195],
            [-68.729567916992181, -11.097668526367197],
            [-68.727067916992183, -11.099868526367196],
            [-68.72426791699219, -11.101368526367196],
            [-68.72096791699218, -11.104568526367196],
            [-68.721367916992179, -11.109468526367195],
            [-68.722067916992188, -11.113568526367196],
            [-68.719867916992186, -11.116368526367197],
            [-68.717667916992184, -11.118268526367196],
            [-68.715867916992181, -11.120868526367197],
            [-68.714267916992185, -11.123368526367198],
            [-68.712267916992189, -11.126568526367198],
            [-68.711167916992181, -11.129868526367197],
            [-68.711167916992181, -11.134268526367197],
            [-68.712267916992189, -11.138168526367195],
            [-68.714067916992178, -11.140368526367197],
            [-68.715867916992181, -11.143468526367197],
            [-68.614967916992185, -11.125468526367197],
            [-68.54276791699219, -11.110268526367197],
            [-68.543067916992186, -11.105968526367196],
            [-68.542867916992179, -11.102068526367198],
            [-68.541667916992182, -11.099168526367198],
            [-68.53976791699219, -11.096668526367196],
            [-68.53676791699219, -11.094468526367198],
            [-68.531967916992187, -11.092668526367195],
            [-68.526467916992189, -11.091268526367195],
            [-68.522067916992185, -11.089368526367195],
            [-68.519267916992192, -11.087868526367195],
            [-68.516267916992177, -11.083568526367195],
            [-68.51376791699218, -11.080768526367198],
            [-68.510967916992186, -11.078268526367197],
            [-68.508467916992188, -11.074968526367197],
            [-68.505467916992188, -11.069968526367198],
            [-68.504867916992183, -11.065268526367195],
            [-68.503467916992179, -11.062668526367197],
            [-68.500267916992186, -11.061368526367197],
            [-68.497667916992185, -11.059468526367198],
            [-68.495267916992191, -11.056868526367197],
            [-68.491367916992189, -11.054068526367196],
            [-68.486467916992183, -11.050768526367197],
            [-68.482867916992191, -11.050068526367195],
            [-68.47996791699218, -11.048968526367197],
            [-68.47566791699218, -11.051168526367196],
            [-68.471467916992182, -11.050368526367198],
            [-68.468067916992183, -11.049368526367196],
            [-68.464767916992187, -11.049668526367196],
            [-68.460767916992182, -11.046668526367196],
            [-68.455767916992187, -11.045568526367198],
            [-68.451067916992187, -11.046068526367197],
            [-68.447067916992182, -11.046068526367197],
            [-68.44386791699219, -11.045568526367198],
            [-68.443067916992192, -11.042768526367198],
            [-68.439967916992188, -11.040268526367196],
            [-68.438067916992182, -11.036268526367195],
            [-68.435467916992181, -11.033768526367197],
            [-68.433067916992186, -11.031768526367195],
            [-68.429667916992187, -11.032668526367196],
            [-68.428067916992191, -11.035368526367197],
            [-68.426667916992187, -11.038068526367198],
            [-68.423667916992187, -11.040668526367195],
            [-68.419567916992179, -11.040668526367195],
            [-68.415967916992187, -11.039568526367198],
            [-68.412567916992188, -11.042468526367195],
            [-68.409867916992184, -11.044968526367196],
            [-68.409867916992184, -11.048068526367196],
            [-68.407667916992182, -11.049968526367195],
            [-68.404867916992188, -11.053568526367197],
            [-68.400667916992191, -11.052568526367196],
            [-68.396467916992179, -11.051068526367196],
            [-68.393167916992184, -11.048568526367195],
            [-68.390167916992183, -11.044668526367197],
            [-68.388267916992191, -11.039468526367195],
            [-68.387767916992189, -11.035568526367197],
            [-68.389367916992185, -11.032268526367197],
            [-68.390467916992179, -11.029568526367196],
            [-68.389667916992181, -11.026768526367196],
            [-68.388467916992184, -11.023668526367196],
            [-68.385967916992186, -11.019268526367195],
            [-68.382467916992184, -11.017068526367197],
            [-68.377767916992184, -11.014368526367196],
            [-68.372367916992189, -11.012068526367198],
            [-68.369367916992189, -11.010768526367197],
            [-68.366167916992183, -11.009368526367197],
            [-68.362867916992187, -11.007468526367198],
            [-68.358867916992182, -11.005968526367198],
            [-68.354567916992181, -11.005168526367196],
            [-68.351667916992184, -11.004368526367195],
            [-68.348367916992188, -11.004468526367198],
            [-68.344167916992191, -11.005468526367196],
            [-68.340367916992193, -11.005768526367195],
            [-68.337567916992185, -11.007468526367198],
            [-68.337067916992183, -11.010668526367198],
            [-68.333767916992187, -11.012468526367197],
            [-68.32956791699219, -11.011768526367195],
            [-68.326367916992183, -11.009868526367196],
            [-68.323567916992189, -11.006268526367197],
            [-68.322467916992181, -11.002668526367195],
            [-68.318767916992186, -10.999068526367196],
            [-68.314067916992187, -10.997668526367196],
            [-68.310467916992181, -10.995868526367197],
            [-68.308067916992186, -10.992768526367197],
            [-68.302467916992185, -10.991068526367197],
            [-68.298067916992181, -10.990468526367195],
            [-68.29416791699218, -10.990568526367195],
            [-68.288667916992182, -10.988368526367196],
            [-68.285867916992188, -10.990368526367195],
            [-68.282867916992188, -10.990068526367196],
            [-68.27876791699218, -10.989368526367198],
            [-68.276167916992179, -10.987868526367198],
            [-68.271867916992193, -10.983568526367197],
            [-68.270367916992186, -10.978968526367197],
            [-68.265967916992182, -10.975668526367198],
            [-68.262867916992178, -10.975668526367198],
            [-68.258567916992178, -10.973068526367197],
            [-68.254567916992187, -10.969468526367198],
            [-68.252767916992184, -10.966968526367197],
            [-68.250767916992189, -10.963768526367197],
            [-68.246867916992187, -10.960468526367197],
            [-68.242967916992185, -10.959368526367196],
            [-68.240067916992189, -10.958168526367196],
            [-68.237467916992188, -10.955768526367198],
            [-68.236667916992189, -10.952868526367197],
            [-68.233867916992182, -10.949368526367195],
            [-68.23196791699219, -10.945968526367196],
            [-68.230867916992182, -10.943168526367195],
            [-68.230567916992186, -10.939668526367196],
            [-68.230667916992189, -10.935768526367195],
            [-68.229467916992178, -10.932968526367198],
            [-68.229267916992185, -10.929368526367195],
            [-68.227067916992183, -10.925368526367198],
            [-68.228067916992188, -10.921968526367195],
            [-68.22596791699219, -10.917168526367195],
            [-68.224467916992182, -10.914468526367195],
            [-68.223067916992193, -10.911368526367195],
            [-68.219767916992183, -10.909768526367195],
            [-68.21626791699218, -10.905868526367197],
            [-68.21156791699218, -10.905068526367195],
            [-68.207967916992189, -10.902568526367197],
            [-68.206167916992186, -10.897268526367196],
            [-68.205067916992192, -10.892168526367197],
            [-68.203967916992184, -10.887868526367196],
            [-68.204367916992183, -10.883868526367195],
            [-68.205167916992181, -10.879868526367197],
            [-68.202467916992191, -10.877968526367198],
            [-68.200167916992186, -10.875568526367196],
            [-68.198767916992182, -10.872968526367195],
            [-68.197767916992191, -10.870168526367195],
            [-68.197367916992192, -10.867168526367195],
            [-68.196267916992184, -10.864468526367197],
            [-68.195567916992189, -10.861668526367197],
            [-68.194567916992185, -10.858568526367197],
            [-68.191267916992189, -10.857468526367196],
            [-68.186967916992188, -10.857268526367196],
            [-68.182967916992183, -10.855568526367197],
            [-68.18016791699219, -10.852568526367197],
            [-68.177867916992184, -10.850068526367195],
            [-68.176967916992183, -10.847268526367195],
            [-68.174767916992181, -10.844868526367197],
            [-68.173967916992183, -10.841468526367198],
            [-68.171767916992181, -10.838468526367198],
            [-68.168867916992184, -10.837268526367197],
            [-68.165667916992192, -10.835068526367195],
            [-68.162867916992184, -10.832668526367197],
            [-68.159867916992184, -10.829668526367197],
            [-68.157367916992186, -10.826368526367197],
            [-68.156267916992192, -10.823568526367197],
            [-68.153167916992189, -10.821368526367195],
            [-68.150067916992185, -10.819568526367195],
            [-68.146867916992193, -10.817068526367198],
            [-68.143967916992182, -10.814568526367196],
            [-68.141867916992183, -10.811568526367196],
            [-68.139267916992182, -10.807968526367198],
            [-68.136367916992185, -10.807168526367196],
            [-68.133767916992184, -10.804668526367195],
            [-68.130467916992188, -10.800268526367198],
            [-68.127167916992192, -10.797268526367198],
            [-68.12466791699218, -10.793868526367195],
            [-68.122767916992188, -10.791068526367198],
            [-68.119467916992193, -10.788168526367198],
            [-68.116567916992182, -10.783368526367195],
            [-68.112567916992191, -10.782068526367198],
            [-68.109467916992187, -10.780368526367198],
            [-68.10666791699218, -10.778668526367195],
            [-68.104267916992185, -10.774768526367197],
            [-68.104467916992178, -10.770368526367196],
            [-68.10526791699219, -10.766868526367197],
            [-68.105667916992189, -10.762368526367197],
            [-68.102767916992178, -10.759568526367197],
            [-68.102767916992178, -10.755968526367198],
            [-68.101367916992189, -10.753268526367197],
            [-68.099167916992187, -10.751368526367198],
            [-68.098067916992193, -10.747768526367196],
            [-68.100367916992184, -10.744768526367196],
            [-68.103067916992188, -10.740868526367198],
            [-68.10496791699218, -10.737568526367195],
            [-68.105567916992186, -10.734268526367195],
            [-68.106167916992177, -10.731468526367195],
            [-68.106067916992188, -10.727568526367197],
            [-68.107567916992181, -10.724568526367197],
            [-68.105567916992186, -10.721268526367197],
            [-68.10526791699219, -10.717268526367196],
            [-68.103867916992186, -10.714768526367195],
            [-68.101367916992189, -10.712068526367197],
            [-68.097767916992183, -10.709868526367195],
            [-68.095367916992188, -10.707668526367197],
            [-68.09596791699218, -10.704368526367197],
            [-68.092667916992184, -10.704268526367198],
            [-68.088967916992189, -10.700768526367195],
            [-68.085867916992186, -10.696368526367195],
            [-68.081267916992189, -10.693468526367198],
            [-68.078567916992185, -10.690468526367198],
            [-68.076767916992182, -10.687968526367197],
            [-68.076367916992183, -10.684868526367197],
            [-68.07186791699219, -10.684468526367198],
            [-68.066567916992184, -10.683668526367196],
            [-68.064067916992187, -10.681168526367195],
            [-68.06246791699219, -10.677168526367197],
            [-68.058667916992192, -10.674968526367195],
            [-68.055267916992193, -10.671468526367196],
            [-68.051567916992184, -10.671068526367197],
            [-68.047567916992179, -10.671368526367196],
            [-68.043167916992189, -10.669568526367197],
            [-68.039167916992184, -10.668168526367197],
            [-68.035867916992188, -10.666668526367197],
            [-68.032867916992188, -10.665368526367196],
            [-68.030667916992186, -10.662868526367195],
            [-68.032367916992186, -10.660568526367197],
            [-68.031267916992192, -10.656268526367196],
            [-68.027267916992187, -10.656568526367195],
            [-68.023767916992185, -10.654468526367197],
            [-68.021767916992189, -10.650968526367198],
            [-68.01506791699218, -10.649868526367197],
            [-68.011367916992185, -10.648268526367197],
            [-68.008767916992184, -10.650068526367196],
            [-68.004567916992187, -10.650468526367195],
            [-68.002367916992185, -10.647668526367195],
            [-68.000167916992183, -10.649768526367197],
            [-67.99666791699218, -10.650868526367198],
            [-67.992967916992185, -10.651768526367196],
            [-67.988667916992185, -10.653368526367196],
            [-67.985367916992189, -10.655168526367195],
            [-67.98166791699218, -10.657268526367197],
            [-67.978567916992191, -10.660868526367196],
            [-67.974567916992186, -10.660568526367197],
            [-67.971467916992182, -10.658368526367195],
            [-67.970667916992184, -10.655568526367198],
            [-67.965967916992184, -10.654068526367197],
            [-67.962867916992181, -10.651268526367197],
            [-67.959567916992185, -10.649268526367198],
            [-67.95456791699219, -10.649868526367197],
            [-67.95156791699219, -10.652268526367195],
            [-67.94816791699219, -10.651468526367196],
            [-67.94356791699218, -10.653368526367196],
            [-67.940467916992191, -10.655068526367195],
            [-67.936967916992188, -10.655068526367195],
            [-67.933267916992179, -10.654768526367196],
            [-67.928667916992183, -10.654868526367196],
            [-67.925567916992179, -10.652368526367198],
            [-67.922267916992183, -10.651568526367196],
            [-67.91776791699219, -10.651768526367196],
            [-67.913667916992182, -10.649068526367195],
            [-67.911967916992182, -10.645668526367196],
            [-67.909167916992189, -10.642368526367196],
            [-67.90376791699218, -10.641768526367198],
            [-67.900367916992181, -10.643468526367197],
            [-67.897067916992185, -10.644268526367195],
            [-67.89506791699219, -10.646768526367197],
            [-67.890467916992179, -10.644368526367195],
            [-67.889267916992182, -10.640668526367197],
            [-67.885167916992188, -10.640668526367197],
            [-67.88236791699218, -10.640068526367195],
            [-67.877367916992185, -10.640168526367198],
            [-67.876067916992184, -10.643268526367198],
            [-67.872667916992185, -10.642868526367195],
            [-67.868867916992187, -10.641868526367197],
            [-67.864767916992179, -10.640768526367197],
            [-67.860267916992186, -10.643468526367197],
            [-67.857767916992188, -10.646068526367195],
            [-67.854167916992182, -10.647868526367198],
            [-67.849567916992186, -10.647868526367198],
            [-67.84456791699219, -10.649768526367197],
            [-67.840367916992193, -10.651368526367197],
            [-67.836167916992181, -10.650068526367196],
            [-67.833267916992185, -10.650968526367198],
            [-67.830067916992192, -10.651868526367195],
            [-67.82656791699219, -10.651568526367196],
            [-67.822467916992181, -10.654468526367197],
            [-67.81886791699219, -10.656568526367195],
            [-67.817667916992178, -10.660668526367196],
            [-67.814067916992187, -10.663668526367196],
            [-67.80946791699219, -10.661968526367197],
            [-67.807567916992184, -10.665968526367195],
            [-67.804367916992192, -10.667568526367198],
            [-67.801667916992187, -10.669168526367198],
            [-67.797867916992189, -10.670568526367198],
            [-67.794667916992182, -10.674268526367197],
            [-67.79146791699219, -10.676868526367198],
            [-67.786967916992182, -10.676068526367196],
            [-67.782267916992183, -10.676668526367198],
            [-67.780067916992181, -10.678568526367197],
            [-67.777367916992191, -10.680468526367196],
            [-67.773967916992177, -10.683668526367196],
            [-67.769267916992192, -10.685468526367195],
            [-67.76506791699218, -10.687668526367197],
            [-67.76206791699218, -10.684768526367197],
            [-67.758167916992193, -10.685168526367196],
            [-67.756267916992186, -10.687668526367197],
            [-67.755267916992182, -10.691968526367198],
            [-67.752967916992191, -10.695168526367198],
            [-67.749467916992188, -10.696568526367198],
            [-67.745067916992184, -10.697168526367197],
            [-67.744067916992179, -10.700268526367196],
            [-67.74366791699218, -10.703268526367197],
            [-67.742767916992193, -10.707568526367197],
            [-67.739767916992179, -10.707068526367195],
            [-67.73796791699219, -10.709368526367196],
            [-67.735067916992179, -10.711568526367195],
            [-67.730667916992189, -10.711768526367198],
            [-67.727367916992179, -10.708568526367195],
            [-67.725067916992188, -10.706268526367197],
            [-67.72096791699218, -10.709268526367197],
            [-67.71656791699219, -10.708468526367195],
            [-67.712867916992181, -10.707368526367198],
            [-67.708467916992191, -10.707568526367197],
            [-67.707867916992186, -10.704368526367197],
            [-67.706467916992182, -10.700768526367195],
            [-67.702667916992183, -10.692068526367198],
            [-67.67686791699218, -10.607868526367195],
            [-67.675367916992187, -10.603068526367196],
            [-67.67216791699218, -10.604968526367195],
            [-67.668367916992182, -10.608268526367198],
            [-67.66346791699219, -10.612768526367198],
            [-67.658967916992182, -10.614468526367197],
            [-67.654267916992183, -10.611068526367195],
            [-67.647867916992183, -10.604968526367195],
            [-67.643467916992179, -10.600968526367197],
            [-67.64036791699219, -10.598668526367195],
            [-67.637667916992186, -10.594868526367197],
            [-67.636467916992189, -10.590468526367196],
            [-67.636267916992182, -10.584568526367196],
            [-67.634667916992186, -10.581168526367197],
            [-67.63136791699219, -10.578468526367196],
            [-67.623067916992184, -10.573468526367197],
            [-67.619967916992181, -10.572368526367196],
            [-67.615067916992189, -10.567368526367197],
            [-67.612167916992178, -10.562668526367197],
            [-67.611167916992187, -10.557168526367196],
            [-67.60966791699218, -10.552868526367195],
            [-67.607567916992181, -10.548168526367196],
            [-67.60526791699219, -10.544168526367198],
            [-67.601367916992189, -10.538368526367197],
            [-67.59926791699219, -10.533668526367197],
            [-67.597367916992184, -10.528768526367195],
            [-67.595867916992191, -10.523968526367195],
            [-67.593967916992185, -10.518968526367196],
            [-67.59126791699218, -10.515068526367195],
            [-67.588967916992189, -10.510768526367197],
            [-67.583967916992179, -10.507468526367198],
            [-67.579667916992179, -10.504068526367195],
            [-67.575467916992181, -10.501868526367197],
            [-67.57016791699219, -10.501868526367197],
            [-67.567367916992183, -10.499368526367196],
            [-67.562967916992193, -10.498068526367195],
            [-67.560767916992191, -10.496068526367196],
            [-67.556567916992179, -10.496168526367196],
            [-67.554067916992182, -10.494368526367197],
            [-67.551667916992187, -10.492468526367198],
            [-67.547867916992189, -10.489468526367197],
            [-67.543867916992184, -10.489668526367197],
            [-67.539967916992182, -10.488668526367196],
            [-67.535167916992179, -10.488668526367196],
            [-67.530367916992191, -10.490468526367195],
            [-67.527267916992187, -10.491068526367197],
            [-67.519667916992191, -10.490768526367198],
            [-67.516467916992184, -10.488268526367197],
            [-67.515667916992186, -10.482168526367197],
            [-67.513467916992184, -10.478468526367195],
            [-67.508867916992187, -10.475568526367198],
            [-67.505467916992188, -10.472568526367198],
            [-67.50166791699219, -10.470968526367198],
            [-67.497467916992179, -10.471668526367196],
            [-67.494667916992185, -10.472268526367195],
            [-67.491067916992179, -10.472368526367195],
            [-67.487867916992187, -10.470668526367195],
            [-67.483367916992179, -10.469068526367195],
            [-67.479267916992185, -10.468368526367197],
            [-67.476367916992189, -10.466968526367197],
            [-67.474167916992187, -10.464268526367196],
            [-67.47126791699219, -10.461168526367196],
            [-67.467067916992193, -10.460068526367195],
            [-67.464067916992178, -10.459768526367196],
            [-67.458467916992191, -10.458768526367198],
            [-67.453267916992189, -10.457968526367196],
            [-67.448867916992185, -10.455368526367195],
            [-67.444167916992185, -10.453568526367196],
            [-67.440467916992191, -10.449268526367195],
            [-67.43586791699218, -10.446568526367198],
            [-67.436667916992178, -10.442668526367196],
            [-67.436367916992182, -10.438768526367195],
            [-67.432967916992183, -10.435168526367196],
            [-67.430867916992185, -10.431168526367195],
            [-67.428267916992183, -10.427968526367195],
            [-67.42386791699218, -10.428868526367197],
            [-67.419567916992179, -10.429168526367196],
            [-67.415867916992184, -10.426968526367197],
            [-67.41346791699219, -10.425068526367195],
            [-67.413867916992189, -10.422168526367198],
            [-67.411967916992182, -10.418468526367196],
            [-67.412867916992184, -10.414568526367198],
            [-67.413367916992186, -10.410668526367196],
            [-67.41446791699218, -10.406268526367196],
            [-67.41446791699218, -10.401168526367197],
            [-67.41776791699219, -10.397568526367195],
            [-67.421867916992184, -10.396268526367198],
            [-67.423267916992188, -10.393268526367198],
            [-67.421167916992189, -10.388568526367198],
            [-67.416667916992182, -10.384568526367197],
            [-67.41316791699218, -10.381368526367197],
            [-67.410867916992188, -10.378268526367197],
            [-67.409167916992189, -10.375468526367197],
            [-67.405667916992186, -10.372968526367195],
            [-67.401967916992191, -10.372668526367196],
            [-67.39906791699218, -10.371368526367196],
            [-67.397667916992191, -10.374168526367196],
            [-67.395967916992191, -10.376668526367197],
            [-67.393767916992189, -10.374768526367195],
            [-67.389567916992178, -10.376968526367197],
            [-67.384167916992183, -10.379068526367195],
            [-67.379067916992184, -10.381368526367197],
            [-67.37466791699218, -10.380768526367195],
            [-67.371367916992185, -10.379968526367197],
            [-67.368267916992181, -10.376568526367198],
            [-67.364167916992187, -10.378568526367197],
            [-67.362567916992191, -10.381868526367196],
            [-67.360867916992191, -10.384168526367198],
            [-67.358567916992186, -10.387868526367196],
            [-67.355567916992186, -10.391368526367195],
            [-67.35196791699218, -10.391568526367195],
            [-67.353067916992188, -10.387368526367197],
            [-67.353467916992187, -10.382768526367197],
            [-67.351667916992184, -10.379168526367195],
            [-67.348067916992193, -10.377968526367198],
            [-67.344167916992191, -10.376068526367195],
            [-67.339367916992188, -10.376268526367195],
            [-67.33696791699218, -10.377968526367198],
            [-67.333767916992187, -10.378768526367196],
            [-67.331467916992182, -10.381368526367197],
            [-67.328267916992189, -10.382368526367195],
            [-67.324067916992192, -10.380568526367195],
            [-67.319567916992185, -10.379868526367197],
            [-67.317067916992187, -10.376368526367198],
            [-67.313267916992189, -10.376268526367195],
            [-67.311267916992179, -10.372968526367195],
            [-67.312767916992186, -10.370068526367195],
            [-67.314667916992192, -10.367968526367196],
            [-67.316967916992184, -10.365568526367195],
            [-67.316367916992192, -10.361668526367197],
            [-67.317967916992188, -10.358568526367197],
            [-67.316367916992192, -10.355268526367198],
            [-67.31556791699218, -10.347068526367195],
            [-67.317667916992178, -10.344168526367195],
            [-67.320267916992179, -10.340868526367196],
            [-67.324967916992179, -10.337968526367195],
            [-67.327067916992178, -10.334368526367196],
            [-67.325667916992188, -10.330368526367195],
            [-67.324067916992192, -10.327068526367196],
            [-67.321367916992187, -10.324068526367196],
            [-67.32156791699218, -10.321068526367196],
            [-67.317667916992178, -10.320468526367197],
            [-67.314867916992185, -10.317768526367196],
            [-67.311267916992179, -10.319868526367195],
            [-67.308067916992186, -10.320968526367196],
            [-67.305267916992193, -10.321768526367197],
            [-67.30216791699219, -10.323168526367198],
            [-67.300067916992191, -10.320468526367197],
            [-67.296667916992192, -10.320968526367196],
            [-67.294967916992192, -10.323268526367197],
            [-67.29146791699219, -10.323268526367197],
            [-67.289167916992184, -10.320168526367198],
            [-67.285567916992193, -10.319268526367196],
            [-67.280967916992182, -10.320268526367197],
            [-67.276167916992179, -10.321568526367198],
            [-67.273967916992177, -10.324568526367198],
            [-67.270367916992186, -10.324868526367197],
            [-67.268167916992184, -10.327068526367196],
            [-67.265667916992186, -10.331168526367197],
            [-67.26206791699218, -10.330768526367198],
            [-67.25766791699219, -10.328868526367195],
            [-67.254067916992184, -10.327868526367197],
            [-67.250967916992181, -10.326768526367196],
            [-67.247767916992188, -10.324568526367198],
            [-67.243367916992185, -10.323168526367198],
            [-67.241467916992178, -10.319068526367197],
            [-67.238067916992179, -10.318768526367197],
            [-67.235067916992179, -10.316068526367197],
            [-67.23166791699218, -10.315468526367198],
            [-67.227567916992186, -10.316068526367197],
            [-67.22426791699219, -10.314868526367196],
            [-67.222067916992188, -10.316668526367195],
            [-67.220567916992181, -10.319968526367198],
            [-67.217267916992185, -10.322368526367196],
            [-67.212267916992189, -10.323168526367198],
            [-67.209067916992183, -10.322868526367195],
            [-67.205667916992184, -10.322868526367195],
            [-67.202067916992178, -10.322068526367197],
            [-67.198767916992182, -10.323268526367197],
            [-67.195967916992188, -10.325368526367196],
            [-67.192767916992182, -10.329068526367195],
            [-67.190967916992179, -10.333268526367196],
            [-67.188767916992191, -10.337568526367196],
            [-67.184967916992179, -10.337868526367195],
            [-67.182167916992185, -10.338668526367197],
            [-67.178967916992178, -10.339368526367195],
            [-67.175567916992179, -10.338668526367197],
            [-67.171367916992182, -10.337668526367196],
            [-67.167167916992184, -10.335668526367197],
            [-67.16476791699219, -10.333568526367195],
            [-67.16316791699218, -10.329968526367196],
            [-67.159867916992184, -10.328768526367195],
            [-67.156567916992188, -10.328568526367196],
            [-67.153367916992181, -10.327468526367195],
            [-67.149767916992189, -10.325768526367195],
            [-67.146867916992193, -10.323468526367197],
            [-67.14506791699219, -10.325968526367195],
            [-67.142167916992179, -10.322968526367195],
            [-67.140167916992183, -10.319068526367197],
            [-67.138967916992186, -10.315868526367197],
            [-67.136367916992185, -10.318068526367195],
            [-67.132967916992186, -10.316268526367196],
            [-67.128867916992192, -10.315168526367195],
            [-67.130767916992184, -10.312268526367195],
            [-67.133567916992178, -10.309868526367197],
            [-67.136367916992185, -10.309368526367198],
            [-67.139567916992178, -10.308768526367196],
            [-67.139567916992178, -10.305468526367196],
            [-67.137967916992181, -10.302468526367196],
            [-67.13736791699219, -10.298868526367198],
            [-67.13566791699219, -10.296168526367197],
            [-67.132767916992179, -10.294568526367197],
            [-67.12936791699218, -10.292268526367195],
            [-67.124767916992184, -10.290568526367196],
            [-67.120867916992182, -10.292268526367195],
            [-67.117167916992187, -10.292568526367198],
            [-67.113267916992186, -10.291168526367198],
            [-67.10996791699219, -10.288668526367196],
            [-67.106367916992184, -10.287368526367196],
            [-67.102767916992178, -10.285568526367197],
            [-67.099567916992186, -10.285568526367197],
            [-67.098067916992193, -10.282268526367197],
            [-67.093167916992186, -10.283768526367197],
            [-67.088767916992182, -10.285568526367197],
            [-67.088967916992189, -10.288968526367196],
            [-67.085467916992187, -10.289868526367197],
            [-67.082367916992183, -10.288068526367198],
            [-67.079067916992187, -10.288368526367197],
            [-67.075967916992184, -10.284768526367195],
            [-67.072067916992182, -10.284268526367196],
            [-67.068467916992191, -10.283468526367198],
            [-67.065267916992184, -10.282268526367197],
            [-67.061867916992185, -10.280868526367197],
            [-67.058667916992192, -10.278768526367195],
            [-67.055767916992181, -10.278668526367195],
            [-67.05186791699218, -10.276468526367196],
            [-67.048567916992184, -10.274568526367197],
            [-67.051567916992184, -10.271768526367197],
            [-67.051167916992185, -10.267168526367197],
            [-67.047267916992183, -10.265368526367197],
            [-67.043667916992177, -10.265968526367196],
            [-67.042167916992184, -10.263268526367195],
            [-67.039167916992184, -10.262068526367198],
            [-67.035167916992179, -10.263468526367195],
            [-67.032867916992188, -10.259968526367196],
            [-67.030567916992183, -10.256768526367196],
            [-67.027267916992187, -10.257168526367195],
            [-67.023467916992189, -10.256068526367198],
            [-67.019267916992192, -10.255168526367196],
            [-67.015667916992186, -10.256768526367196],
            [-67.012667916992186, -10.254668526367198],
            [-67.009967916992181, -10.251668526367197],
            [-67.009567916992182, -10.247968526367195],
            [-67.006867916992178, -10.245468526367198],
            [-67.004867916992183, -10.241168526367197],
            [-67.005967916992191, -10.237568526367195],
            [-67.00636791699219, -10.233968526367196],
            [-67.004467916992184, -10.230568526367197],
            [-67.000467916992193, -10.229168526367197],
            [-66.99836791699218, -10.231668526367198],
            [-66.995167916992187, -10.229168526367197],
            [-66.993567916992191, -10.223768526367195],
            [-66.988967916992181, -10.221468526367197],
            [-66.989467916992183, -10.218168526367197],
            [-66.994667916992185, -10.218368526367197],
            [-66.997667916992185, -10.218968526367195],
            [-66.999067916992189, -10.215668526367196],
            [-66.997267916992186, -10.211968526367198],
            [-66.993867916992187, -10.209668526367196],
            [-66.993867916992187, -10.205768526367198],
            [-66.996167916992192, -10.203568526367196],
            [-66.995867916992182, -10.199668526367198],
            [-66.992267916992191, -10.198968526367196],
            [-66.992167916992187, -10.202668526367198],
            [-66.988367916992189, -10.201368526367197],
            [-66.98466791699218, -10.198568526367197],
            [-66.981067916992188, -10.195468526367197],
            [-66.974867916992181, -10.195768526367196],
            [-66.977567916992186, -10.192068526367198],
            [-66.979767916992188, -10.189468526367197],
            [-66.97556791699219, -10.188768526367195],
            [-66.971967916992185, -10.191668526367195],
            [-66.968767916992192, -10.191668526367195],
            [-66.96626791699218, -10.189968526367196],
            [-66.963967916992189, -10.186868526367196],
            [-66.959967916992184, -10.183968526367195],
            [-66.955067916992192, -10.188268526367196],
            [-66.951467916992186, -10.186168526367197],
            [-66.950667916992188, -10.181668526367197],
            [-66.949967916992179, -10.177168526367197],
            [-66.948567916992189, -10.173368526367195],
            [-66.947667916992188, -10.169268526367198],
            [-66.946267916992184, -10.164468526367195],
            [-66.946367916992187, -10.160068526367198],
            [-66.94486791699218, -10.157668526367196],
            [-66.941367916992192, -10.155068526367195],
            [-66.937767916992186, -10.154168526367197],
            [-66.933567916992189, -10.153068526367196],
            [-66.933367916992182, -10.148168526367197],
            [-66.929367916992192, -10.147168526367196],
            [-66.925867916992189, -10.145168526367197],
            [-66.923567916992184, -10.142968526367195],
            [-66.920267916992188, -10.144268526367195],
            [-66.916367916992186, -10.144568526367195],
            [-66.911967916992182, -10.146568526367197],
            [-66.910567916992193, -10.141268526367195],
            [-66.911167916992184, -10.138468526367195],
            [-66.91346791699219, -10.136068526367197],
            [-66.917067916992181, -10.134868526367196],
            [-66.917167916992184, -10.129868526367197],
            [-66.913867916992189, -10.128768526367196],
            [-66.912267916992192, -10.131268526367197],
            [-66.909167916992189, -10.129868526367197],
            [-66.90876791699219, -10.125268526367197],
            [-66.902967916992182, -10.126068526367195],
            [-66.901167916992179, -10.123068526367195],
            [-66.897867916992183, -10.121668526367195],
            [-66.897067916992185, -10.118268526367196],
            [-66.897267916992178, -10.115068526367196],
            [-66.897967916992187, -10.111668526367197],
            [-66.89506791699219, -10.109968526367197],
            [-66.89206791699219, -10.112568526367195],
            [-66.88906791699219, -10.114068526367195],
            [-66.88876791699218, -10.110268526367197],
            [-66.888967916992186, -10.105368526367197],
            [-66.888567916992187, -10.101768526367195],
            [-66.886467916992189, -10.099468526367197],
            [-66.88566791699219, -10.103568526367198],
            [-66.883767916992184, -10.107068526367197],
            [-66.880267916992182, -10.104668526367195],
            [-66.881267916992186, -10.100368526367195],
            [-66.878267916992186, -10.097368526367195],
            [-66.87666791699219, -10.100068526367195],
            [-66.873267916992191, -10.100568526367198],
            [-66.871867916992187, -10.093768526367196],
            [-66.874067916992189, -10.091268526367195],
            [-66.872967916992181, -10.087568526367196],
            [-66.875467916992193, -10.083968526367197],
            [-66.87336791699218, -10.080768526367198],
            [-66.869667916992185, -10.080968526367197],
            [-66.866167916992183, -10.083168526367196],
            [-66.860367916992189, -10.082668526367197],
            [-66.855367916992179, -10.083268526367196],
            [-66.851367916992189, -10.081768526367195],
            [-66.852067916992183, -10.085168526367195],
            [-66.849167916992187, -10.085168526367195],
            [-66.844867916992186, -10.085068526367195],
            [-66.841167916992191, -10.083668526367195],
            [-66.837867916992181, -10.081268526367197],
            [-66.839067916992178, -10.076868526367196],
            [-66.839567916992181, -10.073268526367197],
            [-66.840067916992183, -10.069668526367195],
            [-66.835067916992188, -10.066668526367195],
            [-66.83656791699218, -10.063768526367195],
            [-66.835467916992187, -10.060868526367198],
            [-66.832167916992191, -10.059068526367195],
            [-66.82656791699219, -10.059668526367197],
            [-66.822367916992192, -10.058368526367197],
            [-66.81986791699218, -10.055868526367195],
            [-66.817967916992188, -10.052168526367197],
            [-66.81246791699219, -10.048868526367198],
            [-66.808667916992192, -10.048568526367195],
            [-66.806567916992179, -10.044768526367196],
            [-66.802267916992179, -10.043068526367197],
            [-66.805767916992181, -10.040268526367196],
            [-66.807167916992185, -10.037768526367195],
            [-66.802467916992185, -10.034868526367195],
            [-66.800267916992183, -10.032068526367198],
            [-66.79746791699219, -10.028768526367195],
            [-66.793867916992184, -10.027268526367195],
            [-66.792267916992188, -10.024368526367198],
            [-66.789967916992182, -10.021068526367195],
            [-66.786267916992188, -10.020368526367196],
            [-66.778967916992187, -10.016868526367197],
            [-66.775067916992185, -10.018268526367198],
            [-66.771767916992189, -10.018668526367197],
            [-66.772067916992185, -10.015168526367198],
            [-66.772967916992187, -10.011468526367196],
            [-66.771167916992184, -10.008768526367195],
            [-66.768467916992179, -10.007668526367198],
            [-66.765667916992186, -10.006368526367197],
            [-66.764567916992178, -10.002968526367198],
            [-66.764567916992178, -9.999068526367196],
            [-66.760967916992186, -9.997968526367195],
            [-66.757067916992185, -9.998568526367198],
            [-66.754067916992184, -9.998268526367195],
            [-66.752367916992185, -9.994668526367196],
            [-66.74836791699218, -9.993368526367195],
            [-66.743267916992181, -9.995468526367198],
            [-66.73936791699218, -9.993568526367195],
            [-66.737467916992188, -9.991468526367196],
            [-66.735067916992179, -9.987168526367196],
            [-66.73496791699219, -9.983968526367196],
            [-66.732867916992191, -9.981168526367195],
            [-66.729267916992185, -9.979768526367195],
            [-66.725867916992186, -9.980668526367197],
            [-66.721967916992185, -9.981368526367195],
            [-66.722667916992179, -9.978068526367196],
            [-66.72096791699218, -9.974968526367196],
            [-66.718767916992192, -9.973068526367197],
            [-66.715867916992181, -9.974168526367198],
            [-66.713667916992179, -9.977068526367198],
            [-66.709267916992189, -9.975968526367197],
            [-66.70456791699219, -9.974768526367196],
            [-66.698567916992189, -9.970968526367198],
            [-66.695467916992186, -9.968368526367197],
            [-66.691667916992188, -9.965568526367196],
            [-66.687967916992193, -9.965568526367196],
            [-66.684867916992189, -9.964868526367198],
            [-66.680467916992185, -9.965368526367197],
            [-66.676367916992177, -9.966268526367195],
            [-66.676167916992185, -9.962068526367197],
            [-66.67716791699219, -9.957568526367197],
            [-66.67386791699218, -9.956068526367197],
            [-66.669967916992192, -9.957368526367198],
            [-66.668967916992187, -9.952668526367198],
            [-66.66746791699218, -9.949568526367198],
            [-66.664567916992183, -9.948468526367197],
            [-66.666367916992186, -9.945468526367197],
            [-66.665867916992184, -9.942368526367197],
            [-66.664967916992182, -9.939568526367196],
            [-66.663367916992186, -9.936868526367196],
            [-66.660167916992179, -9.935768526367195],
            [-66.65676791699218, -9.935168526367196],
            [-66.654767916992185, -9.938868526367198],
            [-66.651967916992191, -9.935868526367198],
            [-66.650467916992184, -9.940568526367198],
            [-66.648467916992189, -9.943868526367197],
            [-66.645767916992185, -9.945168526367198],
            [-66.642567916992192, -9.945268526367197],
            [-66.641067916992185, -9.948468526367197],
            [-66.637767916992189, -9.948968526367196],
            [-66.63706791699218, -9.945768526367196],
            [-66.635767916992179, -9.940868526367197],
            [-66.632767916992179, -9.935768526367195],
            [-66.629867916992183, -9.935568526367195],
            [-66.62636791699218, -9.931868526367197],
            [-66.631667916992185, -9.928068526367195],
            [-66.635267916992191, -9.926168526367196],
            [-66.633467916992188, -9.921068526367197],
            [-66.627967916992191, -9.919268526367198],
            [-66.628467916992179, -9.915268526367196],
            [-66.633767916992184, -9.912868526367195],
            [-66.63266791699219, -9.908768526367197],
            [-66.628267916992186, -9.906168526367196],
            [-66.624367916992185, -9.904468526367197],
            [-66.626567916992187, -9.899568526367197],
            [-66.62336791699218, -9.896768526367197],
            [-66.620267916992191, -9.894668526367198],
            [-66.616167916992183, -9.894868526367198],
            [-66.608967916992185, -9.895168526367197],
            [-66.603167916992192, -9.897368526367195],
            [-66.600367916992184, -9.898168526367197],
            [-66.591667916992179, -9.899068526367195],
            [-66.587567916992185, -9.900368526367195],
            [-66.581267916992189, -9.905368526367198],
            [-66.57926791699218, -9.901868526367195],
            [-66.578767916992177, -9.894768526367198],
            [-66.576467916992186, -9.892968526367195],
            [-66.574267916992184, -9.890068526367195],
            [-66.570967916992188, -9.892668526367196],
            [-66.567367916992183, -9.894568526367195],
            [-66.564567916992189, -9.890968526367196],
            [-66.562267916992184, -9.888168526367195],
            [-66.565967916992179, -9.886268526367196],
            [-66.564567916992189, -9.883468526367196],
            [-66.560467916992181, -9.880968526367198],
            [-66.553267916992183, -9.885968526367197],
            [-66.54886791699218, -9.888768526367198],
            [-66.546067916992186, -9.885468526367195],
            [-66.547267916992183, -9.882768526367197],
            [-66.545267916992188, -9.880568526367195],
            [-66.543167916992189, -9.877668526367195],
            [-66.53846791699219, -9.882068526367195],
            [-66.533667916992187, -9.885168526367195],
            [-66.527967916992182, -9.887068526367198],
            [-66.52006791699219, -9.885168526367195],
            [-66.515767916992189, -9.882368526367195],
            [-66.51376791699218, -9.876568526367198],
            [-66.51066791699219, -9.876668526367197],
            [-66.499467916992188, -9.876268526367195],
            [-66.501267916992191, -9.881268526367197],
            [-66.496267916992181, -9.883768526367195],
            [-66.491067916992179, -9.882468526367198],
            [-66.491167916992183, -9.879068526367195],
            [-66.489167916992187, -9.875168526367197],
            [-66.488067916992179, -9.869768526367196],
            [-66.482167916992182, -9.868668526367195],
            [-66.477067916992183, -9.868668526367195],
            [-66.471667916992189, -9.868568526367195],
            [-66.467867916992191, -9.871068526367196],
            [-66.464867916992191, -9.875768526367196],
            [-66.460667916992179, -9.878568526367197],
            [-66.455367916992188, -9.879168526367195],
            [-66.458667916992184, -9.874468526367195],
            [-66.453967916992184, -9.870868526367197],
            [-66.448767916992182, -9.871868526367198],
            [-66.44516791699219, -9.871368526367196],
            [-66.440167916992181, -9.869468526367196],
            [-66.437667916992183, -9.866968526367195],
            [-66.434767916992186, -9.866068526367197],
            [-66.430767916992181, -9.867668526367197],
            [-66.42686791699218, -9.870768526367197],
            [-66.424767916992181, -9.873668526367197],
            [-66.428267916992183, -9.883868526367195],
            [-66.432167916992185, -9.891868526367197],
            [-66.431567916992179, -9.894768526367198],
            [-66.42716791699219, -9.899268526367198],
            [-66.419967916992192, -9.898268526367197],
            [-66.41346791699219, -9.895068526367197],
            [-66.41946791699219, -9.895168526367197],
            [-66.423567916992184, -9.891768526367198],
            [-66.42246791699219, -9.887168526367198],
            [-66.41776791699219, -9.883468526367196],
            [-66.411967916992182, -9.880968526367198],
            [-66.409467916992185, -9.874668526367195],
            [-66.407667916992182, -9.871968526367198],
            [-66.402667916992186, -9.868268526367196],
            [-66.403967916992187, -9.864968526367196],
            [-66.395167916992179, -9.864168526367198],
            [-66.382167916992188, -9.867768526367197],
            [-66.371867916992187, -9.866368526367197],
            [-66.366667916992185, -9.866068526367197],
            [-66.363667916992185, -9.865368526367195],
            [-66.359367916992184, -9.865368526367195],
            [-66.357267916992186, -9.857568526367196],
            [-66.356367916992184, -9.854468526367196],
            [-66.356367916992184, -9.851168526367196],
            [-66.355567916992186, -9.848368526367196],
            [-66.351667916992184, -9.847268526367195],
            [-66.35096791699219, -9.841268526367195],
            [-66.346167916992187, -9.839268526367196],
            [-66.340867916992181, -9.840568526367196],
            [-66.337567916992185, -9.840668526367196],
            [-66.334267916992189, -9.839768526367195],
            [-66.328167916992186, -9.836868526367198],
            [-66.320667916992193, -9.837068526367197],
            [-66.317367916992183, -9.835368526367198],
            [-66.315167916992181, -9.831768526367195],
            [-66.311267916992179, -9.836268526367196],
            [-66.306867916992189, -9.839868526367198],
            [-66.304367916992192, -9.835368526367198],
            [-66.301567916992184, -9.837668526367196],
            [-66.295267916992188, -9.840668526367196],
            [-66.290667916992192, -9.841468526367198],
            [-66.286667916992187, -9.844568526367198],
            [-66.282267916992183, -9.840868526367196],
            [-66.282267916992183, -9.831868526367195],
            [-66.278667916992191, -9.828568526367196],
            [-66.275367916992181, -9.827668526367198],
            [-66.275467916992184, -9.834268526367197],
            [-66.262167916992183, -9.830768526367198],
            [-66.25766791699219, -9.833468526367195],
            [-66.253867916992192, -9.832668526367197],
            [-66.249767916992184, -9.827568526367195],
            [-66.248567916992187, -9.821868526367197],
            [-66.242967916992185, -9.822168526367197],
            [-66.23966791699219, -9.816668526367195],
            [-66.237167916992178, -9.814568526367196],
            [-66.234767916992183, -9.816668526367195],
            [-66.228067916992188, -9.820268526367197],
            [-66.219767916992183, -9.827868526367197],
            [-66.214267916992185, -9.833268526367196],
            [-66.210667916992179, -9.834868526367195],
            [-66.204867916992185, -9.834868526367195],
            [-66.199967916992179, -9.831768526367195],
            [-66.197167916992186, -9.830668526367198],
            [-66.194167916992185, -9.827868526367197],
            [-66.197367916992192, -9.823868526367196],
            [-66.194967916992184, -9.819968526367198],
            [-66.188367916992178, -9.824268526367195],
            [-66.18586791699218, -9.821368526367195],
            [-66.189967916992188, -9.818268526367195],
            [-66.19216791699219, -9.815568526367198],
            [-66.196767916992187, -9.813568526367195],
            [-66.19386791699219, -9.805468526367196],
            [-66.186867916992185, -9.805468526367196],
            [-66.181067916992191, -9.803568526367197],
            [-66.176967916992183, -9.803368526367198],
            [-66.176467916992181, -9.799168526367197],
            [-66.173167916992185, -9.793968526367195],
            [-66.168067916992186, -9.791068526367198],
            [-66.16306791699219, -9.790268526367196],
            [-66.159867916992184, -9.787768526367195],
            [-66.155567916992183, -9.787068526367197],
            [-66.151467916992189, -9.785968526367196],
            [-66.147867916992183, -9.786768526367197],
            [-66.145367916992186, -9.790868526367195],
            [-66.14206791699219, -9.788668526367196],
            [-66.136767916992184, -9.788668526367196],
            [-66.133167916992193, -9.789868526367197],
            [-66.130167916992178, -9.788968526367196],
            [-66.127667916992181, -9.786468526367198],
            [-66.124167916992178, -9.787768526367195],
            [-66.123267916992191, -9.790668526367195],
            [-66.117967916992185, -9.789968526367197],
            [-66.11466791699219, -9.788468526367197],
            [-66.110267916992186, -9.789268526367195],
            [-66.110367916992189, -9.785568526367197],
            [-66.10526791699219, -9.783068526367195],
            [-66.100267916992181, -9.784768526367195],
            [-66.097767916992183, -9.787068526367197],
            [-66.097767916992183, -9.791368526367197],
            [-66.100267916992181, -9.798268526367195],
            [-66.101367916992189, -9.803268526367198],
            [-66.10066791699218, -9.806868526367197],
            [-66.097367916992184, -9.811568526367196],
            [-66.091467916992187, -9.813268526367196],
            [-66.08656791699218, -9.804968526367198],
            [-66.083667916992184, -9.799268526367197],
            [-66.07926791699218, -9.794768526367196],
            [-66.084867916992181, -9.792568526367198],
            [-66.087267916992189, -9.790868526367195],
            [-66.07926791699218, -9.786468526367198],
            [-66.06986791699218, -9.784468526367196],
            [-66.059367916992187, -9.785968526367196],
            [-66.053267916992183, -9.784768526367195],
            [-66.044967916992192, -9.785668526367196],
            [-66.040667916992192, -9.786968526367197],
            [-66.035667916992182, -9.787068526367197],
            [-66.033167916992184, -9.788868526367196],
            [-66.030167916992184, -9.801668526367198],
            [-66.028067916992185, -9.806668526367197],
            [-66.025067916992185, -9.811568526367196],
            [-66.019267916992192, -9.811368526367197],
            [-66.013567916992187, -9.804668526367195],
            [-66.009967916992181, -9.801168526367196],
            [-66.002167916992192, -9.795068526367196],
            [-65.999067916992189, -9.794768526367196],
            [-65.999067916992189, -9.800368526367198],
            [-65.995867916992182, -9.804068526367196],
            [-65.992267916992191, -9.803868526367197],
            [-65.989067916992184, -9.801668526367198],
            [-65.98496791699219, -9.806168526367195],
            [-65.981067916992188, -9.807968526367198],
            [-65.981367916992184, -9.802168526367197],
            [-65.974767916992192, -9.803068526367195],
            [-65.965867916992181, -9.804468526367195],
            [-65.963967916992189, -9.801968526367197],
            [-65.970067916992178, -9.799268526367197],
            [-65.973667916992184, -9.798168526367196],
            [-65.974167916992187, -9.793668526367195],
            [-65.974967916992185, -9.790568526367196],
            [-65.974767916992192, -9.785668526367196],
            [-65.973367916992188, -9.780968526367197],
            [-65.972867916992186, -9.776468526367196],
            [-65.970567916992181, -9.774468526367198],
            [-65.96656791699219, -9.770468526367196],
            [-65.961767916992187, -9.770768526367196],
            [-65.95726791699218, -9.769368526367195],
            [-65.952467916992191, -9.768168526367198],
            [-65.949067916992192, -9.763968526367197],
            [-65.946267916992184, -9.762668526367197],
            [-65.943767916992186, -9.760168526367195],
            [-65.939967916992188, -9.761268526367196],
            [-65.938567916992184, -9.765668526367197],
            [-65.93586791699218, -9.768968526367196],
            [-65.930767916992181, -9.767168526367197],
            [-65.92716791699219, -9.762968526367196],
            [-65.92246791699219, -9.762668526367197],
            [-65.925267916992183, -9.759368526367197],
            [-65.922767916992186, -9.756768526367196],
            [-65.92246791699219, -9.753368526367197],
            [-65.918867916992184, -9.752668526367195],
            [-65.914867916992179, -9.754668526367198],
            [-65.915567916992188, -9.761868526367195],
            [-65.91306791699219, -9.764068526367197],
            [-65.908867916992179, -9.764868526367195],
            [-65.902367916992191, -9.766068526367196],
            [-65.899767916992189, -9.760768526367197],
            [-65.89006791699218, -9.757068526367195],
            [-65.887867916992178, -9.754068526367195],
            [-65.884667916992186, -9.752168526367196],
            [-65.881267916992186, -9.751968526367197],
            [-65.876867916992182, -9.752768526367195],
            [-65.870867916992182, -9.754168526367195],
            [-65.868867916992187, -9.760668526367198],
            [-65.86466791699219, -9.765468526367197],
            [-65.862167916992178, -9.768168526367198],
            [-65.865067916992189, -9.770768526367196],
            [-65.868267916992181, -9.773168526367197],
            [-65.869167916992183, -9.775868526367198],
            [-65.868267916992181, -9.782568526367196],
            [-65.871067916992189, -9.785968526367196],
            [-65.871867916992187, -9.789868526367197],
            [-65.868567916992191, -9.793068526367197],
            [-65.864967916992185, -9.794468526367197],
            [-65.860567916992181, -9.795068526367196],
            [-65.853067916992188, -9.793968526367195],
            [-65.85096791699219, -9.791768526367196],
            [-65.846667916992189, -9.789468526367195],
            [-65.841667916992179, -9.789468526367195],
            [-65.843667916992189, -9.784768526367195],
            [-65.834367916992193, -9.775968526367198],
            [-65.82926791699218, -9.766768526367198],
            [-65.821367916992187, -9.767068526367197],
            [-65.819667916992188, -9.763968526367197],
            [-65.819667916992188, -9.756368526367197],
            [-65.816067916992182, -9.754868526367197],
            [-65.81246791699219, -9.757168526367195],
            [-65.807967916992183, -9.761768526367195],
            [-65.80216791699219, -9.756568526367197],
            [-65.799367916992182, -9.757668526367198],
            [-65.802467916992185, -9.766568526367195],
            [-65.806567916992179, -9.768968526367196],
            [-65.811267916992179, -9.769568526367195],
            [-65.813067916992182, -9.771768526367197],
            [-65.811067916992187, -9.776768526367196],
            [-65.807967916992183, -9.781268526367196],
            [-65.805767916992181, -9.788068526367198],
            [-65.802567916992189, -9.789468526367195],
            [-65.79716791699218, -9.786268526367195],
            [-65.795267916992188, -9.791168526367198],
            [-65.790667916992192, -9.788168526367198],
            [-65.784567916992188, -9.786768526367197],
            [-65.78176791699218, -9.783068526367195],
            [-65.780867916992179, -9.780068526367195],
            [-65.780167916992184, -9.770968526367195],
            [-65.775367916992181, -9.765668526367197],
            [-65.777567916992183, -9.758068526367197],
            [-65.779467916992189, -9.749068526367196],
            [-65.779767916992185, -9.741668526367196],
            [-65.777867916992193, -9.736768526367197],
            [-65.775067916992185, -9.734968526367197],
            [-65.770767916992185, -9.734368526367195],
            [-65.767167916992179, -9.736368526367198],
            [-65.766567916992187, -9.739668526367197],
            [-65.768567916992183, -9.743568526367195],
            [-65.769567916992187, -9.746868526367198],
            [-65.771567916992183, -9.750968526367195],
            [-65.771867916992193, -9.755968526367198],
            [-65.771767916992189, -9.758868526367195],
            [-65.76536791699219, -9.762168526367198],
            [-65.756567916992182, -9.766468526367195],
            [-65.751267916992191, -9.776468526367196],
            [-65.744967916992181, -9.781768526367195],
            [-65.739467916992183, -9.781968526367198],
            [-65.736767916992193, -9.778668526367195],
            [-65.736167916992187, -9.767868526367195],
            [-65.734167916992192, -9.763568526367198],
            [-65.72896791699219, -9.759568526367197],
            [-65.722767916992183, -9.756568526367197],
            [-65.718767916992192, -9.755968526367198],
            [-65.714767916992187, -9.755768526367195],
            [-65.705167916992181, -9.757068526367195],
            [-65.696767916992187, -9.750168526367197],
            [-65.692767916992182, -9.749368526367196],
            [-65.686267916992179, -9.749368526367196],
            [-65.68446791699219, -9.752068526367196],
            [-65.684967916992179, -9.757168526367195],
            [-65.685767916992191, -9.760968526367197],
            [-65.682767916992191, -9.761768526367195],
            [-65.679067916992182, -9.761068526367197],
            [-65.680567916992189, -9.766368526367195],
            [-65.685167916992185, -9.768968526367196],
            [-65.68916791699219, -9.768768526367197],
            [-65.694667916992188, -9.766868526367197],
            [-65.700167916992186, -9.764368526367196],
            [-65.704967916992189, -9.763968526367197],
            [-65.708167916992181, -9.765968526367196],
            [-65.709567916992185, -9.771568526367197],
            [-65.703267916992189, -9.779268526367197],
            [-65.701367916992183, -9.782768526367196],
            [-65.699967916992179, -9.789168526367195],
            [-65.700967916992184, -9.792468526367195],
            [-65.708167916992181, -9.800768526367197],
            [-65.710367916992183, -9.804368526367195],
            [-65.710467916992187, -9.807268526367196],
            [-65.700167916992186, -9.809068526367195],
            [-65.69386791699219, -9.812668526367197],
            [-65.689067916992187, -9.811868526367196],
            [-65.691067916992182, -9.806568526367197],
            [-65.690167916992181, -9.800768526367197],
            [-65.681867916992189, -9.793568526367196],
            [-65.679767916992191, -9.791368526367197],
            [-65.673267916992188, -9.782668526367196],
            [-65.669967916992192, -9.781168526367196],
            [-65.666367916992186, -9.782668526367196],
            [-65.66446791699218, -9.786468526367198],
            [-65.66316791699218, -9.790568526367196],
            [-65.658867916992179, -9.793468526367196],
            [-65.652267916992187, -9.791768526367196],
            [-65.646467916992179, -9.791768526367196],
            [-65.643967916992182, -9.795068526367196],
            [-65.645667916992181, -9.799168526367197],
            [-65.652067916992181, -9.804968526367198],
            [-65.653667916992191, -9.808668526367196],
            [-65.651567916992178, -9.813868526367198],
            [-65.648267916992182, -9.815968526367197],
            [-65.641267916992177, -9.807268526367196],
            [-65.637667916992186, -9.804668526367195],
            [-65.634567916992182, -9.806068526367195],
            [-65.632967916992186, -9.809868526367197],
            [-65.633567916992178, -9.816668526367195],
            [-65.633167916992193, -9.823568526367197],
            [-65.632167916992188, -9.830068526367196],
            [-65.630467916992188, -9.834268526367197],
            [-65.626567916992187, -9.836768526367198],
            [-65.623767916992179, -9.835968526367196],
            [-65.61866791699218, -9.832368526367198],
            [-65.615567916992191, -9.830768526367198],
            [-65.613267916992186, -9.828568526367196],
            [-65.606767916992183, -9.824968526367197],
            [-65.60056791699219, -9.824268526367195],
            [-65.597867916992186, -9.826568526367197],
            [-65.599167916992187, -9.835868526367197],
            [-65.598767916992188, -9.839068526367196],
            [-65.595867916992191, -9.841968526367197],
            [-65.59156791699219, -9.841468526367198],
            [-65.585767916992182, -9.839868526367198],
            [-65.57486791699219, -9.838268526367198],
            [-65.56886791699219, -9.838168526367195],
            [-65.563067916992182, -9.843468526367197],
            [-65.559067916992191, -9.844568526367198],
            [-65.554667916992187, -9.841968526367197],
            [-65.553267916992183, -9.838768526367197],
            [-65.552967916992188, -9.835368526367198],
            [-65.554067916992182, -9.831868526367195],
            [-65.564067916992187, -9.820568526367197],
            [-65.565467916992191, -9.816568526367195],
            [-65.56556791699218, -9.813068526367196],
            [-65.563767916992191, -9.810168526367196],
            [-65.559367916992187, -9.810768526367195],
            [-65.556667916992183, -9.808768526367196],
            [-65.553967916992178, -9.804768526367198],
            [-65.548267916992188, -9.804468526367195],
            [-65.543167916992189, -9.803968526367196],
            [-65.539267916992188, -9.801868526367198],
            [-65.537267916992192, -9.798868526367198],
            [-65.535867916992188, -9.788868526367196],
            [-65.533667916992187, -9.782368526367197],
            [-65.530867916992179, -9.781268526367196],
            [-65.52876791699218, -9.784268526367196],
            [-65.526467916992189, -9.787368526367196],
            [-65.521867916992193, -9.788368526367197],
            [-65.518267916992187, -9.791368526367197],
            [-65.512467916992179, -9.792768526367198],
            [-65.508067916992189, -9.788968526367196],
            [-65.502767916992184, -9.786368526367195],
            [-65.499067916992189, -9.781268526367196],
            [-65.498267916992191, -9.778368526367196],
            [-65.498867916992182, -9.775168526367196],
            [-65.500467916992193, -9.772568526367195],
            [-65.503867916992192, -9.768468526367197],
            [-65.503767916992189, -9.759368526367197],
            [-65.504067916992184, -9.747668526367196],
            [-65.501267916992191, -9.743268526367196],
            [-65.50136791699218, -9.733568526367197],
            [-65.500267916992186, -9.729168526367197],
            [-65.491867916992192, -9.729468526367196],
            [-65.486167916992187, -9.729168526367197],
            [-65.482567916992181, -9.726668526367195],
            [-65.487767916992183, -9.721368526367197],
            [-65.489767916992179, -9.714068526367196],
            [-65.488567916992181, -9.710368526367198],
            [-65.485567916992181, -9.707668526367197],
            [-65.482467916992192, -9.713468526367198],
            [-65.474967916992185, -9.714268526367196],
            [-65.471767916992178, -9.713668526367197],
            [-65.465467916992182, -9.710068526367195],
            [-65.463167916992191, -9.708268526367196],
            [-65.460367916992183, -9.706568526367196],
            [-65.455967916992179, -9.704068526367195],
            [-65.450467916992181, -9.700368526367196],
            [-65.447067916992182, -9.697068526367197],
            [-65.443467916992191, -9.692668526367196],
            [-65.441367916992192, -9.686268526367197],
            [-65.442667916992178, -9.678668526367197],
            [-65.446667916992183, -9.673868526367198],
            [-65.446367916992187, -9.669268526367198],
            [-65.443467916992191, -9.669268526367198],
            [-65.440767916992186, -9.670768526367198],
            [-65.431067916992191, -9.674668526367196],
            [-65.421767916992181, -9.677268526367197],
            [-65.413667916992182, -9.678668526367197],
            [-65.400067916992185, -9.683568526367196],
            [-65.394867916992183, -9.685768526367195],
            [-65.391567916992187, -9.687668526367197],
            [-65.378267916992186, -9.697068526367197],
            [-65.368267916992181, -9.706768526367195],
            [-65.363667916992185, -9.711568526367195],
            [-65.356767916992183, -9.720068526367196],
            [-65.354567916992181, -9.723668526367195],
            [-65.351367916992189, -9.731468526367195],
            [-65.348167916992182, -9.741668526367196],
            [-65.343067916992183, -9.753468526367197],
            [-65.339767916992187, -9.758768526367195],
            [-65.33686791699219, -9.764568526367196],
            [-65.331767916992192, -9.772368526367195],
            [-65.327967916992179, -9.780068526367195],
            [-65.326767916992182, -9.783968526367197],
            [-65.32486791699219, -9.797468526367197],
            [-65.322067916992182, -9.811268526367197],
            [-65.320467916992186, -9.814468526367197],
            [-65.317667916992178, -9.817768526367196],
            [-65.307267916992188, -9.823568526367197],
            [-65.301167916992185, -9.827968526367197],
            [-65.298067916992181, -9.829568526367197],
            [-65.292867916992179, -9.833268526367196],
            [-65.28976791699219, -9.836268526367196],
            [-65.286967916992182, -9.840868526367196],
            [-65.285967916992178, -9.845568526367195],
            [-65.285967916992178, -9.853368526367195],
            [-65.286667916992187, -9.858568526367197],
            [-65.28846791699219, -9.865768526367198],
            [-65.291367916992186, -9.871568526367195],
            [-65.29576791699219, -9.878368526367197],
            [-65.299367916992182, -9.882068526367195],
            [-65.306967916992193, -9.887868526367196],
            [-65.31216791699218, -9.893168526367198],
            [-65.316567916992184, -9.898968526367195],
            [-65.321267916992184, -9.905968526367197],
            [-65.328467916992182, -9.921168526367197],
            [-65.331767916992192, -9.929968526367198],
            [-65.333267916992185, -9.937468526367198],
            [-65.332867916992186, -9.943568526367198],
            [-65.332067916992187, -9.949068526367196],
            [-65.331767916992192, -9.952368526367195],
            [-65.33256791699219, -9.957368526367198],
            [-65.333167916992181, -9.964568526367195],
            [-65.330767916992187, -9.968768526367196],
            [-65.325467916992181, -9.972768526367197],
            [-65.323467916992186, -9.974968526367196],
            [-65.322167916992186, -9.977568526367197],
            [-65.32186791699219, -9.984668526367198],
            [-65.321267916992184, -9.992268526367198],
            [-65.321267916992184, -9.997768526367196],
            [-65.320267916992179, -10.001668526367197],
            [-65.32186791699219, -10.009268526367197],
            [-65.32286791699218, -10.013968526367197],
            [-65.324067916992192, -10.021468526367197],
            [-65.325967916992184, -10.028768526367195],
            [-65.326767916992182, -10.032368526367197],
            [-65.32786791699219, -10.036668526367198],
            [-65.32926791699218, -10.043168526367197],
            [-65.32956791699219, -10.047468526367197],
            [-65.32956791699219, -10.052568526367196],
            [-65.328967916992184, -10.055768526367196],
            [-65.327467916992191, -10.061868526367196],
            [-65.325767916992191, -10.066568526367195],
            [-65.323567916992189, -10.070968526367196],
            [-65.31986791699218, -10.079568526367197],
            [-65.318167916992181, -10.081868526367195],
            [-65.315267916992184, -10.085968526367196],
            [-65.311967916992188, -10.089468526367195],
            [-65.30916791699218, -10.091668526367197],
            [-65.303967916992178, -10.094768526367197],
            [-65.301667916992187, -10.097368526367195],
            [-65.300567916992179, -10.100868526367197],
            [-65.300267916992183, -10.104768526367195],
            [-65.300567916992179, -10.110368526367196],
            [-65.30216791699219, -10.121968526367198],
            [-65.301667916992187, -10.127468526367196],
            [-65.301067916992181, -10.130768526367195],
            [-65.300367916992187, -10.136068526367197],
            [-65.300267916992183, -10.139368526367196],
            [-65.301167916992185, -10.144368526367195],
            [-65.302767916992181, -10.149368526367198],
            [-65.305767916992181, -10.156168526367196],
            [-65.306867916992189, -10.159468526367196],
            [-65.306867916992189, -10.166368526367197],
            [-65.305767916992181, -10.172168526367198],
            [-65.304667916992187, -10.176568526367195],
            [-65.303367916992187, -10.182168526367196],
            [-65.301167916992185, -10.187268526367195],
            [-65.299767916992181, -10.190468526367198],
            [-65.298167916992185, -10.193868526367197],
            [-65.295367916992191, -10.198268526367197],
            [-65.293567916992188, -10.201568526367197],
            [-65.290867916992184, -10.206468526367196],
            [-65.289567916992183, -10.209568526367196],
            [-65.288867916992189, -10.215168526367197],
            [-65.289267916992188, -10.220868526367195],
            [-65.293167916992189, -10.227768526367196],
            [-65.29416791699218, -10.231968526367197],
            [-65.29446791699219, -10.238268526367197],
            [-65.296167916992189, -10.240768526367198],
            [-65.30046791699219, -10.244468526367196],
            [-65.304167916992185, -10.247668526367196],
            [-65.307467916992181, -10.251268526367195],
            [-65.30946791699219, -10.255168526367196],
            [-65.31086791699218, -10.258168526367196],
            [-65.312367916992187, -10.263468526367195],
            [-65.313567916992184, -10.267168526367197],
            [-65.315267916992184, -10.271868526367196],
            [-65.317067916992187, -10.277368526367198],
            [-65.317767916992182, -10.281168526367196],
            [-65.317967916992188, -10.286268526367195],
            [-65.319267916992189, -10.291468526367197],
            [-65.320967916992188, -10.295768526367198],
            [-65.323967916992189, -10.300468526367197],
            [-65.326367916992183, -10.304068526367196],
            [-65.331067916992183, -10.309068526367195],
            [-65.334567916992185, -10.311868526367196],
            [-65.337067916992183, -10.314068526367198],
            [-65.343467916992182, -10.317768526367196],
            [-65.347767916992183, -10.322168526367197],
            [-65.35066791699218, -10.325968526367195],
            [-65.353467916992187, -10.330168526367196],
            [-65.356167916992177, -10.333568526367195],
            [-65.358167916992187, -10.336868526367198],
            [-65.361667916992189, -10.341668526367197],
            [-65.363867916992177, -10.343768526367196],
            [-65.366067916992179, -10.345868526367195],
            [-65.371567916992191, -10.349168526367198],
            [-65.374867916992187, -10.351268526367196],
            [-65.378267916992186, -10.355268526367198],
            [-65.381667916992185, -10.360868526367195],
            [-65.384567916992182, -10.364968526367196],
            [-65.389367916992185, -10.370068526367195],
            [-65.39176791699218, -10.375568526367196],
            [-65.389667916992181, -10.382468526367198],
            [-65.388167916992188, -10.385668526367198],
            [-65.38566791699219, -10.390768526367197],
            [-65.383867916992187, -10.393768526367197],
            [-65.381267916992186, -10.397868526367198],
            [-65.380267916992182, -10.400868526367198],
            [-65.379067916992184, -10.408968526367197],
            [-65.379067916992184, -10.416168526367198],
            [-65.379867916992183, -10.420368526367195],
            [-65.380167916992178, -10.424168526367197],
            [-65.380167916992178, -10.429668526367195],
            [-65.383067916992189, -10.432968526367198],
            [-65.384267916992187, -10.435568526367195],
            [-65.386267916992182, -10.439168526367197],
            [-65.39036791699219, -10.447468526367196],
            [-65.392867916992188, -10.450968526367195],
            [-65.395767916992185, -10.454068526367195],
            [-65.400867916992183, -10.460468526367197],
            [-65.402867916992193, -10.462668526367196],
            [-65.405667916992186, -10.464068526367196],
            [-65.410867916992188, -10.464068526367196],
            [-65.416667916992182, -10.462868526367195],
            [-65.420667916992187, -10.462968526367195],
            [-65.42386791699218, -10.465068526367197],
            [-65.424667916992178, -10.468368526367197],
            [-65.425567916992179, -10.471968526367196],
            [-65.428667916992183, -10.475968526367197],
            [-65.429667916992187, -10.481768526367198],
            [-65.429167916992185, -10.484968526367197],
            [-65.42716791699219, -10.488668526367196],
            [-65.424667916992178, -10.492968526367196],
            [-65.419267916992183, -10.497868526367196],
            [-65.415267916992178, -10.501568526367198],
            [-65.41346791699219, -10.504368526367195],
            [-65.410567916992193, -10.509568526367197],
            [-65.410567916992193, -10.512868526367196],
            [-65.411967916992182, -10.515368526367197],
            [-65.414167916992184, -10.518768526367197],
            [-65.41776791699219, -10.524368526367198],
            [-65.41946791699219, -10.527368526367198],
            [-65.420067916992181, -10.530568526367198],
            [-65.419967916992192, -10.535568526367197],
            [-65.417867916992179, -10.540368526367196],
            [-65.415267916992178, -10.543068526367197],
            [-65.408367916992191, -10.548568526367195],
            [-65.405667916992186, -10.551868526367198],
            [-65.404467916992189, -10.554768526367198],
            [-65.403467916992184, -10.561368526367197],
            [-65.402667916992186, -10.564168526367197],
            [-65.402067916992181, -10.568068526367195],
            [-65.39906791699218, -10.574868526367197],
            [-65.397867916992183, -10.579868526367196],
            [-65.397167916992188, -10.583268526367196],
            [-65.397067916992185, -10.586568526367195],
            [-65.398367916992186, -10.590168526367197],
            [-65.402267916992187, -10.592668526367195],
            [-65.40846791699218, -10.597368526367195],
            [-65.410567916992193, -10.602268526367197],
            [-65.409867916992184, -10.608868526367196],
            [-65.409567916992188, -10.613568526367196],
            [-65.41646791699219, -10.617668526367197],
            [-65.41616791699218, -10.621068526367196],
            [-65.413667916992182, -10.623868526367197],
            [-65.412267916992192, -10.627968526367198],
            [-65.41006791699219, -10.631268526367197],
            [-65.405867916992179, -10.634968526367196],
            [-65.405167916992184, -10.639568526367196],
            [-65.40376791699218, -10.642568526367196],
            [-65.400467916992184, -10.644368526367195],
            [-65.395967916992191, -10.645668526367196],
            [-65.386067916992189, -10.645468526367196],
            [-65.379167916992188, -10.645668526367196],
            [-65.37466791699218, -10.647068526367196],
            [-65.371167916992192, -10.648668526367196],
            [-65.36766791699219, -10.650668526367195],
            [-65.361767916992193, -10.654468526367197],
            [-65.358967916992185, -10.656668526367195],
            [-65.35526791699219, -10.661268526367195],
            [-65.351767916992188, -10.666668526367197],
            [-65.349167916992187, -10.671468526367196],
            [-65.347867916992186, -10.674968526367195],
            [-65.346767916992178, -10.678668526367197],
            [-65.34596791699218, -10.681568526367197],
            [-65.345367916992188, -10.686568526367196],
            [-65.34456791699219, -10.690768526367197],
            [-65.343667916992189, -10.699568526367198],
            [-65.343367916992179, -10.707268526367198],
            [-65.343367916992179, -10.715068526367197],
            [-65.344467916992187, -10.721668526367196],
            [-65.346967916992185, -10.731168526367195],
            [-65.348167916992182, -10.734968526367197],
            [-65.349167916992187, -10.738868526367195],
            [-65.349467916992182, -10.744068526367197],
            [-65.348367916992188, -10.748068526367195],
            [-65.346767916992178, -10.752968526367198],
            [-65.345567916992181, -10.757768526367197],
            [-65.344767916992183, -10.762168526367198],
            [-65.344467916992187, -10.765768526367197],
            [-65.344767916992183, -10.770368526367196],
            [-65.34626791699219, -10.774068526367195],
            [-65.348067916992193, -10.778368526367196],
            [-65.35056791699219, -10.780468526367198],
            [-65.354167916992182, -10.780968526367197],
            [-65.358867916992182, -10.779468526367197],
            [-65.365367916992184, -10.780868526367197],
            [-65.367267916992191, -10.785568526367197],
            [-65.36766791699219, -10.789868526367197],
            [-65.367167916992187, -10.794968526367196],
            [-65.365067916992189, -10.799268526367197],
            [-65.362467916992188, -10.801468526367195],
            [-65.358067916992184, -10.803268526367198],
            [-65.354167916992182, -10.805068526367197],
            [-65.348467916992178, -10.806868526367197],
            [-65.343767916992192, -10.807468526367195],
            [-65.340067916992183, -10.807668526367195],
            [-65.337667916992189, -10.809968526367197],
            [-65.334767916992178, -10.812768526367197],
            [-65.330667916992184, -10.815968526367197],
            [-65.32626791699218, -10.818768526367197],
            [-65.323567916992189, -10.820168526367198],
            [-65.320967916992188, -10.821868526367197],
            [-65.317667916992178, -10.824668526367198],
            [-65.314367916992182, -10.828468526367196],
            [-65.310567916992184, -10.833268526367196],
            [-65.307167916992185, -10.837868526367195],
            [-65.304167916992185, -10.841268526367195],
            [-65.301467916992181, -10.844168526367195],
            [-65.29886791699218, -10.847268526367195],
            [-65.29276791699219, -10.854168526367197],
            [-65.289167916992184, -10.857768526367195],
            [-65.284567916992188, -10.861468526367197],
            [-65.28206791699219, -10.864068526367195],
            [-65.27876791699218, -10.866368526367197],
            [-65.276167916992179, -10.869768526367196],
            [-65.273467916992189, -10.877468526367196],
            [-65.271467916992179, -10.884868526367196],
            [-65.268967916992182, -10.896768526367197],
            [-65.268167916992184, -10.904468526367197],
            [-65.267167916992179, -10.909768526367195],
            [-65.266567916992187, -10.915668526367195],
            [-65.266467916992184, -10.918668526367195],
            [-65.267467916992189, -10.922568526367197],
            [-65.27406791699218, -10.922468526367197],
            [-65.277667916992186, -10.920668526367198],
            [-65.280167916992184, -10.919168526367198],
            [-65.284467916992185, -10.919768526367196],
            [-65.285967916992178, -10.922868526367196],
            [-65.285867916992188, -10.926568526367195],
            [-65.283867916992179, -10.931668526367197],
            [-65.280567916992183, -10.936968526367195],
            [-65.278467916992184, -10.939668526367196],
            [-65.274767916992189, -10.943568526367198],
            [-65.272967916992187, -10.948968526367196],
            [-65.271867916992193, -10.952868526367197],
            [-65.269567916992187, -10.958268526367196],
            [-65.26706791699219, -10.962968526367195],
            [-65.263967916992186, -10.966668526367197],
            [-65.261767916992184, -10.968768526367196],
            [-65.257967916992186, -10.973168526367196],
            [-65.255267916992182, -10.976968526367195],
            [-65.252967916992191, -10.980668526367197],
            [-65.250567916992182, -10.984968526367197],
            [-65.250267916992186, -10.987868526367198],
            [-65.250967916992181, -10.990768526367198],
            [-65.252767916992184, -10.992968526367196],
            [-65.254967916992186, -10.994768526367196],
            [-65.25766791699219, -10.996368526367196],
            [-65.26236791699219, -10.998068526367195],
            [-65.265767916992189, -10.999368526367196],
            [-65.269367916992181, -11.000168526367197],
            [-65.272967916992187, -11.003568526367197],
            [-65.277267916992187, -11.007368526367195],
            [-65.281167916992189, -11.010668526367198],
            [-65.285667916992182, -11.015168526367198],
            [-65.288867916992189, -11.018168526367198],
            [-65.292567916992184, -11.021568526367197],
            [-65.294767916992186, -11.023668526367196],
            [-65.29716791699218, -11.025968526367198],
            [-65.300767916992186, -11.031168526367196],
            [-65.301667916992187, -11.035568526367197],
            [-65.300767916992186, -11.038768526367196],
            [-65.298367916992191, -11.041368526367197],
            [-65.295367916992191, -11.044668526367197],
            [-65.29246791699218, -11.047868526367196],
            [-65.289967916992182, -11.050468526367197],
            [-65.285867916992188, -11.055068526367197],
            [-65.283067916992181, -11.058268526367197],
            [-65.28176791699218, -11.060868526367198],
            [-65.280867916992179, -11.063568526367195],
            [-65.279567916992193, -11.069668526367195],
            [-65.279467916992189, -11.074868526367197],
            [-65.279767916992185, -11.079068526367195],
            [-65.280867916992179, -11.082368526367198],
            [-65.284267916992178, -11.089568526367195],
            [-65.287267916992192, -11.092968526367198],
            [-65.293567916992188, -11.095668526367195],
            [-65.303667916992183, -11.097668526367197],
            [-65.307467916992181, -11.098468526367196],
            [-65.311567916992189, -11.099568526367197],
            [-65.315167916992181, -11.100568526367198],
            [-65.317967916992188, -11.101368526367196],
            [-65.321067916992178, -11.102368526367197],
            [-65.325767916992191, -11.104168526367197],
            [-65.328767916992177, -11.105568526367197],
            [-65.332067916992187, -11.107068526367197],
            [-65.335467916992187, -11.109468526367195],
            [-65.338967916992189, -11.111968526367196],
            [-65.343767916992192, -11.115768526367198],
            [-65.347367916992184, -11.118368526367195],
            [-65.35056791699219, -11.120868526367197],
            [-65.353367916992184, -11.123868526367197],
            [-65.356367916992184, -11.127068526367196],
            [-65.35826791699219, -11.130468526367196],
            [-65.361467916992183, -11.134868526367196],
            [-65.362767916992183, -11.137768526367196],
            [-65.363867916992177, -11.142068526367197],
            [-65.363667916992185, -11.146168526367195],
            [-65.361967916992185, -11.150868526367198],
            [-65.360267916992186, -11.155868526367197],
            [-65.359367916992184, -11.158768526367197],
            [-65.357067916992179, -11.165268526367196],
            [-65.355567916992186, -11.169968526367196],
            [-65.354267916992185, -11.173268526367195],
            [-65.352067916992183, -11.181568526367197],
            [-65.350267916992181, -11.186968526367195],
            [-65.34756791699219, -11.189468526367197],
            [-65.34426791699218, -11.190168526367195],
            [-65.338967916992189, -11.188768526367195],
            [-65.333767916992187, -11.186068526367198],
            [-65.330967916992179, -11.185568526367195],
            [-65.32456791699218, -11.186968526367195],
            [-65.321367916992187, -11.190468526367198],
            [-65.319567916992185, -11.193468526367198],
            [-65.318767916992186, -11.196368526367195],
            [-65.31856791699218, -11.199368526367195],
            [-65.31986791699218, -11.204268526367198],
            [-65.32156791699218, -11.208468526367195],
            [-65.324067916992192, -11.210868526367197],
            [-65.330767916992187, -11.211568526367195],
            [-65.335067916992188, -11.211168526367196],
            [-65.339567916992181, -11.210768526367197],
            [-65.345567916992181, -11.210768526367197],
            [-65.349767916992192, -11.211568526367195],
            [-65.353367916992184, -11.213468526367198],
            [-65.356067916992188, -11.215968526367195],
            [-65.358967916992185, -11.219768526367197],
            [-65.360267916992186, -11.222368526367195],
            [-65.36136791699218, -11.228368526367195],
            [-65.361867916992182, -11.240268526367196],
            [-65.361867916992182, -11.245568526367197],
            [-65.36136791699218, -11.250468526367197],
            [-65.359767916992183, -11.257768526367197],
            [-65.35666791699218, -11.269068526367196],
            [-65.355667916992189, -11.272168526367196],
            [-65.354467916992178, -11.276568526367196],
            [-65.353167916992192, -11.281168526367196],
            [-65.35066791699218, -11.289168526367195],
            [-65.349567916992186, -11.292768526367198],
            [-65.348467916992178, -11.296468526367196],
            [-65.346367916992179, -11.303868526367197],
            [-65.343367916992179, -11.308768526367196],
            [-65.34126791699218, -11.310868526367198],
            [-65.338667916992179, -11.312668526367197],
            [-65.335367916992183, -11.313768526367195],
            [-65.332367916992183, -11.314068526367198],
            [-65.316567916992184, -11.314868526367196],
            [-65.310467916992181, -11.315268526367195],
            [-65.306667916992183, -11.315568526367198],
            [-65.299767916992181, -11.315768526367197],
            [-65.296067916992186, -11.316968526367198],
            [-65.293167916992189, -11.319868526367195],
            [-65.291667916992182, -11.324868526367197],
            [-65.291767916992185, -11.330468526367195],
            [-65.293867916992184, -11.333168526367196],
            [-65.29886791699218, -11.335368526367198],
            [-65.307967916992183, -11.335868526367197],
            [-65.313767916992191, -11.336968526367198],
            [-65.325167916992186, -11.338768526367197],
            [-65.32926791699218, -11.340368526367197],
            [-65.33226791699218, -11.343668526367196],
            [-65.334567916992185, -11.349968526367196],
            [-65.335367916992183, -11.355868526367196],
            [-65.333667916992184, -11.363368526367196],
            [-65.329667916992179, -11.374168526367196],
            [-65.328767916992177, -11.377968526367198],
            [-65.327067916992178, -11.384368526367197],
            [-65.326067916992187, -11.390768526367197],
            [-65.326067916992187, -11.394368526367195],
            [-65.326467916992186, -11.397268526367196],
            [-65.326467916992186, -11.401568526367196],
            [-65.325667916992188, -11.405068526367195],
            [-65.324967916992179, -11.409868526367195],
            [-65.324267916992184, -11.414268526367195],
            [-65.323967916992189, -11.417168526367195],
            [-65.323567916992189, -11.420068526367196],
            [-65.322067916992182, -11.426668526367198],
            [-65.320567916992189, -11.435168526367196],
            [-65.319967916992184, -11.439968526367196],
            [-65.31886791699219, -11.447368526367196],
            [-65.317767916992182, -11.451468526367197],
            [-65.316667916992188, -11.456268526367197],
            [-65.31556791699218, -11.459768526367196],
            [-65.312967916992193, -11.471968526367196],
            [-65.311067916992187, -11.479568526367196],
            [-65.310467916992181, -11.485568526367196],
            [-65.309767916992186, -11.490468526367195],
            [-65.309067916992191, -11.493568526367195],
            [-65.307267916992188, -11.497168526367197],
            [-65.304767916992191, -11.500768526367196],
            [-65.298967916992183, -11.504168526367195],
            [-65.291367916992186, -11.505468526367196],
            [-65.28846791699219, -11.505568526367195],
            [-65.28476791699218, -11.505968526367198],
            [-65.280167916992184, -11.507368526367195],
            [-65.276567916992178, -11.509068526367198],
            [-65.271767916992189, -11.510668526367198],
            [-65.267567916992192, -11.511868526367195],
            [-65.263467916992184, -11.513168526367195],
            [-65.258467916992188, -11.514268526367196],
            [-65.250167916992183, -11.515068526367195],
            [-65.239967916992185, -11.515068526367195],
            [-65.228867916992186, -11.515668526367197],
            [-65.224967916992185, -11.516368526367195],
            [-65.21796791699218, -11.520068526367197],
            [-65.214067916992178, -11.524368526367198],
            [-65.212567916992185, -11.528068526367196],
            [-65.21156791699218, -11.531568526367195],
            [-65.211767916992187, -11.537568526367195],
            [-65.212267916992189, -11.542768526367198],
            [-65.214067916992178, -11.547868526367196],
            [-65.218167916992186, -11.553568526367197],
            [-65.222667916992179, -11.557668526367195],
            [-65.22596791699219, -11.560468526367195],
            [-65.228167916992192, -11.562468526367198],
            [-65.231767916992183, -11.566068526367197],
            [-65.234467916992187, -11.570268526367197],
            [-65.23496791699219, -11.575668526367195],
            [-65.23466791699218, -11.579568526367197],
            [-65.233367916992179, -11.583468526367195],
            [-65.22866791699218, -11.590568526367196],
            [-65.226667916992184, -11.593168526367197],
            [-65.225267916992181, -11.596368526367197],
            [-65.224767916992192, -11.599868526367196],
            [-65.22596791699219, -11.605368526367197],
            [-65.22996791699218, -11.611468526367197],
            [-65.23166791699218, -11.615368526367195],
            [-65.231367916992184, -11.619368526367197],
            [-65.229567916992181, -11.623668526367197],
            [-65.22696791699218, -11.627368526367196],
            [-65.224567916992186, -11.630468526367196],
            [-65.221167916992187, -11.636268526367196],
            [-65.218367916992179, -11.641068526367196],
            [-65.217567916992181, -11.645368526367196],
            [-65.217667916992184, -11.650868526367198],
            [-65.218967916992185, -11.655868526367197],
            [-65.221967916992185, -11.661268526367195],
            [-65.225067916992188, -11.665668526367195],
            [-65.227467916992182, -11.668868526367195],
            [-65.229267916992185, -11.671368526367196],
            [-65.232067916992179, -11.674168526367197],
            [-65.236067916992184, -11.678368526367198],
            [-65.23966791699219, -11.682168526367196],
            [-65.243067916992189, -11.685268526367196],
            [-65.24996791699219, -11.690768526367197],
            [-65.253767916992189, -11.693868526367197],
            [-65.256267916992186, -11.695968526367196],
            [-65.259667916992186, -11.700268526367196],
            [-65.26066791699219, -11.705368526367195],
            [-65.258467916992188, -11.711468526367195],
            [-65.255267916992182, -11.715868526367196],
            [-65.250967916992181, -11.720868526367195],
            [-65.248067916992184, -11.723368526367196],
            [-65.24366791699218, -11.726368526367196],
            [-65.239767916992179, -11.728468526367195],
            [-65.23636791699218, -11.730868526367196],
            [-65.233867916992182, -11.732468526367196],
            [-65.230667916992189, -11.734468526367195],
            [-65.226467916992192, -11.737168526367196],
            [-65.220867916992191, -11.740068526367196],
            [-65.217667916992184, -11.742268526367198],
            [-65.214767916992187, -11.744068526367197],
            [-65.208667916992184, -11.747168526367197],
            [-65.205667916992184, -11.749068526367196],
            [-65.200967916992184, -11.750568526367196],
            [-65.197467916992181, -11.751868526367197],
            [-65.19186791699218, -11.754368526367195],
            [-65.186367916992182, -11.756368526367197],
            [-65.181367916992187, -11.756568526367197],
            [-65.178567916992179, -11.756068526367198],
            [-65.17386791699218, -11.752468526367196],
            [-65.173667916992187, -11.747768526367196],
            [-65.174467916992185, -11.744368526367197],
            [-65.175767916992186, -11.741568526367196],
            [-65.178867916992189, -11.737768526367198],
            [-65.183367916992182, -11.733168526367198],
            [-65.185467916992181, -11.730268526367198],
            [-65.185767916992191, -11.725968526367197],
            [-65.183367916992182, -11.721968526367196],
            [-65.179767916992191, -11.720968526367198],
            [-65.176167916992185, -11.720868526367195],
            [-65.171967916992187, -11.720968526367198],
            [-65.166767916992185, -11.721168526367197],
            [-65.16006791699219, -11.720868526367195],
            [-65.147367916992181, -11.720868526367195],
            [-65.142967916992191, -11.720368526367196],
            [-65.12806791699218, -11.720568526367195],
            [-65.122967916992181, -11.719468526367198],
            [-65.120067916992184, -11.718368526367197],
            [-65.114767916992179, -11.714268526367196],
            [-65.106367916992184, -11.708168526367196],
            [-65.101667916992184, -11.706468526367196],
            [-65.097667916992179, -11.705668526367198],
            [-65.094467916992187, -11.706268526367197],
            [-65.090467916992182, -11.707668526367197],
            [-65.087967916992184, -11.709268526367197],
            [-65.085867916992186, -11.711568526367195],
            [-65.083967916992179, -11.714068526367196],
            [-65.082067916992187, -11.717268526367196],
            [-65.080767916992187, -11.719868526367197],
            [-65.080067916992192, -11.725568526367198],
            [-65.079967916992189, -11.730868526367196],
            [-65.079667916992179, -11.735068526367197],
            [-65.078567916992185, -11.738568526367196],
            [-65.07656791699219, -11.744368526367197],
            [-65.075267916992189, -11.748268526367195],
            [-65.073567916992189, -11.753468526367197],
            [-65.071367916992187, -11.758768526367195],
            [-65.068467916992191, -11.763868526367197],
            [-65.066567916992184, -11.767368526367196],
            [-65.064967916992188, -11.771868526367196],
            [-65.063267916992189, -11.777068526367195],
            [-65.061967916992188, -11.780868526367197],
            [-65.060267916992188, -11.786768526367197],
            [-65.059667916992183, -11.790568526367196],
            [-65.059367916992187, -11.798968526367197],
            [-65.059867916992189, -11.804368526367195],
            [-65.060167916992185, -11.813068526367196],
            [-65.060767916992191, -11.816568526367195],
            [-65.060767916992191, -11.819968526367198],
            [-65.061267916992179, -11.824268526367195],
            [-65.063067916992182, -11.830668526367198],
            [-65.064367916992182, -11.834368526367196],
            [-65.06556791699218, -11.837268526367197],
            [-65.067767916992182, -11.845168526367196],
            [-65.06886791699219, -11.849168526367198],
            [-65.069567916992185, -11.852068526367198],
            [-65.070767916992182, -11.856368526367195],
            [-65.071367916992187, -11.862568526367195],
            [-65.070667916992193, -11.869068526367197],
            [-65.06886791699219, -11.871668526367195],
            [-65.064867916992185, -11.877968526367198],
            [-65.06086791699218, -11.882068526367195],
            [-65.058067916992186, -11.883468526367196],
            [-65.053267916992183, -11.885468526367195],
            [-65.050067916992191, -11.885968526367197],
            [-65.031667916992191, -11.887168526367198],
            [-65.025367916992181, -11.888768526367198],
            [-65.022567916992188, -11.889868526367195],
            [-65.018767916992189, -11.892068526367197],
            [-65.016567916992187, -11.894368526367195],
            [-65.01536791699219, -11.897368526367195],
            [-65.014867916992188, -11.900868526367198],
            [-65.014967916992191, -11.904168526367197],
            [-65.016267916992177, -11.911168526367195],
            [-65.017367916992185, -11.915068526367197],
            [-65.017867916992188, -11.918068526367197],
            [-65.018967916992182, -11.921668526367196],
            [-65.020767916992185, -11.927468526367196],
            [-65.023467916992189, -11.935268526367196],
            [-65.024767916992189, -11.938468526367195],
            [-65.025867916992183, -11.941568526367195],
            [-65.027367916992191, -11.945168526367198],
            [-65.030167916992184, -11.950168526367197],
            [-65.035567916992193, -11.954668526367197],
            [-65.039567916992183, -11.958168526367196],
            [-65.041767916992185, -11.961768526367198],
            [-65.041367916992186, -11.967268526367196],
            [-65.039167916992184, -11.970268526367196],
            [-65.036167916992184, -11.968368526367197],
            [-65.03206791699219, -11.966268526367195],
            [-65.028367916992181, -11.967368526367196],
            [-65.02576791699218, -11.970668526367195],
            [-65.026567916992178, -11.973968526367198],
            [-65.029267916992183, -11.977568526367197],
            [-65.032767916992185, -11.980668526367197],
            [-65.034467916992185, -11.983368526367197],
            [-65.03506791699219, -11.987568526367195],
            [-65.033667916992187, -11.992268526367198],
            [-65.031967916992187, -11.994968526367195],
            [-65.029567916992193, -11.996968526367198],
            [-65.025967916992187, -11.998568526367198],
            [-65.021467916992179, -11.998768526367197],
            [-65.017967916992191, -11.998568526367198],
            [-65.015667916992186, -11.996668526367195],
            [-65.012167916992183, -11.994368526367197],
            [-65.00736791699218, -11.992968526367196],
            [-65.00166791699219, -11.993068526367196],
            [-64.998267916992191, -11.995468526367198],
            [-64.994067916992179, -12.000168526367197],
            [-64.992267916992191, -12.002468526367196],
            [-64.989467916992183, -12.007768526367197],
            [-64.98636791699218, -12.011568526367196],
            [-64.984167916992192, -12.014268526367196],
            [-64.97996791699218, -12.017168526367197],
            [-64.97556791699219, -12.018568526367197],
            [-64.971667916992189, -12.017368526367196],
            [-64.969467916992187, -12.012768526367196],
            [-64.968167916992186, -12.008268526367196],
            [-64.966767916992183, -12.003568526367197],
            [-64.965067916992183, -11.999468526367195],
            [-64.959367916992193, -11.996368526367196],
            [-64.95256791699218, -11.994368526367197],
            [-64.948267916992179, -11.993368526367195],
            [-64.94486791699218, -11.994968526367195],
            [-64.941267916992189, -11.999068526367196],
            [-64.938067916992182, -12.006868526367196],
            [-64.933767916992181, -12.014368526367196],
            [-64.929767916992191, -12.020668526367196],
            [-64.926167916992185, -12.023768526367196],
            [-64.922867916992189, -12.026268526367197],
            [-64.918067916992186, -12.028168526367196],
            [-64.911667916992187, -12.026468526367196],
            [-64.907667916992182, -12.025068526367196],
            [-64.904167916992179, -12.022968526367197],
            [-64.900467916992184, -12.020768526367196],
            [-64.895767916992185, -12.019768526367198],
            [-64.890667916992186, -12.016368526367195],
            [-64.88706791699218, -12.014968526367195],
            [-64.882767916992179, -12.013468526367195],
            [-64.878767916992189, -12.012668526367197],
            [-64.875167916992183, -12.012368526367197],
            [-64.869067916992179, -12.011768526367195],
            [-64.863267916992186, -12.012168526367198],
            [-64.857467916992192, -12.012168526367198],
            [-64.85366791699218, -12.011068526367197],
            [-64.848067916992193, -12.008868526367195],
            [-64.843667916992189, -12.009168526367198],
            [-64.839567916992181, -12.010668526367198],
            [-64.835067916992188, -12.013568526367198],
            [-64.831167916992186, -12.016068526367196],
            [-64.828767916992177, -12.017968526367195],
            [-64.826767916992182, -12.020368526367196],
            [-64.825467916992181, -12.024268526367198],
            [-64.823467916992186, -12.029068526367197],
            [-64.81886791699219, -12.035868526367196],
            [-64.816367916992192, -12.038668526367196],
            [-64.812967916992193, -12.044268526367198],
            [-64.809767916992186, -12.053268526367198],
            [-64.809367916992187, -12.059068526367195],
            [-64.810567916992184, -12.065968526367197],
            [-64.814667916992192, -12.071668526367198],
            [-64.822467916992181, -12.075968526367195],
            [-64.827467916992191, -12.077468526367195],
            [-64.831067916992183, -12.080068526367196],
            [-64.833967916992179, -12.082968526367196],
            [-64.835967916992189, -12.086768526367198],
            [-64.839067916992178, -12.091168526367195],
            [-64.841967916992189, -12.094068526367195],
            [-64.842667916992184, -12.096968526367196],
            [-64.843667916992189, -12.100268526367195],
            [-64.843167916992186, -12.104268526367196],
            [-64.84156791699219, -12.108168526367198],
            [-64.837267916992189, -12.115868526367198],
            [-64.833967916992179, -12.120168526367195],
            [-64.830067916992192, -12.122368526367197],
            [-64.82456791699218, -12.121068526367196],
            [-64.821367916992187, -12.117768526367197],
            [-64.81986791699218, -12.114668526367197],
            [-64.817967916992188, -12.111868526367196],
            [-64.816667916992188, -12.109168526367196],
            [-64.815267916992184, -12.104968526367195],
            [-64.813267916992189, -12.100668526367198],
            [-64.811567916992189, -12.097568526367198],
            [-64.80946791699219, -12.093768526367196],
            [-64.807467916992181, -12.091268526367195],
            [-64.803667916992183, -12.087568526367196],
            [-64.80046791699219, -12.085868526367197],
            [-64.790567916992188, -12.085468526367197],
            [-64.786967916992182, -12.086768526367198],
            [-64.782767916992185, -12.088968526367196],
            [-64.777567916992183, -12.090668526367196],
            [-64.77406791699218, -12.092368526367196],
            [-64.770367916992186, -12.093668526367196],
            [-64.76676791699218, -12.095868526367195],
            [-64.76236791699219, -12.098868526367195],
            [-64.760267916992191, -12.101268526367196],
            [-64.75906791699218, -12.105268526367198],
            [-64.757767916992179, -12.108068526367198],
            [-64.757467916992184, -12.112268526367195],
            [-64.757967916992186, -12.115268526367196],
            [-64.759267916992187, -12.119168526367197],
            [-64.761767916992184, -12.122668526367196],
            [-64.763267916992191, -12.125568526367196],
            [-64.764967916992191, -12.129068526367195],
            [-64.76676791699218, -12.132368526367195],
            [-64.767967916992191, -12.135768526367197],
            [-64.768767916992189, -12.139068526367197],
            [-64.769067916992185, -12.143668526367197],
            [-64.767867916992188, -12.146768526367197],
            [-64.765667916992186, -12.150368526367195],
            [-64.762867916992178, -12.154068526367197],
            [-64.757467916992184, -12.157368526367197],
            [-64.75306791699218, -12.157868526367196],
            [-64.749867916992187, -12.157568526367196],
            [-64.746867916992187, -12.156268526367196],
            [-64.74366791699218, -12.153768526367195],
            [-64.741567916992182, -12.150668526367195],
            [-64.740267916992181, -12.147268526367196],
            [-64.738667916992185, -12.143968526367196],
            [-64.736867916992182, -12.139568526367196],
            [-64.735367916992189, -12.131368526367197],
            [-64.73466791699218, -12.125568526367196],
            [-64.734167916992192, -12.120768526367197],
            [-64.734367916992184, -12.117568526367197],
            [-64.735267916992186, -12.114068526367195],
            [-64.736067916992184, -12.110868526367195],
            [-64.738067916992179, -12.106668526367198],
            [-64.738667916992185, -12.103168526367195],
            [-64.738267916992186, -12.100268526367195],
            [-64.731067916992188, -12.093668526367196],
            [-64.727767916992178, -12.092268526367196],
            [-64.72396791699218, -12.089768526367195],
            [-64.721467916992182, -12.087968526367195],
            [-64.717067916992193, -12.086968526367198],
            [-64.712867916992181, -12.086268526367196],
            [-64.707867916992186, -12.087568526367196],
            [-64.703267916992189, -12.090468526367196],
            [-64.699967916992179, -12.092668526367195],
            [-64.697967916992184, -12.094868526367197],
            [-64.697367916992192, -12.099468526367197],
            [-64.698267916992179, -12.103568526367198],
            [-64.700667916992188, -12.106468526367195],
            [-64.703167916992186, -12.108868526367196],
            [-64.705067916992192, -12.111168526367198],
            [-64.708167916992181, -12.115068526367196],
            [-64.711467916992177, -12.119668526367196],
            [-64.713767916992182, -12.124068526367196],
            [-64.715067916992183, -12.127468526367196],
            [-64.715867916992181, -12.132968526367197],
            [-64.716767916992183, -12.136068526367197],
            [-64.717567916992181, -12.144068526367196],
            [-64.717267916992185, -12.149368526367198],
            [-64.717567916992181, -12.153968526367198],
            [-64.716667916992179, -12.157068526367198],
            [-64.715867916992181, -12.161168526367195],
            [-64.715167916992186, -12.164568526367198],
            [-64.714367916992188, -12.168668526367195],
            [-64.712067916992183, -12.174268526367197],
            [-64.709267916992189, -12.177468526367196],
            [-64.705667916992184, -12.179768526367198],
            [-64.703467916992182, -12.181868526367197],
            [-64.699067916992192, -12.186568526367196],
            [-64.694967916992184, -12.189168526367197],
            [-64.691567916992184, -12.189968526367196],
            [-64.686267916992179, -12.190968526367197],
            [-64.680567916992189, -12.191568526367195],
            [-64.674667916992178, -12.191668526367195],
            [-64.671467916992185, -12.192668526367196],
            [-64.66776791699219, -12.194368526367196],
            [-64.664867916992179, -12.195968526367196],
            [-64.660867916992188, -12.197868526367195],
            [-64.657567916992178, -12.199268526367195],
            [-64.652667916992186, -12.200668526367195],
            [-64.648967916992177, -12.201768526367196],
            [-64.64506791699219, -12.202568526367195],
            [-64.64006791699218, -12.205168526367196],
            [-64.635267916992191, -12.206868526367195],
            [-64.631267916992186, -12.208268526367196],
            [-64.627767916992184, -12.210168526367195],
            [-64.622667916992185, -12.212268526367197],
            [-64.619067916992179, -12.213668526367197],
            [-64.616167916992183, -12.214768526367195],
            [-64.612167916992178, -12.215168526367197],
            [-64.607267916992186, -12.215968526367195],
            [-64.602067916992183, -12.215868526367196],
            [-64.597667916992179, -12.215968526367195],
            [-64.593667916992189, -12.215868526367196],
            [-64.589767916992187, -12.215368526367197],
            [-64.584267916992189, -12.214368526367195],
            [-64.580067916992192, -12.214268526367196],
            [-64.576867916992185, -12.215068526367197],
            [-64.573267916992179, -12.216968526367197],
            [-64.56856791699218, -12.219768526367197],
            [-64.566067916992182, -12.225268526367195],
            [-64.563367916992178, -12.231168526367195],
            [-64.560467916992181, -12.233868526367196],
            [-64.557967916992183, -12.237468526367195],
            [-64.553067916992191, -12.241468526367196],
            [-64.546067916992186, -12.242568526367197],
            [-64.540667916992192, -12.239468526367197],
            [-64.537467916992185, -12.235768526367195],
            [-64.534167916992189, -12.232768526367195],
            [-64.529767916992185, -12.229968526367195],
            [-64.526267916992182, -12.227268526367197],
            [-64.523667916992181, -12.225668526367198],
            [-64.515667916992186, -12.223368526367196],
            [-64.511767916992184, -12.223068526367197],
            [-64.508867916992187, -12.224568526367197],
            [-64.505967916992191, -12.228068526367196],
            [-64.504967916992186, -12.231368526367195],
            [-64.503567916992182, -12.234168526367196],
            [-64.502667916992181, -12.244368526367197],
            [-64.50166791699219, -12.247268526367197],
            [-64.499367916992185, -12.251668526367197],
            [-64.498267916992191, -12.256568526367197],
            [-64.49696791699219, -12.261068526367197],
            [-64.495867916992182, -12.266268526367195],
            [-64.495267916992191, -12.270668526367196],
            [-64.494667916992185, -12.275968526367198],
            [-64.494467916992193, -12.279768526367196],
            [-64.493567916992191, -12.285968526367196],
            [-64.492967916992185, -12.290568526367196],
            [-64.492767916992193, -12.294168526367198],
            [-64.493367916992185, -12.297268526367198],
            [-64.494367916992189, -12.303968526367196],
            [-64.495467916992183, -12.310868526367198],
            [-64.49666791699218, -12.314368526367197],
            [-64.498067916992184, -12.317668526367196],
            [-64.499367916992185, -12.324968526367197],
            [-64.500467916992193, -12.329368526367197],
            [-64.504167916992188, -12.333168526367196],
            [-64.507767916992179, -12.335968526367196],
            [-64.509867916992192, -12.338468526367198],
            [-64.51236791699219, -12.341968526367197],
            [-64.512967916992181, -12.347768526367197],
            [-64.511467916992189, -12.352368526367197],
            [-64.507767916992179, -12.357468526367196],
            [-64.503767916992189, -12.362468526367195],
            [-64.500767916992189, -12.365468526367195],
            [-64.49836791699218, -12.367168526367195],
            [-64.494167916992183, -12.367768526367197],
            [-64.490467916992188, -12.369968526367195],
            [-64.487467916992188, -12.372468526367197],
            [-64.483967916992185, -12.374168526367196],
            [-64.48166791699218, -12.376068526367195],
            [-64.479267916992185, -12.379168526367195],
            [-64.47556791699219, -12.380768526367195],
            [-64.470567916992181, -12.382068526367195],
            [-64.465167916992186, -12.384068526367198],
            [-64.460967916992189, -12.386268526367196],
            [-64.458167916992181, -12.389068526367197],
            [-64.454867916992185, -12.394368526367195],
            [-64.452967916992179, -12.399068526367195],
            [-64.451367916992183, -12.402268526367195],
            [-64.449267916992184, -12.406768526367195],
            [-64.447667916992188, -12.409168526367196],
            [-64.444167916992185, -12.412668526367195],
            [-64.440767916992186, -12.415868526367195],
            [-64.436067916992187, -12.418368526367196],
            [-64.432467916992181, -12.420868526367197],
            [-64.430767916992181, -12.423268526367195],
            [-64.428867916992189, -12.426868526367198],
            [-64.424967916992188, -12.432468526367195],
            [-64.419767916992186, -12.438368526367196],
            [-64.41616791699218, -12.441368526367196],
            [-64.41006791699219, -12.444568526367195],
            [-64.406667916992191, -12.446668526367198],
            [-64.402867916992193, -12.447968526367195],
            [-64.398967916992177, -12.449068526367196],
            [-64.393767916992189, -12.449268526367195],
            [-64.389567916992178, -12.449968526367197],
            [-64.386267916992182, -12.451268526367198],
            [-64.38266791699219, -12.452368526367195],
            [-64.375967916992181, -12.452968526367197],
            [-64.370067916992184, -12.454368526367197],
            [-64.366867916992192, -12.455368526367195],
            [-64.363267916992186, -12.456868526367195],
            [-64.359767916992183, -12.458668526367195],
            [-64.352867916992182, -12.458968526367197],
            [-64.349767916992192, -12.459568526367196],
            [-64.345067916992178, -12.459768526367196],
            [-64.33656791699218, -12.458468526367195],
            [-64.32756791699218, -12.457268526367198],
            [-64.322467916992181, -12.456568526367196],
            [-64.318267916992184, -12.457168526367198],
            [-64.307467916992181, -12.457568526367197],
            [-64.303267916992183, -12.458168526367196],
            [-64.298967916992183, -12.457868526367196],
            [-64.29576791699219, -12.457868526367196],
            [-64.291767916992185, -12.460468526367197],
            [-64.290567916992188, -12.467668526367195],
            [-64.291967916992178, -12.471968526367196],
            [-64.293567916992188, -12.474868526367196],
            [-64.295267916992188, -12.479168526367197],
            [-64.296667916992192, -12.482768526367195],
            [-64.298167916992185, -12.486468526367197],
            [-64.29886791699218, -12.490768526367198],
            [-64.29716791699218, -12.495768526367197],
            [-64.29416791699218, -12.499468526367195],
            [-64.290867916992184, -12.501368526367198],
            [-64.285567916992193, -12.502968526367198],
            [-64.281267916992192, -12.501568526367198],
            [-64.278367916992181, -12.499768526367195],
            [-64.275067916992185, -12.496568526367195],
            [-64.272567916992188, -12.492668526367197],
            [-64.270667916992181, -12.488668526367196],
            [-64.266467916992184, -12.485368526367196],
            [-64.259267916992187, -12.479968526367195],
            [-64.25466791699219, -12.477768526367196],
            [-64.251867916992182, -12.475268526367195],
            [-64.24836791699218, -12.471968526367196],
            [-64.246067916992189, -12.469868526367197],
            [-64.242167916992187, -12.463968526367196],
            [-64.238667916992185, -12.459568526367196],
            [-64.235267916992186, -12.456168526367197],
            [-64.231067916992188, -12.455068526367196],
            [-64.227567916992186, -12.455968526367197],
            [-64.224767916992192, -12.458668526367195],
            [-64.22226791699218, -12.460468526367197],
            [-64.21796791699218, -12.462268526367197],
            [-64.21326791699218, -12.461568526367195],
            [-64.209267916992189, -12.462268526367197],
            [-64.205767916992187, -12.464268526367196],
            [-64.20256791699218, -12.467568526367195],
            [-64.199367916992188, -12.470968526367198],
            [-64.196767916992187, -12.472768526367197],
            [-64.193467916992191, -12.472668526367197],
            [-64.18916791699219, -12.470968526367198],
            [-64.184767916992186, -12.466668526367197],
            [-64.18116791699218, -12.465568526367196],
            [-64.173567916992184, -12.466268526367195],
            [-64.171367916992182, -12.469768526367197],
            [-64.171167916992189, -12.481968526367197],
            [-64.171467916992185, -12.484968526367197],
            [-64.171067916992186, -12.490868526367198],
            [-64.172767916992186, -12.495768526367197],
            [-64.17386791699218, -12.500468526367197],
            [-64.17386791699218, -12.503868526367196],
            [-64.173367916992191, -12.507068526367195],
            [-64.171167916992189, -12.510368526367195],
            [-64.168667916992177, -12.512068526367198],
            [-64.165567916992188, -12.513868526367197],
            [-64.160867916992188, -12.514568526367196],
            [-64.156467916992185, -12.513468526367195],
            [-64.153667916992191, -12.511468526367196],
            [-64.151467916992189, -12.509068526367198],
            [-64.14906791699218, -12.505468526367196],
            [-64.146567916992183, -12.501668526367197],
            [-64.145667916992181, -12.498068526367195],
            [-64.14506791699219, -12.492968526367196],
            [-64.14506791699219, -12.489968526367196],
            [-64.144267916992192, -12.482768526367195],
            [-64.141067916992185, -12.478568526367198],
            [-64.137667916992186, -12.476968526367195],
            [-64.13436791699219, -12.476368526367196],
            [-64.130167916992178, -12.476968526367195],
            [-64.127767916992184, -12.478868526367197],
            [-64.124867916992187, -12.482168526367197],
            [-64.123067916992184, -12.485068526367197],
            [-64.122167916992183, -12.489368526367198],
            [-64.122667916992185, -12.493868526367198],
            [-64.123067916992184, -12.497168526367197],
            [-64.124167916992178, -12.500768526367196],
            [-64.122967916992181, -12.504868526367197],
            [-64.118367916992185, -12.504568526367198],
            [-64.114967916992185, -12.503568526367197],
            [-64.112467916992188, -12.501068526367195],
            [-64.110567916992181, -12.498568526367198],
            [-64.108067916992184, -12.496668526367195],
            [-64.10526791699219, -12.495468526367198],
            [-64.102467916992182, -12.492968526367196],
            [-64.10096791699219, -12.490068526367196],
            [-64.099467916992182, -12.486068526367195],
            [-64.096367916992179, -12.484368526367195],
            [-64.092067916992193, -12.483868526367196],
            [-64.089467916992191, -12.485268526367197],
            [-64.089367916992188, -12.489768526367197],
            [-64.088967916992189, -12.495268526367198],
            [-64.08656791699218, -12.498868526367197],
            [-64.082867916992186, -12.500868526367196],
            [-64.076367916992183, -12.501268526367195],
            [-64.073567916992189, -12.499868526367198],
            [-64.069067916992182, -12.494068526367197],
            [-64.064567916992189, -12.491168526367197],
            [-64.059967916992179, -12.490868526367198],
            [-64.056967916992193, -12.491968526367195],
            [-64.053067916992191, -12.494968526367195],
            [-64.050567916992179, -12.497668526367196],
            [-64.048567916992184, -12.500768526367196],
            [-64.046067916992186, -12.504568526367198],
            [-64.043367916992182, -12.510468526367195],
            [-64.03946791699218, -12.514968526367195],
            [-64.035867916992188, -12.516068526367196],
            [-64.032267916992183, -12.515368526367197],
            [-64.029267916992183, -12.513968526367197],
            [-64.026467916992189, -12.511768526367195],
            [-64.02406791699218, -12.509668526367197],
            [-64.021267916992187, -12.508168526367196],
            [-64.018467916992179, -12.508768526367195],
            [-64.013267916992191, -12.514368526367196],
            [-64.010467916992184, -12.517468526367196],
            [-64.005767916992184, -12.517068526367197],
            [-64.002767916992184, -12.512768526367196],
            [-64.001067916992184, -12.504168526367195],
            [-63.998367916992187, -12.503068526367198],
            [-63.995267916992184, -12.503368526367197],
            [-63.991967916992181, -12.504868526367197],
            [-63.986467916992183, -12.509168526367198],
            [-63.983167916992187, -12.511768526367195],
            [-63.981367916992184, -12.514368526367196],
            [-63.978567916992183, -12.517168526367197],
            [-63.976167916992182, -12.520168526367197],
            [-63.969567916992183, -12.525468526367195],
            [-63.966667916992186, -12.527568526367197],
            [-63.963367916992183, -12.529768526367196],
            [-63.960467916992187, -12.530568526367198],
            [-63.957067916992187, -12.531268526367196],
            [-63.952467916992184, -12.531668526367195],
            [-63.946567916992187, -12.529768526367196],
            [-63.942767916992182, -12.527668526367197],
            [-63.939067916992187, -12.526168526367197],
            [-63.935567916992184, -12.523168526367197],
            [-63.932767916992184, -12.520668526367196],
            [-63.927867916992184, -12.516868526367197],
            [-63.924367916992182, -12.514268526367196],
            [-63.920367916992184, -12.511268526367196],
            [-63.914167916992184, -12.510968526367197],
            [-63.908467916992187, -12.510468526367195],
            [-63.902867916992186, -12.507668526367198],
            [-63.900367916992181, -12.505268526367196],
            [-63.897867916992183, -12.500168526367197],
            [-63.897867916992183, -12.495568526367197],
            [-63.900367916992181, -12.488568526367196],
            [-63.900867916992183, -12.484968526367197],
            [-63.900467916992184, -12.481768526367198],
            [-63.895767916992185, -12.475968526367197],
            [-63.893467916992186, -12.472268526367195],
            [-63.890667916992186, -12.468468526367197],
            [-63.889267916992182, -12.464768526367195],
            [-63.889267916992182, -12.461268526367196],
            [-63.890667916992186, -12.455168526367196],
            [-63.888467916992184, -12.448468526367197],
            [-63.884367916992183, -12.446268526367195],
            [-63.880267916992182, -12.445568526367197],
            [-63.876567916992187, -12.446268526367195],
            [-63.871567916992184, -12.447668526367195],
            [-63.865367916992184, -12.451568526367197],
            [-63.861467916992183, -12.454068526367195],
            [-63.857767916992188, -12.456268526367197],
            [-63.851667916992184, -12.459368526367196],
            [-63.848067916992186, -12.460368526367198],
            [-63.843367916992186, -12.461768526367198],
            [-63.840167916992186, -12.462868526367195],
            [-63.836967916992187, -12.463368526367198],
            [-63.832867916992186, -12.462968526367195],
            [-63.827467916992184, -12.459268526367197],
            [-63.823467916992186, -12.453468526367196],
            [-63.821067916992185, -12.449968526367197],
            [-63.817967916992188, -12.447068526367197],
            [-63.814867916992185, -12.447468526367196],
            [-63.810767916992184, -12.449068526367196],
            [-63.806067916992184, -12.448468526367197],
            [-63.802767916992181, -12.446768526367197],
            [-63.799367916992182, -12.443868526367197],
            [-63.797267916992183, -12.441268526367196],
            [-63.794267916992183, -12.435868526367198],
            [-63.791067916992183, -12.430468526367196],
            [-63.787267916992185, -12.427968526367195],
            [-63.78346791699218, -12.429168526367196],
            [-63.779767916992185, -12.433068526367197],
            [-63.774867916992186, -12.436668526367196],
            [-63.768467916992186, -12.440268526367195],
            [-63.763467916992184, -12.441968526367198],
            [-63.758767916992184, -12.441668526367195],
            [-63.74696791699219, -12.439368526367197],
            [-63.741967916992181, -12.438368526367196],
            [-63.733667916992189, -12.438568526367195],
            [-63.729167916992182, -12.440168526367195],
            [-63.726367916992189, -12.440768526367197],
            [-63.72096791699218, -12.441868526367195],
            [-63.716967916992189, -12.443268526367195],
            [-63.713167916992184, -12.443768526367197],
            [-63.709767916992185, -12.444868526367195],
            [-63.705167916992181, -12.445968526367196],
            [-63.698567916992189, -12.447768526367195],
            [-63.695967916992188, -12.449068526367196],
            [-63.691567916992184, -12.449368526367195],
            [-63.684767916992186, -12.452968526367197],
            [-63.680067916992186, -12.457868526367196],
            [-63.676167916992185, -12.461568526367195],
            [-63.671467916992185, -12.463268526367198],
            [-63.666967916992185, -12.463268526367198],
            [-63.659267916992185, -12.461968526367198],
            [-63.655867916992186, -12.461968526367198],
            [-63.651767916992185, -12.464068526367196],
            [-63.647867916992183, -12.465868526367196],
            [-63.642567916992185, -12.467268526367196],
            [-63.638967916992186, -12.468368526367197],
            [-63.633167916992186, -12.470868526367195],
            [-63.630167916992185, -12.471668526367196],
            [-63.624067916992189, -12.472268526367195],
            [-63.620567916992186, -12.474568526367197],
            [-63.619067916992186, -12.478568526367198],
            [-63.617267916992184, -12.480968526367196],
            [-63.61466791699219, -12.484668526367198],
            [-63.611067916992184, -12.486168526367198],
            [-63.605567916992186, -12.486968526367196],
            [-63.593667916992189, -12.489768526367197],
            [-63.589767916992187, -12.491168526367197],
            [-63.585067916992188, -12.493668526367195],
            [-63.581467916992182, -12.496968526367198],
            [-63.576867916992185, -12.500568526367196],
            [-63.57286791699218, -12.502368526367196],
            [-63.569267916992189, -12.502968526367198],
            [-63.564867916992185, -12.503068526367198],
            [-63.560267916992188, -12.504168526367195],
            [-63.553967916992185, -12.506568526367197],
            [-63.55046791699219, -12.508468526367196],
            [-63.54716791699218, -12.512968526367196],
            [-63.544967916992185, -12.516068526367196],
            [-63.54246791699218, -12.520368526367196],
            [-63.540067916992186, -12.524468526367198],
            [-63.53846791699219, -12.527968526367196],
            [-63.53676791699219, -12.530468526367198],
            [-63.532367916992186, -12.538868526367196],
            [-63.527267916992187, -12.545568526367198],
            [-63.524767916992189, -12.547468526367197],
            [-63.520967916992184, -12.549968526367195],
            [-63.51536791699219, -12.554168526367196],
            [-63.51206791699218, -12.557568526367195],
            [-63.508167916992186, -12.562768526367197],
            [-63.505467916992188, -12.565268526367195],
            [-63.502067916992189, -12.566968526367198],
            [-63.498567916992187, -12.567368526367197],
            [-63.494167916992183, -12.567468526367197],
            [-63.489967916992185, -12.566568526367195],
            [-63.482867916992184, -12.564168526367197],
            [-63.478567916992183, -12.562668526367197],
            [-63.474867916992181, -12.561968526367195],
            [-63.470667916992184, -12.561668526367196],
            [-63.465167916992186, -12.562368526367198],
            [-63.462267916992189, -12.562368526367198],
            [-63.458167916992181, -12.561868526367196],
            [-63.451467916992186, -12.562668526367197],
            [-63.448467916992186, -12.563368526367196],
            [-63.44186791699218, -12.563568526367195],
            [-63.438767916992184, -12.564368526367197],
            [-63.434667916992183, -12.566668526367195],
            [-63.43716791699218, -12.572468526367196],
            [-63.438067916992182, -12.576468526367197],
            [-63.437967916992186, -12.581468526367196],
            [-63.436567916992189, -12.585468526367197],
            [-63.433367916992182, -12.588268526367198],
            [-63.429367916992184, -12.591268526367195],
            [-63.427267916992186, -12.593668526367196],
            [-63.426567916992184, -12.597068526367195],
            [-63.431867916992189, -12.598468526367196],
            [-63.436067916992187, -12.599568526367197],
            [-63.440567916992187, -12.600668526367198],
            [-63.440767916992186, -12.604968526367195],
            [-63.438067916992182, -12.608268526367198],
            [-63.433567916992189, -12.609368526367195],
            [-63.429067916992182, -12.607568526367196],
            [-63.42216791699218, -12.606668526367198],
            [-63.418067916992186, -12.607568526367196],
            [-63.413967916992185, -12.608868526367196],
            [-63.410967916992185, -12.610368526367196],
            [-63.408367916992184, -12.613668526367196],
            [-63.40676791699218, -12.618268526367196],
            [-63.404567916992185, -12.626568526367198],
            [-63.403667916992184, -12.629668526367198],
            [-63.401567916992185, -12.634968526367196],
            [-63.397067916992185, -12.639968526367195],
            [-63.393467916992186, -12.643168526367198],
            [-63.389567916992185, -12.647068526367196],
            [-63.384967916992181, -12.651268526367197],
            [-63.378067916992187, -12.655368526367198],
            [-63.37466791699218, -12.657068526367198],
            [-63.36866791699218, -12.659468526367196],
            [-63.36466791699219, -12.658868526367197],
            [-63.361767916992186, -12.657568526367196],
            [-63.357567916992181, -12.657068526367198],
            [-63.354267916992185, -12.656268526367196],
            [-63.350267916992181, -12.656668526367195],
            [-63.34626791699219, -12.658468526367198],
            [-63.343067916992183, -12.660268526367197],
            [-63.339767916992187, -12.661768526367197],
            [-63.336967916992187, -12.663868526367196],
            [-63.334767916992185, -12.666668526367197],
            [-63.33226791699218, -12.668068526367197],
            [-63.328567916992185, -12.669668526367197],
            [-63.322967916992184, -12.671468526367196],
            [-63.317467916992186, -12.673268526367195],
            [-63.313567916992184, -12.674968526367195],
            [-63.31086791699218, -12.676168526367196],
            [-63.305467916992185, -12.677968526367195],
            [-63.301167916992185, -12.679668526367195],
            [-63.295367916992184, -12.681868526367197],
            [-63.291667916992182, -12.682268526367196],
            [-63.286267916992188, -12.683268526367197],
            [-63.281967916992187, -12.683568526367196],
            [-63.27876791699218, -12.684168526367195],
            [-63.272567916992188, -12.684168526367195],
            [-63.26706791699219, -12.683368526367197],
            [-63.262767916992189, -12.683268526367197],
            [-63.255467916992188, -12.684968526367197],
            [-63.25166791699219, -12.686868526367196],
            [-63.247467916992186, -12.689068526367198],
            [-63.244067916992186, -12.690468526367198],
            [-63.239767916992186, -12.690868526367197],
            [-63.23496791699219, -12.688068526367196],
            [-63.231767916992183, -12.683668526367196],
            [-63.237267916992181, -12.679368526367195],
            [-63.239967916992185, -12.675368526367198],
            [-63.241067916992186, -12.669668526367197],
            [-63.245267916992184, -12.665968526367195],
            [-63.244667916992185, -12.661668526367198],
            [-63.23966791699219, -12.661668526367198],
            [-63.236867916992182, -12.662868526367195],
            [-63.233167916992187, -12.664868526367197],
            [-63.229567916992181, -12.664768526367197],
            [-63.22696791699218, -12.663368526367197],
            [-63.223767916992188, -12.659868526367195],
            [-63.220167916992182, -12.657068526367198],
            [-63.216967916992189, -12.655368526367198],
            [-63.211467916992184, -12.655068526367195],
            [-63.207067916992187, -12.656168526367196],
            [-63.202967916992186, -12.656768526367195],
            [-63.197167916992186, -12.655068526367195],
            [-63.192767916992182, -12.651468526367196],
            [-63.189967916992188, -12.647568526367195],
            [-63.189867916992185, -12.644368526367195],
            [-63.190267916992184, -12.641068526367196],
            [-63.19186791699218, -12.638468526367195],
            [-63.194267916992189, -12.635968526367197],
            [-63.197167916992186, -12.631268526367197],
            [-63.19686791699219, -12.626668526367197],
            [-63.19386791699219, -12.623868526367197],
            [-63.189067916992187, -12.624068526367196],
            [-63.185767916992184, -12.624968526367198],
            [-63.183267916992186, -12.626668526367197],
            [-63.179767916992184, -12.631768526367196],
            [-63.177967916992188, -12.635668526367198],
            [-63.176067916992181, -12.638168526367195],
            [-63.173967916992183, -12.640368526367197],
            [-63.168967916992187, -12.642168526367197],
            [-63.162767916992181, -12.639368526367196],
            [-63.16176791699219, -12.635768526367197],
            [-63.162867916992184, -12.632968526367197],
            [-63.165567916992188, -12.629168526367195],
            [-63.16746791699218, -12.624968526367198],
            [-63.167067916992181, -12.620268526367198],
            [-63.163067916992183, -12.614968526367196],
            [-63.158867916992186, -12.613968526367195],
            [-63.155967916992182, -12.615768526367198],
            [-63.153667916992184, -12.619468526367196],
            [-63.153467916992184, -12.624468526367195],
            [-63.153167916992189, -12.630568526367195],
            [-63.150967916992187, -12.634368526367197],
            [-63.145367916992186, -12.637368526367197],
            [-63.137767916992189, -12.639868526367195],
            [-63.138567916992187, -12.642868526367195],
            [-63.139867916992188, -12.645468526367196],
            [-63.134967916992181, -12.648168526367197],
            [-63.128767916992189, -12.645668526367196],
            [-63.125867916992185, -12.647068526367196],
            [-63.122667916992185, -12.653768526367195],
            [-63.119367916992189, -12.657368526367197],
            [-63.113567916992181, -12.658968526367197],
            [-63.106367916992184, -12.656768526367195],
            [-63.103167916992184, -12.655168526367195],
            [-63.097767916992183, -12.655868526367197],
            [-63.093467916992182, -12.658068526367195],
            [-63.091167916992184, -12.662268526367196],
            [-63.091667916992186, -12.667468526367195],
            [-63.092967916992187, -12.671068526367197],
            [-63.095567916992181, -12.673168526367196],
            [-63.098467916992185, -12.676568526367195],
            [-63.098067916992186, -12.680068526367197],
            [-63.096367916992186, -12.682968526367198],
            [-63.092867916992184, -12.683868526367196],
            [-63.087967916992184, -12.684068526367195],
            [-63.083167916992181, -12.685168526367196],
            [-63.078567916992185, -12.685768526367195],
            [-63.07486791699219, -12.684968526367197],
            [-63.071267916992184, -12.682968526367198],
            [-63.067667916992185, -12.682668526367195],
            [-63.063567916992184, -12.684168526367195],
            [-63.061067916992187, -12.687468526367198],
            [-63.061267916992186, -12.691868526367195],
            [-63.064667916992185, -12.696068526367196],
            [-63.069067916992182, -12.697468526367196],
            [-63.072067916992182, -12.698768526367196],
            [-63.077067916992185, -12.700268526367196],
            [-63.079667916992186, -12.703168526367197],
            [-63.081867916992181, -12.706768526367195],
            [-63.08256791699219, -12.710768526367197],
            [-63.082867916992186, -12.715368526367197],
            [-63.082367916992183, -12.720568526367195],
            [-63.080667916992184, -12.725068526367195],
            [-63.07756791699218, -12.728868526367197],
            [-63.073867916992185, -12.730568526367197],
            [-63.069067916992182, -12.730868526367196],
            [-63.061267916992186, -12.728068526367196],
            [-63.057267916992188, -12.729268526367196],
            [-63.054067916992182, -12.731768526367198],
            [-63.051667916992187, -12.734368526367195],
            [-63.049467916992185, -12.737768526367198],
            [-63.04746791699219, -12.740068526367196],
            [-63.045367916992184, -12.744368526367197],
            [-63.043167916992189, -12.747668526367196],
            [-63.041767916992185, -12.750168526367197],
            [-63.040567916992188, -12.755168526367196],
            [-63.040067916992186, -12.758168526367196],
            [-63.040567916992188, -12.762068526367198],
            [-63.040267916992185, -12.765168526367198],
            [-63.035967916992185, -12.770668526367196],
            [-63.035667916992182, -12.774868526367197],
            [-63.03506791699219, -12.777668526367197],
            [-63.033067916992181, -12.781268526367196],
            [-63.028967916992187, -12.782868526367196],
            [-63.025867916992183, -12.785168526367197],
            [-63.022167916992188, -12.787768526367195],
            [-63.019867916992183, -12.791768526367196],
            [-63.021467916992187, -12.799368526367196],
            [-63.021167916992184, -12.805268526367197],
            [-63.018667916992186, -12.806968526367196],
            [-63.015667916992186, -12.806868526367197],
            [-63.012467916992186, -12.807968526367198],
            [-63.008867916992187, -12.809868526367197],
            [-63.009267916992187, -12.816068526367197],
            [-63.01206791699218, -12.821068526367196],
            [-63.012967916992181, -12.824268526367195],
            [-63.013467916992184, -12.829868526367196],
            [-63.012167916992183, -12.834068526367197],
            [-63.009167916992183, -12.837668526367196],
            [-63.00636791699219, -12.839568526367195],
            [-63.000967916992181, -12.841768526367197],
            [-62.991567916992182, -12.841268526367195],
            [-62.983367916992187, -12.844068526367195],
            [-62.983167916992187, -12.849468526367197],
            [-62.98196791699219, -12.853668526367198],
            [-62.97896791699219, -12.858368526367197],
            [-62.976167916992182, -12.859768526367198],
            [-62.970867916992184, -12.858868526367196],
            [-62.968667916992189, -12.854968526367195],
            [-62.965467916992182, -12.850268526367195],
            [-62.963167916992184, -12.847268526367195],
            [-62.957967916992189, -12.847268526367195],
            [-62.951267916992187, -12.850568526367198],
            [-62.950467916992181, -12.854968526367195],
            [-62.947467916992181, -12.858568526367197],
            [-62.942967916992188, -12.854568526367196],
            [-62.941667916992188, -12.846968526367196],
            [-62.937767916992186, -12.842968526367198],
            [-62.931867916992189, -12.841968526367197],
            [-62.926567916992184, -12.841468526367198],
            [-62.921367916992182, -12.840868526367196],
            [-62.915667916992184, -12.844168526367195],
            [-62.910667916992182, -12.850268526367195],
            [-62.908967916992182, -12.852768526367196],
            [-62.905867916992186, -12.854668526367195],
            [-62.900367916992181, -12.855068526367198],
            [-62.896567916992183, -12.855868526367196],
            [-62.892667916992181, -12.858268526367198],
            [-62.890667916992186, -12.860868526367195],
            [-62.891767916992187, -12.864368526367198],
            [-62.893667916992186, -12.869468526367196],
            [-62.894067916992185, -12.872968526367195],
            [-62.893167916992184, -12.877068526367196],
            [-62.891067916992185, -12.879868526367197],
            [-62.888467916992184, -12.882168526367195],
            [-62.884667916992186, -12.883568526367196],
            [-62.880467916992188, -12.887168526367198],
            [-62.878467916992186, -12.890168526367198],
            [-62.875767916992189, -12.895468526367196],
            [-62.875167916992183, -12.898668526367196],
            [-62.872767916992188, -12.906268526367196],
            [-62.870167916992187, -12.910568526367197],
            [-62.866067916992186, -12.911968526367197],
            [-62.861467916992183, -12.912468526367196],
            [-62.858867916992182, -12.915268526367196],
            [-62.855367916992186, -12.918168526367197],
            [-62.845567916992181, -12.923068526367196],
            [-62.843067916992183, -12.927168526367197],
            [-62.843467916992182, -12.932168526367196],
            [-62.84726791699218, -12.936068526367198],
            [-62.850267916992181, -12.940868526367197],
            [-62.849767916992185, -12.944368526367196],
            [-62.845867916992184, -12.948568526367197],
            [-62.841967916992189, -12.948268526367197],
            [-62.838967916992189, -12.947368526367196],
            [-62.836467916992184, -12.943168526367195],
            [-62.832067916992187, -12.937768526367197],
            [-62.822967916992184, -12.933768526367196],
            [-62.817967916992188, -12.935268526367196],
            [-62.814967916992188, -12.936668526367196],
            [-62.810267916992188, -12.937968526367197],
            [-62.805867916992185, -12.940568526367198],
            [-62.803267916992183, -12.944968526367198],
            [-62.805867916992185, -12.952568526367195],
            [-62.807567916992184, -12.954868526367196],
            [-62.808067916992186, -12.958268526367196],
            [-62.808867916992185, -12.962068526367197],
            [-62.806867916992189, -12.968068526367198],
            [-62.803567916992186, -12.974468526367197],
            [-62.79746791699219, -12.983868526367196],
            [-62.796967916992187, -12.987268526367195],
            [-62.79576791699219, -12.991968526367195],
            [-62.794267916992183, -12.995168526367195],
            [-62.787767916992181, -13.002168526367196],
            [-62.780067916992181, -13.008868526367195],
            [-62.772967916992187, -13.009968526367196],
            [-62.767667916992181, -13.012468526367197],
            [-62.76406791699219, -13.015668526367197],
            [-62.759667916992186, -13.018168526367198],
            [-62.75636791699219, -13.017168526367197],
            [-62.754567916992187, -13.008168526367196],
            [-62.753467916992186, -13.004668526367198],
            [-62.752167916992185, -13.002068526367196],
            [-62.750267916992186, -12.999868526367198],
            [-62.746867916992187, -12.998868526367197],
            [-62.743367916992185, -13.001268526367195],
            [-62.744367916992189, -13.005168526367196],
            [-62.744167916992183, -13.008768526367195],
            [-62.740767916992183, -13.012468526367197],
            [-62.738567916992181, -13.014368526367196],
            [-62.736467916992183, -13.018568526367197],
            [-62.733967916992185, -13.021768526367197],
            [-62.729167916992182, -13.020668526367196],
            [-62.72696791699218, -13.014668526367196],
            [-62.728867916992186, -13.008568526367196],
            [-62.72726791699219, -13.003068526367198],
            [-62.725067916992188, -13.001268526367195],
            [-62.719067916992188, -13.003368526367197],
            [-62.713167916992184, -13.004168526367195],
            [-62.709267916992189, -13.002468526367196],
            [-62.706867916992181, -12.999868526367198],
            [-62.699367916992188, -13.002668526367195],
            [-62.694367916992185, -12.992168526367195],
            [-62.690567916992187, -12.991568526367196],
            [-62.687267916992184, -12.992768526367197],
            [-62.682467916992181, -12.992968526367196],
            [-62.676067916992181, -12.991368526367197],
            [-62.669967916992185, -12.987168526367196],
            [-62.667167916992184, -12.984668526367198],
            [-62.66446791699218, -12.980568526367197],
            [-62.663167916992187, -12.975968526367197],
            [-62.65976791699218, -12.968668526367196],
            [-62.655367916992184, -12.966568526367197],
            [-62.65076791699218, -12.965568526367196],
            [-62.645367916992186, -12.967668526367195],
            [-62.645167916992186, -12.974568526367197],
            [-62.64906791699218, -12.980668526367197],
            [-62.651467916992189, -12.983968526367196],
            [-62.652067916992181, -12.989368526367198],
            [-62.650667916992184, -12.991868526367195],
            [-62.646467916992187, -12.993068526367196],
            [-62.641067916992185, -12.990868526367198],
            [-62.638867916992183, -12.989068526367195],
            [-62.634967916992181, -12.988568526367196],
            [-62.63136791699219, -12.990068526367196],
            [-62.629067916992184, -12.995768526367197],
            [-62.629867916992183, -12.999668526367195],
            [-62.631767916992189, -13.004068526367195],
            [-62.63266791699219, -13.011768526367195],
            [-62.629167916992188, -13.016068526367196],
            [-62.625467916992186, -13.017668526367196],
            [-62.620267916992184, -13.017068526367197],
            [-62.613667916992185, -13.013568526367198],
            [-62.610267916992186, -13.011368526367196],
            [-62.606767916992183, -13.010968526367197],
            [-62.602567916992186, -13.012668526367197],
            [-62.600267916992181, -13.017468526367196],
            [-62.606167916992185, -13.021868526367196],
            [-62.610667916992185, -13.024268526367198],
            [-62.614067916992184, -13.026168526367197],
            [-62.61466791699219, -13.028968526367198],
            [-62.615267916992181, -13.032868526367196],
            [-62.614767916992186, -13.036468526367198],
            [-62.612467916992188, -13.041368526367197],
            [-62.608967916992185, -13.043968526367195],
            [-62.600367916992184, -13.048668526367198],
            [-62.595567916992181, -13.049668526367196],
            [-62.589267916992185, -13.051168526367196],
            [-62.581767916992185, -13.054468526367195],
            [-62.575967916992184, -13.057768526367198],
            [-62.567467916992186, -13.062168526367195],
            [-62.562467916992183, -13.063768526367195],
            [-62.557967916992183, -13.065568526367198],
            [-62.555267916992186, -13.067368526367197],
            [-62.551867916992187, -13.070268526367197],
            [-62.54716791699218, -13.073968526367196],
            [-62.541167916992187, -13.075468526367196],
            [-62.534867916992184, -13.073268526367197],
            [-62.529867916992188, -13.069868526367195],
            [-62.526467916992189, -13.067068526367198],
            [-62.523767916992185, -13.065468526367198],
            [-62.520967916992184, -13.067168526367198],
            [-62.518167916992184, -13.071668526367198],
            [-62.513767916992187, -13.075768526367195],
            [-62.508867916992187, -13.079068526367195],
            [-62.505267916992182, -13.081768526367195],
            [-62.501567916992187, -13.082868526367196],
            [-62.496267916992181, -13.081268526367197],
            [-62.490767916992183, -13.079268526367198],
            [-62.486167916992187, -13.077868526367197],
            [-62.482867916992184, -13.076068526367198],
            [-62.48166791699218, -13.071868526367197],
            [-62.48196791699219, -13.068868526367197],
            [-62.483367916992187, -13.063268526367196],
            [-62.482567916992181, -13.058768526367196],
            [-62.479267916992185, -13.057468526367195],
            [-62.475867916992186, -13.057468526367195],
            [-62.473067916992186, -13.061568526367196],
            [-62.472067916992188, -13.066268526367196],
            [-62.473667916992184, -13.072968526367195],
            [-62.47256791699219, -13.077468526367195],
            [-62.467967916992187, -13.078968526367195],
            [-62.465567916992185, -13.077168526367196],
            [-62.462967916992184, -13.073468526367197],
            [-62.461167916992181, -13.066568526367195],
            [-62.457167916992184, -13.063868526367198],
            [-62.453267916992189, -13.066068526367197],
            [-62.452367916992188, -13.070168526367198],
            [-62.453567916992185, -13.072768526367195],
            [-62.456867916992181, -13.075968526367195],
            [-62.459067916992183, -13.081068526367197],
            [-62.457367916992183, -13.086568526367195],
            [-62.451867916992185, -13.087668526367196],
            [-62.448267916992187, -13.085368526367198],
            [-62.445267916992186, -13.081768526367195],
            [-62.442767916992182, -13.079868526367196],
            [-62.439867916992185, -13.079268526367198],
            [-62.436967916992188, -13.080468526367195],
            [-62.434867916992189, -13.082668526367197],
            [-62.433767916992181, -13.086968526367198],
            [-62.435167916992185, -13.095068526367196],
            [-62.43586791699218, -13.098368526367196],
            [-62.434367916992187, -13.101968526367195],
            [-62.429367916992184, -13.107468526367196],
            [-62.425867916992189, -13.109968526367197],
            [-62.418167916992189, -13.117768526367197],
            [-62.418067916992186, -13.121968526367198],
            [-62.416967916992185, -13.124668526367195],
            [-62.413967916992185, -13.128268526367197],
            [-62.410667916992182, -13.126968526367197],
            [-62.407267916992183, -13.123868526367197],
            [-62.403367916992181, -13.118268526367196],
            [-62.399267916992187, -13.113368526367196],
            [-62.396167916992184, -13.114368526367198],
            [-62.394867916992183, -13.118568526367195],
            [-62.395967916992184, -13.124668526367195],
            [-62.397367916992181, -13.130168526367196],
            [-62.39206791699219, -13.134668526367197],
            [-62.387667916992186, -13.132368526367195],
            [-62.382467916992184, -13.130768526367195],
            [-62.379467916992184, -13.133468526367196],
            [-62.377667916992181, -13.138468526367195],
            [-62.372467916992186, -13.140668526367197],
            [-62.369067916992186, -13.140768526367197],
            [-62.364067916992184, -13.139568526367196],
            [-62.360867916992184, -13.139868526367195],
            [-62.35666791699218, -13.142868526367195],
            [-62.35196791699218, -13.143268526367198],
            [-62.349567916992186, -13.139068526367197],
            [-62.345567916992181, -13.137068526367198],
            [-62.337367916992186, -13.140468526367197],
            [-62.342367916992188, -13.145768526367196],
            [-62.340967916992184, -13.149868526367197],
            [-62.336167916992181, -13.147968526367197],
            [-62.333267916992185, -13.143168526367198],
            [-62.330467916992184, -13.139868526367195],
            [-62.326067916992187, -13.144668526367198],
            [-62.325367916992185, -13.150068526367196],
            [-62.321367916992187, -13.152068526367195],
            [-62.319667916992188, -13.147168526367196],
            [-62.318767916992186, -13.138468526367195],
            [-62.316667916992188, -13.134368526367197],
            [-62.305067916992186, -13.140368526367197],
            [-62.303267916992183, -13.146068526367195],
            [-62.299367916992182, -13.150468526367195],
            [-62.294967916992185, -13.149868526367197],
            [-62.29276791699219, -13.144868526367198],
            [-62.287367916992181, -13.140468526367197],
            [-62.284567916992188, -13.142068526367197],
            [-62.282567916992186, -13.149868526367197],
            [-62.278467916992184, -13.155368526367198],
            [-62.271567916992183, -13.155168526367195],
            [-62.275467916992184, -13.151268526367197],
            [-62.276567916992185, -13.146168526367195],
            [-62.272867916992183, -13.146768526367197],
            [-62.267867916992188, -13.149068526367195],
            [-62.264567916992185, -13.153168526367196],
            [-62.261567916992185, -13.150668526367195],
            [-62.26506791699218, -13.146868526367196],
            [-62.259867916992185, -13.146468526367197],
            [-62.255267916992182, -13.144368526367195],
            [-62.245867916992182, -13.145068526367197],
            [-62.245267916992184, -13.142168526367197],
            [-62.245467916992183, -13.138468526367195],
            [-62.244367916992189, -13.134968526367196],
            [-62.24366791699218, -13.130668526367195],
            [-62.245467916992183, -13.125968526367195],
            [-62.244967916992181, -13.120768526367197],
            [-62.243367916992185, -13.117268526367198],
            [-62.240567916992184, -13.115768526367198],
            [-62.235767916992188, -13.116968526367195],
            [-62.232467916992185, -13.120768526367197],
            [-62.229467916992185, -13.121568526367195],
            [-62.225567916992183, -13.117968526367196],
            [-62.223067916992186, -13.113668526367196],
            [-62.219767916992183, -13.111468526367197],
            [-62.214767916992187, -13.111368526367198],
            [-62.211167916992181, -13.113268526367197],
            [-62.206267916992189, -13.116868526367195],
            [-62.203167916992186, -13.121168526367196],
            [-62.200967916992184, -13.124468526367195],
            [-62.199667916992183, -13.131368526367197],
            [-62.200667916992188, -13.137368526367197],
            [-62.201367916992183, -13.141768526367198],
            [-62.200167916992186, -13.150468526367195],
            [-62.19516791699219, -13.153968526367198],
            [-62.190967916992186, -13.153768526367195],
            [-62.186867916992185, -13.150968526367198],
            [-62.182267916992188, -13.147568526367195],
            [-62.178267916992183, -13.142968526367195],
            [-62.177567916992189, -13.138168526367195],
            [-62.181367916992187, -13.132968526367197],
            [-62.183667916992185, -13.127768526367195],
            [-62.183867916992185, -13.123068526367195],
            [-62.181367916992187, -13.117668526367197],
            [-62.178667916992183, -13.115068526367196],
            [-62.174967916992188, -13.113568526367196],
            [-62.170367916992184, -13.113868526367195],
            [-62.166967916992185, -13.116368526367197],
            [-62.166367916992186, -13.120168526367195],
            [-62.168567916992188, -13.125468526367197],
            [-62.169567916992186, -13.129068526367195],
            [-62.16946791699219, -13.134168526367198],
            [-62.162567916992188, -13.142568526367196],
            [-62.16006791699219, -13.146468526367197],
            [-62.157267916992183, -13.147168526367196],
            [-62.151767916992185, -13.150068526367196],
            [-62.151767916992185, -13.154168526367197],
            [-62.151967916992184, -13.159568526367195],
            [-62.148667916992181, -13.163368526367197],
            [-62.142867916992188, -13.161168526367195],
            [-62.14036791699219, -13.157868526367196],
            [-62.137467916992186, -13.153768526367195],
            [-62.134867916992185, -13.149368526367198],
            [-62.128767916992189, -13.148368526367197],
            [-62.124167916992185, -13.149768526367197],
            [-62.119667916992185, -13.153468526367195],
            [-62.116167916992183, -13.159468526367196],
            [-62.114167916992187, -13.164468526367195],
            [-62.117167916992187, -13.168468526367196],
            [-62.117767916992186, -13.172468526367197],
            [-62.11766791699219, -13.177168526367197],
            [-62.115067916992189, -13.184668526367197],
            [-62.114367916992187, -13.188268526367196],
            [-62.114467916992183, -13.195168526367198],
            [-62.114967916992185, -13.203768526367195],
            [-62.113567916992181, -13.208168526367196],
            [-62.113567916992181, -13.211568526367195],
            [-62.108967916992185, -13.218968526367195],
            [-62.10966791699218, -13.224468526367197],
            [-62.10996791699219, -13.228968526367197],
            [-62.109167916992185, -13.234268526367195],
            [-62.110367916992189, -13.240468526367195],
            [-62.109367916992184, -13.245768526367197],
            [-62.10996791699219, -13.250168526367197],
            [-62.111967916992185, -13.254168526367195],
            [-62.114967916992185, -13.257368526367195],
            [-62.113967916992181, -13.260968526367197],
            [-62.109367916992184, -13.265068526367195],
            [-62.106467916992187, -13.267968526367195],
            [-62.10096791699219, -13.270068526367197],
            [-62.098167916992182, -13.272668526367195],
            [-62.096167916992187, -13.275768526367198],
            [-62.093767916992185, -13.278468526367195],
            [-62.09126791699218, -13.281968526367198],
            [-62.085967916992189, -13.281968526367198],
            [-62.084867916992181, -13.285268526367197],
            [-62.084267916992189, -13.290268526367196],
            [-62.079067916992187, -13.291968526367196],
            [-62.074067916992185, -13.292868526367197],
            [-62.07016791699219, -13.295868526367197],
            [-62.066567916992184, -13.293668526367195],
            [-62.06216791699218, -13.289268526367195],
            [-62.056867916992189, -13.288168526367198],
            [-62.056067916992184, -13.294968526367196],
            [-62.057667916992187, -13.300068526367195],
            [-62.057667916992187, -13.304768526367198],
            [-62.056867916992189, -13.311668526367196],
            [-62.052867916992184, -13.314168526367197],
            [-62.046167916992189, -13.316268526367196],
            [-62.043167916992189, -13.319068526367197],
            [-62.03976791699219, -13.324668526367198],
            [-62.037467916992185, -13.327068526367196],
            [-62.036267916992188, -13.330068526367196],
            [-62.032667916992182, -13.333568526367195],
            [-62.02876791699218, -13.331868526367195],
            [-62.025067916992185, -13.327568526367195],
            [-62.01706791699219, -13.327168526367196],
            [-62.013567916992187, -13.330368526367195],
            [-62.013167916992188, -13.333468526367195],
            [-62.014667916992181, -13.340968526367195],
            [-62.01536791699219, -13.347768526367197],
            [-62.01406791699219, -13.352268526367197],
            [-62.011467916992189, -13.358368526367197],
            [-62.007167916992188, -13.362568526367195],
            [-62.003067916992187, -13.365368526367195],
            [-62.000767916992189, -13.369668526367196],
            [-61.99666791699218, -13.374368526367196],
            [-61.994367916992189, -13.370768526367197],
            [-61.996367916992185, -13.367968526367196],
            [-61.998567916992187, -13.364068526367195],
            [-61.994767916992188, -13.365268526367196],
            [-61.987167916992185, -13.366568526367196],
            [-61.980367916992186, -13.365468526367195],
            [-61.976467916992185, -13.368268526367196],
            [-61.971367916992186, -13.374168526367196],
            [-61.974167916992187, -13.381768526367196],
            [-61.97726791699219, -13.387968526367196],
            [-61.97426791699219, -13.389568526367196],
            [-61.967567916992181, -13.386768526367195],
            [-61.962267916992189, -13.391068526367196],
            [-61.968167916992186, -13.402368526367198],
            [-61.970567916992181, -13.406168526367196],
            [-61.967567916992181, -13.407868526367196],
            [-61.961767916992187, -13.408668526367197],
            [-61.956267916992189, -13.408168526367195],
            [-61.952067916992185, -13.410568526367197],
            [-61.947967916992184, -13.415368526367196],
            [-61.94386791699219, -13.412068526367197],
            [-61.940267916992184, -13.407768526367196],
            [-61.93586791699218, -13.410068526367198],
            [-61.930867916992185, -13.417468526367195],
            [-61.928667916992183, -13.425568526367197],
            [-61.924467916992185, -13.429468526367195],
            [-61.918167916992189, -13.431868526367197],
            [-61.914267916992188, -13.433668526367196],
            [-61.907567916992186, -13.434068526367195],
            [-61.905067916992181, -13.431168526367195],
            [-61.90376791699218, -13.428268526367198],
            [-61.899267916992187, -13.426868526367198],
            [-61.892967916992184, -13.430868526367195],
            [-61.889867916992188, -13.435468526367195],
            [-61.88736791699219, -13.440768526367197],
            [-61.885967916992186, -13.451068526367198],
            [-61.883267916992182, -13.456768526367195],
            [-61.883567916992185, -13.463968526367196],
            [-61.88266791699219, -13.472368526367195],
            [-61.880967916992184, -13.479568526367196],
            [-61.882167916992188, -13.487868526367198],
            [-61.883467916992188, -13.492268526367198],
            [-61.883167916992186, -13.497268526367197],
            [-61.879167916992188, -13.501868526367197],
            [-61.876067916992184, -13.507368526367195],
            [-61.87196791699219, -13.513268526367195],
            [-61.869367916992189, -13.525168526367196],
            [-61.867967916992185, -13.528768526367195],
            [-61.866067916992186, -13.531668526367195],
            [-61.861067916992184, -13.535368526367197],
            [-61.852867916992182, -13.538468526367197],
            [-61.849467916992182, -13.535968526367196],
            [-61.848467916992185, -13.531668526367195],
            [-61.847067916992188, -13.527368526367198],
            [-61.840167916992186, -13.526968526367195],
            [-61.838667916992186, -13.534768526367195],
            [-61.843367916992186, -13.541368526367197],
            [-61.840967916992184, -13.548868526367198],
            [-61.837567916992185, -13.543368526367196],
            [-61.833667916992184, -13.539268526367195],
            [-61.826067916992187, -13.537068526367197],
            [-61.821067916992185, -13.533768526367197],
            [-61.818467916992184, -13.539268526367195],
            [-61.811267916992186, -13.540268526367196],
            [-61.812967916992186, -13.535868526367196],
            [-61.817667916992185, -13.530568526367198],
            [-61.817067916992187, -13.527368526367198],
            [-61.805767916992181, -13.529468526367197],
            [-61.802867916992184, -13.531168526367196],
            [-61.798067916992181, -13.534468526367196],
            [-61.789967916992182, -13.537768526367195],
            [-61.784167916992189, -13.536968526367197],
            [-61.778067916992185, -13.532868526367196],
            [-61.776267916992182, -13.527868526367197],
            [-61.777967916992182, -13.522068526367196],
            [-61.776567916992185, -13.517668526367196],
            [-61.769267916992185, -13.519268526367195],
            [-61.766767916992187, -13.525068526367196],
            [-61.766067916992185, -13.533168526367195],
            [-61.764567916992185, -13.539168526367195],
            [-61.75736791699218, -13.540568526367196],
            [-61.754467916992184, -13.536968526367197],
            [-61.753567916992182, -13.531468526367195],
            [-61.755767916992184, -13.529068526367197],
            [-61.757767916992186, -13.524068526367195],
            [-61.75166791699219, -13.520368526367196],
            [-61.747767916992188, -13.520768526367196],
            [-61.744167916992183, -13.525868526367198],
            [-61.736767916992186, -13.527368526367198],
            [-61.730567916992186, -13.525168526367196],
            [-61.73466791699218, -13.518568526367197],
            [-61.730067916992184, -13.518668526367197],
            [-61.725067916992188, -13.522268526367196],
            [-61.719767916992183, -13.519568526367195],
            [-61.713667916992186, -13.517968526367195],
            [-61.70726791699218, -13.516868526367197],
            [-61.702967916992186, -13.515068526367195],
            [-61.704267916992187, -13.511768526367195],
            [-61.699067916992185, -13.510968526367197],
            [-61.693767916992186, -13.507968526367197],
            [-61.694567916992185, -13.501568526367198],
            [-61.689567916992189, -13.505268526367196],
            [-61.686067916992187, -13.509068526367198],
            [-61.680267916992186, -13.506668526367196],
            [-61.673367916992184, -13.505268526367196],
            [-61.668867916992184, -13.504968526367197],
            [-61.668867916992184, -13.509868526367196],
            [-61.663367916992186, -13.510668526367198],
            [-61.65846791699218, -13.514668526367196],
            [-61.651467916992189, -13.518668526367197],
            [-61.645767916992185, -13.516468526367195],
            [-61.647367916992181, -13.510768526367197],
            [-61.64506791699219, -13.506768526367196],
            [-61.636867916992188, -13.509368526367197],
            [-61.628867916992185, -13.515468526367197],
            [-61.622767916992188, -13.514568526367196],
            [-61.622967916992181, -13.509168526367198],
            [-61.625567916992182, -13.504868526367197],
            [-61.626267916992184, -13.499868526367198],
            [-61.623067916992184, -13.496668526367195],
            [-61.619067916992186, -13.499168526367196],
            [-61.618867916992187, -13.505668526367195],
            [-61.615867916992187, -13.511368526367196],
            [-61.607167916992182, -13.510468526367195],
            [-61.604567916992181, -13.504568526367198],
            [-61.599467916992182, -13.501668526367197],
            [-61.59126791699218, -13.502968526367198],
            [-61.585467916992187, -13.500568526367196],
            [-61.584267916992189, -13.496168526367196],
            [-61.585867916992186, -13.493568526367195],
            [-61.584267916992189, -13.490568526367195],
            [-61.576767916992182, -13.488568526367196],
            [-61.57316791699219, -13.494368526367197],
            [-61.576067916992187, -13.500168526367197],
            [-61.578567916992185, -13.505268526367196],
            [-61.578967916992184, -13.509868526367196],
            [-61.57656791699219, -13.513968526367197],
            [-61.572367916992185, -13.518468526367197],
            [-61.567667916992185, -13.521168526367195],
            [-61.563067916992182, -13.521468526367197],
            [-61.559667916992183, -13.519868526367198],
            [-61.556367916992187, -13.518168526367198],
            [-61.553567916992186, -13.519568526367195],
            [-61.548367916992184, -13.523668526367196],
            [-61.543067916992186, -13.525368526367195],
            [-61.534567916992188, -13.528968526367198],
            [-61.532867916992188, -13.533068526367195],
            [-61.529767916992185, -13.534468526367196],
            [-61.52576791699218, -13.534468526367196],
            [-61.521767916992189, -13.535368526367197],
            [-61.51406791699219, -13.543068526367197],
            [-61.508167916992186, -13.547868526367196],
            [-61.503067916992187, -13.548168526367196],
            [-61.500167916992183, -13.548568526367195],
            [-61.495467916992183, -13.547868526367196],
            [-61.491867916992184, -13.546368526367196],
            [-61.489067916992184, -13.547468526367197],
            [-61.479167916992182, -13.553668526367197],
            [-61.474967916992185, -13.553368526367198],
            [-61.470267916992185, -13.554668526367195],
            [-61.465667916992189, -13.554068526367196],
            [-61.460067916992188, -13.551468526367195],
            [-61.455067916992185, -13.549668526367196],
            [-61.442767916992182, -13.547468526367197],
            [-61.434767916992186, -13.546368526367196],
            [-61.429467916992181, -13.545368526367195],
            [-61.423967916992183, -13.542268526367195],
            [-61.418567916992188, -13.539568526367198],
            [-61.413167916992187, -13.537868526367195],
            [-61.409467916992185, -13.537568526367195],
            [-61.405067916992181, -13.539568526367198],
            [-61.402367916992183, -13.537768526367195],
            [-61.404467916992189, -13.533368526367195],
            [-61.404867916992188, -13.528368526367196],
            [-61.401267916992182, -13.524068526367195],
            [-61.394867916992183, -13.521468526367197],
            [-61.389667916992181, -13.519768526367198],
            [-61.38436791699219, -13.519368526367195],
            [-61.378867916992185, -13.520468526367196],
            [-61.374367916992185, -13.521068526367195],
            [-61.369667916992185, -13.520968526367195],
            [-61.365267916992181, -13.521068526367195],
            [-61.360767916992188, -13.520768526367196],
            [-61.355667916992189, -13.521468526367197],
            [-61.350567916992183, -13.521168526367195],
            [-61.346667916992189, -13.520168526367197],
            [-61.342267916992185, -13.518268526367198],
            [-61.340067916992183, -13.515768526367197],
            [-61.339867916992183, -13.511568526367196],
            [-61.339467916992184, -13.508068526367197],
            [-61.336267916992185, -13.505468526367196],
            [-61.328567916992185, -13.503568526367197],
            [-61.313467916992181, -13.504368526367195],
            [-61.308867916992185, -13.504068526367195],
            [-61.304967916992183, -13.502368526367196],
            [-61.301067916992181, -13.498268526367195],
            [-61.301067916992181, -13.494068526367197],
            [-61.302767916992181, -13.490068526367196],
            [-61.305467916992185, -13.486368526367198],
            [-61.305267916992186, -13.481968526367197],
            [-61.302467916992185, -13.478868526367197],
            [-61.296367916992182, -13.477768526367196],
            [-61.291767916992185, -13.480068526367198],
            [-61.288167916992187, -13.482768526367195],
            [-61.286967916992182, -13.487968526367197],
            [-61.285867916992188, -13.492268526367198],
            [-61.285967916992185, -13.498268526367195],
            [-61.285567916992186, -13.502068526367196],
            [-61.280367916992184, -13.505568526367195],
            [-61.275167916992189, -13.505968526367198],
            [-61.272267916992185, -13.504168526367195],
            [-61.269667916992184, -13.501268526367195],
            [-61.266767916992187, -13.499368526367196],
            [-61.26236791699219, -13.497368526367197],
            [-61.255267916992182, -13.496268526367196],
            [-61.248267916992184, -13.496268526367196],
            [-61.243267916992181, -13.499068526367196],
            [-61.241867916992184, -13.501668526367197],
            [-61.241667916992185, -13.505268526367196],
            [-61.243567916992184, -13.511368526367196],
            [-61.241367916992189, -13.515968526367196],
            [-61.237167916992185, -13.516868526367197],
            [-61.234767916992183, -13.520068526367197],
            [-61.233567916992186, -13.525068526367196],
            [-61.222867916992186, -13.529768526367196],
            [-61.215167916992186, -13.528668526367195],
            [-61.212567916992185, -13.526768526367196],
            [-61.207867916992186, -13.525368526367195],
            [-61.204267916992187, -13.526968526367195],
            [-61.198467916992186, -13.533368526367195],
            [-61.192767916992182, -13.535968526367196],
            [-61.188567916992184, -13.532268526367197],
            [-61.185167916992185, -13.530468526367198],
            [-61.182767916992184, -13.527568526367197],
            [-61.183267916992186, -13.521868526367196],
            [-61.187767916992186, -13.516768526367198],
            [-61.184067916992184, -13.507768526367197],
            [-61.180467916992185, -13.508768526367195],
            [-61.182467916992181, -13.514268526367196],
            [-61.178067916992184, -13.514668526367196],
            [-61.171167916992189, -13.510668526367198],
            [-61.160367916992186, -13.515068526367195],
            [-61.156667916992184, -13.517068526367197],
            [-61.154867916992188, -13.519568526367195],
            [-61.153167916992189, -13.527268526367195],
            [-61.146867916992186, -13.531968526367198],
            [-61.144267916992185, -13.528668526367195],
            [-61.145667916992181, -13.523168526367197],
            [-61.147367916992181, -13.518968526367196],
            [-61.144067916992185, -13.513468526367195],
            [-61.137867916992185, -13.512868526367196],
            [-61.137667916992186, -13.517668526367196],
            [-61.140667916992186, -13.524768526367197],
            [-61.139567916992185, -13.528968526367198],
            [-61.133867916992187, -13.527268526367195],
            [-61.132067916992185, -13.523368526367197],
            [-61.125967916992181, -13.519868526367198],
            [-61.120267916992184, -13.521468526367197],
            [-61.112467916992188, -13.528368526367196],
            [-61.106167916992185, -13.531668526367195],
            [-61.100867916992186, -13.528368526367196],
            [-61.101667916992184, -13.524868526367197],
            [-61.104167916992182, -13.520368526367196],
            [-61.103367916992184, -13.515668526367197],
            [-61.094767916992183, -13.504868526367197],
            [-61.095667916992184, -13.499868526367198],
            [-61.097767916992183, -13.495768526367197],
            [-61.09456791699219, -13.492168526367195],
            [-61.088967916992189, -13.491968526367195],
            [-61.082367916992183, -13.495168526367195],
            [-61.079067916992187, -13.497968526367195],
            [-61.075767916992184, -13.499468526367195],
            [-61.071067916992185, -13.499968526367198],
            [-61.065967916992186, -13.500568526367196],
            [-61.063567916992184, -13.502068526367196],
            [-61.063367916992185, -13.507768526367197],
            [-61.060267916992188, -13.511768526367195],
            [-61.054067916992182, -13.511368526367196],
            [-61.049367916992182, -13.509868526367196],
            [-61.046467916992185, -13.509268526367197],
            [-61.041067916992183, -13.507668526367198],
            [-61.038367916992186, -13.505768526367195],
            [-61.035567916992186, -13.500968526367195],
            [-61.035567916992186, -13.496968526367198],
            [-61.039267916992188, -13.496868526367198],
            [-61.04746791699219, -13.495168526367195],
            [-61.050867916992189, -13.495268526367198],
            [-61.052267916992186, -13.492468526367198],
            [-61.050867916992189, -13.488668526367196],
            [-61.046967916992187, -13.484668526367198],
            [-61.041067916992183, -13.484968526367197],
            [-61.029767916992185, -13.489968526367196],
            [-61.025467916992184, -13.490468526367195],
            [-61.020667916992181, -13.488268526367197],
            [-61.014867916992188, -13.487468526367195],
            [-61.01206791699218, -13.489368526367198],
            [-61.007167916992188, -13.492468526367198],
            [-61.00136791699218, -13.494768526367196],
            [-60.99696791699219, -13.494768526367196],
            [-60.993367916992185, -13.496268526367196],
            [-60.988567916992181, -13.500968526367195],
            [-60.990467916992188, -13.504168526367195],
            [-60.993867916992187, -13.504868526367197],
            [-60.996367916992185, -13.501968526367197],
            [-60.999067916992189, -13.499868526367198],
            [-61.003467916992186, -13.501668526367197],
            [-61.00636791699219, -13.503368526367197],
            [-61.00906791699218, -13.507068526367195],
            [-61.008767916992184, -13.511768526367195],
            [-61.007167916992188, -13.517168526367197],
            [-61.003267916992186, -13.520168526367197],
            [-60.999067916992189, -13.523468526367196],
            [-60.997967916992181, -13.528668526367195],
            [-61.001567916992187, -13.529868526367196],
            [-61.003867916992185, -13.525168526367196],
            [-61.008767916992184, -13.523268526367197],
            [-61.01236791699219, -13.529568526367196],
            [-61.00936791699219, -13.538868526367196],
            [-61.007067916992185, -13.547468526367197],
            [-61.005567916992185, -13.550268526367198],
            [-61.000467916992186, -13.551668526367198],
            [-60.995567916992186, -13.551068526367196],
            [-60.991367916992189, -13.549268526367197],
            [-60.986967916992185, -13.548068526367196],
            [-60.986467916992183, -13.542168526367195],
            [-60.986067916992184, -13.538668526367196],
            [-60.978867916992186, -13.540068526367197],
            [-60.972067916992188, -13.536968526367197],
            [-60.967367916992188, -13.536768526367197],
            [-60.962367916992186, -13.540568526367196],
            [-60.957967916992189, -13.548868526367198],
            [-60.950967916992184, -13.551868526367198],
            [-60.944367916992185, -13.548868526367198],
            [-60.940267916992184, -13.545268526367195],
            [-60.934667916992183, -13.541168526367198],
            [-60.922867916992189, -13.540668526367195],
            [-60.918667916992185, -13.543368526367196],
            [-60.91646791699219, -13.546668526367196],
            [-60.918967916992187, -13.548868526367198],
            [-60.924667916992185, -13.556968526367196],
            [-60.92246791699219, -13.564968526367196],
            [-60.92076791699219, -13.568868526367197],
            [-60.908867916992186, -13.584768526367196],
            [-60.902567916992183, -13.589368526367195],
            [-60.894067916992185, -13.593768526367196],
            [-60.88706791699218, -13.601968526367195],
            [-60.883467916992188, -13.610768526367195],
            [-60.879867916992183, -13.617468526367198],
            [-60.875167916992183, -13.621368526367196],
            [-60.868867916992187, -13.622168526367197],
            [-60.861367916992187, -13.624368526367196],
            [-60.854167916992182, -13.629368526367195],
            [-60.844867916992186, -13.631368526367197],
            [-60.841167916992184, -13.627668526367195],
            [-60.834867916992181, -13.627068526367196],
            [-60.828567916992185, -13.629468526367198],
            [-60.81886791699219, -13.636868526367195],
            [-60.815167916992181, -13.643568526367197],
            [-60.810267916992188, -13.654468526367197],
            [-60.801867916992187, -13.656268526367196],
            [-60.790867916992184, -13.655568526367198],
            [-60.786367916992184, -13.656268526367196],
            [-60.779867916992188, -13.659768526367195],
            [-60.77406791699218, -13.661168526367195],
            [-60.770467916992189, -13.665068526367197],
            [-60.77006791699219, -13.672768526367197],
            [-60.771267916992187, -13.679668526367195],
            [-60.769367916992181, -13.684168526367195],
            [-60.762967916992181, -13.686368526367197],
            [-60.755167916992185, -13.687268526367195],
            [-60.75136791699218, -13.686668526367196],
            [-60.748267916992184, -13.685468526367195],
            [-60.73966791699219, -13.685268526367196],
            [-60.735067916992186, -13.686368526367197],
            [-60.723767916992188, -13.691568526367195],
            [-60.717567916992181, -13.693768526367197],
            [-60.709267916992189, -13.692968526367196],
            [-60.705667916992184, -13.695268526367197],
            [-60.705767916992187, -13.700768526367195],
            [-60.704667916992186, -13.704568526367197],
            [-60.697067916992182, -13.714868526367198],
            [-60.693767916992186, -13.715368526367197],
            [-60.694267916992189, -13.709768526367196],
            [-60.695567916992189, -13.701468526367197],
            [-60.689967916992188, -13.699368526367195],
            [-60.686567916992189, -13.703168526367197],
            [-60.68586791699218, -13.711968526367198],
            [-60.684067916992184, -13.715968526367195],
            [-60.680767916992181, -13.719868526367197],
            [-60.676067916992181, -13.722868526367197],
            [-60.67216791699218, -13.724168526367198],
            [-60.665667916992184, -13.729968526367195],
            [-60.660567916992186, -13.733368526367197],
            [-60.655567916992183, -13.737468526367195],
            [-60.656167916992189, -13.733568526367197],
            [-60.652667916992186, -13.730068526367198],
            [-60.651567916992185, -13.736068526367195],
            [-60.646167916992184, -13.738668526367196],
            [-60.642667916992181, -13.735668526367196],
            [-60.641467916992184, -13.730868526367196],
            [-60.636767916992184, -13.728168526367195],
            [-60.630967916992184, -13.728368526367195],
            [-60.62336791699218, -13.719468526367198],
            [-60.619167916992183, -13.720868526367195],
            [-60.618867916992187, -13.725268526367195],
            [-60.611667916992189, -13.725268526367195],
            [-60.60966791699218, -13.730368526367197],
            [-60.612167916992185, -13.734768526367198],
            [-60.610867916992184, -13.740268526367196],
            [-60.607867916992184, -13.736468526367197],
            [-60.605867916992182, -13.739368526367198],
            [-60.60666791699218, -13.746268526367196],
            [-60.604267916992185, -13.747968526367195],
            [-60.600367916992184, -13.748868526367197],
            [-60.59426791699218, -13.745468526367198],
            [-60.591967916992189, -13.743268526367196],
            [-60.586967916992187, -13.746868526367198],
            [-60.581567916992185, -13.747668526367196],
            [-60.574667916992183, -13.747368526367197],
            [-60.578767916992184, -13.755668526367195],
            [-60.572367916992185, -13.757068526367195],
            [-60.566367916992185, -13.757768526367197],
            [-60.567367916992183, -13.762868526367196],
            [-60.570267916992186, -13.765068526367195],
            [-60.569367916992185, -13.768668526367197],
            [-60.561267916992186, -13.769368526367195],
            [-60.557667916992187, -13.770768526367196],
            [-60.550067916992184, -13.781668526367195],
            [-60.546467916992185, -13.782368526367197],
            [-60.546767916992181, -13.777568526367197],
            [-60.548567916992184, -13.773968526367195],
            [-60.548067916992181, -13.770368526367196],
            [-60.54246791699218, -13.769268526367195],
            [-60.531967916992187, -13.771468526367197],
            [-60.527367916992183, -13.769568526367195],
            [-60.522967916992187, -13.768968526367196],
            [-60.518167916992184, -13.772568526367195],
            [-60.517367916992185, -13.777868526367197],
            [-60.519267916992185, -13.781568526367195],
            [-60.522867916992183, -13.784468526367196],
            [-60.528467916992184, -13.791768526367196],
            [-60.523167916992186, -13.793168526367197],
            [-60.511567916992185, -13.791068526367198],
            [-60.504067916992184, -13.795568526367198],
            [-60.502067916992189, -13.790868526367195],
            [-60.497967916992181, -13.789468526367195],
            [-60.493067916992189, -13.794468526367197],
            [-60.490067916992189, -13.795768526367198],
            [-60.487167916992185, -13.796068526367197],
            [-60.483067916992184, -13.795768526367198],
            [-60.47256791699219, -13.793668526367195],
            [-60.467967916992187, -13.795268526367195],
            [-60.465467916992182, -13.800368526367198],
            [-60.47396791699218, -13.801168526367196],
            [-60.477867916992182, -13.803368526367198],
            [-60.478067916992188, -13.807968526367198],
            [-60.475267916992181, -13.810268526367196],
            [-60.467367916992188, -13.812268526367195],
            [-60.465167916992186, -13.814868526367196],
            [-60.467567916992181, -13.820268526367197],
            [-60.470367916992188, -13.822968526367195],
            [-60.474767916992185, -13.823968526367196],
            [-60.48196791699219, -13.820168526367198],
            [-60.486067916992184, -13.819168526367196],
            [-60.489367916992187, -13.820268526367197],
            [-60.48966791699219, -13.824568526367198],
            [-60.485267916992186, -13.830368526367195],
            [-60.482467916992185, -13.832368526367198],
            [-60.479667916992184, -13.833268526367196],
            [-60.474767916992185, -13.829668526367197],
            [-60.471667916992189, -13.829568526367197],
            [-60.469067916992188, -13.832168526367198],
            [-60.475267916992181, -13.837068526367197],
            [-60.478667916992187, -13.839768526367195],
            [-60.48166791699218, -13.843368526367197],
            [-60.484167916992185, -13.848868526367195],
            [-60.491167916992183, -13.853168526367195],
            [-60.491867916992184, -13.857068526367197],
            [-60.487567916992184, -13.858068526367198],
            [-60.478467916992187, -13.852768526367196],
            [-60.473067916992186, -13.853168526367195],
            [-60.466167916992184, -13.855968526367196],
            [-60.460067916992188, -13.852868526367196],
            [-60.456267916992189, -13.854768526367195],
            [-60.456167916992186, -13.858568526367197],
            [-60.461967916992187, -13.861368526367198],
            [-60.464267916992185, -13.864368526367198],
            [-60.464467916992184, -13.872468526367197],
            [-60.467267916992185, -13.876268526367195],
            [-60.472867916992186, -13.879068526367195],
            [-60.47596791699219, -13.884668526367197],
            [-60.474567916992186, -13.888468526367195],
            [-60.467567916992181, -13.890668526367197],
            [-60.467067916992185, -13.885868526367197],
            [-60.463467916992187, -13.882368526367195],
            [-60.455067916992185, -13.880968526367198],
            [-60.451867916992185, -13.882668526367198],
            [-60.451067916992187, -13.887468526367197],
            [-60.456067916992183, -13.892868526367195],
            [-60.455767916992187, -13.897568526367195],
            [-60.452167916992181, -13.904568526367196],
            [-60.452667916992183, -13.912768526367195],
            [-60.451067916992187, -13.917468526367195],
            [-60.449967916992186, -13.928568526367197],
            [-60.450667916992188, -13.933268526367197],
            [-60.450967916992184, -13.937468526367198],
            [-60.448467916992186, -13.939168526367197],
            [-60.44486791699218, -13.939668526367196],
            [-60.446367916992187, -13.933668526367196],
            [-60.445267916992186, -13.930868526367195],
            [-60.438567916992184, -13.935168526367196],
            [-60.431867916992189, -13.939068526367198],
            [-60.427967916992188, -13.942668526367196],
            [-60.423867916992187, -13.939368526367197],
            [-60.420267916992188, -13.937168526367195],
            [-60.417167916992184, -13.941368526367196],
            [-60.41916791699218, -13.946568526367198],
            [-60.41646791699219, -13.949368526367195],
            [-60.410967916992185, -13.948268526367197],
            [-60.410667916992182, -13.951468526367197],
            [-60.418867916992184, -13.954968526367196],
            [-60.418367916992182, -13.958968526367197],
            [-60.420067916992181, -13.963668526367197],
            [-60.422767916992186, -13.962268526367197],
            [-60.425767916992186, -13.962568526367196],
            [-60.422867916992189, -13.966268526367195],
            [-60.420067916992181, -13.968968526367195],
            [-60.418667916992185, -13.974468526367197],
            [-60.415067916992186, -13.980568526367197],
            [-60.412267916992185, -13.981468526367195],
            [-60.403467916992184, -13.978668526367198],
            [-60.397667916992184, -13.977468526367197],
            [-60.392667916992181, -13.977768526367196],
            [-60.38906791699219, -13.978868526367197],
            [-60.386867916992188, -13.980968526367196],
            [-60.382067916992185, -13.987468526367195],
            [-60.382067916992185, -13.991568526367196],
            [-60.38566791699219, -13.993368526367195],
            [-60.390967916992182, -13.992568526367197],
            [-60.393967916992182, -13.992768526367197],
            [-60.395467916992189, -13.996968526367198],
            [-60.398667916992181, -13.996068526367196],
            [-60.402267916992187, -13.999468526367195],
            [-60.402267916992187, -14.003468526367197],
            [-60.401467916992189, -14.009868526367196],
            [-60.402867916992186, -14.017068526367197],
            [-60.405067916992181, -14.019868526367198],
            [-60.406967916992187, -14.022968526367197],
            [-60.407367916992186, -14.026868526367195],
            [-60.406967916992187, -14.030868526367197],
            [-60.410167916992187, -14.033368526367195],
            [-60.412767916992181, -14.035968526367196],
            [-60.416167916992187, -14.037768526367195],
            [-60.416967916992185, -14.041168526367198],
            [-60.419967916992185, -14.043668526367195],
            [-60.421367916992182, -14.047168526367198],
            [-60.425067916992184, -14.048568526367195],
            [-60.426867916992187, -14.051168526367196],
            [-60.428267916992183, -14.053868526367197],
            [-60.428267916992183, -14.057168526367196],
            [-60.428567916992186, -14.061868526367196],
            [-60.430767916992181, -14.064068526367198],
            [-60.432167916992185, -14.068468526367198],
            [-60.436567916992189, -14.072068526367197],
            [-60.439467916992186, -14.075968526367195],
            [-60.441567916992184, -14.078768526367195],
            [-60.444267916992189, -14.079668526367197],
            [-60.445567916992189, -14.083168526367196],
            [-60.448267916992187, -14.085668526367197],
            [-60.452367916992188, -14.087968526367195],
            [-60.462067916992183, -14.093668526367196],
            [-60.466167916992184, -14.094568526367198],
            [-60.470367916992188, -14.089768526367195],
            [-60.47726791699219, -14.093168526367197],
            [-60.479467916992185, -14.096668526367196],
            [-60.479467916992185, -14.101368526367196],
            [-60.47696791699218, -14.105268526367198],
            [-60.473367916992188, -14.108368526367197],
            [-60.475067916992188, -14.111768526367197],
            [-60.47996791699218, -14.113068526367197],
            [-60.483867916992182, -14.116068526367197],
            [-60.482167916992182, -14.118868526367198],
            [-60.479467916992185, -14.120868526367197],
            [-60.478567916992183, -14.123768526367197],
            [-60.476667916992184, -14.126368526367198],
            [-60.473167916992182, -14.128268526367197],
            [-60.474567916992186, -14.130968526367198],
            [-60.480367916992186, -14.132668526367198],
            [-60.481767916992183, -14.136868526367195],
            [-60.47996791699218, -14.141568526367195],
            [-60.477067916992183, -14.147968526367197],
            [-60.47426791699219, -14.151768526367196],
            [-60.47596791699219, -14.156668526367195],
            [-60.476367916992189, -14.160668526367196],
            [-60.48196791699219, -14.162868526367195],
            [-60.48496791699219, -14.165568526367196],
            [-60.486967916992185, -14.169168526367198],
            [-60.488667916992185, -14.172168526367198],
            [-60.488667916992185, -14.175268526367198],
            [-60.487767916992183, -14.179068526367196],
            [-60.490067916992189, -14.183268526367197],
            [-60.491867916992184, -14.188568526367195],
            [-60.486067916992184, -14.193068526367195],
            [-60.481767916992183, -14.193568526367198],
            [-60.476367916992189, -14.192768526367196],
            [-60.47226791699218, -14.191968526367198],
            [-60.471667916992189, -14.196268526367195],
            [-60.471367916992186, -14.199268526367195],
            [-60.468767916992185, -14.201768526367196],
            [-60.467567916992181, -14.205668526367198],
            [-60.468367916992186, -14.210768526367197],
            [-60.470267916992185, -14.213968526367196],
            [-60.468067916992183, -14.216168526367195],
            [-60.465567916992185, -14.218368526367197],
            [-60.462267916992189, -14.223168526367196],
            [-60.465467916992182, -14.230268526367198],
            [-60.463967916992189, -14.234368526367195],
            [-60.458667916992184, -14.235568526367196],
            [-60.455067916992185, -14.237568526367195],
            [-60.449967916992186, -14.240368526367195],
            [-60.44956791699218, -14.250168526367197],
            [-60.457367916992183, -14.255268526367196],
            [-60.461467916992184, -14.258468526367196],
            [-60.463167916992184, -14.261868526367195],
            [-60.465167916992186, -14.265168526367198],
            [-60.463667916992186, -14.267568526367196],
            [-60.461167916992181, -14.272868526367198],
            [-60.462067916992183, -14.277568526367197],
            [-60.462067916992183, -14.280568526367198],
            [-60.461167916992181, -14.284768526367195],
            [-60.458967916992187, -14.288868526367196],
            [-60.456867916992181, -14.291968526367196],
            [-60.456467916992182, -14.295368526367195],
            [-60.456267916992189, -14.298368526367195],
            [-60.456167916992186, -14.304168526367196],
            [-60.451367916992183, -14.306568526367197],
            [-60.452067916992185, -14.309468526367198],
            [-60.453267916992189, -14.313268526367196],
            [-60.44986791699219, -14.315568526367198],
            [-60.445267916992186, -14.319268526367196],
            [-60.440567916992187, -14.321268526367195],
            [-60.439467916992186, -14.324668526367198],
            [-60.43886791699218, -14.327868526367197],
            [-60.435467916992181, -14.330768526367198],
            [-60.433267916992186, -14.335468526367197],
            [-60.429367916992184, -14.338768526367197],
            [-60.425567916992186, -14.343168526367197],
            [-60.426867916992187, -14.347268526367195],
            [-60.424267916992186, -14.350368526367195],
            [-60.422767916992186, -14.353368526367195],
            [-60.419767916992186, -14.352768526367196],
            [-60.41776791699219, -14.348368526367196],
            [-60.413867916992189, -14.349868526367196],
            [-60.415267916992185, -14.354168526367197],
            [-60.413867916992189, -14.358568526367197],
            [-60.410867916992188, -14.359368526367195],
            [-60.408367916992184, -14.355568526367197],
            [-60.406467916992185, -14.359168526367196],
            [-60.409267916992185, -14.361868526367196],
            [-60.411367916992184, -14.364068526367195],
            [-60.408367916992184, -14.368068526367196],
            [-60.405567916992183, -14.364768526367197],
            [-60.402867916992186, -14.366568526367196],
            [-60.399767916992189, -14.365768526367198],
            [-60.396467916992187, -14.364368526367198],
            [-60.394067916992185, -14.367568526367197],
            [-60.396167916992184, -14.369768526367196],
            [-60.395367916992186, -14.373668526367197],
            [-60.397367916992181, -14.377368526367196],
            [-60.394267916992185, -14.379168526367195],
            [-60.395167916992186, -14.382668526367198],
            [-60.396767916992189, -14.387768526367196],
            [-60.399567916992183, -14.386368526367196],
            [-60.400467916992184, -14.382168526367195],
            [-60.404467916992189, -14.385368526367195],
            [-60.403467916992184, -14.389268526367196],
            [-60.400467916992184, -14.390168526367198],
            [-60.402267916992187, -14.395968526367195],
            [-60.40376791699218, -14.399868526367197],
            [-60.402567916992183, -14.402968526367196],
            [-60.399567916992183, -14.404268526367197],
            [-60.401267916992182, -14.409868526367195],
            [-60.403667916992184, -14.411668526367198],
            [-60.406267916992185, -14.413068526367198],
            [-60.402667916992186, -14.416168526367198],
            [-60.400867916992183, -14.419768526367196],
            [-60.399767916992189, -14.422868526367196],
            [-60.397067916992185, -14.419768526367196],
            [-60.394367916992181, -14.422168526367198],
            [-60.397967916992187, -14.426368526367195],
            [-60.39506791699219, -14.428868526367197],
            [-60.39936791699219, -14.432268526367196],
            [-60.395467916992189, -14.435468526367195],
            [-60.391567916992187, -14.434068526367195],
            [-60.387867916992185, -14.435868526367198],
            [-60.384667916992186, -14.439168526367197],
            [-60.383067916992189, -14.441668526367195],
            [-60.381667916992185, -14.445668526367196],
            [-60.380567916992185, -14.454668526367197],
            [-60.377967916992183, -14.456468526367196],
            [-60.379067916992184, -14.460868526367197],
            [-60.378267916992186, -14.464268526367196],
            [-60.372467916992186, -14.467668526367195],
            [-60.368267916992181, -14.468068526367198],
            [-60.364967916992185, -14.469068526367195],
            [-60.361967916992185, -14.470568526367195],
            [-60.361667916992189, -14.474168526367198],
            [-60.360267916992186, -14.477568526367197],
            [-60.360767916992188, -14.481368526367195],
            [-60.361467916992183, -14.485568526367196],
            [-60.365767916992183, -14.484468526367195],
            [-60.366167916992183, -14.487768526367198],
            [-60.358367916992187, -14.491368526367197],
            [-60.35526791699219, -14.492568526367197],
            [-60.351767916992188, -14.494368526367197],
            [-60.348467916992185, -14.496968526367198],
            [-60.34756791699219, -14.501568526367198],
            [-60.346967916992185, -14.505268526367196],
            [-60.344767916992183, -14.507368526367195],
            [-60.338667916992186, -14.511068526367197],
            [-60.339867916992183, -14.515968526367196],
            [-60.341967916992189, -14.519668526367198],
            [-60.341967916992189, -14.523968526367195],
            [-60.339067916992185, -14.526968526367195],
            [-60.336467916992184, -14.529468526367197],
            [-60.339567916992181, -14.530968526367197],
            [-60.342567916992181, -14.533468526367198],
            [-60.338667916992186, -14.535368526367197],
            [-60.335967916992189, -14.538168526367198],
            [-60.334367916992186, -14.542768526367198],
            [-60.335367916992183, -14.546068526367197],
            [-60.332867916992186, -14.553368526367198],
            [-60.331467916992182, -14.557168526367196],
            [-60.328267916992189, -14.558268526367197],
            [-60.32786791699219, -14.561868526367196],
            [-60.330767916992187, -14.564968526367196],
            [-60.331167916992186, -14.568568526367198],
            [-60.329267916992187, -14.573168526367198],
            [-60.32786791699219, -14.576868526367196],
            [-60.325967916992184, -14.579968526367196],
            [-60.325367916992185, -14.585168526367195],
            [-60.325967916992184, -14.588668526367197],
            [-60.325967916992184, -14.592668526367195],
            [-60.324367916992188, -14.597568526367198],
            [-60.323967916992189, -14.600968526367197],
            [-60.32316791699219, -14.604168526367197],
            [-60.321067916992185, -14.608868526367196],
            [-60.316367916992185, -14.613668526367196],
            [-60.312367916992187, -14.615468526367195],
            [-60.305867916992185, -14.617668526367197],
            [-60.298967916992183, -14.621368526367196],
            [-60.291667916992182, -14.625168526367197],
            [-60.287467916992185, -14.623868526367197],
            [-60.280167916992184, -14.623668526367197],
            [-60.276467916992189, -14.622668526367196],
            [-60.272967916992187, -14.620768526367197],
            [-60.262667916992186, -14.789968526367197],
            [-60.259867916992185, -14.836468526367195],
            [-60.254167916992188, -14.930568526367196],
            [-60.246267916992181, -15.063768526367195],
            [-60.244367916992189, -15.096768526367196],
            [-60.500967916992181, -15.096168526367197],
            [-60.564967916992188, -15.108668526367197],
            [-60.533067916992181, -15.146468526367197],
            [-60.500467916992186, -15.179768526367198],
            [-60.494067916992186, -15.186868526367196],
            [-60.38406791699218, -15.310468526367195],
            [-60.371567916992184, -15.324368526367195],
            [-60.243867916992187, -15.467668526367195],
            [-60.238567916992181, -15.473868526367195],
            [-60.236067916992184, -15.500768526367196],
            [-60.232167916992182, -15.544968526367196],
            [-60.228367916992184, -15.586868526367198],
            [-60.22596791699219, -15.618268526367196],
            [-60.222867916992186, -15.656668526367195],
            [-60.214067916992185, -15.758268526367196],
            [-60.210467916992187, -15.801868526367198],
            [-60.20726791699218, -15.839068526367196],
            [-60.203267916992189, -15.885768526367197],
            [-60.201067916992187, -15.911968526367197],
            [-60.197067916992182, -15.963368526367198],
            [-60.193767916992186, -16.000568526367196],
            [-60.181367916992187, -16.151468526367196],
            [-60.177267916992186, -16.197768526367195],
            [-60.171467916992185, -16.265968526367196],
            [-60.135967916992186, -16.267068526367197],
            [-60.105667916992189, -16.267168526367197],
            [-60.060267916992188, -16.267868526367195],
            [-60.03346791699218, -16.268168526367198],
            [-60.012467916992186, -16.268968526367196],
            [-60.001567916992187, -16.268968526367196],
            [-59.973067916992186, -16.270068526367197],
            [-59.89506791699219, -16.271768526367197],
            [-59.869067916992186, -16.272268526367196],
            [-59.82316791699219, -16.272968526367197],
            [-59.775067916992185, -16.273768526367196],
            [-59.705667916992184, -16.274768526367197],
            [-59.53676791699219, -16.277368526367198],
            [-59.500167916992183, -16.278368526367196],
            [-59.496067916992189, -16.278768526367195],
            [-59.472767916992183, -16.279268526367197],
            [-59.468167916992186, -16.279068526367197],
            [-59.267467916992189, -16.287768526367195],
            [-59.15676791699218, -16.292068526367196],
            [-59.13406791699218, -16.293068526367197],
            [-59.114167916992187, -16.293868526367195],
            [-59.01706791699219, -16.298068526367196],
            [-59.000167916992183, -16.298868526367198],
            [-58.965367916992186, -16.300068526367195],
            [-58.961467916992184, -16.300068526367195],
            [-58.922267916992183, -16.301668526367198],
            [-58.875967916992181, -16.303368526367198],
            [-58.81856791699218, -16.305868526367195],
            [-58.785967916992185, -16.306868526367197],
            [-58.744467916992186, -16.308768526367196],
            [-58.621267916992181, -16.313268526367196],
            [-58.436567916992189, -16.320968526367196],
            [-58.430467916992185, -16.321268526367195],
            [-58.423867916992187, -16.316568526367195],
            [-58.408367916992184, -16.309168526367195],
            [-58.399767916992189, -16.303268526367198],
            [-58.394067916992185, -16.296668526367196],
            [-58.390767916992189, -16.284468526367196],
            [-58.39006791699218, -16.274768526367197],
            [-58.391067916992185, -16.269368526367195],
            [-58.388567916992187, -16.260968526367197],
            [-58.38236791699218, -16.264668526367196],
            [-58.365767916992183, -16.273268526367197],
            [-58.353167916992184, -16.269268526367195],
            [-58.346367916992186, -16.273368526367197],
            [-58.338467916992187, -16.272968526367197],
            [-58.328267916992189, -16.270368526367196],
            [-58.326767916992182, -16.267968526367195],
            [-58.32316791699219, -16.265468526367197],
            [-58.31686791699218, -16.290868526367195],
            [-58.303067916992184, -16.307668526367195],
            [-58.303267916992183, -16.329568526367197],
            [-58.306367916992187, -16.338368526367198],
            [-58.308767916992181, -16.360268526367197],
            [-58.307167916992185, -16.370168526367195],
            [-58.309367916992187, -16.372968526367195],
            [-58.314567916992189, -16.371968526367198],
            [-58.31856791699218, -16.376268526367195],
            [-58.324367916992188, -16.377968526367198],
            [-58.32786791699219, -16.382068526367195],
            [-58.330967916992186, -16.385468526367195],
            [-58.333767916992187, -16.387668526367197],
            [-58.339367916992188, -16.386268526367196],
            [-58.344167916992184, -16.389268526367196],
            [-58.34626791699219, -16.393768526367197],
            [-58.34426791699218, -16.396268526367198],
            [-58.344767916992183, -16.400068526367196],
            [-58.34756791699219, -16.406668526367195],
            [-58.35096791699219, -16.412768526367195],
            [-58.352767916992185, -16.419168526367198],
            [-58.356067916992188, -16.427168526367197],
            [-58.354467916992185, -16.431168526367195],
            [-58.353167916992184, -16.435168526367196],
            [-58.352867916992182, -16.439968526367196],
            [-58.347667916992187, -16.454368526367197],
            [-58.342867916992184, -16.462268526367197],
            [-58.334667916992188, -16.477868526367196],
            [-58.333167916992181, -16.484468526367195],
            [-58.333667916992184, -16.489968526367196],
            [-58.337867916992181, -16.499968526367198],
            [-58.343767916992185, -16.517668526367196],
            [-58.353867916992186, -16.523368526367197],
            [-58.357767916992188, -16.529268526367197],
            [-58.385967916992186, -16.543568526367196],
            [-58.388567916992187, -16.551668526367198],
            [-58.393767916992189, -16.562768526367197],
            [-58.413167916992187, -16.570768526367196],
            [-58.421367916992182, -16.573468526367197],
            [-58.428067916992184, -16.585668526367197],
            [-58.436067916992187, -16.592268526367196],
            [-58.450967916992184, -16.627668526367195],
            [-58.462967916992184, -16.652268526367195],
            [-58.460867916992186, -16.666668526367197],
            [-58.468467916992182, -16.698868526367196],
            [-58.470267916992185, -16.702068526367196],
            [-58.470567916992181, -16.705368526367195],
            [-58.468067916992183, -16.713768526367197],
            [-58.466767916992183, -16.716668526367197],
            [-58.466667916992186, -16.720868526367195],
            [-58.467667916992184, -16.724568526367197],
            [-58.468167916992186, -16.730868526367196],
            [-58.469867916992186, -16.733668526367197],
            [-58.472767916992183, -16.738068526367197],
            [-58.473167916992182, -16.745868526367197],
            [-58.469867916992186, -16.750568526367196],
            [-58.466667916992186, -16.757968526367197],
            [-58.463167916992184, -16.770768526367196],
            [-58.463667916992186, -16.778668526367195],
            [-58.461167916992181, -16.782568526367196],
            [-58.458667916992184, -16.785868526367196],
            [-58.463367916992183, -16.792468526367195],
            [-58.466467916992187, -16.792468526367195],
            [-58.471967916992185, -16.791368526367197],
            [-58.472767916992183, -16.797568526367197],
            [-58.475267916992181, -16.803868526367197],
            [-58.477067916992183, -16.812168526367195],
            [-58.477467916992182, -16.815468526367198],
            [-58.477367916992186, -16.818868526367197],
            [-58.47596791699219, -16.827368526367195],
            [-58.469867916992186, -16.833968526367197],
            [-58.462267916992189, -16.840068526367197],
            [-58.463367916992183, -16.844168526367195],
            [-58.467667916992184, -16.849168526367198],
            [-58.461467916992184, -16.849468526367197],
            [-58.462267916992189, -16.853468526367195],
            [-58.465967916992184, -16.857568526367196],
            [-58.463467916992187, -16.859268526367195],
            [-58.461467916992184, -16.864068526367195],
            [-58.46626791699218, -16.868668526367195],
            [-58.463167916992184, -16.873368526367198],
            [-58.464867916992183, -16.880168526367196],
            [-58.467267916992185, -16.884568526367197],
            [-58.465667916992189, -16.888268526367195],
            [-58.46626791699218, -16.893768526367197],
            [-58.467967916992187, -16.897568526367195],
            [-58.460867916992186, -16.899268526367198],
            [-58.461967916992187, -16.904068526367197],
            [-58.466967916992189, -16.910968526367196],
            [-58.473767916992188, -16.915568526367196],
            [-58.469767916992183, -16.920868526367197],
            [-58.468767916992185, -16.923968526367197],
            [-58.473467916992185, -16.931068526367195],
            [-58.474467916992182, -16.934868526367197],
            [-58.472767916992183, -16.937968526367197],
            [-58.468667916992189, -16.941068526367197],
            [-58.463167916992184, -16.939368526367197],
            [-58.460467916992187, -16.937668526367197],
            [-58.45756791699219, -16.939068526367198],
            [-58.45756791699219, -16.944568526367195],
            [-58.458667916992184, -16.947368526367196],
            [-58.460667916992186, -16.949868526367197],
            [-58.461767916992187, -16.954368526367197],
            [-58.461967916992187, -16.958168526367196],
            [-58.460167916992184, -16.965868526367196],
            [-58.456167916992186, -16.969268526367195],
            [-58.45256791699218, -16.968668526367196],
            [-58.448767916992182, -16.967568526367195],
            [-58.448267916992187, -16.971668526367196],
            [-58.447067916992182, -16.978868526367197],
            [-58.444967916992184, -16.981668526367198],
            [-58.437767916992186, -16.985368526367196],
            [-58.431567916992186, -16.990768526367198],
            [-58.423967916992183, -16.988668526367196],
            [-58.426567916992184, -16.995868526367197],
            [-58.427267916992186, -16.999368526367196],
            [-58.429067916992182, -17.001668526367197],
            [-58.433767916992181, -17.004368526367195],
            [-58.435767916992184, -17.008468526367196],
            [-58.433567916992189, -17.021768526367197],
            [-58.434967916992186, -17.027268526367195],
            [-58.430767916992181, -17.029168526367197],
            [-58.426167916992185, -17.029068526367197],
            [-58.420367916992184, -17.029768526367196],
            [-58.417567916992184, -17.032368526367197],
            [-58.416767916992185, -17.035668526367196],
            [-58.41916791699218, -17.038068526367198],
            [-58.425767916992186, -17.036968526367197],
            [-58.430567916992189, -17.036768526367197],
            [-58.433867916992185, -17.038868526367196],
            [-58.433367916992182, -17.046668526367196],
            [-58.429167916992185, -17.048568526367195],
            [-58.422867916992189, -17.049668526367196],
            [-58.422767916992186, -17.053268526367198],
            [-58.429767916992184, -17.057168526367196],
            [-58.432167916992185, -17.058768526367196],
            [-58.432967916992183, -17.063468526367195],
            [-58.430567916992189, -17.071268526367195],
            [-58.432767916992184, -17.074568526367198],
            [-58.435767916992184, -17.078768526367195],
            [-58.43586791699218, -17.082668526367197],
            [-58.434667916992183, -17.085668526367197],
            [-58.425767916992186, -17.089368526367195],
            [-58.424967916992188, -17.093668526367196],
            [-58.428367916992187, -17.100068526367195],
            [-58.429067916992182, -17.105268526367198],
            [-58.425367916992187, -17.111968526367196],
            [-58.42246791699219, -17.113368526367196],
            [-58.41916791699218, -17.113668526367196],
            [-58.41476791699219, -17.111668526367197],
            [-58.408367916992184, -17.110368526367196],
            [-58.404467916992189, -17.108368526367197],
            [-58.401567916992185, -17.106168526367195],
            [-58.397067916992185, -17.104668526367195],
            [-58.391867916992183, -17.105368526367197],
            [-58.388267916992184, -17.107768526367195],
            [-58.386067916992189, -17.111368526367198],
            [-58.385967916992186, -17.115368526367195],
            [-58.387467916992186, -17.119168526367197],
            [-58.393767916992189, -17.127968526367198],
            [-58.397567916992188, -17.136768526367195],
            [-58.397967916992187, -17.152668526367197],
            [-58.396867916992186, -17.181568526367197],
            [-58.394567916992187, -17.185268526367196],
            [-58.382467916992184, -17.192368526367197],
            [-58.36866791699218, -17.200468526367196],
            [-58.36466791699219, -17.199668526367198],
            [-58.363567916992181, -17.202968526367197],
            [-58.363367916992189, -17.206168526367197],
            [-58.364467916992183, -17.213168526367195],
            [-58.361367916992187, -17.215168526367197],
            [-58.360667916992185, -17.212368526367197],
            [-58.356467916992187, -17.212268526367197],
            [-58.351467916992185, -17.219068526367195],
            [-58.349467916992182, -17.225268526367195],
            [-58.346967916992185, -17.228568526367198],
            [-58.344767916992183, -17.233168526367198],
            [-58.340567916992185, -17.233868526367196],
            [-58.337267916992189, -17.240468526367195],
            [-58.334267916992189, -17.242668526367197],
            [-58.327067916992185, -17.247368526367197],
            [-58.323267916992187, -17.251868526367197],
            [-58.319567916992185, -17.255668526367195],
            [-58.322067916992182, -17.261268526367196],
            [-58.318467916992184, -17.267368526367196],
            [-58.315567916992187, -17.267868526367195],
            [-58.312767916992186, -17.267068526367197],
            [-58.309367916992187, -17.266868526367197],
            [-58.305767916992181, -17.267868526367195],
            [-58.303267916992183, -17.271468526367197],
            [-58.303867916992189, -17.277268526367195],
            [-58.302467916992185, -17.280468526367198],
            [-58.29416791699218, -17.284468526367196],
            [-58.292167916992184, -17.287268526367196],
            [-58.296867916992184, -17.291368526367197],
            [-58.298667916992187, -17.294668526367197],
            [-58.298567916992184, -17.297868526367196],
            [-58.293967916992187, -17.301468526367195],
            [-58.291067916992183, -17.301868526367198],
            [-58.287767916992181, -17.301068526367196],
            [-58.284267916992185, -17.298968526367197],
            [-58.277267916992187, -17.299968526367195],
            [-58.275467916992184, -17.304768526367198],
            [-58.278667916992184, -17.314068526367198],
            [-58.276467916992189, -17.323568526367197],
            [-58.270167916992186, -17.327968526367197],
            [-58.266767916992187, -17.328168526367197],
            [-58.263567916992187, -17.330368526367195],
            [-58.262167916992183, -17.334268526367197],
            [-58.263767916992187, -17.342268526367196],
            [-58.261467916992189, -17.345168526367196],
            [-58.256267916992186, -17.345968526367198],
            [-58.25466791699219, -17.352468526367197],
            [-58.251567916992187, -17.354268526367196],
            [-58.24696791699219, -17.354168526367197],
            [-58.24366791699218, -17.351468526367196],
            [-58.240267916992181, -17.349568526367197],
            [-58.233567916992186, -17.351368526367196],
            [-58.227567916992186, -17.348368526367196],
            [-58.226367916992189, -17.351668526367195],
            [-58.227767916992185, -17.355868526367196],
            [-58.218167916992186, -17.360068526367197],
            [-58.207167916992184, -17.356168526367195],
            [-58.203167916992186, -17.356468526367195],
            [-58.19986791699219, -17.359268526367195],
            [-58.19656791699218, -17.363668526367196],
            [-58.19516791699219, -17.368868526367198],
            [-58.196267916992184, -17.372768526367196],
            [-58.199967916992186, -17.378568526367197],
            [-58.200367916992185, -17.382968526367197],
            [-58.197067916992182, -17.386568526367196],
            [-58.193467916992184, -17.387968526367196],
            [-58.190467916992183, -17.389668526367196],
            [-58.185467916992181, -17.391468526367195],
            [-58.17716791699219, -17.391468526367195],
            [-58.16916791699218, -17.389268526367196],
            [-58.158067916992181, -17.384568526367197],
            [-58.152367916992183, -17.384068526367198],
            [-58.149767916992189, -17.387768526367196],
            [-58.151167916992186, -17.393168526367198],
            [-58.151167916992186, -17.397168526367196],
            [-58.152567916992183, -17.401968526367195],
            [-58.150967916992187, -17.405868526367197],
            [-58.147367916992181, -17.411468526367198],
            [-58.144267916992185, -17.414168526367195],
            [-58.139267916992182, -17.415568526367196],
            [-58.133267916992182, -17.412468526367196],
            [-58.129867916992183, -17.411468526367198],
            [-58.12666791699219, -17.412068526367197],
            [-58.122767916992188, -17.414568526367198],
            [-58.120867916992182, -17.419668526367197],
            [-58.120467916992183, -17.425568526367197],
            [-58.11866791699218, -17.434368526367198],
            [-58.118867916992187, -17.441568526367195],
            [-58.120767916992186, -17.447468526367196],
            [-58.117267916992184, -17.451068526367198],
            [-58.10996791699219, -17.452568526367195],
            [-58.101167916992182, -17.456268526367197],
            [-58.097767916992183, -17.457268526367198],
            [-58.090167916992186, -17.460968526367196],
            [-58.085867916992186, -17.459368526367196],
            [-58.079667916992186, -17.457668526367197],
            [-58.076367916992183, -17.456268526367197],
            [-58.073467916992186, -17.452868526367197],
            [-58.068267916992184, -17.452368526367195],
            [-58.065167916992181, -17.450968526367195],
            [-58.060167916992185, -17.450668526367195],
            [-58.057967916992183, -17.455368526367195],
            [-58.053067916992184, -17.458668526367195],
            [-58.049467916992185, -17.464068526367196],
            [-58.046467916992185, -17.475868526367197],
            [-58.04416791699218, -17.478868526367197],
            [-58.044567916992186, -17.482468526367196],
            [-58.043867916992184, -17.485268526367197],
            [-58.044767916992186, -17.489968526367196],
            [-58.043367916992182, -17.492668526367197],
            [-58.040067916992186, -17.493568526367195],
            [-58.034867916992184, -17.493668526367195],
            [-58.030867916992186, -17.495768526367197],
            [-58.027567916992183, -17.496268526367196],
            [-58.022067916992185, -17.495068526367195],
            [-58.017167916992186, -17.499368526367196],
            [-58.013267916992184, -17.498668526367197],
            [-58.010167916992188, -17.499668526367195],
            [-58.008267916992182, -17.502368526367196],
            [-58.005967916992184, -17.505268526367196],
            [-58.000267916992186, -17.506368526367197],
            [-57.999967916992183, -17.510968526367197],
            [-57.997467916992186, -17.515168526367198],
            [-57.992967916992185, -17.515068526367195],
            [-57.988267916992186, -17.513268526367195],
            [-57.98466791699218, -17.511068526367197],
            [-57.980667916992189, -17.510768526367197],
            [-57.972867916992186, -17.505768526367195],
            [-57.965567916992185, -17.501568526367198],
            [-57.962567916992185, -17.500268526367197],
            [-57.93116791699218, -17.480068526367198],
            [-57.883467916992188, -17.449568526367198],
            [-57.752367916992185, -17.564368526367197],
            [-57.759567916992182, -17.562168526367195],
            [-57.767367916992185, -17.558268526367197],
            [-57.771467916992187, -17.557968526367198],
            [-57.776867916992188, -17.558568526367196],
            [-57.781167916992189, -17.557268526367196],
            [-57.785567916992186, -17.555468526367196],
            [-57.789167916992184, -17.555568526367196],
            [-57.79276791699219, -17.555768526367196],
            [-57.795267916992188, -17.559068526367195],
            [-57.794767916992186, -17.563368526367196],
            [-57.790367916992182, -17.566668526367195],
            [-57.785867916992188, -17.569068526367197],
            [-57.785367916992186, -17.575168526367197],
            [-57.781667916992184, -17.577368526367195],
            [-57.786367916992184, -17.580168526367196],
            [-57.790267916992185, -17.583168526367196],
            [-57.789867916992186, -17.586468526367195],
            [-57.786367916992184, -17.585768526367197],
            [-57.777067916992181, -17.585068526367195],
            [-57.77876791699218, -17.587668526367196],
            [-57.782767916992185, -17.592368526367196],
            [-57.786667916992187, -17.592268526367196],
            [-57.78506791699219, -17.595068526367196],
            [-57.778067916992185, -17.594868526367197],
            [-57.77436791699219, -17.598368526367196],
            [-57.777867916992186, -17.603168526367195],
            [-57.783667916992187, -17.606768526367198],
            [-57.782567916992186, -17.612968526367197],
            [-57.77876791699218, -17.614368526367198],
            [-57.773367916992186, -17.611468526367197],
            [-57.771867916992186, -17.614668526367197],
            [-57.773167916992186, -17.619368526367197],
            [-57.773967916992184, -17.625268526367197],
            [-57.780067916992181, -17.632168526367195],
            [-57.782867916992188, -17.636068526367197],
            [-57.783367916992184, -17.639268526367196],
            [-57.781267916992185, -17.643768526367197],
            [-57.776467916992189, -17.650068526367196],
            [-57.775667916992184, -17.656268526367196],
            [-57.768667916992186, -17.659768526367195],
            [-57.762867916992185, -17.660168526367197],
            [-57.759867916992185, -17.664868526367197],
            [-57.758267916992182, -17.668068526367197],
            [-57.755667916992181, -17.676368526367195],
            [-57.753067916992187, -17.681368526367198],
            [-57.751567916992187, -17.687468526367198],
            [-57.74836791699218, -17.693468526367198],
            [-57.741167916992183, -17.693168526367195],
            [-57.737767916992183, -17.699268526367195],
            [-57.735567916992181, -17.705468526367195],
            [-57.73466791699218, -17.710368526367198],
            [-57.730867916992182, -17.718668526367196],
            [-57.727767916992185, -17.720568526367195],
            [-57.724567916992186, -17.721368526367197],
            [-57.720267916992185, -17.720168526367196],
            [-57.709567916992185, -17.729768526367195],
            [-57.720067916992186, -17.771768526367197],
            [-57.683267916992186, -17.807968526367198],
            [-57.683067916992186, -17.828168526367197],
            [-57.720567916992181, -17.827868526367197],
            [-57.709667916992188, -17.848068526367197],
            [-57.65406791699219, -17.945668526367196],
            [-57.631867916992185, -17.985568526367196],
            [-57.624167916992185, -18.004068526367195],
            [-57.600067916992188, -18.046368526367196],
            [-57.581767916992185, -18.108268526367198],
            [-57.576267916992187, -18.126668526367197],
            [-57.57486791699219, -18.131868526367196],
            [-57.505167916992185, -18.200268526367196],
            [-57.501567916992187, -18.197468526367196],
            [-57.495267916992184, -18.197868526367195],
            [-57.491467916992185, -18.202068526367196],
            [-57.488067916992186, -18.200368526367196],
            [-57.485067916992186, -18.198168526367198],
            [-57.482067916992186, -18.197868526367195],
            [-57.478667916992187, -18.201568526367197],
            [-57.479167916992182, -18.204568526367197],
            [-57.480067916992184, -18.207668526367197],
            [-57.476767916992188, -18.208968526367197],
            [-57.473467916992185, -18.212968526367195],
            [-57.473667916992184, -18.217268526367196],
            [-57.470067916992186, -18.218368526367197],
            [-57.468067916992183, -18.221968526367196],
            [-57.464467916992184, -18.223368526367196],
            [-57.45756791699219, -18.225968526367197],
            [-57.455167916992181, -18.230568526367197],
            [-57.499967916992183, -18.236068526367195],
            [-57.557767916992184, -18.240268526367196],
            [-57.766567916992187, -18.899268526367198],
            [-57.719767916992183, -18.898968526367195],
            [-57.71926791699218, -18.976968526367195],
            [-57.714267916992185, -18.977268526367197],
            [-57.710667916992186, -18.979268526367196],
            [-57.708767916992187, -18.981968526367197],
            [-57.704367916992183, -18.986468526367197],
            [-57.702667916992183, -18.989368526367198],
            [-57.701767916992182, -18.993268526367196],
            [-57.697367916992185, -18.996868526367198],
            [-57.696667916992183, -19.000168526367197],
            [-57.698467916992186, -19.002968526367198],
            [-57.698567916992189, -19.006268526367197],
            [-57.693767916992186, -19.010268526367195],
            [-57.697467916992181, -19.012668526367197],
            [-57.70256791699218, -19.019868526367198],
            [-57.704667916992186, -19.022568526367195],
            [-57.706867916992181, -19.025368526367195],
            [-57.710167916992184, -19.032568526367196],
            [-57.720367916992188, -19.032768526367196],
            [-57.752367916992185, -19.033068526367195],
            [-57.784467916992185, -19.033868526367197],
            [-57.85526791699219, -19.182668526367195],
            [-57.856767916992183, -19.185768526367195],
            [-57.864167916992187, -19.200768526367195],
            [-57.874467916992188, -19.222368526367195],
            [-57.877667916992181, -19.229168526367197],
            [-57.886267916992182, -19.247468526367197],
            [-57.965667916992189, -19.413468526367197],
            [-58.000867916992185, -19.486968526367196],
            [-58.032267916992183, -19.552468526367196],
            [-58.131767916992189, -19.758468526367196],
            [-57.858067916992184, -19.971368526367197],
            [-57.862467916992188, -19.985568526367196],
            [-57.87936791699218, -20.007168526367195],
            [-57.88736791699219, -20.015468526367197],
            [-57.896267916992187, -20.031168526367196],
            [-57.900367916992181, -20.039568526367198],
            [-57.902267916992187, -20.042068526367196],
            [-57.905467916992187, -20.042868526367197],
            [-57.90846791699218, -20.039568526367198],
            [-57.91476791699219, -20.033868526367197],
            [-57.927567916992189, -20.022968526367197],
            [-57.935167916992185, -20.020368526367196],
            [-57.943267916992184, -20.018968526367196],
            [-57.946267916992184, -20.018768526367197],
            [-57.952167916992181, -20.019568526367195],
            [-57.958667916992184, -20.021768526367197],
            [-57.965867916992181, -20.030868526367197],
            [-57.969467916992187, -20.041968526367196],
            [-57.97256791699219, -20.048068526367196],
            [-57.977767916992185, -20.053368526367198],
            [-57.98326791699219, -20.056868526367197],
            [-57.986767916992186, -20.058368526367197],
            [-57.990067916992189, -20.058268526367197],
            [-57.994767916992188, -20.056568526367197],
            [-58.000267916992186, -20.055868526367195],
            [-58.008267916992182, -20.058368526367197],
            [-58.01406791699219, -20.057768526367198],
            [-58.018967916992182, -20.057668526367195],
            [-58.022867916992183, -20.058068526367197],
            [-58.026467916992189, -20.059368526367198],
            [-58.033167916992184, -20.065168526367195],
            [-58.037267916992185, -20.071768526367197],
            [-58.039167916992184, -20.082568526367197],
            [-58.040567916992188, -20.086768526367198],
            [-58.043967916992187, -20.098668526367195],
            [-58.048267916992188, -20.104268526367196],
            [-58.052967916992188, -20.106668526367198],
            [-58.056667916992183, -20.107168526367197],
            [-58.061667916992185, -20.106968526367197],
            [-58.069367916992185, -20.105568526367197],
            [-58.075367916992185, -20.105268526367198],
            [-58.081767916992185, -20.106468526367195],
            [-58.085367916992183, -20.106668526367198],
            [-58.088967916992189, -20.107768526367195],
            [-58.093067916992183, -20.110268526367197],
            [-58.09756791699219, -20.115068526367196],
            [-58.104567916992181, -20.124468526367195],
            [-58.108167916992187, -20.130168526367196],
            [-58.110567916992181, -20.136068526367197],
            [-58.116167916992183, -20.144368526367195],
            [-58.122967916992181, -20.148168526367197],
            [-58.133867916992187, -20.151468526367196],
            [-58.138567916992187, -20.152268526367195],
            [-58.149267916992187, -20.151568526367196],
            [-58.152567916992183, -20.152868526367197],
            [-58.156467916992185, -20.155668526367197],
            [-58.159467916992185, -20.158768526367197],
            [-58.163167916992187, -20.162568526367195],
            [-58.165267916992185, -20.166168526367198],
            [-58.16746791699218, -20.168168526367197],
            [-58.168167916992189, -20.172268526367198],
            [-58.166167916992187, -20.176468526367195],
            [-58.158667916992187, -20.181368526367198],
            [-58.154467916992189, -20.181868526367197],
            [-58.146467916992187, -20.180468526367196],
            [-58.139867916992188, -20.180568526367196],
            [-58.132467916992184, -20.181868526367197],
            [-58.127167916992185, -20.185268526367196],
            [-58.123567916992187, -20.189668526367196],
            [-58.121867916992187, -20.194368526367196],
            [-58.12196791699219, -20.198468526367197],
            [-58.124367916992185, -20.202068526367196],
            [-58.132067916992185, -20.207268526367198],
            [-58.14036791699219, -20.210068526367195],
            [-58.147667916992184, -20.211968526367198],
            [-58.154167916992186, -20.215668526367196],
            [-58.159867916992184, -20.222368526367195],
            [-58.16346791699219, -20.227268526367197],
            [-58.165967916992187, -20.231768526367198],
            [-58.166367916992186, -20.236468526367197],
            [-58.165367916992182, -20.242168526367195],
            [-58.163867916992189, -20.249168526367196],
            [-58.162067916992186, -20.257168526367195],
            [-58.159467916992185, -20.263768526367198],
            [-58.155067916992181, -20.267468526367196],
            [-58.150067916992185, -20.270368526367196],
            [-58.144367916992181, -20.271768526367197],
            [-58.14006791699218, -20.270768526367196],
            [-58.137167916992183, -20.268568526367197],
            [-58.136267916992182, -20.265468526367197],
            [-58.135167916992188, -20.261568526367196],
            [-58.132767916992186, -20.258768526367195],
            [-58.130767916992184, -20.256268526367197],
            [-58.127167916992185, -20.253068526367198],
            [-58.123267916992184, -20.251268526367195],
            [-58.119467916992186, -20.249468526367195],
            [-58.114467916992183, -20.248668526367197],
            [-58.107567916992181, -20.249068526367196],
            [-58.10196791699218, -20.250568526367196],
            [-58.096667916992189, -20.253868526367196],
            [-58.092567916992181, -20.258768526367195],
            [-58.089867916992183, -20.264568526367196],
            [-58.089767916992187, -20.268968526367196],
            [-58.089767916992187, -20.276768526367196],
            [-58.090667916992189, -20.284868526367195],
            [-58.092667916992184, -20.294468526367197],
            [-58.094767916992183, -20.301168526367196],
            [-58.097067916992188, -20.306368526367198],
            [-58.101367916992189, -20.313568526367195],
            [-58.102867916992182, -20.319868526367195],
            [-58.100867916992186, -20.328268526367197],
            [-58.097367916992184, -20.335968526367196],
            [-58.095667916992184, -20.342868526367198],
            [-58.093767916992185, -20.351368526367196],
            [-58.090867916992181, -20.358168526367198],
            [-58.086967916992187, -20.366168526367197],
            [-58.083967916992187, -20.372168526367197],
            [-58.081767916992185, -20.375768526367196],
            [-58.078967916992184, -20.380668526367195],
            [-58.076267916992187, -20.384668526367197],
            [-58.07316791699219, -20.387968526367196],
            [-58.06716791699219, -20.391068526367196],
            [-58.061667916992185, -20.393168526367198],
            [-58.055067916992186, -20.394568526367195],
            [-58.04746791699219, -20.396768526367197],
            [-58.043367916992182, -20.398168526367197],
            [-58.039567916992183, -20.398968526367195],
            [-58.03346791699218, -20.399368526367198],
            [-58.027867916992186, -20.399568526367197],
            [-58.024567916992183, -20.400068526367196],
            [-58.021767916992189, -20.401868526367195],
            [-58.019567916992187, -20.406468526367195],
            [-58.015667916992186, -20.412568526367195],
            [-58.010767916992187, -20.418868526367195],
            [-58.007167916992188, -20.423068526367196],
            [-58.004167916992188, -20.426568526367195],
            [-58.00136791699218, -20.430768526367196],
            [-57.998067916992184, -20.436868526367196],
            [-57.996367916992185, -20.443468526367198],
            [-57.996867916992187, -20.450668526367195],
            [-57.99836791699218, -20.455468526367195],
            [-58.00166791699219, -20.460668526367197],
            [-58.00466791699219, -20.465568526367196],
            [-58.006767916992189, -20.468468526367197],
            [-58.009667916992186, -20.473968526367198],
            [-58.013567916992187, -20.480268526367198],
            [-58.015667916992186, -20.486168526367198],
            [-58.016767916992187, -20.490768526367198],
            [-58.017167916992186, -20.496868526367198],
            [-58.018167916992184, -20.503068526367198],
            [-58.01976791699218, -20.508568526367196],
            [-58.019867916992183, -20.514368526367196],
            [-58.017667916992181, -20.522868526367198],
            [-58.015667916992186, -20.528468526367195],
            [-58.013267916992184, -20.533368526367195],
            [-58.01036791699218, -20.539468526367195],
            [-58.006567916992182, -20.547568526367197],
            [-58.004867916992183, -20.554468526367195],
            [-58.003767916992189, -20.559368526367198],
            [-58.002167916992185, -20.563468526367195],
            [-57.999367916992185, -20.568868526367197],
            [-57.997167916992183, -20.572368526367196],
            [-57.99696791699219, -20.575468526367196],
            [-57.998567916992187, -20.578468526367196],
            [-58.002067916992189, -20.582868526367196],
            [-58.003867916992185, -20.585368526367198],
            [-58.006567916992182, -20.588968526367196],
            [-58.007967916992186, -20.591768526367197],
            [-58.009267916992187, -20.594568526367198],
            [-58.011067916992189, -20.598468526367196],
            [-58.012667916992186, -20.604568526367196],
            [-58.013167916992188, -20.608268526367198],
            [-58.012467916992186, -20.612568526367195],
            [-58.010767916992187, -20.616168526367197],
            [-58.006767916992189, -20.619968526367195],
            [-58.003467916992186, -20.624068526367196],
            [-57.999767916992184, -20.627768526367195],
            [-57.995467916992183, -20.631668526367196],
            [-57.990267916992181, -20.634068526367198],
            [-57.98466791699218, -20.636768526367195],
            [-57.978667916992187, -20.638268526367195],
            [-57.974567916992186, -20.639968526367195],
            [-57.970867916992184, -20.643168526367198],
            [-57.97096791699218, -20.647268526367196],
            [-57.972767916992183, -20.651568526367196],
            [-57.974467916992182, -20.658368526367195],
            [-57.977767916992185, -20.667568526367198],
            [-57.980867916992182, -20.673968526367197],
            [-57.98466791699218, -20.680268526367197],
            [-57.988267916992186, -20.686668526367196],
            [-57.988867916992184, -20.694068526367197],
            [-57.987867916992187, -20.699568526367198],
            [-57.985067916992186, -20.703468526367196],
            [-57.981067916992188, -20.705668526367198],
            [-57.975667916992187, -20.706268526367197],
            [-57.971767916992185, -20.705668526367198],
            [-57.966967916992189, -20.704368526367197],
            [-57.962367916992186, -20.704568526367197],
            [-57.958167916992181, -20.704868526367196],
            [-57.953567916992185, -20.702168526367196],
            [-57.950167916992186, -20.698268526367197],
            [-57.948467916992186, -20.694568526367195],
            [-57.94686791699219, -20.689968526367196],
            [-57.94516791699219, -20.686068526367198],
            [-57.942367916992183, -20.681068526367195],
            [-57.93916791699219, -20.675368526367198],
            [-57.936667916992185, -20.669968526367196],
            [-57.933067916992186, -20.666068526367198],
            [-57.929167916992185, -20.663968526367196],
            [-57.923067916992181, -20.664468526367195],
            [-57.918567916992188, -20.666368526367197],
            [-57.913667916992182, -20.670768526367198],
            [-57.911167916992184, -20.674968526367195],
            [-57.908367916992184, -20.679968526367198],
            [-57.905067916992181, -20.686568526367196],
            [-57.903167916992189, -20.689868526367196],
            [-57.897867916992183, -20.693568526367198],
            [-57.894067916992185, -20.696868526367197],
            [-57.89036791699219, -20.701068526367198],
            [-57.886267916992182, -20.705468526367195],
            [-57.882167916992188, -20.709068526367197],
            [-57.877967916992183, -20.713668526367197],
            [-57.87466791699218, -20.719468526367198],
            [-57.870167916992187, -20.725368526367195],
            [-57.86866791699218, -20.730668526367197],
            [-57.868267916992181, -20.735368526367196],
            [-57.864767916992186, -20.742268526367198],
            [-57.86466791699219, -20.746368526367196],
            [-57.867967916992185, -20.749368526367196],
            [-57.87336791699218, -20.750768526367196],
            [-57.878867916992185, -20.750468526367197],
            [-57.883867916992187, -20.749168526367196],
            [-57.889567916992185, -20.748568526367198],
            [-57.896267916992187, -20.747668526367196],
            [-57.903167916992189, -20.746568526367195],
            [-57.908667916992187, -20.745568526367197],
            [-57.91446791699218, -20.744668526367196],
            [-57.920267916992188, -20.743868526367198],
            [-57.92546791699219, -20.744168526367197],
            [-57.930267916992186, -20.744768526367196],
            [-57.934067916992184, -20.745868526367197],
            [-57.936667916992185, -20.748268526367195],
            [-57.941367916992185, -20.752168526367196],
            [-57.946067916992185, -20.758468526367196],
            [-57.94956791699218, -20.765168526367198],
            [-57.951267916992187, -20.772268526367196],
            [-57.952367916992188, -20.776168526367197],
            [-57.953967916992184, -20.780668526367197],
            [-57.956867916992181, -20.784468526367196],
            [-57.958667916992184, -20.786968526367197],
            [-57.961267916992185, -20.791468526367197],
            [-57.961267916992185, -20.794968526367196],
            [-57.959367916992186, -20.797868526367196],
            [-57.955767916992187, -20.798868526367198],
            [-57.951467916992186, -20.800268526367198],
            [-57.947067916992182, -20.800468526367197],
            [-57.941267916992189, -20.800768526367197],
            [-57.93586791699218, -20.799468526367196],
            [-57.929967916992183, -20.797768526367197],
            [-57.92216791699218, -20.794768526367196],
            [-57.917567916992184, -20.792468526367195],
            [-57.913667916992182, -20.790068526367197],
            [-57.910967916992185, -20.788868526367196],
            [-57.905867916992186, -20.788068526367198],
            [-57.898767916992185, -20.788668526367196],
            [-57.893267916992187, -20.790668526367195],
            [-57.889667916992181, -20.793668526367195],
            [-57.884567916992182, -20.798668526367198],
            [-57.87666791699219, -20.804368526367195],
            [-57.873767916992186, -20.807168526367196],
            [-57.871567916992184, -20.810868526367198],
            [-57.868067916992189, -20.815168526367195],
            [-57.863367916992189, -20.819368526367196],
            [-57.859367916992184, -20.824868526367197],
            [-57.857267916992186, -20.829668526367197],
            [-57.854667916992184, -20.835668526367197],
            [-57.85396791699219, -20.841168526367195],
            [-57.85496791699218, -20.845568526367195],
            [-57.857867916992184, -20.850868526367197],
            [-57.862267916992181, -20.853568526367198],
            [-57.866467916992185, -20.853868526367197],
            [-57.869467916992186, -20.853468526367195],
            [-57.872667916992185, -20.853868526367197],
            [-57.876967916992186, -20.856468526367195],
            [-57.883867916992187, -20.862468526367195],
            [-57.889667916992181, -20.868068526367196],
            [-57.891867916992183, -20.870068526367195],
            [-57.89476791699218, -20.872168526367197],
            [-57.897567916992188, -20.873868526367197],
            [-57.901567916992185, -20.876668526367197],
            [-57.904267916992183, -20.878868526367196],
            [-57.908067916992181, -20.881268526367197],
            [-57.910967916992185, -20.882068526367195],
            [-57.914567916992183, -20.883468526367196],
            [-57.918167916992189, -20.885968526367197],
            [-57.921967916992187, -20.888568526367198],
            [-57.926467916992181, -20.891868526367197],
            [-57.928867916992189, -20.895168526367197],
            [-57.928067916992184, -20.900168526367196],
            [-57.926167916992185, -20.902668526367197],
            [-57.921867916992184, -20.904468526367197],
            [-57.91646791699219, -20.904468526367197],
            [-57.911267916992188, -20.903468526367195],
            [-57.907367916992186, -20.903068526367196],
            [-57.902067916992181, -20.901768526367196],
            [-57.897667916992184, -20.902568526367197],
            [-57.892367916992185, -20.904468526367197],
            [-57.886467916992189, -20.908468526367198],
            [-57.88136791699219, -20.914568526367198],
            [-57.87966791699219, -20.918568526367196],
            [-57.878767916992189, -20.923868526367198],
            [-57.877667916992181, -20.929368526367195],
            [-57.876067916992184, -20.935168526367196],
            [-57.874467916992188, -20.939868526367196],
            [-57.871567916992184, -20.943468526367198],
            [-57.867167916992187, -20.946668526367198],
            [-57.863567916992181, -20.948768526367196],
            [-57.85666791699218, -20.949368526367195],
            [-57.850667916992187, -20.948768526367196],
            [-57.844467916992187, -20.946368526367195],
            [-57.841667916992186, -20.942768526367196],
            [-57.837967916992184, -20.939968526367196],
            [-57.835467916992187, -20.937768526367197],
            [-57.833467916992184, -20.935168526367196],
            [-57.828567916992185, -20.933868526367196],
            [-57.82486791699219, -20.933868526367196],
            [-57.82156791699218, -20.935468526367195],
            [-57.819667916992188, -20.937968526367197],
            [-57.819067916992182, -20.942068526367198],
            [-57.818767916992186, -20.945168526367198],
            [-57.818767916992186, -20.948468526367197],
            [-57.820967916992188, -20.952068526367196],
            [-57.823567916992189, -20.956768526367195],
            [-57.824667916992183, -20.961468526367195],
            [-57.825167916992186, -20.965168526367197],
            [-57.824267916992184, -20.968168526367197],
            [-57.822167916992186, -20.972068526367195],
            [-57.81886791699219, -20.975968526367197],
            [-57.817367916992183, -20.979668526367195],
            [-57.820467916992186, -20.983368526367197],
            [-57.823567916992189, -20.985768526367195],
            [-57.827367916992188, -20.989668526367197],
            [-57.82956791699219, -20.992668526367197],
            [-57.833267916992185, -20.997168526367197],
            [-57.837267916992189, -21.001568526367198],
            [-57.840467916992182, -21.005568526367195],
            [-57.842667916992184, -21.010368526367195],
            [-57.845567916992181, -21.016068526367196],
            [-57.849567916992186, -21.021268526367198],
            [-57.852567916992186, -21.025368526367195],
            [-57.857567916992181, -21.031268526367196],
            [-57.862167916992185, -21.035368526367197],
            [-57.866167916992183, -21.039168526367195],
            [-57.868267916992181, -21.041768526367196],
            [-57.868567916992184, -21.046168526367197],
            [-57.868067916992189, -21.051968526367197],
            [-57.86766791699219, -21.060168526367196],
            [-57.865767916992183, -21.066668526367195],
            [-57.862267916992181, -21.070568526367197],
            [-57.859167916992185, -21.074568526367198],
            [-57.856767916992183, -21.077868526367197],
            [-57.854467916992185, -21.082168526367198],
            [-57.85096791699219, -21.089068526367196],
            [-57.845867916992184, -21.096968526367196],
            [-57.847367916992184, -21.101268526367196],
            [-57.850267916992181, -21.106168526367195],
            [-57.854467916992185, -21.113968526367195],
            [-57.858967916992185, -21.123568526367198],
            [-57.863067916992186, -21.129868526367197],
            [-57.864967916992185, -21.136268526367196],
            [-57.862467916992188, -21.147368526367195],
            [-57.860067916992186, -21.158168526367195],
            [-57.855867916992182, -21.168868526367195],
            [-57.851667916992184, -21.179468526367195],
            [-57.848867916992184, -21.187768526367197],
            [-57.848167916992182, -21.194968526367198],
            [-57.849167916992187, -21.201768526367196],
            [-57.849967916992185, -21.207668526367197],
            [-57.851367916992189, -21.217968526367198],
            [-57.853067916992188, -21.227068526367198],
            [-57.857167916992182, -21.233068526367198],
            [-57.86296791699219, -21.236068526367195],
            [-57.86766791699219, -21.238668526367196],
            [-57.877167916992185, -21.247768526367196],
            [-57.886567916992185, -21.253768526367196],
            [-57.897967916992187, -21.260368526367195],
            [-57.905967916992182, -21.265168526367198],
            [-57.912067916992186, -21.270968526367195],
            [-57.921367916992182, -21.278768526367195],
            [-57.920367916992184, -21.284568526367195],
            [-57.91446791699218, -21.292268526367195],
            [-57.905667916992186, -21.300568526367197],
            [-57.894867916992183, -21.307168526367196],
            [-57.885167916992188, -21.308768526367196],
            [-57.875767916992189, -21.311068526367198],
            [-57.869067916992186, -21.313468526367195],
            [-57.861167916992187, -21.315568526367198],
            [-57.854667916992184, -21.316868526367195],
            [-57.852867916992182, -21.320168526367198],
            [-57.852567916992186, -21.325768526367195],
            [-57.853667916992187, -21.332068526367195],
            [-57.855867916992182, -21.338468526367198],
            [-57.857167916992182, -21.341768526367197],
            [-57.861067916992184, -21.350568526367198],
            [-57.866667916992185, -21.356768526367198],
            [-57.874467916992188, -21.363268526367197],
            [-57.880267916992182, -21.368068526367196],
            [-57.884867916992185, -21.371668526367195],
            [-57.887967916992181, -21.374468526367195],
            [-57.892367916992185, -21.377968526367198],
            [-57.896767916992189, -21.383468526367196],
            [-57.901867916992188, -21.392168526367197],
            [-57.905567916992183, -21.398768526367196],
            [-57.908067916992181, -21.402568526367197],
            [-57.909467916992185, -21.406968526367198],
            [-57.909567916992188, -21.414468526367195],
            [-57.91006791699219, -21.420868526367197],
            [-57.912867916992184, -21.425268526367198],
            [-57.915567916992188, -21.429968526367198],
            [-57.919667916992182, -21.437968526367197],
            [-57.924667916992185, -21.444968526367198],
            [-57.92716791699219, -21.449868526367197],
            [-57.927267916992186, -21.452968526367197],
            [-57.925567916992186, -21.456868526367195],
            [-57.925067916992184, -21.461968526367198],
            [-57.926567916992184, -21.468768526367196],
            [-57.929467916992181, -21.474768526367196],
            [-57.932267916992188, -21.480268526367198],
            [-57.934367916992187, -21.483968526367196],
            [-57.939667916992185, -21.491368526367197],
            [-57.946367916992187, -21.498568526367198],
            [-57.954867916992185, -21.505768526367195],
            [-57.960467916992187, -21.509868526367196],
            [-57.964567916992181, -21.515368526367197],
            [-57.967667916992184, -21.521468526367197],
            [-57.968367916992186, -21.527668526367197],
            [-57.968467916992182, -21.535268526367197],
            [-57.965867916992181, -21.551568526367195],
            [-57.964067916992185, -21.557268526367196],
            [-57.96156791699218, -21.562368526367198],
            [-57.956767916992185, -21.566868526367195],
            [-57.950767916992184, -21.569868526367195],
            [-57.940967916992186, -21.571868526367197],
            [-57.936267916992186, -21.573468526367197],
            [-57.930267916992186, -21.576568526367197],
            [-57.923367916992184, -21.579268526367198],
            [-57.918867916992184, -21.582168526367198],
            [-57.915567916992188, -21.586268526367196],
            [-57.913967916992185, -21.590368526367197],
            [-57.91446791699218, -21.595568526367195],
            [-57.918567916992188, -21.598868526367195],
            [-57.923567916992184, -21.601768526367195],
            [-57.928567916992186, -21.605868526367196],
            [-57.931967916992186, -21.609968526367197],
            [-57.935267916992188, -21.612868526367198],
            [-57.937967916992186, -21.615068526367196],
            [-57.94216791699219, -21.620868526367197],
            [-57.943167916992181, -21.628468526367197],
            [-57.93916791699219, -21.638168526367195],
            [-57.936667916992185, -21.644568526367195],
            [-57.933367916992182, -21.651568526367196],
            [-57.929967916992183, -21.657268526367197],
            [-57.925567916992186, -21.662068526367197],
            [-57.920267916992188, -21.666168526367198],
            [-57.911367916992184, -21.670068526367196],
            [-57.903467916992184, -21.674668526367196],
            [-57.897167916992188, -21.677968526367195],
            [-57.892967916992184, -21.679368526367195],
            [-57.889967916992184, -21.680468526367196],
            [-57.886067916992189, -21.682768526367198],
            [-57.883267916992182, -21.687968526367197],
            [-57.884867916992185, -21.691668526367195],
            [-57.887767916992189, -21.694868526367195],
            [-57.890767916992189, -21.697668526367195],
            [-57.892667916992181, -21.700468526367196],
            [-57.897567916992188, -21.704968526367196],
            [-57.900967916992187, -21.707668526367197],
            [-57.90376791699218, -21.709768526367196],
            [-57.907867916992188, -21.712568526367196],
            [-57.919767916992186, -21.719568526367198],
            [-57.93116791699218, -21.726368526367196],
            [-57.940967916992186, -21.732868526367195],
            [-57.945667916992186, -21.740368526367195],
            [-57.947367916992185, -21.745868526367197],
            [-57.945567916992189, -21.752068526367196],
            [-57.940567916992187, -21.757768526367197],
            [-57.932767916992184, -21.762968526367196],
            [-57.926667916992187, -21.766768526367198],
            [-57.917067916992181, -21.769068526367196],
            [-57.909867916992184, -21.770368526367196],
            [-57.90846791699218, -21.773968526367195],
            [-57.909867916992184, -21.776968526367195],
            [-57.911667916992187, -21.780568526367198],
            [-57.912867916992184, -21.785368526367197],
            [-57.914267916992188, -21.790868526367195],
            [-57.915567916992188, -21.793568526367196],
            [-57.920067916992181, -21.801068526367196],
            [-57.924967916992188, -21.809168526367195],
            [-57.927867916992184, -21.816668526367195],
            [-57.931067916992184, -21.824568526367198],
            [-57.935767916992184, -21.829668526367197],
            [-57.942667916992185, -21.835368526367198],
            [-57.949667916992183, -21.838768526367197],
            [-57.955467916992184, -21.840868526367196],
            [-57.959567916992185, -21.841968526367197],
            [-57.965667916992189, -21.843068526367198],
            [-57.969767916992183, -21.844168526367195],
            [-57.971767916992185, -21.847068526367195],
            [-57.96956791699219, -21.850968526367197],
            [-57.965167916992186, -21.851968526367195],
            [-57.959767916992185, -21.853068526367196],
            [-57.954967916992189, -21.855368526367197],
            [-57.947467916992181, -21.859668526367198],
            [-57.940567916992187, -21.862968526367197],
            [-57.932767916992184, -21.866668526367196],
            [-57.926467916992181, -21.870768526367197],
            [-57.919967916992185, -21.873768526367197],
            [-57.916167916992187, -21.876268526367195],
            [-57.913867916992189, -21.879668526367198],
            [-57.914167916992184, -21.884968526367196],
            [-57.916767916992185, -21.889268526367196],
            [-57.921467916992185, -21.890768526367197],
            [-57.925767916992186, -21.890968526367196],
            [-57.929767916992184, -21.889868526367195],
            [-57.934567916992187, -21.888668526367198],
            [-57.939067916992187, -21.892568526367196],
            [-57.942667916992185, -21.896768526367197],
            [-57.947967916992184, -21.900368526367195],
            [-57.949667916992183, -21.903368526367196],
            [-57.949667916992183, -21.907268526367197],
            [-57.947467916992181, -21.910668526367196],
            [-57.942667916992185, -21.913068526367198],
            [-57.938067916992182, -21.915268526367196],
            [-57.936667916992185, -21.919668526367197],
            [-57.936967916992188, -21.927868526367195],
            [-57.940467916992183, -21.935568526367195],
            [-57.945267916992186, -21.944368526367196],
            [-57.948967916992189, -21.953168526367197],
            [-57.951867916992185, -21.959768526367196],
            [-57.954867916992185, -21.965868526367196],
            [-57.959267916992189, -21.973068526367197],
            [-57.962667916992189, -21.978368526367195],
            [-57.965067916992183, -21.983568526367197],
            [-57.964767916992187, -21.989668526367197],
            [-57.964467916992184, -21.996268526367196],
            [-57.964767916992187, -22.001068526367195],
            [-57.968967916992185, -22.009968526367196],
            [-57.974967916992185, -22.015368526367197],
            [-57.983567916992186, -22.020168526367197],
            [-57.989967916992185, -22.022968526367197],
            [-57.994467916992186, -22.025068526367196],
            [-57.999367916992185, -22.027368526367198],
            [-58.002967916992183, -22.029168526367197],
            [-58.006267916992186, -22.031468526367195],
            [-58.008267916992182, -22.034168526367196],
            [-58.009267916992187, -22.039168526367195],
            [-58.009567916992182, -22.045268526367195],
            [-58.007067916992185, -22.049968526367195],
            [-58.001267916992184, -22.053668526367197],
            [-57.994067916992186, -22.055568526367196],
            [-57.987567916992184, -22.059068526367195],
            [-57.984267916992181, -22.063568526367195],
            [-57.98326791699219, -22.070668526367196],
            [-57.986167916992187, -22.079368526367197],
            [-57.990767916992183, -22.083768526367198],
            [-57.99366791699218, -22.089368526367195],
            [-57.989467916992183, -22.090668526367196],
            [-57.98196791699219, -22.090068526367197],
            [-57.977867916992182, -22.090168526367197],
            [-57.973467916992185, -22.090568526367196],
            [-57.970567916992181, -22.090368526367197],
            [-57.967067916992185, -22.089268526367196],
            [-57.965367916992186, -22.086868526367198],
            [-57.964467916992184, -22.080968526367197],
            [-57.961767916992187, -22.078768526367195],
            [-57.958267916992185, -22.081468526367196],
            [-57.955767916992187, -22.086268526367196],
            [-57.951767916992182, -22.086968526367198],
            [-57.949967916992186, -22.083668526367195],
            [-57.946767916992187, -22.082568526367197],
            [-57.941967916992184, -22.082868526367196],
            [-57.938567916992184, -22.086168526367196],
            [-57.936567916992189, -22.090368526367197],
            [-57.937667916992183, -22.097368526367195],
            [-57.935567916992184, -22.101768526367195],
            [-57.932767916992184, -22.104768526367195],
            [-57.928067916992184, -22.106768526367198],
            [-57.923667916992187, -22.106668526367198],
            [-57.921467916992185, -22.108968526367196],
            [-57.925567916992186, -22.113568526367196],
            [-57.928567916992186, -22.116168526367197],
            [-57.929767916992184, -22.119668526367196],
            [-57.926167916992185, -22.120168526367195],
            [-57.921967916992187, -22.121168526367196],
            [-57.918067916992186, -22.121068526367196],
            [-57.913967916992185, -22.121568526367195],
            [-57.910867916992188, -22.123268526367195],
            [-57.905867916992186, -22.125468526367197],
            [-57.898367916992186, -22.123868526367197],
            [-57.893967916992182, -22.122668526367196],
            [-57.888167916992188, -22.124068526367196],
            [-57.886867916992188, -22.129368526367195],
            [-57.883567916992185, -22.129468526367198],
            [-57.880667916992181, -22.129068526367195],
            [-57.876267916992184, -22.129968526367197],
            [-57.872967916992181, -22.129068526367195],
            [-57.869167916992183, -22.130568526367195],
            [-57.867567916992186, -22.133768526367195],
            [-57.865267916992181, -22.135668526367198],
            [-57.863567916992181, -22.131768526367196],
            [-57.85996791699219, -22.130268526367196],
            [-57.856367916992184, -22.128768526367196],
            [-57.851367916992189, -22.127368526367196],
            [-57.84596791699218, -22.127468526367196],
            [-57.841767916992183, -22.124368526367196],
            [-57.839067916992185, -22.121968526367198],
            [-57.835167916992184, -22.119768526367196],
            [-57.828967916992184, -22.120168526367195],
            [-57.82486791699219, -22.121568526367195],
            [-57.822967916992184, -22.123868526367197],
            [-57.823467916992186, -22.127968526367198],
            [-57.821767916992187, -22.132068526367195],
            [-57.820967916992188, -22.136068526367197],
            [-57.818767916992186, -22.138468526367195],
            [-57.815167916992181, -22.139868526367195],
            [-57.811967916992188, -22.140168526367198],
            [-57.80916791699218, -22.142568526367196],
            [-57.806667916992183, -22.146768526367197],
            [-57.80516791699219, -22.150068526367196],
            [-57.801967916992183, -22.150768526367198],
            [-57.797867916992189, -22.148768526367196],
            [-57.796467916992185, -22.143468526367197],
            [-57.793067916992186, -22.140768526367197],
            [-57.791167916992187, -22.138168526367195],
            [-57.790567916992188, -22.134968526367196],
            [-57.78946791699218, -22.131368526367197],
            [-57.784267916992185, -22.128568526367197],
            [-57.779667916992182, -22.125868526367196],
            [-57.77436791699219, -22.127668526367195],
            [-57.770767916992185, -22.128268526367197],
            [-57.767367916992185, -22.130968526367198],
            [-57.763567916992187, -22.135368526367195],
            [-57.75936791699219, -22.135268526367195],
            [-57.754867916992183, -22.135168526367195],
            [-57.750467916992186, -22.137668526367197],
            [-57.747667916992185, -22.138968526367197],
            [-57.744067916992186, -22.136068526367197],
            [-57.738267916992186, -22.133468526367196],
            [-57.738267916992186, -22.128468526367197],
            [-57.736367916992187, -22.125468526367197],
            [-57.734367916992184, -22.122668526367196],
            [-57.735367916992189, -22.119668526367196],
            [-57.73966791699219, -22.118068526367196],
            [-57.741967916992181, -22.114668526367197],
            [-57.741367916992189, -22.111868526367196],
            [-57.740067916992189, -22.107068526367197],
            [-57.737567916992184, -22.102568526367197],
            [-57.735067916992186, -22.100568526367198],
            [-57.731067916992188, -22.099768526367196],
            [-57.728867916992186, -22.102468526367197],
            [-57.726367916992189, -22.106468526367195],
            [-57.723367916992188, -22.110268526367197],
            [-57.719867916992186, -22.113068526367197],
            [-57.716967916992189, -22.113668526367196],
            [-57.715167916992186, -22.110568526367196],
            [-57.715967916992184, -22.106968526367197],
            [-57.712367916992186, -22.103168526367195],
            [-57.707667916992186, -22.102768526367196],
            [-57.704267916992187, -22.098468526367196],
            [-57.705767916992187, -22.095068526367196],
            [-57.706767916992185, -22.091968526367197],
            [-57.703467916992182, -22.091768526367197],
            [-57.699367916992188, -22.092068526367196],
            [-57.696267916992184, -22.091168526367195],
            [-57.693267916992184, -22.089868526367198],
            [-57.689867916992185, -22.088768526367197],
            [-57.68616791699219, -22.092568526367195],
            [-57.681567916992186, -22.096268526367197],
            [-57.678267916992183, -22.100568526367198],
            [-57.674767916992181, -22.102768526367196],
            [-57.670867916992187, -22.101368526367196],
            [-57.667067916992181, -22.102568526367197],
            [-57.665067916992186, -22.105668526367197],
            [-57.660867916992188, -22.105268526367198],
            [-57.65706791699219, -22.104568526367196],
            [-57.653667916992184, -22.106768526367198],
            [-57.64936791699219, -22.106068526367196],
            [-57.645767916992185, -22.104768526367195],
            [-57.64206791699219, -22.105368526367197],
            [-57.638167916992188, -22.105968526367196],
            [-57.633767916992184, -22.103068526367196],
            [-57.629067916992184, -22.101668526367195],
            [-57.624467916992188, -22.100068526367195],
            [-57.621167916992185, -22.097268526367195],
            [-57.618067916992189, -22.095368526367196],
            [-57.613867916992184, -22.094468526367198],
            [-57.60996791699219, -22.095968526367198],
            [-57.611067916992184, -22.099168526367198],
            [-57.612567916992184, -22.103668526367198],
            [-57.613367916992189, -22.107468526367196],
            [-57.61296791699219, -22.110368526367196],
            [-57.610267916992186, -22.112968526367197],
            [-57.609267916992181, -22.117168526367195],
            [-57.611467916992183, -22.120268526367198],
            [-57.612267916992181, -22.123268526367195],
            [-57.608667916992189, -22.121968526367198],
            [-57.606067916992188, -22.123368526367198],
            [-57.607067916992186, -22.126268526367195],
            [-57.606367916992184, -22.129468526367198],
            [-57.602867916992182, -22.130968526367198],
            [-57.598667916992184, -22.130568526367195],
            [-57.596967916992185, -22.133768526367195],
            [-57.600267916992181, -22.136268526367196],
            [-57.599967916992185, -22.139268526367196],
            [-57.596367916992186, -22.136568526367196],
            [-57.593167916992186, -22.136868526367195],
            [-57.594067916992188, -22.140068526367195],
            [-57.594067916992188, -22.143268526367198],
            [-57.590867916992181, -22.143668526367197],
            [-57.588667916992186, -22.139668526367196],
            [-57.585167916992184, -22.141368526367195],
            [-57.586267916992185, -22.145368526367196],
            [-57.585667916992186, -22.149368526367198],
            [-57.585867916992186, -22.153168526367196],
            [-57.587867916992181, -22.157568526367196],
            [-57.588167916992184, -22.162068526367197],
            [-57.588367916992183, -22.165068526367197],
            [-57.587367916992186, -22.167768526367198],
            [-57.584267916992189, -22.169968526367196],
            [-57.581467916992182, -22.174168526367197],
            [-57.57656791699219, -22.176168526367196],
            [-57.572067916992182, -22.175768526367197],
            [-57.567667916992185, -22.177568526367196],
            [-57.563067916992182, -22.178268526367198],
            [-57.558367916992182, -22.177568526367196],
            [-57.554367916992184, -22.180468526367196],
            [-57.551067916992181, -22.181868526367197],
            [-57.547767916992186, -22.181968526367196],
            [-57.543567916992188, -22.181668526367197],
            [-57.540567916992188, -22.181168526367195],
            [-57.537467916992185, -22.180268526367197],
            [-57.534467916992185, -22.178368526367198],
            [-57.531667916992184, -22.177268526367197],
            [-57.528367916992181, -22.175768526367197],
            [-57.52576791699218, -22.173168526367196],
            [-57.523967916992184, -22.168968526367195],
            [-57.520667916992181, -22.170068526367196],
            [-57.517867916992188, -22.172868526367196],
            [-57.513567916992187, -22.174968526367195],
            [-57.512767916992189, -22.178068526367195],
            [-57.51036791699218, -22.181168526367195],
            [-57.506467916992186, -22.184268526367195],
            [-57.501267916992184, -22.185168526367196],
            [-57.496867916992187, -22.184068526367195],
            [-57.493567916992184, -22.181868526367197],
            [-57.491567916992182, -22.184168526367195],
            [-57.490267916992181, -22.187468526367198],
            [-57.489367916992187, -22.191068526367197],
            [-57.486367916992187, -22.190468526367198],
            [-57.482867916992184, -22.191568526367195],
            [-57.479167916992182, -22.190168526367195],
            [-57.475267916992181, -22.188568526367195],
            [-57.47096791699218, -22.187268526367195],
            [-57.468467916992182, -22.190468526367198],
            [-57.465167916992186, -22.192368526367197],
            [-57.46326791699218, -22.189168526367197],
            [-57.460367916992183, -22.187668526367197],
            [-57.456767916992185, -22.189968526367196],
            [-57.452167916992181, -22.187968526367197],
            [-57.447467916992181, -22.187268526367195],
            [-57.44486791699218, -22.189868526367196],
            [-57.442367916992183, -22.191868526367195],
            [-57.439067916992187, -22.192068526367198],
            [-57.438767916992184, -22.195268526367197],
            [-57.436967916992188, -22.197968526367195],
            [-57.434367916992187, -22.199968526367197],
            [-57.431367916992187, -22.198968526367196],
            [-57.428667916992183, -22.196068526367196],
            [-57.424367916992182, -22.197868526367195],
            [-57.42716791699219, -22.201568526367197],
            [-57.424667916992185, -22.204268526367198],
            [-57.42246791699219, -22.201568526367197],
            [-57.421167916992189, -22.198968526367196],
            [-57.417567916992184, -22.197068526367197],
            [-57.413167916992187, -22.197368526367196],
            [-57.409867916992184, -22.199068526367196],
            [-57.406967916992187, -22.197968526367195],
            [-57.404267916992183, -22.199668526367198],
            [-57.400467916992184, -22.201368526367197],
            [-57.396767916992189, -22.203568526367196],
            [-57.397967916992187, -22.207368526367198],
            [-57.395467916992189, -22.208768526367198],
            [-57.392167916992186, -22.207568526367197],
            [-57.389667916992181, -22.205368526367195],
            [-57.386867916992188, -22.204668526367197],
            [-57.386767916992184, -22.207568526367197],
            [-57.382067916992185, -22.209268526367197],
            [-57.378567916992182, -22.212668526367196],
            [-57.379167916992188, -22.216468526367198],
            [-57.380167916992185, -22.220868526367195],
            [-57.380667916992181, -22.225968526367197],
            [-57.375567916992182, -22.224468526367197],
            [-57.372667916992185, -22.226968526367195],
            [-57.375267916992186, -22.228668526367198],
            [-57.374067916992189, -22.231468526367195],
            [-57.370767916992186, -22.231368526367195],
            [-57.368367916992185, -22.228568526367198],
            [-57.363567916992181, -22.228468526367195],
            [-57.363967916992181, -22.231668526367198],
            [-57.361067916992184, -22.233968526367196],
            [-57.358567916992186, -22.231468526367195],
            [-57.357867916992184, -22.226968526367195],
            [-57.354767916992188, -22.228068526367196],
            [-57.351367916992189, -22.229468526367196],
            [-57.347067916992188, -22.231668526367198],
            [-57.342667916992184, -22.229168526367197],
            [-57.340467916992182, -22.232868526367195],
            [-57.338467916992187, -22.230568526367197],
            [-57.334667916992188, -22.229968526367195],
            [-57.335767916992182, -22.233668526367197],
            [-57.331067916992183, -22.234268526367195],
            [-57.329667916992186, -22.238068526367197],
            [-57.325967916992184, -22.238868526367195],
            [-57.323467916992186, -22.242668526367197],
            [-57.32016791699219, -22.245168526367195],
            [-57.317367916992183, -22.243068526367196],
            [-57.314667916992185, -22.240368526367195],
            [-57.31686791699218, -22.237168526367196],
            [-57.314567916992189, -22.233568526367197],
            [-57.311267916992186, -22.231968526367197],
            [-57.310267916992188, -22.228468526367195],
            [-57.306967916992186, -22.229568526367196],
            [-57.303567916992186, -22.228468526367195],
            [-57.304167916992185, -22.225268526367195],
            [-57.301867916992187, -22.222268526367195],
            [-57.298567916992184, -22.221968526367196],
            [-57.295867916992187, -22.219768526367197],
            [-57.29246791699218, -22.219768526367197],
            [-57.291367916992186, -22.223868526367195],
            [-57.287567916992188, -22.221668526367196],
            [-57.283667916992187, -22.225068526367195],
            [-57.280467916992187, -22.226368526367196],
            [-57.277567916992183, -22.230268526367198],
            [-57.278467916992184, -22.234268526367195],
            [-57.275367916992181, -22.234968526367197],
            [-57.27436791699219, -22.231468526367195],
            [-57.270967916992184, -22.230268526367198],
            [-57.271267916992187, -22.234668526367198],
            [-57.26976791699218, -22.237468526367195],
            [-57.266067916992185, -22.237768526367198],
            [-57.263167916992188, -22.239068526367195],
            [-57.260167916992188, -22.239368526367198],
            [-57.256867916992185, -22.238368526367196],
            [-57.253767916992189, -22.238968526367195],
            [-57.251267916992184, -22.241468526367196],
            [-57.249167916992185, -22.245568526367197],
            [-57.245867916992182, -22.248568526367198],
            [-57.241067916992186, -22.250568526367196],
            [-57.236067916992184, -22.250168526367197],
            [-57.231767916992183, -22.246868526367198],
            [-57.227467916992182, -22.243868526367198],
            [-57.223767916992188, -22.242968526367196],
            [-57.218367916992186, -22.240268526367196],
            [-57.216967916992189, -22.235868526367195],
            [-57.215367916992186, -22.232768526367195],
            [-57.215367916992186, -22.228368526367195],
            [-57.211767916992187, -22.226968526367195],
            [-57.214267916992185, -22.223368526367196],
            [-57.214067916992185, -22.218668526367196],
            [-57.210467916992187, -22.218168526367197],
            [-57.207667916992186, -22.220568526367195],
            [-57.204667916992186, -22.217968526367198],
            [-57.203267916992189, -22.215168526367197],
            [-57.202067916992185, -22.211568526367195],
            [-57.197767916992184, -22.212068526367197],
            [-57.194067916992182, -22.213668526367197],
            [-57.190567916992187, -22.217068526367196],
            [-57.192367916992183, -22.220968526367198],
            [-57.189967916992188, -22.224168526367198],
            [-57.187667916992183, -22.227068526367198],
            [-57.18416791699218, -22.230868526367196],
            [-57.183267916992186, -22.235368526367196],
            [-57.178667916992183, -22.233668526367197],
            [-57.173967916992183, -22.233368526367197],
            [-57.169267916992183, -22.233568526367197],
            [-57.170267916992188, -22.229968526367195],
            [-57.166367916992186, -22.228168526367195],
            [-57.164967916992182, -22.232168526367197],
            [-57.160267916992183, -22.231768526367198],
            [-57.157367916992186, -22.227268526367197],
            [-57.15406791699219, -22.230268526367198],
            [-57.150867916992183, -22.231968526367197],
            [-57.147667916992184, -22.233068526367198],
            [-57.143467916992186, -22.231068526367196],
            [-57.140467916992186, -22.228668526367198],
            [-57.13736791699219, -22.226668526367195],
            [-57.13266791699219, -22.226168526367196],
            [-57.128267916992186, -22.227068526367198],
            [-57.124467916992188, -22.228068526367196],
            [-57.12196791699219, -22.229768526367195],
            [-57.118367916992185, -22.230068526367198],
            [-57.114767916992186, -22.228168526367195],
            [-57.109267916992181, -22.225968526367197],
            [-57.105067916992184, -22.227768526367196],
            [-57.107867916992184, -22.231068526367196],
            [-57.10966791699218, -22.235568526367196],
            [-57.111767916992186, -22.239468526367197],
            [-57.108567916992186, -22.242468526367198],
            [-57.104467916992185, -22.244168526367197],
            [-57.101467916992185, -22.247968526367195],
            [-57.097067916992188, -22.248068526367195],
            [-57.093167916992186, -22.248268526367195],
            [-57.09156791699219, -22.243668526367195],
            [-57.088967916992189, -22.242168526367195],
            [-57.086267916992185, -22.245868526367197],
            [-57.085767916992182, -22.248868526367197],
            [-57.082667916992186, -22.250868526367196],
            [-57.079367916992183, -22.248668526367197],
            [-57.07956791699219, -22.245768526367197],
            [-57.07656791699219, -22.245168526367195],
            [-57.073567916992189, -22.246568526367195],
            [-57.071367916992187, -22.244468526367196],
            [-57.068267916992184, -22.242968526367196],
            [-57.06416791699219, -22.243668526367195],
            [-57.059867916992189, -22.242268526367198],
            [-57.058767916992181, -22.239068526367195],
            [-57.05946791699219, -22.235568526367196],
            [-57.060267916992188, -22.232468526367196],
            [-57.058567916992189, -22.229968526367195],
            [-57.053967916992185, -22.231368526367195],
            [-57.050367916992187, -22.231668526367198],
            [-57.048167916992185, -22.233968526367196],
            [-57.048567916992184, -22.237768526367198],
            [-57.046967916992187, -22.241168526367197],
            [-57.043167916992189, -22.241868526367195],
            [-57.042567916992184, -22.238068526367197],
            [-57.039867916992186, -22.236368526367198],
            [-57.036267916992188, -22.235668526367196],
            [-57.031967916992187, -22.234168526367196],
            [-57.027567916992183, -22.234968526367197],
            [-57.022867916992183, -22.236368526367198],
            [-57.020367916992186, -22.234468526367195],
            [-57.018167916992184, -22.231368526367195],
            [-57.015667916992186, -22.232868526367195],
            [-57.012867916992185, -22.234768526367198],
            [-57.009567916992182, -22.234668526367198],
            [-57.00606791699218, -22.232068526367197],
            [-57.003467916992186, -22.230568526367197],
            [-57.001567916992187, -22.228168526367195],
            [-57.000467916992186, -22.223768526367195],
            [-56.996267916992181, -22.223068526367197],
            [-56.99266791699219, -22.224568526367197],
            [-56.990067916992189, -22.227068526367198],
            [-56.989067916992184, -22.230668526367197],
            [-56.990467916992188, -22.233968526367196],
            [-56.991167916992183, -22.237168526367196],
            [-56.989367916992187, -22.239468526367197],
            [-56.987267916992181, -22.242268526367198],
            [-56.983867916992182, -22.242568526367197],
            [-56.98026791699219, -22.243068526367196],
            [-56.975667916992187, -22.243868526367198],
            [-56.971967916992185, -22.244168526367197],
            [-56.970567916992181, -22.241068526367197],
            [-56.966167916992184, -22.240768526367198],
            [-56.962667916992189, -22.239968526367196],
            [-56.959067916992183, -22.238368526367196],
            [-56.956867916992181, -22.240468526367195],
            [-56.956167916992186, -22.244768526367196],
            [-56.954267916992187, -22.248868526367197],
            [-56.952167916992181, -22.250868526367196],
            [-56.94956791699218, -22.253568526367197],
            [-56.946367916992187, -22.255968526367198],
            [-56.943267916992184, -22.255668526367195],
            [-56.940167916992181, -22.254868526367197],
            [-56.935467916992181, -22.254168526367195],
            [-56.931067916992184, -22.254068526367195],
            [-56.927467916992185, -22.251268526367195],
            [-56.923667916992187, -22.254068526367195],
            [-56.918967916992187, -22.255168526367196],
            [-56.915667916992184, -22.255268526367196],
            [-56.917567916992184, -22.258168526367196],
            [-56.913067916992183, -22.259168526367198],
            [-56.91006791699219, -22.262868526367196],
            [-56.90676791699218, -22.265968526367196],
            [-56.902667916992186, -22.265368526367197],
            [-56.901867916992188, -22.261468526367196],
            [-56.902867916992186, -22.258468526367196],
            [-56.901767916992185, -22.254568526367198],
            [-56.898967916992184, -22.251368526367198],
            [-56.894367916992181, -22.248768526367197],
            [-56.889667916992181, -22.246368526367196],
            [-56.886267916992182, -22.246068526367196],
            [-56.883567916992185, -22.247468526367197],
            [-56.880567916992185, -22.250768526367196],
            [-56.879167916992188, -22.255668526367195],
            [-56.877967916992183, -22.259368526367197],
            [-56.875867916992185, -22.262468526367197],
            [-56.875567916992182, -22.265768526367197],
            [-56.87666791699219, -22.268568526367197],
            [-56.878467916992186, -22.271768526367197],
            [-56.880067916992189, -22.276168526367197],
            [-56.875967916992181, -22.279468526367197],
            [-56.871567916992184, -22.281468526367195],
            [-56.867967916992185, -22.283868526367197],
            [-56.867567916992186, -22.287068526367197],
            [-56.864967916992185, -22.289568526367198],
            [-56.860267916992186, -22.291768526367196],
            [-56.856467916992187, -22.289568526367198],
            [-56.852367916992186, -22.291668526367197],
            [-56.849867916992181, -22.294668526367197],
            [-56.84756791699219, -22.296668526367196],
            [-56.845267916992185, -22.299268526367197],
            [-56.843167916992186, -22.301368526367195],
            [-56.840167916992186, -22.301168526367196],
            [-56.836167916992181, -22.299968526367195],
            [-56.832667916992186, -22.297768526367197],
            [-56.829367916992183, -22.296168526367197],
            [-56.825967916992184, -22.295568526367198],
            [-56.824267916992184, -22.292568526367198],
            [-56.822667916992188, -22.289568526367198],
            [-56.819967916992184, -22.287268526367196],
            [-56.816567916992184, -22.288068526367198],
            [-56.812767916992186, -22.286968526367197],
            [-56.80946791699219, -22.286668526367198],
            [-56.807267916992188, -22.284468526367196],
            [-56.803967916992185, -22.284168526367196],
            [-56.802267916992186, -22.280868526367197],
            [-56.804467916992181, -22.275968526367198],
            [-56.807267916992188, -22.271868526367196],
            [-56.805867916992185, -22.268468526367197],
            [-56.803567916992186, -22.266068526367196],
            [-56.804767916992184, -22.262468526367197],
            [-56.805867916992185, -22.259168526367198],
            [-56.805067916992186, -22.256268526367197],
            [-56.804167916992185, -22.252668526367195],
            [-56.801567916992184, -22.248768526367197],
            [-56.798567916992184, -22.245868526367197],
            [-56.795667916992187, -22.243668526367195],
            [-56.792267916992188, -22.242168526367195],
            [-56.787767916992181, -22.241868526367195],
            [-56.789167916992184, -22.244768526367196],
            [-56.787067916992186, -22.249368526367196],
            [-56.787067916992186, -22.254068526367195],
            [-56.78476791699218, -22.257168526367195],
            [-56.78176791699218, -22.257368526367195],
            [-56.778167916992189, -22.257768526367197],
            [-56.776267916992182, -22.259868526367196],
            [-56.772867916992183, -22.258068526367197],
            [-56.770967916992184, -22.254868526367197],
            [-56.767667916992181, -22.253468526367197],
            [-56.763467916992184, -22.250568526367196],
            [-56.763567916992187, -22.246868526367198],
            [-56.766567916992187, -22.244968526367195],
            [-56.763167916992188, -22.242668526367197],
            [-56.758467916992188, -22.242268526367198],
            [-56.755467916992188, -22.244968526367195],
            [-56.752167916992185, -22.245468526367198],
            [-56.751867916992182, -22.240468526367195],
            [-56.749767916992184, -22.237568526367195],
            [-56.74696791699219, -22.236068526367195],
            [-56.745767916992186, -22.238968526367195],
            [-56.74266791699219, -22.241168526367197],
            [-56.739067916992184, -22.244068526367197],
            [-56.736067916992184, -22.244468526367196],
            [-56.732267916992186, -22.246868526367198],
            [-56.732467916992185, -22.249868526367198],
            [-56.735267916992186, -22.252968526367198],
            [-56.733867916992182, -22.256568526367197],
            [-56.730667916992189, -22.258268526367196],
            [-56.727367916992186, -22.259668526367197],
            [-56.724167916992187, -22.262368526367197],
            [-56.720367916992188, -22.263568526367198],
            [-56.720167916992182, -22.259968526367196],
            [-56.719767916992183, -22.256668526367196],
            [-56.716767916992183, -22.254868526367197],
            [-56.713667916992186, -22.252668526367195],
            [-56.712967916992184, -22.249368526367196],
            [-56.709067916992183, -22.247268526367197],
            [-56.706767916992185, -22.242768526367197],
            [-56.704867916992185, -22.237568526367195],
            [-56.700967916992184, -22.234968526367197],
            [-56.697767916992184, -22.234668526367198],
            [-56.695667916992186, -22.231768526367198],
            [-56.695467916992186, -22.227568526367197],
            [-56.701067916992187, -22.225668526367198],
            [-56.703967916992184, -22.221368526367197],
            [-56.703167916992186, -22.217668526367195],
            [-56.699667916992183, -22.219868526367197],
            [-56.696267916992184, -22.219468526367198],
            [-56.691967916992184, -22.218668526367196],
            [-56.688567916992184, -22.221268526367197],
            [-56.685767916992184, -22.220568526367195],
            [-56.682767916992184, -22.221468526367197],
            [-56.681567916992186, -22.226368526367196],
            [-56.682667916992187, -22.230568526367197],
            [-56.682567916992184, -22.234468526367195],
            [-56.681567916992186, -22.238068526367197],
            [-56.679767916992184, -22.242468526367198],
            [-56.677567916992189, -22.245168526367195],
            [-56.674467916992185, -22.246268526367196],
            [-56.671367916992182, -22.248868526367197],
            [-56.666967916992185, -22.249668526367195],
            [-56.66446791699218, -22.253268526367197],
            [-56.660567916992186, -22.253068526367198],
            [-56.656967916992187, -22.255268526367196],
            [-56.65276791699219, -22.254968526367197],
            [-56.650467916992184, -22.258468526367196],
            [-56.647867916992183, -22.261068526367197],
            [-56.644667916992184, -22.263468526367195],
            [-56.641067916992185, -22.263968526367197],
            [-56.637667916992186, -22.261768526367195],
            [-56.638167916992188, -22.256268526367197],
            [-56.639967916992184, -22.252668526367195],
            [-56.642567916992185, -22.250168526367197],
            [-56.643167916992184, -22.246568526367195],
            [-56.641767916992187, -22.244068526367197],
            [-56.638167916992188, -22.241468526367196],
            [-56.633867916992187, -22.241168526367197],
            [-56.631767916992189, -22.244668526367196],
            [-56.628467916992186, -22.246568526367195],
            [-56.625467916992186, -22.243268526367196],
            [-56.622367916992189, -22.241968526367195],
            [-56.619467916992186, -22.239068526367195],
            [-56.615267916992181, -22.235868526367195],
            [-56.612167916992185, -22.234268526367195],
            [-56.608967916992185, -22.233668526367197],
            [-56.606467916992187, -22.230868526367196],
            [-56.607167916992182, -22.226368526367196],
            [-56.608567916992186, -22.221168526367197],
            [-56.604267916992185, -22.220368526367196],
            [-56.601367916992189, -22.219768526367197],
            [-56.599167916992187, -22.217268526367196],
            [-56.599167916992187, -22.212668526367196],
            [-56.596667916992189, -22.214868526367198],
            [-56.593667916992189, -22.217368526367196],
            [-56.592067916992185, -22.214268526367196],
            [-56.588367916992183, -22.212268526367197],
            [-56.584267916992189, -22.211268526367196],
            [-56.581467916992182, -22.209868526367195],
            [-56.581467916992182, -22.205668526367198],
            [-56.577067916992185, -22.203168526367197],
            [-56.575467916992181, -22.206868526367195],
            [-56.571367916992187, -22.207368526367198],
            [-56.56886791699219, -22.205668526367198],
            [-56.567667916992185, -22.202068526367196],
            [-56.562967916992186, -22.199568526367198],
            [-56.559767916992186, -22.196668526367198],
            [-56.556567916992186, -22.196068526367196],
            [-56.557167916992185, -22.192668526367196],
            [-56.556867916992189, -22.188768526367195],
            [-56.552867916992184, -22.186568526367196],
            [-56.554367916992184, -22.180768526367196],
            [-56.553667916992183, -22.176868526367198],
            [-56.555867916992185, -22.173668526367198],
            [-56.55616791699218, -22.169268526367198],
            [-56.552267916992186, -22.167568526367198],
            [-56.549267916992186, -22.166368526367197],
            [-56.54746791699219, -22.164168526367195],
            [-56.544567916992186, -22.163068526367198],
            [-56.540567916992188, -22.161668526367198],
            [-56.536667916992187, -22.160568526367197],
            [-56.533667916992187, -22.159468526367196],
            [-56.533667916992187, -22.156468526367195],
            [-56.537067916992186, -22.157268526367197],
            [-56.538367916992186, -22.153768526367195],
            [-56.540267916992185, -22.150868526367198],
            [-56.53946791699218, -22.147168526367196],
            [-56.535967916992185, -22.146568526367197],
            [-56.532567916992186, -22.147568526367195],
            [-56.528967916992187, -22.148168526367197],
            [-56.527867916992186, -22.144068526367196],
            [-56.526167916992186, -22.140368526367197],
            [-56.522867916992183, -22.140768526367197],
            [-56.519667916992184, -22.140468526367197],
            [-56.521467916992187, -22.137668526367197],
            [-56.523967916992184, -22.133468526367196],
            [-56.525967916992187, -22.129968526367197],
            [-56.523667916992181, -22.128068526367198],
            [-56.524467916992187, -22.125168526367197],
            [-56.523667916992181, -22.121568526367195],
            [-56.521867916992186, -22.118268526367196],
            [-56.52006791699219, -22.114968526367196],
            [-56.519067916992185, -22.112168526367196],
            [-56.518667916992186, -22.108868526367196],
            [-56.515167916992183, -22.106668526367198],
            [-56.511767916992184, -22.104568526367196],
            [-56.507767916992186, -22.102368526367197],
            [-56.508467916992188, -22.099168526367198],
            [-56.505567916992185, -22.096268526367197],
            [-56.50166791699219, -22.096968526367196],
            [-56.498867916992182, -22.095568526367195],
            [-56.496067916992189, -22.094168526367195],
            [-56.493267916992181, -22.095568526367195],
            [-56.488667916992185, -22.094468526367198],
            [-56.483567916992186, -22.094868526367197],
            [-56.477467916992182, -22.092668526367195],
            [-56.476467916992185, -22.088468526367198],
            [-56.473367916992188, -22.086768526367198],
            [-56.472667916992187, -22.082968526367196],
            [-56.470267916992185, -22.079968526367196],
            [-56.467267916992185, -22.080768526367198],
            [-56.462667916992189, -22.083568526367195],
            [-56.462067916992183, -22.079668526367197],
            [-56.45726791699218, -22.080468526367195],
            [-56.455767916992187, -22.084368526367196],
            [-56.452967916992186, -22.082868526367196],
            [-56.451267916992187, -22.078468526367196],
            [-56.44786791699218, -22.077868526367197],
            [-56.446667916992183, -22.083168526367196],
            [-56.44216791699219, -22.082668526367197],
            [-56.438467916992181, -22.081868526367195],
            [-56.435767916992184, -22.084768526367196],
            [-56.430867916992185, -22.084768526367196],
            [-56.428567916992186, -22.082168526367198],
            [-56.424667916992185, -22.082268526367198],
            [-56.421167916992189, -22.083968526367197],
            [-56.417867916992186, -22.079568526367197],
            [-56.413967916992185, -22.079668526367197],
            [-56.40976791699218, -22.079668526367197],
            [-56.405867916992186, -22.077968526367197],
            [-56.402267916992187, -22.079968526367196],
            [-56.39906791699218, -22.079368526367197],
            [-56.39506791699219, -22.076068526367198],
            [-56.392967916992184, -22.079068526367195],
            [-56.392667916992181, -22.082168526367198],
            [-56.390767916992189, -22.085368526367198],
            [-56.390667916992186, -22.088968526367196],
            [-56.390167916992183, -22.093068526367198],
            [-56.393967916992182, -22.092568526367195],
            [-56.393767916992189, -22.097068526367195],
            [-56.392567916992185, -22.099868526367196],
            [-56.39006791699218, -22.102068526367198],
            [-56.386367916992185, -22.104168526367197],
            [-56.382067916992185, -22.104668526367195],
            [-56.383267916992182, -22.109168526367196],
            [-56.382067916992185, -22.112468526367195],
            [-56.379067916992184, -22.114768526367197],
            [-56.379567916992187, -22.118068526367196],
            [-56.377967916992183, -22.121568526367195],
            [-56.374367916992185, -22.123768526367197],
            [-56.372467916992186, -22.128268526367197],
            [-56.370167916992187, -22.132668526367198],
            [-56.368367916992185, -22.137468526367197],
            [-56.370167916992187, -22.140768526367197],
            [-56.372767916992188, -22.143468526367197],
            [-56.37166791699218, -22.147568526367195],
            [-56.37336791699218, -22.149868526367197],
            [-56.370867916992182, -22.152368526367198],
            [-56.36866791699218, -22.155968526367197],
            [-56.366367916992189, -22.158368526367195],
            [-56.366167916992183, -22.162568526367195],
            [-56.363967916992181, -22.166768526367196],
            [-56.363667916992185, -22.170068526367196],
            [-56.360267916992186, -22.171468526367196],
            [-56.357467916992185, -22.173968526367197],
            [-56.353467916992187, -22.174468526367196],
            [-56.350567916992183, -22.173868526367198],
            [-56.34926791699219, -22.176468526367195],
            [-56.348367916992188, -22.179468526367195],
            [-56.345267916992185, -22.180768526367196],
            [-56.343667916992189, -22.183568526367196],
            [-56.345867916992184, -22.187168526367195],
            [-56.343467916992182, -22.189668526367196],
            [-56.340167916992186, -22.189368526367197],
            [-56.337967916992184, -22.192968526367196],
            [-56.336267916992185, -22.197968526367195],
            [-56.332667916992186, -22.199268526367195],
            [-56.329967916992189, -22.200668526367195],
            [-56.328167916992186, -22.203868526367195],
            [-56.325267916992189, -22.206868526367195],
            [-56.323467916992186, -22.210368526367198],
            [-56.321267916992184, -22.212868526367195],
            [-56.317667916992185, -22.215168526367197],
            [-56.316267916992189, -22.217868526367198],
            [-56.313067916992182, -22.216168526367195],
            [-56.310767916992184, -22.214068526367196],
            [-56.30516791699219, -22.215368526367197],
            [-56.300567916992186, -22.217268526367196],
            [-56.298567916992184, -22.220068526367196],
            [-56.295267916992188, -22.220668526367195],
            [-56.292567916992184, -22.222868526367197],
            [-56.28946791699218, -22.225068526367195],
            [-56.285267916992183, -22.225868526367197],
            [-56.284167916992189, -22.230668526367197],
            [-56.280067916992181, -22.231768526367198],
            [-56.277567916992183, -22.233568526367197],
            [-56.275967916992187, -22.236468526367197],
            [-56.273367916992186, -22.234468526367195],
            [-56.26976791699218, -22.234368526367195],
            [-56.266767916992187, -22.235768526367195],
            [-56.264867916992188, -22.238068526367197],
            [-56.261367916992185, -22.239168526367198],
            [-56.258467916992188, -22.237568526367195],
            [-56.255267916992182, -22.237468526367195],
            [-56.252367916992185, -22.238568526367196],
            [-56.249067916992189, -22.237468526367195],
            [-56.245467916992183, -22.236668526367197],
            [-56.241867916992184, -22.235668526367196],
            [-56.237567916992184, -22.237168526367196],
            [-56.23496791699219, -22.241468526367196],
            [-56.232767916992188, -22.245168526367195],
            [-56.232567916992181, -22.249468526367195],
            [-56.232567916992181, -22.254168526367195],
            [-56.23196791699219, -22.257368526367195],
            [-56.229567916992181, -22.259268526367197],
            [-56.227067916992183, -22.261868526367195],
            [-56.224467916992182, -22.263268526367195],
            [-56.221467916992182, -22.263968526367197],
            [-56.21956791699219, -22.267068526367197],
            [-56.215367916992186, -22.269268526367195],
            [-56.213367916992183, -22.272968526367197],
            [-56.210067916992188, -22.277068526367195],
            [-56.206167916992186, -22.276168526367197],
            [-56.201067916992187, -22.276868526367195],
            [-56.197967916992184, -22.275668526367195],
            [-56.194467916992181, -22.272768526367198],
            [-56.189867916992185, -22.274068526367195],
            [-56.188767916992184, -22.278368526367196],
            [-56.187267916992184, -22.280968526367197],
            [-56.185267916992188, -22.278368526367196],
            [-56.181567916992186, -22.278768526367195],
            [-56.178267916992183, -22.279868526367196],
            [-56.174467916992185, -22.280368526367198],
            [-56.173867916992187, -22.285068526367198],
            [-56.169767916992186, -22.285268526367197],
            [-56.165667916992184, -22.286668526367198],
            [-56.161667916992187, -22.285968526367196],
            [-56.16006791699219, -22.282868526367196],
            [-56.157567916992186, -22.284768526367195],
            [-56.154467916992189, -22.282568526367196],
            [-56.150967916992187, -22.281968526367198],
            [-56.147867916992183, -22.283468526367198],
            [-56.144367916992181, -22.284768526367195],
            [-56.139867916992188, -22.285268526367197],
            [-56.136067916992189, -22.285268526367197],
            [-56.133467916992188, -22.283368526367195],
            [-56.130167916992185, -22.283868526367197],
            [-56.128767916992189, -22.280068526367195],
            [-56.127767916992184, -22.275368526367195],
            [-56.124467916992188, -22.274268526367198],
            [-56.123567916992187, -22.271568526367197],
            [-56.119367916992189, -22.271868526367196],
            [-56.116067916992186, -22.273768526367196],
            [-56.112767916992183, -22.274068526367195],
            [-56.110067916992186, -22.275868526367198],
            [-56.105867916992182, -22.277568526367197],
            [-56.101667916992184, -22.278968526367198],
            [-56.097367916992184, -22.281768526367195],
            [-56.094767916992183, -22.283768526367197],
            [-56.091967916992189, -22.285068526367198],
            [-56.087267916992189, -22.284768526367195],
            [-56.083267916992185, -22.283068526367195],
            [-56.079367916992183, -22.284868526367195],
            [-56.073967916992189, -22.286368526367195],
            [-56.069367916992185, -22.285868526367196],
            [-56.067367916992183, -22.283468526367198],
            [-56.065967916992186, -22.280368526367198],
            [-56.063367916992185, -22.277368526367198],
            [-56.060167916992185, -22.276468526367196],
            [-56.055867916992185, -22.276768526367196],
            [-56.051967916992183, -22.277968526367196],
            [-56.04746791699219, -22.278468526367195],
            [-56.043867916992184, -22.282068526367198],
            [-56.041167916992187, -22.283168526367195],
            [-56.037767916992181, -22.282268526367197],
            [-56.03476791699218, -22.281668526367195],
            [-56.032767916992185, -22.284468526367196],
            [-56.029467916992189, -22.284868526367195],
            [-56.025067916992185, -22.285568526367197],
            [-56.019667916992184, -22.286768526367197],
            [-56.016267916992184, -22.288068526367198],
            [-56.012467916992186, -22.287068526367197],
            [-56.010267916992184, -22.290268526367196],
            [-56.011067916992189, -22.293168526367197],
            [-56.007467916992184, -22.292868526367197],
            [-56.00436791699218, -22.293068526367197],
            [-56.002967916992183, -22.289568526367198],
            [-56.001567916992187, -22.286368526367195],
            [-55.999167916992185, -22.284468526367196],
            [-55.995267916992184, -22.282568526367196],
            [-55.990767916992183, -22.281468526367195],
            [-55.986667916992189, -22.280968526367197],
            [-55.982867916992184, -22.279168526367197],
            [-55.979767916992188, -22.280868526367197],
            [-55.97696791699218, -22.284268526367196],
            [-55.973767916992188, -22.287868526367195],
            [-55.970567916992181, -22.290868526367195],
            [-55.965167916992186, -22.289168526367195],
            [-55.96156791699218, -22.289768526367197],
            [-55.958167916992181, -22.290268526367196],
            [-55.95286791699219, -22.291168526367198],
            [-55.949067916992185, -22.291668526367197],
            [-55.947067916992182, -22.287468526367196],
            [-55.946067916992185, -22.284468526367196],
            [-55.942767916992182, -22.282668526367196],
            [-55.93886791699218, -22.282068526367198],
            [-55.936267916992186, -22.280468526367198],
            [-55.933567916992189, -22.279568526367196],
            [-55.930767916992181, -22.278968526367198],
            [-55.927267916992186, -22.279068526367197],
            [-55.92246791699219, -22.279868526367196],
            [-55.918167916992189, -22.278968526367198],
            [-55.914867916992186, -22.278468526367195],
            [-55.911967916992182, -22.279568526367196],
            [-55.909467916992185, -22.281268526367196],
            [-55.906167916992189, -22.283468526367198],
            [-55.902667916992186, -22.284868526367195],
            [-55.898167916992186, -22.284868526367195],
            [-55.892367916992185, -22.283768526367197],
            [-55.887467916992186, -22.282568526367196],
            [-55.883467916992188, -22.282568526367196],
            [-55.879467916992184, -22.282368526367197],
            [-55.874367916992185, -22.283068526367195],
            [-55.86466791699219, -22.282868526367196],
            [-55.860267916992186, -22.282568526367196],
            [-55.85666791699218, -22.280668526367197],
            [-55.852067916992183, -22.280968526367197],
            [-55.849467916992182, -22.285968526367196],
            [-55.843067916992183, -22.287068526367197],
            [-55.841667916992186, -22.289568526367198],
            [-55.839067916992185, -22.294168526367198],
            [-55.835367916992183, -22.296068526367197],
            [-55.838667916992186, -22.297468526367197],
            [-55.838467916992187, -22.300868526367196],
            [-55.835867916992186, -22.304668526367195],
            [-55.836167916992181, -22.308368526367197],
            [-55.835067916992188, -22.312168526367195],
            [-55.832367916992183, -22.314568526367196],
            [-55.828767916992184, -22.315968526367197],
            [-55.832367916992183, -22.321068526367196],
            [-55.831467916992182, -22.324868526367197],
            [-55.828467916992182, -22.331468526367196],
            [-55.825767916992184, -22.335968526367196],
            [-55.824267916992184, -22.339768526367195],
            [-55.820967916992188, -22.345068526367196],
            [-55.814667916992185, -22.345568526367195],
            [-55.809767916992186, -22.349568526367197],
            [-55.80916791699218, -22.352468526367197],
            [-55.804367916992184, -22.354668526367195],
            [-55.800767916992186, -22.355268526367198],
            [-55.798567916992184, -22.364668526367197],
            [-55.799667916992185, -22.368568526367195],
            [-55.803367916992187, -22.370868526367197],
            [-55.791367916992186, -22.380468526367196],
            [-55.791067916992183, -22.388468526367195],
            [-55.788167916992187, -22.388968526367197],
            [-55.784267916992185, -22.386468526367196],
            [-55.779767916992185, -22.384268526367197],
            [-55.776567916992185, -22.385368526367195],
            [-55.766467916992184, -22.383768526367195],
            [-55.757067916992185, -22.386068526367197],
            [-55.747667916992185, -22.384868526367196],
            [-55.745767916992186, -22.389068526367197],
            [-55.741967916992181, -22.395368526367196],
            [-55.745767916992186, -22.403668526367195],
            [-55.740867916992187, -22.407068526367198],
            [-55.737167916992185, -22.410968526367196],
            [-55.737167916992185, -22.413968526367196],
            [-55.73966791699219, -22.423668526367198],
            [-55.736367916992187, -22.429068526367196],
            [-55.736367916992187, -22.435168526367196],
            [-55.733867916992182, -22.441568526367195],
            [-55.736667916992189, -22.444568526367195],
            [-55.737167916992185, -22.447468526367196],
            [-55.738267916992186, -22.454368526367197],
            [-55.736867916992182, -22.459668526367196],
            [-55.741067916992186, -22.461268526367196],
            [-55.743367916992185, -22.463668526367197],
            [-55.748067916992184, -22.464468526367195],
            [-55.746567916992184, -22.469468526367198],
            [-55.751967916992186, -22.474568526367197],
            [-55.751267916992184, -22.481668526367198],
            [-55.747667916992185, -22.483068526367198],
            [-55.746867916992187, -22.487468526367195],
            [-55.747367916992189, -22.492268526367198],
            [-55.747667916992185, -22.495768526367197],
            [-55.747167916992183, -22.505468526367196],
            [-55.740767916992183, -22.515468526367197],
            [-55.740267916992181, -22.519368526367195],
            [-55.737267916992181, -22.527368526367198],
            [-55.735567916992181, -22.530868526367197],
            [-55.733067916992184, -22.534568526367195],
            [-55.730667916992189, -22.538868526367196],
            [-55.728867916992186, -22.542768526367198],
            [-55.725667916992187, -22.548168526367196],
            [-55.723467916992185, -22.551668526367198],
            [-55.718467916992182, -22.553968526367196],
            [-55.713967916992189, -22.557168526367196],
            [-55.708467916992184, -22.559368526367198],
            [-55.705467916992184, -22.560468526367195],
            [-55.698467916992186, -22.563268526367196],
            [-55.69486791699218, -22.572468526367196],
            [-55.69486791699218, -22.577568526367195],
            [-55.688467916992181, -22.581068526367197],
            [-55.683267916992186, -22.587568526367196],
            [-55.676567916992184, -22.591968526367197],
            [-55.669567916992186, -22.593968526367195],
            [-55.666667916992182, -22.598468526367196],
            [-55.660967916992185, -22.602368526367197],
            [-55.651467916992189, -22.607768526367195],
            [-55.64906791699218, -22.610368526367196],
            [-55.642967916992184, -22.612168526367196],
            [-55.636767916992184, -22.617768526367197],
            [-55.63136791699219, -22.620468526367198],
            [-55.629467916992184, -22.622768526367196],
            [-55.627667916992181, -22.624968526367198],
            [-55.625267916992186, -22.628668526367196],
            [-55.624467916992188, -22.633468526367196],
            [-55.624067916992189, -22.637068526367198],
            [-55.623567916992187, -22.641068526367196],
            [-55.622467916992186, -22.647068526367196],
            [-55.622167916992183, -22.651568526367196],
            [-55.618067916992189, -22.653468526367195],
            [-55.614367916992187, -22.655068526367195],
            [-55.613667916992185, -22.659868526367195],
            [-55.617167916992187, -22.664768526367197],
            [-55.618267916992181, -22.670368526367195],
            [-55.617267916992184, -22.677568526367196],
            [-55.609267916992181, -22.689068526367198],
            [-55.613567916992181, -22.692768526367196],
            [-55.61466791699219, -22.699568526367198],
            [-55.613067916992186, -22.704568526367197],
            [-55.613867916992184, -22.711468526367195],
            [-55.613367916992189, -22.716968526367197],
            [-55.616967916992181, -22.725068526367195],
            [-55.615867916992187, -22.735868526367195],
            [-55.61296791699219, -22.738868526367195],
            [-55.616367916992189, -22.740068526367196],
            [-55.620067916992184, -22.744668526367196],
            [-55.619367916992189, -22.749368526367196],
            [-55.620867916992182, -22.754068526367195],
            [-55.624067916992189, -22.755668526367195],
            [-55.625967916992181, -22.759168526367198],
            [-55.622967916992181, -22.762068526367198],
            [-55.619967916992181, -22.764568526367196],
            [-55.618267916992181, -22.767668526367196],
            [-55.622167916992183, -22.767168526367197],
            [-55.627667916992181, -22.766568526367195],
            [-55.630567916992185, -22.767168526367197],
            [-55.633167916992186, -22.769368526367195],
            [-55.635967916992186, -22.770768526367196],
            [-55.636767916992184, -22.773768526367196],
            [-55.636267916992182, -22.777868526367197],
            [-55.638967916992186, -22.782568526367196],
            [-55.642167916992186, -22.785368526367197],
            [-55.64476791699218, -22.788168526367198],
            [-55.647867916992183, -22.789968526367197],
            [-55.650967916992187, -22.792068526367196],
            [-55.650067916992185, -22.795368526367195],
            [-55.649467916992187, -22.800368526367198],
            [-55.650867916992183, -22.804368526367195],
            [-55.655067916992181, -22.807768526367198],
            [-55.653367916992181, -22.811868526367196],
            [-55.653367916992181, -22.816568526367195],
            [-55.655167916992184, -22.819868526367195],
            [-55.658167916992184, -22.824068526367196],
            [-55.660867916992188, -22.827368526367195],
            [-55.660267916992183, -22.830768526367194],
            [-55.659167916992189, -22.835868526367197],
            [-55.658967916992182, -22.840968526367199],
            [-55.663067916992183, -22.847068526367195],
            [-55.665667916992184, -22.852468526367197],
            [-55.663067916992183, -22.857168526367197],
            [-55.663167916992187, -22.860268526367197],
            [-55.660867916992188, -22.863268526367197],
            [-55.659867916992184, -22.868068526367196],
            [-55.661667916992187, -22.874368526367196],
            [-55.661667916992187, -22.877968526367198],
            [-55.661167916992184, -22.882168526367195],
            [-55.65676791699218, -22.884968526367196],
            [-55.653667916992184, -22.884868526367196],
            [-55.650867916992183, -22.887168526367198],
            [-55.649467916992187, -22.890968526367196],
            [-55.64906791699218, -22.897868526367198],
            [-55.651167916992186, -22.902568526367197],
            [-55.650467916992184, -22.910968526367196],
            [-55.647967916992187, -22.912868526367198],
            [-55.647067916992185, -22.921768526367195],
            [-55.645467916992189, -22.924368526367196],
            [-55.651567916992185, -22.931068526367199],
            [-55.650467916992184, -22.934468526367198],
            [-55.647067916992185, -22.937368526367194],
            [-55.644267916992185, -22.941268526367196],
            [-55.647567916992188, -22.949868526367197],
            [-55.643967916992182, -22.950768526367199],
            [-55.638267916992184, -22.950468526367196],
            [-55.635767916992187, -22.955368526367195],
            [-55.637967916992181, -22.958968526367194],
            [-55.636767916992184, -22.964768526367195],
            [-55.633567916992185, -22.967668526367198],
            [-55.631567916992182, -22.970068526367196],
            [-55.628767916992189, -22.975068526367195],
            [-55.62636791699218, -22.977868526367196],
            [-55.621867916992187, -22.984468526367195],
            [-55.624067916992189, -22.988368526367196],
            [-55.627367916992185, -22.988568526367196],
            [-55.633867916992187, -22.991668526367196],
            [-55.633267916992182, -22.997268526367197],
            [-55.628467916992186, -22.998268526367198],
            [-55.63136791699219, -23.000768526367196],
            [-55.632767916992186, -23.008268526367196],
            [-55.634967916992181, -23.013968526367197],
            [-55.639367916992185, -23.025868526367198],
            [-55.635167916992188, -23.025468526367199],
            [-55.63266791699219, -23.028068526367196],
            [-55.628767916992189, -23.032368526367197],
            [-55.623767916992186, -23.032868526367196],
            [-55.619467916992186, -23.036368526367198],
            [-55.617267916992184, -23.038168526367194],
            [-55.614167916992187, -23.041068526367198],
            [-55.611367916992187, -23.048868526367194],
            [-55.614467916992183, -23.053868526367197],
            [-55.611967916992185, -23.059368526367194],
            [-55.611067916992184, -23.066368526367196],
            [-55.610767916992188, -23.069668526367195],
            [-55.611367916992187, -23.076768526367196],
            [-55.617267916992184, -23.081768526367195],
            [-55.612467916992188, -23.085968526367196],
            [-55.611967916992185, -23.090468526367196],
            [-55.604667916992184, -23.098668526367199],
            [-55.599567916992186, -23.104168526367197],
            [-55.596467916992182, -23.116468526367196],
            [-55.595867916992184, -23.119368526367197],
            [-55.598167916992182, -23.121268526367196],
            [-55.596967916992185, -23.126368526367195],
            [-55.595867916992184, -23.130468526367196],
            [-55.596367916992186, -23.142368526367196],
            [-55.59726791699218, -23.146868526367196],
            [-55.596767916992185, -23.152568526367197],
            [-55.591967916992189, -23.151568526367196],
            [-55.584267916992189, -23.148668526367196],
            [-55.573467916992186, -23.143268526367194],
            [-55.567767916992182, -23.143968526367196],
            [-55.56416791699219, -23.146568526367197],
            [-55.56086791699218, -23.148268526367197],
            [-55.558267916992186, -23.150068526367196],
            [-55.554767916992184, -23.152968526367196],
            [-55.549267916992186, -23.155168526367198],
            [-55.546067916992186, -23.155968526367197],
            [-55.541767916992185, -23.156168526367196],
            [-55.540967916992187, -23.159868526367198],
            [-55.541167916992187, -23.163368526367197],
            [-55.54446791699219, -23.166968526367196],
            [-55.540667916992184, -23.169268526367198],
            [-55.539967916992182, -23.174968526367195],
            [-55.543667916992185, -23.177468526367196],
            [-55.543067916992186, -23.180768526367196],
            [-55.538867916992189, -23.184468526367198],
            [-55.535867916992188, -23.186068526367194],
            [-55.532767916992185, -23.190268526367198],
            [-55.528067916992185, -23.192968526367196],
            [-55.525167916992189, -23.194868526367195],
            [-55.523167916992186, -23.197768526367199],
            [-55.526167916992186, -23.208168526367196],
            [-55.530067916992181, -23.211568526367195],
            [-55.534167916992189, -23.216768526367197],
            [-55.540267916992185, -23.220868526367198],
            [-55.54276791699219, -23.219268526367195],
            [-55.546167916992189, -23.220168526367196],
            [-55.54246791699218, -23.226368526367196],
            [-55.540367916992182, -23.233968526367196],
            [-55.536667916992187, -23.235768526367195],
            [-55.53846791699219, -23.241168526367197],
            [-55.540367916992182, -23.244068526367194],
            [-55.535867916992188, -23.251568526367194],
            [-55.530167916992184, -23.252668526367195],
            [-55.526167916992186, -23.256868526367196],
            [-55.522567916992188, -23.259668526367197],
            [-55.527067916992181, -23.263568526367195],
            [-55.529867916992188, -23.266468526367198],
            [-55.532267916992183, -23.268568526367197],
            [-55.536167916992184, -23.270368526367196],
            [-55.539567916992183, -23.272868526367198],
            [-55.543967916992187, -23.274068526367195],
            [-55.546667916992185, -23.275068526367196],
            [-55.548967916992183, -23.278368526367196],
            [-55.553067916992184, -23.279468526367197],
            [-55.554767916992184, -23.282868526367196],
            [-55.554667916992187, -23.288168526367194],
            [-55.551867916992187, -23.296668526367196],
            [-55.549167916992189, -23.303668526367197],
            [-55.555867916992185, -23.316268526367196],
            [-55.554667916992187, -23.320568526367197],
            [-55.550867916992189, -23.322368526367196],
            [-55.546067916992186, -23.323568526367197],
            [-55.538367916992186, -23.329568526367197],
            [-55.534467916992185, -23.333268526367196],
            [-55.53206791699219, -23.341168526367198],
            [-55.527367916992183, -23.342568526367195],
            [-55.523967916992184, -23.349268526367197],
            [-55.520667916992181, -23.352268526367197],
            [-55.519367916992181, -23.365068526367196],
            [-55.51066791699219, -23.366868526367199],
            [-55.50636791699219, -23.370268526367198],
            [-55.50466791699219, -23.374068526367196],
            [-55.50436791699218, -23.378468526367197],
            [-55.509567916992182, -23.393468526367194],
            [-55.513267916992184, -23.400368526367195],
            [-55.515467916992186, -23.409468526367196],
            [-55.520367916992186, -23.416468526367197],
            [-55.522667916992184, -23.419968526367196],
            [-55.52406791699218, -23.423268526367195],
            [-55.527067916992181, -23.427168526367197],
            [-55.529167916992186, -23.429768526367198],
            [-55.538167916992187, -23.438868526367195],
            [-55.544667916992182, -23.449268526367199],
            [-55.543067916992186, -23.451768526367196],
            [-55.540567916992188, -23.455468526367198],
            [-55.541767916992185, -23.464768526367195],
            [-55.543167916992189, -23.474168526367194],
            [-55.544967916992185, -23.477268526367197],
            [-55.553867916992189, -23.475668526367194],
            [-55.561967916992188, -23.476168526367196],
            [-55.559367916992187, -23.483168526367194],
            [-55.559067916992184, -23.488668526367196],
            [-55.552467916992185, -23.488668526367196],
            [-55.549667916992185, -23.496168526367196],
            [-55.548167916992185, -23.504468526367198],
            [-55.547567916992186, -23.511268526367196],
            [-55.546667916992185, -23.522568526367195],
            [-55.540367916992182, -23.524568526367197],
            [-55.542567916992184, -23.529868526367196],
            [-55.540567916992188, -23.533668526367194],
            [-55.538967916992185, -23.536768526367197],
            [-55.537367916992181, -23.540568526367196],
            [-55.534867916992184, -23.551068526367196],
            [-55.535967916992185, -23.554968526367198],
            [-55.532267916992183, -23.559968526367197],
            [-55.530167916992184, -23.563068526367196],
            [-55.526467916992189, -23.567368526367197],
            [-55.531167916992189, -23.571568526367194],
            [-55.528367916992181, -23.580168526367196],
            [-55.530967916992182, -23.584368526367196],
            [-55.538167916992187, -23.591968526367197],
            [-55.538367916992186, -23.600868526367197],
            [-55.536267916992188, -23.609968526367197],
            [-55.535967916992185, -23.617168526367195],
            [-55.537867916992184, -23.621168526367196],
            [-55.53976791699219, -23.625168526367197],
            [-55.537067916992186, -23.628068526367194],
            [-55.53206791699219, -23.630268526367196],
            [-55.530367916992184, -23.627768526367198],
            [-55.520667916992181, -23.625868526367196],
            [-55.511067916992189, -23.623268526367198],
            [-55.509967916992181, -23.631268526367194],
            [-55.498067916992184, -23.638268526367199],
            [-55.493267916992181, -23.637668526367197],
            [-55.490367916992184, -23.642368526367196],
            [-55.483967916992185, -23.640168526367198],
            [-55.481767916992183, -23.644368526367195],
            [-55.478067916992188, -23.646868526367196],
            [-55.474567916992186, -23.650368526367195],
            [-55.474567916992186, -23.658068526367195],
            [-55.47396791699218, -23.661168526367199],
            [-55.474767916992185, -23.667768526367198],
            [-55.475067916992188, -23.674768526367195],
            [-55.469167916992184, -23.676868526367194],
            [-55.465967916992184, -23.681068526367199],
            [-55.462267916992189, -23.684468526367198],
            [-55.463367916992183, -23.692668526367196],
            [-55.463967916992189, -23.698768526367196],
            [-55.466467916992187, -23.703468526367196],
            [-55.464267916992185, -23.708268526367196],
            [-55.462367916992186, -23.712268526367197],
            [-55.456867916992181, -23.714068526367196],
            [-55.454967916992189, -23.716568526367197],
            [-55.452067916992185, -23.715068526367197],
            [-55.449367916992188, -23.712268526367197],
            [-55.443467916992184, -23.712568526367196],
            [-55.441067916992182, -23.710068526367195],
            [-55.439867916992185, -23.712868526367195],
            [-55.436267916992186, -23.716668526367194],
            [-55.442467916992186, -23.722868526367197],
            [-55.441967916992184, -23.728168526367199],
            [-55.44656791699218, -23.734968526367197],
            [-55.44516791699219, -23.738268526367197],
            [-55.448567916992189, -23.742168526367195],
            [-55.446667916992183, -23.747968526367195],
            [-55.443167916992181, -23.752968526367198],
            [-55.442767916992182, -23.755968526367198],
            [-55.447167916992186, -23.760168526367195],
            [-55.443167916992181, -23.765668526367197],
            [-55.433767916992181, -23.770968526367199],
            [-55.433367916992182, -23.775168526367196],
            [-55.432267916992188, -23.782368526367197],
            [-55.430767916992181, -23.786968526367197],
            [-55.43016791699219, -23.790068526367197],
            [-55.435167916992185, -23.793168526367197],
            [-55.436867916992185, -23.798068526367196],
            [-55.441067916992182, -23.803368526367194],
            [-55.444067916992182, -23.809168526367195],
            [-55.448467916992186, -23.814168526367197],
            [-55.450667916992188, -23.816368526367196],
            [-55.45256791699218, -23.819868526367195],
            [-55.447167916992186, -23.822568526367196],
            [-55.441567916992184, -23.829268526367194],
            [-55.441267916992189, -23.832568526367197],
            [-55.440467916992183, -23.839868526367198],
            [-55.440167916992181, -23.845968526367194],
            [-55.440267916992184, -23.853568526367198],
            [-55.43886791699218, -23.857168526367197],
            [-55.439667916992185, -23.862568526367198],
            [-55.439367916992182, -23.866068526367194],
            [-55.437267916992184, -23.869468526367196],
            [-55.435767916992184, -23.874068526367196],
            [-55.436267916992186, -23.877768526367198],
            [-55.436667916992185, -23.882068526367195],
            [-55.436967916992188, -23.885368526367195],
            [-55.437767916992186, -23.890668526367197],
            [-55.438067916992182, -23.894568526367195],
            [-55.438767916992184, -23.899368526367198],
            [-55.439467916992186, -23.904868526367196],
            [-55.442367916992183, -23.907368526367197],
            [-55.44486791699218, -23.909768526367195],
            [-55.445267916992186, -23.914268526367195],
            [-55.44516791699219, -23.917268526367195],
            [-55.44216791699219, -23.918368526367196],
            [-55.438067916992182, -23.919168526367194],
            [-55.435167916992185, -23.922168526367194],
            [-55.432767916992184, -23.923868526367194],
            [-55.427767916992181, -23.926168526367196],
            [-55.429667916992187, -23.931568526367194],
            [-55.432667916992187, -23.940568526367194],
            [-55.426967916992183, -23.939668526367196],
            [-55.420367916992184, -23.943068526367199],
            [-55.416767916992185, -23.948468526367197],
            [-55.417867916992186, -23.953168526367197],
            [-55.419967916992185, -23.957268526367194],
            [-55.414267916992188, -23.964568526367195],
            [-55.405167916992184, -23.964868526367198],
            [-55.401767916992185, -23.970368526367196],
            [-55.402867916992186, -23.974168526367194],
            [-55.396867916992186, -23.975068526367195],
            [-55.394267916992185, -23.973468526367196],
            [-55.390167916992183, -23.970968526367194],
            [-55.387467916992186, -23.967568526367195],
            [-55.378267916992186, -23.970568526367195],
            [-55.379067916992184, -23.975268526367195],
            [-55.375467916992186, -23.979768526367195],
            [-55.371567916992184, -23.979168526367197],
            [-55.366667916992185, -23.981368526367199],
            [-55.363867916992184, -23.982168526367197],
            [-55.358367916992187, -23.989168526367195],
            [-55.352467916992182, -23.989968526367196],
            [-55.34926791699219, -23.993368526367199],
            [-55.345367916992188, -23.994468526367196],
            [-55.341167916992184, -23.994468526367196],
            [-55.335967916992189, -23.992968526367196],
            [-55.333967916992187, -23.988568526367196],
            [-55.329867916992185, -23.983068526367198],
            [-55.325667916992188, -23.976968526367195],
            [-55.322367916992185, -23.967568526367195],
            [-55.317667916992185, -23.962868526367195],
            [-55.31216791699218, -23.965068526367197],
            [-55.303367916992187, -23.965868526367196],
            [-55.29746791699219, -23.970568526367195],
            [-55.287267916992185, -23.975868526367197],
            [-55.283167916992184, -23.977568526367197],
            [-55.277667916992186, -23.984468526367195],
            [-55.27436791699219, -23.984768526367198],
            [-55.270967916992184, -23.985368526367196],
            [-55.269667916992184, -23.988268526367197],
            [-55.266267916992184, -23.989368526367194],
            [-55.263167916992188, -23.987168526367196],
            [-55.26206791699218, -23.991668526367196],
            [-55.260167916992188, -23.994068526367194],
            [-55.256867916992185, -23.996168526367196],
            [-55.253067916992187, -23.997668526367196],
            [-55.247967916992181, -24.000168526367197],
            [-55.245867916992182, -23.998268526367198],
            [-55.243067916992189, -24.002368526367196],
            [-55.240467916992188, -24.005968526367198],
            [-55.235267916992186, -24.006568526367197],
            [-55.232867916992184, -24.009968526367196],
            [-55.228067916992188, -24.013468526367198],
            [-55.223867916992184, -24.012468526367197],
            [-55.218667916992189, -24.009668526367197],
            [-55.215167916992186, -24.005768526367198],
            [-55.211467916992184, -24.004068526367199],
            [-55.206867916992181, -24.003568526367197],
            [-55.204367916992183, -24.000768526367196],
            [-55.201767916992182, -23.998068526367199],
            [-55.199067916992185, -23.995068526367199],
            [-55.198567916992189, -23.987968526367197],
            [-55.192767916992182, -23.986768526367197],
            [-55.187367916992187, -23.988368526367196],
            [-55.18116791699218, -23.988668526367196],
            [-55.178567916992186, -23.993668526367195],
            [-55.175567916992186, -23.993268526367196],
            [-55.165667916992184, -23.988968526367195],
            [-55.159867916992184, -23.987568526367198],
            [-55.157867916992188, -23.985368526367196],
            [-55.155867916992186, -23.983068526367198],
            [-55.149467916992187, -23.988568526367196],
            [-55.145667916992181, -23.988668526367196],
            [-55.141267916992184, -23.987568526367198],
            [-55.137967916992181, -23.986868526367196],
            [-55.128767916992189, -23.983668526367197],
            [-55.126267916992184, -23.979468526367196],
            [-55.12466791699218, -23.975968526367197],
            [-55.122667916992185, -23.978668526367194],
            [-55.118367916992185, -23.977068526367198],
            [-55.107167916992182, -23.961968526367194],
            [-55.10696791699219, -23.965168526367197],
            [-55.099467916992182, -23.969868526367197],
            [-55.097767916992183, -23.973068526367197],
            [-55.094167916992184, -23.974968526367196],
            [-55.092967916992187, -23.977568526367197],
            [-55.090167916992186, -23.982568526367196],
            [-55.084267916992189, -23.982768526367195],
            [-55.080367916992188, -23.984668526367194],
            [-55.074267916992184, -23.984768526367198],
            [-55.071667916992183, -23.987468526367195],
            [-55.063267916992189, -23.992568526367194],
            [-55.059967916992186, -23.991868526367199],
            [-55.052567916992189, -23.986168526367194],
            [-55.051167916992185, -23.980668526367197],
            [-55.045067916992181, -23.983568526367197],
            [-55.039567916992183, -23.980268526367198],
            [-55.035867916992188, -23.977068526367198],
            [-55.032267916992183, -23.973468526367196],
            [-55.027267916992187, -23.973068526367197],
            [-55.023467916992189, -23.972868526367197],
            [-55.019267916992185, -23.971968526367196],
            [-55.005167916992185, -23.962668526367196],
            [-54.998567916992187, -23.957568526367197],
            [-54.995167916992187, -23.958968526367194],
            [-54.990867916992187, -23.962268526367197],
            [-54.986067916992184, -23.962068526367197],
            [-54.981067916992188, -23.960968526367196],
            [-54.976367916992189, -23.957668526367197],
            [-54.972667916992187, -23.960168526367198],
            [-54.96956791699219, -23.964368526367195],
            [-54.966667916992186, -23.966168526367198],
            [-54.955367916992188, -23.965368526367197],
            [-54.951367916992183, -23.964768526367195],
            [-54.948567916992189, -23.963668526367197],
            [-54.944167916992185, -23.964768526367195],
            [-54.941567916992184, -23.966568526367197],
            [-54.938067916992182, -23.962868526367195],
            [-54.933767916992181, -23.963168526367198],
            [-54.926067916992181, -23.960968526367196],
            [-54.926167916992185, -23.954668526367197],
            [-54.926367916992184, -23.950668526367195],
            [-54.928067916992184, -23.947368526367196],
            [-54.929967916992183, -23.943168526367195],
            [-54.923967916992183, -23.936268526367197],
            [-54.926467916992181, -23.932268526367196],
            [-54.927267916992186, -23.929368526367199],
            [-54.926867916992187, -23.924768526367195],
            [-54.913167916992187, -23.922168526367194],
            [-54.913367916992186, -23.917068526367196],
            [-54.91146791699218, -23.914468526367195],
            [-54.908867916992186, -23.911668526367194],
            [-54.903367916992181, -23.909568526367195],
            [-54.90076791699218, -23.912268526367196],
            [-54.893567916992183, -23.910668526367196],
            [-54.891067916992185, -23.901468526367196],
            [-54.885967916992186, -23.897368526367195],
            [-54.88106791699218, -23.898468526367196],
            [-54.880467916992188, -23.901768526367196],
            [-54.877067916992189, -23.905168526367198],
            [-54.87336791699218, -23.909168526367196],
            [-54.869167916992183, -23.906168526367196],
            [-54.862167916992185, -23.907568526367196],
            [-54.859367916992184, -23.905368526367198],
            [-54.850267916992181, -23.907868526367196],
            [-54.849167916992187, -23.902568526367197],
            [-54.849767916992185, -23.896868526367196],
            [-54.84726791699218, -23.894568526367195],
            [-54.844467916992187, -23.892468526367196],
            [-54.841967916992189, -23.888968526367197],
            [-54.835467916992187, -23.884068526367194],
            [-54.832067916992187, -23.884568526367197],
            [-54.82956791699219, -23.887068526367194],
            [-54.826767916992182, -23.888168526367195],
            [-54.822767916992184, -23.889868526367195],
            [-54.818767916992186, -23.890168526367198],
            [-54.81416791699219, -23.890368526367197],
            [-54.810767916992184, -23.883568526367196],
            [-54.807567916992184, -23.881268526367194],
            [-54.805767916992181, -23.879068526367199],
            [-54.799667916992185, -23.877068526367196],
            [-54.798967916992183, -23.873868526367197],
            [-54.796367916992182, -23.870868526367197],
            [-54.791367916992186, -23.868268526367196],
            [-54.786967916992182, -23.870268526367198],
            [-54.779867916992188, -23.869668526367196],
            [-54.776467916992189, -23.868068526367196],
            [-54.774567916992183, -23.865368526367199],
            [-54.763967916992186, -23.866168526367197],
            [-54.761867916992188, -23.858068526367198],
            [-54.75906791699218, -23.856668526367194],
            [-54.754567916992187, -23.858668526367197],
            [-54.749167916992185, -23.860868526367199],
            [-54.74836791699218, -23.866068526367194],
            [-54.745267916992184, -23.868668526367195],
            [-54.736467916992183, -23.865868526367194],
            [-54.73326791699219, -23.869668526367196],
            [-54.721167916992187, -23.865468526367195],
            [-54.710767916992182, -23.864668526367197],
            [-54.706267916992189, -23.864468526367197],
            [-54.699967916992186, -23.859968526367197],
            [-54.69786791699218, -23.857768526367195],
            [-54.698467916992186, -23.850568526367198],
            [-54.698467916992186, -23.841968526367197],
            [-54.691567916992184, -23.833768526367194],
            [-54.686567916992189, -23.832168526367198],
            [-54.684067916992184, -23.829668526367197],
            [-54.680867916992185, -23.828268526367197],
            [-54.677767916992181, -23.822468526367196],
            [-54.66946791699219, -23.812468526367198],
            [-54.668867916992184, -23.816268526367196],
            [-54.663867916992189, -23.819268526367196],
            [-54.658067916992181, -23.819568526367195],
            [-54.653467916992184, -23.820668526367196],
            [-54.646767916992189, -23.833668526367198],
            [-54.638267916992184, -23.830468526367198],
            [-54.631267916992186, -23.829968526367196],
            [-54.624867916992187, -23.835068526367195],
            [-54.616567916992182, -23.835068526367195],
            [-54.608867916992182, -23.851468526367196],
            [-54.60496791699218, -23.851968526367195],
            [-54.601367916992189, -23.852068526367198],
            [-54.598467916992185, -23.849968526367196],
            [-54.59626791699219, -23.846168526367194],
            [-54.593767916992185, -23.843368526367197],
            [-54.587867916992181, -23.838468526367194],
            [-54.584067916992183, -23.836968526367194],
            [-54.583167916992181, -23.841768526367197],
            [-54.581467916992182, -23.845868526367198],
            [-54.575667916992188, -23.848068526367197],
            [-54.570667916992186, -23.850368526367198],
            [-54.566067916992182, -23.853668526367194],
            [-54.565967916992186, -23.858968526367196],
            [-54.567767916992182, -23.862568526367198],
            [-54.570267916992186, -23.867968526367196],
            [-54.56856791699218, -23.874368526367196],
            [-54.561567916992189, -23.872168526367197],
            [-54.556867916992189, -23.870068526367199],
            [-54.552967916992188, -23.870768526367197],
            [-54.548267916992188, -23.869468526367196],
            [-54.543367916992182, -23.868068526367196],
            [-54.538867916992189, -23.869068526367194],
            [-54.53506791699219, -23.873068526367199],
            [-54.531167916992189, -23.871968526367198],
            [-54.526467916992189, -23.872668526367196],
            [-54.522967916992187, -23.873568526367194],
            [-54.520467916992189, -23.875468526367197],
            [-54.518267916992187, -23.877768526367198],
            [-54.517167916992186, -23.881368526367197],
            [-54.514867916992188, -23.885168526367195],
            [-54.507167916992188, -23.882368526367195],
            [-54.502767916992184, -23.884368526367197],
            [-54.497367916992189, -23.884668526367197],
            [-54.492967916992185, -23.884968526367196],
            [-54.491567916992182, -23.887468526367197],
            [-54.486667916992189, -23.893968526367196],
            [-54.487567916992184, -23.900368526367195],
            [-54.487267916992181, -23.903668526367198],
            [-54.48026791699219, -23.901168526367197],
            [-54.477767916992185, -23.897868526367198],
            [-54.470567916992181, -23.897868526367198],
            [-54.466467916992187, -23.899068526367195],
            [-54.462867916992181, -23.900668526367198],
            [-54.457367916992183, -23.899368526367198],
            [-54.451867916992185, -23.900668526367198],
            [-54.44656791699218, -23.903368526367196],
            [-54.443267916992184, -23.904268526367197],
            [-54.440167916992181, -23.904268526367197],
            [-54.437667916992183, -23.906168526367196],
            [-54.434767916992186, -23.909168526367196],
            [-54.43116791699218, -23.911168526367199],
            [-54.426867916992187, -23.914468526367195],
            [-54.427467916992185, -23.926668526367195],
            [-54.432967916992183, -23.928668526367197],
            [-54.429667916992187, -23.929768526367198],
            [-54.424967916992188, -23.930868526367199],
            [-54.421967916992187, -23.940468526367198],
            [-54.418967916992187, -23.943468526367198],
            [-54.41646791699219, -23.946068526367196],
            [-54.414267916992188, -23.953268526367197],
            [-54.413867916992189, -23.958568526367195],
            [-54.410367916992186, -23.961468526367199],
            [-54.404867916992188, -23.962368526367197],
            [-54.401267916992182, -23.964768526367195],
            [-54.397367916992181, -23.965068526367197],
            [-54.394267916992185, -23.965168526367197],
            [-54.392667916992181, -23.969068526367195],
            [-54.391467916992184, -23.971968526367196],
            [-54.389667916992181, -23.974768526367196],
            [-54.387867916992185, -23.979968526367195],
            [-54.386767916992184, -23.982768526367195],
            [-54.384167916992183, -23.985768526367195],
            [-54.379967916992186, -23.987568526367198],
            [-54.37666791699219, -23.990768526367198],
            [-54.374167916992185, -23.989368526367194],
            [-54.36866791699218, -23.992968526367196],
            [-54.365567916992184, -23.997668526367196],
            [-54.361467916992183, -23.999968526367198],
            [-54.357767916992188, -24.002968526367198],
            [-54.35496791699218, -24.004968526367197],
            [-54.35196791699218, -24.007668526367198],
            [-54.34756791699219, -24.004668526367198],
            [-54.34626791699219, -24.001368526367195],
            [-54.341967916992189, -24.002768526367198],
            [-54.338167916992184, -24.001668526367197],
            [-54.336467916992184, -24.004868526367197],
            [-54.32786791699219, -24.005168526367196],
            [-54.323267916992187, -24.004668526367198],
            [-54.320467916992186, -24.007968526367197],
            [-54.31886791699219, -24.011768526367199],
            [-54.321367916992187, -24.019268526367195],
            [-54.321667916992183, -24.023368526367197],
            [-54.317767916992182, -24.028468526367199],
            [-54.317067916992187, -24.031968526367194],
            [-54.313567916992184, -24.034168526367196],
            [-54.311967916992188, -24.038068526367198],
            [-54.309867916992189, -24.046368526367196],
            [-54.304067916992182, -24.050868526367196],
            [-54.301567916992184, -24.054768526367198],
            [-54.297767916992186, -24.056668526367197],
            [-54.293167916992189, -24.061268526367197],
            [-54.289967916992182, -24.067468526367197],
            [-54.285167916992187, -24.067768526367196],
            [-54.288367916992186, -24.070668526367196],
            [-54.293167916992189, -24.077668526367198],
            [-54.298967916992183, -24.085768526367197],
            [-54.302267916992186, -24.090168526367197],
            [-54.305567916992189, -24.094168526367199],
            [-54.311667916992185, -24.101168526367196],
            [-54.314067916992187, -24.103968526367197],
            [-54.32316791699219, -24.109768526367198],
            [-54.333267916992185, -24.115868526367194],
            [-54.339767916992187, -24.122668526367196],
            [-54.343367916992186, -24.133068526367197],
            [-54.344467916992187, -24.140668526367197],
            [-54.344867916992186, -24.144368526367195],
            [-54.345567916992181, -24.157268526367197],
            [-54.343967916992185, -24.162768526367195],
            [-54.340467916992182, -24.168868526367199],
            [-54.334067916992183, -24.179068526367196],
            [-54.329867916992185, -24.187968526367197],
            [-54.327467916992184, -24.196368526367195],
            [-54.327067916992185, -24.210168526367198],
            [-54.328167916992186, -24.217568526367195],
            [-54.329967916992189, -24.224168526367194],
            [-54.330667916992184, -24.230268526367198],
            [-54.32786791699219, -24.237968526367197],
            [-54.32486791699219, -24.244668526367196],
            [-54.322467916992181, -24.251568526367194],
            [-54.317067916992187, -24.255268526367196],
            [-54.309667916992183, -24.260968526367197],
            [-54.297867916992189, -24.270668526367196],
            [-54.289167916992184, -24.279468526367197],
            [-54.283167916992184, -24.285668526367196],
            [-54.277367916992183, -24.291368526367194],
            [-54.271567916992183, -24.306868526367197],
            [-54.267567916992185, -24.321868526367197],
            [-54.261267916992182, -24.338768526367197],
            [-54.257767916992186, -24.355268526367198],
            [-54.258167916992186, -24.365768526367198],
            [-54.260767916992187, -24.375568526367196],
            [-54.262667916992186, -24.378068526367194],
            [-54.269867916992183, -24.390368526367197],
            [-54.276567916992185, -24.402568526367197],
            [-54.278967916992187, -24.405368526367198],
            [-54.285267916992183, -24.412568526367195],
            [-54.292867916992186, -24.424468526367196],
            [-54.295567916992184, -24.427568526367196],
            [-54.301567916992184, -24.434168526367195],
            [-54.306967916992186, -24.437968526367197],
            [-54.310767916992184, -24.440568526367194],
            [-54.318167916992181, -24.450668526367195],
            [-54.322167916992186, -24.457568526367197],
            [-54.323567916992189, -24.460068526367195],
            [-54.32656791699219, -24.465968526367199],
            [-54.32756791699218, -24.473468526367196],
            [-54.328967916992184, -24.483968526367196],
            [-54.331067916992183, -24.493368526367199],
            [-54.333167916992181, -24.498868526367197],
            [-54.334267916992189, -24.502168526367196],
            [-54.336167916992181, -24.507968526367197],
            [-54.335467916992187, -24.517868526367195],
            [-54.332967916992189, -24.527668526367197],
            [-54.329267916992187, -24.537068526367197],
            [-54.324067916992185, -24.551568526367198],
            [-54.31986791699218, -24.568868526367197],
            [-54.319367916992185, -24.576068526367195],
            [-54.317967916992188, -24.590568526367196],
            [-54.318167916992181, -24.594468526367194],
            [-54.318767916992186, -24.606768526367198],
            [-54.321367916992187, -24.622968526367195],
            [-54.322767916992184, -24.630568526367199],
            [-54.32486791699219, -24.642368526367196],
            [-54.324967916992186, -24.645768526367196],
            [-54.326067916992187, -24.653168526367196],
            [-54.326867916992185, -24.659468526367196],
            [-54.327067916992185, -24.662768526367195],
            [-54.327167916992181, -24.666168526367194],
            [-54.32756791699218, -24.674168526367197],
            [-54.331067916992183, -24.690468526367198],
            [-54.33226791699218, -24.694568526367195],
            [-54.334267916992189, -24.701068526367195],
            [-54.341667916992186, -24.714068526367196],
            [-54.349167916992187, -24.723168526367196],
            [-54.353467916992187, -24.729668526367199],
            [-54.35526791699219, -24.732268526367196],
            [-54.362467916992188, -24.743268526367196],
            [-54.369967916992181, -24.756268526367194],
            [-54.375167916992183, -24.760168526367195],
            [-54.38136791699219, -24.765968526367196],
            [-54.389867916992188, -24.777268526367195],
            [-54.390967916992182, -24.786968526367197],
            [-54.391467916992184, -24.790368526367196],
            [-54.393167916992184, -24.800868526367196],
            [-54.394867916992183, -24.811268526367197],
            [-54.400367916992181, -24.832068526367195],
            [-54.405167916992184, -24.845268526367196],
            [-54.410267916992183, -24.858568526367197],
            [-54.415367916992182, -24.873868526367197],
            [-54.416367916992186, -24.877068526367196],
            [-54.423167916992185, -24.898268526367197],
            [-54.430267916992186, -24.919168526367194],
            [-54.43586791699218, -24.938068526367196],
            [-54.436967916992188, -24.941368526367196],
            [-54.436967916992188, -24.949068526367196],
            [-54.438467916992181, -24.962668526367196],
            [-54.440467916992183, -24.973668526367199],
            [-54.442067916992187, -24.980268526367198],
            [-54.442767916992182, -24.983868526367196],
            [-54.44656791699218, -24.999168526367196],
            [-54.447467916992181, -25.004568526367194],
            [-54.449267916992184, -25.014068526367197],
            [-54.451467916992186, -25.022068526367196],
            [-54.454367916992183, -25.031668526367199],
            [-54.456067916992183, -25.039568526367198],
            [-54.459567916992185, -25.046068526367197],
            [-54.46156791699218, -25.051168526367196],
            [-54.462867916992181, -25.057168526367196],
            [-54.460667916992186, -25.060568526367199],
            [-54.45756791699219, -25.067368526367197],
            [-54.45756791699219, -25.074268526367199],
            [-54.458467916992184, -25.081468526367196],
            [-54.46156791699218, -25.090068526367197],
            [-54.461967916992187, -25.096368526367197],
            [-54.459367916992186, -25.101968526367195],
            [-54.450667916992188, -25.108668526367197],
            [-54.445767916992182, -25.113868526367199],
            [-54.441267916992189, -25.121168526367196],
            [-54.437667916992183, -25.127068526367196],
            [-54.43446791699219, -25.132968526367197],
            [-54.431667916992183, -25.140068526367195],
            [-54.43016791699219, -25.143768526367197],
            [-54.428267916992183, -25.146568526367197],
            [-54.424767916992181, -25.153168526367196],
            [-54.424467916992185, -25.158068526367195],
            [-54.426867916992187, -25.165368526367196],
            [-54.428367916992187, -25.167768526367198],
            [-54.430767916992181, -25.170068526367196],
            [-54.434067916992184, -25.170768526367198],
            [-54.43716791699218, -25.169768526367196],
            [-54.443267916992184, -25.167768526367198],
            [-54.447767916992184, -25.168668526367199],
            [-54.45286791699219, -25.176168526367196],
            [-54.46156791699218, -25.186568526367196],
            [-54.466767916992183, -25.195968526367196],
            [-54.473767916992188, -25.211968526367194],
            [-54.478067916992188, -25.225668526367194],
            [-54.479567916992181, -25.230368526367194],
            [-54.483967916992185, -25.249768526367198],
            [-54.487567916992184, -25.263868526367197],
            [-54.491167916992183, -25.277868526367197],
            [-54.496167916992185, -25.287068526367197],
            [-54.501567916992187, -25.297168526367194],
            [-54.513267916992184, -25.311268526367197],
            [-54.514567916992185, -25.313868526367195],
            [-54.525367916992181, -25.331468526367196],
            [-54.539167916992184, -25.347368526367198],
            [-54.544267916992183, -25.351668526367199],
            [-54.554967916992183, -25.360068526367197],
            [-54.561367916992182, -25.366968526367195],
            [-54.571267916992184, -25.378368526367197],
            [-54.582867916992186, -25.391568526367195],
            [-54.586467916992184, -25.396568526367197],
            [-54.588167916992184, -25.403368526367196],
            [-54.589367916992188, -25.407268526367197],
            [-54.590967916992184, -25.411968526367197],
            [-54.592267916992185, -25.414768526367197],
            [-54.593667916992189, -25.417568526367198],
            [-54.595667916992184, -25.420368526367199],
            [-54.597067916992188, -25.423368526367199],
            [-54.598367916992188, -25.426368526367199],
            [-54.599467916992182, -25.429468526367195],
            [-54.600667916992187, -25.433368526367197],
            [-54.602467916992182, -25.437968526367197],
            [-54.604267916992185, -25.440868526367197],
            [-54.606067916992188, -25.443468526367198],
            [-54.609167916992185, -25.446068526367196],
            [-54.61296791699219, -25.448868526367196],
            [-54.616167916992183, -25.450268526367196],
            [-54.619367916992189, -25.451068526367195],
            [-54.620067916992184, -25.454568526367197],
            [-54.619767916992188, -25.459368526367196],
            [-54.619667916992185, -25.463968526367196],
            [-54.617767916992186, -25.469268526367195],
            [-54.615067916992189, -25.472868526367197],
            [-54.612167916992185, -25.475568526367198],
            [-54.608067916992184, -25.478868526367194],
            [-54.60526791699219, -25.480868526367196],
            [-54.603067916992188, -25.483568526367197],
            [-54.601667916992184, -25.486968526367196],
            [-54.60196791699218, -25.491068526367194],
            [-54.602767916992185, -25.495868526367197],
            [-54.60196791699218, -25.501568526367194],
            [-54.60096791699219, -25.505268526367196],
            [-54.600867916992186, -25.510268526367199],
            [-54.601167916992182, -25.515968526367196],
            [-54.601467916992185, -25.520368526367196],
            [-54.600667916992187, -25.524268526367194],
            [-54.599567916992186, -25.528068526367196],
            [-54.598167916992182, -25.531268526367196],
            [-54.596367916992186, -25.533968526367197],
            [-54.594867916992186, -25.536968526367197],
            [-54.59456791699219, -25.540568526367196],
            [-54.594767916992183, -25.543868526367199],
            [-54.59456791699219, -25.548368526367199],
            [-54.594467916992187, -25.551568526367198],
            [-54.59426791699218, -25.555068526367194],
            [-54.594867916992186, -25.558768526367196],
            [-54.595167916992182, -25.562468526367198],
            [-54.594867916992186, -25.566568526367199],
            [-54.594867916992186, -25.570968526367196],
            [-54.59426791699218, -25.575268526367196],
            [-54.594167916992184, -25.578968526367198],
            [-54.594167916992184, -25.583468526367199],
            [-54.593767916992185, -25.588968526367196],
            [-54.592267916992185, -25.592268526367196],
            [-54.588667916992186, -25.592568526367195],
            [-54.584867916992181, -25.592668526367198],
            [-54.581167916992186, -25.592268526367196],
            [-54.577067916992185, -25.591168526367198],
            [-54.571667916992183, -25.591168526367198],
            [-54.568067916992185, -25.590968526367199],
            [-54.56416791699219, -25.590068526367197],
            [-54.561567916992189, -25.588668526367197],
            [-54.557967916992183, -25.587568526367196],
            [-54.553567916992186, -25.587868526367195],
            [-54.549667916992185, -25.590068526367197],
            [-54.54576791699219, -25.592568526367195],
            [-54.542267916992188, -25.594868526367197],
            [-54.53846791699219, -25.596268526367197],
            [-54.534867916992184, -25.598768526367195],
            [-54.533867916992186, -25.604768526367195],
            [-54.534867916992184, -25.609668526367194],
            [-54.536267916992188, -25.612268526367195],
            [-54.537767916992181, -25.616168526367197],
            [-54.535967916992185, -25.620768526367197],
            [-54.53346791699218, -25.623268526367198],
            [-54.528067916992185, -25.627668526367195],
            [-54.523967916992184, -25.628868526367196],
            [-54.520767916992185, -25.626968526367197],
            [-54.51706791699219, -25.624668526367195],
            [-54.513767916992187, -25.621968526367198],
            [-54.511367916992185, -25.618568526367199],
            [-54.505967916992184, -25.617168526367195],
            [-54.502967916992183, -25.614468526367197],
            [-54.49966791699218, -25.614668526367197],
            [-54.495467916992183, -25.616568526367196],
            [-54.492567916992186, -25.618268526367196],
            [-54.48966791699219, -25.620868526367197],
            [-54.485867916992184, -25.624368526367196],
            [-54.483167916992187, -25.627068526367196],
            [-54.48026791699219, -25.628468526367197],
            [-54.476667916992184, -25.631068526367194],
            [-54.473467916992185, -25.633568526367196],
            [-54.470167916992182, -25.635668526367198],
            [-54.468167916992186, -25.637868526367196],
            [-54.465567916992185, -25.641068526367196],
            [-54.461267916992185, -25.644868526367198],
            [-54.458667916992184, -25.648668526367196],
            [-54.455967916992186, -25.651768526367196],
            [-54.454067916992187, -25.654068526367197],
            [-54.450767916992184, -25.657768526367196],
            [-54.448267916992187, -25.661268526367195],
            [-54.446667916992183, -25.663668526367196],
            [-54.44516791699219, -25.666368526367194],
            [-54.443767916992186, -25.669668526367197],
            [-54.44356791699218, -25.672568526367197],
            [-54.443467916992184, -25.675468526367197],
            [-54.442467916992186, -25.678268526367198],
            [-54.441967916992184, -25.681968526367196],
            [-54.441267916992189, -25.685468526367195],
            [-54.440167916992181, -25.688368526367196],
            [-54.438767916992184, -25.691268526367196],
            [-54.437467916992183, -25.694068526367197],
            [-54.435767916992184, -25.696368526367195],
            [-54.431667916992183, -25.697068526367197],
            [-54.426867916992187, -25.695568526367197],
            [-54.423867916992187, -25.692768526367196],
            [-54.423567916992184, -25.689868526367196],
            [-54.423867916992187, -25.685168526367196],
            [-54.425567916992186, -25.679468526367195],
            [-54.426167916992185, -25.675868526367196],
            [-54.426867916992187, -25.669968526367196],
            [-54.426867916992187, -25.666968526367196],
            [-54.426367916992184, -25.663868526367196],
            [-54.425567916992186, -25.659868526367198],
            [-54.423167916992185, -25.656268526367196],
            [-54.41946791699219, -25.653168526367196],
            [-54.415567916992188, -25.650868526367198],
            [-54.412267916992185, -25.648668526367196],
            [-54.408067916992181, -25.645768526367196],
            [-54.403367916992181, -25.644068526367196],
            [-54.400867916992183, -25.642068526367197],
            [-54.396867916992186, -25.639668526367196],
            [-54.39476791699218, -25.636468526367196],
            [-54.392167916992186, -25.633068526367197],
            [-54.389867916992188, -25.628268526367197],
            [-54.38906791699219, -25.625268526367197],
            [-54.388767916992187, -25.621168526367196],
            [-54.38906791699219, -25.617768526367197],
            [-54.389967916992184, -25.614768526367197],
            [-54.390767916992189, -25.611368526367194],
            [-54.391067916992185, -25.606968526367197],
            [-54.390667916992186, -25.603568526367198],
            [-54.387867916992185, -25.598968526367194],
            [-54.38406791699218, -25.596468526367197],
            [-54.37966791699219, -25.595268526367196],
            [-54.375567916992182, -25.593968526367199],
            [-54.371567916992184, -25.594468526367194],
            [-54.36866791699218, -25.596368526367197],
            [-54.365267916992181, -25.599968526367196],
            [-54.363267916992186, -25.602468526367197],
            [-54.35996791699219, -25.605568526367197],
            [-54.356467916992187, -25.607568526367196],
            [-54.352867916992182, -25.608168526367194],
            [-54.349867916992181, -25.606968526367197],
            [-54.345367916992188, -25.604968526367195],
            [-54.342267916992185, -25.601268526367196],
            [-54.339867916992183, -25.597368526367198],
            [-54.337567916992185, -25.593368526367197],
            [-54.335867916992186, -25.589268526367196],
            [-54.334867916992181, -25.585368526367198],
            [-54.332967916992189, -25.580368526367195],
            [-54.330967916992186, -25.575668526367195],
            [-54.328967916992184, -25.571868526367197],
            [-54.326267916992187, -25.569668526367195],
            [-54.32286791699218, -25.567468526367197],
            [-54.318767916992186, -25.565468526367198],
            [-54.314067916992187, -25.564868526367196],
            [-54.306067916992184, -25.563468526367195],
            [-54.302767916992181, -25.561968526367195],
            [-54.298667916992187, -25.559668526367197],
            [-54.296067916992186, -25.557268526367196],
            [-54.292267916992188, -25.555268526367197],
            [-54.288167916992187, -25.554368526367199],
            [-54.282567916992186, -25.555768526367196],
            [-54.279467916992189, -25.557968526367198],
            [-54.276567916992185, -25.562168526367195],
            [-54.273667916992181, -25.567768526367196],
            [-54.266067916992185, -25.581768526367195],
            [-54.263967916992186, -25.584868526367195],
            [-54.260167916992188, -25.590168526367197],
            [-54.257767916992186, -25.593468526367197],
            [-54.253767916992189, -25.596368526367197],
            [-54.250467916992186, -25.596768526367196],
            [-54.245567916992186, -25.597368526367198],
            [-54.242167916992187, -25.597268526367195],
            [-54.238067916992186, -25.595868526367198],
            [-54.235567916992181, -25.593768526367196],
            [-54.234167916992185, -25.590168526367197],
            [-54.234167916992185, -25.587268526367197],
            [-54.23466791699218, -25.582968526367196],
            [-54.235267916992186, -25.579268526367194],
            [-54.236067916992184, -25.576068526367195],
            [-54.236767916992186, -25.573268526367194],
            [-54.237167916992185, -25.568468526367198],
            [-54.234467916992187, -25.564368526367197],
            [-54.229667916992184, -25.562968526367197],
            [-54.224767916992185, -25.564068526367194],
            [-54.221367916992186, -25.565968526367197],
            [-54.217967916992187, -25.568768526367194],
            [-54.214767916992187, -25.570968526367196],
            [-54.209367916992186, -25.575168526367197],
            [-54.202667916992183, -25.580668526367198],
            [-54.199067916992185, -25.582168526367198],
            [-54.193467916992184, -25.583768526367194],
            [-54.186267916992186, -25.585368526367198],
            [-54.182267916992188, -25.585368526367198],
            [-54.175267916992183, -25.583268526367196],
            [-54.173567916992184, -25.579268526367194],
            [-54.173367916992184, -25.575768526367199],
            [-54.174667916992185, -25.572968526367198],
            [-54.176167916992185, -25.570268526367194],
            [-54.179067916992182, -25.566668526367195],
            [-54.183667916992185, -25.564568526367196],
            [-54.187667916992183, -25.563068526367196],
            [-54.192767916992182, -25.562368526367194],
            [-54.195967916992188, -25.561568526367196],
            [-54.198867916992185, -25.560568526367199],
            [-54.202967916992186, -25.558768526367196],
            [-54.205467916992184, -25.556668526367197],
            [-54.207867916992186, -25.553268526367198],
            [-54.208267916992185, -25.548568526367198],
            [-54.20756791699219, -25.544768526367196],
            [-54.206267916992189, -25.541668526367197],
            [-54.204367916992183, -25.539468526367195],
            [-54.199967916992186, -25.536468526367194],
            [-54.197067916992182, -25.534868526367198],
            [-54.191567916992184, -25.534168526367196],
            [-54.186867916992185, -25.535368526367197],
            [-54.181567916992186, -25.537868526367198],
            [-54.175867916992189, -25.540368526367196],
            [-54.173067916992181, -25.541468526367197],
            [-54.167267916992188, -25.543068526367197],
            [-54.163067916992183, -25.542568526367198],
            [-54.158667916992187, -25.539468526367195],
            [-54.155367916992184, -25.535968526367196],
            [-54.152567916992183, -25.531768526367195],
            [-54.14906791699218, -25.526968526367199],
            [-54.143967916992182, -25.520668526367196],
            [-54.141867916992183, -25.518468526367194],
            [-54.137467916992186, -25.514668526367196],
            [-54.13436791699219, -25.511568526367196],
            [-54.12936791699218, -25.508468526367196],
            [-54.12466791699218, -25.504968526367197],
            [-54.120267916992184, -25.501568526367194],
            [-54.115767916992183, -25.498368526367194],
            [-54.110067916992186, -25.495468526367198],
            [-54.103567916992183, -25.494368526367197],
            [-54.097867916992186, -25.495868526367197],
            [-54.094467916992187, -25.498868526367197],
            [-54.093067916992183, -25.501868526367197],
            [-54.092667916992184, -25.509868526367196],
            [-54.093767916992185, -25.514368526367196],
            [-54.095367916992188, -25.518468526367194],
            [-54.096467916992182, -25.521268526367194],
            [-54.099167916992187, -25.525468526367199],
            [-54.10196791699218, -25.530668526367194],
            [-54.10526791699219, -25.535268526367197],
            [-54.108967916992185, -25.539968526367197],
            [-54.111367916992187, -25.542768526367198],
            [-54.115567916992184, -25.547768526367197],
            [-54.118267916992181, -25.551068526367196],
            [-54.119967916992181, -25.553968526367196],
            [-54.121267916992181, -25.557168526367196],
            [-54.123067916992184, -25.562768526367197],
            [-54.12336791699218, -25.568068526367199],
            [-54.121867916992187, -25.575968526367198],
            [-54.119467916992186, -25.583468526367199],
            [-54.117767916992186, -25.586568526367195],
            [-54.113267916992186, -25.597568526367198],
            [-54.110067916992186, -25.604768526367195],
            [-54.108567916992186, -25.608268526367198],
            [-54.105367916992186, -25.614068526367198],
            [-54.101767916992188, -25.617968526367196],
            [-54.098467916992185, -25.619168526367197],
            [-54.093667916992189, -25.619068526367194],
            [-54.091167916992184, -25.616068526367194],
            [-54.090167916992186, -25.611968526367196],
            [-54.088967916992189, -25.606468526367195],
            [-54.088967916992189, -25.602268526367197],
            [-54.089567916992181, -25.595868526367198],
            [-54.089567916992181, -25.590668526367196],
            [-54.089767916992187, -25.585468526367194],
            [-54.087267916992189, -25.579568526367197],
            [-54.085667916992186, -25.574568526367194],
            [-54.084567916992185, -25.569868526367195],
            [-54.084067916992183, -25.566068526367197],
            [-54.083467916992184, -25.562168526367195],
            [-54.079967916992189, -25.558568526367196],
            [-54.074267916992184, -25.559068526367199],
            [-54.071367916992187, -25.559868526367197],
            [-54.066267916992189, -25.564968526367196],
            [-54.062767916992186, -25.572368526367196],
            [-54.060467916992181, -25.577468526367198],
            [-54.05616791699218, -25.582568526367197],
            [-54.051167916992185, -25.585068526367195],
            [-54.045267916992188, -25.585068526367195],
            [-54.041767916992185, -25.583168526367196],
            [-54.036267916992188, -25.577668526367198],
            [-54.032567916992186, -25.571368526367195],
            [-54.028667916992184, -25.566568526367199],
            [-54.024767916992189, -25.563768526367198],
            [-54.020167916992186, -25.563268526367196],
            [-54.015767916992189, -25.564168526367197],
            [-54.010767916992187, -25.566568526367199],
            [-54.006767916992189, -25.570768526367196],
            [-54.005467916992188, -25.574268526367199],
            [-54.003467916992186, -25.579068526367195],
            [-53.99966791699218, -25.582268526367194],
            [-53.994667916992185, -25.584368526367196],
            [-53.990867916992187, -25.586768526367194],
            [-53.988867916992184, -25.589268526367196],
            [-53.985767916992188, -25.590568526367196],
            [-53.981067916992188, -25.591968526367197],
            [-53.97726791699219, -25.594468526367194],
            [-53.974167916992187, -25.597368526367198],
            [-53.97096791699218, -25.600668526367194],
            [-53.973667916992184, -25.604268526367196],
            [-53.977767916992185, -25.604568526367196],
            [-53.978467916992187, -25.607768526367195],
            [-53.977367916992186, -25.612468526367195],
            [-53.974867916992181, -25.614968526367196],
            [-53.971967916992185, -25.613668526367196],
            [-53.967567916992181, -25.612468526367195],
            [-53.965367916992186, -25.616568526367196],
            [-53.965167916992186, -25.620068526367199],
            [-53.964067916992185, -25.623368526367194],
            [-53.962267916992189, -25.626668526367197],
            [-53.961467916992184, -25.631268526367194],
            [-53.963467916992187, -25.635368526367195],
            [-53.963367916992183, -25.639868526367195],
            [-53.960967916992189, -25.643468526367194],
            [-53.956467916992182, -25.645068526367197],
            [-53.953767916992184, -25.646768526367197],
            [-53.951267916992187, -25.645368526367196],
            [-53.948567916992189, -25.641068526367196],
            [-53.947467916992181, -25.637168526367198],
            [-53.946667916992183, -25.634268526367194],
            [-53.945267916992186, -25.631368526367197],
            [-53.94816791699219, -25.629968526367197],
            [-53.950167916992186, -25.627168526367196],
            [-53.953167916992186, -25.624468526367195],
            [-53.950767916992184, -25.621968526367198],
            [-53.948567916992189, -25.619068526367194],
            [-53.948467916992186, -25.615268526367196],
            [-53.94786791699218, -25.611768526367197],
            [-53.942767916992182, -25.611468526367197],
            [-53.941967916992184, -25.614468526367197],
            [-53.94186791699218, -25.618668526367195],
            [-53.938767916992184, -25.621068526367196],
            [-53.935467916992181, -25.621668526367195],
            [-53.931867916992189, -25.622468526367197],
            [-53.927267916992186, -25.621368526367196],
            [-53.924367916992182, -25.618368526367199],
            [-53.920867916992187, -25.616868526367199],
            [-53.916667916992182, -25.617268526367198],
            [-53.915967916992187, -25.622168526367197],
            [-53.918567916992188, -25.626268526367198],
            [-53.920667916992187, -25.629168526367195],
            [-53.918867916992184, -25.632368526367195],
            [-53.915567916992188, -25.629368526367195],
            [-53.91446791699218, -25.632768526367194],
            [-53.913067916992183, -25.635368526367195],
            [-53.90976791699218, -25.635468526367198],
            [-53.908367916992184, -25.632368526367195],
            [-53.904567916992185, -25.629868526367197],
            [-53.901767916992185, -25.627668526367195],
            [-53.898667916992181, -25.625468526367197],
            [-53.895767916992185, -25.623368526367194],
            [-53.89206791699219, -25.622668526367196],
            [-53.88906791699219, -25.625568526367196],
            [-53.891567916992187, -25.628368526367197],
            [-53.892567916992185, -25.632168526367195],
            [-53.893167916992184, -25.635168526367195],
            [-53.894367916992181, -25.638568526367195],
            [-53.894567916992187, -25.642868526367195],
            [-53.89036791699219, -25.643268526367194],
            [-53.886467916992189, -25.644368526367195],
            [-53.883567916992185, -25.642868526367195],
            [-53.880267916992182, -25.640968526367196],
            [-53.876967916992186, -25.644268526367195],
            [-53.878467916992186, -25.647068526367196],
            [-53.881667916992185, -25.646868526367196],
            [-53.88406791699218, -25.649268526367194],
            [-53.884867916992185, -25.652668526367197],
            [-53.88706791699218, -25.655168526367198],
            [-53.889967916992184, -25.657768526367196],
            [-53.888567916992187, -25.661168526367199],
            [-53.883867916992187, -25.658368526367198],
            [-53.880267916992182, -25.656268526367196],
            [-53.877067916992189, -25.654568526367196],
            [-53.873267916992184, -25.656268526367196],
            [-53.869467916992186, -25.657868526367196],
            [-53.865567916992184, -25.658468526367194],
            [-53.862467916992188, -25.658968526367197],
            [-53.860767916992188, -25.663068526367198],
            [-53.858867916992182, -25.667768526367198],
            [-53.856367916992184, -25.670368526367199],
            [-53.855667916992189, -25.673968526367197],
            [-53.858967916992185, -25.678268526367198],
            [-53.856367916992184, -25.682268526367196],
            [-53.851467916992185, -25.683768526367196],
            [-53.847067916992188, -25.685268526367196],
            [-53.843167916992186, -25.688768526367198],
            [-53.84726791699218, -25.689868526367196],
            [-53.849167916992187, -25.691968526367198],
            [-53.854267916992185, -25.691568526367199],
            [-53.853667916992187, -25.696368526367195],
            [-53.850867916992186, -25.700668526367195],
            [-53.85096791699219, -25.704868526367196],
            [-53.853167916992184, -25.706768526367195],
            [-53.856767916992183, -25.706168526367197],
            [-53.85826791699219, -25.702468526367198],
            [-53.862167916992185, -25.699268526367199],
            [-53.866567916992182, -25.696268526367199],
            [-53.87196791699219, -25.695168526367198],
            [-53.869367916992189, -25.698168526367198],
            [-53.867167916992187, -25.700268526367196],
            [-53.865467916992188, -25.702868526367197],
            [-53.867467916992183, -25.706468526367196],
            [-53.871267916992181, -25.706768526367195],
            [-53.875467916992186, -25.706768526367195],
            [-53.878767916992189, -25.708168526367196],
            [-53.875867916992185, -25.711568526367195],
            [-53.871867916992187, -25.713468526367194],
            [-53.868267916992181, -25.714068526367196],
            [-53.869667916992185, -25.718668526367196],
            [-53.865367916992184, -25.717868526367198],
            [-53.861367916992187, -25.718168526367194],
            [-53.859367916992184, -25.722768526367197],
            [-53.85996791699219, -25.726768526367195],
            [-53.863267916992186, -25.730668526367197],
            [-53.863967916992181, -25.735268526367197],
            [-53.865867916992187, -25.740068526367196],
            [-53.86466791699219, -25.744668526367196],
            [-53.860267916992186, -25.744668526367196],
            [-53.856767916992183, -25.743868526367194],
            [-53.853067916992188, -25.742568526367194],
            [-53.848767916992188, -25.742168526367195],
            [-53.846667916992189, -25.745168526367195],
            [-53.84426791699218, -25.746668526367195],
            [-53.839767916992187, -25.747968526367195],
            [-53.835467916992187, -25.749368526367196],
            [-53.834367916992186, -25.752368526367196],
            [-53.837667916992189, -25.752468526367196],
            [-53.841167916992184, -25.752068526367196],
            [-53.841167916992184, -25.756868526367196],
            [-53.840667916992189, -25.760668526367198],
            [-53.843667916992189, -25.759368526367197],
            [-53.84726791699218, -25.757968526367197],
            [-53.849767916992185, -25.760468526367198],
            [-53.852067916992183, -25.763168526367195],
            [-53.855367916992186, -25.765768526367197],
            [-53.852567916992186, -25.768668526367197],
            [-53.848167916992182, -25.768968526367196],
            [-53.843467916992182, -25.769368526367195],
            [-53.839467916992184, -25.772068526367196],
            [-53.83686791699219, -25.773968526367199],
            [-53.834367916992186, -25.776168526367197],
            [-53.83226791699218, -25.778768526367195],
            [-53.830767916992187, -25.781468526367199],
            [-53.83256791699219, -25.784868526367198],
            [-53.83656791699218, -25.787068526367197],
            [-53.840967916992184, -25.790568526367196],
            [-53.837267916992189, -25.791768526367196],
            [-53.833767916992187, -25.791768526367196],
            [-53.830167916992181, -25.791368526367194],
            [-53.827367916992188, -25.790268526367196],
            [-53.82286791699218, -25.792168526367199],
            [-53.821767916992187, -25.796368526367196],
            [-53.822367916992185, -25.799968526367195],
            [-53.826367916992183, -25.802768526367196],
            [-53.82786791699219, -25.807168526367196],
            [-53.823567916992189, -25.810168526367196],
            [-53.819567916992185, -25.813068526367196],
            [-53.820267916992186, -25.816368526367196],
            [-53.82316791699219, -25.815468526367198],
            [-53.826767916992182, -25.813468526367195],
            [-53.831567916992185, -25.813268526367196],
            [-53.835467916992187, -25.814968526367196],
            [-53.837667916992189, -25.817068526367194],
            [-53.836467916992184, -25.820568526367197],
            [-53.835667916992186, -25.824668526367198],
            [-53.840067916992183, -25.826168526367198],
            [-53.843767916992185, -25.828468526367196],
            [-53.845367916992188, -25.831768526367195],
            [-53.848767916992188, -25.835468526367194],
            [-53.849867916992181, -25.840168526367197],
            [-53.847767916992183, -25.842068526367196],
            [-53.843367916992186, -25.839768526367195],
            [-53.840367916992186, -25.841968526367197],
            [-53.838967916992189, -25.845568526367195],
            [-53.836267916992185, -25.848068526367197],
            [-53.834367916992186, -25.850368526367198],
            [-53.835667916992186, -25.853468526367195],
            [-53.838767916992182, -25.855968526367196],
            [-53.843067916992183, -25.856968526367197],
            [-53.842067916992185, -25.859968526367197],
            [-53.836967916992187, -25.859968526367197],
            [-53.832667916992186, -25.858868526367196],
            [-53.833667916992184, -25.862568526367198],
            [-53.835367916992183, -25.867568526367194],
            [-53.831767916992185, -25.868868526367194],
            [-53.82756791699218, -25.869768526367196],
            [-53.82486791699219, -25.872668526367196],
            [-53.828567916992185, -25.876068526367199],
            [-53.832167916992184, -25.878568526367197],
            [-53.835467916992187, -25.877968526367198],
            [-53.838767916992182, -25.878868526367196],
            [-53.839767916992187, -25.882368526367195],
            [-53.843367916992186, -25.882968526367197],
            [-53.848067916992186, -25.883468526367196],
            [-53.84726791699218, -25.887468526367197],
            [-53.843667916992189, -25.888268526367199],
            [-53.838367916992183, -25.887668526367197],
            [-53.834767916992185, -25.889368526367196],
            [-53.834767916992185, -25.893168526367198],
            [-53.831567916992185, -25.895368526367196],
            [-53.826467916992186, -25.895168526367197],
            [-53.823467916992186, -25.895668526367196],
            [-53.81986791699218, -25.897568526367195],
            [-53.82016791699219, -25.900468526367199],
            [-53.822667916992188, -25.904068526367197],
            [-53.827167916992181, -25.905368526367198],
            [-53.830667916992184, -25.905968526367197],
            [-53.832967916992189, -25.908068526367195],
            [-53.834367916992186, -25.911768526367197],
            [-53.832667916992186, -25.914468526367195],
            [-53.828967916992184, -25.911968526367197],
            [-53.824967916992186, -25.910568526367197],
            [-53.821367916992187, -25.911168526367199],
            [-53.819967916992184, -25.913668526367196],
            [-53.82286791699218, -25.917168526367199],
            [-53.821367916992187, -25.920368526367199],
            [-53.819967916992184, -25.924468526367196],
            [-53.822367916992185, -25.928868526367197],
            [-53.826067916992187, -25.928368526367194],
            [-53.828567916992185, -25.926168526367196],
            [-53.83256791699219, -25.923168526367196],
            [-53.833167916992181, -25.928268526367198],
            [-53.834067916992183, -25.931868526367197],
            [-53.838367916992183, -25.931968526367196],
            [-53.842067916992185, -25.932968526367198],
            [-53.842067916992185, -25.936568526367196],
            [-53.841967916992189, -25.939468526367197],
            [-53.840867916992181, -25.942368526367197],
            [-53.840167916992186, -25.945968526367196],
            [-53.839767916992187, -25.949968526367197],
            [-53.839867916992183, -25.953568526367196],
            [-53.837367916992186, -25.955668526367198],
            [-53.835167916992184, -25.958968526367194],
            [-53.830967916992186, -25.960368526367198],
            [-53.829867916992185, -25.963468526367194],
            [-53.833967916992187, -25.965068526367197],
            [-53.835667916992186, -25.968968526367199],
            [-53.835067916992188, -25.972568526367198],
            [-53.831767916992185, -25.974568526367197],
            [-53.827967916992186, -25.975968526367197],
            [-53.822967916992184, -25.975568526367198],
            [-53.819367916992185, -25.976268526367196],
            [-53.816267916992189, -25.978868526367194],
            [-53.814667916992185, -25.981668526367194],
            [-53.819067916992182, -25.982468526367196],
            [-53.818167916992181, -25.986668526367197],
            [-53.81716791699219, -25.989468526367197],
            [-53.813767916992184, -25.989668526367197],
            [-53.810167916992185, -25.989968526367196],
            [-53.807167916992185, -25.986668526367197],
            [-53.803567916992186, -25.984768526367198],
            [-53.801167916992185, -25.989168526367195],
            [-53.803067916992184, -25.992968526367196],
            [-53.803667916992183, -25.996368526367196],
            [-53.800767916992186, -25.999868526367194],
            [-53.797567916992186, -26.000568526367196],
            [-53.793967916992187, -25.999368526367196],
            [-53.791967916992185, -26.002368526367196],
            [-53.792267916992188, -26.006068526367194],
            [-53.788067916992183, -26.006768526367196],
            [-53.784267916992185, -26.007368526367195],
            [-53.780867916992186, -26.008468526367196],
            [-53.782567916992186, -26.011768526367199],
            [-53.779867916992188, -26.015068526367195],
            [-53.776967916992184, -26.016468526367198],
            [-53.779467916992189, -26.020168526367197],
            [-53.775467916992184, -26.022868526367198],
            [-53.772567916992188, -26.024768526367197],
            [-53.770967916992184, -26.027268526367195],
            [-53.771467916992187, -26.030468526367194],
            [-53.768467916992186, -26.032368526367197],
            [-53.76536791699219, -26.034468526367196],
            [-53.762867916992185, -26.035968526367196],
            [-53.761367916992185, -26.033368526367198],
            [-53.75766791699219, -26.031968526367194],
            [-53.755467916992188, -26.034568526367195],
            [-53.757567916992187, -26.038268526367197],
            [-53.755267916992182, -26.041668526367197],
            [-53.752467916992188, -26.038168526367194],
            [-53.748267916992184, -26.040268526367196],
            [-53.746367916992185, -26.043968526367195],
            [-53.742967916992185, -26.040568526367196],
            [-53.740367916992184, -26.041768526367196],
            [-53.736867916992182, -26.041668526367197],
            [-53.73466791699218, -26.044168526367194],
            [-53.733367916992187, -26.047768526367197],
            [-53.732467916992185, -26.050768526367197],
            [-53.730567916992186, -26.054368526367199],
            [-53.730667916992189, -26.057968526367198],
            [-53.729267916992185, -26.061068526367194],
            [-53.727367916992186, -26.065768526367197],
            [-53.732067916992186, -26.069368526367196],
            [-53.734367916992184, -26.073168526367198],
            [-53.737167916992185, -26.077968526367197],
            [-53.73966791699219, -26.082568526367197],
            [-53.740467916992188, -26.086268526367196],
            [-53.740067916992189, -26.090068526367197],
            [-53.738867916992184, -26.094468526367194],
            [-53.738367916992189, -26.098668526367199],
            [-53.73966791699219, -26.103068526367196],
            [-53.741867916992184, -26.106668526367194],
            [-53.742967916992185, -26.111068526367198],
            [-53.741467916992185, -26.115068526367196],
            [-53.739067916992184, -26.117668526367197],
            [-53.734767916992183, -26.119468526367196],
            [-53.731767916992183, -26.122468526367197],
            [-53.728567916992183, -26.125568526367196],
            [-53.72426791699219, -26.128268526367197],
            [-53.720867916992184, -26.128868526367196],
            [-53.718167916992186, -26.127168526367196],
            [-53.714767916992187, -26.127668526367195],
            [-53.710867916992186, -26.130468526367196],
            [-53.709867916992181, -26.134168526367198],
            [-53.707667916992186, -26.137068526367194],
            [-53.706767916992185, -26.140968526367196],
            [-53.705067916992185, -26.143768526367197],
            [-53.702967916992186, -26.146768526367197],
            [-53.701467916992186, -26.149768526367197],
            [-53.699267916992184, -26.152968526367196],
            [-53.696767916992187, -26.154568526367196],
            [-53.692667916992185, -26.156668526367199],
            [-53.68916791699219, -26.158968526367197],
            [-53.686667916992185, -26.160968526367196],
            [-53.682267916992188, -26.162468526367196],
            [-53.678567916992186, -26.166168526367194],
            [-53.677567916992189, -26.168868526367199],
            [-53.676167916992185, -26.171468526367196],
            [-53.673867916992187, -26.173268526367195],
            [-53.671867916992184, -26.176568526367198],
            [-53.669567916992186, -26.179668526367195],
            [-53.667067916992181, -26.181068526367199],
            [-53.66446791699218, -26.184468526367198],
            [-53.660967916992185, -26.186968526367195],
            [-53.658867916992186, -26.189468526367197],
            [-53.65676791699218, -26.192668526367196],
            [-53.653167916992189, -26.193068526367199],
            [-53.650167916992189, -26.195168526367198],
            [-53.650367916992181, -26.199668526367198],
            [-53.652667916992186, -26.203168526367197],
            [-53.652267916992187, -26.205968526367194],
            [-53.647967916992187, -26.208668526367198],
            [-53.644867916992183, -26.209868526367195],
            [-53.641467916992184, -26.214368526367195],
            [-53.644067916992185, -26.217068526367196],
            [-53.646467916992187, -26.220268526367196],
            [-53.648967916992184, -26.226268526367196],
            [-53.651167916992186, -26.229968526367195],
            [-53.653067916992185, -26.235268526367197],
            [-53.650367916992181, -26.237868526367194],
            [-53.645367916992186, -26.240768526367198],
            [-53.642667916992181, -26.243568526367199],
            [-53.640667916992186, -26.245768526367197],
            [-53.638167916992188, -26.248768526367197],
            [-53.63906791699219, -26.251668526367197],
            [-53.641767916992187, -26.249468526367195],
            [-53.64476791699218, -26.249968526367198],
            [-53.643467916992186, -26.254868526367197],
            [-53.642467916992189, -26.257968526367197],
            [-53.64206791699219, -26.261568526367196],
            [-53.642167916992186, -26.265768526367197],
            [-53.644067916992185, -26.270368526367196],
            [-53.645167916992186, -26.273968526367199],
            [-53.645667916992181, -26.278368526367196],
            [-53.644567916992187, -26.281968526367194],
            [-53.645667916992181, -26.285868526367196],
            [-53.647967916992187, -26.288968526367196],
            [-53.651567916992185, -26.290668526367199],
            [-53.653467916992184, -26.293868526367199],
            [-53.655867916992186, -26.296368526367196],
            [-53.657667916992182, -26.298968526367197],
            [-53.658367916992184, -26.301968526367197],
            [-53.660267916992183, -26.305068526367194],
            [-53.662367916992181, -26.308268526367197],
            [-53.662567916992188, -26.311568526367196],
            [-53.664167916992184, -26.314468526367197],
            [-53.666167916992187, -26.317668526367196],
            [-53.669267916992183, -26.319568526367195],
            [-53.669667916992182, -26.323768526367196],
            [-53.673567916992184, -26.325468526367196],
            [-53.671367916992182, -26.329268526367194],
            [-53.673567916992184, -26.331868526367195],
            [-53.676667916992187, -26.330968526367194],
            [-53.677467916992185, -26.335068526367195],
            [-53.681667916992183, -26.334668526367196],
            [-53.685467916992181, -26.336868526367198],
            [-53.687267916992184, -26.340168526367197],
            [-53.688067916992182, -26.343368526367197],
            [-53.689667916992185, -26.346268526367197],
            [-53.68916791699219, -26.349968526367196],
            [-53.690167916992181, -26.353068526367196],
            [-53.69386791699219, -26.352768526367196],
            [-53.695667916992186, -26.355268526367198],
            [-53.692467916992186, -26.356068526367196],
            [-53.690567916992187, -26.359968526367197],
            [-53.69356791699218, -26.361468526367197],
            [-53.696267916992184, -26.363268526367197],
            [-53.699667916992183, -26.363968526367195],
            [-53.700967916992184, -26.367268526367198],
            [-53.701267916992187, -26.372668526367196],
            [-53.698467916992186, -26.376368526367195],
            [-53.697967916992184, -26.380268526367196],
            [-53.698867916992185, -26.383468526367196],
            [-53.701467916992186, -26.387368526367197],
            [-53.708467916992184, -26.387368526367197],
            [-53.708967916992187, -26.390768526367197],
            [-53.707367916992183, -26.394268526367195],
            [-53.703967916992184, -26.396768526367197],
            [-53.701267916992187, -26.397868526367198],
            [-53.698767916992182, -26.400068526367196],
            [-53.69686791699219, -26.403468526367199],
            [-53.699667916992183, -26.407668526367196],
            [-53.70256791699218, -26.408068526367195],
            [-53.705767916992187, -26.409468526367196],
            [-53.704667916992186, -26.412368526367196],
            [-53.705367916992188, -26.415268526367196],
            [-53.70286791699219, -26.416768526367196],
            [-53.698567916992189, -26.416768526367196],
            [-53.698967916992189, -26.421768526367195],
            [-53.695967916992188, -26.423568526367198],
            [-53.694267916992189, -26.426068526367196],
            [-53.693467916992184, -26.429768526367198],
            [-53.690567916992187, -26.428068526367198],
            [-53.68716791699218, -26.428268526367198],
            [-53.687967916992186, -26.431568526367194],
            [-53.688767916992184, -26.434768526367197],
            [-53.687767916992186, -26.439368526367197],
            [-53.688767916992184, -26.443268526367198],
            [-53.693767916992186, -26.443268526367198],
            [-53.697367916992185, -26.444568526367195],
            [-53.696667916992183, -26.441568526367199],
            [-53.700367916992185, -26.440468526367198],
            [-53.70456791699219, -26.442668526367196],
            [-53.704067916992187, -26.446268526367199],
            [-53.70286791699219, -26.449968526367197],
            [-53.700267916992189, -26.452868526367197],
            [-53.697467916992181, -26.454968526367196],
            [-53.69816791699219, -26.458968526367194],
            [-53.699267916992184, -26.462968526367199],
            [-53.699267916992184, -26.466468526367194],
            [-53.699267916992184, -26.469468526367194],
            [-53.702167916992181, -26.467068526367196],
            [-53.70456791699219, -26.463668526367197],
            [-53.708667916992184, -26.464868526367198],
            [-53.712867916992181, -26.467668526367198],
            [-53.715467916992182, -26.470968526367194],
            [-53.712867916992181, -26.473368526367196],
            [-53.709567916992185, -26.474568526367197],
            [-53.708967916992187, -26.478368526367198],
            [-53.706267916992189, -26.480668526367197],
            [-53.704367916992183, -26.476968526367195],
            [-53.702067916992185, -26.473668526367199],
            [-53.698567916992189, -26.474168526367194],
            [-53.696667916992183, -26.476268526367196],
            [-53.69986791699219, -26.478968526367197],
            [-53.699267916992184, -26.482868526367199],
            [-53.698467916992186, -26.485768526367195],
            [-53.697067916992182, -26.489968526367196],
            [-53.69986791699219, -26.490468526367195],
            [-53.70286791699219, -26.490468526367195],
            [-53.705367916992188, -26.493568526367199],
            [-53.706467916992182, -26.497168526367197],
            [-53.708267916992185, -26.500568526367196],
            [-53.705967916992186, -26.503268526367197],
            [-53.70456791699219, -26.507068526367195],
            [-53.708267916992185, -26.506568526367197],
            [-53.712067916992183, -26.504068526367199],
            [-53.71626791699218, -26.503468526367197],
            [-53.719467916992187, -26.506268526367194],
            [-53.722667916992187, -26.504068526367199],
            [-53.725367916992184, -26.500868526367196],
            [-53.729167916992182, -26.501268526367198],
            [-53.730667916992189, -26.503868526367196],
            [-53.731067916992188, -26.507468526367198],
            [-53.730067916992184, -26.511768526367199],
            [-53.728867916992186, -26.515968526367196],
            [-53.729167916992182, -26.519368526367195],
            [-53.728067916992188, -26.522868526367198],
            [-53.726367916992189, -26.526168526367197],
            [-53.721967916992185, -26.526568526367196],
            [-53.718467916992182, -26.525068526367196],
            [-53.717867916992184, -26.528368526367196],
            [-53.72096791699218, -26.529768526367196],
            [-53.724167916992187, -26.531668526367199],
            [-53.723467916992185, -26.535868526367196],
            [-53.71926791699218, -26.536368526367198],
            [-53.721467916992182, -26.540868526367198],
            [-53.724167916992187, -26.542568526367198],
            [-53.728067916992188, -26.540868526367198],
            [-53.73166791699218, -26.538968526367196],
            [-53.735267916992186, -26.540568526367196],
            [-53.740867916992187, -26.543568526367196],
            [-53.741167916992183, -26.547168526367194],
            [-53.738267916992186, -26.548368526367199],
            [-53.734167916992185, -26.548868526367194],
            [-53.730667916992189, -26.548968526367197],
            [-53.727067916992183, -26.550768526367197],
            [-53.725567916992183, -26.554768526367198],
            [-53.725667916992187, -26.558568526367196],
            [-53.729567916992181, -26.559868526367197],
            [-53.732867916992184, -26.558668526367196],
            [-53.736467916992183, -26.560168526367196],
            [-53.737467916992188, -26.563568526367199],
            [-53.735667916992185, -26.566568526367199],
            [-53.73166791699218, -26.566268526367196],
            [-53.728667916992187, -26.564568526367196],
            [-53.72426791699219, -26.562768526367197],
            [-53.72226791699218, -26.566568526367199],
            [-53.721467916992182, -26.570968526367196],
            [-53.722067916992188, -26.575468526367196],
            [-53.722367916992184, -26.580368526367195],
            [-53.724867916992181, -26.584668526367196],
            [-53.725267916992181, -26.588668526367197],
            [-53.728867916992186, -26.587668526367196],
            [-53.732467916992185, -26.586468526367199],
            [-53.731167916992185, -26.589368526367195],
            [-53.72896791699219, -26.592668526367198],
            [-53.729567916992181, -26.597868526367197],
            [-53.729567916992181, -26.603068526367196],
            [-53.730567916992186, -26.607568526367196],
            [-53.735267916992186, -26.608568526367197],
            [-53.73496791699219, -26.612768526367198],
            [-53.732467916992185, -26.614768526367197],
            [-53.730367916992186, -26.617268526367198],
            [-53.73026791699219, -26.621568526367199],
            [-53.732467916992185, -26.623768526367197],
            [-53.733867916992182, -26.626568526367194],
            [-53.733367916992187, -26.630468526367196],
            [-53.736867916992182, -26.630168526367196],
            [-53.741067916992186, -26.630468526367196],
            [-53.741467916992185, -26.634068526367194],
            [-53.741467916992185, -26.637968526367196],
            [-53.740367916992184, -26.642968526367198],
            [-53.743367916992185, -26.643968526367196],
            [-53.746367916992185, -26.640168526367198],
            [-53.750467916992186, -26.635968526367197],
            [-53.75436791699218, -26.636068526367197],
            [-53.758567916992185, -26.638468526367198],
            [-53.759567916992182, -26.641468526367198],
            [-53.756567916992182, -26.644368526367195],
            [-53.753267916992186, -26.645368526367196],
            [-53.748567916992187, -26.646468526367194],
            [-53.743367916992185, -26.647968526367194],
            [-53.739167916992187, -26.646868526367196],
            [-53.73496791699219, -26.646068526367195],
            [-53.733867916992182, -26.649768526367197],
            [-53.73326791699219, -26.653768526367195],
            [-53.729167916992182, -26.657568526367196],
            [-53.724767916992185, -26.658868526367197],
            [-53.723667916992184, -26.655168526367198],
            [-53.725267916992181, -26.651168526367197],
            [-53.723367916992188, -26.648168526367197],
            [-53.720867916992184, -26.652568526367197],
            [-53.719867916992186, -26.656968526367194],
            [-53.719467916992187, -26.661768526367197],
            [-53.718767916992185, -26.665868526367198],
            [-53.720667916992184, -26.668568526367196],
            [-53.725667916992187, -26.667568526367198],
            [-53.72696791699218, -26.670268526367195],
            [-53.727067916992183, -26.673668526367194],
            [-53.73026791699219, -26.675768526367197],
            [-53.731167916992185, -26.678568526367194],
            [-53.72596791699219, -26.679368526367199],
            [-53.722367916992184, -26.679468526367195],
            [-53.718067916992183, -26.681068526367199],
            [-53.717567916992181, -26.684368526367194],
            [-53.721167916992187, -26.686868526367196],
            [-53.724467916992182, -26.690568526367194],
            [-53.72896791699219, -26.691968526367198],
            [-53.732867916992184, -26.690568526367194],
            [-53.73496791699219, -26.688068526367196],
            [-53.736767916992186, -26.684168526367195],
            [-53.739767916992186, -26.680868526367199],
            [-53.743267916992181, -26.680768526367196],
            [-53.742967916992185, -26.684968526367197],
            [-53.742967916992185, -26.688368526367196],
            [-53.741867916992184, -26.691368526367196],
            [-53.73966791699219, -26.693468526367198],
            [-53.736367916992187, -26.695568526367197],
            [-53.733667916992189, -26.699968526367197],
            [-53.733567916992186, -26.703968526367198],
            [-53.734767916992183, -26.708268526367196],
            [-53.73496791699219, -26.712968526367199],
            [-53.73496791699219, -26.716768526367197],
            [-53.738567916992181, -26.719168526367199],
            [-53.743867916992187, -26.719068526367195],
            [-53.749067916992189, -26.718368526367197],
            [-53.750867916992185, -26.714468526367199],
            [-53.752367916992185, -26.711168526367196],
            [-53.755167916992185, -26.710168526367198],
            [-53.757167916992188, -26.712568526367196],
            [-53.757767916992186, -26.715868526367196],
            [-53.758467916992188, -26.719568526367198],
            [-53.755767916992184, -26.722868526367197],
            [-53.751267916992184, -26.724468526367197],
            [-53.748067916992184, -26.726268526367196],
            [-53.745467916992183, -26.728168526367199],
            [-53.743367916992185, -26.730368526367194],
            [-53.743567916992184, -26.733268526367198],
            [-53.746567916992184, -26.734968526367197],
            [-53.749067916992189, -26.736368526367194],
            [-53.750867916992185, -26.738868526367199],
            [-53.749467916992188, -26.741868526367199],
            [-53.746567916992184, -26.741968526367195],
            [-53.742767916992186, -26.742168526367195],
            [-53.738367916992189, -26.741868526367199],
            [-53.735267916992186, -26.739968526367196],
            [-53.73466791699218, -26.736768526367197],
            [-53.734167916992185, -26.733668526367197],
            [-53.731467916992187, -26.731368526367199],
            [-53.729467916992185, -26.734668526367194],
            [-53.729267916992185, -26.738368526367196],
            [-53.727067916992183, -26.741868526367199],
            [-53.724767916992185, -26.744468526367196],
            [-53.721667916992189, -26.747668526367196],
            [-53.717667916992184, -26.749168526367196],
            [-53.714467916992184, -26.750168526367197],
            [-53.714767916992187, -26.753368526367197],
            [-53.716767916992183, -26.756568526367197],
            [-53.718367916992186, -26.759068526367194],
            [-53.720867916992184, -26.760968526367197],
            [-53.724567916992186, -26.762968526367196],
            [-53.729267916992185, -26.765168526367198],
            [-53.733167916992187, -26.765668526367197],
            [-53.736967916992185, -26.763568526367195],
            [-53.741067916992186, -26.761468526367196],
            [-53.742967916992185, -26.764068526367197],
            [-53.738367916992189, -26.766268526367199],
            [-53.735367916992189, -26.769868526367198],
            [-53.732467916992185, -26.772968526367194],
            [-53.729167916992182, -26.774068526367195],
            [-53.724867916992181, -26.774068526367195],
            [-53.720267916992185, -26.773668526367196],
            [-53.71626791699218, -26.772668526367198],
            [-53.714867916992183, -26.767168526367197],
            [-53.710167916992184, -26.766068526367196],
            [-53.706767916992185, -26.766768526367194],
            [-53.702367916992188, -26.767468526367196],
            [-53.700267916992189, -26.770968526367199],
            [-53.704667916992186, -26.772568526367195],
            [-53.708967916992187, -26.773968526367199],
            [-53.712067916992183, -26.776168526367197],
            [-53.71626791699218, -26.779568526367196],
            [-53.717267916992185, -26.782868526367196],
            [-53.715567916992185, -26.788468526367197],
            [-53.712567916992185, -26.793368526367196],
            [-53.710367916992183, -26.796768526367195],
            [-53.706467916992182, -26.798268526367195],
            [-53.70156791699219, -26.798068526367196],
            [-53.700967916992184, -26.803068526367198],
            [-53.699267916992184, -26.807168526367196],
            [-53.697767916992184, -26.810868526367194],
            [-53.69816791699219, -26.813868526367195],
            [-53.701367916992183, -26.815268526367198],
            [-53.705067916992185, -26.815868526367197],
            [-53.701067916992187, -26.819868526367195],
            [-53.699967916992186, -26.823468526367197],
            [-53.698567916992189, -26.828168526367197],
            [-53.695467916992186, -26.832568526367197],
            [-53.692467916992186, -26.835068526367195],
            [-53.689867916992185, -26.837968526367199],
            [-53.694567916992185, -26.841168526367198],
            [-53.696067916992185, -26.844568526367198],
            [-53.69516791699219, -26.847368526367198],
            [-53.690967916992186, -26.848368526367196],
            [-53.686667916992185, -26.846368526367197],
            [-53.683267916992186, -26.843468526367197],
            [-53.677867916992184, -26.842968526367194],
            [-53.676167916992185, -26.845568526367195],
            [-53.674667916992185, -26.849468526367197],
            [-53.671667916992185, -26.852868526367196],
            [-53.668367916992182, -26.854268526367196],
            [-53.66446791699218, -26.855968526367196],
            [-53.660967916992185, -26.858168526367194],
            [-53.660867916992188, -26.861668526367197],
            [-53.663067916992183, -26.864168526367195],
            [-53.66646791699219, -26.864168526367195],
            [-53.66916791699218, -26.862968526367197],
            [-53.67216791699218, -26.861668526367197],
            [-53.676867916992187, -26.861168526367194],
            [-53.680867916992185, -26.861368526367194],
            [-53.685167916992185, -26.861068526367198],
            [-53.688067916992182, -26.860568526367196],
            [-53.691267916992189, -26.859668526367194],
            [-53.695967916992188, -26.858968526367196],
            [-53.695467916992186, -26.862968526367197],
            [-53.691567916992184, -26.865768526367198],
            [-53.687967916992186, -26.866468526367196],
            [-53.684367916992187, -26.867168526367195],
            [-53.680767916992181, -26.869768526367196],
            [-53.683767916992181, -26.870868526367197],
            [-53.687667916992183, -26.872468526367197],
            [-53.690967916992186, -26.875568526367196],
            [-53.693267916992184, -26.879668526367198],
            [-53.69516791699219, -26.882768526367194],
            [-53.696067916992185, -26.886368526367196],
            [-53.693267916992184, -26.889568526367196],
            [-53.68886791699218, -26.890968526367196],
            [-53.685267916992188, -26.891568526367195],
            [-53.680867916992185, -26.892168526367197],
            [-53.675767916992186, -26.892668526367196],
            [-53.672767916992186, -26.892968526367198],
            [-53.67216791699218, -26.896168526367198],
            [-53.674967916992188, -26.897968526367194],
            [-53.677467916992185, -26.899768526367197],
            [-53.679667916992187, -26.902668526367197],
            [-53.679467916992181, -26.908668526367194],
            [-53.674667916992185, -26.912068526367197],
            [-53.678567916992186, -26.915268526367196],
            [-53.688567916992184, -26.920668526367194],
            [-53.691267916992189, -26.922568526367197],
            [-53.694367916992185, -26.924968526367195],
            [-53.69686791699219, -26.928068526367198],
            [-53.695667916992186, -26.931168526367195],
            [-53.692467916992186, -26.933868526367196],
            [-53.689367916992182, -26.935268526367196],
            [-53.685567916992184, -26.936868526367196],
            [-53.679667916992187, -26.938768526367198],
            [-53.676167916992185, -26.940168526367195],
            [-53.67246791699219, -26.941268526367196],
            [-53.669967916992185, -26.943468526367198],
            [-53.672867916992189, -26.945468526367197],
            [-53.675767916992186, -26.946268526367199],
            [-53.679067916992182, -26.945168526367198],
            [-53.683067916992186, -26.944368526367196],
            [-53.686367916992182, -26.943168526367195],
            [-53.688567916992184, -26.941368526367196],
            [-53.691367916992185, -26.939068526367194],
            [-53.69486791699218, -26.936968526367195],
            [-53.698967916992189, -26.935468526367195],
            [-53.702667916992183, -26.934068526367199],
            [-53.705967916992186, -26.933268526367197],
            [-53.709667916992188, -26.934668526367197],
            [-53.709867916992181, -26.939068526367194],
            [-53.707367916992183, -26.944268526367196],
            [-53.705767916992187, -26.946868526367197],
            [-53.703167916992186, -26.950668526367195],
            [-53.70156791699219, -26.954268526367194],
            [-53.702367916992188, -26.957268526367194],
            [-53.704967916992189, -26.960368526367198],
            [-53.707967916992189, -26.962568526367196],
            [-53.711267916992185, -26.965168526367197],
            [-53.715467916992182, -26.964468526367199],
            [-53.718467916992182, -26.962868526367195],
            [-53.72096791699218, -26.960468526367194],
            [-53.724467916992182, -26.958768526367194],
            [-53.72726791699219, -26.959668526367196],
            [-53.728867916992186, -26.963668526367197],
            [-53.729667916992184, -26.967868526367198],
            [-53.731167916992185, -26.972768526367197],
            [-53.735667916992185, -26.974268526367197],
            [-53.734367916992184, -26.978168526367199],
            [-53.728867916992186, -26.980668526367197],
            [-53.723367916992188, -26.982168526367197],
            [-53.71926791699218, -26.983868526367196],
            [-53.717367916992188, -26.986968526367196],
            [-53.718667916992189, -26.990268526367196],
            [-53.722767916992183, -26.992968526367196],
            [-53.727867916992182, -26.993668526367195],
            [-53.730867916992182, -26.992768526367197],
            [-53.733967916992185, -26.991868526367199],
            [-53.738267916992186, -26.990868526367194],
            [-53.743267916992181, -26.992268526367198],
            [-53.741167916992183, -26.996968526367198],
            [-53.738667916992185, -26.998768526367197],
            [-53.734767916992183, -27.001568526367194],
            [-53.732567916992181, -27.004868526367197],
            [-53.733167916992187, -27.007968526367197],
            [-53.736367916992187, -27.010668526367198],
            [-53.740467916992188, -27.014068526367197],
            [-53.743867916992187, -27.012768526367196],
            [-53.745867916992182, -27.010368526367195],
            [-53.749367916992185, -27.007068526367195],
            [-53.753767916992189, -27.004868526367197],
            [-53.757967916992186, -27.005168526367196],
            [-53.760967916992186, -27.005768526367198],
            [-53.763567916992187, -27.007768526367194],
            [-53.762767916992189, -27.011568526367196],
            [-53.760767916992187, -27.015968526367196],
            [-53.758767916992184, -27.018168526367198],
            [-53.755767916992184, -27.019568526367195],
            [-53.75136791699218, -27.021068526367195],
            [-53.74836791699218, -27.025168526367196],
            [-53.748567916992187, -27.032768526367196],
            [-53.752667916992181, -27.033468526367194],
            [-53.758467916992188, -27.033868526367197],
            [-53.761267916992182, -27.037868526367198],
            [-53.76236791699219, -27.042568526367198],
            [-53.764667916992181, -27.044668526367197],
            [-53.767867916992188, -27.041968526367196],
            [-53.770367916992186, -27.037368526367196],
            [-53.772867916992183, -27.034268526367196],
            [-53.775367916992181, -27.031268526367196],
            [-53.777567916992183, -27.029268526367197],
            [-53.781167916992189, -27.026768526367196],
            [-53.785567916992186, -27.026568526367196],
            [-53.785267916992183, -27.029568526367196],
            [-53.782767916992185, -27.033368526367198],
            [-53.780167916992184, -27.036968526367197],
            [-53.77876791699218, -27.039468526367195],
            [-53.77876791699218, -27.042468526367195],
            [-53.778167916992189, -27.045568526367198],
            [-53.777267916992187, -27.049168526367197],
            [-53.773967916992184, -27.052868526367199],
            [-53.770367916992186, -27.056168526367195],
            [-53.766267916992184, -27.057968526367198],
            [-53.760767916992187, -27.060168526367196],
            [-53.759667916992186, -27.063368526367196],
            [-53.763467916992184, -27.066568526367199],
            [-53.766767916992187, -27.067668526367196],
            [-53.771167916992184, -27.066668526367195],
            [-53.776267916992182, -27.063568526367199],
            [-53.779267916992183, -27.060768526367198],
            [-53.78206791699219, -27.057468526367195],
            [-53.784867916992184, -27.054168526367196],
            [-53.787367916992181, -27.049768526367195],
            [-53.790367916992182, -27.044768526367196],
            [-53.793167916992189, -27.041668526367197],
            [-53.79746791699219, -27.039768526367197],
            [-53.801867916992187, -27.040068526367197],
            [-53.801667916992187, -27.043068526367197],
            [-53.800767916992186, -27.046368526367196],
            [-53.799267916992186, -27.049968526367195],
            [-53.798367916992184, -27.053668526367197],
            [-53.797767916992186, -27.058568526367196],
            [-53.794267916992183, -27.062168526367195],
            [-53.791967916992185, -27.064468526367197],
            [-53.788867916992189, -27.068268526367198],
            [-53.784267916992185, -27.071768526367194],
            [-53.77906791699219, -27.071368526367195],
            [-53.774467916992187, -27.071368526367195],
            [-53.772367916992181, -27.073868526367196],
            [-53.774567916992183, -27.076368526367197],
            [-53.777567916992183, -27.077968526367197],
            [-53.781267916992185, -27.078568526367196],
            [-53.785367916992186, -27.077368526367195],
            [-53.78846791699219, -27.075768526367199],
            [-53.792067916992181, -27.072368526367196],
            [-53.794267916992183, -27.069268526367196],
            [-53.796167916992189, -27.066668526367195],
            [-53.799667916992185, -27.067668526367196],
            [-53.802467916992185, -27.072768526367199],
            [-53.805467916992185, -27.079068526367195],
            [-53.803867916992189, -27.083268526367196],
            [-53.801367916992184, -27.086268526367196],
            [-53.798167916992185, -27.088368526367198],
            [-53.793067916992186, -27.091268526367195],
            [-53.790067916992186, -27.093968526367199],
            [-53.784867916992184, -27.097368526367198],
            [-53.77876791699218, -27.100668526367194],
            [-53.778167916992189, -27.103868526367194],
            [-53.78346791699218, -27.104968526367195],
            [-53.787867916992184, -27.105668526367197],
            [-53.790867916992184, -27.107568526367196],
            [-53.793867916992184, -27.109768526367198],
            [-53.797867916992189, -27.109968526367197],
            [-53.803067916992184, -27.108268526367198],
            [-53.805567916992189, -27.106468526367195],
            [-53.807667916992187, -27.103368526367198],
            [-53.809367916992187, -27.099868526367196],
            [-53.812267916992184, -27.097868526367197],
            [-53.816067916992182, -27.099468526367197],
            [-53.817967916992188, -27.103668526367194],
            [-53.814367916992182, -27.106468526367195],
            [-53.811867916992185, -27.107868526367199],
            [-53.809067916992184, -27.109468526367195],
            [-53.806567916992186, -27.111768526367197],
            [-53.805467916992185, -27.116668526367196],
            [-53.804767916992184, -27.119668526367196],
            [-53.804967916992183, -27.123268526367198],
            [-53.807267916992188, -27.125968526367195],
            [-53.810467916992181, -27.127368526367196],
            [-53.814967916992188, -27.129368526367195],
            [-53.818267916992184, -27.129068526367199],
            [-53.821267916992184, -27.127768526367198],
            [-53.82486791699219, -27.127368526367196],
            [-53.824967916992186, -27.131268526367194],
            [-53.823867916992185, -27.134068526367194],
            [-53.822067916992182, -27.136768526367199],
            [-53.81986791699218, -27.138968526367197],
            [-53.816267916992189, -27.140168526367198],
            [-53.81216791699218, -27.139568526367196],
            [-53.808767916992181, -27.138468526367198],
            [-53.804167916992185, -27.137168526367198],
            [-53.798967916992183, -27.137068526367194],
            [-53.798167916992185, -27.140468526367197],
            [-53.798167916992185, -27.144868526367198],
            [-53.800367916992187, -27.146768526367197],
            [-53.803667916992183, -27.146568526367197],
            [-53.808067916992186, -27.145768526367196],
            [-53.812667916992183, -27.144868526367198],
            [-53.815967916992186, -27.144568526367195],
            [-53.819367916992185, -27.144368526367195],
            [-53.822467916992181, -27.144368526367195],
            [-53.825467916992181, -27.144768526367194],
            [-53.82956791699219, -27.147268526367196],
            [-53.832967916992189, -27.150468526367199],
            [-53.836167916992181, -27.153368526367196],
            [-53.836467916992184, -27.157268526367197],
            [-53.833467916992184, -27.162268526367196],
            [-53.833267916992185, -27.166168526367194],
            [-53.837867916992181, -27.168068526367197],
            [-53.84156791699219, -27.165568526367196],
            [-53.843467916992182, -27.162768526367195],
            [-53.846967916992185, -27.157368526367197],
            [-53.84896791699218, -27.153968526367194],
            [-53.850567916992183, -27.150968526367194],
            [-53.855667916992189, -27.139368526367196],
            [-53.858567916992186, -27.132668526367198],
            [-53.861167916992187, -27.128468526367197],
            [-53.865367916992184, -27.125968526367195],
            [-53.869767916992188, -27.125168526367197],
            [-53.875267916992186, -27.125868526367196],
            [-53.878067916992187, -27.127168526367196],
            [-53.88266791699219, -27.130668526367195],
            [-53.884967916992181, -27.135668526367198],
            [-53.886067916992189, -27.141068526367196],
            [-53.88736791699219, -27.147868526367198],
            [-53.88736791699219, -27.150868526367198],
            [-53.887967916992181, -27.153768526367195],
            [-53.890167916992183, -27.157668526367196],
            [-53.892167916992186, -27.160568526367197],
            [-53.893767916992189, -27.163168526367194],
            [-53.895167916992186, -27.166668526367197],
            [-53.898267916992182, -27.170868526367194],
            [-53.902667916992186, -27.174368526367196],
            [-53.905467916992187, -27.175468526367197],
            [-53.90976791699218, -27.176068526367196],
            [-53.916767916992185, -27.174668526367196],
            [-53.921367916992182, -27.168968526367195],
            [-53.925367916992187, -27.163068526367198],
            [-53.928967916992185, -27.158968526367197],
            [-53.933567916992189, -27.155868526367197],
            [-53.940167916992181, -27.152868526367197],
            [-53.94516791699219, -27.151568526367196],
            [-53.953167916992186, -27.152868526367197],
            [-53.95726791699218, -27.156968526367194],
            [-53.959767916992185, -27.160068526367198],
            [-53.960667916992186, -27.165068526367197],
            [-53.958267916992185, -27.170368526367199],
            [-53.955667916992184, -27.175568526367197],
            [-53.955167916992181, -27.178368526367194],
            [-53.955467916992184, -27.183668526367196],
            [-53.957367916992183, -27.188568526367199],
            [-53.960067916992188, -27.195268526367194],
            [-53.963167916992184, -27.197668526367195],
            [-53.967667916992184, -27.198868526367196],
            [-53.97226791699218, -27.198868526367196],
            [-53.977067916992183, -27.197968526367198],
            [-53.983967916992185, -27.196268526367199],
            [-53.992167916992187, -27.195968526367196],
            [-54.000967916992181, -27.196268526367199],
            [-54.007067916992185, -27.197968526367198],
            [-54.012167916992183, -27.202568526367195],
            [-54.014867916992188, -27.208168526367196],
            [-54.015667916992186, -27.211868526367198],
            [-54.016467916992184, -27.216968526367197],
            [-54.015767916992189, -27.223468526367196],
            [-54.015467916992186, -27.229168526367197],
            [-54.015767916992189, -27.232868526367199],
            [-54.017567916992185, -27.238568526367196],
            [-54.020767916992185, -27.245768526367197],
            [-54.02436791699219, -27.251568526367194],
            [-54.027667916992186, -27.254868526367197],
            [-54.035267916992183, -27.257168526367195],
            [-54.04146791699219, -27.257968526367197],
            [-54.04716791699218, -27.259368526367197],
            [-54.051867916992187, -27.261468526367196],
            [-54.055567916992189, -27.264568526367196],
            [-54.060267916992188, -27.269368526367195],
            [-54.064667916992185, -27.275868526367198],
            [-54.068767916992186, -27.283368526367198],
            [-54.070667916992186, -27.286968526367197],
            [-54.072167916992186, -27.290868526367198],
            [-54.075667916992188, -27.294468526367197],
            [-54.081167916992186, -27.298268526367195],
            [-54.089067916992185, -27.301168526367196],
            [-54.096467916992182, -27.302768526367196],
            [-54.10096791699219, -27.303268526367198],
            [-54.107467916992185, -27.302968526367195],
            [-54.111067916992184, -27.303668526367197],
            [-54.11466791699219, -27.303268526367198],
            [-54.119067916992186, -27.301868526367194],
            [-54.126267916992184, -27.301468526367195],
            [-54.129867916992183, -27.301468526367195],
            [-54.132767916992186, -27.301368526367199],
            [-54.14006791699218, -27.300068526367198],
            [-54.143667916992186, -27.299368526367196],
            [-54.148467916992189, -27.298568526367198],
            [-54.155167916992184, -27.296468526367196],
            [-54.159267916992185, -27.292768526367198],
            [-54.16006791699219, -27.287468526367196],
            [-54.158867916992186, -27.283968526367197],
            [-54.156167916992189, -27.280068526367195],
            [-54.155367916992184, -27.276468526367196],
            [-54.155367916992184, -27.272368526367195],
            [-54.155667916992186, -27.268968526367196],
            [-54.156967916992187, -27.264568526367196],
            [-54.159467916992185, -27.259368526367197],
            [-54.162067916992186, -27.256068526367194],
            [-54.165267916992185, -27.254068526367199],
            [-54.16946791699219, -27.253568526367197],
            [-54.174167916992189, -27.254868526367197],
            [-54.177567916992189, -27.257768526367194],
            [-54.180767916992181, -27.260168526367195],
            [-54.183367916992182, -27.262368526367197],
            [-54.186567916992189, -27.264868526367195],
            [-54.187367916992187, -27.267968526367198],
            [-54.189067916992187, -27.271568526367197],
            [-54.190767916992186, -27.278068526367196],
            [-54.191567916992184, -27.283068526367195],
            [-54.192767916992182, -27.288168526367194],
            [-54.194167916992185, -27.292268526367195],
            [-54.193467916992184, -27.295768526367198],
            [-54.19216791699219, -27.300768526367197],
            [-54.19216791699219, -27.304668526367195],
            [-54.193267916992184, -27.308368526367197],
            [-54.195667916992186, -27.312668526367197],
            [-54.198767916992182, -27.317668526367196],
            [-54.202367916992188, -27.323568526367197],
            [-54.204667916992186, -27.327868526367197],
            [-54.206467916992182, -27.331468526367196],
            [-54.208667916992184, -27.335868526367197],
            [-54.210367916992183, -27.339868526367198],
            [-54.211967916992187, -27.345568526367195],
            [-54.21326791699218, -27.351168526367196],
            [-54.213967916992189, -27.358868526367196],
            [-54.214067916992185, -27.365268526367196],
            [-54.21326791699218, -27.369668526367196],
            [-54.212967916992184, -27.375168526367197],
            [-54.214367916992188, -27.381568526367197],
            [-54.217267916992185, -27.385368526367195],
            [-54.222367916992184, -27.389368526367196],
            [-54.228367916992184, -27.392068526367197],
            [-54.23196791699219, -27.392968526367198],
            [-54.235267916992186, -27.393668526367197],
            [-54.241567916992182, -27.392968526367198],
            [-54.245867916992182, -27.393168526367198],
            [-54.252667916992181, -27.394268526367195],
            [-54.259867916992185, -27.396168526367198],
            [-54.263967916992186, -27.399268526367194],
            [-54.267467916992189, -27.404268526367197],
            [-54.268567916992183, -27.414168526367199],
            [-54.270667916992181, -27.418668526367199],
            [-54.270167916992186, -27.424168526367197],
            [-54.269067916992185, -27.427268526367197],
            [-54.267867916992188, -27.431168526367195],
            [-54.268267916992187, -27.436268526367197],
            [-54.271867916992186, -27.440968526367197],
            [-54.277267916992187, -27.444868526367195],
            [-54.283667916992187, -27.447768526367199],
            [-54.288967916992185, -27.447968526367198],
            [-54.29416791699218, -27.445968526367196],
            [-54.29746791699219, -27.441668526367195],
            [-54.301467916992181, -27.433368526367197],
            [-54.310567916992184, -27.421868526367199],
            [-54.320667916992186, -27.412868526367198],
            [-54.328767916992184, -27.406668526367199],
            [-54.334367916992186, -27.403168526367196],
            [-54.33826791699218, -27.403068526367196],
            [-54.34126791699218, -27.404268526367197],
            [-54.342567916992181, -27.408168526367199],
            [-54.342667916992184, -27.412768526367195],
            [-54.341667916992186, -27.417468526367195],
            [-54.341767916992183, -27.421168526367197],
            [-54.34126791699218, -27.425568526367197],
            [-54.340367916992186, -27.430868526367199],
            [-54.338967916992189, -27.435768526367198],
            [-54.337967916992184, -27.439868526367196],
            [-54.336467916992184, -27.443768526367194],
            [-54.335067916992188, -27.446668526367198],
            [-54.335067916992188, -27.451268526367194],
            [-54.33686791699219, -27.456568526367196],
            [-54.338467916992187, -27.459568526367196],
            [-54.340367916992186, -27.463368526367198],
            [-54.342567916992181, -27.465168526367197],
            [-54.346367916992186, -27.466968526367197],
            [-54.350367916992184, -27.466968526367197],
            [-54.357167916992182, -27.464868526367198],
            [-54.36296791699219, -27.459768526367196],
            [-54.369667916992185, -27.452568526367195],
            [-54.377067916992189, -27.441868526367195],
            [-54.385167916992188, -27.426968526367197],
            [-54.388267916992184, -27.422768526367197],
            [-54.392167916992186, -27.418068526367197],
            [-54.398667916992181, -27.411668526367194],
            [-54.405567916992183, -27.407368526367197],
            [-54.410567916992186, -27.405468526367194],
            [-54.415667916992184, -27.406968526367194],
            [-54.418667916992185, -27.408868526367197],
            [-54.421467916992185, -27.411368526367198],
            [-54.426567916992184, -27.416068526367198],
            [-54.430467916992185, -27.419468526367197],
            [-54.435767916992184, -27.423268526367195],
            [-54.439067916992187, -27.424668526367196],
            [-54.444067916992182, -27.424968526367195],
            [-54.452667916992183, -27.422868526367196],
            [-54.456067916992183, -27.422168526367194],
            [-54.459667916992188, -27.422468526367197],
            [-54.462667916992189, -27.422868526367196],
            [-54.465867916992181, -27.423568526367198],
            [-54.469867916992186, -27.425768526367197],
            [-54.471967916992185, -27.430468526367196],
            [-54.471967916992185, -27.434368526367194],
            [-54.469867916992186, -27.437968526367197],
            [-54.465467916992182, -27.442968526367196],
            [-54.460367916992183, -27.448568526367197],
            [-54.452167916992181, -27.453268526367197],
            [-54.448467916992186, -27.455668526367198],
            [-54.44386791699219, -27.459868526367195],
            [-54.442667916992185, -27.464868526367198],
            [-54.443267916992184, -27.468368526367197],
            [-54.445767916992182, -27.472068526367195],
            [-54.44956791699218, -27.475368526367198],
            [-54.456167916992186, -27.478068526367196],
            [-54.462967916992184, -27.479668526367199],
            [-54.47426791699219, -27.481668526367194],
            [-54.477767916992185, -27.481068526367196],
            [-54.48166791699218, -27.480968526367196],
            [-54.486667916992189, -27.480368526367194],
            [-54.492967916992185, -27.479968526367195],
            [-54.500567916992182, -27.480268526367198],
            [-54.506267916992186, -27.482268526367196],
            [-54.510267916992184, -27.485368526367196],
            [-54.512467916992186, -27.488968526367195],
            [-54.514667916992181, -27.493368526367199],
            [-54.51706791699219, -27.497668526367196],
            [-54.520467916992189, -27.502768526367198],
            [-54.524867916992186, -27.506068526367194],
            [-54.530067916992181, -27.506068526367194],
            [-54.534467916992185, -27.505668526367195],
            [-54.537067916992186, -27.502768526367198],
            [-54.538167916992187, -27.498668526367197],
            [-54.53946791699218, -27.492968526367196],
            [-54.54276791699219, -27.486068526367198],
            [-54.547567916992186, -27.479268526367196],
            [-54.55216791699219, -27.473468526367196],
            [-54.556367916992187, -27.468368526367197],
            [-54.558567916992189, -27.465668526367196],
            [-54.561267916992186, -27.462568526367196],
            [-54.566667916992188, -27.457568526367197],
            [-54.573267916992187, -27.453568526367196],
            [-54.57656791699219, -27.453268526367197],
            [-54.580467916992184, -27.453168526367197],
            [-54.585367916992183, -27.454668526367197],
            [-54.589367916992188, -27.458168526367196],
            [-54.591167916992184, -27.462868526367195],
            [-54.592267916992185, -27.468468526367197],
            [-54.591767916992183, -27.475068526367195],
            [-54.593667916992189, -27.482468526367196],
            [-54.594867916992186, -27.487268526367195],
            [-54.597367916992184, -27.491668526367196],
            [-54.60226791699219, -27.497668526367196],
            [-54.606167916992185, -27.503768526367196],
            [-54.60966791699218, -27.510968526367197],
            [-54.611467916992183, -27.516568526367195],
            [-54.61296791699219, -27.523168526367197],
            [-54.616367916992189, -27.532868526367196],
            [-54.620767916992186, -27.538668526367196],
            [-54.626867916992182, -27.543968526367195],
            [-54.633467916992188, -27.546068526367197],
            [-54.63906791699219, -27.545368526367199],
            [-54.642367916992185, -27.544768526367196],
            [-54.647967916992187, -27.540268526367196],
            [-54.651967916992184, -27.531668526367199],
            [-54.654767916992185, -27.520668526367196],
            [-54.657267916992183, -27.512368526367197],
            [-54.66006791699219, -27.508568526367196],
            [-54.662767916992181, -27.507168526367195],
            [-54.665567916992188, -27.505768526367198],
            [-54.670267916992188, -27.504868526367197],
            [-54.67546791699219, -27.505768526367198],
            [-54.677867916992184, -27.508868526367195],
            [-54.679367916992184, -27.512868526367196],
            [-54.679767916992184, -27.518168526367198],
            [-54.679767916992184, -27.527568526367197],
            [-54.677967916992188, -27.533968526367197],
            [-54.676867916992187, -27.537768526367195],
            [-54.673867916992187, -27.548168526367196],
            [-54.672767916992186, -27.553668526367197],
            [-54.671667916992185, -27.559368526367194],
            [-54.671467916992185, -27.565568526367194],
            [-54.674167916992189, -27.570468526367197],
            [-54.681967916992186, -27.574368526367195],
            [-54.693067916992185, -27.572868526367195],
            [-54.698567916992189, -27.571268526367199],
            [-54.706767916992185, -27.568568526367194],
            [-54.717267916992185, -27.564868526367196],
            [-54.723367916992188, -27.563568526367199],
            [-54.730567916992186, -27.561668526367196],
            [-54.738567916992181, -27.560868526367194],
            [-54.741667916992185, -27.561668526367196],
            [-54.744767916992188, -27.562668526367197],
            [-54.747167916992183, -27.565268526367198],
            [-54.749467916992188, -27.567768526367196],
            [-54.750967916992181, -27.570768526367196],
            [-54.753467916992186, -27.575368526367196],
            [-54.758767916992184, -27.581468526367196],
            [-54.765967916992182, -27.584668526367196],
            [-54.772967916992187, -27.585168526367198],
            [-54.778067916992185, -27.583668526367198],
            [-54.784867916992184, -27.577368526367195],
            [-54.787767916992181, -27.570168526367198],
            [-54.790667916992184, -27.557668526367195],
            [-54.79276791699219, -27.543568526367196],
            [-54.79416791699218, -27.538868526367196],
            [-54.795067916992181, -27.534868526367198],
            [-54.802567916992189, -27.530668526367194],
            [-54.806867916992189, -27.530168526367198],
            [-54.810267916992188, -27.530368526367198],
            [-54.815967916992186, -27.534768526367195],
            [-54.821267916992184, -27.543868526367199],
            [-54.826467916992186, -27.553968526367196],
            [-54.830967916992186, -27.567768526367196],
            [-54.83256791699219, -27.573168526367198],
            [-54.833667916992184, -27.577668526367198],
            [-54.83686791699219, -27.591168526367198],
            [-54.838667916992186, -27.596368526367197],
            [-54.839067916992185, -27.600268526367195],
            [-54.839867916992183, -27.605568526367197],
            [-54.842667916992184, -27.612468526367195],
            [-54.845867916992184, -27.617968526367196],
            [-54.847867916992186, -27.621568526367199],
            [-54.850667916992187, -27.623868526367197],
            [-54.854567916992181, -27.627368526367196],
            [-54.861067916992184, -27.629168526367195],
            [-54.869167916992183, -27.627068526367196],
            [-54.878767916992189, -27.625968526367195],
            [-54.888467916992184, -27.626568526367194],
            [-54.895167916992186, -27.629168526367195],
            [-54.903067916992185, -27.636368526367196],
            [-54.906467916992185, -27.641768526367194],
            [-54.90706791699219, -27.647868526367198],
            [-54.904867916992188, -27.656268526367196],
            [-54.904767916992185, -27.659868526367198],
            [-54.904267916992183, -27.671668526367196],
            [-54.903667916992184, -27.682168526367196],
            [-54.904467916992189, -27.690168526367195],
            [-54.902567916992183, -27.698568526367197],
            [-54.902267916992187, -27.702168526367196],
            [-54.902367916992183, -27.710968526367196],
            [-54.902367916992183, -27.715568526367196],
            [-54.905167916992184, -27.725268526367195],
            [-54.90676791699218, -27.729968526367195],
            [-54.915267916992185, -27.737968526367197],
            [-54.920667916992187, -27.744668526367196],
            [-54.928867916992189, -27.755768526367198],
            [-54.930767916992181, -27.758868526367195],
            [-54.937967916992186, -27.770768526367196],
            [-54.940967916992186, -27.772968526367194],
            [-54.948467916992186, -27.778468526367199],
            [-54.954967916992189, -27.778768526367195],
            [-54.961167916992181, -27.778368526367196],
            [-54.970267916992185, -27.774568526367197],
            [-54.977567916992186, -27.770768526367196],
            [-54.983567916992186, -27.770668526367196],
            [-54.988667916992185, -27.772168526367196],
            [-54.992967916992185, -27.775468526367199],
            [-54.995467916992183, -27.780168526367198],
            [-54.997367916992189, -27.786768526367197],
            [-54.999367916992185, -27.791668526367197],
            [-55.005767916992184, -27.796168526367197],
            [-55.014267916992182, -27.796968526367195],
            [-55.022967916992187, -27.794468526367197],
            [-55.030467916992187, -27.788668526367196],
            [-55.038367916992186, -27.779168526367194],
            [-55.04416791699218, -27.773468526367196],
            [-55.050867916992189, -27.768668526367197],
            [-55.057967916992183, -27.767968526367198],
            [-55.065267916992184, -27.770468526367196],
            [-55.06986791699218, -27.772368526367195],
            [-55.072667916992188, -27.773468526367196],
            [-55.080367916992188, -27.778468526367199],
            [-55.081867916992181, -27.780868526367197],
            [-55.083767916992187, -27.784868526367198],
            [-55.084267916992189, -27.791768526367196],
            [-55.08256791699219, -27.796068526367197],
            [-55.077367916992188, -27.799768526367195],
            [-55.07016791699219, -27.803568526367194],
            [-55.06216791699218, -27.810468526367195],
            [-55.052767916992181, -27.816068526367197],
            [-55.04276791699219, -27.820168526367198],
            [-55.034867916992184, -27.824268526367199],
            [-55.028467916992184, -27.829368526367197],
            [-55.024767916992189, -27.835468526367194],
            [-55.02436791699219, -27.842268526367196],
            [-55.025467916992184, -27.848168526367196],
            [-55.030567916992183, -27.854968526367195],
            [-55.036367916992184, -27.857768526367195],
            [-55.044267916992183, -27.855668526367197],
            [-55.05216791699219, -27.853568526367198],
            [-55.056867916992189, -27.849968526367196],
            [-55.06686791699218, -27.846968526367196],
            [-55.078167916992186, -27.844868526367197],
            [-55.081167916992186, -27.844168526367199],
            [-55.085367916992183, -27.843068526367198],
            [-55.090967916992184, -27.843068526367198],
            [-55.10096791699219, -27.845968526367194],
            [-55.108967916992185, -27.847868526367197],
            [-55.113667916992185, -27.848968526367194],
            [-55.119667916992185, -27.853168526367199],
            [-55.125867916992185, -27.859368526367199],
            [-55.127167916992185, -27.864468526367197],
            [-55.126067916992184, -27.869768526367196],
            [-55.125267916992186, -27.875168526367197],
            [-55.124067916992189, -27.881368526367197],
            [-55.125267916992186, -27.887868526367196],
            [-55.128067916992187, -27.893768526367197],
            [-55.133167916992186, -27.895968526367199],
            [-55.138267916992184, -27.895168526367197],
            [-55.145367916992186, -27.889368526367196],
            [-55.152367916992183, -27.883468526367196],
            [-55.156267916992185, -27.879668526367198],
            [-55.161667916992187, -27.874668526367195],
            [-55.165667916992184, -27.866968526367195],
            [-55.171367916992182, -27.861368526367194],
            [-55.181367916992187, -27.856968526367197],
            [-55.187967916992186, -27.855868526367196],
            [-55.191367916992185, -27.855368526367194],
            [-55.202667916992183, -27.857768526367195],
            [-55.210067916992188, -27.863568526367196],
            [-55.212567916992185, -27.866868526367199],
            [-55.220367916992188, -27.873568526367194],
            [-55.224867916992181, -27.877068526367196],
            [-55.232767916992188, -27.884968526367196],
            [-55.236867916992182, -27.890968526367196],
            [-55.239067916992184, -27.895768526367196],
            [-55.240267916992181, -27.898668526367196],
            [-55.242167916992187, -27.901168526367197],
            [-55.245567916992186, -27.905968526367197],
            [-55.250167916992183, -27.914168526367199],
            [-55.257767916992186, -27.922168526367194],
            [-55.266467916992184, -27.929768526367198],
            [-55.275167916992189, -27.933068526367194],
            [-55.277967916992182, -27.934068526367199],
            [-55.285867916992188, -27.932268526367196],
            [-55.29276791699219, -27.929668526367195],
            [-55.30046791699219, -27.925268526367198],
            [-55.309367916992187, -27.922168526367194],
            [-55.319267916992189, -27.921868526367199],
            [-55.327167916992181, -27.926668526367195],
            [-55.330367916992188, -27.931368526367194],
            [-55.333267916992185, -27.939168526367197],
            [-55.335367916992183, -27.950168526367197],
            [-55.337367916992186, -27.959868526367195],
            [-55.338467916992187, -27.966168526367198],
            [-55.346967916992185, -27.972268526367195],
            [-55.357567916992181, -27.973368526367196],
            [-55.368867916992187, -27.974868526367196],
            [-55.376067916992184, -27.976168526367196],
            [-55.382167916992188, -27.979168526367197],
            [-55.386367916992185, -27.985068526367197],
            [-55.387167916992183, -27.989968526367196],
            [-55.385967916992186, -27.993868526367194],
            [-55.38236791699218, -28.000468526367197],
            [-55.371567916992184, -28.013968526367197],
            [-55.369667916992185, -28.017868526367195],
            [-55.369367916992189, -28.027268526367195],
            [-55.375567916992182, -28.034168526367196],
            [-55.386767916992184, -28.042468526367195],
            [-55.390467916992186, -28.045268526367195],
            [-55.402267916992187, -28.050068526367198],
            [-55.410367916992186, -28.053268526367198],
            [-55.413867916992189, -28.054668526367195],
            [-55.422567916992186, -28.062968526367197],
            [-55.425067916992184, -28.067968526367196],
            [-55.430467916992185, -28.076068526367195],
            [-55.434767916992186, -28.081768526367195],
            [-55.438767916992184, -28.086968526367194],
            [-55.442467916992186, -28.094268526367195],
            [-55.44786791699218, -28.097368526367198],
            [-55.452367916992188, -28.097868526367197],
            [-55.45456791699219, -28.095568526367195],
            [-55.458767916992187, -28.093668526367196],
            [-55.462967916992184, -28.090868526367196],
            [-55.468767916992185, -28.087968526367199],
            [-55.478867916992186, -28.080968526367194],
            [-55.487767916992183, -28.076568526367197],
            [-55.491367916992189, -28.076568526367197],
            [-55.495467916992183, -28.076768526367196],
            [-55.499367916992185, -28.080968526367194],
            [-55.500567916992182, -28.085368526367198],
            [-55.501967916992186, -28.090068526367197],
            [-55.504167916992188, -28.094868526367197],
            [-55.505467916992188, -28.097768526367197],
            [-55.506667916992185, -28.102768526367196],
            [-55.509667916992186, -28.109368526367199],
            [-55.513167916992188, -28.114668526367197],
            [-55.518967916992182, -28.115568526367198],
            [-55.524767916992189, -28.114668526367197],
            [-55.530567916992183, -28.115468526367195],
            [-55.536267916992188, -28.118568526367199],
            [-55.540067916992186, -28.123268526367198],
            [-55.543667916992185, -28.129868526367197],
            [-55.54576791699219, -28.133468526367196],
            [-55.547567916992186, -28.136468526367196],
            [-55.548067916992181, -28.142668526367196],
            [-55.548267916992188, -28.147368526367195],
            [-55.549267916992186, -28.155868526367197],
            [-55.55216791699219, -28.160068526367198],
            [-55.557467916992181, -28.164468526367195],
            [-55.564067916992187, -28.163468526367197],
            [-55.571267916992184, -28.156968526367194],
            [-55.577067916992185, -28.153468526367199],
            [-55.585367916992183, -28.147868526367198],
            [-55.588767916992182, -28.142168526367197],
            [-55.587867916992181, -28.136768526367199],
            [-55.585367916992183, -28.131868526367196],
            [-55.583267916992185, -28.124968526367198],
            [-55.587567916992185, -28.119468526367196],
            [-55.592567916992181, -28.117168526367195],
            [-55.596167916992187, -28.115768526367198],
            [-55.604767916992188, -28.115568526367198],
            [-55.609767916992183, -28.117168526367195],
            [-55.616067916992186, -28.123768526367197],
            [-55.620767916992186, -28.130968526367198],
            [-55.622367916992189, -28.138468526367198],
            [-55.621567916992184, -28.145668526367196],
            [-55.619767916992188, -28.152368526367198],
            [-55.622267916992186, -28.160968526367196],
            [-55.628467916992186, -28.172568526367197],
            [-55.634167916992183, -28.177768526367196],
            [-55.64206791699219, -28.179768526367198],
            [-55.652967916992182, -28.182168526367196],
            [-55.662067916992186, -28.189168526367197],
            [-55.670667916992187, -28.198568526367197],
            [-55.678267916992183, -28.205068526367196],
            [-55.685167916992185, -28.211468526367199],
            [-55.692467916992186, -28.218768526367196],
            [-55.695767916992182, -28.220268526367196],
            [-55.704667916992186, -28.224268526367197],
            [-55.718067916992183, -28.226668526367199],
            [-55.733867916992182, -28.229268526367196],
            [-55.749967916992183, -28.233668526367197],
            [-55.762967916992181, -28.238668526367196],
            [-55.771167916992184, -28.241368526367197],
            [-55.778667916992184, -28.247968526367195],
            [-55.78206791699219, -28.255268526367196],
            [-55.780067916992181, -28.265068526367195],
            [-55.772167916992188, -28.274368526367198],
            [-55.768767916992189, -28.275468526367199],
            [-55.755667916992181, -28.279468526367197],
            [-55.751267916992184, -28.280168526367198],
            [-55.742967916992185, -28.281268526367196],
            [-55.735667916992185, -28.284568526367195],
            [-55.732167916992182, -28.286368526367198],
            [-55.723367916992188, -28.292768526367198],
            [-55.716667916992186, -28.298968526367197],
            [-55.71186791699219, -28.303868526367197],
            [-55.701767916992182, -28.311168526367197],
            [-55.694267916992189, -28.315168526367195],
            [-55.686867916992185, -28.318768526367194],
            [-55.68016791699219, -28.322168526367197],
            [-55.674467916992185, -28.327968526367197],
            [-55.670367916992184, -28.334368526367196],
            [-55.66916791699218, -28.340668526367196],
            [-55.67076791699219, -28.345668526367199],
            [-55.674667916992185, -28.356368526367199],
            [-55.68016791699219, -28.369768526367196],
            [-55.685467916992181, -28.381368526367197],
            [-55.688767916992184, -28.388168526367195],
            [-55.690567916992187, -28.395968526367199],
            [-55.690567916992187, -28.399768526367197],
            [-55.690467916992183, -28.404768526367196],
            [-55.690567916992187, -28.413168526367194],
            [-55.691567916992184, -28.415868526367198],
            [-55.69516791699219, -28.422868526367196],
            [-55.704667916992186, -28.427468526367196],
            [-55.713167916992184, -28.425568526367197],
            [-55.716467916992187, -28.422168526367194],
            [-55.719767916992183, -28.418668526367199],
            [-55.723067916992186, -28.407368526367197],
            [-55.724967916992185, -28.401968526367199],
            [-55.728167916992184, -28.392468526367196],
            [-55.734367916992184, -28.381268526367194],
            [-55.739967916992185, -28.376968526367197],
            [-55.746367916992185, -28.373268526367198],
            [-55.756567916992182, -28.369368526367197],
            [-55.76706791699219, -28.368068526367196],
            [-55.776267916992182, -28.367568526367194],
            [-55.781267916992185, -28.367268526367198],
            [-55.785367916992186, -28.367268526367198],
            [-55.796367916992182, -28.367268526367198],
            [-55.805467916992185, -28.366168526367197],
            [-55.80946791699219, -28.365768526367198],
            [-55.82156791699218, -28.363568526367196],
            [-55.838467916992187, -28.358368526367194],
            [-55.847367916992184, -28.356068526367196],
            [-55.850567916992183, -28.355368526367194],
            [-55.859467916992187, -28.354768526367195],
            [-55.871067916992189, -28.357168526367197],
            [-55.878767916992189, -28.361868526367196],
            [-55.881567916992182, -28.368368526367199],
            [-55.882767916992186, -28.371068526367196],
            [-55.884567916992182, -28.374768526367198],
            [-55.886467916992189, -28.377068526367196],
            [-55.89006791699218, -28.381068526367194],
            [-55.895667916992181, -28.389868526367195],
            [-55.900367916992181, -28.398368526367197],
            [-55.903067916992185, -28.407568526367196],
            [-55.902267916992187, -28.411468526367194],
            [-55.901467916992189, -28.415568526367196],
            [-55.900867916992183, -28.418668526367199],
            [-55.895467916992189, -28.429468526367195],
            [-55.88906791699219, -28.444668526367195],
            [-55.884867916992185, -28.456868526367195],
            [-55.882467916992184, -28.464468526367199],
            [-55.881267916992186, -28.473468526367196],
            [-55.883867916992187, -28.479168526367197],
            [-55.896867916992186, -28.480668526367197],
            [-55.907267916992183, -28.481468526367195],
            [-55.911167916992184, -28.482568526367196],
            [-55.923867916992187, -28.485268526367197],
            [-55.928667916992183, -28.486368526367194],
            [-55.935467916992181, -28.489668526367197],
            [-55.939967916992188, -28.491968526367195],
            [-55.94516791699219, -28.493368526367199],
            [-55.949267916992184, -28.494468526367196],
            [-55.960367916992183, -28.494768526367196],
            [-55.97096791699218, -28.496368526367196],
            [-55.987167916992185, -28.497968526367195],
            [-56.002067916992189, -28.501968526367197],
            [-56.010267916992184, -28.506368526367197],
            [-56.017567916992185, -28.513568526367195],
            [-56.023167916992186, -28.522668526367198],
            [-56.024467916992187, -28.528668526367198],
            [-56.025667916992184, -28.534768526367195],
            [-56.024767916992189, -28.541968526367196],
            [-56.02406791699218, -28.545668526367194],
            [-56.020767916992185, -28.553268526367198],
            [-56.012967916992181, -28.562968526367197],
            [-56.010167916992188, -28.565868526367197],
            [-56.00736791699218, -28.568768526367194],
            [-56.005267916992182, -28.572468526367196],
            [-56.002367916992185, -28.578268526367197],
            [-56.001267916992184, -28.587968526367199],
            [-56.003867916992185, -28.598368526367196],
            [-56.009667916992186, -28.605668526367197],
            [-56.015767916992189, -28.611168526367194],
            [-56.018567916992183, -28.613368526367196],
            [-56.022167916992188, -28.615068526367196],
            [-56.026567916992185, -28.617268526367198],
            [-56.030667916992186, -28.619068526367194],
            [-56.036667916992187, -28.621568526367199],
            [-56.046067916992186, -28.623668526367197],
            [-56.05616791699218, -28.629168526367195],
            [-56.06386791699218, -28.635468526367198],
            [-56.07186791699219, -28.643468526367194],
            [-56.07656791699219, -28.648668526367196],
            [-56.081867916992181, -28.654168526367194],
            [-56.086167916992181, -28.656968526367194],
            [-56.094767916992183, -28.664168526367199],
            [-56.102067916992183, -28.669668526367197],
            [-56.108667916992189, -28.674668526367196],
            [-56.119067916992186, -28.681168526367195],
            [-56.125267916992186, -28.687468526367198],
            [-56.13136791699219, -28.694668526367195],
            [-56.133867916992187, -28.698168526367198],
            [-56.137767916992189, -28.703568526367196],
            [-56.14036791699219, -28.706068526367197],
            [-56.143267916992187, -28.709068526367197],
            [-56.149267916992187, -28.717668526367198],
            [-56.150967916992187, -28.720868526367198],
            [-56.155167916992184, -28.730568526367197],
            [-56.160667916992182, -28.739668526367197],
            [-56.162867916992184, -28.742668526367197],
            [-56.165567916992188, -28.746568526367199],
            [-56.17216791699218, -28.753468526367197],
            [-56.17716791699219, -28.760668526367198],
            [-56.183067916992186, -28.767668526367196],
            [-56.188067916992182, -28.772268526367196],
            [-56.194067916992182, -28.775168526367196],
            [-56.201067916992187, -28.774268526367194],
            [-56.210467916992187, -28.774568526367197],
            [-56.223167916992182, -28.776768526367196],
            [-56.236967916992185, -28.777568526367197],
            [-56.24966791699218, -28.777868526367197],
            [-56.260967916992186, -28.778968526367194],
            [-56.271167916992184, -28.781968526367194],
            [-56.276767916992185, -28.785568526367197],
            [-56.282367916992186, -28.788768526367196],
            [-56.289167916992184, -28.793668526367199],
            [-56.29446791699219, -28.799968526367195],
            [-56.299667916992185, -28.808068526367194],
            [-56.301167916992185, -28.816968526367198],
            [-56.298567916992184, -28.830968526367194],
            [-56.297267916992183, -28.841268526367195],
            [-56.29746791699219, -28.857268526367196],
            [-56.298567916992184, -28.873268526367198],
            [-56.298167916992185, -28.885168526367195],
            [-56.300367916992187, -28.896268526367194],
            [-56.303667916992183, -28.902968526367196],
            [-56.308867916992185, -28.908668526367194],
            [-56.315567916992187, -28.916368526367194],
            [-56.322367916992185, -28.923968526367197],
            [-56.327367916992188, -28.928368526367194],
            [-56.332867916992186, -28.932168526367196],
            [-56.344167916992184, -28.939968526367196],
            [-56.362467916992188, -28.944168526367196],
            [-56.378867916992185, -28.947968526367198],
            [-56.39006791699218, -28.955668526367198],
            [-56.397067916992185, -28.962868526367195],
            [-56.406267916992185, -28.972568526367198],
            [-56.412067916992186, -28.982868526367199],
            [-56.413967916992185, -28.987568526367198],
            [-56.415867916992184, -28.992168526367195],
            [-56.415667916992184, -28.995868526367197],
            [-56.416167916992187, -29.000568526367196],
            [-56.412767916992181, -29.005268526367196],
            [-56.40676791699218, -29.008868526367195],
            [-56.402267916992187, -29.012068526367194],
            [-56.399767916992189, -29.015968526367196],
            [-56.40076791699218, -29.024568526367197],
            [-56.404267916992183, -29.032368526367197],
            [-56.409867916992184, -29.038468526367197],
            [-56.412267916992185, -29.047168526367194],
            [-56.413067916992183, -29.054368526367199],
            [-56.413067916992183, -29.061868526367196],
            [-56.415967916992187, -29.068868526367197],
            [-56.420867916992187, -29.074568526367194],
            [-56.430467916992185, -29.079568526367197],
            [-56.438467916992181, -29.080768526367194],
            [-56.445967916992188, -29.081868526367195],
            [-56.459267916992189, -29.083568526367195],
            [-56.462967916992184, -29.084068526367197],
            [-56.476467916992185, -29.086168526367196],
            [-56.489167916992187, -29.086868526367198],
            [-56.502967916992183, -29.088668526367197],
            [-56.513967916992186, -29.092268526367196],
            [-56.523467916992189, -29.097868526367197],
            [-56.532767916992185, -29.104668526367199],
            [-56.537567916992188, -29.111368526367194],
            [-56.540267916992185, -29.113068526367197],
            [-56.54416791699218, -29.115568526367198],
            [-56.55046791699219, -29.116468526367196],
            [-56.554367916992184, -29.115768526367198],
            [-56.557167916992185, -29.114968526367196],
            [-56.560467916992181, -29.114768526367197],
            [-56.567967916992188, -29.114068526367198],
            [-56.577167916992181, -29.115068526367196],
            [-56.588667916992186, -29.119768526367196],
            [-56.593767916992185, -29.126268526367198],
            [-56.594767916992183, -29.136268526367196],
            [-56.600667916992187, -29.145668526367196],
            [-56.610767916992188, -29.160268526367197],
            [-56.613567916992181, -29.165068526367197],
            [-56.616867916992184, -29.171068526367197],
            [-56.623067916992184, -29.177568526367196],
            [-56.634167916992183, -29.189068526367194],
            [-56.640767916992189, -29.194568526367195],
            [-56.645767916992185, -29.200268526367196],
            [-56.648367916992186, -29.206868526367195],
            [-56.651167916992186, -29.213968526367196],
            [-56.654767916992185, -29.223768526367195],
            [-56.654767916992185, -29.231068526367196],
            [-56.652267916992187, -29.239768526367197],
            [-56.64906791699218, -29.254868526367197],
            [-56.650667916992184, -29.264268526367196],
            [-56.657567916992186, -29.280868526367197],
            [-56.660167916992187, -29.285968526367196],
            [-56.66446791699218, -29.294968526367196],
            [-56.68416791699218, -29.326068526367195],
            [-56.689067916992187, -29.333968526367194],
            [-56.691667916992188, -29.339468526367199],
            [-56.69986791699219, -29.356368526367199],
            [-56.705367916992188, -29.360868526367199],
            [-56.713167916992184, -29.367168526367195],
            [-56.730067916992184, -29.368668526367195],
            [-56.746167916992185, -29.368268526367196],
            [-56.758767916992184, -29.370868526367197],
            [-56.767667916992181, -29.377668526367195],
            [-56.770667916992181, -29.382368526367195],
            [-56.773167916992186, -29.387368526367197],
            [-56.775167916992189, -29.394768526367194],
            [-56.777567916992183, -29.404068526367197],
            [-56.779867916992188, -29.414868526367197],
            [-56.779267916992183, -29.419768526367196],
            [-56.779467916992189, -29.423968526367197],
            [-56.777667916992186, -29.428968526367196],
            [-56.777867916992186, -29.432168526367196],
            [-56.779467916992189, -29.437368526367194],
            [-56.785567916992186, -29.441668526367195],
            [-56.796867916992184, -29.449068526367196],
            [-56.801367916992184, -29.454868526367196],
            [-56.803967916992185, -29.459568526367196],
            [-56.805467916992185, -29.466268526367195],
            [-56.808267916992186, -29.473068526367197],
            [-56.811567916992189, -29.477768526367196],
            [-56.81386791699218, -29.481068526367196],
            [-56.822067916992182, -29.489968526367196],
            [-56.831267916992189, -29.494668526367196],
            [-56.843967916992185, -29.499068526367196],
            [-56.846767916992185, -29.500468526367197],
            [-56.856467916992187, -29.505168526367196],
            [-56.861767916992186, -29.507768526367194],
            [-56.872767916992188, -29.514668526367196],
            [-56.887967916992181, -29.526468526367196],
            [-56.901867916992188, -29.533768526367197],
            [-56.911367916992184, -29.543868526367199],
            [-56.915567916992188, -29.548568526367198],
            [-56.920667916992187, -29.554468526367195],
            [-56.929467916992181, -29.566268526367196],
            [-56.933367916992182, -29.568768526367194],
            [-56.939967916992188, -29.572868526367195],
            [-56.950967916992184, -29.580668526367198],
            [-56.958667916992184, -29.589568526367195],
            [-56.963667916992186, -29.596168526367194],
            [-56.967667916992184, -29.601768526367195],
            [-56.971367916992186, -29.610268526367197],
            [-56.972067916992188, -29.617268526367198],
            [-56.969467916992187, -29.622368526367197],
            [-56.967267916992185, -29.629968526367197],
            [-56.967867916992184, -29.638168526367195],
            [-56.97096791699218, -29.643268526367194],
            [-56.98026791699219, -29.644368526367195],
            [-56.990567916992184, -29.646568526367197],
            [-57.000867916992185, -29.651868526367195],
            [-57.006567916992182, -29.658168526367199],
            [-57.011467916992189, -29.664468526367195],
            [-57.016067916992185, -29.674768526367195],
            [-57.021167916992184, -29.683268526367197],
            [-57.026967916992184, -29.689368526367197],
            [-57.033367916992184, -29.695668526367196],
            [-57.045367916992184, -29.701568526367197],
            [-57.05216791699219, -29.707568526367197],
            [-57.057267916992188, -29.713668526367197],
            [-57.069267916992189, -29.722068526367195],
            [-57.082867916992186, -29.734668526367194],
            [-57.094467916992187, -29.743868526367194],
            [-57.099567916992186, -29.748268526367198],
            [-57.102767916992185, -29.750268526367197],
            [-57.106367916992184, -29.752668526367195],
            [-57.111767916992186, -29.756268526367194],
            [-57.120567916992186, -29.762068526367194],
            [-57.123867916992182, -29.764568526367196],
            [-57.132767916992186, -29.770368526367196],
            [-57.151967916992184, -29.774768526367197],
            [-57.171967916992187, -29.780668526367194],
            [-57.180567916992189, -29.781168526367196],
            [-57.195267916992186, -29.779768526367196],
            [-57.207867916992186, -29.777668526367197],
            [-57.22096791699218, -29.776268526367197],
            [-57.235367916992189, -29.780668526367194],
            [-57.24836791699218, -29.792768526367198],
            [-57.25736791699218, -29.800468526367197],
            [-57.267667916992181, -29.809368526367194],
            [-57.270367916992186, -29.810768526367198],
            [-57.28206791699219, -29.817168526367198],
            [-57.287067916992186, -29.823168526367198],
            [-57.297767916992186, -29.834668526367196],
            [-57.304667916992187, -29.844768526367197],
            [-57.314467916992186, -29.859968526367197],
            [-57.32456791699218, -29.872768526367196],
            [-57.326267916992187, -29.877968526367198],
            [-57.328267916992189, -29.883868526367195],
            [-57.329667916992186, -29.887468526367197],
            [-57.329067916992187, -29.895368526367196],
            [-57.328567916992185, -29.902568526367197],
            [-57.326267916992187, -29.911968526367197],
            [-57.324067916992185, -29.921668526367196],
            [-57.324067916992185, -29.925268526367198],
            [-57.323967916992189, -29.939168526367197],
            [-57.325467916992181, -29.954568526367197],
            [-57.326867916992185, -29.971468526367197],
            [-57.337567916992185, -29.989968526367196],
            [-57.339767916992187, -29.992468526367198],
            [-57.344867916992186, -29.998268526367198],
            [-57.352767916992185, -30.008068526367197],
            [-57.357467916992185, -30.009968526367196],
            [-57.362167916992185, -30.011868526367195],
            [-57.370467916992183, -30.014068526367197],
            [-57.383067916992189, -30.017568526367196],
            [-57.396267916992187, -30.022668526367198],
            [-57.407667916992182, -30.030168526367198],
            [-57.418067916992186, -30.038968526367196],
            [-57.429167916992185, -30.050568526367197],
            [-57.436667916992185, -30.059868526367197],
            [-57.447467916992181, -30.072168526367197],
            [-57.453567916992185, -30.081468526367196],
            [-57.455667916992184, -30.090168526367197],
            [-57.458167916992181, -30.100868526367197],
            [-57.459767916992185, -30.103368526367198],
            [-57.462267916992189, -30.107068526367197],
            [-57.464567916992181, -30.110268526367197],
            [-57.475267916992181, -30.117968526367196],
            [-57.485567916992181, -30.124368526367196],
            [-57.503467916992186, -30.135768526367194],
            [-57.522567916992188, -30.149268526367194],
            [-57.525467916992184, -30.151268526367197],
            [-57.528667916992184, -30.152668526367197],
            [-57.546667916992185, -30.161168526367199],
            [-57.569167916992185, -30.171468526367196],
            [-57.587967916992184, -30.178268526367198],
            [-57.594067916992188, -30.178968526367196],
            [-57.600267916992181, -30.178568526367194],
            [-57.603667916992187, -30.178268526367198],
            [-57.609267916992181, -30.177568526367196],
            [-57.617267916992184, -30.176168526367196],
            [-57.624967916992183, -30.174968526367195],
            [-57.63266791699219, -30.174768526367195],
            [-57.639567916992185, -30.176668526367195],
            [-57.644567916992187, -30.179668526367195],
            [-57.646767916992189, -30.181668526367197],
            [-57.648667916992181, -30.185768526367198],
            [-57.649567916992183, -30.188768526367198],
            [-57.644367916992181, -30.193168526367195],
            [-57.639667916992181, -30.190168526367195],
            [-57.636467916992189, -30.188468526367195],
            [-57.632067916992185, -30.186968526367195],
            [-57.62636791699218, -30.186668526367196],
            [-57.620467916992183, -30.187468526367198],
            [-57.61466791699219, -30.189168526367197],
            [-57.611167916992187, -30.189068526367194],
            [-57.603367916992184, -30.189668526367196],
            [-57.593767916992185, -30.194368526367196],
            [-57.584367916992186, -30.199068526367196],
            [-57.573267916992187, -30.204668526367197],
            [-57.562967916992186, -30.209268526367197],
            [-57.559867916992189, -30.211568526367195],
            [-57.557667916992187, -30.213668526367197],
            [-57.556367916992187, -30.219468526367194],
            [-57.55616791699218, -30.224968526367196],
            [-57.558067916992186, -30.234268526367195],
            [-57.561367916992182, -30.240468526367195],
            [-57.56416791699219, -30.245868526367197],
            [-57.565767916992186, -30.249468526367195],
            [-57.567967916992188, -30.252168526367196],
            [-57.567467916992186, -30.255968526367198],
            [-57.566367916992185, -30.258768526367199],
            [-57.562367916992187, -30.261768526367199],
            [-57.558067916992186, -30.263168526367195],
            [-57.555567916992189, -30.264668526367196],
            [-57.553367916992187, -30.267868526367195],
            [-57.550767916992186, -30.272568526367195],
            [-57.54716791699218, -30.274868526367197],
            [-57.542067916992181, -30.276968526367199],
            [-57.53676791699219, -30.279468526367197],
            [-57.531567916992188, -30.281268526367196],
            [-57.528167916992189, -30.283368526367198],
            [-57.525067916992185, -30.285368526367197],
            [-57.521267916992187, -30.285868526367196],
            [-57.51706791699219, -30.285568526367197],
            [-57.512767916992189, -30.285168526367194],
            [-57.50906791699218, -30.285868526367196],
            [-57.505967916992184, -30.286968526367197],
            [-57.503467916992186, -30.288368526367197],
            [-57.499067916992189, -30.288068526367198],
            [-57.49666791699218, -30.284468526367196],
            [-57.495567916992186, -30.279768526367196],
            [-57.493267916992181, -30.275868526367198],
            [-57.491467916992185, -30.272068526367196],
            [-57.489967916992185, -30.268968526367196],
            [-57.486667916992189, -30.266768526367194],
            [-57.48166791699218, -30.266568526367195],
            [-57.476467916992185, -30.266768526367194],
            [-57.471767916992185, -30.266068526367196],
            [-57.464767916992187, -30.265168526367198],
            [-57.460467916992187, -30.267368526367196],
            [-57.457667916992186, -30.271168526367198],
            [-57.455467916992184, -30.274468526367194],
            [-57.452167916992181, -30.275968526367194],
            [-57.44816791699219, -30.275968526367194],
            [-57.44486791699218, -30.275368526367195],
            [-57.439867916992185, -30.275468526367199],
            [-57.435767916992184, -30.275668526367198],
            [-57.428267916992183, -30.274868526367197],
            [-57.423967916992183, -30.276168526367197],
            [-57.419667916992182, -30.277268526367195],
            [-57.416367916992186, -30.277868526367197],
            [-57.414967916992182, -30.280668526367194],
            [-57.418867916992184, -30.284568526367195],
            [-57.421467916992185, -30.289168526367199],
            [-57.421467916992185, -30.293168526367197],
            [-57.421467916992185, -30.299968526367195],
            [-57.419267916992183, -30.302468526367196],
            [-57.415667916992184, -30.301868526367194],
            [-57.412367916992181, -30.300268526367198],
            [-57.407567916992186, -30.300468526367197],
            [-57.402867916992186, -30.301068526367196],
            [-57.39906791699218, -30.303568526367194],
            [-57.391467916992184, -30.304368526367199],
            [-57.389567916992185, -30.301168526367196],
            [-57.389567916992185, -30.296468526367196],
            [-57.387467916992186, -30.293068526367197],
            [-57.384567916992182, -30.288468526367197],
            [-57.38266791699219, -30.285368526367197],
            [-57.380767916992184, -30.282368526367197],
            [-57.378767916992189, -30.279468526367197],
            [-57.374767916992184, -30.276268526367197],
            [-57.369767916992188, -30.272968526367194],
            [-57.364967916992185, -30.270968526367199],
            [-57.361867916992182, -30.270768526367196],
            [-57.358867916992182, -30.272368526367195],
            [-57.357567916992181, -30.276768526367196],
            [-57.357767916992188, -30.281668526367199],
            [-57.356067916992188, -30.284168526367196],
            [-57.352767916992185, -30.284768526367195],
            [-57.34756791699219, -30.280368526367198],
            [-57.342867916992184, -30.277668526367197],
            [-57.339067916992185, -30.276168526367197],
            [-57.333167916992181, -30.273968526367199],
            [-57.329267916992187, -30.272568526367195],
            [-57.325667916992188, -30.272068526367196],
            [-57.321767916992187, -30.271268526367194],
            [-57.319567916992185, -30.269368526367195],
            [-57.317967916992188, -30.263568526367195],
            [-57.316967916992184, -30.260268526367199],
            [-57.314367916992182, -30.257668526367198],
            [-57.311267916992186, -30.258068526367197],
            [-57.306967916992186, -30.262368526367197],
            [-57.302567916992189, -30.268668526367197],
            [-57.301067916992181, -30.273968526367199],
            [-57.29746791699219, -30.279868526367196],
            [-57.293067916992186, -30.286368526367198],
            [-57.288967916992185, -30.290568526367196],
            [-57.284467916992185, -30.293668526367199],
            [-57.280867916992186, -30.294168526367194],
            [-57.277667916992186, -30.292468526367195],
            [-57.275367916992181, -30.288168526367194],
            [-57.271167916992184, -30.285368526367197],
            [-57.269067916992185, -30.281768526367195],
            [-57.27006791699219, -30.277568526367197],
            [-57.269267916992185, -30.273668526367196],
            [-57.267667916992181, -30.270968526367199],
            [-57.262667916992186, -30.267868526367195],
            [-57.258467916992188, -30.267068526367197],
            [-57.256267916992186, -30.269668526367198],
            [-57.252967916992183, -30.274468526367194],
            [-57.25436791699218, -30.277868526367197],
            [-57.255267916992182, -30.282568526367196],
            [-57.253567916992182, -30.286268526367195],
            [-57.249967916992183, -30.289168526367199],
            [-57.245867916992182, -30.289468526367195],
            [-57.238267916992186, -30.289168526367199],
            [-57.229267916992185, -30.288968526367196],
            [-57.220267916992185, -30.289268526367195],
            [-57.215867916992181, -30.288168526367194],
            [-57.212867916992181, -30.286668526367194],
            [-57.210067916992188, -30.284868526367198],
            [-57.205367916992188, -30.284868526367198],
            [-57.20256791699218, -30.283168526367199],
            [-57.202067916992185, -30.279568526367196],
            [-57.199667916992183, -30.276168526367197],
            [-57.197467916992181, -30.268968526367196],
            [-57.19656791699218, -30.265368526367197],
            [-57.19386791699219, -30.262968526367196],
            [-57.189867916992185, -30.260368526367195],
            [-57.186067916992187, -30.258268526367196],
            [-57.184367916992187, -30.255968526367198],
            [-57.18016791699219, -30.253568526367197],
            [-57.175867916992189, -30.250868526367196],
            [-57.170667916992187, -30.250268526367197],
            [-57.167567916992184, -30.248268526367198],
            [-57.168367916992182, -30.245268526367198],
            [-57.166767916992185, -30.241368526367197],
            [-57.162767916992181, -30.242268526367198],
            [-57.15876791699219, -30.238868526367199],
            [-57.155867916992186, -30.234768526367198],
            [-57.157667916992182, -30.230568526367197],
            [-57.160967916992185, -30.228568526367198],
            [-57.163867916992189, -30.226968526367195],
            [-57.165267916992185, -30.222768526367197],
            [-57.163167916992187, -30.218168526367194],
            [-57.167267916992188, -30.217368526367196],
            [-57.165267916992185, -30.213968526367196],
            [-57.166767916992185, -30.210168526367198],
            [-57.168167916992189, -30.207268526367194],
            [-57.167567916992184, -30.202868526367197],
            [-57.168167916992189, -30.198468526367197],
            [-57.166367916992186, -30.196068526367196],
            [-57.162867916992184, -30.195268526367194],
            [-57.16006791699219, -30.193468526367198],
            [-57.158367916992184, -30.190168526367195],
            [-57.15846791699218, -30.185268526367196],
            [-57.155067916992181, -30.182468526367195],
            [-57.149267916992187, -30.176868526367194],
            [-57.145967916992184, -30.173068526367196],
            [-57.144867916992183, -30.168968526367195],
            [-57.142967916992184, -30.164868526367197],
            [-57.139667916992181, -30.162068526367197],
            [-57.137667916992186, -30.159568526367195],
            [-57.135167916992188, -30.156668526367199],
            [-57.133467916992188, -30.152268526367195],
            [-57.132067916992185, -30.148268526367197],
            [-57.130267916992182, -30.144568526367195],
            [-57.129567916992187, -30.141368526367195],
            [-57.125967916992181, -30.140468526367197],
            [-57.12196791699219, -30.141768526367194],
            [-57.118567916992184, -30.145068526367197],
            [-57.114467916992183, -30.145368526367196],
            [-57.111767916992186, -30.143468526367194],
            [-57.110067916992186, -30.140668526367197],
            [-57.108667916992189, -30.138168526367195],
            [-57.10666791699218, -30.133768526367199],
            [-57.105667916992189, -30.130168526367196],
            [-57.106367916992184, -30.125868526367196],
            [-57.10966791699218, -30.127968526367198],
            [-57.113967916992181, -30.127168526367196],
            [-57.114767916992186, -30.123868526367197],
            [-57.116367916992189, -30.120768526367197],
            [-57.116467916992185, -30.117668526367197],
            [-57.11466791699219, -30.113868526367199],
            [-57.110567916992181, -30.115068526367196],
            [-57.107867916992184, -30.116368526367197],
            [-57.10526791699219, -30.115068526367196],
            [-57.099567916992186, -30.114668526367197],
            [-57.095567916992181, -30.115068526367196],
            [-57.094967916992189, -30.119668526367196],
            [-57.092367916992188, -30.121668526367195],
            [-57.089267916992185, -30.120468526367198],
            [-57.087267916992189, -30.118268526367196],
            [-57.085067916992188, -30.114968526367196],
            [-57.085467916992187, -30.111468526367197],
            [-57.091167916992184, -30.112868526367194],
            [-57.091667916992186, -30.109668526367194],
            [-57.087967916992184, -30.106768526367198],
            [-57.087267916992189, -30.101968526367195],
            [-57.084867916992181, -30.098868526367198],
            [-57.082967916992189, -30.095968526367194],
            [-57.080667916992184, -30.093368526367197],
            [-57.078267916992189, -30.091468526367194],
            [-57.075367916992185, -30.090668526367196],
            [-57.072067916992182, -30.087968526367199],
            [-57.067667916992185, -30.085368526367198],
            [-57.063767916992184, -30.086168526367196],
            [-57.061967916992188, -30.089068526367196],
            [-57.066267916992189, -30.090468526367196],
            [-57.067667916992185, -30.094168526367199],
            [-57.06886791699219, -30.098368526367196],
            [-57.067067916992187, -30.101268526367196],
            [-57.064067916992187, -30.100868526367197],
            [-57.06086791699218, -30.099268526367197],
            [-57.057767916992184, -30.098468526367196],
            [-57.054367916992184, -30.100268526367195],
            [-57.049167916992189, -30.102868526367196],
            [-57.045267916992188, -30.105568526367197],
            [-57.041067916992183, -30.105068526367198],
            [-57.035867916992188, -30.103568526367198],
            [-57.03206791699219, -30.102368526367197],
            [-57.030067916992181, -30.099768526367196],
            [-57.030067916992181, -30.096268526367197],
            [-57.02876791699218, -30.092668526367198],
            [-57.024767916992189, -30.088468526367194],
            [-57.019367916992181, -30.087568526367196],
            [-57.012667916992186, -30.087568526367196],
            [-57.008867916992187, -30.087668526367196],
            [-57.005767916992184, -30.087668526367196],
            [-57.00166791699219, -30.087268526367197],
            [-56.997767916992188, -30.086268526367196],
            [-56.992167916992187, -30.085968526367196],
            [-56.986167916992187, -30.087268526367197],
            [-56.982067916992186, -30.089268526367196],
            [-56.97996791699218, -30.092668526367198],
            [-56.978067916992188, -30.095368526367196],
            [-56.97596791699219, -30.097568526367198],
            [-56.971467916992182, -30.097068526367195],
            [-56.967667916992184, -30.097568526367198],
            [-56.963367916992183, -30.098968526367194],
            [-56.959667916992188, -30.099968526367196],
            [-56.95456791699219, -30.100668526367194],
            [-56.951367916992183, -30.099768526367196],
            [-56.948467916992186, -30.099168526367194],
            [-56.944267916992189, -30.098468526367196],
            [-56.940467916992183, -30.096968526367196],
            [-56.936867916992185, -30.094868526367197],
            [-56.931367916992187, -30.093768526367196],
            [-56.926067916992181, -30.095268526367196],
            [-56.921067916992186, -30.095868526367198],
            [-56.916767916992185, -30.096668526367196],
            [-56.914167916992184, -30.099468526367197],
            [-56.911667916992187, -30.102268526367197],
            [-56.913167916992187, -30.105668526367197],
            [-56.914867916992186, -30.109268526367195],
            [-56.911667916992187, -30.109968526367197],
            [-56.908167916992184, -30.107468526367196],
            [-56.905067916992181, -30.108568526367197],
            [-56.904767916992185, -30.112568526367198],
            [-56.900867916992183, -30.110068526367197],
            [-56.898467916992189, -30.107868526367199],
            [-56.895167916992186, -30.104468526367196],
            [-56.891867916992183, -30.100268526367195],
            [-56.89036791699219, -30.097268526367195],
            [-56.889367916992185, -30.093668526367196],
            [-56.88906791699219, -30.090068526367197],
            [-56.886767916992184, -30.087868526367195],
            [-56.883567916992185, -30.087568526367196],
            [-56.879867916992183, -30.088268526367195],
            [-56.874767916992184, -30.086568526367195],
            [-56.873767916992186, -30.089868526367198],
            [-56.873067916992184, -30.092868526367198],
            [-56.869767916992188, -30.093668526367196],
            [-56.866867916992184, -30.091668526367194],
            [-56.864467916992183, -30.090068526367197],
            [-56.857267916992186, -30.088968526367196],
            [-56.854167916992182, -30.088768526367197],
            [-56.850367916992184, -30.088768526367197],
            [-56.842567916992181, -30.090168526367197],
            [-56.833467916992184, -30.092268526367196],
            [-56.827467916992184, -30.094268526367195],
            [-56.824067916992185, -30.095568526367195],
            [-56.822067916992182, -30.097868526367197],
            [-56.820967916992188, -30.100668526367194],
            [-56.81886791699219, -30.104968526367195],
            [-56.815567916992187, -30.107468526367196],
            [-56.812467916992183, -30.106468526367195],
            [-56.810467916992181, -30.103568526367198],
            [-56.807167916992185, -30.103668526367194],
            [-56.804667916992187, -30.105868526367196],
            [-56.802967916992188, -30.110068526367197],
            [-56.801867916992187, -30.116068526367194],
            [-56.801567916992184, -30.120068526367199],
            [-56.799367916992182, -30.122268526367197],
            [-56.795867916992187, -30.123068526367199],
            [-56.79246791699218, -30.123368526367194],
            [-56.78946791699218, -30.124368526367196],
            [-56.784567916992188, -30.125468526367197],
            [-56.780167916992184, -30.126568526367194],
            [-56.778167916992189, -30.130668526367195],
            [-56.777867916992186, -30.134068526367194],
            [-56.77876791699218, -30.137068526367194],
            [-56.780067916992181, -30.140768526367197],
            [-56.781467916992185, -30.145168526367197],
            [-56.78346791699218, -30.147968526367194],
            [-56.783867916992186, -30.151168526367197],
            [-56.782267916992183, -30.157568526367196],
            [-56.777267916992187, -30.162868526367198],
            [-56.773967916992184, -30.164168526367199],
            [-56.769067916992185, -30.163068526367198],
            [-56.764367916992185, -30.161368526367198],
            [-56.759567916992182, -30.160568526367197],
            [-56.755267916992182, -30.162268526367196],
            [-56.751567916992187, -30.164568526367198],
            [-56.746567916992184, -30.166668526367197],
            [-56.741367916992189, -30.168068526367197],
            [-56.736367916992187, -30.170368526367199],
            [-56.732767916992188, -30.170868526367194],
            [-56.729767916992188, -30.171668526367196],
            [-56.725567916992183, -30.174468526367196],
            [-56.720567916992181, -30.178868526367197],
            [-56.71626791699218, -30.179168526367196],
            [-56.712867916992181, -30.176668526367195],
            [-56.709867916992181, -30.175768526367197],
            [-56.705767916992187, -30.176168526367196],
            [-56.704867916992185, -30.179668526367195],
            [-56.706167916992186, -30.183268526367197],
            [-56.707867916992186, -30.187768526367197],
            [-56.704267916992187, -30.193468526367198],
            [-56.702067916992185, -30.195568526367197],
            [-56.699967916992186, -30.198568526367197],
            [-56.69786791699218, -30.203568526367196],
            [-56.692067916992187, -30.202568526367195],
            [-56.689067916992187, -30.199668526367198],
            [-56.686067916992187, -30.196668526367198],
            [-56.682767916992184, -30.194868526367195],
            [-56.680067916992186, -30.195768526367196],
            [-56.679467916992181, -30.199568526367194],
            [-56.675267916992183, -30.199968526367197],
            [-56.669967916992185, -30.199268526367199],
            [-56.666067916992183, -30.198168526367198],
            [-56.662867916992184, -30.197368526367196],
            [-56.660267916992183, -30.198868526367196],
            [-56.65706791699219, -30.202068526367196],
            [-56.653367916992181, -30.206068526367197],
            [-56.649467916992187, -30.206168526367197],
            [-56.646467916992187, -30.204568526367197],
            [-56.645667916992181, -30.207868526367196],
            [-56.648967916992184, -30.211268526367196],
            [-56.654267916992183, -30.212668526367196],
            [-56.65846791699218, -30.213468526367194],
            [-56.662467916992185, -30.215668526367196],
            [-56.665567916992188, -30.216268526367195],
            [-56.668167916992189, -30.217568526367195],
            [-56.667267916992188, -30.220568526367195],
            [-56.663367916992186, -30.221668526367196],
            [-56.660167916992187, -30.221168526367194],
            [-56.656267916992185, -30.220568526367195],
            [-56.651567916992185, -30.219868526367197],
            [-56.647667916992184, -30.222268526367195],
            [-56.64506791699219, -30.225968526367197],
            [-56.644267916992185, -30.228868526367194],
            [-56.64206791699219, -30.233868526367196],
            [-56.637667916992186, -30.237168526367196],
            [-56.633767916992184, -30.239368526367194],
            [-56.62966791699219, -30.241968526367195],
            [-56.626567916992187, -30.244668526367196],
            [-56.624467916992188, -30.247168526367197],
            [-56.627667916992181, -30.251568526367194],
            [-56.630467916992188, -30.254568526367194],
            [-56.633867916992187, -30.255268526367196],
            [-56.636767916992184, -30.254368526367195],
            [-56.639867916992188, -30.254668526367198],
            [-56.639567916992185, -30.258168526367196],
            [-56.63736791699219, -30.260368526367195],
            [-56.633467916992188, -30.263568526367195],
            [-56.629867916992183, -30.265968526367196],
            [-56.625467916992186, -30.266568526367195],
            [-56.621267916992181, -30.265068526367195],
            [-56.617767916992186, -30.262668526367197],
            [-56.61466791699219, -30.262068526367194],
            [-56.612767916992183, -30.265468526367197],
            [-56.60996791699219, -30.269268526367195],
            [-56.609167916992185, -30.273368526367197],
            [-56.608067916992184, -30.277968526367196],
            [-56.607467916992185, -30.282068526367198],
            [-56.609167916992185, -30.285668526367196],
            [-56.611967916992185, -30.288168526367194],
            [-56.614367916992187, -30.290068526367197],
            [-56.617967916992185, -30.291768526367196],
            [-56.620867916992182, -30.294768526367196],
            [-56.619667916992185, -30.298868526367194],
            [-56.617267916992184, -30.301168526367196],
            [-56.613367916992189, -30.300468526367197],
            [-56.60996791699219, -30.297868526367196],
            [-56.605567916992186, -30.293568526367196],
            [-56.600867916992186, -30.294668526367197],
            [-56.596767916992185, -30.295668526367194],
            [-56.591667916992186, -30.293668526367199],
            [-56.586267916992185, -30.290968526367195],
            [-56.581767916992185, -30.289568526367198],
            [-56.575367916992185, -30.289968526367197],
            [-56.57456791699218, -30.295668526367194],
            [-56.576067916992187, -30.301568526367198],
            [-56.574267916992184, -30.305268526367197],
            [-56.56886791699219, -30.308368526367197],
            [-56.563067916992182, -30.311568526367196],
            [-56.559367916992187, -30.315468526367198],
            [-56.557767916992184, -30.319068526367197],
            [-56.554667916992187, -30.319668526367195],
            [-56.550867916992189, -30.316568526367199],
            [-56.548867916992187, -30.313868526367195],
            [-56.545567916992184, -30.311268526367197],
            [-56.541767916992185, -30.313068526367196],
            [-56.540867916992184, -30.316368526367196],
            [-56.541067916992183, -30.320268526367194],
            [-56.540667916992184, -30.326268526367194],
            [-56.538167916992187, -30.329668526367197],
            [-56.538067916992183, -30.334268526367197],
            [-56.541767916992185, -30.337568526367196],
            [-56.546467916992185, -30.338468526367194],
            [-56.549667916992185, -30.339068526367196],
            [-56.551567916992184, -30.341268526367195],
            [-56.551567916992184, -30.344868526367197],
            [-56.549767916992181, -30.347568526367198],
            [-56.546867916992184, -30.349568526367197],
            [-56.544267916992183, -30.352368526367197],
            [-56.545567916992184, -30.357468526367196],
            [-56.545067916992181, -30.360868526367199],
            [-56.540667916992184, -30.360768526367195],
            [-56.537767916992181, -30.359268526367195],
            [-56.532367916992186, -30.359768526367198],
            [-56.527567916992183, -30.360268526367197],
            [-56.524467916992187, -30.359668526367194],
            [-56.521567916992183, -30.358868526367196],
            [-56.518667916992186, -30.360568526367196],
            [-56.51706791699219, -30.363268526367197],
            [-56.513567916992187, -30.365468526367195],
            [-56.510167916992188, -30.367168526367195],
            [-56.507167916992188, -30.368868526367194],
            [-56.504867916992183, -30.371668526367195],
            [-56.505267916992182, -30.374968526367198],
            [-56.506567916992182, -30.378768526367196],
            [-56.50636791699219, -30.382068526367195],
            [-56.502167916992185, -30.384268526367194],
            [-56.498867916992182, -30.385668526367198],
            [-56.496367916992185, -30.387068526367194],
            [-56.49366791699218, -30.391468526367198],
            [-56.492267916992184, -30.394068526367196],
            [-56.491867916992184, -30.397168526367196],
            [-56.488367916992189, -30.398468526367196],
            [-56.483967916992185, -30.398668526367196],
            [-56.481767916992183, -30.396068526367195],
            [-56.480367916992186, -30.392568526367196],
            [-56.478067916992188, -30.390468526367197],
            [-56.475267916992181, -30.388768526367194],
            [-56.470867916992184, -30.387168526367198],
            [-56.467267916992185, -30.385768526367194],
            [-56.462567916992185, -30.384968526367196],
            [-56.458267916992185, -30.388868526367197],
            [-56.455767916992187, -30.393168526367198],
            [-56.455367916992188, -30.396868526367196],
            [-56.454267916992187, -30.400968526367194],
            [-56.45156791699219, -30.404468526367197],
            [-56.449367916992188, -30.406468526367199],
            [-56.445267916992186, -30.410368526367197],
            [-56.445467916992186, -30.415568526367196],
            [-56.450467916992181, -30.418368526367196],
            [-56.452467916992184, -30.422568526367197],
            [-56.447367916992185, -30.424668526367196],
            [-56.44516791699219, -30.427168526367197],
            [-56.440967916992186, -30.431468526367198],
            [-56.436367916992182, -30.432968526367198],
            [-56.432167916992185, -30.433768526367196],
            [-56.426467916992181, -30.434968526367197],
            [-56.422767916992186, -30.432568526367199],
            [-56.417167916992184, -30.431868526367197],
            [-56.414867916992186, -30.436668526367196],
            [-56.413367916992186, -30.440568526367194],
            [-56.410367916992186, -30.444068526367197],
            [-56.408067916992181, -30.446868526367197],
            [-56.403667916992184, -30.447468526367196],
            [-56.398667916992181, -30.449268526367199],
            [-56.398967916992184, -30.454268526367194],
            [-56.400467916992184, -30.458968526367194],
            [-56.405867916992186, -30.459868526367195],
            [-56.406467916992185, -30.465068526367197],
            [-56.405567916992183, -30.469468526367194],
            [-56.403967916992187, -30.471968526367196],
            [-56.401467916992189, -30.474468526367197],
            [-56.397867916992183, -30.472568526367198],
            [-56.397067916992185, -30.477068526367198],
            [-56.395967916992184, -30.481668526367194],
            [-56.394867916992183, -30.484968526367197],
            [-56.39036791699219, -30.485668526367196],
            [-56.386767916992184, -30.488868526367199],
            [-56.384167916992183, -30.492768526367197],
            [-56.387667916992186, -30.493368526367199],
            [-56.385267916992184, -30.496268526367196],
            [-56.383567916992185, -30.498568526367194],
            [-56.380467916992188, -30.500268526367197],
            [-56.377367916992185, -30.501268526367198],
            [-56.374067916992189, -30.501068526367199],
            [-56.371267916992181, -30.501868526367197],
            [-56.367467916992183, -30.502668526367195],
            [-56.362167916992185, -30.500468526367197],
            [-56.360567916992181, -30.496168526367196],
            [-56.363567916992181, -30.496968526367198],
            [-56.364367916992187, -30.492668526367197],
            [-56.358867916992182, -30.492168526367195],
            [-56.354667916992184, -30.493068526367196],
            [-56.35096791699219, -30.496568526367199],
            [-56.35096791699219, -30.500168526367197],
            [-56.348767916992188, -30.504068526367199],
            [-56.345867916992184, -30.506368526367197],
            [-56.342967916992187, -30.507068526367195],
            [-56.340667916992189, -30.509068526367194],
            [-56.336967916992187, -30.510968526367197],
            [-56.334067916992183, -30.514268526367196],
            [-56.334567916992185, -30.517068526367197],
            [-56.336967916992187, -30.519868526367198],
            [-56.336467916992184, -30.523168526367197],
            [-56.332967916992189, -30.525368526367195],
            [-56.328967916992184, -30.528968526367194],
            [-56.324267916992184, -30.530168526367198],
            [-56.32016791699219, -30.531568526367195],
            [-56.316567916992184, -30.530168526367198],
            [-56.31216791699218, -30.530968526367197],
            [-56.308567916992189, -30.530668526367194],
            [-56.304467916992181, -30.528968526367194],
            [-56.30216791699219, -30.526768526367196],
            [-56.298367916992184, -30.525168526367196],
            [-56.293167916992189, -30.521568526367197],
            [-56.290267916992185, -30.522668526367198],
            [-56.288667916992182, -30.526868526367195],
            [-56.28846791699219, -30.532268526367197],
            [-56.28946791699218, -30.536168526367199],
            [-56.288167916992187, -30.540868526367198],
            [-56.285667916992182, -30.544668526367197],
            [-56.283167916992184, -30.548068526367196],
            [-56.280367916992184, -30.550768526367197],
            [-56.279167916992186, -30.554368526367199],
            [-56.280967916992182, -30.558568526367196],
            [-56.276167916992186, -30.560168526367196],
            [-56.270767916992185, -30.557568526367199],
            [-56.266767916992187, -30.555168526367197],
            [-56.263267916992184, -30.556868526367197],
            [-56.259267916992187, -30.560868526367194],
            [-56.256867916992185, -30.566268526367196],
            [-56.258767916992184, -30.568468526367198],
            [-56.261767916992184, -30.571268526367199],
            [-56.260967916992186, -30.577068526367196],
            [-56.26066791699219, -30.580668526367198],
            [-56.261767916992184, -30.583968526367194],
            [-56.256767916992189, -30.587368526367197],
            [-56.253467916992186, -30.585868526367197],
            [-56.251967916992186, -30.582068526367195],
            [-56.249467916992188, -30.577468526367198],
            [-56.246367916992185, -30.574668526367198],
            [-56.242467916992183, -30.573468526367197],
            [-56.238567916992181, -30.573468526367197],
            [-56.23496791699219, -30.574268526367199],
            [-56.232267916992186, -30.575368526367196],
            [-56.228067916992188, -30.577968526367197],
            [-56.224567916992186, -30.581268526367197],
            [-56.224767916992185, -30.585468526367194],
            [-56.227867916992182, -30.586568526367195],
            [-56.232067916992186, -30.586968526367194],
            [-56.23466791699218, -30.588968526367196],
            [-56.232867916992184, -30.594168526367199],
            [-56.228567916992183, -30.595668526367199],
            [-56.225367916992184, -30.596668526367196],
            [-56.222367916992184, -30.601168526367196],
            [-56.220567916992181, -30.605968526367196],
            [-56.21656791699219, -30.607868526367199],
            [-56.212867916992181, -30.607868526367199],
            [-56.208267916992185, -30.606768526367198],
            [-56.202967916992186, -30.605268526367198],
            [-56.198567916992189, -30.604468526367196],
            [-56.194267916992189, -30.603868526367194],
            [-56.18916791699219, -30.604268526367196],
            [-56.185467916992181, -30.606468526367195],
            [-56.182567916992184, -30.608568526367197],
            [-56.179767916992184, -30.610268526367197],
            [-56.177467916992185, -30.612168526367196],
            [-56.173967916992183, -30.614068526367198],
            [-56.170667916992187, -30.615268526367196],
            [-56.171667916992185, -30.618268526367196],
            [-56.174367916992182, -30.620168526367195],
            [-56.177467916992185, -30.620768526367197],
            [-56.181067916992184, -30.622968526367195],
            [-56.182967916992183, -30.625468526367197],
            [-56.180867916992185, -30.629868526367197],
            [-56.176467916992181, -30.633468526367196],
            [-56.173367916992184, -30.634668526367197],
            [-56.168967916992187, -30.637768526367196],
            [-56.16646791699219, -30.639668526367196],
            [-56.165567916992188, -30.644868526367198],
            [-56.168367916992182, -30.650068526367196],
            [-56.169967916992185, -30.652968526367196],
            [-56.16916791699218, -30.656968526367194],
            [-56.166967916992185, -30.662268526367196],
            [-56.164567916992183, -30.666368526367194],
            [-56.162767916992181, -30.668568526367196],
            [-56.160667916992182, -30.671168526367197],
            [-56.157867916992188, -30.673868526367194],
            [-56.155367916992184, -30.676068526367196],
            [-56.152867916992186, -30.678568526367194],
            [-56.14906791699218, -30.680468526367196],
            [-56.144667916992184, -30.680268526367197],
            [-56.140767916992189, -30.681168526367195],
            [-56.137667916992186, -30.684368526367194],
            [-56.138567916992187, -30.687668526367197],
            [-56.14206791699219, -30.688768526367198],
            [-56.147267916992185, -30.691568526367199],
            [-56.150167916992189, -30.694668526367195],
            [-56.149767916992189, -30.698968526367196],
            [-56.150067916992185, -30.703568526367196],
            [-56.148167916992186, -30.705768526367194],
            [-56.144367916992181, -30.707168526367198],
            [-56.140767916992189, -30.709068526367197],
            [-56.136367916992185, -30.710668526367197],
            [-56.132767916992186, -30.714268526367196],
            [-56.130167916992185, -30.717368526367196],
            [-56.128867916992185, -30.721168526367194],
            [-56.131267916992186, -30.723868526367198],
            [-56.132067916992185, -30.727268526367197],
            [-56.131867916992185, -30.731668526367194],
            [-56.129967916992186, -30.734268526367195],
            [-56.128267916992186, -30.738868526367199],
            [-56.12336791699218, -30.739468526367197],
            [-56.119767916992188, -30.737768526367198],
            [-56.118267916992181, -30.735068526367197],
            [-56.116367916992189, -30.732868526367199],
            [-56.113067916992186, -30.732568526367196],
            [-56.10996791699219, -30.732168526367197],
            [-56.10666791699218, -30.732768526367195],
            [-56.104267916992185, -30.736368526367194],
            [-56.102767916992185, -30.740068526367196],
            [-56.101767916992188, -30.743668526367195],
            [-56.09756791699219, -30.747268526367197],
            [-56.09596791699218, -30.752768526367198],
            [-56.092967916992187, -30.755468526367196],
            [-56.090167916992186, -30.756868526367196],
            [-56.086467916992184, -30.756668526367196],
            [-56.082967916992189, -30.753368526367197],
            [-56.079867916992185, -30.749968526367198],
            [-56.077167916992181, -30.747968526367195],
            [-56.072467916992181, -30.745768526367197],
            [-56.068767916992186, -30.747168526367197],
            [-56.065967916992186, -30.748768526367197],
            [-56.063767916992184, -30.752368526367196],
            [-56.063567916992184, -30.756768526367196],
            [-56.06416791699219, -30.759968526367196],
            [-56.065967916992186, -30.762668526367197],
            [-56.065767916992186, -30.767968526367198],
            [-56.064067916992187, -30.772068526367196],
            [-56.062267916992184, -30.774468526367194],
            [-56.058067916992186, -30.773968526367199],
            [-56.053567916992186, -30.775068526367196],
            [-56.048967916992183, -30.775868526367198],
            [-56.046367916992182, -30.773768526367196],
            [-56.04246791699218, -30.774468526367194],
            [-56.039167916992184, -30.775168526367196],
            [-56.038167916992187, -30.778168526367196],
            [-56.035667916992182, -30.783368526367198],
            [-56.030967916992182, -30.784168526367196],
            [-56.027267916992187, -30.784868526367198],
            [-56.023967916992184, -30.785868526367196],
            [-56.021767916992189, -30.789568526367198],
            [-56.02436791699219, -30.794668526367197],
            [-56.025067916992185, -30.798568526367198],
            [-56.021467916992187, -30.800468526367197],
            [-56.016267916992184, -30.802268526367197],
            [-56.012967916992181, -30.806368526367194],
            [-56.015767916992189, -30.810768526367198],
            [-56.016767916992187, -30.815168526367195],
            [-56.015467916992186, -30.818268526367198],
            [-56.011067916992189, -30.818868526367197],
            [-56.008567916992185, -30.820268526367194],
            [-56.00766791699219, -30.823168526367198],
            [-56.005667916992181, -30.826868526367196],
            [-56.004467916992184, -30.831468526367196],
            [-56.004567916992187, -30.836468526367195],
            [-56.003467916992186, -30.839768526367195],
            [-56.000967916992181, -30.841668526367197],
            [-55.99836791699218, -30.843368526367197],
            [-55.994467916992186, -30.845268526367196],
            [-55.991867916992184, -30.849468526367197],
            [-55.992267916992184, -30.853168526367195],
            [-55.990767916992183, -30.855868526367196],
            [-55.98966791699219, -30.858868526367196],
            [-55.990467916992188, -30.862268526367195],
            [-55.990267916992181, -30.865768526367198],
            [-55.992567916992186, -30.869068526367197],
            [-55.995067916992184, -30.871868526367194],
            [-55.997767916992188, -30.873368526367194],
            [-55.999467916992188, -30.878268526367197],
            [-56.00136791699218, -30.883468526367196],
            [-56.003467916992186, -30.886768526367195],
            [-56.005567916992185, -30.890368526367197],
            [-56.007767916992186, -30.894568526367195],
            [-56.007067916992185, -30.899268526367194],
            [-56.007967916992186, -30.904268526367197],
            [-56.009667916992186, -30.908868526367197],
            [-56.010167916992188, -30.912568526367195],
            [-56.00736791699218, -30.916368526367197],
            [-56.005967916992184, -30.919268526367198],
            [-56.005667916992181, -30.922468526367197],
            [-56.008467916992188, -30.925068526367198],
            [-56.010167916992188, -30.927568526367196],
            [-56.01066791699219, -30.932668526367195],
            [-56.01066791699219, -30.937768526367197],
            [-56.014267916992182, -30.941268526367196],
            [-56.013967916992186, -30.944868526367195],
            [-56.009867916992185, -30.947068526367197],
            [-56.01066791699219, -30.950168526367197],
            [-56.01236791699219, -30.953868526367195],
            [-56.010467916992184, -30.957068526367195],
            [-56.011267916992182, -30.962568526367196],
            [-56.01066791699219, -30.967568526367195],
            [-56.008267916992182, -30.970968526367194],
            [-56.00636791699219, -30.974568526367197],
            [-56.009967916992181, -30.979268526367196],
            [-56.00736791699218, -30.983868526367196],
            [-56.01066791699219, -30.987468526367195],
            [-56.012767916992189, -30.991668526367196],
            [-56.013567916992187, -30.995468526367198],
            [-56.014867916992188, -30.998568526367198],
            [-56.016767916992187, -31.001868526367197],
            [-56.016767916992187, -31.005968526367198],
            [-56.017667916992181, -31.009168526367198],
            [-56.016867916992183, -31.014368526367196],
            [-56.014867916992188, -31.019268526367195],
            [-56.015167916992183, -31.022968526367197],
            [-56.016267916992184, -31.027868526367197],
            [-56.017467916992189, -31.032868526367196],
            [-56.017967916992184, -31.038468526367197],
            [-56.017967916992184, -31.043468526367196],
            [-56.017867916992188, -31.047868526367196],
            [-56.015667916992186, -31.051068526367196],
            [-56.014667916992181, -31.054768526367198],
            [-56.012467916992186, -31.059468526367198],
            [-56.011067916992189, -31.064668526367196],
            [-56.009967916992181, -31.067668526367196],
            [-56.008267916992182, -31.070568526367197],
            [-56.00766791699219, -31.073868526367196],
            [-56.008067916992189, -31.077468526367198],
            [-56.009967916992181, -31.080668526367198],
            [-56.006267916992186, -31.081868526367195],
            [-56.002667916992181, -31.082568526367197],
            [-55.998567916992187, -31.083568526367195],
            [-55.995767916992186, -31.084268526367197],
            [-55.992567916992186, -31.084868526367195],
            [-55.98966791699219, -31.082568526367197],
            [-55.986667916992189, -31.077168526367196],
            [-55.982567916992181, -31.074368526367195],
            [-55.978567916992183, -31.073468526367197],
            [-55.974867916992181, -31.074668526367198],
            [-55.968667916992189, -31.074868526367197],
            [-55.965167916992186, -31.077468526367198],
            [-55.958967916992187, -31.077368526367195],
            [-55.955167916992181, -31.080068526367196],
            [-55.94986791699219, -31.081168526367197],
            [-55.944367916992185, -31.081868526367195],
            [-55.941367916992185, -31.083168526367196],
            [-55.938467916992181, -31.085768526367197],
            [-55.934367916992187, -31.086968526367198],
            [-55.930267916992186, -31.086168526367196],
            [-55.929767916992184, -31.083168526367196],
            [-55.926967916992183, -31.081268526367197],
            [-55.924967916992188, -31.076068526367195],
            [-55.918567916992188, -31.073568526367197],
            [-55.913067916992183, -31.075668526367195],
            [-55.909567916992188, -31.074068526367196],
            [-55.902667916992186, -31.068568526367194],
            [-55.898667916992181, -31.066668526367195],
            [-55.895367916992186, -31.068468526367198],
            [-55.89506791699219, -31.071568526367194],
            [-55.892167916992186, -31.073868526367196],
            [-55.890667916992186, -31.077068526367196],
            [-55.886767916992184, -31.076768526367196],
            [-55.88136791699219, -31.076068526367195],
            [-55.87666791699219, -31.072668526367195],
            [-55.87196791699219, -31.071268526367195],
            [-55.868267916992181, -31.064868526367196],
            [-55.866367916992189, -31.061568526367196],
            [-55.864167916992187, -31.059168526367195],
            [-55.860267916992186, -31.053568526367197],
            [-55.858867916992182, -31.051068526367196],
            [-55.855667916992189, -31.045668526367194],
            [-55.852767916992185, -31.044568526367197],
            [-55.848067916992186, -31.043168526367197],
            [-55.842967916992187, -31.043168526367197],
            [-55.838667916992186, -31.042568526367198],
            [-55.832667916992186, -31.043868526367199],
            [-55.829667916992186, -31.043668526367195],
            [-55.830167916992181, -31.040568526367196],
            [-55.826267916992187, -31.039168526367195],
            [-55.822467916992181, -31.033968526367197],
            [-55.820467916992186, -31.030968526367197],
            [-55.816667916992188, -31.027368526367198],
            [-55.814467916992186, -31.024268526367194],
            [-55.811867916992185, -31.021568526367197],
            [-55.813067916992182, -31.016368526367195],
            [-55.80916791699218, -31.014968526367198],
            [-55.805767916992181, -31.016368526367195],
            [-55.801367916992184, -31.015768526367197],
            [-55.79576791699219, -31.015968526367196],
            [-55.785367916992186, -31.016568526367195],
            [-55.780667916992186, -31.015968526367196],
            [-55.776567916992185, -31.015668526367197],
            [-55.772067916992185, -31.013468526367198],
            [-55.766467916992184, -31.009968526367196],
            [-55.767867916992188, -31.006368526367197],
            [-55.767867916992188, -31.003468526367197],
            [-55.771467916992187, -31.001668526367197],
            [-55.77436791699219, -31.000168526367197],
            [-55.773267916992182, -30.997368526367197],
            [-55.768167916992184, -30.996868526367194],
            [-55.763567916992187, -30.999168526367196],
            [-55.758567916992185, -30.999368526367196],
            [-55.75606791699218, -30.997668526367196],
            [-55.752467916992188, -30.997768526367196],
            [-55.749967916992183, -30.994468526367196],
            [-55.750467916992186, -30.991668526367196],
            [-55.74836791699218, -30.989468526367197],
            [-55.746167916992185, -30.983568526367197],
            [-55.745467916992183, -30.979268526367196],
            [-55.741067916992186, -30.978868526367197],
            [-55.737867916992187, -30.979768526367195],
            [-55.733867916992182, -30.981368526367199],
            [-55.728567916992183, -30.978468526367195],
            [-55.731167916992185, -30.975268526367195],
            [-55.729767916992188, -30.970968526367194],
            [-55.72696791699218, -30.968168526367197],
            [-55.724767916992185, -30.965668526367196],
            [-55.725267916992181, -30.962568526367196],
            [-55.72226791699218, -30.957268526367194],
            [-55.72426791699219, -30.952168526367196],
            [-55.722067916992188, -30.948968526367196],
            [-55.72096791699218, -30.945668526367196],
            [-55.723167916992182, -30.943068526367195],
            [-55.719467916992187, -30.941568526367195],
            [-55.716667916992186, -30.942768526367196],
            [-55.715867916992181, -30.946568526367194],
            [-55.71326791699218, -30.948468526367197],
            [-55.710867916992186, -30.950168526367197],
            [-55.710067916992188, -30.953768526367195],
            [-55.707167916992184, -30.954268526367194],
            [-55.703267916992189, -30.954068526367195],
            [-55.700667916992188, -30.950468526367196],
            [-55.697467916992181, -30.947468526367196],
            [-55.694067916992182, -30.943568526367194],
            [-55.688767916992184, -30.944968526367198],
            [-55.68916791699219, -30.948468526367197],
            [-55.687367916992187, -30.952168526367196],
            [-55.681567916992186, -30.954068526367195],
            [-55.674967916992188, -30.955968526367197],
            [-55.670267916992188, -30.953768526367195],
            [-55.666167916992187, -30.953968526367198],
            [-55.66446791699218, -30.947968526367198],
            [-55.661167916992184, -30.947468526367196],
            [-55.659467916992185, -30.943468526367198],
            [-55.660667916992182, -30.939468526367197],
            [-55.663067916992183, -30.934368526367194],
            [-55.660267916992183, -30.930568526367196],
            [-55.65676791699218, -30.926468526367195],
            [-55.653667916992184, -30.925068526367198],
            [-55.651167916992186, -30.922868526367196],
            [-55.647867916992183, -30.921768526367195],
            [-55.646167916992184, -30.917768526367198],
            [-55.643267916992187, -30.914768526367197],
            [-55.642567916992185, -30.911168526367195],
            [-55.645167916992186, -30.909568526367195],
            [-55.647867916992183, -30.906668526367199],
            [-55.648967916992184, -30.903168526367196],
            [-55.650667916992184, -30.900768526367195],
            [-55.653167916992189, -30.896568526367197],
            [-55.65376791699218, -30.890968526367196],
            [-55.655667916992186, -30.884868526367196],
            [-55.652867916992186, -30.883268526367196],
            [-55.657367916992186, -30.878768526367196],
            [-55.659567916992188, -30.875568526367196],
            [-55.663067916992183, -30.872668526367196],
            [-55.660667916992182, -30.869068526367197],
            [-55.659267916992185, -30.866068526367197],
            [-55.656667916992184, -30.863568526367196],
            [-55.650467916992184, -30.865368526367195],
            [-55.647067916992185, -30.861368526367198],
            [-55.645167916992186, -30.857468526367196],
            [-55.646567916992183, -30.854768526367195],
            [-55.642167916992186, -30.849568526367197],
            [-55.639267916992182, -30.848468526367196],
            [-55.637167916992183, -30.845968526367194],
            [-55.631567916992182, -30.845868526367198],
            [-55.627667916992181, -30.847368526367198],
            [-55.623567916992187, -30.844868526367197],
            [-55.621867916992187, -30.841968526367197],
            [-55.61866791699218, -30.842268526367196],
            [-55.615267916992181, -30.842568526367195],
            [-55.612567916992184, -30.844768526367197],
            [-55.610567916992181, -30.848168526367196],
            [-55.605667916992189, -30.849168526367198],
            [-55.599867916992181, -30.846768526367196],
            [-55.59756791699219, -30.845068526367196],
            [-55.595067916992186, -30.843368526367197],
            [-55.590667916992189, -30.840868526367196],
            [-55.586967916992187, -30.839568526367195],
            [-55.585367916992183, -30.836568526367195],
            [-55.581467916992182, -30.834268526367197],
            [-55.57786791699219, -30.833968526367197],
            [-55.57786791699219, -30.837268526367197],
            [-55.578967916992184, -30.840168526367197],
            [-55.577067916992185, -30.842568526367195],
            [-55.574067916992185, -30.842968526367194],
            [-55.571767916992187, -30.845868526367198],
            [-55.566667916992188, -30.846668526367196],
            [-55.564867916992185, -30.851968526367195],
            [-55.562767916992186, -30.854768526367195],
            [-55.56416791699219, -30.857768526367195],
            [-55.564067916992187, -30.861668526367197],
            [-55.563767916992184, -30.864968526367196],
            [-55.563467916992181, -30.867968526367196],
            [-55.559967916992186, -30.870068526367195],
            [-55.557567916992184, -30.871568526367195],
            [-55.553967916992185, -30.873368526367194],
            [-55.552267916992186, -30.877768526367198],
            [-55.551667916992187, -30.881668526367196],
            [-55.549167916992189, -30.886268526367196],
            [-55.546467916992185, -30.889568526367196],
            [-55.542867916992186, -30.892368526367196],
            [-55.540367916992182, -30.893968526367196],
            [-55.537767916992181, -30.895368526367196],
            [-55.533667916992187, -30.898668526367196],
            [-55.532367916992186, -30.902068526367195],
            [-55.528367916992181, -30.899768526367197],
            [-55.525367916992181, -30.898168526367197],
            [-55.52006791699219, -30.898668526367196],
            [-55.516067916992185, -30.898968526367195],
            [-55.512767916992189, -30.900468526367195],
            [-55.510167916992188, -30.901768526367196],
            [-55.508067916992189, -30.904568526367196],
            [-55.510267916992184, -30.906968526367194],
            [-55.509567916992182, -30.912568526367195],
            [-55.506867916992185, -30.918868526367199],
            [-55.505667916992181, -30.922768526367197],
            [-55.502967916992183, -30.924968526367195],
            [-55.498567916992187, -30.926668526367195],
            [-55.495767916992186, -30.925368526367198],
            [-55.49266791699219, -30.924668526367196],
            [-55.488667916992185, -30.925368526367198],
            [-55.486367916992187, -30.928268526367198],
            [-55.488567916992181, -30.932268526367196],
            [-55.489167916992187, -30.937668526367197],
            [-55.488067916992186, -30.941268526367196],
            [-55.486367916992187, -30.946668526367198],
            [-55.483867916992182, -30.949868526367197],
            [-55.480667916992189, -30.952068526367196],
            [-55.476467916992185, -30.952868526367197],
            [-55.472367916992184, -30.952668526367198],
            [-55.469867916992186, -30.954268526367194],
            [-55.46626791699218, -30.953168526367197],
            [-55.463367916992183, -30.950368526367196],
            [-55.45756791699219, -30.951568526367197],
            [-55.452067916992185, -30.959368526367196],
            [-55.44816791699219, -30.958968526367197],
            [-55.444967916992184, -30.958568526367195],
            [-55.441267916992189, -30.959868526367195],
            [-55.440267916992184, -30.963668526367197],
            [-55.438467916992181, -30.967868526367198],
            [-55.436867916992185, -30.971968526367196],
            [-55.432967916992183, -30.973668526367195],
            [-55.431567916992186, -30.978068526367196],
            [-55.42716791699219, -30.981168526367195],
            [-55.425567916992186, -30.985368526367196],
            [-55.428867916992189, -30.987868526367198],
            [-55.431867916992189, -30.992668526367197],
            [-55.435467916992181, -30.992268526367198],
            [-55.436967916992188, -30.995568526367197],
            [-55.434667916992183, -30.999768526367198],
            [-55.436667916992185, -31.004168526367195],
            [-55.430467916992185, -31.009068526367194],
            [-55.425067916992184, -31.012968526367196],
            [-55.422867916992189, -31.016768526367194],
            [-55.418667916992185, -31.015368526367197],
            [-55.41446791699218, -31.014868526367195],
            [-55.411267916992188, -31.017568526367196],
            [-55.406667916992184, -31.018968526367196],
            [-55.405967916992182, -31.021768526367197],
            [-55.405067916992181, -31.025968526367198],
            [-55.400867916992183, -31.027668526367197],
            [-55.394267916992185, -31.025968526367198],
            [-55.387467916992186, -31.021068526367195],
            [-55.38406791699218, -31.022068526367196],
            [-55.379967916992186, -31.023468526367196],
            [-55.375567916992182, -31.021868526367196],
            [-55.373867916992182, -31.019068526367196],
            [-55.371267916992181, -31.023368526367197],
            [-55.373267916992184, -31.026968526367195],
            [-55.372467916992186, -31.030368526367198],
            [-55.368067916992189, -31.034868526367198],
            [-55.363567916992181, -31.035568526367197],
            [-55.362467916992188, -31.038968526367196],
            [-55.358667916992189, -31.039968526367197],
            [-55.354167916992182, -31.039168526367195],
            [-55.351367916992189, -31.037568526367195],
            [-55.349767916992185, -31.039968526367197],
            [-55.348767916992188, -31.042868526367197],
            [-55.350667916992187, -31.049268526367197],
            [-55.353167916992184, -31.053668526367197],
            [-55.352867916992182, -31.056668526367197],
            [-55.357467916992185, -31.062368526367194],
            [-55.35966791699218, -31.067468526367197],
            [-55.353167916992184, -31.069568526367195],
            [-55.350567916992183, -31.067768526367196],
            [-55.349867916992181, -31.071368526367195],
            [-55.345567916992181, -31.075668526367195],
            [-55.34426791699218, -31.078568526367196],
            [-55.340167916992186, -31.083268526367196],
            [-55.334867916992181, -31.085668526367197],
            [-55.328267916992189, -31.087868526367195],
            [-55.328767916992184, -31.092568526367195],
            [-55.332067916992187, -31.096168526367197],
            [-55.333167916992181, -31.100868526367197],
            [-55.333467916992184, -31.105068526367198],
            [-55.329267916992187, -31.109268526367195],
            [-55.330467916992184, -31.115368526367195],
            [-55.332867916992186, -31.118068526367196],
            [-55.336967916992187, -31.119768526367196],
            [-55.338367916992183, -31.124468526367195],
            [-55.338967916992189, -31.127668526367195],
            [-55.338367916992183, -31.132068526367195],
            [-55.334567916992185, -31.132968526367197],
            [-55.329967916992189, -31.135168526367195],
            [-55.327067916992185, -31.137368526367197],
            [-55.322967916992184, -31.136768526367195],
            [-55.319967916992184, -31.136768526367195],
            [-55.318067916992185, -31.140368526367197],
            [-55.317467916992186, -31.145668526367196],
            [-55.312967916992186, -31.146168526367198],
            [-55.305867916992185, -31.142168526367197],
            [-55.302467916992185, -31.142668526367196],
            [-55.29746791699219, -31.144368526367195],
            [-55.29416791699218, -31.144268526367195],
            [-55.290567916992188, -31.143268526367194],
            [-55.289967916992182, -31.146768526367197],
            [-55.290267916992185, -31.150168526367196],
            [-55.288067916992183, -31.152368526367198],
            [-55.285567916992186, -31.155968526367197],
            [-55.28946791699218, -31.160168526367197],
            [-55.28946791699218, -31.164168526367195],
            [-55.28476791699218, -31.165568526367196],
            [-55.283067916992181, -31.168068526367197],
            [-55.279467916992189, -31.169768526367196],
            [-55.275067916992185, -31.171068526367197],
            [-55.271867916992186, -31.172768526367197],
            [-55.270967916992184, -31.175868526367196],
            [-55.272867916992183, -31.178568526367197],
            [-55.276467916992189, -31.178368526367194],
            [-55.282767916992185, -31.184068526367195],
            [-55.285167916992187, -31.186068526367198],
            [-55.283667916992187, -31.188868526367195],
            [-55.282367916992186, -31.193168526367195],
            [-55.279267916992183, -31.194368526367196],
            [-55.273467916992189, -31.194568526367195],
            [-55.27006791699219, -31.195768526367196],
            [-55.265667916992186, -31.195268526367197],
            [-55.26206791699218, -31.195668526367196],
            [-55.257467916992184, -31.197868526367195],
            [-55.255767916992184, -31.201368526367197],
            [-55.253567916992182, -31.203868526367195],
            [-55.250967916992181, -31.206868526367195],
            [-55.252467916992188, -31.212368526367197],
            [-55.250767916992189, -31.215468526367196],
            [-55.247967916992181, -31.214868526367198],
            [-55.248267916992184, -31.219468526367194],
            [-55.247967916992181, -31.225368526367198],
            [-55.247267916992186, -31.229268526367196],
            [-55.246867916992187, -31.234268526367195],
            [-55.247167916992183, -31.237768526367198],
            [-55.248767916992186, -31.241468526367196],
            [-55.247767916992188, -31.245268526367198],
            [-55.24836791699218, -31.248068526367195],
            [-55.24666791699218, -31.251568526367198],
            [-55.24366791699218, -31.252168526367196],
            [-55.240267916992181, -31.254368526367195],
            [-55.239067916992184, -31.257468526367198],
            [-55.239367916992187, -31.260968526367197],
            [-55.234367916992184, -31.258868526367195],
            [-55.228667916992187, -31.259868526367196],
            [-55.224467916992182, -31.260968526367197],
            [-55.220867916992184, -31.261568526367196],
            [-55.217667916992184, -31.261768526367195],
            [-55.213667916992186, -31.263168526367195],
            [-55.210367916992183, -31.262368526367197],
            [-55.202667916992183, -31.261868526367195],
            [-55.199267916992184, -31.263868526367197],
            [-55.194567916992185, -31.264268526367196],
            [-55.188767916992184, -31.265368526367197],
            [-55.183267916992186, -31.266568526367195],
            [-55.179667916992187, -31.269568526367195],
            [-55.175067916992184, -31.268668526367197],
            [-55.169267916992183, -31.270668526367196],
            [-55.16746791699218, -31.273668526367196],
            [-55.164567916992183, -31.275968526367198],
            [-55.164167916992184, -31.280568526367198],
            [-55.16346791699219, -31.285168526367197],
            [-55.160567916992186, -31.284468526367196],
            [-55.156467916992185, -31.285568526367197],
            [-55.152867916992186, -31.289168526367195],
            [-55.151967916992184, -31.293168526367197],
            [-55.148367916992186, -31.294668526367197],
            [-55.145467916992189, -31.296068526367197],
            [-55.141467916992184, -31.297468526367197],
            [-55.13566791699219, -31.301068526367196],
            [-55.132167916992188, -31.301868526367198],
            [-55.128467916992186, -31.302468526367196],
            [-55.123767916992186, -31.303868526367197],
            [-55.121867916992187, -31.308768526367196],
            [-55.121567916992184, -31.312768526367197],
            [-55.118567916992184, -31.311868526367196],
            [-55.113667916992185, -31.311168526367197],
            [-55.108867916992182, -31.308268526367197],
            [-55.106167916992185, -31.309668526367197],
            [-55.103167916992184, -31.313568526367195],
            [-55.097367916992184, -31.315768526367197],
            [-55.091167916992184, -31.319068526367197],
            [-55.087867916992181, -31.320168526367198],
            [-55.084767916992185, -31.322968526367198],
            [-55.080767916992187, -31.326068526367195],
            [-55.077667916992183, -31.327668526367198],
            [-55.07486791699219, -31.330768526367194],
            [-55.071267916992184, -31.331068526367197],
            [-55.068267916992184, -31.330768526367194],
            [-55.064967916992188, -31.330668526367198],
            [-55.060167916992185, -31.332268526367194],
            [-55.057267916992188, -31.331768526367195],
            [-55.059067916992184, -31.327468526367198],
            [-55.061567916992189, -31.324868526367197],
            [-55.058267916992186, -31.322868526367195],
            [-55.057667916992187, -31.318568526367194],
            [-55.05916791699218, -31.314968526367196],
            [-55.052967916992188, -31.312668526367197],
            [-55.049467916992185, -31.309768526367197],
            [-55.046767916992181, -31.307468526367195],
            [-55.043067916992186, -31.306368526367194],
            [-55.040667916992184, -31.302568526367196],
            [-55.038967916992185, -31.298968526367197],
            [-55.037067916992186, -31.294568526367197],
            [-55.034467916992185, -31.290968526367195],
            [-55.03376791699219, -31.287368526367196],
            [-55.035867916992188, -31.283868526367197],
            [-55.032267916992183, -31.279068526367197],
            [-55.036167916992184, -31.275768526367195],
            [-55.032367916992186, -31.273668526367196],
            [-55.03176791699218, -31.270668526367196],
            [-55.028167916992189, -31.268968526367196],
            [-55.023367916992186, -31.269368526367195],
            [-55.020367916992186, -31.267668526367196],
            [-55.015967916992182, -31.268268526367194],
            [-55.011467916992189, -31.268768526367197],
            [-55.008267916992182, -31.269268526367195],
            [-55.005267916992182, -31.267568526367196],
            [-55.002067916992189, -31.270468526367196],
            [-55.00136791699218, -31.275668526367198],
            [-55.000767916992189, -31.282368526367197],
            [-54.995867916992182, -31.283368526367198],
            [-54.994367916992189, -31.285868526367196],
            [-54.994067916992186, -31.289168526367195],
            [-54.988967916992181, -31.293068526367197],
            [-54.985567916992181, -31.292468526367195],
            [-54.982867916992184, -31.295368526367195],
            [-54.97996791699218, -31.302768526367196],
            [-54.981167916992185, -31.308068526367197],
            [-54.981367916992184, -31.312168526367195],
            [-54.976367916992189, -31.317068526367194],
            [-54.964767916992187, -31.320668526367196],
            [-54.958467916992184, -31.323768526367196],
            [-54.954967916992189, -31.330168526367196],
            [-54.952967916992186, -31.332268526367194],
            [-54.952967916992186, -31.336168526367196],
            [-54.953567916992185, -31.339368526367195],
            [-54.958667916992184, -31.339568526367195],
            [-54.959867916992181, -31.343768526367196],
            [-54.960467916992187, -31.347668526367197],
            [-54.959667916992188, -31.351668526367195],
            [-54.956467916992182, -31.352768526367196],
            [-54.952167916992181, -31.352768526367196],
            [-54.948767916992182, -31.353568526367198],
            [-54.945667916992186, -31.353868526367197],
            [-54.942767916992182, -31.354468526367196],
            [-54.938767916992184, -31.353968526367197],
            [-54.935167916992185, -31.356668526367194],
            [-54.935467916992181, -31.359668526367194],
            [-54.93586791699218, -31.364368526367198],
            [-54.933567916992189, -31.367968526367196],
            [-54.936967916992188, -31.370068526367195],
            [-54.938767916992184, -31.376268526367198],
            [-54.940767916992186, -31.379968526367197],
            [-54.936567916992189, -31.382768526367197],
            [-54.931867916992189, -31.384268526367197],
            [-54.927767916992181, -31.384368526367197],
            [-54.921067916992186, -31.379868526367197],
            [-54.918067916992186, -31.378768526367196],
            [-54.913367916992186, -31.380268526367196],
            [-54.908067916992181, -31.381068526367194],
            [-54.904767916992185, -31.384068526367194],
            [-54.897967916992187, -31.381268526367197],
            [-54.89906791699218, -31.377168526367196],
            [-54.895167916992186, -31.374968526367198],
            [-54.890967916992182, -31.375568526367196],
            [-54.88566791699219, -31.379168526367195],
            [-54.88706791699218, -31.383468526367196],
            [-54.88436791699219, -31.386068526367197],
            [-54.883567916992185, -31.389268526367196],
            [-54.879867916992183, -31.395468526367196],
            [-54.878067916992187, -31.397968526367197],
            [-54.869767916992188, -31.401768526367196],
            [-54.864067916992184, -31.404168526367197],
            [-54.857867916992184, -31.408168526367195],
            [-54.860867916992184, -31.417068526367196],
            [-54.85696791699219, -31.418368526367196],
            [-54.85496791699218, -31.423568526367198],
            [-54.850567916992183, -31.425468526367197],
            [-54.846667916992189, -31.430768526367196],
            [-54.84456791699219, -31.433868526367196],
            [-54.840067916992183, -31.438368526367196],
            [-54.837267916992189, -31.441068526367197],
            [-54.833667916992184, -31.440568526367194],
            [-54.828167916992186, -31.436868526367196],
            [-54.823267916992187, -31.435868526367194],
            [-54.815167916992181, -31.435168526367196],
            [-54.811367916992182, -31.434068526367195],
            [-54.810467916992181, -31.431168526367195],
            [-54.805567916992189, -31.429968526367198],
            [-54.802467916992185, -31.429968526367198],
            [-54.799167916992189, -31.432468526367195],
            [-54.796367916992182, -31.430768526367196],
            [-54.792067916992181, -31.432968526367198],
            [-54.78946791699218, -31.434168526367195],
            [-54.786367916992184, -31.432968526367198],
            [-54.783367916992184, -31.431968526367196],
            [-54.776267916992182, -31.428868526367197],
            [-54.769367916992181, -31.426568526367198],
            [-54.765667916992186, -31.426868526367198],
            [-54.76066791699219, -31.426068526367196],
            [-54.753467916992186, -31.424768526367195],
            [-54.749467916992188, -31.427168526367197],
            [-54.74366791699218, -31.426068526367196],
            [-54.738367916992189, -31.426068526367196],
            [-54.735767916992188, -31.428668526367197],
            [-54.72896791699219, -31.429168526367196],
            [-54.72426791699219, -31.429968526367198],
            [-54.722767916992183, -31.432668526367195],
            [-54.719867916992186, -31.437668526367197],
            [-54.713667916992186, -31.437968526367197],
            [-54.706867916992181, -31.439168526367197],
            [-54.703167916992186, -31.438768526367198],
            [-54.700967916992184, -31.434768526367197],
            [-54.69786791699218, -31.436968526367195],
            [-54.695967916992188, -31.440168526367195],
            [-54.69516791699219, -31.443168526367195],
            [-54.692367916992183, -31.445468526367197],
            [-54.688767916992184, -31.445668526367196],
            [-54.685267916992188, -31.444668526367195],
            [-54.682267916992188, -31.445468526367197],
            [-54.678867916992189, -31.447168526367197],
            [-54.67546791699219, -31.449868526367197],
            [-54.671667916992185, -31.453168526367197],
            [-54.661367916992184, -31.454268526367194],
            [-54.64206791699219, -31.454868526367196],
            [-54.630167916992185, -31.455168526367196],
            [-54.619167916992183, -31.455468526367198],
            [-54.602367916992186, -31.455968526367197],
            [-54.587067916992183, -31.458768526367194],
            [-54.585867916992186, -31.462568526367196],
            [-54.583167916992181, -31.464868526367198],
            [-54.57956791699219, -31.470368526367196],
            [-54.579267916992187, -31.475868526367197],
            [-54.575967916992184, -31.481668526367194],
            [-54.57156791699218, -31.482468526367196],
            [-54.569267916992189, -31.484968526367197],
            [-54.565967916992186, -31.486868526367196],
            [-54.562667916992183, -31.486668526367197],
            [-54.559967916992186, -31.489868526367196],
            [-54.557167916992185, -31.493668526367195],
            [-54.552867916992184, -31.492668526367197],
            [-54.549367916992182, -31.494768526367196],
            [-54.546867916992184, -31.497768526367196],
            [-54.540667916992184, -31.499868526367194],
            [-54.532867916992188, -31.501868526367197],
            [-54.529267916992183, -31.504568526367194],
            [-54.52436791699219, -31.506568526367197],
            [-54.522567916992188, -31.509568526367197],
            [-54.518167916992184, -31.510468526367198],
            [-54.514867916992188, -31.511268526367196],
            [-54.513567916992187, -31.514268526367196],
            [-54.512667916992186, -31.517068526367197],
            [-54.509667916992186, -31.521468526367197],
            [-54.505667916992181, -31.524068526367195],
            [-54.502967916992183, -31.529068526367197],
            [-54.501867916992182, -31.532568526367196],
            [-54.501267916992184, -31.536768526367197],
            [-54.499367916992185, -31.539268526367195],
            [-54.49666791699218, -31.540668526367195],
            [-54.495067916992184, -31.543568526367196],
            [-54.492167916992187, -31.547468526367197],
            [-54.490367916992184, -31.552168526367197],
            [-54.493067916992189, -31.556868526367197],
            [-54.489467916992183, -31.563068526367196],
            [-54.485667916992185, -31.565568526367194],
            [-54.482467916992185, -31.567368526367197],
            [-54.479567916992181, -31.569968526367198],
            [-54.473867916992184, -31.569868526367195],
            [-54.472667916992187, -31.572868526367195],
            [-54.471467916992182, -31.576268526367198],
            [-54.470267916992185, -31.579268526367194],
            [-54.469067916992188, -31.582868526367196],
            [-54.469167916992184, -31.587568526367196],
            [-54.468467916992182, -31.591968526367197],
            [-54.464367916992188, -31.590068526367197],
            [-54.461167916992181, -31.593368526367197],
            [-54.458267916992185, -31.595568526367195],
            [-54.456167916992186, -31.598668526367195],
            [-54.452967916992186, -31.599268526367197],
            [-54.455767916992187, -31.601768526367195],
            [-54.456767916992185, -31.605568526367197],
            [-54.460167916992184, -31.606168526367195],
            [-54.463667916992186, -31.608868526367196],
            [-54.466167916992184, -31.612868526367198],
            [-54.46326791699218, -31.614068526367198],
            [-54.464367916992188, -31.617968526367196],
            [-54.46156791699218, -31.621968526367198],
            [-54.460367916992183, -31.626368526367195],
            [-54.45726791699218, -31.629068526367195],
            [-54.454367916992183, -31.629168526367195],
            [-54.45456791699219, -31.632768526367197],
            [-54.454267916992187, -31.635968526367197],
            [-54.452167916992181, -31.639968526367198],
            [-54.453767916992184, -31.645468526367196],
            [-54.453967916992184, -31.650368526367195],
            [-54.451867916992185, -31.654768526367196],
            [-54.421367916992182, -31.677868526367195],
            [-54.402067916992181, -31.692168526367198],
            [-54.383467916992188, -31.706168526367197],
            [-54.377067916992189, -31.710968526367196],
            [-54.351667916992184, -31.729968526367195],
            [-54.343767916992185, -31.735768526367195],
            [-54.334667916992188, -31.742668526367197],
            [-54.317967916992188, -31.755168526367196],
            [-54.285967916992185, -31.778768526367195],
            [-54.255267916992182, -31.801868526367198],
            [-54.236067916992184, -31.816268526367196],
            [-54.218667916992189, -31.829068526367195],
            [-54.203967916992184, -31.840068526367197],
            [-54.188567916992184, -31.851468526367196],
            [-54.173967916992183, -31.862168526367196],
            [-54.166367916992186, -31.867968526367196],
            [-54.163667916992182, -31.869768526367196],
            [-54.160867916992188, -31.872168526367197],
            [-54.158067916992181, -31.874068526367196],
            [-54.155567916992183, -31.876068526367195],
            [-54.152967916992182, -31.878468526367197],
            [-54.149467916992187, -31.879468526367198],
            [-54.147067916992185, -31.881068526367194],
            [-54.143967916992182, -31.882968526367197],
            [-54.142667916992181, -31.885968526367197],
            [-54.143467916992186, -31.889068526367197],
            [-54.142167916992186, -31.892068526367197],
            [-54.140667916992186, -31.894568526367195],
            [-54.137467916992186, -31.896468526367197],
            [-54.134267916992187, -31.897268526367196],
            [-54.130967916992184, -31.897568526367195],
            [-54.127367916992185, -31.898968526367195],
            [-54.123867916992182, -31.900768526367195],
            [-54.120767916992186, -31.903168526367196],
            [-54.116867916992184, -31.904768526367196],
            [-54.114067916992184, -31.907068526367198],
            [-54.110567916992181, -31.908068526367195],
            [-54.108367916992187, -31.910568526367197],
            [-54.105667916992189, -31.912768526367195],
            [-54.107067916992186, -31.916768526367196],
            [-54.104567916992181, -31.920368526367195],
            [-54.101667916992184, -31.923668526367194],
            [-54.100867916992186, -31.927168526367197],
            [-54.098067916992186, -31.928568526367197],
            [-54.094767916992183, -31.930568526367196],
            [-54.09126791699218, -31.930768526367196],
            [-54.090067916992183, -31.934068526367195],
            [-54.087367916992186, -31.932968526367198],
            [-54.083967916992187, -31.929768526367198],
            [-54.080367916992188, -31.928068526367198],
            [-54.079667916992186, -31.923668526367194],
            [-54.076767916992182, -31.920868526367197],
            [-54.07316791699219, -31.919468526367197],
            [-54.07016791699219, -31.921968526367195],
            [-54.065967916992186, -31.921668526367196],
            [-54.062667916992183, -31.917568526367198],
            [-54.059067916992184, -31.913968526367196],
            [-54.056567916992186, -31.910568526367197],
            [-54.054667916992187, -31.906968526367194],
            [-54.051967916992183, -31.904468526367197],
            [-54.048867916992187, -31.904468526367197],
            [-54.045067916992181, -31.902668526367197],
            [-54.04276791699219, -31.900468526367195],
            [-54.040667916992184, -31.897868526367198],
            [-54.037867916992184, -31.897368526367195],
            [-54.032767916992185, -31.896768526367197],
            [-54.027667916992186, -31.897668526367198],
            [-54.02406791699218, -31.898368526367197],
            [-54.022567916992188, -31.901468526367196],
            [-54.016567916992187, -31.902968526367196],
            [-54.012667916992186, -31.904768526367196],
            [-54.014267916992182, -31.907668526367196],
            [-54.010467916992184, -31.908068526367195],
            [-54.00736791699218, -31.908468526367194],
            [-54.006567916992182, -31.911668526367198],
            [-54.003067916992187, -31.914168526367195],
            [-53.999967916992183, -31.914568526367198],
            [-53.996867916992187, -31.914268526367195],
            [-53.993367916992185, -31.915268526367196],
            [-53.990367916992184, -31.917068526367196],
            [-53.986467916992183, -31.916968526367196],
            [-53.98196791699219, -31.916668526367197],
            [-53.977367916992186, -31.917768526367198],
            [-53.97426791699219, -31.918068526367197],
            [-53.97096791699218, -31.918468526367196],
            [-53.968367916992186, -31.920668526367194],
            [-53.965067916992183, -31.921768526367195],
            [-53.962867916992181, -31.923568526367198],
            [-53.961767916992187, -31.927268526367197],
            [-53.963467916992187, -31.930768526367196],
            [-53.965867916992181, -31.933568526367196],
            [-53.967567916992181, -31.936068526367198],
            [-53.971467916992182, -31.936368526367197],
            [-53.97226791699218, -31.940568526367194],
            [-53.970867916992184, -31.943168526367195],
            [-53.967667916992184, -31.945168526367198],
            [-53.965867916992181, -31.949268526367195],
            [-53.962967916992184, -31.950968526367198],
            [-53.960967916992189, -31.953868526367195],
            [-53.957367916992183, -31.955368526367195],
            [-53.95256791699218, -31.956168526367197],
            [-53.94786791699218, -31.953268526367197],
            [-53.943467916992184, -31.956768526367195],
            [-53.93916791699219, -31.958168526367196],
            [-53.935467916992181, -31.959068526367197],
            [-53.932167916992185, -31.962268526367197],
            [-53.929067916992182, -31.962668526367196],
            [-53.924967916992188, -31.962868526367195],
            [-53.91946791699219, -31.963668526367197],
            [-53.916067916992183, -31.965168526367197],
            [-53.912367916992181, -31.966968526367197],
            [-53.91006791699219, -31.970368526367196],
            [-53.907267916992183, -31.974568526367197],
            [-53.903667916992184, -31.978068526367196],
            [-53.89936791699219, -31.978668526367194],
            [-53.896767916992189, -31.983168526367194],
            [-53.894667916992184, -31.986068526367198],
            [-53.892167916992186, -31.989468526367197],
            [-53.89206791699219, -31.994368526367197],
            [-53.885967916992186, -31.995168526367195],
            [-53.88136791699219, -31.994768526367196],
            [-53.878467916992186, -31.995768526367197],
            [-53.875167916992183, -31.997668526367196],
            [-53.872367916992189, -31.998868526367197],
            [-53.870067916992184, -31.996368526367196],
            [-53.865467916992188, -31.993868526367194],
            [-53.860667916992185, -31.996268526367196],
            [-53.85666791699218, -31.997368526367197],
            [-53.852767916992185, -31.999468526367195],
            [-53.849867916992181, -32.000768526367196],
            [-53.851667916992184, -32.003068526367194],
            [-53.853367916992184, -32.006568526367197],
            [-53.853467916992187, -32.010468526367198],
            [-53.852067916992183, -32.014668526367196],
            [-53.853067916992188, -32.020168526367193],
            [-53.851767916992188, -32.024068526367195],
            [-53.851367916992189, -32.028768526367195],
            [-53.847667916992187, -32.028968526367194],
            [-53.84456791699219, -32.0295685263672],
            [-53.840467916992182, -32.031668526367199],
            [-53.838667916992186, -32.033868526367193],
            [-53.841967916992189, -32.036668526367194],
            [-53.840967916992184, -32.039468526367195],
            [-53.835867916992186, -32.040568526367196],
            [-53.833767916992187, -32.043568526367196],
            [-53.833467916992184, -32.046468526367192],
            [-53.835467916992187, -32.049968526367195],
            [-53.833667916992184, -32.054068526367196],
            [-53.831067916992183, -32.055768526367196],
            [-53.828767916992184, -32.057968526367198],
            [-53.825767916992184, -32.059468526367198],
            [-53.820767916992182, -32.059368526367194],
            [-53.815467916992183, -32.059668526367197],
            [-53.813767916992184, -32.063268526367196],
            [-53.81086791699218, -32.065468526367198],
            [-53.807967916992183, -32.067368526367197],
            [-53.805867916992185, -32.069568526367199],
            [-53.80216791699219, -32.070768526367196],
            [-53.79746791699219, -32.069868526367195],
            [-53.793967916992187, -32.069168526367193],
            [-53.790567916992188, -32.069568526367199],
            [-53.78676791699219, -32.071568526367194],
            [-53.781567916992188, -32.071268526367199],
            [-53.777867916992186, -32.071668526367198],
            [-53.774567916992183, -32.072468526367196],
            [-53.770467916992189, -32.073168526367198],
            [-53.767967916992184, -32.077068526367199],
            [-53.764367916992185, -32.079368526367197],
            [-53.759867916992185, -32.077668526367198],
            [-53.755567916992185, -32.077568526367195],
            [-53.751267916992184, -32.078968526367198],
            [-53.747167916992183, -32.078268526367197],
            [-53.74666791699218, -32.082568526367197],
            [-53.746567916992184, -32.087668526367196],
            [-53.745167916992187, -32.091268526367195],
            [-53.741067916992186, -32.0920685263672],
            [-53.736367916992187, -32.092568526367195],
            [-53.731767916992183, -32.093768526367199],
            [-53.72696791699218, -32.095168526367196],
            [-53.725567916992183, -32.098168526367196],
            [-53.727067916992183, -32.101168526367196],
            [-53.72896791699219, -32.103568526367198],
            [-53.731067916992188, -32.105568526367193],
            [-53.73466791699218, -32.1027685263672],
            [-53.739167916992187, -32.100668526367194],
            [-53.740267916992181, -32.103468526367195],
            [-53.738267916992186, -32.106768526367198],
            [-53.739767916992186, -32.111068526367198],
            [-53.74266791699219, -32.113968526367195],
            [-53.742767916992186, -32.118068526367196],
            [-53.739967916992185, -32.121268526367196],
            [-53.738267916992186, -32.123768526367193],
            [-53.736367916992187, -32.126268526367198],
            [-53.734167916992185, -32.129868526367197],
            [-53.731767916992183, -32.132968526367193],
            [-53.727767916992185, -32.136468526367196],
            [-53.725567916992183, -32.141068526367199],
            [-53.725567916992183, -32.144768526367194],
            [-53.728067916992188, -32.146768526367197],
            [-53.731467916992187, -32.147968526367194],
            [-53.733967916992185, -32.150968526367194],
            [-53.729467916992185, -32.152668526367194],
            [-53.724567916992186, -32.151268526367197],
            [-53.718767916992185, -32.152068526367195],
            [-53.717067916992185, -32.155068526367195],
            [-53.718067916992183, -32.159768526367195],
            [-53.71926791699218, -32.164468526367195],
            [-53.72226791699218, -32.168068526367193],
            [-53.723367916992188, -32.173168526367199],
            [-53.720267916992185, -32.175368526367194],
            [-53.71656791699219, -32.175568526367194],
            [-53.713667916992186, -32.176868526367194],
            [-53.714067916992185, -32.180268526367193],
            [-53.714767916992187, -32.183368526367197],
            [-53.713967916992189, -32.187268526367198],
            [-53.710067916992188, -32.188768526367198],
            [-53.706267916992189, -32.187368526367194],
            [-53.70286791699219, -32.188768526367198],
            [-53.703567916992185, -32.192368526367197],
            [-53.707067916992187, -32.194568526367199],
            [-53.710467916992187, -32.196768526367194],
            [-53.709767916992185, -32.201068526367195],
            [-53.707067916992187, -32.204268526367194],
            [-53.703967916992184, -32.206568526367192],
            [-53.699967916992186, -32.204568526367197],
            [-53.69516791699219, -32.203868526367195],
            [-53.692667916992185, -32.207568526367197],
            [-53.694167916992185, -32.210468526367194],
            [-53.693067916992185, -32.213668526367194],
            [-53.690267916992184, -32.215968526367199],
            [-53.68716791699218, -32.218368526367193],
            [-53.68416791699218, -32.221668526367196],
            [-53.682267916992188, -32.225068526367195],
            [-53.680267916992186, -32.228168526367199],
            [-53.67716791699219, -32.233268526367198],
            [-53.676067916992181, -32.237268526367195],
            [-53.678367916992187, -32.241068526367194],
            [-53.678067916992184, -32.246868526367194],
            [-53.677267916992186, -32.250868526367199],
            [-53.67716791699219, -32.254368526367195],
            [-53.677467916992185, -32.258568526367199],
            [-53.675067916992184, -32.260768526367194],
            [-53.673567916992184, -32.263468526367198],
            [-53.671967916992187, -32.266068526367199],
            [-53.669967916992185, -32.268268526367194],
            [-53.666967916992185, -32.270768526367199],
            [-53.665267916992185, -32.274468526367194],
            [-53.66006791699219, -32.275368526367195],
            [-53.656567916992188, -32.278368526367196],
            [-53.655867916992186, -32.282268526367197],
            [-53.656267916992185, -32.285868526367196],
            [-53.656467916992185, -32.288968526367199],
            [-53.652667916992186, -32.292268526367195],
            [-53.648767916992185, -32.293868526367199],
            [-53.643767916992189, -32.295868526367194],
            [-53.643467916992186, -32.301568526367198],
            [-53.646267916992187, -32.3086685263672],
            [-53.647667916992184, -32.311968526367195],
            [-53.652267916992187, -32.3116685263672],
            [-53.656167916992189, -32.311968526367195],
            [-53.65676791699218, -32.315268526367198],
            [-53.656167916992189, -32.318768526367194],
            [-53.651267916992182, -32.321068526367199],
            [-53.646267916992187, -32.3223685263672],
            [-53.643267916992187, -32.325168526367193],
            [-53.644567916992187, -32.329668526367193],
            [-53.643567916992183, -32.335768526367197],
            [-53.641267916992184, -32.338468526367194],
            [-53.638467916992184, -32.343668526367196],
            [-53.634867916992185, -32.346968526367199],
            [-53.633267916992182, -32.351168526367196],
            [-53.633767916992184, -32.355568526367193],
            [-53.636467916992189, -32.358268526367198],
            [-53.638567916992187, -32.361368526367194],
            [-53.64036791699219, -32.364168526367195],
            [-53.642967916992184, -32.367168526367195],
            [-53.643667916992186, -32.370468526367198],
            [-53.644067916992185, -32.375268526367194],
            [-53.64506791699219, -32.378868526367199],
            [-53.64506791699219, -32.382968526367193],
            [-53.639267916992182, -32.385768526367194],
            [-53.634967916992181, -32.386768526367199],
            [-53.631567916992182, -32.3878685263672],
            [-53.627767916992184, -32.389068526367197],
            [-53.623867916992182, -32.390968526367196],
            [-53.619667916992185, -32.393668526367193],
            [-53.615267916992181, -32.397368526367195],
            [-53.612467916992188, -32.402568526367197],
            [-53.613267916992186, -32.406268526367199],
            [-53.61466791699219, -32.411168526367199],
            [-53.615367916992184, -32.415668526367199],
            [-53.613067916992186, -32.417868526367194],
            [-53.609767916992183, -32.420268526367195],
            [-53.607267916992186, -32.422268526367198],
            [-53.604267916992185, -32.425068526367198],
            [-53.60196791699218, -32.429068526367196],
            [-53.599167916992187, -32.431168526367195],
            [-53.595567916992181, -32.432968526367198],
            [-53.592267916992185, -32.4336685263672],
            [-53.588667916992186, -32.435768526367198],
            [-53.587367916992186, -32.439468526367193],
            [-53.586767916992187, -32.442368526367197],
            [-53.586467916992184, -32.445268526367194],
            [-53.585067916992188, -32.448568526367197],
            [-53.583767916992187, -32.451268526367194],
            [-53.580367916992188, -32.452968526367194],
            [-53.57656791699219, -32.454668526367193],
            [-53.573567916992189, -32.456068526367197],
            [-53.56986791699218, -32.456868526367195],
            [-53.566567916992184, -32.459268526367197],
            [-53.565167916992181, -32.461868526367198],
            [-53.562367916992187, -32.465868526367196],
            [-53.559067916992184, -32.469468526367194],
            [-53.556067916992184, -32.472668526367194],
            [-53.552567916992189, -32.473668526367199],
            [-53.548267916992188, -32.4730685263672],
            [-53.544667916992182, -32.4730685263672],
            [-53.54146791699219, -32.4730685263672],
            [-53.541167916992187, -32.476168526367196],
            [-53.54246791699218, -32.479468526367199],
            [-53.537567916992188, -32.480568526367193],
            [-53.531667916992184, -32.4824685263672],
            [-53.528667916992184, -32.485768526367195],
            [-53.525367916992181, -32.488868526367199],
            [-53.522867916992183, -32.486368526367194],
            [-53.523267916992182, -32.482868526367199],
            [-53.520367916992186, -32.481168526367199],
            [-53.516067916992185, -32.482768526367195],
            [-53.516767916992187, -32.486868526367196],
            [-53.513967916992186, -32.487968526367197],
            [-53.510467916992184, -32.486068526367198],
            [-53.508167916992186, -32.483368526367194],
            [-53.505467916992188, -32.482268526367193],
            [-53.502367916992185, -32.481368526367199],
            [-53.499067916992189, -32.481068526367196],
            [-53.494167916992183, -32.481468526367195],
            [-53.490067916992189, -32.482068526367193],
            [-53.486067916992184, -32.483368526367194],
            [-53.482267916992186, -32.483868526367196],
            [-53.478667916992187, -32.483868526367196],
            [-53.474867916992181, -32.4824685263672],
            [-53.471167916992187, -32.480668526367197],
            [-53.468367916992186, -32.482868526367199],
            [-53.464867916992183, -32.484268526367195],
            [-53.462867916992181, -32.486868526367196],
            [-53.464067916992185, -32.490068526367196],
            [-53.46626791699218, -32.492568526367194],
            [-53.464767916992187, -32.495268526367198],
            [-53.462567916992185, -32.498268526367198],
            [-53.462967916992184, -32.503268526367194],
            [-53.461767916992187, -32.507068526367199],
            [-53.460467916992187, -32.510968526367193],
            [-53.457367916992183, -32.514968526367198],
            [-53.457867916992186, -32.519568526367195],
            [-53.456467916992182, -32.522968526367194],
            [-53.453267916992189, -32.525968526367194],
            [-53.450167916992186, -32.527968526367196],
            [-53.44816791699219, -32.530668526367194],
            [-53.446367916992187, -32.533368526367198],
            [-53.44216791699219, -32.539468526367195],
            [-53.43886791699218, -32.5449685263672],
            [-53.436867916992185, -32.548068526367196],
            [-53.435567916992184, -32.551068526367196],
            [-53.434367916992187, -32.554468526367195],
            [-53.433367916992182, -32.557568526367199],
            [-53.433267916992186, -32.5616685263672],
            [-53.431367916992187, -32.565468526367198],
            [-53.426467916992181, -32.563768526367198],
            [-53.424967916992188, -32.559768526367193],
            [-53.421367916992182, -32.558568526367196],
            [-53.416967916992185, -32.562168526367195],
            [-53.416767916992185, -32.567168526367198],
            [-53.41476791699219, -32.569868526367195],
            [-53.412267916992185, -32.572468526367196],
            [-53.409467916992185, -32.574868526367197],
            [-53.405667916992186, -32.577868526367197],
            [-53.403367916992181, -32.579968526367196],
            [-53.397867916992183, -32.582668526367193],
            [-53.392667916992181, -32.584768526367199],
            [-53.388967916992186, -32.586568526367195],
            [-53.38566791699219, -32.585668526367193],
            [-53.383267916992182, -32.582868526367193],
            [-53.380467916992188, -32.578268526367197],
            [-53.379567916992187, -32.573868526367193],
            [-53.377667916992181, -32.570968526367196],
            [-53.373867916992182, -32.569968526367198],
            [-53.370467916992183, -32.569668526367195],
            [-53.368267916992181, -32.572068526367197],
            [-53.366067916992186, -32.5753685263672],
            [-53.363567916992181, -32.577568526367195],
            [-53.360567916992181, -32.581468526367196],
            [-53.358667916992189, -32.585768526367197],
            [-53.356367916992184, -32.589568526367195],
            [-53.352767916992185, -32.591968526367197],
            [-53.34896791699218, -32.592268526367199],
            [-53.346167916992187, -32.591568526367197],
            [-53.343767916992185, -32.589768526367195],
            [-53.339767916992187, -32.585368526367198],
            [-53.335967916992189, -32.584068526367197],
            [-53.333167916992181, -32.585068526367195],
            [-53.330367916992188, -32.587868526367195],
            [-53.32456791699218, -32.591968526367197],
            [-53.319567916992185, -32.595968526367194],
            [-53.313067916992182, -32.598968526367194],
            [-53.307767916992184, -32.602868526367196],
            [-53.303067916992184, -32.606768526367198],
            [-53.299767916992181, -32.611668526367197],
            [-53.29716791699218, -32.615468526367195],
            [-53.292167916992184, -32.6194685263672],
            [-53.288867916992189, -32.621068526367196],
            [-53.284467916992185, -32.621368526367199],
            [-53.281267916992185, -32.620568526367194],
            [-53.278467916992184, -32.617968526367193],
            [-53.278067916992185, -32.612768526367198],
            [-53.274767916992189, -32.6074685263672],
            [-53.271767916992189, -32.605268526367198],
            [-53.268467916992186, -32.603968526367197],
            [-53.263967916992186, -32.603668526367194],
            [-53.260267916992184, -32.602468526367197],
            [-53.254867916992183, -32.601968526367195],
            [-53.249467916992188, -32.603168526367199],
            [-53.245567916992186, -32.607068526367193],
            [-53.244167916992183, -32.614168526367195],
            [-53.244167916992183, -32.6194685263672],
            [-53.243367916992185, -32.623068526367199],
            [-53.240467916992188, -32.627068526367196],
            [-53.236967916992185, -32.629668526367198],
            [-53.230567916992186, -32.632668526367198],
            [-53.221167916992187, -32.636468526367196],
            [-53.214567916992181, -32.638768526367194],
            [-53.209267916992189, -32.639668526367196],
            [-53.202667916992183, -32.640168526367198],
            [-53.197167916992186, -32.640768526367197],
            [-53.193267916992184, -32.641568526367195],
            [-53.189667916992185, -32.642868526367195],
            [-53.185467916992181, -32.645668526367196],
            [-53.182667916992187, -32.649068526367195],
            [-53.182167916992185, -32.652568526367197],
            [-53.180567916992189, -32.655068526367195],
            [-53.175767916992186, -32.657368526367193],
            [-53.075467916992181, -32.740868526367194],
            [-53.076867916992185, -32.754368526367195],
            [-53.079067916992187, -32.768968526367196],
            [-53.081267916992189, -32.7795685263672],
            [-53.083267916992185, -32.785968526367199],
            [-53.085767916992182, -32.788168526367194],
            [-53.090467916992182, -32.788368526367194],
            [-53.096467916992182, -32.788068526367198],
            [-53.10526791699219, -32.787568526367195],
            [-53.11296791699219, -32.787368526367196],
            [-53.119167916992183, -32.787568526367195],
            [-53.124867916992187, -32.788068526367198],
            [-53.132767916992186, -32.788168526367194],
            [-53.138467916992184, -32.789268526367195],
            [-53.142167916992186, -32.790668526367199],
            [-53.145667916992181, -32.791968526367199],
            [-53.149567916992183, -32.794468526367197],
            [-53.152867916992186, -32.798068526367196],
            [-53.154767916992185, -32.801068526367196],
            [-53.153167916992189, -32.804368526367199],
            [-53.150467916992184, -32.807168526367192],
            [-53.152567916992183, -32.811568526367196],
            [-53.154867916992188, -32.815268526367198],
            [-53.158067916992181, -32.820168526367198],
            [-53.162467916992185, -32.8253685263672],
            [-53.165867916992184, -32.830968526367194],
            [-53.171067916992186, -32.8373685263672],
            [-53.17716791699219, -32.844168526367199],
            [-53.184867916992189, -32.850368526367198],
            [-53.23166791699218, -32.871268526367196],
            [-53.29446791699219, -32.899268526367194],
            [-53.310167916992185, -32.918668526367199],
            [-53.272667916992184, -32.927968526367195],
            [-53.245067916992184, -32.935168526367192],
            [-53.444367916992185, -33.052968526367195],
            [-53.518167916992184, -33.153368526367196],
            [-53.521467916992187, -33.258768526367199],
            [-53.521767916992189, -33.265068526367195],
            [-53.507767916992186, -33.353068526367196],
            [-53.511067916992189, -33.4593685263672],
            [-53.525167916992189, -33.526268526367197],
            [-53.523667916992181, -33.593368526367193],
            [-53.531267916992185, -33.600968526367197],
            [-53.534567916992188, -33.603368526367198],
            [-53.532567916992186, -33.607768526367195],
            [-53.533367916992184, -33.611068526367198],
            [-53.535867916992188, -33.613268526367193],
            [-53.534267916992185, -33.6164685263672],
            [-53.53206791699219, -33.618668526367195],
            [-53.529267916992183, -33.620868526367197],
            [-53.527267916992187, -33.624968526367198],
            [-53.527867916992186, -33.6301685263672],
            [-53.528967916992187, -33.633268526367196],
            [-53.528667916992184, -33.636268526367196],
            [-53.52876791699218, -33.639668526367196],
            [-53.530467916992187, -33.642568526367199],
            [-53.531667916992184, -33.646768526367197],
            [-53.53376791699219, -33.649768526367197],
            [-53.533067916992181, -33.652668526367194],
            [-53.531567916992188, -33.656168526367196],
            [-53.532167916992186, -33.6605685263672],
            [-53.531467916992185, -33.666068526367198],
            [-53.528167916992189, -33.669168526367194],
            [-53.527967916992182, -33.672568526367193],
            [-53.525667916992184, -33.674468526367193],
            [-53.529467916992189, -33.677868526367199],
            [-53.530367916992184, -33.681068526367199],
            [-53.530167916992184, -33.684368526367194],
            [-53.530167916992184, -33.687668526367197],
            [-53.523167916992186, -33.689168526367197],
            [-53.511867916992188, -33.689868526367199],
            [-53.492267916992184, -33.690768526367194],
            [-53.483967916992185, -33.691268526367196],
            [-53.481067916992188, -33.691368526367199],
            [-53.475867916992186, -33.691568526367199],
            [-53.471967916992185, -33.691668526367195],
            [-53.468667916992189, -33.691868526367195],
            [-53.462267916992189, -33.692168526367198],
            [-53.455367916992188, -33.6926685263672],
            [-53.452167916992181, -33.692768526367196],
            [-53.447367916992185, -33.692968526367196],
            [-53.440267916992184, -33.694068526367197],
            [-53.441267916992189, -33.697868526367195],
            [-53.437267916992184, -33.700468526367196],
            [-53.438067916992182, -33.703968526367198],
            [-53.437367916992187, -33.707368526367198],
            [-53.437467916992183, -33.712968526367199],
            [-53.435167916992185, -33.716468526367194],
            [-53.433767916992181, -33.720868526367198],
            [-53.435467916992181, -33.724268526367197],
            [-53.432767916992184, -33.727368526367194],
            [-53.431067916992184, -33.730868526367196],
            [-53.431667916992183, -33.734468526367195],
            [-53.430567916992189, -33.738868526367199],
            [-53.426867916992187, -33.739468526367197],
            [-53.423167916992185, -33.742968526367193],
            [-53.419567916992186, -33.745168526367195],
            [-53.415867916992184, -33.748268526367198],
            [-53.41176791699219, -33.749368526367199],
            [-53.407567916992186, -33.749368526367199],
            [-53.403367916992181, -33.749468526367195],
            [-53.39906791699218, -33.748568526367194],
            [-53.395767916992185, -33.750768526367196],
            [-53.392167916992186, -33.750568526367196],
            [-53.389567916992185, -33.748668526367197],
            [-53.386267916992182, -33.746268526367196],
            [-53.379867916992183, -33.746968526367198],
            [-53.374967916992183, -33.744668526367192],
            [-53.370867916992182, -33.743268526367196],
            [-53.366967916992181, -33.740768526367198],
            [-53.364067916992184, -33.738368526367196],
            [-53.360067916992186, -33.735768526367195],
            [-53.35526791699219, -33.732168526367197],
            [-53.350267916992181, -33.728468526367195],
            [-53.347767916992183, -33.726768526367195],
            [-53.343967916992185, -33.723768526367195],
            [-53.34126791699218, -33.721968526367199],
            [-53.337867916992181, -33.719568526367198],
            [-53.335067916992188, -33.717668526367198],
            [-53.33256791699219, -33.715868526367196],
            [-53.328967916992184, -33.713768526367197],
            [-53.32656791699219, -33.712268526367197],
            [-53.32316791699219, -33.709768526367199],
            [-53.319067916992182, -33.707168526367198],
            [-53.314967916992188, -33.704268526367194],
            [-53.312267916992184, -33.702168526367196],
            [-53.308367916992182, -33.699568526367194],
            [-53.304167916992185, -33.696668526367198],
            [-53.300267916992183, -33.694068526367197],
            [-53.296067916992186, -33.691268526367196],
            [-53.292167916992184, -33.688768526367198],
            [-53.288867916992189, -33.6866685263672],
            [-53.285867916992188, -33.684868526367197],
            [-53.28346791699218, -33.683368526367197],
            [-53.279767916992185, -33.681068526367199],
            [-53.274767916992189, -33.677868526367199],
            [-53.268967916992182, -33.673868526367194],
            [-53.264967916992184, -33.671368526367196],
            [-53.262467916992186, -33.669168526367194],
            [-53.259567916992182, -33.667068526367196],
            [-53.25636791699219, -33.664768526367197],
            [-53.253867916992185, -33.662768526367195],
            [-53.249767916992184, -33.659868526367198],
            [-53.246167916992185, -33.657368526367193],
            [-53.241467916992185, -33.654268526367197],
            [-53.237767916992183, -33.6515685263672],
            [-53.233967916992185, -33.648468526367196],
            [-53.230667916992189, -33.645968526367199],
            [-53.227367916992186, -33.643168526367198],
            [-53.224167916992187, -33.640468526367194],
            [-53.221167916992187, -33.6378685263672],
            [-53.218167916992186, -33.635468526367198],
            [-53.213967916992189, -33.632368526367195],
            [-53.210067916992188, -33.629068526367199],
            [-53.206867916992181, -33.626368526367195],
            [-53.204067916992187, -33.624368526367199],
            [-53.200667916992188, -33.622168526367197],
            [-53.196667916992183, -33.619768526367196],
            [-53.193767916992186, -33.618068526367196],
            [-53.190967916992186, -33.616168526367197],
            [-53.187467916992183, -33.613868526367199],
            [-53.184067916992184, -33.611368526367194],
            [-53.180467916992185, -33.608668526367197],
            [-53.176667916992187, -33.605868526367196],
            [-53.173567916992184, -33.603068526367196],
            [-53.170567916992184, -33.600968526367197],
            [-53.167167916992184, -33.598468526367199],
            [-53.162767916992181, -33.595168526367196],
            [-53.160367916992186, -33.592968526367194],
            [-53.157267916992183, -33.590868526367196],
            [-53.152667916992186, -33.587068526367197],
            [-53.150367916992181, -33.585368526367198],
            [-53.147067916992185, -33.582568526367197],
            [-53.144567916992187, -33.580468526367198],
            [-53.141067916992185, -33.577168526367196],
            [-53.137467916992186, -33.573868526367193],
            [-53.135167916992188, -33.572068526367197],
            [-53.132967916992186, -33.570168526367198],
            [-53.122467916992186, -33.561068526367194],
            [-53.119767916992188, -33.558568526367196],
            [-53.116167916992183, -33.555768526367196],
            [-53.112267916992181, -33.5522685263672],
            [-53.110267916992186, -33.550068526367198],
            [-53.106767916992183, -33.547268526367198],
            [-53.103567916992183, -33.544768526367193],
            [-53.101167916992182, -33.542768526367198],
            [-53.097867916992186, -33.5402685263672],
            [-53.094167916992184, -33.537068526367193],
            [-53.090467916992182, -33.534168526367196],
            [-53.086167916992181, -33.530668526367194],
            [-53.08226791699218, -33.527668526367194],
            [-53.077967916992186, -33.524468526367194],
            [-53.07286791699218, -33.520468526367196],
            [-53.06986791699218, -33.518168526367198],
            [-53.066367916992185, -33.515468526367194],
            [-53.062267916992184, -33.512068526367194],
            [-53.058267916992186, -33.508868526367195],
            [-53.054367916992184, -33.505968526367198],
            [-53.049467916992185, -33.5021685263672],
            [-53.046667916992185, -33.499668526367195],
            [-53.042867916992186, -33.496868526367194],
            [-53.038867916992189, -33.493568526367199],
            [-53.035867916992188, -33.491168526367197],
            [-53.032767916992185, -33.488668526367199],
            [-53.030167916992184, -33.486468526367197],
            [-53.027067916992181, -33.483968526367192],
            [-53.023767916992185, -33.481468526367195],
            [-53.019667916992184, -33.478168526367199],
            [-53.016267916992184, -33.475568526367198],
            [-53.013567916992187, -33.473368526367196],
            [-53.01066791699219, -33.470968526367194],
            [-53.006567916992182, -33.467668526367198],
            [-53.002967916992183, -33.464868526367198],
            [-53.000167916992183, -33.462568526367193],
            [-52.996567916992184, -33.459768526367199],
            [-52.992267916992184, -33.456468526367196],
            [-52.988267916992186, -33.453168526367193],
            [-52.983367916992187, -33.449568526367194],
            [-52.980667916992189, -33.447168526367193],
            [-52.977467916992182, -33.444568526367199],
            [-52.972767916992183, -33.4409685263672],
            [-52.96926791699218, -33.438068526367196],
            [-52.964867916992183, -33.434668526367197],
            [-52.960667916992186, -33.431668526367197],
            [-52.95756791699219, -33.428568526367194],
            [-52.953167916992186, -33.425068526367198],
            [-52.948467916992186, -33.421668526367199],
            [-52.944967916992184, -33.418868526367199],
            [-52.940467916992183, -33.4152685263672],
            [-52.936367916992182, -33.411768526367197],
            [-52.932467916992181, -33.408768526367197],
            [-52.928067916992184, -33.405368526367198],
            [-52.924467916992185, -33.402568526367197],
            [-52.920367916992184, -33.399368526367198],
            [-52.916767916992185, -33.396468526367194],
            [-52.911667916992187, -33.392468526367196],
            [-52.905867916992186, -33.3878685263672],
            [-52.900467916992184, -33.383568526367199],
            [-52.895667916992181, -33.379568526367194],
            [-52.89036791699219, -33.375168526367197],
            [-52.884567916992182, -33.370568526367194],
            [-52.879067916992184, -33.366168526367197],
            [-52.876067916992184, -33.363668526367199],
            [-52.870467916992183, -33.358668526367197],
            [-52.865767916992183, -33.354668526367199],
            [-52.860867916992184, -33.350668526367194],
            [-52.856167916992185, -33.3467685263672],
            [-52.850067916992188, -33.3420685263672],
            [-52.845667916992184, -33.338668526367194],
            [-52.839767916992187, -33.334268526367197],
            [-52.835167916992184, -33.330668526367198],
            [-52.829867916992185, -33.326468526367194],
            [-52.82456791699218, -33.3223685263672],
            [-52.819367916992185, -33.318468526367198],
            [-52.815167916992181, -33.315168526367195],
            [-52.811867916992185, -33.312768526367194],
            [-52.808367916992182, -33.309468526367198],
            [-52.801967916992183, -33.304768526367198],
            [-52.796467916992185, -33.300068526367198],
            [-52.790667916992184, -33.295368526367199],
            [-52.786967916992182, -33.292068526367196],
            [-52.780867916992186, -33.286668526367194],
            [-52.776567916992185, -33.283068526367195],
            [-52.772867916992183, -33.279468526367197],
            [-52.769267916992185, -33.276168526367194],
            [-52.765967916992182, -33.272868526367198],
            [-52.76066791699219, -33.267668526367196],
            [-52.755467916992188, -33.262768526367196],
            [-52.750567916992182, -33.257768526367194],
            [-52.746067916992189, -33.2534685263672],
            [-52.741367916992189, -33.248568526367194],
            [-52.737767916992183, -33.244768526367196],
            [-52.733967916992185, -33.241068526367194],
            [-52.730567916992186, -33.237268526367195],
            [-52.726667916992184, -33.233068526367198],
            [-52.72226791699218, -33.228068526367196],
            [-52.717667916992184, -33.223168526367196],
            [-52.712967916992184, -33.218068526367198],
            [-52.709367916992186, -33.213768526367197],
            [-52.705667916992184, -33.2093685263672],
            [-52.701267916992187, -33.204368526367197],
            [-52.698767916992182, -33.201268526367194],
            [-52.69516791699219, -33.197168526367193],
            [-52.692367916992183, -33.193568526367194],
            [-52.687667916992183, -33.188468526367195],
            [-52.685567916992184, -33.185568526367199],
            [-52.682767916992184, -33.182168526367192],
            [-52.680267916992186, -33.179168526367199],
            [-52.676067916992181, -33.174168526367197],
            [-52.671967916992187, -33.169168526367194],
            [-52.666667916992182, -33.162068526367193],
            [-52.663167916992187, -33.158068526367195],
            [-52.658867916992186, -33.152568526367197],
            [-52.654467916992189, -33.1468685263672],
            [-52.650067916992185, -33.141568526367195],
            [-52.645467916992189, -33.135468526367198],
            [-52.640967916992182, -33.129668526367198],
            [-52.636367916992185, -33.123268526367198],
            [-52.63236791699218, -33.116868526367199],
            [-52.627367916992185, -33.109468526367195],
            [-52.623767916992186, -33.104168526367197],
            [-52.620167916992187, -33.0980685263672],
            [-52.615567916992184, -33.0903685263672],
            [-52.611467916992183, -33.083268526367199],
            [-52.607167916992182, -33.075768526367199],
            [-52.603367916992184, -33.069268526367196],
            [-52.599467916992182, -33.061968526367195],
            [-52.595667916992184, -33.054668526367195],
            [-52.593167916992186, -33.050068526367198],
            [-52.589867916992183, -33.043168526367197],
            [-52.58656791699218, -33.036668526367194],
            [-52.584567916992185, -33.031968526367194],
            [-52.580767916992187, -33.024468526367194],
            [-52.577167916992181, -33.016868526367197],
            [-52.572467916992181, -33.008568526367199],
            [-52.569567916992185, -33.002668526367195],
            [-52.567467916992186, -32.999068526367196],
            [-52.564967916992188, -32.994768526367196],
            [-52.563067916992182, -32.990868526367194],
            [-52.561267916992186, -32.986868526367196],
            [-52.559367916992187, -32.983368526367194],
            [-52.557767916992184, -32.979968526367195],
            [-52.556067916992184, -32.976468526367199],
            [-52.554167916992185, -32.9730685263672],
            [-52.551867916992187, -32.969168526367199],
            [-52.550067916992184, -32.965568526367193],
            [-52.548167916992185, -32.961868526367198],
            [-52.544967916992185, -32.956568526367192],
            [-52.541967916992185, -32.951268526367194],
            [-52.53946791699218, -32.946568526367194],
            [-52.537467916992185, -32.943168526367195],
            [-52.533967916992182, -32.937668526367197],
            [-52.530167916992184, -32.931168526367195],
            [-52.526567916992185, -32.925068526367198],
            [-52.522167916992188, -32.917868526367194],
            [-52.518467916992186, -32.911768526367197],
            [-52.514667916992181, -32.904868526367196],
            [-52.511467916992189, -32.899068526367195],
            [-52.506767916992189, -32.890368526367197],
            [-52.502467916992188, -32.882068526367199],
            [-52.498567916992187, -32.874068526367196],
            [-52.494667916992185, -32.866068526367194],
            [-52.490367916992184, -32.857068526367193],
            [-52.487867916992187, -32.850968526367197],
            [-52.485267916992186, -32.844268526367195],
            [-52.482167916992182, -32.835468526367194],
            [-52.479167916992182, -32.827968526367194],
            [-52.47596791699219, -32.819368526367199],
            [-52.471667916992189, -32.808568526367196],
            [-52.468367916992186, -32.799368526367196],
            [-52.46626791699218, -32.793868526367199],
            [-52.463967916992189, -32.787568526367195],
            [-52.462567916992185, -32.782668526367196],
            [-52.460067916992188, -32.775368526367195],
            [-52.457867916992186, -32.768568526367197],
            [-52.455767916992187, -32.761768526367199],
            [-52.453567916992185, -32.753768526367196],
            [-52.451367916992183, -32.744968526367195],
            [-52.448567916992189, -32.734968526367197],
            [-52.446367916992187, -32.725668526367194],
            [-52.44516791699219, -32.720668526367199],
            [-52.444367916992185, -32.717268526367199],
            [-52.443467916992184, -32.713368526367198],
            [-52.442667916992185, -32.709668526367196],
            [-52.441367916992185, -32.704568526367197],
            [-52.440567916992187, -32.701068526367195],
            [-52.439967916992188, -32.697668526367195],
            [-52.43916791699219, -32.694068526367197],
            [-52.438267916992189, -32.690468526367198],
            [-52.437267916992184, -32.6866685263672],
            [-52.436567916992189, -32.681868526367197],
            [-52.435467916992181, -32.677468526367193],
            [-52.43416791699218, -32.672468526367197],
            [-52.433267916992186, -32.668168526367197],
            [-52.431567916992186, -32.662468526367199],
            [-52.429767916992184, -32.656268526367199],
            [-52.429067916992182, -32.652568526367197],
            [-52.427767916992181, -32.647868526367198],
            [-52.426167916992185, -32.642068526367197],
            [-52.424767916992181, -32.636368526367193],
            [-52.423267916992188, -32.629868526367197],
            [-52.42076791699219, -32.621668526367195],
            [-52.418167916992189, -32.613068526367194],
            [-52.415667916992184, -32.605268526367198],
            [-52.413167916992187, -32.598468526367199],
            [-52.410967916992185, -32.592368526367196],
            [-52.409267916992185, -32.587668526367196],
            [-52.406967916992187, -32.581868526367195],
            [-52.405067916992181, -32.576768526367196],
            [-52.403167916992189, -32.570968526367196],
            [-52.400167916992189, -32.563268526367196],
            [-52.397667916992184, -32.556568526367194],
            [-52.39506791699219, -32.548968526367197],
            [-52.392667916992181, -32.543068526367193],
            [-52.391567916992187, -32.539968526367197],
            [-52.388967916992186, -32.533068526367195],
            [-52.386367916992185, -32.525768526367195],
            [-52.384167916992183, -32.520068526367197],
            [-52.381267916992186, -32.512768526367196],
            [-52.37936791699218, -32.508568526367199],
            [-52.376567916992187, -32.501868526367197],
            [-52.373567916992187, -32.494668526367192],
            [-52.370867916992182, -32.488268526367193],
            [-52.368067916992189, -32.481668526367194],
            [-52.365767916992183, -32.476668526367199],
            [-52.362567916992184, -32.469868526367193],
            [-52.359767916992183, -32.463968526367196],
            [-52.356467916992187, -32.457568526367197],
            [-52.354467916992185, -32.452968526367194],
            [-52.350667916992187, -32.445168526367198],
            [-52.347667916992187, -32.438468526367195],
            [-52.345567916992181, -32.434468526367198],
            [-52.344167916992184, -32.431668526367197],
            [-52.340667916992189, -32.424468526367193],
            [-52.337067916992183, -32.417868526367194],
            [-52.333467916992184, -32.411168526367199],
            [-52.330467916992184, -32.405168526367198],
            [-52.327067916992185, -32.399068526367195],
            [-52.324367916992188, -32.393268526367194],
            [-52.321667916992183, -32.388168526367195],
            [-52.32016791699219, -32.384968526367196],
            [-52.318267916992184, -32.381868526367199],
            [-52.316667916992188, -32.378868526367199],
            [-52.314667916992185, -32.375768526367196],
            [-52.312767916992186, -32.371968526367198],
            [-52.31086791699218, -32.368568526367199],
            [-52.307967916992183, -32.363068526367194],
            [-52.304967916992183, -32.358168526367194],
            [-52.301967916992183, -32.353168526367199],
            [-52.299167916992189, -32.348468526367199],
            [-52.296667916992185, -32.344268526367195],
            [-52.293067916992186, -32.338268526367195],
            [-52.290267916992185, -32.333968526367194],
            [-52.286267916992188, -32.328168526367193],
            [-52.28346791699218, -32.323768526367196],
            [-52.280367916992184, -32.319368526367199],
            [-52.276767916992185, -32.314068526367194],
            [-52.271567916992183, -32.306868526367197],
            [-52.269667916992184, -32.304068526367196],
            [-52.264967916992184, -32.298068526367196],
            [-52.262467916992186, -32.295068526367196],
            [-52.260267916992184, -32.292168526367199],
            [-52.25606791699218, -32.286968526367197],
            [-52.250867916992185, -32.279768526367199],
            [-52.244667916992185, -32.272368526367195],
            [-52.238967916992181, -32.266068526367199],
            [-52.233867916992182, -32.260168526367195],
            [-52.229167916992182, -32.255268526367196],
            [-52.225267916992181, -32.251268526367198],
            [-52.220867916992184, -32.246968526367198],
            [-52.215967916992184, -32.242968526367193],
            [-52.21186791699219, -32.239668526367197],
            [-52.208267916992185, -32.235668526367199],
            [-52.203467916992182, -32.230568526367193],
            [-52.198967916992189, -32.226668526367199],
            [-52.19516791699219, -32.223468526367199],
            [-52.188767916992184, -32.218668526367196],
            [-52.186067916992187, -32.216468526367194],
            [-52.181567916992186, -32.212868526367195],
            [-52.178267916992183, -32.210168526367198],
            [-52.173367916992184, -32.205768526367194],
            [-52.17076791699219, -32.203568526367199],
            [-52.16746791699218, -32.200768526367199],
            [-52.16476791699219, -32.198468526367193],
            [-52.162067916992186, -32.196268526367199],
            [-52.159167916992189, -32.193768526367194],
            [-52.156267916992185, -32.191368526367199],
            [-52.15376791699218, -32.189468526367193],
            [-52.151467916992189, -32.187368526367194],
            [-52.147267916992185, -32.184068526367199],
            [-52.143967916992182, -32.181368526367194],
            [-52.140967916992182, -32.179168526367199],
            [-52.132967916992186, -32.173868526367194],
            [-52.129967916992186, -32.172168526367194],
            [-52.124867916992187, -32.169168526367194],
            [-52.116867916992184, -32.165568526367196],
            [-52.110667916992185, -32.163468526367197],
            [-52.106767916992183, -32.163068526367198],
            [-52.098467916992185, -32.161168526367199],
            [-52.082367916992183, -32.159868526367198],
            [-52.082167916992184, -32.156168526367196],
            [-52.080667916992184, -32.151768526367199],
            [-52.077967916992186, -32.1468685263672],
            [-52.078267916992189, -32.143268526367194],
            [-52.074667916992183, -32.140468526367194],
            [-52.069567916992185, -32.135268526367199],
            [-52.066267916992189, -32.132068526367199],
            [-52.061567916992189, -32.127068526367196],
            [-52.057667916992187, -32.123268526367198],
            [-52.053567916992186, -32.119068526367194],
            [-52.049667916992185, -32.114968526367193],
            [-52.044567916992186, -32.109968526367197],
            [-52.039967916992182, -32.104768526367195],
            [-52.035567916992186, -32.099968526367199],
            [-52.03176791699218, -32.095968526367194],
            [-52.027667916992186, -32.091568526367197],
            [-52.022667916992184, -32.086268526367199],
            [-52.018167916992184, -32.081468526367196],
            [-52.012467916992186, -32.075668526367195],
            [-52.005967916992184, -32.069068526367197],
            [-51.999367916992185, -32.062468526367198],
            [-51.993867916992187, -32.0569685263672],
            [-51.985767916992188, -32.048968526367197],
            [-51.981767916992183, -32.0449685263672],
            [-51.977467916992182, -32.0402685263672],
            [-51.973367916992188, -32.036368526367198],
            [-51.968467916992182, -32.031468526367199],
            [-51.964767916992187, -32.0278685263672],
            [-51.958467916992184, -32.0218685263672],
            [-51.95286791699219, -32.016768526367194],
            [-51.947367916992185, -32.011568526367199],
            [-51.943467916992184, -32.008068526367197],
            [-51.936667916992185, -32.001868526367197],
            [-51.93416791699218, -31.999668526367195],
            [-51.931567916992186, -31.997368526367197],
            [-51.92716791699219, -31.993268526367196],
            [-51.920567916992184, -31.987468526367195],
            [-51.914567916992183, -31.982768526367195],
            [-51.910667916992182, -31.979268526367196],
            [-51.90846791699218, -31.977468526367197],
            [-51.902667916992186, -31.972768526367197],
            [-51.89506791699219, -31.966268526367195],
            [-51.885967916992186, -31.958968526367197],
            [-51.880267916992182, -31.954668526367197],
            [-51.874067916992189, -31.950168526367197],
            [-51.867567916992186, -31.945268526367197],
            [-51.85996791699219, -31.939668526367196],
            [-51.853867916992186, -31.935168526367196],
            [-51.84756791699219, -31.930768526367196],
            [-51.844467916992187, -31.928568526367197],
            [-51.839767916992187, -31.925268526367198],
            [-51.835067916992188, -31.921968526367195],
            [-51.830767916992187, -31.919168526367194],
            [-51.826067916992187, -31.915968526367195],
            [-51.821267916992184, -31.912868526367198],
            [-51.815767916992186, -31.909768526367195],
            [-51.812467916992183, -31.907568526367196],
            [-51.807567916992184, -31.904468526367197],
            [-51.803667916992183, -31.901968526367195],
            [-51.800767916992186, -31.900168526367196],
            [-51.796467916992185, -31.897668526367198],
            [-51.793067916992186, -31.895668526367196],
            [-51.790067916992186, -31.893968526367196],
            [-51.785367916992186, -31.891068526367196],
            [-51.780867916992186, -31.888468526367198],
            [-51.775867916992183, -31.885468526367198],
            [-51.770767916992185, -31.882768526367197],
            [-51.76536791699219, -31.879868526367197],
            [-51.759967916992181, -31.876568526367198],
            [-51.75466791699219, -31.873668526367197],
            [-51.747967916992181, -31.869968526367195],
            [-51.74266791699219, -31.867268526367198],
            [-51.735367916992189, -31.863268526367197],
            [-51.728067916992188, -31.859468526367195],
            [-51.722067916992188, -31.856368526367199],
            [-51.718967916992185, -31.854768526367195],
            [-51.715967916992184, -31.853168526367195],
            [-51.712267916992189, -31.851368526367196],
            [-51.707867916992186, -31.849168526367198],
            [-51.70286791699219, -31.846668526367196],
            [-51.69956791699218, -31.844868526367197],
            [-51.695467916992186, -31.843068526367198],
            [-51.691267916992189, -31.840868526367196],
            [-51.686967916992188, -31.838768526367197],
            [-51.682667916992187, -31.836568526367195],
            [-51.677967916992188, -31.834368526367196],
            [-51.674367916992182, -31.832868526367196],
            [-51.67076791699219, -31.831068526367197],
            [-51.66746791699218, -31.829568526367197],
            [-51.663367916992186, -31.827468526367198],
            [-51.659467916992185, -31.825768526367195],
            [-51.655467916992187, -31.824268526367195],
            [-51.651467916992189, -31.822368526367196],
            [-51.648667916992181, -31.820968526367196],
            [-51.638267916992184, -31.815968526367197],
            [-51.624467916992188, -31.809468526367198],
            [-51.612267916992181, -31.803268526367198],
            [-51.606367916992184, -31.799968526367195],
            [-51.600367916992184, -31.796768526367195],
            [-51.591167916992184, -31.791368526367197],
            [-51.585167916992184, -31.788168526367198],
            [-51.577667916992183, -31.783868526367197],
            [-51.571367916992187, -31.780368526367198],
            [-51.560167916992185, -31.774068526367195],
            [-51.550867916992189, -31.769268526367195],
            [-51.543367916992182, -31.765168526367198],
            [-51.532367916992186, -31.759268526367197],
            [-51.502367916992185, -31.742768526367197],
            [-51.497667916992185, -31.739968526367196],
            [-51.493067916992189, -31.737468526367195],
            [-51.489367916992187, -31.735268526367197],
            [-51.483667916992189, -31.731768526367198],
            [-51.478867916992186, -31.728868526367197],
            [-51.475667916992187, -31.726768526367195],
            [-51.471467916992182, -31.724168526367198],
            [-51.465667916992189, -31.720568526367195],
            [-51.462067916992183, -31.718168526367197],
            [-51.458667916992184, -31.715968526367195],
            [-51.452067916992185, -31.711868526367198],
            [-51.447667916992188, -31.709068526367197],
            [-51.442967916992188, -31.705768526367194],
            [-51.438067916992182, -31.702668526367198],
            [-51.432267916992188, -31.698968526367196],
            [-51.42716791699219, -31.695568526367197],
            [-51.423167916992185, -31.692668526367196],
            [-51.418567916992188, -31.689168526367197],
            [-51.412867916992184, -31.685468526367195],
            [-51.409267916992185, -31.682668526367195],
            [-51.404567916992185, -31.679368526367195],
            [-51.401267916992182, -31.677168526367197],
            [-51.396567916992183, -31.673668526367194],
            [-51.392667916992181, -31.670568526367198],
            [-51.386567916992185, -31.666168526367194],
            [-51.38136791699219, -31.662468526367196],
            [-51.375467916992186, -31.657868526367196],
            [-51.369067916992186, -31.653368526367196],
            [-51.363567916992181, -31.649468526367198],
            [-51.358367916992187, -31.645968526367195],
            [-51.353067916992188, -31.641768526367194],
            [-51.350367916992184, -31.639868526367195],
            [-51.34456791699219, -31.636068526367197],
            [-51.339267916992185, -31.631768526367196],
            [-51.335467916992187, -31.628768526367196],
            [-51.330767916992187, -31.625268526367197],
            [-51.325267916992189, -31.621568526367195],
            [-51.31986791699218, -31.617668526367197],
            [-51.314367916992182, -31.613268526367197],
            [-51.310567916992184, -31.610368526367196],
            [-51.306967916992186, -31.608068526367198],
            [-51.303267916992183, -31.605268526367198],
            [-51.298867916992187, -31.602068526367198],
            [-51.293867916992184, -31.598668526367195],
            [-51.289267916992188, -31.595068526367196],
            [-51.283967916992182, -31.591168526367198],
            [-51.280867916992186, -31.588668526367197],
            [-51.276167916992186, -31.585068526367195],
            [-51.272067916992185, -31.581868526367195],
            [-51.267467916992189, -31.578568526367196],
            [-51.26206791699218, -31.574268526367195],
            [-51.255967916992184, -31.568868526367197],
            [-51.249367916992185, -31.563868526367195],
            [-51.243867916992187, -31.559368526367194],
            [-51.239167916992187, -31.555168526367197],
            [-51.23196791699219, -31.548968526367197],
            [-51.226367916992189, -31.543968526367195],
            [-51.220867916992184, -31.539468526367195],
            [-51.214467916992184, -31.533368526367198],
            [-51.20726791699218, -31.526468526367196],
            [-51.202367916992188, -31.521568526367197],
            [-51.19686791699219, -31.515668526367197],
            [-51.18916791699219, -31.507668526367198],
            [-51.18586791699218, -31.504368526367195],
            [-51.183067916992186, -31.501368526367195],
            [-51.177567916992189, -31.495868526367197],
            [-51.17246791699219, -31.490468526367195],
            [-51.166667916992182, -31.484668526367194],
            [-51.162267916992185, -31.480268526367198],
            [-51.152567916992183, -31.470668526367195],
            [-51.143267916992187, -31.461168526367196],
            [-51.133767916992184, -31.451468526367197],
            [-51.122367916992189, -31.440268526367198],
            [-51.113667916992185, -31.431568526367197],
            [-51.107767916992188, -31.425268526367198],
            [-51.10396791699219, -31.421368526367196],
            [-51.101367916992189, -31.418868526367199],
            [-51.098067916992186, -31.415668526367195],
            [-51.095867916992184, -31.413468526367197],
            [-51.093067916992183, -31.410668526367196],
            [-51.088467916992187, -31.406168526367196],
            [-51.085967916992189, -31.403768526367195],
            [-51.083267916992185, -31.401268526367197],
            [-51.080167916992181, -31.398168526367197],
            [-51.077367916992188, -31.395368526367196],
            [-51.072767916992184, -31.391068526367196],
            [-51.068267916992184, -31.386768526367195],
            [-51.065267916992184, -31.383868526367195],
            [-51.062467916992183, -31.381068526367194],
            [-51.058767916992181, -31.377668526367195],
            [-51.055767916992181, -31.374768526367198],
            [-51.051367916992184, -31.370468526367198],
            [-51.047567916992186, -31.367168526367195],
            [-51.044267916992183, -31.364368526367198],
            [-51.041667916992182, -31.362168526367196],
            [-51.038967916992185, -31.358968526367196],
            [-51.034467916992185, -31.355868526367196],
            [-51.031167916992189, -31.353068526367196],
            [-51.027867916992186, -31.350268526367195],
            [-51.025067916992185, -31.348068526367197],
            [-51.021267916992187, -31.344868526367197],
            [-51.017867916992188, -31.342068526367196],
            [-51.013967916992186, -31.338968526367196],
            [-51.010767916992187, -31.336168526367196],
            [-51.006767916992189, -31.333168526367196],
            [-51.002467916992188, -31.329968526367196],
            [-50.997167916992183, -31.325968526367198],
            [-50.991567916992182, -31.321368526367195],
            [-50.986867916992182, -31.317768526367196],
            [-50.980667916992189, -31.313068526367196],
            [-50.97596791699219, -31.309168526367195],
            [-50.970367916992188, -31.304768526367198],
            [-50.965367916992186, -31.300468526367197],
            [-50.961267916992185, -31.296868526367195],
            [-50.957867916992186, -31.294168526367194],
            [-50.954267916992187, -31.291468526367197],
            [-50.950667916992188, -31.288168526367198],
            [-50.944967916992184, -31.283468526367194],
            [-50.941267916992189, -31.280368526367198],
            [-50.937467916992183, -31.277268526367195],
            [-50.934867916992189, -31.275068526367196],
            [-50.929467916992181, -31.270768526367196],
            [-50.923967916992183, -31.266368526367195],
            [-50.918867916992184, -31.261768526367195],
            [-50.91476791699219, -31.258268526367196],
            [-50.909467916992185, -31.253768526367196],
            [-50.906467916992185, -31.251268526367198],
            [-50.902267916992187, -31.247768526367196],
            [-50.89906791699218, -31.244968526367195],
            [-50.894267916992185, -31.240368526367195],
            [-50.888967916992186, -31.235568526367196],
            [-50.884167916992183, -31.231368526367199],
            [-50.879067916992184, -31.226968526367195],
            [-50.874067916992189, -31.221968526367196],
            [-50.869767916992188, -31.217968526367194],
            [-50.865367916992184, -31.213268526367195],
            [-50.861767916992186, -31.209868526367195],
            [-50.857067916992186, -31.205368526367195],
            [-50.853067916992188, -31.201468526367197],
            [-50.848867916992184, -31.197068526367197],
            [-50.84596791699218, -31.194168526367196],
            [-50.841967916992189, -31.189868526367196],
            [-50.837067916992183, -31.185268526367196],
            [-50.833667916992184, -31.182168526367196],
            [-50.830367916992188, -31.178568526367197],
            [-50.826767916992182, -31.174768526367195],
            [-50.822767916992184, -31.170268526367195],
            [-50.82016791699219, -31.167168526367195],
            [-50.815467916992183, -31.161968526367197],
            [-50.810767916992184, -31.156768526367195],
            [-50.807467916992181, -31.153768526367195],
            [-50.804367916992184, -31.150468526367195],
            [-50.800567916992186, -31.146468526367197],
            [-50.797567916992186, -31.143168526367198],
            [-50.794967916992185, -31.140368526367197],
            [-50.791667916992182, -31.136768526367195],
            [-50.788867916992189, -31.133868526367195],
            [-50.785567916992186, -31.130468526367196],
            [-50.782767916992185, -31.127468526367196],
            [-50.779267916992183, -31.123868526367197],
            [-50.776267916992182, -31.120468526367198],
            [-50.773167916992186, -31.116968526367195],
            [-50.770467916992189, -31.113668526367196],
            [-50.767167916992186, -31.110068526367197],
            [-50.763567916992187, -31.105268526367198],
            [-50.759967916992181, -31.100368526367198],
            [-50.757167916992188, -31.096668526367196],
            [-50.753767916992189, -31.091968526367197],
            [-50.750967916992181, -31.087868526367195],
            [-50.747167916992183, -31.082168526367198],
            [-50.744667916992185, -31.078268526367197],
            [-50.742467916992183, -31.075368526367196],
            [-50.740367916992184, -31.072068526367197],
            [-50.738267916992186, -31.068568526367194],
            [-50.735767916992188, -31.064468526367197],
            [-50.733067916992184, -31.060468526367195],
            [-50.729267916992185, -31.054768526367198],
            [-50.72726791699219, -31.051668526367195],
            [-50.724967916992185, -31.047868526367196],
            [-50.722667916992187, -31.044268526367198],
            [-50.720367916992188, -31.040568526367196],
            [-50.718667916992189, -31.037768526367195],
            [-50.716667916992186, -31.034868526367198],
            [-50.714367916992188, -31.031168526367196],
            [-50.712067916992183, -31.027568526367197],
            [-50.709567916992185, -31.023668526367196],
            [-50.706767916992185, -31.019368526367195],
            [-50.703567916992185, -31.014668526367196],
            [-50.700667916992188, -31.010368526367195],
            [-50.697467916992181, -31.005768526367198],
            [-50.69486791699218, -31.001968526367197],
            [-50.69216791699219, -30.998068526367195],
            [-50.688767916992184, -30.992668526367197],
            [-50.685467916992181, -30.988268526367197],
            [-50.682167916992185, -30.983568526367197],
            [-50.677467916992185, -30.976468526367196],
            [-50.674967916992188, -30.973068526367197],
            [-50.67246791699219, -30.969468526367194],
            [-50.670367916992184, -30.966668526367197],
            [-50.66776791699219, -30.962868526367195],
            [-50.664967916992182, -30.958968526367197],
            [-50.663167916992187, -30.956568526367196],
            [-50.66006791699219, -30.952668526367198],
            [-50.658367916992184, -30.949968526367197],
            [-50.656567916992188, -30.947368526367196],
            [-50.654767916992185, -30.944868526367195],
            [-50.651467916992189, -30.940468526367198],
            [-50.64906791699218, -30.937368526367194],
            [-50.646767916992189, -30.934068526367195],
            [-50.64506791699219, -30.931568526367197],
            [-50.643267916992187, -30.929068526367196],
            [-50.64036791699219, -30.925068526367198],
            [-50.637967916992181, -30.922468526367197],
            [-50.63536791699218, -30.918668526367195],
            [-50.63236791699218, -30.914768526367197],
            [-50.630267916992182, -30.911968526367197],
            [-50.628067916992187, -30.908968526367197],
            [-50.625267916992186, -30.905168526367198],
            [-50.622667916992185, -30.901768526367196],
            [-50.620867916992182, -30.899268526367194],
            [-50.618267916992181, -30.895968526367195],
            [-50.615267916992181, -30.891868526367197],
            [-50.612267916992181, -30.888168526367195],
            [-50.60996791699219, -30.884968526367196],
            [-50.607567916992181, -30.882068526367195],
            [-50.60526791699219, -30.879068526367195],
            [-50.60196791699218, -30.874368526367196],
            [-50.59896791699218, -30.870268526367198],
            [-50.594767916992183, -30.865368526367195],
            [-50.592067916992185, -30.862168526367196],
            [-50.589067916992185, -30.858168526367194],
            [-50.586467916992184, -30.854668526367195],
            [-50.582967916992189, -30.850368526367198],
            [-50.580067916992185, -30.846368526367197],
            [-50.577367916992188, -30.842668526367198],
            [-50.574367916992188, -30.838968526367196],
            [-50.57186791699219, -30.835968526367196],
            [-50.569067916992182, -30.832568526367197],
            [-50.565967916992186, -30.828268526367197],
            [-50.563767916992184, -30.825268526367196],
            [-50.560767916992184, -30.821268526367199],
            [-50.558267916992186, -30.818168526367195],
            [-50.555767916992181, -30.814368526367197],
            [-50.551967916992183, -30.809168526367195],
            [-50.548967916992183, -30.804968526367198],
            [-50.54716791699218, -30.802568526367196],
            [-50.543967916992187, -30.797768526367197],
            [-50.541767916992185, -30.794668526367197],
            [-50.539567916992183, -30.791168526367194],
            [-50.536967916992182, -30.787268526367196],
            [-50.534167916992189, -30.783168526367199],
            [-50.530867916992186, -30.778768526367195],
            [-50.528367916992181, -30.775168526367196],
            [-50.525867916992183, -30.771268526367194],
            [-50.522167916992188, -30.765968526367196],
            [-50.52006791699219, -30.762468526367197],
            [-50.517167916992186, -30.758268526367196],
            [-50.514567916992185, -30.754668526367198],
            [-50.511767916992184, -30.750968526367195],
            [-50.509867916992185, -30.747968526367195],
            [-50.506667916992185, -30.743568526367199],
            [-50.50166791699219, -30.736068526367198],
            [-50.495767916992186, -30.726668526367199],
            [-50.492167916992187, -30.721168526367194],
            [-50.489067916992184, -30.716668526367194],
            [-50.486367916992187, -30.712368526367197],
            [-50.483567916992186, -30.707568526367197],
            [-50.48026791699219, -30.702868526367197],
            [-50.477767916992185, -30.698968526367196],
            [-50.474567916992186, -30.694868526367195],
            [-50.47226791699218, -30.691668526367195],
            [-50.470267916992185, -30.689068526367194],
            [-50.467567916992181, -30.684868526367197],
            [-50.464867916992183, -30.680768526367196],
            [-50.46156791699218, -30.676168526367196],
            [-50.459367916992186, -30.673268526367195],
            [-50.456267916992189, -30.669168526367194],
            [-50.453567916992185, -30.665068526367197],
            [-50.450967916992184, -30.661768526367197],
            [-50.447767916992184, -30.657568526367196],
            [-50.428667916992183, -30.632068526367195],
            [-50.397967916992187, -30.593668526367196],
            [-50.395767916992185, -30.590668526367196],
            [-50.392367916992185, -30.586868526367198],
            [-50.389367916992185, -30.582968526367196],
            [-50.38736791699219, -30.580368526367195],
            [-50.383267916992182, -30.574868526367197],
            [-50.380667916992181, -30.571768526367194],
            [-50.376867916992182, -30.566668526367195],
            [-50.374067916992189, -30.563068526367196],
            [-50.370567916992186, -30.557968526367198],
            [-50.367267916992184, -30.553068526367198],
            [-50.364767916992186, -30.549668526367196],
            [-50.361967916992185, -30.545368526367199],
            [-50.359367916992184, -30.541668526367197],
            [-50.356767916992183, -30.537768526367195],
            [-50.353667916992187, -30.532668526367196],
            [-50.350867916992186, -30.528468526367199],
            [-50.348167916992182, -30.524568526367197],
            [-50.345067916992186, -30.519368526367195],
            [-50.342867916992184, -30.515368526367197],
            [-50.340067916992183, -30.511068526367197],
            [-50.337967916992184, -30.507668526367198],
            [-50.334667916992188, -30.501568526367194],
            [-50.331467916992182, -30.496368526367196],
            [-50.329367916992183, -30.492468526367198],
            [-50.326467916992186, -30.487268526367195],
            [-50.322467916992181, -30.480268526367198],
            [-50.320667916992186, -30.476768526367195],
            [-50.318767916992186, -30.473068526367197],
            [-50.317367916992183, -30.469768526367197],
            [-50.314867916992185, -30.464868526367198],
            [-50.312667916992183, -30.460368526367198],
            [-50.311267916992186, -30.457368526367198],
            [-50.30916791699218, -30.453568526367196],
            [-50.307667916992187, -30.450368526367196],
            [-50.306067916992184, -30.447068526367197],
            [-50.304067916992182, -30.442368526367197],
            [-50.301867916992187, -30.436668526367196],
            [-50.299967916992188, -30.432268526367196],
            [-50.29746791699219, -30.425768526367197],
            [-50.295667916992187, -30.421368526367196],
            [-50.29416791699218, -30.417168526367199],
            [-50.292167916992184, -30.412468526367196],
            [-50.290867916992184, -30.408768526367197],
            [-50.28946791699218, -30.405168526367198],
            [-50.287067916992186, -30.398968526367199],
            [-50.28506791699219, -30.392868526367195],
            [-50.283167916992184, -30.387668526367197],
            [-50.281967916992187, -30.384168526367198],
            [-50.280867916992186, -30.380468526367196],
            [-50.279167916992186, -30.375968526367195],
            [-50.277867916992186, -30.371868526367194],
            [-50.276467916992189, -30.367768526367197],
            [-50.27406791699218, -30.361968526367196],
            [-50.272667916992184, -30.358168526367194],
            [-50.271267916992187, -30.354568526367196],
            [-50.269667916992184, -30.350668526367194],
            [-50.267467916992189, -30.344468526367194],
            [-50.265667916992186, -30.339768526367195],
            [-50.26406791699219, -30.335468526367194],
            [-50.26236791699219, -30.331768526367195],
            [-50.261267916992182, -30.328968526367198],
            [-50.259867916992185, -30.325368526367196],
            [-50.258067916992189, -30.320968526367196],
            [-50.256267916992186, -30.316368526367196],
            [-50.254867916992183, -30.312768526367197],
            [-50.253467916992186, -30.308668526367196],
            [-50.251867916992182, -30.303668526367197],
            [-50.249167916992185, -30.298568526367198],
            [-50.247967916992181, -30.295268526367195],
            [-50.245467916992183, -30.289468526367195],
            [-50.242567916992186, -30.283068526367195],
            [-50.241067916992186, -30.278668526367198],
            [-50.239467916992183, -30.274868526367197],
            [-50.237867916992187, -30.271068526367195],
            [-50.236867916992182, -30.268268526367194],
            [-50.23496791699219, -30.263468526367198],
            [-50.23166791699218, -30.255168526367196],
            [-50.229467916992185, -30.249768526367198],
            [-50.227367916992186, -30.244368526367197],
            [-50.225267916992181, -30.238968526367195],
            [-50.224567916992186, -30.236068526367198],
            [-50.22256791699219, -30.232768526367195],
            [-50.220867916992184, -30.228068526367196],
            [-50.219067916992188, -30.222768526367197],
            [-50.217567916992181, -30.218768526367196],
            [-50.215967916992184, -30.215368526367197],
            [-50.214867916992183, -30.212568526367196],
            [-50.213767916992182, -30.209568526367196],
            [-50.211267916992185, -30.202968526367197],
            [-50.209267916992189, -30.197668526367195],
            [-50.207367916992183, -30.192768526367196],
            [-50.205367916992188, -30.187668526367197],
            [-50.203267916992189, -30.182468526367195],
            [-50.202067916992185, -30.179368526367199],
            [-50.199967916992186, -30.174168526367197],
            [-50.198767916992182, -30.170568526367198],
            [-50.196367916992187, -30.164468526367195],
            [-50.194667916992188, -30.160068526367198],
            [-50.19186791699218, -30.152968526367196],
            [-50.189667916992185, -30.147568526367195],
            [-50.187967916992186, -30.141868526367197],
            [-50.184867916992189, -30.134068526367194],
            [-50.182167916992185, -30.126668526367197],
            [-50.179967916992183, -30.121868526367194],
            [-50.178567916992186, -30.117968526367196],
            [-50.177467916992185, -30.114968526367196],
            [-50.174667916992185, -30.107568526367196],
            [-50.17246791699219, -30.102768526367196],
            [-50.171067916992186, -30.096668526367196],
            [-50.168367916992182, -30.092668526367198],
            [-50.166067916992183, -30.086768526367194],
            [-50.164267916992188, -30.081468526367196],
            [-50.160967916992185, -30.074268526367199],
            [-50.158167916992184, -30.067368526367197],
            [-50.154767916992185, -30.059368526367194],
            [-50.152367916992183, -30.054068526367196],
            [-50.150167916992189, -30.049268526367197],
            [-50.148667916992181, -30.045568526367198],
            [-50.146167916992184, -30.039968526367197],
            [-50.143567916992183, -30.034468526367196],
            [-50.141467916992184, -30.029568526367196],
            [-50.139367916992185, -30.024868526367197],
            [-50.136767916992184, -30.019368526367195],
            [-50.134867916992185, -30.015068526367195],
            [-50.132767916992186, -30.011068526367197],
            [-50.130967916992184, -30.006568526367197],
            [-50.12966791699219, -30.003868526367196],
            [-50.128467916992186, -30.000468526367197],
            [-50.126567916992187, -29.995868526367197],
            [-50.124467916992188, -29.991068526367194],
            [-50.122767916992188, -29.986668526367197],
            [-50.121567916992184, -29.983868526367196],
            [-50.119167916992183, -29.976968526367195],
            [-50.116867916992184, -29.973368526367196],
            [-50.115767916992183, -29.970668526367199],
            [-50.113567916992181, -29.964868526367198],
            [-50.111067916992184, -29.959268526367197],
            [-50.10996791699219, -29.956568526367196],
            [-50.108367916992187, -29.953468526367196],
            [-50.106367916992184, -29.949268526367199],
            [-50.104267916992185, -29.945568526367197],
            [-50.102767916992185, -29.943068526367199],
            [-50.100667916992187, -29.938368526367196],
            [-50.099467916992182, -29.935268526367196],
            [-50.097667916992187, -29.931568526367194],
            [-50.095567916992181, -29.926868526367194],
            [-50.093467916992182, -29.922768526367197],
            [-50.09156791699219, -29.918868526367199],
            [-50.089467916992184, -29.914468526367195],
            [-50.086967916992187, -29.909168526367196],
            [-50.085367916992183, -29.905468526367194],
            [-50.083667916992184, -29.901568526367196],
            [-50.080667916992184, -29.895768526367196],
            [-50.078967916992184, -29.891768526367194],
            [-50.075967916992184, -29.885668526367198],
            [-50.073967916992189, -29.881768526367196],
            [-50.07156791699218, -29.876668526367197],
            [-50.069667916992188, -29.872768526367196],
            [-50.066267916992189, -29.865568526367198],
            [-50.063367916992185, -29.858968526367196],
            [-50.06086791699218, -29.854768526367195],
            [-50.059067916992184, -29.850668526367194],
            [-50.057567916992184, -29.847768526367197],
            [-50.05616791699218, -29.845168526367196],
            [-50.054167916992185, -29.841168526367198],
            [-50.052767916992181, -29.838268526367195],
            [-50.051067916992181, -29.835068526367195],
            [-50.048267916992188, -29.830368526367195],
            [-50.044667916992182, -29.823868526367196],
            [-50.04276791699219, -29.820168526367198],
            [-50.040567916992188, -29.816568526367199],
            [-50.038667916992182, -29.812668526367197],
            [-50.036667916992187, -29.808668526367196],
            [-50.034567916992188, -29.805268526367197],
            [-50.032767916992185, -29.801468526367195],
            [-50.030867916992186, -29.798168526367196],
            [-50.028367916992181, -29.793168526367197],
            [-50.026467916992189, -29.789568526367198],
            [-50.024267916992187, -29.785568526367197],
            [-50.022667916992184, -29.782668526367196],
            [-50.020767916992185, -29.779268526367197],
            [-50.019267916992185, -29.775868526367198],
            [-50.017667916992181, -29.772268526367196],
            [-50.016067916992185, -29.769768526367194],
            [-50.014267916992182, -29.766068526367196],
            [-50.012467916992186, -29.762868526367196],
            [-50.010967916992186, -29.759968526367196],
            [-50.008767916992184, -29.756268526367194],
            [-50.007067916992185, -29.752668526367195],
            [-50.005167916992185, -29.749368526367196],
            [-50.003467916992186, -29.745768526367197],
            [-50.000567916992182, -29.740468526367195],
            [-49.99836791699218, -29.736468526367197],
            [-49.995867916992182, -29.731968526367197],
            [-49.99366791699218, -29.728168526367199],
            [-49.991167916992183, -29.723368526367196],
            [-49.988567916992181, -29.718668526367196],
            [-49.985667916992185, -29.713668526367197],
            [-49.98326791699219, -29.709268526367197],
            [-49.981367916992184, -29.706068526367197],
            [-49.979467916992185, -29.702668526367198],
            [-49.977767916992185, -29.699668526367198],
            [-49.976367916992189, -29.697068526367197],
            [-49.974567916992186, -29.694368526367196],
            [-49.97226791699218, -29.690468526367198],
            [-49.969767916992183, -29.685868526367194],
            [-49.968067916992183, -29.682668526367195],
            [-49.965867916992181, -29.678968526367196],
            [-49.963767916992182, -29.675868526367196],
            [-49.962067916992183, -29.672568526367197],
            [-49.959867916992181, -29.668168526367197],
            [-49.95726791699218, -29.664268526367195],
            [-49.955967916992186, -29.661468526367194],
            [-49.95286791699219, -29.656568526367195],
            [-49.950367916992185, -29.652568526367197],
            [-49.948467916992186, -29.649468526367194],
            [-49.946667916992183, -29.646768526367197],
            [-49.944667916992188, -29.643268526367194],
            [-49.943467916992184, -29.640668526367197],
            [-49.94186791699218, -29.638168526367195],
            [-49.939967916992188, -29.635268526367199],
            [-49.938067916992182, -29.632468526367198],
            [-49.93586791699218, -29.629068526367199],
            [-49.93446791699219, -29.624668526367195],
            [-49.931567916992186, -29.621568526367199],
            [-49.929367916992184, -29.618368526367199],
            [-49.926567916992184, -29.613868526367199],
            [-49.924467916992185, -29.610768526367195],
            [-49.922767916992186, -29.607568526367196],
            [-49.920267916992188, -29.603968526367197],
            [-49.917867916992186, -29.600568526367198],
            [-49.915667916992184, -29.597368526367198],
            [-49.913067916992183, -29.593468526367197],
            [-49.910967916992185, -29.590068526367197],
            [-49.908367916992184, -29.586468526367199],
            [-49.905367916992184, -29.581868526367195],
            [-49.902967916992182, -29.578568526367196],
            [-49.900167916992189, -29.574968526367197],
            [-49.897867916992183, -29.571668526367198],
            [-49.895367916992186, -29.567968526367196],
            [-49.892867916992188, -29.564168526367197],
            [-49.890667916992186, -29.561268526367197],
            [-49.888867916992183, -29.558768526367196],
            [-49.886567916992185, -29.555868526367199],
            [-49.883867916992187, -29.552168526367197],
            [-49.88136791699219, -29.548668526367194],
            [-49.878067916992187, -29.544268526367198],
            [-49.875567916992182, -29.540568526367196],
            [-49.872667916992185, -29.536468526367194],
            [-49.870867916992182, -29.534168526367196],
            [-49.868367916992185, -29.530368526367198],
            [-49.865567916992184, -29.526468526367196],
            [-49.863567916992181, -29.523668526367196],
            [-49.861667916992189, -29.521468526367194],
            [-49.859267916992181, -29.517868526367195],
            [-49.85666791699218, -29.514268526367196],
            [-49.853467916992187, -29.509968526367196],
            [-49.851667916992184, -29.507368526367195],
            [-49.849467916992182, -29.504368526367195],
            [-49.84726791699218, -29.501368526367195],
            [-49.844767916992183, -29.497968526367195],
            [-49.840867916992181, -29.492568526367194],
            [-49.837967916992184, -29.488868526367199],
            [-49.835967916992189, -29.485868526367199],
            [-49.833967916992187, -29.483268526367198],
            [-49.831867916992181, -29.480368526367194],
            [-49.829867916992185, -29.477768526367196],
            [-49.828167916992186, -29.475268526367195],
            [-49.825767916992184, -29.472568526367198],
            [-49.82316791699219, -29.469268526367195],
            [-49.820567916992189, -29.465568526367196],
            [-49.818467916992184, -29.462868526367195],
            [-49.815467916992183, -29.459268526367197],
            [-49.813067916992182, -29.455968526367194],
            [-49.811067916992187, -29.453268526367197],
            [-49.809067916992184, -29.450768526367199],
            [-49.806867916992189, -29.447968526367198],
            [-49.804067916992182, -29.444068526367197],
            [-49.799967916992188, -29.439068526367194],
            [-49.798067916992181, -29.436868526367196],
            [-49.796067916992186, -29.434368526367194],
            [-49.794267916992183, -29.431668526367197],
            [-49.792067916992181, -29.429068526367196],
            [-49.788867916992189, -29.424968526367195],
            [-49.78676791699219, -29.422268526367198],
            [-49.784167916992189, -29.418868526367199],
            [-49.781967916992187, -29.416068526367198],
            [-49.779567916992185, -29.413168526367194],
            [-49.776567916992185, -29.409468526367196],
            [-49.773667916992181, -29.405668526367194],
            [-49.770667916992181, -29.401868526367195],
            [-49.767667916992181, -29.398168526367197],
            [-49.765667916992186, -29.395768526367196],
            [-49.763167916992188, -29.392468526367196],
            [-49.761067916992189, -29.390068526367195],
            [-49.758067916992189, -29.386468526367196],
            [-49.755167916992185, -29.382668526367198],
            [-49.752367916992185, -29.379168526367195],
            [-49.74966791699218, -29.375868526367196],
            [-49.74696791699219, -29.372668526367196],
            [-49.744767916992188, -29.369768526367196],
            [-49.742467916992183, -29.366968526367195],
            [-49.740067916992189, -29.363968526367195],
            [-49.737567916992184, -29.361068526367198],
            [-49.73496791699219, -29.358668526367197],
            [-49.731067916992188, -29.356668526367194],
            [-49.730067916992184, -29.352468526367197],
            [-49.72996791699218, -29.349568526367197],
            [-49.728067916992188, -29.347268526367195],
            [-49.725267916992181, -29.343968526367199],
            [-49.72226791699218, -29.340168526367197],
            [-49.720267916992185, -29.337568526367196],
            [-49.717367916992188, -29.334068526367197],
            [-49.715067916992183, -29.331468526367196],
            [-49.71326791699218, -29.327968526367197],
            [-49.712567916992185, -29.323968526367196],
            [-49.71156791699218, -29.320568526367197],
            [-49.710367916992183, -29.317068526367194],
            [-49.707967916992189, -29.314068526367194],
            [-49.705767916992187, -29.311268526367197],
            [-49.70286791699219, -29.307268526367196],
            [-49.700167916992186, -29.304168526367196],
            [-49.696767916992187, -29.299968526367195],
            [-49.694167916992185, -29.296668526367196],
            [-49.691267916992189, -29.293068526367197],
            [-49.687767916992186, -29.288768526367196],
            [-49.685167916992185, -29.285568526367197],
            [-49.682667916992187, -29.282368526367197],
            [-49.680467916992185, -29.279868526367196],
            [-49.678667916992183, -29.277668526367197],
            [-49.676167916992185, -29.274768526367197],
            [-49.672767916992186, -29.270668526367196],
            [-49.669267916992183, -29.266468526367198],
            [-49.666767916992185, -29.263568526367195],
            [-49.66476791699219, -29.261068526367197],
            [-49.662767916992181, -29.258868526367195],
            [-49.660667916992182, -29.256368526367197],
            [-49.657667916992182, -29.252968526367198],
            [-49.654467916992189, -29.249368526367196],
            [-49.652267916992187, -29.246268526367196],
            [-49.649867916992186, -29.243668526367195],
            [-49.647567916992188, -29.240868526367194],
            [-49.644367916992181, -29.237268526367195],
            [-49.641567916992187, -29.234468526367195],
            [-49.638567916992187, -29.231068526367196],
            [-49.636467916992189, -29.228168526367199],
            [-49.634167916992183, -29.225668526367194],
            [-49.631767916992189, -29.223068526367197],
            [-49.628267916992186, -29.218768526367196],
            [-49.625967916992181, -29.215868526367196],
            [-49.62336791699218, -29.212568526367196],
            [-49.619967916992181, -29.208868526367198],
            [-49.616967916992181, -29.205368526367195],
            [-49.61466791699219, -29.203168526367197],
            [-49.612567916992184, -29.200668526367195],
            [-49.610567916992181, -29.198568526367197],
            [-49.607867916992184, -29.195268526367194],
            [-49.60496791699218, -29.191668526367195],
            [-49.602767916992185, -29.189368526367197],
            [-49.600667916992187, -29.187168526367195],
            [-49.598667916992184, -29.184868526367197],
            [-49.595867916992184, -29.181168526367195],
            [-49.592567916992181, -29.177568526367196],
            [-49.590067916992183, -29.175068526367198],
            [-49.587267916992189, -29.171968526367195],
            [-49.584367916992186, -29.169168526367194],
            [-49.581567916992185, -29.165668526367199],
            [-49.578967916992184, -29.163068526367198],
            [-49.576767916992182, -29.160668526367196],
            [-49.573567916992189, -29.157768526367196],
            [-49.57016791699219, -29.154168526367194],
            [-49.567767916992182, -29.151268526367197],
            [-49.564667916992185, -29.147568526367195],
            [-49.561567916992189, -29.144068526367196],
            [-49.557967916992183, -29.140468526367197],
            [-49.555267916992186, -29.137468526367197],
            [-49.552567916992189, -29.135168526367195],
            [-49.55046791699219, -29.132768526367194],
            [-49.547867916992189, -29.130168526367196],
            [-49.545267916992188, -29.127468526367196],
            [-49.54276791699219, -29.124768526367198],
            [-49.540567916992188, -29.122768526367196],
            [-49.538067916992183, -29.119968526367195],
            [-49.53476791699218, -29.116368526367197],
            [-49.530367916992184, -29.111468526367197],
            [-49.527367916992183, -29.107768526367195],
            [-49.525067916992185, -29.104768526367195],
            [-49.522167916992188, -29.101968526367195],
            [-49.519267916992185, -29.098968526367194],
            [-49.516067916992185, -29.095568526367195],
            [-49.513567916992187, -29.093068526367198],
            [-49.51036791699218, -29.089768526367195],
            [-49.50636791699219, -29.085368526367198],
            [-49.503767916992189, -29.082568526367197],
            [-49.501567916992187, -29.079868526367196],
            [-49.499067916992189, -29.077168526367196],
            [-49.496267916992181, -29.074368526367195],
            [-49.494067916992186, -29.072068526367197],
            [-49.491167916992183, -29.069268526367196],
            [-49.488667916992185, -29.066668526367195],
            [-49.486367916992187, -29.064168526367197],
            [-49.483067916992184, -29.060468526367195],
            [-49.480567916992186, -29.057268526367196],
            [-49.478467916992187, -29.055168526367197],
            [-49.476467916992185, -29.052968526367195],
            [-49.473767916992188, -29.050068526367198],
            [-49.470867916992184, -29.046868526367199],
            [-49.468367916992186, -29.044468526367197],
            [-49.46626791699218, -29.042468526367195],
            [-49.463367916992183, -29.039568526367198],
            [-49.460367916992183, -29.036668526367194],
            [-49.458167916992181, -29.034268526367196],
            [-49.455967916992186, -29.032068526367198],
            [-49.453167916992186, -29.029468526367197],
            [-49.450467916992181, -29.026468526367196],
            [-49.44816791699219, -29.023668526367196],
            [-49.445267916992186, -29.020768526367196],
            [-49.44186791699218, -29.017868526367195],
            [-49.43886791699218, -29.014368526367196],
            [-49.435167916992185, -29.010768526367194],
            [-49.431867916992189, -29.007668526367198],
            [-49.428567916992186, -29.004168526367195],
            [-49.424267916992186, -29.000468526367197],
            [-49.421867916992184, -28.998268526367198],
            [-49.41916791699218, -28.995768526367197],
            [-49.416167916992187, -28.992768526367197],
            [-49.413367916992186, -28.990068526367196],
            [-49.410267916992183, -28.987168526367196],
            [-49.407367916992186, -28.984368526367199],
            [-49.405067916992181, -28.982068526367197],
            [-49.401767916992185, -28.979168526367197],
            [-49.398667916992181, -28.975968526367197],
            [-49.395367916992186, -28.973068526367197],
            [-49.391767916992187, -28.969768526367197],
            [-49.388467916992184, -28.966968526367197],
            [-49.386067916992189, -28.964768526367195],
            [-49.383867916992187, -28.962668526367196],
            [-49.381667916992185, -28.960868526367197],
            [-49.378767916992189, -28.957968526367196],
            [-49.375767916992189, -28.955468526367198],
            [-49.372667916992185, -28.952668526367198],
            [-49.369767916992188, -28.949968526367197],
            [-49.367567916992186, -28.947968526367198],
            [-49.364967916992185, -28.945768526367196],
            [-49.362167916992185, -28.942968526367196],
            [-49.359467916992187, -28.940568526367194],
            [-49.355367916992186, -28.936968526367195],
            [-49.351167916992182, -28.933568526367196],
            [-49.34726791699218, -28.930168526367197],
            [-49.343467916992182, -28.927168526367197],
            [-49.340367916992186, -28.924268526367197],
            [-49.336967916992187, -28.921468526367196],
            [-49.334267916992189, -28.919168526367194],
            [-49.331167916992186, -28.916668526367197],
            [-49.327467916992184, -28.913468526367197],
            [-49.324967916992186, -28.911368526367198],
            [-49.322467916992181, -28.909168526367196],
            [-49.32016791699219, -28.907268526367197],
            [-49.316667916992188, -28.903668526367198],
            [-49.314667916992185, -28.900968526367194],
            [-49.311867916992185, -28.899768526367197],
            [-49.308567916992189, -28.897268526367196],
            [-49.305767916992181, -28.894268526367195],
            [-49.301967916992183, -28.892668526367196],
            [-49.298867916992187, -28.890968526367196],
            [-49.296367916992182, -28.889368526367196],
            [-49.294267916992183, -28.887168526367198],
            [-49.291967916992185, -28.884968526367196],
            [-49.289567916992183, -28.882668526367198],
            [-49.286267916992188, -28.879668526367198],
            [-49.283667916992187, -28.877368526367196],
            [-49.280067916992181, -28.874668526367195],
            [-49.276467916992189, -28.871668526367195],
            [-49.27406791699218, -28.869768526367196],
            [-49.270167916992186, -28.866668526367196],
            [-49.266467916992184, -28.863868526367199],
            [-49.263267916992184, -28.861068526367198],
            [-49.259167916992183, -28.857468526367196],
            [-49.25636791699219, -28.855068526367198],
            [-49.253867916992185, -28.853168526367199],
            [-49.251067916992184, -28.850668526367194],
            [-49.24836791699218, -28.848468526367196],
            [-49.245467916992183, -28.846268526367197],
            [-49.242267916992184, -28.843768526367196],
            [-49.239167916992187, -28.841268526367195],
            [-49.236367916992187, -28.839368526367195],
            [-49.233167916992187, -28.836768526367194],
            [-49.229567916992181, -28.834268526367197],
            [-49.226667916992184, -28.832168526367198],
            [-49.224467916992182, -28.830368526367195],
            [-49.220867916992184, -28.827868526367197],
            [-49.217667916992184, -28.825668526367195],
            [-49.215167916992186, -28.823868526367196],
            [-49.21186791699219, -28.821368526367195],
            [-49.209267916992189, -28.819268526367196],
            [-49.205467916992184, -28.816268526367196],
            [-49.200967916992184, -28.813068526367196],
            [-49.197467916992181, -28.810568526367199],
            [-49.19486791699218, -28.808368526367197],
            [-49.190967916992186, -28.805868526367199],
            [-49.187767916992186, -28.802468526367196],
            [-49.184367916992187, -28.800868526367196],
            [-49.181367916992187, -28.798968526367197],
            [-49.178367916992187, -28.796968526367195],
            [-49.175867916992189, -28.795068526367196],
            [-49.173067916992181, -28.792768526367198],
            [-49.170267916992188, -28.791368526367194],
            [-49.167567916992184, -28.789468526367195],
            [-49.16446791699218, -28.787368526367196],
            [-49.161367916992184, -28.785268526367197],
            [-49.157867916992188, -28.783068526367195],
            [-49.153467916992184, -28.780068526367195],
            [-49.14906791699218, -28.777268526367195],
            [-49.146867916992186, -28.775368526367195],
            [-49.144267916992185, -28.774068526367195],
            [-49.140967916992182, -28.771868526367196],
            [-49.137467916992186, -28.769568526367195],
            [-49.134167916992183, -28.767368526367196],
            [-49.13136791699219, -28.765668526367197],
            [-49.128467916992186, -28.763568526367195],
            [-49.125567916992182, -28.761868526367195],
            [-49.122767916992188, -28.759968526367196],
            [-49.119167916992183, -28.757768526367194],
            [-49.114967916992185, -28.755268526367196],
            [-49.111467916992183, -28.753068526367194],
            [-49.108867916992182, -28.751368526367195],
            [-49.105567916992186, -28.749368526367196],
            [-49.099467916992182, -28.745468526367198],
            [-49.095167916992182, -28.742668526367197],
            [-49.09156791699219, -28.740368526367199],
            [-49.088467916992187, -28.738568526367196],
            [-49.085467916992187, -28.736768526367197],
            [-49.082167916992184, -28.734668526367194],
            [-49.078567916992185, -28.732468526367196],
            [-49.076067916992187, -28.730668526367197],
            [-49.073267916992187, -28.729268526367196],
            [-49.069567916992185, -28.727268526367197],
            [-49.065767916992186, -28.724868526367196],
            [-49.062767916992186, -28.723068526367197],
            [-49.058867916992185, -28.721168526367194],
            [-49.055067916992186, -28.718968526367199],
            [-49.050367916992187, -28.716168526367198],
            [-49.04746791699219, -28.714068526367196],
            [-49.044967916992185, -28.712668526367196],
            [-49.042567916992184, -28.710968526367196],
            [-49.039167916992184, -28.709368526367196],
            [-49.035367916992186, -28.707268526367194],
            [-49.032367916992186, -28.705668526367198],
            [-49.028967916992187, -28.703268526367197],
            [-49.025467916992184, -28.701068526367195],
            [-49.022667916992184, -28.699368526367195],
            [-49.018567916992183, -28.697068526367197],
            [-49.015967916992182, -28.695268526367194],
            [-49.011767916992184, -28.693568526367194],
            [-49.007067916992185, -28.690968526367197],
            [-49.002967916992183, -28.688768526367198],
            [-48.999467916992188, -28.686568526367196],
            [-48.995267916992184, -28.684068526367199],
            [-48.991567916992182, -28.681868526367197],
            [-48.987867916992187, -28.679668526367195],
            [-48.98466791699218, -28.677468526367196],
            [-48.980567916992186, -28.675068526367198],
            [-48.97696791699218, -28.672868526367196],
            [-48.973167916992182, -28.670768526367198],
            [-48.96926791699218, -28.668668526367199],
            [-48.964767916992187, -28.666368526367194],
            [-48.960167916992184, -28.664268526367195],
            [-48.95726791699218, -28.662768526367195],
            [-48.953767916992184, -28.660868526367196],
            [-48.950667916992188, -28.659168526367196],
            [-48.94656791699218, -28.656968526367194],
            [-48.942767916992182, -28.655068526367195],
            [-48.940167916992181, -28.653668526367198],
            [-48.937367916992187, -28.652368526367198],
            [-48.93446791699219, -28.650868526367198],
            [-48.931967916992186, -28.649468526367194],
            [-48.929167916992185, -28.647968526367194],
            [-48.925567916992186, -28.646268526367194],
            [-48.922867916992189, -28.644868526367198],
            [-48.919567916992186, -28.643168526367198],
            [-48.916067916992183, -28.641568526367195],
            [-48.912867916992184, -28.639668526367196],
            [-48.909567916992188, -28.638468526367198],
            [-48.906267916992185, -28.636768526367199],
            [-48.902267916992187, -28.634868526367196],
            [-48.897167916992188, -28.632368526367195],
            [-48.89206791699219, -28.629968526367197],
            [-48.88706791699218, -28.627668526367195],
            [-48.884267916992187, -28.626568526367194],
            [-48.88136791699219, -28.625268526367197],
            [-48.878467916992186, -28.624068526367196],
            [-48.874467916992188, -28.622168526367197],
            [-48.870467916992183, -28.620468526367198],
            [-48.866867916992184, -28.618668526367195],
            [-48.863067916992186, -28.617168526367195],
            [-48.859167916992185, -28.615868526367194],
            [-48.855667916992189, -28.614668526367197],
            [-48.852767916992185, -28.613368526367196],
            [-48.84896791699218, -28.611968526367196],
            [-48.845567916992181, -28.610868526367199],
            [-48.842667916992184, -28.610068526367197],
            [-48.839467916992184, -28.610268526367197],
            [-48.83656791699218, -28.611068526367198],
            [-48.833167916992181, -28.608368526367194],
            [-48.829367916992183, -28.607068526367197],
            [-48.825767916992184, -28.606468526367195],
            [-48.822467916992181, -28.607068526367197],
            [-48.819267916992189, -28.608568526367197],
            [-48.815167916992181, -28.606368526367199],
            [-48.812367916992187, -28.603368526367198],
            [-48.816067916992182, -28.601968526367195],
            [-48.817367916992183, -28.597868526367197],
            [-48.816067916992182, -28.594768526367197],
            [-48.816967916992184, -28.591668526367194],
            [-48.815467916992183, -28.588668526367197],
            [-48.813267916992189, -28.585468526367194],
            [-48.811267916992186, -28.583168526367196],
            [-48.808867916992185, -28.580668526367198],
            [-48.805767916992181, -28.577168526367196],
            [-48.802967916992188, -28.574568526367194],
            [-48.800067916992184, -28.572368526367196],
            [-48.797767916992186, -28.570468526367197],
            [-48.795367916992184, -28.568468526367198],
            [-48.792567916992184, -28.567168526367198],
            [-48.78846791699219, -28.567968526367196],
            [-48.785567916992186, -28.565468526367198],
            [-48.788167916992187, -28.563268526367196],
            [-48.788967916992185, -28.559168526367195],
            [-48.787567916992188, -28.555868526367199],
            [-48.785867916992188, -28.553268526367198],
            [-48.78376791699219, -28.550468526367197],
            [-48.781467916992185, -28.547868526367196],
            [-48.77876791699218, -28.545568526367198],
            [-48.775367916992181, -28.542268526367195],
            [-48.771867916992186, -28.539568526367198],
            [-48.768467916992186, -28.537768526367195],
            [-48.764567916992185, -28.536268526367195],
            [-48.761767916992184, -28.534468526367196],
            [-48.761867916992188, -28.531268526367196],
            [-48.760467916992184, -28.526868526367195],
            [-48.76236791699219, -28.523468526367196],
            [-48.761267916992182, -28.520668526367196],
            [-48.761067916992189, -28.517368526367196],
            [-48.75906791699218, -28.514368526367196],
            [-48.756267916992186, -28.511268526367196],
            [-48.753467916992186, -28.509868526367196],
            [-48.751867916992182, -28.506768526367196],
            [-48.748067916992184, -28.506268526367194],
            [-48.747367916992189, -28.502668526367195],
            [-48.747267916992186, -28.499068526367196],
            [-48.745867916992182, -28.496268526367196],
            [-48.749767916992184, -28.494368526367197],
            [-48.754167916992188, -28.494368526367197],
            [-48.758567916992185, -28.494768526367196],
            [-48.762467916992186, -28.492268526367198],
            [-48.763967916992186, -28.489168526367195],
            [-48.76536791699219, -28.485368526367196],
            [-48.765967916992182, -28.481768526367198],
            [-48.766067916992185, -28.478668526367194],
            [-48.766267916992184, -28.474568526367197],
            [-48.766067916992185, -28.469868526367197],
            [-48.765967916992182, -28.465868526367196],
            [-48.765667916992186, -28.462868526367195],
            [-48.764667916992181, -28.458968526367194],
            [-48.763167916992188, -28.454268526367194],
            [-48.761467916992189, -28.450668526367195],
            [-48.759667916992186, -28.446868526367197],
            [-48.757167916992188, -28.442668526367196],
            [-48.755667916992181, -28.440168526367195],
            [-48.753767916992189, -28.437268526367198],
            [-48.751567916992187, -28.434168526367195],
            [-48.749167916992185, -28.430868526367199],
            [-48.746567916992184, -28.427568526367196],
            [-48.744167916992183, -28.425768526367197],
            [-48.741167916992183, -28.425068526367198],
            [-48.739067916992184, -28.423168526367196],
            [-48.742567916992186, -28.421068526367197],
            [-48.746567916992184, -28.416968526367196],
            [-48.746567916992184, -28.412468526367196],
            [-48.746267916992181, -28.408468526367194],
            [-48.745767916992186, -28.405068526367195],
            [-48.745067916992184, -28.401268526367197],
            [-48.744367916992189, -28.398368526367197],
            [-48.743267916992181, -28.395068526367197],
            [-48.741967916992181, -28.391268526367199],
            [-48.740067916992189, -28.387168526367198],
            [-48.738667916992185, -28.383868526367195],
            [-48.736767916992186, -28.380268526367196],
            [-48.734267916992181, -28.375968526367195],
            [-48.732767916992188, -28.373368526367194],
            [-48.730567916992186, -28.370168526367195],
            [-48.728667916992187, -28.367168526367195],
            [-48.726367916992189, -28.364068526367198],
            [-48.723467916992185, -28.360268526367197],
            [-48.72096791699218, -28.356668526367194],
            [-48.718767916992185, -28.353868526367194],
            [-48.71626791699218, -28.350968526367197],
            [-48.712867916992181, -28.346968526367196],
            [-48.710067916992188, -28.343968526367199],
            [-48.706467916992182, -28.341768526367197],
            [-48.703167916992186, -28.342068526367196],
            [-48.702167916992181, -28.338468526367194],
            [-48.705767916992187, -28.336768526367194],
            [-48.708167916992181, -28.333268526367196],
            [-48.709267916992189, -28.328268526367197],
            [-48.708967916992187, -28.323468526367197],
            [-48.708167916992181, -28.319568526367195],
            [-48.707167916992184, -28.316268526367196],
            [-48.706167916992186, -28.313468526367195],
            [-48.704667916992186, -28.309368526367194],
            [-48.703267916992189, -28.305768526367196],
            [-48.701767916992182, -28.301468526367195],
            [-48.700267916992189, -28.297868526367196],
            [-48.698567916992189, -28.294468526367197],
            [-48.696367916992187, -28.289868526367197],
            [-48.694967916992184, -28.286968526367197],
            [-48.693167916992181, -28.283668526367194],
            [-48.691067916992182, -28.279868526367196],
            [-48.689067916992187, -28.276468526367196],
            [-48.686367916992182, -28.272068526367196],
            [-48.684367916992187, -28.268968526367196],
            [-48.682467916992181, -28.266068526367196],
            [-48.680067916992186, -28.262768526367196],
            [-48.677567916992189, -28.259568526367197],
            [-48.675567916992186, -28.256768526367196],
            [-48.673067916992181, -28.254068526367199],
            [-48.669567916992186, -28.250468526367197],
            [-48.665567916992188, -28.246968526367198],
            [-48.660867916992188, -28.244668526367196],
            [-48.658667916992187, -28.242668526367197],
            [-48.656467916992185, -28.240368526367199],
            [-48.651967916992184, -28.238268526367197],
            [-48.648367916992186, -28.235868526367199],
            [-48.645767916992185, -28.233668526367197],
            [-48.646867916992186, -28.230568526367197],
            [-48.650067916992185, -28.228668526367194],
            [-48.651767916992185, -28.231668526367194],
            [-48.655067916992181, -28.230268526367198],
            [-48.657767916992185, -28.228868526367194],
            [-48.660867916992188, -28.226968526367195],
            [-48.662867916992184, -28.223768526367195],
            [-48.665367916992182, -28.219768526367197],
            [-48.666667916992182, -28.216968526367197],
            [-48.666767916992185, -28.213268526367195],
            [-48.663367916992186, -28.209368526367196],
            [-48.665667916992184, -28.205968526367194],
            [-48.663067916992183, -28.202868526367197],
            [-48.660667916992182, -28.199568526367194],
            [-48.65846791699218, -28.197368526367196],
            [-48.657367916992186, -28.193868526367197],
            [-48.660967916992185, -28.192368526367197],
            [-48.662867916992184, -28.188768526367198],
            [-48.662867916992184, -28.183568526367196],
            [-48.662067916992186, -28.179368526367199],
            [-48.66146791699218, -28.176468526367195],
            [-48.660567916992186, -28.173268526367195],
            [-48.659167916992189, -28.169968526367196],
            [-48.658067916992181, -28.167068526367196],
            [-48.656567916992188, -28.164168526367199],
            [-48.655067916992181, -28.160968526367196],
            [-48.653067916992185, -28.157168526367194],
            [-48.650367916992181, -28.153368526367196],
            [-48.647067916992185, -28.150068526367196],
            [-48.646267916992187, -28.145768526367196],
            [-48.644067916992185, -28.143668526367197],
            [-48.639567916992185, -28.142468526367196],
            [-48.637467916992186, -28.138968526367197],
            [-48.641067916992185, -28.137068526367194],
            [-48.642467916992189, -28.133468526367196],
            [-48.643267916992187, -28.129168526367195],
            [-48.640467916992186, -28.126068526367199],
            [-48.639267916992182, -28.123368526367194],
            [-48.636467916992189, -28.121968526367198],
            [-48.633467916992188, -28.122768526367196],
            [-48.63106791699218, -28.119668526367196],
            [-48.634567916992182, -28.117968526367196],
            [-48.63566791699219, -28.114468526367197],
            [-48.634567916992182, -28.111768526367197],
            [-48.631867916992185, -28.108968526367196],
            [-48.63566791699219, -28.107568526367196],
            [-48.636767916992184, -28.104668526367199],
            [-48.633767916992184, -28.100868526367197],
            [-48.632967916992186, -28.096668526367196],
            [-48.632067916992185, -28.093368526367197],
            [-48.630967916992184, -28.090068526367197],
            [-48.631667916992185, -28.085368526367198],
            [-48.629067916992184, -28.082568526367197],
            [-48.628067916992187, -28.078468526367196],
            [-48.625167916992183, -28.074868526367197],
            [-48.621167916992185, -28.074868526367197],
            [-48.619067916992186, -28.072968526367198],
            [-48.616867916992184, -28.070468526367197],
            [-48.615067916992189, -28.065868526367197],
            [-48.610367916992189, -28.062968526367197],
            [-48.608367916992187, -28.059168526367195],
            [-48.607167916992182, -28.054968526367198],
            [-48.60666791699218, -28.051568526367198],
            [-48.607067916992186, -28.047568526367197],
            [-48.60966791699218, -28.043368526367196],
            [-48.607867916992184, -28.038468526367197],
            [-48.605667916992189, -28.035368526367197],
            [-48.602467916992182, -28.033768526367197],
            [-48.59896791699218, -28.033468526367194],
            [-48.598467916992185, -28.030068526367195],
            [-48.60096791699219, -28.028368526367196],
            [-48.60226791699219, -28.025068526367196],
            [-48.604267916992185, -28.020468526367196],
            [-48.607867916992184, -28.017568526367196],
            [-48.611067916992184, -28.019368526367195],
            [-48.615067916992189, -28.022668526367198],
            [-48.619667916992185, -28.022068526367196],
            [-48.623267916992184, -28.019668526367198],
            [-48.62636791699218, -28.016068526367196],
            [-48.628867916992185, -28.011268526367196],
            [-48.630267916992182, -28.007768526367194],
            [-48.631267916992186, -28.004368526367195],
            [-48.631767916992189, -28.001068526367199],
            [-48.632067916992185, -27.997368526367197],
            [-48.631867916992185, -27.992268526367198],
            [-48.631267916992186, -27.988968526367195],
            [-48.629867916992183, -27.984968526367197],
            [-48.629067916992184, -27.982068526367197],
            [-48.629067916992184, -27.979168526367197],
            [-48.627967916992183, -27.974568526367197],
            [-48.625167916992183, -27.971968526367196],
            [-48.62336791699218, -27.968768526367196],
            [-48.621267916992181, -27.965068526367197],
            [-48.620867916992182, -27.961768526367194],
            [-48.623267916992184, -27.960168526367198],
            [-48.625467916992186, -27.958268526367196],
            [-48.624967916992183, -27.953968526367198],
            [-48.624067916992189, -27.951068526367195],
            [-48.623067916992184, -27.947368526367196],
            [-48.620867916992182, -27.944968526367198],
            [-48.619167916992183, -27.941668526367195],
            [-48.617467916992183, -27.938768526367198],
            [-48.615267916992181, -27.935568526367199],
            [-48.613367916992189, -27.932968526367198],
            [-48.610667916992185, -27.929368526367199],
            [-48.607867916992184, -27.925768526367197],
            [-48.605667916992189, -27.923068526367196],
            [-48.603667916992187, -27.920768526367198],
            [-48.601667916992184, -27.918468526367196],
            [-48.599467916992182, -27.916168526367194],
            [-48.59596791699218, -27.912768526367195],
            [-48.591967916992189, -27.909168526367196],
            [-48.590067916992183, -27.905868526367197],
            [-48.586267916992185, -27.904468526367197],
            [-48.582867916992186, -27.905568526367198],
            [-48.581467916992182, -27.902268526367195],
            [-48.581767916992185, -27.899068526367195],
            [-48.580067916992185, -27.895768526367196],
            [-48.577467916992184, -27.893968526367196],
            [-48.575167916992186, -27.891468526367198],
            [-48.573567916992189, -27.886768526367199],
            [-48.575267916992189, -27.883468526367196],
            [-48.575967916992184, -27.879068526367199],
            [-48.578567916992185, -27.882368526367195],
            [-48.581767916992185, -27.883068526367197],
            [-48.585067916992188, -27.881668526367196],
            [-48.58826791699218, -27.882368526367195],
            [-48.590167916992186, -27.884868526367196],
            [-48.594467916992187, -27.883768526367199],
            [-48.597367916992184, -27.880768526367198],
            [-48.599167916992187, -27.878468526367197],
            [-48.600567916992183, -27.875168526367197],
            [-48.601667916992184, -27.870868526367197],
            [-48.60196791699218, -27.866968526367195],
            [-48.601767916992188, -27.863268526367197],
            [-48.600867916992186, -27.858968526367196],
            [-48.599567916992186, -27.855968526367196],
            [-48.598067916992186, -27.853368526367198],
            [-48.595867916992184, -27.850968526367197],
            [-48.592967916992187, -27.849468526367197],
            [-48.588667916992186, -27.848068526367197],
            [-48.583967916992187, -27.847768526367197],
            [-48.580767916992187, -27.848968526367194],
            [-48.578767916992184, -27.851368526367196],
            [-48.576767916992182, -27.847668526367194],
            [-48.576067916992187, -27.843668526367196],
            [-48.573267916992187, -27.842068526367196],
            [-48.569267916992189, -27.835868526367197],
            [-48.565967916992186, -27.834268526367197],
            [-48.56086791699218, -27.835368526367198],
            [-48.55946791699219, -27.838268526367195],
            [-48.555867916992185, -27.835968526367196],
            [-48.552467916992185, -27.833968526367194],
            [-48.549367916992182, -27.832368526367198],
            [-48.545667916992187, -27.830168526367196],
            [-48.542567916992184, -27.827068526367196],
            [-48.541667916992182, -27.824068526367196],
            [-48.540867916992184, -27.820968526367196],
            [-48.540067916992186, -27.816968526367198],
            [-48.538367916992186, -27.812968526367197],
            [-48.53676791699219, -27.810468526367195],
            [-48.535867916992188, -27.807768526367198],
            [-48.536167916992184, -27.803668526367197],
            [-48.533967916992182, -27.800068526367198],
            [-48.534567916992188, -27.797168526367194],
            [-48.53206791699219, -27.792468526367195],
            [-48.528667916992184, -27.788168526367194],
            [-48.525467916992184, -27.785368526367197],
            [-48.522567916992188, -27.783468526367194],
            [-48.519567916992187, -27.782068526367198],
            [-48.515667916992186, -27.781268526367196],
            [-48.511467916992189, -27.781268526367196],
            [-48.507767916992186, -27.783068526367195],
            [-48.50766791699219, -27.786768526367197],
            [-48.50736791699218, -27.790068526367197],
            [-48.504067916992184, -27.793068526367197],
            [-48.499467916992188, -27.793968526367195],
            [-48.495067916992184, -27.794268526367198],
            [-48.491867916992184, -27.791968526367196],
            [-48.489367916992187, -27.790568526367196],
            [-48.486467916992183, -27.788968526367196],
            [-48.485267916992186, -27.786168526367199],
            [-48.483867916992182, -27.782768526367196],
            [-48.487167916992185, -27.778768526367195],
            [-48.486867916992182, -27.774768526367197],
            [-48.48466791699218, -27.771468526367194],
            [-48.480567916992186, -27.769868526367198],
            [-48.47696791699218, -27.770068526367197],
            [-48.478267916992181, -27.763068526367196],
            [-48.482067916992186, -27.761068526367197],
            [-48.485267916992186, -27.759568526367197],
            [-48.487867916992187, -27.755968526367198],
            [-48.492167916992187, -27.756368526367197],
            [-48.494967916992181, -27.757468526367198],
            [-48.497767916992188, -27.755768526367198],
            [-48.49966791699218, -27.752668526367195],
            [-48.499467916992188, -27.749468526367195],
            [-48.503467916992186, -27.749468526367195],
            [-48.506267916992186, -27.747368526367197],
            [-48.50736791699218, -27.744668526367196],
            [-48.50766791699219, -27.741368526367197],
            [-48.507767916992186, -27.737468526367195],
            [-48.507467916992184, -27.733368526367194],
            [-48.506667916992185, -27.728668526367194],
            [-48.505267916992182, -27.724568526367197],
            [-48.503067916992187, -27.719568526367198],
            [-48.502167916992185, -27.715068526367197],
            [-48.500867916992185, -27.712268526367197],
            [-48.499167916992185, -27.709268526367197],
            [-48.497467916992186, -27.706468526367196],
            [-48.495267916992184, -27.703568526367196],
            [-48.492967916992185, -27.700668526367195],
            [-48.489967916992185, -27.698168526367198],
            [-48.487467916992188, -27.696068526367196],
            [-48.483867916992182, -27.694368526367196],
            [-48.48166791699218, -27.691268526367196],
            [-48.48026791699219, -27.686868526367196],
            [-48.479467916992185, -27.683268526367197],
            [-48.479167916992182, -27.678968526367196],
            [-48.478367916992184, -27.675568526367197],
            [-48.47726791699219, -27.671668526367196],
            [-48.47596791699219, -27.668068526367197],
            [-48.474567916992186, -27.664568526367198],
            [-48.47256791699219, -27.660568526367197],
            [-48.470867916992184, -27.657268526367197],
            [-48.468767916992185, -27.654268526367197],
            [-48.466467916992187, -27.650168526367196],
            [-48.464367916992188, -27.647168526367196],
            [-48.46156791699218, -27.643268526367194],
            [-48.458967916992187, -27.640068526367195],
            [-48.456867916992181, -27.637468526367197],
            [-48.45456791699219, -27.634568526367197],
            [-48.451367916992183, -27.631268526367194],
            [-48.447767916992184, -27.629468526367198],
            [-48.44516791699219, -27.626268526367198],
            [-48.442767916992182, -27.622468526367197],
            [-48.43916791699219, -27.620068526367199],
            [-48.437967916992186, -27.617268526367198],
            [-48.433067916992186, -27.614468526367197],
            [-48.435567916992184, -27.610268526367197],
            [-48.434967916992186, -27.606368526367199],
            [-48.432967916992183, -27.602868526367196],
            [-48.430067916992186, -27.599568526367197],
            [-48.428367916992187, -27.595868526367198],
            [-48.425767916992186, -27.592868526367198],
            [-48.42246791699219, -27.591568526367197],
            [-48.419667916992182, -27.592268526367196],
            [-48.418367916992182, -27.588668526367197],
            [-48.41916791699218, -27.584368526367196],
            [-48.416767916992185, -27.580168526367196],
            [-48.41446791699218, -27.577668526367198],
            [-48.412067916992186, -27.575368526367196],
            [-48.412767916992181, -27.572468526367196],
            [-48.416167916992187, -27.572068526367197],
            [-48.418867916992184, -27.573168526367198],
            [-48.422267916992183, -27.573468526367197],
            [-48.425867916992189, -27.572368526367196],
            [-48.427867916992184, -27.568768526367194],
            [-48.428967916992185, -27.565168526367195],
            [-48.429367916992184, -27.561068526367194],
            [-48.429167916992185, -27.555468526367196],
            [-48.428067916992184, -27.549968526367195],
            [-48.426167916992185, -27.543868526367199],
            [-48.424167916992189, -27.538768526367196],
            [-48.42246791699219, -27.534768526367195],
            [-48.420867916992187, -27.531568526367195],
            [-48.41946791699219, -27.528668526367198],
            [-48.417867916992186, -27.525968526367194],
            [-48.415867916992184, -27.522268526367196],
            [-48.413167916992187, -27.517668526367196],
            [-48.409467916992185, -27.511768526367199],
            [-48.407367916992186, -27.509168526367198],
            [-48.405367916992184, -27.505968526367198],
            [-48.403067916992185, -27.502968526367198],
            [-48.400067916992185, -27.499368526367196],
            [-48.396767916992189, -27.495468526367198],
            [-48.394267916992185, -27.492468526367198],
            [-48.391267916992184, -27.489368526367194],
            [-48.38736791699219, -27.485768526367195],
            [-48.384667916992186, -27.483868526367196],
            [-48.38236791699218, -27.481768526367198],
            [-48.379167916992188, -27.481368526367199],
            [-48.375867916992185, -27.479568526367196],
            [-48.374467916992188, -27.474768526367196],
            [-48.374367916992185, -27.470568526367195],
            [-48.375867916992185, -27.467268526367196],
            [-48.376567916992187, -27.462968526367199],
            [-48.375167916992183, -27.458968526367194],
            [-48.373567916992187, -27.455468526367198],
            [-48.371267916992181, -27.451068526367195],
            [-48.368267916992181, -27.449268526367199],
            [-48.363867916992184, -27.448168526367198],
            [-48.360667916992185, -27.445168526367198],
            [-48.359267916992181, -27.440468526367198],
            [-48.361367916992187, -27.437968526367197],
            [-48.363567916992181, -27.435468526367195],
            [-48.365367916992184, -27.433268526367197],
            [-48.367267916992184, -27.435468526367195],
            [-48.368867916992187, -27.438868526367195],
            [-48.370067916992184, -27.442368526367197],
            [-48.372967916992181, -27.443868526367197],
            [-48.375967916992181, -27.443568526367194],
            [-48.379167916992188, -27.442668526367196],
            [-48.382967916992186, -27.441268526367196],
            [-48.38706791699218, -27.438768526367198],
            [-48.389867916992188, -27.436568526367196],
            [-48.392867916992188, -27.433668526367196],
            [-48.396167916992184, -27.429768526367198],
            [-48.398667916992181, -27.426168526367196],
            [-48.400667916992184, -27.422768526367197],
            [-48.402267916992187, -27.418868526367199],
            [-48.403667916992184, -27.415568526367196],
            [-48.403167916992189, -27.412568526367195],
            [-48.403167916992189, -27.409468526367196],
            [-48.405467916992187, -27.406268526367196],
            [-48.410567916992186, -27.405168526367198],
            [-48.412767916992181, -27.402268526367195],
            [-48.41346791699219, -27.398968526367199],
            [-48.414167916992184, -27.395468526367196],
            [-48.413167916992187, -27.391768526367194],
            [-48.411967916992182, -27.387968526367196],
            [-48.410967916992185, -27.384868526367196],
            [-48.41346791699219, -27.382768526367194],
            [-48.415967916992187, -27.380968526367198],
            [-48.483867916992182, -27.378568526367197],
            [-48.50636791699219, -27.393968526367196],
            [-48.516467916992184, -27.399768526367197],
            [-48.519867916992183, -27.402568526367197],
            [-48.526167916992186, -27.408168526367199],
            [-48.532767916992185, -27.388268526367199],
            [-48.532367916992186, -27.382068526367195],
            [-48.528167916992189, -27.377968526367198],
            [-48.531167916992189, -27.374168526367196],
            [-48.532267916992183, -27.371268526367196],
            [-48.53346791699218, -27.367168526367195],
            [-48.535367916992186, -27.363968526367195],
            [-48.536167916992184, -27.361068526367198],
            [-48.536367916992184, -27.357868526367199],
            [-48.533667916992187, -27.354168526367197],
            [-48.534467916992185, -27.349768526367196],
            [-48.531567916992188, -27.347868526367197],
            [-48.527367916992183, -27.344868526367197],
            [-48.526967916992184, -27.340068526367197],
            [-48.526167916992186, -27.336168526367196],
            [-48.525167916992189, -27.333268526367196],
            [-48.529467916992189, -27.334568526367196],
            [-48.533167916992184, -27.333168526367196],
            [-48.535267916992183, -27.330368526367195],
            [-48.537767916992181, -27.325968526367198],
            [-48.538967916992185, -27.322868526367195],
            [-48.53976791699219, -27.318468526367198],
            [-48.539567916992183, -27.312968526367197],
            [-48.539167916992184, -27.309068526367199],
            [-48.53846791699219, -27.305868526367199],
            [-48.540267916992185, -27.302968526367195],
            [-48.53976791699219, -27.299968526367195],
            [-48.543067916992186, -27.298868526367194],
            [-48.544967916992185, -27.302268526367197],
            [-48.546967916992187, -27.305468526367196],
            [-48.550067916992184, -27.305268526367197],
            [-48.552567916992189, -27.306968526367196],
            [-48.552767916992181, -27.310768526367198],
            [-48.554167916992185, -27.314168526367197],
            [-48.558367916992182, -27.314468526367197],
            [-48.561867916992185, -27.316068526367197],
            [-48.56416791699219, -27.312968526367197],
            [-48.564867916992185, -27.309468526367198],
            [-48.568067916992185, -27.309868526367197],
            [-48.569967916992184, -27.312268526367198],
            [-48.57316791699219, -27.314868526367196],
            [-48.576267916992187, -27.314868526367196],
            [-48.579267916992187, -27.312968526367197],
            [-48.581867916992181, -27.314868526367196],
            [-48.58556791699219, -27.319168526367196],
            [-48.590067916992183, -27.318468526367198],
            [-48.593367916992186, -27.316268526367196],
            [-48.596667916992189, -27.311668526367196],
            [-48.600367916992184, -27.306068526367199],
            [-48.603167916992184, -27.301368526367199],
            [-48.605567916992186, -27.296468526367196],
            [-48.607767916992188, -27.291768526367196],
            [-48.609167916992185, -27.288368526367197],
            [-48.610767916992188, -27.284868526367198],
            [-48.612167916992185, -27.282268526367197],
            [-48.613367916992189, -27.278168526367196],
            [-48.614967916992185, -27.274068526367195],
            [-48.615367916992184, -27.270068526367197],
            [-48.615467916992188, -27.266068526367196],
            [-48.615367916992184, -27.262368526367197],
            [-48.615467916992188, -27.257168526367195],
            [-48.615067916992189, -27.253768526367196],
            [-48.614367916992187, -27.250168526367197],
            [-48.614367916992187, -27.245868526367197],
            [-48.613967916992181, -27.241068526367194],
            [-48.613267916992186, -27.235668526367196],
            [-48.612167916992185, -27.231668526367194],
            [-48.610367916992189, -27.227568526367197],
            [-48.608867916992182, -27.223368526367196],
            [-48.60666791699218, -27.219468526367194],
            [-48.602867916992182, -27.217268526367196],
            [-48.601367916992189, -27.219868526367197],
            [-48.598367916992188, -27.219068526367195],
            [-48.595667916992184, -27.217668526367198],
            [-48.592867916992184, -27.215868526367196],
            [-48.588967916992189, -27.214068526367196],
            [-48.585467916992187, -27.211968526367194],
            [-48.58256791699219, -27.209768526367196],
            [-48.578167916992186, -27.210368526367198],
            [-48.575467916992181, -27.206868526367195],
            [-48.571767916992187, -27.203268526367197],
            [-48.56856791699218, -27.201468526367197],
            [-48.56416791699219, -27.199568526367194],
            [-48.55916791699218, -27.197168526367197],
            [-48.554667916992187, -27.195968526367196],
            [-48.55216791699219, -27.191968526367198],
            [-48.548067916992181, -27.187468526367198],
            [-48.544967916992185, -27.185168526367196],
            [-48.54246791699218, -27.182968526367198],
            [-48.539267916992188, -27.181568526367194],
            [-48.536367916992184, -27.180868526367199],
            [-48.532667916992182, -27.180468526367196],
            [-48.528367916992181, -27.180468526367196],
            [-48.524267916992187, -27.180768526367196],
            [-48.520167916992186, -27.181968526367196],
            [-48.515667916992186, -27.183568526367196],
            [-48.512467916992186, -27.185468526367195],
            [-48.509167916992183, -27.187968526367197],
            [-48.50636791699219, -27.190268526367198],
            [-48.504167916992188, -27.192668526367196],
            [-48.501867916992182, -27.195268526367194],
            [-48.499967916992183, -27.198168526367198],
            [-48.499767916992184, -27.202668526367198],
            [-48.501567916992187, -27.206468526367196],
            [-48.503767916992189, -27.208268526367196],
            [-48.506267916992186, -27.210368526367198],
            [-48.50906791699218, -27.211868526367198],
            [-48.512167916992183, -27.213968526367196],
            [-48.514967916992184, -27.215968526367199],
            [-48.51506791699218, -27.218968526367199],
            [-48.51236791699219, -27.219868526367197],
            [-48.511067916992189, -27.216768526367197],
            [-48.508067916992189, -27.216568526367197],
            [-48.504067916992184, -27.216268526367195],
            [-48.501267916992184, -27.214768526367195],
            [-48.49836791699218, -27.215968526367199],
            [-48.495267916992184, -27.215968526367199],
            [-48.493367916992185, -27.212968526367199],
            [-48.489967916992185, -27.211268526367196],
            [-48.486767916992186, -27.209368526367196],
            [-48.483567916992186, -27.209668526367196],
            [-48.480367916992186, -27.208168526367196],
            [-48.477467916992182, -27.206068526367197],
            [-48.476367916992189, -27.203268526367197],
            [-48.47696791699218, -27.199868526367197],
            [-48.482067916992186, -27.198968526367196],
            [-48.485367916992189, -27.200468526367196],
            [-48.488267916992186, -27.202568526367195],
            [-48.491667916992185, -27.201068526367195],
            [-48.494667916992185, -27.198868526367196],
            [-48.496267916992181, -27.196368526367195],
            [-48.497667916992185, -27.193468526367198],
            [-48.498867916992182, -27.189168526367197],
            [-48.499467916992188, -27.184968526367197],
            [-48.499767916992184, -27.181868526367197],
            [-48.49966791699218, -27.177568526367196],
            [-48.499067916992189, -27.173968526367197],
            [-48.49836791699218, -27.171068526367197],
            [-48.497367916992189, -27.167868526367194],
            [-48.495567916992186, -27.165268526367196],
            [-48.492267916992184, -27.164168526367199],
            [-48.488367916992189, -27.163768526367196],
            [-48.48496791699219, -27.162068526367197],
            [-48.484767916992183, -27.157368526367197],
            [-48.482767916992188, -27.154068526367197],
            [-48.478667916992187, -27.153968526367194],
            [-48.474167916992187, -27.151968526367199],
            [-48.471967916992185, -27.148368526367197],
            [-48.468367916992186, -27.145068526367197],
            [-48.473067916992186, -27.145368526367196],
            [-48.476467916992185, -27.144668526367198],
            [-48.476667916992184, -27.140768526367197],
            [-48.479767916992188, -27.144668526367198],
            [-48.483667916992189, -27.147868526367198],
            [-48.487467916992188, -27.148468526367196],
            [-48.490567916992184, -27.147968526367194],
            [-48.494767916992188, -27.146268526367194],
            [-48.499067916992189, -27.145668526367196],
            [-48.501967916992186, -27.145068526367197],
            [-48.505167916992185, -27.142868526367195],
            [-48.507967916992186, -27.140068526367195],
            [-48.510967916992186, -27.136568526367196],
            [-48.513267916992184, -27.132068526367195],
            [-48.51236791699219, -27.129068526367199],
            [-48.51236791699219, -27.125568526367196],
            [-48.511367916992185, -27.121168526367196],
            [-48.50766791699219, -27.118568526367199],
            [-48.50606791699218, -27.114168526367195],
            [-48.508167916992186, -27.111468526367197],
            [-48.509967916992181, -27.115068526367196],
            [-48.512467916992186, -27.118068526367196],
            [-48.517167916992186, -27.120768526367197],
            [-48.521467916992187, -27.118868526367194],
            [-48.524767916992189, -27.122368526367197],
            [-48.527667916992186, -27.122968526367195],
            [-48.529467916992189, -27.126268526367198],
            [-48.525367916992181, -27.126368526367195],
            [-48.526767916992185, -27.130268526367196],
            [-48.530067916992181, -27.133468526367196],
            [-48.532267916992183, -27.135668526367198],
            [-48.53476791699218, -27.137468526367197],
            [-48.536267916992188, -27.140368526367197],
            [-48.536967916992182, -27.143168526367198],
            [-48.533667916992187, -27.145468526367196],
            [-48.537367916992181, -27.150068526367196],
            [-48.541967916992185, -27.151568526367196],
            [-48.54416791699218, -27.153768526367195],
            [-48.548067916992181, -27.156268526367196],
            [-48.551867916992187, -27.157368526367197],
            [-48.554767916992184, -27.156568526367195],
            [-48.556967916992186, -27.153368526367196],
            [-48.560567916992184, -27.154768526367196],
            [-48.563767916992184, -27.155168526367198],
            [-48.567367916992183, -27.154468526367197],
            [-48.571267916992184, -27.152868526367197],
            [-48.575967916992184, -27.150368526367195],
            [-48.57956791699219, -27.147868526367198],
            [-48.583167916992181, -27.145668526367196],
            [-48.58656791699218, -27.143668526367197],
            [-48.590167916992186, -27.139568526367196],
            [-48.593367916992186, -27.136368526367196],
            [-48.596167916992187, -27.132768526367194],
            [-48.599167916992187, -27.129568526367194],
            [-48.601367916992189, -27.126868526367197],
            [-48.60396791699219, -27.123268526367198],
            [-48.606467916992187, -27.119168526367197],
            [-48.608167916992187, -27.116068526367194],
            [-48.610067916992186, -27.111668526367197],
            [-48.611467916992183, -27.108368526367194],
            [-48.612567916992184, -27.105068526367198],
            [-48.613567916992181, -27.102068526367198],
            [-48.613667916992185, -27.099168526367194],
            [-48.611967916992185, -27.094868526367197],
            [-48.609267916992181, -27.091968526367197],
            [-48.605667916992189, -27.091568526367197],
            [-48.602067916992183, -27.093768526367196],
            [-48.59896791699218, -27.090368526367197],
            [-48.59596791699218, -27.087968526367199],
            [-48.59456791699219, -27.083468526367199],
            [-48.590367916992186, -27.080468526367198],
            [-48.593367916992186, -27.078568526367196],
            [-48.595067916992186, -27.073868526367196],
            [-48.595067916992186, -27.070168526367198],
            [-48.593967916992185, -27.066268526367196],
            [-48.593667916992189, -27.061968526367195],
            [-48.590367916992186, -27.060468526367195],
            [-48.588667916992186, -27.057168526367196],
            [-48.587067916992183, -27.053868526367197],
            [-48.587667916992189, -27.049668526367196],
            [-48.587067916992183, -27.045668526367194],
            [-48.584067916992183, -27.043368526367196],
            [-48.580767916992187, -27.040268526367196],
            [-48.581767916992185, -27.035968526367196],
            [-48.581467916992182, -27.032068526367198],
            [-48.581067916992183, -27.028068526367196],
            [-48.578767916992184, -27.024368526367198],
            [-48.57486791699219, -27.023968526367199],
            [-48.575767916992184, -27.019368526367195],
            [-48.573467916992186, -27.016768526367194],
            [-48.570767916992182, -27.013268526367199],
            [-48.570567916992189, -27.009168526367198],
            [-48.57456791699218, -27.009968526367196],
            [-48.577467916992184, -27.011068526367197],
            [-48.580167916992181, -27.008768526367199],
            [-48.581767916992185, -27.005968526367198],
            [-48.58226791699218, -27.002168526367196],
            [-48.582667916992186, -26.998768526367197],
            [-48.58226791699218, -26.994768526367196],
            [-48.587267916992189, -26.992768526367197],
            [-48.588467916992187, -26.995468526367198],
            [-48.590867916992181, -26.997168526367197],
            [-48.594867916992186, -26.995868526367197],
            [-48.598367916992188, -26.994168526367197],
            [-48.60196791699218, -26.996068526367196],
            [-48.603167916992184, -26.999368526367196],
            [-48.602367916992186, -27.003368526367197],
            [-48.60666791699218, -27.005468526367196],
            [-48.610267916992186, -27.005668526367195],
            [-48.613567916992181, -27.004968526367197],
            [-48.616167916992183, -27.003768526367196],
            [-48.619067916992186, -27.002168526367196],
            [-48.62166791699218, -26.999868526367194],
            [-48.624367916992185, -26.997368526367197],
            [-48.62666791699219, -26.995068526367199],
            [-48.628767916992189, -26.991668526367196],
            [-48.631267916992186, -26.987568526367198],
            [-48.633467916992188, -26.984168526367196],
            [-48.63436791699219, -26.980568526367197],
            [-48.634867916992185, -26.977268526367197],
            [-48.63436791699219, -26.974168526367194],
            [-48.631667916992185, -26.971968526367196],
            [-48.628267916992186, -26.971668526367196],
            [-48.627767916992184, -26.968368526367197],
            [-48.627667916992181, -26.965168526367197],
            [-48.625967916992181, -26.961468526367199],
            [-48.628767916992189, -26.959668526367196],
            [-48.629067916992184, -26.956768526367195],
            [-48.629167916992188, -26.952868526367197],
            [-48.628867916992185, -26.948268526367194],
            [-48.628467916992186, -26.944968526367198],
            [-48.627967916992183, -26.941268526367196],
            [-48.627067916992189, -26.936568526367196],
            [-48.626267916992184, -26.932568526367199],
            [-48.624467916992188, -26.929768526367198],
            [-48.62336791699218, -26.926568526367198],
            [-48.627367916992185, -26.928368526367194],
            [-48.63106791699218, -26.928868526367197],
            [-48.633767916992184, -26.925868526367196],
            [-48.63736791699219, -26.921868526367199],
            [-48.641067916992185, -26.918968526367195],
            [-48.641467916992184, -26.914568526367198],
            [-48.644267916992185, -26.911268526367195],
            [-48.643567916992183, -26.906768526367195],
            [-48.643267916992187, -26.903068526367196],
            [-48.642867916992188, -26.898368526367197],
            [-48.64206791699219, -26.893968526367196],
            [-48.641467916992184, -26.890368526367197],
            [-48.640667916992186, -26.886368526367196],
            [-48.639667916992181, -26.882368526367195],
            [-48.639267916992182, -26.878568526367197],
            [-48.638267916992184, -26.873268526367198],
            [-48.637667916992186, -26.869368526367197],
            [-48.636867916992188, -26.865568526367198],
            [-48.636267916992182, -26.861968526367196],
            [-48.635167916992188, -26.857568526367196],
            [-48.63406791699218, -26.852868526367196],
            [-48.633067916992189, -26.848668526367199],
            [-48.63236791699218, -26.845168526367196],
            [-48.63106791699218, -26.841968526367197],
            [-48.629567916992187, -26.839068526367196],
            [-48.628767916992189, -26.836168526367196],
            [-48.627367916992185, -26.833268526367196],
            [-48.625167916992183, -26.830468526367198],
            [-48.623267916992184, -26.827568526367195],
            [-48.620767916992186, -26.824568526367194],
            [-48.618867916992187, -26.827868526367197],
            [-48.615567916992184, -26.825768526367199],
            [-48.612267916992181, -26.825168526367197],
            [-48.609367916992184, -26.827468526367198],
            [-48.606067916992188, -26.826568526367197],
            [-48.602767916992185, -26.825768526367199],
            [-48.60096791699219, -26.822668526367195],
            [-48.599467916992182, -26.819668526367195],
            [-48.599467916992182, -26.816668526367195],
            [-48.59926791699219, -26.813768526367198],
            [-48.598067916992186, -26.811068526367194],
            [-48.597067916992188, -26.805768526367196],
            [-48.595067916992186, -26.801568526367198],
            [-48.593167916992186, -26.798568526367198],
            [-48.590567916992185, -26.796668526367196],
            [-48.587267916992189, -26.797468526367197],
            [-48.585667916992186, -26.794268526367198],
            [-48.587267916992189, -26.790068526367197],
            [-48.584867916992181, -26.785568526367197],
            [-48.587867916992181, -26.783668526367194],
            [-48.591467916992187, -26.785568526367197],
            [-48.594867916992186, -26.784168526367196],
            [-48.596467916992182, -26.781768526367195],
            [-48.598167916992182, -26.778668526367198],
            [-48.598367916992188, -26.773968526367199],
            [-48.602067916992183, -26.776768526367196],
            [-48.603167916992184, -26.780368526367198],
            [-48.602767916992185, -26.784768526367195],
            [-48.605067916992184, -26.787768526367195],
            [-48.607467916992185, -26.789868526367197],
            [-48.611167916992187, -26.790368526367196],
            [-48.61466791699219, -26.790568526367196],
            [-48.617967916992185, -26.789968526367197],
            [-48.621267916992181, -26.788668526367196],
            [-48.625467916992186, -26.786468526367194],
            [-48.62966791699219, -26.783468526367194],
            [-48.63266791699219, -26.780568526367198],
            [-48.634967916992181, -26.777568526367197],
            [-48.636767916992184, -26.774468526367194],
            [-48.638867916992183, -26.771268526367194],
            [-48.640467916992186, -26.767568526367196],
            [-48.643267916992187, -26.765768526367197],
            [-48.646767916992189, -26.763168526367195],
            [-48.649867916992186, -26.764968526367198],
            [-48.651267916992182, -26.767868526367195],
            [-48.652867916992186, -26.770968526367199],
            [-48.656967916992187, -26.772268526367196],
            [-48.660967916992185, -26.771468526367194],
            [-48.664167916992184, -26.770368526367196],
            [-48.667067916992181, -26.768268526367194],
            [-48.669767916992186, -26.765768526367197],
            [-48.671667916992185, -26.762968526367196],
            [-48.673067916992181, -26.760368526367195],
            [-48.674167916992189, -26.757468526367198],
            [-48.675067916992184, -26.754568526367194],
            [-48.676067916992181, -26.751568526367194],
            [-48.676867916992187, -26.747668526367196],
            [-48.677867916992184, -26.742468526367198],
            [-48.679167916992185, -26.736868526367196],
            [-48.680067916992186, -26.732768526367195],
            [-48.680567916992189, -26.729768526367195],
            [-48.681067916992184, -26.725968526367197],
            [-48.681867916992189, -26.721668526367196],
            [-48.681967916992186, -26.718068526367198],
            [-48.681667916992183, -26.714368526367195],
            [-48.681567916992186, -26.710668526367197],
            [-48.681367916992187, -26.707568526367197],
            [-48.68116791699218, -26.704268526367194],
            [-48.680867916992185, -26.701068526367195],
            [-48.679767916992184, -26.697968526367198],
            [-48.682167916992185, -26.694368526367196],
            [-48.684367916992187, -26.691868526367195],
            [-48.686867916992185, -26.688868526367195],
            [-48.68716791699218, -26.685468526367195],
            [-48.687667916992183, -26.680868526367199],
            [-48.688467916992181, -26.677168526367197],
            [-48.688067916992182, -26.672768526367197],
            [-48.68716791699218, -26.666768526367196],
            [-48.686567916992189, -26.662768526367195],
            [-48.686067916992187, -26.659268526367196],
            [-48.685167916992185, -26.655468526367194],
            [-48.684367916992187, -26.652268526367195],
            [-48.683767916992181, -26.648968526367199],
            [-48.683267916992186, -26.646068526367195],
            [-48.682167916992185, -26.642168526367197],
            [-48.682967916992183, -26.638868526367197],
            [-48.681567916992186, -26.634968526367196],
            [-48.680067916992186, -26.628768526367196],
            [-48.679667916992187, -26.624368526367196],
            [-48.678667916992183, -26.620568526367194],
            [-48.677567916992189, -26.616868526367199],
            [-48.676467916992181, -26.612868526367194],
            [-48.675567916992186, -26.609968526367197],
            [-48.674667916992185, -26.606968526367197],
            [-48.673567916992184, -26.604168526367197],
            [-48.67246791699219, -26.600868526367197],
            [-48.671067916992186, -26.597268526367195],
            [-48.669667916992182, -26.593368526367197],
            [-48.668167916992189, -26.589768526367195],
            [-48.666967916992185, -26.586468526367199],
            [-48.665667916992184, -26.583168526367196],
            [-48.664567916992183, -26.580168526367196],
            [-48.663167916992187, -26.577468526367198],
            [-48.661167916992184, -26.572668526367195],
            [-48.659167916992189, -26.567968526367196],
            [-48.657567916992186, -26.564368526367197],
            [-48.656167916992189, -26.560868526367194],
            [-48.654767916992185, -26.555868526367199],
            [-48.652367916992183, -26.551168526367196],
            [-48.65076791699218, -26.547268526367198],
            [-48.64936791699219, -26.544568526367197],
            [-48.647967916992187, -26.541668526367197],
            [-48.646567916992183, -26.538068526367198],
            [-48.645367916992186, -26.535068526367198],
            [-48.643467916992186, -26.531268526367196],
            [-48.64206791699219, -26.528468526367199],
            [-48.640467916992186, -26.525168526367196],
            [-48.638867916992183, -26.521768526367197],
            [-48.636767916992184, -26.517368526367196],
            [-48.634567916992182, -26.513168526367195],
            [-48.632967916992186, -26.510368526367195],
            [-48.630967916992184, -26.506368526367197],
            [-48.628467916992186, -26.501968526367197],
            [-48.627067916992189, -26.499468526367195],
            [-48.625167916992183, -26.496168526367196],
            [-48.623267916992184, -26.492968526367196],
            [-48.62166791699218, -26.490468526367195],
            [-48.61866791699218, -26.485568526367196],
            [-48.616467916992185, -26.481968526367197],
            [-48.614167916992187, -26.478368526367198],
            [-48.611667916992189, -26.474468526367197],
            [-48.60996791699219, -26.471768526367196],
            [-48.606767916992183, -26.467668526367198],
            [-48.603567916992183, -26.464068526367196],
            [-48.599567916992186, -26.460368526367198],
            [-48.597067916992188, -26.457268526367194],
            [-48.595567916992181, -26.453868526367195],
            [-48.596667916992189, -26.449868526367197],
            [-48.598167916992182, -26.445968526367196],
            [-48.598867916992184, -26.443068526367199],
            [-48.599467916992182, -26.439068526367194],
            [-48.599467916992182, -26.435168526367196],
            [-48.599167916992187, -26.430868526367199],
            [-48.598367916992188, -26.425868526367196],
            [-48.59756791699219, -26.422568526367197],
            [-48.596367916992186, -26.418168526367197],
            [-48.59456791699219, -26.413168526367194],
            [-48.592967916992187, -26.409168526367196],
            [-48.59156791699219, -26.405668526367194],
            [-48.589867916992183, -26.401968526367199],
            [-48.588667916992186, -26.399268526367194],
            [-48.587267916992189, -26.396768526367197],
            [-48.585067916992188, -26.392568526367196],
            [-48.58256791699219, -26.387768526367196],
            [-48.579667916992186, -26.383268526367196],
            [-48.577667916992183, -26.379968526367197],
            [-48.574967916992186, -26.375968526367195],
            [-48.572367916992185, -26.371668526367195],
            [-48.569967916992184, -26.367968526367196],
            [-48.568267916992184, -26.364768526367197],
            [-48.565167916992181, -26.359368526367199],
            [-48.56216791699218, -26.352768526367196],
            [-48.561267916992186, -26.349968526367196],
            [-48.560267916992188, -26.346968526367196],
            [-48.55916791699218, -26.342268526367196],
            [-48.557767916992184, -26.337268526367197],
            [-48.556067916992184, -26.332568526367197],
            [-48.554767916992184, -26.329068526367195],
            [-48.552967916992188, -26.324568526367194],
            [-48.550567916992186, -26.319368526367196],
            [-48.548867916992187, -26.315468526367198],
            [-48.54746791699219, -26.312768526367197],
            [-48.546167916992189, -26.309868526367197],
            [-48.544667916992182, -26.307168526367196],
            [-48.54246791699218, -26.302468526367196],
            [-48.53946791699218, -26.297268526367198],
            [-48.537067916992186, -26.292868526367194],
            [-48.53506791699219, -26.289468526367195],
            [-48.533167916992184, -26.285968526367196],
            [-48.531267916992185, -26.282368526367197],
            [-48.52876791699218, -26.277968526367196],
            [-48.526967916992184, -26.274868526367197],
            [-48.524467916992187, -26.270768526367196],
            [-48.522167916992188, -26.266868526367197],
            [-48.520467916992189, -26.264268526367196],
            [-48.518967916992182, -26.261568526367196],
            [-48.517167916992186, -26.258868526367195],
            [-48.515667916992186, -26.256268526367194],
            [-48.51406791699219, -26.253768526367196],
            [-48.512167916992183, -26.250568526367196],
            [-48.510267916992184, -26.247368526367197],
            [-48.508767916992184, -26.244768526367196],
            [-48.507067916992185, -26.242168526367195],
            [-48.504167916992188, -26.237468526367195],
            [-48.500567916992182, -26.235268526367197],
            [-48.499767916992184, -26.232068526367197],
            [-48.498267916992184, -26.228868526367194],
            [-48.496567916992184, -26.226468526367196],
            [-48.495767916992186, -26.222668526367194],
            [-48.494067916992186, -26.218768526367196],
            [-48.496867916992187, -26.217268526367196],
            [-48.500767916992189, -26.219268526367195],
            [-48.501567916992187, -26.223368526367196],
            [-48.503567916992182, -26.225968526367197],
            [-48.507167916992188, -26.226368526367196],
            [-48.51206791699218, -26.225268526367195],
            [-48.51536791699219, -26.223168526367196],
            [-48.518267916992187, -26.220968526367194],
            [-48.520767916992185, -26.217868526367198],
            [-48.522167916992188, -26.215168526367197],
            [-48.523467916992189, -26.212568526367196],
            [-48.525067916992185, -26.208768526367194],
            [-48.525967916992187, -26.205068526367196],
            [-48.526567916992185, -26.201468526367197],
            [-48.527267916992187, -26.196368526367195],
            [-48.527567916992183, -26.193068526367199],
            [-48.527667916992186, -26.189668526367196],
            [-48.527567916992183, -26.186068526367194],
            [-48.527067916992181, -26.182968526367198],
            [-48.526267916992182, -26.179668526367195],
            [-48.524867916992186, -26.176068526367196],
            [-48.52406791699218, -26.171768526367195],
            [-48.525367916992181, -26.167168526367199],
            [-48.528067916992185, -26.164168526367199],
            [-48.531967916992187, -26.163368526367197],
            [-48.534867916992184, -26.165968526367195],
            [-48.537067916992186, -26.168168526367197],
            [-48.540267916992185, -26.169768526367196],
            [-48.543867916992184, -26.170368526367199],
            [-48.548567916992184, -26.170868526367194],
            [-48.553667916992183, -26.169768526367196],
            [-48.558267916992186, -26.169168526367194],
            [-48.564467916992186, -26.168168526367197],
            [-48.569267916992189, -26.167768526367198],
            [-48.57286791699218, -26.167168526367199],
            [-48.575967916992184, -26.165968526367195],
            [-48.581867916992181, -26.163168526367194],
            [-48.585367916992183, -26.159768526367195],
            [-48.587267916992189, -26.156568526367195],
            [-48.588967916992189, -26.153768526367195],
            [-48.590067916992183, -26.150468526367199],
            [-48.59126791699218, -26.147368526367195],
            [-48.592567916992181, -26.143968526367196],
            [-48.59456791699219, -26.138968526367197],
            [-48.596167916992187, -26.134268526367194],
            [-48.59726791699218, -26.130268526367196],
            [-48.598367916992188, -26.126668526367197],
            [-48.59896791699218, -26.123768526367197],
            [-48.599567916992186, -26.120768526367197],
            [-48.600467916992187, -26.116368526367197],
            [-48.60096791699219, -26.111868526367196],
            [-48.601667916992184, -26.108068526367198],
            [-48.602067916992183, -26.103168526367199],
            [-48.602467916992182, -26.099768526367196],
            [-48.602767916992185, -26.095568526367195],
            [-48.603067916992188, -26.090068526367197],
            [-48.603167916992184, -26.085768526367197],
            [-48.603167916992184, -26.082568526367197],
            [-48.603067916992188, -26.079368526367197],
            [-48.603067916992188, -26.076368526367197],
            [-48.603067916992188, -26.072468526367196],
            [-48.606067916992188, -26.069668526367195],
            [-48.608667916992189, -26.066868526367195],
            [-48.610367916992189, -26.062668526367197],
            [-48.610567916992181, -26.057768526367198],
            [-48.610367916992189, -26.054068526367196],
            [-48.610267916992186, -26.049968526367195],
            [-48.609767916992183, -26.045768526367198],
            [-48.609167916992185, -26.040668526367199],
            [-48.608867916992182, -26.037268526367196],
            [-48.608367916992187, -26.033068526367195],
            [-48.607867916992184, -26.029268526367197],
            [-48.607467916992185, -26.026268526367197],
            [-48.606767916992183, -26.023168526367197],
            [-48.606067916992188, -26.018468526367194],
            [-48.60496791699218, -26.013468526367198],
            [-48.603867916992186, -26.008468526367196],
            [-48.602467916992182, -26.002468526367196],
            [-48.602067916992183, -25.997968526367195],
            [-48.600567916992183, -25.993668526367195],
            [-48.59926791699219, -25.989068526367198],
            [-48.597867916992186, -25.984268526367195],
            [-48.596467916992182, -25.980568526367197],
            [-48.595567916992181, -25.977468526367197],
            [-48.594467916992187, -25.970368526367196],
            [-48.592867916992184, -25.964768526367195],
            [-48.591767916992183, -25.960968526367196],
            [-48.590867916992181, -25.957868526367196],
            [-48.589467916992184, -25.954068526367195],
            [-48.587867916992181, -25.949568526367194],
            [-48.586267916992185, -25.945468526367197],
            [-48.584667916992188, -25.941268526367196],
            [-48.583267916992185, -25.938068526367196],
            [-48.581867916992181, -25.934468526367198],
            [-48.580367916992188, -25.930568526367196],
            [-48.578567916992185, -25.926568526367198],
            [-48.576867916992185, -25.922468526367197],
            [-48.575467916992181, -25.919268526367198],
            [-48.573767916992182, -25.916168526367194],
            [-48.571267916992184, -25.911168526367199],
            [-48.569067916992182, -25.906968526367194],
            [-48.566567916992184, -25.902068526367195],
            [-48.564067916992187, -25.897268526367196],
            [-48.561867916992185, -25.893268526367194],
            [-48.56086791699218, -25.889668526367196],
            [-48.565467916992183, -25.888468526367198],
            [-48.567367916992183, -25.885768526367194],
            [-48.567367916992183, -25.882068526367195],
            [-48.565767916992186, -25.878068526367194],
            [-48.56416791699219, -25.874768526367198],
            [-48.562967916992186, -25.871168526367196],
            [-48.562767916992186, -25.866468526367196],
            [-48.56416791699219, -25.862468526367195],
            [-48.564567916992189, -25.856768526367198],
            [-48.561667916992185, -25.855068526367198],
            [-48.559067916992184, -25.853668526367194],
            [-48.554767916992184, -25.852868526367196],
            [-48.551167916992185, -25.850868526367197],
            [-48.547867916992189, -25.851468526367196],
            [-48.544667916992182, -25.849768526367196],
            [-48.540267916992185, -25.848068526367197],
            [-48.535667916992182, -25.850968526367197],
            [-48.53506791699219, -25.847368526367198],
            [-48.536967916992182, -25.844068526367195],
            [-48.535967916992185, -25.838668526367197],
            [-48.534467916992185, -25.832268526367194],
            [-48.533367916992184, -25.827468526367198],
            [-48.53206791699219, -25.824268526367199],
            [-48.530167916992184, -25.820268526367194],
            [-48.531667916992184, -25.815568526367194],
            [-48.531167916992189, -25.811968526367195],
            [-48.529767916992185, -25.808368526367197],
            [-48.527067916992181, -25.802968526367195],
            [-48.52406791699218, -25.797568526367197],
            [-48.521167916992184, -25.792168526367199],
            [-48.519367916992181, -25.788068526367198],
            [-48.517167916992186, -25.784168526367196],
            [-48.515667916992186, -25.780968526367197],
            [-48.51236791699219, -25.774268526367194],
            [-48.510767916992187, -25.771268526367194],
            [-48.50906791699218, -25.768168526367198],
            [-48.50736791699218, -25.764568526367196],
            [-48.50466791699219, -25.759968526367196],
            [-48.502767916992184, -25.756668526367196],
            [-48.500867916992185, -25.753368526367197],
            [-48.497267916992186, -25.747268526367197],
            [-48.492467916992183, -25.739368526367194],
            [-48.489467916992183, -25.734468526367195],
            [-48.487467916992188, -25.730868526367196],
            [-48.484767916992183, -25.726668526367199],
            [-48.482167916992182, -25.722268526367195],
            [-48.480067916992184, -25.718668526367196],
            [-48.477867916992182, -25.714468526367199],
            [-48.473667916992184, -25.708268526367196],
            [-48.471967916992185, -25.705668526367198],
            [-48.470067916992186, -25.702568526367195],
            [-48.468067916992183, -25.699368526367195],
            [-48.466167916992184, -25.696368526367195],
            [-48.464267916992185, -25.693468526367198],
            [-48.462567916992185, -25.690568526367194],
            [-48.460667916992186, -25.687968526367197],
            [-48.45756791699219, -25.683368526367197],
            [-48.455667916992184, -25.680468526367196],
            [-48.453567916992185, -25.677568526367196],
            [-48.450667916992188, -25.673568526367198],
            [-48.447967916992184, -25.668868526367199],
            [-48.444967916992184, -25.663868526367196],
            [-48.442467916992186, -25.659468526367196],
            [-48.440267916992184, -25.655968526367197],
            [-48.437667916992183, -25.651768526367196],
            [-48.435767916992184, -25.648768526367196],
            [-48.433567916992189, -25.645668526367196],
            [-48.430467916992185, -25.641468526367198],
            [-48.427467916992185, -25.637668526367197],
            [-48.423967916992183, -25.633568526367196],
            [-48.420567916992184, -25.629868526367197],
            [-48.41776791699219, -25.626868526367197],
            [-48.41446791699218, -25.623268526367198],
            [-48.41176791699219, -25.620768526367197],
            [-48.40846791699218, -25.617668526367197],
            [-48.404167916992186, -25.613968526367195],
            [-48.401567916992185, -25.611968526367196],
            [-48.397267916992185, -25.608868526367196],
            [-48.391467916992184, -25.604768526367195],
            [-48.385267916992184, -25.600668526367194],
            [-48.379167916992188, -25.597368526367198],
            [-48.376567916992187, -25.595668526367199],
            [-48.371167916992185, -25.592568526367195],
            [-48.368067916992189, -25.590668526367196],
            [-48.364967916992185, -25.589068526367196],
            [-48.360767916992188, -25.587268526367197],
            [-48.357267916992186, -25.585668526367197],
            [-48.353567916992183, -25.584368526367196],
            [-48.31986791699218, -25.586468526367199],
            [-48.312967916992186, -25.575668526367195],
            [-48.310567916992184, -25.573168526367198],
            [-48.307567916992184, -25.570468526367197],
            [-48.305067916992186, -25.568768526367194],
            [-48.301967916992183, -25.566668526367195],
            [-48.299167916992189, -25.566068526367197],
            [-48.301067916992181, -25.563068526367196],
            [-48.30216791699219, -25.559668526367197],
            [-48.300267916992183, -25.556168526367195],
            [-48.298167916992185, -25.552968526367195],
            [-48.296367916992182, -25.550068526367198],
            [-48.29446791699219, -25.547868526367196],
            [-48.290867916992184, -25.546368526367196],
            [-48.276867916992188, -25.514068526367197],
            [-48.310767916992184, -25.492568526367194],
            [-48.301967916992183, -25.492468526367198],
            [-48.295867916992187, -25.491468526367196],
            [-48.286967916992182, -25.489168526367195],
            [-48.283067916992181, -25.486768526367197],
            [-48.27876791699218, -25.483268526367198],
            [-48.275667916992184, -25.480568526367197],
            [-48.273467916992189, -25.478668526367194],
            [-48.270367916992186, -25.476668526367199],
            [-48.266267916992184, -25.474168526367194],
            [-48.262767916992189, -25.472368526367198],
            [-48.258467916992188, -25.470568526367195],
            [-48.254967916992186, -25.469468526367194],
            [-48.251567916992187, -25.467568526367195],
            [-48.246867916992187, -25.465368526367197],
            [-48.243267916992181, -25.465368526367197],
            [-48.238667916992185, -25.465868526367196],
            [-48.233667916992189, -25.469268526367195],
            [-48.227367916992186, -25.472268526367195],
            [-48.223167916992182, -25.473668526367199],
            [-48.219067916992188, -25.473668526367199],
            [-48.216167916992184, -25.473768526367195],
            [-48.213467916992187, -25.471368526367197],
            [-48.212667916992189, -25.468068526367198],
            [-48.212067916992183, -25.465168526367197],
            [-48.21156791699218, -25.462268526367197],
            [-48.210667916992186, -25.459368526367196],
            [-48.209267916992189, -25.455168526367196],
            [-48.207067916992187, -25.449668526367198],
            [-48.205667916992184, -25.445768526367196],
            [-48.20456791699219, -25.442968526367196],
            [-48.202667916992183, -25.438468526367195],
            [-48.200767916992184, -25.434068526367199],
            [-48.19956791699218, -25.431168526367195],
            [-48.197167916992186, -25.426868526367194],
            [-48.19516791699219, -25.423068526367196],
            [-48.193167916992181, -25.419568526367197],
            [-48.191567916992184, -25.416468526367197],
            [-48.188567916992184, -25.411968526367197],
            [-48.185467916992181, -25.406968526367194],
            [-48.181667916992183, -25.401268526367197],
            [-48.179967916992183, -25.398968526367199],
            [-48.177967916992188, -25.395968526367199],
            [-48.175567916992186, -25.392668526367196],
            [-48.173567916992184, -25.390068526367195],
            [-48.171467916992185, -25.387168526367198],
            [-48.169267916992183, -25.384268526367194],
            [-48.166767916992185, -25.380968526367198],
            [-48.164567916992183, -25.378068526367194],
            [-48.162767916992181, -25.375468526367197],
            [-48.159867916992184, -25.372168526367197],
            [-48.157267916992183, -25.368868526367194],
            [-48.154867916992188, -25.366068526367194],
            [-48.152567916992183, -25.363268526367197],
            [-48.150467916992184, -25.361068526367198],
            [-48.147667916992184, -25.357868526367199],
            [-48.143967916992182, -25.353868526367194],
            [-48.14036791699219, -25.349968526367196],
            [-48.13706791699218, -25.346968526367196],
            [-48.134567916992182, -25.344468526367194],
            [-48.13136791699219, -25.341468526367194],
            [-48.127167916992185, -25.337868526367195],
            [-48.12336791699218, -25.334668526367196],
            [-48.120467916992183, -25.332368526367198],
            [-48.116167916992183, -25.329568526367197],
            [-48.112167916992185, -25.326568526367197],
            [-48.109267916992181, -25.324568526367194],
            [-48.105667916992189, -25.321368526367195],
            [-48.102067916992183, -25.318468526367198],
            [-48.099567916992186, -25.316268526367196],
            [-48.098367916992188, -25.312668526367197],
            [-48.096667916992189, -25.309468526367198],
            [-48.091167916992184, -25.304368526367199],
            [-48.084267916992189, -25.296768526367195],
            [-48.079067916992187, -25.290668526367199],
            [-48.069367916992185, -25.280168526367198],
            [-48.063767916992184, -25.274468526367194],
            [-48.061267916992186, -25.271868526367196],
            [-48.056367916992187, -25.267368526367196],
            [-48.052567916992189, -25.263868526367197],
            [-48.046867916992184, -25.258468526367196],
            [-48.043067916992186, -25.255268526367196],
            [-48.03476791699218, -25.247968526367195],
            [-48.025367916992181, -25.240068526367196],
            [-48.016767916992187, -25.233068526367198],
            [-48.013167916992188, -25.230268526367198],
            [-48.002367916992185, -25.221668526367196],
            [-47.991567916992182, -25.212968526367199],
            [-47.983667916992189, -25.206168526367197],
            [-47.978067916992188, -25.202168526367196],
            [-47.974567916992186, -25.200968526367198],
            [-47.97226791699218, -25.198268526367194],
            [-47.970867916992184, -25.195768526367196],
            [-47.968767916992185, -25.193768526367194],
            [-47.965367916992186, -25.190868526367197],
            [-47.962867916992181, -25.188568526367199],
            [-47.959767916992185, -25.185868526367194],
            [-47.956067916992183, -25.182968526367198],
            [-47.952967916992186, -25.180268526367197],
            [-47.945967916992188, -25.174268526367197],
            [-47.942467916992186, -25.171468526367196],
            [-47.934867916992189, -25.166968526367196],
            [-47.930867916992185, -25.165068526367197],
            [-47.927567916992189, -25.162268526367196],
            [-47.923267916992188, -25.160668526367196],
            [-47.918867916992184, -25.159768526367195],
            [-47.914567916992183, -25.159168526367196],
            [-47.912767916992181, -25.156568526367195],
            [-47.915567916992188, -25.155868526367197],
            [-47.918867916992184, -25.154168526367194],
            [-47.919567916992186, -25.150068526367196],
            [-47.918967916992187, -25.145668526367196],
            [-47.916667916992182, -25.143568526367197],
            [-47.915567916992188, -25.139668526367196],
            [-47.91446791699218, -25.135368526367195],
            [-47.913067916992183, -25.130968526367198],
            [-47.91176791699219, -25.127368526367196],
            [-47.909867916992184, -25.123568526367194],
            [-47.908367916992184, -25.119468526367196],
            [-47.905867916992186, -25.116968526367195],
            [-47.903367916992181, -25.114468526367197],
            [-47.90076791699218, -25.110668526367196],
            [-47.897167916992188, -25.108568526367197],
            [-47.895767916992185, -25.105268526367198],
            [-47.899767916992189, -25.104168526367197],
            [-47.902567916992183, -25.102568526367197],
            [-47.90406791699219, -25.099568526367197],
            [-47.904567916992185, -25.094768526367197],
            [-47.903967916992187, -25.089768526367195],
            [-47.903067916992185, -25.086468526367199],
            [-47.902667916992186, -25.082368526367198],
            [-47.902667916992186, -25.079268526367194],
            [-47.902567916992183, -25.074868526367197],
            [-47.902567916992183, -25.070968526367196],
            [-47.902367916992183, -25.067668526367196],
            [-47.903667916992184, -25.064468526367197],
            [-47.907367916992186, -25.063068526367196],
            [-47.910867916992188, -25.052868526367199],
            [-47.90376791699218, -25.052468526367196],
            [-47.900867916992183, -25.052168526367197],
            [-47.897567916992188, -25.051868526367194],
            [-47.894567916992187, -25.051968526367197],
            [-47.890767916992189, -25.052168526367197],
            [-47.88736791699219, -25.051068526367196],
            [-47.885967916992186, -25.047468526367197],
            [-47.885967916992186, -25.043968526367195],
            [-47.885467916992184, -25.040868526367198],
            [-47.884667916992186, -25.037068526367197],
            [-47.883867916992187, -25.033868526367197],
            [-47.88236791699218, -25.029668526367196],
            [-47.880267916992182, -25.025068526367196],
            [-47.878267916992186, -25.021568526367197],
            [-47.875467916992186, -25.017068526367197],
            [-47.872367916992189, -25.012468526367197],
            [-47.867167916992187, -25.005468526367196],
            [-47.862467916992188, -24.999468526367195],
            [-47.854267916992185, -24.989468526367197],
            [-47.850267916992188, -24.984968526367197],
            [-47.842867916992184, -24.976668526367199],
            [-47.836467916992184, -24.969868526367197],
            [-47.821867916992183, -24.955768526367194],
            [-47.811967916992188, -24.947068526367197],
            [-47.809667916992183, -24.944868526367195],
            [-47.806567916992186, -24.942168526367198],
            [-47.800267916992183, -24.936668526367196],
            [-47.797267916992183, -24.934368526367194],
            [-47.789767916992183, -24.927968526367195],
            [-47.778367916992188, -24.918668526367199],
            [-47.768467916992186, -24.910568526367197],
            [-47.759267916992187, -24.902968526367196],
            [-47.744967916992188, -24.891768526367194],
            [-47.731467916992187, -24.880568526367199],
            [-47.715867916992188, -24.868268526367196],
            [-47.694267916992182, -24.852068526367198],
            [-47.691367916992185, -24.849968526367196],
            [-47.685167916992185, -24.845368526367196],
            [-47.675467916992183, -24.837968526367199],
            [-47.659567916992188, -24.826568526367197],
            [-47.653167916992182, -24.821768526367194],
            [-47.641467916992184, -24.812968526367197],
            [-47.632067916992185, -24.805868526367199],
            [-47.622967916992188, -24.798968526367197],
            [-47.604267916992185, -24.786368526367198],
            [-47.591967916992182, -24.777668526367197],
            [-47.582667916992186, -24.771768526367197],
            [-47.576067916992187, -24.767568526367196],
            [-47.569967916992184, -24.763568526367195],
            [-47.565967916992186, -24.761068526367197],
            [-47.562667916992183, -24.758868526367195],
            [-47.560167916992185, -24.757368526367195],
            [-47.556867916992182, -24.755268526367196],
            [-47.553067916992184, -24.753068526367194],
            [-47.549967916992188, -24.751268526367198],
            [-47.547467916992183, -24.749768526367198],
            [-47.544167916992187, -24.747968526367195],
            [-47.540267916992185, -24.745768526367197],
            [-47.535667916992182, -24.743068526367196],
            [-47.530867916992186, -24.740368526367199],
            [-47.526167916992186, -24.737768526367198],
            [-47.521267916992187, -24.735068526367197],
            [-47.518467916992186, -24.733568526367197],
            [-47.513267916992184, -24.730568526367197],
            [-47.510367916992188, -24.728868526367194],
            [-47.504167916992188, -24.725668526367194],
            [-47.496067916992182, -24.721468526367197],
            [-47.489467916992183, -24.718068526367198],
            [-47.485067916992186, -24.715868526367196],
            [-47.482467916992185, -24.714468526367199],
            [-47.461967916992187, -24.704368526367197],
            [-47.437167916992188, -24.691568526367199],
            [-47.424767916992188, -24.685168526367196],
            [-47.421667916992185, -24.682968526367198],
            [-47.420267916992188, -24.679768526367198],
            [-47.414167916992184, -24.674968526367195],
            [-47.409467916992185, -24.673868526367194],
            [-47.405167916992184, -24.672768526367197],
            [-47.401467916992182, -24.669668526367197],
            [-47.396767916992182, -24.665368526367196],
            [-47.390367916992183, -24.660268526367197],
            [-47.385267916992184, -24.656768526367195],
            [-47.378767916992182, -24.652368526367198],
            [-47.375267916992186, -24.650068526367196],
            [-47.366967916992188, -24.645068526367197],
            [-47.362167916992185, -24.642068526367197],
            [-47.356967916992183, -24.639268526367196],
            [-47.348967916992187, -24.634268526367194],
            [-47.340367916992186, -24.629168526367195],
            [-47.328267916992182, -24.622168526367197],
            [-47.319367916992185, -24.616868526367199],
            [-47.309767916992186, -24.610868526367199],
            [-47.300267916992183, -24.605268526367198],
            [-47.292167916992184, -24.600268526367195],
            [-47.280867916992186, -24.593768526367196],
            [-47.274267916992187, -24.590168526367197],
            [-47.265667916992186, -24.585168526367198],
            [-47.262367916992183, -24.583468526367199],
            [-47.253467916992186, -24.578568526367196],
            [-47.249467916992188, -24.576468526367197],
            [-47.243267916992188, -24.574268526367199],
            [-47.239667916992182, -24.573768526367196],
            [-47.236467916992183, -24.571668526367198],
            [-47.233167916992187, -24.572068526367197],
            [-47.228867916992186, -24.571668526367198],
            [-47.225367916992184, -24.569068526367197],
            [-47.224567916992186, -24.565468526367198],
            [-47.226667916992184, -24.561568526367196],
            [-47.224967916992185, -24.557468526367195],
            [-47.221167916992187, -24.554068526367196],
            [-47.217567916992188, -24.551368526367199],
            [-47.214567916992188, -24.549168526367197],
            [-47.211567916992188, -24.547168526367194],
            [-47.208667916992184, -24.545068526367196],
            [-47.205667916992184, -24.543068526367197],
            [-47.201467916992186, -24.540268526367196],
            [-47.197067916992182, -24.538068526367198],
            [-47.191667916992188, -24.535968526367196],
            [-47.187267916992184, -24.534468526367196],
            [-47.187267916992184, -24.530568526367198],
            [-47.186067916992187, -24.525368526367195],
            [-47.182667916992187, -24.522068526367196],
            [-47.179367916992184, -24.519268526367195],
            [-47.176367916992184, -24.516768526367194],
            [-47.173267916992188, -24.514268526367196],
            [-47.166667916992182, -24.509168526367198],
            [-47.162867916992184, -24.506268526367194],
            [-47.157667916992182, -24.502368526367196],
            [-47.153167916992182, -24.498768526367197],
            [-47.149467916992187, -24.496068526367196],
            [-47.144567916992187, -24.492268526367198],
            [-47.140767916992182, -24.489468526367197],
            [-47.134667916992186, -24.485268526367197],
            [-47.131367916992183, -24.482868526367199],
            [-47.123867916992182, -24.477768526367196],
            [-47.121267916992188, -24.475868526367197],
            [-47.112167916992185, -24.469768526367197],
            [-47.109467916992187, -24.467968526367194],
            [-47.097267916992188, -24.460168526367198],
            [-47.090467916992182, -24.455768526367194],
            [-47.082367916992183, -24.450968526367198],
            [-47.078567916992185, -24.448768526367196],
            [-47.070667916992186, -24.443768526367194],
            [-47.065167916992188, -24.440268526367198],
            [-47.058667916992185, -24.436368526367197],
            [-47.053667916992183, -24.433368526367197],
            [-47.048567916992184, -24.431168526367195],
            [-47.044767916992186, -24.429668526367195],
            [-47.040367916992182, -24.428268526367198],
            [-47.035967916992185, -24.426968526367197],
            [-47.030467916992187, -24.427168526367197],
            [-47.027267916992187, -24.425868526367196],
            [-47.024467916992187, -24.423968526367197],
            [-47.021867916992186, -24.422468526367197],
            [-47.020767916992185, -24.419268526367198],
            [-47.020067916992183, -24.415568526367196],
            [-47.017067916992183, -24.413368526367197],
            [-47.013967916992186, -24.412768526367195],
            [-47.011067916992182, -24.414268526367195],
            [-47.008467916992188, -24.412768526367195],
            [-47.006667916992185, -24.409868526367198],
            [-47.001067916992184, -24.409168526367196],
            [-47.004367916992187, -24.406268526367196],
            [-47.004367916992187, -24.402268526367195],
            [-47.002967916992183, -24.397968526367194],
            [-47.007367916992187, -24.396768526367197],
            [-47.010167916992188, -24.394268526367195],
            [-47.009967916992188, -24.389568526367196],
            [-47.012867916992185, -24.387968526367196],
            [-47.015967916992182, -24.387068526367194],
            [-47.017067916992183, -24.384268526367194],
            [-47.017467916992182, -24.380168526367196],
            [-47.015367916992183, -24.375468526367197],
            [-47.013167916992188, -24.371968526367198],
            [-47.011067916992182, -24.369468526367196],
            [-47.008067916992182, -24.366868526367199],
            [-47.004167916992188, -24.363668526367196],
            [-47.001667916992183, -24.360268526367197],
            [-47.000967916992188, -24.357268526367196],
            [-46.999367916992185, -24.353868526367194],
            [-46.997367916992182, -24.350868526367197],
            [-46.999867916992187, -24.348668526367199],
            [-47.001267916992184, -24.344868526367197],
            [-47.000167916992183, -24.340968526367199],
            [-47.001867916992182, -24.336968526367194],
            [-46.999767916992184, -24.332568526367197],
            [-46.995867916992182, -24.327568526367195],
            [-46.993367916992185, -24.324968526367197],
            [-46.990067916992189, -24.321568526367194],
            [-46.986867916992182, -24.318468526367198],
            [-46.984167916992185, -24.315968526367197],
            [-46.981467916992187, -24.313468526367195],
            [-46.977867916992182, -24.310268526367196],
            [-46.972367916992184, -24.305568526367196],
            [-46.967667916992184, -24.301668526367195],
            [-46.962867916992188, -24.297868526367196],
            [-46.958967916992187, -24.294968526367196],
            [-46.956767916992185, -24.293068526367197],
            [-46.952967916992186, -24.290268526367196],
            [-46.950367916992185, -24.288168526367194],
            [-46.946367916992187, -24.285368526367197],
            [-46.940567916992187, -24.280968526367197],
            [-46.936667916992185, -24.278368526367196],
            [-46.930867916992185, -24.274068526367195],
            [-46.928267916992183, -24.272568526367195],
            [-46.920367916992184, -24.267368526367196],
            [-46.909867916992184, -24.260668526367198],
            [-46.907067916992183, -24.258868526367195],
            [-46.898667916992189, -24.253468526367197],
            [-46.893667916992186, -24.250468526367197],
            [-46.891267916992184, -24.248768526367197],
            [-46.887667916992186, -24.246368526367196],
            [-46.883167916992186, -24.243068526367196],
            [-46.878267916992186, -24.239768526367197],
            [-46.874367916992185, -24.237468526367195],
            [-46.871867916992187, -24.235768526367195],
            [-46.869067916992186, -24.233968526367196],
            [-46.866367916992182, -24.232268526367196],
            [-46.862767916992183, -24.230068526367198],
            [-46.860267916992186, -24.228368526367198],
            [-46.857167916992182, -24.226368526367196],
            [-46.853967916992183, -24.224468526367197],
            [-46.849467916992182, -24.221468526367197],
            [-46.843967916992185, -24.217968526367194],
            [-46.839867916992183, -24.215668526367196],
            [-46.837267916992182, -24.214068526367196],
            [-46.833267916992185, -24.211768526367194],
            [-46.829567916992183, -24.209668526367196],
            [-46.825967916992184, -24.207568526367197],
            [-46.820167916992183, -24.204068526367195],
            [-46.814667916992185, -24.201268526367194],
            [-46.810867916992187, -24.201568526367197],
            [-46.807667916992187, -24.200968526367198],
            [-46.804167916992185, -24.199068526367196],
            [-46.801467916992188, -24.196868526367197],
            [-46.799367916992182, -24.194568526367195],
            [-46.795767916992183, -24.193868526367197],
            [-46.793067916992186, -24.191968526367198],
            [-46.790567916992188, -24.189968526367196],
            [-46.787467916992185, -24.186668526367196],
            [-46.783767916992183, -24.185168526367196],
            [-46.779467916992182, -24.182668526367195],
            [-46.773967916992184, -24.179768526367198],
            [-46.769367916992188, -24.177468526367196],
            [-46.765967916992182, -24.175468526367197],
            [-46.762867916992185, -24.173968526367197],
            [-46.758467916992188, -24.171468526367196],
            [-46.754867916992183, -24.169568526367197],
            [-46.751267916992184, -24.167568526367198],
            [-46.748567916992187, -24.166168526367194],
            [-46.745167916992187, -24.164268526367195],
            [-46.741367916992182, -24.162068526367197],
            [-46.737567916992184, -24.159868526367198],
            [-46.733567916992186, -24.157768526367196],
            [-46.727567916992186, -24.154468526367197],
            [-46.721267916992183, -24.150868526367198],
            [-46.715867916992188, -24.147868526367198],
            [-46.712367916992186, -24.145768526367196],
            [-46.709767916992185, -24.143668526367197],
            [-46.702067916992185, -24.139868526367195],
            [-46.694367916992185, -24.135768526367194],
            [-46.690167916992188, -24.133768526367199],
            [-46.686667916992185, -24.131768526367196],
            [-46.683267916992186, -24.129868526367197],
            [-46.679067916992182, -24.127668526367195],
            [-46.675867916992182, -24.125768526367196],
            [-46.672767916992186, -24.124068526367196],
            [-46.668967916992187, -24.122168526367197],
            [-46.662067916992186, -24.118368526367199],
            [-46.657067916992183, -24.115768526367198],
            [-46.652867916992186, -24.113568526367196],
            [-46.649767916992182, -24.111868526367196],
            [-46.646267916992187, -24.110268526367197],
            [-46.642667916992188, -24.108368526367194],
            [-46.635367916992188, -24.104668526367199],
            [-46.632367916992187, -24.103068526367196],
            [-46.629067916992184, -24.101468526367196],
            [-46.626367916992187, -24.100268526367195],
            [-46.623567916992187, -24.098868526367198],
            [-46.617667916992183, -24.096168526367194],
            [-46.613567916992189, -24.094168526367199],
            [-46.609767916992183, -24.092268526367196],
            [-46.606067916992188, -24.089268526367196],
            [-46.600567916992183, -24.087868526367195],
            [-46.591267916992187, -24.083268526367196],
            [-46.582967916992182, -24.079568526367197],
            [-46.574967916992186, -24.075768526367199],
            [-46.565967916992186, -24.071368526367195],
            [-46.558367916992182, -24.067768526367196],
            [-46.549367916992182, -24.063568526367199],
            [-46.538667916992182, -24.058768526367196],
            [-46.530167916992184, -24.054768526367198],
            [-46.520367916992186, -24.050468526367197],
            [-46.510667916992183, -24.046168526367197],
            [-46.501567916992187, -24.042268526367195],
            [-46.497667916992185, -24.040568526367196],
            [-46.492967916992185, -24.038468526367197],
            [-46.484167916992185, -24.034768526367195],
            [-46.479267916992185, -24.032868526367196],
            [-46.474567916992186, -24.031168526367196],
            [-46.464767916992187, -24.027268526367195],
            [-46.454967916992182, -24.023968526367199],
            [-46.450667916992188, -24.022568526367195],
            [-46.445467916992186, -24.021068526367195],
            [-46.434067916992184, -24.018268526367194],
            [-46.424767916992188, -24.016568526367195],
            [-46.416167916992187, -24.015668526367197],
            [-46.408067916992188, -24.015768526367197],
            [-46.401567916992185, -24.017868526367195],
            [-46.399467916992187, -24.020768526367196],
            [-46.399067916992188, -24.025868526367198],
            [-46.400067916992185, -24.029268526367197],
            [-46.400067916992185, -24.032368526367197],
            [-46.396167916992184, -24.030068526367195],
            [-46.393967916992182, -24.027868526367197],
            [-46.392067916992183, -24.025468526367199],
            [-46.390667916992186, -24.022668526367198],
            [-46.390367916992183, -24.018168526367198],
            [-46.384967916992188, -24.017668526367196],
            [-46.386567916992185, -24.014668526367196],
            [-46.389867916992188, -24.012968526367196],
            [-46.392067916992183, -24.010268526367199],
            [-46.391767916992187, -24.007368526367195],
            [-46.389367916992185, -24.005468526367196],
            [-46.390767916992182, -24.002368526367196],
            [-46.392067916992183, -23.997668526367196],
            [-46.389267916992182, -23.996368526367196],
            [-46.385367916992188, -23.995468526367198],
            [-46.381567916992182, -23.994668526367196],
            [-46.378267916992186, -23.992968526367196],
            [-46.375767916992189, -23.989668526367197],
            [-46.374667916992188, -23.986368526367194],
            [-46.377467916992188, -23.984668526367194],
            [-46.379067916992184, -23.980968526367196],
            [-46.381867916992185, -23.978068526367196],
            [-46.385767916992187, -23.977768526367196],
            [-46.387967916992189, -23.975668526367194],
            [-46.384867916992185, -23.973068526367197],
            [-46.381067916992187, -23.970568526367195],
            [-46.376567916992187, -23.969568526367198],
            [-46.373267916992184, -23.972868526367197],
            [-46.371967916992183, -23.976668526367199],
            [-46.371067916992182, -23.980568526367197],
            [-46.367767916992186, -23.981768526367198],
            [-46.368267916992188, -23.978568526367198],
            [-46.368567916992184, -23.974868526367196],
            [-46.366367916992182, -23.973068526367197],
            [-46.363367916992182, -23.971968526367196],
            [-46.360367916992182, -23.971368526367197],
            [-46.356367916992184, -23.970868526367198],
            [-46.351367916992189, -23.972368526367198],
            [-46.346267916992183, -23.970668526367199],
            [-46.342667916992184, -23.971168526367194],
            [-46.339067916992185, -23.971668526367196],
            [-46.335467916992187, -23.972068526367195],
            [-46.332267916992187, -23.972668526367194],
            [-46.329267916992187, -23.973368526367196],
            [-46.325467916992189, -23.974568526367197],
            [-46.321367916992187, -23.976268526367196],
            [-46.317067916992187, -23.979168526367197],
            [-46.314967916992188, -23.981068526367196],
            [-46.311367916992182, -23.984668526367194],
            [-46.309067916992184, -23.987168526367196],
            [-46.307267916992188, -23.989968526367196],
            [-46.305167916992183, -23.993368526367199],
            [-46.309467916992183, -23.995868526367197],
            [-46.312967916992186, -23.997768526367196],
            [-46.316567916992184, -23.997668526367196],
            [-46.320467916992186, -23.998268526367198],
            [-46.320667916992186, -24.001268526367198],
            [-46.320767916992182, -24.004168526367195],
            [-46.323167916992183, -24.007768526367194],
            [-46.323267916992187, -24.012668526367197],
            [-46.322967916992184, -24.016768526367194],
            [-46.324267916992184, -24.020068526367197],
            [-46.322467916992188, -24.022568526367195],
            [-46.319367916992185, -24.024268526367194],
            [-46.317067916992187, -24.022568526367195],
            [-46.314867916992185, -24.020368526367196],
            [-46.312267916992184, -24.022568526367195],
            [-46.310567916992184, -24.026468526367196],
            [-46.307167916992185, -24.023668526367196],
            [-46.305567916992182, -24.020968526367199],
            [-46.302167916992182, -24.018268526367194],
            [-46.299167916992182, -24.016068526367196],
            [-46.295867916992187, -24.015968526367196],
            [-46.293167916992182, -24.017168526367197],
            [-46.291667916992182, -24.021068526367195],
            [-46.288467916992182, -24.024768526367197],
            [-46.284567916992188, -24.026168526367197],
            [-46.284867916992184, -24.029268526367197],
            [-46.285067916992183, -24.032568526367196],
            [-46.287267916992185, -24.034468526367196],
            [-46.288067916992183, -24.037568526367195],
            [-46.288967916992185, -24.040368526367196],
            [-46.287567916992188, -24.044168526367194],
            [-46.282667916992182, -24.042168526367199],
            [-46.281467916992185, -24.038468526367197],
            [-46.278467916992184, -24.035968526367196],
            [-46.277667916992186, -24.031268526367196],
            [-46.279567916992185, -24.027668526367197],
            [-46.279767916992185, -24.024068526367195],
            [-46.278467916992184, -24.020668526367196],
            [-46.275767916992187, -24.017868526367195],
            [-46.272067916992185, -24.014568526367196],
            [-46.267667916992188, -24.013268526367199],
            [-46.264267916992182, -24.012868526367196],
            [-46.266067916992185, -24.009668526367197],
            [-46.266467916992184, -24.005968526367198],
            [-46.263467916992184, -24.002668526367195],
            [-46.259367916992183, -24.000168526367197],
            [-46.256567916992182, -23.999468526367195],
            [-46.253467916992186, -23.997668526367196],
            [-46.250467916992186, -23.996568526367199],
            [-46.247667916992185, -23.994768526367196],
            [-46.243367916992185, -23.992168526367195],
            [-46.239667916992182, -23.990768526367198],
            [-46.236367916992187, -23.989968526367196],
            [-46.232867916992184, -23.989368526367194],
            [-46.227867916992182, -23.988068526367197],
            [-46.223167916992182, -23.987268526367195],
            [-46.217567916992188, -23.986468526367197],
            [-46.212967916992184, -23.986168526367194],
            [-46.209267916992182, -23.987168526367196],
            [-46.205667916992184, -23.989368526367194],
            [-46.203167916992186, -23.992768526367197],
            [-46.205667916992184, -23.996268526367196],
            [-46.206867916992188, -23.999468526367195],
            [-46.202867916992183, -24.000268526367197],
            [-46.200167916992186, -23.996868526367194],
            [-46.196667916992183, -23.994168526367197],
            [-46.193567916992187, -23.992268526367198],
            [-46.191067916992182, -23.990068526367196],
            [-46.186867916992185, -23.991068526367194],
            [-46.182467916992188, -23.990368526367199],
            [-46.185167916992185, -23.986968526367196],
            [-46.185467916992188, -23.982468526367196],
            [-46.185867916992187, -23.978068526367196],
            [-46.184967916992186, -23.972368526367198],
            [-46.185167916992185, -23.967968526367194],
            [-46.184367916992187, -23.964868526367198],
            [-46.181867916992182, -23.961568526367195],
            [-46.177467916992185, -23.960168526367198],
            [-46.174467916992185, -23.958968526367194],
            [-46.171767916992188, -23.955968526367194],
            [-46.169467916992183, -23.951068526367195],
            [-46.167267916992188, -23.947668526367195],
            [-46.164567916992183, -23.944868526367195],
            [-46.166167916992187, -23.941668526367195],
            [-46.167867916992186, -23.938368526367196],
            [-46.171167916992182, -23.938868526367195],
            [-46.173567916992184, -23.940768526367197],
            [-46.177267916992186, -23.939868526367196],
            [-46.179667916992187, -23.936368526367197],
            [-46.180467916992185, -23.932268526367196],
            [-46.180167916992183, -23.928668526367197],
            [-46.178367916992187, -23.923868526367194],
            [-46.173967916992183, -23.920368526367199],
            [-46.171467916992185, -23.917168526367199],
            [-46.168067916992186, -23.915268526367196],
            [-46.168067916992186, -23.912268526367196],
            [-46.165667916992184, -23.909868526367198],
            [-46.162767916992188, -23.907868526367196],
            [-46.159467916992185, -23.906768526367195],
            [-46.155167916992184, -23.908368526367198],
            [-46.150867916992183, -23.906668526367199],
            [-46.152067916992188, -23.903368526367196],
            [-46.151567916992185, -23.899768526367197],
            [-46.149867916992186, -23.895768526367196],
            [-46.147167916992188, -23.893568526367197],
            [-46.144267916992185, -23.893168526367198],
            [-46.139867916992188, -23.891368526367195],
            [-46.137967916992189, -23.886568526367196],
            [-46.139967916992184, -23.882468526367198],
            [-46.140367916992183, -23.879468526367198],
            [-46.138267916992184, -23.877368526367196],
            [-46.138467916992184, -23.873368526367194],
            [-46.134967916992188, -23.871668526367195],
            [-46.136367916992185, -23.869068526367194],
            [-46.133867916992187, -23.865068526367196],
            [-46.129567916992187, -23.862968526367197],
            [-46.124967916992183, -23.859668526367194],
            [-46.127967916992183, -23.856768526367198],
            [-46.132367916992187, -23.856968526367197],
            [-46.132767916992186, -23.852768526367196],
            [-46.132167916992188, -23.849768526367196],
            [-46.130267916992182, -23.846368526367197],
            [-46.126867916992182, -23.842868526367198],
            [-46.123567916992187, -23.840468526367196],
            [-46.120867916992182, -23.838668526367197],
            [-46.118267916992188, -23.836568526367195],
            [-46.115367916992184, -23.835068526367195],
            [-46.112267916992188, -23.833468526367199],
            [-46.108967916992185, -23.831868526367195],
            [-46.104967916992187, -23.829868526367196],
            [-46.100567916992183, -23.827668526367198],
            [-46.096167916992187, -23.825668526367195],
            [-46.093167916992186, -23.824268526367199],
            [-46.089367916992188, -23.822368526367196],
            [-46.085067916992188, -23.820668526367196],
            [-46.079967916992182, -23.818868526367197],
            [-46.075667916992188, -23.817368526367197],
            [-46.071667916992183, -23.815968526367197],
            [-46.066267916992182, -23.814568526367196],
            [-46.060867916992187, -23.814068526367194],
            [-46.057267916992188, -23.814368526367197],
            [-46.053267916992183, -23.815768526367197],
            [-46.049367916992182, -23.817968526367196],
            [-46.046667916992185, -23.820668526367196],
            [-46.045367916992184, -23.824268526367199],
            [-46.047467916992183, -23.827968526367197],
            [-46.050067916992184, -23.829668526367197],
            [-46.046867916992184, -23.831468526367196],
            [-46.043167916992182, -23.829568526367197],
            [-46.039867916992186, -23.826568526367197],
            [-46.039467916992187, -23.823568526367197],
            [-46.036267916992188, -23.821068526367196],
            [-46.038967916992185, -23.817368526367197],
            [-46.037067916992186, -23.813068526367196],
            [-46.034167916992182, -23.810468526367195],
            [-46.030467916992187, -23.808568526367196],
            [-46.024467916992187, -23.805568526367196],
            [-46.020167916992186, -23.803968526367196],
            [-46.014267916992182, -23.801968526367197],
            [-46.009167916992183, -23.800568526367197],
            [-46.005667916992188, -23.799768526367195],
            [-46.002367916992185, -23.799968526367195],
            [-46.000167916992183, -23.802568526367196],
            [-45.996667916992187, -23.802468526367196],
            [-45.993267916992188, -23.800368526367194],
            [-45.991967916992188, -23.796768526367195],
            [-45.991167916992183, -23.793368526367196],
            [-45.988067916992186, -23.791668526367197],
            [-45.985367916992182, -23.790568526367196],
            [-45.981767916992183, -23.788968526367196],
            [-45.977867916992182, -23.787268526367196],
            [-45.971367916992186, -23.784868526367198],
            [-45.966967916992182, -23.783468526367194],
            [-45.958667916992184, -23.781168526367196],
            [-45.951867916992185, -23.778968526367194],
            [-45.940767916992186, -23.775468526367199],
            [-45.926867916992187, -23.771868526367196],
            [-45.917267916992188, -23.769368526367195],
            [-45.909567916992188, -23.767868526367195],
            [-45.897167916992188, -23.765368526367197],
            [-45.892167916992186, -23.764668526367196],
            [-45.888567916992187, -23.763768526367194],
            [-45.885467916992184, -23.763168526367195],
            [-45.881367916992183, -23.762768526367196],
            [-45.876967916992186, -23.762368526367197],
            [-45.870867916992182, -23.760968526367197],
            [-45.865767916992183, -23.760268526367199],
            [-45.861667916992182, -23.759868526367196],
            [-45.855367916992186, -23.759268526367194],
            [-45.850267916992188, -23.758768526367199],
            [-45.845867916992184, -23.758468526367196],
            [-45.841967916992182, -23.757968526367197],
            [-45.832867916992186, -23.757668526367198],
            [-45.828967916992184, -23.757468526367198],
            [-45.822667916992188, -23.757368526367195],
            [-45.817367916992183, -23.757668526367198],
            [-45.812967916992186, -23.758068526367197],
            [-45.807667916992187, -23.759068526367194],
            [-45.803667916992183, -23.760668526367198],
            [-45.801867916992187, -23.763468526367198],
            [-45.803567916992186, -23.768168526367198],
            [-45.799967916992188, -23.769068526367196],
            [-45.796467916992185, -23.768968526367196],
            [-45.793367916992182, -23.769268526367195],
            [-45.789467916992187, -23.766068526367196],
            [-45.784267916992185, -23.765368526367197],
            [-45.778867916992183, -23.764668526367196],
            [-45.773167916992186, -23.762468526367197],
            [-45.769867916992183, -23.762368526367197],
            [-45.765767916992182, -23.762468526367197],
            [-45.762367916992183, -23.763568526367195],
            [-45.761067916992182, -23.766468526367198],
            [-45.757467916992184, -23.769668526367198],
            [-45.753767916992182, -23.768568526367197],
            [-45.749067916992182, -23.767568526367196],
            [-45.744067916992186, -23.767568526367196],
            [-45.740067916992189, -23.767568526367196],
            [-45.736167916992187, -23.768168526367198],
            [-45.732467916992185, -23.768668526367197],
            [-45.728867916992186, -23.769268526367195],
            [-45.725667916992187, -23.770068526367197],
            [-45.720667916992184, -23.772168526367196],
            [-45.716267916992187, -23.773168526367197],
            [-45.712567916992185, -23.771468526367194],
            [-45.708667916992184, -23.770968526367199],
            [-45.705667916992184, -23.772068526367196],
            [-45.701767916992182, -23.773168526367197],
            [-45.697667916992188, -23.773468526367196],
            [-45.694567916992185, -23.775668526367198],
            [-45.695967916992188, -23.779768526367196],
            [-45.694067916992182, -23.782368526367197],
            [-45.690567916992187, -23.780968526367197],
            [-45.686267916992186, -23.778168526367196],
            [-45.684067916992184, -23.775368526367195],
            [-45.680467916992185, -23.774568526367197],
            [-45.675867916992182, -23.774568526367197],
            [-45.671067916992186, -23.775068526367196],
            [-45.666767916992185, -23.776768526367196],
            [-45.664467916992187, -23.779868526367196],
            [-45.664467916992187, -23.784568526367195],
            [-45.662267916992185, -23.782668526367196],
            [-45.659467916992185, -23.780868526367197],
            [-45.655167916992184, -23.778468526367199],
            [-45.650167916992189, -23.778068526367196],
            [-45.646567916992183, -23.778668526367198],
            [-45.642667916992188, -23.778368526367196],
            [-45.641067916992185, -23.782368526367197],
            [-45.637667916992186, -23.783368526367198],
            [-45.633867916992187, -23.783468526367194],
            [-45.630167916992185, -23.784268526367196],
            [-45.625867916992185, -23.786968526367197],
            [-45.625967916992188, -23.791368526367194],
            [-45.624767916992184, -23.794968526367196],
            [-45.625567916992182, -23.798868526367194],
            [-45.624767916992184, -23.801868526367194],
            [-45.621667916992187, -23.803668526367197],
            [-45.617567916992186, -23.803268526367198],
            [-45.616067916992186, -23.799968526367195],
            [-45.611167916992187, -23.798868526367194],
            [-45.606767916992183, -23.799468526367196],
            [-45.601667916992184, -23.797768526367197],
            [-45.596767916992185, -23.797868526367196],
            [-45.593667916992182, -23.796068526367197],
            [-45.589867916992183, -23.795868526367194],
            [-45.587267916992182, -23.793068526367197],
            [-45.583167916992188, -23.792268526367195],
            [-45.579567916992183, -23.792268526367195],
            [-45.575467916992189, -23.792268526367195],
            [-45.571867916992183, -23.792168526367199],
            [-45.566567916992184, -23.791968526367196],
            [-45.562367916992187, -23.792068526367196],
            [-45.559167916992187, -23.792868526367194],
            [-45.556567916992186, -23.794168526367194],
            [-45.554667916992187, -23.796468526367196],
            [-45.555167916992183, -23.800368526367194],
            [-45.552767916992188, -23.802468526367196],
            [-45.552567916992189, -23.805468526367196],
            [-45.553567916992186, -23.809068526367199],
            [-45.549367916992182, -23.809668526367197],
            [-45.546167916992182, -23.809668526367197],
            [-45.542467916992187, -23.810268526367196],
            [-45.537867916992184, -23.814068526367194],
            [-45.534567916992188, -23.818268526367198],
            [-45.533467916992187, -23.822768526367199],
            [-45.535367916992186, -23.824868526367197],
            [-45.537867916992184, -23.827668526367198],
            [-45.536467916992187, -23.830368526367195],
            [-45.532567916992186, -23.828968526367198],
            [-45.528667916992184, -23.829568526367197],
            [-45.524067916992188, -23.829268526367194],
            [-45.520767916992185, -23.831868526367195],
            [-45.516267916992184, -23.832268526367194],
            [-45.512467916992186, -23.832568526367197],
            [-45.511567916992185, -23.836968526367194],
            [-45.513767916992187, -23.840168526367197],
            [-45.509367916992183, -23.840868526367196],
            [-45.503467916992186, -23.841668526367194],
            [-45.499867916992187, -23.839068526367196],
            [-45.496567916992184, -23.838368526367198],
            [-45.492567916992186, -23.837268526367197],
            [-45.491567916992182, -23.834368526367196],
            [-45.489467916992183, -23.830668526367198],
            [-45.484167916992185, -23.829568526367197],
            [-45.480267916992183, -23.828968526367198],
            [-45.477267916992183, -23.827368526367195],
            [-45.474467916992182, -23.824868526367197],
            [-45.469467916992187, -23.822468526367196],
            [-45.467267916992185, -23.819368526367196],
            [-45.462967916992184, -23.819868526367195],
            [-45.458967916992187, -23.821668526367198],
            [-45.455167916992188, -23.823468526367197],
            [-45.451567916992182, -23.825168526367197],
            [-45.447367916992185, -23.828568526367196],
            [-45.444667916992188, -23.831868526367195],
            [-45.441867916992187, -23.828868526367195],
            [-45.439067916992187, -23.826768526367196],
            [-45.434667916992183, -23.828168526367197],
            [-45.430467916992185, -23.831468526367196],
            [-45.425867916992182, -23.829568526367197],
            [-45.422467916992183, -23.828268526367197],
            [-45.420367916992184, -23.824968526367197],
            [-45.416667916992182, -23.823468526367197],
            [-45.412867916992184, -23.824268526367199],
            [-45.410567916992186, -23.822068526367197],
            [-45.407767916992185, -23.821068526367196],
            [-45.405867916992186, -23.818868526367197],
            [-45.408167916992184, -23.813768526367198],
            [-45.405667916992186, -23.810468526367195],
            [-45.401267916992182, -23.812668526367197],
            [-45.397567916992188, -23.809068526367199],
            [-45.396867916992186, -23.804668526367195],
            [-45.398167916992186, -23.799968526367195],
            [-45.399367916992183, -23.796368526367196],
            [-45.398667916992189, -23.792868526367194],
            [-45.397667916992184, -23.788668526367196],
            [-45.398367916992186, -23.784168526367196],
            [-45.397867916992183, -23.780068526367195],
            [-45.400367916992188, -23.776968526367199],
            [-45.401267916992182, -23.772968526367194],
            [-45.403067916992185, -23.768768526367197],
            [-45.405867916992186, -23.764668526367196],
            [-45.408667916992187, -23.760368526367195],
            [-45.411267916992188, -23.756068526367194],
            [-45.411667916992187, -23.751068526367199],
            [-45.409167916992182, -23.747668526367196],
            [-45.406167916992182, -23.743568526367199],
            [-45.402667916992186, -23.740768526367198],
            [-45.399767916992182, -23.736868526367196],
            [-45.398667916992189, -23.733168526367194],
            [-45.399267916992187, -23.730268526367198],
            [-45.397867916992183, -23.727468526367197],
            [-45.397567916992188, -23.723968526367194],
            [-45.400667916992184, -23.724868526367196],
            [-45.404267916992183, -23.725268526367195],
            [-45.408667916992187, -23.725068526367195],
            [-45.413067916992183, -23.724268526367197],
            [-45.416167916992187, -23.725868526367197],
            [-45.419267916992183, -23.725368526367198],
            [-45.421367916992182, -23.722568526367198],
            [-45.422867916992182, -23.718768526367196],
            [-45.424467916992185, -23.714868526367198],
            [-45.425567916992186, -23.711868526367198],
            [-45.429767916992184, -23.712268526367197],
            [-45.427567916992189, -23.708768526367194],
            [-45.426967916992183, -23.705668526367198],
            [-45.427967916992188, -23.700668526367195],
            [-45.428867916992182, -23.695968526367196],
            [-45.429467916992188, -23.691668526367195],
            [-45.430067916992186, -23.687668526367197],
            [-45.430267916992186, -23.684168526367195],
            [-45.430467916992185, -23.680468526367196],
            [-45.430467916992185, -23.676568526367198],
            [-45.430467916992185, -23.673568526367198],
            [-45.430067916992186, -23.669568526367197],
            [-45.429667916992187, -23.665268526367196],
            [-45.428967916992185, -23.662068526367197],
            [-45.428367916992187, -23.658668526367194],
            [-45.427267916992186, -23.654868526367196],
            [-45.426067916992189, -23.651268526367197],
            [-45.424667916992185, -23.647168526367196],
            [-45.422867916992182, -23.643268526367194],
            [-45.421367916992182, -23.640168526367198],
            [-45.419767916992186, -23.637668526367197],
            [-45.418067916992186, -23.635168526367195],
            [-45.415667916992184, -23.632368526367195],
            [-45.413067916992183, -23.629168526367195],
            [-45.409867916992184, -23.626268526367198],
            [-45.405167916992184, -23.623368526367194],
            [-45.400767916992187, -23.623268526367198],
            [-45.398267916992182, -23.625768526367196],
            [-45.395767916992185, -23.629868526367197],
            [-45.392067916992183, -23.631068526367194],
            [-45.389667916992188, -23.633068526367197],
            [-45.386367916992185, -23.633068526367197],
            [-45.383867916992187, -23.629868526367197],
            [-45.381767916992182, -23.627668526367195],
            [-45.379167916992188, -23.625268526367197],
            [-45.375767916992189, -23.625468526367197],
            [-45.373767916992186, -23.627668526367195],
            [-45.369767916992188, -23.629468526367198],
            [-45.366667916992185, -23.627368526367196],
            [-45.363267916992186, -23.625768526367196],
            [-45.359967916992183, -23.625468526367197],
            [-45.357467916992185, -23.624068526367196],
            [-45.355067916992184, -23.619768526367196],
            [-45.353067916992188, -23.616168526367197],
            [-45.351467916992185, -23.613368526367196],
            [-45.349867916992189, -23.610368526367196],
            [-45.346967916992185, -23.605868526367196],
            [-45.344167916992184, -23.601668526367199],
            [-45.340867916992188, -23.597368526367198],
            [-45.337867916992188, -23.593968526367199],
            [-45.334267916992182, -23.590468526367196],
            [-45.330767916992187, -23.587268526367197],
            [-45.328167916992186, -23.585068526367195],
            [-45.325767916992184, -23.583168526367196],
            [-45.322467916992188, -23.581068526367197],
            [-45.319267916992182, -23.579268526367194],
            [-45.315167916992188, -23.577868526367197],
            [-45.310867916992187, -23.575668526367195],
            [-45.307267916992188, -23.575968526367198],
            [-45.304067916992182, -23.574568526367194],
            [-45.299967916992188, -23.573168526367198],
            [-45.295667916992187, -23.572368526367196],
            [-45.292867916992186, -23.574268526367199],
            [-45.288667916992182, -23.572968526367198],
            [-45.284867916992184, -23.572368526367196],
            [-45.281467916992185, -23.572768526367199],
            [-45.278667916992184, -23.574068526367196],
            [-45.275967916992187, -23.576268526367194],
            [-45.277267916992187, -23.580968526367194],
            [-45.279267916992183, -23.583468526367199],
            [-45.278367916992188, -23.587068526367197],
            [-45.272867916992183, -23.586768526367194],
            [-45.269367916992188, -23.587968526367199],
            [-45.265067916992187, -23.588368526367198],
            [-45.261067916992182, -23.592368526367196],
            [-45.258167916992186, -23.590568526367196],
            [-45.254967916992186, -23.589768526367195],
            [-45.252667916992188, -23.592668526367198],
            [-45.248767916992186, -23.592568526367195],
            [-45.244067916992186, -23.592568526367195],
            [-45.241167916992183, -23.588168526367198],
            [-45.237167916992185, -23.584768526367196],
            [-45.233367916992187, -23.582668526367197],
            [-45.229467916992185, -23.582168526367198],
            [-45.225567916992183, -23.583268526367196],
            [-45.222367916992184, -23.581868526367195],
            [-45.218167916992186, -23.581568526367196],
            [-45.214467916992184, -23.581168526367197],
            [-45.211567916992188, -23.582568526367197],
            [-45.209267916992182, -23.578868526367195],
            [-45.210067916992188, -23.574668526367198],
            [-45.210867916992186, -23.571568526367194],
            [-45.213767916992182, -23.568868526367197],
            [-45.212267916992182, -23.565168526367195],
            [-45.216167916992184, -23.564568526367196],
            [-45.218367916992186, -23.561868526367196],
            [-45.217867916992184, -23.558068526367194],
            [-45.220567916992188, -23.555068526367194],
            [-45.223667916992184, -23.551568526367198],
            [-45.226667916992184, -23.551168526367196],
            [-45.229667916992184, -23.550268526367198],
            [-45.229767916992188, -23.545068526367196],
            [-45.228867916992186, -23.541768526367196],
            [-45.227867916992182, -23.538468526367197],
            [-45.225267916992188, -23.533768526367197],
            [-45.220867916992184, -23.529768526367196],
            [-45.215367916992186, -23.527668526367197],
            [-45.211267916992185, -23.524468526367194],
            [-45.206467916992182, -23.522168526367196],
            [-45.202067916992185, -23.520768526367196],
            [-45.197367916992185, -23.520168526367197],
            [-45.194267916992182, -23.520368526367196],
            [-45.190767916992186, -23.521568526367197],
            [-45.190167916992188, -23.524368526367198],
            [-45.187267916992184, -23.527668526367197],
            [-45.187667916992183, -23.530968526367197],
            [-45.188567916992184, -23.534268526367196],
            [-45.185867916992187, -23.538768526367196],
            [-45.180867916992185, -23.538068526367198],
            [-45.176867916992187, -23.539568526367198],
            [-45.173867916992187, -23.542268526367195],
            [-45.169967916992185, -23.539868526367197],
            [-45.165567916992188, -23.541068526367198],
            [-45.162367916992189, -23.541368526367194],
            [-45.159467916992185, -23.538068526367198],
            [-45.158967916992182, -23.533468526367194],
            [-45.158467916992187, -23.530068526367195],
            [-45.162067916992186, -23.531568526367195],
            [-45.165867916992184, -23.529268526367197],
            [-45.166767916992185, -23.525068526367196],
            [-45.165667916992184, -23.520468526367196],
            [-45.164467916992187, -23.516368526367195],
            [-45.167167916992184, -23.512968526367196],
            [-45.170767916992183, -23.512168526367198],
            [-45.173167916992185, -23.510168526367195],
            [-45.172767916992186, -23.506568526367197],
            [-45.172467916992183, -23.502368526367196],
            [-45.174767916992188, -23.499368526367196],
            [-45.172767916992186, -23.495468526367198],
            [-45.168967916992187, -23.492968526367196],
            [-45.164967916992182, -23.494168526367197],
            [-45.163167916992187, -23.497968526367195],
            [-45.159767916992188, -23.499668526367195],
            [-45.157367916992186, -23.497768526367196],
            [-45.153667916992184, -23.498668526367197],
            [-45.149567916992183, -23.497768526367196],
            [-45.145067916992183, -23.497668526367196],
            [-45.143667916992186, -23.500868526367196],
            [-45.138767916992187, -23.501068526367199],
            [-45.134967916992188, -23.504568526367194],
            [-45.134167916992183, -23.509068526367194],
            [-45.130967916992184, -23.511268526367196],
            [-45.127967916992183, -23.514068526367197],
            [-45.124067916992182, -23.515368526367197],
            [-45.121567916992184, -23.517068526367197],
            [-45.119367916992182, -23.520168526367197],
            [-45.114167916992187, -23.519368526367195],
            [-45.113067916992186, -23.523268526367197],
            [-45.112267916992188, -23.526168526367197],
            [-45.107767916992188, -23.524368526367198],
            [-45.107267916992186, -23.520368526367196],
            [-45.108267916992183, -23.515668526367197],
            [-45.109967916992183, -23.511768526367199],
            [-45.107767916992188, -23.508768526367199],
            [-45.112767916992183, -23.507368526367195],
            [-45.118067916992182, -23.505168526367196],
            [-45.122767916992188, -23.502668526367195],
            [-45.119167916992183, -23.500568526367196],
            [-45.115367916992184, -23.497668526367196],
            [-45.109967916992183, -23.494768526367196],
            [-45.106767916992183, -23.490768526367198],
            [-45.103567916992183, -23.490768526367198],
            [-45.103167916992184, -23.496268526367196],
            [-45.099567916992186, -23.494068526367194],
            [-45.095967916992187, -23.492468526367198],
            [-45.092067916992185, -23.492668526367197],
            [-45.088967916992182, -23.493268526367196],
            [-45.085767916992182, -23.494168526367197],
            [-45.083667916992184, -23.496168526367196],
            [-45.083767916992187, -23.499468526367195],
            [-45.084567916992185, -23.502368526367196],
            [-45.085067916992188, -23.505268526367196],
            [-45.085367916992183, -23.508768526367199],
            [-45.085667916992186, -23.512468526367197],
            [-45.084767916992185, -23.516068526367196],
            [-45.081867916992188, -23.518668526367197],
            [-45.080667916992184, -23.513868526367197],
            [-45.079567916992183, -23.508868526367195],
            [-45.076767916992182, -23.505968526367198],
            [-45.076467916992186, -23.502668526367195],
            [-45.073767916992182, -23.500168526367197],
            [-45.071367916992187, -23.497468526367197],
            [-45.068067916992185, -23.493368526367199],
            [-45.072367916992185, -23.493068526367196],
            [-45.074067916992185, -23.489368526367194],
            [-45.073167916992183, -23.484468526367195],
            [-45.070967916992188, -23.479468526367196],
            [-45.069067916992182, -23.476168526367196],
            [-45.066967916992184, -23.473068526367197],
            [-45.064067916992187, -23.469268526367195],
            [-45.061267916992186, -23.466968526367197],
            [-45.058267916992186, -23.466968526367197],
            [-45.056167916992187, -23.465068526367197],
            [-45.052767916992188, -23.466168526367198],
            [-45.048667916992187, -23.463168526367198],
            [-45.045067916992188, -23.461468526367199],
            [-45.039467916992187, -23.462268526367197],
            [-45.035567916992186, -23.464068526367196],
            [-45.032267916992183, -23.464768526367195],
            [-45.028967916992187, -23.462268526367197],
            [-45.030367916992184, -23.459368526367196],
            [-45.034467916992185, -23.458468526367199],
            [-45.035867916992188, -23.453868526367195],
            [-45.036667916992187, -23.449268526367199],
            [-45.040267916992185, -23.448468526367197],
            [-45.043367916992182, -23.449568526367194],
            [-45.046967916992187, -23.452368526367195],
            [-45.050067916992184, -23.455968526367194],
            [-45.053967916992185, -23.458168526367196],
            [-45.057967916992183, -23.457868526367196],
            [-45.060767916992184, -23.455368526367195],
            [-45.063767916992184, -23.451868526367196],
            [-45.066567916992184, -23.447968526367198],
            [-45.068167916992188, -23.443768526367194],
            [-45.069067916992182, -23.439568526367196],
            [-45.069267916992182, -23.436268526367197],
            [-45.068767916992186, -23.433068526367194],
            [-45.065167916992188, -23.431668526367197],
            [-45.060767916992184, -23.430468526367196],
            [-45.062767916992186, -23.427468526367196],
            [-45.064167916992183, -23.424968526367195],
            [-45.062967916992186, -23.422168526367194],
            [-45.059867916992182, -23.418968526367195],
            [-45.056567916992186, -23.417468526367195],
            [-45.053067916992184, -23.416768526367196],
            [-45.049667916992185, -23.417268526367195],
            [-45.046667916992185, -23.418468526367196],
            [-45.045867916992187, -23.421468526367196],
            [-45.047467916992183, -23.424668526367196],
            [-45.044267916992183, -23.425068526367198],
            [-45.042167916992184, -23.422468526367197],
            [-45.039167916992184, -23.421768526367195],
            [-45.037767916992188, -23.418468526367196],
            [-45.034467916992185, -23.415368526367196],
            [-45.029867916992188, -23.414468526367195],
            [-45.025467916992184, -23.414168526367199],
            [-45.022567916992188, -23.415268526367196],
            [-45.022567916992188, -23.418168526367197],
            [-45.019367916992188, -23.418468526367196],
            [-45.014367916992185, -23.418068526367197],
            [-45.011867916992188, -23.414268526367195],
            [-45.009367916992183, -23.412468526367196],
            [-45.008767916992184, -23.408868526367197],
            [-45.006267916992186, -23.404068526367197],
            [-45.001667916992183, -23.401568526367196],
            [-44.997467916992186, -23.399468526367194],
            [-44.992667916992183, -23.397868526367198],
            [-44.988367916992182, -23.398668526367196],
            [-44.984467916992187, -23.397168526367196],
            [-44.981067916992188, -23.399268526367194],
            [-44.976367916992189, -23.398768526367196],
            [-44.974167916992187, -23.395668526367196],
            [-44.971667916992182, -23.392968526367198],
            [-44.970967916992187, -23.388968526367197],
            [-44.967567916992188, -23.386768526367199],
            [-44.963667916992186, -23.385968526367197],
            [-44.960867916992186, -23.381768526367196],
            [-44.958467916992184, -23.379568526367194],
            [-44.955467916992184, -23.379068526367199],
            [-44.951867916992185, -23.379168526367195],
            [-44.949267916992184, -23.377668526367195],
            [-44.949867916992183, -23.373368526367194],
            [-44.949267916992184, -23.369968526367195],
            [-44.948167916992183, -23.366668526367196],
            [-44.947367916992185, -23.363268526367197],
            [-44.943867916992183, -23.360068526367197],
            [-44.940967916992186, -23.356768526367198],
            [-44.936967916992188, -23.355268526367198],
            [-44.934167916992187, -23.354268526367196],
            [-44.929767916992184, -23.353168526367199],
            [-44.924767916992188, -23.352368526367197],
            [-44.920667916992187, -23.350268526367195],
            [-44.917167916992184, -23.347368526367198],
            [-44.913467916992182, -23.343068526367198],
            [-44.911467916992187, -23.337968526367199],
            [-44.908467916992187, -23.335068526367195],
            [-44.904267916992183, -23.334268526367197],
            [-44.899867916992186, -23.335068526367195],
            [-44.895167916992186, -23.336268526367196],
            [-44.890667916992186, -23.337968526367199],
            [-44.887367916992183, -23.339568526367195],
            [-44.884567916992182, -23.343068526367198],
            [-44.882767916992186, -23.346368526367197],
            [-44.882167916992188, -23.349468526367197],
            [-44.885367916992188, -23.352768526367196],
            [-44.888167916992188, -23.355668526367197],
            [-44.889667916992188, -23.359268526367195],
            [-44.887867916992185, -23.362468526367195],
            [-44.888467916992184, -23.366368526367197],
            [-44.891567916992187, -23.369668526367196],
            [-44.888967916992186, -23.371868526367194],
            [-44.884967916992188, -23.368668526367195],
            [-44.883067916992182, -23.364668526367197],
            [-44.879967916992186, -23.363268526367197],
            [-44.875467916992186, -23.363568526367196],
            [-44.872667916992185, -23.361468526367197],
            [-44.870267916992184, -23.359168526367196],
            [-44.866467916992185, -23.357768526367195],
            [-44.862467916992188, -23.357568526367196],
            [-44.857867916992184, -23.358868526367196],
            [-44.853067916992188, -23.360068526367197],
            [-44.848667916992184, -23.361368526367194],
            [-44.844567916992183, -23.363068526367197],
            [-44.841767916992183, -23.364968526367196],
            [-44.839467916992184, -23.366968526367195],
            [-44.837067916992183, -23.370768526367197],
            [-44.838967916992182, -23.375768526367196],
            [-44.839467916992184, -23.379468526367198],
            [-44.842967916992187, -23.382968526367197],
            [-44.843667916992182, -23.386868526367195],
            [-44.840167916992186, -23.387468526367197],
            [-44.835467916992187, -23.386768526367199],
            [-44.831267916992182, -23.385368526367195],
            [-44.829067916992187, -23.387368526367197],
            [-44.826467916992186, -23.385968526367197],
            [-44.822467916992188, -23.382768526367194],
            [-44.818267916992184, -23.381268526367194],
            [-44.813067916992182, -23.380268526367196],
            [-44.808767916992188, -23.379368526367195],
            [-44.804767916992184, -23.378368526367197],
            [-44.801367916992184, -23.375468526367197],
            [-44.800767916992186, -23.371168526367196],
            [-44.796667916992185, -23.369368526367197],
            [-44.791967916992185, -23.369668526367196],
            [-44.788167916992187, -23.370568526367194],
            [-44.784767916992188, -23.370868526367197],
            [-44.783467916992187, -23.375968526367195],
            [-44.780467916992187, -23.376268526367198],
            [-44.777267916992187, -23.374868526367194],
            [-44.773467916992182, -23.373768526367197],
            [-44.768967916992182, -23.372368526367197],
            [-44.766067916992185, -23.372368526367197],
            [-44.763467916992184, -23.370868526367197],
            [-44.759967916992188, -23.369968526367195],
            [-44.756767916992182, -23.368668526367195],
            [-44.752967916992183, -23.367268526367198],
            [-44.747967916992188, -23.366568526367196],
            [-44.743267916992188, -23.366168526367197],
            [-44.739067916992184, -23.366968526367195],
            [-44.733967916992185, -23.369068526367194],
            [-44.728967916992183, -23.369068526367194],
            [-44.724767916992185, -23.368268526367196],
            [-44.727567916992186, -23.366068526367194],
            [-44.731467916992187, -23.361168526367194],
            [-44.729967916992187, -23.355668526367197],
            [-44.725267916992188, -23.354668526367199],
            [-44.721267916992183, -23.356668526367194],
            [-44.720967916992187, -23.353668526367194],
            [-44.721667916992182, -23.349968526367196],
            [-44.717867916992184, -23.346268526367197],
            [-44.711867916992183, -23.344268526367195],
            [-44.707867916992186, -23.343068526367198],
            [-44.704567916992183, -23.342668526367198],
            [-44.702867916992183, -23.340368526367197],
            [-44.699967916992186, -23.340968526367199],
            [-44.697867916992188, -23.343368526367197],
            [-44.694267916992182, -23.343068526367198],
            [-44.691067916992182, -23.344568526367198],
            [-44.690167916992188, -23.347268526367195],
            [-44.685767916992184, -23.347568526367198],
            [-44.685167916992185, -23.350668526367194],
            [-44.682567916992184, -23.349468526367197],
            [-44.682167916992185, -23.346268526367197],
            [-44.683767916992188, -23.342968526367194],
            [-44.681867916992182, -23.339868526367198],
            [-44.678067916992184, -23.338368526367198],
            [-44.674967916992188, -23.336868526367198],
            [-44.673567916992184, -23.340668526367196],
            [-44.672767916992186, -23.344468526367194],
            [-44.667467916992187, -23.345968526367194],
            [-44.663067916992183, -23.346468526367197],
            [-44.659467916992185, -23.345968526367194],
            [-44.662567916992188, -23.343068526367198],
            [-44.661767916992183, -23.339068526367196],
            [-44.657567916992186, -23.341268526367195],
            [-44.653767916992187, -23.342268526367196],
            [-44.651167916992186, -23.340568526367196],
            [-44.648967916992184, -23.336968526367194],
            [-44.646567916992183, -23.333968526367194],
            [-44.642867916992188, -23.333168526367196],
            [-44.638867916992183, -23.331468526367196],
            [-44.634567916992182, -23.333268526367196],
            [-44.630967916992184, -23.335468526367194],
            [-44.629067916992184, -23.338768526367197],
            [-44.627667916992188, -23.342268526367196],
            [-44.624067916992182, -23.339368526367195],
            [-44.620167916992187, -23.340868526367196],
            [-44.617667916992183, -23.345168526367196],
            [-44.616567916992182, -23.349868526367196],
            [-44.613567916992189, -23.347868526367197],
            [-44.610367916992182, -23.348368526367196],
            [-44.606967916992183, -23.349468526367197],
            [-44.607467916992185, -23.353368526367198],
            [-44.608167916992187, -23.357468526367196],
            [-44.607767916992188, -23.360368526367196],
            [-44.607267916992186, -23.364968526367196],
            [-44.603967916992183, -23.365268526367196],
            [-44.603167916992184, -23.362268526367195],
            [-44.600267916992188, -23.359968526367197],
            [-44.595967916992187, -23.358968526367196],
            [-44.592067916992185, -23.361768526367197],
            [-44.589067916992185, -23.361168526367194],
            [-44.586167916992189, -23.359668526367194],
            [-44.582867916992186, -23.357268526367196],
            [-44.580367916992188, -23.354668526367199],
            [-44.578567916992185, -23.351468526367196],
            [-44.575667916992188, -23.349168526367194],
            [-44.572467916992188, -23.345668526367199],
            [-44.570467916992186, -23.342368526367196],
            [-44.567767916992182, -23.338368526367198],
            [-44.565567916992187, -23.335468526367194],
            [-44.563767916992184, -23.332968526367196],
            [-44.561967916992188, -23.329968526367196],
            [-44.562967916992186, -23.326068526367195],
            [-44.565567916992187, -23.322968526367198],
            [-44.564067916992187, -23.320268526367194],
            [-44.561567916992182, -23.316568526367199],
            [-44.560767916992184, -23.311368526367197],
            [-44.557767916992184, -23.308068526367194],
            [-44.556067916992184, -23.304968526367198],
            [-44.551967916992183, -23.304368526367199],
            [-44.550067916992184, -23.307168526367196],
            [-44.548267916992188, -23.304668526367195],
            [-44.545267916992188, -23.302968526367195],
            [-44.541767916992185, -23.303068526367198],
            [-44.540267916992185, -23.300468526367197],
            [-44.538967916992185, -23.296768526367195],
            [-44.535967916992185, -23.293068526367197],
            [-44.531267916992185, -23.290368526367196],
            [-44.528767916992187, -23.287568526367195],
            [-44.525667916992184, -23.287768526367195],
            [-44.523767916992185, -23.290068526367197],
            [-44.520167916992186, -23.290968526367195],
            [-44.516767916992187, -23.291768526367196],
            [-44.513467916992184, -23.292068526367196],
            [-44.513867916992183, -23.288768526367196],
            [-44.515367916992183, -23.285868526367196],
            [-44.519367916992188, -23.283468526367194],
            [-44.518767916992182, -23.280068526367195],
            [-44.521467916992187, -23.278768526367195],
            [-44.524367916992183, -23.276468526367196],
            [-44.526867916992188, -23.273168526367197],
            [-44.529467916992182, -23.271768526367197],
            [-44.533067916992188, -23.270368526367196],
            [-44.535667916992182, -23.268968526367196],
            [-44.538867916992189, -23.266868526367197],
            [-44.542267916992188, -23.269968526367194],
            [-44.546067916992186, -23.273268526367197],
            [-44.549767916992188, -23.273668526367196],
            [-44.552467916992185, -23.271168526367198],
            [-44.557267916992188, -23.272568526367195],
            [-44.561967916992188, -23.272268526367196],
            [-44.565967916992186, -23.271568526367197],
            [-44.569067916992182, -23.271268526367194],
            [-44.572467916992188, -23.271068526367195],
            [-44.576367916992183, -23.269368526367195],
            [-44.579667916992186, -23.270468526367196],
            [-44.583267916992185, -23.267668526367196],
            [-44.584367916992186, -23.263768526367194],
            [-44.581867916992188, -23.262068526367194],
            [-44.581267916992182, -23.258868526367195],
            [-44.579267916992187, -23.256068526367194],
            [-44.576267916992187, -23.253468526367197],
            [-44.575467916992189, -23.249368526367196],
            [-44.577667916992183, -23.246868526367194],
            [-44.575667916992188, -23.243668526367195],
            [-44.572467916992188, -23.240768526367198],
            [-44.569667916992188, -23.238368526367196],
            [-44.567367916992183, -23.236168526367194],
            [-44.564167916992183, -23.233868526367196],
            [-44.560767916992184, -23.230968526367196],
            [-44.560167916992185, -23.227368526367197],
            [-44.563867916992187, -23.228868526367194],
            [-44.567467916992186, -23.229968526367195],
            [-44.570967916992188, -23.230368526367194],
            [-44.574067916992185, -23.232868526367199],
            [-44.577967916992186, -23.232868526367199],
            [-44.581767916992185, -23.232768526367195],
            [-44.586467916992184, -23.232568526367196],
            [-44.591267916992187, -23.232768526367195],
            [-44.594067916992188, -23.233868526367196],
            [-44.597767916992183, -23.236568526367197],
            [-44.596767916992185, -23.241068526367194],
            [-44.597367916992184, -23.244168526367197],
            [-44.600567916992183, -23.246968526367198],
            [-44.605267916992183, -23.249668526367195],
            [-44.608567916992186, -23.251568526367194],
            [-44.611367916992187, -23.252068526367196],
            [-44.614967916992185, -23.252968526367198],
            [-44.617567916992186, -23.255968526367198],
            [-44.620567916992186, -23.259168526367198],
            [-44.621867916992187, -23.263968526367197],
            [-44.623367916992187, -23.267868526367195],
            [-44.625567916992182, -23.270068526367197],
            [-44.627967916992183, -23.274368526367198],
            [-44.629367916992187, -23.278168526367196],
            [-44.630967916992184, -23.282268526367197],
            [-44.633267916992182, -23.284768526367195],
            [-44.635367916992188, -23.286968526367197],
            [-44.638567916992187, -23.289468526367195],
            [-44.642367916992185, -23.287868526367198],
            [-44.645367916992186, -23.289468526367195],
            [-44.642967916992184, -23.293868526367199],
            [-44.639867916992188, -23.297168526367194],
            [-44.638067916992185, -23.300768526367197],
            [-44.641867916992183, -23.301668526367195],
            [-44.646467916992187, -23.301168526367196],
            [-44.648367916992186, -23.297468526367197],
            [-44.650867916992183, -23.294968526367196],
            [-44.655867916992186, -23.293168526367197],
            [-44.654067916992183, -23.288468526367197],
            [-44.650067916992185, -23.286668526367194],
            [-44.648967916992184, -23.283868526367197],
            [-44.646567916992183, -23.280968526367197],
            [-44.642667916992188, -23.277568526367197],
            [-44.640967916992182, -23.274468526367194],
            [-44.640367916992183, -23.271468526367194],
            [-44.637767916992182, -23.269268526367195],
            [-44.635667916992183, -23.264968526367198],
            [-44.633867916992187, -23.259668526367197],
            [-44.631367916992183, -23.255268526367196],
            [-44.628267916992186, -23.251668526367197],
            [-44.626267916992184, -23.248568526367194],
            [-44.624967916992183, -23.244668526367196],
            [-44.620267916992184, -23.242168526367195],
            [-44.618067916992182, -23.238868526367199],
            [-44.615367916992184, -23.236868526367196],
            [-44.613567916992189, -23.234168526367196],
            [-44.617267916992184, -23.233568526367197],
            [-44.620767916992186, -23.235268526367197],
            [-44.624467916992188, -23.236768526367197],
            [-44.630267916992182, -23.239368526367194],
            [-44.636067916992182, -23.238568526367196],
            [-44.639567916992185, -23.234468526367195],
            [-44.644267916992185, -23.235268526367197],
            [-44.647867916992183, -23.235268526367197],
            [-44.652067916992188, -23.235368526367196],
            [-44.654767916992185, -23.238668526367196],
            [-44.658367916992184, -23.239668526367197],
            [-44.660567916992186, -23.242968526367196],
            [-44.663467916992182, -23.246968526367198],
            [-44.668067916992186, -23.248268526367198],
            [-44.669167916992187, -23.245268526367198],
            [-44.672467916992183, -23.246968526367198],
            [-44.674167916992182, -23.249668526367195],
            [-44.678067916992184, -23.251068526367199],
            [-44.682967916992183, -23.252468526367196],
            [-44.685167916992185, -23.250568526367196],
            [-44.680767916992188, -23.246368526367196],
            [-44.683267916992186, -23.242168526367195],
            [-44.679067916992182, -23.242268526367198],
            [-44.675767916992186, -23.242268526367198],
            [-44.673567916992184, -23.239668526367197],
            [-44.677167916992182, -23.239168526367195],
            [-44.676167916992185, -23.236068526367198],
            [-44.674367916992182, -23.233868526367196],
            [-44.672267916992183, -23.230968526367196],
            [-44.669667916992182, -23.233568526367197],
            [-44.667067916992188, -23.234968526367197],
            [-44.663867916992189, -23.233168526367194],
            [-44.660567916992186, -23.231368526367199],
            [-44.658067916992188, -23.229168526367197],
            [-44.655467916992187, -23.225868526367197],
            [-44.658767916992183, -23.225968526367197],
            [-44.662767916992188, -23.226368526367196],
            [-44.659567916992188, -23.222068526367195],
            [-44.660367916992186, -23.218768526367196],
            [-44.662067916992186, -23.215568526367196],
            [-44.658167916992184, -23.215068526367197],
            [-44.654067916992183, -23.217568526367195],
            [-44.651467916992182, -23.221268526367197],
            [-44.648667916992189, -23.219868526367197],
            [-44.646767916992182, -23.217568526367195],
            [-44.643767916992182, -23.216168526367198],
            [-44.639367916992185, -23.213668526367197],
            [-44.638967916992186, -23.217268526367196],
            [-44.633767916992184, -23.217268526367196],
            [-44.632767916992186, -23.220968526367194],
            [-44.629667916992183, -23.223468526367196],
            [-44.626667916992183, -23.223168526367196],
            [-44.624667916992188, -23.219768526367197],
            [-44.624067916992182, -23.215668526367196],
            [-44.626567916992187, -23.214068526367196],
            [-44.624767916992184, -23.210368526367198],
            [-44.622967916992188, -23.207568526367197],
            [-44.619767916992188, -23.204968526367196],
            [-44.622967916992188, -23.203568526367196],
            [-44.626667916992183, -23.201768526367196],
            [-44.626567916992187, -23.197968526367198],
            [-44.630567916992185, -23.196268526367199],
            [-44.635767916992187, -23.196068526367196],
            [-44.640067916992187, -23.196268526367199],
            [-44.643167916992184, -23.196368526367195],
            [-44.645367916992186, -23.193268526367198],
            [-44.644367916992188, -23.187368526367194],
            [-44.649067916992188, -23.187668526367197],
            [-44.653367916992188, -23.189168526367197],
            [-44.654567916992185, -23.192368526367197],
            [-44.655567916992183, -23.196268526367199],
            [-44.657267916992183, -23.200268526367196],
            [-44.657267916992183, -23.203968526367198],
            [-44.659767916992188, -23.207568526367197],
            [-44.663467916992182, -23.206468526367196],
            [-44.665967916992187, -23.209068526367197],
            [-44.668067916992186, -23.206468526367196],
            [-44.671367916992182, -23.210368526367198],
            [-44.676567916992184, -23.212668526367196],
            [-44.673867916992187, -23.215868526367196],
            [-44.677567916992189, -23.219568526367198],
            [-44.681167916992187, -23.220968526367194],
            [-44.683867916992185, -23.223368526367196],
            [-44.688367916992185, -23.221968526367196],
            [-44.691967916992184, -23.225368526367198],
            [-44.694167916992185, -23.230368526367194],
            [-44.697467916992188, -23.232768526367195],
            [-44.701067916992187, -23.233068526367198],
            [-44.705767916992187, -23.233868526367196],
            [-44.709767916992185, -23.234468526367195],
            [-44.713167916992184, -23.233568526367197],
            [-44.714767916992187, -23.229568526367196],
            [-44.712567916992185, -23.227068526367198],
            [-44.713367916992183, -23.222768526367197],
            [-44.709867916992188, -23.219768526367197],
            [-44.710067916992188, -23.216668526367194],
            [-44.711367916992188, -23.212668526367196],
            [-44.714567916992188, -23.210968526367196],
            [-44.715967916992184, -23.208468526367199],
            [-44.718667916992182, -23.205368526367195],
            [-44.720567916992188, -23.202068526367196],
            [-44.720567916992188, -23.196268526367199],
            [-44.718067916992183, -23.193868526367197],
            [-44.715067916992183, -23.194168526367196],
            [-44.713967916992182, -23.190568526367194],
            [-44.710867916992186, -23.190168526367195],
            [-44.708667916992184, -23.188268526367196],
            [-44.707167916992184, -23.185268526367196],
            [-44.710667916992186, -23.184868526367197],
            [-44.713767916992182, -23.186868526367196],
            [-44.716267916992187, -23.183268526367197],
            [-44.712967916992184, -23.182668526367195],
            [-44.711167916992189, -23.180168526367197],
            [-44.709767916992185, -23.176468526367195],
            [-44.705367916992188, -23.174768526367195],
            [-44.702067916992185, -23.175868526367196],
            [-44.698967916992189, -23.173568526367198],
            [-44.698567916992182, -23.169668526367197],
            [-44.703467916992182, -23.168668526367199],
            [-44.706767916992185, -23.165668526367199],
            [-44.706767916992185, -23.162468526367196],
            [-44.706867916992188, -23.158868526367197],
            [-44.705467916992184, -23.156168526367196],
            [-44.701467916992186, -23.155368526367198],
            [-44.697667916992188, -23.154268526367197],
            [-44.695967916992188, -23.150668526367198],
            [-44.695167916992183, -23.145968526367199],
            [-44.697767916992184, -23.142568526367196],
            [-44.697967916992184, -23.139068526367197],
            [-44.698767916992182, -23.135968526367197],
            [-44.699267916992184, -23.132068526367195],
            [-44.695167916992183, -23.130768526367198],
            [-44.691567916992184, -23.130468526367196],
            [-44.689167916992183, -23.128568526367197],
            [-44.689867916992185, -23.124368526367196],
            [-44.694367916992185, -23.122968526367195],
            [-44.697367916992185, -23.123368526367194],
            [-44.698867916992185, -23.119968526367195],
            [-44.698167916992183, -23.114668526367197],
            [-44.696367916992187, -23.110368526367196],
            [-44.694367916992185, -23.106768526367198],
            [-44.695267916992186, -23.102768526367196],
            [-44.694567916992185, -23.097068526367195],
            [-44.692167916992183, -23.092268526367196],
            [-44.688567916992184, -23.090168526367197],
            [-44.689167916992183, -23.086768526367194],
            [-44.690467916992183, -23.082868526367196],
            [-44.687767916992186, -23.081768526367195],
            [-44.685867916992187, -23.078768526367199],
            [-44.686067916992187, -23.074068526367196],
            [-44.682467916992188, -23.071768526367194],
            [-44.677267916992186, -23.070268526367194],
            [-44.677267916992186, -23.066068526367197],
            [-44.676367916992184, -23.061268526367197],
            [-44.672867916992182, -23.058268526367197],
            [-44.670067916992188, -23.056568526367194],
            [-44.667867916992186, -23.054368526367199],
            [-44.664167916992184, -23.053568526367194],
            [-44.661167916992184, -23.055468526367196],
            [-44.658067916992188, -23.053068526367198],
            [-44.654567916992185, -23.054468526367195],
            [-44.650367916992188, -23.055468526367196],
            [-44.647567916992188, -23.052768526367196],
            [-44.647667916992184, -23.049468526367196],
            [-44.644867916992183, -23.046968526367195],
            [-44.641867916992183, -23.047768526367197],
            [-44.637867916992185, -23.048668526367194],
            [-44.636467916992189, -23.051468526367195],
            [-44.631367916992183, -23.049968526367195],
            [-44.626267916992184, -23.047468526367197],
            [-44.621567916992184, -23.044968526367196],
            [-44.616467916992185, -23.044268526367198],
            [-44.612967916992183, -23.047868526367196],
            [-44.609367916992184, -23.048568526367198],
            [-44.604967916992187, -23.048168526367196],
            [-44.600067916992188, -23.047468526367197],
            [-44.597067916992188, -23.045268526367195],
            [-44.593167916992186, -23.046768526367195],
            [-44.590067916992183, -23.050068526367198],
            [-44.590867916992188, -23.053568526367194],
            [-44.593367916992186, -23.055868526367199],
            [-44.594067916992188, -23.059068526367199],
            [-44.591167916992184, -23.058568526367196],
            [-44.587967916992184, -23.058868526367196],
            [-44.585067916992188, -23.058068526367194],
            [-44.582567916992183, -23.055768526367196],
            [-44.578567916992185, -23.054168526367196],
            [-44.576867916992185, -23.050268526367198],
            [-44.574867916992183, -23.047868526367196],
            [-44.571667916992183, -23.047768526367197],
            [-44.568767916992186, -23.046468526367196],
            [-44.566567916992184, -23.043868526367199],
            [-44.562967916992186, -23.042768526367198],
            [-44.561067916992187, -23.040068526367197],
            [-44.557167916992185, -23.037268526367196],
            [-44.552567916992189, -23.037468526367196],
            [-44.549367916992182, -23.035968526367196],
            [-44.547167916992187, -23.034168526367196],
            [-44.544267916992183, -23.032368526367197],
            [-44.540867916992184, -23.031268526367196],
            [-44.535867916992188, -23.029168526367194],
            [-44.531167916992182, -23.027368526367198],
            [-44.528167916992182, -23.025168526367196],
            [-44.525067916992185, -23.025868526367198],
            [-44.521467916992187, -23.026768526367196],
            [-44.517367916992185, -23.026468526367196],
            [-44.513267916992184, -23.026168526367197],
            [-44.508867916992187, -23.026468526367196],
            [-44.506367916992183, -23.029268526367197],
            [-44.502967916992183, -23.028168526367196],
            [-44.499367916992185, -23.025068526367196],
            [-44.495467916992183, -23.023668526367196],
            [-44.492567916992186, -23.020768526367196],
            [-44.489667916992182, -23.020068526367197],
            [-44.487167916992185, -23.016268526367199],
            [-44.484267916992188, -23.010468526367198],
            [-44.480567916992186, -23.006668526367196],
            [-44.475667916992187, -23.006768526367196],
            [-44.471667916992182, -23.008868526367195],
            [-44.469067916992188, -23.011068526367197],
            [-44.464867916992183, -23.009268526367194],
            [-44.461567916992188, -23.008468526367196],
            [-44.458767916992187, -23.011368526367196],
            [-44.457867916992186, -23.016268526367199],
            [-44.452667916992183, -23.015968526367196],
            [-44.450667916992188, -23.018168526367198],
            [-44.449367916992188, -23.021468526367194],
            [-44.447967916992184, -23.025068526367196],
            [-44.445767916992182, -23.029168526367194],
            [-44.442767916992182, -23.026268526367197],
            [-44.439367916992182, -23.024268526367194],
            [-44.437167916992188, -23.021068526367195],
            [-44.440467916992183, -23.019768526367194],
            [-44.441567916992184, -23.016068526367196],
            [-44.445167916992183, -23.012468526367197],
            [-44.443767916992186, -23.008268526367196],
            [-44.442667916992185, -23.005468526367196],
            [-44.438767916992184, -23.002468526367196],
            [-44.434067916992184, -23.000968526367195],
            [-44.430867916992185, -22.998568526367194],
            [-44.427567916992189, -22.999368526367196],
            [-44.425767916992186, -22.997168526367197],
            [-44.429167916992185, -22.994368526367197],
            [-44.433267916992186, -22.994368526367197],
            [-44.437967916992186, -22.995668526367197],
            [-44.440267916992184, -22.992168526367195],
            [-44.436267916992186, -22.988568526367196],
            [-44.435467916992188, -22.985368526367196],
            [-44.436067916992187, -22.982468526367196],
            [-44.434367916992187, -22.979468526367196],
            [-44.431367916992187, -22.977768526367196],
            [-44.432967916992183, -22.975268526367195],
            [-44.436567916992182, -22.973468526367196],
            [-44.436867916992185, -22.970168526367196],
            [-44.435467916992188, -22.966968526367197],
            [-44.434067916992184, -22.963468526367194],
            [-44.427867916992184, -22.961868526367198],
            [-44.427467916992185, -22.958268526367196],
            [-44.425567916992186, -22.956168526367197],
            [-44.423167916992185, -22.953468526367196],
            [-44.421667916992185, -22.949668526367198],
            [-44.418867916992184, -22.947668526367195],
            [-44.415667916992184, -22.944368526367196],
            [-44.412367916992189, -22.941868526367195],
            [-44.409567916992188, -22.943268526367198],
            [-44.409867916992184, -22.946668526367198],
            [-44.412567916992188, -22.948268526367194],
            [-44.414567916992183, -22.952668526367198],
            [-44.410867916992188, -22.953568526367196],
            [-44.408067916992188, -22.951768526367196],
            [-44.404267916992183, -22.951568526367197],
            [-44.400067916992185, -22.951468526367197],
            [-44.399867916992186, -22.954368526367197],
            [-44.397967916992187, -22.957068526367195],
            [-44.396267916992187, -22.953268526367197],
            [-44.396267916992187, -22.949868526367197],
            [-44.392867916992188, -22.952468526367198],
            [-44.388967916992186, -22.951768526367196],
            [-44.386267916992182, -22.952868526367197],
            [-44.382667916992183, -22.955168526367196],
            [-44.378267916992186, -22.958468526367199],
            [-44.380267916992182, -22.962968526367199],
            [-44.384567916992182, -22.966268526367195],
            [-44.386067916992182, -22.969168526367199],
            [-44.383067916992182, -22.971368526367197],
            [-44.380467916992188, -22.967368526367196],
            [-44.377767916992184, -22.968768526367196],
            [-44.374467916992188, -22.966468526367194],
            [-44.374467916992188, -22.962568526367196],
            [-44.372367916992182, -22.959268526367197],
            [-44.367667916992183, -22.960068526367195],
            [-44.364167916992187, -22.957868526367196],
            [-44.362167916992185, -22.955668526367198],
            [-44.358967916992185, -22.956868526367195],
            [-44.356367916992184, -22.954068526367195],
            [-44.352467916992182, -22.953568526367196],
            [-44.353067916992188, -22.949868526367197],
            [-44.351667916992184, -22.947168526367197],
            [-44.350067916992188, -22.944568526367195],
            [-44.353867916992186, -22.943868526367197],
            [-44.357567916992188, -22.943768526367194],
            [-44.360567916992188, -22.941668526367195],
            [-44.364367916992187, -22.942668526367196],
            [-44.366167916992183, -22.946068526367196],
            [-44.369167916992183, -22.947868526367195],
            [-44.370167916992187, -22.941868526367195],
            [-44.370867916992182, -22.938268526367196],
            [-44.366967916992188, -22.934868526367197],
            [-44.362267916992188, -22.932268526367196],
            [-44.365567916992184, -22.930168526367197],
            [-44.362867916992187, -22.928068526367198],
            [-44.359967916992183, -22.924468526367196],
            [-44.357767916992188, -22.926968526367197],
            [-44.355267916992183, -22.929368526367199],
            [-44.352467916992182, -22.930268526367197],
            [-44.350267916992188, -22.927268526367197],
            [-44.347567916992183, -22.924168526367197],
            [-44.343667916992182, -22.922868526367196],
            [-44.339067916992185, -22.922868526367196],
            [-44.337267916992182, -22.926068526367196],
            [-44.332567916992183, -22.927468526367196],
            [-44.328267916992182, -22.931668526367197],
            [-44.324067916992185, -22.932468526367195],
            [-44.324867916992183, -22.935868526367194],
            [-44.321367916992187, -22.936168526367197],
            [-44.321567916992187, -22.939068526367194],
            [-44.323167916992183, -22.942068526367194],
            [-44.327467916992184, -22.943568526367194],
            [-44.330767916992187, -22.945168526367198],
            [-44.335067916992188, -22.947368526367196],
            [-44.336467916992184, -22.949868526367197],
            [-44.336267916992185, -22.953168526367197],
            [-44.333167916992188, -22.951468526367197],
            [-44.329267916992187, -22.955068526367196],
            [-44.332867916992186, -22.955968526367194],
            [-44.336167916992189, -22.957668526367197],
            [-44.336167916992189, -22.961568526367195],
            [-44.335867916992186, -22.964468526367199],
            [-44.332367916992183, -22.966468526367194],
            [-44.330067916992185, -22.964768526367195],
            [-44.328767916992184, -22.961468526367199],
            [-44.325467916992189, -22.960368526367198],
            [-44.321367916992187, -22.960668526367197],
            [-44.319567916992185, -22.957268526367194],
            [-44.313867916992187, -22.957868526367196],
            [-44.309367916992187, -22.957668526367197],
            [-44.307967916992183, -22.960168526367198],
            [-44.303867916992182, -22.957068526367195],
            [-44.300567916992186, -22.958468526367199],
            [-44.302467916992185, -22.960868526367197],
            [-44.305767916992188, -22.962068526367197],
            [-44.307467916992188, -22.964368526367195],
            [-44.307967916992183, -22.967268526367196],
            [-44.310767916992184, -22.970868526367198],
            [-44.311867916992185, -22.976668526367199],
            [-44.312767916992186, -22.979968526367195],
            [-44.315267916992184, -22.982868526367199],
            [-44.317367916992183, -22.984968526367197],
            [-44.320467916992186, -22.985768526367195],
            [-44.323167916992183, -22.983368526367194],
            [-44.326767916992182, -22.982768526367195],
            [-44.329367916992183, -22.985368526367196],
            [-44.327567916992187, -22.989968526367196],
            [-44.326067916992187, -22.992968526367196],
            [-44.328567916992185, -22.995468526367198],
            [-44.332367916992183, -22.995168526367195],
            [-44.335967916992182, -22.992268526367198],
            [-44.340367916992186, -22.990768526367198],
            [-44.345267916992185, -22.991068526367194],
            [-44.348367916992188, -22.994068526367194],
            [-44.352067916992183, -22.995768526367197],
            [-44.354167916992182, -22.998268526367198],
            [-44.355667916992182, -23.002368526367196],
            [-44.357767916992188, -23.004868526367197],
            [-44.356767916992183, -23.007768526367194],
            [-44.360367916992182, -23.009868526367196],
            [-44.361067916992184, -23.013468526367198],
            [-44.362567916992184, -23.017668526367196],
            [-44.359267916992188, -23.019268526367195],
            [-44.358067916992184, -23.022568526367195],
            [-44.353867916992186, -23.021868526367196],
            [-44.350267916992188, -23.023668526367196],
            [-44.349267916992183, -23.026968526367199],
            [-44.350267916992188, -23.030168526367198],
            [-44.346667916992182, -23.028368526367196],
            [-44.344767916992183, -23.026268526367197],
            [-44.342567916992188, -23.024268526367194],
            [-44.339467916992184, -23.022168526367196],
            [-44.335967916992182, -23.023968526367199],
            [-44.331167916992186, -23.020968526367199],
            [-44.327167916992188, -23.020368526367196],
            [-44.328267916992182, -23.015968526367196],
            [-44.326067916992187, -23.014068526367197],
            [-44.322367916992185, -23.014368526367196],
            [-44.319367916992185, -23.011368526367196],
            [-44.315567916992187, -23.013468526367198],
            [-44.315967916992186, -23.008868526367195],
            [-44.311867916992185, -23.007068526367195],
            [-44.306867916992182, -23.005968526367198],
            [-44.301867916992187, -23.003768526367196],
            [-44.298567916992184, -23.006868526367196],
            [-44.297567916992186, -23.010468526367198],
            [-44.299467916992185, -23.014268526367196],
            [-44.302967916992188, -23.017168526367197],
            [-44.304667916992187, -23.022168526367196],
            [-44.302567916992189, -23.024568526367197],
            [-44.297867916992182, -23.025668526367198],
            [-44.293567916992188, -23.024768526367197],
            [-44.290567916992188, -23.023968526367199],
            [-44.289767916992183, -23.020768526367196],
            [-44.288167916992187, -23.017468526367196],
            [-44.284767916992188, -23.015068526367195],
            [-44.280567916992183, -23.015368526367197],
            [-44.278067916992185, -23.011468526367196],
            [-44.274867916992186, -23.008768526367199],
            [-44.273967916992184, -23.005168526367196],
            [-44.270467916992182, -23.002968526367198],
            [-44.267067916992183, -23.001668526367197],
            [-44.263567916992187, -23.003568526367197],
            [-44.261767916992184, -23.006768526367196],
            [-44.258267916992182, -23.005168526367196],
            [-44.256567916992182, -23.002368526367196],
            [-44.253067916992187, -22.999368526367196],
            [-44.249467916992188, -22.999068526367196],
            [-44.246267916992188, -23.000568526367196],
            [-44.241567916992182, -23.001568526367194],
            [-44.238867916992184, -23.003768526367196],
            [-44.237167916992185, -23.007368526367195],
            [-44.234267916992188, -23.009268526367194],
            [-44.232267916992186, -23.011768526367199],
            [-44.229667916992184, -23.013768526367194],
            [-44.226967916992187, -23.011768526367199],
            [-44.223667916992184, -23.011068526367197],
            [-44.221167916992187, -23.015068526367195],
            [-44.223767916992188, -23.018168526367198],
            [-44.225067916992188, -23.020768526367196],
            [-44.228067916992188, -23.021768526367197],
            [-44.231367916992184, -23.022368526367195],
            [-44.233867916992182, -23.025968526367194],
            [-44.237167916992185, -23.028368526367196],
            [-44.236767916992186, -23.031968526367194],
            [-44.240267916992188, -23.033668526367194],
            [-44.241067916992186, -23.037568526367195],
            [-44.243367916992185, -23.041668526367197],
            [-44.246267916992188, -23.045068526367196],
            [-44.249767916992184, -23.048568526367198],
            [-44.246567916992184, -23.049368526367196],
            [-44.243667916992187, -23.053668526367197],
            [-44.240867916992187, -23.055268526367197],
            [-44.236767916992186, -23.055868526367199],
            [-44.231967916992183, -23.053368526367194],
            [-44.227067916992183, -23.050768526367197],
            [-44.222867916992186, -23.048868526367194],
            [-44.219867916992186, -23.049368526367196],
            [-44.215867916992188, -23.047468526367197],
            [-44.211267916992185, -23.045268526367195],
            [-44.208267916992185, -23.043568526367196],
            [-44.204267916992187, -23.041368526367194],
            [-44.201367916992183, -23.038168526367194],
            [-44.197667916992188, -23.039168526367199],
            [-44.194067916992182, -23.041468526367197],
            [-44.193567916992187, -23.044468526367197],
            [-44.195567916992182, -23.047868526367196],
            [-44.194867916992187, -23.053068526367198],
            [-44.189867916992185, -23.052568526367196],
            [-44.185467916992188, -23.052468526367196],
            [-44.185167916992185, -23.049168526367197],
            [-44.182167916992185, -23.045868526367194],
            [-44.179667916992187, -23.048868526367194],
            [-44.176167916992185, -23.049668526367196],
            [-44.173567916992184, -23.048368526367199],
            [-44.171467916992185, -23.045568526367198],
            [-44.173067916992188, -23.043068526367197],
            [-44.176067916992189, -23.040868526367198],
            [-44.176367916992184, -23.037768526367195],
            [-44.174667916992185, -23.035268526367197],
            [-44.171967916992187, -23.033468526367194],
            [-44.168667916992185, -23.033368526367198],
            [-44.165567916992188, -23.032368526367197],
            [-44.160367916992186, -23.032368526367197],
            [-44.154867916992188, -23.036168526367199],
            [-44.152267916992187, -23.039568526367198],
            [-44.150467916992184, -23.036968526367197],
            [-44.145667916992188, -23.035568526367197],
            [-44.141067916992185, -23.034868526367198],
            [-44.137467916992186, -23.033468526367194],
            [-44.134167916992183, -23.033068526367195],
            [-44.129867916992183, -23.031768526367195],
            [-44.126667916992183, -23.028968526367194],
            [-44.123167916992188, -23.025868526367198],
            [-44.118667916992187, -23.025068526367196],
            [-44.114467916992183, -23.023268526367197],
            [-44.111367916992187, -23.020468526367196],
            [-44.108867916992182, -23.017068526367197],
            [-44.105267916992183, -23.014668526367196],
            [-44.103967916992183, -23.011568526367196],
            [-44.101667916992184, -23.009368526367197],
            [-44.098367916992188, -23.007468526367198],
            [-44.096167916992187, -23.004968526367197],
            [-44.095567916992188, -23.002168526367196],
            [-44.095867916992184, -22.997968526367195],
            [-44.092567916992188, -22.994168526367197],
            [-44.087267916992182, -22.992568526367194],
            [-44.083467916992184, -22.992968526367196],
            [-44.081867916992188, -22.989768526367197],
            [-44.080967916992186, -22.986368526367194],
            [-44.079067916992187, -22.981368526367199],
            [-44.077967916992186, -22.976968526367195],
            [-44.075967916992184, -22.973668526367199],
            [-44.076367916992183, -22.970068526367196],
            [-44.079967916992182, -22.967568526367195],
            [-44.080767916992187, -22.963268526367195],
            [-44.080767916992187, -22.959768526367196],
            [-44.079267916992187, -22.956868526367195],
            [-44.077067916992185, -22.954368526367197],
            [-44.073767916992182, -22.951868526367196],
            [-44.070667916992186, -22.950968526367198],
            [-44.066667916992188, -22.950268526367196],
            [-44.061967916992188, -22.948968526367196],
            [-44.059467916992183, -22.945668526367196],
            [-44.054767916992184, -22.944168526367196],
            [-44.052267916992186, -22.942068526367194],
            [-44.047867916992182, -22.941668526367195],
            [-44.044467916992183, -22.944168526367196],
            [-44.040667916992184, -22.947968526367198],
            [-44.040067916992186, -22.951468526367197],
            [-44.042867916992186, -22.953568526367196],
            [-44.045267916992188, -22.955968526367194],
            [-44.043367916992182, -22.959668526367196],
            [-44.042167916992184, -22.963168526367198],
            [-44.044667916992182, -22.965868526367196],
            [-44.046767916992188, -22.969768526367197],
            [-44.049667916992185, -22.971768526367196],
            [-44.052767916992188, -22.973968526367194],
            [-44.054667916992187, -22.977768526367196],
            [-44.055767916992188, -22.980568526367197],
            [-44.052267916992186, -22.982168526367197],
            [-44.048667916992187, -22.983668526367197],
            [-44.045267916992188, -22.984468526367195],
            [-44.041367916992186, -22.983668526367197],
            [-44.036967916992182, -22.981968526367197],
            [-44.033367916992184, -22.980568526367197],
            [-44.032367916992186, -22.976368526367196],
            [-44.032367916992186, -22.972668526367194],
            [-44.032267916992183, -22.969568526367198],
            [-44.031267916992185, -22.966668526367194],
            [-44.029867916992188, -22.964068526367196],
            [-44.025467916992184, -22.962568526367196],
            [-44.022367916992188, -22.960968526367196],
            [-44.022067916992185, -22.955768526367194],
            [-44.018267916992187, -22.952968526367197],
            [-44.013867916992183, -22.950968526367198],
            [-44.011767916992184, -22.947368526367196],
            [-44.009267916992187, -22.943868526367197],
            [-44.005267916992182, -22.942368526367197],
            [-44.000167916992183, -22.941968526367198],
            [-43.994967916992188, -22.941268526367196],
            [-43.990067916992189, -22.938768526367198],
            [-43.985667916992185, -22.934968526367197],
            [-43.981667916992187, -22.932968526367198],
            [-43.977767916992185, -22.931568526367194],
            [-43.974267916992183, -22.931568526367194],
            [-43.970967916992187, -22.932168526367196],
            [-43.967567916992188, -22.933368526367197],
            [-43.964067916992185, -22.933768526367196],
            [-43.960167916992184, -22.932168526367196],
            [-43.955667916992184, -22.928968526367196],
            [-43.951367916992183, -22.927468526367196],
            [-43.948167916992183, -22.927168526367197],
            [-43.945167916992183, -22.928068526367198],
            [-43.940567916992187, -22.930068526367194],
            [-43.936567916992182, -22.929968526367198],
            [-43.933367916992182, -22.929068526367196],
            [-43.930467916992185, -22.929368526367199],
            [-43.927567916992189, -22.929168526367196],
            [-43.924167916992182, -22.929768526367198],
            [-43.920067916992188, -22.929668526367195],
            [-43.914867916992186, -22.930268526367197],
            [-43.910267916992183, -22.930768526367196],
            [-43.907267916992183, -22.929668526367195],
            [-43.904067916992183, -22.928268526367198],
            [-43.901567916992185, -22.926168526367196],
            [-43.897567916992188, -22.924968526367195],
            [-43.893467916992186, -22.922768526367197],
            [-43.889967916992184, -22.922168526367194],
            [-43.885667916992183, -22.921968526367195],
            [-43.881867916992185, -22.920268526367195],
            [-43.880567916992185, -22.915268526367196],
            [-43.880767916992184, -22.911468526367194],
            [-43.877967916992183, -22.910368526367197],
            [-43.874867916992187, -22.907668526367196],
            [-43.871967916992183, -22.905968526367197],
            [-43.868267916992188, -22.904768526367196],
            [-43.865067916992189, -22.903468526367199],
            [-43.860867916992184, -22.902668526367197],
            [-43.855267916992183, -22.902068526367195],
            [-43.851367916992189, -22.903768526367195],
            [-43.846667916992182, -22.902368526367198],
            [-43.842267916992185, -22.901768526367196],
            [-43.839267916992185, -22.905068526367195],
            [-43.839067916992185, -22.910368526367197],
            [-43.841967916992182, -22.913868526367196],
            [-43.845067916992186, -22.916668526367197],
            [-43.849467916992182, -22.917168526367199],
            [-43.853067916992188, -22.918568526367196],
            [-43.850667916992187, -22.921068526367197],
            [-43.850667916992187, -22.926068526367196],
            [-43.848967916992187, -22.929668526367195],
            [-43.844567916992183, -22.929668526367195],
            [-43.842067916992185, -22.931868526367197],
            [-43.836567916992188, -22.931568526367194],
            [-43.833667916992184, -22.931168526367195],
            [-43.817367916992183, -22.920668526367194],
            [-43.813067916992182, -22.919968526367196],
            [-43.809367916992187, -22.917768526367198],
            [-43.806867916992182, -22.919268526367198],
            [-43.808367916992182, -22.923168526367196],
            [-43.802467916992185, -22.923968526367197],
            [-43.801467916992188, -22.920268526367195],
            [-43.800767916992186, -22.917268526367195],
            [-43.798067916992188, -22.916368526367194],
            [-43.795367916992184, -22.918068526367197],
            [-43.792767916992183, -22.921868526367199],
            [-43.789167916992184, -22.924968526367195],
            [-43.784167916992182, -22.926168526367196],
            [-43.780167916992184, -22.928268526367198],
            [-43.776167916992186, -22.931368526367194],
            [-43.771767916992182, -22.932768526367198],
            [-43.768267916992187, -22.933268526367197],
            [-43.766067916992185, -22.935868526367194],
            [-43.763167916992188, -22.937168526367195],
            [-43.760167916992188, -22.938468526367195],
            [-43.757467916992184, -22.939668526367196],
            [-43.754567916992187, -22.941268526367196],
            [-43.750967916992188, -22.943568526367194],
            [-43.746867916992187, -22.945968526367196],
            [-43.744067916992186, -22.948468526367197],
            [-43.741167916992183, -22.950668526367195],
            [-43.736867916992182, -22.953268526367197],
            [-43.733367916992187, -22.955668526367198],
            [-43.730267916992183, -22.958468526367199],
            [-43.725567916992183, -22.960168526367198],
            [-43.721467916992182, -22.963768526367197],
            [-43.719267916992187, -22.966268526367195],
            [-43.718167916992186, -22.970168526367196],
            [-43.714467916992184, -22.969468526367194],
            [-43.709667916992188, -22.971668526367196],
            [-43.705367916992188, -22.976668526367199],
            [-43.701867916992185, -22.982268526367196],
            [-43.699867916992183, -22.985768526367195],
            [-43.696367916992187, -22.985568526367196],
            [-43.693467916992184, -22.986368526367194],
            [-43.690267916992184, -22.989368526367194],
            [-43.686867916992185, -22.985568526367196],
            [-43.682767916992184, -22.983268526367198],
            [-43.678567916992186, -22.980668526367197],
            [-43.674667916992185, -22.980568526367197],
            [-43.670667916992187, -22.982068526367197],
            [-43.668167916992182, -22.983668526367197],
            [-43.664767916992183, -22.986068526367198],
            [-43.659767916992188, -22.990768526367198],
            [-43.656967916992187, -22.993868526367194],
            [-43.655167916992184, -22.997468526367197],
            [-43.652067916992188, -23.001368526367195],
            [-43.648467916992182, -23.001268526367198],
            [-43.644367916992188, -23.001568526367194],
            [-43.639567916992185, -23.004868526367197],
            [-43.635467916992184, -23.005968526367198],
            [-43.630967916992184, -23.005968526367198],
            [-43.627067916992182, -23.007068526367195],
            [-43.623767916992186, -23.008468526367196],
            [-43.620767916992186, -23.011068526367197],
            [-43.617967916992185, -23.013868526367197],
            [-43.614967916992185, -23.016868526367197],
            [-43.611067916992184, -23.019368526367195],
            [-43.607767916992188, -23.021068526367195],
            [-43.603967916992183, -23.021868526367196],
            [-43.599167916992187, -23.024568526367197],
            [-43.599167916992187, -23.027668526367197],
            [-43.603167916992184, -23.027868526367197],
            [-43.604267916992185, -23.030668526367194],
            [-43.608267916992183, -23.030868526367197],
            [-43.611367916992187, -23.031168526367196],
            [-43.615267916992188, -23.031268526367196],
            [-43.620067916992184, -23.032868526367196],
            [-43.623567916992187, -23.033368526367198],
            [-43.626567916992187, -23.033468526367194],
            [-43.630267916992182, -23.033668526367194],
            [-43.633867916992187, -23.034468526367196],
            [-43.638567916992187, -23.035568526367197],
            [-43.641867916992183, -23.035868526367196],
            [-43.647067916992185, -23.036968526367197],
            [-43.651567916992185, -23.038068526367198],
            [-43.655967916992182, -23.038968526367196],
            [-43.659767916992188, -23.040368526367196],
            [-43.663967916992185, -23.041168526367194],
            [-43.667067916992188, -23.042768526367198],
            [-43.670367916992184, -23.043968526367195],
            [-43.673567916992184, -23.044668526367197],
            [-43.677767916992188, -23.045368526367199],
            [-43.681567916992186, -23.046368526367196],
            [-43.686567916992182, -23.047868526367196],
            [-43.691967916992184, -23.049368526367196],
            [-43.695667916992186, -23.050568526367197],
            [-43.699567916992187, -23.051168526367196],
            [-43.703267916992182, -23.052168526367197],
            [-43.706867916992188, -23.054468526367195],
            [-43.703267916992182, -23.055768526367196],
            [-43.700267916992182, -23.055468526367196],
            [-43.696767916992187, -23.054668526367195],
            [-43.693267916992184, -23.054668526367195],
            [-43.689067916992187, -23.054468526367195],
            [-43.684767916992186, -23.054368526367199],
            [-43.680467916992185, -23.053968526367196],
            [-43.675467916992183, -23.053568526367194],
            [-43.672467916992183, -23.053368526367194],
            [-43.666467916992183, -23.052868526367199],
            [-43.658067916992188, -23.052268526367197],
            [-43.651467916992182, -23.051668526367195],
            [-43.644267916992185, -23.051068526367196],
            [-43.636767916992184, -23.050468526367197],
            [-43.630267916992182, -23.050268526367198],
            [-43.624667916992188, -23.050068526367198],
            [-43.618267916992188, -23.050268526367198],
            [-43.611767916992186, -23.050068526367198],
            [-43.608367916992187, -23.050268526367198],
            [-43.605067916992184, -23.050568526367197],
            [-43.598967916992187, -23.051168526367196],
            [-43.595967916992187, -23.051668526367195],
            [-43.591267916992187, -23.052468526367196],
            [-43.585367916992183, -23.053568526367194],
            [-43.581867916992188, -23.054668526367195],
            [-43.577467916992184, -23.055868526367199],
            [-43.574567916992187, -23.057668526367195],
            [-43.572067916992182, -23.059068526367199],
            [-43.569667916992188, -23.060868526367194],
            [-43.566867916992187, -23.063068526367196],
            [-43.568567916992187, -23.066068526367197],
            [-43.567667916992185, -23.068868526367197],
            [-43.568067916992185, -23.073168526367198],
            [-43.565467916992183, -23.074968526367197],
            [-43.561367916992182, -23.074868526367197],
            [-43.556967916992186, -23.073568526367197],
            [-43.553267916992183, -23.074868526367197],
            [-43.550067916992184, -23.073168526367198],
            [-43.552267916992186, -23.071068526367196],
            [-43.551567916992184, -23.068068526367199],
            [-43.549267916992186, -23.063768526367198],
            [-43.547467916992183, -23.060468526367195],
            [-43.544267916992183, -23.059068526367199],
            [-43.540667916992184, -23.057468526367195],
            [-43.537467916992185, -23.055868526367199],
            [-43.535667916992182, -23.053268526367198],
            [-43.534267916992185, -23.050368526367194],
            [-43.530167916992184, -23.049468526367196],
            [-43.525667916992184, -23.048868526367194],
            [-43.521767916992182, -23.048568526367198],
            [-43.516767916992187, -23.048568526367198],
            [-43.512767916992182, -23.048568526367198],
            [-43.509867916992185, -23.047768526367197],
            [-43.507067916992185, -23.044468526367197],
            [-43.504167916992188, -23.040568526367196],
            [-43.500467916992186, -23.040268526367196],
            [-43.497967916992188, -23.037568526367195],
            [-43.494667916992185, -23.036268526367195],
            [-43.489967916992185, -23.034768526367195],
            [-43.486167916992187, -23.033668526367194],
            [-43.479467916992185, -23.032068526367198],
            [-43.474467916992182, -23.031668526367199],
            [-43.469867916992186, -23.034168526367196],
            [-43.468167916992186, -23.030368526367198],
            [-43.463467916992187, -23.028068526367196],
            [-43.457867916992186, -23.026168526367197],
            [-43.454067916992187, -23.025068526367196],
            [-43.446267916992184, -23.022968526367194],
            [-43.439167916992183, -23.021468526367194],
            [-43.433267916992186, -23.020368526367196],
            [-43.428267916992183, -23.019368526367195],
            [-43.423867916992187, -23.018268526367194],
            [-43.419767916992186, -23.017568526367196],
            [-43.414267916992188, -23.016768526367194],
            [-43.408667916992187, -23.015768526367197],
            [-43.404467916992182, -23.015168526367198],
            [-43.398667916992189, -23.014268526367196],
            [-43.395667916992188, -23.013868526367197],
            [-43.391867916992183, -23.013468526367198],
            [-43.386767916992184, -23.012968526367196],
            [-43.381567916992182, -23.012468526367197],
            [-43.377467916992188, -23.012168526367198],
            [-43.373767916992186, -23.012068526367194],
            [-43.370467916992183, -23.011768526367199],
            [-43.365467916992188, -23.011468526367196],
            [-43.360267916992186, -23.011268526367196],
            [-43.356667916992187, -23.011068526367197],
            [-43.352067916992183, -23.011068526367197],
            [-43.348067916992186, -23.011068526367197],
            [-43.342367916992188, -23.011068526367197],
            [-43.339067916992185, -23.011068526367197],
            [-43.336167916992189, -23.011368526367196],
            [-43.331267916992182, -23.011768526367199],
            [-43.327867916992183, -23.012168526367198],
            [-43.324367916992188, -23.012668526367197],
            [-43.318167916992188, -23.013868526367197],
            [-43.315167916992188, -23.014368526367196],
            [-43.309367916992187, -23.015668526367197],
            [-43.305767916992188, -23.016068526367196],
            [-43.302767916992188, -23.016268526367199],
            [-43.299667916992185, -23.015968526367196],
            [-43.296767916992188, -23.016068526367196],
            [-43.292767916992183, -23.015768526367197],
            [-43.287767916992188, -23.015168526367198],
            [-43.286967916992182, -23.010468526367198],
            [-43.283867916992186, -23.007768526367194],
            [-43.280367916992184, -23.006068526367194],
            [-43.276967916992184, -23.005168526367196],
            [-43.275367916992188, -23.002768526367198],
            [-43.271167916992184, -23.001268526367198],
            [-43.265367916992183, -23.000168526367197],
            [-43.260167916992188, -22.999768526367198],
            [-43.255967916992184, -22.999768526367198],
            [-43.252367916992185, -22.999168526367196],
            [-43.249367916992185, -22.999468526367195],
            [-43.246567916992184, -23.000568526367196],
            [-43.243267916992188, -23.000768526367196],
            [-43.240367916992184, -22.999768526367198],
            [-43.237967916992183, -22.998068526367199],
            [-43.233567916992186, -22.995168526367195],
            [-43.229167916992182, -22.990768526367198],
            [-43.223867916992184, -22.987768526367198],
            [-43.220367916992188, -22.987168526367196],
            [-43.215667916992182, -22.987168526367196],
            [-43.210767916992182, -22.987168526367196],
            [-43.207067916992187, -22.987168526367196],
            [-43.203167916992186, -22.987468526367195],
            [-43.198967916992189, -22.987768526367198],
            [-43.194567916992185, -22.988568526367196],
            [-43.190567916992187, -22.989368526367194],
            [-43.187167916992188, -22.986668526367197],
            [-43.189067916992187, -22.981368526367199],
            [-43.187267916992184, -22.976768526367195],
            [-43.184367916992187, -22.973368526367196],
            [-43.181067916992184, -22.970868526367198],
            [-43.177967916992188, -22.968968526367199],
            [-43.174667916992185, -22.967368526367196],
            [-43.171367916992182, -22.965868526367196],
            [-43.167867916992186, -22.964068526367196],
            [-43.164167916992184, -22.964568526367195],
            [-43.160867916992188, -22.964868526367198],
            [-43.159567916992188, -22.961968526367194],
            [-43.160667916992182, -22.957968526367196],
            [-43.163867916992189, -22.955168526367196],
            [-43.160167916992187, -22.953168526367197],
            [-43.155467916992187, -22.952668526367198],
            [-43.151267916992182, -22.949868526367197],
            [-43.154567916992185, -22.947068526367197],
            [-43.154567916992185, -22.942468526367197],
            [-43.153067916992185, -22.939568526367196],
            [-43.158067916992188, -22.942368526367197],
            [-43.161667916992187, -22.943468526367198],
            [-43.163867916992189, -22.947068526367197],
            [-43.167767916992183, -22.949968526367197],
            [-43.170367916992184, -22.951768526367196],
            [-43.175867916992182, -22.949668526367198],
            [-43.179667916992187, -22.947368526367196],
            [-43.178267916992183, -22.943168526367195],
            [-43.173767916992183, -22.943468526367198],
            [-43.169767916992186, -22.940468526367198],
            [-43.169167916992187, -22.936368526367197],
            [-43.171167916992182, -22.932268526367196],
            [-43.170367916992184, -22.928568526367194],
            [-43.169167916992187, -22.925268526367198],
            [-43.167767916992183, -22.922268526367198],
            [-43.168667916992185, -22.919268526367198],
            [-43.171867916992184, -22.919268526367198],
            [-43.172467916992183, -22.916368526367194],
            [-43.169267916992183, -22.915868526367198],
            [-43.165267916992185, -22.916368526367194],
            [-43.161467916992187, -22.915568526367196],
            [-43.159167916992182, -22.913668526367196],
            [-43.161167916992184, -22.910668526367196],
            [-43.162067916992186, -22.904768526367196],
            [-43.165567916992188, -22.904468526367197],
            [-43.168967916992187, -22.904468526367197],
            [-43.172167916992187, -22.902268526367195],
            [-43.175467916992183, -22.899068526367195],
            [-43.176367916992184, -22.896068526367195],
            [-43.179667916992187, -22.895168526367197],
            [-43.183567916992182, -22.894368526367195],
            [-43.186367916992182, -22.893568526367197],
            [-43.190567916992187, -22.892068526367197],
            [-43.194567916992185, -22.892068526367197],
            [-43.197967916992184, -22.892868526367195],
            [-43.201067916992187, -22.894268526367195],
            [-43.203967916992184, -22.895368526367196],
            [-43.210967916992182, -22.896468526367194],
            [-43.213467916992187, -22.891868526367197],
            [-43.215167916992186, -22.888268526367199],
            [-43.215067916992183, -22.885368526367195],
            [-43.210867916992186, -22.881568526367197],
            [-43.208167916992188, -22.878568526367197],
            [-43.204067916992187, -22.874368526367196],
            [-43.203267916992182, -22.871568526367199],
            [-43.206767916992185, -22.869368526367197],
            [-43.210767916992182, -22.867968526367196],
            [-43.213767916992182, -22.869468526367196],
            [-43.214767916992187, -22.865068526367196],
            [-43.217667916992184, -22.861968526367196],
            [-43.213167916992184, -22.859668526367194],
            [-43.215467916992182, -22.857468526367196],
            [-43.219267916992187, -22.858668526367197],
            [-43.223367916992188, -22.858268526367198],
            [-43.226967916992187, -22.855668526367197],
            [-43.229567916992188, -22.852368526367197],
            [-43.231667916992187, -22.848768526367195],
            [-43.231667916992187, -22.844868526367197],
            [-43.229267916992185, -22.843068526367198],
            [-43.224567916992186, -22.841168526367198],
            [-43.226967916992187, -22.837868526367195],
            [-43.230267916992183, -22.836968526367194],
            [-43.233267916992183, -22.838668526367197],
            [-43.237867916992187, -22.838668526367197],
            [-43.242167916992187, -22.838168526367198],
            [-43.240467916992188, -22.841268526367195],
            [-43.241467916992185, -22.844768526367197],
            [-43.244067916992186, -22.841268526367195],
            [-43.248367916992187, -22.839268526367196],
            [-43.252367916992185, -22.837568526367196],
            [-43.255267916992182, -22.835068526367195],
            [-43.259167916992183, -22.831168526367197],
            [-43.263767916992187, -22.827068526367196],
            [-43.267067916992183, -22.822668526367195],
            [-43.268967916992182, -22.818868526367197],
            [-43.270467916992182, -22.815768526367197],
            [-43.271767916992182, -22.812668526367197],
            [-43.273667916992189, -22.808868526367196],
            [-43.272167916992188, -22.806068526367195],
            [-43.272667916992184, -22.801868526367198],
            [-43.270767916992185, -22.798968526367197],
            [-43.271167916992184, -22.796068526367197],
            [-43.272267916992185, -22.792768526367198],
            [-43.275067916992185, -22.791368526367197],
            [-43.276267916992182, -22.785268526367197],
            [-43.277567916992183, -22.780568526367198],
            [-43.275067916992185, -22.776568526367196],
            [-43.272967916992187, -22.773268526367197],
            [-43.270167916992186, -22.769568526367195],
            [-43.267367916992185, -22.766268526367195],
            [-43.264667916992188, -22.763568526367198],
            [-43.264567916992185, -22.760268526367195],
            [-43.260967916992186, -22.760268526367195],
            [-43.257767916992186, -22.757968526367197],
            [-43.254667916992183, -22.755568526367195],
            [-43.250967916992188, -22.753768526367196],
            [-43.246067916992182, -22.751568526367198],
            [-43.243567916992184, -22.748068526367195],
            [-43.240467916992188, -22.745468526367198],
            [-43.237167916992185, -22.742768526367197],
            [-43.233567916992186, -22.741368526367197],
            [-43.229967916992187, -22.740068526367196],
            [-43.226467916992185, -22.741068526367197],
            [-43.222867916992186, -22.740268526367196],
            [-43.220167916992182, -22.739368526367198],
            [-43.216967916992182, -22.737468526367195],
            [-43.213767916992182, -22.734668526367198],
            [-43.211967916992187, -22.732268526367196],
            [-43.212367916992186, -22.729268526367196],
            [-43.215567916992185, -22.727368526367197],
            [-43.210967916992182, -22.726368526367196],
            [-43.206767916992185, -22.726768526367195],
            [-43.203467916992182, -22.726468526367196],
            [-43.200267916992182, -22.725068526367195],
            [-43.197367916992185, -22.723768526367195],
            [-43.192767916992182, -22.722268526367195],
            [-43.187167916992188, -22.721968526367196],
            [-43.181867916992182, -22.720668526367195],
            [-43.176667916992187, -22.717868526367198],
            [-43.172167916992187, -22.715468526367196],
            [-43.167867916992186, -22.713668526367197],
            [-43.163867916992189, -22.710868526367197],
            [-43.159467916992185, -22.709568526367196],
            [-43.156167916992182, -22.708768526367198],
            [-43.152267916992187, -22.707968526367196],
            [-43.148767916992185, -22.708168526367196],
            [-43.143667916992186, -22.708168526367196],
            [-43.139667916992188, -22.709568526367196],
            [-43.136367916992185, -22.710968526367196],
            [-43.133867916992187, -22.714068526367196],
            [-43.130667916992188, -22.714868526367198],
            [-43.127667916992188, -22.714568526367195],
            [-43.124667916992188, -22.713168526367195],
            [-43.121867916992187, -22.710168526367195],
            [-43.117967916992185, -22.707168526367198],
            [-43.116567916992182, -22.703968526367195],
            [-43.117267916992184, -22.699868526367197],
            [-43.114767916992186, -22.696068526367196],
            [-43.111467916992183, -22.694068526367197],
            [-43.107767916992188, -22.694568526367195],
            [-43.105567916992186, -22.690568526367198],
            [-43.103067916992188, -22.687168526367195],
            [-43.097067916992188, -22.685768526367195],
            [-43.093167916992186, -22.685568526367195],
            [-43.089867916992183, -22.683268526367197],
            [-43.086967916992187, -22.681368526367198],
            [-43.083667916992184, -22.678068526367195],
            [-43.079267916992187, -22.680068526367197],
            [-43.073567916992182, -22.681868526367197],
            [-43.068867916992183, -22.684368526367198],
            [-43.066567916992184, -22.687968526367197],
            [-43.061867916992185, -22.686568526367196],
            [-43.057967916992183, -22.685768526367195],
            [-43.054367916992184, -22.686068526367198],
            [-43.052767916992188, -22.690568526367198],
            [-43.050467916992183, -22.692768526367196],
            [-43.046467916992185, -22.692668526367196],
            [-43.042867916992186, -22.692668526367196],
            [-43.038067916992183, -22.692368526367197],
            [-43.033967916992182, -22.694568526367195],
            [-43.036367916992184, -22.700168526367197],
            [-43.033367916992184, -22.702568526367195],
            [-43.031167916992182, -22.704668526367197],
            [-43.033867916992186, -22.706768526367195],
            [-43.035267916992183, -22.710968526367196],
            [-43.034467916992185, -22.714768526367195],
            [-43.031967916992187, -22.718368526367197],
            [-43.028767916992187, -22.721368526367197],
            [-43.025967916992187, -22.725068526367195],
            [-43.029167916992186, -22.726668526367195],
            [-43.029267916992183, -22.729768526367195],
            [-43.029767916992185, -22.733068526367198],
            [-43.029167916992186, -22.735868526367195],
            [-43.029067916992183, -22.740868526367198],
            [-43.032367916992186, -22.742268526367198],
            [-43.032267916992183, -22.747468526367197],
            [-43.036667916992187, -22.749768526367195],
            [-43.040567916992188, -22.751268526367195],
            [-43.047267916992183, -22.757068526367195],
            [-43.050767916992186, -22.758068526367197],
            [-43.054667916992187, -22.760368526367195],
            [-43.058267916992186, -22.762068526367198],
            [-43.062767916992186, -22.764668526367196],
            [-43.066867916992187, -22.769268526367195],
            [-43.067667916992185, -22.774468526367198],
            [-43.068867916992183, -22.779468526367197],
            [-43.071667916992183, -22.781668526367195],
            [-43.074867916992183, -22.783468526367198],
            [-43.078467916992182, -22.785168526367197],
            [-43.080967916992186, -22.788668526367196],
            [-43.080467916992184, -22.791768526367196],
            [-43.079667916992186, -22.795268526367195],
            [-43.078767916992184, -22.798368526367195],
            [-43.074267916992184, -22.800568526367197],
            [-43.069367916992185, -22.801168526367196],
            [-43.066067916992182, -22.802968526367195],
            [-43.068467916992184, -22.806168526367195],
            [-43.070967916992188, -22.808268526367197],
            [-43.073167916992183, -22.811668526367196],
            [-43.077067916992185, -22.815568526367198],
            [-43.079567916992183, -22.817768526367196],
            [-43.082567916992183, -22.820268526367197],
            [-43.085967916992182, -22.821268526367195],
            [-43.089267916992185, -22.822468526367196],
            [-43.093467916992182, -22.823168526367198],
            [-43.096967916992185, -22.826768526367196],
            [-43.097267916992188, -22.832168526367198],
            [-43.096367916992186, -22.836968526367194],
            [-43.096967916992185, -22.840068526367197],
            [-43.098367916992188, -22.842668526367198],
            [-43.101667916992184, -22.844168526367199],
            [-43.104967916992187, -22.847368526367198],
            [-43.103367916992184, -22.850868526367197],
            [-43.100567916992183, -22.849868526367196],
            [-43.101967916992187, -22.853568526367198],
            [-43.103967916992183, -22.856768526367198],
            [-43.107267916992186, -22.859968526367197],
            [-43.108867916992182, -22.862568526367198],
            [-43.110367916992182, -22.865268526367196],
            [-43.111967916992185, -22.868268526367196],
            [-43.113967916992188, -22.865768526367198],
            [-43.117167916992187, -22.865868526367194],
            [-43.120467916992183, -22.868268526367196],
            [-43.123267916992184, -22.871568526367199],
            [-43.124767916992184, -22.876068526367199],
            [-43.129167916992188, -22.876668526367197],
            [-43.133867916992187, -22.880968526367198],
            [-43.132967916992186, -22.884268526367194],
            [-43.129667916992183, -22.887168526367198],
            [-43.128067916992187, -22.891268526367199],
            [-43.124867916992187, -22.893568526367197],
            [-43.128067916992187, -22.895668526367196],
            [-43.132167916992188, -22.896468526367194],
            [-43.135667916992183, -22.899368526367198],
            [-43.136567916992185, -22.902868526367197],
            [-43.134867916992185, -22.905468526367194],
            [-43.132667916992183, -22.907368526367197],
            [-43.129867916992183, -22.908968526367197],
            [-43.125867916992185, -22.907368526367197],
            [-43.123367916992187, -22.905168526367198],
            [-43.120167916992187, -22.905468526367194],
            [-43.116067916992186, -22.905068526367195],
            [-43.110667916992185, -22.910868526367196],
            [-43.110767916992188, -22.913968526367196],
            [-43.107467916992185, -22.915268526367196],
            [-43.102867916992182, -22.914568526367198],
            [-43.099167916992187, -22.914568526367198],
            [-43.095667916992184, -22.916368526367194],
            [-43.094867916992186, -22.921468526367196],
            [-43.096167916992187, -22.926168526367196],
            [-43.098767916992188, -22.931168526367195],
            [-43.101767916992188, -22.934068526367199],
            [-43.105667916992182, -22.935168526367196],
            [-43.109967916992183, -22.934468526367198],
            [-43.113267916992186, -22.932768526367198],
            [-43.113567916992189, -22.928968526367196],
            [-43.116367916992182, -22.930868526367199],
            [-43.118267916992188, -22.927968526367195],
            [-43.120567916992186, -22.923268526367195],
            [-43.124067916992182, -22.924668526367196],
            [-43.123067916992184, -22.928568526367194],
            [-43.124767916992184, -22.930868526367199],
            [-43.126867916992182, -22.933368526367197],
            [-43.129567916992187, -22.937968526367197],
            [-43.126567916992187, -22.938768526367198],
            [-43.121567916992184, -22.938268526367196],
            [-43.116567916992182, -22.938068526367196],
            [-43.116667916992185, -22.941968526367198],
            [-43.119167916992183, -22.944868526367195],
            [-43.114667916992182, -22.945268526367194],
            [-43.111467916992183, -22.948568526367197],
            [-43.112167916992185, -22.952068526367196],
            [-43.107067916992186, -22.953468526367196],
            [-43.103667916992187, -22.955968526367194],
            [-43.100067916992188, -22.952868526367197],
            [-43.097667916992187, -22.954668526367197],
            [-43.093667916992182, -22.954068526367195],
            [-43.090667916992182, -22.954268526367194],
            [-43.086467916992184, -22.954668526367197],
            [-43.081467916992182, -22.955468526367198],
            [-43.076067916992187, -22.956068526367197],
            [-43.072767916992184, -22.956868526367195],
            [-43.069667916992188, -22.959268526367197],
            [-43.066067916992182, -22.959768526367196],
            [-43.062467916992183, -22.960468526367194],
            [-43.058767916992188, -22.961268526367196],
            [-43.054367916992184, -22.962568526367196],
            [-43.050867916992182, -22.964068526367196],
            [-43.047767916992186, -22.967868526367198],
            [-43.046667916992185, -22.973068526367197],
            [-43.051167916992185, -22.977068526367198],
            [-43.053867916992182, -22.980268526367198],
            [-43.050567916992186, -22.981368526367199],
            [-43.046667916992185, -22.980068526367198],
            [-43.043867916992184, -22.977768526367196],
            [-43.040267916992185, -22.975268526367195],
            [-43.037367916992189, -22.975568526367198],
            [-43.033767916992183, -22.974568526367197],
            [-43.030367916992184, -22.975668526367194],
            [-43.028667916992184, -22.980268526367198],
            [-43.024467916992187, -22.979968526367195],
            [-43.024067916992188, -22.976268526367196],
            [-43.020467916992182, -22.975968526367197],
            [-43.017067916992183, -22.976268526367196],
            [-43.013967916992186, -22.976768526367195],
            [-43.013767916992187, -22.973168526367196],
            [-43.010967916992186, -22.970568526367195],
            [-43.007967916992186, -22.969868526367197],
            [-43.004667916992183, -22.969768526367197],
            [-43.001667916992183, -22.969468526367194],
            [-42.997967916992188, -22.969468526367194],
            [-42.994767916992188, -22.969468526367194],
            [-42.991367916992182, -22.969268526367195],
            [-42.986367916992187, -22.969468526367194],
            [-42.978867916992186, -22.969568526367198],
            [-42.974867916992189, -22.969768526367197],
            [-42.965967916992184, -22.969868526367197],
            [-42.962067916992183, -22.970368526367196],
            [-42.956067916992183, -22.970868526367198],
            [-42.953167916992186, -22.970968526367194],
            [-42.949067916992185, -22.971468526367197],
            [-42.944867916992187, -22.972068526367195],
            [-42.937967916992186, -22.973168526367196],
            [-42.934167916992187, -22.973768526367195],
            [-42.931167916992187, -22.974168526367194],
            [-42.927567916992189, -22.974468526367197],
            [-42.923067916992188, -22.974568526367197],
            [-42.918167916992182, -22.974868526367196],
            [-42.914267916992188, -22.974568526367197],
            [-42.910567916992186, -22.974168526367194],
            [-42.906667916992184, -22.973068526367197],
            [-42.902267916992187, -22.971768526367196],
            [-42.897967916992187, -22.970568526367195],
            [-42.893767916992182, -22.969568526367198],
            [-42.890667916992186, -22.968768526367196],
            [-42.887367916992183, -22.968168526367194],
            [-42.883567916992185, -22.967568526367195],
            [-42.879867916992183, -22.966768526367197],
            [-42.875167916992183, -22.966168526367198],
            [-42.870767916992186, -22.965568526367196],
            [-42.866067916992186, -22.965168526367197],
            [-42.861067916992184, -22.964768526367195],
            [-42.857167916992182, -22.964568526367195],
            [-42.852467916992182, -22.964068526367196],
            [-42.847367916992184, -22.963968526367196],
            [-42.843667916992182, -22.963668526367197],
            [-42.840067916992183, -22.963368526367198],
            [-42.836567916992188, -22.962968526367199],
            [-42.832667916992186, -22.962568526367196],
            [-42.827867916992183, -22.962268526367197],
            [-42.824867916992183, -22.962068526367197],
            [-42.820967916992188, -22.961968526367194],
            [-42.817467916992186, -22.961768526367194],
            [-42.814167916992183, -22.961568526367195],
            [-42.810467916992188, -22.961468526367199],
            [-42.806367916992187, -22.961168526367196],
            [-42.802567916992189, -22.960968526367196],
            [-42.798667916992187, -22.960768526367197],
            [-42.794267916992183, -22.960468526367194],
            [-42.788867916992189, -22.960168526367198],
            [-42.783367916992184, -22.960068526367195],
            [-42.777567916992183, -22.959768526367196],
            [-42.773367916992186, -22.959368526367196],
            [-42.768767916992182, -22.959068526367197],
            [-42.764067916992182, -22.958668526367198],
            [-42.759267916992187, -22.958468526367199],
            [-42.755467916992188, -22.958468526367199],
            [-42.750567916992182, -22.958168526367196],
            [-42.744367916992182, -22.957568526367197],
            [-42.740467916992188, -22.957168526367198],
            [-42.736367916992187, -22.956768526367195],
            [-42.733067916992184, -22.956468526367196],
            [-42.728967916992183, -22.956168526367197],
            [-42.724567916992186, -22.956168526367197],
            [-42.720267916992185, -22.956068526367197],
            [-42.716467916992187, -22.956168526367197],
            [-42.712067916992183, -22.956068526367197],
            [-42.707567916992183, -22.956268526367197],
            [-42.703567916992185, -22.956468526367196],
            [-42.698967916992189, -22.957068526367195],
            [-42.694367916992185, -22.959668526367196],
            [-42.691667916992188, -22.961768526367194],
            [-42.688367916992185, -22.961268526367196],
            [-42.685467916992188, -22.959868526367195],
            [-42.682767916992184, -22.957168526367198],
            [-42.681967916992186, -22.954068526367195],
            [-42.681967916992186, -22.950768526367199],
            [-42.681667916992183, -22.947368526367196],
            [-42.679167916992185, -22.945468526367197],
            [-42.672867916992182, -22.943268526367198],
            [-42.668567916992188, -22.942168526367198],
            [-42.665267916992185, -22.941568526367199],
            [-42.661167916992184, -22.940768526367197],
            [-42.657067916992183, -22.939968526367196],
            [-42.653767916992187, -22.939368526367197],
            [-42.649467916992187, -22.938568526367199],
            [-42.644867916992183, -22.938068526367196],
            [-42.640067916992187, -22.936668526367196],
            [-42.635367916992188, -22.936568526367196],
            [-42.631267916992186, -22.936068526367194],
            [-42.625767916992189, -22.935468526367195],
            [-42.621367916992185, -22.935168526367196],
            [-42.612167916992185, -22.934368526367194],
            [-42.606067916992188, -22.933868526367196],
            [-42.601667916992184, -22.933568526367196],
            [-42.595867916992184, -22.933268526367197],
            [-42.587867916992188, -22.933068526367194],
            [-42.583167916992188, -22.932968526367198],
            [-42.578767916992184, -22.932768526367198],
            [-42.574867916992183, -22.932768526367198],
            [-42.571867916992183, -22.932768526367198],
            [-42.566667916992188, -22.932668526367195],
            [-42.558267916992186, -22.932768526367198],
            [-42.553667916992183, -22.932768526367198],
            [-42.550267916992183, -22.932768526367198],
            [-42.544267916992183, -22.932768526367198],
            [-42.539267916992188, -22.932968526367198],
            [-42.532267916992183, -22.933068526367194],
            [-42.525767916992187, -22.933268526367197],
            [-42.519267916992185, -22.933268526367197],
            [-42.512067916992187, -22.933668526367196],
            [-42.508867916992187, -22.933768526367196],
            [-42.504467916992184, -22.934068526367199],
            [-42.500267916992186, -22.934468526367198],
            [-42.494967916992188, -22.936368526367197],
            [-42.490367916992184, -22.937368526367194],
            [-42.486967916992185, -22.935868526367194],
            [-42.483967916992185, -22.935868526367194],
            [-42.480667916992182, -22.936368526367197],
            [-42.476967916992187, -22.936968526367195],
            [-42.474167916992187, -22.938068526367196],
            [-42.469867916992186, -22.936968526367195],
            [-42.465967916992184, -22.936168526367197],
            [-42.462267916992182, -22.935568526367199],
            [-42.458767916992187, -22.935168526367196],
            [-42.454267916992187, -22.934868526367197],
            [-42.448167916992183, -22.934868526367197],
            [-42.441667916992188, -22.934868526367197],
            [-42.437367916992187, -22.934868526367197],
            [-42.434167916992187, -22.934668526367197],
            [-42.429467916992188, -22.934668526367197],
            [-42.422167916992187, -22.934868526367197],
            [-42.416667916992182, -22.934968526367197],
            [-42.410867916992188, -22.935168526367196],
            [-42.405467916992187, -22.935268526367196],
            [-42.401467916992182, -22.935568526367199],
            [-42.395767916992185, -22.935868526367194],
            [-42.392167916992186, -22.935868526367194],
            [-42.389067916992182, -22.935868526367194],
            [-42.386067916992182, -22.935768526367198],
            [-42.380267916992182, -22.934968526367197],
            [-42.375567916992182, -22.935468526367195],
            [-42.371667916992187, -22.935468526367195],
            [-42.367667916992183, -22.935468526367195],
            [-42.363567916992189, -22.935468526367195],
            [-42.360367916992182, -22.935468526367195],
            [-42.357167916992182, -22.935568526367199],
            [-42.353867916992186, -22.935568526367199],
            [-42.350667916992187, -22.935768526367198],
            [-42.347267916992188, -22.935868526367194],
            [-42.343467916992182, -22.936068526367194],
            [-42.339067916992185, -22.936168526367197],
            [-42.333767916992187, -22.936368526367197],
            [-42.329567916992183, -22.936568526367196],
            [-42.326367916992183, -22.936668526367196],
            [-42.322367916992185, -22.936868526367196],
            [-42.318167916992188, -22.937168526367195],
            [-42.314067916992187, -22.937368526367194],
            [-42.308367916992182, -22.937668526367197],
            [-42.303367916992187, -22.937968526367197],
            [-42.297867916992182, -22.938368526367196],
            [-42.292867916992186, -22.938568526367199],
            [-42.288067916992183, -22.939068526367194],
            [-42.284767916992188, -22.939168526367197],
            [-42.280667916992186, -22.938068526367196],
            [-42.276767916992185, -22.939668526367196],
            [-42.272967916992187, -22.939868526367196],
            [-42.268767916992182, -22.939868526367196],
            [-42.264567916992185, -22.940168526367195],
            [-42.261267916992182, -22.940268526367198],
            [-42.258167916992186, -22.940468526367198],
            [-42.253567916992182, -22.940568526367194],
            [-42.248367916992187, -22.940868526367197],
            [-42.244067916992186, -22.940868526367197],
            [-42.239667916992182, -22.941268526367196],
            [-42.236067916992184, -22.941368526367196],
            [-42.232867916992184, -22.941568526367199],
            [-42.229567916992188, -22.941668526367195],
            [-42.226367916992189, -22.941968526367198],
            [-42.223167916992182, -22.942168526367198],
            [-42.220167916992182, -22.942368526367197],
            [-42.216767916992183, -22.942668526367196],
            [-42.213467916992187, -22.942668526367196],
            [-42.208967916992187, -22.943068526367199],
            [-42.204867916992185, -22.943468526367198],
            [-42.199967916992186, -22.943568526367194],
            [-42.195167916992183, -22.943868526367197],
            [-42.190567916992187, -22.944168526367196],
            [-42.187267916992184, -22.944568526367195],
            [-42.183867916992185, -22.944868526367195],
            [-42.179667916992187, -22.944968526367198],
            [-42.175267916992183, -22.945268526367194],
            [-42.170367916992184, -22.945768526367196],
            [-42.165067916992186, -22.946068526367196],
            [-42.159467916992185, -22.946668526367198],
            [-42.155467916992187, -22.947068526367197],
            [-42.151567916992185, -22.947368526367196],
            [-42.147067916992185, -22.947768526367199],
            [-42.143167916992184, -22.948168526367198],
            [-42.139567916992185, -22.948468526367197],
            [-42.135967916992186, -22.948568526367197],
            [-42.132467916992184, -22.949268526367199],
            [-42.127367916992185, -22.949568526367194],
            [-42.123267916992184, -22.949868526367197],
            [-42.119367916992182, -22.950268526367196],
            [-42.114967916992185, -22.950768526367199],
            [-42.111167916992187, -22.950968526367198],
            [-42.108067916992184, -22.951468526367197],
            [-42.104467916992185, -22.951868526367196],
            [-42.100667916992187, -22.952068526367196],
            [-42.096267916992183, -22.952568526367195],
            [-42.091967916992182, -22.953268526367197],
            [-42.088767916992182, -22.953568526367196],
            [-42.085367916992183, -22.954068526367195],
            [-42.081467916992182, -22.954568526367197],
            [-42.075967916992184, -22.955368526367195],
            [-42.071867916992183, -22.955968526367194],
            [-42.067767916992182, -22.956768526367195],
            [-42.063067916992182, -22.957668526367197],
            [-42.059367916992187, -22.958468526367199],
            [-42.055467916992185, -22.959368526367196],
            [-42.051267916992188, -22.960768526367197],
            [-42.046667916992185, -22.962568526367196],
            [-42.043567916992188, -22.963968526367196],
            [-42.040967916992187, -22.965168526367197],
            [-42.038067916992183, -22.967068526367196],
            [-42.034867916992184, -22.969768526367197],
            [-42.032367916992186, -22.973868526367198],
            [-42.034167916992182, -22.977868526367196],
            [-42.030967916992182, -22.981368526367199],
            [-42.026467916992182, -22.982268526367196],
            [-42.022367916992188, -22.982768526367195],
            [-42.019667916992184, -22.984268526367195],
            [-42.017967916992184, -22.986868526367196],
            [-42.016767916992187, -22.991068526367194],
            [-42.013567916992187, -22.995568526367194],
            [-42.012667916992186, -22.989968526367196],
            [-42.009967916992188, -22.986068526367198],
            [-42.008267916992182, -22.982768526367195],
            [-42.010467916992184, -22.979568526367196],
            [-42.013767916992187, -22.978368526367198],
            [-42.018667916992186, -22.978668526367194],
            [-42.020467916992182, -22.973468526367196],
            [-42.017867916992188, -22.970368526367196],
            [-42.013167916992188, -22.971968526367196],
            [-42.014967916992184, -22.968368526367197],
            [-42.014267916992182, -22.964868526367198],
            [-42.010167916992188, -22.965368526367197],
            [-42.007367916992187, -22.966268526367195],
            [-42.003867916992185, -22.968768526367196],
            [-42.001667916992183, -22.965668526367196],
            [-41.997167916992183, -22.964068526367196],
            [-41.995467916992183, -22.961568526367195],
            [-41.997767916992188, -22.959368526367196],
            [-42.002067916992182, -22.959268526367197],
            [-42.005167916992185, -22.961468526367199],
            [-42.008467916992188, -22.960368526367198],
            [-42.007667916992183, -22.955668526367198],
            [-42.005767916992184, -22.952168526367196],
            [-42.010667916992183, -22.952568526367195],
            [-42.014267916992182, -22.954668526367197],
            [-42.016467916992184, -22.956768526367195],
            [-42.018667916992186, -22.960068526367195],
            [-42.023167916992186, -22.960368526367198],
            [-42.025867916992183, -22.957168526367198],
            [-42.024567916992183, -22.954268526367194],
            [-42.024267916992187, -22.950968526367198],
            [-42.023667916992189, -22.946668526367198],
            [-42.027667916992186, -22.946268526367199],
            [-42.032767916992185, -22.945768526367196],
            [-42.033967916992182, -22.941968526367198],
            [-42.035667916992182, -22.939668526367196],
            [-42.036767916992183, -22.936168526367197],
            [-42.037267916992185, -22.931668526367197],
            [-42.037567916992188, -22.926668526367195],
            [-42.037367916992189, -22.922768526367197],
            [-42.036767916992183, -22.918868526367199],
            [-42.035867916992188, -22.914968526367197],
            [-42.034467916992185, -22.910568526367197],
            [-42.033067916992188, -22.906568526367195],
            [-42.031567916992188, -22.903368526367196],
            [-42.029867916992188, -22.900468526367199],
            [-42.027867916992186, -22.897368526367195],
            [-42.025067916992185, -22.893968526367196],
            [-42.022667916992184, -22.891068526367196],
            [-42.019867916992183, -22.888468526367198],
            [-42.017167916992186, -22.885968526367197],
            [-42.014267916992182, -22.883768526367199],
            [-42.010367916992188, -22.882768526367194],
            [-42.007367916992187, -22.885368526367195],
            [-42.004867916992183, -22.887868526367196],
            [-42.002667916992188, -22.889668526367196],
            [-42.000767916992189, -22.887468526367197],
            [-41.998767916992186, -22.884968526367196],
            [-41.995067916992184, -22.883468526367196],
            [-41.990567916992184, -22.882068526367195],
            [-41.987567916992184, -22.879468526367198],
            [-41.985767916992188, -22.876868526367197],
            [-41.984167916992185, -22.874368526367196],
            [-41.980567916992186, -22.872268526367197],
            [-41.982867916992184, -22.869668526367196],
            [-41.982267916992186, -22.866668526367196],
            [-41.984967916992183, -22.863268526367197],
            [-41.986367916992187, -22.858968526367196],
            [-41.986467916992183, -22.855268526367198],
            [-41.986067916992184, -22.851668526367199],
            [-41.984967916992183, -22.848368526367196],
            [-41.983667916992182, -22.844768526367197],
            [-41.982467916992185, -22.841168526367198],
            [-41.980867916992182, -22.837868526367195],
            [-41.978867916992186, -22.834268526367197],
            [-41.976667916992184, -22.830368526367195],
            [-41.974167916992187, -22.827068526367196],
            [-41.971667916992182, -22.824068526367196],
            [-41.967667916992184, -22.821868526367197],
            [-41.963767916992182, -22.819268526367196],
            [-41.959367916992186, -22.818868526367197],
            [-41.955767916992187, -22.816568526367195],
            [-41.951567916992182, -22.814168526367197],
            [-41.947467916992188, -22.812968526367197],
            [-41.943267916992184, -22.813068526367196],
            [-41.939567916992182, -22.809768526367197],
            [-41.935167916992185, -22.809768526367197],
            [-41.932467916992188, -22.811268526367197],
            [-41.927967916992188, -22.812668526367197],
            [-41.925767916992186, -22.808768526367196],
            [-41.928667916992183, -22.807168526367196],
            [-41.930567916992182, -22.803068526367195],
            [-41.928967916992185, -22.797868526367196],
            [-41.926367916992184, -22.793168526367197],
            [-41.923967916992183, -22.789968526367197],
            [-41.921367916992182, -22.786968526367197],
            [-41.918067916992186, -22.784768526367195],
            [-41.915967916992187, -22.782268526367197],
            [-41.913467916992182, -22.780568526367198],
            [-41.910167916992187, -22.778968526367198],
            [-41.906767916992187, -22.778368526367196],
            [-41.903967916992187, -22.780068526367195],
            [-41.902667916992186, -22.782768526367196],
            [-41.898467916992182, -22.782868526367196],
            [-41.895367916992186, -22.785868526367196],
            [-41.892567916992185, -22.784168526367196],
            [-41.889567916992185, -22.780868526367197],
            [-41.886467916992189, -22.777868526367197],
            [-41.884267916992187, -22.780968526367197],
            [-41.882467916992184, -22.784168526367196],
            [-41.882367916992187, -22.778668526367195],
            [-41.884967916992188, -22.774868526367197],
            [-41.888467916992184, -22.772968526367197],
            [-41.886567916992185, -22.768968526367196],
            [-41.883467916992188, -22.770368526367196],
            [-41.881267916992186, -22.773668526367196],
            [-41.878067916992187, -22.774368526367198],
            [-41.877667916992188, -22.770468526367196],
            [-41.877967916992183, -22.766068526367196],
            [-41.875467916992186, -22.762368526367197],
            [-41.873767916992186, -22.765468526367197],
            [-41.870067916992184, -22.762368526367197],
            [-41.866667916992185, -22.762068526367198],
            [-41.865067916992189, -22.758868526367195],
            [-41.864467916992183, -22.755168526367196],
            [-41.867267916992184, -22.753568526367197],
            [-41.870067916992184, -22.755668526367195],
            [-41.873067916992184, -22.753868526367196],
            [-41.873267916992184, -22.750168526367197],
            [-41.871167916992185, -22.746068526367196],
            [-41.869167916992183, -22.742168526367195],
            [-41.871967916992183, -22.738568526367196],
            [-41.875167916992183, -22.741468526367196],
            [-41.878467916992186, -22.741468526367196],
            [-41.881367916992183, -22.740768526367198],
            [-41.882667916992183, -22.744068526367197],
            [-41.882067916992185, -22.747368526367197],
            [-41.883067916992182, -22.750568526367196],
            [-41.885267916992184, -22.753768526367196],
            [-41.889067916992182, -22.755168526367196],
            [-41.892967916992184, -22.754568526367198],
            [-41.895967916992184, -22.752368526367196],
            [-41.898667916992189, -22.750168526367197],
            [-41.900867916992183, -22.753568526367197],
            [-41.903367916992188, -22.755968526367198],
            [-41.906267916992185, -22.756568526367197],
            [-41.909867916992184, -22.756668526367196],
            [-41.908167916992184, -22.760968526367197],
            [-41.908367916992184, -22.764368526367196],
            [-41.909867916992184, -22.767668526367196],
            [-41.912367916992189, -22.769368526367195],
            [-41.915367916992182, -22.771068526367195],
            [-41.919567916992186, -22.771468526367197],
            [-41.923867916992187, -22.770768526367196],
            [-41.928567916992186, -22.769268526367195],
            [-41.932967916992183, -22.766768526367198],
            [-41.935867916992187, -22.764568526367196],
            [-41.938567916992184, -22.762068526367198],
            [-41.941667916992188, -22.758868526367195],
            [-41.944667916992188, -22.755268526367196],
            [-41.947467916992188, -22.751268526367195],
            [-41.949567916992187, -22.748368526367198],
            [-41.950967916992184, -22.745468526367198],
            [-41.952367916992188, -22.742268526367198],
            [-41.954667916992186, -22.740068526367196],
            [-41.957067916992187, -22.737168526367196],
            [-41.956767916992185, -22.732868526367195],
            [-41.960367916992183, -22.732468526367196],
            [-41.963367916992183, -22.732568526367196],
            [-41.966267916992187, -22.732468526367196],
            [-41.969467916992187, -22.730568526367197],
            [-41.973067916992186, -22.729468526367196],
            [-41.976767916992188, -22.727468526367197],
            [-41.979267916992185, -22.725268526367195],
            [-41.981167916992185, -22.722768526367197],
            [-41.982567916992188, -22.719868526367197],
            [-41.984367916992184, -22.717068526367196],
            [-41.986167916992187, -22.713368526367198],
            [-41.988067916992186, -22.710068526367195],
            [-41.989167916992187, -22.706868526367195],
            [-41.990067916992189, -22.703568526367196],
            [-41.991067916992186, -22.700368526367196],
            [-41.992167916992187, -22.696568526367198],
            [-41.993267916992188, -22.692068526367198],
            [-41.994067916992186, -22.688768526367195],
            [-41.994667916992185, -22.685468526367195],
            [-41.995067916992184, -22.682268526367196],
            [-41.995767916992186, -22.679168526367196],
            [-41.996367916992185, -22.675068526367195],
            [-41.996967916992183, -22.670268526367195],
            [-41.997667916992185, -22.666368526367197],
            [-41.997967916992188, -22.662868526367195],
            [-41.998267916992184, -22.658368526367195],
            [-41.998867916992182, -22.652668526367197],
            [-41.999167916992185, -22.647268526367196],
            [-41.999467916992188, -22.642868526367195],
            [-41.999467916992188, -22.638968526367197],
            [-41.999467916992188, -22.633868526367195],
            [-41.999467916992188, -22.629868526367197],
            [-41.999167916992185, -22.625968526367195],
            [-41.998867916992182, -22.621868526367198],
            [-41.998367916992187, -22.617568526367197],
            [-41.998067916992184, -22.613368526367196],
            [-41.997767916992188, -22.609668526367198],
            [-41.997167916992183, -22.606368526367195],
            [-41.996567916992184, -22.603168526367195],
            [-41.995067916992184, -22.599568526367197],
            [-41.991167916992183, -22.597668526367197],
            [-41.988567916992189, -22.595668526367195],
            [-41.988267916992186, -22.592568526367195],
            [-41.987467916992188, -22.589768526367195],
            [-41.986767916992186, -22.585868526367197],
            [-41.985767916992188, -22.582268526367198],
            [-41.984367916992184, -22.577668526367198],
            [-41.983167916992187, -22.573868526367196],
            [-41.982167916992182, -22.570968526367196],
            [-41.981067916992188, -22.567668526367196],
            [-41.979667916992184, -22.563568526367195],
            [-41.977567916992186, -22.559068526367195],
            [-41.976167916992182, -22.555468526367196],
            [-41.974567916992186, -22.552268526367197],
            [-41.973067916992186, -22.549368526367196],
            [-41.971267916992183, -22.546368526367196],
            [-41.969267916992187, -22.543168526367197],
            [-41.966967916992182, -22.540068526367197],
            [-41.964267916992185, -22.537068526367197],
            [-41.961967916992187, -22.534868526367195],
            [-41.959667916992188, -22.532768526367196],
            [-41.955167916992188, -22.531568526367195],
            [-41.951467916992186, -22.530168526367195],
            [-41.948567916992182, -22.528968526367198],
            [-41.945167916992183, -22.528068526367196],
            [-41.941367916992185, -22.530168526367195],
            [-41.938767916992184, -22.532268526367197],
            [-41.936367916992182, -22.535968526367196],
            [-41.932167916992185, -22.535968526367196],
            [-41.928967916992185, -22.532368526367197],
            [-41.926067916992189, -22.530468526367198],
            [-41.922867916992182, -22.528768526367195],
            [-41.920567916992184, -22.526168526367197],
            [-41.918367916992182, -22.520368526367196],
            [-41.915867916992184, -22.515768526367197],
            [-41.913467916992182, -22.512368526367197],
            [-41.910967916992185, -22.509368526367197],
            [-41.907767916992185, -22.505168526367196],
            [-41.905167916992184, -22.501668526367197],
            [-41.903367916992188, -22.499468526367195],
            [-41.900967916992187, -22.496668526367195],
            [-41.898467916992182, -22.493668526367195],
            [-41.895467916992182, -22.490568526367195],
            [-41.892167916992186, -22.487568526367195],
            [-41.889267916992182, -22.486168526367198],
            [-41.885967916992186, -22.486368526367198],
            [-41.883067916992182, -22.488068526367197],
            [-41.878067916992187, -22.487468526367195],
            [-41.876567916992187, -22.484668526367198],
            [-41.873767916992186, -22.483868526367196],
            [-41.871867916992187, -22.480668526367197],
            [-41.868667916992187, -22.478568526367198],
            [-41.865467916992188, -22.477868526367196],
            [-41.862567916992184, -22.475268526367195],
            [-41.861067916992184, -22.470868526367195],
            [-41.860367916992182, -22.464768526367195],
            [-41.859467916992187, -22.460868526367197],
            [-41.857867916992184, -22.457968526367196],
            [-41.854967916992187, -22.455668526367198],
            [-41.854767916992188, -22.450468526367196],
            [-41.852767916992185, -22.446668526367198],
            [-41.849767916992185, -22.443568526367198],
            [-41.846967916992185, -22.440868526367197],
            [-41.843767916992185, -22.437768526367197],
            [-41.841567916992183, -22.435268526367196],
            [-41.839467916992184, -22.432968526367198],
            [-41.837267916992182, -22.430868526367195],
            [-41.835067916992188, -22.428968526367196],
            [-41.831767916992185, -22.426468526367195],
            [-41.828267916992182, -22.424968526367195],
            [-41.825667916992188, -22.422468526367197],
            [-41.822667916992188, -22.420268526367195],
            [-41.819367916992185, -22.416368526367197],
            [-41.815967916992186, -22.415668526367195],
            [-41.813367916992185, -22.414168526367195],
            [-41.808767916992188, -22.411668526367198],
            [-41.804167916992185, -22.409168526367196],
            [-41.800767916992186, -22.407368526367197],
            [-41.797867916992182, -22.405868526367197],
            [-41.794767916992186, -22.404568526367196],
            [-41.791667916992182, -22.401268526367197],
            [-41.788867916992189, -22.399068526367195],
            [-41.786367916992184, -22.397668526367195],
            [-41.783667916992187, -22.396068526367195],
            [-41.780967916992182, -22.394568526367195],
            [-41.778367916992188, -22.393168526367198],
            [-41.775767916992187, -22.391868526367197],
            [-41.771867916992186, -22.389968526367195],
            [-41.767967916992184, -22.388768526367198],
            [-41.767367916992185, -22.384968526367196],
            [-41.770467916992182, -22.384668526367197],
            [-41.769267916992185, -22.380968526367198],
            [-41.767167916992186, -22.376668526367197],
            [-41.770967916992184, -22.376268526367195],
            [-41.773667916992189, -22.374668526367195],
            [-41.775867916992183, -22.372368526367197],
            [-41.774767916992182, -22.368268526367196],
            [-41.772967916992187, -22.363668526367196],
            [-41.770367916992186, -22.359668526367198],
            [-41.767467916992182, -22.356768526367198],
            [-41.765167916992183, -22.354268526367196],
            [-41.762767916992182, -22.352068526367198],
            [-41.759067916992187, -22.349468526367197],
            [-41.755167916992185, -22.347268526367195],
            [-41.751367916992187, -22.345168526367196],
            [-41.747967916992188, -22.343668526367196],
            [-41.744167916992183, -22.341968526367197],
            [-41.740067916992189, -22.339568526367195],
            [-41.735767916992188, -22.336168526367196],
            [-41.732067916992186, -22.333268526367196],
            [-41.728867916992186, -22.330968526367197],
            [-41.726167916992182, -22.328868526367195],
            [-41.723067916992186, -22.325968526367195],
            [-41.719567916992183, -22.322768526367195],
            [-41.716667916992186, -22.320168526367198],
            [-41.714067916992185, -22.317968526367196],
            [-41.711467916992184, -22.315868526367197],
            [-41.708267916992185, -22.313368526367196],
            [-41.705767916992187, -22.311568526367196],
            [-41.702667916992183, -22.309168526367195],
            [-41.699267916992184, -22.306668526367197],
            [-41.696367916992187, -22.304768526367198],
            [-41.693167916992188, -22.302568526367196],
            [-41.689567916992182, -22.299668526367196],
            [-41.684667916992183, -22.297268526367198],
            [-41.681567916992186, -22.295368526367195],
            [-41.678367916992187, -22.293568526367196],
            [-41.675267916992183, -22.291768526367196],
            [-41.672467916992183, -22.290268526367196],
            [-41.669267916992183, -22.288668526367196],
            [-41.666667916992182, -22.287268526367196],
            [-41.663167916992187, -22.285568526367197],
            [-41.659467916992185, -22.283768526367197],
            [-41.654567916992185, -22.281468526367195],
            [-41.650467916992184, -22.279568526367196],
            [-41.646567916992183, -22.277668526367197],
            [-41.641567916992187, -22.275668526367195],
            [-41.637767916992182, -22.273968526367195],
            [-41.634067916992187, -22.272368526367195],
            [-41.630267916992182, -22.270668526367196],
            [-41.626667916992183, -22.269368526367195],
            [-41.624067916992182, -22.268168526367198],
            [-41.620867916992182, -22.266768526367198],
            [-41.617267916992184, -22.265168526367198],
            [-41.614067916992184, -22.264068526367197],
            [-41.610567916992188, -22.262368526367197],
            [-41.606667916992187, -22.260668526367198],
            [-41.603167916992184, -22.259168526367198],
            [-41.598867916992184, -22.257668526367198],
            [-41.594867916992186, -22.255668526367195],
            [-41.590867916992188, -22.254068526367195],
            [-41.587567916992185, -22.252768526367195],
            [-41.583967916992187, -22.251268526367195],
            [-41.578567916992185, -22.249068526367196],
            [-41.574667916992183, -22.247368526367197],
            [-41.571867916992183, -22.246268526367196],
            [-41.567767916992182, -22.244668526367196],
            [-41.564167916992183, -22.243268526367196],
            [-41.560867916992187, -22.241868526367195],
            [-41.557667916992187, -22.240568526367195],
            [-41.554667916992187, -22.239168526367198],
            [-41.550567916992186, -22.237868526367198],
            [-41.545867916992187, -22.236168526367198],
            [-41.542467916992187, -22.233968526367196],
            [-41.538167916992187, -22.232768526367195],
            [-41.533667916992187, -22.230668526367197],
            [-41.530567916992183, -22.229468526367196],
            [-41.527667916992186, -22.228168526367195],
            [-41.523967916992184, -22.226668526367195],
            [-41.520367916992186, -22.225068526367195],
            [-41.517567916992185, -22.223968526367198],
            [-41.514567916992185, -22.222868526367197],
            [-41.510767916992187, -22.221668526367196],
            [-41.506767916992182, -22.219868526367197],
            [-41.502667916992188, -22.218468526367197],
            [-41.499667916992188, -22.217368526367196],
            [-41.496867916992187, -22.216468526367198],
            [-41.493667916992187, -22.215368526367197],
            [-41.489567916992186, -22.214068526367196],
            [-41.485267916992186, -22.212668526367196],
            [-41.480067916992184, -22.211168526367196],
            [-41.475667916992187, -22.209768526367196],
            [-41.472867916992186, -22.208968526367197],
            [-41.469167916992184, -22.207668526367197],
            [-41.465967916992184, -22.206768526367195],
            [-41.462867916992188, -22.205768526367198],
            [-41.459367916992186, -22.204868526367196],
            [-41.454967916992182, -22.203468526367196],
            [-41.450967916992184, -22.202168526367196],
            [-41.448167916992183, -22.201568526367197],
            [-41.445467916992186, -22.200668526367195],
            [-41.442667916992185, -22.199968526367197],
            [-41.439867916992185, -22.199268526367195],
            [-41.436867916992185, -22.198568526367197],
            [-41.434067916992184, -22.197768526367195],
            [-41.430867916992185, -22.197068526367197],
            [-41.427767916992188, -22.196268526367195],
            [-41.423967916992183, -22.195168526367198],
            [-41.419467916992183, -22.193868526367197],
            [-41.415567916992188, -22.192968526367196],
            [-41.411267916992188, -22.191668526367195],
            [-41.407267916992183, -22.190568526367198],
            [-41.403767916992187, -22.189868526367196],
            [-41.399467916992187, -22.188768526367195],
            [-41.395067916992183, -22.187668526367197],
            [-41.391767916992187, -22.186868526367196],
            [-41.387167916992183, -22.185568526367195],
            [-41.382067916992185, -22.184068526367195],
            [-41.378067916992187, -22.182968526367198],
            [-41.374667916992188, -22.182168526367196],
            [-41.370867916992182, -22.181068526367195],
            [-41.367767916992186, -22.180268526367197],
            [-41.364767916992186, -22.179468526367195],
            [-41.361067916992184, -22.178368526367198],
            [-41.357767916992188, -22.177268526367197],
            [-41.354567916992188, -22.176368526367195],
            [-41.350967916992182, -22.175768526367197],
            [-41.347567916992183, -22.174968526367195],
            [-41.344767916992183, -22.174168526367197],
            [-41.340467916992182, -22.173168526367196],
            [-41.335467916992187, -22.171968526367195],
            [-41.331767916992185, -22.171068526367197],
            [-41.327967916992186, -22.169968526367196],
            [-41.323967916992189, -22.169168526367198],
            [-41.319067916992182, -22.168068526367197],
            [-41.315467916992183, -22.167168526367195],
            [-41.311967916992188, -22.166168526367198],
            [-41.308667916992185, -22.165268526367196],
            [-41.305767916992188, -22.164468526367195],
            [-41.301167916992185, -22.163068526367198],
            [-41.296467916992185, -22.161968526367197],
            [-41.291667916992182, -22.160768526367196],
            [-41.286967916992182, -22.159568526367195],
            [-41.284167916992182, -22.158968526367197],
            [-41.280067916992188, -22.157868526367196],
            [-41.275867916992183, -22.156968526367198],
            [-41.271767916992182, -22.155668526367197],
            [-41.267867916992188, -22.154568526367196],
            [-41.264367916992185, -22.153668526367195],
            [-41.260767916992187, -22.152568526367197],
            [-41.257667916992183, -22.151468526367196],
            [-41.253867916992185, -22.150368526367195],
            [-41.248767916992186, -22.148668526367196],
            [-41.244067916992186, -22.147068526367196],
            [-41.239067916992184, -22.145368526367196],
            [-41.233767916992186, -22.143568526367197],
            [-41.229267916992185, -22.142068526367197],
            [-41.225567916992183, -22.140768526367197],
            [-41.222267916992188, -22.139668526367196],
            [-41.218367916992186, -22.138268526367195],
            [-41.213967916992182, -22.136768526367195],
            [-41.210367916992183, -22.135168526367195],
            [-41.206267916992182, -22.133468526367196],
            [-41.201767916992182, -22.131368526367197],
            [-41.197067916992182, -22.129368526367195],
            [-41.193767916992186, -22.128068526367198],
            [-41.189867916992185, -22.126268526367195],
            [-41.186267916992186, -22.124368526367196],
            [-41.183267916992186, -22.122968526367195],
            [-41.180467916992185, -22.121668526367195],
            [-41.177567916992189, -22.120268526367198],
            [-41.174767916992188, -22.119068526367197],
            [-41.171467916992185, -22.117568526367197],
            [-41.167867916992186, -22.116068526367197],
            [-41.164267916992188, -22.114168526367198],
            [-41.159867916992184, -22.111768526367197],
            [-41.155567916992183, -22.109268526367195],
            [-41.152267916992187, -22.107568526367196],
            [-41.148667916992189, -22.105568526367197],
            [-41.145467916992182, -22.103968526367197],
            [-41.142867916992188, -22.102068526367198],
            [-41.139367916992185, -22.099868526367196],
            [-41.134867916992185, -22.097268526367195],
            [-41.135267916992184, -22.093368526367197],
            [-41.132067916992185, -22.090068526367197],
            [-41.129567916992187, -22.088268526367198],
            [-41.126667916992183, -22.086468526367195],
            [-41.123267916992184, -22.084268526367197],
            [-41.120467916992183, -22.082568526367197],
            [-41.116867916992184, -22.081068526367197],
            [-41.113267916992186, -22.078568526367196],
            [-41.109467916992187, -22.076368526367197],
            [-41.106167916992185, -22.074568526367198],
            [-41.102567916992186, -22.072468526367196],
            [-41.099467916992182, -22.070768526367196],
            [-41.095967916992187, -22.068768526367197],
            [-41.092267916992185, -22.066668526367195],
            [-41.088367916992183, -22.064468526367197],
            [-41.083767916992187, -22.061868526367196],
            [-41.081067916992183, -22.060268526367196],
            [-41.078567916992185, -22.058768526367196],
            [-41.075267916992182, -22.056868526367197],
            [-41.072167916992186, -22.055268526367197],
            [-41.069567916992185, -22.053868526367197],
            [-41.065967916992186, -22.052168526367197],
            [-41.062167916992188, -22.050068526367195],
            [-41.058367916992182, -22.048268526367195],
            [-41.054767916992184, -22.046468526367196],
            [-41.050467916992183, -22.044268526367198],
            [-41.047167916992187, -22.042568526367198],
            [-41.043667916992185, -22.040968526367195],
            [-41.039867916992186, -22.039168526367195],
            [-41.035567916992186, -22.037068526367197],
            [-41.031967916992187, -22.035368526367197],
            [-41.029167916992186, -22.034168526367196],
            [-41.025867916992183, -22.032668526367196],
            [-41.021767916992182, -22.030968526367197],
            [-41.018967916992182, -22.029468526367197],
            [-41.016467916992184, -22.028068526367196],
            [-41.013467916992184, -22.026568526367196],
            [-41.010167916992188, -22.024768526367197],
            [-41.007467916992184, -22.022968526367197],
            [-41.004867916992183, -22.021468526367197],
            [-41.002067916992182, -22.019268526367195],
            [-40.999367916992185, -22.017368526367196],
            [-40.996367916992185, -22.014968526367195],
            [-40.993667916992187, -22.012668526367197],
            [-40.991367916992182, -22.010768526367197],
            [-40.989067916992184, -22.008168526367196],
            [-40.986667916992182, -22.004868526367197],
            [-40.984767916992183, -22.000968526367195],
            [-40.983067916992184, -21.996968526367198],
            [-40.981667916992187, -21.993668526367195],
            [-40.980267916992183, -21.989768526367197],
            [-40.978967916992183, -21.985768526367195],
            [-40.977767916992185, -21.981668526367198],
            [-40.977067916992183, -21.978468526367195],
            [-40.976167916992182, -21.974168526367198],
            [-40.975367916992184, -21.969068526367195],
            [-40.974867916992189, -21.964268526367196],
            [-40.974567916992186, -21.959268526367197],
            [-40.974567916992186, -21.954568526367197],
            [-40.974567916992186, -21.950668526367195],
            [-40.974567916992186, -21.947368526367196],
            [-40.974867916992189, -21.943268526367195],
            [-40.975567916992183, -21.938868526367198],
            [-40.976367916992189, -21.934168526367195],
            [-40.977067916992183, -21.929968526367198],
            [-40.978067916992188, -21.925368526367198],
            [-40.979167916992182, -21.920768526367198],
            [-40.980367916992186, -21.915968526367195],
            [-40.982167916992182, -21.909568526367195],
            [-40.983867916992182, -21.903968526367198],
            [-40.985267916992186, -21.899568526367197],
            [-40.986367916992187, -21.895068526367197],
            [-40.987467916992188, -21.891268526367195],
            [-40.988267916992186, -21.887668526367197],
            [-40.989367916992187, -21.882768526367197],
            [-40.990067916992189, -21.879568526367198],
            [-40.990867916992187, -21.876068526367195],
            [-40.991567916992182, -21.872368526367197],
            [-40.992667916992183, -21.866568526367196],
            [-40.993067916992182, -21.863368526367196],
            [-40.994667916992185, -21.854968526367195],
            [-40.994367916992182, -21.850568526367198],
            [-40.998767916992186, -21.840968526367195],
            [-40.998767916992186, -21.836568526367195],
            [-41.000467916992186, -21.832568526367197],
            [-41.001367916992187, -21.828568526367196],
            [-41.002667916992188, -21.824568526367198],
            [-41.003767916992182, -21.820268526367197],
            [-41.004967916992186, -21.815868526367197],
            [-41.006067916992187, -21.811368526367197],
            [-41.006867916992185, -21.808368526367197],
            [-41.007667916992183, -21.804768526367198],
            [-41.008567916992185, -21.801068526367196],
            [-41.009567916992182, -21.796468526367196],
            [-41.010967916992186, -21.791468526367197],
            [-41.011767916992184, -21.788368526367197],
            [-41.012367916992183, -21.785568526367197],
            [-41.013167916992188, -21.781768526367195],
            [-41.014267916992182, -21.777968526367196],
            [-41.014867916992188, -21.775168526367196],
            [-41.015767916992182, -21.771168526367195],
            [-41.017067916992183, -21.766568526367195],
            [-41.017867916992188, -21.762868526367196],
            [-41.018567916992183, -21.759868526367196],
            [-41.019267916992185, -21.757068526367195],
            [-41.019767916992187, -21.754068526367195],
            [-41.020667916992188, -21.750568526367196],
            [-41.021467916992187, -21.746968526367198],
            [-41.022267916992185, -21.742668526367197],
            [-41.022867916992183, -21.738568526367196],
            [-41.023667916992189, -21.734468526367195],
            [-41.023967916992184, -21.730568526367197],
            [-41.024367916992183, -21.725968526367197],
            [-41.024567916992183, -21.722268526367195],
            [-41.024767916992182, -21.719068526367195],
            [-41.024767916992182, -21.715568526367196],
            [-41.024567916992183, -21.712268526367197],
            [-41.024367916992183, -21.709068526367197],
            [-41.024067916992188, -21.705968526367197],
            [-41.024067916992188, -21.702568526367195],
            [-41.023767916992185, -21.698868526367196],
            [-41.023667916992189, -21.695968526367196],
            [-41.023267916992182, -21.692668526367196],
            [-41.022867916992183, -21.687968526367197],
            [-41.022367916992188, -21.683368526367197],
            [-41.021567916992183, -21.678968526367196],
            [-41.020667916992188, -21.675268526367198],
            [-41.020067916992183, -21.672168526367198],
            [-41.019367916992188, -21.668868526367195],
            [-41.018767916992182, -21.664868526367197],
            [-41.017967916992184, -21.660568526367197],
            [-41.017367916992185, -21.656968526367198],
            [-41.016767916992187, -21.653368526367196],
            [-41.016267916992184, -21.649068526367195],
            [-41.015667916992186, -21.645668526367196],
            [-41.015067916992187, -21.640768526367197],
            [-41.014567916992185, -21.637068526367198],
            [-41.014267916992182, -21.633468526367196],
            [-41.013567916992187, -21.629168526367195],
            [-41.013267916992184, -21.625468526367197],
            [-41.012767916992182, -21.621568526367195],
            [-41.015067916992187, -21.619368526367197],
            [-41.018267916992187, -21.620568526367197],
            [-41.023367916992186, -21.621568526367195],
            [-41.027267916992187, -21.621668526367195],
            [-41.030167916992184, -21.621068526367196],
            [-41.033067916992188, -21.620068526367195],
            [-41.035967916992185, -21.619768526367196],
            [-41.039767916992183, -21.619768526367196],
            [-41.045367916992184, -21.618368526367195],
            [-41.048567916992184, -21.617768526367197],
            [-41.047467916992183, -21.614668526367197],
            [-41.042767916992183, -21.616168526367197],
            [-41.037867916992184, -21.615868526367198],
            [-41.033367916992184, -21.616068526367197],
            [-41.028367916992188, -21.615768526367198],
            [-41.022967916992187, -21.615068526367196],
            [-41.019867916992183, -21.614368526367198],
            [-41.016467916992184, -21.612468526367195],
            [-41.019867916992183, -21.607168526367197],
            [-41.022867916992183, -21.605368526367197],
            [-41.026267916992182, -21.603868526367197],
            [-41.029267916992183, -21.602068526367198],
            [-41.032667916992182, -21.599168526367198],
            [-41.035967916992185, -21.596968526367196],
            [-41.038867916992189, -21.594068526367195],
            [-41.041667916992182, -21.589068526367196],
            [-41.043867916992184, -21.585968526367196],
            [-41.047567916992186, -21.588168526367195],
            [-41.046367916992182, -21.592968526367198],
            [-41.046967916992187, -21.596168526367197],
            [-41.048167916992185, -21.599168526367198],
            [-41.051067916992189, -21.597268526367195],
            [-41.051567916992184, -21.594468526367198],
            [-41.053567916992186, -21.585468526367197],
            [-41.054767916992184, -21.581768526367195],
            [-41.055467916992185, -21.576768526367196],
            [-41.056067916992184, -21.573568526367197],
            [-41.059467916992183, -21.568868526367197],
            [-41.059767916992186, -21.565468526367198],
            [-41.060767916992184, -21.561168526367197],
            [-41.063367916992185, -21.555568526367196],
            [-41.064567916992182, -21.549168526367197],
            [-41.069367916992185, -21.535568526367197],
            [-41.071567916992187, -21.528968526367198],
            [-41.072667916992188, -21.524768526367197],
            [-41.073167916992183, -21.521568526367197],
            [-41.073167916992183, -21.518268526367198],
            [-41.073167916992183, -21.514868526367195],
            [-41.072667916992188, -21.510968526367197],
            [-41.071867916992183, -21.507168526367195],
            [-41.070967916992188, -21.503068526367198],
            [-41.069667916992188, -21.499868526367198],
            [-41.068267916992184, -21.496568526367195],
            [-41.066367916992185, -21.492668526367197],
            [-41.063867916992187, -21.488368526367196],
            [-41.061567916992182, -21.485068526367197],
            [-41.059067916992184, -21.481768526367198],
            [-41.057267916992188, -21.479168526367197],
            [-41.055467916992185, -21.476968526367195],
            [-41.052267916992186, -21.473668526367195],
            [-41.049167916992182, -21.470268526367196],
            [-41.046367916992182, -21.467568526367195],
            [-41.042467916992187, -21.465668526367196],
            [-41.039967916992182, -21.463468526367198],
            [-41.037367916992189, -21.461168526367196],
            [-41.035567916992186, -21.457568526367197],
            [-41.032367916992186, -21.454568526367197],
            [-41.029767916992185, -21.452368526367195],
            [-41.027367916992183, -21.448568526367197],
            [-41.023967916992184, -21.445768526367196],
            [-41.020367916992186, -21.440768526367197],
            [-41.019067916992185, -21.436968526367195],
            [-41.017167916992186, -21.433768526367196],
            [-41.013967916992186, -21.429768526367198],
            [-41.011267916992182, -21.425868526367196],
            [-41.008867916992187, -21.422568526367197],
            [-41.006367916992183, -21.419268526367198],
            [-41.004867916992183, -21.414568526367198],
            [-41.003467916992186, -21.410568526367197],
            [-41.001667916992183, -21.406168526367196],
            [-40.999867916992187, -21.402868526367197],
            [-40.998267916992184, -21.400468526367195],
            [-40.996267916992188, -21.397968526367197],
            [-40.993867916992187, -21.395768526367196],
            [-40.991067916992186, -21.393668526367197],
            [-40.987567916992184, -21.392168526367197],
            [-40.983867916992182, -21.392068526367197],
            [-40.981767916992183, -21.389968526367195],
            [-40.980067916992184, -21.386868526367195],
            [-40.978067916992188, -21.384068526367198],
            [-40.975667916992187, -21.381768526367196],
            [-40.972767916992183, -21.378068526367198],
            [-40.969467916992187, -21.375168526367197],
            [-40.968067916992183, -21.371068526367196],
            [-40.965967916992184, -21.367768526367197],
            [-40.965167916992186, -21.364968526367196],
            [-40.964067916992185, -21.362268526367195],
            [-40.961867916992183, -21.359368526367195],
            [-40.962967916992184, -21.354168526367197],
            [-40.963967916992182, -21.349768526367196],
            [-40.964367916992188, -21.345968526367198],
            [-40.964567916992188, -21.342568526367195],
            [-40.964367916992188, -21.337968526367195],
            [-40.963967916992182, -21.332868526367196],
            [-40.962967916992184, -21.328268526367197],
            [-40.961867916992183, -21.323968526367196],
            [-40.960467916992187, -21.319668526367195],
            [-40.959567916992185, -21.316568526367195],
            [-40.958167916992188, -21.312468526367198],
            [-40.958767916992187, -21.308368526367197],
            [-40.959067916992183, -21.303968526367196],
            [-40.959367916992186, -21.301068526367196],
            [-40.959267916992182, -21.297168526367198],
            [-40.959867916992188, -21.294168526367198],
            [-40.960667916992186, -21.290968526367195],
            [-40.961567916992188, -21.285568526367197],
            [-40.961867916992183, -21.280468526367198],
            [-40.962267916992182, -21.275468526367195],
            [-40.962267916992182, -21.270768526367196],
            [-40.962067916992183, -21.266768526367198],
            [-40.961267916992185, -21.262168526367198],
            [-40.960767916992182, -21.258468526367196],
            [-40.960167916992184, -21.255568526367195],
            [-40.958767916992187, -21.250768526367196],
            [-40.957567916992183, -21.246568526367195],
            [-40.955767916992187, -21.242568526367197],
            [-40.953967916992184, -21.237968526367197],
            [-40.952167916992188, -21.233968526367196],
            [-40.950167916992186, -21.230068526367198],
            [-40.948167916992183, -21.226768526367195],
            [-40.946267916992184, -21.223468526367196],
            [-40.944267916992182, -21.220568526367195],
            [-40.942067916992187, -21.217268526367196],
            [-40.939467916992186, -21.212968526367195],
            [-40.936867916992185, -21.208668526367195],
            [-40.935267916992188, -21.204968526367196],
            [-40.933767916992188, -21.202068526367196],
            [-40.932167916992185, -21.199068526367196],
            [-40.930067916992186, -21.195268526367197],
            [-40.926967916992183, -21.190868526367197],
            [-40.924667916992185, -21.187468526367198],
            [-40.922567916992186, -21.184368526367198],
            [-40.920867916992187, -21.181668526367197],
            [-40.919167916992187, -21.178968526367196],
            [-40.917267916992188, -21.176368526367195],
            [-40.915067916992186, -21.173568526367195],
            [-40.912567916992188, -21.170668526367198],
            [-40.908967916992182, -21.167268526367195],
            [-40.906167916992182, -21.164968526367197],
            [-40.902667916992186, -21.162568526367195],
            [-40.899467916992187, -21.160568526367197],
            [-40.896767916992182, -21.159168526367196],
            [-40.892967916992184, -21.156568526367195],
            [-40.890467916992186, -21.153368526367196],
            [-40.887667916992186, -21.149368526367198],
            [-40.884567916992182, -21.145768526367196],
            [-40.881867916992185, -21.143168526367198],
            [-40.878867916992185, -21.140768526367197],
            [-40.876267916992184, -21.138768526367198],
            [-40.873367916992187, -21.137068526367198],
            [-40.870567916992186, -21.135368526367195],
            [-40.867767916992186, -21.133768526367195],
            [-40.864467916992183, -21.132368526367195],
            [-40.861367916992187, -21.130168526367196],
            [-40.858867916992182, -21.127668526367195],
            [-40.857867916992184, -21.124468526367195],
            [-40.857467916992185, -21.121568526367195],
            [-40.856067916992188, -21.117568526367197],
            [-40.854467916992185, -21.113968526367195],
            [-40.852867916992182, -21.111068526367195],
            [-40.851667916992184, -21.108268526367198],
            [-40.849967916992185, -21.104268526367196],
            [-40.847767916992183, -21.099868526367196],
            [-40.846267916992183, -21.096368526367197],
            [-40.844467916992187, -21.092868526367198],
            [-40.842667916992184, -21.088768526367197],
            [-40.841467916992187, -21.085468526367197],
            [-40.840167916992186, -21.082168526367198],
            [-40.838667916992186, -21.077668526367198],
            [-40.837367916992186, -21.073468526367197],
            [-40.836267916992185, -21.069168526367196],
            [-40.835367916992183, -21.065268526367195],
            [-40.834267916992182, -21.061368526367197],
            [-40.833267916992185, -21.057668526367195],
            [-40.832067916992187, -21.053568526367197],
            [-40.830467916992184, -21.049168526367197],
            [-40.828167916992186, -21.045268526367195],
            [-40.824967916992186, -21.043668526367195],
            [-40.821367916992187, -21.041968526367196],
            [-40.818767916992186, -21.038768526367196],
            [-40.817067916992187, -21.036268526367195],
            [-40.814367916992182, -21.035168526367197],
            [-40.812267916992184, -21.032568526367196],
            [-40.811667916992185, -21.028468526367195],
            [-40.810867916992187, -21.023968526367195],
            [-40.809967916992186, -21.019368526367195],
            [-40.808867916992185, -21.014668526367196],
            [-40.807167916992185, -21.009868526367196],
            [-40.804967916992183, -21.005768526367195],
            [-40.805867916992185, -21.002168526367196],
            [-40.807967916992183, -20.999668526367195],
            [-40.809467916992183, -20.995268526367198],
            [-40.810467916992188, -20.991368526367197],
            [-40.810867916992187, -20.987468526367195],
            [-40.811067916992187, -20.983568526367197],
            [-40.810867916992187, -20.980568526367197],
            [-40.810867916992187, -20.976668526367195],
            [-40.810467916992188, -20.972668526367197],
            [-40.809867916992182, -20.967568526367195],
            [-40.809367916992187, -20.963668526367197],
            [-40.808567916992182, -20.958768526367198],
            [-40.807667916992187, -20.955168526367196],
            [-40.806867916992182, -20.951568526367197],
            [-40.803967916992185, -20.943268526367195],
            [-40.802867916992184, -20.940468526367198],
            [-40.801167916992185, -20.936268526367197],
            [-40.799367916992182, -20.932968526367198],
            [-40.796767916992188, -20.928268526367198],
            [-40.795367916992184, -20.925768526367197],
            [-40.790067916992186, -20.918968526367195],
            [-40.786367916992184, -20.914968526367197],
            [-40.784167916992182, -20.911468526367198],
            [-40.781667916992184, -20.908068526367195],
            [-40.777667916992186, -20.905068526367195],
            [-40.776567916992185, -20.900468526367195],
            [-40.774567916992183, -20.895668526367196],
            [-40.771167916992184, -20.892168526367197],
            [-40.767367916992185, -20.891268526367195],
            [-40.764267916992182, -20.887368526367197],
            [-40.762167916992183, -20.883868526367195],
            [-40.760667916992183, -20.881368526367197],
            [-40.759067916992187, -20.878768526367196],
            [-40.761767916992184, -20.875468526367197],
            [-40.759967916992188, -20.871868526367198],
            [-40.758067916992182, -20.868368526367195],
            [-40.760167916992188, -20.866168526367197],
            [-40.757767916992186, -20.863568526367196],
            [-40.754967916992186, -20.859268526367195],
            [-40.752167916992185, -20.856368526367195],
            [-40.749667916992188, -20.853968526367197],
            [-40.746267916992188, -20.850068526367195],
            [-40.742567916992186, -20.847268526367195],
            [-40.738667916992185, -20.845268526367196],
            [-40.733867916992182, -20.843768526367196],
            [-40.729267916992185, -20.842368526367196],
            [-40.725967916992182, -20.842068526367196],
            [-40.722667916992187, -20.845868526367195],
            [-40.718667916992182, -20.844868526367197],
            [-40.713967916992182, -20.842568526367195],
            [-40.710467916992187, -20.839768526367195],
            [-40.706867916992188, -20.837268526367197],
            [-40.702867916992183, -20.836168526367196],
            [-40.699067916992185, -20.833568526367195],
            [-40.694667916992188, -20.834368526367196],
            [-40.691967916992184, -20.831468526367196],
            [-40.689367916992182, -20.827968526367197],
            [-40.684067916992184, -20.826568526367197],
            [-40.681167916992187, -20.823768526367196],
            [-40.676667916992187, -20.822168526367197],
            [-40.672567916992186, -20.821268526367195],
            [-40.671067916992186, -20.818468526367198],
            [-40.667467916992187, -20.817068526367198],
            [-40.665667916992184, -20.813068526367196],
            [-40.662067916992186, -20.811168526367197],
            [-40.657367916992186, -20.810468526367195],
            [-40.654867916992188, -20.808068526367197],
            [-40.652567916992183, -20.806068526367195],
            [-40.649267916992187, -20.807168526367196],
            [-40.645367916992186, -20.808668526367196],
            [-40.641467916992184, -20.810168526367196],
            [-40.637967916992189, -20.811568526367196],
            [-40.634967916992188, -20.813068526367196],
            [-40.631367916992183, -20.815868526367197],
            [-40.629067916992184, -20.820168526367198],
            [-40.631267916992186, -20.824868526367197],
            [-40.632767916992186, -20.829568526367197],
            [-40.632767916992186, -20.832568526367197],
            [-40.632367916992187, -20.835468526367197],
            [-40.630767916992184, -20.839468526367195],
            [-40.627667916992188, -20.840168526367197],
            [-40.625467916992186, -20.835868526367197],
            [-40.621867916992187, -20.833968526367197],
            [-40.619667916992185, -20.830968526367197],
            [-40.617567916992186, -20.826868526367196],
            [-40.615767916992183, -20.824268526367195],
            [-40.614667916992182, -20.819568526367195],
            [-40.613567916992189, -20.815868526367197],
            [-40.611867916992182, -20.813268526367196],
            [-40.610767916992188, -20.809968526367197],
            [-40.607567916992188, -20.805868526367195],
            [-40.603067916992188, -20.804968526367198],
            [-40.598667916992184, -20.804968526367198],
            [-40.594567916992183, -20.802868526367195],
            [-40.590167916992186, -20.801968526367197],
            [-40.586967916992187, -20.804668526367195],
            [-40.582867916992186, -20.803068526367195],
            [-40.582367916992183, -20.798268526367195],
            [-40.580667916992184, -20.794468526367197],
            [-40.577567916992187, -20.790068526367197],
            [-40.574567916992187, -20.787568526367195],
            [-40.576467916992186, -20.783668526367197],
            [-40.576767916992182, -20.780168526367195],
            [-40.576567916992182, -20.776968526367195],
            [-40.575767916992184, -20.773468526367196],
            [-40.574567916992187, -20.770668526367196],
            [-40.572667916992188, -20.767968526367195],
            [-40.570167916992183, -20.764068526367197],
            [-40.568067916992185, -20.761068526367197],
            [-40.565267916992184, -20.758468526367196],
            [-40.562967916992186, -20.755568526367195],
            [-40.560767916992184, -20.752068526367196],
            [-40.558667916992185, -20.748668526367197],
            [-40.556667916992183, -20.746568526367195],
            [-40.554367916992184, -20.744468526367196],
            [-40.551867916992187, -20.742268526367198],
            [-40.548567916992184, -20.740268526367196],
            [-40.543167916992182, -20.738568526367196],
            [-40.538867916992189, -20.738568526367196],
            [-40.535967916992185, -20.742168526367195],
            [-40.533367916992184, -20.740768526367198],
            [-40.532267916992183, -20.735868526367195],
            [-40.527067916992188, -20.733568526367197],
            [-40.527267916992187, -20.729468526367196],
            [-40.524267916992187, -20.729268526367196],
            [-40.520967916992184, -20.728668526367198],
            [-40.524067916992188, -20.725668526367198],
            [-40.522967916992187, -20.720868526367195],
            [-40.520467916992182, -20.716768526367197],
            [-40.518667916992186, -20.712968526367195],
            [-40.516567916992187, -20.709568526367196],
            [-40.514667916992188, -20.705368526367195],
            [-40.514367916992185, -20.702068526367196],
            [-40.513567916992187, -20.698168526367198],
            [-40.512467916992186, -20.695168526367198],
            [-40.510767916992187, -20.691668526367195],
            [-40.508867916992187, -20.688568526367195],
            [-40.507167916992188, -20.685768526367195],
            [-40.504567916992187, -20.682768526367198],
            [-40.501667916992183, -20.678568526367197],
            [-40.499967916992183, -20.675068526367195],
            [-40.496567916992184, -20.673968526367197],
            [-40.494367916992182, -20.670868526367197],
            [-40.492167916992187, -20.668168526367197],
            [-40.494367916992182, -20.665568526367196],
            [-40.496267916992188, -20.662868526367195],
            [-40.494067916992186, -20.659468526367196],
            [-40.491167916992183, -20.656968526367198],
            [-40.487467916992188, -20.654768526367196],
            [-40.484167916992185, -20.653068526367196],
            [-40.479767916992188, -20.652568526367197],
            [-40.475567916992183, -20.653468526367195],
            [-40.474467916992182, -20.656268526367196],
            [-40.473467916992185, -20.660268526367197],
            [-40.467667916992184, -20.660968526367196],
            [-40.467367916992188, -20.656968526367198],
            [-40.466967916992182, -20.653368526367196],
            [-40.471967916992185, -20.652368526367198],
            [-40.470567916992188, -20.649068526367195],
            [-40.468067916992183, -20.646868526367196],
            [-40.464867916992183, -20.644868526367198],
            [-40.468767916992185, -20.642168526367197],
            [-40.470067916992186, -20.639368526367196],
            [-40.467867916992184, -20.636768526367195],
            [-40.469467916992187, -20.633468526367196],
            [-40.466967916992182, -20.631568526367197],
            [-40.464867916992183, -20.629668526367198],
            [-40.465167916992186, -20.625768526367196],
            [-40.462367916992186, -20.623368526367198],
            [-40.458467916992184, -20.623068526367195],
            [-40.454667916992186, -20.623368526367198],
            [-40.449967916992186, -20.625168526367197],
            [-40.446567916992187, -20.628768526367196],
            [-40.446067916992185, -20.632168526367195],
            [-40.449067916992185, -20.635368526367195],
            [-40.445667916992186, -20.637468526367197],
            [-40.441867916992187, -20.634668526367197],
            [-40.437467916992183, -20.634868526367196],
            [-40.434967916992186, -20.637468526367197],
            [-40.433367916992182, -20.641068526367196],
            [-40.430467916992185, -20.638568526367198],
            [-40.429367916992184, -20.635768526367197],
            [-40.425367916992187, -20.635768526367197],
            [-40.425767916992186, -20.631568526367197],
            [-40.424667916992185, -20.627768526367195],
            [-40.423067916992188, -20.624868526367198],
            [-40.421467916992185, -20.622268526367197],
            [-40.419267916992183, -20.619068526367197],
            [-40.415967916992187, -20.615068526367196],
            [-40.413867916992189, -20.611468526367197],
            [-40.411967916992182, -20.608968526367196],
            [-40.410167916992187, -20.606668526367198],
            [-40.407067916992183, -20.602768526367196],
            [-40.404467916992182, -20.599168526367198],
            [-40.403667916992184, -20.595268526367196],
            [-40.403767916992187, -20.591268526367195],
            [-40.403667916992184, -20.587968526367195],
            [-40.402967916992182, -20.584068526367197],
            [-40.402267916992187, -20.580368526367195],
            [-40.401167916992186, -20.577468526367195],
            [-40.400067916992185, -20.574668526367198],
            [-40.398667916992189, -20.571268526367195],
            [-40.396867916992186, -20.567168526367198],
            [-40.395467916992182, -20.564068526367198],
            [-40.393567916992183, -20.560268526367196],
            [-40.391867916992183, -20.557168526367196],
            [-40.390067916992187, -20.554468526367195],
            [-40.388167916992188, -20.551468526367195],
            [-40.386367916992185, -20.548868526367198],
            [-40.384567916992182, -20.546468526367196],
            [-40.382667916992183, -20.544168526367198],
            [-40.380467916992188, -20.540868526367195],
            [-40.378267916992186, -20.538068526367198],
            [-40.376867916992182, -20.535068526367198],
            [-40.374867916992187, -20.532068526367198],
            [-40.371967916992183, -20.528968526367198],
            [-40.370167916992187, -20.526468526367196],
            [-40.368367916992185, -20.522568526367195],
            [-40.364467916992183, -20.518968526367196],
            [-40.359967916992183, -20.518668526367197],
            [-40.359667916992187, -20.515068526367195],
            [-40.359467916992187, -20.510468526367195],
            [-40.358067916992184, -20.506268526367197],
            [-40.356367916992184, -20.502368526367196],
            [-40.354667916992184, -20.499468526367195],
            [-40.353067916992188, -20.496968526367198],
            [-40.351367916992189, -20.493068526367196],
            [-40.349967916992185, -20.488968526367195],
            [-40.348667916992184, -20.485768526367195],
            [-40.347867916992186, -20.481668526367198],
            [-40.347267916992188, -20.478168526367195],
            [-40.345967916992187, -20.475268526367195],
            [-40.343967916992185, -20.471668526367196],
            [-40.341667916992186, -20.467268526367196],
            [-40.340067916992183, -20.463968526367196],
            [-40.337967916992184, -20.460168526367195],
            [-40.335967916992182, -20.456768526367195],
            [-40.333767916992187, -20.453968526367195],
            [-40.331767916992185, -20.449968526367197],
            [-40.329967916992182, -20.445768526367196],
            [-40.328967916992184, -20.442068526367198],
            [-40.326867916992185, -20.438068526367196],
            [-40.325367916992185, -20.434368526367198],
            [-40.323567916992182, -20.430868526367195],
            [-40.321767916992187, -20.427968526367195],
            [-40.319067916992182, -20.426068526367196],
            [-40.321267916992184, -20.422468526367197],
            [-40.322167916992186, -20.418868526367195],
            [-40.321567916992187, -20.415668526367195],
            [-40.321267916992184, -20.412768526367195],
            [-40.320267916992186, -20.409168526367196],
            [-40.319367916992185, -20.405468526367198],
            [-40.317767916992182, -20.402068526367195],
            [-40.316367916992185, -20.398468526367196],
            [-40.314867916992185, -20.394368526367195],
            [-40.313767916992184, -20.391568526367195],
            [-40.311567916992182, -20.387368526367197],
            [-40.309767916992186, -20.384268526367197],
            [-40.308267916992186, -20.381368526367197],
            [-40.304967916992183, -20.376568526367198],
            [-40.303067916992184, -20.372768526367196],
            [-40.301167916992185, -20.369668526367196],
            [-40.298367916992184, -20.365768526367198],
            [-40.295867916992187, -20.362468526367195],
            [-40.293567916992188, -20.359968526367197],
            [-40.290867916992184, -20.358168526367198],
            [-40.288167916992187, -20.356668526367198],
            [-40.284267916992185, -20.353868526367197],
            [-40.283367916992184, -20.348368526367196],
            [-40.283367916992184, -20.344568526367198],
            [-40.282367916992186, -20.340868526367196],
            [-40.280667916992186, -20.336568526367195],
            [-40.277667916992186, -20.333568526367195],
            [-40.273667916992189, -20.332368526367198],
            [-40.272367916992188, -20.329268526367198],
            [-40.268967916992182, -20.328268526367197],
            [-40.267367916992185, -20.324868526367197],
            [-40.271467916992187, -20.322468526367196],
            [-40.276467916992182, -20.320968526367196],
            [-40.278367916992188, -20.315468526367198],
            [-40.279067916992183, -20.312368526367198],
            [-40.276467916992182, -20.310468526367195],
            [-40.279267916992183, -20.308268526367197],
            [-40.283367916992184, -20.308768526367196],
            [-40.287367916992189, -20.309068526367195],
            [-40.289867916992186, -20.307568526367195],
            [-40.290267916992185, -20.303268526367198],
            [-40.289467916992187, -20.298868526367198],
            [-40.287767916992188, -20.295068526367196],
            [-40.287067916992186, -20.292168526367195],
            [-40.289567916992183, -20.290268526367196],
            [-40.290067916992186, -20.287068526367197],
            [-40.288467916992182, -20.283168526367195],
            [-40.286267916992188, -20.280068526367195],
            [-40.283467916992187, -20.277268526367195],
            [-40.280867916992186, -20.275368526367195],
            [-40.277867916992186, -20.273768526367196],
            [-40.275367916992188, -20.270968526367195],
            [-40.271867916992186, -20.269368526367195],
            [-40.268467916992186, -20.267368526367196],
            [-40.263567916992187, -20.266068526367196],
            [-40.259067916992187, -20.265968526367196],
            [-40.254367916992187, -20.267968526367195],
            [-40.254067916992184, -20.271268526367198],
            [-40.252067916992182, -20.274368526367198],
            [-40.249367916992185, -20.278368526367196],
            [-40.247967916992188, -20.282368526367197],
            [-40.245867916992182, -20.285868526367196],
            [-40.241967916992188, -20.289568526367198],
            [-40.236867916992182, -20.294268526367198],
            [-40.232767916992188, -20.292268526367195],
            [-40.233567916992186, -20.284868526367195],
            [-40.233867916992182, -20.275468526367195],
            [-40.231167916992185, -20.270368526367196],
            [-40.230067916992184, -20.265768526367197],
            [-40.228067916992188, -20.263468526367195],
            [-40.223667916992184, -20.257168526367195],
            [-40.220267916992185, -20.258468526367196],
            [-40.221667916992182, -20.254968526367197],
            [-40.223367916992188, -20.252368526367196],
            [-40.220867916992184, -20.248668526367197],
            [-40.217667916992184, -20.245468526367198],
            [-40.215467916992182, -20.241468526367196],
            [-40.213467916992187, -20.238668526367196],
            [-40.215367916992186, -20.235868526367195],
            [-40.213767916992182, -20.232268526367196],
            [-40.210767916992182, -20.230068526367198],
            [-40.208167916992188, -20.227768526367196],
            [-40.204967916992182, -20.224268526367197],
            [-40.201067916992187, -20.219568526367198],
            [-40.198767916992182, -20.215868526367196],
            [-40.198867916992185, -20.212368526367197],
            [-40.198167916992183, -20.208968526367197],
            [-40.196567916992187, -20.205468526367195],
            [-40.194567916992185, -20.201368526367197],
            [-40.192167916992183, -20.198568526367197],
            [-40.190967916992186, -20.195168526367198],
            [-40.190467916992183, -20.191868526367195],
            [-40.190467916992183, -20.186968526367195],
            [-40.190967916992186, -20.183568526367196],
            [-40.189167916992183, -20.179768526367198],
            [-40.188267916992189, -20.176468526367195],
            [-40.186267916992186, -20.173168526367196],
            [-40.185167916992185, -20.169568526367197],
            [-40.184367916992187, -20.166668526367197],
            [-40.184067916992184, -20.162768526367195],
            [-40.184067916992184, -20.159168526367196],
            [-40.183367916992182, -20.155868526367197],
            [-40.184367916992187, -20.151968526367195],
            [-40.184067916992184, -20.147868526367198],
            [-40.182967916992183, -20.144368526367195],
            [-40.181667916992183, -20.141368526367195],
            [-40.181867916992182, -20.138468526367195],
            [-40.181567916992186, -20.134968526367196],
            [-40.179767916992184, -20.130468526367196],
            [-40.177967916992188, -20.124368526367196],
            [-40.176367916992184, -20.120468526367198],
            [-40.175267916992183, -20.117268526367198],
            [-40.174967916992188, -20.114168526367198],
            [-40.172867916992182, -20.110868526367195],
            [-40.172167916992187, -20.107768526367195],
            [-40.172767916992186, -20.104968526367195],
            [-40.173167916992185, -20.100968526367197],
            [-40.173567916992184, -20.097368526367195],
            [-40.173067916992188, -20.094168526367195],
            [-40.173867916992187, -20.088968526367196],
            [-40.173867916992187, -20.083968526367197],
            [-40.173567916992184, -20.079668526367197],
            [-40.173367916992184, -20.076568526367197],
            [-40.174767916992188, -20.073968526367196],
            [-40.176467916992188, -20.070968526367196],
            [-40.178667916992183, -20.069168526367196],
            [-40.180767916992188, -20.066868526367195],
            [-40.182267916992188, -20.063768526367195],
            [-40.184767916992186, -20.061568526367196],
            [-40.187167916992188, -20.059468526367198],
            [-40.189167916992183, -20.057268526367196],
            [-40.192767916992182, -20.054668526367195],
            [-40.190567916992187, -20.052168526367197],
            [-40.189167916992183, -20.048368526367195],
            [-40.189067916992187, -20.045068526367196],
            [-40.185167916992185, -20.041368526367197],
            [-40.181567916992186, -20.038968526367196],
            [-40.177967916992188, -20.038068526367198],
            [-40.173967916992183, -20.037068526367197],
            [-40.169767916992186, -20.037368526367196],
            [-40.163267916992183, -20.037968526367198],
            [-40.159167916992182, -20.035868526367196],
            [-40.159167916992182, -20.032568526367196],
            [-40.158467916992187, -20.029768526367196],
            [-40.158367916992184, -20.025668526367195],
            [-40.159267916992185, -20.020668526367196],
            [-40.157867916992188, -20.015768526367197],
            [-40.154467916992182, -20.011068526367197],
            [-40.151467916992182, -20.007168526367195],
            [-40.148967916992184, -20.005668526367195],
            [-40.148367916992186, -20.002668526367195],
            [-40.147667916992184, -19.999368526367196],
            [-40.148167916992186, -19.995068526367195],
            [-40.148367916992186, -19.992168526367195],
            [-40.146867916992186, -19.988568526367196],
            [-40.143267916992187, -19.985368526367196],
            [-40.141467916992184, -19.980068526367198],
            [-40.139267916992182, -19.975868526367197],
            [-40.137367916992183, -19.972068526367195],
            [-40.134367916992183, -19.968168526367197],
            [-40.132767916992186, -19.965468526367196],
            [-40.133867916992187, -19.961468526367195],
            [-40.136867916992188, -19.957868526367196],
            [-40.139867916992188, -19.955468526367195],
            [-40.143267916992187, -19.953868526367195],
            [-40.146767916992182, -19.954268526367198],
            [-40.150167916992189, -19.954268526367198],
            [-40.153667916992184, -19.952468526367195],
            [-40.152567916992183, -19.949368526367195],
            [-40.150067916992185, -19.946568526367198],
            [-40.147567916992188, -19.945168526367198],
            [-40.144567916992187, -19.944868526367195],
            [-40.142067916992183, -19.943068526367195],
            [-40.137967916992189, -19.941268526367196],
            [-40.134267916992187, -19.939168526367197],
            [-40.132167916992188, -19.936368526367197],
            [-40.129067916992184, -19.934168526367195],
            [-40.124767916992184, -19.932968526367198],
            [-40.121067916992182, -19.931868526367197],
            [-40.117667916992183, -19.930868526367195],
            [-40.115467916992188, -19.928568526367197],
            [-40.111967916992185, -19.925768526367197],
            [-40.107867916992184, -19.923668526367198],
            [-40.103667916992187, -19.921868526367195],
            [-40.099867916992189, -19.918868526367195],
            [-40.098467916992185, -19.915268526367196],
            [-40.097367916992184, -19.911268526367195],
            [-40.098067916992186, -19.906268526367196],
            [-40.095967916992187, -19.902268526367195],
            [-40.093167916992186, -19.898768526367196],
            [-40.091967916992182, -19.894568526367195],
            [-40.089267916992185, -19.892668526367196],
            [-40.089267916992185, -19.889368526367196],
            [-40.087667916992189, -19.886768526367195],
            [-40.086567916992188, -19.883268526367196],
            [-40.085067916992188, -19.879468526367198],
            [-40.083267916992185, -19.876868526367197],
            [-40.081467916992182, -19.874468526367195],
            [-40.078267916992182, -19.873268526367195],
            [-40.076767916992182, -19.870468526367198],
            [-40.074567916992187, -19.867468526367198],
            [-40.072067916992182, -19.864468526367197],
            [-40.069167916992185, -19.861668526367197],
            [-40.067367916992183, -19.858068526367198],
            [-40.065467916992183, -19.855668526367197],
            [-40.063367916992185, -19.852468526367197],
            [-40.060767916992184, -19.849568526367197],
            [-40.061567916992182, -19.846768526367196],
            [-40.061967916992188, -19.843068526367198],
            [-40.059367916992187, -19.839868526367198],
            [-40.054967916992183, -19.843068526367198],
            [-40.054667916992187, -19.838768526367197],
            [-40.057267916992188, -19.836168526367196],
            [-40.058367916992182, -19.831868526367195],
            [-40.058067916992186, -19.827168526367196],
            [-40.056867916992182, -19.821268526367195],
            [-40.055167916992183, -19.815768526367197],
            [-40.053567916992186, -19.812768526367197],
            [-40.051167916992185, -19.809168526367195],
            [-40.049467916992185, -19.806568526367197],
            [-40.045667916992187, -19.801368526367195],
            [-40.041467916992183, -19.796468526367196],
            [-40.036967916992182, -19.791068526367198],
            [-40.029867916992188, -19.783168526367195],
            [-40.025067916992185, -19.778068526367196],
            [-40.022367916992188, -19.775368526367195],
            [-40.019767916992187, -19.772568526367195],
            [-40.015367916992183, -19.768168526367198],
            [-40.007967916992186, -19.761268526367196],
            [-40.005167916992185, -19.758468526367196],
            [-39.996167916992185, -19.750168526367197],
            [-39.988867916992184, -19.743668526367195],
            [-39.977867916992182, -19.734168526367196],
            [-39.971767916992185, -19.728968526367197],
            [-39.968067916992183, -19.725668526367198],
            [-39.965367916992186, -19.723868526367195],
            [-39.962667916992189, -19.721968526367196],
            [-39.959367916992186, -19.719568526367198],
            [-39.954567916992183, -19.715868526367196],
            [-39.951867916992185, -19.713968526367196],
            [-39.947667916992188, -19.710868526367197],
            [-39.942767916992182, -19.707268526367198],
            [-39.939367916992182, -19.704568526367197],
            [-39.931567916992186, -19.699268526367195],
            [-39.926067916992189, -19.695968526367196],
            [-39.918867916992184, -19.691268526367196],
            [-39.911667916992187, -19.686968526367195],
            [-39.909167916992182, -19.685468526367195],
            [-39.905667916992186, -19.683568526367196],
            [-39.902567916992183, -19.682168526367196],
            [-39.899767916992182, -19.680768526367196],
            [-39.894367916992188, -19.678068526367195],
            [-39.890167916992183, -19.676068526367196],
            [-39.887067916992187, -19.674968526367195],
            [-39.882767916992186, -19.672868526367196],
            [-39.879567916992187, -19.671368526367196],
            [-39.874967916992183, -19.669468526367197],
            [-39.870467916992183, -19.667868526367197],
            [-39.866867916992184, -19.666668526367197],
            [-39.863067916992186, -19.664968526367197],
            [-39.859267916992188, -19.663668526367196],
            [-39.856067916992188, -19.662368526367196],
            [-39.852567916992186, -19.661168526367195],
            [-39.849567916992186, -19.660068526367198],
            [-39.845967916992187, -19.659168526367196],
            [-39.842267916992185, -19.658168526367195],
            [-39.838767916992182, -19.657368526367197],
            [-39.834067916992183, -19.656168526367196],
            [-39.828267916992182, -19.654768526367196],
            [-39.824267916992184, -19.654268526367197],
            [-39.820167916992183, -19.655168526367195],
            [-39.815567916992187, -19.653768526367195],
            [-39.811667916992185, -19.650368526367195],
            [-39.810867916992187, -19.646568526367197],
            [-39.811267916992186, -19.642868526367195],
            [-39.809467916992183, -19.637068526367198],
            [-39.807967916992183, -19.631868526367196],
            [-39.804667916992187, -19.626668526367197],
            [-39.802167916992182, -19.622468526367197],
            [-39.800367916992187, -19.619068526367197],
            [-39.798167916992185, -19.615268526367196],
            [-39.796167916992182, -19.611068526367195],
            [-39.794467916992183, -19.607768526367195],
            [-39.791767916992185, -19.602868526367196],
            [-39.789967916992182, -19.599468526367197],
            [-39.788367916992186, -19.596468526367197],
            [-39.785967916992185, -19.591268526367195],
            [-39.783767916992183, -19.587068526367197],
            [-39.782267916992183, -19.583968526367197],
            [-39.778367916992188, -19.576068526367198],
            [-39.774467916992187, -19.567668526367196],
            [-39.771167916992184, -19.560168526367196],
            [-39.766767916992187, -19.550868526367196],
            [-39.762367916992183, -19.539568526367198],
            [-39.760667916992183, -19.535568526367197],
            [-39.757067916992185, -19.526968526367195],
            [-39.754167916992188, -19.520968526367195],
            [-39.751667916992183, -19.514668526367196],
            [-39.749867916992187, -19.510468526367195],
            [-39.748067916992184, -19.506568526367197],
            [-39.745167916992187, -19.500868526367196],
            [-39.743367916992185, -19.497268526367197],
            [-39.741567916992182, -19.493568526367195],
            [-39.738667916992185, -19.487468526367195],
            [-39.737567916992184, -19.484768526367198],
            [-39.735867916992184, -19.480968526367196],
            [-39.733567916992186, -19.475768526367197],
            [-39.730667916992182, -19.469268526367195],
            [-39.728867916992186, -19.463968526367196],
            [-39.726967916992187, -19.458268526367196],
            [-39.725867916992186, -19.454668526367197],
            [-39.724167916992187, -19.447968526367195],
            [-39.722667916992187, -19.444568526367195],
            [-39.721367916992186, -19.441568526367195],
            [-39.720367916992188, -19.438268526367196],
            [-39.718767916992185, -19.433368526367197],
            [-39.716967916992182, -19.428868526367197],
            [-39.714767916992187, -19.423368526367195],
            [-39.713367916992183, -19.419168526367198],
            [-39.711267916992185, -19.414468526367195],
            [-39.709567916992185, -19.410568526367197],
            [-39.707867916992186, -19.404768526367196],
            [-39.705767916992187, -19.400768526367198],
            [-39.703567916992185, -19.394268526367195],
            [-39.702167916992188, -19.390068526367195],
            [-39.700967916992184, -19.386068526367197],
            [-39.699967916992186, -19.382368526367195],
            [-39.699567916992187, -19.378368526367197],
            [-39.698467916992186, -19.374468526367195],
            [-39.697467916992188, -19.371268526367196],
            [-39.696567916992187, -19.367568526367197],
            [-39.696067916992185, -19.364768526367197],
            [-39.695267916992186, -19.360568526367196],
            [-39.694867916992187, -19.356468526367195],
            [-39.694267916992182, -19.351668526367195],
            [-39.693867916992183, -19.348368526367196],
            [-39.693467916992184, -19.344768526367197],
            [-39.692767916992182, -19.340168526367197],
            [-39.692367916992183, -19.335468526367197],
            [-39.691867916992187, -19.330768526367198],
            [-39.691367916992185, -19.325168526367197],
            [-39.690967916992186, -19.321868526367197],
            [-39.690467916992183, -19.317068526367198],
            [-39.689867916992185, -19.311568526367196],
            [-39.689067916992187, -19.304768526367198],
            [-39.689067916992187, -19.301468526367195],
            [-39.689067916992187, -19.298568526367195],
            [-39.689067916992187, -19.293168526367197],
            [-39.689367916992182, -19.289168526367195],
            [-39.689867916992185, -19.285668526367196],
            [-39.690767916992186, -19.279768526367196],
            [-39.691367916992185, -19.274068526367195],
            [-39.692367916992183, -19.268468526367197],
            [-39.693867916992183, -19.260668526367198],
            [-39.694367916992185, -19.257668526367198],
            [-39.695467916992186, -19.251268526367195],
            [-39.696667916992183, -19.244168526367197],
            [-39.697367916992185, -19.241068526367197],
            [-39.700467916992189, -19.226668526367195],
            [-39.701767916992182, -19.220568526367195],
            [-39.702167916992188, -19.217068526367196],
            [-39.702967916992186, -19.212868526367195],
            [-39.703767916992184, -19.209368526367196],
            [-39.704367916992183, -19.205668526367198],
            [-39.705467916992184, -19.198468526367197],
            [-39.705967916992186, -19.195268526367197],
            [-39.706467916992182, -19.191868526367195],
            [-39.706867916992188, -19.187968526367197],
            [-39.707967916992182, -19.182268526367196],
            [-39.708467916992184, -19.179368526367195],
            [-39.709067916992183, -19.174668526367196],
            [-39.709667916992188, -19.170868526367197],
            [-39.710367916992183, -19.166768526367196],
            [-39.711267916992185, -19.159468526367196],
            [-39.711767916992187, -19.156568526367195],
            [-39.712267916992182, -19.152568526367197],
            [-39.712867916992188, -19.148168526367197],
            [-39.713467916992187, -19.143168526367198],
            [-39.713967916992182, -19.139668526367196],
            [-39.714467916992184, -19.135268526367195],
            [-39.715067916992183, -19.130768526367195],
            [-39.715867916992188, -19.125468526367197],
            [-39.716267916992187, -19.120468526367198],
            [-39.717067916992185, -19.116368526367197],
            [-39.717567916992188, -19.110368526367196],
            [-39.718367916992186, -19.104968526367195],
            [-39.720267916992185, -19.099168526367198],
            [-39.719467916992187, -19.093368526367197],
            [-39.719867916992186, -19.090068526367197],
            [-39.720867916992184, -19.086568526367195],
            [-39.721467916992182, -19.081868526367195],
            [-39.721967916992185, -19.074968526367197],
            [-39.722267916992188, -19.067768526367196],
            [-39.723467916992185, -19.063068526367196],
            [-39.724467916992182, -19.057668526367195],
            [-39.725267916992188, -19.052568526367196],
            [-39.726367916992189, -19.046868526367195],
            [-39.726767916992188, -19.042468526367195],
            [-39.728167916992184, -19.033368526367195],
            [-39.728567916992183, -19.030168526367195],
            [-39.728967916992183, -19.027068526367195],
            [-39.729167916992182, -19.024068526367195],
            [-39.729567916992188, -19.020768526367196],
            [-39.729967916992187, -19.017068526367197],
            [-39.730367916992186, -19.013468526367195],
            [-39.730567916992186, -19.010168526367195],
            [-39.731067916992188, -19.007068526367195],
            [-39.731467916992187, -19.003868526367196],
            [-39.732067916992186, -18.999468526367195],
            [-39.732767916992188, -18.995468526367198],
            [-39.733867916992182, -18.988668526367196],
            [-39.734667916992187, -18.983368526367197],
            [-39.735367916992182, -18.977868526367196],
            [-39.736067916992184, -18.973668526367195],
            [-39.736167916992187, -18.970268526367196],
            [-39.736367916992187, -18.965468526367196],
            [-39.736467916992183, -18.961468526367195],
            [-39.736667916992182, -18.957868526367196],
            [-39.737467916992188, -18.954668526367197],
            [-39.738267916992186, -18.951068526367198],
            [-39.739367916992187, -18.947368526367196],
            [-39.741067916992186, -18.943468526367198],
            [-39.742167916992187, -18.939868526367196],
            [-39.742967916992185, -18.935168526367196],
            [-39.743267916992188, -18.930768526367196],
            [-39.743367916992185, -18.925068526367195],
            [-39.743867916992187, -18.910968526367196],
            [-39.744067916992186, -18.906468526367195],
            [-39.744167916992183, -18.902968526367196],
            [-39.744367916992182, -18.898968526367195],
            [-39.744667916992185, -18.892968526367195],
            [-39.745167916992187, -18.888168526367195],
            [-39.745567916992186, -18.883468526367196],
            [-39.746167916992185, -18.878768526367196],
            [-39.746567916992184, -18.875468526367197],
            [-39.746967916992183, -18.870168526367195],
            [-39.747667916992185, -18.865268526367196],
            [-39.748067916992184, -18.860068526367197],
            [-39.748567916992187, -18.855668526367197],
            [-39.749067916992182, -18.850368526367195],
            [-39.749367916992185, -18.844168526367195],
            [-39.749667916992188, -18.838668526367197],
            [-39.749667916992188, -18.834268526367197],
            [-39.749467916992188, -18.827468526367195],
            [-39.749467916992188, -18.824268526367195],
            [-39.749367916992185, -18.820568526367197],
            [-39.749067916992182, -18.816268526367196],
            [-39.748767916992186, -18.809368526367198],
            [-39.748367916992187, -18.798668526367198],
            [-39.748367916992187, -18.794968526367196],
            [-39.748267916992184, -18.786368526367195],
            [-39.748267916992184, -18.779468526367197],
            [-39.748067916992184, -18.775368526367195],
            [-39.747767916992188, -18.770368526367196],
            [-39.747667916992185, -18.764668526367196],
            [-39.747367916992182, -18.757668526367198],
            [-39.747167916992183, -18.753568526367197],
            [-39.746967916992183, -18.750568526367196],
            [-39.746967916992183, -18.747668526367196],
            [-39.746867916992187, -18.743268526367196],
            [-39.746567916992184, -18.739368526367198],
            [-39.746267916992188, -18.735068526367197],
            [-39.746067916992182, -18.731768526367198],
            [-39.745867916992182, -18.727268526367197],
            [-39.746367916992185, -18.719768526367197],
            [-39.746567916992184, -18.715068526367197],
            [-39.746567916992184, -18.706168526367197],
            [-39.746067916992182, -18.702868526367197],
            [-39.746067916992182, -18.699368526367195],
            [-39.745767916992186, -18.695268526367197],
            [-39.745467916992183, -18.690968526367197],
            [-39.745067916992184, -18.686868526367196],
            [-39.744467916992186, -18.682468526367195],
            [-39.744067916992186, -18.678568526367197],
            [-39.743667916992187, -18.674668526367196],
            [-39.743267916992188, -18.669668526367197],
            [-39.743067916992182, -18.666768526367196],
            [-39.742567916992186, -18.663368526367197],
            [-39.741867916992184, -18.658068526367195],
            [-39.741167916992183, -18.654268526367197],
            [-39.740567916992184, -18.650368526367195],
            [-39.739967916992185, -18.647068526367196],
            [-39.738667916992185, -18.642568526367196],
            [-39.737867916992187, -18.639568526367196],
            [-39.736867916992182, -18.635668526367198],
            [-39.735867916992184, -18.632768526367197],
            [-39.734667916992187, -18.629168526367195],
            [-39.733567916992186, -18.625768526367196],
            [-39.732467916992185, -18.622368526367197],
            [-39.731167916992185, -18.618668526367195],
            [-39.729567916992188, -18.614668526367197],
            [-39.728467916992187, -18.610768526367195],
            [-39.728167916992184, -18.606668526367198],
            [-39.728067916992188, -18.600668526367198],
            [-39.728467916992187, -18.596368526367197],
            [-39.729267916992185, -18.592268526367196],
            [-39.730267916992183, -18.588268526367198],
            [-39.730567916992186, -18.583668526367195],
            [-39.730567916992186, -18.579568526367197],
            [-39.730367916992186, -18.576268526367198],
            [-39.730367916992186, -18.572768526367195],
            [-39.730267916992183, -18.567768526367196],
            [-39.729967916992187, -18.562468526367198],
            [-39.729767916992188, -18.557968526367198],
            [-39.729967916992187, -18.552768526367196],
            [-39.729967916992187, -18.546468526367196],
            [-39.729767916992188, -18.542768526367198],
            [-39.729167916992182, -18.534168526367196],
            [-39.728467916992187, -18.528768526367195],
            [-39.728367916992184, -18.525668526367195],
            [-39.728467916992187, -18.521868526367196],
            [-39.728067916992188, -18.517068526367197],
            [-39.727367916992186, -18.513168526367195],
            [-39.726667916992184, -18.508268526367196],
            [-39.725667916992187, -18.504068526367195],
            [-39.724767916992185, -18.499668526367195],
            [-39.723467916992185, -18.493268526367196],
            [-39.722867916992186, -18.490068526367196],
            [-39.721967916992185, -18.486068526367195],
            [-39.720867916992184, -18.482168526367197],
            [-39.720067916992186, -18.479268526367196],
            [-39.718767916992185, -18.475268526367195],
            [-39.717067916992185, -18.469268526367195],
            [-39.715867916992188, -18.465168526367197],
            [-39.714267916992185, -18.460968526367196],
            [-39.712667916992189, -18.456268526367197],
            [-39.711267916992185, -18.452368526367195],
            [-39.709367916992186, -18.447868526367195],
            [-39.708167916992188, -18.443768526367197],
            [-39.707067916992187, -18.441068526367197],
            [-39.706067916992183, -18.438068526367196],
            [-39.704567916992183, -18.434068526367195],
            [-39.703267916992182, -18.430268526367197],
            [-39.701867916992185, -18.425868526367196],
            [-39.700467916992189, -18.421468526367196],
            [-39.699267916992184, -18.418068526367197],
            [-39.697467916992188, -18.414168526367195],
            [-39.696067916992185, -18.410968526367196],
            [-39.694667916992188, -18.407368526367197],
            [-39.693167916992188, -18.402668526367197],
            [-39.690267916992184, -18.393968526367196],
            [-39.688367916992185, -18.389268526367196],
            [-39.686367916992182, -18.384568526367197],
            [-39.684767916992186, -18.380968526367198],
            [-39.682667916992187, -18.376368526367198],
            [-39.680867916992185, -18.372468526367197],
            [-39.679467916992188, -18.369768526367196],
            [-39.677567916992189, -18.366168526367197],
            [-39.676067916992189, -18.362968526367197],
            [-39.674767916992188, -18.359468526367195],
            [-39.673567916992184, -18.356668526367198],
            [-39.671367916992182, -18.352768526367196],
            [-39.669167916992187, -18.350568526367198],
            [-39.671467916992185, -18.348368526367196],
            [-39.668867916992184, -18.343968526367195],
            [-39.665567916992188, -18.340168526367197],
            [-39.663967916992185, -18.336468526367195],
            [-39.663067916992183, -18.332268526367198],
            [-39.660667916992182, -18.326868526367196],
            [-39.658467916992187, -18.322968526367195],
            [-39.659267916992185, -18.319068526367197],
            [-39.658867916992186, -18.314668526367196],
            [-39.657367916992186, -18.311368526367197],
            [-39.655967916992182, -18.308368526367197],
            [-39.655167916992184, -18.305268526367197],
            [-39.654567916992185, -18.302468526367196],
            [-39.653767916992187, -18.299368526367196],
            [-39.652667916992186, -18.296168526367197],
            [-39.652067916992188, -18.292868526367197],
            [-39.652267916992187, -18.289968526367197],
            [-39.652667916992186, -18.287068526367197],
            [-39.651567916992185, -18.283368526367195],
            [-39.650367916992188, -18.279468526367197],
            [-39.648767916992185, -18.275368526367195],
            [-39.647667916992184, -18.271868526367196],
            [-39.646467916992187, -18.268468526367197],
            [-39.644867916992183, -18.264268526367196],
            [-39.643167916992184, -18.259868526367196],
            [-39.642067916992183, -18.256568526367197],
            [-39.640067916992187, -18.252668526367195],
            [-39.638167916992188, -18.249068526367196],
            [-39.637767916992182, -18.245268526367198],
            [-39.637667916992186, -18.240768526367198],
            [-39.637067916992187, -18.236468526367197],
            [-39.634867916992185, -18.231468526367195],
            [-39.632667916992183, -18.227268526367197],
            [-39.631267916992186, -18.224568526367197],
            [-39.629867916992183, -18.221768526367196],
            [-39.627967916992183, -18.217668526367195],
            [-39.625467916992186, -18.213468526367198],
            [-39.622667916992185, -18.209768526367196],
            [-39.620867916992182, -18.207068526367195],
            [-39.618867916992187, -18.203268526367197],
            [-39.616667916992185, -18.199568526367198],
            [-39.615067916992189, -18.196268526367195],
            [-39.613567916992189, -18.192668526367196],
            [-39.610867916992184, -18.187968526367197],
            [-39.608667916992182, -18.183668526367196],
            [-39.606767916992183, -18.180868526367195],
            [-39.605067916992184, -18.178068526367195],
            [-39.603667916992187, -18.175368526367198],
            [-39.601967916992187, -18.172768526367197],
            [-39.599967916992185, -18.169568526367197],
            [-39.598367916992188, -18.166668526367197],
            [-39.596167916992187, -18.163168526367198],
            [-39.594267916992187, -18.159868526367195],
            [-39.592367916992188, -18.157068526367198],
            [-39.590367916992186, -18.153768526367195],
            [-39.587967916992184, -18.149868526367197],
            [-39.585467916992187, -18.146168526367195],
            [-39.583667916992184, -18.143568526367197],
            [-39.581467916992182, -18.140368526367197],
            [-39.579267916992187, -18.137368526367197],
            [-39.577067916992185, -18.134068526367198],
            [-39.573867916992185, -18.130168526367196],
            [-39.571367916992187, -18.126668526367197],
            [-39.569367916992185, -18.123868526367197],
            [-39.563567916992184, -18.117268526367198],
            [-39.560167916992185, -18.113368526367196],
            [-39.556867916992182, -18.109968526367197],
            [-39.554167916992185, -18.107468526367196],
            [-39.552167916992182, -18.105268526367198],
            [-39.549767916992188, -18.102768526367196],
            [-39.547767916992186, -18.098468526367196],
            [-39.548667916992187, -18.095168526367196],
            [-39.547267916992183, -18.092268526367196],
            [-39.545767916992183, -18.088668526367197],
            [-39.544567916992186, -18.085068526367195],
            [-39.542567916992184, -18.080668526367198],
            [-39.541067916992183, -18.077568526367195],
            [-39.539567916992183, -18.074868526367197],
            [-39.537867916992184, -18.071568526367198],
            [-39.535567916992186, -18.067368526367197],
            [-39.533467916992187, -18.063768526367195],
            [-39.531967916992187, -18.060868526367198],
            [-39.529467916992182, -18.056668526367197],
            [-39.527567916992183, -18.053268526367198],
            [-39.525967916992187, -18.050568526367197],
            [-39.524467916992187, -18.047868526367196],
            [-39.522367916992188, -18.044568526367197],
            [-39.520367916992186, -18.040968526367195],
            [-39.517567916992185, -18.036468526367198],
            [-39.515167916992183, -18.032768526367196],
            [-39.513167916992188, -18.029768526367196],
            [-39.511267916992182, -18.026868526367195],
            [-39.508867916992187, -18.023668526367196],
            [-39.506667916992185, -18.020168526367197],
            [-39.504467916992184, -18.016768526367198],
            [-39.502367916992185, -18.013568526367198],
            [-39.500467916992186, -18.010468526367195],
            [-39.498267916992184, -18.007168526367195],
            [-39.495867916992182, -18.004068526367195],
            [-39.493367916992185, -18.000768526367196],
            [-39.491167916992183, -17.997668526367196],
            [-39.488567916992189, -17.994668526367196],
            [-39.486167916992187, -17.991668526367196],
            [-39.483967916992185, -17.988968526367195],
            [-39.481067916992188, -17.985268526367197],
            [-39.478067916992188, -17.981768526367198],
            [-39.475967916992182, -17.979268526367196],
            [-39.473867916992184, -17.976968526367195],
            [-39.471467916992182, -17.974468526367197],
            [-39.468267916992183, -17.970668526367195],
            [-39.464767916992187, -17.967268526367196],
            [-39.462667916992189, -17.965068526367197],
            [-39.460467916992187, -17.962968526367195],
            [-39.456767916992185, -17.959268526367197],
            [-39.453267916992182, -17.955768526367198],
            [-39.450467916992189, -17.953268526367197],
            [-39.448167916992183, -17.951068526367198],
            [-39.445167916992183, -17.948468526367197],
            [-39.441667916992188, -17.945668526367196],
            [-39.438467916992188, -17.942968526367196],
            [-39.435867916992187, -17.940768526367197],
            [-39.433367916992182, -17.938768526367195],
            [-39.430767916992188, -17.936668526367196],
            [-39.427967916992188, -17.934668526367197],
            [-39.424967916992188, -17.932668526367195],
            [-39.421067916992186, -17.930468526367196],
            [-39.416667916992182, -17.927768526367196],
            [-39.412867916992184, -17.925468526367197],
            [-39.409767916992188, -17.923668526367198],
            [-39.406167916992182, -17.921768526367195],
            [-39.402667916992186, -17.920268526367195],
            [-39.398967916992184, -17.918468526367196],
            [-39.395467916992182, -17.916968526367196],
            [-39.392867916992188, -17.915668526367195],
            [-39.389667916992188, -17.914568526367198],
            [-39.386767916992184, -17.913368526367197],
            [-39.381267916992186, -17.911368526367195],
            [-39.377167916992185, -17.910068526367198],
            [-39.373067916992184, -17.908768526367197],
            [-39.367567916992186, -17.907268526367197],
            [-39.363567916992189, -17.906268526367196],
            [-39.361067916992184, -17.904468526367197],
            [-39.359667916992187, -17.901468526367196],
            [-39.362267916992188, -17.897168526367196],
            [-39.363667916992185, -17.891868526367197],
            [-39.356967916992183, -17.890968526367196],
            [-39.352767916992185, -17.891268526367195],
            [-39.348367916992188, -17.891568526367195],
            [-39.340867916992188, -17.892068526367197],
            [-39.335767916992182, -17.892168526367197],
            [-39.331467916992182, -17.892868526367195],
            [-39.326767916992182, -17.892968526367195],
            [-39.323267916992187, -17.892368526367196],
            [-39.319167916992185, -17.891368526367195],
            [-39.315567916992187, -17.889968526367195],
            [-39.312167916992188, -17.888168526367195],
            [-39.308767916992188, -17.886468526367196],
            [-39.304667916992187, -17.884568526367197],
            [-39.301467916992188, -17.883068526367197],
            [-39.298867916992187, -17.881768526367196],
            [-39.295567916992184, -17.880268526367196],
            [-39.292767916992183, -17.878868526367196],
            [-39.290067916992186, -17.877768526367195],
            [-39.287367916992189, -17.876368526367198],
            [-39.283467916992187, -17.874468526367195],
            [-39.278967916992187, -17.872168526367197],
            [-39.275667916992184, -17.869668526367196],
            [-39.272867916992183, -17.867568526367197],
            [-39.270467916992182, -17.865768526367198],
            [-39.267967916992184, -17.863268526367197],
            [-39.266267916992184, -17.860268526367197],
            [-39.265667916992186, -17.856768526367198],
            [-39.266267916992184, -17.853668526367198],
            [-39.267067916992183, -17.850568526367198],
            [-39.266067916992185, -17.846668526367196],
            [-39.264667916992188, -17.843668526367196],
            [-39.264667916992188, -17.840068526367197],
            [-39.261767916992184, -17.835768526367197],
            [-39.259267916992187, -17.831468526367196],
            [-39.257667916992183, -17.828468526367196],
            [-39.256667916992185, -17.824968526367197],
            [-39.254167916992188, -17.822368526367196],
            [-39.252067916992182, -17.819168526367196],
            [-39.249467916992188, -17.816368526367196],
            [-39.246967916992183, -17.813568526367195],
            [-39.244667916992185, -17.810768526367195],
            [-39.242167916992187, -17.807768526367198],
            [-39.239467916992183, -17.805168526367197],
            [-39.236867916992182, -17.802568526367196],
            [-39.234967916992183, -17.799668526367196],
            [-39.232767916992188, -17.797468526367197],
            [-39.230867916992182, -17.794968526367196],
            [-39.228367916992184, -17.792768526367198],
            [-39.225067916992188, -17.789968526367197],
            [-39.222567916992183, -17.788068526367198],
            [-39.219767916992183, -17.785868526367196],
            [-39.215967916992184, -17.782368526367197],
            [-39.212667916992189, -17.779568526367196],
            [-39.209767916992185, -17.776868526367195],
            [-39.206767916992185, -17.773768526367196],
            [-39.206267916992182, -17.769868526367198],
            [-39.204667916992186, -17.766368526367195],
            [-39.203967916992184, -17.762668526367197],
            [-39.204367916992183, -17.758268526367196],
            [-39.206867916992188, -17.751568526367198],
            [-39.207267916992187, -17.746968526367198],
            [-39.202867916992183, -17.745468526367198],
            [-39.198567916992182, -17.743368526367195],
            [-39.194867916992187, -17.742168526367195],
            [-39.191667916992188, -17.741968526367195],
            [-39.188467916992188, -17.739368526367198],
            [-39.185867916992187, -17.735768526367195],
            [-39.184367916992187, -17.733168526367198],
            [-39.181667916992183, -17.730668526367197],
            [-39.178667916992183, -17.728168526367195],
            [-39.175767916992186, -17.726168526367196],
            [-39.172767916992186, -17.724268526367197],
            [-39.169167916992187, -17.721668526367196],
            [-39.165567916992188, -17.718368526367197],
            [-39.162367916992189, -17.715068526367197],
            [-39.157767916992185, -17.710868526367197],
            [-39.154767916992185, -17.708268526367196],
            [-39.151967916992184, -17.706068526367197],
            [-39.148967916992184, -17.703468526367196],
            [-39.145167916992186, -17.699868526367197],
            [-39.142067916992183, -17.696868526367197],
            [-39.139667916992188, -17.694868526367195],
            [-39.137167916992183, -17.692168526367198],
            [-39.135767916992187, -17.689168526367197],
            [-39.138467916992184, -17.684668526367197],
            [-39.139867916992188, -17.680068526367197],
            [-39.142967916992184, -17.675468526367197],
            [-39.145967916992184, -17.671468526367196],
            [-39.149067916992188, -17.667268526367195],
            [-39.151567916992185, -17.663768526367196],
            [-39.154167916992186, -17.660568526367197],
            [-39.156967916992187, -17.657368526367197],
            [-39.159867916992184, -17.653668526367195],
            [-39.162767916992188, -17.650068526367196],
            [-39.164967916992182, -17.647168526367196],
            [-39.167167916992184, -17.643968526367196],
            [-39.169567916992186, -17.640468526367197],
            [-39.171367916992182, -17.638168526367195],
            [-39.173567916992184, -17.634368526367197],
            [-39.175767916992186, -17.631068526367198],
            [-39.177767916992188, -17.627168526367196],
            [-39.180067916992186, -17.622968526367195],
            [-39.181667916992183, -17.619768526367196],
            [-39.183267916992186, -17.616668526367196],
            [-39.184467916992183, -17.613568526367196],
            [-39.185767916992184, -17.610868526367195],
            [-39.186867916992185, -17.608168526367198],
            [-39.187967916992186, -17.605368526367197],
            [-39.189067916992187, -17.601968526367195],
            [-39.189967916992188, -17.599168526367198],
            [-39.190767916992186, -17.596268526367197],
            [-39.191667916992188, -17.593368526367197],
            [-39.192467916992186, -17.589468526367195],
            [-39.193467916992184, -17.585468526367197],
            [-39.193467916992184, -17.580968526367197],
            [-39.190467916992183, -17.578568526367196],
            [-39.188367916992185, -17.574268526367195],
            [-39.187967916992186, -17.569868526367195],
            [-39.188067916992182, -17.565268526367195],
            [-39.188467916992188, -17.561068526367198],
            [-39.189167916992183, -17.557268526367196],
            [-39.189067916992187, -17.551968526367197],
            [-39.189667916992185, -17.546768526367195],
            [-39.190167916992188, -17.542268526367195],
            [-39.190467916992183, -17.538168526367198],
            [-39.190467916992183, -17.534868526367195],
            [-39.190967916992186, -17.529768526367196],
            [-39.191267916992182, -17.524568526367197],
            [-39.191267916992182, -17.521168526367195],
            [-39.191367916992185, -17.516068526367196],
            [-39.191567916992184, -17.512168526367198],
            [-39.191667916992188, -17.507468526367198],
            [-39.191667916992188, -17.504368526367195],
            [-39.191667916992188, -17.499868526367198],
            [-39.191667916992188, -17.494368526367197],
            [-39.191967916992184, -17.490268526367196],
            [-39.192067916992187, -17.486368526367198],
            [-39.192167916992183, -17.481668526367198],
            [-39.192167916992183, -17.476968526367195],
            [-39.192167916992183, -17.472668526367197],
            [-39.192167916992183, -17.468768526367196],
            [-39.191867916992187, -17.463368526367198],
            [-39.191667916992188, -17.459668526367196],
            [-39.191667916992188, -17.456568526367196],
            [-39.191567916992184, -17.452168526367196],
            [-39.191667916992188, -17.446868526367197],
            [-39.192067916992187, -17.442668526367196],
            [-39.192667916992185, -17.439568526367196],
            [-39.193867916992183, -17.435568526367195],
            [-39.195167916992183, -17.431668526367197],
            [-39.196767916992187, -17.427468526367196],
            [-39.198467916992186, -17.422868526367196],
            [-39.199967916992186, -17.418468526367196],
            [-39.201767916992182, -17.413868526367196],
            [-39.203267916992182, -17.409468526367196],
            [-39.203767916992184, -17.406168526367196],
            [-39.204667916992186, -17.402268526367195],
            [-39.203467916992182, -17.397668526367195],
            [-39.203567916992185, -17.393268526367198],
            [-39.204667916992186, -17.390168526367198],
            [-39.205667916992184, -17.385968526367197],
            [-39.206767916992185, -17.382068526367195],
            [-39.207567916992183, -17.378068526367198],
            [-39.208267916992185, -17.374868526367198],
            [-39.209267916992182, -17.371968526367198],
            [-39.209867916992188, -17.369068526367197],
            [-39.210667916992186, -17.365768526367198],
            [-39.211467916992184, -17.362268526367195],
            [-39.212267916992182, -17.358968526367196],
            [-39.212867916992188, -17.355968526367196],
            [-39.213667916992186, -17.352268526367197],
            [-39.214567916992188, -17.348368526367196],
            [-39.215567916992185, -17.344768526367197],
            [-39.216267916992187, -17.341568526367197],
            [-39.217267916992185, -17.337268526367197],
            [-39.217867916992184, -17.332568526367197],
            [-39.218467916992182, -17.326768526367196],
            [-39.219167916992184, -17.321768526367197],
            [-39.219767916992183, -17.316068526367197],
            [-39.220267916992185, -17.311368526367197],
            [-39.220567916992188, -17.306868526367197],
            [-39.220867916992184, -17.302468526367196],
            [-39.220967916992187, -17.298668526367198],
            [-39.220967916992187, -17.294968526367196],
            [-39.220967916992187, -17.290868526367195],
            [-39.221167916992187, -17.286368526367195],
            [-39.220967916992187, -17.282368526367197],
            [-39.220567916992188, -17.279468526367197],
            [-39.219167916992184, -17.275968526367198],
            [-39.219167916992184, -17.272868526367198],
            [-39.219267916992187, -17.269268526367195],
            [-39.219467916992187, -17.265668526367197],
            [-39.218767916992185, -17.261768526367195],
            [-39.217667916992184, -17.257768526367197],
            [-39.217067916992185, -17.254168526367195],
            [-39.216167916992184, -17.250468526367197],
            [-39.215067916992183, -17.245868526367197],
            [-39.213767916992182, -17.240768526367198],
            [-39.213167916992184, -17.235568526367196],
            [-39.213667916992186, -17.231068526367196],
            [-39.214067916992185, -17.226968526367195],
            [-39.214067916992185, -17.222368526367195],
            [-39.214067916992185, -17.218668526367196],
            [-39.214067916992185, -17.215468526367196],
            [-39.214067916992185, -17.210368526367198],
            [-39.214067916992185, -17.206168526367197],
            [-39.213667916992186, -17.202168526367196],
            [-39.213467916992187, -17.197468526367196],
            [-39.212867916992188, -17.192368526367197],
            [-39.212567916992185, -17.189068526367198],
            [-39.212267916992182, -17.185168526367196],
            [-39.212967916992184, -17.179668526367195],
            [-39.213167916992184, -17.175068526367195],
            [-39.212967916992184, -17.170068526367196],
            [-39.212667916992189, -17.166068526367198],
            [-39.212067916992183, -17.162068526367197],
            [-39.210867916992186, -17.157368526367197],
            [-39.209767916992185, -17.153368526367196],
            [-39.207867916992186, -17.147268526367196],
            [-39.206467916992182, -17.143168526367198],
            [-39.204667916992186, -17.140668526367197],
            [-39.202567916992187, -17.137768526367196],
            [-39.201267916992187, -17.133768526367195],
            [-39.198267916992187, -17.127968526367198],
            [-39.193767916992186, -17.123568526367198],
            [-39.188367916992185, -17.119968526367195],
            [-39.184967916992186, -17.116968526367195],
            [-39.183267916992186, -17.114168526367198],
            [-39.181867916992182, -17.111368526367198],
            [-39.181067916992184, -17.108168526367198],
            [-39.180467916992185, -17.104268526367196],
            [-39.180767916992188, -17.100668526367198],
            [-39.179767916992184, -17.097368526367195],
            [-39.178967916992185, -17.093768526367196],
            [-39.179667916992187, -17.090168526367197],
            [-39.179967916992183, -17.086468526367195],
            [-39.180167916992183, -17.082568526367197],
            [-39.179967916992183, -17.079268526367198],
            [-39.178667916992183, -17.075668526367195],
            [-39.177467916992185, -17.072768526367195],
            [-39.176967916992183, -17.068868526367197],
            [-39.175067916992184, -17.065868526367197],
            [-39.171867916992184, -17.063568526367195],
            [-39.171167916992182, -17.058268526367197],
            [-39.173667916992187, -17.054368526367195],
            [-39.174267916992186, -17.050368526367198],
            [-39.173667916992187, -17.045268526367195],
            [-39.172767916992186, -17.041768526367196],
            [-39.171667916992185, -17.038068526367198],
            [-39.171367916992182, -17.033968526367197],
            [-39.171867916992184, -17.030168526367195],
            [-39.171967916992187, -17.026568526367196],
            [-39.171867916992184, -17.022568526367195],
            [-39.172267916992183, -17.018668526367197],
            [-39.172167916992187, -17.013868526367197],
            [-39.170767916992183, -17.007768526367197],
            [-39.169167916992187, -17.003768526367196],
            [-39.167467916992187, -17.000168526367197],
            [-39.165667916992184, -16.996968526367198],
            [-39.163467916992182, -16.993668526367195],
            [-39.161267916992188, -16.990068526367196],
            [-39.159467916992185, -16.987468526367195],
            [-39.157567916992186, -16.983668526367197],
            [-39.155867916992186, -16.980968526367196],
            [-39.155167916992184, -16.978068526367196],
            [-39.154767916992185, -16.974168526367198],
            [-39.153967916992187, -16.970568526367195],
            [-39.153667916992184, -16.966668526367197],
            [-39.154267916992183, -16.962968526367195],
            [-39.153767916992187, -16.958768526367198],
            [-39.152967916992182, -16.955068526367196],
            [-39.152067916992188, -16.950968526367195],
            [-39.151267916992182, -16.947068526367197],
            [-39.151767916992185, -16.943868526367197],
            [-39.150467916992184, -16.940768526367197],
            [-39.148967916992184, -16.938368526367196],
            [-39.146767916992182, -16.934668526367197],
            [-39.144267916992185, -16.930168526367197],
            [-39.141567916992187, -16.926468526367195],
            [-39.139267916992182, -16.923568526367195],
            [-39.136767916992184, -16.920768526367198],
            [-39.134867916992185, -16.918468526367196],
            [-39.132667916992183, -16.915868526367195],
            [-39.130167916992185, -16.912368526367196],
            [-39.127667916992188, -16.908868526367197],
            [-39.125567916992182, -16.906468526367195],
            [-39.122967916992188, -16.903668526367195],
            [-39.120267916992184, -16.901468526367196],
            [-39.116867916992184, -16.899068526367195],
            [-39.111867916992182, -16.896868526367196],
            [-39.113567916992189, -16.894268526367195],
            [-39.116867916992184, -16.892868526367195],
            [-39.120067916992184, -16.892668526367196],
            [-39.122667916992185, -16.890068526367195],
            [-39.125567916992182, -16.888168526367195],
            [-39.128567916992182, -16.885468526367195],
            [-39.131767916992182, -16.882468526367198],
            [-39.134067916992187, -16.879468526367198],
            [-39.136467916992189, -16.876568526367198],
            [-39.138967916992186, -16.872668526367196],
            [-39.141767916992187, -16.868668526367195],
            [-39.142167916992186, -16.865068526367196],
            [-39.142367916992185, -16.860668526367196],
            [-39.142367916992185, -16.856768526367198],
            [-39.142467916992182, -16.852568526367197],
            [-39.143667916992186, -16.846968526367196],
            [-39.144767916992187, -16.843668526367196],
            [-39.144267916992185, -16.839768526367195],
            [-39.144267916992185, -16.834668526367196],
            [-39.144667916992184, -16.830468526367195],
            [-39.144767916992187, -16.827368526367195],
            [-39.144767916992187, -16.823868526367196],
            [-39.144867916992183, -16.819168526367196],
            [-39.145367916992186, -16.814368526367197],
            [-39.145367916992186, -16.810868526367198],
            [-39.145367916992186, -16.806568526367197],
            [-39.144667916992184, -16.801868526367198],
            [-39.144267916992185, -16.798868526367198],
            [-39.144267916992185, -16.795768526367198],
            [-39.143967916992182, -16.790268526367196],
            [-39.143167916992184, -16.784768526367195],
            [-39.142067916992183, -16.779868526367196],
            [-39.142067916992183, -16.774768526367197],
            [-39.140767916992182, -16.770768526367196],
            [-39.141867916992183, -16.767468526367196],
            [-39.141767916992187, -16.763868526367197],
            [-39.141267916992184, -16.759968526367196],
            [-39.140767916992182, -16.756868526367196],
            [-39.139867916992188, -16.754068526367195],
            [-39.137367916992183, -16.750268526367197],
            [-39.133867916992187, -16.750768526367196],
            [-39.130767916992184, -16.749368526367196],
            [-39.128067916992187, -16.746968526367198],
            [-39.128067916992187, -16.744068526367197],
            [-39.127367916992185, -16.739068526367195],
            [-39.126267916992184, -16.734468526367195],
            [-39.125767916992189, -16.730968526367196],
            [-39.123567916992187, -16.727468526367197],
            [-39.121867916992187, -16.724768526367196],
            [-39.119367916992182, -16.722268526367195],
            [-39.116067916992186, -16.719168526367195],
            [-39.115867916992187, -16.715668526367196],
            [-39.114667916992182, -16.712968526367195],
            [-39.111667916992182, -16.712268526367197],
            [-39.108267916992183, -16.710368526367198],
            [-39.106667916992187, -16.707368526367198],
            [-39.107167916992182, -16.704268526367198],
            [-39.105567916992186, -16.701068526367198],
            [-39.103867916992186, -16.697968526367195],
            [-39.103967916992183, -16.694368526367196],
            [-39.103867916992186, -16.690768526367197],
            [-39.104267916992185, -16.686968526367195],
            [-39.104667916992184, -16.682768526367198],
            [-39.104267916992185, -16.678568526367197],
            [-39.103967916992183, -16.674168526367197],
            [-39.103167916992184, -16.670368526367195],
            [-39.102767916992185, -16.667268526367195],
            [-39.102067916992183, -16.664468526367195],
            [-39.100967916992182, -16.661168526367195],
            [-39.098667916992184, -16.657068526367198],
            [-39.094767916992183, -16.654168526367197],
            [-39.094767916992183, -16.649768526367197],
            [-39.096167916992187, -16.645668526367196],
            [-39.095567916992188, -16.642668526367196],
            [-39.093367916992186, -16.639568526367196],
            [-39.092667916992184, -16.634968526367196],
            [-39.091267916992187, -16.630268526367196],
            [-39.090067916992183, -16.626368526367198],
            [-39.090867916992188, -16.623568526367198],
            [-39.092567916992188, -16.620468526367198],
            [-39.093367916992186, -16.617268526367198],
            [-39.094167916992184, -16.614368526367198],
            [-39.094467916992187, -16.610768526367195],
            [-39.093967916992185, -16.607468526367196],
            [-39.092267916992185, -16.603368526367195],
            [-39.090967916992184, -16.600668526367198],
            [-39.089067916992185, -16.598468526367196],
            [-39.088667916992186, -16.594868526367197],
            [-39.087867916992188, -16.590968526367195],
            [-39.088767916992182, -16.586968526367198],
            [-39.088667916992186, -16.583168526367196],
            [-39.087867916992188, -16.580068526367196],
            [-39.086567916992188, -16.576268526367198],
            [-39.085367916992183, -16.572768526367195],
            [-39.085367916992183, -16.568868526367197],
            [-39.085767916992182, -16.565868526367197],
            [-39.086467916992184, -16.562268526367195],
            [-39.086467916992184, -16.557268526367196],
            [-39.085867916992186, -16.552268526367197],
            [-39.085367916992183, -16.547768526367197],
            [-39.084567916992185, -16.544968526367196],
            [-39.083967916992187, -16.541668526367197],
            [-39.083167916992188, -16.537868526367195],
            [-39.081867916992188, -16.533968526367197],
            [-39.080667916992184, -16.530068526367195],
            [-39.079667916992186, -16.526268526367197],
            [-39.078767916992184, -16.522968526367197],
            [-39.077167916992188, -16.518268526367198],
            [-39.074667916992183, -16.514368526367196],
            [-39.073167916992183, -16.509968526367196],
            [-39.072167916992186, -16.506068526367198],
            [-39.070967916992188, -16.502068526367196],
            [-39.069567916992185, -16.498368526367198],
            [-39.068467916992184, -16.494668526367196],
            [-39.067667916992185, -16.490268526367196],
            [-39.067367916992183, -16.486168526367198],
            [-39.067367916992183, -16.483168526367198],
            [-39.065967916992186, -16.480268526367198],
            [-39.064167916992183, -16.477468526367197],
            [-39.062467916992183, -16.474468526367197],
            [-39.061667916992185, -16.470868526367195],
            [-39.061367916992182, -16.466268526367195],
            [-39.060567916992184, -16.461468526367195],
            [-39.059967916992186, -16.457268526367198],
            [-39.063867916992187, -16.455368526367195],
            [-39.062367916992187, -16.451068526367198],
            [-39.061067916992187, -16.447368526367196],
            [-39.061267916992186, -16.443868526367197],
            [-39.061867916992185, -16.440568526367198],
            [-39.061967916992188, -16.436268526367197],
            [-39.061267916992186, -16.432968526367198],
            [-39.059867916992182, -16.429068526367196],
            [-39.057267916992188, -16.425468526367197],
            [-39.054667916992187, -16.422168526367198],
            [-39.051567916992184, -16.417568526367198],
            [-39.049467916992185, -16.413168526367198],
            [-39.047567916992186, -16.409868526367195],
            [-39.045867916992187, -16.406568526367195],
            [-39.043567916992188, -16.403668526367195],
            [-39.041367916992186, -16.400068526367196],
            [-39.039467916992187, -16.395168526367197],
            [-39.036667916992187, -16.391368526367195],
            [-39.034267916992185, -16.388168526367195],
            [-39.030867916992186, -16.384968526367196],
            [-39.025467916992184, -16.380968526367198],
            [-39.021167916992184, -16.378768526367196],
            [-39.016467916992184, -16.377168526367196],
            [-39.011767916992184, -16.375968526367195],
            [-39.008767916992184, -16.374668526367195],
            [-39.008767916992184, -16.370568526367197],
            [-39.009367916992183, -16.367568526367197],
            [-39.009867916992185, -16.364668526367197],
            [-39.010667916992183, -16.361068526367195],
            [-39.011867916992188, -16.357868526367195],
            [-39.012767916992182, -16.354968526367195],
            [-39.012367916992183, -16.350868526367197],
            [-39.009967916992188, -16.346168526367197],
            [-39.007067916992185, -16.343668526367196],
            [-39.006267916992186, -16.340868526367196],
            [-39.007667916992183, -16.336768526367198],
            [-39.007567916992187, -16.332568526367197],
            [-39.013167916992188, -16.330368526367195],
            [-39.016467916992184, -16.328568526367196],
            [-39.018667916992186, -16.326268526367198],
            [-39.020767916992185, -16.322868526367195],
            [-39.022567916992188, -16.317968526367196],
            [-39.023367916992186, -16.312768526367197],
            [-39.023667916992189, -16.308268526367197],
            [-39.023667916992189, -16.302268526367197],
            [-39.023367916992186, -16.296768526367195],
            [-39.022967916992187, -16.293168526367197],
            [-39.022567916992188, -16.289468526367195],
            [-39.021767916992182, -16.285568526367197],
            [-39.020767916992185, -16.282368526367197],
            [-39.019767916992187, -16.279568526367196],
            [-39.018767916992182, -16.276768526367196],
            [-39.017567916992185, -16.273168526367197],
            [-39.016767916992187, -16.270068526367197],
            [-39.015667916992186, -16.267068526367197],
            [-39.014067916992182, -16.262968526367196],
            [-39.012467916992186, -16.258768526367195],
            [-39.011067916992182, -16.255268526367196],
            [-39.010167916992188, -16.252368526367196],
            [-39.010967916992186, -16.248368526367198],
            [-39.010667916992183, -16.244768526367196],
            [-39.008067916992182, -16.241168526367197],
            [-39.004867916992183, -16.237568526367195],
            [-39.002167916992185, -16.234468526367195],
            [-39.000467916992186, -16.232068526367197],
            [-38.997967916992188, -16.229168526367197],
            [-38.995767916992186, -16.227068526367198],
            [-38.993567916992184, -16.224868526367196],
            [-38.991367916992182, -16.222368526367195],
            [-38.989067916992184, -16.220068526367196],
            [-38.985367916992182, -16.217568526367195],
            [-38.981067916992188, -16.215868526367196],
            [-38.978067916992188, -16.215068526367197],
            [-38.974467916992182, -16.212868526367195],
            [-38.973367916992188, -16.208968526367197],
            [-38.973367916992188, -16.205368526367195],
            [-38.972767916992183, -16.202168526367196],
            [-38.971967916992185, -16.199068526367196],
            [-38.969867916992186, -16.195968526367196],
            [-38.967667916992184, -16.193468526367198],
            [-38.964767916992187, -16.189668526367196],
            [-38.961767916992187, -16.184568526367197],
            [-38.958667916992184, -16.179668526367195],
            [-38.958267916992185, -16.175768526367197],
            [-38.958267916992185, -16.171168526367197],
            [-38.956767916992185, -16.166968526367196],
            [-38.953567916992185, -16.161768526367197],
            [-38.950267916992182, -16.158368526367195],
            [-38.948167916992183, -16.155068526367195],
            [-38.948267916992187, -16.150968526367198],
            [-38.949867916992183, -16.147068526367196],
            [-38.952067916992185, -16.143668526367197],
            [-38.954567916992183, -16.139668526367196],
            [-38.955967916992186, -16.134668526367197],
            [-38.956567916992185, -16.130168526367196],
            [-38.956467916992182, -16.125568526367196],
            [-38.956067916992183, -16.120768526367197],
            [-38.955767916992187, -16.117768526367197],
            [-38.955367916992188, -16.114468526367197],
            [-38.955067916992185, -16.111468526367197],
            [-38.954667916992186, -16.108568526367197],
            [-38.954267916992187, -16.105268526367198],
            [-38.953267916992182, -16.102068526367198],
            [-38.952567916992187, -16.098868526367195],
            [-38.951067916992187, -16.095168526367196],
            [-38.949667916992183, -16.092268526367196],
            [-38.947467916992188, -16.090068526367197],
            [-38.947367916992185, -16.086468526367195],
            [-38.946267916992184, -16.083568526367195],
            [-38.944667916992188, -16.079268526367198],
            [-38.944167916992185, -16.074368526367195],
            [-38.943267916992184, -16.070168526367198],
            [-38.941867916992187, -16.065568526367198],
            [-38.940467916992183, -16.061968526367195],
            [-38.939367916992182, -16.058668526367196],
            [-38.938067916992182, -16.055468526367196],
            [-38.936267916992186, -16.050468526367197],
            [-38.934667916992183, -16.046368526367196],
            [-38.933267916992186, -16.042768526367198],
            [-38.932267916992188, -16.039868526367197],
            [-38.930767916992188, -16.036368526367195],
            [-38.929167916992185, -16.033068526367195],
            [-38.927267916992186, -16.030568526367198],
            [-38.924467916992185, -16.028368526367196],
            [-38.923967916992183, -16.024468526367198],
            [-38.922767916992186, -16.020968526367195],
            [-38.921667916992185, -16.016868526367197],
            [-38.919967916992185, -16.012468526367197],
            [-38.918367916992182, -16.008568526367196],
            [-38.916667916992182, -16.004068526367195],
            [-38.915367916992182, -16.000168526367197],
            [-38.914467916992187, -15.997268526367197],
            [-38.913167916992187, -15.993568526367195],
            [-38.911467916992187, -15.988968526367195],
            [-38.909867916992184, -15.984668526367198],
            [-38.908467916992187, -15.979968526367195],
            [-38.907267916992183, -15.976668526367195],
            [-38.906167916992182, -15.973468526367196],
            [-38.905167916992184, -15.970368526367196],
            [-38.903967916992187, -15.967368526367196],
            [-38.902267916992187, -15.963168526367195],
            [-38.900767916992187, -15.959568526367196],
            [-38.899067916992188, -15.955668526367198],
            [-38.897667916992184, -15.951768526367196],
            [-38.896267916992187, -15.948568526367197],
            [-38.894767916992187, -15.945268526367197],
            [-38.893167916992184, -15.941568526367195],
            [-38.891467916992184, -15.937468526367198],
            [-38.889667916992188, -15.933368526367197],
            [-38.888167916992188, -15.929768526367198],
            [-38.886467916992189, -15.926068526367196],
            [-38.884967916992188, -15.922468526367197],
            [-38.882767916992186, -15.917568526367198],
            [-38.881267916992186, -15.913668526367196],
            [-38.879467916992184, -15.909868526367195],
            [-38.877667916992188, -15.905668526367197],
            [-38.875567916992182, -15.900968526367198],
            [-38.874067916992182, -15.897168526367196],
            [-38.872667916992185, -15.893768526367197],
            [-38.871267916992188, -15.890368526367197],
            [-38.869767916992188, -15.887068526367198],
            [-38.868067916992182, -15.883468526367196],
            [-38.866467916992185, -15.879168526367195],
            [-38.864967916992185, -15.874968526367198],
            [-38.863567916992189, -15.871568526367195],
            [-38.862467916992188, -15.868368526367195],
            [-38.860767916992188, -15.864968526367196],
            [-38.858867916992182, -15.861168526367198],
            [-38.856667916992187, -15.857268526367196],
            [-38.854267916992185, -15.853068526367196],
            [-38.852867916992182, -15.848668526367195],
            [-38.852867916992182, -15.844568526367198],
            [-38.853167916992184, -15.839568526367195],
            [-38.854167916992182, -15.835068526367195],
            [-38.857067916992186, -15.830668526367198],
            [-38.858967916992185, -15.827168526367196],
            [-38.861367916992187, -15.823868526367196],
            [-38.865067916992189, -15.821868526367197],
            [-38.867667916992183, -15.818468526367198],
            [-38.869367916992182, -15.815868526367197],
            [-38.871167916992185, -15.813068526367196],
            [-38.872967916992188, -15.809768526367197],
            [-38.875167916992183, -15.805468526367196],
            [-38.877467916992188, -15.800468526367197],
            [-38.879467916992184, -15.796468526367196],
            [-38.880967916992184, -15.793568526367196],
            [-38.882367916992187, -15.790568526367196],
            [-38.883867916992187, -15.787368526367196],
            [-38.885367916992188, -15.784168526367196],
            [-38.887067916992187, -15.780568526367198],
            [-38.888867916992183, -15.776768526367196],
            [-38.890467916992186, -15.773668526367196],
            [-38.891767916992187, -15.770768526367196],
            [-38.893167916992184, -15.767368526367196],
            [-38.894567916992187, -15.764368526367196],
            [-38.896167916992184, -15.760268526367195],
            [-38.897867916992183, -15.755768526367195],
            [-38.899367916992183, -15.751368526367198],
            [-38.900967916992187, -15.746968526367198],
            [-38.902567916992183, -15.743268526367196],
            [-38.903667916992184, -15.739668526367197],
            [-38.904867916992188, -15.736068526367195],
            [-38.905867916992186, -15.732868526367195],
            [-38.908067916992188, -15.728668526367198],
            [-38.910067916992183, -15.724168526367198],
            [-38.909567916992188, -15.720968526367198],
            [-38.912067916992186, -15.717668526367195],
            [-38.916367916992186, -15.716168526367195],
            [-38.918567916992188, -15.711568526367195],
            [-38.921067916992186, -15.703268526367197],
            [-38.923367916992184, -15.699668526367198],
            [-38.925467916992183, -15.695968526367196],
            [-38.927467916992185, -15.690968526367197],
            [-38.928867916992182, -15.686568526367196],
            [-38.930567916992182, -15.681568526367197],
            [-38.932267916992188, -15.675468526367197],
            [-38.933567916992182, -15.670768526367198],
            [-38.934667916992183, -15.665868526367195],
            [-38.935867916992187, -15.658668526367197],
            [-38.936567916992182, -15.654468526367197],
            [-38.936967916992188, -15.650468526367195],
            [-38.938067916992182, -15.644768526367198],
            [-38.938767916992184, -15.638468526367195],
            [-38.939167916992183, -15.632168526367195],
            [-38.939367916992182, -15.627368526367196],
            [-38.939667916992185, -15.621068526367196],
            [-38.939867916992185, -15.616968526367195],
            [-38.939967916992188, -15.611168526367198],
            [-38.940167916992188, -15.605668526367197],
            [-38.940167916992188, -15.600668526367198],
            [-38.940467916992183, -15.597568526367198],
            [-38.942967916992188, -15.593668526367196],
            [-38.943767916992186, -15.587268526367197],
            [-38.943167916992188, -15.582868526367196],
            [-38.943267916992184, -15.579268526367198],
            [-38.943067916992185, -15.575968526367195],
            [-38.943267916992184, -15.572068526367197],
            [-38.943167916992188, -15.564368526367197],
            [-38.942767916992182, -15.560568526367195],
            [-38.942667916992185, -15.557268526367196],
            [-38.942667916992185, -15.553568526367197],
            [-38.942367916992183, -15.549268526367197],
            [-38.942167916992183, -15.544268526367198],
            [-38.942067916992187, -15.540268526367196],
            [-38.942367916992183, -15.536968526367197],
            [-38.942667916992185, -15.533068526367195],
            [-38.942967916992188, -15.528368526367196],
            [-38.943267916992184, -15.524368526367198],
            [-38.943567916992187, -15.520668526367196],
            [-38.944067916992182, -15.516868526367197],
            [-38.944667916992188, -15.512868526367196],
            [-38.945167916992183, -15.508768526367195],
            [-38.945667916992186, -15.505668526367195],
            [-38.946267916992184, -15.502468526367196],
            [-38.946767916992187, -15.497668526367196],
            [-38.947467916992188, -15.493568526367195],
            [-38.948467916992186, -15.489968526367196],
            [-38.949267916992184, -15.485768526367195],
            [-38.950667916992188, -15.481368526367195],
            [-38.952067916992185, -15.471368526367197],
            [-38.952167916992188, -15.466568526367197],
            [-38.953267916992182, -15.462568526367196],
            [-38.954267916992187, -15.459068526367197],
            [-38.955367916992188, -15.455368526367195],
            [-38.956267916992182, -15.451868526367196],
            [-38.957567916992183, -15.447668526367195],
            [-38.958967916992187, -15.442068526367198],
            [-38.960167916992184, -15.437968526367197],
            [-38.960967916992182, -15.434768526367197],
            [-38.961867916992183, -15.431168526367195],
            [-38.963167916992184, -15.427168526367197],
            [-38.963967916992182, -15.424168526367197],
            [-38.965167916992186, -15.418868526367195],
            [-38.966267916992187, -15.414568526367198],
            [-38.967667916992184, -15.409468526367196],
            [-38.968667916992182, -15.405368526367198],
            [-38.969567916992183, -15.401468526367196],
            [-38.970867916992184, -15.396568526367197],
            [-38.971367916992186, -15.393768526367197],
            [-38.972067916992188, -15.390768526367197],
            [-38.973067916992186, -15.386568526367196],
            [-38.973967916992187, -15.381068526367198],
            [-38.974567916992186, -15.376568526367198],
            [-38.975367916992184, -15.371568526367195],
            [-38.975967916992182, -15.366868526367195],
            [-38.976767916992188, -15.363268526367197],
            [-38.977367916992186, -15.359168526367196],
            [-38.978167916992184, -15.353668526367198],
            [-38.979167916992182, -15.348068526367197],
            [-38.980067916992184, -15.343968526367195],
            [-38.980567916992186, -15.340668526367196],
            [-38.981167916992185, -15.337268526367197],
            [-38.981667916992187, -15.333968526367197],
            [-38.982267916992186, -15.330368526367195],
            [-38.982867916992184, -15.325768526367195],
            [-38.983667916992182, -15.321668526367198],
            [-38.984167916992185, -15.317668526367196],
            [-38.984767916992183, -15.312768526367197],
            [-38.985267916992186, -15.309668526367197],
            [-38.985667916992185, -15.306168526367195],
            [-38.986667916992182, -15.301168526367196],
            [-38.989667916992182, -15.298568526367195],
            [-38.992267916992184, -15.296968526367195],
            [-38.991667916992185, -15.292868526367197],
            [-38.990767916992183, -15.289268526367195],
            [-38.990867916992187, -15.285068526367198],
            [-38.991167916992183, -15.281668526367195],
            [-38.991467916992185, -15.278168526367196],
            [-38.991867916992184, -15.273668526367196],
            [-38.991967916992188, -15.269768526367198],
            [-38.992167916992187, -15.265068526367195],
            [-38.992467916992183, -15.260768526367197],
            [-38.992667916992183, -15.256868526367196],
            [-38.992767916992186, -15.253368526367197],
            [-38.992967916992185, -15.250468526367197],
            [-38.993267916992188, -15.246868526367198],
            [-38.993267916992188, -15.243568526367195],
            [-38.993367916992185, -15.239968526367196],
            [-38.994067916992186, -15.234668526367198],
            [-38.994467916992186, -15.228668526367198],
            [-38.994967916992188, -15.224468526367197],
            [-38.995867916992182, -15.220968526367198],
            [-38.996567916992184, -15.217068526367196],
            [-38.996567916992184, -15.213668526367197],
            [-38.996567916992184, -15.210068526367195],
            [-38.996667916992187, -15.206868526367195],
            [-38.996567916992184, -15.203168526367197],
            [-38.996567916992184, -15.198568526367197],
            [-38.996367916992185, -15.193168526367195],
            [-38.996167916992185, -15.188068526367196],
            [-38.995867916992182, -15.184368526367198],
            [-38.995767916992186, -15.180168526367197],
            [-38.995767916992186, -15.174968526367195],
            [-38.995267916992184, -15.169968526367196],
            [-38.995167916992187, -15.165568526367196],
            [-38.994967916992188, -15.160868526367196],
            [-38.994967916992188, -15.157368526367197],
            [-38.994767916992188, -15.153668526367195],
            [-38.994767916992188, -15.148968526367195],
            [-38.994967916992188, -15.144268526367195],
            [-38.994967916992188, -15.140468526367197],
            [-38.994967916992188, -15.136568526367196],
            [-38.995067916992184, -15.132768526367197],
            [-38.995467916992183, -15.129368526367195],
            [-38.995467916992183, -15.125768526367196],
            [-38.995567916992186, -15.120868526367197],
            [-38.995767916992186, -15.117168526367195],
            [-38.995767916992186, -15.114168526367198],
            [-38.995767916992186, -15.109968526367197],
            [-38.995867916992182, -15.105068526367198],
            [-38.996167916992185, -15.098968526367198],
            [-38.996367916992185, -15.094868526367197],
            [-38.996367916992185, -15.090368526367197],
            [-38.996267916992188, -15.085668526367197],
            [-38.996067916992182, -15.079268526367198],
            [-38.996067916992182, -15.073268526367197],
            [-38.996067916992182, -15.068468526367198],
            [-38.996067916992182, -15.063068526367196],
            [-38.996067916992182, -15.058068526367197],
            [-38.996167916992185, -15.054368526367195],
            [-38.996167916992185, -15.050768526367197],
            [-38.996267916992188, -15.045368526367195],
            [-38.996367916992185, -15.041368526367197],
            [-38.996367916992185, -15.037568526367195],
            [-38.996367916992185, -15.032568526367196],
            [-38.996367916992185, -15.028368526367196],
            [-38.996567916992184, -15.025068526367196],
            [-38.996567916992184, -15.021868526367196],
            [-38.996567916992184, -15.018168526367198],
            [-38.996367916992185, -15.013568526367198],
            [-38.996567916992184, -15.009568526367197],
            [-38.996567916992184, -15.004568526367198],
            [-38.996567916992184, -15.000168526367197],
            [-38.996967916992183, -14.996068526367196],
            [-38.997267916992186, -14.991168526367197],
            [-38.997667916992185, -14.986768526367197],
            [-38.998767916992186, -14.983068526367198],
            [-38.999667916992188, -14.980268526367198],
            [-39.001267916992184, -14.977868526367196],
            [-39.002767916992184, -14.974168526367198],
            [-39.003767916992182, -14.970168526367196],
            [-39.004067916992184, -14.966268526367195],
            [-39.004667916992183, -14.961568526367195],
            [-39.005267916992182, -14.956868526367195],
            [-39.006867916992185, -14.952168526367196],
            [-39.008067916992182, -14.949268526367195],
            [-39.008867916992187, -14.945968526367196],
            [-39.010467916992184, -14.941668526367195],
            [-39.013467916992184, -14.937968526367197],
            [-39.014567916992185, -14.932968526367198],
            [-39.015667916992186, -14.930168526367197],
            [-39.016767916992187, -14.926668526367198],
            [-39.018267916992187, -14.921368526367196],
            [-39.018967916992182, -14.916368526367197],
            [-39.019567916992187, -14.912068526367197],
            [-39.020067916992183, -14.907568526367196],
            [-39.020067916992183, -14.902668526367197],
            [-39.020667916992188, -14.899468526367198],
            [-39.021267916992187, -14.896468526367197],
            [-39.021767916992182, -14.893168526367198],
            [-39.021867916992186, -14.888468526367195],
            [-39.022367916992188, -14.883468526367196],
            [-39.022667916992184, -14.877668526367195],
            [-39.022967916992187, -14.872168526367197],
            [-39.023267916992182, -14.866368526367197],
            [-39.023667916992189, -14.861868526367196],
            [-39.023667916992189, -14.858668526367197],
            [-39.023767916992185, -14.854468526367196],
            [-39.023967916992184, -14.850568526367198],
            [-39.023967916992184, -14.847368526367195],
            [-39.024067916992188, -14.843168526367197],
            [-39.024067916992188, -14.837568526367196],
            [-39.023967916992184, -14.833168526367196],
            [-39.023967916992184, -14.830168526367196],
            [-39.024067916992188, -14.825968526367195],
            [-39.024067916992188, -14.820968526367196],
            [-39.023967916992184, -14.815868526367197],
            [-39.023967916992184, -14.811868526367196],
            [-39.024367916992183, -14.805468526367196],
            [-39.029167916992186, -14.803568526367197],
            [-39.028767916992187, -14.798668526367198],
            [-39.027867916992186, -14.794168526367198],
            [-39.027667916992186, -14.791168526367198],
            [-39.027567916992183, -14.787768526367195],
            [-39.027667916992186, -14.783368526367195],
            [-39.031467916992185, -14.784468526367196],
            [-39.035067916992183, -14.783468526367198],
            [-39.039867916992186, -14.783068526367195],
            [-39.043167916992182, -14.781268526367196],
            [-39.046167916992182, -14.779468526367197],
            [-39.049467916992185, -14.777268526367195],
            [-39.052567916992189, -14.777868526367197],
            [-39.054167916992185, -14.774768526367197],
            [-39.054067916992182, -14.770068526367197],
            [-39.056067916992184, -14.766368526367195],
            [-39.057767916992184, -14.762468526367197],
            [-39.059367916992187, -14.758468526367196],
            [-39.060567916992184, -14.753868526367196],
            [-39.061567916992182, -14.749968526367198],
            [-39.062267916992184, -14.745768526367197],
            [-39.062667916992183, -14.742268526367198],
            [-39.062967916992186, -14.739368526367198],
            [-39.063467916992188, -14.734468526367195],
            [-39.063767916992184, -14.729468526367196],
            [-39.064067916992187, -14.725668526367198],
            [-39.064067916992187, -14.722068526367195],
            [-39.064467916992186, -14.717668526367195],
            [-39.064667916992185, -14.713668526367197],
            [-39.064967916992188, -14.709668526367196],
            [-39.064967916992188, -14.704868526367196],
            [-39.064967916992188, -14.699968526367197],
            [-39.065167916992188, -14.694268526367196],
            [-39.065167916992188, -14.689068526367198],
            [-39.065167916992188, -14.684168526367195],
            [-39.065167916992188, -14.679368526367195],
            [-39.065167916992188, -14.675768526367197],
            [-39.065167916992188, -14.672468526367197],
            [-39.065167916992188, -14.669168526367198],
            [-39.064867916992185, -14.665868526367195],
            [-39.064667916992185, -14.662368526367196],
            [-39.064667916992185, -14.659468526367196],
            [-39.064167916992183, -14.654568526367196],
            [-39.063867916992187, -14.649368526367198],
            [-39.063767916992184, -14.645768526367196],
            [-39.063567916992184, -14.642568526367196],
            [-39.063367916992185, -14.639668526367196],
            [-39.062967916992186, -14.635368526367195],
            [-39.062467916992183, -14.631368526367197],
            [-39.061867916992185, -14.626568526367198],
            [-39.060567916992184, -14.620868526367197],
            [-39.059367916992187, -14.616168526367197],
            [-39.057967916992183, -14.611768526367197],
            [-39.055767916992188, -14.607868526367195],
            [-39.054667916992187, -14.602568526367197],
            [-39.052967916992188, -14.598368526367196],
            [-39.053067916992184, -14.593768526367196],
            [-39.052567916992189, -14.590168526367197],
            [-39.050767916992186, -14.586168526367196],
            [-39.049267916992186, -14.583268526367196],
            [-39.048267916992188, -14.579068526367195],
            [-39.048267916992188, -14.574068526367196],
            [-39.047767916992186, -14.569568526367195],
            [-39.046467916992185, -14.566368526367196],
            [-39.045067916992188, -14.563468526367195],
            [-39.043067916992186, -14.559768526367197],
            [-39.041667916992182, -14.554768526367198],
            [-39.040067916992186, -14.551568526367195],
            [-39.039167916992184, -14.547468526367197],
            [-39.038367916992186, -14.543168526367197],
            [-39.037367916992189, -14.540268526367196],
            [-39.036467916992187, -14.536468526367198],
            [-39.035267916992183, -14.532268526367197],
            [-39.035967916992185, -14.528668526367195],
            [-39.035967916992185, -14.524868526367197],
            [-39.035867916992188, -14.521068526367195],
            [-39.034867916992184, -14.517368526367196],
            [-39.034467916992185, -14.513868526367197],
            [-39.034867916992184, -14.510168526367195],
            [-39.033467916992187, -14.506068526367198],
            [-39.033967916992182, -14.502668526367195],
            [-39.033967916992182, -14.498868526367197],
            [-39.033467916992187, -14.494668526367196],
            [-39.033067916992188, -14.490768526367198],
            [-39.032367916992186, -14.486168526367198],
            [-39.031267916992185, -14.481468526367195],
            [-39.029467916992182, -14.477268526367197],
            [-39.027367916992183, -14.473168526367196],
            [-39.026467916992182, -14.469468526367198],
            [-39.026267916992182, -14.466468526367198],
            [-39.025867916992183, -14.461568526367195],
            [-39.025067916992185, -14.458268526367196],
            [-39.024467916992187, -14.453568526367196],
            [-39.023467916992182, -14.448768526367196],
            [-39.022667916992184, -14.444568526367195],
            [-39.021767916992182, -14.440568526367198],
            [-39.021167916992184, -14.436968526367195],
            [-39.020667916992188, -14.433368526367197],
            [-39.019767916992187, -14.429068526367196],
            [-39.019067916992185, -14.425468526367197],
            [-39.018267916992187, -14.420568526367198],
            [-39.017067916992183, -14.415268526367196],
            [-39.015967916992182, -14.410868526367196],
            [-39.015367916992183, -14.406668526367195],
            [-39.014667916992188, -14.403768526367195],
            [-39.013967916992186, -14.400968526367198],
            [-39.013267916992184, -14.397968526367197],
            [-39.011767916992184, -14.394368526367195],
            [-39.010667916992183, -14.389668526367196],
            [-39.009967916992188, -14.384568526367197],
            [-39.009267916992187, -14.380968526367198],
            [-39.008467916992188, -14.377368526367196],
            [-39.007667916992183, -14.373568526367198],
            [-39.006367916992183, -14.369368526367197],
            [-39.004167916992188, -14.364968526367196],
            [-39.003767916992182, -14.361068526367195],
            [-39.001267916992184, -14.358568526367197],
            [-38.999967916992183, -14.355868526367196],
            [-39.001567916992187, -14.353468526367195],
            [-38.999767916992184, -14.351168526367196],
            [-39.000167916992183, -14.347668526367197],
            [-39.001367916992187, -14.344568526367198],
            [-38.999467916992188, -14.340568526367196],
            [-38.997967916992188, -14.336468526367195],
            [-38.997267916992186, -14.333268526367196],
            [-38.996067916992182, -14.329868526367196],
            [-38.994167916992183, -14.325968526367195],
            [-38.993267916992188, -14.321568526367198],
            [-38.992567916992186, -14.318168526367195],
            [-38.991167916992183, -14.315468526367198],
            [-38.989067916992184, -14.312768526367197],
            [-38.987867916992187, -14.308268526367197],
            [-38.985367916992182, -14.305468526367196],
            [-38.983567916992186, -14.302568526367196],
            [-38.981767916992183, -14.298868526367198],
            [-38.980567916992186, -14.292868526367197],
            [-38.983267916992183, -14.290268526367196],
            [-38.984767916992183, -14.287568526367195],
            [-38.984467916992187, -14.284468526367196],
            [-38.983367916992187, -14.281168526367196],
            [-38.983667916992182, -14.278168526367196],
            [-38.985367916992182, -14.275368526367195],
            [-38.989967916992185, -14.275068526367196],
            [-38.992667916992183, -14.272868526367198],
            [-38.993867916992187, -14.269668526367198],
            [-38.994767916992188, -14.265668526367197],
            [-38.995067916992184, -14.261468526367196],
            [-38.994767916992188, -14.257368526367195],
            [-38.994667916992185, -14.253268526367197],
            [-38.994367916992182, -14.249068526367196],
            [-38.994067916992186, -14.244468526367196],
            [-38.993667916992187, -14.239968526367196],
            [-38.992967916992185, -14.235768526367195],
            [-38.993667916992187, -14.231968526367197],
            [-38.993367916992185, -14.228168526367195],
            [-38.991267916992186, -14.222568526367198],
            [-38.987967916992183, -14.220968526367198],
            [-38.989667916992182, -14.218468526367197],
            [-38.989667916992182, -14.215068526367197],
            [-38.990267916992188, -14.211568526367195],
            [-38.990567916992184, -14.207368526367198],
            [-38.990367916992184, -14.203468526367196],
            [-38.989667916992182, -14.198468526367197],
            [-38.988867916992184, -14.194568526367195],
            [-38.988067916992186, -14.190968526367197],
            [-38.987267916992188, -14.187668526367197],
            [-38.986067916992184, -14.182968526367198],
            [-38.984967916992183, -14.179468526367195],
            [-38.983567916992186, -14.176568526367195],
            [-38.981767916992183, -14.173568526367195],
            [-38.979167916992182, -14.170368526367195],
            [-38.978067916992188, -14.167268526367195],
            [-38.978567916992183, -14.162768526367195],
            [-38.978567916992183, -14.158368526367195],
            [-38.977767916992185, -14.153968526367198],
            [-38.976167916992182, -14.150168526367196],
            [-38.975267916992188, -14.146468526367197],
            [-38.975367916992184, -14.141368526367195],
            [-38.974767916992185, -14.138468526367195],
            [-38.973967916992187, -14.135668526367198],
            [-38.972667916992187, -14.131268526367197],
            [-38.971467916992182, -14.126968526367197],
            [-38.968967916992185, -14.122168526367197],
            [-38.968667916992182, -14.117968526367196],
            [-38.967867916992184, -14.114968526367196],
            [-38.967267916992185, -14.111968526367196],
            [-38.966467916992187, -14.108868526367196],
            [-38.965467916992182, -14.105068526367198],
            [-38.963667916992186, -14.101168526367196],
            [-38.961467916992184, -14.097568526367198],
            [-38.960467916992187, -14.094768526367197],
            [-38.959767916992185, -14.090868526367196],
            [-38.958967916992187, -14.087568526367196],
            [-38.957867916992186, -14.084368526367196],
            [-38.955967916992186, -14.081268526367197],
            [-38.954667916992186, -14.077468526367195],
            [-38.954667916992186, -14.073468526367197],
            [-38.955167916992188, -14.070268526367197],
            [-38.954667916992186, -14.065568526367198],
            [-38.953567916992185, -14.062468526367198],
            [-38.952467916992184, -14.059368526367198],
            [-38.951567916992182, -14.054768526367198],
            [-38.951067916992187, -14.049968526367195],
            [-38.949067916992185, -14.045068526367196],
            [-38.948867916992185, -14.041168526367198],
            [-38.948967916992189, -14.037868526367195],
            [-38.948167916992183, -14.033768526367197],
            [-38.946867916992183, -14.030168526367195],
            [-38.945967916992188, -14.026168526367197],
            [-38.945767916992182, -14.022668526367195],
            [-38.945267916992186, -14.019768526367198],
            [-38.944267916992182, -14.017068526367197],
            [-38.942767916992182, -14.013968526367197],
            [-38.942767916992182, -14.009868526367196],
            [-38.943767916992186, -14.005968526367198],
            [-38.943767916992186, -14.001068526367195],
            [-38.942967916992188, -13.995768526367197],
            [-38.942167916992183, -13.991668526367196],
            [-38.941367916992185, -13.988068526367197],
            [-38.940167916992188, -13.983368526367197],
            [-38.939167916992183, -13.978968526367197],
            [-38.938067916992182, -13.975268526367195],
            [-38.936867916992185, -13.971968526367196],
            [-38.934467916992183, -13.968668526367196],
            [-38.933267916992186, -13.965368526367197],
            [-38.933267916992186, -13.961468526367195],
            [-38.932167916992185, -13.957368526367198],
            [-38.931367916992187, -13.954368526367197],
            [-38.930267916992186, -13.951268526367198],
            [-38.929667916992187, -13.948468526367197],
            [-38.929667916992187, -13.945268526367197],
            [-38.928267916992183, -13.942668526367196],
            [-38.927767916992188, -13.938868526367198],
            [-38.928367916992187, -13.935868526367198],
            [-38.929767916992184, -13.932968526367198],
            [-38.930167916992183, -13.928368526367198],
            [-38.930467916992185, -13.924268526367197],
            [-38.929967916992183, -13.921068526367197],
            [-38.928867916992182, -13.916168526367198],
            [-38.928367916992187, -13.912268526367196],
            [-38.928567916992186, -13.907768526367196],
            [-38.930067916992186, -13.903668526367195],
            [-38.932267916992188, -13.898968526367195],
            [-38.933667916992185, -13.894268526367195],
            [-38.934967916992186, -13.891068526367196],
            [-38.935867916992187, -13.887468526367197],
            [-38.938567916992184, -13.884268526367197],
            [-38.941267916992182, -13.883168526367196],
            [-38.943767916992186, -13.880668526367195],
            [-38.946367916992187, -13.879068526367195],
            [-38.952567916992187, -13.873268526367195],
            [-38.967667916992184, -13.848668526367195],
            [-38.972267916992188, -13.842368526367196],
            [-38.981367916992184, -13.829068526367195],
            [-38.983867916992182, -13.826568526367197],
            [-38.985367916992182, -13.824068526367196],
            [-38.986767916992186, -13.821068526367196],
            [-38.988267916992186, -13.817368526367197],
            [-38.989967916992185, -13.812768526367197],
            [-38.991467916992185, -13.809468526367198],
            [-38.993267916992188, -13.806168526367195],
            [-38.994767916992188, -13.802768526367196],
            [-38.996267916992188, -13.798868526367198],
            [-38.997667916992185, -13.794268526367198],
            [-38.998567916992187, -13.789768526367197],
            [-38.999167916992185, -13.786968526367197],
            [-38.999667916992188, -13.783368526367195],
            [-39.000167916992183, -13.778768526367195],
            [-39.000267916992186, -13.774768526367197],
            [-39.000267916992186, -13.770768526367196],
            [-39.000167916992183, -13.766768526367198],
            [-38.999467916992188, -13.762368526367197],
            [-38.999067916992182, -13.758068526367197],
            [-38.998567916992187, -13.754568526367198],
            [-38.998067916992184, -13.750868526367196],
            [-38.997467916992186, -13.746568526367195],
            [-38.996567916992184, -13.742668526367197],
            [-38.995567916992186, -13.738868526367195],
            [-38.994467916992186, -13.734968526367197],
            [-38.993367916992185, -13.730568526367197],
            [-38.992267916992184, -13.727068526367198],
            [-38.991167916992183, -13.723668526367195],
            [-38.989467916992183, -13.719468526367198],
            [-38.988867916992184, -13.715868526367196],
            [-38.987867916992187, -13.711268526367196],
            [-38.984967916992183, -13.710368526367198],
            [-38.983267916992183, -13.707568526367197],
            [-38.981067916992188, -13.704568526367197],
            [-38.978467916992187, -13.701068526367198],
            [-38.975267916992188, -13.697468526367196],
            [-38.972867916992186, -13.695468526367197],
            [-38.970567916992188, -13.693168526367195],
            [-38.968467916992182, -13.691268526367196],
            [-38.966467916992187, -13.689068526367198],
            [-38.963967916992182, -13.686668526367196],
            [-38.967567916992188, -13.683068526367197],
            [-38.969167916992184, -13.679668526367195],
            [-38.969767916992183, -13.676368526367195],
            [-38.971467916992182, -13.671968526367195],
            [-38.968767916992185, -13.670368526367195],
            [-38.964367916992188, -13.671368526367196],
            [-38.959667916992188, -13.670768526367198],
            [-38.956567916992185, -13.669268526367198],
            [-38.952067916992185, -13.669668526367197],
            [-38.949067916992185, -13.666468526367197],
            [-38.946567916992187, -13.664568526367198],
            [-38.944167916992185, -13.662068526367197],
            [-38.939567916992182, -13.659868526367195],
            [-38.935867916992187, -13.658068526367195],
            [-38.931867916992182, -13.657568526367196],
            [-38.928567916992186, -13.657568526367196],
            [-38.924367916992182, -13.658368526367195],
            [-38.921467916992185, -13.662268526367196],
            [-38.920367916992184, -13.666468526367197],
            [-38.918567916992188, -13.671168526367197],
            [-38.917267916992188, -13.674368526367196],
            [-38.914167916992184, -13.675468526367197],
            [-38.912067916992186, -13.673168526367196],
            [-38.909267916992185, -13.670368526367195],
            [-38.906167916992182, -13.668568526367196],
            [-38.902267916992187, -13.666968526367196],
            [-38.898667916992189, -13.665268526367196],
            [-38.899267916992187, -13.660568526367197],
            [-38.896167916992184, -13.657068526367198],
            [-38.892567916992185, -13.655068526367195],
            [-38.891567916992187, -13.651568526367196],
            [-38.892067916992183, -13.648468526367196],
            [-38.891267916992184, -13.644068526367196],
            [-38.892067916992183, -13.639668526367196],
            [-38.895067916992183, -13.636568526367196],
            [-38.896167916992184, -13.631268526367197],
            [-38.900667916992184, -13.627968526367198],
            [-38.903767916992187, -13.624868526367198],
            [-38.905967916992182, -13.621568526367195],
            [-38.905167916992184, -13.616868526367195],
            [-38.905867916992186, -13.613568526367196],
            [-38.906667916992184, -13.609968526367197],
            [-38.904767916992185, -13.607568526367196],
            [-38.901567916992185, -13.601968526367195],
            [-38.903467916992184, -13.598368526367196],
            [-38.906767916992187, -13.596768526367196],
            [-38.911967916992182, -13.593068526367198],
            [-38.914767916992183, -13.588668526367197],
            [-38.914167916992184, -13.584268526367197],
            [-38.914867916992186, -13.579468526367197],
            [-38.918867916992184, -13.579268526367198],
            [-38.921967916992187, -13.579568526367197],
            [-38.924967916992188, -13.578268526367197],
            [-38.928267916992183, -13.576068526367198],
            [-38.930467916992185, -13.572468526367196],
            [-38.931667916992183, -13.569868526367195],
            [-38.933267916992186, -13.563068526367196],
            [-38.933367916992182, -13.559668526367197],
            [-38.933367916992182, -13.555468526367196],
            [-38.933267916992186, -13.552268526367197],
            [-38.933267916992186, -13.548968526367197],
            [-38.932667916992187, -13.544468526367197],
            [-38.931967916992186, -13.539968526367197],
            [-38.931567916992186, -13.535868526367196],
            [-38.930567916992182, -13.530868526367197],
            [-38.929767916992184, -13.526868526367195],
            [-38.928967916992185, -13.523268526367197],
            [-38.927567916992189, -13.519268526367195],
            [-38.926167916992185, -13.514968526367195],
            [-38.924967916992188, -13.511768526367195],
            [-38.923167916992185, -13.509168526367198],
            [-38.921167916992182, -13.507068526367195],
            [-38.918567916992188, -13.503468526367197],
            [-38.915567916992188, -13.501068526367195],
            [-38.912467916992185, -13.498768526367197],
            [-38.911167916992184, -13.495268526367198],
            [-38.911967916992182, -13.491568526367196],
            [-38.915567916992188, -13.486668526367197],
            [-38.917067916992188, -13.482468526367196],
            [-38.916667916992182, -13.478068526367196],
            [-38.914267916992188, -13.474468526367197],
            [-38.910667916992182, -13.473468526367196],
            [-38.906967916992187, -13.474168526367198],
            [-38.903467916992184, -13.472268526367195],
            [-38.900367916992188, -13.468768526367196],
            [-38.898667916992189, -13.465368526367197],
            [-38.895767916992185, -13.463168526367195],
            [-38.892867916992188, -13.460468526367197],
            [-38.892167916992186, -13.454968526367196],
            [-38.892667916992188, -13.450168526367197],
            [-38.895067916992183, -13.447468526367196],
            [-38.897067916992185, -13.443868526367197],
            [-38.900067916992185, -13.440568526367198],
            [-38.900667916992184, -13.436868526367196],
            [-38.902567916992183, -13.432168526367196],
            [-38.904567916992185, -13.428968526367196],
            [-38.905567916992183, -13.426068526367196],
            [-38.905167916992184, -13.422568526367197],
            [-38.905367916992184, -13.419168526367198],
            [-38.906167916992182, -13.415668526367195],
            [-38.906267916992185, -13.412568526367195],
            [-38.907067916992183, -13.409568526367195],
            [-38.907367916992186, -13.406168526367196],
            [-38.907567916992186, -13.402668526367197],
            [-38.907367916992186, -13.399068526367195],
            [-38.907367916992186, -13.395968526367195],
            [-38.906167916992182, -13.391768526367198],
            [-38.905967916992182, -13.386068526367197],
            [-38.909467916992185, -13.380968526367198],
            [-38.912567916992188, -13.378868526367196],
            [-38.916167916992187, -13.375768526367196],
            [-38.919467916992183, -13.378868526367196],
            [-38.923067916992188, -13.380468526367196],
            [-38.925467916992183, -13.382968526367197],
            [-38.927867916992184, -13.385668526367198],
            [-38.930467916992185, -13.388168526367195],
            [-38.934467916992183, -13.390368526367197],
            [-38.936967916992188, -13.393468526367197],
            [-38.940167916992188, -13.395968526367195],
            [-38.944567916992185, -13.398268526367197],
            [-38.950667916992188, -13.389868526367195],
            [-38.954567916992183, -13.378768526367196],
            [-38.956467916992182, -13.374368526367196],
            [-38.957967916992182, -13.371868526367198],
            [-38.959267916992182, -13.369068526367197],
            [-38.960467916992187, -13.365868526367198],
            [-38.961567916992188, -13.362968526367197],
            [-38.962867916992188, -13.359768526367198],
            [-38.964067916992185, -13.355868526367196],
            [-38.965167916992186, -13.351668526367195],
            [-38.966167916992184, -13.346968526367196],
            [-38.966767916992183, -13.343468526367197],
            [-38.967067916992185, -13.340168526367197],
            [-38.967367916992188, -13.335868526367197],
            [-38.967567916992188, -13.332368526367198],
            [-38.967267916992185, -13.329068526367195],
            [-38.967267916992185, -13.326068526367198],
            [-38.967267916992185, -13.323168526367198],
            [-38.967067916992185, -13.319568526367195],
            [-38.966567916992183, -13.315568526367198],
            [-38.966167916992184, -13.312668526367197],
            [-38.965967916992184, -13.309468526367198],
            [-38.965667916992182, -13.305568526367196],
            [-38.965167916992186, -13.301168526367196],
            [-38.964867916992183, -13.296868526367195],
            [-38.964067916992185, -13.292468526367195],
            [-38.963667916992186, -13.288668526367196],
            [-38.962567916992185, -13.284568526367195],
            [-38.961467916992184, -13.280168526367195],
            [-38.960367916992183, -13.275468526367195],
            [-38.959067916992183, -13.271868526367196],
            [-38.957967916992182, -13.268468526367197],
            [-38.957067916992187, -13.265668526367197],
            [-38.956167916992186, -13.262968526367196],
            [-38.954067916992187, -13.257668526367198],
            [-38.952167916992188, -13.253568526367197],
            [-38.950767916992184, -13.250568526367196],
            [-38.948767916992182, -13.246568526367195],
            [-38.946367916992187, -13.242168526367195],
            [-38.944667916992188, -13.239068526367195],
            [-38.942167916992183, -13.234968526367197],
            [-38.939167916992183, -13.230668526367197],
            [-38.937167916992188, -13.227468526367197],
            [-38.934867916992182, -13.224468526367197],
            [-38.931967916992186, -13.220968526367198],
            [-38.929167916992185, -13.218368526367197],
            [-38.926067916992189, -13.216468526367198],
            [-38.922867916992182, -13.215068526367197],
            [-38.920267916992188, -13.212968526367195],
            [-38.916367916992186, -13.213668526367197],
            [-38.912767916992188, -13.212668526367196],
            [-38.909767916992188, -13.212568526367196],
            [-38.906967916992187, -13.211468526367195],
            [-38.905167916992184, -13.208468526367195],
            [-38.902267916992187, -13.205968526367197],
            [-38.898667916992189, -13.203568526367196],
            [-38.895067916992183, -13.201768526367196],
            [-38.890767916992182, -13.199368526367195],
            [-38.887867916992185, -13.197368526367196],
            [-38.885467916992184, -13.195168526367198],
            [-38.882467916992184, -13.193268526367195],
            [-38.877667916992188, -13.191568526367195],
            [-38.872767916992188, -13.189068526367198],
            [-38.868367916992185, -13.186968526367195],
            [-38.865067916992189, -13.183268526367197],
            [-38.863267916992186, -13.179368526367195],
            [-38.861067916992184, -13.177268526367197],
            [-38.859267916992188, -13.175068526367195],
            [-38.857167916992182, -13.172468526367197],
            [-38.854767916992188, -13.169968526367196],
            [-38.851167916992182, -13.166768526367196],
            [-38.846667916992182, -13.163768526367196],
            [-38.843167916992186, -13.160968526367196],
            [-38.839767916992187, -13.158968526367197],
            [-38.835467916992187, -13.156268526367196],
            [-38.831767916992185, -13.153968526367198],
            [-38.827867916992183, -13.151768526367196],
            [-38.824667916992183, -13.150368526367195],
            [-38.821067916992185, -13.149268526367198],
            [-38.817967916992188, -13.148168526367197],
            [-38.814467916992186, -13.146768526367197],
            [-38.811367916992182, -13.146268526367198],
            [-38.807267916992188, -13.146068526367195],
            [-38.803367916992187, -13.143768526367197],
            [-38.797767916992186, -13.137168526367198],
            [-38.789567916992183, -13.133068526367197],
            [-38.785667916992182, -13.132368526367195],
            [-38.780867916992186, -13.129868526367197],
            [-38.776867916992188, -13.127168526367196],
            [-38.772567916992188, -13.124668526367195],
            [-38.768967916992182, -13.123068526367195],
            [-38.765367916992183, -13.120768526367197],
            [-38.761567916992185, -13.117768526367197],
            [-38.760467916992184, -13.114468526367197],
            [-38.759267916992187, -13.111468526367197],
            [-38.756667916992185, -13.108068526367198],
            [-38.753767916992182, -13.104668526367195],
            [-38.752067916992182, -13.101768526367195],
            [-38.749867916992187, -13.097368526367195],
            [-38.747667916992185, -13.093368526367197],
            [-38.745067916992184, -13.090968526367195],
            [-38.742267916992184, -13.089868526367198],
            [-38.739467916992183, -13.090868526367196],
            [-38.736067916992184, -13.089368526367195],
            [-38.732467916992185, -13.085868526367197],
            [-38.730067916992184, -13.083168526367196],
            [-38.727767916992185, -13.079568526367197],
            [-38.726667916992184, -13.076068526367198],
            [-38.724867916992189, -13.072968526367195],
            [-38.722567916992183, -13.070668526367196],
            [-38.720567916992188, -13.068568526367198],
            [-38.717267916992185, -13.065568526367198],
            [-38.714867916992183, -13.063468526367195],
            [-38.712567916992185, -13.061168526367197],
            [-38.710067916992188, -13.059668526367197],
            [-38.707167916992184, -13.058868526367196],
            [-38.703267916992182, -13.057668526367195],
            [-38.698767916992182, -13.056168526367195],
            [-38.695167916992183, -13.055068526367197],
            [-38.691367916992185, -13.052468526367196],
            [-38.688267916992189, -13.049668526367196],
            [-38.684467916992183, -13.047568526367197],
            [-38.680767916992188, -13.045368526367195],
            [-38.677567916992189, -13.042568526367198],
            [-38.674767916992188, -13.039868526367197],
            [-38.671467916992185, -13.037568526367195],
            [-38.666767916992185, -13.037268526367196],
            [-38.660567916992186, -13.035068526367198],
            [-38.655467916992187, -13.031668526367195],
            [-38.652667916992186, -13.028668526367195],
            [-38.650867916992183, -13.026468526367196],
            [-38.648967916992184, -13.024068526367195],
            [-38.646867916992186, -13.020768526367196],
            [-38.644267916992185, -13.016768526367198],
            [-38.585467916992187, -13.012468526367197],
            [-38.539767916992183, -13.010468526367195],
            [-38.531567916992188, -13.010668526367198],
            [-38.527567916992183, -13.009968526367196],
            [-38.523967916992184, -13.011068526367197],
            [-38.519667916992184, -13.010368526367195],
            [-38.515367916992183, -13.010668526367198],
            [-38.511767916992184, -13.011768526367195],
            [-38.508467916992188, -13.011568526367196],
            [-38.505467916992188, -13.012068526367198],
            [-38.501267916992184, -13.011468526367196],
            [-38.496067916992182, -13.011568526367196],
            [-38.492167916992187, -13.012668526367197],
            [-38.489367916992187, -13.014868526367195],
            [-38.487167916992185, -13.016768526367198],
            [-38.483967916992185, -13.015668526367197],
            [-38.480067916992184, -13.015468526367197],
            [-38.475367916992184, -13.014568526367196],
            [-38.470967916992187, -13.014568526367196],
            [-38.468067916992183, -13.013568526367198],
            [-38.464767916992187, -13.010768526367197],
            [-38.461167916992189, -13.009068526367198],
            [-38.458767916992187, -13.007168526367195],
            [-38.454667916992186, -13.005968526367198],
            [-38.450667916992188, -13.003468526367197],
            [-38.447867916992188, -13.000568526367196],
            [-38.444667916992188, -12.999068526367196],
            [-38.440967916992186, -12.997468526367197],
            [-38.439467916992186, -12.994968526367195],
            [-38.437667916992183, -12.992268526367198],
            [-38.434667916992183, -12.989068526367195],
            [-38.431567916992186, -12.985768526367195],
            [-38.428967916992185, -12.983668526367197],
            [-38.425867916992182, -12.981168526367195],
            [-38.421467916992185, -12.978568526367198],
            [-38.416367916992186, -12.975968526367197],
            [-38.412767916992188, -12.973768526367195],
            [-38.409467916992185, -12.971268526367197],
            [-38.405567916992183, -12.968668526367196],
            [-38.401467916992182, -12.965868526367196],
            [-38.397567916992188, -12.962868526367195],
            [-38.393667916992186, -12.960768526367197],
            [-38.390967916992182, -12.959068526367197],
            [-38.388567916992187, -12.957568526367197],
            [-38.384967916992188, -12.956068526367197],
            [-38.379667916992183, -12.955768526367198],
            [-38.377167916992185, -12.953768526367195],
            [-38.372667916992185, -12.952068526367196],
            [-38.367467916992183, -12.951068526367198],
            [-38.362967916992183, -12.952168526367196],
            [-38.359967916992183, -12.955168526367196],
            [-38.357167916992182, -12.956468526367196],
            [-38.353367916992184, -12.956468526367196],
            [-38.349267916992183, -12.954368526367197],
            [-38.345567916992188, -12.952168526367196],
            [-38.342067916992185, -12.949968526367197],
            [-38.337667916992189, -12.946068526367196],
            [-38.333967916992187, -12.944168526367196],
            [-38.331567916992185, -12.941668526367195],
            [-38.328767916992184, -12.939068526367198],
            [-38.325167916992186, -12.935268526367196],
            [-38.322167916992186, -12.932268526367196],
            [-38.318867916992183, -12.929468526367195],
            [-38.315167916992188, -12.925368526367198],
            [-38.313267916992189, -12.922468526367197],
            [-38.310767916992184, -12.919168526367198],
            [-38.308867916992185, -12.916668526367197],
            [-38.306867916992182, -12.913668526367196],
            [-38.304667916992187, -12.911168526367195],
            [-38.302567916992189, -12.908868526367197],
            [-38.298867916992187, -12.904168526367197],
            [-38.295667916992187, -12.899868526367197],
            [-38.292767916992183, -12.895968526367195],
            [-38.288967916992185, -12.891568526367195],
            [-38.285267916992183, -12.887468526367197],
            [-38.281667916992184, -12.883068526367197],
            [-38.280167916992184, -12.879368526367195],
            [-38.277567916992183, -12.878068526367198],
            [-38.275067916992185, -12.876068526367195],
            [-38.270167916992186, -12.875468526367197],
            [-38.265667916992186, -12.873068526367195],
            [-38.263467916992184, -12.869668526367196],
            [-38.260967916992186, -12.867168526367195],
            [-38.258267916992182, -12.864668526367197],
            [-38.254467916992184, -12.861368526367198],
            [-38.250567916992182, -12.858568526367197],
            [-38.247667916992185, -12.855668526367197],
            [-38.244967916992188, -12.853468526367195],
            [-38.241867916992184, -12.849568526367197],
            [-38.239667916992182, -12.846368526367197],
            [-38.237467916992188, -12.843968526367195],
            [-38.234767916992183, -12.840168526367197],
            [-38.231367916992184, -12.835668526367197],
            [-38.228067916992188, -12.832368526367198],
            [-38.225067916992188, -12.829268526367198],
            [-38.221767916992185, -12.826068526367198],
            [-38.218967916992185, -12.823468526367197],
            [-38.216767916992183, -12.821268526367195],
            [-38.214567916992188, -12.818868526367197],
            [-38.211867916992183, -12.815568526367198],
            [-38.208967916992187, -12.812368526367198],
            [-38.206467916992182, -12.808368526367197],
            [-38.203267916992182, -12.803868526367197],
            [-38.200667916992188, -12.799768526367195],
            [-38.197967916992184, -12.796068526367197],
            [-38.194367916992185, -12.791168526367198],
            [-38.191867916992187, -12.787368526367196],
            [-38.189867916992185, -12.784868526367195],
            [-38.186567916992182, -12.782868526367196],
            [-38.184367916992187, -12.780468526367198],
            [-38.180867916992185, -12.777868526367197],
            [-38.177167916992182, -12.774368526367198],
            [-38.174367916992182, -12.771568526367197],
            [-38.171767916992188, -12.768268526367198],
            [-38.168867916992184, -12.764268526367196],
            [-38.167167916992184, -12.761568526367196],
            [-38.164467916992187, -12.758468526367196],
            [-38.161167916992184, -12.754468526367198],
            [-38.158667916992187, -12.751268526367195],
            [-38.155467916992187, -12.747368526367197],
            [-38.152567916992183, -12.744068526367197],
            [-38.149867916992186, -12.740768526367198],
            [-38.147667916992184, -12.737968526367197],
            [-38.145767916992185, -12.735868526367195],
            [-38.143667916992186, -12.733368526367197],
            [-38.141267916992184, -12.731368526367195],
            [-38.138567916992187, -12.728168526367195],
            [-38.134567916992182, -12.723468526367196],
            [-38.131367916992183, -12.719768526367197],
            [-38.128567916992182, -12.716168526367195],
            [-38.125867916992185, -12.712368526367197],
            [-38.124067916992182, -12.708568526367195],
            [-38.120767916992186, -12.706268526367197],
            [-38.118567916992184, -12.703568526367196],
            [-38.115767916992183, -12.700668526367195],
            [-38.112567916992184, -12.697368526367196],
            [-38.109667916992187, -12.693768526367197],
            [-38.106767916992183, -12.690868526367197],
            [-38.104967916992187, -12.688468526367195],
            [-38.102767916992185, -12.686068526367198],
            [-38.099967916992185, -12.683668526367196],
            [-38.096167916992187, -12.680868526367195],
            [-38.093767916992185, -12.679068526367196],
            [-38.089767916992187, -12.676168526367196],
            [-38.086267916992185, -12.672168526367198],
            [-38.083667916992184, -12.668068526367197],
            [-38.081067916992183, -12.664468526367195],
            [-38.078767916992184, -12.662068526367197],
            [-38.076267916992187, -12.659168526367196],
            [-38.073167916992183, -12.655868526367197],
            [-38.069567916992185, -12.654068526367197],
            [-38.067367916992183, -12.651968526367195],
            [-38.064067916992187, -12.650468526367195],
            [-38.061267916992186, -12.648768526367196],
            [-38.059867916992182, -12.645668526367196],
            [-38.057467916992188, -12.641468526367195],
            [-38.054667916992187, -12.639268526367196],
            [-38.051867916992187, -12.637368526367197],
            [-38.048967916992183, -12.636368526367196],
            [-38.046367916992182, -12.633868526367195],
            [-38.044967916992185, -12.629168526367195],
            [-38.045367916992184, -12.624168526367196],
            [-38.043567916992188, -12.619468526367196],
            [-38.042467916992187, -12.614968526367196],
            [-38.040967916992187, -12.610768526367195],
            [-38.038667916992182, -12.607868526367195],
            [-38.036267916992188, -12.604668526367195],
            [-38.034167916992182, -12.602468526367197],
            [-38.031967916992187, -12.599868526367196],
            [-38.030867916992186, -12.594868526367197],
            [-38.027067916992188, -12.593668526367196],
            [-38.025067916992185, -12.591168526367195],
            [-38.021767916992182, -12.588368526367198],
            [-38.017967916992184, -12.585668526367197],
            [-38.014667916992188, -12.583668526367195],
            [-38.011067916992182, -12.581768526367195],
            [-38.007367916992187, -12.581568526367196],
            [-38.004467916992184, -12.579268526367198],
            [-38.001867916992182, -12.577968526367197],
            [-37.999967916992183, -12.574968526367197],
            [-37.998367916992187, -12.572068526367197],
            [-37.996867916992187, -12.569568526367195],
            [-37.994067916992186, -12.565868526367197],
            [-37.992967916992185, -12.561868526367196],
            [-37.991567916992182, -12.559068526367195],
            [-37.989367916992187, -12.556568526367197],
            [-37.987467916992188, -12.554368526367195],
            [-37.986067916992184, -12.551068526367196],
            [-37.984167916992185, -12.548168526367196],
            [-37.982767916992188, -12.544768526367196],
            [-37.981767916992183, -12.540268526367196],
            [-37.980567916992186, -12.536268526367195],
            [-37.978867916992186, -12.532868526367196],
            [-37.976967916992187, -12.529168526367197],
            [-37.975267916992188, -12.525668526367195],
            [-37.973167916992182, -12.521468526367197],
            [-37.971167916992187, -12.518168526367198],
            [-37.969567916992183, -12.515768526367197],
            [-37.967267916992185, -12.512768526367196],
            [-37.964067916992185, -12.509168526367198],
            [-37.961167916992189, -12.504668526367198],
            [-37.958967916992187, -12.499468526367195],
            [-37.957067916992187, -12.496268526367196],
            [-37.954667916992186, -12.492468526367198],
            [-37.952167916992188, -12.488868526367195],
            [-37.950167916992186, -12.486068526367195],
            [-37.947867916992188, -12.482868526367195],
            [-37.945167916992183, -12.478968526367197],
            [-37.941867916992187, -12.474168526367198],
            [-37.939867916992185, -12.470568526367195],
            [-37.937467916992183, -12.467568526367195],
            [-37.935467916992188, -12.465068526367197],
            [-37.931967916992186, -12.460468526367197],
            [-37.930067916992186, -12.456868526367195],
            [-37.928267916992183, -12.453968526367195],
            [-37.925567916992186, -12.450668526367195],
            [-37.922767916992186, -12.446568526367198],
            [-37.918967916992187, -12.442368526367197],
            [-37.915967916992187, -12.440468526367198],
            [-37.914467916992187, -12.437268526367195],
            [-37.912867916992184, -12.433368526367197],
            [-37.910867916992188, -12.430068526367197],
            [-37.908067916992188, -12.426668526367198],
            [-37.905667916992186, -12.423668526367198],
            [-37.903667916992184, -12.421068526367197],
            [-37.901467916992182, -12.418068526367197],
            [-37.897567916992188, -12.413468526367197],
            [-37.895067916992183, -12.410568526367197],
            [-37.892567916992185, -12.406968526367198],
            [-37.889967916992184, -12.403968526367198],
            [-37.888167916992188, -12.401268526367197],
            [-37.885767916992187, -12.398668526367196],
            [-37.882467916992184, -12.394568526367195],
            [-37.879367916992187, -12.389568526367196],
            [-37.876567916992187, -12.384868526367196],
            [-37.874167916992185, -12.381268526367197],
            [-37.872467916992186, -12.378568526367197],
            [-37.870767916992186, -12.376268526367195],
            [-37.868867916992187, -12.373668526367197],
            [-37.866867916992184, -12.370468526367198],
            [-37.864667916992182, -12.367168526367195],
            [-37.862767916992183, -12.363668526367196],
            [-37.861067916992184, -12.361168526367198],
            [-37.858867916992182, -12.358068526367198],
            [-37.856367916992184, -12.354268526367196],
            [-37.854167916992182, -12.351168526367196],
            [-37.851967916992187, -12.347768526367197],
            [-37.848967916992187, -12.343468526367197],
            [-37.846767916992185, -12.340068526367197],
            [-37.844867916992186, -12.337268526367197],
            [-37.842667916992184, -12.334368526367196],
            [-37.840567916992185, -12.331468526367196],
            [-37.838767916992182, -12.328568526367196],
            [-37.837067916992183, -12.326068526367198],
            [-37.835367916992183, -12.323168526367198],
            [-37.832867916992186, -12.319868526367195],
            [-37.829067916992187, -12.314968526367196],
            [-37.826267916992187, -12.310768526367195],
            [-37.823567916992182, -12.307268526367196],
            [-37.820167916992183, -12.302968526367195],
            [-37.816667916992188, -12.298268526367195],
            [-37.813567916992184, -12.294168526367198],
            [-37.810467916992188, -12.290268526367196],
            [-37.807667916992187, -12.286968526367197],
            [-37.805267916992186, -12.283668526367197],
            [-37.802167916992182, -12.280068526367195],
            [-37.798967916992183, -12.276268526367197],
            [-37.796367916992182, -12.273168526367197],
            [-37.793867916992184, -12.270768526367196],
            [-37.791667916992182, -12.268568526367197],
            [-37.788867916992189, -12.265068526367195],
            [-37.786267916992188, -12.261768526367195],
            [-37.784267916992185, -12.258868526367195],
            [-37.782067916992183, -12.255168526367196],
            [-37.779267916992183, -12.251068526367195],
            [-37.777267916992187, -12.248268526367195],
            [-37.775767916992187, -12.245768526367197],
            [-37.773667916992189, -12.242268526367198],
            [-37.771767916992182, -12.240068526367196],
            [-37.770067916992183, -12.237268526367195],
            [-37.768267916992187, -12.234768526367198],
            [-37.766467916992184, -12.231668526367198],
            [-37.764567916992185, -12.228468526367195],
            [-37.762967916992189, -12.225968526367197],
            [-37.761067916992182, -12.221768526367196],
            [-37.758067916992182, -12.219568526367198],
            [-37.756267916992186, -12.216168526367195],
            [-37.754367916992187, -12.212868526367195],
            [-37.752467916992188, -12.209568526367196],
            [-37.750967916992188, -12.207168526367198],
            [-37.748367916992187, -12.203168526367197],
            [-37.745167916992187, -12.197468526367196],
            [-37.742967916992185, -12.193768526367197],
            [-37.740767916992183, -12.189668526367196],
            [-37.738067916992186, -12.185768526367195],
            [-37.736067916992184, -12.182968526367198],
            [-37.733967916992185, -12.179768526367198],
            [-37.730867916992182, -12.175068526367195],
            [-37.728867916992186, -12.171668526367196],
            [-37.726967916992187, -12.168368526367196],
            [-37.724567916992186, -12.164768526367197],
            [-37.723167916992182, -12.162268526367196],
            [-37.720967916992187, -12.158368526367195],
            [-37.718767916992185, -12.154468526367197],
            [-37.716567916992183, -12.150368526367195],
            [-37.714367916992188, -12.146768526367197],
            [-37.712067916992183, -12.142868526367195],
            [-37.710367916992183, -12.139968526367195],
            [-37.708467916992184, -12.136868526367195],
            [-37.706467916992182, -12.133768526367195],
            [-37.704867916992185, -12.130768526367195],
            [-37.702667916992183, -12.126868526367197],
            [-37.699267916992184, -12.121568526367195],
            [-37.697067916992182, -12.117468526367198],
            [-37.694967916992184, -12.114368526367198],
            [-37.692467916992186, -12.110768526367195],
            [-37.690567916992187, -12.107868526367195],
            [-37.689067916992187, -12.104468526367196],
            [-37.687367916992187, -12.101468526367196],
            [-37.686867916992185, -12.097668526367197],
            [-37.684367916992187, -12.095868526367195],
            [-37.681867916992182, -12.091268526367195],
            [-37.678967916992185, -12.086468526367195],
            [-37.677167916992182, -12.082868526367196],
            [-37.675267916992183, -12.078868526367195],
            [-37.673567916992184, -12.074868526367197],
            [-37.671467916992185, -12.071068526367196],
            [-37.670267916992188, -12.068068526367195],
            [-37.668567916992188, -12.064668526367196],
            [-37.667067916992188, -12.061868526367196],
            [-37.665667916992184, -12.059368526367198],
            [-37.663967916992185, -12.055768526367196],
            [-37.661367916992184, -12.050868526367196],
            [-37.658467916992187, -12.045668526367198],
            [-37.656167916992182, -12.041368526367197],
            [-37.654267916992183, -12.037368526367196],
            [-37.651867916992188, -12.033368526367195],
            [-37.649767916992182, -12.029468526367197],
            [-37.647967916992187, -12.026468526367196],
            [-37.646567916992183, -12.023368526367197],
            [-37.644267916992185, -12.019068526367196],
            [-37.642067916992183, -12.015668526367197],
            [-37.640667916992186, -12.012668526367197],
            [-37.637967916992189, -12.008068526367197],
            [-37.634967916992188, -12.003068526367198],
            [-37.631567916992182, -11.997268526367197],
            [-37.629067916992184, -11.992968526367196],
            [-37.626867916992182, -11.988568526367196],
            [-37.624467916992188, -11.983668526367197],
            [-37.621967916992183, -11.979268526367196],
            [-37.619667916992185, -11.974568526367197],
            [-37.617967916992185, -11.970868526367195],
            [-37.616467916992185, -11.967568526367195],
            [-37.614667916992182, -11.963768526367197],
            [-37.612867916992187, -11.960668526367197],
            [-37.610267916992186, -11.955768526367198],
            [-37.608567916992186, -11.952368526367195],
            [-37.607067916992186, -11.948768526367196],
            [-37.605567916992186, -11.945268526367197],
            [-37.604267916992185, -11.942668526367196],
            [-37.602567916992186, -11.939168526367197],
            [-37.601467916992185, -11.936268526367197],
            [-37.600367916992184, -11.933568526367196],
            [-37.598967916992187, -11.930468526367196],
            [-37.597167916992184, -11.926268526367195],
            [-37.595567916992188, -11.922168526367198],
            [-37.593967916992185, -11.919268526367198],
            [-37.591667916992186, -11.914168526367195],
            [-37.589067916992185, -11.909468526367196],
            [-37.586967916992187, -11.904768526367196],
            [-37.584567916992185, -11.899068526367195],
            [-37.583267916992185, -11.896468526367197],
            [-37.581167916992186, -11.891368526367195],
            [-37.578467916992182, -11.885968526367197],
            [-37.576367916992183, -11.881268526367197],
            [-37.574067916992185, -11.875868526367196],
            [-37.572467916992188, -11.872668526367196],
            [-37.570967916992188, -11.869068526367197],
            [-37.569067916992182, -11.864768526367197],
            [-37.567767916992182, -11.861968526367196],
            [-37.566567916992184, -11.859168526367196],
            [-37.564967916992188, -11.856368526367195],
            [-37.563867916992187, -11.853468526367195],
            [-37.562467916992183, -11.850368526367195],
            [-37.560867916992187, -11.847368526367195],
            [-37.559467916992183, -11.843768526367196],
            [-37.558267916992186, -11.841168526367195],
            [-37.555867916992185, -11.836268526367196],
            [-37.554167916992185, -11.832268526367198],
            [-37.552867916992184, -11.829568526367197],
            [-37.551667916992187, -11.826768526367196],
            [-37.549667916992185, -11.822868526367195],
            [-37.548267916992188, -11.819868526367195],
            [-37.546467916992185, -11.816268526367196],
            [-37.544967916992185, -11.812668526367197],
            [-37.542867916992186, -11.808368526367197],
            [-37.540567916992188, -11.803068526367195],
            [-37.538667916992182, -11.798968526367197],
            [-37.537067916992186, -11.794668526367197],
            [-37.534767916992188, -11.789568526367198],
            [-37.532667916992182, -11.784768526367195],
            [-37.530367916992184, -11.779768526367196],
            [-37.528067916992185, -11.774768526367197],
            [-37.526467916992182, -11.770668526367196],
            [-37.524867916992186, -11.766868526367197],
            [-37.523467916992182, -11.763968526367197],
            [-37.521767916992182, -11.760668526367198],
            [-37.519667916992184, -11.756368526367197],
            [-37.517667916992188, -11.752068526367196],
            [-37.515367916992183, -11.747768526367196],
            [-37.514267916992182, -11.743268526367196],
            [-37.512367916992183, -11.739968526367196],
            [-37.509867916992185, -11.737468526367195],
            [-37.507467916992184, -11.733268526367198],
            [-37.506067916992187, -11.730368526367197],
            [-37.504567916992187, -11.727468526367197],
            [-37.503067916992187, -11.724468526367197],
            [-37.501267916992184, -11.720868526367195],
            [-37.499667916992188, -11.717268526367196],
            [-37.497967916992188, -11.714268526367196],
            [-37.496567916992184, -11.711468526367195],
            [-37.494767916992188, -11.707968526367196],
            [-37.493067916992182, -11.704368526367197],
            [-37.491867916992184, -11.701568526367197],
            [-37.489767916992186, -11.698168526367198],
            [-37.488067916992186, -11.694868526367195],
            [-37.486367916992187, -11.691568526367195],
            [-37.483867916992182, -11.686668526367196],
            [-37.481667916992187, -11.682268526367196],
            [-37.479667916992184, -11.678268526367198],
            [-37.478067916992188, -11.675768526367197],
            [-37.476667916992184, -11.672768526367197],
            [-37.474967916992185, -11.669268526367198],
            [-37.473067916992186, -11.666068526367198],
            [-37.471667916992182, -11.663168526367198],
            [-37.469767916992183, -11.658968526367197],
            [-37.467867916992184, -11.655668526367197],
            [-37.464567916992188, -11.648368526367197],
            [-37.462967916992184, -11.645768526367196],
            [-37.461167916992189, -11.641768526367198],
            [-37.457867916992186, -11.635168526367195],
            [-37.455967916992186, -11.631768526367196],
            [-37.454267916992187, -11.628468526367197],
            [-37.452567916992187, -11.625168526367197],
            [-37.451267916992187, -11.622368526367197],
            [-37.448467916992186, -11.616568526367196],
            [-37.446567916992187, -11.612868526367198],
            [-37.444667916992188, -11.608568526367197],
            [-37.441867916992187, -11.603668526367198],
            [-37.439867916992185, -11.600268526367195],
            [-37.436267916992186, -11.592968526367198],
            [-37.433267916992186, -11.586968526367198],
            [-37.429667916992187, -11.580368526367195],
            [-37.426867916992187, -11.575168526367197],
            [-37.423367916992184, -11.568468526367198],
            [-37.420067916992188, -11.562368526367198],
            [-37.417567916992184, -11.557468526367195],
            [-37.415567916992188, -11.553368526367198],
            [-37.412767916992188, -11.548568526367195],
            [-37.409767916992188, -11.543368526367196],
            [-37.406267916992185, -11.536468526367198],
            [-37.401467916992182, -11.529068526367197],
            [-37.399467916992187, -11.526168526367197],
            [-37.395767916992185, -11.520068526367197],
            [-37.392867916992188, -11.515468526367197],
            [-37.390667916992186, -11.512668526367197],
            [-37.388267916992184, -11.508868526367195],
            [-37.384167916992183, -11.503568526367197],
            [-37.381067916992187, -11.500168526367197],
            [-37.379067916992184, -11.497768526367196],
            [-37.376267916992184, -11.494668526367196],
            [-37.373567916992187, -11.491168526367197],
            [-37.371367916992185, -11.488668526367196],
            [-37.367667916992183, -11.484468526367195],
            [-37.364167916992187, -11.480268526367198],
            [-37.361667916992182, -11.477268526367197],
            [-37.359167916992185, -11.474468526367197],
            [-37.356367916992184, -11.471268526367197],
            [-37.353367916992184, -11.467568526367195],
            [-37.350667916992187, -11.464068526367196],
            [-37.348867916992184, -11.461468526367195],
            [-37.348367916992188, -11.457668526367197],
            [-37.348467916992185, -11.454368526367197],
            [-37.345967916992187, -11.448268526367197],
            [-37.341167916992184, -11.442368526367197],
            [-37.337967916992184, -11.442668526367196],
            [-37.325967916992184, -11.436068526367198],
            [-37.319367916992185, -11.429768526367198],
            [-37.316667916992188, -11.426868526367198],
            [-37.313867916992187, -11.425368526367198],
            [-37.312167916992188, -11.422868526367196],
            [-37.311867916992185, -11.418468526367196],
            [-37.311567916992182, -11.415368526367196],
            [-37.310167916992185, -11.408668526367197],
            [-37.309367916992187, -11.402568526367197],
            [-37.309367916992187, -11.398968526367195],
            [-37.309467916992183, -11.394368526367195],
            [-37.306867916992182, -11.384368526367197],
            [-37.305567916992182, -11.380668526367195],
            [-37.304367916992184, -11.377068526367196],
            [-37.302967916992188, -11.372668526367196],
            [-37.301067916992189, -11.367268526367198],
            [-37.298967916992183, -11.362568526367195],
            [-37.296667916992185, -11.357168526367197],
            [-37.294567916992186, -11.352568526367197],
            [-37.293067916992186, -11.349168526367198],
            [-37.290867916992184, -11.344768526367197],
            [-37.288467916992182, -11.339768526367195],
            [-37.286467916992187, -11.335468526367197],
            [-37.284467916992185, -11.331768526367195],
            [-37.282667916992182, -11.328168526367197],
            [-37.280367916992184, -11.323868526367196],
            [-37.278667916992184, -11.320668526367196],
            [-37.276767916992185, -11.316868526367195],
            [-37.274567916992183, -11.313068526367196],
            [-37.272367916992188, -11.309368526367198],
            [-37.269367916992188, -11.304068526367196],
            [-37.267067916992183, -11.300268526367198],
            [-37.264567916992185, -11.296368526367196],
            [-37.261767916992184, -11.291968526367196],
            [-37.260267916992184, -11.289468526367195],
            [-37.257767916992186, -11.286468526367198],
            [-37.255767916992184, -11.283368526367195],
            [-37.252667916992188, -11.279468526367197],
            [-37.250767916992189, -11.276868526367195],
            [-37.248767916992186, -11.273968526367195],
            [-37.246867916992187, -11.271568526367197],
            [-37.244967916992188, -11.269368526367195],
            [-37.242767916992186, -11.266468526367195],
            [-37.239167916992187, -11.262068526367198],
            [-37.235267916992186, -11.257368526367195],
            [-37.232867916992184, -11.254168526367195],
            [-37.230567916992186, -11.251368526367198],
            [-37.228167916992184, -11.248268526367195],
            [-37.225867916992186, -11.245568526367197],
            [-37.223667916992184, -11.242968526367196],
            [-37.220567916992188, -11.239168526367198],
            [-37.218767916992185, -11.236968526367196],
            [-37.216267916992187, -11.233868526367196],
            [-37.213667916992186, -11.230668526367197],
            [-37.210667916992186, -11.227268526367197],
            [-37.208667916992184, -11.224768526367196],
            [-37.205967916992186, -11.221968526367196],
            [-37.202867916992183, -11.218668526367196],
            [-37.199967916992186, -11.215368526367197],
            [-37.196267916992184, -11.211468526367195],
            [-37.189467916992186, -11.205068526367196],
            [-37.185467916992188, -11.200668526367195],
            [-37.180467916992185, -11.195768526367196],
            [-37.176667916992187, -11.192368526367197],
            [-37.173967916992183, -11.189968526367196],
            [-37.168667916992185, -11.184468526367198],
            [-37.166067916992183, -11.182468526367195],
            [-37.164167916992184, -11.179468526367195],
            [-37.162367916992189, -11.174968526367195],
            [-37.160567916992186, -11.170268526367195],
            [-37.157767916992185, -11.164868526367197],
            [-37.154067916992183, -11.160968526367196],
            [-37.152267916992187, -11.156568526367195],
            [-37.150867916992183, -11.151468526367196],
            [-37.147667916992184, -11.146568526367197],
            [-37.145667916992188, -11.138868526367197],
            [-37.144067916992185, -11.134368526367197],
            [-37.140667916992186, -11.127668526367195],
            [-37.137167916992183, -11.121268526367196],
            [-37.134867916992185, -11.117268526367198],
            [-37.132367916992187, -11.113568526367196],
            [-37.129467916992184, -11.109168526367196],
            [-37.125467916992186, -11.102768526367196],
            [-37.123867916992182, -11.100268526367195],
            [-37.121967916992183, -11.097668526367197],
            [-37.119767916992188, -11.094468526367198],
            [-37.114467916992183, -11.086768526367198],
            [-37.111667916992182, -11.082868526367196],
            [-37.109667916992187, -11.079868526367196],
            [-37.105867916992182, -11.074368526367195],
            [-37.101367916992189, -11.067968526367196],
            [-37.099767916992185, -11.065568526367198],
            [-37.096667916992182, -11.060768526367195],
            [-37.092067916992185, -11.054168526367196],
            [-37.088767916992182, -11.049268526367197],
            [-37.083667916992184, -11.041668526367197],
            [-37.079267916992187, -11.035268526367197],
            [-37.076267916992187, -11.031168526367196],
            [-37.073167916992183, -11.026568526367196],
            [-37.071367916992187, -11.024068526367195],
            [-37.068867916992183, -11.020768526367196],
            [-37.066667916992188, -11.017468526367196],
            [-37.062467916992183, -11.011868526367195],
            [-37.059167916992187, -11.007668526367198],
            [-37.057267916992188, -11.005268526367196],
            [-37.054667916992187, -11.002168526367196],
            [-37.052267916992186, -10.999368526367196],
            [-37.048667916992187, -10.995468526367198],
            [-37.046167916992182, -10.992468526367198],
            [-37.044167916992187, -10.990068526367196],
            [-37.040267916992185, -10.985568526367196],
            [-37.037067916992186, -10.982168526367197],
            [-37.034867916992184, -10.978968526367197],
            [-37.033967916992182, -10.975568526367198],
            [-37.033867916992186, -10.970268526367196],
            [-37.035567916992186, -10.965968526367195],
            [-37.036167916992184, -10.962668526367196],
            [-37.035667916992182, -10.959868526367195],
            [-37.034567916992188, -10.957068526367195],
            [-37.030167916992184, -10.954268526367198],
            [-37.026267916992182, -10.951568526367197],
            [-37.024767916992182, -10.949068526367196],
            [-37.022667916992184, -10.945168526367198],
            [-37.020167916992186, -10.941868526367195],
            [-37.018167916992184, -10.938468526367195],
            [-37.015767916992182, -10.934368526367198],
            [-37.014267916992182, -10.931368526367198],
            [-37.012067916992187, -10.927768526367196],
            [-37.009667916992186, -10.923968526367197],
            [-37.007067916992185, -10.920268526367195],
            [-37.004567916992187, -10.916668526367197],
            [-37.002667916992188, -10.914168526367195],
            [-37.000267916992186, -10.911268526367195],
            [-36.998367916992187, -10.908968526367197],
            [-36.996567916992184, -10.906668526367195],
            [-36.994767916992188, -10.904168526367197],
            [-36.991667916992185, -10.900368526367195],
            [-36.989467916992183, -10.897368526367195],
            [-36.987567916992184, -10.895168526367197],
            [-36.983967916992185, -10.890668526367197],
            [-36.981367916992184, -10.887168526367198],
            [-36.976967916992187, -10.881668526367196],
            [-36.973367916992188, -10.876568526367198],
            [-36.969467916992187, -10.871668526367195],
            [-36.964567916992188, -10.865868526367198],
            [-36.961167916992189, -10.861168526367198],
            [-36.953267916992182, -10.851968526367195],
            [-36.943567916992187, -10.841168526367195],
            [-36.938767916992184, -10.836568526367195],
            [-36.935767916992184, -10.834668526367196],
            [-36.931867916992182, -10.832168526367198],
            [-36.930067916992186, -10.829668526367197],
            [-36.928367916992187, -10.826368526367197],
            [-36.926167916992185, -10.823468526367197],
            [-36.924467916992185, -10.821068526367196],
            [-36.922467916992183, -10.818468526367198],
            [-36.919667916992182, -10.815168526367195],
            [-36.916167916992187, -10.811068526367198],
            [-36.912867916992184, -10.807268526367196],
            [-36.909267916992185, -10.803668526367197],
            [-36.906167916992182, -10.800068526367195],
            [-36.903067916992185, -10.796868526367195],
            [-36.899567916992183, -10.793068526367197],
            [-36.895667916992188, -10.788168526367198],
            [-36.890167916992183, -10.782668526367196],
            [-36.885367916992188, -10.777268526367195],
            [-36.879867916992183, -10.771268526367198],
            [-36.875267916992186, -10.766468526367195],
            [-36.867467916992183, -10.757968526367197],
            [-36.860867916992184, -10.751368526367198],
            [-36.858067916992184, -10.748568526367198],
            [-36.854167916992182, -10.743368526367195],
            [-36.849767916992185, -10.742568526367197],
            [-36.846767916992185, -10.739068526367195],
            [-36.843367916992186, -10.735568526367196],
            [-36.840367916992186, -10.732468526367196],
            [-36.833267916992185, -10.725968526367197],
            [-36.827867916992183, -10.721368526367197],
            [-36.820667916992186, -10.715568526367196],
            [-36.815167916992188, -10.711168526367196],
            [-36.809467916992183, -10.706768526367195],
            [-36.805467916992185, -10.703468526367196],
            [-36.801867916992187, -10.700768526367195],
            [-36.796867916992184, -10.696868526367197],
            [-36.792467916992187, -10.693868526367197],
            [-36.787567916992188, -10.690268526367195],
            [-36.783367916992184, -10.687468526367198],
            [-36.779267916992183, -10.685168526367196],
            [-36.775967916992187, -10.682968526367198],
            [-36.772067916992185, -10.680468526367196],
            [-36.769267916992185, -10.678668526367197],
            [-36.760967916992186, -10.673968526367197],
            [-36.755267916992182, -10.670368526367195],
            [-36.750567916992182, -10.667768526367198],
            [-36.744767916992188, -10.664168526367195],
            [-36.742267916992184, -10.662568526367195],
            [-36.738567916992189, -10.660368526367197],
            [-36.734667916992187, -10.658068526367195],
            [-36.731967916992183, -10.656968526367198],
            [-36.725667916992187, -10.653768526367195],
            [-36.722267916992188, -10.652268526367195],
            [-36.717667916992184, -10.650368526367195],
            [-36.699367916992188, -10.643568526367197],
            [-36.687467916992183, -10.638568526367198],
            [-36.680867916992185, -10.635168526367195],
            [-36.673167916992185, -10.629868526367197],
            [-36.656267916992185, -10.619168526367197],
            [-36.648367916992186, -10.613268526367197],
            [-36.639067916992182, -10.606368526367195],
            [-36.624167916992185, -10.596368526367197],
            [-36.612567916992184, -10.588368526367198],
            [-36.599767916992185, -10.580768526367198],
            [-36.592867916992184, -10.576768526367196],
            [-36.587067916992183, -10.573768526367196],
            [-36.583667916992184, -10.572368526367196],
            [-36.580367916992188, -10.572468526367196],
            [-36.576067916992187, -10.571768526367197],
            [-36.564667916992185, -10.566668526367195],
            [-36.547467916992183, -10.559668526367197],
            [-36.537867916992184, -10.555868526367195],
            [-36.527267916992187, -10.551168526367196],
            [-36.521767916992182, -10.549468526367196],
            [-36.511467916992189, -10.545568526367198],
            [-36.500867916992185, -10.541968526367196],
            [-36.493367916992185, -10.538768526367196],
            [-36.489667916992182, -10.537368526367196],
            [-36.486167916992187, -10.536368526367195],
            [-36.482167916992182, -10.534768526367195],
            [-36.476667916992184, -10.532768526367196],
            [-36.469767916992183, -10.530168526367195],
            [-36.466267916992187, -10.528968526367198],
            [-36.458267916992185, -10.525668526367195],
            [-36.454667916992186, -10.523768526367196],
            [-36.449367916992188, -10.521768526367197],
            [-36.442467916992186, -10.519268526367195],
            [-36.436667916992185, -10.517068526367197],
            [-36.432967916992183, -10.515768526367197],
            [-36.426867916992187, -10.514668526367196],
            [-36.422167916992187, -10.514368526367196],
            [-36.418067916992186, -10.514268526367196],
            [-36.414167916992184, -10.513468526367195],
            [-36.408867916992186, -10.511768526367195],
            [-36.406567916992188, -10.507968526367197],
            [-36.404167916992186, -10.506368526367197],
            [-36.401267916992182, -10.503268526367197],
            [-36.398467916992182, -10.499368526367196],
            [-36.394867916992183, -10.496568526367195],
            [-36.391767916992187, -10.496968526367198],
            [-36.390667916992186, -10.499668526367195],
            [-36.387667916992186, -10.498568526367198],
            [-36.385467916992184, -10.495168526367195],
            [-36.383867916992187, -10.491868526367195],
            [-36.381767916992182, -10.487768526367198],
            [-36.379867916992183, -10.484168526367196],
            [-36.376667916992183, -10.478868526367197],
            [-36.374467916992188, -10.474968526367196],
            [-36.372267916992186, -10.471168526367197],
            [-36.370167916992187, -10.467668526367195],
            [-36.368067916992182, -10.464568526367195],
            [-36.366167916992183, -10.461868526367198],
            [-36.363667916992185, -10.457868526367196],
            [-36.361667916992182, -10.454268526367198],
            [-36.360067916992186, -10.451768526367196],
            [-36.358067916992184, -10.448468526367197],
            [-36.356067916992188, -10.444368526367196],
            [-36.354467916992185, -10.441568526367195],
            [-36.352767916992185, -10.438068526367196],
            [-36.350667916992187, -10.434468526367198],
            [-36.349467916992182, -10.431368526367198],
            [-36.348067916992186, -10.428668526367197],
            [-36.345967916992187, -10.425068526367195],
            [-36.344067916992188, -10.421468526367196],
            [-36.342567916992188, -10.418568526367196],
            [-36.340567916992185, -10.414968526367197],
            [-36.338467916992187, -10.411468526367198],
            [-36.336767916992187, -10.408368526367195],
            [-36.335167916992184, -10.405868526367197],
            [-36.333267916992185, -10.402368526367198],
            [-36.331767916992185, -10.399568526367197],
            [-36.330067916992185, -10.397068526367196],
            [-36.328267916992182, -10.394368526367195],
            [-36.326067916992187, -10.391068526367196],
            [-36.323867916992185, -10.387668526367197],
            [-36.322067916992182, -10.385168526367195],
            [-36.320267916992186, -10.382168526367195],
            [-36.318567916992187, -10.379868526367197],
            [-36.315267916992184, -10.375268526367197],
            [-36.312167916992188, -10.371568526367195],
            [-36.309867916992182, -10.369068526367197],
            [-36.307767916992184, -10.366868526367195],
            [-36.305567916992182, -10.364468526367197],
            [-36.302167916992182, -10.363268526367197],
            [-36.298867916992187, -10.361968526367196],
            [-36.297467916992183, -10.359168526367196],
            [-36.295367916992184, -10.356968526367197],
            [-36.293667916992185, -10.353968526367197],
            [-36.296167916992182, -10.350668526367198],
            [-36.299967916992188, -10.347268526367195],
            [-36.302967916992188, -10.344468526367198],
            [-36.302167916992182, -10.340868526367196],
            [-36.302567916992189, -10.335068526367195],
            [-36.302767916992188, -10.330968526367197],
            [-36.302267916992186, -10.327568526367195],
            [-36.301567916992184, -10.323568526367197],
            [-36.300267916992183, -10.318568526367198],
            [-36.298267916992188, -10.313568526367195],
            [-36.296767916992188, -10.310468526367195],
            [-36.295067916992188, -10.307168526367196],
            [-36.293367916992182, -10.303968526367196],
            [-36.291367916992186, -10.300468526367197],
            [-36.288667916992182, -10.296368526367196],
            [-36.286267916992188, -10.293168526367197],
            [-36.283867916992186, -10.289868526367197],
            [-36.281967916992187, -10.287468526367196],
            [-36.279567916992185, -10.284568526367195],
            [-36.277567916992183, -10.281968526367198],
            [-36.275667916992184, -10.279768526367196],
            [-36.273667916992189, -10.277668526367197],
            [-36.271267916992187, -10.275368526367195],
            [-36.268767916992182, -10.272668526367195],
            [-36.266067916992185, -10.270068526367197],
            [-36.262667916992186, -10.266768526367198],
            [-36.258767916992184, -10.263268526367195],
            [-36.252967916992183, -10.258868526367195],
            [-36.249767916992184, -10.256568526367197],
            [-36.246867916992187, -10.254468526367198],
            [-36.244467916992186, -10.252668526367195],
            [-36.241867916992184, -10.250468526367197],
            [-36.239667916992182, -10.248068526367195],
            [-36.237267916992188, -10.245768526367197],
            [-36.234967916992183, -10.242768526367197],
            [-36.232767916992188, -10.239968526367196],
            [-36.229667916992184, -10.236068526367195],
            [-36.226967916992187, -10.232468526367196],
            [-36.224567916992186, -10.229468526367196],
            [-36.222067916992188, -10.226668526367195],
            [-36.219467916992187, -10.223968526367198],
            [-36.216767916992183, -10.221468526367197],
            [-36.214367916992188, -10.219468526367198],
            [-36.211467916992184, -10.217268526367196],
            [-36.208167916992188, -10.214868526367198],
            [-36.204367916992183, -10.212368526367197],
            [-36.201267916992187, -10.210468526367197],
            [-36.198567916992182, -10.208668526367195],
            [-36.195167916992183, -10.206468526367196],
            [-36.190967916992186, -10.203568526367196],
            [-36.187967916992186, -10.201068526367198],
            [-36.185167916992185, -10.198468526367197],
            [-36.182767916992184, -10.194568526367195],
            [-36.180767916992188, -10.190768526367197],
            [-36.179167916992185, -10.187668526367197],
            [-36.177267916992186, -10.184468526367198],
            [-36.175367916992187, -10.181568526367197],
            [-36.173567916992184, -10.178968526367196],
            [-36.170767916992183, -10.175568526367197],
            [-36.168967916992187, -10.173368526367195],
            [-36.166667916992182, -10.170768526367198],
            [-36.163467916992182, -10.168068526367197],
            [-36.159867916992184, -10.165268526367196],
            [-36.156267916992185, -10.162868526367195],
            [-36.152567916992183, -10.159868526367195],
            [-36.150367916992188, -10.156768526367195],
            [-36.146467916992187, -10.156168526367196],
            [-36.141767916992187, -10.155368526367198],
            [-36.137967916992189, -10.156768526367195],
            [-36.134867916992185, -10.159468526367196],
            [-36.132367916992187, -10.156168526367196],
            [-36.130967916992184, -10.153668526367195],
            [-36.129167916992188, -10.150468526367195],
            [-36.127667916992188, -10.147368526367195],
            [-36.125467916992186, -10.143668526367197],
            [-36.123367916992187, -10.140768526367197],
            [-36.121567916992184, -10.138568526367198],
            [-36.117967916992185, -10.136368526367196],
            [-36.113667916992185, -10.134068526367198],
            [-36.110267916992186, -10.130268526367196],
            [-36.108167916992187, -10.125568526367196],
            [-36.106467916992187, -10.120868526367197],
            [-36.104267916992185, -10.117168526367195],
            [-36.102367916992186, -10.114668526367197],
            [-36.100867916992186, -10.111468526367197],
            [-36.099467916992182, -10.108868526367196],
            [-36.097667916992187, -10.105968526367196],
            [-36.095367916992188, -10.102568526367197],
            [-36.093667916992182, -10.100068526367195],
            [-36.091567916992183, -10.097368526367195],
            [-36.089467916992184, -10.095068526367196],
            [-36.086167916992189, -10.092568526367195],
            [-36.082667916992186, -10.090368526367197],
            [-36.078567916992185, -10.088768526367197],
            [-36.075467916992189, -10.087568526367196],
            [-36.072867916992188, -10.086268526367196],
            [-36.067967916992188, -10.084468526367196],
            [-36.061967916992188, -10.084868526367195],
            [-36.057467916992188, -10.083568526367195],
            [-36.054367916992184, -10.080168526367196],
            [-36.050067916992184, -10.076268526367198],
            [-36.045867916992187, -10.074568526367198],
            [-36.044967916992185, -10.070468526367197],
            [-36.043167916992182, -10.066268526367196],
            [-36.041367916992186, -10.062968526367197],
            [-36.038167916992187, -10.059868526367197],
            [-36.035567916992186, -10.057168526367196],
            [-36.032867916992188, -10.053068526367195],
            [-36.030067916992188, -10.049468526367196],
            [-36.027267916992187, -10.046168526367197],
            [-36.025367916992188, -10.042168526367195],
            [-36.023167916992186, -10.038868526367196],
            [-36.020767916992185, -10.034568526367195],
            [-36.018967916992182, -10.031668526367195],
            [-36.017467916992182, -10.029068526367197],
            [-36.015767916992182, -10.025968526367198],
            [-36.014267916992182, -10.022968526367197],
            [-36.012467916992186, -10.019668526367198],
            [-36.010667916992183, -10.015668526367197],
            [-36.008567916992185, -10.012068526367198],
            [-36.006367916992183, -10.008068526367197],
            [-36.004167916992188, -10.004468526367198],
            [-36.002367916992185, -10.001968526367197],
            [-36.000167916992183, -9.998768526367197],
            [-35.997267916992186, -9.995268526367198],
            [-35.994067916992186, -9.990768526367198],
            [-35.991867916992184, -9.987168526367196],
            [-35.989467916992183, -9.983968526367196],
            [-35.987467916992188, -9.981768526367198],
            [-35.985267916992186, -9.979268526367196],
            [-35.982867916992184, -9.976768526367195],
            [-35.981167916992185, -9.974468526367197],
            [-35.978167916992184, -9.970268526367196],
            [-35.975567916992183, -9.966968526367197],
            [-35.974167916992187, -9.963968526367196],
            [-35.971967916992185, -9.959768526367196],
            [-35.970067916992186, -9.955768526367198],
            [-35.968367916992186, -9.952368526367195],
            [-35.966267916992187, -9.948468526367197],
            [-35.964867916992183, -9.945268526367197],
            [-35.963167916992184, -9.941368526367196],
            [-35.959367916992186, -9.936268526367197],
            [-35.956867916992188, -9.933368526367197],
            [-35.954667916992186, -9.930868526367195],
            [-35.952667916992183, -9.927468526367196],
            [-35.950667916992188, -9.924268526367197],
            [-35.948767916992182, -9.921068526367197],
            [-35.946067916992185, -9.916368526367197],
            [-35.943167916992188, -9.910968526367196],
            [-35.940767916992186, -9.907068526367198],
            [-35.937767916992186, -9.902268526367195],
            [-35.934467916992183, -9.898168526367197],
            [-35.929767916992184, -9.894068526367196],
            [-35.926867916992187, -9.891768526367198],
            [-35.924267916992186, -9.889668526367196],
            [-35.921067916992186, -9.887068526367198],
            [-35.918567916992188, -9.884668526367197],
            [-35.915667916992184, -9.882068526367195],
            [-35.913067916992183, -9.880268526367196],
            [-35.910367916992186, -9.878068526367198],
            [-35.907367916992186, -9.874768526367195],
            [-35.906167916992182, -9.870768526367197],
            [-35.905467916992187, -9.866668526367196],
            [-35.905067916992188, -9.862468526367195],
            [-35.903767916992187, -9.859468526367195],
            [-35.904767916992185, -9.856168526367195],
            [-35.906267916992185, -9.852468526367197],
            [-35.903667916992184, -9.848668526367195],
            [-35.899567916992183, -9.845668526367195],
            [-35.896567916992183, -9.843668526367196],
            [-35.893767916992182, -9.842368526367196],
            [-35.890767916992182, -9.840868526367196],
            [-35.888167916992188, -9.838168526367195],
            [-35.885767916992187, -9.835168526367195],
            [-35.883767916992184, -9.832168526367198],
            [-35.881367916992183, -9.829068526367195],
            [-35.878867916992185, -9.825468526367196],
            [-35.876567916992187, -9.821568526367198],
            [-35.875167916992183, -9.818568526367198],
            [-35.873367916992187, -9.815468526367198],
            [-35.871267916992188, -9.811868526367196],
            [-35.869367916992182, -9.808868526367196],
            [-35.866967916992188, -9.805168526367197],
            [-35.864667916992182, -9.801568526367195],
            [-35.862467916992188, -9.798268526367195],
            [-35.860667916992185, -9.795668526367198],
            [-35.858967916992185, -9.793168526367197],
            [-35.858267916992183, -9.789968526367197],
            [-35.854267916992185, -9.786968526367197],
            [-35.851367916992189, -9.783368526367195],
            [-35.849467916992182, -9.780868526367197],
            [-35.847567916992183, -9.778668526367195],
            [-35.845567916992188, -9.775668526367195],
            [-35.841767916992183, -9.771868526367196],
            [-35.838367916992183, -9.769268526367195],
            [-35.836867916992183, -9.766468526367195],
            [-35.834767916992185, -9.763268526367195],
            [-35.832867916992186, -9.759968526367196],
            [-35.830667916992184, -9.756768526367196],
            [-35.827567916992187, -9.753768526367196],
            [-35.825467916992189, -9.751568526367198],
            [-35.823167916992183, -9.749068526367196],
            [-35.820467916992186, -9.744768526367196],
            [-35.818467916992184, -9.740868526367198],
            [-35.818067916992185, -9.737168526367196],
            [-35.818467916992184, -9.733868526367196],
            [-35.815167916992188, -9.732868526367195],
            [-35.812267916992184, -9.729268526367196],
            [-35.810167916992185, -9.727068526367198],
            [-35.806667916992183, -9.724268526367197],
            [-35.804367916992184, -9.721168526367197],
            [-35.805867916992185, -9.717268526367196],
            [-35.801867916992187, -9.715968526367195],
            [-35.798567916992184, -9.715968526367195],
            [-35.794967916992185, -9.716568526367197],
            [-35.793367916992182, -9.713368526367198],
            [-35.790567916992188, -9.711468526367195],
            [-35.787767916992188, -9.708768526367198],
            [-35.785167916992187, -9.705968526367197],
            [-35.779867916992188, -9.701068526367198],
            [-35.776567916992185, -9.698168526367198],
            [-35.773767916992185, -9.694868526367195],
            [-35.770767916992185, -9.692068526367198],
            [-35.767867916992188, -9.690168526367195],
            [-35.764967916992184, -9.688368526367196],
            [-35.762467916992186, -9.685468526367195],
            [-35.760667916992183, -9.682768526367198],
            [-35.758067916992182, -9.680768526367196],
            [-35.754067916992184, -9.677968526367195],
            [-35.749367916992185, -9.675068526367195],
            [-35.745467916992183, -9.673368526367195],
            [-35.742167916992187, -9.672268526367198],
            [-35.738367916992182, -9.671468526367196],
            [-35.734967916992183, -9.671168526367197],
            [-35.731667916992187, -9.671368526367196],
            [-35.727767916992185, -9.672468526367197],
            [-35.724467916992182, -9.674368526367196],
            [-35.724167916992187, -9.680168526367197],
            [-35.723467916992185, -9.684068526367195],
            [-35.720567916992188, -9.680468526367196],
            [-35.717367916992188, -9.676068526367196],
            [-35.715067916992183, -9.672568526367197],
            [-35.712867916992188, -9.669268526367198],
            [-35.710467916992187, -9.666768526367196],
            [-35.707667916992186, -9.664868526367197],
            [-35.704567916992183, -9.664168526367195],
            [-35.699967916992186, -9.664468526367195],
            [-35.695767916992182, -9.664168526367195],
            [-35.697367916992185, -9.659768526367195],
            [-35.697767916992184, -9.656668526367195],
            [-35.698267916992187, -9.653468526367195],
            [-35.699267916992184, -9.649768526367197],
            [-35.698567916992182, -9.644668526367198],
            [-35.697867916992188, -9.640068526367195],
            [-35.697367916992185, -9.635768526367197],
            [-35.696267916992184, -9.632468526367198],
            [-35.695167916992183, -9.629468526367198],
            [-35.693867916992183, -9.626568526367198],
            [-35.692067916992187, -9.622168526367197],
            [-35.689967916992188, -9.618568526367195],
            [-35.687667916992183, -9.615468526367195],
            [-35.684467916992183, -9.611368526367198],
            [-35.681167916992187, -9.607768526367195],
            [-35.678667916992183, -9.604268526367196],
            [-35.676567916992184, -9.600268526367195],
            [-35.673867916992187, -9.596968526367196],
            [-35.670067916992188, -9.594068526367195],
            [-35.666367916992186, -9.591468526367198],
            [-35.664467916992187, -9.588668526367197],
            [-35.662067916992186, -9.586468526367195],
            [-35.659467916992185, -9.583268526367196],
            [-35.656767916992187, -9.579868526367196],
            [-35.655167916992184, -9.576468526367197],
            [-35.653767916992187, -9.572668526367195],
            [-35.651567916992185, -9.569968526367198],
            [-35.648167916992186, -9.567668526367196],
            [-35.644767916992187, -9.564668526367196],
            [-35.642067916992183, -9.560868526367198],
            [-35.639267916992182, -9.558368526367197],
            [-35.636067916992182, -9.556068526367195],
            [-35.633267916992182, -9.554368526367195],
            [-35.629667916992183, -9.552568526367196],
            [-35.624967916992183, -9.550368526367198],
            [-35.619967916992188, -9.548668526367198],
            [-35.616867916992184, -9.546168526367197],
            [-35.614967916992185, -9.542568526367198],
            [-35.613067916992186, -9.538468526367197],
            [-35.608967916992185, -9.534468526367196],
            [-35.606167916992185, -9.532268526367197],
            [-35.603667916992187, -9.530868526367197],
            [-35.600667916992187, -9.529768526367196],
            [-35.596267916992183, -9.529568526367196],
            [-35.592267916992185, -9.529568526367196],
            [-35.590867916992188, -9.522968526367197],
            [-35.590567916992185, -9.519868526367198],
            [-35.589867916992183, -9.517068526367197],
            [-35.588367916992183, -9.514068526367197],
            [-35.585967916992182, -9.510668526367198],
            [-35.582567916992183, -9.507668526367198],
            [-35.580767916992187, -9.505168526367196],
            [-35.578467916992182, -9.502368526367196],
            [-35.574167916992188, -9.499568526367195],
            [-35.569567916992185, -9.495468526367198],
            [-35.565767916992186, -9.493268526367196],
            [-35.562667916992183, -9.490768526367198],
            [-35.559067916992184, -9.487968526367197],
            [-35.556567916992186, -9.485068526367197],
            [-35.555867916992185, -9.481368526367195],
            [-35.555167916992183, -9.477468526367197],
            [-35.553267916992183, -9.474468526367197],
            [-35.548967916992183, -9.471268526367197],
            [-35.546367916992182, -9.468768526367196],
            [-35.543867916992184, -9.466668526367197],
            [-35.541967916992185, -9.464268526367196],
            [-35.539567916992183, -9.461168526367196],
            [-35.535867916992188, -9.457868526367196],
            [-35.531967916992187, -9.456868526367195],
            [-35.529067916992183, -9.453268526367197],
            [-35.528767916992187, -9.448468526367197],
            [-35.526867916992188, -9.445168526367198],
            [-35.523667916992189, -9.441968526367198],
            [-35.520167916992186, -9.440468526367198],
            [-35.516767916992187, -9.440468526367198],
            [-35.513267916992184, -9.439668526367196],
            [-35.511267916992182, -9.436268526367197],
            [-35.509267916992187, -9.431868526367197],
            [-35.506567916992182, -9.428668526367197],
            [-35.504867916992183, -9.426368526367195],
            [-35.502967916992183, -9.424168526367197],
            [-35.500767916992189, -9.421768526367195],
            [-35.503567916992182, -9.419168526367198],
            [-35.499867916992187, -9.416768526367196],
            [-35.497367916992182, -9.411968526367197],
            [-35.496167916992185, -9.407068526367198],
            [-35.495767916992186, -9.402268526367195],
            [-35.496367916992185, -9.398468526367196],
            [-35.495867916992182, -9.394268526367195],
            [-35.495167916992187, -9.390768526367197],
            [-35.494067916992186, -9.387868526367196],
            [-35.491667916992185, -9.382968526367197],
            [-35.488567916992189, -9.377368526367196],
            [-35.485267916992186, -9.372168526367197],
            [-35.482767916992188, -9.368868526367198],
            [-35.480367916992186, -9.366368526367197],
            [-35.478167916992184, -9.364168526367198],
            [-35.475967916992182, -9.361968526367196],
            [-35.473067916992186, -9.358568526367197],
            [-35.469867916992186, -9.355668526367197],
            [-35.467967916992187, -9.353368526367195],
            [-35.465567916992185, -9.350368526367195],
            [-35.462567916992185, -9.346668526367196],
            [-35.458967916992187, -9.343668526367196],
            [-35.456067916992183, -9.342568526367195],
            [-35.452567916992187, -9.341968526367197],
            [-35.448767916992182, -9.341668526367197],
            [-35.445667916992186, -9.340168526367197],
            [-35.442667916992185, -9.339468526367195],
            [-35.440167916992188, -9.336468526367195],
            [-35.439167916992183, -9.331468526367196],
            [-35.437267916992184, -9.327868526367197],
            [-35.434867916992182, -9.325668526367195],
            [-35.431967916992186, -9.323968526367196],
            [-35.428667916992183, -9.322668526367195],
            [-35.425067916992184, -9.321868526367197],
            [-35.419967916992185, -9.321568526367198],
            [-35.416067916992183, -9.319568526367195],
            [-35.419467916992183, -9.315768526367197],
            [-35.415867916992184, -9.313768526367195],
            [-35.412267916992185, -9.310768526367195],
            [-35.408967916992182, -9.308268526367197],
            [-35.406167916992182, -9.306668526367197],
            [-35.401867916992188, -9.305468526367196],
            [-35.397867916992183, -9.303268526367198],
            [-35.395367916992186, -9.299968526367195],
            [-35.394567916992187, -9.296468526367196],
            [-35.393167916992184, -9.293568526367196],
            [-35.389267916992182, -9.290968526367195],
            [-35.386067916992182, -9.285368526367197],
            [-35.382467916992184, -9.282568526367196],
            [-35.378067916992187, -9.280368526367198],
            [-35.373867916992182, -9.277268526367195],
            [-35.371067916992182, -9.273668526367196],
            [-35.368367916992185, -9.270768526367196],
            [-35.365767916992183, -9.267968526367195],
            [-35.363067916992186, -9.264668526367196],
            [-35.360067916992186, -9.261768526367195],
            [-35.356667916992187, -9.258468526367196],
            [-35.353667916992187, -9.254168526367195],
            [-35.351667916992184, -9.250768526367196],
            [-35.349767916992185, -9.248268526367195],
            [-35.347867916992186, -9.244668526367196],
            [-35.344267916992187, -9.240068526367196],
            [-35.342267916992185, -9.236068526367195],
            [-35.338967916992182, -9.232868526367195],
            [-35.338767916992182, -9.229668526367195],
            [-35.335467916992187, -9.226968526367195],
            [-35.332967916992182, -9.225068526367195],
            [-35.330767916992187, -9.223168526367196],
            [-35.329267916992187, -9.220268526367196],
            [-35.328167916992186, -9.217568526367195],
            [-35.327367916992188, -9.214768526367195],
            [-35.324967916992186, -9.211168526367196],
            [-35.323567916992182, -9.206768526367195],
            [-35.321667916992183, -9.202468526367195],
            [-35.319067916992182, -9.198568526367197],
            [-35.316267916992182, -9.196568526367198],
            [-35.311567916992182, -9.195268526367197],
            [-35.307967916992183, -9.194068526367197],
            [-35.305867916992185, -9.191968526367198],
            [-35.304167916992185, -9.189668526367196],
            [-35.301867916992187, -9.185868526367198],
            [-35.300067916992184, -9.182668526367195],
            [-35.298367916992184, -9.178668526367197],
            [-35.296867916992184, -9.174668526367196],
            [-35.295267916992188, -9.171468526367196],
            [-35.294967916992185, -9.168568526367196],
            [-35.295367916992184, -9.165268526367196],
            [-35.295267916992188, -9.160068526367198],
            [-35.295267916992188, -9.155868526367197],
            [-35.291067916992183, -9.156668526367195],
            [-35.287067916992186, -9.154068526367197],
            [-35.285567916992186, -9.149368526367198],
            [-35.286767916992183, -9.145768526367196],
            [-35.287067916992186, -9.142668526367196],
            [-35.286667916992187, -9.139268526367196],
            [-35.285567916992186, -9.135768526367197],
            [-35.284767916992188, -9.132368526367195],
            [-35.283167916992184, -9.129168526367195],
            [-35.281167916992182, -9.126868526367197],
            [-35.278767916992187, -9.125168526367197],
            [-35.275067916992185, -9.124468526367195],
            [-35.271767916992182, -9.123368526367198],
            [-35.269367916992188, -9.121168526367196],
            [-35.268267916992187, -9.114668526367197],
            [-35.265767916992182, -9.110868526367195],
            [-35.262667916992186, -9.106668526367198],
            [-35.260167916992188, -9.103068526367196],
            [-35.258767916992184, -9.099168526367198],
            [-35.258467916992188, -9.094568526367198],
            [-35.257667916992183, -9.091668526367197],
            [-35.256267916992186, -9.088368526367198],
            [-35.253567916992182, -9.086168526367196],
            [-35.250867916992185, -9.083468526367195],
            [-35.248567916992187, -9.081768526367195],
            [-35.245767916992186, -9.080068526367196],
            [-35.243667916992187, -9.076868526367196],
            [-35.242167916992187, -9.073468526367197],
            [-35.240067916992189, -9.068468526367198],
            [-35.241867916992184, -9.065968526367197],
            [-35.239467916992183, -9.061968526367195],
            [-35.239367916992187, -9.058268526367197],
            [-35.239667916992182, -9.052968526367195],
            [-35.238667916992185, -9.048668526367198],
            [-35.235667916992185, -9.043868526367195],
            [-35.232867916992184, -9.039568526367198],
            [-35.230367916992186, -9.036668526367198],
            [-35.229567916992188, -9.033168526367195],
            [-35.227467916992182, -9.030968526367197],
            [-35.226167916992182, -9.027968526367196],
            [-35.224567916992186, -9.025168526367196],
            [-35.224267916992183, -9.021868526367196],
            [-35.223467916992185, -9.019068526367196],
            [-35.221667916992182, -9.015368526367197],
            [-35.218667916992182, -9.010968526367197],
            [-35.215367916992186, -9.006668526367196],
            [-35.211567916992188, -9.003568526367197],
            [-35.207667916992186, -8.999468526367195],
            [-35.204667916992186, -8.996068526367196],
            [-35.200267916992182, -8.992568526367197],
            [-35.197367916992185, -8.990268526367196],
            [-35.192767916992182, -8.987468526367195],
            [-35.189567916992182, -8.983968526367196],
            [-35.186367916992182, -8.981168526367195],
            [-35.182167916992185, -8.978168526367195],
            [-35.179467916992188, -8.973668526367195],
            [-35.177267916992186, -8.969868526367197],
            [-35.175767916992186, -8.966668526367197],
            [-35.175067916992184, -8.963768526367197],
            [-35.173667916992187, -8.959768526367196],
            [-35.172467916992183, -8.956268526367197],
            [-35.171067916992186, -8.952868526367197],
            [-35.170367916992184, -8.948768526367196],
            [-35.169267916992183, -8.944068526367197],
            [-35.168567916992188, -8.940168526367195],
            [-35.167167916992184, -8.936368526367197],
            [-35.164767916992183, -8.931968526367196],
            [-35.162467916992185, -8.929068526367196],
            [-35.160067916992183, -8.926368526367195],
            [-35.157867916992188, -8.923868526367198],
            [-35.155067916992188, -8.920568526367198],
            [-35.153167916992182, -8.917468526367195],
            [-35.152567916992183, -8.914468526367195],
            [-35.153367916992188, -8.911268526367195],
            [-35.150767916992187, -8.906268526367196],
            [-35.147667916992184, -8.902568526367197],
            [-35.144567916992187, -8.899768526367197],
            [-35.143267916992187, -8.896468526367197],
            [-35.142867916992188, -8.892368526367196],
            [-35.142167916992186, -8.888968526367197],
            [-35.141467916992184, -8.885368526367195],
            [-35.139567916992185, -8.881368526367197],
            [-35.137467916992186, -8.878768526367196],
            [-35.136067916992182, -8.874968526367198],
            [-35.134567916992182, -8.871868526367198],
            [-35.131267916992186, -8.868868526367198],
            [-35.129367916992187, -8.866668526367196],
            [-35.129867916992183, -8.863268526367197],
            [-35.131667916992185, -8.859268526367195],
            [-35.132167916992188, -8.854568526367196],
            [-35.133067916992182, -8.849968526367196],
            [-35.133767916992184, -8.846668526367196],
            [-35.134267916992187, -8.842568526367195],
            [-35.134167916992183, -8.838668526367197],
            [-35.134367916992183, -8.835068526367195],
            [-35.133767916992184, -8.831868526367195],
            [-35.131367916992183, -8.827368526367195],
            [-35.129567916992187, -8.823868526367196],
            [-35.127667916992188, -8.820968526367196],
            [-35.125267916992186, -8.817768526367196],
            [-35.123267916992184, -8.814068526367198],
            [-35.120867916992182, -8.810768526367195],
            [-35.119067916992186, -8.806168526367195],
            [-35.117967916992185, -8.802268526367197],
            [-35.116067916992186, -8.799968526367195],
            [-35.113367916992182, -8.796768526367195],
            [-35.109367916992184, -8.795268526367195],
            [-35.106667916992187, -8.793968526367195],
            [-35.104267916992185, -8.790068526367197],
            [-35.103367916992184, -8.784768526367195],
            [-35.104567916992188, -8.780568526367198],
            [-35.105867916992182, -8.775768526367198],
            [-35.105867916992182, -8.772068526367196],
            [-35.105067916992184, -8.768768526367197],
            [-35.103167916992184, -8.764868526367195],
            [-35.099967916992185, -8.761068526367197],
            [-35.096967916992185, -8.759568526367197],
            [-35.093767916992185, -8.757668526367198],
            [-35.091967916992182, -8.753568526367197],
            [-35.087967916992184, -8.749668526367195],
            [-35.086167916992189, -8.746368526367196],
            [-35.086467916992184, -8.742268526367198],
            [-35.087367916992186, -8.736068526367195],
            [-35.089067916992185, -8.731968526367197],
            [-35.089267916992185, -8.727768526367196],
            [-35.087867916992188, -8.722368526367195],
            [-35.086567916992188, -8.719168526367195],
            [-35.084867916992188, -8.716468526367198],
            [-35.083167916992188, -8.713668526367197],
            [-35.079667916992186, -8.710368526367198],
            [-35.079267916992187, -8.706468526367196],
            [-35.079367916992183, -8.703468526367196],
            [-35.082267916992187, -8.701068526367198],
            [-35.085467916992187, -8.698568526367197],
            [-35.089467916992184, -8.695568526367197],
            [-35.087667916992189, -8.690768526367197],
            [-35.085067916992188, -8.687968526367197],
            [-35.084367916992186, -8.684468526367198],
            [-35.083667916992184, -8.681568526367197],
            [-35.081167916992186, -8.678568526367197],
            [-35.077967916992186, -8.675768526367197],
            [-35.072667916992188, -8.672768526367197],
            [-35.071267916992184, -8.667868526367197],
            [-35.073167916992183, -8.662268526367196],
            [-35.072967916992184, -8.657868526367196],
            [-35.072067916992182, -8.653668526367195],
            [-35.070167916992183, -8.649268526367198],
            [-35.067667916992185, -8.643968526367196],
            [-35.065567916992187, -8.640168526367198],
            [-35.063267916992189, -8.635968526367197],
            [-35.061867916992185, -8.632968526367197],
            [-35.060167916992185, -8.629868526367197],
            [-35.057967916992183, -8.625468526367197],
            [-35.054667916992187, -8.619068526367197],
            [-35.051867916992187, -8.615568526367195],
            [-35.048067916992188, -8.612468526367195],
            [-35.049267916992186, -8.607468526367196],
            [-35.046067916992186, -8.605668526367197],
            [-35.045067916992188, -8.602468526367197],
            [-35.046067916992186, -8.599168526367198],
            [-35.045567916992184, -8.594768526367197],
            [-35.043567916992188, -8.590868526367196],
            [-35.040867916992184, -8.588168526367195],
            [-35.038867916992189, -8.585168526367195],
            [-35.038167916992187, -8.581268526367197],
            [-35.036967916992182, -8.578168526367197],
            [-35.035967916992185, -8.574968526367197],
            [-35.033167916992184, -8.571168526367195],
            [-35.029567916992185, -8.567668526367196],
            [-35.026767916992185, -8.564868526367196],
            [-35.022267916992185, -8.560868526367198],
            [-35.018667916992186, -8.559868526367197],
            [-35.014667916992188, -8.560268526367196],
            [-35.010467916992184, -8.562468526367198],
            [-35.007667916992183, -8.563068526367196],
            [-35.008567916992185, -8.558368526367197],
            [-35.008467916992188, -8.554168526367196],
            [-35.005967916992184, -8.551568526367195],
            [-35.003467916992186, -8.547868526367196],
            [-35.004067916992184, -8.542868526367197],
            [-35.003467916992186, -8.539768526367197],
            [-35.005167916992185, -8.534768526367195],
            [-35.006267916992186, -8.530168526367195],
            [-35.006367916992183, -8.524868526367197],
            [-35.005967916992184, -8.520668526367196],
            [-35.005467916992188, -8.517668526367196],
            [-35.004867916992183, -8.514668526367196],
            [-35.002667916992188, -8.511868526367195],
            [-35.000167916992183, -8.508868526367195],
            [-35.000267916992186, -8.504568526367198],
            [-35.001667916992183, -8.501568526367198],
            [-35.002367916992185, -8.497968526367195],
            [-35.001867916992182, -8.494468526367196],
            [-35.001267916992184, -8.491068526367197],
            [-34.999867916992187, -8.486668526367197],
            [-34.998367916992187, -8.483068526367198],
            [-34.996567916992184, -8.478668526367198],
            [-34.994667916992185, -8.474568526367197],
            [-34.992967916992185, -8.471968526367196],
            [-34.991367916992182, -8.469268526367195],
            [-34.988867916992184, -8.464768526367195],
            [-34.986367916992187, -8.461268526367196],
            [-34.983867916992182, -8.458168526367196],
            [-34.983867916992182, -8.454368526367197],
            [-34.983867916992182, -8.450768526367195],
            [-34.982867916992184, -8.446868526367197],
            [-34.981067916992188, -8.441568526367195],
            [-34.979967916992187, -8.438768526367195],
            [-34.978867916992186, -8.435868526367198],
            [-34.977467916992182, -8.433268526367197],
            [-34.975567916992183, -8.428868526367197],
            [-34.973667916992184, -8.424968526367195],
            [-34.971467916992182, -8.420268526367195],
            [-34.969867916992186, -8.416968526367196],
            [-34.966967916992182, -8.410568526367197],
            [-34.965167916992186, -8.406968526367198],
            [-34.963167916992184, -8.402268526367195],
            [-34.960767916992182, -8.399068526367195],
            [-34.961467916992184, -8.395068526367197],
            [-34.970367916992188, -8.389268526367196],
            [-34.974867916992189, -8.384968526367196],
            [-34.979667916992184, -8.382168526367195],
            [-34.972067916992188, -8.380968526367198],
            [-34.969467916992187, -8.379168526367195],
            [-34.967267916992185, -8.375968526367195],
            [-34.969467916992187, -8.372668526367196],
            [-34.970867916992184, -8.369368526367197],
            [-34.967667916992184, -8.368368526367195],
            [-34.965667916992182, -8.371968526367198],
            [-34.963167916992184, -8.369068526367197],
            [-34.961167916992189, -8.366168526367197],
            [-34.958667916992184, -8.364668526367197],
            [-34.957567916992183, -8.361768526367197],
            [-34.956567916992185, -8.358168526367198],
            [-34.953567916992185, -8.355868526367196],
            [-34.949367916992188, -8.358268526367198],
            [-34.945467916992186, -8.358868526367196],
            [-34.942767916992182, -8.356468526367195],
            [-34.940967916992186, -8.353168526367195],
            [-34.939667916992185, -8.349468526367197],
            [-34.940767916992186, -8.345568526367195],
            [-34.943867916992183, -8.344168526367195],
            [-34.947667916992188, -8.341968526367197],
            [-34.949967916992186, -8.337368526367197],
            [-34.950167916992186, -8.333768526367198],
            [-34.950267916992182, -8.328968526367195],
            [-34.949967916992186, -8.324668526367198],
            [-34.949567916992187, -8.321068526367196],
            [-34.948167916992183, -8.316868526367195],
            [-34.946367916992187, -8.312668526367197],
            [-34.944667916992188, -8.308568526367196],
            [-34.943067916992185, -8.303668526367197],
            [-34.946367916992187, -8.302568526367196],
            [-34.948767916992182, -8.301068526367196],
            [-34.951067916992187, -8.298868526367198],
            [-34.951867916992185, -8.295868526367197],
            [-34.951767916992182, -8.292468526367195],
            [-34.950667916992188, -8.287268526367196],
            [-34.948867916992185, -8.283668526367197],
            [-34.947167916992186, -8.280168526367195],
            [-34.946267916992184, -8.275968526367198],
            [-34.945267916992186, -8.271768526367197],
            [-34.944967916992184, -8.267068526367197],
            [-34.944567916992185, -8.261268526367196],
            [-34.942767916992182, -8.256568526367197],
            [-34.941267916992182, -8.252968526367198],
            [-34.939067916992187, -8.248868526367197],
            [-34.937367916992187, -8.246068526367196],
            [-34.935767916992184, -8.243668526367195],
            [-34.933767916992188, -8.241368526367197],
            [-34.931567916992186, -8.239168526367198],
            [-34.929067916992182, -8.236768526367197],
            [-34.926967916992183, -8.234668526367198],
            [-34.924667916992185, -8.231368526367195],
            [-34.925867916992182, -8.227068526367198],
            [-34.928567916992186, -8.225268526367195],
            [-34.925067916992184, -8.222368526367195],
            [-34.922467916992183, -8.219768526367197],
            [-34.919267916992183, -8.215868526367196],
            [-34.917567916992184, -8.211168526367196],
            [-34.916667916992182, -8.206168526367197],
            [-34.916067916992183, -8.200468526367196],
            [-34.916767916992185, -8.195168526367198],
            [-34.917067916992188, -8.191268526367196],
            [-34.917467916992187, -8.185768526367195],
            [-34.916367916992186, -8.181168526367195],
            [-34.915567916992188, -8.176368526367195],
            [-34.914467916992187, -8.172768526367197],
            [-34.913467916992182, -8.168668526367195],
            [-34.911767916992183, -8.163168526367198],
            [-34.909867916992184, -8.158368526367195],
            [-34.908767916992183, -8.155068526367195],
            [-34.907067916992183, -8.151168526367197],
            [-34.905467916992187, -8.147168526367196],
            [-34.904167916992186, -8.144268526367195],
            [-34.902067916992188, -8.139868526367195],
            [-34.900067916992185, -8.134968526367196],
            [-34.897867916992183, -8.129868526367197],
            [-34.896167916992184, -8.125468526367197],
            [-34.893767916992182, -8.120868526367197],
            [-34.891067916992185, -8.116468526367196],
            [-34.888967916992186, -8.112868526367198],
            [-34.887667916992186, -8.109968526367197],
            [-34.886267916992182, -8.107268526367196],
            [-34.884167916992183, -8.103068526367196],
            [-34.882367916992187, -8.099468526367197],
            [-34.881267916992186, -8.096668526367196],
            [-34.880267916992182, -8.093468526367197],
            [-34.879167916992188, -8.090168526367197],
            [-34.878067916992187, -8.086768526367198],
            [-34.876967916992186, -8.083168526367196],
            [-34.875567916992182, -8.079668526367197],
            [-34.874067916992182, -8.075968526367195],
            [-34.871267916992188, -8.069268526367196],
            [-34.869667916992185, -8.065168526367195],
            [-34.868067916992182, -8.061868526367196],
            [-34.865867916992187, -8.056568526367197],
            [-34.863267916992186, -8.050868526367196],
            [-34.861767916992186, -8.048068526367196],
            [-34.858967916992185, -8.047168526367198],
            [-34.860667916992185, -8.044168526367198],
            [-34.863967916992188, -8.040968526367195],
            [-34.863567916992189, -8.038068526367198],
            [-34.862267916992188, -8.034168526367196],
            [-34.860867916992184, -8.031168526367196],
            [-34.858967916992185, -8.027968526367196],
            [-34.856967916992183, -8.025668526367195],
            [-34.852067916992183, -8.022568526367195],
            [-34.849267916992183, -8.020168526367197],
            [-34.846667916992182, -8.017168526367197],
            [-34.843467916992182, -8.013568526367198],
            [-34.841567916992183, -8.010668526367198],
            [-34.840167916992186, -8.007368526367195],
            [-34.839367916992188, -8.003568526367197],
            [-34.838667916992186, -7.999868526367198],
            [-34.837367916992186, -7.995868526367197],
            [-34.838167916992184, -7.992968526367196],
            [-34.838167916992184, -7.990068526367196],
            [-34.836567916992188, -7.985368526367196],
            [-34.835067916992188, -7.981768526367198],
            [-34.834067916992183, -7.978068526367196],
            [-34.832567916992183, -7.973468526367196],
            [-34.830767916992187, -7.967568526367195],
            [-34.829567916992183, -7.961968526367198],
            [-34.828267916992182, -7.958168526367196],
            [-34.828267916992182, -7.954668526367197],
            [-34.827067916992185, -7.952068526367196],
            [-34.824767916992187, -7.947968526367195],
            [-34.823267916992187, -7.942668526367196],
            [-34.822767916992184, -7.936968526367195],
            [-34.821567916992187, -7.932168526367196],
            [-34.820467916992186, -7.927268526367197],
            [-34.819967916992184, -7.921468526367196],
            [-34.820267916992186, -7.916668526367197],
            [-34.821567916992187, -7.911768526367197],
            [-34.823467916992186, -7.908368526367195],
            [-34.824567916992187, -7.905168526367195],
            [-34.824867916992183, -7.901768526367196],
            [-34.824267916992184, -7.898668526367196],
            [-34.823767916992182, -7.895368526367196],
            [-34.823567916992182, -7.890368526367197],
            [-34.824567916992187, -7.884568526367197],
            [-34.827067916992185, -7.880268526367196],
            [-34.829667916992186, -7.877368526367196],
            [-34.832267916992187, -7.873368526367198],
            [-34.833667916992184, -7.869768526367196],
            [-34.834267916992182, -7.866068526367197],
            [-34.834267916992182, -7.861168526367198],
            [-34.834267916992182, -7.856168526367195],
            [-34.835367916992183, -7.850668526367198],
            [-34.836167916992189, -7.845568526367195],
            [-34.839067916992185, -7.840368526367197],
            [-34.841467916992187, -7.844068526367195],
            [-34.844767916992183, -7.839068526367196],
            [-34.844467916992187, -7.834568526367196],
            [-34.843167916992186, -7.831168526367197],
            [-34.843367916992186, -7.826768526367196],
            [-34.844467916992187, -7.820568526367197],
            [-34.846267916992183, -7.816068526367197],
            [-34.843667916992182, -7.812468526367198],
            [-34.839067916992185, -7.811068526367198],
            [-34.836867916992183, -7.808668526367196],
            [-34.836467916992184, -7.804668526367195],
            [-34.836867916992183, -7.800468526367197],
            [-34.836967916992187, -7.796468526367196],
            [-34.836567916992188, -7.793068526367197],
            [-34.836167916992189, -7.788668526367196],
            [-34.834767916992185, -7.783068526367195],
            [-34.832867916992186, -7.777568526367197],
            [-34.831167916992186, -7.772968526367197],
            [-34.829667916992186, -7.768268526367198],
            [-34.827667916992183, -7.763568526367198],
            [-34.825767916992184, -7.760168526367195],
            [-34.824567916992187, -7.757068526367195],
            [-34.823467916992186, -7.752768526367195],
            [-34.823267916992187, -7.749768526367195],
            [-34.823167916992183, -7.746868526367198],
            [-34.823167916992183, -7.743368526367195],
            [-34.823167916992183, -7.740268526367196],
            [-34.823467916992186, -7.736468526367197],
            [-34.824567916992187, -7.732468526367196],
            [-34.825267916992182, -7.728468526367195],
            [-34.827167916992188, -7.725268526367195],
            [-34.830667916992184, -7.723068526367197],
            [-34.830767916992187, -7.720068526367196],
            [-34.832067916992187, -7.716268526367195],
            [-34.832967916992182, -7.712068526367197],
            [-34.833167916992188, -7.708568526367195],
            [-34.832367916992183, -7.704568526367197],
            [-34.831867916992188, -7.700168526367197],
            [-34.835167916992184, -7.696768526367197],
            [-34.839767916992187, -7.694568526367195],
            [-34.841967916992182, -7.692168526367198],
            [-34.843767916992185, -7.689468526367197],
            [-34.847367916992184, -7.687968526367197],
            [-34.843367916992186, -7.683268526367197],
            [-34.837967916992184, -7.682468526367195],
            [-34.834367916992186, -7.683368526367197],
            [-34.831867916992188, -7.679768526367198],
            [-34.830167916992188, -7.674968526367195],
            [-34.829567916992183, -7.670768526367198],
            [-34.828767916992184, -7.667468526367195],
            [-34.827467916992184, -7.663668526367196],
            [-34.826067916992187, -7.659768526367195],
            [-34.824367916992188, -7.655668526367197],
            [-34.823167916992183, -7.652068526367195],
            [-34.821867916992183, -7.648768526367196],
            [-34.819567916992185, -7.644868526367198],
            [-34.817767916992182, -7.641768526367198],
            [-34.815967916992186, -7.638868526367197],
            [-34.814167916992183, -7.636068526367197],
            [-34.811667916992185, -7.632968526367197],
            [-34.808367916992182, -7.628568526367197],
            [-34.807267916992188, -7.623868526367197],
            [-34.806867916992182, -7.619668526367196],
            [-34.808367916992182, -7.616868526367195],
            [-34.811267916992186, -7.615068526367196],
            [-34.813467916992188, -7.611668526367197],
            [-34.815267916992184, -7.608568526367197],
            [-34.817667916992185, -7.604268526367196],
            [-34.819667916992188, -7.600568526367198],
            [-34.820967916992188, -7.597668526367197],
            [-34.822867916992188, -7.594568526367198],
            [-34.824967916992186, -7.591968526367197],
            [-34.826567916992182, -7.587668526367196],
            [-34.828267916992182, -7.583568526367195],
            [-34.829267916992187, -7.580668526367198],
            [-34.830967916992186, -7.576068526367198],
            [-34.831767916992185, -7.571368526367195],
            [-34.832267916992187, -7.567668526367196],
            [-34.833967916992187, -7.564068526367198],
            [-34.836967916992187, -7.560768526367195],
            [-34.837567916992185, -7.556568526367197],
            [-34.837567916992185, -7.553368526367198],
            [-34.833467916992184, -7.548968526367197],
            [-34.830467916992184, -7.549368526367196],
            [-34.827567916992187, -7.550568526367197],
            [-34.824567916992187, -7.548168526367196],
            [-34.823467916992186, -7.544768526367196],
            [-34.822667916992188, -7.539868526367197],
            [-34.822167916992186, -7.536168526367195],
            [-34.821067916992185, -7.532668526367196],
            [-34.819067916992182, -7.528168526367196],
            [-34.816567916992184, -7.523668526367196],
            [-34.813067916992182, -7.518768526367197],
            [-34.810767916992184, -7.515368526367197],
            [-34.809167916992187, -7.510668526367198],
            [-34.809867916992182, -7.506568526367197],
            [-34.811667916992185, -7.502368526367196],
            [-34.813467916992188, -7.497268526367197],
            [-34.814067916992187, -7.493668526367195],
            [-34.814067916992187, -7.490068526367196],
            [-34.812667916992183, -7.484268526367195],
            [-34.810767916992184, -7.479968526367195],
            [-34.808267916992186, -7.476268526367196],
            [-34.804767916992184, -7.472268526367195],
            [-34.805567916992182, -7.468368526367197],
            [-34.807767916992184, -7.465668526367196],
            [-34.810167916992185, -7.460468526367197],
            [-34.810767916992184, -7.455668526367198],
            [-34.810767916992184, -7.451068526367198],
            [-34.810467916992188, -7.445768526367196],
            [-34.809667916992183, -7.440568526367198],
            [-34.809467916992183, -7.437368526367198],
            [-34.809467916992183, -7.433668526367196],
            [-34.808867916992185, -7.430068526367197],
            [-34.808267916992186, -7.426368526367195],
            [-34.807267916992188, -7.420068526367196],
            [-34.806867916992182, -7.415568526367196],
            [-34.806367916992187, -7.412568526367195],
            [-34.805867916992185, -7.409468526367196],
            [-34.805467916992185, -7.405868526367197],
            [-34.804767916992184, -7.402568526367197],
            [-34.804367916992184, -7.399068526367195],
            [-34.803367916992187, -7.392968526367195],
            [-34.803367916992187, -7.387368526367197],
            [-34.802567916992189, -7.384368526367197],
            [-34.799967916992188, -7.373868526367197],
            [-34.798067916992188, -7.369668526367196],
            [-34.797567916992186, -7.366068526367197],
            [-34.798867916992187, -7.362468526367195],
            [-34.799767916992188, -7.358868526367196],
            [-34.799167916992182, -7.354268526367196],
            [-34.797867916992182, -7.349568526367197],
            [-34.797867916992182, -7.346268526367197],
            [-34.797567916992186, -7.342568526367195],
            [-34.796767916992188, -7.339268526367196],
            [-34.795767916992183, -7.335968526367196],
            [-34.794967916992185, -7.331768526367195],
            [-34.796367916992182, -7.327868526367197],
            [-34.798267916992188, -7.322968526367195],
            [-34.800867916992182, -7.319068526367197],
            [-34.801967916992183, -7.315268526367195],
            [-34.802167916992182, -7.311368526367197],
            [-34.800467916992183, -7.306868526367197],
            [-34.798567916992184, -7.302768526367196],
            [-34.799367916992182, -7.299168526367197],
            [-34.801067916992189, -7.296068526367197],
            [-34.801067916992189, -7.292868526367197],
            [-34.801067916992189, -7.289568526367198],
            [-34.799967916992188, -7.285868526367196],
            [-34.798967916992183, -7.281968526367198],
            [-34.799267916992186, -7.277368526367198],
            [-34.801567916992184, -7.273668526367196],
            [-34.802467916992185, -7.270668526367196],
            [-34.803567916992186, -7.267968526367195],
            [-34.804767916992184, -7.261368526367196],
            [-34.805167916992183, -7.257068526367195],
            [-34.805167916992183, -7.253768526367196],
            [-34.805267916992186, -7.249768526367195],
            [-34.806867916992182, -7.245468526367198],
            [-34.807167916992185, -7.242268526367198],
            [-34.806167916992187, -7.238668526367196],
            [-34.804467916992188, -7.235768526367195],
            [-34.804367916992184, -7.231968526367197],
            [-34.804367916992184, -7.226768526367195],
            [-34.804067916992182, -7.222068526367195],
            [-34.804067916992182, -7.218068526367198],
            [-34.803567916992186, -7.214568526367195],
            [-34.802167916992182, -7.208768526367198],
            [-34.801167916992185, -7.206068526367197],
            [-34.800067916992184, -7.203268526367197],
            [-34.797767916992186, -7.199368526367195],
            [-34.795267916992188, -7.195668526367196],
            [-34.794767916992186, -7.192068526367198],
            [-34.795267916992188, -7.188368526367196],
            [-34.795767916992183, -7.185468526367195],
            [-34.795867916992187, -7.181168526367195],
            [-34.795767916992183, -7.175268526367198],
            [-34.795667916992187, -7.169468526367197],
            [-34.795267916992188, -7.163668526367196],
            [-34.793867916992184, -7.158368526367195],
            [-34.793567916992188, -7.154068526367197],
            [-34.796467916992185, -7.147668526367195],
            [-34.800467916992183, -7.145968526367195],
            [-34.803867916992182, -7.145468526367196],
            [-34.806967916992186, -7.145668526367196],
            [-34.810467916992188, -7.144868526367198],
            [-34.813567916992184, -7.142368526367196],
            [-34.816567916992184, -7.139268526367196],
            [-34.818867916992183, -7.135368526367195],
            [-34.821067916992185, -7.130468526367196],
            [-34.822067916992182, -7.127368526367196],
            [-34.822867916992188, -7.123368526367198],
            [-34.822767916992184, -7.120468526367198],
            [-34.821867916992183, -7.116068526367197],
            [-34.821267916992184, -7.111968526367196],
            [-34.823267916992187, -7.109668526367198],
            [-34.826867916992185, -7.106668526367198],
            [-34.829667916992186, -7.103168526367195],
            [-34.831467916992182, -7.100868526367197],
            [-34.832667916992186, -7.097268526367195],
            [-34.833167916992188, -7.092368526367196],
            [-34.832667916992186, -7.087868526367195],
            [-34.830767916992187, -7.081868526367195],
            [-34.829667916992186, -7.078168526367197],
            [-34.829867916992185, -7.074268526367195],
            [-34.833167916992188, -7.071268526367195],
            [-34.836967916992187, -7.067768526367196],
            [-34.840167916992186, -7.063068526367196],
            [-34.841767916992183, -7.059168526367195],
            [-34.843367916992186, -7.056168526367195],
            [-34.842267916992185, -7.052568526367196],
            [-34.841667916992186, -7.048868526367198],
            [-34.840467916992182, -7.045368526367195],
            [-34.837567916992185, -7.039568526367198],
            [-34.835067916992188, -7.036368526367195],
            [-34.831067916992183, -7.032668526367196],
            [-34.829367916992183, -7.028768526367195],
            [-34.829567916992183, -7.024268526367198],
            [-34.829067916992187, -7.018968526367196],
            [-34.828267916992182, -7.015768526367197],
            [-34.827367916992188, -7.012768526367196],
            [-34.826767916992182, -7.009868526367196],
            [-34.825367916992185, -7.004668526367198],
            [-34.825367916992185, -6.999668526367195],
            [-34.825967916992184, -6.995768526367197],
            [-34.826867916992185, -6.989368526367198],
            [-34.827167916992188, -6.983968526367196],
            [-34.827467916992184, -6.979768526367195],
            [-34.827867916992183, -6.974968526367196],
            [-34.828267916992182, -6.970568526367195],
            [-34.829667916992186, -6.966768526367197],
            [-34.832067916992187, -6.965068526367197],
            [-34.836467916992184, -6.964068526367196],
            [-34.842267916992185, -6.963468526367198],
            [-34.842367916992188, -6.966768526367197],
            [-34.840667916992182, -6.970068526367196],
            [-34.836167916992189, -6.977768526367196],
            [-34.835367916992183, -6.981768526367198],
            [-34.834367916992186, -6.985768526367195],
            [-34.834067916992183, -6.993568526367195],
            [-34.835467916992187, -6.999368526367196],
            [-34.835067916992188, -7.003568526367197],
            [-34.836867916992183, -7.009568526367197],
            [-34.839267916992185, -7.012968526367196],
            [-34.843767916992185, -7.017068526367197],
            [-34.847267916992188, -7.020168526367197],
            [-34.850567916992183, -7.024068526367195],
            [-34.853867916992186, -7.027568526367197],
            [-34.858267916992183, -7.029568526367196],
            [-34.864667916992182, -7.029568526367196],
            [-34.865867916992187, -7.025968526367198],
            [-34.866667916992185, -7.019068526367196],
            [-34.870567916992186, -7.011268526367196],
            [-34.871067916992182, -7.004568526367198],
            [-34.871967916992183, -6.999468526367195],
            [-34.872767916992188, -6.992668526367197],
            [-34.873367916992187, -6.989768526367197],
            [-34.873767916992186, -6.986468526367197],
            [-34.869667916992185, -6.978668526367198],
            [-34.867167916992187, -6.976168526367196],
            [-34.863267916992186, -6.973668526367195],
            [-34.858967916992185, -6.971968526367196],
            [-34.855567916992186, -6.969568526367198],
            [-34.855067916992184, -6.963968526367196],
            [-34.856067916992188, -6.959768526367196],
            [-34.857467916992185, -6.956768526367195],
            [-34.859967916992183, -6.952568526367195],
            [-34.862567916992184, -6.947068526367197],
            [-34.864367916992187, -6.942768526367196],
            [-34.865467916992188, -6.939168526367197],
            [-34.866067916992186, -6.934068526367195],
            [-34.865767916992183, -6.929068526367196],
            [-34.863967916992188, -6.922768526367197],
            [-34.862567916992184, -6.918468526367196],
            [-34.860767916992188, -6.914868526367197],
            [-34.859167916992185, -6.912268526367196],
            [-34.857467916992185, -6.909768526367195],
            [-34.855567916992186, -6.906168526367196],
            [-34.854467916992185, -6.901268526367197],
            [-34.859267916992188, -6.899868526367197],
            [-34.862267916992188, -6.899368526367198],
            [-34.866067916992186, -6.898268526367197],
            [-34.870167916992187, -6.896468526367197],
            [-34.874467916992188, -6.894268526367195],
            [-34.877167916992185, -6.892468526367196],
            [-34.880667916992188, -6.889868526367195],
            [-34.884567916992182, -6.886368526367196],
            [-34.888267916992184, -6.882068526367195],
            [-34.890767916992182, -6.878268526367197],
            [-34.893767916992182, -6.874068526367196],
            [-34.896767916992182, -6.869068526367197],
            [-34.899367916992183, -6.865368526367195],
            [-34.900367916992188, -6.861968526367196],
            [-34.901567916992185, -6.857468526367196],
            [-34.902067916992188, -6.854668526367195],
            [-34.903667916992184, -6.851468526367196],
            [-34.905367916992184, -6.846968526367196],
            [-34.906567916992188, -6.843668526367196],
            [-34.909467916992185, -6.833168526367196],
            [-34.910267916992183, -6.829668526367197],
            [-34.911267916992188, -6.825268526367196],
            [-34.911667916992187, -6.821768526367194],
            [-34.912567916992188, -6.817068526367194],
            [-34.913367916992186, -6.811168526367197],
            [-34.914167916992184, -6.806068526367199],
            [-34.914467916992187, -6.799968526367195],
            [-34.914467916992187, -6.796068526367193],
            [-34.914767916992183, -6.788668526367196],
            [-34.917067916992188, -6.780068526367195],
            [-34.918167916992182, -6.774468526367194],
            [-34.920367916992184, -6.769368526367195],
            [-34.925767916992186, -6.768268526367194],
            [-34.932267916992188, -6.763468526367198],
            [-34.933867916992185, -6.758868526367195],
            [-34.936267916992186, -6.755768526367198],
            [-34.938867916992187, -6.753768526367196],
            [-34.936967916992188, -6.750968526367195],
            [-34.935167916992185, -6.7476685263672],
            [-34.933567916992182, -6.744668526367199],
            [-34.931167916992187, -6.742168526367195],
            [-34.928967916992185, -6.739368526367194],
            [-34.931167916992187, -6.736368526367194],
            [-34.933067916992186, -6.732868526367199],
            [-34.933867916992185, -6.728168526367199],
            [-34.932767916992184, -6.723768526367195],
            [-34.931567916992186, -6.720668526367199],
            [-34.932267916992188, -6.716668526367194],
            [-34.933267916992186, -6.713368526367198],
            [-34.933667916992185, -6.709668526367196],
            [-34.933667916992185, -6.706468526367196],
            [-34.933667916992185, -6.701268526367194],
            [-34.933867916992185, -6.696868526367197],
            [-34.932567916992184, -6.692368526367197],
            [-34.930767916992188, -6.688768526367198],
            [-34.935867916992187, -6.687668526367197],
            [-34.939567916992182, -6.685768526367198],
            [-34.942067916992187, -6.684168526367195],
            [-34.945167916992183, -6.682468526367195],
            [-34.947767916992184, -6.679668526367195],
            [-34.951067916992187, -6.675068526367198],
            [-34.952667916992183, -6.672568526367193],
            [-34.954567916992183, -6.668368526367196],
            [-34.956067916992183, -6.663368526367194],
            [-34.957167916992184, -6.659168526367196],
            [-34.957867916992186, -6.655968526367197],
            [-34.958967916992187, -6.650968526367194],
            [-34.959367916992186, -6.647668526367198],
            [-34.960167916992184, -6.644568526367195],
            [-34.961167916992189, -6.641468526367198],
            [-34.961567916992188, -6.637968526367196],
            [-34.962267916992182, -6.634568526367197],
            [-34.962967916992184, -6.630968526367198],
            [-34.963367916992183, -6.627368526367199],
            [-34.963967916992182, -6.621968526367198],
            [-34.963967916992182, -6.618868526367194],
            [-34.964067916992185, -6.614368526367194],
            [-34.964067916992185, -6.610268526367193],
            [-34.963967916992182, -6.606768526367198],
            [-34.964867916992183, -6.603868526367194],
            [-34.965867916992188, -6.598968526367194],
            [-34.966167916992184, -6.590168526367194],
            [-34.966267916992187, -6.585468526367194],
            [-34.966267916992187, -6.5798685263672],
            [-34.966167916992184, -6.574368526367195],
            [-34.966167916992184, -6.570168526367198],
            [-34.966167916992184, -6.562268526367198],
            [-34.966267916992187, -6.554168526367199],
            [-34.966267916992187, -6.546768526367195],
            [-34.966567916992183, -6.542468526367195],
            [-34.966767916992183, -6.538068526367198],
            [-34.967067916992185, -6.528768526367195],
            [-34.967367916992188, -6.521068526367195],
            [-34.967567916992188, -6.510968526367193],
            [-34.967367916992188, -6.501668526367197],
            [-34.967367916992188, -6.497168526367197],
            [-34.967567916992188, -6.491368526367197],
            [-34.969267916992187, -6.487968526367197],
            [-34.969867916992186, -6.484668526367194],
            [-34.969467916992187, -6.480068526367198],
            [-34.969567916992183, -6.474568526367193],
            [-34.969467916992187, -6.470968526367194],
            [-34.970867916992184, -6.466968526367197],
            [-34.972767916992183, -6.461868526367198],
            [-34.972767916992183, -6.456768526367199],
            [-34.973667916992184, -6.452868526367197],
            [-34.973967916992187, -6.449668526367198],
            [-34.973467916992185, -6.445968526367196],
            [-34.973067916992186, -6.442168526367198],
            [-34.971467916992182, -6.438568526367199],
            [-34.972067916992188, -6.435768526367198],
            [-34.974167916992187, -6.432268526367196],
            [-34.974867916992189, -6.428668526367197],
            [-34.974967916992185, -6.425068526367198],
            [-34.974867916992189, -6.421368526367196],
            [-34.975567916992183, -6.418068526367193],
            [-34.976967916992187, -6.414468526367195],
            [-34.979167916992182, -6.410668526367196],
            [-34.979767916992188, -6.405868526367193],
            [-34.979967916992187, -6.402068526367195],
            [-34.981767916992183, -6.397668526367198],
            [-34.984267916992188, -6.393668526367193],
            [-34.985267916992186, -6.390468526367194],
            [-34.986967916992185, -6.386768526367199],
            [-34.988567916992189, -6.383468526367196],
            [-34.989667916992182, -6.380668526367195],
            [-34.990367916992184, -6.377668526367195],
            [-34.991067916992186, -6.372968526367195],
            [-34.995867916992182, -6.371268526367196],
            [-34.999867916992187, -6.369368526367197],
            [-35.001967916992186, -6.367168526367195],
            [-35.004867916992183, -6.365768526367198],
            [-35.009067916992187, -6.366868526367199],
            [-35.010967916992186, -6.369768526367196],
            [-35.014067916992182, -6.369668526367199],
            [-35.016867916992183, -6.367968526367193],
            [-35.020367916992186, -6.364668526367197],
            [-35.022967916992187, -6.360568526367196],
            [-35.024767916992182, -6.357268526367193],
            [-35.026167916992186, -6.354168526367197],
            [-35.027567916992183, -6.348868526367198],
            [-35.028467916992184, -6.344768526367197],
            [-35.029167916992186, -6.341268526367195],
            [-35.029467916992182, -6.337968526367199],
            [-35.030167916992184, -6.334368526367193],
            [-35.031167916992182, -6.330768526367194],
            [-35.031567916992188, -6.327868526367197],
            [-35.032067916992183, -6.324568526367194],
            [-35.032567916992186, -6.320468526367193],
            [-35.032667916992182, -6.316068526367197],
            [-35.031967916992187, -6.311968526367195],
            [-35.031267916992185, -6.306568526367194],
            [-35.031967916992187, -6.301468526367195],
            [-35.032767916992185, -6.297168526367194],
            [-35.033167916992184, -6.293568526367196],
            [-35.033367916992184, -6.289468526367195],
            [-35.034467916992185, -6.286268526367195],
            [-35.033967916992182, -6.283168526367199],
            [-35.033767916992183, -6.280168526367198],
            [-35.033367916992184, -6.275968526367194],
            [-35.035167916992187, -6.272368526367195],
            [-35.037367916992189, -6.267568526367199],
            [-35.037567916992188, -6.263868526367197],
            [-35.037467916992185, -6.259968526367196],
            [-35.037267916992185, -6.256568526367197],
            [-35.036967916992182, -6.252468526367196],
            [-35.036767916992183, -6.249068526367196],
            [-35.036767916992183, -6.245468526367198],
            [-35.036367916992184, -6.241568526367196],
            [-35.035967916992185, -6.236468526367197],
            [-35.038167916992187, -6.233568526367193],
            [-35.040667916992184, -6.231768526367198],
            [-35.041967916992185, -6.227568526367193],
            [-35.045267916992188, -6.225668526367194],
            [-35.049267916992186, -6.225668526367194],
            [-35.053967916992185, -6.225968526367197],
            [-35.057667916992187, -6.2262685263672],
            [-35.061567916992182, -6.225968526367197],
            [-35.064067916992187, -6.223868526367198],
            [-35.066367916992185, -6.221268526367197],
            [-35.069167916992185, -6.222268526367195],
            [-35.072167916992186, -6.220668526367199],
            [-35.074667916992183, -6.2170685263672],
            [-35.076467916992186, -6.213968526367196],
            [-35.078267916992182, -6.210668526367193],
            [-35.079567916992183, -6.206268526367197],
            [-35.081067916992183, -6.200468526367196],
            [-35.081867916992188, -6.195268526367194],
            [-35.083667916992184, -6.187968526367193],
            [-35.087367916992186, -6.184868526367197],
            [-35.090367916992186, -6.181868526367197],
            [-35.094167916992184, -6.182568526367199],
            [-35.095367916992188, -6.178668526367197],
            [-35.095967916992187, -6.173168526367199],
            [-35.096167916992187, -6.169168526367194],
            [-35.096967916992185, -6.164468526367195],
            [-35.097267916992188, -6.161268526367195],
            [-35.097667916992187, -6.158068526367195],
            [-35.097367916992184, -6.153768526367195],
            [-35.097267916992188, -6.150368526367195],
            [-35.097567916992183, -6.147368526367195],
            [-35.097767916992183, -6.143168526367198],
            [-35.098467916992185, -6.137668526367193],
            [-35.098067916992186, -6.133168526367193],
            [-35.098167916992182, -6.129868526367197],
            [-35.098367916992188, -6.124368526367199],
            [-35.098367916992188, -6.121268526367196],
            [-35.098467916992185, -6.117168526367195],
            [-35.099867916992189, -6.112468526367195],
            [-35.100267916992188, -6.107168526367197],
            [-35.099967916992185, -6.104168526367197],
            [-35.099867916992189, -6.099568526367193],
            [-35.099567916992186, -6.095668526367199],
            [-35.099467916992182, -6.091268526367195],
            [-35.099167916992187, -6.087668526367196],
            [-35.098967916992187, -6.084368526367193],
            [-35.099167916992187, -6.081468526367196],
            [-35.099767916992185, -6.077668526367198],
            [-35.100267916992188, -6.073268526367194],
            [-35.097867916992186, -6.069268526367196],
            [-35.098367916992188, -6.065768526367194],
            [-35.097267916992188, -6.061868526367199],
            [-35.096367916992186, -6.057768526367198],
            [-35.098367916992188, -6.055268526367193],
            [-35.101367916992189, -6.056568526367194],
            [-35.104667916992184, -6.054768526367198],
            [-35.107267916992186, -6.051168526367199],
            [-35.108867916992182, -6.047568526367193],
            [-35.109467916992187, -6.044168526367194],
            [-35.109967916992183, -6.038168526367194],
            [-35.109967916992183, -6.033068526367195],
            [-35.109767916992183, -6.028368526367196],
            [-35.109267916992188, -6.024768526367197],
            [-35.108967916992185, -6.021768526367197],
            [-35.108267916992183, -6.018468526367194],
            [-35.107767916992188, -6.015668526367193],
            [-35.106367916992184, -6.011568526367199],
            [-35.106367916992184, -6.007368526367195],
            [-35.108867916992182, -6.003768526367196],
            [-35.110867916992184, -5.999868526367194],
            [-35.112167916992185, -5.996568526367199],
            [-35.112567916992184, -5.991668526367199],
            [-35.112867916992187, -5.988368526367196],
            [-35.116067916992186, -5.985668526367199],
            [-35.119967916992188, -5.983268526367198],
            [-35.121067916992182, -5.979968526367195],
            [-35.122267916992186, -5.977068526367198],
            [-35.123767916992186, -5.974168526367194],
            [-35.125567916992182, -5.971268526367197],
            [-35.128467916992186, -5.967968526367194],
            [-35.132767916992186, -5.9655685263672],
            [-35.137667916992186, -5.965868526367196],
            [-35.141867916992183, -5.964568526367195],
            [-35.146567916992183, -5.961168526367196],
            [-35.149467916992187, -5.956868526367195],
            [-35.151167916992186, -5.952568526367195],
            [-35.152567916992183, -5.948168526367198],
            [-35.153467916992184, -5.943168526367195],
            [-35.154167916992186, -5.937668526367197],
            [-35.154267916992183, -5.932768526367198],
            [-35.154267916992183, -5.929368526367199],
            [-35.156167916992182, -5.925568526367194],
            [-35.156967916992187, -5.920368526367199],
            [-35.156467916992185, -5.9152685263672],
            [-35.156167916992182, -5.910568526367193],
            [-35.155867916992186, -5.906668526367199],
            [-35.155167916992184, -5.903668526367198],
            [-35.154767916992185, -5.900768526367195],
            [-35.155467916992187, -5.897568526367195],
            [-35.153467916992184, -5.893168526367198],
            [-35.152967916992182, -5.887168526367198],
            [-35.154067916992183, -5.883468526367196],
            [-35.156767916992187, -5.882068526367199],
            [-35.160867916992188, -5.879968526367193],
            [-35.164867916992186, -5.882668526367198],
            [-35.168567916992188, -5.882368526367195],
            [-35.171167916992182, -5.880268526367196],
            [-35.173867916992187, -5.8771685263672],
            [-35.176367916992184, -5.8726685263672],
            [-35.178267916992183, -5.867568526367194],
            [-35.179467916992188, -5.862568526367198],
            [-35.180167916992183, -5.857868526367199],
            [-35.180567916992182, -5.852868526367196],
            [-35.180767916992188, -5.848468526367199],
            [-35.180767916992188, -5.844568526367198],
            [-35.180767916992188, -5.839768526367195],
            [-35.181167916992187, -5.836168526367196],
            [-35.181167916992187, -5.833168526367196],
            [-35.181167916992187, -5.8298685263672],
            [-35.181167916992187, -5.826768526367196],
            [-35.181367916992187, -5.822068526367197],
            [-35.181167916992187, -5.819068526367197],
            [-35.181067916992184, -5.815568526367194],
            [-35.180767916992188, -5.811068526367194],
            [-35.180467916992185, -5.806368526367194],
            [-35.179967916992183, -5.801968526367197],
            [-35.180067916992186, -5.798568526367198],
            [-35.181867916992182, -5.795868526367194],
            [-35.182667916992187, -5.793068526367193],
            [-35.186367916992182, -5.790368526367196],
            [-35.188867916992187, -5.786968526367197],
            [-35.190167916992188, -5.783168526367199],
            [-35.192667916992185, -5.780868526367193],
            [-35.192967916992188, -5.777268526367195],
            [-35.193467916992184, -5.773768526367199],
            [-35.193867916992183, -5.770068526367197],
            [-35.194267916992182, -5.766768526367194],
            [-35.194167916992185, -5.762168526367198],
            [-35.194267916992182, -5.757668526367198],
            [-35.197467916992188, -5.753468526367193],
            [-35.200967916992184, -5.752068526367196],
            [-35.202867916992183, -5.749768526367198],
            [-35.203467916992182, -5.746568526367199],
            [-35.204267916992187, -5.741168526367197],
            [-35.202967916992186, -5.736768526367193],
            [-35.202667916992183, -5.733368526367194],
            [-35.201867916992185, -5.730068526367198],
            [-35.201067916992187, -5.725268526367195],
            [-35.200367916992185, -5.720868526367198],
            [-35.199367916992188, -5.717568526367195],
            [-35.197867916992188, -5.7140685263672],
            [-35.196367916992187, -5.708968526367194],
            [-35.194867916992187, -5.705368526367195],
            [-35.192467916992186, -5.702068526367199],
            [-35.193167916992188, -5.698468526367193],
            [-35.194867916992187, -5.694568526367199],
            [-35.198967916992189, -5.693868526367197],
            [-35.202067916992185, -5.694568526367199],
            [-35.204967916992182, -5.692668526367193],
            [-35.207867916992186, -5.689168526367197],
            [-35.210967916992182, -5.685468526367195],
            [-35.213267916992187, -5.681868526367197],
            [-35.215467916992182, -5.677568526367196],
            [-35.216967916992182, -5.673568526367198],
            [-35.217567916992188, -5.668068526367193],
            [-35.217567916992188, -5.663168526367194],
            [-35.216467916992187, -5.658868526367193],
            [-35.216467916992187, -5.653368526367196],
            [-35.217567916992188, -5.647868526367198],
            [-35.217567916992188, -5.643268526367194],
            [-35.217567916992188, -5.639668526367196],
            [-35.217267916992185, -5.635768526367194],
            [-35.216267916992187, -5.630568526367199],
            [-35.219467916992187, -5.629868526367197],
            [-35.222867916992186, -5.627668526367195],
            [-35.224967916992185, -5.623568526367194],
            [-35.225667916992187, -5.619768526367196],
            [-35.226467916992185, -5.616168526367197],
            [-35.227067916992183, -5.613268526367193],
            [-35.227067916992183, -5.609668526367194],
            [-35.226667916992184, -5.605968526367199],
            [-35.225567916992183, -5.602068526367198],
            [-35.224967916992185, -5.598168526367196],
            [-35.224867916992189, -5.594268526367195],
            [-35.226367916992189, -5.589768526367195],
            [-35.226767916992188, -5.585468526367194],
            [-35.226367916992189, -5.580768526367194],
            [-35.228367916992184, -5.575668526367195],
            [-35.231367916992184, -5.574968526367194],
            [-35.233867916992182, -5.573568526367197],
            [-35.236167916992187, -5.571268526367199],
            [-35.237467916992188, -5.567468526367193],
            [-35.238667916992185, -5.563268526367196],
            [-35.241967916992188, -5.559968526367193],
            [-35.244067916992186, -5.557168526367199],
            [-35.246167916992185, -5.551968526367197],
            [-35.248067916992184, -5.546768526367195],
            [-35.248867916992182, -5.543668526367199],
            [-35.249367916992185, -5.5402685263672],
            [-35.249367916992185, -5.535968526367199],
            [-35.248567916992187, -5.531768526367195],
            [-35.248767916992186, -5.528368526367196],
            [-35.249967916992183, -5.524268526367194],
            [-35.250967916992188, -5.521068526367195],
            [-35.250767916992189, -5.518168526367198],
            [-35.255167916992185, -5.517468526367196],
            [-35.257067916992185, -5.515368526367197],
            [-35.258767916992184, -5.512068526367194],
            [-35.259667916992186, -5.508768526367199],
            [-35.259967916992188, -5.503368526367197],
            [-35.259867916992185, -5.498368526367194],
            [-35.258867916992187, -5.493668526367195],
            [-35.260267916992184, -5.488568526367196],
            [-35.259867916992185, -5.482868526367199],
            [-35.262367916992183, -5.481368526367199],
            [-35.265067916992187, -5.4824685263672],
            [-35.268467916992186, -5.480668526367197],
            [-35.272367916992188, -5.478668526367194],
            [-35.274867916992186, -5.475068526367195],
            [-35.276267916992182, -5.472368526367198],
            [-35.278467916992184, -5.4702685263672],
            [-35.280167916992184, -5.467568526367195],
            [-35.282667916992182, -5.464468526367199],
            [-35.286667916992187, -5.461468526367199],
            [-35.289267916992188, -5.456468526367196],
            [-35.289967916992182, -5.451768526367196],
            [-35.290567916992188, -5.4473685263672],
            [-35.290867916992184, -5.443468526367198],
            [-35.291667916992182, -5.438768526367198],
            [-35.292467916992187, -5.435768526367198],
            [-35.293067916992186, -5.432168526367199],
            [-35.292867916992186, -5.4274685263672],
            [-35.296767916992188, -5.426168526367199],
            [-35.299667916992185, -5.423568526367198],
            [-35.301067916992189, -5.4199685263672],
            [-35.302467916992185, -5.417268526367195],
            [-35.306367916992187, -5.415868526367198],
            [-35.309067916992184, -5.412468526367199],
            [-35.310267916992188, -5.409768526367195],
            [-35.310567916992184, -5.406568526367195],
            [-35.310467916992188, -5.402268526367195],
            [-35.310167916992185, -5.398268526367197],
            [-35.309767916992186, -5.394768526367194],
            [-35.309867916992182, -5.391268526367199],
            [-35.312667916992183, -5.387468526367194],
            [-35.314667916992185, -5.385168526367195],
            [-35.317467916992186, -5.381268526367194],
            [-35.321267916992184, -5.377768526367198],
            [-35.325467916992189, -5.380768526367198],
            [-35.329667916992186, -5.380968526367198],
            [-35.334867916992188, -5.379468526367198],
            [-35.339267916992185, -5.376568526367194],
            [-35.341967916992182, -5.3741685263672],
            [-35.344767916992183, -5.3711685263672],
            [-35.348167916992182, -5.366868526367199],
            [-35.350667916992187, -5.362868526367194],
            [-35.352067916992183, -5.360268526367193],
            [-35.354567916992188, -5.355268526367198],
            [-35.356067916992188, -5.351468526367199],
            [-35.357267916992186, -5.347568526367198],
            [-35.358367916992187, -5.343968526367199],
            [-35.359467916992187, -5.339568526367195],
            [-35.359667916992187, -5.335868526367193],
            [-35.359667916992187, -5.331468526367196],
            [-35.360767916992188, -5.325668526367195],
            [-35.361467916992183, -5.319968526367198],
            [-35.363567916992189, -5.315468526367198],
            [-35.364367916992187, -5.311968526367195],
            [-35.364667916992182, -5.308368526367197],
            [-35.365767916992183, -5.304668526367195],
            [-35.367167916992187, -5.301568526367198],
            [-35.367267916992184, -5.297768526367193],
            [-35.368367916992185, -5.292168526367199],
            [-35.370067916992184, -5.288068526367198],
            [-35.370767916992186, -5.284868526367198],
            [-35.372467916992186, -5.281768526367195],
            [-35.374767916992184, -5.279068526367197],
            [-35.377467916992188, -5.276268526367197],
            [-35.380567916992185, -5.272668526367198],
            [-35.383767916992184, -5.269768526367194],
            [-35.384067916992187, -5.266068526367199],
            [-35.385467916992184, -5.263468526367198],
            [-35.386767916992184, -5.260668526367198],
            [-35.388967916992186, -5.256868526367199],
            [-35.391267916992184, -5.252768526367198],
            [-35.393667916992186, -5.249368526367199],
            [-35.396767916992182, -5.245268526367198],
            [-35.400467916992184, -5.242668526367197],
            [-35.403067916992185, -5.239668526367197],
            [-35.404867916992188, -5.236668526367197],
            [-35.406967916992187, -5.233868526367196],
            [-35.409467916992185, -5.231368526367199],
            [-35.412067916992186, -5.227468526367197],
            [-35.414567916992183, -5.224268526367197],
            [-35.418167916992182, -5.220568526367195],
            [-35.423067916992188, -5.218368526367193],
            [-35.427467916992185, -5.216668526367194],
            [-35.430167916992183, -5.215368526367193],
            [-35.434067916992184, -5.213268526367195],
            [-35.436967916992188, -5.211468526367199],
            [-35.439167916992183, -5.2095685263672],
            [-35.442667916992185, -5.207568526367197],
            [-35.445467916992186, -5.205368526367195],
            [-35.448167916992183, -5.203768526367199],
            [-35.451067916992187, -5.201468526367194],
            [-35.454267916992187, -5.198968526367196],
            [-35.458267916992185, -5.1973685263672],
            [-35.460767916992182, -5.194168526367193],
            [-35.462967916992184, -5.189168526367197],
            [-35.464367916992188, -5.186068526367194],
            [-35.465667916992182, -5.183268526367193],
            [-35.467267916992185, -5.180068526367194],
            [-35.470567916992188, -5.176068526367196],
            [-35.474167916992187, -5.171968526367195],
            [-35.477467916992182, -5.168068526367193],
            [-35.480367916992186, -5.164168526367199],
            [-35.484167916992185, -5.160968526367199],
            [-35.486967916992185, -5.158868526367193],
            [-35.490067916992189, -5.157268526367197],
            [-35.493367916992185, -5.157068526367198],
            [-35.497767916992188, -5.155568526367198],
            [-35.502667916992188, -5.153768526367195],
            [-35.506667916992185, -5.154168526367194],
            [-35.511567916992185, -5.152668526367194],
            [-35.515767916992182, -5.150968526367194],
            [-35.520367916992186, -5.149468526367194],
            [-35.524367916992183, -5.147568526367195],
            [-35.528367916992188, -5.145368526367193],
            [-35.531267916992185, -5.144368526367195],
            [-35.534467916992185, -5.142868526367195],
            [-35.539167916992184, -5.141468526367198],
            [-35.543867916992184, -5.140668526367193],
            [-35.548367916992184, -5.138568526367195],
            [-35.551967916992183, -5.138168526367195],
            [-35.555767916992188, -5.137668526367193],
            [-35.559667916992183, -5.137168526367198],
            [-35.563767916992184, -5.1363685263672],
            [-35.567367916992183, -5.134968526367196],
            [-35.571067916992185, -5.134268526367194],
            [-35.573767916992182, -5.132668526367198],
            [-35.577167916992188, -5.130268526367196],
            [-35.581567916992185, -5.128468526367193],
            [-35.586467916992184, -5.127968526367198],
            [-35.589867916992183, -5.126668526367197],
            [-35.593967916992185, -5.124368526367199],
            [-35.598067916992186, -5.122968526367195],
            [-35.603167916992184, -5.121268526367196],
            [-35.607567916992188, -5.119768526367196],
            [-35.610767916992188, -5.118368526367199],
            [-35.613367916992182, -5.116668526367199],
            [-35.616867916992184, -5.1149685263672],
            [-35.621867916992187, -5.118368526367199],
            [-35.626667916992183, -5.119068526367194],
            [-35.631267916992186, -5.120268526367198],
            [-35.634967916992188, -5.120068526367199],
            [-35.639367916992185, -5.119368526367197],
            [-35.643667916992186, -5.118068526367196],
            [-35.649367916992183, -5.116368526367197],
            [-35.654267916992183, -5.1149685263672],
            [-35.657367916992186, -5.113668526367199],
            [-35.661667916992187, -5.112468526367195],
            [-35.664567916992183, -5.111068526367198],
            [-35.668667916992185, -5.110668526367199],
            [-35.673967916992183, -5.109768526367198],
            [-35.678667916992183, -5.108568526367193],
            [-35.681967916992186, -5.1074685263672],
            [-35.686767916992189, -5.105768526367193],
            [-35.691267916992182, -5.104168526367197],
            [-35.694367916992185, -5.1027685263672],
            [-35.698267916992187, -5.101968526367195],
            [-35.702067916992185, -5.104168526367197],
            [-35.706167916992186, -5.103868526367194],
            [-35.710167916992184, -5.102868526367196],
            [-35.713467916992187, -5.100968526367197],
            [-35.717567916992188, -5.097868526367193],
            [-35.722267916992188, -5.096968526367199],
            [-35.725667916992187, -5.097268526367195],
            [-35.730267916992183, -5.096168526367194],
            [-35.734667916992187, -5.093368526367193],
            [-35.738067916992186, -5.093168526367194],
            [-35.741167916992183, -5.092668526367198],
            [-35.744767916992188, -5.091568526367197],
            [-35.748367916992187, -5.089768526367195],
            [-35.752367916992185, -5.089468526367199],
            [-35.756067916992187, -5.089268526367199],
            [-35.759567916992182, -5.088168526367198],
            [-35.763467916992184, -5.085968526367196],
            [-35.766767916992187, -5.083968526367194],
            [-35.769667916992184, -5.082068526367195],
            [-35.773167916992186, -5.081168526367193],
            [-35.776467916992182, -5.079968526367196],
            [-35.780067916992188, -5.077168526367196],
            [-35.783167916992184, -5.075768526367199],
            [-35.786467916992187, -5.075268526367196],
            [-35.790567916992188, -5.077168526367196],
            [-35.794667916992182, -5.077468526367198],
            [-35.798967916992183, -5.075668526367195],
            [-35.802467916992185, -5.073468526367193],
            [-35.805767916992188, -5.073168526367198],
            [-35.809167916992187, -5.073268526367194],
            [-35.812767916992186, -5.0738685263672],
            [-35.818067916992185, -5.0738685263672],
            [-35.822167916992186, -5.074268526367199],
            [-35.825967916992184, -5.074868526367197],
            [-35.830367916992188, -5.074968526367194],
            [-35.834367916992186, -5.074268526367199],
            [-35.838167916992184, -5.073168526367198],
            [-35.841967916992182, -5.0723685263672],
            [-35.844867916992186, -5.071368526367195],
            [-35.847867916992186, -5.070268526367194],
            [-35.851667916992184, -5.069268526367196],
            [-35.854667916992184, -5.068768526367194],
            [-35.858967916992185, -5.067668526367193],
            [-35.864667916992182, -5.068068526367199],
            [-35.868567916992184, -5.068768526367194],
            [-35.871667916992187, -5.068468526367198],
            [-35.875467916992186, -5.068168526367195],
            [-35.879067916992184, -5.067968526367196],
            [-35.882367916992187, -5.067368526367197],
            [-35.885767916992187, -5.066568526367199],
            [-35.889267916992182, -5.065768526367194],
            [-35.892667916992188, -5.0646685263672],
            [-35.896167916992184, -5.063568526367199],
            [-35.899767916992182, -5.062468526367198],
            [-35.904467916992182, -5.060768526367198],
            [-35.909167916992182, -5.059368526367194],
            [-35.912767916992188, -5.058768526367196],
            [-35.915667916992184, -5.057968526367198],
            [-35.919267916992183, -5.056668526367197],
            [-35.923867916992187, -5.054968526367198],
            [-35.928567916992186, -5.052968526367195],
            [-35.931867916992182, -5.051568526367198],
            [-35.935467916992188, -5.050468526367197],
            [-35.939067916992187, -5.049168526367197],
            [-35.942367916992183, -5.047568526367193],
            [-35.945267916992186, -5.046068526367193],
            [-35.949567916992187, -5.044168526367194],
            [-35.953267916992182, -5.044168526367194],
            [-35.956567916992185, -5.043868526367199],
            [-35.959367916992186, -5.043068526367193],
            [-35.962567916992185, -5.043568526367196],
            [-35.965667916992182, -5.043868526367199],
            [-35.970367916992188, -5.042768526367198],
            [-35.973467916992185, -5.043068526367193],
            [-35.977467916992182, -5.0417685263672],
            [-35.981767916992183, -5.043568526367196],
            [-35.986367916992187, -5.045868526367194],
            [-35.990267916992188, -5.046068526367193],
            [-35.993367916992185, -5.046068526367193],
            [-35.996367916992185, -5.045568526367198],
            [-35.999367916992185, -5.0449685263672],
            [-36.003467916992186, -5.045368526367199],
            [-36.007367916992187, -5.046368526367196],
            [-36.010367916992188, -5.048068526367196],
            [-36.014267916992182, -5.0494685263672],
            [-36.017167916992186, -5.049668526367199],
            [-36.020367916992186, -5.050068526367198],
            [-36.023367916992186, -5.050468526367197],
            [-36.027267916992187, -5.050768526367193],
            [-36.030667916992186, -5.051068526367196],
            [-36.034767916992188, -5.051368526367199],
            [-36.038667916992182, -5.052168526367197],
            [-36.040067916992186, -5.055868526367199],
            [-36.042867916992186, -5.057268526367196],
            [-36.045367916992184, -5.059068526367199],
            [-36.049267916992186, -5.060768526367198],
            [-36.053367916992187, -5.062368526367194],
            [-36.056667916992183, -5.063268526367196],
            [-36.060867916992187, -5.064068526367194],
            [-36.066067916992182, -5.0646685263672],
            [-36.071267916992184, -5.064868526367199],
            [-36.076067916992187, -5.065268526367198],
            [-36.082167916992184, -5.067468526367193],
            [-36.085467916992187, -5.069368526367199],
            [-36.088667916992186, -5.070668526367193],
            [-36.091767916992183, -5.072468526367196],
            [-36.094767916992183, -5.074868526367197],
            [-36.098467916992185, -5.0768685263672],
            [-36.102067916992183, -5.078768526367199],
            [-36.104667916992184, -5.080168526367196],
            [-36.108967916992185, -5.082568526367197],
            [-36.113067916992186, -5.085768526367197],
            [-36.116867916992184, -5.087268526367197],
            [-36.120067916992184, -5.088668526367194],
            [-36.122667916992185, -5.090068526367197],
            [-36.125467916992186, -5.091468526367194],
            [-36.128567916992182, -5.092268526367199],
            [-36.131767916992182, -5.092868526367198],
            [-36.136267916992182, -5.093668526367196],
            [-36.140767916992182, -5.094468526367194],
            [-36.144667916992184, -5.094868526367193],
            [-36.148167916992186, -5.095368526367196],
            [-36.151167916992186, -5.095568526367195],
            [-36.155367916992184, -5.095968526367194],
            [-36.159767916992188, -5.096368526367193],
            [-36.163067916992183, -5.096468526367197],
            [-36.168067916992186, -5.096368526367193],
            [-36.172767916992186, -5.095968526367194],
            [-36.175867916992182, -5.095968526367194],
            [-36.180267916992186, -5.095868526367198],
            [-36.185767916992184, -5.095568526367195],
            [-36.190767916992186, -5.095368526367196],
            [-36.194667916992188, -5.095368526367196],
            [-36.198167916992183, -5.095068526367193],
            [-36.201767916992182, -5.094868526367193],
            [-36.205067916992185, -5.095168526367196],
            [-36.211167916992189, -5.095568526367195],
            [-36.215367916992186, -5.095668526367199],
            [-36.218367916992186, -5.095868526367198],
            [-36.222267916992188, -5.095668526367199],
            [-36.225967916992182, -5.095568526367195],
            [-36.229267916992185, -5.0952685263672],
            [-36.233067916992184, -5.094868526367193],
            [-36.236667916992182, -5.094568526367198],
            [-36.240367916992184, -5.0952685263672],
            [-36.245467916992183, -5.095868526367198],
            [-36.249467916992188, -5.095568526367195],
            [-36.252467916992188, -5.094868526367193],
            [-36.257767916992186, -5.093968526367199],
            [-36.262367916992183, -5.092668526367198],
            [-36.267367916992185, -5.090968526367199],
            [-36.271867916992186, -5.089568526367195],
            [-36.276167916992186, -5.089468526367199],
            [-36.280567916992183, -5.0905685263672],
            [-36.284767916992188, -5.089468526367199],
            [-36.289267916992188, -5.088168526367198],
            [-36.293567916992188, -5.090868526367196],
            [-36.304067916992182, -5.099268526367197],
            [-36.308567916992182, -5.103068526367196],
            [-36.311867916992185, -5.0997685263672],
            [-36.314967916992188, -5.097368526367198],
            [-36.317967916992188, -5.0952685263672],
            [-36.323567916992182, -5.091968526367197],
            [-36.326267916992187, -5.090168526367194],
            [-36.330767916992187, -5.087368526367193],
            [-36.335867916992186, -5.086568526367195],
            [-36.341267916992187, -5.085368526367198],
            [-36.345167916992182, -5.086468526367199],
            [-36.351367916992189, -5.086768526367194],
            [-36.355867916992182, -5.086468526367199],
            [-36.360267916992186, -5.086468526367199],
            [-36.363567916992189, -5.086768526367194],
            [-36.366967916992188, -5.087868526367195],
            [-36.372667916992185, -5.088768526367197],
            [-36.375867916992185, -5.087668526367196],
            [-36.379867916992183, -5.087268526367197],
            [-36.383767916992184, -5.0875685263672],
            [-36.387167916992183, -5.087068526367197],
            [-36.390667916992186, -5.085968526367196],
            [-36.394267916992185, -5.084668526367196],
            [-36.398667916992189, -5.083168526367196],
            [-36.402667916992186, -5.081468526367196],
            [-36.406667916992184, -5.080668526367198],
            [-36.411467916992187, -5.079368526367197],
            [-36.416667916992182, -5.077868526367197],
            [-36.421967916992187, -5.076068526367195],
            [-36.425067916992184, -5.074968526367194],
            [-36.428667916992183, -5.073468526367193],
            [-36.434167916992187, -5.071368526367195],
            [-36.438367916992185, -5.069968526367198],
            [-36.442067916992187, -5.068768526367194],
            [-36.446367916992187, -5.067368526367197],
            [-36.449367916992188, -5.066568526367199],
            [-36.453267916992182, -5.066068526367197],
            [-36.457367916992183, -5.066268526367196],
            [-36.460367916992183, -5.068568526367194],
            [-36.463667916992186, -5.068868526367197],
            [-36.466667916992186, -5.068768526367194],
            [-36.470367916992188, -5.068768526367194],
            [-36.474267916992183, -5.069268526367196],
            [-36.477067916992183, -5.068468526367198],
            [-36.481867916992186, -5.066868526367195],
            [-36.485867916992184, -5.065468526367198],
            [-36.489967916992185, -5.064168526367197],
            [-36.495167916992187, -5.062968526367193],
            [-36.499467916992188, -5.062368526367194],
            [-36.502967916992183, -5.061868526367199],
            [-36.508067916992182, -5.062668526367197],
            [-36.511767916992184, -5.0646685263672],
            [-36.516867916992183, -5.067468526367193],
            [-36.519767916992187, -5.067368526367197],
            [-36.523767916992185, -5.067368526367197],
            [-36.528667916992184, -5.068268526367198],
            [-36.532767916992185, -5.070168526367198],
            [-36.536467916992187, -5.0738685263672],
            [-36.540567916992188, -5.076368526367197],
            [-36.542567916992184, -5.079968526367196],
            [-36.544167916992187, -5.088668526367194],
            [-36.548867916992187, -5.088368526367198],
            [-36.552767916992188, -5.087868526367195],
            [-36.556867916992182, -5.087368526367193],
            [-36.561267916992186, -5.087068526367197],
            [-36.564167916992183, -5.086768526367194],
            [-36.567067916992187, -5.086468526367199],
            [-36.570967916992188, -5.086168526367196],
            [-36.574667916992183, -5.085768526367197],
            [-36.578167916992186, -5.086568526367195],
            [-36.580667916992184, -5.088168526367198],
            [-36.584267916992182, -5.085368526367198],
            [-36.587867916992188, -5.083668526367198],
            [-36.590867916992188, -5.082668526367193],
            [-36.594467916992187, -5.081268526367197],
            [-36.598967916992187, -5.079668526367193],
            [-36.603467916992187, -5.078968526367198],
            [-36.607167916992182, -5.078568526367199],
            [-36.610567916992188, -5.078568526367199],
            [-36.612167916992185, -5.081868526367195],
            [-36.609667916992187, -5.088968526367196],
            [-36.610567916992188, -5.092368526367196],
            [-36.614167916992187, -5.094168526367199],
            [-36.617567916992186, -5.093968526367199],
            [-36.620867916992182, -5.093368526367193],
            [-36.623767916992186, -5.092368526367196],
            [-36.627167916992185, -5.091568526367197],
            [-36.630467916992188, -5.090868526367196],
            [-36.634367916992183, -5.090068526367197],
            [-36.638267916992184, -5.089568526367195],
            [-36.641267916992184, -5.089268526367199],
            [-36.644567916992187, -5.088768526367197],
            [-36.648967916992184, -5.087868526367195],
            [-36.654467916992182, -5.087868526367195],
            [-36.657667916992182, -5.0875685263672],
            [-36.660867916992188, -5.086968526367194],
            [-36.663867916992189, -5.086568526367195],
            [-36.667567916992184, -5.087268526367197],
            [-36.672167916992187, -5.086768526367194],
            [-36.676867916992187, -5.085068526367195],
            [-36.682167916992185, -5.084768526367199],
            [-36.686967916992188, -5.087268526367197],
            [-36.694367916992185, -5.086568526367195],
            [-36.701467916992186, -5.084768526367199],
            [-36.716267916992187, -5.081768526367199],
            [-36.729267916992185, -5.075968526367198],
            [-36.731367916992184, -5.071868526367197],
            [-36.734167916992185, -5.068068526367199],
            [-36.736467916992183, -5.065468526367198],
            [-36.738967916992188, -5.063868526367195],
            [-36.741867916992184, -5.062768526367194],
            [-36.745767916992186, -5.061568526367196],
            [-36.749667916992188, -5.059968526367193],
            [-36.752667916992188, -5.0586685263672],
            [-36.755567916992185, -5.056968526367193],
            [-36.758567916992185, -5.054968526367198],
            [-36.762467916992186, -5.0524685263672],
            [-36.766067916992185, -5.049968526367195],
            [-36.770067916992183, -5.049668526367199],
            [-36.774767916992182, -5.051168526367199],
            [-36.777267916992187, -5.046168526367197],
            [-36.781767916992187, -5.045268526367195],
            [-36.785867916992188, -5.0449685263672],
            [-36.788667916992182, -5.043868526367199],
            [-36.791367916992186, -5.042468526367195],
            [-36.794667916992182, -5.040368526367196],
            [-36.796967916992187, -5.038668526367196],
            [-36.799967916992188, -5.036468526367194],
            [-36.803667916992183, -5.033168526367199],
            [-36.806967916992186, -5.030168526367198],
            [-36.810167916992185, -5.027668526367194],
            [-36.812467916992183, -5.025468526367199],
            [-36.814967916992188, -5.022968526367194],
            [-36.817367916992183, -5.020468526367196],
            [-36.819567916992185, -5.017468526367196],
            [-36.822067916992182, -5.014068526367197],
            [-36.824867916992183, -5.010968526367193],
            [-36.828767916992184, -5.007068526367199],
            [-36.831767916992185, -5.003468526367193],
            [-36.834567916992185, -4.999768526367198],
            [-36.837267916992182, -4.996368526367199],
            [-36.839767916992187, -4.992968526367193],
            [-36.842667916992184, -4.989768526367193],
            [-36.845667916992184, -4.986368526367194],
            [-36.848967916992187, -4.981768526367198],
            [-36.851667916992184, -4.977868526367196],
            [-36.854267916992185, -4.973468526367199],
            [-36.857767916992188, -4.969468526367194],
            [-36.860367916992182, -4.966568526367197],
            [-36.862467916992188, -4.9640685263672],
            [-36.865067916992189, -4.960968526367196],
            [-36.866967916992188, -4.958268526367199],
            [-36.870467916992183, -4.957168526367198],
            [-36.875167916992183, -4.954268526367194],
            [-36.878467916992186, -4.950968526367198],
            [-36.882967916992186, -4.949868526367197],
            [-36.885767916992187, -4.951068526367195],
            [-36.889367916992185, -4.952168526367196],
            [-36.895367916992186, -4.952068526367199],
            [-36.899567916992183, -4.951468526367194],
            [-36.902567916992183, -4.951468526367194],
            [-36.906667916992184, -4.950668526367195],
            [-36.910067916992183, -4.949868526367197],
            [-36.914467916992187, -4.948168526367198],
            [-36.920067916992188, -4.945768526367196],
            [-36.925267916992183, -4.943168526367195],
            [-36.933867916992185, -4.937768526367194],
            [-36.942367916992183, -4.931868526367197],
            [-36.948267916992187, -4.927168526367197],
            [-36.953767916992184, -4.922868526367196],
            [-36.956167916992186, -4.921368526367196],
            [-36.958967916992187, -4.919168526367194],
            [-36.962267916992182, -4.918868526367199],
            [-36.965067916992183, -4.921368526367196],
            [-36.967667916992184, -4.925068526367198],
            [-36.970667916992184, -4.9274685263672],
            [-36.974567916992186, -4.929968526367198],
            [-36.977867916992182, -4.931168526367195],
            [-36.982167916992182, -4.931868526367197],
            [-36.986367916992187, -4.931568526367194],
            [-36.989167916992187, -4.930868526367199],
            [-36.992667916992183, -4.930168526367197],
            [-36.995467916992183, -4.929468526367195],
            [-37.000167916992183, -4.928568526367194],
            [-37.003567916992182, -4.929968526367198],
            [-37.005667916992188, -4.933068526367194],
            [-37.008767916992184, -4.935868526367194],
            [-37.013167916992188, -4.937468526367198],
            [-37.017867916992188, -4.939868526367199],
            [-37.022267916992185, -4.941668526367195],
            [-37.025767916992187, -4.943868526367197],
            [-37.028767916992187, -4.946268526367199],
            [-37.032567916992186, -4.948268526367194],
            [-37.035967916992185, -4.949568526367194],
            [-37.039767916992183, -4.949968526367194],
            [-37.043667916992185, -4.949868526367197],
            [-37.047267916992183, -4.9488685263672],
            [-37.051667916992187, -4.9473685263672],
            [-37.057267916992188, -4.944868526367195],
            [-37.061667916992185, -4.942368526367197],
            [-37.064667916992185, -4.940568526367194],
            [-37.068467916992184, -4.938068526367196],
            [-37.072367916992185, -4.935468526367195],
            [-37.074867916992183, -4.9336685263672],
            [-37.077467916992184, -4.931868526367197],
            [-37.079967916992182, -4.930268526367193],
            [-37.082967916992182, -4.929068526367196],
            [-37.086567916992188, -4.928668526367197],
            [-37.089867916992183, -4.9274685263672],
            [-37.092667916992184, -4.926168526367199],
            [-37.097067916992188, -4.924268526367193],
            [-37.100067916992188, -4.923368526367199],
            [-37.103467916992187, -4.922468526367197],
            [-37.107767916992188, -4.922768526367193],
            [-37.111467916992183, -4.925068526367198],
            [-37.115767916992183, -4.926668526367195],
            [-37.117467916992183, -4.929668526367195],
            [-37.120867916992182, -4.932968526367198],
            [-37.124467916992188, -4.9351685263672],
            [-37.129167916992188, -4.936268526367193],
            [-37.134167916992183, -4.936068526367194],
            [-37.137467916992186, -4.935868526367194],
            [-37.141767916992187, -4.935768526367198],
            [-37.144567916992187, -4.937768526367194],
            [-37.143967916992182, -4.941568526367199],
            [-37.139567916992185, -4.944168526367193],
            [-37.137467916992186, -4.949568526367194],
            [-37.141767916992187, -4.949668526367198],
            [-37.145167916992186, -4.949868526367197],
            [-37.149067916992188, -4.949368526367195],
            [-37.152567916992183, -4.948268526367194],
            [-37.155167916992184, -4.947068526367197],
            [-37.158067916992188, -4.944868526367195],
            [-37.160167916992187, -4.941668526367195],
            [-37.162867916992184, -4.936968526367195],
            [-37.165667916992184, -4.9336685263672],
            [-37.167567916992184, -4.931568526367194],
            [-37.169767916992186, -4.9289685263672],
            [-37.172767916992186, -4.924968526367195],
            [-37.174667916992185, -4.922768526367193],
            [-37.177467916992185, -4.9199685263672],
            [-37.180467916992185, -4.917168526367199],
            [-37.183567916992182, -4.915568526367196],
            [-37.186867916992185, -4.9137685263672],
            [-37.189567916992182, -4.911968526367197],
            [-37.192767916992182, -4.909768526367195],
            [-37.196267916992184, -4.907268526367197],
            [-37.200167916992186, -4.904068526367197],
            [-37.204067916992187, -4.900668526367198],
            [-37.206567916992185, -4.898268526367197],
            [-37.209867916992188, -4.894868526367198],
            [-37.213167916992184, -4.891068526367199],
            [-37.215067916992183, -4.888568526367195],
            [-37.216967916992182, -4.886468526367196],
            [-37.218767916992185, -4.884068526367194],
            [-37.221267916992183, -4.881368526367197],
            [-37.223467916992185, -4.877668526367195],
            [-37.225867916992186, -4.874368526367199],
            [-37.227567916992186, -4.871668526367195],
            [-37.229467916992185, -4.868868526367194],
            [-37.231367916992184, -4.866368526367197],
            [-37.233067916992184, -4.863568526367196],
            [-37.235267916992186, -4.860268526367193],
            [-37.237567916992184, -4.856368526367199],
            [-37.239167916992187, -4.853868526367194],
            [-37.240867916992187, -4.850668526367194],
            [-37.242567916992186, -4.847768526367197],
            [-37.244367916992182, -4.844868526367193],
            [-37.246867916992187, -4.840968526367199],
            [-37.249167916992185, -4.836468526367199],
            [-37.251067916992184, -4.833268526367199],
            [-37.253267916992186, -4.831268526367197],
            [-37.254867916992183, -4.827868526367197],
            [-37.257067916992185, -4.823168526367198],
            [-37.259167916992183, -4.818768526367194],
            [-37.262067916992187, -4.811268526367193],
            [-37.264667916992188, -4.802968526367195],
            [-37.266267916992184, -4.798168526367199],
            [-37.268167916992184, -4.793468526367199],
            [-37.269767916992187, -4.789468526367195],
            [-37.271267916992187, -4.785668526367196],
            [-37.272867916992183, -4.780168526367198],
            [-37.276167916992186, -4.769068526367199],
            [-37.278767916992187, -4.759068526367194],
            [-37.280367916992184, -4.754368526367195],
            [-37.281967916992187, -4.750768526367196],
            [-37.284767916992188, -4.745768526367193],
            [-37.286767916992183, -4.742168526367195],
            [-37.289467916992187, -4.738368526367196],
            [-37.293367916992182, -4.732268526367193],
            [-37.295867916992187, -4.7292685263672],
            [-37.299767916992188, -4.725668526367194],
            [-37.302567916992189, -4.723368526367196],
            [-37.308067916992186, -4.718068526367198],
            [-37.310767916992184, -4.715468526367196],
            [-37.315267916992184, -4.710468526367194],
            [-37.321267916992184, -4.704568526367197],
            [-37.325167916992186, -4.700968526367198],
            [-37.329067916992187, -4.697668526367195],
            [-37.333467916992184, -4.694868526367195],
            [-37.336567916992188, -4.693168526367195],
            [-37.340867916992188, -4.691568526367199],
            [-37.345867916992184, -4.690468526367198],
            [-37.349567916992186, -4.691568526367199],
            [-37.356067916992188, -4.689468526367193],
            [-37.361467916992183, -4.686968526367195],
            [-37.365067916992189, -4.685468526367195],
            [-37.367967916992185, -4.684168526367195],
            [-37.372367916992182, -4.682168526367199],
            [-37.375567916992182, -4.681168526367195],
            [-37.378767916992182, -4.680768526367196],
            [-37.383167916992186, -4.679768526367198],
            [-37.389567916992185, -4.678568526367194],
            [-37.398267916992182, -4.676868526367194],
            [-37.403467916992184, -4.675868526367196],
            [-37.407267916992183, -4.675068526367198],
            [-37.410667916992182, -4.673968526367197],
            [-37.414867916992186, -4.672168526367194],
            [-37.418867916992184, -4.670268526367195],
            [-37.422167916992187, -4.668868526367199],
            [-37.425067916992184, -4.667068526367196],
            [-37.430467916992185, -4.664568526367198],
            [-37.434367916992187, -4.663368526367194],
            [-37.440467916992183, -4.661668526367194],
            [-37.445467916992186, -4.659568526367195],
            [-37.450767916992184, -4.656968526367194],
            [-37.454867916992185, -4.655168526367198],
            [-37.458967916992187, -4.653168526367196],
            [-37.462667916992189, -4.652068526367195],
            [-37.467067916992185, -4.6515685263672],
            [-37.470967916992187, -4.650768526367195],
            [-37.475567916992183, -4.648368526367193],
            [-37.478667916992187, -4.646068526367195],
            [-37.485067916992186, -4.639868526367195],
            [-37.487467916992188, -4.637368526367197],
            [-37.489467916992183, -4.634668526367193],
            [-37.492167916992187, -4.630968526367198],
            [-37.495067916992184, -4.628368526367197],
            [-37.499867916992187, -4.626868526367197],
            [-37.504667916992183, -4.627968526367198],
            [-37.507667916992183, -4.628768526367196],
            [-37.511267916992182, -4.631768526367196],
            [-37.514367916992185, -4.6348685263672],
            [-37.517867916992188, -4.637368526367197],
            [-37.520067916992183, -4.640068526367195],
            [-37.523767916992185, -4.642068526367197],
            [-37.526867916992188, -4.642568526367199],
            [-37.530367916992184, -4.6423685263672],
            [-37.536267916992188, -4.643168526367198],
            [-37.540267916992185, -4.645668526367196],
            [-37.545867916992187, -4.644068526367199],
            [-37.552267916992186, -4.643168526367198],
            [-37.558567916992182, -4.641568526367195],
            [-37.562667916992183, -4.639968526367198],
            [-37.566067916992182, -4.638168526367195],
            [-37.572467916992188, -4.634268526367194],
            [-37.579667916992186, -4.629868526367197],
            [-37.582967916992182, -4.628768526367196],
            [-37.586167916992189, -4.628268526367194],
            [-37.589567916992188, -4.626668526367197],
            [-37.592367916992188, -4.624968526367198],
            [-37.595267916992185, -4.622968526367195],
            [-37.603067916992188, -4.617268526367198],
            [-37.607467916992185, -4.613568526367196],
            [-37.613267916992186, -4.608568526367193],
            [-37.615467916992188, -4.606668526367194],
            [-37.625267916992186, -4.598368526367196],
            [-37.631067916992187, -4.593168526367194],
            [-37.635767916992187, -4.5890685263672],
            [-37.639567916992185, -4.585768526367197],
            [-37.644667916992184, -4.580668526367198],
            [-37.648267916992182, -4.577068526367199],
            [-37.652567916992183, -4.572968526367198],
            [-37.655367916992184, -4.569968526367198],
            [-37.657567916992186, -4.567468526367193],
            [-37.660967916992185, -4.563868526367195],
            [-37.664467916992187, -4.560468526367195],
            [-37.667067916992188, -4.557268526367196],
            [-37.670267916992188, -4.5539685263672],
            [-37.672767916992186, -4.550768526367193],
            [-37.675067916992184, -4.547868526367196],
            [-37.678867916992182, -4.543368526367196],
            [-37.681867916992182, -4.539768526367197],
            [-37.685267916992188, -4.535868526367196],
            [-37.689467916992186, -4.532368526367193],
            [-37.693567916992187, -4.530368526367198],
            [-37.697967916992184, -4.526468526367196],
            [-37.700967916992184, -4.524268526367194],
            [-37.703467916992182, -4.522368526367195],
            [-37.705767916992187, -4.520468526367196],
            [-37.708467916992184, -4.518568526367197],
            [-37.712867916992188, -4.515168526367198],
            [-37.716467916992187, -4.511568526367199],
            [-37.720667916992184, -4.506268526367194],
            [-37.723867916992184, -4.502068526367196],
            [-37.726167916992182, -4.498768526367193],
            [-37.728367916992184, -4.495168526367195],
            [-37.732467916992185, -4.488368526367196],
            [-37.736067916992184, -4.481068526367196],
            [-37.740367916992184, -4.472368526367198],
            [-37.744667916992185, -4.462968526367199],
            [-37.746567916992184, -4.457968526367196],
            [-37.748767916992186, -4.452368526367195],
            [-37.751567916992187, -4.443268526367198],
            [-37.753067916992187, -4.439868526367199],
            [-37.754667916992183, -4.436568526367196],
            [-37.757067916992185, -4.433368526367197],
            [-37.759567916992182, -4.430768526367196],
            [-37.763167916992188, -4.427568526367196],
            [-37.767867916992188, -4.426668526367195],
            [-37.768267916992187, -4.422568526367193],
            [-37.766767916992187, -4.418468526367199],
            [-37.767067916992183, -4.415368526367196],
            [-37.768167916992184, -4.411668526367194],
            [-37.768267916992187, -4.407568526367193],
            [-37.769567916992187, -4.402568526367197],
            [-37.774367916992183, -4.402068526367195],
            [-37.779767916992185, -4.4015685263672],
            [-37.785067916992183, -4.400368526367195],
            [-37.789567916992183, -4.397868526367198],
            [-37.793067916992186, -4.396468526367194],
            [-37.796667916992185, -4.395668526367196],
            [-37.800067916992184, -4.398768526367199],
            [-37.805767916992188, -4.398968526367199],
            [-37.810867916992187, -4.397968526367194],
            [-37.815467916992183, -4.395768526367199],
            [-37.819367916992185, -4.393568526367197],
            [-37.822767916992184, -4.391368526367195],
            [-37.826567916992182, -4.389068526367197],
            [-37.830667916992184, -4.387368526367197],
            [-37.834067916992183, -4.383868526367195],
            [-37.838267916992187, -4.380968526367198],
            [-37.842867916992184, -4.379168526367195],
            [-37.845967916992187, -4.381668526367193],
            [-37.850567916992183, -4.381268526367194],
            [-37.854167916992182, -4.379468526367198],
            [-37.856367916992184, -4.377368526367199],
            [-37.862967916992183, -4.372168526367197],
            [-37.866167916992183, -4.369968526367195],
            [-37.868567916992184, -4.368368526367199],
            [-37.871867916992187, -4.366168526367197],
            [-37.874467916992188, -4.364468526367197],
            [-37.877767916992184, -4.361868526367196],
            [-37.880267916992182, -4.359968526367197],
            [-37.884267916992187, -4.356768526367198],
            [-37.888467916992184, -4.353468526367195],
            [-37.892567916992185, -4.350268526367195],
            [-37.895767916992185, -4.347568526367198],
            [-37.899367916992183, -4.344768526367197],
            [-37.902667916992186, -4.342368526367196],
            [-37.906567916992188, -4.339768526367195],
            [-37.910167916992187, -4.336268526367199],
            [-37.916167916992187, -4.330368526367195],
            [-37.919167916992187, -4.327668526367198],
            [-37.921467916992185, -4.325668526367195],
            [-37.924167916992182, -4.323468526367193],
            [-37.927467916992185, -4.320268526367194],
            [-37.929967916992183, -4.317668526367193],
            [-37.934067916992184, -4.313468526367195],
            [-37.936967916992188, -4.310768526367198],
            [-37.940167916992188, -4.308368526367197],
            [-37.944267916992182, -4.304668526367195],
            [-37.948267916992187, -4.301368526367199],
            [-37.950767916992184, -4.299368526367196],
            [-37.953967916992184, -4.296668526367199],
            [-37.957567916992183, -4.293868526367199],
            [-37.960067916992188, -4.2917685263672],
            [-37.963767916992182, -4.288068526367198],
            [-37.966167916992184, -4.285568526367193],
            [-37.969767916992183, -4.281968526367194],
            [-37.973767916992188, -4.277568526367197],
            [-37.976967916992187, -4.274068526367195],
            [-37.979967916992187, -4.270668526367196],
            [-37.982167916992182, -4.268168526367198],
            [-37.984367916992184, -4.265668526367193],
            [-37.986467916992183, -4.262968526367196],
            [-37.989667916992182, -4.260668526367198],
            [-37.994767916992188, -4.258468526367196],
            [-37.999367916992185, -4.255168526367193],
            [-38.003767916992182, -4.253868526367199],
            [-38.010167916992188, -4.250268526367194],
            [-38.012767916992182, -4.247768526367196],
            [-38.015167916992183, -4.245068526367199],
            [-38.017167916992186, -4.242968526367193],
            [-38.019367916992188, -4.240768526367198],
            [-38.022967916992187, -4.236468526367197],
            [-38.026467916992182, -4.233368526367194],
            [-38.028367916992188, -4.230968526367199],
            [-38.030367916992184, -4.228468526367195],
            [-38.033067916992188, -4.225368526367198],
            [-38.038067916992183, -4.223068526367193],
            [-38.042767916992183, -4.219868526367193],
            [-38.046367916992182, -4.216168526367198],
            [-38.048867916992187, -4.213968526367196],
            [-38.051167916992185, -4.211168526367196],
            [-38.054167916992185, -4.207868526367193],
            [-38.056867916992182, -4.204568526367197],
            [-38.061067916992187, -4.199568526367194],
            [-38.064067916992187, -4.197068526367197],
            [-38.066867916992187, -4.195468526367193],
            [-38.070767916992182, -4.191868526367195],
            [-38.073567916992182, -4.188768526367198],
            [-38.076067916992187, -4.185468526367195],
            [-38.079667916992186, -4.181168526367195],
            [-38.083467916992184, -4.177568526367196],
            [-38.086167916992189, -4.175068526367198],
            [-38.090167916992186, -4.171368526367196],
            [-38.092267916992185, -4.169268526367198],
            [-38.096467916992182, -4.165368526367196],
            [-38.100467916992187, -4.161568526367198],
            [-38.103467916992187, -4.158468526367194],
            [-38.105867916992182, -4.156268526367199],
            [-38.110267916992186, -4.153368526367196],
            [-38.114167916992187, -4.149568526367197],
            [-38.116867916992184, -4.146768526367197],
            [-38.120167916992187, -4.142568526367199],
            [-38.122167916992183, -4.139868526367195],
            [-38.129367916992187, -4.130268526367196],
            [-38.134367916992183, -4.122968526367195],
            [-38.139967916992184, -4.113868526367199],
            [-38.143967916992182, -4.106668526367194],
            [-38.145367916992186, -4.103468526367195],
            [-38.148667916992189, -4.101768526367195],
            [-38.148967916992184, -4.098168526367196],
            [-38.151267916992182, -4.094868526367193],
            [-38.154067916992183, -4.092268526367199],
            [-38.156267916992185, -4.090168526367194],
            [-38.158667916992187, -4.087668526367196],
            [-38.160267916992183, -4.085168526367198],
            [-38.162067916992186, -4.082868526367193],
            [-38.164467916992187, -4.079568526367197],
            [-38.167567916992184, -4.075168526367193],
            [-38.170067916992188, -4.071368526367195],
            [-38.171867916992184, -4.068468526367198],
            [-38.175267916992183, -4.063768526367198],
            [-38.180567916992182, -4.057668526367195],
            [-38.182567916992184, -4.053068526367198],
            [-38.184767916992186, -4.050368526367194],
            [-38.186967916992188, -4.047268526367198],
            [-38.188767916992184, -4.0449685263672],
            [-38.190567916992187, -4.042268526367195],
            [-38.194267916992182, -4.039568526367198],
            [-38.198467916992186, -4.037768526367195],
            [-38.200967916992184, -4.035868526367196],
            [-38.203967916992184, -4.033068526367195],
            [-38.207867916992186, -4.028668526367198],
            [-38.210867916992186, -4.023768526367199],
            [-38.213967916992182, -4.019568526367195],
            [-38.216767916992183, -4.016068526367199],
            [-38.219067916992188, -4.012668526367193],
            [-38.224567916992186, -4.006368526367197],
            [-38.226967916992187, -4.002768526367198],
            [-38.230067916992184, -3.998868526367197],
            [-38.232467916992185, -3.995868526367197],
            [-38.235267916992186, -3.992468526367198],
            [-38.237767916992183, -3.989168526367195],
            [-38.240767916992183, -3.985668526367199],
            [-38.242967916992185, -3.983068526367198],
            [-38.246167916992185, -3.978568526367198],
            [-38.249067916992182, -3.974868526367196],
            [-38.251367916992187, -3.972068526367195],
            [-38.256067916992187, -3.966268526367195],
            [-38.259967916992188, -3.960668526367193],
            [-38.262867916992185, -3.957568526367197],
            [-38.266867916992183, -3.952868526367197],
            [-38.268767916992182, -3.950668526367195],
            [-38.271167916992184, -3.947168526367193],
            [-38.274467916992187, -3.943768526367194],
            [-38.279167916992186, -3.941368526367199],
            [-38.284167916992182, -3.941568526367199],
            [-38.288667916992182, -3.942068526367194],
            [-38.292767916992183, -3.941068526367197],
            [-38.296667916992185, -3.939168526367197],
            [-38.301167916992185, -3.9366685263672],
            [-38.304767916992184, -3.934968526367193],
            [-38.308667916992185, -3.932568526367199],
            [-38.311567916992182, -3.930768526367196],
            [-38.314867916992185, -3.928568526367194],
            [-38.319967916992184, -3.924968526367195],
            [-38.322767916992184, -3.922768526367193],
            [-38.326067916992187, -3.9199685263672],
            [-38.328967916992184, -3.917568526367198],
            [-38.331067916992183, -3.915668526367199],
            [-38.334567916992185, -3.912368526367196],
            [-38.337067916992183, -3.909768526367195],
            [-38.339067916992185, -3.907568526367193],
            [-38.341967916992182, -3.904768526367199],
            [-38.345067916992186, -3.901468526367196],
            [-38.349167916992187, -3.897168526367196],
            [-38.351967916992187, -3.894368526367195],
            [-38.354967916992187, -3.890768526367197],
            [-38.357467916992185, -3.8878685263672],
            [-38.360267916992186, -3.8848685263672],
            [-38.369467916992186, -3.872468526367193],
            [-38.372767916992188, -3.867668526367197],
            [-38.376067916992184, -3.862768526367198],
            [-38.377767916992184, -3.860068526367193],
            [-38.382367916992187, -3.853368526367198],
            [-38.385667916992183, -3.848168526367196],
            [-38.388567916992187, -3.843968526367199],
            [-38.392367916992185, -3.838168526367198],
            [-38.393967916992182, -3.835068526367195],
            [-38.396167916992184, -3.831768526367199],
            [-38.399067916992188, -3.8268685263672],
            [-38.401767916992185, -3.8253685263672],
            [-38.401767916992185, -3.821768526367194],
            [-38.403367916992188, -3.819268526367196],
            [-38.406267916992185, -3.815568526367194],
            [-38.408667916992187, -3.811568526367196],
            [-38.414867916992186, -3.802568526367196],
            [-38.418067916992186, -3.797868526367196],
            [-38.420867916992187, -3.793968526367195],
            [-38.423967916992183, -3.788368526367194],
            [-38.427267916992186, -3.782568526367193],
            [-38.429667916992187, -3.778768526367195],
            [-38.433267916992186, -3.772268526367199],
            [-38.435467916992188, -3.770368526367193],
            [-38.436867916992185, -3.767168526367193],
            [-38.438367916992185, -3.764568526367199],
            [-38.440167916992188, -3.761768526367199],
            [-38.442667916992185, -3.757468526367198],
            [-38.443867916992183, -3.754868526367197],
            [-38.446067916992185, -3.750268526367194],
            [-38.447467916992188, -3.7476685263672],
            [-38.449867916992183, -3.743268526367196],
            [-38.452067916992185, -3.738868526367199],
            [-38.453567916992185, -3.735368526367196],
            [-38.455767916992187, -3.731368526367199],
            [-38.457567916992183, -3.728068526367196],
            [-38.458967916992187, -3.724568526367193],
            [-38.460867916992186, -3.720168526367196],
            [-38.462367916992186, -3.715868526367196],
            [-38.463267916992187, -3.712968526367199],
            [-38.463767916992182, -3.709268526367197],
            [-38.467567916992188, -3.708168526367196],
            [-38.470667916992184, -3.706168526367193],
            [-38.474567916992186, -3.701768526367196],
            [-38.473667916992184, -3.705368526367195],
            [-38.474567916992186, -3.710768526367197],
            [-38.477067916992183, -3.716268526367195],
            [-38.477567916992186, -3.719768526367197],
            [-38.481767916992183, -3.721968526367199],
            [-38.484667916992187, -3.722368526367198],
            [-38.487567916992184, -3.722368526367198],
            [-38.490267916992188, -3.723668526367199],
            [-38.494167916992183, -3.724868526367196],
            [-38.499067916992182, -3.724468526367197],
            [-38.503767916992182, -3.721668526367196],
            [-38.507667916992183, -3.720968526367194],
            [-38.512367916992183, -3.718968526367199],
            [-38.516567916992187, -3.7170685263672],
            [-38.520167916992186, -3.717268526367199],
            [-38.523967916992184, -3.717868526367198],
            [-38.526867916992188, -3.719468526367194],
            [-38.530567916992183, -3.718668526367196],
            [-38.534767916992188, -3.717568526367195],
            [-38.539867916992186, -3.7155685263672],
            [-38.541967916992185, -3.712268526367197],
            [-38.546067916992186, -3.711568526367195],
            [-38.549267916992186, -3.709868526367195],
            [-38.551867916992187, -3.708168526367196],
            [-38.554467916992188, -3.706468526367196],
            [-38.558667916992185, -3.705168526367196],
            [-38.564167916992183, -3.703168526367193],
            [-38.569567916992185, -3.700968526367198],
            [-38.574867916992183, -3.698168526367198],
            [-38.576767916992182, -3.695168526367198],
            [-38.580367916992188, -3.693768526367194],
            [-38.585467916992187, -3.693068526367199],
            [-38.587967916992184, -3.696368526367195],
            [-38.591167916992184, -3.695168526367198],
            [-38.594867916992186, -3.694068526367197],
            [-38.598467916992185, -3.693168526367195],
            [-38.603867916992186, -3.691668526367195],
            [-38.607867916992184, -3.690968526367193],
            [-38.612167916992185, -3.688868526367195],
            [-38.617767916992186, -3.6896685263672],
            [-38.622967916992188, -3.689068526367194],
            [-38.627067916992182, -3.688068526367196],
            [-38.629867916992183, -3.687268526367198],
            [-38.633767916992184, -3.686068526367194],
            [-38.637467916992186, -3.6866685263672],
            [-38.640967916992182, -3.686868526367199],
            [-38.645067916992183, -3.685468526367195],
            [-38.648967916992184, -3.683868526367199],
            [-38.652867916992186, -3.681668526367197],
            [-38.656267916992185, -3.679668526367195],
            [-38.660367916992186, -3.676868526367194],
            [-38.663067916992183, -3.674968526367195],
            [-38.665867916992184, -3.673168526367199],
            [-38.668967916992187, -3.670768526367198],
            [-38.673967916992183, -3.6667685263672],
            [-38.676667916992187, -3.664468526367195],
            [-38.680867916992185, -3.659868526367198],
            [-38.685167916992185, -3.655568526367198],
            [-38.689567916992182, -3.651168526367194],
            [-38.691567916992184, -3.648168526367193],
            [-38.696067916992185, -3.643168526367198],
            [-38.699067916992185, -3.640368526367197],
            [-38.701567916992182, -3.638468526367198],
            [-38.705667916992184, -3.636068526367197],
            [-38.708967916992187, -3.633868526367195],
            [-38.712567916992185, -3.632668526367198],
            [-38.715667916992182, -3.631368526367197],
            [-38.719467916992187, -3.629168526367195],
            [-38.724567916992186, -3.625568526367196],
            [-38.728367916992184, -3.624468526367195],
            [-38.731367916992184, -3.623368526367194],
            [-38.735267916992186, -3.622368526367197],
            [-38.738667916992185, -3.620868526367197],
            [-38.741967916992188, -3.619368526367197],
            [-38.746967916992183, -3.617468526367198],
            [-38.752367916992185, -3.613868526367199],
            [-38.755667916992188, -3.611168526367194],
            [-38.759867916992185, -3.607868526367199],
            [-38.762467916992186, -3.605668526367197],
            [-38.766067916992185, -3.601968526367195],
            [-38.770667916992188, -3.5967685263672],
            [-38.773167916992186, -3.593468526367197],
            [-38.776167916992186, -3.5890685263672],
            [-38.777867916992186, -3.586268526367199],
            [-38.780067916992188, -3.583468526367199],
            [-38.783467916992187, -3.5798685263672],
            [-38.786967916992182, -3.576268526367194],
            [-38.789467916992187, -3.573468526367193],
            [-38.791467916992183, -3.570568526367197],
            [-38.794267916992183, -3.566968526367198],
            [-38.798267916992188, -3.561868526367199],
            [-38.800767916992186, -3.557668526367195],
            [-38.803267916992183, -3.554168526367199],
            [-38.805767916992188, -3.549668526367199],
            [-38.808267916992186, -3.546368526367196],
            [-38.810167916992185, -3.544168526367194],
            [-38.813367916992185, -3.543468526367199],
            [-38.816567916992184, -3.544668526367197],
            [-38.820467916992186, -3.546068526367193],
            [-38.824867916992183, -3.5464685263672],
            [-38.828567916992185, -3.546368526367196],
            [-38.834267916992182, -3.545668526367194],
            [-38.838667916992186, -3.545068526367196],
            [-38.842667916992184, -3.543968526367195],
            [-38.847267916992188, -3.542868526367194],
            [-38.852067916992183, -3.540668526367199],
            [-38.854467916992185, -3.538968526367199],
            [-38.858167916992187, -3.536368526367198],
            [-38.860567916992188, -3.534468526367199],
            [-38.863267916992186, -3.532268526367197],
            [-38.866367916992182, -3.529068526367197],
            [-38.869367916992182, -3.525468526367199],
            [-38.871967916992183, -3.523168526367193],
            [-38.874467916992188, -3.520968526367199],
            [-38.877067916992182, -3.518568526367197],
            [-38.880667916992188, -3.515068526367195],
            [-38.886267916992182, -3.510168526367195],
            [-38.888567916992187, -3.507768526367194],
            [-38.891267916992184, -3.506268526367194],
            [-38.894667916992184, -3.506068526367194],
            [-38.898367916992186, -3.505668526367195],
            [-38.901567916992185, -3.505268526367196],
            [-38.906167916992182, -3.505468526367196],
            [-38.910567916992186, -3.502968526367198],
            [-38.913667916992182, -3.499868526367194],
            [-38.917067916992188, -3.495268526367198],
            [-38.921467916992185, -3.487768526367198],
            [-38.924467916992185, -3.482168526367197],
            [-38.926667916992187, -3.476768526367195],
            [-38.929467916992188, -3.472568526367198],
            [-38.932467916992188, -3.464268526367199],
            [-38.934367916992187, -3.460068526367195],
            [-38.935267916992188, -3.456868526367195],
            [-38.936967916992188, -3.453168526367193],
            [-38.938567916992184, -3.450668526367195],
            [-38.941667916992188, -3.447868526367195],
            [-38.944667916992188, -3.445968526367196],
            [-38.946667916992183, -3.443568526367194],
            [-38.949267916992184, -3.440468526367198],
            [-38.952167916992188, -3.437768526367194],
            [-38.954867916992185, -3.434368526367194],
            [-38.957367916992183, -3.430868526367199],
            [-38.959567916992185, -3.427868526367199],
            [-38.962567916992185, -3.423968526367197],
            [-38.965867916992188, -3.420068526367196],
            [-38.967867916992184, -3.417868526367194],
            [-38.970567916992188, -3.4152685263672],
            [-38.973767916992188, -3.410968526367199],
            [-38.976367916992189, -3.409468526367199],
            [-38.979267916992185, -3.406968526367194],
            [-38.982767916992188, -3.404768526367199],
            [-38.988067916992186, -3.401868526367195],
            [-38.991667916992185, -3.399568526367197],
            [-38.995067916992184, -3.397868526367198],
            [-38.998367916992187, -3.397268526367199],
            [-39.001567916992187, -3.396868526367193],
            [-39.005667916992188, -3.396868526367193],
            [-39.008867916992187, -3.398368526367193],
            [-39.012767916992182, -3.400168526367196],
            [-39.016067916992185, -3.403368526367196],
            [-39.020367916992186, -3.404168526367194],
            [-39.023467916992182, -3.403668526367198],
            [-39.027867916992186, -3.404768526367199],
            [-39.030867916992186, -3.405968526367197],
            [-39.032667916992182, -3.408868526367193],
            [-39.035367916992186, -3.410968526367199],
            [-39.040267916992185, -3.411268526367195],
            [-39.045667916992187, -3.411968526367197],
            [-39.052467916992185, -3.411268526367195],
            [-39.056967916992186, -3.409168526367196],
            [-39.062967916992186, -3.408668526367194],
            [-39.067667916992185, -3.404768526367199],
            [-39.071367916992187, -3.402868526367193],
            [-39.074267916992184, -3.401268526367197],
            [-39.078267916992182, -3.397568526367195],
            [-39.083667916992184, -3.393268526367194],
            [-39.085867916992186, -3.390968526367196],
            [-39.088767916992182, -3.387468526367194],
            [-39.091167916992184, -3.384568526367197],
            [-39.103167916992184, -3.366168526367197],
            [-39.107867916992184, -3.357268526367193],
            [-39.110367916992182, -3.350868526367194],
            [-39.113067916992186, -3.346668526367196],
            [-39.117267916992184, -3.345368526367196],
            [-39.121067916992182, -3.344468526367194],
            [-39.125167916992183, -3.343968526367199],
            [-39.128067916992187, -3.343768526367199],
            [-39.130967916992184, -3.344868526367193],
            [-39.135667916992183, -3.345168526367196],
            [-39.140767916992182, -3.344868526367193],
            [-39.144067916992185, -3.343368526367193],
            [-39.146867916992186, -3.340968526367199],
            [-39.150667916992184, -3.337868526367195],
            [-39.156267916992185, -3.335368526367198],
            [-39.159867916992184, -3.331468526367196],
            [-39.162767916992188, -3.328268526367197],
            [-39.165567916992188, -3.325968526367198],
            [-39.168067916992186, -3.322068526367197],
            [-39.169967916992185, -3.319868526367195],
            [-39.177467916992185, -3.309168526367195],
            [-39.185467916992188, -3.295268526367195],
            [-39.187667916992183, -3.290668526367199],
            [-39.189867916992185, -3.2872685263672],
            [-39.191967916992184, -3.284168526367196],
            [-39.194567916992185, -3.282268526367197],
            [-39.196267916992184, -3.279868526367196],
            [-39.198767916992182, -3.275468526367199],
            [-39.201867916992185, -3.271868526367193],
            [-39.205167916992188, -3.267868526367195],
            [-39.207567916992183, -3.264968526367198],
            [-39.209367916992186, -3.261868526367195],
            [-39.212867916992188, -3.257968526367193],
            [-39.219167916992184, -3.250268526367194],
            [-39.221667916992182, -3.247168526367197],
            [-39.224467916992182, -3.244468526367193],
            [-39.226967916992187, -3.2414685263672],
            [-39.229567916992188, -3.239168526367195],
            [-39.232867916992184, -3.237568526367198],
            [-39.237267916992188, -3.235668526367199],
            [-39.240867916992187, -3.232868526367199],
            [-39.244467916992186, -3.229468526367199],
            [-39.248767916992186, -3.225268526367195],
            [-39.251067916992184, -3.222868526367193],
            [-39.254367916992187, -3.220868526367198],
            [-39.257767916992186, -3.219168526367199],
            [-39.260667916992183, -3.217968526367194],
            [-39.263967916992186, -3.217668526367198],
            [-39.268767916992182, -3.218368526367193],
            [-39.272867916992183, -3.219768526367197],
            [-39.276467916992182, -3.219768526367197],
            [-39.279467916992182, -3.218768526367199],
            [-39.284467916992185, -3.216968526367197],
            [-39.287867916992184, -3.215968526367199],
            [-39.292567916992184, -3.215068526367197],
            [-39.296967916992187, -3.212668526367196],
            [-39.300467916992183, -3.210068526367195],
            [-39.305867916992185, -3.206868526367195],
            [-39.309067916992184, -3.206868526367195],
            [-39.312967916992186, -3.205768526367194],
            [-39.318267916992184, -3.202468526367198],
            [-39.322167916992186, -3.200668526367195],
            [-39.325167916992186, -3.198468526367193],
            [-39.330167916992188, -3.195168526367198],
            [-39.332867916992186, -3.192668526367193],
            [-39.335367916992183, -3.189968526367196],
            [-39.338467916992187, -3.185568526367199],
            [-39.340667916992182, -3.181568526367194],
            [-39.345267916992185, -3.177768526367196],
            [-39.349167916992187, -3.176568526367198],
            [-39.352067916992183, -3.174768526367195],
            [-39.356367916992184, -3.173568526367198],
            [-39.360267916992186, -3.173168526367199],
            [-39.364667916992182, -3.173268526367195],
            [-39.367967916992185, -3.175268526367198],
            [-39.371867916992187, -3.178268526367198],
            [-39.378467916992186, -3.180268526367193],
            [-39.378867916992185, -3.1836685263672],
            [-39.382467916992184, -3.183068526367194],
            [-39.385767916992187, -3.180868526367199],
            [-39.388167916992188, -3.179068526367196],
            [-39.391767916992187, -3.178368526367194],
            [-39.396867916992186, -3.176668526367195],
            [-39.399567916992183, -3.174768526367195],
            [-39.402267916992187, -3.172268526367198],
            [-39.404067916992183, -3.170068526367196],
            [-39.407567916992186, -3.166668526367197],
            [-39.409167916992182, -3.164268526367195],
            [-39.411767916992183, -3.161168526367199],
            [-39.419267916992183, -3.155968526367197],
            [-39.422167916992187, -3.1545685263672],
            [-39.426167916992185, -3.154768526367199],
            [-39.431967916992186, -3.151468526367196],
            [-39.435467916992188, -3.149068526367195],
            [-39.439067916992187, -3.147668526367198],
            [-39.442967916992188, -3.146568526367197],
            [-39.447167916992186, -3.146468526367194],
            [-39.451067916992187, -3.145668526367196],
            [-39.455167916992188, -3.145668526367196],
            [-39.458267916992185, -3.144768526367194],
            [-39.462867916992188, -3.143468526367194],
            [-39.469067916992188, -3.139668526367196],
            [-39.471967916992185, -3.137968526367196],
            [-39.474967916992185, -3.134968526367196],
            [-39.478567916992183, -3.129968526367193],
            [-39.481067916992188, -3.126568526367194],
            [-39.483267916992183, -3.124368526367199],
            [-39.486167916992187, -3.119768526367196],
            [-39.489367916992187, -3.118268526367196],
            [-39.493367916992185, -3.116868526367199],
            [-39.496867916992187, -3.114768526367193],
            [-39.499467916992188, -3.113268526367193],
            [-39.503067916992187, -3.111368526367194],
            [-39.508767916992184, -3.109168526367199],
            [-39.515167916992183, -3.104968526367195],
            [-39.521567916992183, -3.102468526367197],
            [-39.527367916992183, -3.098068526367193],
            [-39.530067916992188, -3.094768526367197],
            [-39.533067916992188, -3.091168526367198],
            [-39.537267916992185, -3.088668526367194],
            [-39.542467916992187, -3.084668526367196],
            [-39.545067916992188, -3.082268526367194],
            [-39.550767916992186, -3.079268526367194],
            [-39.553867916992182, -3.078268526367197],
            [-39.558367916992182, -3.076068526367195],
            [-39.562167916992188, -3.075968526367198],
            [-39.565967916992186, -3.076068526367195],
            [-39.569267916992182, -3.074568526367194],
            [-39.573467916992186, -3.071668526367198],
            [-39.578567916992185, -3.072868526367195],
            [-39.583267916992185, -3.072468526367196],
            [-39.586767916992187, -3.071268526367199],
            [-39.591267916992187, -3.069568526367199],
            [-39.594267916992187, -3.067968526367196],
            [-39.597067916992188, -3.065268526367198],
            [-39.600567916992183, -3.060468526367195],
            [-39.602767916992185, -3.057268526367196],
            [-39.606167916992185, -3.051868526367194],
            [-39.609767916992183, -3.045368526367199],
            [-39.611067916992184, -3.042468526367195],
            [-39.613667916992185, -3.038468526367197],
            [-39.614967916992185, -3.034868526367198],
            [-39.616167916992183, -3.031268526367199],
            [-39.617567916992186, -3.028768526367195],
            [-39.619667916992185, -3.026268526367197],
            [-39.623067916992184, -3.024368526367198],
            [-39.628567916992182, -3.021868526367193],
            [-39.633567916992185, -3.020368526367193],
            [-39.637467916992186, -3.018968526367196],
            [-39.641067916992185, -3.018968526367196],
            [-39.643767916992182, -3.020668526367196],
            [-39.645967916992184, -3.023368526367193],
            [-39.648767916992185, -3.024268526367194],
            [-39.652667916992186, -3.024268526367194],
            [-39.656767916992187, -3.023168526367193],
            [-39.659767916992188, -3.021568526367197],
            [-39.663167916992187, -3.018468526367194],
            [-39.665267916992185, -3.016268526367199],
            [-39.670267916992188, -3.010268526367199],
            [-39.672467916992183, -3.007368526367195],
            [-39.673967916992183, -3.004668526367198],
            [-39.676167916992185, -3.001668526367197],
            [-39.679667916992187, -3.000768526367196],
            [-39.683267916992186, -2.999068526367196],
            [-39.687667916992183, -2.996568526367199],
            [-39.691067916992182, -2.996668526367195],
            [-39.693867916992183, -2.998268526367198],
            [-39.696867916992183, -2.999968526367198],
            [-39.701267916992187, -3.001068526367199],
            [-39.705067916992185, -3.001068526367199],
            [-39.709267916992182, -2.999868526367194],
            [-39.713967916992182, -2.998368526367194],
            [-39.719767916992183, -2.998268526367198],
            [-39.722567916992183, -2.995168526367195],
            [-39.727267916992183, -2.993068526367196],
            [-39.732467916992185, -2.990468526367195],
            [-39.736167916992187, -2.987268526367195],
            [-39.740367916992184, -2.983568526367193],
            [-39.743667916992187, -2.980568526367193],
            [-39.746267916992188, -2.978968526367197],
            [-39.749467916992188, -2.977268526367197],
            [-39.752467916992188, -2.974168526367194],
            [-39.756267916992186, -2.970568526367195],
            [-39.758467916992188, -2.968768526367199],
            [-39.761067916992182, -2.9670685263672],
            [-39.765767916992182, -2.963268526367195],
            [-39.768767916992182, -2.960868526367193],
            [-39.772667916992184, -2.958268526367199],
            [-39.776167916992186, -2.955968526367194],
            [-39.780867916992186, -2.953968526367198],
            [-39.787067916992186, -2.950968526367198],
            [-39.790567916992188, -2.950968526367198],
            [-39.793867916992184, -2.9518685263672],
            [-39.798567916992184, -2.945768526367196],
            [-39.804167916992185, -2.942968526367196],
            [-39.809467916992183, -2.939868526367199],
            [-39.814367916992182, -2.935868526367194],
            [-39.818567916992187, -2.934668526367197],
            [-39.822667916992188, -2.931668526367197],
            [-39.825367916992185, -2.929668526367195],
            [-39.829567916992183, -2.926568526367198],
            [-39.831867916992188, -2.924168526367197],
            [-39.835367916992183, -2.920868526367194],
            [-39.837867916992188, -2.918568526367196],
            [-39.841667916992186, -2.915068526367193],
            [-39.844867916992186, -2.912368526367196],
            [-39.850367916992184, -2.908068526367195],
            [-39.853367916992184, -2.905868526367193],
            [-39.856967916992183, -2.903368526367196],
            [-39.861867916992182, -2.899868526367193],
            [-39.865067916992189, -2.897968526367194],
            [-39.868267916992188, -2.896868526367193],
            [-39.872467916992186, -2.896168526367198],
            [-39.876567916992187, -2.893268526367194],
            [-39.880967916992184, -2.892468526367196],
            [-39.885667916992183, -2.889568526367199],
            [-39.886767916992184, -2.885968526367193],
            [-39.888567916992187, -2.883768526367199],
            [-39.892167916992186, -2.881268526367194],
            [-39.896267916992187, -2.878768526367196],
            [-39.900467916992184, -2.878068526367194],
            [-39.899367916992183, -2.882968526367193],
            [-39.909867916992184, -2.879568526367194],
            [-39.913967916992185, -2.878768526367196],
            [-39.919267916992183, -2.876668526367197],
            [-39.922567916992186, -2.875268526367194],
            [-39.926467916992188, -2.873368526367194],
            [-39.930767916992188, -2.871668526367195],
            [-39.933867916992185, -2.870768526367193],
            [-39.936267916992186, -2.868868526367194],
            [-39.939067916992187, -2.867668526367197],
            [-39.942467916992186, -2.866168526367197],
            [-39.947167916992186, -2.862868526367194],
            [-39.950967916992184, -2.860268526367193],
            [-39.955167916992188, -2.857868526367199],
            [-39.957867916992186, -2.856468526367195],
            [-39.960767916992182, -2.855368526367194],
            [-39.965867916992188, -2.8512685263672],
            [-39.970867916992184, -2.848368526367196],
            [-39.979967916992187, -2.844568526367198],
            [-39.987467916992188, -2.841968526367197],
            [-39.990467916992188, -2.841768526367197],
            [-39.987867916992187, -2.846268526367197],
            [-39.992467916992183, -2.844868526367193],
            [-39.996967916992183, -2.844468526367194],
            [-40.000567916992182, -2.844168526367199],
            [-40.005567916992185, -2.843668526367196],
            [-40.009567916992182, -2.843168526367194],
            [-40.012467916992186, -2.842568526367195],
            [-40.019067916992185, -2.840668526367196],
            [-40.022867916992183, -2.839568526367195],
            [-40.026167916992186, -2.838968526367196],
            [-40.026567916992185, -2.841968526367197],
            [-40.032267916992183, -2.840068526367197],
            [-40.035367916992186, -2.840868526367196],
            [-40.038467916992182, -2.841568526367197],
            [-40.043967916992187, -2.839368526367195],
            [-40.048367916992184, -2.8375685263672],
            [-40.051167916992185, -2.836468526367199],
            [-40.054667916992187, -2.835068526367195],
            [-40.059167916992187, -2.834368526367193],
            [-40.062367916992187, -2.836968526367194],
            [-40.068167916992188, -2.836268526367199],
            [-40.072467916992188, -2.836168526367196],
            [-40.075967916992184, -2.840068526367197],
            [-40.078767916992184, -2.837868526367195],
            [-40.080767916992187, -2.8345685263672],
            [-40.085367916992183, -2.831868526367195],
            [-40.095867916992184, -2.828268526367197],
            [-40.100267916992188, -2.827068526367199],
            [-40.103367916992184, -2.827168526367196],
            [-40.109967916992183, -2.826468526367194],
            [-40.113267916992186, -2.826368526367197],
            [-40.117267916992184, -2.827068526367199],
            [-40.120267916992184, -2.824868526367197],
            [-40.126267916992184, -2.823768526367196],
            [-40.130267916992182, -2.824868526367197],
            [-40.135367916992188, -2.826068526367195],
            [-40.133567916992185, -2.829268526367194],
            [-40.136367916992185, -2.830468526367198],
            [-40.136567916992185, -2.834368526367193],
            [-40.139267916992182, -2.837968526367199],
            [-40.144867916992183, -2.839768526367195],
            [-40.147967916992187, -2.838668526367194],
            [-40.151167916992186, -2.836868526367198],
            [-40.156267916992185, -2.834368526367193],
            [-40.159867916992184, -2.832568526367197],
            [-40.163867916992189, -2.828968526367198],
            [-40.168567916992188, -2.825968526367198],
            [-40.171467916992185, -2.823468526367193],
            [-40.173867916992187, -2.821668526367198],
            [-40.176567916992184, -2.819968526367198],
            [-40.179967916992183, -2.817768526367196],
            [-40.182967916992183, -2.814968526367196],
            [-40.184867916992182, -2.812168526367195],
            [-40.188767916992184, -2.812168526367195],
            [-40.192067916992187, -2.811968526367195],
            [-40.195267916992186, -2.8116685263672],
            [-40.198267916992187, -2.811568526367196],
            [-40.201067916992187, -2.810868526367194],
            [-40.205067916992185, -2.8116685263672],
            [-40.208967916992187, -2.811268526367193],
            [-40.212367916992186, -2.811368526367197],
            [-40.216467916992187, -2.810868526367194],
            [-40.219867916992186, -2.810768526367198],
            [-40.223967916992187, -2.810768526367198],
            [-40.228067916992188, -2.810868526367194],
            [-40.232467916992185, -2.811568526367196],
            [-40.236367916992187, -2.814068526367194],
            [-40.241567916992182, -2.814168526367197],
            [-40.253267916992186, -2.812368526367194],
            [-40.256267916992186, -2.811868526367199],
            [-40.261367916992185, -2.811168526367197],
            [-40.264267916992182, -2.810768526367198],
            [-40.267867916992188, -2.8101685263672],
            [-40.271767916992182, -2.809868526367197],
            [-40.274767916992182, -2.809468526367198],
            [-40.278167916992182, -2.809668526367197],
            [-40.283767916992183, -2.809068526367199],
            [-40.288167916992187, -2.809068526367199],
            [-40.294267916992183, -2.808268526367193],
            [-40.297867916992182, -2.807968526367198],
            [-40.303367916992187, -2.807968526367198],
            [-40.306867916992182, -2.807968526367198],
            [-40.309767916992186, -2.807668526367195],
            [-40.313567916992184, -2.807268526367196],
            [-40.317967916992188, -2.806968526367193],
            [-40.321667916992183, -2.806568526367194],
            [-40.327067916992185, -2.806068526367199],
            [-40.332567916992183, -2.805868526367199],
            [-40.339567916992188, -2.806068526367199],
            [-40.344167916992184, -2.806668526367197],
            [-40.350067916992188, -2.807968526367198],
            [-40.353167916992184, -2.8086685263672],
            [-40.361167916992187, -2.809968526367193],
            [-40.365067916992189, -2.810768526367198],
            [-40.368067916992182, -2.8116685263672],
            [-40.370867916992182, -2.812168526367195],
            [-40.376867916992182, -2.812668526367197],
            [-40.385667916992183, -2.813468526367195],
            [-40.390667916992186, -2.813768526367198],
            [-40.400467916992184, -2.813468526367195],
            [-40.405067916992188, -2.813268526367196],
            [-40.408067916992188, -2.813068526367196],
            [-40.411167916992184, -2.812968526367193],
            [-40.420367916992184, -2.811968526367195],
            [-40.423967916992183, -2.811068526367194],
            [-40.428267916992183, -2.811168526367197],
            [-40.434667916992183, -2.810268526367196],
            [-40.438067916992182, -2.809368526367194],
            [-40.449067916992185, -2.805768526367196],
            [-40.454567916992183, -2.804368526367199],
            [-40.458267916992185, -2.802268526367193],
            [-40.462867916992188, -2.800268526367198],
            [-40.467667916992184, -2.798568526367198],
            [-40.470567916992188, -2.797468526367197],
            [-40.473467916992185, -2.796168526367197],
            [-40.476467916992185, -2.794668526367197],
            [-40.482467916992185, -2.791968526367199],
            [-40.488567916992189, -2.788668526367196],
            [-40.491667916992185, -2.787068526367193],
            [-40.496867916992187, -2.784768526367195],
            [-40.500867916992185, -2.784768526367195],
            [-40.504467916992184, -2.786468526367194],
            [-40.508067916992182, -2.787068526367193],
            [-40.512367916992183, -2.788668526367196],
            [-40.515767916992182, -2.790368526367196],
            [-40.520067916992183, -2.792468526367195],
            [-40.519267916992185, -2.797168526367194],
            [-40.520767916992185, -2.800768526367193],
            [-40.523667916992189, -2.804468526367195],
            [-40.525967916992187, -2.807768526367198],
            [-40.528967916992187, -2.811568526367196],
            [-40.530867916992186, -2.814168526367197],
            [-40.534267916992185, -2.817368526367197],
            [-40.537067916992186, -2.819368526367199],
            [-40.540967916992187, -2.821368526367195],
            [-40.546967916992187, -2.823668526367193],
            [-40.553567916992186, -2.825668526367195],
            [-40.557967916992183, -2.827068526367199],
            [-40.560767916992184, -2.827668526367198],
            [-40.563767916992184, -2.827668526367198],
            [-40.567167916992183, -2.830768526367194],
            [-40.570267916992186, -2.832868526367193],
            [-40.573767916992182, -2.835068526367195],
            [-40.577567916992187, -2.836768526367194],
            [-40.580467916992184, -2.837868526367195],
            [-40.588667916992186, -2.839768526367195],
            [-40.592567916992188, -2.839268526367199],
            [-40.595967916992187, -2.839868526367198],
            [-40.597867916992186, -2.844068526367195],
            [-40.592867916992184, -2.845968526367194],
            [-40.596267916992183, -2.846968526367199],
            [-40.600567916992183, -2.848368526367196],
            [-40.604567916992188, -2.846268526367197],
            [-40.608567916992186, -2.8420685263672],
            [-40.612167916992185, -2.841268526367195],
            [-40.618367916992185, -2.844568526367198],
            [-40.625167916992183, -2.845968526367194],
            [-40.628067916992187, -2.845968526367194],
            [-40.632467916992184, -2.846968526367199],
            [-40.637367916992183, -2.847568526367198],
            [-40.640767916992182, -2.848868526367198],
            [-40.644267916992185, -2.849168526367194],
            [-40.649867916992186, -2.848968526367194],
            [-40.655067916992188, -2.848368526367196],
            [-40.660867916992188, -2.846368526367193],
            [-40.665667916992184, -2.844568526367198],
            [-40.670267916992188, -2.843668526367196],
            [-40.675267916992183, -2.844868526367193],
            [-40.679967916992183, -2.845668526367199],
            [-40.684767916992186, -2.846268526367197],
            [-40.687467916992183, -2.848468526367199],
            [-40.690767916992186, -2.851168526367196],
            [-40.690467916992183, -2.846968526367199],
            [-40.694867916992187, -2.8467685263672],
            [-40.699567916992187, -2.846968526367199],
            [-40.702167916992188, -2.848368526367196],
            [-40.705667916992184, -2.849268526367197],
            [-40.709567916992185, -2.849468526367197],
            [-40.714467916992184, -2.849268526367197],
            [-40.718467916992182, -2.848968526367194],
            [-40.725967916992182, -2.850268526367195],
            [-40.729467916992185, -2.850868526367194],
            [-40.732867916992184, -2.850968526367197],
            [-40.736867916992182, -2.850668526367194],
            [-40.740567916992184, -2.850368526367198],
            [-40.746967916992183, -2.849468526367197],
            [-40.751667916992183, -2.849468526367197],
            [-40.756667916992185, -2.848768526367195],
            [-40.760967916992186, -2.849168526367194],
            [-40.765367916992183, -2.850268526367195],
            [-40.769867916992183, -2.851168526367196],
            [-40.774067916992188, -2.853068526367196],
            [-40.778167916992182, -2.855568526367193],
            [-40.781467916992185, -2.858268526367198],
            [-40.785167916992187, -2.862168526367199],
            [-40.790667916992184, -2.8664685263672],
            [-40.797267916992183, -2.870068526367199],
            [-40.802167916992182, -2.872768526367196],
            [-40.805767916992188, -2.873868526367197],
            [-40.810767916992184, -2.875468526367193],
            [-40.815267916992184, -2.876568526367194],
            [-40.821267916992184, -2.877368526367199],
            [-40.829867916992185, -2.878368526367197],
            [-40.834667916992188, -2.878768526367196],
            [-40.838167916992184, -2.880168526367193],
            [-40.841667916992186, -2.882368526367195],
            [-40.845067916992186, -2.8848685263672],
            [-40.847667916992187, -2.880068526367197],
            [-40.850567916992183, -2.875468526367193],
            [-40.852867916992182, -2.873368526367194],
            [-40.854167916992182, -2.869368526367197],
            [-40.857167916992182, -2.864468526367197],
            [-40.859667916992187, -2.860268526367193],
            [-40.863267916992186, -2.857168526367197],
            [-40.865767916992183, -2.861468526367197],
            [-40.870867916992182, -2.861668526367197],
            [-40.875467916992186, -2.860368526367196],
            [-40.879467916992184, -2.860768526367195],
            [-40.882667916992183, -2.860668526367199],
            [-40.885467916992184, -2.861368526367194],
            [-40.888567916992187, -2.862268526367195],
            [-40.892367916992185, -2.862268526367195],
            [-40.898467916992182, -2.861868526367196],
            [-40.902667916992186, -2.860068526367193],
            [-40.905867916992186, -2.859768526367198],
            [-40.906567916992188, -2.864168526367195],
            [-40.908367916992184, -2.868668526367195],
            [-40.912067916992186, -2.871868526367194],
            [-40.915267916992185, -2.873068526367199],
            [-40.919267916992183, -2.874068526367196],
            [-40.922467916992183, -2.874368526367199],
            [-40.925767916992186, -2.874368526367199],
            [-40.932167916992185, -2.874068526367196],
            [-40.937667916992183, -2.874068526367196],
            [-40.942767916992182, -2.873268526367198],
            [-40.946767916992187, -2.872368526367197],
            [-40.950467916992189, -2.871668526367195],
            [-40.955767916992187, -2.872468526367193],
            [-40.958967916992187, -2.876868526367197],
            [-40.962867916992188, -2.880268526367196],
            [-40.966167916992184, -2.881668526367193],
            [-40.969767916992183, -2.882468526367198],
            [-40.972867916992186, -2.883168526367193],
            [-40.975967916992182, -2.883468526367196],
            [-40.980567916992186, -2.884368526367197],
            [-40.987167916992185, -2.884068526367194],
            [-40.991967916992188, -2.884168526367198],
            [-40.995467916992183, -2.884968526367196],
            [-40.999867916992187, -2.887068526367194],
            [-41.001667916992183, -2.889268526367196],
            [-41.006067916992187, -2.890668526367193],
            [-41.010267916992184, -2.891468526367198],
            [-41.015967916992182, -2.892168526367193],
            [-41.022067916992185, -2.891768526367194],
            [-41.025067916992185, -2.890968526367196],
            [-41.029467916992182, -2.891768526367194],
            [-41.034567916992188, -2.891468526367198],
            [-41.038167916992187, -2.892668526367196],
            [-41.041467916992183, -2.892968526367198],
            [-41.046067916992186, -2.894068526367199],
            [-41.054167916992185, -2.893968526367196],
            [-41.059467916992183, -2.894368526367195],
            [-41.065267916992184, -2.894868526367198],
            [-41.068167916992188, -2.895068526367197],
            [-41.077867916992183, -2.894568526367195],
            [-41.083467916992184, -2.894568526367195],
            [-41.087667916992189, -2.893968526367196],
            [-41.091767916992183, -2.892668526367196],
            [-41.094867916992186, -2.891068526367199],
            [-41.098167916992182, -2.890668526367193],
            [-41.100967916992182, -2.891768526367194],
            [-41.105267916992183, -2.890968526367196],
            [-41.108167916992187, -2.890768526367197],
            [-41.106667916992187, -2.894568526367195],
            [-41.104167916992182, -2.898668526367196],
            [-41.108967916992185, -2.897268526367199],
            [-41.111367916992187, -2.894768526367194],
            [-41.112967916992183, -2.891768526367194],
            [-41.113667916992185, -2.888268526367199],
            [-41.116567916992182, -2.885468526367198],
            [-41.118267916992188, -2.888168526367195],
            [-41.121167916992185, -2.890068526367195],
            [-41.124167916992185, -2.891868526367197],
            [-41.128867916992185, -2.892568526367199],
            [-41.133167916992186, -2.891868526367197],
            [-41.137967916992189, -2.892068526367197],
            [-41.143567916992183, -2.891568526367195],
            [-41.147967916992187, -2.891768526367194],
            [-41.154267916992183, -2.890768526367197],
            [-41.159467916992185, -2.889568526367199],
            [-41.162767916992188, -2.8893685263672],
            [-41.165567916992188, -2.888468526367198],
            [-41.168867916992184, -2.888268526367199],
            [-41.174167916992182, -2.886868526367195],
            [-41.177467916992185, -2.886468526367196],
            [-41.181167916992187, -2.886768526367199],
            [-41.184067916992184, -2.888968526367194],
            [-41.187467916992183, -2.890768526367197],
            [-41.191867916992187, -2.890368526367197],
            [-41.196767916992187, -2.889068526367197],
            [-41.200167916992186, -2.888168526367195],
            [-41.204267916992187, -2.890068526367195],
            [-41.208667916992184, -2.890368526367197],
            [-41.216967916992182, -2.889268526367196],
            [-41.224867916992189, -2.8878685263672],
            [-41.232767916992188, -2.889268526367196],
            [-41.238067916992186, -2.888968526367194],
            [-41.243267916992188, -2.888168526367195],
            [-41.246267916992188, -2.887468526367194],
            [-41.251667916992183, -2.886568526367199],
            [-41.256367916992183, -2.8848685263672],
            [-41.259567916992182, -2.883768526367199],
            [-41.262967916992189, -2.884068526367194],
            [-41.265667916992186, -2.884968526367196],
            [-41.270367916992186, -2.886768526367199],
            [-41.273667916992189, -2.889268526367196],
            [-41.276467916992182, -2.891768526367194],
            [-41.279267916992183, -2.894568526367195],
            [-41.283967916992182, -2.899268526367194],
            [-41.287067916992186, -2.901868526367195],
            [-41.290267916992185, -2.903768526367195],
            [-41.293867916992184, -2.906168526367196],
            [-41.297467916992183, -2.907868526367196],
            [-41.300467916992183, -2.909468526367199],
            [-41.303867916992182, -2.911368526367198],
            [-41.309167916992187, -2.913968526367199],
            [-41.322767916992184, -2.921368526367196],
            [-41.329067916992187, -2.923968526367197],
            [-41.332867916992186, -2.924968526367195],
            [-41.336467916992184, -2.925868526367196],
            [-41.341267916992187, -2.926868526367194],
            [-41.346667916992182, -2.923868526367194],
            [-41.349467916992182, -2.922868526367196],
            [-41.356667916992187, -2.921168526367197],
            [-41.360367916992182, -2.920368526367199],
            [-41.363667916992185, -2.918068526367193],
            [-41.368367916992185, -2.917768526367198],
            [-41.373267916992184, -2.917868526367194],
            [-41.376667916992183, -2.916168526367194],
            [-41.380967916992184, -2.914168526367199],
            [-41.383767916992184, -2.912768526367195],
            [-41.386867916992188, -2.910068526367198],
            [-41.390367916992183, -2.907668526367196],
            [-41.393767916992182, -2.906168526367196],
            [-41.399067916992188, -2.904468526367197],
            [-41.403367916992188, -2.906168526367196],
            [-41.407267916992183, -2.906568526367195],
            [-41.411267916992188, -2.908368526367198],
            [-41.416467916992183, -2.909568526367195],
            [-41.420867916992187, -2.909768526367195],
            [-41.423967916992183, -2.909468526367199],
            [-41.427467916992185, -2.908868526367193],
            [-41.431567916992186, -2.907568526367193],
            [-41.435567916992184, -2.906168526367196],
            [-41.438867916992187, -2.906168526367196],
            [-41.441567916992184, -2.907268526367197],
            [-41.447367916992185, -2.906168526367196],
            [-41.451767916992182, -2.903368526367196],
            [-41.455367916992188, -2.900968526367194],
            [-41.466167916992184, -2.9000685263672],
            [-41.471167916992187, -2.899068526367195],
            [-41.474767916992185, -2.897368526367195],
            [-41.478967916992183, -2.895968526367199],
            [-41.482867916992184, -2.897868526367198],
            [-41.486967916992185, -2.898668526367196],
            [-41.491167916992183, -2.899568526367197],
            [-41.494767916992188, -2.901168526367194],
            [-41.498267916992184, -2.904168526367194],
            [-41.501567916992187, -2.905968526367197],
            [-41.504067916992184, -2.907368526367193],
            [-41.507367916992187, -2.910268526367197],
            [-41.511067916992182, -2.911668526367194],
            [-41.515767916992182, -2.911668526367194],
            [-41.519067916992185, -2.911168526367199],
            [-41.522867916992183, -2.910968526367199],
            [-41.527367916992183, -2.910968526367199],
            [-41.530167916992184, -2.910368526367193],
            [-41.533367916992184, -2.909568526367195],
            [-41.536267916992188, -2.908368526367198],
            [-41.539967916992182, -2.906268526367199],
            [-41.543667916992185, -2.904168526367194],
            [-41.547467916992183, -2.902268526367195],
            [-41.552167916992182, -2.900368526367195],
            [-41.555067916992186, -2.898968526367199],
            [-41.558267916992186, -2.897668526367198],
            [-41.560567916992184, -2.900668526367198],
            [-41.564167916992183, -2.9015685263672],
            [-41.567367916992183, -2.902668526367194],
            [-41.571567916992187, -2.902668526367194],
            [-41.575267916992182, -2.905068526367195],
            [-41.578967916992184, -2.906468526367199],
            [-41.583667916992184, -2.906468526367199],
            [-41.586867916992183, -2.905968526367197],
            [-41.590067916992183, -2.905368526367198],
            [-41.593367916992186, -2.904468526367197],
            [-41.597867916992186, -2.902568526367197],
            [-41.602767916992185, -2.900468526367199],
            [-41.606167916992185, -2.899068526367195],
            [-41.612467916992188, -2.896468526367194],
            [-41.616167916992183, -2.894068526367199],
            [-41.619067916992186, -2.892468526367196],
            [-41.622667916992185, -2.890668526367193],
            [-41.625567916992182, -2.888568526367195],
            [-41.628767916992182, -2.886768526367199],
            [-41.632767916992186, -2.883768526367199],
            [-41.635767916992187, -2.881068526367194],
            [-41.638467916992184, -2.878468526367193],
            [-41.641467916992184, -2.875468526367193],
            [-41.643967916992182, -2.872768526367196],
            [-41.648167916992186, -2.867968526367193],
            [-41.649767916992182, -2.863668526367199],
            [-41.652267916992187, -2.861068526367198],
            [-41.655167916992184, -2.860068526367193],
            [-41.659767916992188, -2.858568526367193],
            [-41.665567916992188, -2.856668526367194],
            [-41.669967916992185, -2.854568526367196],
            [-41.674367916992182, -2.851768526367195],
            [-41.677167916992182, -2.850068526367195],
            [-41.680767916992188, -2.847568526367198],
            [-41.683667916992185, -2.845068526367193],
            [-41.688267916992189, -2.841168526367198],
            [-41.691867916992187, -2.838168526367198],
            [-41.694667916992188, -2.835768526367197],
            [-41.698467916992186, -2.832368526367198],
            [-41.701767916992182, -2.829568526367197],
            [-41.704567916992183, -2.826768526367196],
            [-41.706767916992185, -2.824568526367194],
            [-41.709567916992185, -2.821868526367197],
            [-41.714467916992184, -2.817468526367193],
            [-41.719467916992187, -2.812668526367197],
            [-41.723767916992188, -2.808768526367196],
            [-41.727067916992183, -2.805268526367193],
            [-41.730067916992184, -2.803668526367197],
            [-41.731467916992187, -2.807168526367199],
            [-41.733967916992185, -2.808568526367196],
            [-41.737567916992184, -2.808368526367197],
            [-41.740767916992183, -2.807468526367195],
            [-41.743367916992185, -2.806068526367199],
            [-41.746367916992185, -2.804168526367199],
            [-41.749067916992182, -2.802268526367193],
            [-41.751367916992187, -2.800568526367194],
            [-41.755167916992185, -2.796968526367195],
            [-41.758467916992188, -2.793968526367195],
            [-41.760767916992187, -2.7917685263672],
            [-41.763467916992184, -2.789168526367199],
            [-41.765667916992186, -2.786468526367194],
            [-41.768267916992187, -2.783368526367198],
            [-41.770667916992188, -2.780168526367198],
            [-41.772567916992188, -2.776968526367199],
            [-41.773967916992184, -2.774268526367194],
            [-41.775767916992187, -2.771468526367194],
            [-41.777567916992183, -2.767868526367195],
            [-41.779267916992183, -2.764068526367197],
            [-41.780667916992186, -2.760768526367194],
            [-41.783367916992184, -2.757068526367199],
            [-41.787067916992186, -2.754868526367197],
            [-41.790667916992184, -2.752668526367195],
            [-41.793867916992184, -2.750168526367197],
            [-41.797467916992183, -2.749768526367198],
            [-41.801867916992187, -2.750168526367197],
            [-41.805067916992186, -2.751268526367198],
            [-41.808367916992182, -2.753368526367197],
            [-41.811867916992185, -2.756368526367197],
            [-41.815167916992188, -2.758568526367199],
            [-41.817967916992188, -2.759668526367193],
            [-41.820967916992188, -2.760768526367194],
            [-41.825967916992184, -2.757368526367195],
            [-41.829567916992183, -2.753768526367196],
            [-41.826767916992182, -2.750168526367197],
            [-41.824967916992186, -2.747368526367197],
            [-41.822067916992182, -2.744968526367195],
            [-41.817767916992182, -2.741668526367199],
            [-41.814167916992183, -2.739468526367197],
            [-41.810467916992188, -2.737968526367197],
            [-41.807767916992184, -2.736868526367196],
            [-41.804467916992188, -2.735668526367199],
            [-41.804367916992184, -2.732268526367193],
            [-41.807267916992188, -2.730068526367198],
            [-41.811867916992185, -2.728368526367198],
            [-41.817367916992183, -2.726968526367195],
            [-41.822367916992185, -2.725968526367197],
            [-41.827567916992187, -2.725368526367198],
            [-41.830467916992184, -2.724868526367196],
            [-41.833667916992184, -2.724168526367194],
            [-41.838367916992183, -2.723668526367199],
            [-41.842367916992188, -2.723468526367199],
            [-41.845967916992187, -2.723068526367193],
            [-41.850567916992183, -2.722368526367198],
            [-41.855567916992186, -2.721968526367199],
            [-41.858867916992182, -2.721468526367197],
            [-41.861767916992186, -2.721668526367196],
            [-41.865267916992188, -2.721668526367196],
            [-41.869467916992186, -2.721168526367194],
            [-41.874067916992182, -2.720868526367198],
            [-41.879067916992184, -2.7202685263672],
            [-41.882067916992185, -2.7202685263672],
            [-41.885267916992184, -2.720068526367193],
            [-41.889067916992182, -2.719768526367197],
            [-41.892067916992183, -2.719768526367197],
            [-41.895667916992188, -2.719568526367198],
            [-41.900067916992185, -2.719468526367194],
            [-41.905067916992188, -2.719268526367195],
            [-41.910667916992182, -2.718768526367199],
            [-41.915567916992188, -2.718968526367199],
            [-41.918867916992184, -2.719068526367195],
            [-41.922567916992186, -2.718968526367199],
            [-41.926567916992184, -2.719068526367195],
            [-41.931167916992187, -2.718668526367196],
            [-41.936567916992182, -2.718468526367197],
            [-41.941267916992182, -2.718668526367196],
            [-41.945567916992182, -2.718768526367199],
            [-41.949967916992186, -2.718768526367199],
            [-41.953567916992185, -2.718668526367196],
            [-41.959067916992183, -2.718668526367196],
            [-41.963967916992182, -2.718968526367199],
            [-41.967067916992185, -2.719168526367199],
            [-41.970267916992185, -2.719468526367194],
            [-41.973167916992182, -2.720568526367195],
            [-41.976667916992184, -2.722268526367195],
            [-41.982467916992185, -2.723068526367193],
            [-41.987267916992188, -2.722768526367197],
            [-41.990367916992184, -2.721968526367199],
            [-41.994667916992185, -2.720868526367198],
            [-41.998067916992184, -2.719768526367197],
            [-42.002067916992182, -2.717368526367196],
            [-42.007467916992184, -2.714568526367195],
            [-42.012667916992186, -2.712868526367195],
            [-42.016867916992183, -2.712868526367195],
            [-42.019367916992188, -2.715168526367194],
            [-42.020067916992183, -2.719568526367198],
            [-42.019567916992187, -2.723068526367193],
            [-42.018267916992187, -2.725668526367194],
            [-42.016567916992187, -2.728368526367198],
            [-42.016067916992185, -2.7324685263672],
            [-42.016467916992184, -2.736468526367197],
            [-42.031567916992188, -2.739068526367198],
            [-42.034467916992185, -2.737168526367199],
            [-42.037267916992185, -2.734768526367198],
            [-42.039967916992182, -2.732168526367197],
            [-42.042467916992187, -2.730068526367198],
            [-42.044267916992183, -2.727468526367197],
            [-42.046067916992186, -2.723468526367199],
            [-42.047867916992182, -2.719768526367197],
            [-42.049967916992188, -2.716168526367198],
            [-42.051967916992183, -2.712868526367195],
            [-42.054167916992185, -2.709268526367197],
            [-42.055267916992186, -2.705668526367198],
            [-42.055867916992185, -2.700768526367199],
            [-42.057767916992184, -2.697868526367195],
            [-42.060767916992184, -2.695268526367194],
            [-42.063067916992182, -2.692768526367196],
            [-42.066267916992182, -2.689868526367199],
            [-42.069167916992185, -2.687668526367197],
            [-42.072867916992188, -2.687668526367197],
            [-42.077567916992187, -2.688868526367195],
            [-42.081867916992188, -2.690568526367194],
            [-42.086967916992187, -2.691868526367195],
            [-42.092367916992188, -2.692968526367196],
            [-42.095667916992184, -2.693468526367198],
            [-42.099567916992186, -2.693468526367198],
            [-42.104267916992185, -2.694068526367197],
            [-42.108067916992184, -2.694568526367199],
            [-42.111067916992184, -2.694868526367195],
            [-42.115267916992188, -2.694868526367195],
            [-42.118667916992187, -2.694968526367198],
            [-42.122267916992186, -2.694868526367195],
            [-42.125467916992186, -2.694668526367195],
            [-42.128767916992182, -2.695168526367198],
            [-42.132067916992185, -2.694868526367195],
            [-42.135467916992184, -2.694868526367195],
            [-42.139667916992188, -2.694268526367196],
            [-42.142967916992184, -2.694268526367196],
            [-42.146467916992187, -2.693768526367194],
            [-42.150367916992188, -2.693468526367198],
            [-42.154267916992183, -2.693068526367199],
            [-42.159267916992185, -2.692668526367193],
            [-42.162267916992185, -2.692168526367198],
            [-42.165667916992184, -2.691868526367195],
            [-42.168867916992184, -2.691368526367199],
            [-42.171867916992184, -2.691268526367196],
            [-42.174967916992188, -2.690768526367194],
            [-42.177967916992188, -2.690268526367198],
            [-42.181667916992183, -2.689468526367193],
            [-42.186367916992182, -2.688868526367195],
            [-42.189567916992182, -2.688468526367195],
            [-42.193867916992183, -2.687768526367194],
            [-42.197767916992184, -2.687968526367193],
            [-42.200967916992184, -2.687768526367194],
            [-42.204367916992183, -2.687468526367198],
            [-42.208267916992185, -2.687368526367194],
            [-42.211767916992187, -2.686968526367195],
            [-42.215667916992182, -2.687668526367197],
            [-42.218667916992182, -2.688868526367195],
            [-42.221167916992187, -2.691868526367195],
            [-42.229467916992185, -2.696368526367195],
            [-42.231667916992187, -2.694168526367193],
            [-42.233267916992183, -2.691368526367199],
            [-42.235267916992186, -2.688768526367198],
            [-42.237467916992188, -2.685768526367198],
            [-42.239967916992185, -2.683368526367197],
            [-42.243667916992187, -2.680568526367196],
            [-42.247767916992188, -2.678668526367197],
            [-42.250967916992188, -2.677268526367193],
            [-42.254167916992188, -2.676068526367196],
            [-42.257167916992188, -2.676068526367196],
            [-42.257067916992185, -2.6789685263672],
            [-42.255567916992185, -2.682668526367195],
            [-42.257067916992185, -2.685768526367198],
            [-42.259867916992185, -2.689368526367197],
            [-42.259567916992182, -2.692968526367196],
            [-42.258567916992185, -2.702068526367199],
            [-42.259167916992183, -2.7048685263672],
            [-42.259867916992185, -2.708468526367199],
            [-42.260167916992188, -2.713668526367194],
            [-42.260467916992184, -2.717668526367198],
            [-42.261067916992182, -2.721668526367196],
            [-42.262367916992183, -2.725568526367198],
            [-42.262367916992183, -2.728868526367194],
            [-42.260467916992184, -2.732568526367196],
            [-42.256267916992186, -2.735768526367195],
            [-42.252667916992188, -2.738068526367194],
            [-42.264867916992188, -2.757068526367199],
            [-42.269367916992188, -2.755268526367196],
            [-42.272967916992187, -2.753868526367199],
            [-42.276167916992186, -2.753068526367194],
            [-42.280167916992184, -2.752768526367198],
            [-42.283967916992182, -2.753068526367194],
            [-42.286767916992183, -2.754068526367199],
            [-42.292467916992187, -2.753868526367199],
            [-42.295367916992184, -2.754168526367195],
            [-42.299367916992182, -2.754368526367195],
            [-42.303567916992186, -2.754368526367195],
            [-42.307667916992187, -2.754168526367195],
            [-42.310767916992184, -2.754068526367199],
            [-42.314167916992183, -2.753568526367197],
            [-42.318067916992185, -2.753068526367194],
            [-42.322367916992185, -2.752368526367199],
            [-42.326767916992182, -2.751568526367194],
            [-42.331467916992182, -2.750568526367196],
            [-42.336467916992184, -2.749468526367195],
            [-42.339267916992185, -2.748768526367193],
            [-42.341967916992182, -2.7476685263672],
            [-42.345667916992184, -2.745268526367198],
            [-42.349167916992187, -2.742468526367198],
            [-42.352067916992183, -2.7399685263672],
            [-42.354167916992182, -2.736868526367196],
            [-42.357467916992185, -2.733668526367197],
            [-42.361367916992187, -2.7292685263672],
            [-42.362567916992184, -2.724468526367197],
            [-42.363667916992185, -2.718968526367199],
            [-42.364667916992182, -2.715468526367196],
            [-42.369367916992182, -2.7140685263672],
            [-42.374467916992188, -2.715068526367197],
            [-42.378767916992182, -2.715468526367196],
            [-42.382667916992183, -2.715368526367193],
            [-42.386067916992182, -2.714768526367195],
            [-42.389267916992182, -2.713968526367196],
            [-42.392167916992186, -2.712368526367193],
            [-42.395667916992188, -2.711568526367195],
            [-42.400367916992188, -2.712668526367196],
            [-42.404567916992185, -2.713468526367194],
            [-42.408467916992187, -2.713968526367196],
            [-42.413167916992187, -2.7140685263672],
            [-42.417767916992183, -2.7140685263672],
            [-42.421467916992185, -2.713968526367196],
            [-42.425467916992183, -2.713168526367198],
            [-42.430567916992182, -2.7125685263672],
            [-42.434867916992182, -2.711768526367194],
            [-42.438767916992184, -2.711168526367196],
            [-42.441867916992187, -2.710668526367193],
            [-42.446367916992187, -2.709768526367199],
            [-42.450167916992186, -2.708968526367194],
            [-42.454367916992183, -2.707868526367193],
            [-42.458667916992184, -2.706768526367199],
            [-42.461767916992187, -2.705968526367194],
            [-42.465167916992186, -2.7048685263672],
            [-42.468967916992185, -2.703168526367193],
            [-42.472067916992188, -2.701768526367196],
            [-42.475967916992182, -2.699668526367198],
            [-42.481467916992187, -2.699568526367194],
            [-42.485667916992185, -2.7003685263672],
            [-42.487167916992185, -2.703268526367197],
            [-42.489967916992185, -2.703768526367199],
            [-42.494067916992186, -2.703568526367199],
            [-42.495767916992186, -2.700668526367195],
            [-42.496867916992187, -2.697868526367195],
            [-42.497667916992185, -2.692668526367193],
            [-42.498267916992184, -2.687668526367197],
            [-42.499367916992185, -2.684068526367199],
            [-42.501267916992184, -2.680268526367193],
            [-42.504067916992184, -2.678568526367194],
            [-42.507667916992183, -2.678568526367194],
            [-42.510667916992183, -2.679368526367199],
            [-42.513967916992186, -2.680168526367197],
            [-42.518467916992186, -2.680868526367199],
            [-42.523667916992189, -2.681168526367195],
            [-42.527967916992182, -2.681368526367194],
            [-42.531967916992187, -2.681368526367194],
            [-42.535867916992188, -2.681168526367195],
            [-42.538867916992189, -2.680768526367196],
            [-42.543067916992186, -2.680468526367193],
            [-42.546867916992184, -2.679968526367198],
            [-42.550067916992184, -2.679468526367195],
            [-42.554367916992184, -2.678668526367197],
            [-42.558267916992186, -2.678668526367197],
            [-42.561967916992188, -2.678568526367194],
            [-42.568267916992184, -2.676668526367195],
            [-42.573467916992186, -2.675068526367198],
            [-42.577967916992186, -2.673368526367199],
            [-42.582867916992186, -2.671068526367193],
            [-42.587267916992182, -2.668968526367195],
            [-42.590367916992186, -2.666468526367197],
            [-42.593767916992185, -2.6637685263672],
            [-42.598067916992186, -2.661268526367195],
            [-42.603167916992184, -2.657868526367196],
            [-42.606667916992187, -2.655468526367194],
            [-42.610267916992186, -2.652868526367193],
            [-42.612567916992184, -2.650868526367198],
            [-42.616867916992184, -2.647868526367198],
            [-42.620867916992182, -2.645068526367197],
            [-42.624367916992185, -2.641768526367194],
            [-42.627467916992188, -2.638568526367195],
            [-42.630967916992184, -2.635268526367199],
            [-42.634667916992186, -2.631768526367196],
            [-42.639267916992182, -2.627668526367195],
            [-42.641567916992187, -2.625568526367196],
            [-42.643767916992182, -2.623268526367198],
            [-42.647067916992185, -2.620268526367198],
            [-42.650367916992188, -2.617268526367198],
            [-42.653667916992184, -2.614368526367194],
            [-42.657567916992186, -2.610768526367195],
            [-42.660067916992183, -2.608268526367198],
            [-42.661967916992182, -2.606168526367199],
            [-42.664467916992187, -2.603568526367198],
            [-42.667867916992186, -2.599968526367199],
            [-42.671367916992182, -2.596668526367196],
            [-42.674667916992185, -2.593468526367197],
            [-42.678267916992183, -2.590068526367197],
            [-42.680467916992185, -2.587968526367199],
            [-42.682667916992187, -2.585868526367193],
            [-42.685167916992185, -2.583968526367194],
            [-42.687967916992186, -2.581468526367196],
            [-42.690467916992183, -2.579068526367195],
            [-42.693267916992184, -2.576468526367194],
            [-42.696367916992187, -2.573568526367197],
            [-42.699567916992187, -2.571068526367199],
            [-42.702067916992185, -2.568868526367197],
            [-42.704367916992183, -2.567168526367198],
            [-42.708467916992184, -2.564168526367197],
            [-42.711567916992188, -2.562168526367195],
            [-42.714067916992185, -2.560568526367199],
            [-42.717667916992184, -2.558568526367196],
            [-42.721367916992186, -2.556668526367197],
            [-42.726467916992185, -2.554768526367198],
            [-42.730267916992183, -2.554068526367196],
            [-42.733567916992186, -2.553568526367194],
            [-42.737467916992188, -2.554368526367199],
            [-42.740067916992189, -2.555868526367199],
            [-42.742167916992187, -2.558268526367193],
            [-42.745567916992186, -2.559868526367197],
            [-42.749367916992185, -2.5601685263672],
            [-42.751667916992183, -2.556868526367197],
            [-42.754867916992183, -2.553868526367197],
            [-42.757767916992186, -2.550268526367198],
            [-42.760667916992183, -2.546968526367195],
            [-42.763867916992183, -2.545368526367199],
            [-42.768567916992183, -2.546368526367196],
            [-42.772567916992188, -2.547568526367193],
            [-42.777267916992187, -2.547868526367196],
            [-42.780167916992184, -2.547768526367193],
            [-42.783967916992182, -2.547868526367196],
            [-42.790567916992188, -2.547768526367193],
            [-42.795367916992184, -2.547568526367193],
            [-42.800067916992184, -2.547268526367198],
            [-42.803967916992185, -2.547568526367193],
            [-42.808067916992186, -2.548068526367196],
            [-42.812167916992188, -2.547868526367196],
            [-42.817167916992183, -2.546368526367196],
            [-42.821667916992183, -2.545368526367199],
            [-42.825267916992182, -2.544668526367197],
            [-42.828567916992185, -2.543168526367197],
            [-42.832867916992186, -2.540968526367195],
            [-42.836767916992187, -2.538968526367199],
            [-42.840067916992183, -2.536968526367197],
            [-42.844467916992187, -2.534468526367199],
            [-42.848367916992188, -2.532568526367193],
            [-42.852467916992182, -2.530168526367198],
            [-42.864967916992185, -2.524768526367197],
            [-42.867967916992185, -2.523368526367193],
            [-42.871367916992185, -2.521468526367194],
            [-42.875167916992183, -2.519068526367199],
            [-42.878067916992187, -2.517568526367199],
            [-42.881567916992182, -2.515368526367197],
            [-42.885467916992184, -2.513268526367199],
            [-42.888467916992184, -2.511768526367199],
            [-42.892067916992183, -2.510968526367193],
            [-42.896167916992184, -2.509968526367196],
            [-42.900067916992185, -2.507768526367194],
            [-42.903967916992187, -2.505968526367198],
            [-42.906767916992187, -2.504568526367194],
            [-42.910567916992186, -2.502468526367196],
            [-42.914967916992182, -2.499368526367199],
            [-42.918867916992184, -2.496968526367198],
            [-42.921467916992185, -2.495768526367193],
            [-42.924367916992182, -2.494468526367193],
            [-42.928567916992186, -2.492268526367198],
            [-42.932767916992184, -2.490068526367196],
            [-42.937667916992183, -2.487968526367197],
            [-42.942767916992182, -2.485368526367196],
            [-42.947067916992182, -2.483368526367194],
            [-42.950967916992184, -2.481668526367194],
            [-42.955367916992188, -2.479168526367197],
            [-42.958667916992184, -2.4777685263672],
            [-42.961567916992188, -2.476368526367196],
            [-42.964267916992185, -2.474868526367196],
            [-42.968967916992185, -2.474468526367197],
            [-42.973367916992188, -2.473768526367195],
            [-42.977067916992183, -2.472068526367195],
            [-42.979967916992187, -2.470968526367194],
            [-42.982767916992188, -2.469768526367197],
            [-42.987267916992188, -2.467668526367198],
            [-42.991067916992186, -2.465868526367196],
            [-42.996367916992185, -2.463668526367194],
            [-43.000267916992186, -2.461968526367194],
            [-43.005667916992188, -2.460468526367194],
            [-43.008467916992188, -2.459368526367193],
            [-43.011567916992185, -2.458168526367196],
            [-43.015367916992183, -2.456568526367199],
            [-43.019867916992183, -2.454368526367197],
            [-43.025467916992184, -2.452068526367199],
            [-43.029467916992182, -2.449968526367194],
            [-43.033067916992188, -2.448768526367196],
            [-43.036967916992182, -2.447068526367197],
            [-43.040267916992185, -2.445668526367193],
            [-43.044467916992183, -2.443768526367194],
            [-43.048867916992187, -2.442368526367197],
            [-43.051867916992187, -2.441368526367199],
            [-43.054767916992184, -2.440168526367195],
            [-43.057467916992188, -2.438868526367195],
            [-43.060467916992188, -2.437668526367197],
            [-43.064067916992187, -2.435868526367194],
            [-43.066967916992184, -2.434368526367194],
            [-43.073467916992186, -2.431168526367195],
            [-43.079267916992187, -2.427768526367196],
            [-43.084267916992182, -2.425268526367198],
            [-43.091467916992187, -2.421668526367199],
            [-43.096967916992185, -2.419168526367194],
            [-43.100967916992182, -2.417268526367195],
            [-43.104667916992184, -2.415668526367199],
            [-43.107767916992188, -2.414268526367195],
            [-43.110867916992184, -2.413068526367198],
            [-43.114667916992182, -2.412068526367193],
            [-43.120467916992183, -2.411168526367199],
            [-43.124467916992188, -2.4092685263672],
            [-43.127167916992185, -2.408168526367199],
            [-43.130167916992185, -2.406968526367194],
            [-43.135967916992186, -2.404468526367197],
            [-43.141067916992185, -2.401968526367199],
            [-43.144367916992188, -2.400368526367195],
            [-43.148367916992186, -2.398768526367199],
            [-43.152667916992186, -2.3970685263672],
            [-43.155967916992182, -2.395468526367196],
            [-43.159267916992185, -2.393468526367194],
            [-43.162267916992185, -2.391868526367197],
            [-43.165367916992182, -2.390068526367195],
            [-43.168367916992182, -2.388468526367198],
            [-43.171867916992184, -2.386568526367199],
            [-43.175467916992183, -2.3848685263672],
            [-43.178267916992183, -2.383768526367199],
            [-43.181967916992186, -2.382368526367195],
            [-43.185167916992185, -2.380668526367195],
            [-43.189167916992183, -2.378268526367194],
            [-43.193767916992186, -2.376068526367199],
            [-43.200767916992184, -2.372968526367195],
            [-43.208267916992185, -2.370168526367195],
            [-43.215167916992186, -2.367768526367193],
            [-43.222267916992188, -2.365068526367196],
            [-43.225567916992183, -2.364068526367198],
            [-43.228567916992183, -2.362868526367194],
            [-43.231667916992187, -2.361368526367194],
            [-43.234667916992187, -2.359768526367198],
            [-43.238567916992189, -2.358368526367194],
            [-43.243567916992184, -2.357068526367193],
            [-43.247667916992185, -2.355968526367199],
            [-43.251067916992184, -2.354968526367195],
            [-43.255167916992185, -2.353968526367197],
            [-43.258867916992187, -2.353068526367196],
            [-43.262967916992189, -2.351968526367195],
            [-43.267967916992184, -2.350568526367198],
            [-43.272367916992188, -2.349468526367197],
            [-43.276267916992182, -2.348168526367196],
            [-43.281267916992185, -2.346268526367197],
            [-43.285967916992185, -2.344868526367193],
            [-43.290667916992184, -2.343368526367193],
            [-43.296067916992186, -2.342268526367199],
            [-43.299967916992188, -2.341268526367195],
            [-43.302967916992188, -2.340868526367196],
            [-43.306567916992186, -2.340068526367197],
            [-43.310267916992188, -2.338968526367196],
            [-43.314667916992185, -2.338168526367198],
            [-43.317467916992186, -2.337668526367196],
            [-43.321667916992183, -2.337068526367197],
            [-43.326367916992183, -2.336768526367194],
            [-43.331067916992183, -2.336768526367194],
            [-43.333967916992187, -2.338268526367195],
            [-43.337567916992185, -2.338968526367196],
            [-43.340867916992188, -2.339868526367198],
            [-43.344867916992186, -2.340068526367197],
            [-43.348367916992188, -2.340168526367194],
            [-43.352567916992186, -2.340068526367197],
            [-43.358367916992187, -2.339768526367195],
            [-43.363567916992189, -2.339568526367195],
            [-43.368067916992182, -2.339268526367199],
            [-43.371567916992184, -2.339268526367199],
            [-43.375167916992183, -2.340068526367197],
            [-43.377367916992185, -2.343668526367196],
            [-43.385967916992186, -2.344568526367198],
            [-43.388267916992184, -2.341568526367197],
            [-43.390667916992186, -2.338268526367195],
            [-43.395467916992182, -2.336868526367198],
            [-43.399767916992182, -2.335868526367193],
            [-43.403967916992187, -2.335468526367194],
            [-43.408767916992183, -2.336768526367194],
            [-43.413467916992182, -2.3390685263672],
            [-43.416467916992183, -2.340368526367193],
            [-43.421067916992186, -2.341268526367195],
            [-43.425067916992184, -2.342268526367199],
            [-43.429767916992184, -2.343668526367196],
            [-43.435167916992185, -2.342268526367199],
            [-43.440267916992184, -2.341968526367197],
            [-43.443767916992186, -2.344568526367198],
            [-43.447067916992182, -2.346668526367196],
            [-43.452067916992185, -2.348368526367196],
            [-43.456567916992185, -2.349568526367193],
            [-43.460767916992182, -2.350968526367197],
            [-43.461467916992184, -2.354968526367195],
            [-43.462867916992188, -2.357768526367195],
            [-43.467067916992185, -2.359668526367194],
            [-43.469867916992186, -2.361168526367194],
            [-43.472667916992187, -2.362768526367198],
            [-43.475967916992182, -2.3649685263672],
            [-43.478467916992187, -2.366868526367199],
            [-43.481367916992184, -2.369768526367196],
            [-43.483267916992183, -2.373268526367198],
            [-43.484167916992185, -2.378068526367194],
            [-43.483567916992186, -2.384368526367197],
            [-43.480667916992182, -2.389868526367195],
            [-43.468067916992183, -2.412068526367193],
            [-43.466267916992187, -2.415668526367199],
            [-43.463167916992184, -2.423568526367198],
            [-43.460367916992183, -2.429968526367198],
            [-43.457567916992183, -2.434668526367197],
            [-43.456167916992186, -2.437468526367198],
            [-43.452167916992188, -2.443268526367198],
            [-43.454667916992186, -2.446768526367194],
            [-43.456467916992182, -2.449968526367194],
            [-43.456167916992186, -2.453468526367196],
            [-43.454667916992186, -2.457268526367194],
            [-43.454267916992187, -2.461168526367196],
            [-43.453967916992184, -2.472668526367194],
            [-43.454667916992186, -2.476768526367195],
            [-43.456267916992182, -2.481768526367198],
            [-43.458167916992188, -2.484168526367199],
            [-43.461467916992184, -2.486868526367196],
            [-43.465967916992184, -2.487568526367198],
            [-43.469567916992183, -2.487968526367197],
            [-43.474467916992182, -2.487768526367198],
            [-43.479167916992182, -2.486868526367196],
            [-43.482867916992184, -2.485768526367195],
            [-43.485867916992184, -2.4839685263672],
            [-43.488567916992189, -2.481068526367196],
            [-43.492767916992186, -2.479668526367199],
            [-43.500267916992186, -2.478668526367194],
            [-43.506067916992187, -2.4777685263672],
            [-43.509067916992187, -2.475568526367198],
            [-43.511267916992182, -2.470568526367195],
            [-43.513467916992184, -2.466168526367198],
            [-43.517367916992185, -2.462968526367199],
            [-43.518967916992182, -2.460168526367198],
            [-43.520367916992186, -2.457568526367197],
            [-43.520367916992186, -2.453568526367199],
            [-43.518767916992182, -2.449868526367197],
            [-43.516867916992183, -2.446768526367194],
            [-43.513267916992184, -2.443868526367197],
            [-43.511467916992189, -2.440768526367194],
            [-43.512367916992183, -2.437168526367195],
            [-43.514367916992185, -2.433568526367196],
            [-43.514667916992188, -2.429668526367195],
            [-43.515767916992182, -2.425268526367198],
            [-43.519667916992184, -2.421668526367199],
            [-43.523667916992189, -2.419268526367198],
            [-43.528767916992187, -2.418868526367199],
            [-43.534167916992182, -2.420268526367195],
            [-43.537867916992184, -2.422468526367197],
            [-43.540067916992186, -2.425068526367198],
            [-43.541367916992186, -2.428668526367197],
            [-43.543067916992186, -2.432668526367195],
            [-43.545367916992184, -2.436168526367197],
            [-43.547867916992182, -2.439168526367197],
            [-43.550867916992182, -2.441568526367199],
            [-43.553267916992183, -2.444168526367193],
            [-43.554667916992187, -2.447468526367196],
            [-43.554767916992184, -2.452068526367199],
            [-43.554767916992184, -2.455668526367198],
            [-43.554067916992182, -2.460068526367195],
            [-43.554367916992184, -2.463668526367194],
            [-43.554767916992184, -2.468168526367194],
            [-43.555267916992186, -2.471968526367199],
            [-43.556567916992186, -2.475668526367194],
            [-43.558067916992186, -2.479768526367195],
            [-43.562467916992183, -2.486368526367194],
            [-43.565267916992184, -2.489468526367197],
            [-43.567167916992183, -2.492468526367198],
            [-43.569867916992187, -2.494468526367193],
            [-43.575267916992182, -2.4976685263672],
            [-43.577167916992188, -2.502668526367195],
            [-43.579567916992183, -2.506568526367197],
            [-43.584367916992186, -2.506768526367196],
            [-43.587867916992188, -2.506268526367194],
            [-43.591967916992182, -2.505568526367199],
            [-43.596467916992182, -2.504568526367194],
            [-43.598367916992188, -2.502068526367196],
            [-43.598167916992182, -2.498268526367198],
            [-43.595567916992188, -2.494968526367195],
            [-43.592667916992184, -2.491168526367197],
            [-43.589767916992187, -2.488568526367196],
            [-43.586567916992188, -2.487468526367195],
            [-43.580767916992187, -2.485268526367193],
            [-43.576867916992185, -2.482268526367193],
            [-43.573867916992185, -2.479468526367199],
            [-43.571767916992187, -2.4762685263672],
            [-43.570167916992183, -2.472568526367198],
            [-43.570667916992186, -2.469468526367194],
            [-43.572967916992184, -2.466768526367197],
            [-43.574267916992184, -2.4640685263672],
            [-43.576367916992183, -2.460468526367194],
            [-43.580667916992184, -2.458568526367195],
            [-43.584067916992183, -2.461268526367199],
            [-43.586767916992187, -2.465168526367194],
            [-43.587867916992188, -2.468468526367197],
            [-43.589067916992185, -2.4717685263672],
            [-43.589267916992185, -2.4747685263672],
            [-43.590867916992188, -2.477468526367197],
            [-43.593767916992185, -2.480868526367196],
            [-43.598067916992186, -2.483868526367196],
            [-43.601167916992182, -2.486168526367194],
            [-43.604167916992182, -2.4899685263672],
            [-43.605667916992182, -2.494068526367194],
            [-43.606367916992184, -2.497768526367196],
            [-43.610267916992186, -2.500468526367193],
            [-43.611467916992183, -2.503468526367193],
            [-43.612567916992184, -2.506268526367194],
            [-43.614967916992185, -2.508068526367197],
            [-43.617967916992185, -2.509168526367198],
            [-43.621867916992187, -2.510168526367195],
            [-43.625167916992183, -2.510268526367199],
            [-43.628767916992182, -2.508068526367197],
            [-43.631367916992183, -2.504568526367194],
            [-43.633867916992187, -2.502468526367196],
            [-43.637967916992189, -2.500268526367194],
            [-43.641067916992185, -2.498568526367194],
            [-43.644767916992187, -2.496868526367194],
            [-43.648467916992182, -2.495168526367195],
            [-43.649867916992186, -2.491368526367197],
            [-43.647567916992188, -2.488568526367196],
            [-43.647867916992183, -2.485268526367193],
            [-43.650467916992184, -2.482168526367197],
            [-43.654867916992188, -2.482768526367195],
            [-43.660067916992183, -2.485268526367193],
            [-43.663967916992185, -2.487968526367197],
            [-43.666967916992185, -2.491068526367194],
            [-43.669967916992185, -2.494668526367199],
            [-43.671867916992184, -2.4976685263672],
            [-43.675467916992183, -2.503768526367196],
            [-43.680867916992185, -2.512768526367196],
            [-43.684167916992187, -2.515468526367194],
            [-43.686967916992188, -2.518168526367198],
            [-43.689967916992188, -2.519868526367198],
            [-43.693767916992186, -2.519868526367198],
            [-43.696867916992183, -2.516068526367199],
            [-43.700967916992184, -2.5128685263672],
            [-43.702867916992183, -2.509668526367193],
            [-43.702867916992183, -2.506668526367193],
            [-43.701367916992183, -2.503768526367196],
            [-43.699967916992186, -2.498068526367199],
            [-43.698467916992186, -2.493268526367196],
            [-43.696267916992184, -2.488668526367199],
            [-43.695667916992186, -2.484668526367194],
            [-43.699067916992185, -2.482768526367195],
            [-43.704267916992187, -2.485268526367193],
            [-43.709067916992183, -2.487168526367199],
            [-43.711167916992189, -2.482568526367196],
            [-43.713467916992187, -2.478868526367194],
            [-43.716267916992187, -2.476168526367196],
            [-43.719467916992187, -2.475268526367195],
            [-43.722867916992186, -2.474568526367193],
            [-43.725967916992182, -2.475968526367197],
            [-43.730067916992184, -2.478168526367199],
            [-43.733867916992182, -2.479968526367195],
            [-43.736167916992187, -2.482868526367199],
            [-43.739667916992182, -2.486468526367197],
            [-43.742967916992185, -2.490068526367196],
            [-43.744767916992188, -2.492268526367198],
            [-43.747467916992186, -2.493668526367195],
            [-43.751967916992186, -2.494068526367194],
            [-43.756267916992186, -2.494468526367193],
            [-43.760767916992187, -2.494768526367196],
            [-43.765767916992182, -2.494668526367199],
            [-43.768267916992187, -2.493068526367196],
            [-43.770667916992188, -2.489768526367193],
            [-43.773167916992186, -2.486468526367197],
            [-43.776567916992185, -2.4824685263672],
            [-43.777567916992183, -2.478068526367196],
            [-43.776067916992183, -2.473368526367196],
            [-43.771867916992186, -2.470868526367198],
            [-43.768167916992184, -2.470068526367193],
            [-43.765067916992187, -2.468768526367199],
            [-43.761067916992182, -2.466568526367197],
            [-43.758167916992186, -2.462868526367195],
            [-43.756767916992182, -2.459068526367197],
            [-43.755267916992182, -2.455368526367195],
            [-43.752467916992188, -2.452568526367195],
            [-43.749667916992188, -2.4503685263672],
            [-43.747967916992188, -2.447068526367197],
            [-43.746567916992184, -2.443768526367194],
            [-43.743967916992183, -2.440068526367199],
            [-43.741867916992184, -2.434768526367193],
            [-43.739067916992184, -2.431168526367195],
            [-43.735267916992186, -2.428268526367198],
            [-43.732067916992186, -2.425468526367197],
            [-43.728867916992186, -2.424168526367197],
            [-43.725267916992188, -2.423968526367197],
            [-43.720867916992184, -2.424668526367199],
            [-43.715367916992186, -2.426168526367199],
            [-43.709267916992182, -2.426168526367199],
            [-43.706567916992185, -2.422468526367197],
            [-43.705767916992187, -2.419268526367198],
            [-43.703567916992185, -2.414968526367197],
            [-43.699567916992187, -2.411268526367195],
            [-43.693467916992184, -2.409468526367199],
            [-43.689167916992183, -2.405568526367198],
            [-43.688467916992188, -2.402368526367198],
            [-43.688067916992182, -2.398668526367196],
            [-43.685167916992185, -2.397568526367195],
            [-43.681567916992186, -2.398468526367196],
            [-43.674667916992185, -2.399868526367193],
            [-43.669767916992186, -2.400468526367199],
            [-43.664767916992183, -2.401868526367195],
            [-43.661467916992187, -2.402868526367193],
            [-43.657667916992182, -2.401268526367197],
            [-43.654167916992186, -2.4015685263672],
            [-43.650367916992188, -2.403468526367199],
            [-43.645667916992188, -2.4030685263672],
            [-43.644367916992188, -2.398968526367199],
            [-43.643267916992187, -2.394568526367195],
            [-43.641067916992185, -2.392468526367196],
            [-43.638167916992188, -2.388968526367194],
            [-43.638767916992187, -2.384368526367197],
            [-43.637067916992187, -2.381068526367194],
            [-43.634967916992188, -2.378468526367193],
            [-43.634367916992183, -2.375168526367197],
            [-43.633467916992188, -2.372368526367197],
            [-43.631667916992185, -2.368268526367196],
            [-43.630667916992188, -2.3649685263672],
            [-43.629867916992183, -2.362168526367199],
            [-43.628767916992182, -2.357768526367195],
            [-43.628567916992182, -2.352268526367197],
            [-43.628067916992187, -2.348068526367193],
            [-43.628467916992186, -2.344868526367193],
            [-43.628067916992187, -2.341268526367195],
            [-43.627667916992188, -2.337668526367196],
            [-43.627167916992185, -2.334068526367197],
            [-43.629067916992184, -2.329568526367197],
            [-43.629867916992183, -2.324968526367194],
            [-43.627667916992188, -2.321068526367199],
            [-43.626267916992184, -2.316968526367198],
            [-43.625267916992186, -2.313068526367196],
            [-43.625467916992186, -2.309368526367194],
            [-43.624467916992188, -2.305568526367196],
            [-43.622967916992188, -2.302168526367197],
            [-43.621267916992188, -2.299268526367193],
            [-43.618367916992185, -2.296368526367196],
            [-43.614967916992185, -2.294168526367194],
            [-43.610767916992188, -2.293068526367193],
            [-43.604967916992187, -2.292468526367195],
            [-43.600567916992183, -2.293068526367193],
            [-43.596967916992185, -2.291468526367197],
            [-43.594567916992183, -2.288968526367199],
            [-43.593667916992182, -2.285968526367199],
            [-43.594867916992186, -2.281668526367199],
            [-43.597267916992188, -2.278668526367198],
            [-43.599467916992182, -2.275968526367194],
            [-43.601667916992184, -2.271468526367194],
            [-43.601967916992187, -2.266768526367194],
            [-43.602067916992183, -2.262968526367196],
            [-43.603167916992184, -2.258768526367199],
            [-43.607267916992186, -2.255968526367198],
            [-43.610567916992188, -2.253468526367193],
            [-43.614067916992184, -2.252068526367196],
            [-43.616967916992188, -2.251868526367197],
            [-43.620767916992186, -2.251568526367194],
            [-43.623767916992186, -2.251268526367198],
            [-43.627067916992182, -2.251268526367198],
            [-43.632067916992185, -2.251868526367197],
            [-43.638267916992184, -2.254068526367199],
            [-43.644367916992188, -2.257768526367194],
            [-43.650667916992184, -2.260368526367195],
            [-43.654467916992182, -2.262368526367197],
            [-43.660367916992186, -2.265068526367195],
            [-43.669667916992182, -2.269068526367199],
            [-43.677967916992188, -2.271168526367198],
            [-43.682267916992188, -2.271868526367193],
            [-43.686067916992187, -2.272968526367194],
            [-43.689967916992188, -2.274468526367194],
            [-43.696267916992184, -2.2765685263672],
            [-43.700667916992188, -2.278768526367195],
            [-43.704267916992187, -2.281268526367199],
            [-43.708967916992187, -2.284168526367196],
            [-43.713367916992183, -2.286168526367199],
            [-43.718067916992183, -2.2887685263672],
            [-43.720267916992185, -2.291368526367194],
            [-43.721167916992187, -2.295868526367194],
            [-43.721967916992185, -2.300068526367198],
            [-43.723167916992182, -2.304468526367195],
            [-43.723467916992185, -2.308368526367197],
            [-43.722567916992183, -2.312768526367194],
            [-43.721667916992182, -2.317168526367198],
            [-43.721767916992185, -2.322068526367197],
            [-43.722867916992186, -2.324968526367194],
            [-43.724567916992186, -2.329268526367194],
            [-43.727267916992183, -2.333768526367194],
            [-43.730367916992186, -2.337668526367196],
            [-43.734367916992184, -2.342368526367196],
            [-43.737467916992188, -2.3467685263672],
            [-43.738667916992185, -2.3512685263672],
            [-43.735367916992182, -2.354168526367197],
            [-43.731767916992183, -2.356168526367199],
            [-43.728867916992186, -2.359368526367199],
            [-43.726667916992184, -2.364368526367194],
            [-43.725067916992188, -2.367968526367193],
            [-43.724867916992189, -2.371668526367195],
            [-43.724867916992189, -2.375868526367199],
            [-43.725067916992188, -2.379168526367195],
            [-43.725967916992182, -2.383568526367199],
            [-43.727467916992182, -2.386868526367195],
            [-43.730367916992186, -2.389668526367196],
            [-43.733567916992186, -2.393168526367198],
            [-43.736467916992183, -2.394368526367195],
            [-43.739967916992185, -2.395368526367193],
            [-43.743267916992188, -2.396168526367198],
            [-43.746867916992187, -2.398668526367196],
            [-43.750167916992183, -2.402668526367194],
            [-43.753067916992187, -2.404868526367196],
            [-43.756367916992183, -2.406268526367199],
            [-43.758867916992187, -2.410068526367198],
            [-43.759067916992187, -2.4152685263672],
            [-43.761767916992184, -2.418868526367199],
            [-43.765667916992186, -2.420668526367194],
            [-43.768967916992182, -2.422568526367193],
            [-43.772267916992185, -2.424268526367193],
            [-43.780067916992188, -2.428268526367198],
            [-43.782767916992185, -2.429768526367198],
            [-43.785367916992186, -2.431568526367194],
            [-43.788067916992183, -2.433268526367193],
            [-43.791067916992183, -2.4351685263672],
            [-43.795067916992188, -2.437668526367197],
            [-43.798867916992187, -2.439568526367196],
            [-43.802267916992186, -2.439968526367196],
            [-43.806067916992184, -2.439168526367197],
            [-43.809367916992187, -2.436968526367195],
            [-43.809467916992183, -2.432068526367196],
            [-43.806567916992186, -2.427568526367196],
            [-43.804367916992184, -2.423168526367199],
            [-43.801667916992187, -2.420368526367199],
            [-43.799667916992185, -2.4167685263672],
            [-43.799467916992185, -2.413868526367196],
            [-43.800767916992186, -2.409768526367195],
            [-43.800267916992183, -2.405068526367195],
            [-43.798567916992184, -2.401868526367195],
            [-43.796667916992185, -2.398468526367196],
            [-43.793367916992182, -2.395068526367197],
            [-43.797867916992182, -2.391768526367194],
            [-43.802567916992189, -2.390768526367197],
            [-43.806567916992186, -2.392568526367199],
            [-43.810567916992184, -2.395068526367197],
            [-43.814367916992182, -2.397568526367195],
            [-43.817767916992182, -2.400468526367199],
            [-43.820167916992183, -2.402968526367196],
            [-43.824267916992184, -2.406268526367199],
            [-43.829967916992182, -2.409868526367198],
            [-43.833667916992184, -2.412768526367195],
            [-43.836867916992183, -2.415568526367196],
            [-43.839467916992184, -2.419168526367194],
            [-43.839867916992183, -2.424168526367197],
            [-43.838767916992182, -2.428568526367194],
            [-43.835967916992182, -2.431168526367195],
            [-43.833667916992184, -2.434168526367195],
            [-43.832067916992187, -2.437268526367198],
            [-43.831767916992185, -2.440468526367198],
            [-43.834367916992186, -2.443468526367198],
            [-43.837267916992182, -2.445568526367197],
            [-43.841267916992187, -2.447468526367196],
            [-43.842567916992188, -2.452368526367195],
            [-43.842267916992185, -2.457968526367196],
            [-43.842567916992188, -2.461568526367195],
            [-43.843467916992182, -2.468068526367198],
            [-43.844867916992186, -2.475568526367198],
            [-43.845967916992187, -2.488568526367196],
            [-43.846767916992185, -2.501868526367197],
            [-43.848367916992188, -2.507368526367195],
            [-43.850067916992188, -2.509968526367196],
            [-43.855267916992183, -2.514268526367196],
            [-43.859967916992183, -2.515668526367193],
            [-43.864067916992184, -2.516568526367195],
            [-43.867167916992187, -2.516068526367199],
            [-43.871867916992187, -2.515768526367197],
            [-43.875467916992186, -2.517668526367196],
            [-43.877767916992184, -2.521168526367198],
            [-43.879067916992184, -2.525768526367195],
            [-43.882167916992188, -2.528768526367195],
            [-43.887067916992187, -2.531768526367195],
            [-43.890367916992183, -2.534468526367199],
            [-43.891867916992183, -2.537068526367193],
            [-43.892367916992185, -2.540068526367193],
            [-43.891467916992184, -2.544668526367197],
            [-43.890467916992186, -2.548968526367197],
            [-43.891067916992185, -2.553668526367197],
            [-43.894767916992187, -2.556868526367197],
            [-43.899567916992183, -2.558068526367194],
            [-43.902867916992186, -2.557968526367198],
            [-43.905667916992186, -2.556568526367194],
            [-43.908767916992183, -2.554768526367198],
            [-43.914467916992187, -2.554368526367199],
            [-43.920367916992184, -2.555468526367193],
            [-43.924467916992185, -2.557168526367199],
            [-43.927167916992182, -2.558368526367197],
            [-43.930267916992186, -2.560868526367194],
            [-43.931567916992186, -2.564168526367197],
            [-43.932967916992183, -2.567768526367196],
            [-43.935867916992187, -2.572168526367193],
            [-43.939767916992189, -2.576968526367196],
            [-43.945667916992186, -2.586968526367194],
            [-43.949067916992185, -2.593768526367199],
            [-43.950967916992184, -2.598068526367193],
            [-43.953767916992184, -2.605268526367198],
            [-43.954067916992187, -2.611868526367196],
            [-43.953967916992184, -2.617768526367193],
            [-43.956867916992188, -2.621568526367199],
            [-43.962667916992189, -2.6226685263672],
            [-43.973667916992184, -2.625768526367196],
            [-43.981467916992187, -2.630268526367196],
            [-43.988867916992184, -2.633768526367199],
            [-43.993667916992187, -2.635168526367195],
            [-43.998867916992182, -2.636768526367199],
            [-44.003867916992185, -2.638168526367195],
            [-44.007167916992188, -2.643468526367194],
            [-44.009867916992185, -2.647268526367199],
            [-44.013767916992187, -2.650368526367195],
            [-44.019267916992185, -2.651168526367194],
            [-44.022567916992188, -2.652568526367197],
            [-44.025967916992187, -2.656468526367199],
            [-44.027867916992186, -2.661668526367194],
            [-44.027867916992186, -2.666168526367194],
            [-44.029167916992186, -2.670068526367196],
            [-44.031167916992182, -2.673368526367199],
            [-44.035167916992187, -2.676668526367195],
            [-44.037767916992188, -2.678368526367194],
            [-44.041667916992182, -2.682968526367198],
            [-44.043967916992187, -2.687168526367195],
            [-44.044667916992182, -2.690568526367194],
            [-44.047167916992187, -2.693168526367195],
            [-44.050367916992187, -2.698168526367198],
            [-44.051167916992185, -2.702068526367199],
            [-44.052267916992186, -2.705668526367198],
            [-44.053067916992184, -2.708568526367195],
            [-44.055067916992186, -2.711968526367194],
            [-44.058267916992186, -2.715668526367196],
            [-44.061067916992187, -2.718968526367199],
            [-44.065567916992187, -2.720968526367194],
            [-44.067667916992185, -2.724568526367193],
            [-44.067767916992182, -2.728168526367199],
            [-44.070467916992186, -2.732868526367199],
            [-44.072867916992188, -2.735568526367196],
            [-44.075367916992185, -2.739668526367197],
            [-44.078267916992182, -2.743268526367196],
            [-44.080767916992187, -2.745768526367193],
            [-44.084767916992185, -2.748868526367197],
            [-44.089267916992185, -2.751268526367198],
            [-44.092267916992185, -2.751868526367197],
            [-44.094867916992186, -2.753068526367194],
            [-44.098067916992186, -2.752368526367199],
            [-44.102267916992183, -2.749368526367199],
            [-44.106767916992183, -2.748368526367194],
            [-44.111367916992187, -2.747768526367196],
            [-44.115267916992188, -2.748768526367193],
            [-44.117967916992185, -2.752468526367196],
            [-44.120167916992187, -2.755268526367196],
            [-44.122667916992185, -2.758168526367193],
            [-44.125567916992182, -2.759668526367193],
            [-44.127667916992188, -2.762068526367194],
            [-44.129667916992183, -2.7643685263672],
            [-44.132367916992187, -2.765368526367197],
            [-44.136067916992182, -2.766268526367199],
            [-44.141267916992184, -2.767068526367197],
            [-44.147967916992187, -2.768268526367194],
            [-44.152567916992183, -2.767968526367198],
            [-44.158767916992183, -2.768468526367194],
            [-44.163467916992182, -2.768168526367198],
            [-44.169167916992187, -2.767668526367196],
            [-44.175067916992184, -2.767068526367197],
            [-44.179667916992187, -2.766468526367198],
            [-44.184867916992182, -2.764668526367196],
            [-44.190267916992184, -2.764568526367199],
            [-44.194967916992184, -2.765368526367197],
            [-44.197967916992184, -2.766268526367199],
            [-44.202067916992185, -2.767068526367197],
            [-44.208167916992188, -2.767868526367195],
            [-44.211967916992187, -2.769868526367198],
            [-44.215867916992188, -2.771768526367197],
            [-44.220967916992187, -2.771068526367195],
            [-44.225067916992188, -2.771868526367193],
            [-44.228567916992183, -2.772668526367198],
            [-44.232067916992186, -2.774468526367194],
            [-44.234967916992183, -2.775768526367195],
            [-44.237267916992188, -2.778168526367196],
            [-44.240867916992187, -2.780368526367198],
            [-44.243867916992187, -2.780568526367198],
            [-44.246867916992187, -2.780468526367194],
            [-44.250867916992185, -2.780068526367195],
            [-44.254867916992183, -2.778968526367194],
            [-44.257767916992186, -2.777268526367195],
            [-44.260767916992187, -2.774768526367197],
            [-44.263167916992188, -2.771468526367194],
            [-44.264367916992185, -2.767068526367197],
            [-44.265367916992183, -2.762968526367196],
            [-44.265767916992182, -2.759168526367198],
            [-44.264567916992185, -2.753868526367199],
            [-44.262367916992183, -2.749668526367195],
            [-44.259867916992185, -2.744368526367197],
            [-44.257667916992183, -2.740868526367194],
            [-44.255767916992184, -2.738368526367196],
            [-44.251867916992182, -2.732868526367199],
            [-44.249067916992182, -2.729968526367195],
            [-44.245267916992184, -2.728368526367198],
            [-44.241167916992183, -2.726468526367199],
            [-44.237167916992185, -2.725268526367195],
            [-44.232067916992186, -2.724568526367193],
            [-44.228967916992183, -2.7247685263672],
            [-44.223467916992185, -2.725668526367194],
            [-44.219767916992183, -2.723968526367194],
            [-44.216767916992183, -2.723068526367193],
            [-44.213667916992186, -2.720968526367194],
            [-44.209667916992188, -2.719068526367195],
            [-44.205367916992188, -2.717268526367199],
            [-44.202067916992185, -2.713668526367194],
            [-44.199867916992183, -2.709668526367196],
            [-44.197067916992182, -2.706768526367199],
            [-44.193467916992184, -2.704668526367193],
            [-44.188067916992182, -2.704668526367193],
            [-44.184367916992187, -2.704668526367193],
            [-44.180767916992188, -2.703868526367195],
            [-44.176367916992184, -2.701068526367195],
            [-44.170667916992187, -2.700468526367196],
            [-44.165867916992184, -2.699568526367194],
            [-44.163167916992187, -2.697868526367195],
            [-44.161267916992188, -2.693868526367197],
            [-44.159567916992188, -2.690568526367194],
            [-44.157667916992182, -2.688268526367196],
            [-44.153667916992184, -2.685268526367196],
            [-44.148167916992186, -2.684168526367195],
            [-44.143767916992182, -2.682168526367199],
            [-44.138767916992187, -2.681568526367194],
            [-44.137067916992187, -2.6774685263672],
            [-44.135967916992186, -2.672768526367193],
            [-44.134967916992188, -2.668168526367197],
            [-44.133067916992182, -2.665368526367196],
            [-44.133467916992188, -2.661468526367194],
            [-44.132767916992186, -2.658668526367194],
            [-44.129367916992187, -2.655168526367198],
            [-44.125167916992183, -2.654768526367199],
            [-44.123367916992187, -2.651268526367197],
            [-44.125167916992183, -2.648468526367196],
            [-44.121567916992184, -2.646768526367197],
            [-44.117267916992184, -2.643168526367198],
            [-44.115267916992188, -2.638968526367194],
            [-44.113567916992189, -2.636468526367196],
            [-44.112467916992188, -2.632768526367194],
            [-44.108167916992187, -2.630168526367193],
            [-44.105567916992186, -2.628568526367197],
            [-44.106167916992185, -2.625468526367193],
            [-44.107267916992186, -2.621568526367199],
            [-44.107767916992188, -2.617668526367197],
            [-44.106767916992183, -2.613568526367196],
            [-44.106067916992188, -2.609268526367195],
            [-44.105267916992183, -2.6042685263672],
            [-44.102767916992185, -2.598068526367193],
            [-44.100267916992188, -2.594768526367197],
            [-44.097267916992188, -2.592668526367198],
            [-44.093367916992186, -2.590368526367193],
            [-44.092267916992185, -2.587068526367197],
            [-44.092967916992187, -2.583968526367194],
            [-44.093467916992182, -2.579668526367193],
            [-44.093367916992186, -2.576068526367195],
            [-44.090967916992184, -2.573768526367196],
            [-44.086567916992188, -2.571368526367195],
            [-44.083467916992184, -2.567468526367193],
            [-44.081467916992182, -2.563768526367198],
            [-44.076467916992186, -2.561968526367195],
            [-44.072667916992188, -2.560268526367196],
            [-44.068767916992186, -2.559368526367194],
            [-44.065167916992188, -2.559468526367198],
            [-44.061967916992188, -2.559868526367197],
            [-44.057967916992183, -2.562668526367197],
            [-44.053867916992182, -2.562968526367193],
            [-44.053267916992183, -2.558768526367196],
            [-44.051467916992188, -2.555868526367199],
            [-44.046967916992187, -2.554368526367199],
            [-44.043167916992182, -2.554668526367195],
            [-44.040867916992184, -2.557168526367199],
            [-44.036967916992182, -2.556868526367197],
            [-44.034567916992188, -2.551668526367195],
            [-44.033867916992186, -2.545368526367199],
            [-44.033767916992183, -2.542268526367195],
            [-44.034267916992185, -2.537868526367198],
            [-44.033767916992183, -2.533668526367194],
            [-44.031267916992185, -2.531468526367199],
            [-44.027667916992186, -2.528768526367195],
            [-44.027567916992183, -2.524068526367195],
            [-44.027667916992186, -2.519768526367194],
            [-44.026567916992185, -2.516268526367199],
            [-44.024367916992183, -2.513168526367195],
            [-44.023767916992185, -2.509068526367194],
            [-44.025067916992185, -2.505168526367193],
            [-44.027067916992188, -2.500868526367199],
            [-44.028767916992187, -2.498568526367194],
            [-44.031267916992185, -2.495468526367198],
            [-44.034267916992185, -2.491868526367199],
            [-44.041767916992185, -2.483268526367198],
            [-44.048367916992184, -2.475668526367194],
            [-44.050867916992182, -2.472368526367198],
            [-44.052467916992185, -2.469468526367194],
            [-44.052267916992186, -2.466268526367195],
            [-44.051067916992189, -2.462068526367197],
            [-44.048367916992184, -2.456868526367195],
            [-44.044567916992186, -2.452868526367197],
            [-44.038467916992182, -2.450968526367198],
            [-44.033367916992184, -2.450468526367196],
            [-44.029267916992183, -2.451268526367194],
            [-44.025467916992184, -2.450668526367195],
            [-44.023367916992186, -2.4473685263672],
            [-44.022867916992183, -2.442368526367197],
            [-44.022867916992183, -2.439068526367194],
            [-44.022867916992183, -2.434668526367197],
            [-44.023467916992182, -2.430868526367199],
            [-44.025167916992189, -2.426668526367195],
            [-44.026267916992182, -2.423268526367195],
            [-44.027267916992187, -2.420068526367196],
            [-44.028067916992185, -2.416368526367194],
            [-44.028767916992187, -2.413168526367194],
            [-44.029467916992182, -2.410068526367198],
            [-44.031267916992185, -2.406968526367194],
            [-44.036967916992182, -2.406268526367199],
            [-44.042167916992184, -2.405968526367197],
            [-44.047567916992186, -2.405168526367198],
            [-44.051667916992187, -2.404768526367199],
            [-44.059067916992184, -2.404068526367197],
            [-44.063467916992188, -2.403368526367196],
            [-44.068267916992184, -2.403368526367196],
            [-44.072367916992185, -2.403668526367198],
            [-44.077667916992183, -2.404068526367197],
            [-44.082567916992183, -2.404468526367197],
            [-44.088967916992182, -2.405368526367198],
            [-44.094567916992183, -2.406568526367195],
            [-44.097267916992188, -2.407868526367196],
            [-44.100667916992187, -2.409768526367195],
            [-44.103167916992184, -2.411368526367198],
            [-44.108567916992186, -2.414268526367195],
            [-44.125267916992186, -2.423568526367198],
            [-44.132067916992185, -2.427268526367193],
            [-44.136567916992185, -2.429768526367198],
            [-44.140667916992186, -2.430768526367196],
            [-44.145167916992186, -2.432168526367199],
            [-44.150467916992184, -2.433568526367196],
            [-44.154867916992188, -2.4366685263672],
            [-44.156167916992182, -2.440568526367194],
            [-44.157867916992188, -2.444868526367195],
            [-44.162467916992185, -2.448768526367196],
            [-44.167467916992187, -2.452868526367197],
            [-44.171667916992185, -2.455768526367194],
            [-44.173867916992187, -2.458268526367199],
            [-44.177867916992184, -2.459368526367193],
            [-44.183367916992182, -2.460968526367196],
            [-44.186267916992186, -2.461468526367199],
            [-44.190267916992184, -2.462668526367196],
            [-44.193867916992183, -2.463268526367195],
            [-44.197467916992188, -2.463668526367194],
            [-44.201367916992183, -2.4640685263672],
            [-44.205067916992185, -2.466668526367194],
            [-44.210467916992187, -2.469768526367197],
            [-44.214567916992188, -2.470868526367198],
            [-44.218367916992186, -2.472268526367195],
            [-44.222067916992188, -2.475068526367195],
            [-44.225967916992182, -2.476968526367195],
            [-44.230267916992183, -2.4777685263672],
            [-44.234967916992183, -2.478368526367198],
            [-44.238367916992182, -2.478968526367197],
            [-44.242467916992183, -2.479568526367196],
            [-44.246267916992188, -2.480968526367199],
            [-44.250467916992186, -2.481368526367199],
            [-44.254167916992188, -2.481068526367196],
            [-44.258567916992185, -2.480968526367199],
            [-44.263567916992187, -2.481968526367197],
            [-44.268267916992187, -2.484968526367197],
            [-44.272567916992188, -2.486368526367194],
            [-44.276167916992186, -2.486768526367193],
            [-44.280867916992186, -2.486868526367196],
            [-44.286467916992187, -2.487168526367199],
            [-44.292067916992188, -2.4869685263672],
            [-44.296467916992185, -2.486468526367197],
            [-44.300067916992184, -2.486368526367194],
            [-44.303667916992183, -2.487568526367198],
            [-44.305867916992185, -2.490468526367195],
            [-44.307167916992185, -2.493668526367195],
            [-44.309167916992187, -2.496568526367199],
            [-44.312467916992183, -2.498368526367194],
            [-44.316067916992182, -2.500568526367196],
            [-44.319667916992188, -2.502468526367196],
            [-44.318867916992183, -2.505268526367196],
            [-44.315967916992186, -2.506268526367194],
            [-44.312367916992187, -2.505168526367193],
            [-44.310767916992184, -2.508868526367195],
            [-44.309067916992184, -2.512968526367196],
            [-44.307267916992188, -2.516368526367195],
            [-44.306967916992186, -2.521768526367197],
            [-44.306967916992186, -2.526168526367194],
            [-44.307967916992183, -2.529768526367199],
            [-44.307767916992184, -2.534168526367196],
            [-44.308267916992186, -2.537068526367193],
            [-44.310167916992185, -2.539568526367198],
            [-44.314067916992187, -2.540568526367196],
            [-44.317667916992185, -2.539568526367198],
            [-44.318867916992183, -2.536168526367199],
            [-44.319867916992187, -2.533368526367198],
            [-44.320467916992186, -2.530168526367198],
            [-44.321767916992187, -2.527568526367197],
            [-44.327067916992185, -2.527868526367193],
            [-44.334367916992186, -2.529068526367197],
            [-44.339767916992187, -2.530368526367198],
            [-44.344267916992187, -2.529468526367197],
            [-44.348067916992186, -2.527268526367195],
            [-44.349567916992186, -2.530368526367198],
            [-44.353067916992188, -2.531768526367195],
            [-44.355567916992186, -2.533468526367194],
            [-44.355567916992186, -2.538168526367194],
            [-44.356467916992187, -2.541368526367194],
            [-44.355267916992183, -2.545068526367196],
            [-44.355067916992184, -2.548268526367195],
            [-44.356067916992188, -2.551068526367196],
            [-44.358967916992185, -2.555468526367193],
            [-44.362567916992184, -2.558368526367197],
            [-44.365367916992184, -2.559868526367197],
            [-44.368267916992188, -2.5616685263672],
            [-44.371367916992185, -2.562268526367198],
            [-44.374367916992185, -2.562968526367193],
            [-44.377367916992185, -2.565268526367198],
            [-44.375767916992189, -2.568868526367197],
            [-44.370767916992186, -2.572068526367197],
            [-44.369367916992182, -2.576068526367195],
            [-44.368067916992182, -2.581768526367199],
            [-44.364767916992186, -2.580968526367194],
            [-44.362967916992183, -2.584068526367197],
            [-44.360567916992188, -2.587868526367195],
            [-44.357767916992188, -2.595968526367194],
            [-44.355667916992182, -2.598868526367198],
            [-44.354967916992187, -2.602068526367198],
            [-44.356767916992183, -2.606468526367195],
            [-44.357767916992188, -2.612168526367199],
            [-44.359267916992188, -2.615368526367199],
            [-44.360067916992186, -2.619168526367197],
            [-44.366867916992184, -2.641868526367197],
            [-44.367967916992185, -2.647868526367198],
            [-44.367967916992185, -2.651168526367194],
            [-44.369067916992186, -2.655068526367195],
            [-44.370767916992186, -2.657868526367196],
            [-44.371967916992183, -2.660868526367196],
            [-44.374767916992184, -2.663368526367194],
            [-44.378267916992186, -2.666068526367198],
            [-44.380167916992185, -2.669768526367193],
            [-44.380167916992185, -2.673668526367194],
            [-44.379067916992184, -2.677868526367199],
            [-44.379167916992188, -2.682968526367198],
            [-44.380167916992185, -2.687468526367198],
            [-44.381367916992183, -2.690568526367194],
            [-44.383467916992188, -2.694568526367199],
            [-44.384967916992188, -2.697868526367195],
            [-44.385367916992188, -2.700768526367199],
            [-44.386367916992185, -2.705068526367199],
            [-44.387467916992186, -2.709368526367193],
            [-44.388167916992188, -2.712668526367196],
            [-44.389267916992182, -2.716268526367195],
            [-44.391067916992185, -2.720568526367195],
            [-44.389567916992185, -2.723968526367194],
            [-44.387467916992186, -2.7277685263672],
            [-44.385467916992184, -2.736068526367198],
            [-44.385367916992188, -2.740268526367196],
            [-44.385767916992187, -2.744368526367197],
            [-44.386467916992189, -2.7491685263672],
            [-44.388567916992187, -2.753768526367196],
            [-44.390467916992186, -2.756068526367194],
            [-44.394067916992185, -2.760168526367195],
            [-44.397167916992188, -2.763968526367194],
            [-44.399067916992188, -2.7673685263672],
            [-44.401867916992188, -2.769268526367199],
            [-44.403467916992184, -2.772268526367199],
            [-44.404467916992182, -2.775868526367198],
            [-44.404767916992185, -2.778768526367195],
            [-44.405167916992184, -2.781968526367194],
            [-44.405867916992186, -2.785968526367199],
            [-44.407867916992188, -2.790068526367193],
            [-44.409267916992185, -2.793868526367199],
            [-44.408467916992187, -2.798668526367194],
            [-44.410267916992183, -2.801368526367199],
            [-44.409467916992185, -2.805268526367193],
            [-44.410067916992183, -2.8101685263672],
            [-44.409567916992188, -2.815168526367195],
            [-44.408967916992182, -2.821268526367199],
            [-44.408967916992182, -2.8268685263672],
            [-44.408367916992184, -2.830668526367198],
            [-44.407367916992186, -2.836168526367196],
            [-44.407367916992186, -2.843368526367193],
            [-44.407267916992183, -2.847268526367195],
            [-44.407267916992183, -2.851668526367199],
            [-44.407667916992182, -2.857868526367199],
            [-44.407067916992183, -2.862168526367199],
            [-44.406767916992187, -2.865268526367196],
            [-44.406967916992187, -2.874068526367196],
            [-44.406467916992185, -2.878768526367196],
            [-44.406167916992182, -2.883468526367196],
            [-44.406667916992184, -2.887768526367196],
            [-44.407067916992183, -2.892068526367197],
            [-44.407367916992186, -2.895768526367199],
            [-44.408367916992184, -2.899868526367193],
            [-44.409767916992188, -2.905068526367195],
            [-44.410967916992185, -2.908168526367199],
            [-44.411967916992182, -2.911968526367197],
            [-44.412867916992184, -2.914768526367197],
            [-44.414467916992187, -2.918568526367196],
            [-44.416767916992185, -2.9244685263672],
            [-44.421467916992185, -2.934468526367198],
            [-44.422567916992186, -2.937968526367193],
            [-44.424767916992188, -2.943868526367197],
            [-44.430767916992188, -2.952168526367196],
            [-44.433267916992186, -2.955768526367194],
            [-44.436267916992186, -2.961868526367198],
            [-44.438567916992184, -2.964768526367195],
            [-44.441867916992187, -2.969568526367198],
            [-44.448767916992182, -2.980868526367196],
            [-44.451067916992187, -2.983868526367196],
            [-44.453567916992185, -2.987268526367195],
            [-44.457567916992183, -2.991368526367197],
            [-44.463267916992187, -2.997168526367197],
            [-44.466967916992182, -2.999868526367194],
            [-44.472367916992184, -3.005168526367193],
            [-44.476667916992184, -3.009268526367194],
            [-44.490067916992189, -3.021068526367195],
            [-44.496567916992184, -3.027668526367194],
            [-44.506867916992185, -3.0327685263672],
            [-44.515067916992187, -3.036968526367197],
            [-44.519267916992185, -3.038868526367196],
            [-44.525367916992188, -3.041168526367194],
            [-44.532367916992186, -3.043968526367195],
            [-44.541067916992183, -3.044468526367197],
            [-44.548867916992187, -3.045368526367199],
            [-44.562667916992183, -3.045268526367195],
            [-44.574067916992185, -3.044268526367198],
            [-44.586767916992187, -3.041668526367197],
            [-44.591167916992184, -3.041968526367199],
            [-44.597067916992188, -3.039968526367197],
            [-44.603967916992183, -3.037768526367195],
            [-44.610867916992184, -3.033368526367198],
            [-44.613867916992184, -3.030968526367197],
            [-44.618367916992185, -3.028368526367196],
            [-44.621967916992183, -3.026968526367199],
            [-44.628067916992187, -3.024868526367193],
            [-44.633467916992188, -3.023968526367199],
            [-44.638167916992188, -3.022868526367198],
            [-44.642067916992183, -3.021868526367193],
            [-44.645967916992184, -3.020368526367193],
            [-44.650067916992185, -3.018668526367193],
            [-44.653367916992188, -3.017468526367196],
            [-44.658167916992184, -3.015368526367197],
            [-44.662467916992185, -3.0128685263672],
            [-44.666367916992186, -3.009968526367196],
            [-44.669567916992186, -3.006768526367196],
            [-44.671467916992185, -3.004468526367198],
            [-44.673367916992184, -3.000268526367194],
            [-44.675267916992183, -2.996368526367199],
            [-44.676167916992185, -2.993268526367196],
            [-44.676967916992183, -2.989168526367195],
            [-44.677867916992184, -2.985068526367193],
            [-44.678867916992182, -2.981768526367198],
            [-44.679367916992184, -2.978868526367194],
            [-44.679367916992184, -2.974568526367193],
            [-44.678867916992182, -2.970568526367195],
            [-44.678267916992183, -2.967668526367198],
            [-44.677567916992189, -2.964468526367199],
            [-44.676667916992187, -2.960768526367197],
            [-44.677167916992182, -2.954968526367196],
            [-44.679167916992185, -2.948468526367193],
            [-44.680267916992186, -2.943868526367197],
            [-44.680867916992185, -2.939968526367196],
            [-44.680767916992188, -2.935468526367195],
            [-44.680767916992188, -2.928868526367197],
            [-44.680067916992186, -2.924768526367195],
            [-44.679667916992187, -2.919468526367197],
            [-44.677567916992189, -2.913168526367194],
            [-44.674967916992188, -2.908868526367193],
            [-44.672467916992183, -2.905968526367197],
            [-44.670267916992188, -2.904068526367197],
            [-44.666667916992182, -2.9015685263672],
            [-44.662867916992184, -2.900168526367196],
            [-44.658967916992182, -2.897368526367195],
            [-44.655067916992188, -2.895368526367193],
            [-44.651467916992182, -2.892168526367193],
            [-44.648467916992182, -2.888468526367198],
            [-44.646467916992187, -2.884668526367193],
            [-44.642667916992188, -2.876268526367198],
            [-44.636867916992188, -2.865768526367198],
            [-44.634167916992183, -2.860268526367193],
            [-44.632767916992186, -2.856068526367196],
            [-44.632467916992184, -2.852368526367194],
            [-44.633267916992182, -2.844168526367199],
            [-44.635367916992188, -2.838968526367196],
            [-44.638167916992188, -2.834868526367195],
            [-44.640067916992187, -2.830368526367195],
            [-44.642067916992183, -2.825968526367198],
            [-44.643767916992182, -2.821668526367198],
            [-44.645667916992188, -2.818168526367195],
            [-44.647267916992185, -2.815568526367194],
            [-44.649367916992183, -2.812468526367198],
            [-44.651467916992182, -2.806868526367197],
            [-44.649067916992188, -2.804168526367199],
            [-44.648667916992189, -2.796668526367199],
            [-44.647967916992187, -2.793368526367196],
            [-44.646767916992182, -2.789568526367198],
            [-44.646167916992184, -2.785168526367194],
            [-44.645667916992188, -2.782068526367198],
            [-44.643567916992183, -2.778368526367196],
            [-44.641067916992185, -2.7750685263672],
            [-44.638967916992186, -2.771768526367197],
            [-44.636367916992185, -2.766768526367194],
            [-44.633467916992188, -2.761068526367197],
            [-44.632067916992185, -2.757968526367193],
            [-44.629967916992186, -2.753568526367197],
            [-44.626067916992184, -2.745268526367198],
            [-44.624067916992182, -2.739768526367193],
            [-44.623267916992184, -2.735368526367196],
            [-44.623067916992184, -2.730268526367198],
            [-44.623367916992187, -2.724168526367194],
            [-44.624367916992185, -2.720568526367195],
            [-44.625867916992185, -2.713368526367198],
            [-44.626567916992187, -2.708468526367199],
            [-44.626067916992184, -2.702168526367196],
            [-44.622167916992183, -2.698568526367197],
            [-44.618367916992185, -2.695568526367197],
            [-44.615567916992184, -2.694868526367195],
            [-44.611067916992184, -2.691868526367195],
            [-44.607867916992184, -2.690268526367198],
            [-44.604967916992187, -2.686268526367193],
            [-44.604267916992185, -2.682968526367198],
            [-44.603867916992186, -2.679668526367195],
            [-44.602267916992183, -2.675868526367196],
            [-44.600267916992188, -2.671968526367195],
            [-44.598767916992188, -2.669268526367198],
            [-44.597267916992188, -2.666368526367194],
            [-44.594067916992188, -2.662868526367198],
            [-44.590067916992183, -2.660868526367196],
            [-44.585367916992183, -2.658068526367195],
            [-44.583767916992187, -2.654168526367194],
            [-44.581767916992185, -2.649068526367195],
            [-44.580167916992188, -2.645368526367193],
            [-44.578167916992186, -2.639968526367198],
            [-44.576367916992183, -2.635168526367195],
            [-44.573267916992187, -2.627768526367198],
            [-44.571567916992187, -2.623068526367199],
            [-44.569367916992185, -2.619468526367193],
            [-44.563067916992182, -2.610068526367193],
            [-44.560167916992185, -2.607168526367197],
            [-44.557667916992187, -2.604968526367195],
            [-44.556567916992186, -2.600268526367195],
            [-44.554967916992183, -2.595568526367195],
            [-44.552967916992188, -2.592368526367196],
            [-44.551867916992187, -2.589568526367195],
            [-44.550767916992186, -2.586868526367198],
            [-44.549267916992186, -2.580668526367198],
            [-44.546467916992185, -2.570668526367193],
            [-44.544167916992187, -2.564568526367196],
            [-44.542567916992184, -2.561868526367199],
            [-44.541167916992187, -2.558268526367193],
            [-44.538967916992185, -2.551968526367197],
            [-44.537567916992188, -2.547768526367193],
            [-44.536967916992182, -2.544668526367197],
            [-44.537067916992186, -2.540668526367199],
            [-44.537067916992186, -2.5372685263672],
            [-44.536667916992187, -2.5342685263672],
            [-44.535867916992188, -2.530868526367193],
            [-44.534167916992182, -2.527068526367195],
            [-44.531267916992185, -2.525668526367198],
            [-44.527667916992186, -2.525468526367199],
            [-44.523967916992184, -2.525468526367199],
            [-44.520767916992185, -2.527268526367195],
            [-44.518167916992184, -2.529468526367197],
            [-44.514567916992185, -2.533368526367198],
            [-44.509967916992188, -2.5342685263672],
            [-44.505967916992184, -2.530568526367198],
            [-44.498867916992182, -2.524768526367197],
            [-44.495067916992184, -2.521768526367197],
            [-44.489767916992186, -2.520468526367196],
            [-44.487467916992188, -2.516368526367195],
            [-44.486467916992183, -2.512668526367193],
            [-44.485367916992182, -2.509568526367197],
            [-44.482567916992188, -2.503568526367197],
            [-44.481667916992187, -2.499668526367195],
            [-44.479467916992185, -2.493368526367199],
            [-44.477567916992186, -2.487768526367198],
            [-44.475567916992183, -2.484368526367199],
            [-44.473067916992186, -2.481368526367199],
            [-44.470567916992188, -2.479168526367197],
            [-44.465567916992185, -2.476668526367199],
            [-44.461567916992188, -2.476968526367195],
            [-44.460467916992187, -2.479768526367195],
            [-44.455667916992184, -2.474168526367194],
            [-44.450667916992188, -2.467868526367198],
            [-44.446767916992187, -2.462668526367196],
            [-44.444567916992185, -2.459368526367193],
            [-44.442967916992188, -2.456768526367199],
            [-44.444167916992185, -2.453568526367199],
            [-44.442967916992188, -2.450468526367196],
            [-44.444367916992185, -2.446068526367199],
            [-44.446267916992184, -2.442468526367193],
            [-44.448967916992189, -2.439368526367197],
            [-44.451767916992182, -2.437668526367197],
            [-44.455667916992184, -2.435568526367199],
            [-44.459067916992183, -2.433768526367196],
            [-44.467367916992188, -2.431168526367195],
            [-44.470567916992188, -2.429468526367195],
            [-44.473067916992186, -2.428068526367198],
            [-44.478467916992187, -2.429668526367195],
            [-44.484367916992184, -2.431168526367195],
            [-44.489667916992182, -2.433268526367193],
            [-44.493667916992187, -2.434668526367197],
            [-44.497167916992183, -2.434468526367198],
            [-44.499767916992184, -2.430268526367193],
            [-44.498367916992187, -2.425868526367196],
            [-44.495867916992182, -2.422768526367193],
            [-44.493267916992188, -2.420368526367199],
            [-44.488867916992184, -2.416968526367199],
            [-44.485267916992186, -2.414568526367198],
            [-44.479167916992182, -2.411768526367197],
            [-44.474267916992183, -2.412568526367195],
            [-44.471267916992183, -2.412568526367195],
            [-44.467567916992188, -2.411968526367197],
            [-44.463967916992182, -2.410568526367193],
            [-44.460467916992187, -2.410668526367196],
            [-44.457567916992183, -2.411668526367194],
            [-44.454667916992186, -2.412568526367195],
            [-44.451567916992182, -2.412068526367193],
            [-44.448167916992183, -2.411968526367197],
            [-44.444567916992185, -2.413868526367196],
            [-44.440767916992186, -2.413868526367196],
            [-44.439467916992186, -2.410868526367196],
            [-44.436067916992187, -2.408468526367194],
            [-44.431567916992186, -2.408368526367198],
            [-44.425067916992184, -2.408468526367194],
            [-44.420267916992188, -2.409768526367195],
            [-44.416467916992183, -2.410868526367196],
            [-44.413367916992186, -2.411968526367197],
            [-44.408667916992187, -2.409468526367199],
            [-44.405567916992183, -2.412068526367193],
            [-44.405567916992183, -2.4167685263672],
            [-44.401167916992186, -2.414568526367198],
            [-44.396867916992186, -2.412368526367196],
            [-44.392067916992183, -2.409768526367195],
            [-44.387667916992186, -2.408368526367198],
            [-44.385967916992186, -2.405168526367198],
            [-44.383267916992182, -2.401968526367199],
            [-44.381667916992185, -2.398768526367199],
            [-44.379967916992186, -2.395668526367196],
            [-44.378867916992185, -2.392868526367195],
            [-44.375967916992188, -2.390668526367193],
            [-44.374467916992188, -2.387068526367194],
            [-44.373067916992184, -2.382068526367199],
            [-44.372267916992186, -2.377968526367198],
            [-44.373267916992184, -2.371068526367196],
            [-44.374367916992185, -2.367168526367195],
            [-44.376867916992182, -2.362168526367199],
            [-44.379467916992184, -2.359668526367194],
            [-44.379167916992188, -2.356168526367199],
            [-44.374967916992183, -2.355668526367197],
            [-44.372267916992186, -2.352868526367196],
            [-44.370267916992184, -2.350268526367195],
            [-44.367667916992183, -2.348468526367199],
            [-44.364967916992185, -2.347068526367195],
            [-44.362867916992187, -2.344868526367193],
            [-44.361167916992187, -2.341968526367197],
            [-44.357867916992184, -2.338768526367197],
            [-44.358567916992186, -2.334068526367197],
            [-44.360367916992182, -2.330468526367198],
            [-44.361067916992184, -2.327368526367195],
            [-44.360667916992185, -2.324368526367195],
            [-44.361367916992187, -2.320968526367196],
            [-44.362867916992187, -2.315468526367198],
            [-44.364367916992187, -2.310768526367198],
            [-44.366067916992186, -2.306968526367193],
            [-44.368367916992185, -2.301168526367199],
            [-44.370767916992186, -2.296068526367193],
            [-44.372967916992188, -2.291168526367194],
            [-44.374367916992185, -2.2872685263672],
            [-44.375567916992182, -2.284468526367199],
            [-44.377067916992182, -2.281468526367199],
            [-44.379867916992183, -2.278768526367195],
            [-44.383567916992185, -2.276968526367199],
            [-44.386567916992185, -2.276468526367196],
            [-44.389667916992188, -2.2750685263672],
            [-44.385667916992183, -2.273268526367197],
            [-44.384967916992188, -2.270468526367196],
            [-44.384867916992185, -2.267568526367199],
            [-44.387767916992182, -2.257768526367194],
            [-44.389967916992184, -2.2491685263672],
            [-44.391067916992185, -2.246068526367196],
            [-44.392067916992183, -2.242468526367198],
            [-44.392867916992188, -2.238968526367195],
            [-44.393467916992186, -2.235668526367199],
            [-44.394767916992187, -2.229668526367199],
            [-44.395367916992186, -2.225568526367198],
            [-44.396567916992183, -2.222868526367193],
            [-44.400667916992184, -2.2217685263672],
            [-44.403167916992182, -2.218368526367193],
            [-44.400667916992184, -2.216568526367197],
            [-44.399467916992187, -2.213668526367194],
            [-44.403767916992187, -2.208968526367194],
            [-44.408067916992188, -2.204668526367193],
            [-44.410267916992183, -2.2018685263672],
            [-44.413067916992183, -2.198468526367193],
            [-44.417167916992184, -2.192468526367193],
            [-44.420367916992184, -2.185468526367195],
            [-44.422767916992186, -2.181168526367195],
            [-44.427567916992189, -2.175468526367197],
            [-44.429367916992184, -2.172468526367197],
            [-44.433367916992182, -2.166368526367194],
            [-44.437267916992184, -2.164168526367199],
            [-44.439867916992185, -2.161968526367197],
            [-44.444067916992182, -2.158868526367193],
            [-44.448167916992183, -2.155168526367198],
            [-44.451467916992186, -2.152868526367193],
            [-44.454667916992186, -2.1515685263672],
            [-44.458167916992188, -2.150368526367195],
            [-44.460867916992186, -2.147268526367199],
            [-44.463967916992182, -2.145468526367196],
            [-44.467367916992188, -2.147668526367198],
            [-44.469467916992187, -2.150468526367199],
            [-44.473467916992185, -2.151768526367199],
            [-44.477367916992186, -2.150968526367194],
            [-44.480667916992182, -2.149068526367195],
            [-44.484667916992187, -2.146468526367194],
            [-44.489867916992182, -2.1470685263672],
            [-44.494067916992186, -2.148168526367193],
            [-44.497667916992185, -2.148468526367196],
            [-44.500467916992186, -2.149568526367197],
            [-44.502967916992183, -2.1515685263672],
            [-44.507067916992185, -2.152968526367196],
            [-44.510767916992187, -2.153668526367198],
            [-44.515767916992182, -2.154068526367197],
            [-44.521267916992187, -2.155168526367198],
            [-44.526467916992182, -2.157668526367196],
            [-44.529867916992188, -2.162068526367193],
            [-44.528367916992188, -2.165868526367198],
            [-44.530167916992184, -2.168868526367199],
            [-44.531467916992185, -2.172768526367193],
            [-44.533767916992183, -2.176468526367195],
            [-44.537567916992188, -2.180468526367193],
            [-44.540267916992185, -2.183768526367196],
            [-44.539467916992187, -2.189068526367194],
            [-44.541367916992186, -2.191568526367199],
            [-44.544267916992183, -2.195168526367198],
            [-44.547767916992186, -2.1973685263672],
            [-44.550767916992186, -2.198568526367197],
            [-44.552467916992185, -2.201568526367197],
            [-44.556567916992186, -2.201068526367195],
            [-44.559467916992183, -2.203268526367197],
            [-44.560567916992184, -2.207168526367198],
            [-44.561667916992185, -2.210368526367198],
            [-44.571667916992183, -2.219468526367194],
            [-44.580167916992188, -2.226968526367195],
            [-44.583167916992188, -2.228868526367194],
            [-44.586867916992183, -2.228468526367195],
            [-44.587267916992182, -2.231768526367198],
            [-44.586967916992187, -2.235368526367196],
            [-44.587667916992189, -2.239468526367197],
            [-44.589867916992183, -2.243268526367196],
            [-44.592867916992184, -2.244068526367194],
            [-44.595967916992187, -2.245768526367193],
            [-44.597567916992183, -2.248868526367197],
            [-44.599567916992186, -2.251068526367199],
            [-44.602767916992185, -2.251668526367197],
            [-44.606067916992188, -2.252968526367198],
            [-44.609467916992187, -2.254468526367198],
            [-44.612167916992185, -2.256368526367197],
            [-44.614967916992185, -2.257768526367194],
            [-44.617767916992186, -2.260968526367193],
            [-44.620867916992182, -2.264568526367199],
            [-44.623267916992184, -2.267668526367196],
            [-44.624067916992182, -2.270768526367199],
            [-44.625567916992182, -2.2750685263672],
            [-44.624767916992184, -2.279768526367199],
            [-44.626067916992184, -2.285968526367199],
            [-44.627167916992185, -2.2902685263672],
            [-44.630667916992188, -2.292468526367195],
            [-44.634967916992188, -2.294668526367197],
            [-44.639667916992188, -2.295768526367198],
            [-44.644367916992188, -2.296168526367197],
            [-44.647567916992188, -2.295868526367194],
            [-44.650667916992184, -2.295268526367195],
            [-44.653767916992187, -2.293868526367199],
            [-44.656167916992182, -2.290868526367198],
            [-44.658067916992188, -2.288468526367197],
            [-44.661667916992187, -2.2872685263672],
            [-44.666467916992183, -2.287768526367195],
            [-44.671867916992184, -2.286968526367197],
            [-44.675067916992184, -2.283368526367198],
            [-44.677967916992188, -2.280868526367193],
            [-44.682267916992188, -2.271268526367194],
            [-44.683867916992185, -2.267068526367197],
            [-44.685167916992185, -2.263568526367195],
            [-44.685267916992188, -2.260668526367198],
            [-44.684867916992182, -2.257768526367194],
            [-44.682667916992187, -2.255468526367196],
            [-44.677567916992189, -2.253768526367196],
            [-44.674467916992185, -2.252768526367198],
            [-44.670767916992183, -2.251968526367193],
            [-44.666067916992183, -2.250268526367194],
            [-44.661267916992188, -2.247968526367195],
            [-44.657367916992186, -2.245168526367195],
            [-44.655167916992184, -2.242468526367198],
            [-44.652267916992187, -2.238568526367196],
            [-44.648667916992189, -2.237168526367199],
            [-44.645367916992186, -2.236168526367194],
            [-44.642167916992186, -2.2339685263672],
            [-44.638867916992183, -2.231668526367194],
            [-44.635467916992184, -2.2292685263672],
            [-44.632167916992188, -2.225368526367198],
            [-44.628467916992186, -2.221968526367199],
            [-44.625567916992182, -2.217868526367198],
            [-44.623767916992186, -2.213968526367196],
            [-44.620267916992184, -2.210368526367198],
            [-44.617467916992183, -2.207868526367193],
            [-44.616467916992185, -2.205068526367199],
            [-44.614367916992187, -2.202068526367199],
            [-44.611867916992182, -2.198968526367196],
            [-44.608667916992182, -2.194368526367199],
            [-44.605067916992184, -2.192068526367194],
            [-44.601667916992184, -2.189968526367196],
            [-44.598667916992184, -2.185768526367198],
            [-44.595567916992188, -2.181868526367197],
            [-44.592667916992184, -2.177568526367196],
            [-44.591267916992187, -2.173568526367198],
            [-44.587667916992189, -2.166068526367198],
            [-44.586767916992187, -2.163068526367198],
            [-44.586167916992189, -2.159768526367195],
            [-44.586567916992188, -2.156768526367195],
            [-44.587967916992184, -2.154068526367197],
            [-44.587567916992185, -2.151168526367194],
            [-44.583767916992187, -2.147668526367198],
            [-44.579967916992182, -2.143968526367196],
            [-44.582667916992186, -2.141768526367194],
            [-44.586567916992188, -2.138468526367198],
            [-44.588667916992186, -2.135768526367194],
            [-44.585967916992182, -2.133868526367195],
            [-44.581767916992185, -2.132668526367198],
            [-44.579867916992185, -2.128568526367197],
            [-44.577467916992184, -2.126668526367197],
            [-44.567767916992182, -2.126368526367195],
            [-44.566367916992185, -2.118368526367199],
            [-44.565467916992183, -2.110268526367193],
            [-44.562667916992183, -2.107168526367197],
            [-44.558767916992188, -2.101668526367199],
            [-44.555867916992185, -2.098368526367196],
            [-44.553867916992182, -2.094468526367194],
            [-44.553567916992186, -2.091168526367198],
            [-44.551367916992184, -2.088668526367194],
            [-44.547467916992183, -2.090868526367196],
            [-44.543167916992182, -2.090068526367197],
            [-44.538067916992183, -2.086868526367198],
            [-44.533767916992183, -2.084068526367197],
            [-44.528767916992187, -2.078868526367195],
            [-44.526567916992185, -2.0738685263672],
            [-44.522267916992185, -2.072468526367196],
            [-44.519267916992185, -2.070768526367196],
            [-44.516767916992187, -2.068268526367198],
            [-44.514567916992185, -2.065268526367198],
            [-44.511267916992182, -2.062768526367194],
            [-44.505967916992184, -2.057968526367198],
            [-44.503567916992182, -2.055168526367197],
            [-44.500167916992183, -2.053568526367194],
            [-44.498367916992187, -2.051168526367199],
            [-44.496167916992185, -2.048568526367198],
            [-44.492567916992186, -2.048668526367194],
            [-44.489967916992185, -2.047468526367197],
            [-44.486967916992185, -2.0449685263672],
            [-44.486067916992184, -2.040968526367195],
            [-44.489667916992182, -2.0402685263672],
            [-44.492467916992183, -2.038468526367197],
            [-44.495867916992182, -2.031468526367199],
            [-44.496367916992185, -2.027868526367193],
            [-44.497967916992188, -2.0250685263672],
            [-44.502167916992185, -2.023768526367199],
            [-44.505667916992188, -2.026268526367197],
            [-44.509867916992185, -2.029168526367194],
            [-44.513767916992187, -2.031968526367194],
            [-44.518467916992186, -2.034568526367195],
            [-44.522267916992185, -2.034468526367199],
            [-44.529467916992182, -2.035068526367198],
            [-44.533667916992187, -2.035568526367193],
            [-44.539167916992184, -2.033368526367198],
            [-44.543067916992186, -2.030968526367197],
            [-44.546367916992182, -2.028168526367196],
            [-44.550467916992183, -2.026168526367194],
            [-44.553867916992182, -2.0250685263672],
            [-44.557267916992188, -2.024368526367198],
            [-44.560867916992187, -2.023768526367199],
            [-44.564667916992185, -2.0220685263672],
            [-44.569667916992188, -2.022568526367195],
            [-44.572367916992185, -2.025868526367198],
            [-44.575967916992184, -2.029868526367196],
            [-44.579067916992187, -2.029068526367197],
            [-44.576367916992183, -2.024868526367193],
            [-44.573267916992187, -2.020768526367199],
            [-44.570267916992186, -2.018168526367198],
            [-44.566267916992182, -2.016068526367199],
            [-44.563067916992182, -2.015368526367197],
            [-44.559167916992187, -2.016068526367199],
            [-44.555767916992188, -2.017168526367193],
            [-44.551467916992188, -2.017568526367199],
            [-44.547567916992186, -2.017868526367195],
            [-44.544267916992183, -2.017968526367198],
            [-44.540567916992188, -2.019368526367195],
            [-44.535167916992187, -2.019368526367195],
            [-44.531267916992185, -2.017468526367196],
            [-44.526767916992185, -2.015668526367193],
            [-44.522567916992188, -2.014268526367196],
            [-44.518967916992182, -2.013568526367195],
            [-44.514367916992185, -2.012068526367194],
            [-44.509367916992183, -2.009968526367196],
            [-44.506067916992187, -2.007068526367199],
            [-44.503767916992182, -2.004668526367198],
            [-44.501867916992182, -2.002468526367196],
            [-44.497767916992188, -1.999668526367195],
            [-44.494067916992186, -1.9976685263672],
            [-44.490567916992184, -1.993668526367195],
            [-44.486667916992182, -1.991168526367197],
            [-44.483567916992186, -1.989068526367198],
            [-44.482767916992188, -1.986068526367198],
            [-44.486667916992182, -1.982768526367195],
            [-44.490867916992187, -1.980868526367196],
            [-44.491167916992183, -1.977468526367197],
            [-44.493667916992187, -1.975268526367195],
            [-44.495467916992183, -1.972368526367198],
            [-44.495767916992186, -1.968768526367199],
            [-44.499067916992182, -1.966968526367197],
            [-44.503567916992182, -1.964768526367195],
            [-44.508467916992188, -1.961468526367199],
            [-44.510967916992186, -1.958268526367199],
            [-44.508767916992184, -1.956268526367197],
            [-44.505567916992185, -1.957668526367193],
            [-44.503467916992186, -1.959868526367195],
            [-44.501067916992184, -1.961468526367199],
            [-44.497967916992188, -1.9625685263672],
            [-44.493567916992184, -1.962968526367199],
            [-44.490467916992188, -1.959368526367193],
            [-44.489467916992183, -1.954368526367197],
            [-44.490467916992188, -1.948968526367196],
            [-44.491867916992184, -1.943468526367198],
            [-44.493367916992185, -1.939868526367199],
            [-44.494767916992188, -1.935768526367198],
            [-44.496267916992188, -1.932168526367199],
            [-44.499667916992188, -1.923868526367194],
            [-44.508267916992182, -1.900668526367198],
            [-44.512667916992186, -1.889068526367197],
            [-44.514967916992184, -1.884968526367196],
            [-44.517667916992188, -1.882168526367195],
            [-44.520467916992182, -1.880668526367195],
            [-44.524267916992187, -1.879468526367198],
            [-44.529067916992183, -1.879068526367199],
            [-44.534467916992185, -1.881668526367193],
            [-44.535967916992185, -1.884268526367194],
            [-44.538067916992183, -1.887668526367193],
            [-44.542767916992183, -1.889968526367198],
            [-44.547767916992186, -1.891068526367199],
            [-44.551967916992183, -1.887468526367194],
            [-44.553567916992186, -1.883868526367195],
            [-44.552867916992184, -1.880168526367193],
            [-44.551867916992187, -1.876668526367197],
            [-44.549667916992185, -1.874068526367196],
            [-44.547767916992186, -1.8711685263672],
            [-44.545267916992188, -1.866868526367199],
            [-44.544167916992187, -1.862568526367198],
            [-44.541367916992186, -1.857868526367199],
            [-44.539867916992186, -1.853968526367197],
            [-44.539267916992188, -1.849968526367199],
            [-44.540967916992187, -1.843768526367199],
            [-44.541467916992183, -1.839768526367195],
            [-44.540367916992182, -1.836568526367195],
            [-44.538867916992189, -1.833668526367198],
            [-44.537767916992188, -1.830968526367194],
            [-44.535567916992186, -1.827868526367197],
            [-44.533467916992187, -1.824868526367197],
            [-44.534767916992188, -1.822068526367197],
            [-44.538867916992189, -1.820568526367197],
            [-44.541767916992185, -1.819668526367195],
            [-44.544767916992186, -1.820468526367193],
            [-44.547167916992187, -1.823768526367196],
            [-44.547767916992186, -1.827668526367198],
            [-44.548967916992183, -1.831068526367197],
            [-44.550067916992184, -1.834068526367197],
            [-44.551867916992187, -1.837868526367195],
            [-44.554767916992184, -1.837668526367196],
            [-44.555867916992185, -1.833968526367194],
            [-44.560267916992188, -1.834268526367197],
            [-44.563867916992187, -1.835468526367194],
            [-44.563067916992182, -1.830668526367198],
            [-44.565767916992186, -1.828168526367193],
            [-44.567367916992183, -1.824568526367194],
            [-44.565567916992187, -1.820968526367196],
            [-44.567667916992185, -1.816868526367195],
            [-44.566367916992185, -1.813368526367199],
            [-44.565167916992188, -1.809968526367193],
            [-44.563467916992188, -1.806968526367193],
            [-44.564567916992182, -1.802568526367196],
            [-44.567467916992186, -1.799368526367196],
            [-44.571567916992187, -1.796168526367197],
            [-44.576267916992187, -1.795368526367199],
            [-44.580167916992188, -1.793468526367199],
            [-44.583667916992184, -1.791468526367197],
            [-44.586467916992184, -1.789168526367199],
            [-44.589067916992185, -1.785368526367193],
            [-44.590167916992186, -1.781568526367195],
            [-44.587267916992182, -1.779468526367197],
            [-44.583767916992187, -1.778368526367196],
            [-44.580367916992188, -1.777268526367195],
            [-44.576567916992182, -1.777268526367195],
            [-44.573467916992186, -1.776268526367197],
            [-44.578767916992184, -1.773668526367196],
            [-44.582667916992186, -1.772368526367195],
            [-44.586867916992183, -1.771068526367195],
            [-44.592067916992185, -1.768968526367196],
            [-44.595367916992188, -1.768168526367198],
            [-44.598367916992188, -1.768668526367193],
            [-44.602467916992182, -1.766268526367199],
            [-44.606667916992187, -1.767168526367193],
            [-44.611067916992184, -1.766068526367199],
            [-44.614967916992185, -1.763568526367195],
            [-44.617167916992187, -1.759968526367196],
            [-44.614167916992187, -1.757368526367195],
            [-44.611367916992187, -1.755568526367199],
            [-44.606367916992184, -1.751668526367197],
            [-44.603367916992184, -1.750268526367194],
            [-44.599967916992185, -1.747968526367195],
            [-44.598467916992185, -1.743668526367195],
            [-44.601367916992189, -1.739668526367197],
            [-44.604967916992187, -1.737468526367195],
            [-44.607567916992188, -1.734968526367197],
            [-44.610367916992182, -1.732768526367195],
            [-44.613867916992184, -1.730968526367199],
            [-44.616567916992182, -1.729668526367199],
            [-44.620167916992187, -1.7277685263672],
            [-44.622667916992185, -1.725968526367197],
            [-44.625567916992182, -1.724468526367197],
            [-44.638167916992188, -1.717868526367198],
            [-44.642467916992182, -1.716468526367194],
            [-44.645767916992185, -1.715668526367196],
            [-44.648967916992184, -1.715168526367194],
            [-44.652367916992183, -1.715468526367196],
            [-44.656767916992187, -1.718468526367197],
            [-44.658467916992187, -1.720968526367194],
            [-44.657567916992186, -1.723968526367194],
            [-44.653767916992187, -1.724868526367196],
            [-44.651567916992185, -1.727868526367196],
            [-44.650067916992185, -1.7339685263672],
            [-44.649067916992188, -1.737968526367197],
            [-44.648367916992186, -1.741168526367197],
            [-44.652967916992182, -1.741368526367197],
            [-44.653367916992188, -1.746368526367199],
            [-44.652067916992188, -1.750868526367199],
            [-44.650767916992187, -1.755168526367193],
            [-44.647567916992188, -1.759268526367194],
            [-44.646767916992182, -1.763568526367195],
            [-44.650467916992184, -1.763168526367195],
            [-44.652967916992182, -1.767168526367193],
            [-44.654767916992185, -1.770068526367197],
            [-44.657667916992182, -1.770668526367196],
            [-44.662267916992185, -1.770668526367196],
            [-44.663867916992189, -1.767868526367195],
            [-44.666467916992183, -1.765368526367197],
            [-44.670067916992188, -1.766368526367195],
            [-44.673367916992184, -1.7673685263672],
            [-44.677967916992188, -1.769068526367199],
            [-44.681567916992186, -1.771468526367194],
            [-44.685567916992184, -1.770768526367199],
            [-44.688767916992184, -1.771768526367197],
            [-44.691567916992184, -1.771168526367198],
            [-44.694567916992185, -1.770168526367193],
            [-44.698567916992182, -1.769068526367199],
            [-44.702967916992186, -1.769668526367198],
            [-44.704367916992183, -1.773468526367196],
            [-44.706467916992182, -1.776468526367196],
            [-44.708667916992184, -1.779768526367199],
            [-44.710967916992182, -1.784168526367196],
            [-44.713767916992182, -1.789568526367198],
            [-44.716967916992182, -1.789968526367197],
            [-44.720267916992185, -1.788368526367194],
            [-44.720167916992182, -1.785068526367198],
            [-44.719767916992183, -1.781168526367196],
            [-44.723067916992186, -1.780868526367193],
            [-44.727467916992182, -1.780868526367193],
            [-44.731367916992184, -1.781268526367199],
            [-44.734967916992183, -1.782668526367196],
            [-44.738267916992186, -1.784768526367195],
            [-44.741067916992186, -1.785868526367196],
            [-44.745267916992184, -1.786968526367197],
            [-44.750167916992183, -1.788168526367194],
            [-44.754567916992187, -1.789468526367195],
            [-44.757767916992186, -1.788668526367196],
            [-44.759367916992183, -1.785868526367196],
            [-44.758067916992182, -1.782368526367193],
            [-44.754067916992184, -1.780068526367195],
            [-44.751867916992182, -1.777268526367195],
            [-44.749067916992182, -1.774068526367195],
            [-44.745767916992186, -1.771168526367198],
            [-44.741867916992184, -1.767868526367195],
            [-44.738267916992186, -1.765068526367195],
            [-44.735767916992188, -1.763168526367195],
            [-44.731467916992187, -1.761068526367197],
            [-44.727767916992185, -1.759268526367194],
            [-44.724967916992185, -1.756068526367194],
            [-44.723167916992182, -1.752368526367199],
            [-44.719767916992183, -1.751268526367198],
            [-44.717267916992185, -1.749468526367195],
            [-44.715167916992186, -1.746568526367199],
            [-44.713967916992182, -1.742768526367193],
            [-44.710967916992182, -1.740268526367196],
            [-44.708467916992184, -1.736868526367196],
            [-44.704667916992186, -1.739368526367194],
            [-44.702367916992188, -1.737168526367199],
            [-44.700267916992182, -1.732568526367196],
            [-44.700267916992182, -1.7277685263672],
            [-44.702567916992187, -1.723468526367199],
            [-44.705667916992184, -1.720568526367195],
            [-44.708667916992184, -1.717868526367198],
            [-44.712667916992189, -1.714768526367195],
            [-44.716167916992184, -1.713968526367196],
            [-44.718167916992186, -1.716568526367197],
            [-44.720267916992185, -1.718768526367199],
            [-44.723367916992188, -1.721168526367194],
            [-44.726667916992184, -1.722068526367195],
            [-44.731067916992188, -1.721268526367197],
            [-44.735367916992182, -1.721668526367196],
            [-44.738667916992185, -1.7217685263672],
            [-44.742267916992184, -1.720968526367194],
            [-44.746267916992188, -1.719868526367193],
            [-44.750467916992186, -1.717968526367194],
            [-44.754167916992188, -1.717668526367198],
            [-44.756867916992185, -1.714768526367195],
            [-44.759167916992183, -1.711468526367199],
            [-44.760967916992186, -1.708268526367199],
            [-44.763167916992188, -1.705168526367196],
            [-44.766767916992187, -1.703468526367196],
            [-44.770667916992188, -1.703568526367199],
            [-44.774067916992188, -1.704568526367197],
            [-44.776767916992185, -1.705468526367198],
            [-44.779067916992183, -1.708468526367199],
            [-44.778767916992187, -1.711768526367194],
            [-44.781667916992184, -1.712868526367195],
            [-44.786367916992184, -1.710868526367193],
            [-44.789767916992183, -1.706768526367199],
            [-44.794967916992185, -1.705468526367198],
            [-44.798367916992184, -1.702968526367194],
            [-44.795367916992184, -1.702168526367196],
            [-44.792867916992186, -1.699268526367199],
            [-44.789867916992186, -1.694568526367199],
            [-44.786767916992183, -1.691968526367198],
            [-44.783767916992183, -1.690268526367198],
            [-44.780967916992182, -1.689068526367194],
            [-44.779467916992182, -1.685268526367196],
            [-44.778367916992188, -1.681668526367197],
            [-44.774467916992187, -1.679468526367195],
            [-44.771467916992187, -1.678268526367198],
            [-44.767467916992182, -1.676168526367199],
            [-44.764267916992182, -1.674668526367199],
            [-44.760467916992184, -1.674168526367197],
            [-44.757367916992187, -1.671668526367199],
            [-44.753867916992185, -1.668368526367196],
            [-44.751967916992186, -1.6652685263672],
            [-44.749767916992184, -1.662068526367193],
            [-44.748367916992187, -1.658668526367194],
            [-44.746867916992187, -1.655468526367194],
            [-44.746867916992187, -1.651268526367197],
            [-44.744167916992183, -1.652868526367193],
            [-44.740567916992184, -1.652868526367193],
            [-44.737567916992184, -1.650668526367198],
            [-44.732467916992185, -1.649568526367197],
            [-44.727767916992185, -1.648468526367196],
            [-44.722367916992184, -1.647968526367194],
            [-44.716967916992182, -1.647968526367194],
            [-44.711767916992187, -1.648168526367193],
            [-44.708267916992185, -1.647568526367195],
            [-44.705367916992188, -1.643768526367197],
            [-44.702567916992187, -1.645468526367196],
            [-44.697167916992186, -1.643768526367197],
            [-44.692467916992186, -1.642668526367196],
            [-44.687667916992183, -1.640968526367196],
            [-44.684367916992187, -1.642668526367196],
            [-44.681667916992183, -1.643968526367196],
            [-44.677467916992185, -1.642168526367193],
            [-44.673867916992187, -1.643968526367196],
            [-44.671367916992182, -1.646068526367195],
            [-44.671667916992185, -1.649068526367195],
            [-44.670767916992183, -1.653368526367196],
            [-44.667467916992187, -1.657868526367196],
            [-44.664267916992188, -1.659768526367195],
            [-44.661267916992188, -1.662068526367193],
            [-44.658467916992187, -1.659768526367195],
            [-44.656767916992187, -1.656268526367199],
            [-44.653767916992187, -1.649768526367197],
            [-44.649867916992186, -1.638968526367194],
            [-44.643967916992182, -1.628368526367197],
            [-44.640067916992187, -1.624368526367199],
            [-44.637467916992186, -1.620768526367193],
            [-44.636367916992185, -1.617268526367198],
            [-44.638167916992188, -1.614168526367195],
            [-44.640667916992186, -1.609968526367197],
            [-44.642067916992183, -1.607268526367193],
            [-44.644267916992185, -1.6042685263672],
            [-44.647867916992183, -1.601168526367196],
            [-44.652367916992183, -1.594168526367199],
            [-44.654867916992188, -1.590468526367196],
            [-44.657067916992183, -1.587668526367196],
            [-44.661667916992187, -1.583968526367194],
            [-44.665867916992184, -1.581168526367193],
            [-44.668867916992184, -1.578468526367196],
            [-44.673267916992188, -1.576568526367197],
            [-44.675367916992187, -1.573568526367197],
            [-44.676567916992184, -1.570468526367193],
            [-44.679167916992185, -1.567968526367196],
            [-44.682467916992188, -1.565968526367193],
            [-44.686367916992182, -1.565868526367197],
            [-44.688367916992185, -1.568468526367198],
            [-44.689467916992186, -1.571268526367199],
            [-44.691667916992188, -1.574668526367198],
            [-44.695267916992186, -1.580768526367194],
            [-44.699867916992183, -1.583168526367196],
            [-44.703267916992182, -1.583168526367196],
            [-44.707567916992183, -1.581268526367197],
            [-44.709567916992185, -1.578568526367199],
            [-44.707867916992186, -1.574968526367194],
            [-44.706867916992188, -1.571368526367195],
            [-44.706867916992188, -1.568468526367198],
            [-44.706767916992185, -1.565168526367195],
            [-44.706167916992186, -1.561568526367196],
            [-44.705367916992188, -1.558268526367193],
            [-44.705367916992188, -1.5539685263672],
            [-44.707867916992186, -1.551868526367194],
            [-44.712267916992182, -1.553268526367198],
            [-44.715467916992182, -1.554368526367199],
            [-44.719267916992187, -1.555468526367193],
            [-44.723367916992188, -1.556668526367197],
            [-44.725267916992188, -1.559368526367194],
            [-44.728367916992184, -1.564468526367193],
            [-44.733967916992185, -1.571268526367199],
            [-44.739967916992185, -1.576268526367194],
            [-44.743067916992182, -1.579368526367197],
            [-44.748067916992184, -1.581568526367199],
            [-44.750967916992188, -1.583168526367196],
            [-44.755167916992185, -1.587268526367197],
            [-44.757667916992183, -1.591168526367198],
            [-44.762767916992182, -1.594068526367195],
            [-44.767067916992183, -1.593968526367199],
            [-44.768767916992182, -1.590968526367199],
            [-44.768267916992187, -1.587668526367196],
            [-44.767567916992185, -1.583268526367199],
            [-44.767867916992188, -1.577868526367197],
            [-44.768267916992187, -1.572468526367196],
            [-44.767867916992188, -1.568568526367194],
            [-44.767167916992186, -1.563768526367198],
            [-44.770367916992186, -1.561868526367199],
            [-44.772367916992188, -1.564968526367196],
            [-44.773667916992189, -1.567768526367196],
            [-44.775467916992184, -1.572668526367195],
            [-44.778067916992185, -1.577668526367198],
            [-44.781967916992187, -1.5798685263672],
            [-44.787767916992188, -1.580768526367194],
            [-44.792067916992188, -1.581168526367193],
            [-44.795767916992183, -1.584368526367193],
            [-44.799767916992188, -1.587868526367195],
            [-44.802867916992184, -1.589268526367199],
            [-44.806967916992186, -1.588668526367194],
            [-44.810767916992184, -1.586868526367198],
            [-44.814067916992187, -1.583268526367199],
            [-44.817367916992183, -1.580768526367194],
            [-44.819867916992187, -1.578968526367198],
            [-44.822667916992188, -1.577368526367195],
            [-44.826867916992185, -1.575668526367195],
            [-44.831867916992188, -1.573268526367194],
            [-44.835467916992187, -1.573568526367197],
            [-44.836567916992188, -1.579068526367195],
            [-44.836567916992188, -1.583968526367194],
            [-44.837267916992182, -1.586968526367194],
            [-44.837367916992186, -1.591268526367195],
            [-44.838667916992186, -1.595368526367196],
            [-44.839467916992184, -1.598468526367199],
            [-44.841267916992187, -1.602368526367194],
            [-44.842867916992184, -1.604768526367195],
            [-44.845567916992188, -1.608668526367197],
            [-44.848767916992188, -1.611868526367196],
            [-44.852767916992185, -1.616168526367197],
            [-44.855667916992182, -1.618268526367196],
            [-44.859967916992183, -1.618368526367199],
            [-44.863367916992182, -1.614668526367197],
            [-44.864967916992185, -1.610368526367196],
            [-44.866967916992188, -1.6074685263672],
            [-44.869067916992186, -1.604168526367197],
            [-44.871267916992188, -1.601968526367195],
            [-44.875167916992183, -1.601668526367199],
            [-44.879067916992184, -1.601768526367195],
            [-44.882967916992186, -1.602268526367197],
            [-44.886267916992182, -1.603168526367199],
            [-44.889667916992188, -1.603568526367198],
            [-44.893567916992183, -1.604168526367197],
            [-44.896567916992183, -1.604968526367195],
            [-44.899267916992187, -1.606668526367194],
            [-44.902367916992183, -1.606668526367194],
            [-44.905167916992184, -1.604768526367195],
            [-44.908467916992187, -1.601968526367195],
            [-44.910867916992188, -1.598468526367199],
            [-44.912067916992186, -1.595568526367195],
            [-44.912267916992185, -1.591268526367195],
            [-44.911367916992184, -1.586568526367195],
            [-44.910967916992185, -1.581868526367195],
            [-44.912367916992189, -1.578168526367193],
            [-44.914467916992187, -1.574368526367195],
            [-44.918067916992186, -1.570968526367196],
            [-44.917467916992187, -1.565268526367198],
            [-44.915067916992186, -1.562368526367194],
            [-44.913467916992182, -1.559468526367198],
            [-44.912467916992185, -1.556668526367197],
            [-44.912767916992188, -1.552968526367195],
            [-44.915067916992186, -1.548668526367194],
            [-44.916467916992183, -1.543868526367199],
            [-44.917467916992187, -1.539868526367194],
            [-44.913467916992182, -1.540668526367199],
            [-44.908067916992188, -1.538468526367197],
            [-44.903667916992184, -1.537368526367196],
            [-44.900367916992188, -1.535568526367193],
            [-44.898467916992182, -1.531168526367196],
            [-44.895467916992182, -1.531968526367194],
            [-44.897067916992185, -1.528668526367198],
            [-44.894267916992185, -1.5250685263672],
            [-44.893967916992182, -1.521868526367193],
            [-44.890767916992182, -1.518668526367193],
            [-44.890667916992186, -1.514668526367196],
            [-44.894567916992187, -1.512468526367194],
            [-44.892367916992185, -1.509068526367194],
            [-44.888167916992188, -1.508168526367193],
            [-44.884867916992185, -1.506568526367197],
            [-44.884067916992187, -1.503768526367196],
            [-44.881667916992185, -1.500868526367199],
            [-44.879067916992184, -1.498068526367199],
            [-44.877767916992184, -1.494968526367195],
            [-44.880167916992185, -1.491168526367197],
            [-44.876567916992187, -1.4899685263672],
            [-44.872667916992185, -1.487968526367197],
            [-44.874867916992187, -1.484368526367199],
            [-44.877767916992184, -1.482868526367199],
            [-44.881367916992183, -1.481468526367195],
            [-44.884567916992182, -1.480568526367193],
            [-44.883467916992188, -1.4777685263672],
            [-44.879067916992184, -1.478468526367195],
            [-44.874367916992185, -1.480568526367193],
            [-44.871567916992184, -1.482168526367197],
            [-44.868667916992187, -1.483568526367193],
            [-44.864967916992185, -1.485368526367196],
            [-44.860767916992188, -1.486368526367194],
            [-44.857867916992184, -1.484968526367197],
            [-44.854167916992182, -1.484668526367194],
            [-44.850967916992182, -1.485868526367199],
            [-44.847067916992188, -1.487768526367198],
            [-44.843467916992182, -1.487868526367194],
            [-44.843067916992183, -1.483168526367194],
            [-44.843467916992182, -1.479168526367197],
            [-44.841667916992186, -1.470868526367198],
            [-44.840167916992186, -1.462268526367197],
            [-44.838967916992182, -1.456868526367195],
            [-44.837867916992188, -1.451068526367195],
            [-44.836967916992187, -1.447468526367196],
            [-44.836167916992189, -1.443768526367194],
            [-44.835167916992184, -1.440268526367198],
            [-44.832867916992186, -1.437268526367198],
            [-44.830367916992188, -1.433268526367193],
            [-44.830667916992184, -1.428268526367198],
            [-44.834367916992186, -1.4244685263672],
            [-44.837567916992185, -1.421368526367196],
            [-44.841467916992187, -1.418868526367199],
            [-44.845867916992184, -1.416168526367194],
            [-44.849467916992182, -1.414568526367198],
            [-44.853867916992186, -1.412568526367195],
            [-44.857167916992182, -1.411968526367197],
            [-44.860067916992186, -1.411368526367198],
            [-44.862967916992183, -1.412368526367196],
            [-44.866567916992182, -1.413668526367196],
            [-44.870267916992184, -1.414268526367195],
            [-44.873367916992187, -1.4152685263672],
            [-44.876067916992184, -1.416968526367199],
            [-44.879967916992186, -1.418468526367199],
            [-44.883067916992182, -1.419168526367194],
            [-44.885267916992184, -1.421668526367199],
            [-44.881367916992183, -1.424168526367197],
            [-44.883767916992184, -1.426168526367199],
            [-44.883867916992187, -1.429768526367198],
            [-44.884167916992183, -1.433868526367199],
            [-44.885267916992184, -1.437968526367193],
            [-44.887467916992186, -1.442368526367197],
            [-44.892467916992182, -1.442668526367193],
            [-44.896267916992187, -1.445168526367198],
            [-44.898967916992184, -1.446268526367199],
            [-44.901567916992185, -1.449568526367194],
            [-44.906267916992185, -1.452868526367197],
            [-44.910167916992187, -1.454968526367196],
            [-44.911967916992182, -1.457368526367198],
            [-44.913367916992186, -1.460068526367195],
            [-44.916167916992187, -1.460868526367193],
            [-44.920367916992184, -1.462968526367199],
            [-44.922167916992187, -1.467268526367199],
            [-44.923567916992184, -1.469868526367193],
            [-44.926067916992189, -1.472368526367198],
            [-44.929667916992187, -1.474868526367196],
            [-44.933567916992182, -1.477068526367198],
            [-44.935567916992184, -1.480268526367198],
            [-44.935767916992184, -1.483868526367196],
            [-44.935767916992184, -1.488268526367193],
            [-44.939067916992187, -1.490468526367195],
            [-44.942367916992183, -1.490268526367196],
            [-44.946067916992185, -1.490868526367194],
            [-44.949867916992183, -1.491168526367197],
            [-44.953567916992185, -1.491168526367197],
            [-44.954567916992183, -1.484768526367198],
            [-44.949367916992188, -1.482868526367199],
            [-44.951567916992182, -1.479668526367199],
            [-44.954967916992182, -1.4777685263672],
            [-44.958167916992188, -1.474468526367197],
            [-44.962867916992188, -1.474468526367197],
            [-44.966167916992184, -1.475568526367198],
            [-44.969267916992187, -1.478368526367198],
            [-44.971367916992186, -1.4824685263672],
            [-44.972867916992186, -1.485568526367196],
            [-44.974167916992187, -1.489068526367198],
            [-44.974467916992182, -1.495168526367195],
            [-44.973867916992184, -1.4991685263672],
            [-44.972367916992184, -1.503468526367193],
            [-44.966967916992182, -1.510668526367198],
            [-44.963967916992182, -1.512168526367198],
            [-44.960167916992184, -1.514068526367197],
            [-44.956867916992188, -1.518768526367197],
            [-44.953467916992182, -1.524268526367194],
            [-44.951067916992187, -1.529268526367197],
            [-44.949267916992184, -1.534768526367195],
            [-44.948167916992183, -1.538368526367194],
            [-44.947967916992184, -1.541668526367197],
            [-44.948567916992182, -1.548268526367195],
            [-44.949267916992184, -1.553068526367198],
            [-44.951067916992187, -1.557268526367196],
            [-44.953567916992185, -1.5601685263672],
            [-44.957067916992187, -1.560768526367198],
            [-44.960167916992184, -1.559468526367198],
            [-44.962867916992188, -1.557168526367199],
            [-44.963267916992187, -1.554068526367196],
            [-44.961867916992183, -1.551068526367196],
            [-44.961167916992189, -1.543368526367196],
            [-44.962267916992182, -1.539968526367197],
            [-44.963667916992186, -1.536168526367199],
            [-44.966267916992187, -1.532868526367196],
            [-44.970667916992184, -1.529468526367197],
            [-44.975867916992186, -1.527268526367195],
            [-44.979767916992188, -1.5250685263672],
            [-44.982167916992182, -1.527868526367193],
            [-44.985067916992186, -1.529468526367197],
            [-44.989667916992182, -1.530668526367194],
            [-44.993667916992187, -1.533068526367195],
            [-44.996967916992183, -1.534768526367195],
            [-45.000867916992185, -1.534868526367198],
            [-45.005167916992185, -1.533368526367198],
            [-45.007767916992186, -1.529868526367196],
            [-45.008467916992188, -1.524768526367197],
            [-45.007067916992185, -1.520768526367199],
            [-45.003467916992186, -1.519368526367195],
            [-45.000167916992183, -1.517868526367195],
            [-44.996667916992187, -1.516268526367199],
            [-44.992267916992184, -1.5143685263672],
            [-44.990067916992189, -1.511068526367197],
            [-44.993067916992182, -1.506768526367196],
            [-44.994067916992186, -1.502468526367196],
            [-44.997167916992183, -1.500468526367193],
            [-44.999867916992187, -1.498068526367199],
            [-45.001267916992184, -1.494468526367193],
            [-45.002467916992188, -1.491068526367194],
            [-45.004367916992187, -1.488368526367196],
            [-45.006667916992185, -1.486168526367194],
            [-45.011067916992182, -1.485768526367195],
            [-45.014867916992188, -1.485368526367196],
            [-45.018167916992184, -1.487268526367195],
            [-45.020467916992182, -1.491068526367194],
            [-45.023967916992184, -1.491368526367197],
            [-45.028767916992187, -1.492468526367198],
            [-45.033467916992187, -1.491868526367199],
            [-45.038667916992182, -1.490568526367198],
            [-45.042767916992183, -1.491068526367194],
            [-45.046167916992182, -1.490768526367198],
            [-45.049767916992188, -1.488568526367196],
            [-45.052867916992184, -1.486768526367193],
            [-45.056067916992184, -1.483368526367194],
            [-45.061267916992186, -1.4824685263672],
            [-45.064967916992188, -1.482768526367195],
            [-45.069567916992185, -1.480168526367194],
            [-45.070167916992183, -1.474568526367193],
            [-45.070267916992186, -1.470368526367196],
            [-45.070967916992188, -1.467568526367195],
            [-45.079067916992187, -1.460368526367198],
            [-45.084267916992182, -1.458268526367199],
            [-45.087967916992184, -1.457168526367198],
            [-45.091267916992187, -1.456268526367197],
            [-45.095867916992184, -1.455968526367194],
            [-45.097567916992183, -1.459868526367195],
            [-45.098967916992187, -1.4625685263672],
            [-45.100067916992188, -1.467568526367195],
            [-45.102367916992186, -1.471968526367199],
            [-45.106667916992187, -1.474468526367197],
            [-45.110267916992186, -1.475268526367195],
            [-45.115867916992187, -1.476168526367196],
            [-45.120067916992184, -1.476368526367196],
            [-45.122967916992188, -1.476968526367195],
            [-45.126067916992184, -1.478968526367197],
            [-45.129067916992184, -1.480668526367197],
            [-45.131667916992185, -1.4839685263672],
            [-45.135967916992186, -1.484968526367197],
            [-45.140467916992186, -1.4839685263672],
            [-45.143767916992182, -1.481768526367198],
            [-45.145667916992188, -1.477568526367193],
            [-45.148167916992186, -1.473768526367195],
            [-45.150367916992188, -1.470968526367194],
            [-45.153667916992184, -1.470868526367198],
            [-45.156967916992187, -1.473368526367196],
            [-45.160267916992183, -1.477468526367197],
            [-45.162867916992184, -1.482068526367193],
            [-45.165667916992184, -1.485368526367196],
            [-45.166467916992183, -1.488568526367196],
            [-45.169967916992185, -1.491868526367199],
            [-45.173567916992184, -1.494168526367197],
            [-45.177767916992188, -1.495768526367193],
            [-45.181967916992186, -1.495768526367193],
            [-45.184867916992182, -1.495268526367198],
            [-45.187667916992183, -1.493868526367194],
            [-45.191267916992182, -1.488968526367195],
            [-45.196767916992187, -1.488268526367193],
            [-45.199867916992183, -1.491868526367199],
            [-45.201767916992182, -1.495768526367193],
            [-45.202167916992188, -1.499668526367195],
            [-45.203167916992186, -1.505968526367198],
            [-45.203467916992182, -1.510368526367195],
            [-45.203967916992184, -1.513968526367194],
            [-45.205467916992184, -1.518468526367194],
            [-45.207267916992187, -1.521068526367195],
            [-45.211167916992189, -1.525468526367199],
            [-45.212067916992183, -1.528368526367196],
            [-45.212967916992184, -1.531768526367195],
            [-45.215567916992185, -1.534168526367196],
            [-45.217367916992188, -1.536668526367194],
            [-45.219867916992186, -1.538168526367194],
            [-45.222267916992188, -1.540668526367199],
            [-45.225367916992184, -1.542468526367195],
            [-45.227867916992182, -1.544568526367193],
            [-45.231067916992188, -1.547168526367194],
            [-45.232467916992185, -1.551068526367196],
            [-45.234967916992183, -1.554368526367199],
            [-45.236367916992187, -1.557968526367198],
            [-45.236367916992187, -1.5616685263672],
            [-45.239967916992185, -1.564068526367194],
            [-45.241667916992185, -1.567968526367196],
            [-45.242267916992184, -1.570968526367196],
            [-45.243867916992187, -1.573468526367193],
            [-45.246867916992187, -1.576368526367197],
            [-45.248867916992182, -1.581168526367193],
            [-45.249367916992185, -1.585968526367196],
            [-45.251667916992183, -1.588968526367196],
            [-45.254167916992188, -1.591568526367197],
            [-45.257967916992186, -1.594168526367199],
            [-45.260967916992186, -1.595968526367194],
            [-45.263867916992183, -1.597568526367198],
            [-45.269067916992185, -1.598468526367199],
            [-45.272967916992187, -1.598668526367199],
            [-45.277567916992183, -1.598668526367199],
            [-45.285867916992188, -1.598068526367193],
            [-45.290267916992185, -1.598068526367193],
            [-45.293667916992185, -1.598468526367199],
            [-45.297167916992187, -1.599168526367194],
            [-45.301867916992187, -1.599468526367197],
            [-45.306167916992187, -1.599468526367197],
            [-45.310567916992184, -1.599468526367197],
            [-45.316367916992185, -1.598468526367199],
            [-45.321267916992184, -1.597768526367197],
            [-45.325667916992188, -1.596368526367193],
            [-45.329667916992186, -1.595368526367196],
            [-45.334767916992185, -1.594268526367195],
            [-45.337867916992188, -1.593668526367196],
            [-45.342067916992185, -1.590168526367194],
            [-45.343367916992186, -1.586768526367194],
            [-45.341767916992183, -1.582868526367193],
            [-45.340567916992185, -1.578468526367196],
            [-45.338367916992183, -1.575468526367196],
            [-45.336967916992187, -1.5723685263672],
            [-45.336267916992185, -1.568568526367194],
            [-45.334667916992188, -1.565168526367195],
            [-45.330367916992188, -1.562368526367194],
            [-45.323867916992185, -1.5601685263672],
            [-45.319567916992185, -1.557168526367199],
            [-45.318467916992184, -1.553868526367197],
            [-45.319367916992185, -1.549368526367196],
            [-45.321567916992187, -1.545368526367199],
            [-45.320467916992186, -1.542468526367195],
            [-45.315967916992186, -1.533868526367193],
            [-45.315567916992187, -1.528368526367196],
            [-45.315567916992187, -1.522168526367196],
            [-45.314867916992185, -1.517868526367195],
            [-45.313367916992185, -1.514668526367196],
            [-45.310767916992184, -1.512068526367194],
            [-45.308767916992188, -1.507768526367194],
            [-45.308567916992182, -1.503768526367196],
            [-45.308267916992186, -1.497968526367195],
            [-45.311267916992186, -1.492668526367197],
            [-45.314367916992182, -1.489168526367195],
            [-45.316667916992188, -1.486068526367198],
            [-45.320667916992186, -1.483268526367198],
            [-45.323767916992182, -1.481768526367198],
            [-45.328267916992182, -1.480368526367194],
            [-45.330967916992186, -1.4792685263672],
            [-45.337667916992189, -1.477568526367193],
            [-45.340367916992186, -1.478668526367194],
            [-45.343667916992182, -1.480268526367198],
            [-45.347367916992184, -1.478968526367197],
            [-45.350267916992188, -1.476668526367199],
            [-45.351767916992188, -1.473968526367194],
            [-45.356167916992185, -1.4717685263672],
            [-45.361167916992187, -1.472868526367193],
            [-45.364967916992185, -1.473468526367199],
            [-45.368267916992188, -1.473368526367196],
            [-45.371567916992184, -1.474168526367194],
            [-45.374767916992184, -1.4762685263672],
            [-45.378067916992187, -1.478068526367196],
            [-45.381767916992182, -1.478868526367194],
            [-45.384967916992188, -1.478168526367199],
            [-45.387967916992189, -1.475868526367194],
            [-45.388167916992188, -1.472268526367195],
            [-45.384367916992183, -1.467568526367195],
            [-45.379567916992187, -1.464668526367198],
            [-45.374967916992183, -1.462068526367197],
            [-45.372267916992186, -1.460868526367193],
            [-45.368067916992182, -1.4595685263672],
            [-45.364167916992187, -1.457968526367196],
            [-45.360567916992188, -1.456868526367195],
            [-45.356367916992184, -1.455468526367198],
            [-45.351667916992184, -1.453168526367193],
            [-45.345967916992187, -1.451068526367195],
            [-45.342567916992188, -1.4503685263672],
            [-45.338967916992182, -1.450168526367193],
            [-45.335467916992187, -1.449268526367199],
            [-45.332067916992187, -1.447968526367198],
            [-45.327167916992188, -1.446268526367199],
            [-45.322067916992182, -1.443768526367194],
            [-45.317367916992183, -1.441668526367195],
            [-45.313067916992182, -1.439068526367194],
            [-45.308867916992185, -1.437968526367193],
            [-45.302167916992182, -1.438768526367198],
            [-45.299467916992185, -1.435568526367199],
            [-45.298967916992183, -1.432768526367198],
            [-45.298667916992187, -1.429368526367199],
            [-45.298567916992184, -1.425468526367197],
            [-45.298267916992188, -1.422468526367197],
            [-45.297867916992182, -1.416168526367194],
            [-45.297867916992182, -1.413068526367198],
            [-45.298167916992185, -1.408468526367194],
            [-45.298667916992187, -1.403368526367196],
            [-45.299667916992185, -1.398368526367193],
            [-45.300767916992186, -1.393968526367196],
            [-45.301867916992187, -1.389668526367196],
            [-45.303067916992184, -1.385968526367193],
            [-45.306867916992182, -1.384968526367196],
            [-45.309767916992186, -1.383468526367196],
            [-45.312267916992184, -1.381568526367197],
            [-45.313267916992189, -1.377368526367199],
            [-45.313367916992185, -1.373068526367199],
            [-45.310567916992184, -1.370568526367194],
            [-45.308267916992186, -1.367468526367198],
            [-45.307467916992188, -1.362468526367195],
            [-45.306967916992186, -1.3574685263672],
            [-45.305867916992185, -1.352068526367198],
            [-45.305867916992185, -1.348468526367199],
            [-45.307967916992183, -1.344868526367193],
            [-45.310467916992188, -1.342568526367195],
            [-45.310767916992184, -1.339368526367195],
            [-45.305867916992185, -1.337968526367199],
            [-45.306167916992187, -1.335068526367195],
            [-45.309367916992187, -1.332168526367198],
            [-45.318767916992186, -1.325468526367196],
            [-45.326767916992182, -1.320468526367193],
            [-45.332267916992187, -1.317668526367193],
            [-45.338367916992183, -1.314868526367199],
            [-45.342667916992184, -1.313568526367199],
            [-45.347567916992183, -1.312668526367197],
            [-45.352067916992183, -1.313368526367199],
            [-45.356667916992187, -1.315468526367198],
            [-45.361167916992187, -1.318068526367199],
            [-45.364467916992183, -1.322068526367197],
            [-45.366167916992183, -1.325468526367196],
            [-45.364967916992185, -1.328568526367199],
            [-45.359667916992187, -1.330468526367198],
            [-45.356467916992187, -1.331768526367199],
            [-45.354167916992182, -1.335168526367198],
            [-45.351367916992189, -1.3390685263672],
            [-45.348667916992184, -1.3420685263672],
            [-45.346167916992187, -1.344168526367199],
            [-45.345367916992188, -1.347568526367198],
            [-45.349267916992183, -1.346968526367199],
            [-45.352867916992182, -1.345968526367194],
            [-45.356667916992187, -1.346668526367196],
            [-45.358067916992184, -1.349868526367196],
            [-45.360367916992182, -1.353168526367199],
            [-45.361067916992184, -1.357768526367195],
            [-45.362967916992183, -1.360668526367199],
            [-45.366067916992186, -1.362168526367199],
            [-45.369367916992182, -1.363268526367193],
            [-45.371967916992183, -1.364668526367197],
            [-45.375867916992185, -1.366368526367197],
            [-45.376367916992187, -1.370268526367198],
            [-45.376267916992184, -1.374868526367194],
            [-45.375467916992186, -1.378868526367199],
            [-45.370867916992182, -1.380668526367195],
            [-45.374367916992185, -1.383768526367199],
            [-45.372367916992182, -1.388468526367198],
            [-45.376867916992182, -1.387368526367197],
            [-45.378567916992182, -1.390668526367193],
            [-45.377167916992185, -1.393568526367197],
            [-45.374167916992185, -1.394368526367195],
            [-45.371567916992184, -1.396068526367195],
            [-45.374767916992184, -1.399068526367195],
            [-45.377667916992188, -1.401468526367196],
            [-45.381367916992183, -1.403168526367196],
            [-45.384667916992186, -1.404468526367197],
            [-45.387967916992189, -1.408068526367195],
            [-45.390167916992183, -1.4122685263672],
            [-45.392667916992188, -1.414168526367199],
            [-45.396167916992184, -1.414468526367195],
            [-45.402567916992183, -1.419168526367194],
            [-45.407367916992186, -1.421768526367195],
            [-45.412067916992186, -1.423168526367199],
            [-45.415867916992184, -1.423368526367199],
            [-45.419167916992187, -1.422468526367197],
            [-45.420867916992187, -1.4199685263672],
            [-45.424467916992185, -1.419268526367198],
            [-45.427467916992185, -1.422468526367197],
            [-45.429967916992183, -1.424668526367199],
            [-45.433367916992182, -1.428068526367198],
            [-45.436667916992185, -1.430068526367194],
            [-45.439167916992183, -1.434068526367199],
            [-45.440567916992187, -1.437768526367194],
            [-45.441967916992184, -1.440768526367194],
            [-45.443167916992188, -1.443468526367198],
            [-45.445567916992182, -1.448168526367198],
            [-45.449067916992185, -1.452068526367199],
            [-45.454867916992185, -1.452968526367194],
            [-45.456867916992188, -1.455668526367198],
            [-45.456467916992182, -1.458568526367195],
            [-45.456567916992185, -1.4625685263672],
            [-45.457867916992186, -1.468068526367198],
            [-45.459767916992185, -1.471668526367196],
            [-45.462367916992186, -1.474468526367197],
            [-45.465467916992182, -1.477568526367193],
            [-45.470367916992188, -1.479168526367197],
            [-45.475267916992188, -1.479668526367199],
            [-45.478367916992184, -1.479668526367199],
            [-45.481667916992187, -1.478868526367194],
            [-45.484467916992187, -1.477368526367194],
            [-45.486967916992185, -1.474468526367197],
            [-45.488667916992185, -1.470568526367195],
            [-45.486767916992186, -1.466168526367198],
            [-45.484967916992183, -1.4625685263672],
            [-45.481367916992184, -1.459368526367193],
            [-45.480567916992186, -1.453768526367199],
            [-45.480667916992182, -1.450268526367196],
            [-45.482567916992188, -1.447068526367197],
            [-45.485567916992188, -1.444668526367195],
            [-45.487167916992185, -1.442068526367194],
            [-45.486367916992187, -1.439168526367197],
            [-45.484467916992187, -1.436968526367195],
            [-45.484367916992184, -1.4336685263672],
            [-45.483367916992187, -1.429668526367195],
            [-45.482267916992186, -1.425368526367194],
            [-45.482467916992185, -1.421068526367193],
            [-45.485267916992186, -1.419168526367194],
            [-45.484167916992185, -1.413468526367197],
            [-45.483567916992186, -1.409168526367196],
            [-45.482467916992185, -1.405968526367197],
            [-45.480667916992182, -1.402268526367195],
            [-45.478567916992183, -1.397868526367198],
            [-45.477467916992182, -1.392968526367198],
            [-45.477767916992185, -1.387368526367197],
            [-45.477067916992183, -1.381768526367196],
            [-45.476467916992185, -1.377668526367195],
            [-45.474967916992185, -1.373368526367194],
            [-45.471167916992187, -1.370068526367199],
            [-45.467967916992187, -1.367768526367193],
            [-45.464767916992187, -1.366068526367194],
            [-45.460467916992187, -1.365768526367198],
            [-45.456467916992182, -1.365368526367199],
            [-45.453467916992182, -1.367668526367197],
            [-45.449167916992188, -1.367068526367198],
            [-45.445167916992183, -1.363868526367199],
            [-45.442367916992183, -1.362768526367198],
            [-45.439967916992188, -1.360268526367193],
            [-45.437667916992183, -1.357068526367193],
            [-45.435167916992185, -1.354968526367195],
            [-45.433067916992186, -1.352068526367198],
            [-45.429367916992184, -1.3542685263672],
            [-45.424667916992185, -1.353468526367195],
            [-45.420767916992183, -1.351668526367199],
            [-45.416467916992183, -1.346668526367196],
            [-45.414267916992188, -1.343768526367199],
            [-45.413067916992183, -1.340968526367199],
            [-45.410967916992185, -1.336168526367196],
            [-45.409467916992185, -1.332668526367193],
            [-45.408367916992184, -1.328868526367195],
            [-45.407367916992186, -1.324668526367198],
            [-45.407367916992186, -1.321568526367194],
            [-45.407267916992183, -1.316568526367199],
            [-45.407767916992185, -1.312368526367194],
            [-45.408067916992188, -1.308768526367196],
            [-45.408467916992187, -1.305868526367199],
            [-45.410567916992186, -1.301368526367199],
            [-45.413467916992182, -1.297868526367196],
            [-45.416667916992182, -1.295768526367198],
            [-45.419467916992183, -1.2949685263672],
            [-45.422867916992182, -1.294568526367193],
            [-45.428067916992184, -1.293968526367195],
            [-45.431867916992182, -1.293368526367196],
            [-45.435167916992185, -1.293068526367193],
            [-45.439167916992183, -1.292768526367198],
            [-45.443867916992183, -1.292568526367198],
            [-45.448267916992187, -1.293168526367197],
            [-45.452667916992183, -1.294768526367193],
            [-45.456467916992182, -1.296968526367195],
            [-45.458767916992187, -1.298968526367197],
            [-45.460767916992182, -1.301168526367199],
            [-45.461267916992185, -1.3039685263672],
            [-45.459567916992185, -1.306668526367197],
            [-45.455167916992188, -1.308568526367196],
            [-45.451367916992183, -1.309368526367194],
            [-45.447167916992186, -1.3101685263672],
            [-45.449667916992183, -1.315168526367195],
            [-45.453267916992182, -1.316868526367195],
            [-45.456767916992185, -1.318568526367194],
            [-45.460967916992182, -1.317468526367193],
            [-45.465467916992182, -1.319068526367197],
            [-45.468667916992182, -1.320968526367196],
            [-45.472367916992184, -1.322468526367196],
            [-45.475967916992182, -1.325468526367196],
            [-45.477267916992183, -1.330368526367195],
            [-45.477067916992183, -1.335468526367194],
            [-45.479267916992185, -1.337868526367195],
            [-45.481667916992187, -1.339568526367195],
            [-45.484267916992188, -1.342668526367198],
            [-45.486667916992182, -1.344468526367194],
            [-45.491167916992183, -1.346968526367199],
            [-45.495767916992186, -1.353868526367194],
            [-45.495767916992186, -1.350268526367195],
            [-45.496967916992183, -1.347368526367198],
            [-45.499367916992185, -1.350968526367197],
            [-45.500567916992182, -1.353968526367197],
            [-45.498267916992184, -1.3574685263672],
            [-45.498267916992184, -1.360868526367199],
            [-45.500567916992182, -1.364368526367194],
            [-45.501667916992183, -1.368268526367196],
            [-45.502067916992182, -1.372368526367197],
            [-45.504067916992184, -1.376368526367195],
            [-45.508067916992182, -1.377968526367198],
            [-45.512767916992182, -1.377968526367198],
            [-45.517067916992183, -1.376268526367198],
            [-45.519867916992183, -1.372468526367193],
            [-45.522067916992185, -1.369668526367199],
            [-45.523767916992185, -1.367168526367195],
            [-45.525467916992184, -1.363668526367199],
            [-45.526467916992182, -1.357568526367196],
            [-45.525367916992188, -1.353168526367199],
            [-45.524067916992188, -1.347268526367195],
            [-45.523967916992184, -1.343468526367197],
            [-45.522167916992188, -1.336468526367199],
            [-45.521167916992184, -1.332568526367197],
            [-45.520067916992183, -1.329368526367197],
            [-45.519367916992188, -1.324968526367194],
            [-45.519067916992185, -1.319568526367199],
            [-45.517367916992185, -1.316668526367195],
            [-45.515467916992186, -1.311268526367193],
            [-45.515367916992183, -1.306968526367193],
            [-45.517167916992186, -1.303268526367198],
            [-45.520067916992183, -1.301068526367196],
            [-45.523367916992186, -1.302268526367193],
            [-45.525467916992184, -1.306168526367195],
            [-45.528467916992184, -1.308568526367196],
            [-45.531967916992187, -1.309068526367199],
            [-45.535067916992183, -1.308368526367197],
            [-45.538467916992182, -1.305868526367199],
            [-45.541667916992182, -1.303368526367194],
            [-45.545267916992188, -1.300768526367193],
            [-45.546867916992184, -1.296368526367196],
            [-45.546467916992185, -1.287068526367193],
            [-45.547567916992186, -1.283968526367197],
            [-45.548267916992188, -1.281168526367196],
            [-45.549367916992182, -1.276768526367199],
            [-45.552167916992182, -1.277868526367193],
            [-45.554767916992184, -1.281168526367196],
            [-45.559067916992184, -1.283968526367197],
            [-45.563767916992184, -1.285668526367196],
            [-45.568467916992184, -1.286668526367194],
            [-45.574067916992185, -1.287468526367199],
            [-45.576867916992185, -1.290668526367199],
            [-45.579567916992183, -1.293668526367199],
            [-45.582367916992183, -1.295568526367198],
            [-45.587367916992186, -1.298068526367196],
            [-45.590367916992186, -1.299768526367195],
            [-45.592067916992185, -1.3024685263672],
            [-45.598367916992188, -1.298568526367198],
            [-45.596267916992183, -1.294668526367197],
            [-45.592267916992185, -1.289768526367197],
            [-45.589767916992187, -1.286668526367194],
            [-45.587267916992182, -1.283468526367194],
            [-45.585667916992186, -1.279868526367196],
            [-45.585067916992188, -1.275768526367195],
            [-45.585867916992186, -1.272268526367199],
            [-45.587967916992184, -1.270068526367197],
            [-45.591667916992186, -1.268268526367194],
            [-45.594767916992183, -1.2673685263672],
            [-45.597267916992188, -1.268768526367197],
            [-45.600267916992188, -1.270768526367199],
            [-45.604167916992182, -1.272268526367199],
            [-45.606967916992183, -1.273168526367193],
            [-45.612167916992185, -1.274868526367193],
            [-45.617167916992187, -1.276968526367199],
            [-45.621367916992185, -1.279868526367196],
            [-45.624367916992185, -1.282368526367193],
            [-45.628867916992185, -1.283768526367197],
            [-45.633467916992188, -1.282668526367196],
            [-45.637367916992183, -1.280568526367198],
            [-45.639667916992188, -1.277968526367196],
            [-45.640767916992182, -1.2750685263672],
            [-45.641767916992187, -1.272268526367199],
            [-45.641467916992184, -1.269268526367199],
            [-45.637667916992186, -1.264068526367197],
            [-45.635267916992184, -1.258168526367193],
            [-45.635767916992187, -1.254968526367193],
            [-45.633867916992187, -1.251268526367198],
            [-45.633467916992188, -1.246568526367199],
            [-45.633867916992187, -1.243668526367195],
            [-45.634967916992188, -1.2399685263672],
            [-45.637467916992186, -1.236168526367194],
            [-45.641467916992184, -1.232268526367193],
            [-45.643167916992184, -1.229968526367195],
            [-45.643767916992182, -1.225968526367197],
            [-45.643167916992184, -1.2217685263672],
            [-45.641067916992185, -1.217268526367199],
            [-45.640067916992187, -1.212868526367195],
            [-45.639567916992185, -1.208268526367199],
            [-45.638167916992188, -1.204268526367194],
            [-45.637867916992185, -1.199968526367194],
            [-45.637467916992186, -1.196268526367199],
            [-45.636367916992185, -1.192468526367193],
            [-45.634567916992182, -1.1896685263672],
            [-45.631367916992183, -1.186968526367195],
            [-45.627167916992185, -1.184768526367193],
            [-45.622167916992183, -1.182668526367195],
            [-45.618667916992187, -1.180868526367199],
            [-45.616567916992182, -1.1774685263672],
            [-45.615567916992184, -1.173868526367194],
            [-45.614967916992185, -1.170268526367195],
            [-45.614067916992184, -1.1667685263672],
            [-45.613567916992189, -1.162868526367198],
            [-45.613567916992189, -1.1592685263672],
            [-45.614367916992187, -1.155868526367193],
            [-45.615767916992183, -1.150968526367194],
            [-45.617167916992187, -1.147368526367195],
            [-45.617767916992186, -1.143168526367198],
            [-45.617967916992185, -1.138168526367195],
            [-45.618667916992187, -1.135168526367195],
            [-45.621667916992187, -1.131868526367199],
            [-45.624767916992184, -1.127968526367198],
            [-45.627967916992183, -1.124968526367198],
            [-45.630967916992184, -1.124468526367195],
            [-45.629567916992187, -1.127968526367198],
            [-45.627767916992184, -1.130668526367195],
            [-45.628467916992186, -1.133868526367195],
            [-45.629167916992188, -1.137168526367198],
            [-45.630267916992182, -1.140968526367196],
            [-45.632667916992183, -1.145168526367193],
            [-45.634567916992182, -1.148368526367193],
            [-45.634867916992185, -1.152668526367194],
            [-45.636267916992182, -1.157668526367196],
            [-45.637067916992187, -1.161368526367198],
            [-45.637367916992183, -1.165868526367198],
            [-45.638567916992187, -1.1699685263672],
            [-45.640367916992183, -1.172768526367193],
            [-45.642067916992183, -1.176068526367196],
            [-45.643167916992184, -1.180468526367193],
            [-45.644767916992187, -1.187968526367193],
            [-45.646167916992184, -1.195768526367196],
            [-45.647367916992188, -1.203568526367199],
            [-45.647967916992187, -1.209068526367197],
            [-45.648267916992182, -1.214568526367195],
            [-45.647567916992188, -1.222268526367195],
            [-45.646767916992182, -1.226968526367195],
            [-45.646567916992183, -1.232568526367196],
            [-45.647867916992183, -1.236368526367194],
            [-45.649867916992186, -1.239668526367197],
            [-45.654767916992185, -1.2414685263672],
            [-45.657667916992182, -1.245868526367197],
            [-45.663967916992185, -1.253768526367196],
            [-45.666667916992182, -1.255268526367196],
            [-45.669567916992186, -1.258468526367196],
            [-45.673367916992184, -1.261768526367199],
            [-45.676467916992188, -1.263968526367194],
            [-45.680267916992186, -1.265768526367197],
            [-45.683867916992185, -1.269068526367199],
            [-45.686867916992185, -1.272268526367199],
            [-45.690967916992186, -1.273668526367196],
            [-45.695967916992188, -1.273168526367193],
            [-45.699667916992183, -1.272568526367195],
            [-45.702967916992186, -1.271268526367194],
            [-45.706467916992182, -1.270068526367197],
            [-45.708767916992187, -1.267968526367198],
            [-45.711167916992189, -1.265668526367193],
            [-45.713367916992183, -1.2628685263672],
            [-45.716267916992187, -1.260168526367195],
            [-45.719467916992187, -1.256368526367197],
            [-45.721767916992185, -1.252668526367195],
            [-45.723667916992184, -1.249468526367195],
            [-45.724967916992185, -1.246568526367199],
            [-45.726667916992184, -1.241068526367194],
            [-45.727267916992183, -1.236768526367193],
            [-45.730567916992186, -1.235568526367196],
            [-45.733967916992185, -1.235068526367193],
            [-45.735067916992186, -1.231968526367197],
            [-45.735767916992188, -1.228668526367194],
            [-45.737167916992185, -1.222868526367193],
            [-45.737167916992185, -1.219568526367198],
            [-45.735367916992182, -1.215668526367196],
            [-45.735367916992182, -1.211468526367199],
            [-45.735667916992185, -1.207368526367198],
            [-45.733967916992185, -1.203568526367199],
            [-45.732567916992188, -1.200668526367195],
            [-45.728667916992187, -1.194868526367195],
            [-45.726767916992188, -1.192068526367194],
            [-45.725267916992188, -1.189168526367197],
            [-45.722567916992183, -1.185568526367199],
            [-45.719267916992187, -1.182268526367196],
            [-45.716667916992186, -1.178568526367194],
            [-45.714267916992185, -1.175068526367198],
            [-45.711567916992188, -1.172568526367193],
            [-45.707967916992182, -1.169268526367198],
            [-45.705367916992188, -1.1652685263672],
            [-45.702467916992184, -1.162768526367195],
            [-45.699867916992183, -1.161368526367198],
            [-45.698767916992182, -1.158468526367194],
            [-45.696767916992187, -1.155068526367195],
            [-45.692367916992183, -1.151768526367199],
            [-45.690967916992186, -1.146568526367197],
            [-45.692067916992187, -1.141568526367195],
            [-45.694067916992182, -1.138168526367195],
            [-45.696267916992184, -1.135468526367198],
            [-45.698467916992186, -1.133268526367196],
            [-45.701567916992182, -1.130968526367198],
            [-45.704667916992186, -1.128068526367194],
            [-45.707667916992186, -1.125168526367197],
            [-45.709867916992188, -1.123068526367199],
            [-45.713267916992187, -1.119168526367197],
            [-45.717067916992185, -1.117168526367195],
            [-45.718967916992185, -1.114368526367194],
            [-45.720267916992185, -1.110768526367195],
            [-45.724867916992189, -1.110368526367196],
            [-45.726967916992187, -1.113568526367196],
            [-45.726967916992187, -1.117168526367195],
            [-45.726667916992184, -1.120068526367199],
            [-45.724867916992189, -1.123568526367194],
            [-45.724767916992185, -1.128768526367196],
            [-45.728867916992186, -1.128868526367199],
            [-45.731367916992184, -1.130468526367196],
            [-45.730267916992183, -1.134968526367196],
            [-45.734467916992187, -1.136868526367195],
            [-45.739067916992184, -1.138468526367198],
            [-45.742267916992184, -1.141368526367195],
            [-45.742967916992185, -1.146068526367195],
            [-45.744367916992182, -1.152268526367195],
            [-45.746667916992187, -1.1545685263672],
            [-45.748867916992182, -1.158368526367198],
            [-45.749467916992188, -1.163168526367194],
            [-45.752067916992182, -1.165868526367198],
            [-45.755967916992184, -1.1667685263672],
            [-45.757367916992187, -1.171868526367199],
            [-45.759167916992183, -1.175368526367194],
            [-45.759867916992185, -1.180068526367194],
            [-45.758867916992187, -1.184368526367194],
            [-45.761767916992184, -1.188768526367198],
            [-45.763567916992187, -1.191968526367198],
            [-45.764667916992188, -1.195168526367198],
            [-45.767867916992188, -1.198468526367193],
            [-45.771867916992186, -1.198168526367198],
            [-45.777667916992186, -1.197468526367196],
            [-45.781267916992185, -1.193868526367197],
            [-45.784467916992185, -1.190268526367198],
            [-45.788467916992182, -1.187668526367197],
            [-45.792567916992184, -1.185468526367195],
            [-45.795767916992183, -1.183068526367194],
            [-45.798967916992183, -1.180468526367193],
            [-45.799967916992188, -1.177568526367196],
            [-45.800767916992186, -1.174168526367197],
            [-45.801867916992187, -1.1699685263672],
            [-45.800867916992182, -1.166368526367194],
            [-45.802267916992186, -1.161768526367197],
            [-45.806567916992186, -1.163168526367194],
            [-45.809467916992183, -1.164268526367195],
            [-45.814367916992182, -1.164568526367198],
            [-45.817967916992188, -1.166668526367197],
            [-45.821367916992187, -1.1699685263672],
            [-45.825667916992188, -1.174368526367196],
            [-45.828967916992184, -1.176868526367194],
            [-45.829267916992187, -1.181168526367195],
            [-45.830667916992184, -1.187168526367195],
            [-45.829567916992183, -1.191568526367199],
            [-45.827967916992186, -1.194868526367195],
            [-45.826067916992187, -1.197968526367198],
            [-45.825667916992188, -1.2018685263672],
            [-45.830667916992184, -1.205768526367194],
            [-45.834267916992182, -1.203968526367198],
            [-45.838667916992186, -1.201268526367194],
            [-45.840867916992188, -1.2048685263672],
            [-45.841567916992183, -1.208268526367199],
            [-45.843667916992182, -1.211768526367194],
            [-45.846667916992182, -1.213468526367194],
            [-45.850067916992188, -1.213768526367197],
            [-45.856467916992187, -1.213968526367196],
            [-45.860567916992188, -1.213468526367194],
            [-45.864367916992187, -1.211168526367196],
            [-45.866867916992184, -1.207968526367196],
            [-45.868267916992188, -1.203868526367195],
            [-45.867967916992185, -1.200468526367196],
            [-45.865067916992189, -1.197068526367197],
            [-45.863667916992185, -1.192368526367197],
            [-45.862767916992183, -1.187968526367193],
            [-45.862467916992188, -1.184968526367193],
            [-45.862967916992183, -1.181368526367194],
            [-45.866667916992185, -1.183568526367196],
            [-45.869667916992185, -1.186368526367197],
            [-45.871067916992182, -1.189468526367193],
            [-45.874367916992185, -1.191868526367195],
            [-45.874467916992188, -1.187668526367197],
            [-45.872967916992188, -1.183868526367199],
            [-45.870267916992184, -1.179968526367198],
            [-45.868667916992187, -1.174768526367195],
            [-45.869767916992188, -1.171368526367196],
            [-45.874967916992183, -1.1714685263672],
            [-45.879867916992183, -1.173968526367197],
            [-45.883267916992182, -1.176568526367198],
            [-45.883067916992182, -1.172868526367196],
            [-45.882167916992188, -1.168868526367199],
            [-45.880467916992188, -1.164868526367194],
            [-45.877467916992188, -1.161668526367194],
            [-45.874467916992188, -1.158668526367194],
            [-45.872467916992186, -1.155868526367193],
            [-45.871667916992187, -1.152968526367196],
            [-45.875467916992186, -1.149568526367197],
            [-45.879467916992184, -1.148468526367196],
            [-45.882367916992187, -1.149068526367195],
            [-45.884867916992185, -1.150868526367198],
            [-45.887667916992186, -1.153968526367194],
            [-45.889967916992184, -1.155968526367197],
            [-45.893167916992184, -1.158668526367194],
            [-45.897367916992188, -1.163468526367197],
            [-45.900867916992183, -1.168168526367197],
            [-45.906767916992187, -1.171068526367193],
            [-45.911267916992188, -1.165668526367199],
            [-45.909167916992182, -1.163368526367194],
            [-45.906767916992187, -1.159868526367198],
            [-45.904767916992185, -1.156268526367199],
            [-45.901567916992185, -1.152568526367197],
            [-45.899567916992183, -1.149768526367197],
            [-45.896467916992187, -1.147968526367194],
            [-45.892967916992184, -1.146268526367194],
            [-45.889567916992185, -1.145468526367196],
            [-45.886567916992185, -1.143268526367194],
            [-45.883267916992182, -1.141068526367199],
            [-45.880467916992188, -1.137768526367196],
            [-45.878067916992187, -1.134068526367194],
            [-45.874367916992185, -1.133868526367195],
            [-45.870067916992184, -1.133068526367197],
            [-45.865467916992188, -1.130968526367198],
            [-45.863667916992185, -1.125568526367196],
            [-45.865067916992189, -1.121568526367199],
            [-45.867767916992186, -1.118868526367194],
            [-45.865767916992183, -1.115068526367196],
            [-45.864467916992183, -1.108568526367193],
            [-45.862167916992185, -1.106368526367199],
            [-45.858867916992182, -1.104968526367195],
            [-45.854967916992187, -1.103168526367199],
            [-45.849767916992185, -1.100668526367194],
            [-45.848067916992186, -1.097068526367195],
            [-45.847267916992188, -1.092868526367198],
            [-45.845867916992184, -1.089468526367199],
            [-45.844467916992187, -1.086168526367196],
            [-45.843467916992182, -1.081768526367199],
            [-45.843667916992182, -1.078568526367199],
            [-45.843767916992185, -1.075668526367195],
            [-45.844267916992187, -1.072468526367196],
            [-45.844867916992186, -1.069268526367196],
            [-45.845967916992187, -1.066368526367199],
            [-45.847867916992186, -1.062168526367195],
            [-45.849767916992185, -1.058268526367193],
            [-45.852467916992182, -1.054668526367195],
            [-45.854967916992187, -1.051968526367197],
            [-45.858067916992184, -1.050468526367197],
            [-45.861367916992187, -1.049668526367199],
            [-45.864667916992182, -1.0494685263672],
            [-45.867567916992186, -1.050068526367198],
            [-45.871667916992187, -1.053668526367197],
            [-45.872167916992183, -1.059368526367194],
            [-45.874167916992185, -1.063068526367196],
            [-45.875967916992188, -1.067668526367193],
            [-45.873767916992186, -1.070168526367198],
            [-45.871067916992182, -1.072168526367193],
            [-45.873267916992184, -1.075668526367195],
            [-45.877967916992183, -1.076268526367194],
            [-45.882767916992186, -1.078168526367193],
            [-45.886067916992182, -1.080968526367194],
            [-45.887767916992182, -1.083968526367194],
            [-45.889267916992182, -1.086768526367194],
            [-45.890767916992182, -1.089768526367195],
            [-45.891767916992187, -1.093168526367194],
            [-45.894367916992188, -1.096668526367196],
            [-45.895967916992184, -1.100268526367195],
            [-45.895767916992185, -1.1042685263672],
            [-45.894867916992183, -1.107568526367196],
            [-45.895167916992186, -1.111368526367194],
            [-45.896167916992184, -1.115268526367196],
            [-45.898967916992184, -1.118668526367195],
            [-45.902867916992186, -1.121868526367194],
            [-45.904867916992188, -1.126268526367198],
            [-45.906467916992185, -1.130168526367193],
            [-45.907667916992182, -1.133168526367193],
            [-45.909467916992185, -1.136068526367197],
            [-45.911267916992188, -1.139668526367196],
            [-45.913167916992187, -1.142668526367196],
            [-45.915967916992187, -1.146768526367197],
            [-45.918967916992187, -1.151968526367199],
            [-45.923967916992183, -1.160368526367193],
            [-45.930167916992183, -1.171068526367193],
            [-45.935167916992185, -1.1774685263672],
            [-45.938367916992185, -1.181668526367197],
            [-45.939867916992185, -1.184368526367194],
            [-45.941267916992182, -1.187368526367194],
            [-45.942767916992182, -1.189868526367199],
            [-45.944667916992188, -1.193268526367198],
            [-45.946367916992187, -1.196568526367194],
            [-45.945567916992182, -1.200668526367195],
            [-45.948267916992187, -1.202668526367198],
            [-45.952867916992183, -1.202468526367198],
            [-45.954667916992186, -1.199668526367198],
            [-45.957167916992184, -1.195968526367196],
            [-45.956567916992185, -1.192068526367194],
            [-45.953567916992185, -1.188068526367196],
            [-45.953967916992184, -1.182968526367198],
            [-45.956867916992188, -1.185468526367195],
            [-45.957967916992182, -1.178868526367197],
            [-45.958167916992188, -1.174368526367196],
            [-45.956767916992185, -1.1714685263672],
            [-45.954967916992182, -1.166968526367199],
            [-45.954867916992185, -1.1622685263672],
            [-45.958767916992187, -1.159868526367198],
            [-45.957667916992186, -1.155868526367193],
            [-45.955467916992184, -1.151968526367199],
            [-45.953767916992184, -1.147868526367198],
            [-45.951867916992185, -1.143668526367193],
            [-45.953267916992182, -1.140368526367197],
            [-45.957567916992183, -1.140768526367197],
            [-45.961767916992187, -1.140768526367197],
            [-45.964467916992184, -1.138168526367195],
            [-45.964267916992185, -1.1348685263672],
            [-45.961567916992188, -1.130468526367196],
            [-45.960367916992183, -1.125268526367194],
            [-45.962267916992182, -1.1226685263672],
            [-45.960967916992182, -1.118068526367196],
            [-45.961467916992184, -1.112468526367195],
            [-45.963667916992186, -1.109668526367194],
            [-45.968167916992186, -1.108668526367197],
            [-45.970867916992184, -1.110268526367193],
            [-45.974767916992185, -1.112568526367198],
            [-45.978167916992184, -1.115468526367195],
            [-45.981467916992187, -1.115068526367196],
            [-45.982867916992184, -1.111368526367194],
            [-45.981767916992183, -1.106468526367195],
            [-45.982167916992182, -1.1027685263672],
            [-45.981067916992188, -1.0997685263672],
            [-45.978367916992184, -1.095568526367195],
            [-45.974867916992189, -1.092568526367195],
            [-45.971267916992183, -1.091168526367198],
            [-45.968167916992186, -1.088268526367195],
            [-45.968367916992186, -1.084368526367193],
            [-45.968667916992182, -1.081268526367197],
            [-45.969767916992183, -1.077368526367195],
            [-45.972267916992188, -1.073168526367198],
            [-45.974567916992186, -1.070968526367196],
            [-45.975667916992187, -1.067968526367196],
            [-45.978167916992184, -1.065168526367195],
            [-45.983267916992183, -1.065568526367194],
            [-45.981767916992183, -1.062968526367193],
            [-45.979267916992185, -1.061368526367197],
            [-45.976367916992189, -1.060868526367194],
            [-45.973967916992187, -1.062368526367194],
            [-45.970667916992184, -1.064868526367199],
            [-45.967067916992185, -1.063768526367198],
            [-45.964867916992183, -1.060468526367195],
            [-45.964567916992188, -1.056868526367197],
            [-45.966467916992187, -1.054368526367199],
            [-45.968767916992185, -1.052568526367196],
            [-45.972567916992183, -1.050568526367194],
            [-45.977067916992183, -1.0494685263672],
            [-45.981067916992188, -1.0494685263672],
            [-45.987167916992185, -1.050068526367198],
            [-45.991167916992183, -1.051468526367195],
            [-45.994067916992186, -1.052568526367196],
            [-45.996567916992184, -1.055168526367197],
            [-45.998067916992184, -1.058268526367193],
            [-45.997767916992188, -1.061568526367196],
            [-45.996967916992183, -1.065468526367198],
            [-45.999367916992185, -1.068768526367194],
            [-46.002367916992185, -1.072468526367196],
            [-46.002967916992183, -1.076068526367195],
            [-46.006667916992185, -1.079068526367195],
            [-46.011867916992188, -1.080068526367199],
            [-46.016267916992184, -1.079268526367194],
            [-46.020667916992188, -1.083268526367199],
            [-46.021767916992182, -1.085968526367196],
            [-46.022967916992187, -1.088968526367196],
            [-46.024067916992188, -1.092568526367195],
            [-46.026567916992185, -1.096368526367193],
            [-46.028967916992187, -1.098468526367199],
            [-46.031267916992185, -1.101168526367196],
            [-46.033967916992182, -1.104768526367195],
            [-46.036767916992183, -1.109968526367197],
            [-46.040367916992182, -1.112468526367195],
            [-46.041967916992185, -1.115868526367194],
            [-46.046067916992186, -1.121068526367196],
            [-46.045867916992187, -1.124968526367198],
            [-46.042767916992183, -1.130568526367199],
            [-46.042167916992184, -1.133568526367199],
            [-46.042267916992188, -1.140368526367197],
            [-46.044267916992183, -1.149768526367197],
            [-46.043967916992187, -1.153368526367196],
            [-46.038167916992187, -1.164168526367199],
            [-46.037367916992189, -1.167268526367195],
            [-46.037267916992185, -1.170368526367199],
            [-46.039267916992188, -1.173368526367199],
            [-46.040367916992182, -1.169768526367193],
            [-46.043067916992186, -1.165868526367198],
            [-46.045067916992188, -1.161468526367194],
            [-46.048967916992183, -1.1500685263672],
            [-46.048967916992183, -1.142868526367195],
            [-46.051067916992189, -1.140468526367194],
            [-46.052467916992185, -1.135968526367193],
            [-46.053667916992183, -1.131368526367197],
            [-46.057567916992184, -1.132368526367195],
            [-46.060767916992184, -1.136068526367197],
            [-46.062667916992183, -1.141768526367194],
            [-46.064667916992185, -1.144268526367199],
            [-46.065567916992187, -1.147968526367194],
            [-46.065267916992184, -1.154768526367199],
            [-46.066967916992184, -1.160568526367193],
            [-46.068867916992183, -1.151168526367194],
            [-46.072967916992184, -1.155968526367197],
            [-46.074967916992186, -1.160968526367199],
            [-46.076267916992187, -1.166968526367199],
            [-46.076567916992182, -1.170668526367194],
            [-46.074967916992186, -1.185268526367196],
            [-46.074967916992186, -1.189368526367197],
            [-46.077067916992185, -1.196268526367199],
            [-46.081167916992186, -1.196868526367197],
            [-46.085367916992183, -1.196768526367194],
            [-46.087867916992188, -1.198268526367194],
            [-46.092567916992188, -1.201468526367194],
            [-46.103967916992183, -1.202068526367199],
            [-46.098067916992186, -1.195968526367196],
            [-46.093367916992186, -1.180768526367196],
            [-46.087567916992185, -1.158368526367198],
            [-46.087067916992183, -1.134368526367197],
            [-46.081767916992185, -1.126568526367194],
            [-46.080067916992185, -1.121968526367198],
            [-46.075767916992184, -1.115568526367198],
            [-46.063567916992184, -1.105868526367196],
            [-46.061367916992182, -1.100968526367197],
            [-46.062267916992184, -1.095968526367194],
            [-46.063767916992184, -1.091968526367197],
            [-46.064567916992182, -1.0890685263672],
            [-46.065967916992186, -1.086168526367196],
            [-46.068067916992185, -1.084268526367197],
            [-46.069567916992185, -1.081768526367199],
            [-46.071367916992187, -1.078568526367199],
            [-46.074567916992187, -1.074568526367194],
            [-46.077067916992185, -1.070568526367197],
            [-46.079667916992186, -1.066968526367198],
            [-46.081867916992188, -1.064868526367199],
            [-46.080767916992187, -1.062168526367195],
            [-46.081567916992185, -1.059368526367194],
            [-46.085467916992187, -1.059468526367198],
            [-46.089267916992185, -1.061068526367194],
            [-46.092667916992184, -1.059768526367193],
            [-46.093667916992182, -1.056868526367197],
            [-46.093167916992186, -1.053868526367197],
            [-46.089867916992183, -1.049968526367195],
            [-46.085767916992182, -1.048268526367195],
            [-46.082067916992187, -1.047568526367193],
            [-46.078267916992182, -1.047268526367198],
            [-46.074367916992188, -1.046868526367199],
            [-46.072067916992182, -1.044268526367198],
            [-46.071267916992184, -1.040668526367199],
            [-46.069867916992187, -1.037068526367193],
            [-46.070967916992188, -1.033368526367198],
            [-46.073267916992187, -1.030068526367195],
            [-46.076267916992187, -1.026168526367194],
            [-46.080367916992188, -1.022868526367198],
            [-46.084667916992188, -1.020768526367199],
            [-46.087567916992185, -1.019868526367198],
            [-46.090867916992188, -1.019768526367194],
            [-46.094867916992186, -1.021068526367195],
            [-46.099167916992187, -1.024268526367194],
            [-46.101967916992187, -1.027868526367193],
            [-46.104467916992185, -1.031168526367196],
            [-46.107767916992188, -1.033368526367198],
            [-46.110267916992186, -1.036368526367198],
            [-46.109667916992187, -1.039568526367198],
            [-46.113267916992186, -1.038168526367194],
            [-46.116367916992182, -1.038168526367194],
            [-46.119467916992186, -1.039168526367199],
            [-46.123767916992186, -1.040968526367195],
            [-46.127067916992182, -1.044568526367193],
            [-46.130567916992185, -1.047168526367194],
            [-46.134367916992183, -1.051368526367199],
            [-46.137167916992183, -1.053868526367197],
            [-46.140367916992183, -1.057168526367199],
            [-46.143967916992182, -1.063068526367196],
            [-46.146767916992182, -1.068468526367198],
            [-46.148267916992182, -1.071668526367198],
            [-46.149467916992187, -1.075968526367198],
            [-46.150367916992188, -1.079968526367196],
            [-46.150367916992188, -1.084668526367196],
            [-46.152267916992187, -1.087968526367199],
            [-46.155167916992184, -1.089568526367195],
            [-46.159567916992188, -1.089868526367198],
            [-46.164167916992184, -1.0890685263672],
            [-46.168667916992185, -1.087268526367197],
            [-46.171367916992182, -1.083968526367194],
            [-46.170667916992187, -1.077968526367194],
            [-46.170067916992188, -1.073968526367196],
            [-46.168967916992187, -1.071268526367199],
            [-46.166367916992186, -1.067968526367196],
            [-46.163067916992183, -1.065868526367197],
            [-46.159867916992184, -1.064468526367193],
            [-46.155867916992186, -1.063068526367196],
            [-46.153767916992187, -1.060468526367195],
            [-46.152567916992183, -1.056568526367194],
            [-46.150967916992187, -1.051968526367197],
            [-46.151467916992182, -1.048368526367199],
            [-46.150667916992184, -1.044768526367193],
            [-46.150467916992184, -1.041668526367197],
            [-46.151167916992186, -1.038168526367194],
            [-46.153467916992184, -1.0342685263672],
            [-46.156767916992187, -1.031768526367195],
            [-46.156167916992182, -1.027368526367198],
            [-46.156967916992187, -1.022268526367199],
            [-46.156267916992185, -1.019268526367199],
            [-46.156767916992187, -1.015968526367196],
            [-46.158867916992186, -1.008868526367195],
            [-46.159867916992184, -1.005968526367198],
            [-46.163667916992182, -1.002468526367196],
            [-46.166367916992186, -1.000868526367199],
            [-46.169267916992183, -0.999768526367198],
            [-46.172867916992182, -0.999368526367199],
            [-46.176167916992185, -1.000468526367193],
            [-46.178967916992185, -1.003368526367197],
            [-46.180567916992182, -1.006368526367197],
            [-46.181967916992186, -1.009268526367194],
            [-46.183367916992182, -1.012968526367196],
            [-46.185867916992187, -1.023768526367199],
            [-46.186567916992182, -1.028368526367196],
            [-46.186667916992185, -1.033368526367198],
            [-46.186267916992186, -1.038168526367194],
            [-46.185267916992188, -1.043668526367199],
            [-46.184767916992186, -1.048368526367199],
            [-46.184067916992184, -1.052968526367195],
            [-46.184767916992186, -1.056868526367197],
            [-46.184967916992186, -1.061368526367197],
            [-46.186867916992185, -1.066968526367198],
            [-46.188467916992188, -1.072168526367193],
            [-46.190467916992183, -1.076068526367195],
            [-46.193467916992184, -1.079568526367197],
            [-46.197867916992188, -1.082868526367193],
            [-46.201067916992187, -1.084768526367199],
            [-46.203467916992182, -1.086568526367195],
            [-46.206867916992188, -1.088768526367197],
            [-46.210467916992187, -1.0920685263672],
            [-46.213467916992187, -1.095668526367199],
            [-46.216167916992184, -1.098168526367196],
            [-46.218767916992185, -1.099568526367193],
            [-46.222567916992183, -1.101368526367196],
            [-46.226367916992189, -1.103168526367199],
            [-46.229267916992185, -1.1042685263672],
            [-46.235867916992184, -1.103168526367199],
            [-46.239467916992183, -1.099968526367199],
            [-46.236967916992185, -1.094868526367193],
            [-46.231967916992183, -1.088668526367194],
            [-46.225667916992187, -1.082568526367197],
            [-46.218467916992182, -1.074268526367199],
            [-46.212867916992188, -1.069968526367198],
            [-46.208267916992185, -1.067468526367193],
            [-46.205367916992188, -1.062268526367198],
            [-46.204567916992183, -1.057168526367199],
            [-46.204667916992186, -1.053068526367198],
            [-46.205367916992188, -1.049168526367197],
            [-46.206167916992186, -1.044168526367194],
            [-46.207267916992187, -1.037468526367199],
            [-46.209567916992185, -1.033468526367194],
            [-46.212967916992184, -1.029768526367199],
            [-46.215167916992186, -1.026768526367199],
            [-46.215867916992188, -1.023168526367193],
            [-46.215967916992184, -1.019668526367198],
            [-46.216767916992183, -1.016568526367195],
            [-46.217667916992184, -1.013468526367198],
            [-46.218367916992186, -1.009068526367194],
            [-46.218367916992186, -1.005568526367199],
            [-46.218067916992183, -1.002468526367196],
            [-46.218367916992186, -0.999368526367199],
            [-46.217667916992184, -0.995868526367197],
            [-46.217667916992184, -0.992468526367198],
            [-46.218367916992186, -0.989668526367197],
            [-46.221167916992187, -0.988968526367195],
            [-46.221667916992182, -0.985368526367196],
            [-46.218367916992186, -0.982868526367199],
            [-46.214367916992188, -0.980368526367194],
            [-46.210467916992187, -0.976668526367199],
            [-46.208167916992188, -0.973668526367199],
            [-46.205167916992188, -0.970968526367194],
            [-46.202467916992184, -0.968468526367197],
            [-46.200167916992186, -0.965368526367193],
            [-46.197967916992184, -0.962068526367197],
            [-46.194867916992187, -0.958968526367194],
            [-46.192667916992185, -0.956168526367193],
            [-46.191567916992184, -0.9518685263672],
            [-46.189867916992185, -0.948568526367197],
            [-46.186867916992185, -0.949268526367199],
            [-46.183767916992188, -0.951568526367197],
            [-46.179967916992183, -0.952168526367196],
            [-46.177467916992185, -0.950168526367193],
            [-46.176067916992189, -0.946268526367199],
            [-46.175867916992182, -0.941368526367199],
            [-46.176667916992187, -0.935468526367195],
            [-46.177167916992182, -0.930868526367199],
            [-46.177467916992185, -0.927968526367195],
            [-46.177567916992189, -0.923968526367197],
            [-46.178067916992184, -0.920368526367199],
            [-46.178567916992186, -0.917168526367199],
            [-46.179367916992184, -0.914168526367199],
            [-46.181167916992187, -0.910568526367193],
            [-46.185767916992184, -0.905068526367195],
            [-46.188067916992182, -0.902368526367198],
            [-46.190967916992186, -0.899568526367197],
            [-46.193867916992183, -0.896868526367193],
            [-46.196367916992187, -0.893468526367194],
            [-46.198867916992185, -0.890068526367195],
            [-46.200467916992189, -0.887068526367194],
            [-46.203467916992182, -0.884968526367196],
            [-46.206767916992185, -0.885268526367199],
            [-46.210967916992182, -0.887368526367197],
            [-46.215567916992185, -0.889868526367195],
            [-46.219567916992183, -0.892168526367193],
            [-46.222767916992183, -0.893268526367194],
            [-46.226667916992184, -0.895068526367197],
            [-46.230667916992182, -0.896468526367194],
            [-46.233967916992185, -0.897868526367198],
            [-46.237167916992185, -0.898968526367199],
            [-46.240067916992189, -0.9000685263672],
            [-46.244367916992182, -0.9015685263672],
            [-46.248767916992186, -0.903668526367198],
            [-46.252967916992183, -0.905868526367193],
            [-46.256867916992185, -0.9077685263672],
            [-46.260667916992183, -0.909868526367198],
            [-46.264067916992182, -0.912768526367195],
            [-46.265767916992182, -0.916068526367198],
            [-46.266867916992183, -0.920368526367199],
            [-46.267867916992188, -0.923968526367197],
            [-46.269367916992188, -0.928068526367198],
            [-46.270667916992188, -0.931868526367197],
            [-46.271167916992184, -0.935268526367196],
            [-46.271167916992184, -0.939068526367194],
            [-46.270967916992184, -0.942768526367196],
            [-46.268667916992186, -0.9473685263672],
            [-46.267167916992186, -0.952068526367199],
            [-46.266767916992187, -0.957168526367198],
            [-46.266567916992187, -0.960468526367194],
            [-46.267167916992186, -0.963968526367196],
            [-46.269767916992187, -0.968968526367199],
            [-46.272867916992183, -0.972768526367197],
            [-46.276167916992186, -0.975268526367195],
            [-46.280067916992188, -0.977368526367194],
            [-46.283767916992183, -0.9792685263672],
            [-46.287367916992189, -0.981668526367194],
            [-46.289967916992182, -0.986468526367197],
            [-46.289467916992187, -0.991668526367199],
            [-46.288167916992187, -0.994468526367193],
            [-46.287867916992184, -0.9976685263672],
            [-46.288467916992182, -1.000568526367196],
            [-46.291767916992185, -1.002968526367198],
            [-46.296467916992185, -1.005468526367196],
            [-46.301167916992185, -1.005768526367198],
            [-46.304067916992182, -1.007368526367195],
            [-46.306167916992187, -1.009668526367193],
            [-46.307967916992183, -1.012068526367194],
            [-46.308867916992185, -1.015668526367193],
            [-46.311967916992188, -1.018668526367193],
            [-46.315567916992187, -1.018968526367196],
            [-46.318467916992184, -1.022168526367196],
            [-46.322467916992188, -1.024868526367193],
            [-46.326267916992187, -1.025368526367195],
            [-46.329867916992185, -1.025368526367195],
            [-46.335667916992186, -1.0250685263672],
            [-46.336967916992187, -1.022168526367196],
            [-46.339567916992188, -1.020468526367196],
            [-46.341167916992184, -1.017968526367198],
            [-46.342267916992185, -1.015068526367195],
            [-46.343367916992186, -1.012068526367194],
            [-46.347267916992188, -1.007668526367198],
            [-46.351967916992187, -1.007768526367194],
            [-46.356967916992183, -1.009168526367198],
            [-46.361367916992187, -1.010268526367199],
            [-46.364367916992187, -1.012068526367194],
            [-46.366867916992184, -1.014968526367198],
            [-46.368067916992182, -1.018268526367194],
            [-46.368667916992187, -1.021868526367193],
            [-46.370567916992186, -1.025368526367195],
            [-46.371967916992183, -1.0280685263672],
            [-46.375467916992186, -1.0265685263672],
            [-46.379067916992184, -1.026968526367199],
            [-46.382467916992184, -1.025868526367198],
            [-46.385367916992188, -1.023768526367199],
            [-46.388767916992187, -1.021168526367198],
            [-46.390767916992182, -1.017468526367196],
            [-46.391567916992187, -1.014068526367197],
            [-46.394267916992185, -1.011068526367197],
            [-46.396467916992187, -1.007368526367195],
            [-46.396467916992187, -1.003768526367196],
            [-46.395767916992185, -1.000468526367193],
            [-46.396167916992184, -0.997268526367193],
            [-46.400367916992188, -0.996968526367198],
            [-46.403967916992187, -0.996868526367194],
            [-46.406967916992187, -0.997768526367196],
            [-46.410667916992182, -0.999068526367196],
            [-46.413967916992185, -1.000268526367194],
            [-46.416767916992185, -1.001968526367193],
            [-46.418667916992185, -1.004168526367195],
            [-46.420367916992184, -1.006768526367196],
            [-46.422467916992183, -1.009168526367198],
            [-46.423867916992187, -1.012468526367194],
            [-46.423867916992187, -1.017668526367196],
            [-46.426167916992185, -1.021068526367195],
            [-46.428967916992185, -1.023968526367199],
            [-46.432267916992188, -1.025168526367196],
            [-46.434967916992186, -1.026268526367197],
            [-46.438067916992182, -1.027268526367195],
            [-46.441567916992184, -1.027568526367197],
            [-46.445667916992186, -1.0280685263672],
            [-46.448867916992185, -1.028368526367196],
            [-46.452067916992185, -1.0280685263672],
            [-46.455367916992188, -1.027568526367197],
            [-46.458167916992188, -1.026968526367199],
            [-46.461167916992189, -1.026968526367199],
            [-46.465467916992182, -1.026468526367196],
            [-46.469167916992184, -1.023668526367196],
            [-46.469467916992187, -1.019268526367199],
            [-46.472667916992187, -1.017568526367199],
            [-46.475867916992186, -1.016368526367195],
            [-46.475667916992187, -1.013468526367198],
            [-46.472867916992186, -1.010168526367195],
            [-46.468767916992185, -1.008868526367195],
            [-46.466267916992187, -1.006668526367193],
            [-46.465067916992183, -0.9976685263672],
            [-46.466567916992183, -0.992968526367193],
            [-46.468067916992183, -0.989368526367194],
            [-46.468667916992182, -0.984268526367195],
            [-46.469867916992186, -0.981368526367199],
            [-46.473667916992184, -0.978068526367196],
            [-46.479267916992185, -0.976668526367199],
            [-46.482767916992188, -0.9762685263672],
            [-46.486967916992185, -0.976168526367196],
            [-46.490767916992183, -0.975868526367194],
            [-46.494067916992186, -0.975268526367195],
            [-46.493267916992188, -0.970368526367196],
            [-46.489967916992185, -0.967568526367195],
            [-46.486967916992185, -0.9670685263672],
            [-46.483867916992182, -0.966768526367197],
            [-46.478467916992187, -0.965168526367194],
            [-46.471167916992187, -0.958768526367194],
            [-46.467667916992184, -0.954668526367193],
            [-46.465467916992182, -0.951468526367194],
            [-46.463967916992182, -0.9473685263672],
            [-46.462567916992185, -0.939068526367194],
            [-46.462967916992184, -0.935768526367198],
            [-46.465167916992186, -0.933368526367197],
            [-46.467967916992187, -0.930868526367199],
            [-46.470567916992188, -0.928268526367198],
            [-46.475867916992186, -0.925768526367193],
            [-46.480267916992183, -0.923068526367196],
            [-46.474467916992182, -0.918868526367199],
            [-46.470567916992188, -0.917468526367195],
            [-46.467067916992185, -0.914568526367198],
            [-46.464767916992187, -0.912768526367195],
            [-46.460367916992183, -0.913168526367194],
            [-46.458267916992185, -0.910168526367194],
            [-46.456267916992182, -0.907668526367196],
            [-46.459067916992183, -0.904868526367196],
            [-46.456067916992183, -0.903968526367194],
            [-46.452867916992183, -0.906168526367196],
            [-46.449367916992188, -0.908868526367193],
            [-46.444667916992188, -0.910268526367197],
            [-46.441667916992188, -0.908768526367197],
            [-46.439067916992187, -0.906768526367195],
            [-46.436367916992182, -0.904268526367197],
            [-46.432967916992183, -0.902068526367195],
            [-46.428367916992187, -0.899768526367197],
            [-46.425767916992186, -0.898468526367196],
            [-46.422767916992186, -0.896868526367193],
            [-46.419967916992185, -0.895168526367193],
            [-46.417167916992184, -0.892468526367196],
            [-46.413467916992182, -0.888468526367198],
            [-46.410067916992183, -0.886768526367199],
            [-46.410367916992186, -0.882468526367198],
            [-46.412067916992186, -0.878768526367196],
            [-46.414167916992184, -0.876268526367198],
            [-46.417467916992187, -0.8726685263672],
            [-46.420567916992184, -0.869368526367197],
            [-46.422867916992182, -0.866868526367199],
            [-46.426067916992189, -0.865768526367198],
            [-46.429767916992184, -0.865768526367198],
            [-46.432167916992185, -0.867268526367198],
            [-46.434967916992186, -0.867968526367193],
            [-46.438767916992184, -0.868268526367196],
            [-46.442167916992183, -0.868268526367196],
            [-46.445767916992182, -0.869768526367196],
            [-46.448267916992187, -0.871968526367198],
            [-46.452067916992185, -0.871968526367198],
            [-46.456267916992182, -0.871868526367194],
            [-46.460967916992182, -0.871868526367194],
            [-46.464767916992187, -0.872268526367193],
            [-46.467967916992187, -0.873368526367194],
            [-46.468667916992182, -0.876268526367198],
            [-46.464467916992184, -0.877668526367195],
            [-46.461567916992188, -0.879368526367195],
            [-46.459867916992188, -0.882368526367195],
            [-46.459767916992185, -0.885668526367198],
            [-46.463967916992182, -0.888868526367197],
            [-46.466267916992187, -0.892468526367196],
            [-46.468767916992185, -0.889068526367197],
            [-46.470367916992188, -0.885268526367199],
            [-46.473967916992187, -0.883468526367196],
            [-46.478667916992187, -0.882768526367194],
            [-46.481667916992187, -0.879468526367198],
            [-46.484267916992188, -0.877368526367199],
            [-46.488567916992189, -0.874468526367195],
            [-46.494967916992188, -0.872368526367197],
            [-46.498867916992182, -0.873368526367194],
            [-46.504067916992184, -0.877668526367195],
            [-46.508467916992188, -0.880668526367195],
            [-46.512467916992186, -0.883468526367196],
            [-46.514567916992185, -0.885668526367198],
            [-46.513967916992186, -0.889068526367197],
            [-46.511567916992185, -0.891568526367195],
            [-46.509167916992183, -0.894268526367199],
            [-46.505667916992188, -0.898168526367193],
            [-46.508267916992182, -0.901268526367197],
            [-46.508767916992184, -0.905868526367193],
            [-46.510367916992188, -0.909768526367195],
            [-46.512867916992185, -0.914168526367199],
            [-46.514867916992188, -0.918168526367197],
            [-46.513867916992183, -0.922168526367194],
            [-46.515067916992187, -0.925268526367198],
            [-46.515467916992186, -0.929768526367198],
            [-46.519367916992188, -0.932568526367199],
            [-46.523167916992186, -0.934168526367195],
            [-46.524867916992186, -0.936968526367195],
            [-46.525067916992185, -0.940868526367197],
            [-46.527867916992186, -0.943268526367198],
            [-46.530067916992188, -0.946068526367199],
            [-46.530867916992186, -0.949868526367197],
            [-46.532667916992182, -0.953568526367199],
            [-46.536967916992182, -0.954668526367193],
            [-46.537867916992184, -0.950768526367199],
            [-46.537067916992186, -0.946668526367198],
            [-46.537567916992188, -0.942968526367196],
            [-46.538367916992186, -0.938768526367198],
            [-46.537867916992184, -0.934468526367198],
            [-46.536667916992187, -0.931168526367195],
            [-46.535867916992188, -0.926868526367194],
            [-46.534567916992188, -0.923668526367194],
            [-46.533067916992188, -0.919468526367197],
            [-46.532867916992188, -0.9137685263672],
            [-46.532867916992188, -0.908968526367197],
            [-46.535967916992185, -0.905868526367193],
            [-46.540567916992188, -0.906268526367199],
            [-46.543567916992188, -0.907568526367193],
            [-46.546467916992185, -0.909468526367199],
            [-46.549967916992188, -0.911968526367197],
            [-46.552967916992188, -0.914168526367199],
            [-46.555767916992188, -0.917068526367196],
            [-46.556867916992182, -0.920868526367194],
            [-46.557167916992185, -0.925768526367193],
            [-46.557467916992188, -0.930268526367193],
            [-46.558267916992186, -0.933268526367193],
            [-46.559367916992187, -0.936568526367196],
            [-46.560767916992184, -0.939168526367197],
            [-46.562467916992183, -0.941668526367195],
            [-46.563367916992185, -0.946068526367199],
            [-46.565967916992186, -0.951268526367194],
            [-46.568167916992188, -0.957568526367197],
            [-46.569567916992185, -0.960968526367196],
            [-46.570967916992188, -0.964768526367195],
            [-46.572867916992188, -0.969468526367194],
            [-46.574567916992187, -0.973368526367196],
            [-46.578167916992186, -0.981368526367199],
            [-46.582567916992183, -0.981368526367199],
            [-46.585067916992188, -0.979968526367195],
            [-46.587967916992184, -0.977068526367198],
            [-46.589867916992183, -0.973768526367195],
            [-46.591767916992183, -0.970968526367194],
            [-46.593467916992182, -0.968068526367198],
            [-46.595367916992188, -0.964468526367199],
            [-46.598667916992184, -0.960068526367195],
            [-46.602367916992186, -0.957568526367197],
            [-46.606667916992187, -0.956568526367199],
            [-46.605267916992183, -0.953268526367197],
            [-46.604267916992185, -0.949068526367199],
            [-46.602767916992185, -0.944868526367195],
            [-46.603167916992184, -0.940468526367198],
            [-46.607467916992185, -0.940168526367195],
            [-46.611467916992183, -0.941568526367199],
            [-46.614467916992183, -0.942668526367193],
            [-46.617667916992183, -0.944168526367193],
            [-46.620467916992183, -0.946368526367195],
            [-46.622367916992182, -0.949668526367198],
            [-46.624467916992188, -0.953568526367199],
            [-46.625767916992189, -0.956268526367197],
            [-46.626267916992184, -0.960068526367195],
            [-46.629067916992184, -0.963968526367196],
            [-46.631767916992182, -0.966468526367194],
            [-46.634267916992187, -0.968168526367194],
            [-46.637067916992187, -0.969468526367194],
            [-46.639567916992185, -0.957568526367197],
            [-46.641067916992185, -0.948468526367193],
            [-46.637167916992183, -0.945668526367193],
            [-46.634367916992183, -0.942668526367193],
            [-46.632067916992185, -0.939068526367194],
            [-46.629867916992183, -0.935568526367199],
            [-46.628267916992186, -0.930868526367199],
            [-46.627167916992185, -0.927168526367197],
            [-46.625567916992182, -0.922168526367194],
            [-46.628467916992186, -0.918668526367199],
            [-46.632467916992184, -0.915068526367193],
            [-46.636467916992189, -0.913868526367196],
            [-46.639067916992182, -0.911468526367194],
            [-46.641767916992187, -0.910068526367198],
            [-46.639667916992188, -0.907568526367193],
            [-46.636067916992182, -0.905968526367197],
            [-46.634167916992183, -0.901968526367199],
            [-46.636067916992182, -0.899068526367195],
            [-46.636367916992185, -0.895768526367199],
            [-46.632067916992185, -0.895068526367197],
            [-46.629167916992188, -0.891568526367195],
            [-46.630167916992185, -0.887668526367193],
            [-46.630267916992182, -0.884168526367198],
            [-46.626067916992184, -0.882668526367198],
            [-46.622167916992183, -0.880968526367198],
            [-46.619467916992186, -0.876868526367197],
            [-46.619967916992188, -0.872368526367197],
            [-46.616667916992185, -0.871268526367196],
            [-46.615767916992183, -0.868368526367199],
            [-46.617667916992183, -0.865068526367196],
            [-46.615567916992184, -0.858668526367197],
            [-46.612967916992183, -0.856668526367194],
            [-46.610067916992186, -0.855268526367198],
            [-46.605667916992182, -0.855668526367197],
            [-46.603067916992188, -0.857868526367199],
            [-46.599467916992182, -0.856768526367198],
            [-46.596467916992182, -0.8542685263672],
            [-46.595567916992188, -0.850568526367198],
            [-46.595567916992188, -0.846368526367193],
            [-46.595867916992184, -0.843068526367198],
            [-46.597667916992187, -0.838168526367198],
            [-46.600267916992188, -0.833568526367195],
            [-46.601667916992184, -0.830168526367196],
            [-46.603367916992184, -0.827368526367195],
            [-46.606667916992187, -0.823168526367198],
            [-46.608967916992185, -0.820668526367193],
            [-46.611367916992187, -0.817968526367196],
            [-46.613967916992188, -0.815168526367195],
            [-46.616567916992182, -0.813768526367198],
            [-46.619667916992185, -0.813068526367196],
            [-46.620767916992186, -0.809768526367193],
            [-46.619367916992182, -0.806168526367195],
            [-46.622167916992183, -0.8039685263672],
            [-46.626067916992184, -0.801968526367197],
            [-46.629867916992183, -0.800868526367196],
            [-46.632067916992185, -0.803268526367198],
            [-46.629167916992188, -0.806168526367195],
            [-46.627367916992185, -0.809368526367194],
            [-46.627067916992182, -0.812668526367197],
            [-46.630167916992185, -0.814568526367196],
            [-46.633567916992185, -0.811868526367199],
            [-46.636567916992185, -0.809468526367198],
            [-46.639267916992182, -0.805268526367193],
            [-46.638867916992183, -0.801168526367199],
            [-46.639267916992182, -0.797468526367197],
            [-46.641567916992187, -0.793868526367199],
            [-46.645767916992185, -0.792768526367198],
            [-46.649767916992182, -0.792468526367195],
            [-46.654767916992185, -0.791668526367197],
            [-46.660567916992186, -0.791368526367194],
            [-46.665267916992185, -0.791168526367194],
            [-46.668867916992184, -0.791468526367197],
            [-46.671867916992184, -0.791668526367197],
            [-46.674667916992185, -0.792468526367195],
            [-46.678067916992184, -0.793968526367195],
            [-46.679967916992183, -0.796768526367195],
            [-46.676167916992185, -0.798868526367194],
            [-46.672767916992186, -0.801368526367199],
            [-46.668967916992187, -0.804368526367199],
            [-46.666667916992182, -0.807568526367199],
            [-46.664967916992182, -0.809968526367193],
            [-46.663167916992187, -0.812468526367198],
            [-46.665667916992184, -0.814868526367199],
            [-46.670667916992187, -0.815168526367195],
            [-46.673967916992183, -0.816568526367199],
            [-46.676067916992189, -0.820168526367198],
            [-46.677267916992186, -0.824268526367199],
            [-46.676867916992187, -0.827868526367197],
            [-46.675767916992186, -0.831768526367199],
            [-46.673967916992183, -0.835168526367198],
            [-46.671967916992187, -0.838668526367194],
            [-46.670867916992187, -0.841968526367197],
            [-46.670067916992188, -0.845868526367198],
            [-46.670067916992188, -0.849168526367194],
            [-46.671767916992188, -0.851668526367199],
            [-46.676067916992189, -0.851468526367199],
            [-46.678267916992183, -0.847268526367195],
            [-46.680867916992185, -0.844468526367194],
            [-46.684467916992183, -0.842268526367199],
            [-46.686667916992185, -0.839568526367195],
            [-46.689567916992182, -0.836468526367199],
            [-46.692767916992182, -0.833668526367198],
            [-46.696267916992184, -0.834068526367197],
            [-46.700467916992189, -0.835668526367193],
            [-46.704067916992187, -0.8390685263672],
            [-46.707067916992187, -0.839768526367195],
            [-46.710467916992187, -0.840068526367197],
            [-46.713367916992183, -0.840968526367199],
            [-46.715467916992182, -0.843068526367198],
            [-46.716167916992184, -0.846668526367196],
            [-46.716267916992187, -0.8497685263672],
            [-46.716567916992183, -0.852868526367196],
            [-46.716967916992182, -0.8574685263672],
            [-46.717667916992184, -0.863268526367193],
            [-46.721667916992182, -0.8664685263672],
            [-46.724867916992189, -0.866568526367196],
            [-46.728067916992188, -0.865868526367194],
            [-46.731767916992183, -0.864068526367198],
            [-46.735267916992186, -0.860368526367196],
            [-46.734767916992183, -0.852468526367197],
            [-46.734967916992183, -0.848168526367196],
            [-46.734767916992183, -0.844168526367199],
            [-46.734267916992188, -0.8375685263672],
            [-46.736167916992187, -0.831268526367197],
            [-46.739967916992185, -0.830168526367196],
            [-46.741067916992186, -0.826068526367195],
            [-46.740767916992183, -0.821268526367199],
            [-46.744367916992182, -0.817368526367197],
            [-46.746267916992188, -0.814468526367193],
            [-46.750267916992186, -0.814568526367196],
            [-46.754867916992183, -0.812168526367195],
            [-46.758067916992182, -0.809068526367199],
            [-46.757967916992186, -0.805568526367196],
            [-46.756267916992186, -0.801668526367195],
            [-46.759067916992187, -0.798568526367198],
            [-46.762367916992183, -0.800068526367198],
            [-46.763867916992183, -0.802868526367199],
            [-46.764367916992185, -0.806868526367197],
            [-46.764867916992188, -0.811968526367195],
            [-46.765667916992186, -0.818168526367195],
            [-46.765767916992182, -0.822768526367199],
            [-46.765467916992186, -0.829068526367195],
            [-46.765067916992187, -0.833968526367194],
            [-46.766867916992183, -0.839768526367195],
            [-46.769767916992187, -0.840168526367194],
            [-46.773667916992189, -0.841268526367195],
            [-46.777367916992183, -0.843168526367194],
            [-46.780867916992186, -0.845368526367196],
            [-46.783667916992187, -0.848368526367196],
            [-46.786267916992188, -0.851168526367196],
            [-46.787367916992189, -0.854468526367199],
            [-46.788867916992189, -0.857768526367195],
            [-46.788167916992187, -0.861468526367197],
            [-46.788067916992183, -0.866668526367199],
            [-46.791467916992183, -0.869668526367199],
            [-46.795567916992184, -0.870868526367197],
            [-46.800867916992182, -0.872268526367193],
            [-46.806167916992187, -0.869768526367196],
            [-46.808067916992186, -0.864768526367193],
            [-46.809367916992187, -0.859968526367197],
            [-46.809367916992187, -0.855868526367196],
            [-46.809167916992187, -0.851668526367199],
            [-46.808567916992182, -0.848668526367199],
            [-46.808267916992186, -0.844768526367197],
            [-46.807167916992185, -0.840868526367196],
            [-46.806967916992186, -0.837868526367195],
            [-46.806867916992182, -0.833768526367194],
            [-46.805467916992185, -0.830468526367198],
            [-46.806167916992187, -0.826268526367194],
            [-46.811367916992182, -0.827368526367195],
            [-46.815267916992184, -0.829568526367197],
            [-46.817967916992188, -0.831868526367195],
            [-46.820767916992182, -0.835068526367195],
            [-46.823567916992182, -0.837868526367195],
            [-46.827067916992185, -0.841268526367195],
            [-46.830767916992187, -0.842868526367198],
            [-46.834067916992183, -0.841168526367198],
            [-46.833267916992185, -0.837268526367197],
            [-46.832167916992184, -0.833968526367194],
            [-46.833167916992188, -0.827568526367195],
            [-46.836167916992189, -0.824268526367199],
            [-46.840567916992185, -0.821068526367199],
            [-46.843467916992182, -0.818768526367194],
            [-46.839067916992185, -0.817068526367194],
            [-46.833667916992184, -0.815968526367193],
            [-46.829267916992187, -0.813768526367198],
            [-46.826267916992187, -0.810868526367194],
            [-46.825967916992184, -0.807968526367198],
            [-46.824867916992183, -0.804368526367199],
            [-46.828167916992186, -0.800768526367193],
            [-46.831767916992185, -0.799168526367197],
            [-46.833967916992187, -0.797168526367194],
            [-46.835967916992182, -0.794668526367197],
            [-46.838967916992182, -0.792168526367199],
            [-46.834667916992188, -0.790668526367199],
            [-46.830167916992188, -0.791668526367197],
            [-46.825667916992188, -0.792868526367194],
            [-46.824267916992184, -0.7887685263672],
            [-46.825167916992186, -0.785568526367193],
            [-46.826267916992187, -0.782068526367198],
            [-46.826767916992182, -0.778168526367196],
            [-46.829267916992187, -0.774768526367197],
            [-46.831867916992188, -0.771068526367195],
            [-46.830667916992184, -0.767868526367195],
            [-46.829567916992183, -0.764268526367196],
            [-46.830367916992188, -0.759368526367197],
            [-46.831867916992188, -0.754568526367194],
            [-46.835367916992183, -0.7521685263672],
            [-46.835867916992186, -0.747968526367195],
            [-46.838767916992182, -0.745468526367198],
            [-46.842267916992185, -0.743368526367199],
            [-46.846767916992185, -0.740768526367198],
            [-46.850067916992188, -0.739368526367194],
            [-46.853167916992184, -0.739668526367197],
            [-46.856167916992185, -0.740468526367195],
            [-46.859667916992187, -0.741068526367194],
            [-46.862767916992183, -0.741968526367195],
            [-46.865267916992188, -0.743668526367195],
            [-46.868267916992188, -0.746068526367196],
            [-46.871067916992182, -0.749668526367195],
            [-46.868367916992185, -0.751868526367197],
            [-46.864367916992187, -0.752968526367198],
            [-46.861767916992186, -0.754468526367198],
            [-46.862267916992188, -0.757768526367194],
            [-46.864067916992184, -0.760268526367199],
            [-46.869067916992186, -0.760668526367198],
            [-46.875167916992183, -0.760768526367194],
            [-46.879067916992184, -0.7628685263672],
            [-46.882167916992188, -0.765668526367193],
            [-46.884167916992183, -0.768968526367196],
            [-46.883867916992187, -0.772368526367195],
            [-46.882667916992183, -0.776468526367196],
            [-46.882467916992184, -0.780868526367193],
            [-46.882667916992183, -0.784468526367199],
            [-46.882767916992186, -0.787768526367195],
            [-46.882667916992183, -0.791468526367197],
            [-46.882967916992186, -0.795668526367194],
            [-46.882767916992186, -0.801068526367196],
            [-46.884167916992183, -0.808368526367197],
            [-46.887367916992183, -0.810768526367198],
            [-46.892167916992186, -0.812968526367193],
            [-46.896867916992186, -0.814068526367194],
            [-46.901167916992186, -0.814568526367196],
            [-46.905067916992188, -0.815968526367193],
            [-46.909167916992182, -0.818168526367195],
            [-46.912767916992188, -0.821068526367199],
            [-46.914967916992182, -0.825968526367198],
            [-46.915367916992182, -0.829968526367196],
            [-46.918367916992182, -0.840068526367197],
            [-46.918367916992182, -0.844768526367197],
            [-46.916767916992185, -0.849468526367197],
            [-46.916067916992183, -0.852368526367194],
            [-46.919767916992186, -0.856368526367199],
            [-46.923567916992184, -0.857568526367196],
            [-46.925067916992184, -0.859968526367197],
            [-46.926867916992187, -0.862868526367194],
            [-46.929767916992184, -0.866168526367197],
            [-46.933367916992182, -0.868268526367196],
            [-46.936267916992186, -0.869668526367199],
            [-46.939967916992188, -0.869368526367197],
            [-46.942767916992182, -0.865568526367198],
            [-46.943467916992184, -0.860368526367196],
            [-46.940467916992183, -0.857868526367199],
            [-46.937667916992183, -0.855068526367198],
            [-46.938467916992188, -0.849468526367197],
            [-46.942067916992187, -0.839468526367199],
            [-46.943467916992184, -0.833968526367194],
            [-46.944267916992182, -0.830168526367196],
            [-46.944967916992184, -0.825468526367196],
            [-46.944367916992185, -0.820168526367198],
            [-46.943767916992186, -0.816668526367195],
            [-46.942667916992185, -0.811368526367197],
            [-46.941967916992184, -0.807968526367198],
            [-46.949967916992186, -0.800368526367194],
            [-46.948467916992186, -0.797568526367193],
            [-46.948267916992187, -0.794168526367194],
            [-46.950667916992188, -0.7917685263672],
            [-46.953967916992184, -0.789168526367199],
            [-46.956767916992185, -0.786168526367199],
            [-46.959767916992185, -0.7827685263672],
            [-46.962367916992186, -0.780168526367198],
            [-46.967267916992185, -0.772868526367198],
            [-46.969167916992184, -0.766768526367194],
            [-46.966967916992182, -0.763768526367194],
            [-46.965567916992185, -0.760268526367199],
            [-46.962567916992185, -0.756568526367197],
            [-46.959267916992182, -0.753068526367194],
            [-46.956067916992183, -0.750468526367193],
            [-46.952167916992188, -0.746668526367195],
            [-46.950667916992188, -0.742768526367193],
            [-46.949567916992187, -0.739668526367197],
            [-46.948467916992186, -0.736468526367197],
            [-46.946867916992183, -0.7339685263672],
            [-46.946367916992187, -0.729768526367195],
            [-46.948467916992186, -0.726168526367196],
            [-46.950367916992185, -0.723168526367196],
            [-46.953267916992182, -0.719868526367193],
            [-46.956067916992183, -0.716568526367197],
            [-46.957567916992183, -0.711868526367198],
            [-46.960067916992188, -0.709668526367196],
            [-46.963167916992184, -0.708268526367199],
            [-46.968067916992183, -0.706068526367197],
            [-46.971967916992185, -0.704668526367193],
            [-46.974967916992185, -0.704268526367194],
            [-46.977867916992182, -0.705768526367194],
            [-46.981067916992188, -0.707268526367194],
            [-46.985367916992182, -0.709268526367197],
            [-46.989967916992185, -0.711468526367199],
            [-46.993667916992187, -0.714568526367195],
            [-46.993667916992187, -0.718468526367197],
            [-46.989767916992186, -0.718668526367196],
            [-46.988967916992188, -0.723668526367199],
            [-46.990067916992189, -0.727268526367197],
            [-46.992267916992184, -0.7292685263672],
            [-46.993367916992185, -0.733268526367198],
            [-46.995567916992186, -0.7369685263672],
            [-46.996167916992185, -0.741668526367199],
            [-47.000567916992182, -0.745768526367193],
            [-47.003467916992186, -0.748268526367198],
            [-47.003467916992186, -0.752468526367196],
            [-47.003767916992182, -0.756768526367196],
            [-47.003067916992187, -0.759968526367196],
            [-47.004867916992183, -0.764868526367195],
            [-47.007067916992185, -0.7673685263672],
            [-47.010667916992183, -0.769368526367195],
            [-47.013967916992186, -0.770668526367196],
            [-47.016467916992184, -0.767068526367197],
            [-47.013467916992184, -0.762468526367194],
            [-47.015367916992183, -0.758768526367199],
            [-47.018167916992184, -0.760468526367198],
            [-47.021467916992187, -0.764268526367196],
            [-47.025667916992184, -0.764568526367199],
            [-47.030067916992188, -0.766368526367195],
            [-47.032267916992183, -0.770668526367196],
            [-47.033967916992182, -0.774068526367195],
            [-47.035567916992186, -0.778368526367196],
            [-47.036467916992187, -0.781268526367199],
            [-47.035567916992186, -0.785568526367193],
            [-47.035267916992183, -0.789268526367195],
            [-47.036667916992187, -0.793068526367193],
            [-47.040967916992187, -0.796168526367197],
            [-47.045567916992184, -0.798068526367196],
            [-47.049767916992188, -0.798368526367199],
            [-47.053067916992184, -0.797768526367193],
            [-47.055867916992185, -0.797268526367198],
            [-47.059167916992187, -0.795668526367194],
            [-47.063067916992182, -0.793468526367199],
            [-47.063367916992185, -0.789868526367194],
            [-47.063367916992185, -0.785568526367193],
            [-47.064167916992183, -0.780468526367194],
            [-47.065567916992187, -0.7765685263672],
            [-47.067667916992185, -0.774068526367195],
            [-47.071267916992184, -0.774068526367195],
            [-47.073467916992186, -0.776868526367195],
            [-47.073567916992182, -0.781968526367194],
            [-47.075367916992185, -0.785668526367196],
            [-47.077567916992187, -0.7827685263672],
            [-47.078167916992186, -0.777068526367195],
            [-47.077167916992188, -0.771768526367197],
            [-47.078567916992185, -0.766768526367194],
            [-47.081567916992185, -0.764568526367199],
            [-47.081767916992185, -0.761268526367196],
            [-47.082167916992184, -0.757768526367194],
            [-47.081767916992185, -0.754068526367199],
            [-47.085967916992182, -0.751868526367197],
            [-47.089767916992187, -0.753568526367197],
            [-47.094067916992188, -0.755568526367199],
            [-47.097367916992184, -0.755168526367193],
            [-47.098967916992187, -0.751668526367197],
            [-47.096967916992185, -0.749068526367196],
            [-47.095367916992188, -0.745768526367193],
            [-47.094067916992188, -0.742468526367198],
            [-47.091267916992187, -0.738668526367199],
            [-47.091167916992184, -0.734668526367194],
            [-47.090167916992186, -0.730968526367199],
            [-47.089367916992188, -0.727068526367198],
            [-47.088967916992182, -0.723968526367194],
            [-47.087967916992184, -0.720968526367194],
            [-47.090567916992185, -0.717268526367199],
            [-47.087967916992184, -0.711268526367199],
            [-47.084067916992183, -0.709368526367193],
            [-47.081067916992183, -0.710768526367197],
            [-47.078167916992186, -0.712868526367195],
            [-47.074967916992186, -0.708968526367194],
            [-47.074267916992184, -0.704668526367193],
            [-47.074267916992184, -0.700468526367196],
            [-47.074967916992186, -0.695168526367198],
            [-47.077467916992184, -0.691068526367197],
            [-47.081467916992182, -0.686368526367197],
            [-47.083767916992187, -0.683368526367197],
            [-47.085867916992186, -0.680768526367196],
            [-47.089367916992188, -0.677168526367197],
            [-47.092967916992187, -0.673268526367195],
            [-47.097067916992188, -0.672168526367194],
            [-47.099867916992189, -0.672768526367193],
            [-47.102867916992182, -0.673968526367197],
            [-47.106167916992185, -0.675768526367193],
            [-47.111467916992183, -0.675868526367196],
            [-47.115367916992184, -0.675868526367196],
            [-47.118267916992188, -0.675768526367193],
            [-47.121867916992187, -0.676368526367199],
            [-47.126367916992187, -0.678668526367197],
            [-47.129567916992187, -0.682168526367199],
            [-47.129667916992183, -0.6866685263672],
            [-47.124767916992184, -0.689468526367193],
            [-47.124167916992185, -0.693768526367194],
            [-47.129067916992184, -0.693768526367194],
            [-47.133767916992184, -0.694268526367196],
            [-47.137667916992186, -0.695668526367193],
            [-47.139067916992182, -0.699268526367199],
            [-47.136867916992188, -0.701568526367197],
            [-47.134567916992182, -0.703968526367198],
            [-47.132667916992183, -0.707168526367198],
            [-47.130267916992182, -0.710368526367198],
            [-47.128467916992186, -0.713368526367198],
            [-47.131867916992185, -0.716968526367197],
            [-47.133067916992182, -0.722268526367195],
            [-47.134967916992188, -0.725568526367198],
            [-47.136067916992182, -0.729168526367197],
            [-47.137967916992189, -0.733568526367193],
            [-47.145967916992184, -0.742468526367198],
            [-47.149567916992183, -0.740268526367196],
            [-47.153067916992185, -0.740468526367195],
            [-47.155967916992182, -0.742968526367193],
            [-47.158067916992188, -0.747168526367197],
            [-47.159767916992188, -0.752368526367199],
            [-47.160167916992187, -0.756768526367196],
            [-47.160567916992186, -0.761068526367197],
            [-47.161967916992182, -0.764268526367196],
            [-47.165867916992184, -0.765368526367197],
            [-47.170267916992188, -0.763868526367197],
            [-47.173567916992184, -0.760668526367198],
            [-47.176167916992185, -0.753568526367197],
            [-47.176867916992187, -0.749668526367195],
            [-47.178067916992184, -0.746868526367194],
            [-47.178267916992183, -0.740468526367195],
            [-47.176567916992184, -0.736468526367197],
            [-47.174667916992185, -0.7339685263672],
            [-47.171667916992185, -0.730668526367197],
            [-47.167567916992184, -0.729768526367195],
            [-47.166467916992183, -0.726768526367195],
            [-47.166667916992182, -0.723368526367196],
            [-47.166667916992182, -0.718668526367196],
            [-47.166667916992182, -0.713368526367198],
            [-47.167767916992183, -0.708668526367198],
            [-47.168867916992184, -0.705768526367194],
            [-47.168167916992182, -0.7018685263672],
            [-47.167767916992183, -0.697868526367195],
            [-47.167567916992184, -0.694568526367199],
            [-47.169967916992185, -0.686968526367195],
            [-47.170367916992184, -0.683068526367194],
            [-47.171067916992186, -0.679168526367199],
            [-47.171067916992186, -0.676168526367199],
            [-47.173367916992184, -0.673568526367198],
            [-47.177467916992185, -0.673668526367194],
            [-47.179767916992184, -0.677268526367193],
            [-47.183867916992185, -0.679968526367198],
            [-47.187667916992183, -0.682668526367195],
            [-47.191667916992188, -0.686368526367197],
            [-47.194367916992185, -0.688268526367196],
            [-47.196067916992185, -0.691068526367197],
            [-47.197067916992182, -0.693868526367197],
            [-47.198467916992186, -0.696568526367194],
            [-47.201767916992182, -0.697968526367198],
            [-47.205467916992184, -0.697768526367199],
            [-47.209267916992182, -0.695568526367197],
            [-47.210967916992182, -0.690268526367198],
            [-47.212267916992182, -0.685768526367198],
            [-47.211167916992189, -0.681168526367195],
            [-47.208267916992185, -0.678568526367194],
            [-47.206767916992185, -0.674968526367195],
            [-47.206167916992186, -0.667268526367195],
            [-47.205667916992184, -0.660968526367199],
            [-47.205967916992186, -0.657668526367196],
            [-47.204667916992186, -0.654768526367199],
            [-47.204867916992185, -0.650368526367195],
            [-47.208267916992185, -0.647668526367198],
            [-47.211767916992187, -0.645368526367193],
            [-47.212867916992188, -0.642068526367197],
            [-47.214767916992187, -0.637768526367196],
            [-47.216567916992183, -0.635368526367195],
            [-47.219767916992183, -0.632368526367195],
            [-47.224267916992183, -0.631368526367197],
            [-47.228367916992184, -0.630468526367196],
            [-47.232567916992188, -0.629168526367195],
            [-47.236467916992183, -0.628268526367194],
            [-47.241467916992185, -0.627768526367198],
            [-47.251067916992184, -0.626268526367198],
            [-47.255267916992182, -0.623668526367197],
            [-47.260967916992186, -0.625268526367194],
            [-47.260467916992184, -0.630168526367193],
            [-47.257967916992186, -0.634968526367196],
            [-47.253867916992185, -0.636768526367199],
            [-47.251967916992186, -0.640068526367195],
            [-47.251567916992187, -0.644268526367199],
            [-47.256667916992185, -0.646268526367194],
            [-47.258567916992185, -0.648968526367199],
            [-47.261267916992182, -0.650668526367198],
            [-47.261767916992184, -0.647868526367198],
            [-47.264267916992182, -0.644868526367198],
            [-47.268267916992187, -0.644368526367195],
            [-47.271167916992184, -0.645068526367197],
            [-47.274767916992182, -0.641768526367194],
            [-47.277667916992186, -0.639668526367196],
            [-47.280567916992183, -0.638468526367198],
            [-47.283667916992187, -0.638168526367195],
            [-47.287267916992185, -0.6393685263672],
            [-47.289967916992182, -0.642068526367197],
            [-47.292767916992183, -0.644768526367194],
            [-47.295267916992188, -0.642868526367195],
            [-47.294967916992185, -0.639868526367195],
            [-47.294567916992186, -0.636768526367199],
            [-47.292267916992188, -0.6348685263672],
            [-47.291067916992183, -0.631268526367194],
            [-47.289867916992186, -0.627468526367196],
            [-47.286267916992188, -0.624868526367194],
            [-47.285867916992188, -0.620868526367197],
            [-47.285267916992183, -0.617168526367195],
            [-47.285067916992183, -0.613868526367199],
            [-47.285167916992187, -0.610368526367196],
            [-47.285567916992186, -0.606968526367197],
            [-47.286167916992184, -0.603568526367198],
            [-47.288067916992183, -0.600568526367198],
            [-47.291467916992183, -0.597668526367194],
            [-47.295067916992188, -0.596168526367194],
            [-47.298267916992188, -0.595568526367195],
            [-47.301667916992187, -0.595868526367198],
            [-47.306167916992187, -0.594868526367193],
            [-47.309767916992186, -0.594068526367195],
            [-47.312967916992186, -0.593168526367194],
            [-47.316567916992184, -0.592268526367199],
            [-47.320467916992186, -0.591268526367195],
            [-47.324267916992184, -0.592868526367198],
            [-47.327067916992185, -0.595968526367194],
            [-47.329967916992182, -0.597268526367195],
            [-47.333267916992185, -0.598068526367193],
            [-47.336967916992187, -0.599168526367194],
            [-47.341167916992184, -0.599868526367196],
            [-47.344467916992187, -0.600368526367198],
            [-47.349267916992183, -0.600668526367194],
            [-47.354467916992185, -0.602368526367194],
            [-47.356067916992188, -0.606068526367196],
            [-47.349567916992186, -0.606768526367198],
            [-47.347367916992184, -0.609968526367197],
            [-47.350967916992182, -0.612168526367199],
            [-47.353967916992183, -0.612868526367194],
            [-47.357167916992182, -0.611768526367193],
            [-47.360367916992182, -0.609768526367198],
            [-47.363667916992185, -0.607068526367193],
            [-47.365067916992189, -0.603368526367198],
            [-47.367267916992184, -0.600968526367197],
            [-47.370467916992183, -0.599968526367199],
            [-47.373767916992186, -0.600068526367195],
            [-47.378067916992187, -0.600668526367194],
            [-47.381667916992185, -0.601468526367199],
            [-47.384867916992185, -0.603068526367196],
            [-47.386567916992185, -0.606668526367194],
            [-47.385667916992183, -0.609968526367197],
            [-47.384867916992185, -0.612868526367194],
            [-47.375467916992186, -0.617468526367198],
            [-47.370867916992182, -0.620068526367199],
            [-47.367767916992186, -0.623368526367194],
            [-47.367667916992183, -0.6271685263672],
            [-47.370467916992183, -0.629968526367193],
            [-47.375167916992183, -0.631768526367196],
            [-47.380667916992188, -0.631368526367197],
            [-47.385167916992188, -0.629968526367193],
            [-47.388467916992184, -0.628068526367194],
            [-47.388767916992187, -0.631268526367194],
            [-47.388867916992183, -0.635368526367195],
            [-47.390667916992186, -0.639268526367196],
            [-47.392167916992186, -0.643268526367194],
            [-47.392967916992184, -0.646868526367193],
            [-47.394867916992183, -0.652968526367196],
            [-47.404167916992186, -0.655068526367195],
            [-47.408067916992188, -0.652268526367195],
            [-47.410267916992183, -0.650468526367199],
            [-47.413067916992183, -0.649868526367193],
            [-47.415867916992184, -0.647668526367198],
            [-47.417867916992186, -0.645368526367193],
            [-47.418067916992186, -0.642168526367193],
            [-47.419767916992186, -0.639868526367195],
            [-47.422867916992182, -0.642668526367196],
            [-47.424767916992188, -0.646068526367195],
            [-47.425867916992182, -0.6500685263672],
            [-47.426567916992184, -0.655868526367193],
            [-47.429367916992184, -0.6515685263672],
            [-47.429767916992184, -0.647668526367198],
            [-47.430467916992185, -0.643568526367197],
            [-47.430467916992185, -0.639668526367196],
            [-47.428967916992185, -0.637168526367198],
            [-47.426967916992183, -0.634968526367196],
            [-47.423967916992183, -0.632068526367199],
            [-47.421967916992187, -0.627368526367199],
            [-47.420767916992183, -0.624368526367199],
            [-47.422167916992187, -0.620868526367197],
            [-47.418567916992188, -0.617968526367193],
            [-47.416367916992186, -0.612768526367198],
            [-47.414567916992183, -0.609768526367198],
            [-47.411767916992183, -0.6042685263672],
            [-47.410967916992185, -0.600368526367198],
            [-47.411967916992182, -0.597268526367195],
            [-47.413467916992182, -0.594768526367197],
            [-47.414567916992183, -0.591968526367197],
            [-47.418167916992182, -0.587868526367195],
            [-47.422167916992187, -0.584868526367195],
            [-47.426167916992185, -0.584368526367193],
            [-47.430767916992188, -0.585068526367195],
            [-47.433767916992188, -0.585068526367195],
            [-47.439067916992187, -0.584768526367199],
            [-47.442367916992183, -0.5845685263672],
            [-47.446067916992185, -0.585168526367198],
            [-47.448867916992185, -0.585868526367193],
            [-47.453567916992185, -0.586768526367194],
            [-47.458467916992184, -0.587968526367199],
            [-47.462867916992188, -0.589568526367195],
            [-47.466167916992184, -0.5905685263672],
            [-47.470067916992186, -0.591268526367195],
            [-47.472867916992186, -0.593468526367197],
            [-47.470567916992188, -0.596968526367199],
            [-47.471967916992185, -0.600968526367197],
            [-47.475267916992188, -0.601668526367199],
            [-47.477867916992182, -0.603968526367197],
            [-47.479667916992184, -0.607868526367199],
            [-47.480567916992186, -0.612568526367198],
            [-47.483267916992183, -0.616368526367197],
            [-47.484667916992187, -0.619068526367194],
            [-47.482467916992185, -0.622268526367193],
            [-47.477067916992183, -0.623068526367199],
            [-47.476367916992189, -0.626568526367194],
            [-47.473167916992182, -0.629968526367193],
            [-47.474567916992186, -0.635168526367195],
            [-47.475567916992183, -0.641568526367195],
            [-47.474867916992189, -0.645068526367197],
            [-47.475667916992187, -0.648368526367193],
            [-47.476967916992187, -0.652568526367197],
            [-47.478467916992187, -0.656268526367199],
            [-47.477267916992183, -0.659768526367195],
            [-47.476367916992189, -0.663968526367199],
            [-47.473167916992182, -0.670268526367195],
            [-47.473467916992185, -0.675568526367194],
            [-47.474167916992187, -0.679768526367198],
            [-47.472367916992184, -0.684368526367194],
            [-47.471367916992186, -0.687768526367194],
            [-47.469867916992186, -0.693768526367194],
            [-47.470067916992186, -0.708668526367198],
            [-47.471167916992187, -0.718368526367193],
            [-47.471767916992185, -0.723468526367199],
            [-47.472867916992186, -0.729568526367196],
            [-47.473867916992184, -0.735668526367199],
            [-47.476467916992185, -0.738068526367194],
            [-47.480567916992186, -0.738668526367199],
            [-47.483667916992182, -0.736068526367198],
            [-47.484667916992187, -0.732768526367195],
            [-47.487567916992184, -0.729168526367197],
            [-47.491067916992186, -0.726368526367196],
            [-47.494067916992186, -0.724168526367194],
            [-47.495767916992186, -0.721668526367196],
            [-47.497367916992182, -0.718068526367198],
            [-47.498367916992187, -0.715168526367194],
            [-47.499867916992187, -0.712668526367196],
            [-47.501767916992186, -0.707868526367193],
            [-47.500467916992186, -0.703768526367199],
            [-47.498867916992182, -0.699968526367194],
            [-47.499767916992184, -0.695268526367194],
            [-47.501567916992187, -0.690168526367195],
            [-47.504067916992184, -0.686968526367195],
            [-47.506867916992185, -0.683368526367197],
            [-47.510167916992188, -0.678668526367197],
            [-47.513567916992187, -0.675068526367198],
            [-47.515367916992183, -0.672268526367198],
            [-47.516867916992183, -0.668968526367195],
            [-47.518167916992184, -0.664568526367198],
            [-47.519267916992185, -0.661668526367194],
            [-47.519767916992187, -0.658468526367194],
            [-47.520167916992186, -0.655468526367194],
            [-47.520667916992188, -0.650968526367194],
            [-47.522567916992188, -0.646768526367197],
            [-47.525767916992187, -0.645368526367193],
            [-47.528667916992184, -0.645768526367199],
            [-47.531167916992182, -0.647868526367198],
            [-47.534167916992182, -0.645768526367199],
            [-47.536467916992187, -0.641868526367197],
            [-47.539967916992182, -0.639068526367197],
            [-47.537567916992188, -0.635968526367193],
            [-47.536167916992184, -0.632668526367198],
            [-47.537267916992185, -0.629068526367199],
            [-47.538667916992182, -0.625168526367197],
            [-47.536467916992187, -0.6211685263672],
            [-47.532867916992188, -0.620568526367194],
            [-47.531267916992185, -0.617168526367195],
            [-47.532867916992188, -0.613268526367193],
            [-47.535267916992183, -0.609368526367199],
            [-47.537767916992188, -0.605368526367194],
            [-47.540067916992186, -0.602368526367194],
            [-47.542767916992183, -0.599568526367193],
            [-47.545267916992188, -0.597268526367195],
            [-47.549367916992182, -0.593368526367193],
            [-47.553367916992187, -0.590168526367194],
            [-47.556167916992187, -0.587868526367195],
            [-47.559367916992187, -0.585768526367197],
            [-47.562667916992183, -0.582868526367193],
            [-47.567367916992183, -0.581268526367197],
            [-47.571767916992187, -0.580368526367195],
            [-47.576267916992187, -0.578568526367199],
            [-47.580367916992188, -0.577368526367195],
            [-47.583667916992184, -0.577068526367199],
            [-47.587867916992188, -0.576768526367196],
            [-47.587867916992188, -0.581568526367199],
            [-47.588967916992182, -0.584768526367199],
            [-47.590367916992186, -0.588268526367195],
            [-47.591267916992187, -0.593368526367193],
            [-47.590067916992183, -0.596268526367197],
            [-47.587267916992182, -0.599468526367197],
            [-47.583767916992187, -0.602068526367198],
            [-47.584367916992186, -0.606668526367194],
            [-47.585667916992186, -0.610268526367193],
            [-47.586467916992184, -0.613368526367196],
            [-47.588167916992184, -0.617268526367198],
            [-47.587367916992186, -0.620868526367197],
            [-47.586867916992183, -0.624468526367195],
            [-47.586567916992188, -0.629068526367199],
            [-47.586467916992184, -0.632968526367193],
            [-47.586767916992187, -0.637368526367197],
            [-47.588667916992186, -0.641268526367199],
            [-47.588967916992182, -0.644368526367195],
            [-47.590567916992185, -0.648168526367193],
            [-47.593067916992183, -0.652268526367195],
            [-47.595867916992184, -0.655168526367198],
            [-47.597367916992184, -0.658468526367194],
            [-47.598767916992188, -0.661268526367195],
            [-47.600367916992184, -0.664568526367198],
            [-47.601367916992189, -0.667768526367198],
            [-47.602767916992185, -0.671768526367195],
            [-47.604267916992185, -0.674668526367199],
            [-47.606067916992188, -0.678068526367198],
            [-47.608667916992182, -0.682468526367195],
            [-47.610567916992188, -0.686268526367193],
            [-47.609767916992183, -0.690168526367195],
            [-47.606967916992183, -0.694068526367197],
            [-47.607867916992184, -0.697668526367195],
            [-47.610367916992182, -0.699568526367194],
            [-47.614067916992184, -0.699868526367197],
            [-47.617967916992185, -0.699968526367194],
            [-47.619767916992188, -0.702968526367194],
            [-47.623267916992184, -0.705468526367198],
            [-47.626067916992184, -0.706268526367197],
            [-47.630567916992185, -0.706768526367199],
            [-47.634567916992182, -0.703768526367199],
            [-47.633467916992188, -0.700168526367193],
            [-47.631567916992182, -0.696568526367194],
            [-47.631267916992186, -0.692368526367197],
            [-47.633467916992188, -0.688568526367199],
            [-47.636067916992182, -0.686068526367194],
            [-47.639667916992188, -0.685868526367194],
            [-47.643967916992182, -0.688568526367199],
            [-47.644767916992187, -0.684368526367194],
            [-47.643167916992184, -0.680468526367193],
            [-47.640667916992186, -0.677568526367196],
            [-47.638167916992188, -0.672868526367196],
            [-47.635667916992183, -0.668468526367199],
            [-47.634367916992183, -0.665568526367196],
            [-47.633867916992187, -0.662468526367199],
            [-47.634867916992185, -0.658468526367194],
            [-47.637667916992186, -0.655468526367194],
            [-47.640967916992182, -0.6530685263672],
            [-47.639267916992182, -0.649868526367193],
            [-47.636767916992184, -0.648368526367193],
            [-47.635167916992188, -0.644868526367198],
            [-47.635667916992183, -0.640768526367197],
            [-47.636767916992184, -0.636068526367197],
            [-47.634367916992183, -0.633468526367196],
            [-47.631667916992185, -0.630168526367193],
            [-47.630267916992182, -0.625568526367196],
            [-47.631667916992185, -0.621068526367196],
            [-47.631567916992182, -0.616168526367197],
            [-47.634167916992183, -0.613268526367193],
            [-47.637367916992183, -0.616868526367199],
            [-47.637767916992182, -0.613368526367196],
            [-47.635267916992184, -0.610368526367196],
            [-47.631667916992185, -0.6074685263672],
            [-47.632967916992186, -0.601668526367199],
            [-47.635667916992183, -0.597868526367193],
            [-47.638867916992183, -0.594068526367195],
            [-47.642167916992186, -0.590168526367194],
            [-47.644567916992187, -0.587968526367199],
            [-47.647267916992185, -0.585468526367194],
            [-47.650767916992187, -0.582668526367193],
            [-47.654567916992185, -0.581068526367197],
            [-47.658167916992184, -0.578968526367198],
            [-47.662367916992189, -0.577168526367196],
            [-47.666667916992182, -0.575668526367195],
            [-47.670867916992187, -0.575168526367193],
            [-47.674267916992186, -0.575768526367199],
            [-47.676967916992183, -0.577168526367196],
            [-47.679667916992187, -0.579568526367197],
            [-47.681167916992187, -0.582968526367196],
            [-47.681567916992186, -0.587268526367197],
            [-47.684467916992183, -0.589468526367199],
            [-47.688367916992185, -0.590468526367196],
            [-47.691267916992182, -0.593668526367196],
            [-47.694867916992187, -0.595968526367194],
            [-47.697667916992188, -0.597268526367195],
            [-47.700767916992184, -0.596268526367197],
            [-47.701867916992185, -0.592968526367194],
            [-47.700667916992188, -0.589868526367198],
            [-47.699867916992183, -0.585768526367197],
            [-47.698767916992182, -0.580668526367198],
            [-47.697067916992182, -0.575468526367196],
            [-47.694767916992184, -0.569968526367198],
            [-47.693767916992186, -0.564368526367197],
            [-47.692667916992185, -0.560768526367198],
            [-47.690967916992186, -0.557768526367198],
            [-47.690467916992183, -0.554468526367195],
            [-47.693167916992188, -0.553568526367194],
            [-47.697767916992184, -0.553668526367197],
            [-47.702667916992183, -0.554068526367196],
            [-47.703567916992185, -0.551368526367199],
            [-47.700767916992184, -0.550068526367198],
            [-47.701067916992187, -0.547168526367194],
            [-47.698867916992185, -0.543868526367199],
            [-47.698567916992182, -0.539768526367197],
            [-47.700967916992184, -0.536668526367194],
            [-47.703767916992184, -0.534768526367195],
            [-47.708667916992184, -0.534468526367199],
            [-47.713167916992184, -0.534468526367199],
            [-47.718667916992182, -0.535168526367194],
            [-47.722767916992183, -0.535568526367193],
            [-47.725867916992186, -0.535868526367196],
            [-47.729567916992188, -0.535868526367196],
            [-47.734367916992184, -0.535968526367199],
            [-47.739767916992186, -0.536968526367197],
            [-47.744167916992183, -0.537868526367198],
            [-47.748367916992187, -0.539568526367198],
            [-47.751267916992184, -0.541668526367197],
            [-47.754067916992184, -0.543168526367197],
            [-47.755267916992182, -0.545868526367194],
            [-47.751267916992184, -0.548068526367196],
            [-47.746867916992187, -0.551168526367199],
            [-47.743667916992187, -0.555168526367197],
            [-47.742567916992186, -0.559368526367194],
            [-47.741367916992182, -0.563068526367196],
            [-47.743267916992188, -0.566268526367196],
            [-47.746367916992185, -0.566568526367199],
            [-47.748267916992184, -0.563068526367196],
            [-47.752367916992185, -0.561968526367195],
            [-47.756267916992186, -0.5616685263672],
            [-47.761067916992182, -0.560768526367198],
            [-47.766467916992184, -0.560768526367198],
            [-47.770467916992182, -0.563368526367199],
            [-47.770067916992183, -0.567068526367194],
            [-47.769267916992185, -0.570768526367196],
            [-47.768167916992184, -0.574968526367194],
            [-47.766467916992184, -0.579568526367197],
            [-47.765467916992186, -0.583268526367199],
            [-47.763167916992188, -0.586768526367194],
            [-47.761067916992182, -0.589368526367195],
            [-47.758767916992184, -0.5920685263672],
            [-47.757367916992187, -0.595968526367194],
            [-47.757467916992184, -0.5997685263672],
            [-47.756767916992182, -0.603968526367197],
            [-47.756267916992186, -0.608568526367193],
            [-47.759367916992183, -0.609968526367197],
            [-47.762667916992186, -0.610668526367199],
            [-47.765767916992182, -0.610868526367199],
            [-47.768767916992182, -0.609468526367195],
            [-47.772167916992188, -0.606368526367199],
            [-47.773367916992186, -0.602868526367196],
            [-47.775367916992188, -0.599968526367199],
            [-47.776167916992186, -0.5967685263672],
            [-47.776567916992185, -0.593768526367199],
            [-47.775667916992184, -0.590168526367194],
            [-47.773667916992189, -0.587268526367197],
            [-47.775167916992189, -0.584268526367197],
            [-47.777567916992183, -0.581768526367199],
            [-47.781767916992187, -0.582568526367197],
            [-47.786667916992187, -0.584868526367195],
            [-47.790267916992185, -0.585668526367193],
            [-47.794967916992185, -0.585468526367194],
            [-47.797867916992182, -0.585668526367193],
            [-47.801067916992189, -0.585468526367194],
            [-47.803367916992187, -0.587668526367196],
            [-47.807167916992185, -0.587968526367199],
            [-47.809367916992187, -0.585168526367198],
            [-47.809067916992184, -0.582068526367195],
            [-47.808267916992186, -0.579268526367194],
            [-47.806067916992184, -0.575668526367195],
            [-47.802867916992184, -0.572168526367193],
            [-47.799767916992188, -0.569568526367199],
            [-47.798167916992185, -0.565968526367193],
            [-47.796367916992182, -0.563268526367196],
            [-47.797267916992183, -0.559968526367193],
            [-47.799967916992188, -0.557768526367198],
            [-47.802967916992188, -0.556368526367194],
            [-47.806067916992184, -0.555468526367193],
            [-47.810167916992185, -0.556168526367195],
            [-47.814867916992185, -0.558368526367197],
            [-47.817667916992185, -0.560868526367194],
            [-47.819167916992185, -0.564068526367194],
            [-47.816367916992185, -0.567968526367196],
            [-47.815267916992184, -0.572468526367196],
            [-47.814467916992186, -0.576368526367197],
            [-47.816867916992187, -0.579668526367193],
            [-47.818167916992188, -0.583968526367194],
            [-47.819867916992187, -0.586768526367194],
            [-47.822067916992182, -0.589768526367195],
            [-47.824067916992185, -0.592368526367196],
            [-47.825667916992188, -0.595668526367199],
            [-47.825667916992188, -0.600268526367195],
            [-47.826767916992182, -0.605068526367198],
            [-47.829667916992186, -0.608868526367196],
            [-47.830167916992188, -0.613368526367196],
            [-47.829567916992183, -0.617968526367193],
            [-47.828467916992182, -0.6211685263672],
            [-47.826767916992182, -0.623668526367197],
            [-47.824267916992184, -0.626668526367197],
            [-47.822067916992182, -0.629368526367195],
            [-47.822167916992186, -0.632668526367198],
            [-47.820567916992182, -0.636068526367197],
            [-47.821667916992183, -0.639568526367199],
            [-47.824567916992187, -0.646268526367194],
            [-47.826467916992186, -0.653468526367199],
            [-47.826267916992187, -0.658168526367199],
            [-47.826767916992182, -0.662868526367198],
            [-47.829267916992187, -0.667768526367198],
            [-47.832567916992183, -0.670268526367195],
            [-47.834767916992185, -0.675768526367193],
            [-47.835967916992182, -0.679368526367199],
            [-47.838167916992184, -0.681868526367197],
            [-47.842067916992185, -0.681968526367193],
            [-47.845867916992184, -0.677968526367195],
            [-47.847567916992183, -0.675268526367198],
            [-47.848967916992187, -0.672468526367197],
            [-47.852067916992183, -0.669768526367193],
            [-47.856367916992184, -0.671968526367195],
            [-47.861367916992187, -0.673068526367196],
            [-47.86466791699219, -0.671368526367196],
            [-47.866167916992183, -0.666668526367197],
            [-47.86296791699219, -0.659868526367198],
            [-47.857867916992184, -0.655668526367194],
            [-47.855367916992186, -0.652268526367195],
            [-47.855567916992186, -0.649268526367194],
            [-47.855667916992182, -0.646068526367195],
            [-47.856067916992188, -0.642668526367196],
            [-47.854567916992188, -0.639268526367196],
            [-47.852067916992183, -0.635668526367198],
            [-47.850867916992186, -0.632668526367198],
            [-47.848067916992186, -0.629068526367199],
            [-47.845967916992187, -0.625168526367197],
            [-47.846967916992185, -0.621068526367196],
            [-47.848167916992182, -0.617168526367195],
            [-47.849467916992182, -0.613068526367194],
            [-47.849967916992185, -0.609168526367199],
            [-47.849867916992189, -0.605568526367193],
            [-47.849267916992183, -0.601968526367195],
            [-47.850367916992184, -0.599168526367194],
            [-47.849567916992186, -0.595968526367194],
            [-47.848467916992185, -0.591468526367194],
            [-47.848367916992188, -0.588368526367198],
            [-47.852067916992183, -0.588768526367197],
            [-47.855667916992182, -0.5890685263672],
            [-47.858867916992182, -0.588768526367197],
            [-47.859467916992187, -0.585768526367197],
            [-47.857567916992188, -0.583668526367198],
            [-47.857067916992186, -0.580468526367198],
            [-47.857767916992188, -0.575968526367198],
            [-47.858567916992186, -0.571368526367195],
            [-47.860367916992189, -0.567468526367193],
            [-47.863667916992185, -0.564168526367197],
            [-47.867167916992187, -0.562368526367194],
            [-47.870767916992186, -0.560868526367194],
            [-47.874367916992185, -0.560468526367195],
            [-47.877667916992181, -0.559668526367197],
            [-47.88136791699219, -0.559168526367195],
            [-47.884867916992185, -0.559368526367194],
            [-47.887667916992186, -0.5586685263672],
            [-47.891267916992184, -0.558268526367193],
            [-47.896267916992187, -0.558268526367193],
            [-47.900367916992181, -0.558368526367197],
            [-47.903167916992189, -0.559068526367199],
            [-47.90706791699219, -0.5601685263672],
            [-47.911367916992184, -0.561868526367199],
            [-47.914267916992188, -0.563568526367199],
            [-47.916667916992182, -0.566268526367196],
            [-47.914567916992183, -0.568468526367198],
            [-47.913867916992189, -0.572668526367195],
            [-47.910667916992182, -0.576368526367197],
            [-47.908867916992186, -0.579268526367194],
            [-47.909567916992188, -0.582868526367193],
            [-47.90976791699218, -0.588368526367198],
            [-47.90976791699218, -0.593368526367193],
            [-47.90846791699218, -0.596468526367197],
            [-47.908067916992181, -0.600368526367198],
            [-47.911967916992182, -0.603168526367199],
            [-47.91446791699218, -0.600568526367198],
            [-47.91946791699219, -0.602068526367198],
            [-47.920367916992184, -0.605868526367196],
            [-47.919667916992182, -0.609268526367195],
            [-47.918167916992189, -0.612268526367195],
            [-47.916367916992186, -0.616668526367199],
            [-47.915667916992184, -0.619668526367199],
            [-47.91746791699218, -0.623268526367198],
            [-47.920367916992184, -0.625268526367194],
            [-47.923367916992184, -0.626068526367199],
            [-47.930067916992186, -0.627468526367196],
            [-47.932767916992184, -0.629068526367199],
            [-47.935767916992184, -0.631068526367194],
            [-47.93886791699218, -0.633068526367197],
            [-47.941967916992184, -0.632768526367194],
            [-47.94516791699219, -0.633768526367199],
            [-47.948767916992182, -0.633868526367195],
            [-47.952067916992185, -0.632368526367195],
            [-47.955467916992184, -0.632068526367199],
            [-47.958467916992184, -0.633568526367199],
            [-47.960667916992186, -0.6363685263672],
            [-47.963967916992189, -0.641268526367199],
            [-47.965067916992183, -0.646868526367193],
            [-47.964767916992187, -0.651268526367197],
            [-47.965167916992186, -0.655968526367197],
            [-47.965167916992186, -0.660068526367198],
            [-47.963467916992187, -0.665668526367199],
            [-47.963767916992182, -0.669568526367193],
            [-47.965167916992186, -0.673568526367198],
            [-47.967267916992185, -0.676168526367199],
            [-47.969867916992186, -0.677768526367196],
            [-47.97256791699219, -0.6789685263672],
            [-47.975267916992181, -0.680568526367196],
            [-47.975567916992183, -0.686368526367197],
            [-47.978867916992186, -0.688568526367199],
            [-47.980867916992182, -0.690768526367194],
            [-47.983867916992182, -0.692668526367193],
            [-47.988267916992186, -0.694168526367193],
            [-47.993367916992185, -0.693268526367198],
            [-47.997367916992189, -0.691368526367199],
            [-47.999467916992188, -0.688368526367199],
            [-48.002667916992181, -0.686568526367196],
            [-48.010267916992184, -0.686168526367197]
        ],
        [
            [-66.97556791699219, -10.188768526367195],
            [-66.974567916992186, -10.185468526367195],
            [-66.972067916992188, -10.187668526367197],
            [-66.97556791699219, -10.188768526367195]
        ],
        [
            [-53.72426791699219, -26.562768526367197],
            [-53.72096791699218, -26.558768526367196],
            [-53.71656791699219, -26.557268526367196],
            [-53.712967916992184, -26.556568526367194],
            [-53.709667916992188, -26.557268526367196],
            [-53.708267916992185, -26.561068526367194],
            [-53.710867916992186, -26.562968526367197],
            [-53.714267916992185, -26.562668526367197],
            [-53.718767916992185, -26.561868526367196],
            [-53.72426791699219, -26.562768526367197]
        ],
        [
            [-43.566867916992187, -23.063068526367196],
            [-43.566867916992187, -23.058868526367196],
            [-43.567167916992183, -23.052868526367199],
            [-43.564067916992187, -23.052868526367199],
            [-43.562967916992186, -23.057968526367198],
            [-43.564067916992187, -23.060868526367194],
            [-43.566867916992187, -23.063068526367196]
        ],
        [
            [-43.213767916992182, -22.869468526367196],
            [-43.213967916992182, -22.872668526367196],
            [-43.218167916992186, -22.874768526367198],
            [-43.220567916992188, -22.872968526367195],
            [-43.225267916992188, -22.874368526367196],
            [-43.225667916992187, -22.870068526367199],
            [-43.220867916992184, -22.869768526367196],
            [-43.216967916992182, -22.870268526367198],
            [-43.213767916992182, -22.869468526367196]
        ],
        [
            [-43.232467916992185, -22.864668526367197],
            [-43.228867916992186, -22.866968526367195],
            [-43.232167916992182, -22.868268526367196],
            [-43.232467916992185, -22.864668526367197]
        ],
        [
            [-45.459367916992186, -23.577068526367196],
            [-45.456567916992185, -23.573568526367197],
            [-45.456267916992182, -23.577168526367196],
            [-45.459367916992186, -23.577068526367196]
        ],
        [
            [-43.581467916992182, -23.046468526367196],
            [-43.578267916992182, -23.043968526367195],
            [-43.577067916992185, -23.046868526367199],
            [-43.581467916992182, -23.046468526367196]
        ],
        [
            [-43.120767916992186, -22.877668526367195],
            [-43.116667916992185, -22.876368526367195],
            [-43.115467916992188, -22.880968526367198],
            [-43.119667916992185, -22.881768526367196],
            [-43.120767916992186, -22.877668526367195]
        ]
        ]
    ]
    }
}

export default brazilGeoJson;
