import AuthApi from "Api/AuthApi";
import Storage from "./Storage";

const AuthHelper = {

    checkForTokenRefresh: async () => {
        if (Storage.exists('isRefreshingToken'))
            return true;

        let tokenRefreshDate = Storage.get('token_refresh_date');
        if ( !tokenRefreshDate || new Date(tokenRefreshDate).toISOString() <= (new Date).toISOString() ) {
            try {
                Storage.set('isRefreshingToken', true);
                let refresh = await AuthApi.refreshToken();
                Storage.remove('isRefreshingToken');
                if (refresh.status === 200) {
                    Storage.set('token', refresh.json.token);
                    AuthHelper.setRefreshDate();
                    return true;
                } else {
                    return false;
                }
            } catch (err) {
                return false;
            }
        } else {
            return true;
        }
    },

    setRefreshDate: () => {
        let newRefreshDate = new Date;
        newRefreshDate.setMinutes( newRefreshDate.getMinutes() + 10 );
        Storage.set('token_refresh_date', newRefreshDate);
    },

    getBearerToken: () => {
        if (Storage.exists('token'))
            return `Bearer ${Storage.get('token')}`;
        return '';
    }
}

export default AuthHelper;
