import { Box, Flex, Text } from "@chakra-ui/react";
import jsonData from "../../apex_chart_pt_br.json";
import StationsApi from "Api/StationsApi";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

export default function Forecast16Graphs({ station_id }) {
    const [ loading, setLoading ] = useState(false);
    const [ forecast, setForecast ] = useState([]);

    useEffect(() => {
        loadForecastData();
    }, []);

    async function loadForecastData() {
        setLoading(true);

        let response = await StationsApi.forecast16(station_id);
        setForecast(response.json);

        setLoading(false);
    }


    const getChartSeriesTemp = () => [
        {
            name: 'Temperatura Mínima',
            data: forecast.map(d => parseFloat(d.temp_min)),
            color: "#0BC5EA"
        },
        {
            name: 'Temperatura Máxima',
            data: forecast.map(d => parseFloat(d.temp_max)),
            color: "#F56565"
        },

        {
            name: 'Sensação Térmica Mínima',
            data: forecast.map(d => parseFloat(d.heat_index_min)),
            color: "#48BB7855"
        },
        {
            name: 'Sensação Térmica Máxima',
            data: forecast.map(d => parseFloat(d.heat_index_max)),
            color: "#ECC94B55"
        }
    ];

    const getChartOptionsTemp = () => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: 'pt-br',
            },

            dataLabels: {
                enabled: true,
                enabledOnSeries: [0, 1],
                formatter: function (val, opts) {
                    return val + "°";
                },
            },
            tooltip: {
                theme: "dark",
                x: {
                    show: true,
                    format: "dd MMM"
                }
            },
            xaxis: {
                type: "datetime",
                categories: forecast.map(d => d.date_iso),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            yaxis: {
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: function (val) {
                        return val + "°";
                    },
                },
            },
        }
    };



    const getChartOptionsRain = () => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: 'pt-br',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    if (opts.seriesIndex == 0) {
                        return val + "%";
                    } else if (opts.seriesIndex == 0) {
                        return val + "mm";
                    } else {
                        return val;
                    }
                },
            },
            tooltip: {
                theme: "dark",
                x: {
                    show: true,
                    format: "dd MMM"
                }
            },
            xaxis: {
                type: "datetime",
                categories: forecast.map(d => d.date_iso),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            yaxis: [{
                opposite: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: (value) => { return value + "%" },
                },
            },{
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: (value) => { return value + "mm" },
                },
            },{
                show: false,
                opposite: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: (value) => { return value },
                },
            }],
        }
    };


    const getChartSeriesRain = () => [
        {
            name: 'Probabilidade',
            data: forecast.map(d => parseFloat(d.precip_chance)),
            color: "#0BC5EA",
            type: 'line',
            id: "probabilidade"
        },
        {
            name: 'Chuva',
            data: forecast.map(d => parseFloat(d.precip)),
            color: "#3182CE",
            type: 'bar',
        },
        {
            name: 'Evapotranspiração',
            data: forecast.map(d => parseFloat(d.evapotranspiration)),
            color: "#48BB78",
            type: 'line',
        },
    ];


    const getChartOptionsWind = () => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                locales: [jsonData],
                defaultLocale: 'pt-br',
            },
            dataLabels: {
                enabled: true,
                formatter: function (val, opts) {
                    let dir = forecast[opts.dataPointIndex].wind_direction || 0;

                    return [val + " km/h",  `${dir}° ` + windDirecitonArrow( dir )];
                },
            },
            tooltip: {
                theme: "dark",
                x: {
                    show: true,
                    format: "dd MMM"
                }
            },
            xaxis: {
                type: "datetime",
                categories: forecast.map(d => d.date_iso),
                show: true,
                color: "#A0AEC0",
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                },
            },
            yaxis: {
                color: "#A0AEC0",
                labels: {
                    show: true,
                    style: {
                        colors: "#A0AEC0",
                        fontSize: "14px",
                    },
                    formatter: (value) => {
                        return value + " km/h"
                    },
                },
            },
        }
    };


    const getChartSeriesWind = () => [
        {
            name: 'Velocidade',
            data: forecast.map(d => parseFloat(d.wind_speed)),
            color: "#48BB78",
            type: 'line',
        },
        {
            name: 'Rajada',
            data: forecast.map(d => parseFloat(d.wind_gust)),
            color: "#9F7AEA",
        },
    ];

    function windDirecitonArrow(degress) {
        if (degress > 15 && degress <= 75) {
            return '⬈';
        } else if (degress > 75 && degress <= 105) {
            return '⮕';
        } else if (degress > 105 && degress <= 165) {
            return '⬊';
        } else if (degress > 165 && degress <= 195) {
            return '⬇';
        } else if (degress > 195 && degress <= 255) {
            return '⬋';
        } else if (degress > 255 && degress <= 285) {
            return '⬅';
        } else if (degress > 285 && degress <= 345) {
            return '⬉';
        } else {
            return '⬆';
        }
    }

    return (
        <Box w="100%" borderRadius={10} p={2}>
            {
                loading ? "Carregando previsão do tempo..." : <>
                    <Flex direction="column" mb={4} w="100%">
                        <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>Temperatura 15 dias</Text>
                        <ReactApexChart type="line" options={getChartOptionsTemp()} series={getChartSeriesTemp()} width="100%" height={350} />
                    </Flex>

                    <Flex direction="column" mb={4} w="100%">
                        <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>Chuva 15 dias</Text>
                        <ReactApexChart type="line" options={getChartOptionsRain()} series={getChartSeriesRain()} width="100%" height={350} />
                    </Flex>


                    <Flex direction="column" mb={4} w="100%">
                        <Text fontSize={"lg"} fontWeight={"semibold"} textAlign={"center"}>Vento 15 dias</Text>
                        <ReactApexChart type="line" options={getChartOptionsWind()} series={getChartSeriesWind()} width="100%" height={350} />
                    </Flex>

                </>
            }
        </Box>
    );
}
