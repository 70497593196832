import { Box, Button } from "@chakra-ui/react";

export default function PrintMode() {

    function togglePrintMode() {
        document.body.classList.toggle('print-mode');
    }

    return (
        <Box w={"100%"} mt={2} textAlign="center">
            <Button
                size={"xs"}
                variant="outline"
                colorScheme="blue"
                onClick={togglePrintMode}
            >
                Modo Print
            </Button>
        </Box>
    );
}
