import {
    Button,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Flex,
    GridItem,
    SimpleGrid,
    Spinner,
    Text,
    useMediaQuery,
    useDisclosure,
    Box,
    RadioGroup,
    Stack,
    Radio,
    Table,
    Tbody,
    Td,
    Tr,
} from "@chakra-ui/react";
import StationsApi from "Api/StationsApi";
import { divIcon } from "leaflet";
import { useEffect, useState } from "react";
import { TbExternalLink } from "react-icons/tb";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { Link } from "react-router-dom";

export function renderStationMarkers(stations) {
    return (stations || []).map((station) => (
        <Marker
            icon={divIcon({
                iconSize: [0, 0],
                html: `<div style="background-color: ${getCor(
                    getActiveData(station)
                )}; color:white;" class="icone icone-${getCor(
                    getActiveData(station)
                ).replace('#', '')}">${getActiveData(station) || "-"
                    }</div>`,
                popupAnchor: [5, 0],
                className: `${getActiveData(station) || "x"}`,
            })}
            key={"marker_" + station.identifier}
            position={[station.lat, station.long]}
        >
            <Popup>
                <Flex justifyContent={"center"} w="100%" direction={"column"}>
                    <Text
                        textAlign={"center"}
                        fontWeight={"semibold"}
                        m={"0 !important"}
                    >
                        {station.full_name
                            ? station.full_name
                            : `${station.city} / ${station.state} / ${station.provider}`}
                    </Text>
                    <SimpleGrid columns={3} mt={2} rowGap={3}>
                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="blue.500"
                            >
                                <b>{station.temp_min || "-"}º</b>
                                Mínima
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                            >
                                <b>{station.last_data.temp || "-"}º</b>
                                Temperatura
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="red.500"
                            >
                                <b>{station.temp_max || "-"}º</b>
                                Máxima
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="green.500"
                            >
                                <b>{station.last_data.wind_speed || "-"} km/h</b>
                                Vel. Vento
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="green.500"
                            >
                                <b>
                                    {station.last_data.wind_dir || "-"}º (
                                    {degreesToCardinal(station.last_data.wind_dir) || "-"}
                                    )
                                </b>
                                Direção
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="green.500"
                            >
                                <b>{station.last_data.wind_gust || "-"} km/h</b>
                                Rajada
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="blue.500"
                            >
                                <b>{station.last_data.humidity || "-"}%</b>
                                Umidade
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="blue.500"
                            >
                                <b>{station.last_data.precip_rate || "-"} mm</b>
                                Chuva
                            </Flex>
                        </GridItem>

                        <GridItem>
                            <Flex
                                justifyContent={"center"}
                                direction={"column"}
                                textAlign={"center"}
                                color="blue.500"
                            >
                                <b>{station.last_data.precip_total || "-"} mm</b>
                                Acumulado
                            </Flex>
                        </GridItem>

                        <GridItem colSpan={3} textAlign={"center"}>
                            Atualizado {station.last_data.created_at}
                            <br />
                            <Link to={`/estacao/${station.id}`}>
                                <Button size="xs" mt={2} rightIcon={<TbExternalLink />}>
                                    Ver mais detalhes
                                </Button>
                            </Link>
                        </GridItem>
                    </SimpleGrid>
                </Flex>
            </Popup>
        </Marker>
    ))
}


function corTemperatura(valor) {
    valor = Number(valor);

    if (valor <= -15) {
        return "#ED64A6";
    } else if (valor <= -5) {
        return "#97266D"; // "#B758B3";
    } else if (valor < 0) {
        return "#805AD5"; // "#694295";
    } else if (valor < 5) {
        return "#2B6CB0"; // "#2D458F";
    } else if (valor < 10) {
        return "#00B5D8"; // "#2682C5";
    } else if (valor < 15) {
        return "#38B2AC"; // "#4FAEAD";
    } else if (valor < 20) {
        return "#48BB78"; // "#51A14F";
    } else if (valor < 25) {
        return "#68D391"; // "#94BD3A";
    } else if (valor < 30) {
        return "#F6E05E"; // "#EAB735";
    } else if (valor < 35) {
        return "#ED8936"; // "#EC743B";
    } else if (valor < 40) {
        return "#E53E3E"; // "#CE3C35";
    } else if (valor < 45) {
        return "#9B2C2C"; // "#673721";
    } else {
        return "#652B19"; // "#1F1F1F";
    }
}

function corUmidade(valor) {
    valor = Number(valor);
    if (valor <= 10) {
        return "#1F1F1F";
    } else if (valor <= 20) {
        return "#9C4221"; // "#673721";
    } else if (valor <= 30) {
        return "#9B2C2C"; // "#942823";
    } else if (valor <= 40) {
        return "#E53E3E"; // "#CE3C35";
    } else if (valor <= 50) {
        return "#F6AD55"; // "#DF9D9D";
    } else if (valor <= 60) {
        return "#90CDF4"; // "#DDDDDD";
    } else if (valor <= 70) {
        return "#4299E1"; // "#9EC5E1";
    } else if (valor <= 80) {
        return "#2B6CB0"; // "#1673C2";
    } else if (valor <= 90) {
        return "#2C5282"; // "#2D458F";
    } else {
        return "#6B46C1"; // "#694295";
    }
}

function corElevacao(valor) {
    valor = Number(valor);
    if (valor <= 0) {
        return "#575757";
    } else if (valor <= 20) {
        return "#2D458F";
    } else if (valor <= 50) {
        return "#2682C5";
    } else if (valor <= 100) {
        return "#4FAEAD";
    } else if (valor <= 200) {
        return "#51A14F";
    } else if (valor <= 400) {
        return "#EAB735";
    } else if (valor <= 600) {
        return "#EC743B";
    } else if (valor <= 800) {
        return "#CE3C35";
    } else if (valor <= 1000) {
        return "#DF9D9D";
    } else {
        return "#333333";
    }
}

function corChuva(valor) {
    valor = Number(valor);

    if (valor <= 0) {
        return "#AAAAAA"; // "#FFFFFF";
    } else if (valor <= 1) {
        return "#63B3ED"; // "#EC743B";
    } else if (valor <= 5) {
        return "#4299E1"; // "#EAB735";
    } else if (valor <= 10) {
        return "#3182CE"; // "#51A14F";
    } else if (valor <= 20) {
        return "#2B6CB0"; // "#4FAEAD";
    } else if (valor <= 30) {
        return "#2C5282"; // "#2682C5";
    } else if (valor <= 50) {
        return "#2A4365"; // "#2D458F";
    } else if (valor <= 100) {
        return "#1A365D"; // "#694295";
    } else if (valor <= 150) {
        return "#44337A"; // "#575757";
    } else {
        return "#322659"; // "#1F1F1F";
    }
}

function degreesToCardinal(dir) {
    let directions = [
        "N",
        "NNE",
        "NE",
        "ENE",
        "E",
        "ESE",
        "SE",
        "SSE",
        "S",
        "SSW",
        "SW",
        "WSW",
        "W",
        "WNW",
        "NW",
        "NNW",
        "N",
    ];
    return directions[Math.round(dir / 22.5)];
}

export function getCor(value) {
    switch (window.mapMarkerType) {
        case "temp":
        case "temp_max":
        case "temp_min":
            return corTemperatura(value);
        case "humidity":
            return corUmidade(value);
        case "elevation":
            return corElevacao(value);
        case "precip_rate":
        case "precip_total":
            return corChuva(value);
        default:
            return "red";
    }
}


function getActiveData(station) {
    switch (window.mapMarkerType) {
        case "temp":
            return station.last_data.temp;
        case "temp_max":
            return station.temp_max;
        case "temp_min":
            return station.temp_min;
        case "humidity":
            return station.last_data.humidity;
        case "elevation":
            return station.elevation;
        case "precip_rate":
            return station.last_data.precip_rate;
        case "precip_total":
            return station.last_data.precip_total;
        default:
            return 0;
    }
}

export default function Home() {
    const [stations, setStations] = useState([]);
    const [mapMarkerType, setMapMarkerType] = useState("temp");
    const [mapLayerType, setMapLayerType] = useState("default");
    const [mapGroupBehavior, setMapGroupBehavior] = useState("group");
    const [isLoadingStations, setIsLoadingStations] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();

    let center = [-14.4095261, -51.31668];

    useEffect(() => {
        loadStations();
    }, []);

    useEffect(() => {
        var channel = window.pusher.subscribe("stations");
        channel.bind("stations-update", function () {
            loadStations();
        });

        return () => {
            window.pusher.unsubscribe("stations");
        };
    }, []);

    async function loadStations() {
        setIsLoadingStations(true);
        let data = await StationsApi.homepage();
        setStations(data.json || []);
        setIsLoadingStations(false);
    }

    const [isMobile] = useMediaQuery("(max-width: 768px)");

    function getLabel() {
        switch (mapMarkerType) {
            case "temp":
                return "Temperatura (ºC)";
            case "temp_max":
                return "Temperatura Máx. (ºC)";
            case "temp_min":
                return "Temperatura Mín. (ºC)";
            case "humidity":
                return "Umidade (%)";
            case "elevation":
                return "Elevação (m)";
            case "precip_rate":
                return "Índice de Precip. (mm)";
            case "precip_total":
                return "Precip. acumulada (mm)";
            default:
                return 0;
        }
    }

    useEffect(() => {
        window.mapMarkerType = mapMarkerType;
        onClose();
    }, [mapMarkerType]);

    return (
        <>
            <link
                rel="stylesheet"
                href="https://unpkg.com/react-leaflet-markercluster/dist/styles.min.css"
            />
            {isLoadingStations && (
                <Flex
                    position={"fixed"}
                    bottom={"60px"}
                    left={0}
                    right={0}
                    height={"20px"}
                    zIndex={99}
                    color={"white"}
                    gridGap={2}
                    justifyContent={"center"}
                    textShadow={"#333 0px 0 5px;"}
                >
                    <Spinner color="blue" /> Atualizando estações...
                </Flex>
            )}

            <Box className="map-drawer-btn" zIndex={99}>
                <Flex
                    align="center"
                    direction={"column"}
                    cursor={"pointer"}
                    onClick={onOpen}
                >
                    <Text fontSize={"sm"}>Mapa</Text>
                    <Text fontSize={"xs"}>{getLabel()}</Text>
                </Flex>
            </Box>

            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Configuração do Mapa</DrawerHeader>

                    <DrawerBody>
                        <Text fontWeight={"semibold"}>Dado a ser exibido</Text>
                        <RadioGroup
                            onChange={setMapMarkerType}
                            value={mapMarkerType}
                            mt={2}
                        >
                            <Stack direction="column">
                                <Radio value="temp">Temperatura (°C)</Radio>
                                <Radio value="temp_min">Temperatura Mínima (°C)</Radio>
                                <Radio value="temp_max">Temperatura Máxima (°C)</Radio>
                                <Radio value="humidity">Umidade (%)</Radio>
                                <Radio value="precip_rate">Índice de Precip. (mm)</Radio>
                                <Radio value="precip_total">Precip. acumulada (mm)</Radio>
                                <Radio value="elevation">Elevação (m)</Radio>
                            </Stack>
                        </RadioGroup>


                        <Text fontWeight={"semibold"} mt={9}>Imagem do Mapa</Text>
                        <RadioGroup
                            onChange={setMapLayerType}
                            value={mapLayerType}
                            mt={2}
                        >
                            <Stack direction="column">
                                <Radio value="default">Padrão</Radio>
                                <Radio value="satelite">Imagem de Satélite</Radio>
                            </Stack>
                        </RadioGroup>


                        <Text fontWeight={"semibold"} mt={9}>Agrupar Estações</Text>
                        <RadioGroup
                            onChange={setMapGroupBehavior}
                            value={mapGroupBehavior}
                            mt={2}
                        >
                            <Stack direction="column">
                                <Radio value="group">Agrupar se pouco zoom</Radio>
                                <Radio value="ungroup">Não agrupar</Radio>
                            </Stack>
                        </RadioGroup>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Cancelar
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>

            <MapContainer
                center={center}
                zoom={4}
                style={{
                    position: "fixed",
                    top: isMobile ? "70px" : "50px",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    width: "100%",
                    borderRadius: "15px !important",
                    zIndex: 0,
                }}
                attributionControl={false}
            >
                {
                    mapLayerType == "default" ?
                        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="OpenStreetMap" />
                        :
                        <>
                            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" attribution="ArcGIS" />
                            <TileLayer
                                url="https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}"
                                opacity={0.75}
                            />
                        </>
                }


                {
                    mapGroupBehavior == "group" ?
                        <MarkerClusterGroup
                            maxClusterRadius={50}
                            disableClusteringAtZoom={7}
                            iconCreateFunction={(cluster) => {
                                let data = [];
                                const childMarkers = cluster.getAllChildMarkers();

                                childMarkers.forEach((childMarker) => {
                                    let value = childMarker.options.icon.options.className;

                                    if (value != "x") {
                                        data.push(Number(value));
                                    }
                                });

                                let avg = (
                                    data.reduce((a, b) => a + b, 0) / childMarkers.length
                                ).toFixed(1);

                                return divIcon({
                                    iconSize: [50, 30],
                                    html: `<div style="background-color: ${getCor(
                                        avg
                                    )}; color:white;" class="icone icone-cluster icone-${getCor(
                                        avg
                                    ).replace('#', '')}">~${avg}<br>${cluster._childCount
                                        } estações</div>`,
                                    popupAnchor: [60, 0],
                                });
                            }}
                        >
                            {renderStationMarkers(stations)}
                        </MarkerClusterGroup>
                        :
                        renderStationMarkers(stations)
                }

                {/* Legend */}


                <Flex zIndex={999} position={"absolute"} bottom={0} justify={"center"} align={"center"} w="full">
                    <Box bg={"white"} px={2} py={1} roundedTop="md" roundedBottom={"none"} className="map-legend-container">
                        {
                            ['temp', 'temp_max', 'temp_min'].indexOf(mapMarkerType) !== -1 && (
                                <Table w={"auto"} size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>-10°C</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>0°C</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>10°C</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>20°C</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>30°C</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>40°C</Td>
                                            <Td py={0} px={0} fontSize={"xs"} colSpan={1} textAlign={"center"}>50°C</Td>
                                        </Tr>
                                        <Tr>
                                            <Td p={2} backgroundColor={corTemperatura(-15)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(-5)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(-1)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(2)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(7)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(12)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(17)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(22)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(27)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(32)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(37)}></Td>
                                            <Td p={2} backgroundColor={corTemperatura(40)}></Td>
                                            <Td p={2} px={0} backgroundColor={corTemperatura(50)}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            )
                        }

                        {
                            mapMarkerType == "humidity" && (
                                <Table w={"auto"} size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>10%</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>30%</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>50%</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>70%</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>90%</Td>
                                        </Tr>
                                        <Tr>
                                            <Td p={2} backgroundColor={corUmidade(10)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(20)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(30)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(40)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(50)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(60)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(70)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(80)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(90)}></Td>
                                            <Td p={2} backgroundColor={corUmidade(100)}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            )
                        }


                        {
                            ['precip_total', 'precip_rate'].indexOf(mapMarkerType) !== -1 && (
                                <Table w={"auto"} size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>1mm</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>10mm</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>30mm</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>100mm</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>150mm</Td>
                                        </Tr>
                                        <Tr>
                                            <Td p={2} backgroundColor={corChuva(0)}></Td>
                                            <Td p={2} backgroundColor={corChuva(1)}></Td>
                                            <Td p={2} backgroundColor={corChuva(5)}></Td>
                                            <Td p={2} backgroundColor={corChuva(10)}></Td>
                                            <Td p={2} backgroundColor={corChuva(20)}></Td>
                                            <Td p={2} backgroundColor={corChuva(30)}></Td>
                                            <Td p={2} backgroundColor={corChuva(50)}></Td>
                                            <Td p={2} backgroundColor={corChuva(100)}></Td>
                                            <Td p={2} backgroundColor={corChuva(150)}></Td>
                                            <Td p={2} backgroundColor={corChuva(160)}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            )
                        }

                        {
                            mapMarkerType == "elevation" && (
                                <Table w={"auto"} size="sm">
                                    <Tbody>
                                        <Tr>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>20m</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>100m</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>200m</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>600m</Td>
                                            <Td py={0} fontSize={"xs"} colSpan={2} textAlign={"center"}>1000m</Td>
                                        </Tr>
                                        <Tr>
                                            <Td p={2} backgroundColor={corElevacao(0)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(20)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(50)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(100)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(200)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(400)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(600)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(800)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(1000)}></Td>
                                            <Td p={2} backgroundColor={corElevacao(1100)}></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            )
                        }
                    </Box>
                </Flex>

            </MapContainer>
        </>
    );
}
