import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "components/Routing/PrivateRoute";
import { LoadingProvider } from "Providers/LoadingProvider";
import { AuthProvider } from "Providers/AuthProvider";
import theme from "theme/theme.js";
import { ChakraProvider } from "@chakra-ui/react";
import PublicLayout from "layouts/Public.js";
import AuthLayout from "layouts/Auth.js";
import Member from "layouts/Member.js";
import Pusher from "pusher-js";
import NotFound from "views/Pages/NotFound";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import env from "react-dotenv";
import './global.css';

window.pusher = new Pusher('6b6cb10e949c5def1e29', {
    cluster: 'us2'
});

env.APP_ENV != 'local' && Sentry.init({
    dsn: "https://da6cdfe4dabd492c98eaf2bb9abda565@o4504655965650944.ingest.sentry.io/4504655967682560",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
});

ReactDOM.render(
    <ChakraProvider theme={theme} resetCss={false} w="100%">
        <BrowserRouter>
            <LoadingProvider>
                <AuthProvider>
                    <Switch>
                        <Route exact={true} path={`/estacao/:identifier`} component={PublicLayout} />
                        <Route exact={true} path={`/estacoes`} component={PublicLayout} />
                        <Route exact={true} path={`/`} component={PublicLayout} />
                        <PrivateRoute path="/members">
                            <Member/>
                        </PrivateRoute>
                        <Route path={`/auth`} component={AuthLayout} />
                        <Route path='*' exact={true} component={NotFound} />
                    </Switch>
                </AuthProvider>
            </LoadingProvider>
        </BrowserRouter>
    </ChakraProvider>,
    document.getElementById("root")
);
