import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
import PublicNavbar from "components/Navbars/PublicNavbar.js";
// import Sidebar from "components/Sidebar/Sidebar.js";
import React from "react";
import { Route, Switch } from "react-router-dom";
import {default as routes} from "public_routes.js";
import theme from "theme/theme.js";
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
// import Configurator from "components/Configurator/Configurator";


export default function Public(props) {
    const { ...rest } = props;

    const getActiveRoute = (routes) => {
        let activeRoute = "";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.href.indexOf(routes[i].path) !== -1) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    // This changes navbar state(fixed or not)
    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.href.indexOf(routes[i].path) !== -1) {
                    if (routes[i].secondaryNavbar) {
                        return routes[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "account") {
                return getRoutes(prop.views);
            }

            return (
                <Route exact={true} path={prop.path} component={prop.component} key={key}/>
            );
        });
    };

    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <ChakraProvider theme={theme} resetCss={false}>
            <MainPanel w={'100%'}>
                <Portal>
                    <PublicNavbar
                        onOpen={onOpen}
                        logoText={"WS Clima"}
                        brandText={getActiveRoute(routes)}
                        fixed={true}
                        {...rest}
                    />
                </Portal>
                <PanelContent pt={{sm: 75, lg: 50}}>
                    <PanelContainer>
                        <Switch>
                            {getRoutes(routes)}
                        </Switch>
                    </PanelContainer>
                </PanelContent>
                {/* <Footer /> */}
            </MainPanel>
        </ChakraProvider>
    );
}
